import styled from 'styled-components';

export const ActiveClassCardContainer = styled.button`
  background-color: #fff;
  border: 0.0625rem solid #e5e5e5;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  text-align: left;
  width: 100%;

  :focus,
  :active {
    outline: none;
  }

  &.left-card {
    margin-right: 1.79%;
  }

  .card-top {
    align-items: flex-start;
    display: flex;

    .card-column-left {
      width: calc(100% - 2.1875rem);

      p {
        font-weight: 500;
        margin-bottom: 0;
        overflow: hidden;
        padding-right: 1.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .class-name {
        color: ${({theme}) => theme.colors.text};
        font-size: 1.25rem;
        line-height: 1.3;
        margin-bottom: 0.25rem;
      }

      .unit-name {
        color: #aaaaaa;
        flex-shrink: 0;
        font-size: 1rem;
        line-height: 1.625;
      }
    }

    button {
      background-color: transparent;
      border: none;
      margin: 0.3125rem 0.3125rem 0 0;

      :active,
      :focus {
        outline: none;
      }

      img {
        height: 1.875rem;
      }
    }
  }

  .seperator {
    background-color: #e5e5e5;
    height: 0.0625rem;
    margin: 1.5rem 0;
    width: 100%;
  }

  .card-bottom {
    align-items: center;
    display: flex;

    .card-column {
      display: flex;
      flex-direction: column;

      :not(:last-child) {
        margin-right: 2rem;
      }

      .column-count {
        color: ${({theme}) => theme.colors.text};
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.21;
        margin-bottom: 0.125rem;
      }

      .column-type {
        color: #aaaaaa;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.21;
        margin-bottom: 0;
      }
    }
  }
`;
