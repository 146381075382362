/* eslint-disable react/prop-types */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  coursesSelector,
  clearFormError,
  getDataForAddCourse,
  getUnitByCourseId,
  getUnitByQualificationId,
  setError as setServerError,
} from '../../../../../../../../redux/coursesSlice';

import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {Select, TextInput} from '../../../../../../../../components/common';

import {courseDetailsSchema} from '../../../../../../../../utils/validation';
import {EditSessionContainer} from './elements';
import {EditUnitsToCourse} from './components';

const FIELDS_IN_ORDER = ['courseName', 'qualification', 'courseType'];

export const EditCourseModal = ({
  editDetails,
  onHide,
  onSubmit,
  addUnits,
  setAddUnits,
}) => {
  const dispatch = useDispatch();

  const {control, errors, handleSubmit, setValue} = useForm({
    defaultValues: {
      courseName: '',
      qualification: null,
      courseType: null,
    },
    resolver: yupResolver(courseDetailsSchema),
  });
  const {
    error: serverError,
    allCourseType,
    allQualifications,
    formError,
    courseUnits,
    isAddCourseLoading,
  } = useSelector(coursesSelector);

  const courseUnitsOptions = useMemo(
    () => [
      ...(courseUnits || [])?.map((item, index) => ({
        serial: index + 1,
        unit: {
          code: item?.orgUnit?.unit?.tun_code,
          label: item?.orgUnit?.unit?.tun_title,
          value: item?.orgUnit?.ou_id_orgunit,
        },
        sessions: item?.ocru_sessions_number,
        hours: item?.ocru_hours_number,
      })),
    ],
    [courseUnits],
  );

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const courseTypeOptions = useMemo(
    () => [
      ...(allCourseType || [])?.map(item => ({
        label: item.typecr_type,
        value: item.typecr_id_typecourse,
      })),
    ],
    [allCourseType],
  );
  const qualificationOptions = useMemo(
    () => [
      ...(allQualifications || [])?.map(item => ({
        label: `${item.qualification.tqual_title} # ${item.qualification.tqual_code}`,
        code: item.qualification.tqual_code,
        value: item.oq_id_orgqual,
      })),
    ],
    [allQualifications],
  );

  useEffect(() => {
    dispatch(getDataForAddCourse());
    dispatch(getUnitByCourseId(editDetails?.id));
    dispatch(getUnitByQualificationId(editDetails?.qualification?.id));
  }, [dispatch, editDetails]);

  useEffect(() => {
    if (editDetails) {
      setValue('courseType', {
        label: editDetails.courseType?.name,
        value: editDetails.courseType?.id,
      });
      setValue('qualification', {
        label: editDetails.qualification?.name,
        value: editDetails.qualification?.id,
      });

      setValue('courseName', editDetails.courseName);
    }
    setAddUnits(courseUnitsOptions);
  }, [courseUnitsOptions, editDetails, setAddUnits, setValue]);

  return (
    <EditSessionContainer
      buttonLabel="Save Changes"
      description="Make changes to the course details"
      isButtonLoading={isAddCourseLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit course details"
      width="55.5rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container">
          <div className="row-container">
            <div className="course-name">
              <Controller
                control={control}
                name="courseName"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoComplete="off"
                    errorMessage={
                      errors.courseName?.message || formError?.courseName
                    }
                    hasError={!!errors.courseName || !!formError.courseName}
                    label="Course Name"
                    placeholder="Enter course name"
                    id="course-name"
                  />
                )}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="field-left field-left1">
              <Controller
                control={control}
                name="qualification"
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.qualification}
                    errorMessage={errors.qualification?.message}
                    isLarge={false}
                    label="Qualification"
                    isDisabled
                    options={qualificationOptions}
                    placeholder="Choose a qualification"
                    onChange={e => {
                      fields.onChange(e);
                    }}
                    id="qualification-name"
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="courseType"
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.courseType}
                    errorMessage={errors.courseType?.message}
                    isLarge={false}
                    label="Type"
                    isDisabled
                    options={courseTypeOptions}
                    placeholder="Choose a type"
                    id="course-type"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <EditUnitsToCourse addUnits={addUnits} setAddUnits={setAddUnits} />
      </form>
    </EditSessionContainer>
  );
};

EditCourseModal.defaultProps = {
  editDetails: null,
};

EditCourseModal.propTypes = {
  editDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    courseName: PropTypes.string.isRequired,
    courseType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    qualification: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
