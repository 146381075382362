/* eslint-disable react/prop-types */

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import searchIcon from '../../../assets/icons/search-alt.svg';
import {SearchInputContainer} from './SearchInputContainer';

const SearchInput = ({
  className,
  onChange,
  placeholder,
  value,
  width,
  style,
  icon,
  id,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <SearchInputContainer
      className={(isFocused ? 'is-focused' : '') + (` ${className}` || '')}
      style={style}
      width={width}
      id={id}
    >
      <img alt="Search" src={icon || searchIcon} />
      <input
        onBlur={() => setIsFocused(false)}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        placeholder={placeholder}
        value={value}
        id={id}
      />
    </SearchInputContainer>
  );
};

export default SearchInput;

SearchInput.defaultProps = {
  id: '',
};

SearchInput.propTypes = {
  id: PropTypes.string,
};
