/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React, {useCallback, useRef} from 'react';
import moment from 'moment';
import ReactBootstrapModal from 'react-bootstrap/Modal';
import {FilePreviewContainer} from './elements';
import {handleFileDownload} from '../../../../../../../../../../../../../../utils/downloadFile';

import CloudDownloadIcon from '../../../../../../../../../../../../../../assets/icons/cloud-download.svg';
import CloseModalIcon from '../../../../../../../../../../../../../../assets/icons/refactor/close-modal.svg';
import ImageIcon from '../../../../../../../../../../../../../../assets/images/filetypes/img.svg';
import DocIcon from '../../../../../../../../../../../../../../assets/images/filetypes/doc.svg';
import CsvIcon from '../../../../../../../../../../../../../../assets/images/filetypes/csv.svg';
import FileIcon from '../../../../../../../../../../../../../../assets/icons/document-instruction.svg';
import imageAudio from '../../../../../../../../../../../../../../assets/images/filetypes/audio-modal-image.png';

export const FilePreviewModal = ({
  curriculumFile,
  onHide,
  show,
  width,
  className,
}) => {
  const videoRef = useRef(null);

  const renderPreview = useCallback(() => {
    const fileExt = curriculumFile?.fi_filename?.substring(
      curriculumFile?.fi_filename?.lastIndexOf('.') + 1,
    );
    const renderIcon = ext => {
      if (ext === 'svg') {
        return ImageIcon;
      }
      if (ext === 'csv') {
        return CsvIcon;
      }
      if (ext === 'doc' || ext === 'docx') {
        return DocIcon;
      }
      return FileIcon;
    };
    if (curriculumFile?.ft_type?.includes('Image')) {
      return (
        <img
          src={curriculumFile?.fi_location}
          alt="cloud"
          className="image-file"
        />
      );
    }
    if (curriculumFile?.ft_type?.includes('Video')) {
      return (
        <div className="videos">
          <video
            controls
            src={curriculumFile?.fi_location}
            width="100%"
            height="100%"
            ref={videoRef}
          >
            <track
              default
              kind="captions"
              srcLang="en"
              src={curriculumFile?.fi_location}
            />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      );
    }
    if (curriculumFile?.ft_type?.includes('Voice')) {
      return (
        <div className="audios" src={curriculumFile?.fi_location} width="100%">
          <img
            src={imageAudio}
            alt="audio icon"
            style={{
              height: '200px',
              width: '200px',
              marginTop: '80px',
            }}
          />
          <audio controls>
            <source
              src={curriculumFile?.fi_location}
              type="audio/mpeg"
              className="source-audio"
            />
          </audio>
        </div>
      );
    }

    if (curriculumFile?.ft_type?.includes('Document')) {
      return (
        <div className="file-data">
          {fileExt === 'pdf' ? (
            <div style={{height: '500px'}}>
              <embed
                src={curriculumFile?.fi_location}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <img
              src={curriculumFile?.fi_thumbnail || renderIcon(fileExt)}
              alt=""
              className="preview-Icon"
            />
          )}
        </div>
      );
    }
    return FileIcon;
  }, [
    curriculumFile?.fi_filename,
    curriculumFile?.ft_type,
    curriculumFile?.fi_location,
    curriculumFile?.fi_thumbnail,
  ]);

  return (
    <ReactBootstrapModal centered onHide={onHide} show={show}>
      <FilePreviewContainer width={width} className={className}>
        <header className="align-items-center d-flex header justify-content-between">
          <div className="d-flex">
            <h3 className="mb-1 title">view document</h3>
          </div>
          <div className="d-flex">
            <button
              type="button"
              className="button-download d-flex align-items-center"
              title="download"
              onClick={() => {
                handleFileDownload({
                  filePath: `stream-${curriculumFile?.fi_S3_filename}`,
                  fileName: curriculumFile?.fi_filename,
                });
              }}
            >
              <img
                className="mb-0 downloadicon"
                alt="arrow"
                src={CloudDownloadIcon}
              />
            </button>
            <div className="spliter" />
            <button
              className="close-modal-button flex-shrink-0"
              onClick={onHide}
              type="button"
            >
              <img
                alt="Close Modal"
                className="close-modal-icon"
                src={CloseModalIcon}
              />
            </button>
          </div>
        </header>
        <div className="main-div">
          <div
            className="container-preview"
            style={
              curriculumFile?.ft_type?.includes('Image')
                ? {
                    height: '550px',
                    backgroundColor: '#535659',
                    display: 'flex',
                    alignItems: 'center',
                  }
                : {}
            }
          >
            {renderPreview()}
          </div>
        </div>
        <footer className="d-flex footer p-4">
          <div className="d-flex flex-column">
            <p className="file-heading">{curriculumFile?.fi_text_content}</p>
            <p className="d-flex date">
              {moment(curriculumFile?.occf_created_at).format('DD MMMM YYYY')}
            </p>
          </div>
        </footer>
      </FilePreviewContainer>
    </ReactBootstrapModal>
  );
};
