/* eslint-disable react/prop-types */

import ReactBootstrapModal from 'react-bootstrap/Modal';

import {Button} from '../../../../../../../../../../components/common/Button';
import {ModalContainer} from './elements';

export const AddModulesModal = ({
  children,
  className,
  description,
  isButtonLoading,
  onButtonClick,
  onHide,
  show,
  title,
  width,
  step,
  uploadFiles,
  onSubmitFiles,
}) => (
  <ReactBootstrapModal centered onHide={onHide} show={show} backdrop="static">
    <ModalContainer width={width} className={className}>
      <header className="align-items-start d-flex header justify-content-between">
        <div className="align-items-center d-flex details">
          <div className="d-flex flex-column">
            <h3 className="mb-1 title" title={title}>
              {title}
            </h3>
            <p className="description mb-0">{description}</p>
          </div>
        </div>
      </header>
      {children}
      <footer className="d-flex footer justify-content-end p-4">
        {step === 0 ? (
          <div className="d-flex">
            <Button
              className="button cancel-button"
              isFullWidth={false}
              onClick={onHide}
              label="Cancel"
              id="cancel-id"
            />

            <Button
              className="button next-btn"
              isFullWidth={false}
              isLoading={isButtonLoading}
              label={isButtonLoading ? 'Next' : 'Next'}
              onClick={onButtonClick}
              id="next-id"
            />
          </div>
        ) : null}
        {step === 1 ? (
          <div className="d-flex">
            <Button
              className="button cancel-button"
              isFullWidth={false}
              onClick={onHide}
              label="Cancel"
              id="cancel"
            />

            <Button
              className="button save-btn"
              isFullWidth={false}
              isDisabled={!uploadFiles.length}
              isLoading={isButtonLoading}
              label={isButtonLoading ? 'Saving' : 'Save'}
              onClick={() => {
                onSubmitFiles();
              }}
              id="save-id"
            />
          </div>
        ) : null}
      </footer>
    </ModalContainer>
  </ReactBootstrapModal>
);
