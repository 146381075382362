import styled from 'styled-components';

export const AudioModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 43.75rem) {
    & {
      .audio-modal-custom-full {
        width: 100% !important;
        margin-bottom: 4%;
        .audio-modal-close {
          display: block;
        }
        .modal-close-button {
          display: none;
        }
      }
    }
  }

  .audio-modal-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0 1.5rem;

    h2 {
      color: ${({theme}) => theme.colors.text};
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.21;
      margin-bottom: 0;
    }

    .modal-close-button {
      background-color: transparent;
      border: none;

      :focus,
      :active {
        outline: none;
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .audio-modal-body {
    padding: 0 1.5rem;
    width: 100%;

    .audio-container {
      background-color: #d9ecff;
      border-radius: 0.312rem;
      height: 35vh;
      overflow: hidden;
      position: relative;
      width: 100%;

      .retake-audio-button-container {
        left: 1.5rem;
        position: absolute;
        top: 1.5rem;
        z-index: 3000;

        .retake-audio-button {
          align-items: center;
          background-color: rgba(61, 68, 87, 0.25);
          border: none;
          border-radius: 0.5rem;
          color: #fff;
          display: flex;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.21;
          padding: 0.5rem;
          transition: background-color 0.1s;

          &.is-toggled {
            background-color: rgba(61, 68, 87, 0.71);
          }

          :focus,
          :active {
            outline: none;
          }

          img {
            height: 1.5rem;
            width: 1.5rem;
          }

          span {
            margin-left: 0.5rem;
          }
        }

        .retake-confirmation {
          background-color: ${({theme}) => theme.colors.text};
          border-radius: 0.312rem;
          margin-top: 1rem;
          padding: 1rem 1rem 0.5rem 1rem;
          position: relative;

          .retake-confirmation-arrow {
            background-color: ${({theme}) => theme.colors.text};
            height: 0.625rem;
            left: 1.31rem;
            position: absolute;
            top: -0.312rem;
            transform: rotate(45deg);
            width: 0.625rem;
          }

          p {
            color: #fff;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.21;
            margin-bottom: 1rem;
          }

          .retake-button-group {
            display: flex;
            justify-content: flex-end;

            button {
              background-color: transparent;
              border: none;
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.71;

              :focus,
              :active {
                outline: none;
              }
            }

            .retake-cancel-button {
              color: #aeaeae;
              margin-right: 1rem;
            }

            .retake-confirm-button {
              color: ${({theme}) => theme.colors.primary};
            }
          }
        }
      }

      .audio-time-duration {
        align-items: center;
        display: flex;
        flex-direction: column;
        left: 50%;
        position: absolute;
        top: calc(50% - 5.93rem);
        transform: translate(-50%, 50%);

        div {
          color: ${({theme}) => theme.colors.text};
          font-size: 2.25rem;
          font-weight: 500;
          line-height: 1.21;
          margin-bottom: 0.5rem;

          span {
            color: ${({theme}) => theme.colors.text};
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 1.21;
            margin-left: 0.5rem;
          }
        }

        .audio-quality {
          color: '#aaa';
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.21;
        }
      }

      audio {
        bottom: 4%;
        left: 1%;
        position: absolute;
        width: 98%;
      }

      .audio-modal-controls {
        align-items: center;
        background-color: ${({theme}) => theme.colors.primary};
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        padding: 1.25rem 0 1.187rem 0;
        position: absolute;
        width: 100%;

        button {
          background-color: transparent;
          border: none;
          height: 3.5rem;
          width: 3.5rem;

          &.small-button {
            height: 2.5rem;
            width: 2.5rem;
          }

          :focus,
          :active {
            outline: none;
          }

          img {
            height: 100%;
            vertical-align: super !important;
            width: 100%;
          }
        }

        .stop-recording-button {
          margin: 0 1rem;
        }
      }
    }
  }

  .audio-modal-footer {
    margin-top: 1.5rem;

    .audio-title {
      padding: 0 1.5rem;
    }

    .audio-instruction-buttons {
      align-items: center;
      border-top: 0.0625rem solid #f2f2f4;
      display: flex;
      justify-content: flex-end;
      margin-top: 1.5rem;
      padding: 1.5rem 1.5rem 0 1.5rem;

      .cancel-button {
        background-color: transparent;
        border: none;
        color: ${({theme}) => theme.colors.text};
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.625;
        margin-right: 2.5rem;

        :focus,
        :active {
          outline: none;
        }
      }
    }
  }
`;
