import instance from '..';

export const getAssessmentDatas = activityId =>
  instance.get(`api/class/student/${activityId}/assessment/getDetails`);

export const getTaskData = (activityId, taskID) =>
  instance.get(`api/class/student/${activityId}/assessment/${taskID}`);

export const reuseAlreadyExistFiles = () =>
  instance.get(`api/class/student/alreadyexistfiles`);

export const fileLinkedToTask = (activityId, taskID, data) =>
  instance.post(
    `api/class/student/${activityId}/assessment/${taskID}/fileLinkedToTask`,
    data,
  );

export const reusedFileLinkedToTask = (activityId, taskID, data) =>
  instance.post(
    `api/class/student/${activityId}/assessment/${taskID}/reusedFileLinkedToTask`,
    data,
  );

export const unlinkFileFromTask = (activityId, taskID, fileLinkId) =>
  instance.delete(
    `api/class/student/${activityId}/assessment/${taskID}/unlinkFileFromTask/${fileLinkId}`,
  );
export const removeAddedTaskFile = (activityId, taskID, fileId, fileLinkId) =>
  instance.delete(
    `api/class/student/${activityId}/assessment/${taskID}/removeFileFromTask/${fileId}?fileLinkId=${fileLinkId}`,
  );

export const submitAssessement = (activityId, data) =>
  instance.post(
    `api/class/student/${activityId}/assessment/submitAssessment`,
    data,
  );

export const resubmissionAssessementRequest = (activityId, data) =>
  instance.post(
    `api/class/student/${activityId}/assessment/resubmissionAssessementRequest`,
    data,
  );
