/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import ArrowLeftImg from '../../../../../../../../../../../../assets/icons/arrow_left.svg';
import {MoreOptions} from '../../../../../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../../../../../components/common/MoreOptions/components';
import FileDownloadIcon from '../../../../../../../../../../../../assets/icons/file-download-shared-class.svg';
import {handleFileDownload} from '../../../../../../../../../../../../utils/downloadFile';
import {ViewFileSharedToClassHeaderContainer} from './elements';

export const ViewFileSharedToClassHeader = ({
  curriculumFile,
  onBack,
  setDeleteName,
  setDeleteId,
  setEditFileName,
  setEditFileId,
}) => (
  <ViewFileSharedToClassHeaderContainer>
    <div className="header-container" id="header-container">
      <div className="header-top">
        <div className="left-content">
          <div className="back-container">
            <div>
              <button
                className="backbutton"
                onClick={onBack}
                type="button"
                id="back-btn"
              >
                <img src={ArrowLeftImg} alt="arrow" />
              </button>
            </div>
            <div>
              <h2 className="title">{curriculumFile?.ocrm_module_name}</h2>
              <p className="year">
                {`${curriculumFile?.ft_type} Document - ${moment(
                  curriculumFile?.fi_uploadedate,
                ).format('DD MMMM YYYY')}`}
              </p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <button type="button" className="menu-btn ml-4 mr-3" id="menu-btn-id">
            <MoreOptions className="menu-dot" id="more-option-menu-id">
              <DropdownItem
                label="Edit File"
                onClick={() => {
                  setEditFileId(curriculumFile?.fi_id_file);
                  setEditFileName(curriculumFile?.fi_text_content);
                }}
                id="edit-file-id"
              />
              <DropdownItem
                isDanger
                label="Delete file"
                onClick={() => {
                  setDeleteId(curriculumFile?.ocrf_id_course_curriculum_file);
                  setDeleteName(curriculumFile?.fi_filename);
                }}
                id="delete-file-id"
              />
            </MoreOptions>
          </button>

          <button
            type="button"
            className="file-download"
            title="download"
            id="download-file-id"
            onClick={() => {
              handleFileDownload({
                filePath: `stream-${curriculumFile?.fi_S3_filename}`,
                fileName: curriculumFile?.fi_filename,
              });
            }}
          >
            <img src={FileDownloadIcon} alt="download icon" />
          </button>
        </div>
      </div>
    </div>
  </ViewFileSharedToClassHeaderContainer>
);
