/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useLocalStorage} from 'react-use';
import CalendarAttendanceIcon from '../../../../../../../../../../assets/icons/calendar-attendance.svg';
import {
  sessionsSelector,
  setCalendarSlot,
} from '../../../../../../../../../../redux/sessionsSlice';
import {EventWrapperContainer} from './elements';
import PresentIcon from '../../../../../../../../../../assets/icons/attendance-present.svg';
import AbsentIcon from '../../../../../../../../../../assets/icons/attendance-absent.svg';
import {authSelector} from '../../../../../../../../../../redux/authSlice';

const EventWrapper = ({draggable, event, slotEnd, slotStart}) => {
  const dispatch = useDispatch();
  const {calendarSlots, search} = useSelector(sessionsSelector);
  const authState = useSelector(authSelector);

  // Check org preference for video class page is enable
  const liveSessionPreference = authState?.orgPreferences?.find(
    item =>
      item.pr_slug === 'enable_video_session' && Number(item.pr_value) === 1,
  );
  const [isTooltipLeft, setIsTooltipLeft] = useState(false);

  const sessionTitle = event.title || 'Untitled';
  const sessionDescription = event.description || '';
  const [userType] = useLocalStorage('jc-user-type', null, {raw: true});
  const {user} = useSelector(state => state.auth);

  const userProfileId = useMemo(() => {
    const userProfile = user?.userProfiles?.find(
      item => item.up_id_typeuserprofile === 2,
    );
    return userProfile?.up_id_userprofile;
  }, [user]);

  useEffect(() => {
    if (!draggable) {
      dispatch(setCalendarSlot([slotStart, slotEnd]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set tooltip position for events in the popup.
  useEffect(() => {
    if (!draggable) {
      // const slot = calendarSlots.find(([startDate, endDate]) => {
      //   if (
      //     slotStart.getTime() >= startDate.getTime() &&
      //     slotStart.getTime() <= endDate.getTime()
      //   )
      //     return true;
      //   return false;
      // });
      // const dateDifference = slot?.[1].getDate() - event.start?.getDate();
      // setIsTooltipLeft(dateDifference !== 0 && dateDifference <= 3);

      const dateDifference = slotStart?.getDate() - event.start?.getDate();
      setIsTooltipLeft(dateDifference <= -3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarSlots, event]);

  const isStudent = useMemo(() => +userType === 2, [userType]);

  const isPresent = useMemo(() => {
    if (isStudent) {
      const presentStudent = event.attendance.presentStudents.find(
        item => item.oruia_id_userprofile_student === userProfileId,
      );
      const absentStudent = event.attendance.absentStudents.find(
        item => item.oruia_id_userprofile_student === userProfileId,
      );

      if (presentStudent) return true;
      if (absentStudent) return false;
    }

    return undefined;
  }, [
    event.attendance?.absentStudents,
    event.attendance?.presentStudents,
    isStudent,
    userProfileId,
  ]);

  const isAttendanceMarked =
    event?.attendance?.presentStudents?.length ||
    event?.attendance?.absentStudents?.length;

  let color;
  let attendanceIcon;
  if (isPresent === true) {
    color = '#4ec977';
    attendanceIcon = PresentIcon;
  } else if (isPresent === false) {
    color = '#ff6969';
    attendanceIcon = AbsentIcon;
  }

  return (
    <EventWrapperContainer
      color={color || event.color}
      isPopup={draggable}
      isTooltipLeft={isTooltipLeft}
      isSearchResult={
        Boolean(search) &&
        sessionTitle.toLowerCase().includes(search.toLowerCase())
      }
    >
      <div className="event-bullet" />
      {Number(userType) !== 4 && liveSessionPreference ? (
        <Link
          // as={button}
          to={`${event.id}`}
          className="d-flex session-button record-button"
          type="button"
        >
          <div className="event-title">{sessionTitle}</div>
        </Link>
      ) : (
        <div className="event-title">{sessionTitle}</div>
      )}

      <div className="event-tooltip">
        <span className="tooltip-time">
          <div />
          <span>{event.time}</span>
        </span>
        <span className="tooltip-title">{sessionTitle}</span>
        <span className="tooltip-teacher">{sessionDescription}</span>
        <span className="tooltip-teacher">{event.teacher}</span>
        <span className="tooltip-class">{event.class}</span>
        {!isStudent ? (
          <>
            {liveSessionPreference &&
              (isAttendanceMarked ? (
                <Link className="tooltip-link" to={`${event?.id}/attendance`}>
                  <img alt="attendance" src={CalendarAttendanceIcon} />
                  <span>
                    {event?.attendance?.presentStudents?.length || 0} /{' '}
                    {event?.attendance?.students?.length || 0}
                  </span>
                </Link>
              ) : (
                <Link className="tooltip-button" to={`${event?.id}/attendance`}>
                  <img alt="attendance" src={CalendarAttendanceIcon} />
                </Link>
              ))}
          </>
        ) : (
          attendanceIcon && (
            <div className="attendance-icon">
              <img src={attendanceIcon} alt="Attendance Icon" />
            </div>
          )
        )}
      </div>
    </EventWrapperContainer>
  );
};

export {EventWrapper};
