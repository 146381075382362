/* eslint-disable react/prop-types */
import React from 'react';
import {useHistory} from 'react-router-dom';
import {HeaderContainer} from './elements';
import CloseIcon from '../../../../../../../../../../assets/icons/addadminclose.svg';

// const DELAY = 500;

export const Header = () => {
  const history = useHistory();
  const onButtonCancel = () => {
    history.replace(`/admin/classes`);
  };
  return (
    <HeaderContainer>
      <div className="header-container fixed-top">
        <div className="header">
          <div className="title">Add a class</div>
          <div>
            <button
              className="btn"
              onClick={onButtonCancel}
              type="button"
              id="close"
            >
              <img alt="Options" src={CloseIcon} />
            </button>
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};
