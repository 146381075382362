import {createSlice} from '@reduxjs/toolkit';

import * as adminLibraryApi from '../api/admin/adminLibrary';

const initialFormError = {fileCode: ''};

const adminLibrarySlice = createSlice({
  initialState: {
    error: null,
    adminLibrary: null,
    formError: initialFormError,
    publicLibraryFiles: null,
    orgUnitInstance: null,
    isAdminLibraryLoading: false,
    isListLoading: false,
    isFileUpload: 0,
    activeClasses: [],
    category: [],
    isValidationLoading: false,
    isCategoryLoading: false,
  },
  name: 'adminLibrary',
  reducers: {
    setAdminLibrary: (state, action) => {
      state.adminLibrary = action.payload;
    },
    setAdminPublicLibrary: (state, action) => {
      state.publicLibraryFiles = action.payload;
    },
    setOrgUnitInstance: (state, action) => {
      state.orgUnitInstance = action.payload;
    },
    setIsAdminLibrarysLoading: (state, action) => {
      state.isAdminLibraryLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    deleteAdminLibraryById: (state, action) => {
      state.adminLibrary = state.adminLibrary.filter(
        file => file.fi_id_file !== action.payload,
      );
    },
    deletePublicLibraryById: (state, action) => {
      state.publicLibraryFiles = state.publicLibraryFiles.filter(
        file => file.fi_id_file !== action.payload,
      );
    },

    setIsFileUpload: (state, action) => {
      state.isFileUpload = action.payload;
    },
    setActiveClasses: (state, action) => {
      state.activeClasses = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    // editAdminLibraryById: (state, action) => {
    //   const fileIndex = state.adminLibrary.findIndex(
    //     libraryFile => libraryFile.fi_id_file === action.payload.fi_id_file,
    //   );
    //   state.adminLibrary[fileIndex] = action.payload;
    // },
    setIsValidationLoading: (state, action) => {
      state.isValidationLoading = action.payload;
    },
    setIsCategoryLoading: (state, action) => {
      state.isCategoryLoading = action.payload;
    },
    appendNewCategory: (state, action) => {
      state.category = [action.payload, ...state.category];
    },
  },
});

export const {
  setAdminLibrary,
  setAdminPublicLibrary,
  setOrgUnitInstance,
  setIsAdminLibrarysLoading,
  setError,
  setIsListLoading,
  deleteAdminLibraryById,
  deletePublicLibraryById,
  setIsFileUpload,
  setActiveClasses,
  setCategory,
  setFormError,
  clearFormError,
  editAdminLibraryById,
  setIsValidationLoading,
  setIsCategoryLoading,
  appendNewCategory,
} = adminLibrarySlice.actions;

export const fetchAllLibraryFiles = unitId => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const [classLibraryResponse, publicLibraryResponse] = await Promise.all([
      adminLibraryApi.fetchAllLibraryFiles(unitId),
      adminLibraryApi.fetchPublicLibraryFiles(),
    ]);
    dispatch(setAdminLibrary(classLibraryResponse?.data?.libraryFiles || []));
    dispatch(
      setAdminPublicLibrary(
        publicLibraryResponse?.data?.publicLibraryFiles || [],
      ),
    );
    dispatch(
      setOrgUnitInstance(classLibraryResponse?.data?.orgUnitInstance || []),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const addLibraryDocument = (data, callBack) => async dispatch => {
  dispatch(setIsAdminLibrarysLoading(true));
  try {
    await adminLibraryApi.addLibraryDocument(data);
    if (callBack) {
      callBack();
    }
    const unitId = 'all-files';
    dispatch(fetchAllLibraryFiles(unitId));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAdminLibrarysLoading(false));
  }
};

export const deleteLibraryClassFile =
  (deleteId, deleteFileSection, callback) => async dispatch => {
    dispatch(setIsAdminLibrarysLoading(true));
    try {
      await adminLibraryApi.deleteLibraryClassFile(deleteId);
      if (deleteFileSection === 1) {
        dispatch(deleteAdminLibraryById(deleteId));
      } else {
        dispatch(deletePublicLibraryById(deleteId));
      }
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAdminLibrarysLoading(false));
    }
  };

export const fetchAllCategory = () => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const response = await adminLibraryApi.fetchAllCategory();
    dispatch(setCategory(response.data.allCategory));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const updateLibraryDocument =
  (orgUnitInstanceId, fileId, data, callBack) => async dispatch => {
    dispatch(setIsAdminLibrarysLoading(true));
    try {
      await adminLibraryApi.updateLibraryDocument(
        orgUnitInstanceId,
        fileId,
        data,
      );
      // dispatch(editAdminLibraryById(fileId));
      const unitId = 'all-files';
      dispatch(fetchAllLibraryFiles(unitId));
      dispatch(fetchAllCategory());
      if (callBack) {
        callBack();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAdminLibrarysLoading(false));
    }
  };

export const fetchActiveClasses = () => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const response = await adminLibraryApi.fetchActiveClasses();
    dispatch(setActiveClasses(response.data.allClasses));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const individualFileCodeValid = data => async dispatch => {
  dispatch(setIsValidationLoading(true));
  try {
    await adminLibraryApi.individualFileCodeValid({fileCode: data});
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
    dispatch(setFormError(error?.response?.data?.error));
  } finally {
    dispatch(setIsValidationLoading(false));
  }
};

export const individualFileCodeValidationInEdit =
  (data, fileId) => async dispatch => {
    dispatch(setIsValidationLoading(true));
    try {
      await adminLibraryApi.individualFileCodeValidationInEdit(
        {fileCode: data},
        fileId,
      );
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
      dispatch(setFormError(error?.response?.data?.error));
    } finally {
      dispatch(setIsValidationLoading(false));
    }
  };

export const addNewCategory = (data, callBack) => async dispatch => {
  dispatch(setIsCategoryLoading(true));
  try {
    const category = {newCategory: data};
    const resp = await adminLibraryApi.addNewCategory(category);
    dispatch(appendNewCategory(resp.data.addCategory));

    if (callBack) {
      callBack(resp.data.addCategory);
    }
  } catch (error) {
    callBack('error');
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsCategoryLoading(false));
  }
};

export const adminLibrarySelector = state => state.adminLibrary;

export default adminLibrarySlice.reducer;
