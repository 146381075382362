import styled from 'styled-components';

export const AddAssessmentFooterContainer = styled.div`
  .footer-container {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 3rem 1.5rem 3rem;
    display: flex;
    justify-content: space-between;
    @media (max-width: 41.25rem) {
      & {
        width: 100%;
        padding: 1.5rem;
      }
    }
  }
  .selected-students {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    p {
      margin-top: unset;
      font-size: 0.875rem;
      color: #363636;
    }
  }
  .manage-footer {
    .button-container {
      display: flex;
      flex-direction: row;
    }
    .button {
      padding: 0.875rem 1.25rem;
      &.is-archive {
        display: none !important;
      }
      &.cancel-button {
        background-color: white;
        border: 0.0625rem solid ${({theme}) => theme.colors.darkBorder};

        :active,
        :focus {
          border-color: ${({theme}) => theme.colors.primary};
        }

        .label {
          color: ${({theme}) => theme.colors.text};
        }
      }
    }
  }
`;
