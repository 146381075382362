/* eslint-disable react/prop-types */
import React, {useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Controller} from 'react-hook-form';

import {
  Select,
  TextInput,
  // DateInput,
} from '../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import {TextEditor} from '../../../../../../../../components/common/TextEditor';
import {BasicDetailContainer} from './elements';

import {activeClassSelector} from '../../../../../../../../redux/activeClassSlice';

export const BasicDetails = ({
  setValue,
  control,
  getValues,
  errors,
  isPublished,
  clearErrors,
}) => {
  const {orgUnits, assessmentType, createdAssessment} =
    useSelector(activeClassSelector);

  const dispatch = useDispatch();

  const setAdditionalInformations = additionalInformationValue => {
    setValue('additional-information', additionalInformationValue);
  };
  const setStudentInstructions = studentInstructionValue => {
    setValue('student-instructions', studentInstructionValue);
  };

  useEffect(() => {
    if (createdAssessment) {
      // Setting default value for assessments basic details input
      setValue('assessTitle', createdAssessment.ac_activity_name);
      setValue(
        'description',
        createdAssessment.ac_activity_description !== null
          ? createdAssessment.ac_activity_description
          : '',
      );

      setValue(
        'resourcerequired',
        createdAssessment.ac_resource_required !== null
          ? createdAssessment.ac_resource_required
          : getValues('resourcerequired'),
      );

      setValue(
        'student-instructions',
        createdAssessment.ac_student_instructions !== ''
          ? createdAssessment.ac_student_instructions
          : getValues('student-instructions'),
      );
      setValue(
        'additional-information',
        createdAssessment.ac_additional_information !== null
          ? createdAssessment.ac_additional_information
          : getValues('additional-information'),
      );
      setValue('assessType', {
        value: createdAssessment.ac_id_activitymethod,
        label:
          createdAssessment?.ac_id_activitymethod === 1
            ? 'Practical'
            : 'Written Assessment',
      });

      setValue('unit', {
        value: createdAssessment?.ou_id_orgunit,
        label: createdAssessment?.tun_title,
        tgovId: createdAssessment?.tun_id_tgovunit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdAssessment, dispatch, getValues, isPublished, setValue]);

  // assessment map for add assessment
  const assessmentTypeOptions = useMemo(
    () =>
      assessmentType?.map(
        item =>
          ({
            value: item.am_id_activitymethod,
            label: item.am_activitymethod,
          } || []),
      ),
    [assessmentType],
  );

  // unit map for add assessment
  const orgUnitsOptions = useMemo(
    () =>
      orgUnits?.map(
        item =>
          ({
            value: item.ou_id_orgunit,
            label: item.tun_title,
            tgovId: item.tun_id_tgovunit,
          } || []),
      ),
    [orgUnits],
  );

  return (
    <BasicDetailContainer>
      <div className="basic-details">
        <div className="basic-left">
          <Controller
            control={control}
            name="assessTitle"
            render={({onChange, ...fields}) => (
              <TextInput
                {...fields}
                className="mb-4"
                type="text"
                id="assessTitle"
                label="Assessment Title"
                errorMessage={errors?.assessTitle?.message}
                hasError={errors?.assessTitle?.message}
                placeholder="Enter the Title"
                onChange={e => {
                  onChange(e);
                  if (e.target.value) {
                    clearErrors('assessTitle');
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="unit"
            render={fields => (
              <Select
                {...fields}
                className="mb-4"
                hasError={!!errors.unit}
                errorMessage={errors.unit?.message}
                isLarge={false}
                label="Unit"
                isDisabled
                options={orgUnitsOptions}
                placeholder="Choose a unit"
                onChange={e => {
                  fields.onChange(e);
                }}
                id="unit-name"
              />
            )}
          />
          <Controller
            control={control}
            name="assessType"
            render={fields => (
              <Select
                {...fields}
                className="mb-4"
                hasError={!!errors.assessType}
                errorMessage={errors.assessType?.message}
                isLarge={false}
                label="Assessment Type"
                options={assessmentTypeOptions}
                placeholder="Select the Assessment Type"
                onChange={e => {
                  fields.onChange(e);
                }}
                id="assessment-type"
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            render={({onChange, ...fields}) => (
              <TextArea
                {...fields}
                className="mr-4 mt-4 mb-4"
                label="Description"
                rows="5"
                type="text"
                id="description"
                errorMessage={errors?.description?.message}
                hasError={errors?.description?.message}
                placeholder="Enter description"
                height="159px"
                onChange={e => {
                  onChange(e);
                  if (e.target.value) {
                    clearErrors('description');
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="resourcerequired"
            render={({onChange, ...fields}) => (
              <TextArea
                {...fields}
                className="mr-4 mt-4 mb-4"
                label="Resources Required"
                rows="5"
                type="text"
                id="resourceRequired"
                placeholder="Enter the required resources"
                height="159px"
                onChange={e => {
                  onChange(e);
                }}
              />
            )}
          />
        </div>
        <div className="basic-right">
          <Controller
            control={control}
            name="student-instructions"
            render={({onChange, ...fields}) => (
              <TextEditor
                {...fields}
                label="Student Instructions"
                onChange={e => {
                  onChange(e);
                  setStudentInstructions(e);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="additional-information"
            render={({onChange, ...fields}) => (
              <TextEditor
                {...fields}
                label="Additional Information"
                className="mt-4"
                onChange={e => {
                  onChange(e);
                  setAdditionalInformations(e);
                }}
              />
            )}
          />
        </div>
      </div>
    </BasicDetailContainer>
  );
};
