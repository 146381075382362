import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -15px;
  min-height: 100vh;
  padding-top: 7.87rem;
  padding-bottom: 2.87rem;
  background-color: #f4f5f8;
  .container-academic {
    padding: 0 0.937rem;
    flex-shrink: 0;
    width: 84rem;
    @media (max-width: 84rem) {
      & {
        width: 100%;
      }
    }
  }
  .loader-images {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;
  }
  .button-spinner1 {
    width: 2.5rem;
    height: 2.5rem;
    animation: rotating 1s linear infinite;
  }
  .title {
    color: #3d4457;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.3;
  }
`;

export const EditQualificationModalStyles = styled.div`
  width: 660px;
  margin: 0 15px;
  @media (max-width: 660px) {
    & {
      width: 94%;
    }

    .closeIconBtn {
      display: none;
    }
    .close-responsive {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 18px;
    }
    .closeIconBtn1 {
      display: block;
      border: none;
      background: transparent;
      outline: none;
    }
    .closeModalBtn {
      display: none;
    }

    .add-academic-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .date-wrapper1 {
        margin-right: 0 !important;
        text-align: left;
      }
      .date-wrapper2 {
        margin-left: 0 !important;
        text-align: left;
        margin-top: 10px;
      }
      p {
        color: #aaaaaa;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .close-responsive {
    display: none;
  }
  .closeIconBtn1 {
    display: none;
  }
  .add-academic-modal {
    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }

    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .header-modal {
    text-align: left;
  }
  .modal-seperator {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
  }
  .add-academic-modal-header {
    display: flex;
    justify-content: space-between;
    p {
      color: #aaaaaa;
    }
  }
  .add-academic-modal-body {
    display: flex;
    justify-content: space-between;
    .date-wrapper1 {
      flex-grow: 1;
      text-align: left;
      margin-right: 8px;
    }
    .date-wrapper2 {
      flex-grow: 1;
      text-align: left;
      margin-left: 8px;
    }
    p {
      color: #aaaaaa;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .button-container {
    .save-changes {
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      .button-spinner {
        margin-right: 8px;
        animation: rotating 1s linear infinite;
      }
    }
  }
`;

export const DeleteQualificationModalStyles = styled.div`
  width: 660px;
  margin: 0 15px;
  @media (max-width: 660px) {
    & {
      width: 94%;
    }
    .closeIconBtn {
      display: none;
    }
    .close-responsive {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 18px;
    }
    .closeIconBtn1 {
      display: block;
      border: none;
      background: transparent;
      outline: none;
    }
    .closeModalBtn {
      display: none;
    }

    .add-academic-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .date-wrapper1 {
        margin: unset;
        text-align: left;
      }
      .date-wrapper2 {
        margin: unset;
        text-align: left;
      }
      p {
        color: #aaaaaa;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .close-responsive {
    display: none;
  }
  .closeIconBtn1 {
    display: none;
  }

  .delete-department-modal {
    border-radius: 12px;
    .delete-department-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      .closeModalBtn {
        outline: none;
        background: unset;
        border: none;
      }
      .modal-header-left {
        display: flex;
        p {
          color: #aaaaaa;
        }
      }
      .delete-department-modal-title {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .footer-delete-break {
      top: 40px;
      height: 1px;
      background-color: #e0e0e0;
      width: 100%;
    }
    .delete-department-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -15px;
  min-height: 100vh;
  padding-top: 126px;
  padding-bottom: 46px;
`;
