/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import ArrowHideImg from '../../../../../../../../../../assets/icons/tablehide.svg';
import GreenTickImg from '../../../../../../../../../../assets/icons/donemark.svg';
import ArrowShowImg from '../../../../../../../../../../assets/icons/tableshow.svg';
import {Table} from '../../../../../../../../../../components/common';
import {AccordionContainer, SpreadSheetTableStyles} from './elements';

export const AccordionForUnits = ({
  title,
  subtitle,
  alreadyExistUcodeData,
  spreadsheetcolumns,
  alreadyExistUcode,
  duplicateUnitDataError,
}) => {
  const [isUcodeExpanded, setIsUcodeExpanded] = useState(false);
  const [isDuplicateExpanded, setIsDuplicateExpanded] = useState(false);

  const toggleQualificationCode = () => setIsUcodeExpanded(!isUcodeExpanded);

  const toggleDuplicateData = () =>
    setIsDuplicateExpanded(!isDuplicateExpanded);

  return (
    <AccordionContainer className="mt-4 mb-4">
      <img src={GreenTickImg} alt="green tick" />
      <h2 className="mt-3 header">{title}</h2>
      <p className="state">{subtitle}</p>
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleQualificationCode}
              >
                <div>unit code error ({alreadyExistUcode.length})</div>
                <div>
                  <img
                    alt="Show Works"
                    src={isUcodeExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className={`collapse ${isUcodeExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {alreadyExistUcode && alreadyExistUcode?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={alreadyExistUcodeData}
                    itemName="Units"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleDuplicateData}
              >
                <div>
                  Invalid and Duplicate Entries ({duplicateUnitDataError.length}
                  )
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={isDuplicateExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className={`collapse ${isDuplicateExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {duplicateUnitDataError && duplicateUnitDataError?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={duplicateUnitDataError}
                    itemName="Units"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </AccordionContainer>
  );
};
