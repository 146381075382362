import styled from 'styled-components';

const CoreAndElectiveComponentsFormContainer = styled.div`
  table {
    width: 222.3125rem !important;
  }
  [data-label='Unit Code'],
  .unit-code {
    background-color: #fff;
    left: 50px !important;
  }

  [data-label='Unit Name'],
  .unit-name {
    background-color: #fff;
    left: 170px !important;
  }

  [data-label='#'],
  .serial {
    background-color: #fff;
    left: 10px !important;
    width: 40px;
  }

  [data-sticky-last-left-td] {
    box-shadow: 2px 0px 3px #ccc;
  }

  [data-sticky-first-right-td] {
    box-shadow: -2px 0px 3px #ccc;
  }

  .serial {
    min-width: 2.125rem;
  }

  .unit-code {
    min-width: 9.1875rem;
  }

  .unit-name {
    min-width: 19.125rem;
  }

  .type {
    min-width: 8.375rem;
  }

  .semester {
    min-width: 13rem;
  }

  .cat {
    min-width: 16.75rem;
  }

  .nominal-hours,
  .face-hours,
  .non-face-hours,
  .assessment-hours,
  .maximum-payable-hours,
  .minimum-payable-hours {
    min-width: 14.25rem;
  }
  .delivery-hours {
    min-width: 13.25rem;
  }

  .prerequisite {
    min-width: 17.25rem;
  }

  .lessons {
    min-width: 12.75rem;
  }

  .unit-type {
    background-color: #defae8;
    border-radius: 0.3125rem;
    color: #4ec977;
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;

    &.is-core {
      background-color: ${({theme}) => theme.colors.lighterPrimary};
      color: ${({theme}) => theme.colors.primaryAlt};
    }
  }

  .delete {
    background-color: transparent;
    border: none;
    display: flex;
    height: 1.5rem;
    width: 1.5rem;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .add-unit-section {
    border-bottom: 0.0625rem solid ${({theme}) => theme.colors.border};
    padding: 1rem 0;
    width: 168.3125rem;

    button {
      align-items: center;
      background-color: transparent;
      border: none;
      color: ${({theme}) => theme.colors.primaryAlt};
      display: flex;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 0.875rem;
      padding: 0.6875rem 1.25rem;

      img {
        height: 1.25rem;
        margin-right: 0.5rem;
        object-fit: contain;
        width: 1.25rem;
      }
    }
  }

  .total-section {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 18.75rem 1.5rem 0;
    /* width: 168.3125rem; */
    width: 220.8125rem;

    .section-label {
      color: #aaa;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1rem;
      margin-right: 1.5rem;
      text-transform: uppercase;
    }

    .section-value {
      background-color: #f6f8fa;
      border-radius: 0.5rem;
      color: ${({theme}) => theme.colors.text};
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.125rem;
      padding: 0.75rem 1rem;
      width: 16.75rem;

      :not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`;

export default CoreAndElectiveComponentsFormContainer;
