import styled from 'styled-components';

import {Modal} from '../../../../../../../../../components/common';

const TasSubmissionModalContainer = styled(Modal)`
  .submit-modal-fields {
    padding: 0rem 1.6rem;
  }
  .row-container {
    display: flex;
    padding-top: 2.6rem;
    padding-bottom: 3rem;
    justify-content: space-between;
    align-items: center;
  }
  .field-left {
    width: 44%;
  }
  .or {
    width: 10%;
    text-align: center;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
  }
  .field-right {
    width: 46%;
  }
  .suggestion {
    position: absolute;
    background: #ffffff;
    border: 1px solid #dedede;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    width: 18.375rem;
    overflow-x: hidden;
    /* overflow-y: auto; */
    max-height: 250px !important;
    tr {
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;
      :hover {
        tr {
          background-color: #ecf6ff;
        }
      }
      .addPlusBtn {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: none;
        background: transparent;
        outline: none;
        .add-btn {
          display: none;
        }
      }
    }
    .add-assessment-button {
      width: 98%;
      margin: -7px 7px 7px 7px;
      border-radius: 5px;
    }
    .error-dept {
      width: 98%;
      margin: -7px 7px -8px 7px;
      border-radius: 5px;
      p {
        color: #eb5757;
        padding: 12px 24px;
      }
    }
    .department-table {
      width: 98%;
      margin: 7px;
      tr {
        border: none;

        :hover {
          background-color: #ecf6ff;
          .add-btn {
            display: block !important;
          }
        }
      }
    }
  }
  .user-selected-div {
    max-height: 230px !important;
    overflow-y: auto;
    margin-bottom: 15px;
  }
  .added-users-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 12px;
    color: #3d4457;
    border-radius: 4px;
    :hover {
      background-color: #ecf6ff;
      .user-cancel {
        display: block;
      }
    }
    .user-cancel {
      border: none;
      background: transparent;
      outline: none;
      display: none;
    }
  }
  .user-counts-added {
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #aaaaaa;
    margin-top: 1rem;
  }
  .div-line {
    height: 1px;
    background-color: #e0e0e0;
    width: 100%;
    margin-bottom: 15px;
  }
`;

export default TasSubmissionModalContainer;
