import {createSlice} from '@reduxjs/toolkit';
import {
  getClassDetails,
  inviteSingleStudent,
  inviteStudentBulk,
  deactivateClassStudent,
  updateStudent,
  deactivateClassUnitInstance,
  addClassUnitInstance,
  getUnitDetails,
  addStudentsToUnit as _addStudentsToUnit,
  deleteStudentFromUnit,
  updateClassUnitInstance,
  getElementsByUnit,
  getAssessmentMap,
  getPerformanceEvidence,
  getKnowledgeEvidence,
  getFoundationSkills,
  getAssessmentCondition,
} from '../../api/classes';
import {
  getOrgUnits,
  getOrgAcademicTerms,
  getOrgLocationRooms,
} from '../../api/Organisation';
import {
  createPost as crtPost,
  getPost,
  deleteStreamPost,
  getPostsForStudent,
  commentOnActivity,
  deleteComment,
  getComments,
  updatePost as updateActivity,
  removeFilesFromPost,
  addFilesToPost,
  updateStudentsOfPost,
} from '../../api/stream/post';

export const streamSlice = createSlice({
  name: 'stream',
  initialState: {
    isLoading: true,
    isLoadingOrgUnits: false,
    isLoadingLocationRoom: false,
    isLoadingTerm: false,
    isLoadingUnitDetails: false,
    unitDetails: null,
    unitDetailsError: '',
    classDetails: {},
    addStudentError: '',
    addUnitError: '',
    isDeleting: false,
    isAdding: false,
    classStudents: [],
    classUnits: [],
    orgUnits: [],
    academicTerms: [],
    locationRooms: [],
    streamLive: {
      isLoadingPost: false,
      isPostError: false,
      isSharePostInputOpen: false,
      selectedStudents: [],
      studentSelectDropDownTitle: 'All Students',
      streamPostSubmitting: false,
      validationMessage: '',
      posts: [],
    },
    streamCount: 0,
    isPaginatingPosts: false,
    elements: [],
    assessmentMap: [],
    unitOfAssessmentMap: null,
    isLoadingAssessmentMap: true,
    performanceEvidence: null,
    knowledgeEvidence: null,
    foundationSkills: null,
    assessmentCondition: null,
  },
  reducers: {
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoadingRoomsStatus: (state, action) => {
      state.isLoadingLocationRoom = action.payload;
    },
    setLoadingTermStatus: (state, action) => {
      state.isLoadingTerm = action.payload;
    },
    setLoadingOrgUnits: (state, action) => {
      state.isLoadingOrgUnits = action.payload;
    },
    setLoadingUnitDetailsStatus: (state, action) => {
      state.isLoadingUnitDetails = action.payload;
    },
    setUnitDetails: (state, action) => {
      state.unitDetails = action.payload;
    },
    setUnitDetailsError: (state, action) => {
      state.unitDetailsError = action.payload;
    },
    setClassDetails: (state, action) => {
      state.classDetails = action.payload;
    },
    setClassStudents: (state, action) => {
      state.classStudents = action.payload;
    },
    setOrgUnits: (state, action) => {
      state.orgUnits = action.payload;
    },
    setClassUnits: (state, action) => {
      state.classUnits = action.payload;
    },
    setAcademicTerms: (state, action) => {
      state.academicTerms = action.payload;
    },
    setLocationRooms: (state, action) => {
      state.locationRooms = action.payload;
    },
    setAddStudentError: (state, action) => {
      state.addStudentError = action.payload;
    },
    setAddUnitError: (state, action) => {
      state.addUnitError = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    setIsAdding: (state, action) => {
      state.isAdding = action.payload;
    },
    // streamPost:
    setSharePostInputOpenState: (state, action) => {
      state.streamLive.isSharePostInputOpen = action.payload;
    },
    setSelectedStudents: (state, action) => {
      state.streamLive.selectedStudents = action.payload;
    },

    setStudentDropdownTitle: (state, action) => {
      state.streamLive.studentSelectDropDownTitle = action.payload;
    },
    streamPostSetSubmitting: (state, action) => {
      state.streamLive.streamPostSubmitting = action.payload;
    },
    streamPostSetValidationMessage: (state, action) => {
      state.streamLive.validationMessage = action.payload;
    },
    setPostLoadingStatus: (state, action) => {
      state.streamLive.isLoadingPost = action.payload;
    },
    setErrorGettingPostStatus: (state, action) => {
      state.streamLive.isPostError = action.payload;
    },
    setPost: (state, action) => {
      state.streamLive.posts = action.payload;
    },
    setNextPosts: (state, action) => {
      state.streamLive.posts = [...state.streamLive.posts, ...action.payload];
    },
    setComment: (state, action) => {
      const post = state.streamLive.posts.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      post.comments = [action.payload.comment, ...(post?.comments || [])];
    },
    deleteCommentById: (state, action) => {
      const post = state.streamLive.posts.find(
        item => item.ac_id_activity === action.payload.activityId,
      );

      post.comments = post.comments.filter(
        item =>
          item.acc_id_activity_comment !== Number(action.payload.commentId),
      );
    },
    setComments: (state, action) => {
      const post = state.streamLive.posts.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      post.comments = [...(post?.comments || []), ...action.payload.comments];
    },
    setIsPaginatingPosts: (state, action) => {
      state.isPaginatingPosts = action.payload;
    },
    setStreamCount: (state, action) => {
      state.streamCount = action.payload;
    },
    deleteAssessmentFromPosts: (state, action) => {
      state.streamLive.posts = state.streamLive.posts.filter(
        assessment =>
          assessment.aoui_id_activityorgunitinstance !== action.payload,
      );
    },
    setElements: (state, action) => {
      state.elements = action.payload;
    },
    setAssessmentMap: (state, action) => {
      state.assessmentMap = action.payload;
    },
    clearAssessmentMap: state => {
      state.assessmentMap = [];
      state.isLoadingAssessmentMap = true;
      state.unitOfAssessmentMap = null;
    },
    setUnitOfAssessmentMap: (state, action) => {
      state.unitOfAssessmentMap = action.payload;
    },
    setIsLoadingAssessmentMap: (state, action) => {
      state.isLoadingAssessmentMap = action.payload;
    },
    setPerformanceEvidence: (state, action) => {
      state.performanceEvidence = action.payload;
    },
    setKnowledgeEvidence: (state, action) => {
      state.knowledgeEvidence = action.payload;
    },
    setFoundationSkills: (state, action) => {
      state.foundationSkills = action.payload;
    },
    setAssessmentCondition: (state, action) => {
      state.assessmentCondition = action.payload;
    },
  },
});

export const {
  setLoadingStatus,
  setClassDetails,
  setClassStudents,
  setOrgUnits,
  setAcademicTerms,
  setLocationRooms,
  setLoadingRoomsStatus,
  setLoadingTermStatus,
  setLoadingOrgUnits,
  setLoadingUnitDetailsStatus,
  setUnitDetails,
  setUnitDetailsError,
  setClassUnits,
  setAddStudentError,
  setAddUnitError,
  setIsDeleting,
  setIsAdding,
  setSharePostInputOpenState,
  setSelectedStudents,
  setSelectedUnits,
  setStudentDropdownTitle,
  streamPostSetSubmitting,
  streamPostSetValidationMessage,
  setPost,
  setNextPosts,
  setPostLoadingStatus,
  setErrorGettingPostStatus,
  setComment,
  deleteCommentById,
  setComments,
  setIsPaginatingPosts,
  setStreamCount,
  deleteAssessmentFromPosts,
  setElements,
  setAssessmentMap,
  clearAssessmentMap,
  setUnitOfAssessmentMap,
  setIsLoadingAssessmentMap,
  setPerformanceEvidence,
  setKnowledgeEvidence,
  setFoundationSkills,
  setAssessmentCondition,
} = streamSlice.actions;

export const fetchClassDetails = (id, cb) => async dispatch => {
  dispatch(setLoadingStatus(true));
  try {
    const resp = await getClassDetails(id);
    dispatch(setLoadingStatus(false));
    dispatch(setClassDetails(resp.data.unitDetails));
    dispatch(
      setClassStudents(
        resp.data.unitDetails.students.map(student => student.profile),
      ),
    );
    // dispatch(setClassUnits(resp.data.classDetails.units));
    if (cb) cb(resp.data.classDetails?.oay_id_orgacadyear);
  } catch (err) {
    dispatch(setLoadingStatus(false));
  }
};

export const fetchOrgUnits = () => async dispatch => {
  dispatch(setLoadingOrgUnits(true));
  try {
    const resp = await getOrgUnits();
    dispatch(setLoadingOrgUnits(false));
    dispatch(setOrgUnits(resp.data.orgUnits));
  } catch (err) {
    dispatch(setLoadingOrgUnits(false));
  }
};

export const fetchAcademicTerms = yearId => async dispatch => {
  dispatch(setLoadingTermStatus(true));
  try {
    const resp = await getOrgAcademicTerms(yearId);
    dispatch(setLoadingTermStatus(false));
    dispatch(setAcademicTerms(resp.data.academicTerms));
  } catch (err) {
    dispatch(setLoadingTermStatus(false));
  }
};

export const fetchLocationRooms = campusId => async dispatch => {
  dispatch(setLoadingRoomsStatus(true));
  try {
    const resp = await getOrgLocationRooms(campusId);
    dispatch(setLoadingRoomsStatus(false));
    dispatch(setLocationRooms(resp.data.locationRooms));
  } catch (err) {
    dispatch(setLoadingRoomsStatus(false));
  }
};

export const fetchUnitDetails = unitID => async dispatch => {
  try {
    dispatch(setLoadingUnitDetailsStatus(true));
    const {unitDetails} = (await getUnitDetails(unitID)).data;
    dispatch(setUnitDetails(unitDetails));
  } catch (error) {
    dispatch(setUnitDetailsError(error.response.data.error));
  } finally {
    dispatch(setLoadingUnitDetailsStatus(false));
  }
};

export const addStudentsToUnit =
  (orgUnitInstanceId, userProfileIdList, unitId, callback) =>
  async dispatch => {
    try {
      await _addStudentsToUnit(orgUnitInstanceId, userProfileIdList);
      const {unitDetails} = (await getUnitDetails(unitId)).data;
      dispatch(setUnitDetails(unitDetails));
    } catch (error) {
      dispatch(setUnitDetailsError(error.response.data.error));
    } finally {
      if (callback) {
        callback();
      }
    }
  };

export const removeStudentFromUnit =
  (unitInstanceID, unitProfileID, unitID) => async dispatch => {
    try {
      await deleteStudentFromUnit(unitInstanceID, unitProfileID);
      const {unitDetails} = (await getUnitDetails(unitID)).data;
      dispatch(setUnitDetails(unitDetails));
    } catch (error) {
      dispatch(setUnitDetailsError(error.response.data.error));
    }
  };

export const removeUnit =
  (unitInstanceId, classUnits, cb) => async dispatch => {
    dispatch(setIsDeleting(true));
    try {
      await deactivateClassUnitInstance(unitInstanceId);
      const updatedClassUnits = [...classUnits];
      const deleteIndex = updatedClassUnits.findIndex(
        unit => unit.orui_id_orgunitinstance === unitInstanceId,
      );
      updatedClassUnits.splice(deleteIndex, 1);
      dispatch(setIsDeleting(false));
      dispatch(setClassUnits(updatedClassUnits));
      if (cb) cb(null);
    } catch (err) {
      if (err.response) {
        dispatch(setIsDeleting(false));
      }
    }
  };

export const addUnit = (details, cb) => async (dispatch, getState) => {
  dispatch(setIsAdding(true));
  dispatch(setAddUnitError(''));
  try {
    const {stream} = getState();
    const unitDetails = {
      ...details,
      orglocationcampus: stream.classDetails.oc_id_orglocationcampus,
    };
    const resp = await addClassUnitInstance(
      stream.classDetails.oc_id_orgclass,
      unitDetails,
    );
    const updatedClassUnits = [resp.data.newClassUnit, ...stream.classUnits];
    dispatch(setIsAdding(false));
    dispatch(setClassUnits(updatedClassUnits));
    if (cb) cb(null);
  } catch (err) {
    if (err.response) {
      dispatch(setIsAdding(false));
      dispatch(setAddUnitError(err.response.data.error));
    }
  }
};

export const editUnit =
  (unitInstanceId, unitDetails, cb) => async (dispatch, getState) => {
    dispatch(setIsAdding(true));
    try {
      const {stream} = getState();
      const {data} = await updateClassUnitInstance(unitInstanceId, unitDetails);
      const updatedClassUnits = stream.classUnits.map(unit =>
        unit.orui_id_orgunitinstance === unitInstanceId
          ? data.updatedUnit
          : unit,
      );
      dispatch(setIsAdding(false));
      dispatch(setClassUnits(updatedClassUnits));
      if (cb) cb(null);
    } catch (err) {
      if (err.response) {
        dispatch(setIsAdding(false));
      }
    }
  };

export const addStudent =
  (classId, orgUnitInstanceId, studentDetails, classStudents, cb) =>
  async dispatch => {
    dispatch(setLoadingStatus(true));
    dispatch(setAddStudentError(''));

    try {
      const resp = await inviteSingleStudent(
        classId,
        orgUnitInstanceId,
        studentDetails,
      );
      const updatedClassStudents = [...classStudents];
      updatedClassStudents.splice(0, 0, resp.data.newStudent);
      dispatch(setLoadingStatus(false));
      dispatch(setClassStudents(updatedClassStudents));
      if (cb) cb(null);
    } catch (err) {
      if (err.response) {
        dispatch(setAddStudentError(err.response.data.error));
        dispatch(setLoadingStatus(false));
      }
    }
  };

export const editStudent =
  (profileId, studentDetails, classStudents, cb) => async dispatch => {
    dispatch(setLoadingStatus(true));
    dispatch(setAddStudentError(''));

    try {
      const resp = await updateStudent(profileId, studentDetails);
      const updatedClassStudents = [...classStudents];
      const index = updatedClassStudents.findIndex(
        student => student.up_id_userprofile === profileId,
      );
      updatedClassStudents.splice(index, 1, resp.data.updatedStudent);
      dispatch(setLoadingStatus(false));
      dispatch(setClassStudents(updatedClassStudents));
      if (cb) cb(null);
    } catch (err) {
      if (err.response) {
        dispatch(setAddStudentError(err.response.data.error));
        dispatch(setLoadingStatus(false));
      }
    }
  };

export const addStudentBulk =
  (classId, orgUnitInstanceId, formData, classStudents, cb) =>
  async dispatch => {
    dispatch(setLoadingStatus(true));

    try {
      const resp = await inviteStudentBulk(
        classId,
        orgUnitInstanceId,
        formData,
      );
      const updatedClassStudents = [
        ...classStudents,
        ...resp.data.studentsAdded,
      ];
      dispatch(setLoadingStatus(false));
      dispatch(setClassStudents(updatedClassStudents));
      if (cb) cb(resp.data);
    } catch (err) {
      if (err.response) {
        dispatch(setLoadingStatus(false));
      }
    }
  };

export const deleteStudent =
  (profileId, classStudents, cb) => async dispatch => {
    dispatch(setIsDeleting(true));
    try {
      await deactivateClassStudent(profileId);
      const updatedClassStudents = [...classStudents];
      const deleteIndex = updatedClassStudents.findIndex(
        student => student.up_id_userprofile === profileId,
      );
      updatedClassStudents.splice(deleteIndex, 1);
      dispatch(setIsDeleting(false));
      dispatch(setClassStudents(updatedClassStudents));
      if (cb) cb(null);
    } catch (err) {
      if (err.response) {
        dispatch(setIsDeleting(false));
      }
    }
  };

// streamLive
export const gettingPost =
  (classId, type = 3, offset) =>
  async dispatch => {
    if (offset) {
      dispatch(setIsPaginatingPosts(true));
    } else {
      dispatch(setPostLoadingStatus(true));
    }
    dispatch(setErrorGettingPostStatus(false));
    try {
      let resp;

      if (type === 3) {
        resp = await getPost(classId, offset || 0);
      } else if (type === 2) {
        resp = await getPostsForStudent(classId, offset || 0);
      }

      dispatch(setErrorGettingPostStatus(false));

      if (offset) {
        dispatch(setNextPosts(resp.data.streams));
      } else {
        dispatch(setPost(resp.data.streams));
      }

      dispatch(setStreamCount(resp.data.streamCount));

      if (offset) {
        dispatch(setIsPaginatingPosts(false));
      } else {
        dispatch(setPostLoadingStatus(false));
      }
    } catch (err) {
      if (offset) {
        dispatch(setIsPaginatingPosts(false));
      } else {
        dispatch(setPostLoadingStatus(false));
      }

      dispatch(setErrorGettingPostStatus(err));
    }
  };

export const createPost = (classId, data, userType, cb) => async dispatch => {
  dispatch(streamPostSetSubmitting(true));
  try {
    await crtPost(classId, data);
    dispatch(streamPostSetSubmitting(false));
    dispatch(streamPostSetValidationMessage(''));
    // close box .
    dispatch(setSharePostInputOpenState(false));
    // Getting post when submitting
    dispatch(gettingPost(classId, userType));
    if (cb) cb(null);
  } catch (err) {
    dispatch(streamPostSetSubmitting(false));
    dispatch(streamPostSetValidationMessage('failed.'));
  }
};

export const updatePost =
  (
    classID,
    activityID,
    data,
    fileIDs,
    files,
    oruiID,
    aouiID,
    students,
    userType,
    callback,
  ) =>
  async dispatch => {
    dispatch(streamPostSetSubmitting(true));
    try {
      if (data) {
        await updateActivity(activityID, data);
      }

      if (fileIDs) {
        await removeFilesFromPost({
          fileIDs,
        });
      }

      if (files) {
        await addFilesToPost(activityID, files);
      }

      if (students) {
        await updateStudentsOfPost(aouiID, {
          oruiID,
          students,
        });
      }

      dispatch(streamPostSetSubmitting(false));
      dispatch(streamPostSetValidationMessage(''));
      dispatch(setSharePostInputOpenState(false));
      dispatch(gettingPost(classID, userType));

      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(streamPostSetSubmitting(false));
      dispatch(streamPostSetValidationMessage('Failed.'));
    }
  };

export const deletePost = (unitInstanceId, posts, cb) => async dispatch => {
  dispatch(setIsDeleting(true));
  try {
    await deleteStreamPost(unitInstanceId);
    const updatedPosts = [...posts];
    const deleteIndex = updatedPosts.findIndex(
      post => post.aoui_id_activityorgunitinstance === unitInstanceId,
    );
    updatedPosts.splice(deleteIndex, 1);
    dispatch(setIsDeleting(false));
    dispatch(setPost(updatedPosts));
    if (cb) cb(null);
  } catch (err) {
    if (err.response) {
      dispatch(setIsDeleting(false));
    }
  }
};

export const commentOnPost =
  (activityId, comment, callback) => async dispatch => {
    const {data} = await commentOnActivity(activityId, comment);
    dispatch(
      setComment({
        activityId,
        comment: data.comment,
      }),
    );

    if (callback) {
      callback();
    }
  };
export const commentDelete =
  (commentId, activityId, callback) => async dispatch => {
    const {data} = await deleteComment(commentId);
    dispatch(deleteCommentById({commentId: data.commentId, activityId}));
    if (callback) {
      callback();
    }
  };

export const getMoreComments = (activityId, offset) => async dispatch => {
  const {data} = await getComments(activityId, offset);
  dispatch(
    setComments({
      activityId,
      comments: data.comments,
    }),
  );
};

/**
 * Fetch all elements inside of a unit, along with their performance criteria.
 */
export const fetchElementsByUnit = orgUnitInstanceId => async dispatch => {
  // Get the unit's ID using the unit instance's ID in the organization.
  const {
    data: {
      unitDetails: {
        orgUnit: {
          unit: {un_id_unit: unitId},
        },
      },
    },
  } = await getUnitDetails(orgUnitInstanceId);
  // Get the elements using the unit's ID.
  const {
    data: {elements},
  } = await getElementsByUnit(unitId);
  dispatch(setElements(elements));
};

/** Fetch the assessment map of a unit. */
export const fetchAssessmentMap = orgUnitInstanceId => async dispatch => {
  try {
    dispatch(setIsLoadingAssessmentMap(true));
    // Get the unit's ID using the unit instance's ID in the organization.
    const {
      data: {
        unitDetails: {
          orgUnit: {
            unit,
            unit: {tun_id_tgovunit: unitId},
          },
        },
      },
    } = await getUnitDetails(orgUnitInstanceId);
    dispatch(setUnitOfAssessmentMap(unit));
    // Get the assessment map using the unit's ID.
    const {
      data: {assessmentMap},
    } = await getAssessmentMap(unitId);
    dispatch(setAssessmentMap(assessmentMap));
  } finally {
    dispatch(setIsLoadingAssessmentMap(false));
  }
};

/** Fetch the performance evidence of a unit. */
export const fetchPerformanceEvidence = orgUnitInstanceId => async dispatch => {
  try {
    dispatch(setIsLoadingAssessmentMap(true));
    const resp = await getPerformanceEvidence(orgUnitInstanceId);
    dispatch(setPerformanceEvidence(resp.data.performanceEvidence));
  } finally {
    dispatch(setIsLoadingAssessmentMap(false));
  }
};

/** Fetch the knowledge evidence of a unit. */
export const fetchKnowledgeEvidence = orgUnitInstanceId => async dispatch => {
  try {
    const resp = await getKnowledgeEvidence(orgUnitInstanceId);
    dispatch(setKnowledgeEvidence(resp.data.knowledgeEvidence));
  } finally {
    dispatch(setIsLoadingAssessmentMap(false));
  }
};

/** Fetch the knowledge evidence of a unit. */
export const fetchFoundationSkills = orgUnitInstanceId => async dispatch => {
  try {
    const resp = await getFoundationSkills(orgUnitInstanceId);
    dispatch(setFoundationSkills(resp.data.foundationSkills));
  } finally {
    dispatch(setIsLoadingAssessmentMap(false));
  }
};
/** Fetch the knowledge evidence of a unit. */
export const fetchAssessmentCondition = orgUnitInstanceId => async dispatch => {
  try {
    const resp = await getAssessmentCondition(orgUnitInstanceId);
    dispatch(setAssessmentCondition(resp.data.assessmentCondition));
  } finally {
    dispatch(setIsLoadingAssessmentMap(false));
  }
};

export const streamSelector = state => state.stream;

export default streamSlice.reducer;
