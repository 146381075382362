/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */

import moment from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import ProfileIcon from '../../../../assets/icons/profile.svg';
import {Spacer} from '../../../../components/common';
import {
  clearAssessmentTasks,
  fetchAssessmentTasksOfStudent,
} from '../Assesments/Assessment/slices/assessmentSlice';
import {LinkedTask, TaskFiles} from './components';
import {TaskEvaluationContainer} from './elements';

export const TaskEvaluation = () => {
  const isFirstRender = useRef(true);
  const [currentFile, setCurrentFile] = useState(null);
  const [isFirstPlayback, setIsFirstPlayback] = useState(false);
  const [selectedLinkedTaskId, setSelectedLinkedTaskId] = useState(null);
  const [onSelectLinkedTask, setOnSelectLinkedTask] = useState(() => {});
  const {
    createdAssessment,
    assessmentTasks,
    assessmentTasksLoading,
    assessmentTasksError,
  } = useSelector(state => state.assessment);
  const history = useHistory();
  const dispatch = useDispatch();
  const {assessmentID, studentID} = useParams();

  const student = useMemo(
    () =>
      createdAssessment.instance.students.find(
        studentItem => studentItem.aouis_id_userprofile_student === studentID,
      ),
    [createdAssessment, studentID],
  );

  useEffect(
    () => () => {
      dispatch(clearAssessmentTasks());
    },
    [dispatch],
  );

  useEffect(() => {
    if (assessmentID && !assessmentTasksLoading && !assessmentTasks) {
      dispatch(fetchAssessmentTasksOfStudent(assessmentID, studentID));
    }
  }, [
    assessmentID,
    assessmentTasks,
    assessmentTasksLoading,
    dispatch,
    studentID,
  ]);

  useEffect(() => {
    if (selectedLinkedTaskId) {
      const taskItem = assessmentTasks?.find(
        item => item.ai_id_activityitem === selectedLinkedTaskId,
      );

      if (!isFirstRender.current) {
        setIsFirstPlayback(taskItem?.linkedFiles[0]?.fi_id_filetype === 2);
      }

      isFirstRender.current = false;
    }
  }, [assessmentTasks, selectedLinkedTaskId]);

  const isPageLoading = assessmentTasksLoading || assessmentTasksError;

  if (!assessmentTasks) {
    return null;
  }

  return (
    <TaskEvaluationContainer className="align-items-center d-flex flex-column">
      <Spacer height="5rem" />
      <header>
        <div className="left">
          <div className="back-btn-hide">
            <button onClick={() => history.goBack()} type="button">
              Back to Students List
            </button>
          </div>
          <div className="profile-details">
            <img alt="Profile" src={ProfileIcon} />
            <div>
              {!isPageLoading ? (
                <p
                  className="name"
                  style={
                    student?.aouis_timestamp_submission
                      ? {marginBottom: '0.375rem'}
                      : {marginBottom: '0'}
                  }
                >
                  {student?.profile.up_name_first}{' '}
                  {student?.profile.up_name_last}
                </p>
              ) : (
                <Skeleton
                  height="1.4375rem"
                  style={{
                    display: 'block',
                    marginBottom: '0.375rem',
                  }}
                />
              )}
              {student?.aouis_timestamp_submission ? (
                <p className="align-items-center d-flex submission-date">
                  <span>
                    Submitted on{' '}
                    {moment(student?.aouis_timestamp_submission).format(
                      'MMM D, H:mm A',
                    )}
                  </span>
                  <span className="px-3">|</span>
                  <span>
                    <span className="primary-text">
                      {student?.aouis_hours?.toString().padStart(2, '0')}:
                      {student?.aouis_minutes?.toString().padStart(2, '0')} Hrs
                    </span>{' '}
                    <span>taken for submission</span>
                  </span>
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="back-btn">
          <button onClick={() => history.goBack()} type="button">
            Back to Students List
          </button>
        </div>
      </header>
      <div className="task-evaluation">
        <div className="left">
          <div>
            <div className="d-flex flex-column">
              {assessmentTasks?.map((item, index) => {
                const isLinkedToFile = item.linkedFiles.some(
                  file => file.fl_id_file === currentFile?.fl_id_file,
                );

                const submissiondetails = item.submissions.filter(
                  submitData =>
                    submitData.ais_id_userprofile_student === studentID,
                );

                const isSelected =
                  selectedLinkedTaskId === item.ai_id_activityitem;
                return (
                  <LinkedTask
                    isLinkedToFile={isLinkedToFile}
                    isSelected={isSelected}
                    index={index + 1}
                    item={item}
                    key={item.ai_id_activityitem}
                    onSelectLinkedTask={taskId => {
                      const task = assessmentTasks?.find(
                        taskItem => taskItem.ai_id_activityitem === taskId,
                      );
                      setCurrentFile(task.linkedFiles[0]);
                      setSelectedLinkedTaskId(taskId);
                      onSelectLinkedTask();
                    }}
                    submissiondetails={submissiondetails}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="rightContent">
          <TaskFiles
            currentFile={currentFile}
            onSelectFile={setCurrentFile}
            isFirstPlayback={isFirstPlayback}
            selectedLinkedTaskId={selectedLinkedTaskId}
            setIsFirstPlayback={setIsFirstPlayback}
            setSelectedLinkedTaskId={setSelectedLinkedTaskId}
            isGraded={student?.aouis_status === 3}
            setOnSelectLinkedTask={setOnSelectLinkedTask}
          />
          <div className="padding" />
        </div>
      </div>
    </TaskEvaluationContainer>
  );
};
