import styled from 'styled-components';
import {Title} from '../../../../../Elements/Container';

export const DashBoardContainer = styled(Title)`
  .container-dashboard {
    flex-shrink: 0;
    width: 100%;
    padding-left: 100px !important ;
    @media (min-width: 84rem) {
      & {
        min-width: 84rem;
        width: 93%;
      }
    }
    @media (max-width: 48rem) {
      & {
        max-width: 48rem;
        width: 94%;
        padding: 0 0.625rem;
      }
      .left {
        display: flex;
        flex-direction: column-reverse;
      }
      [class*='col-'] {
        width: 100%;
        padding: 0.625rem;
      }
      .offset-7 {
        margin: unset;
      }
      .mb-5 {
        margin-bottom: 1.5rem !important;
      }
      .loader-images {
        display: flex;
        margin-top: 6.25rem;
      }
      .dashboard-head {
        .dashboard-header-bottom {
          margin-left: 0.125rem;
        }
      }
      .dashboard-content-head {
        margin-bottom: -0.3125rem;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.8125rem;
        color: #3d4457;
        margin-top: -1.875rem;
      }
      .dashboard-right {
        margin: 0 -0.625rem;
        .dashboard-right-content {
          text-align: center;
          padding: 2.5rem 1.5rem;
          .basic-info-logo {
            margin-top: 2.5rem;
          }
        }
      }
    }
    .dashboard-head {
      .dashboard-header-title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.375rem;
        letter-spacing: 0.025rem;
        color: #3d4457;
      }
      .dashboard-header-bottom {
        margin-top: 0.5rem;
        .academic-year-title {
          font-size: 0.875rem;
          line-height: 1.3125rem;
          color: #a5a5a5;
        }
        .academic-year {
          font-size: 0.875rem;
          line-height: 1.3125rem;
          color: #3d4457;
          margin-left: 0.5rem;
        }
      }
    }
    .dashboard-content-head {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.8125rem;
      color: #3d4457;
      margin-top: 3rem;
    }

    .loader-images {
      margin-top: 7.9375rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-spinner {
      width: 3rem;
      height: 3rem;
      animation: rotating 1s linear infinite;
    }
  }
`;
