import styled from 'styled-components';

export const AddedConstructionContainer = styled.div`
  .add-instructions-section {
    border: 0.0625rem dashed #dedede;
    border-radius: 0.3125rem;
    transition: all 0.1s;
    margin-left: unset;
    &.dragged-inside {
      background-color: #f7fbff;
      border-color: ${({theme}) => theme.colors.primary};
    }

    h3 {
      color: ${({theme}) => theme.colors.text};
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.21;
    }

    .instruction-types {
      display: flex;

      .instruction-type {
        align-items: center;
        background-color: #fff;
        border: 0.0625rem solid #00abff;
        border-radius: 0.5rem;
        display: flex;
        padding: 0.437rem 1rem;

        :focus,
        :active {
          box-shadow: 0 0 0 0.125rem ${({theme}) => theme.colors.lightPrimary};
          outline: none;
        }

        img {
          height: 1.25rem;
          margin-right: 0.5rem;
          width: 1.25rem;
        }

        span {
          color: #00abff;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.6;
        }
      }
    }
    @media (max-width: 48rem) {
      .instruction-types {
        display: flex;
        flex-flow: wrap;
        .instruction-type {
          margin-top: 1rem;
        }
      }
    }
    @media (max-width: 48rem) {
      .instruction-types {
        display: flex;
        flex-flow: wrap;
        .instruction-type {
          margin-top: 1rem;
        }
      }
    }

    .instruction-section-seperator {
      background-color: #dedede;
      height: 0.0625rem;
      width: 100%;
    }

    .instructions-tip {
      align-items: center;
      display: flex;

      img {
        height: 1rem;
        margin-right: 0.375rem;
        width: 1rem;
      }

      span {
        color: #aeaeae;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.21;
      }
    }
  }
  .audio-modal,
  .video-modal-container {
    width: 100%;
    height: 100%;
  }
  .added-instructions-section {
    h3 {
      color: ${({theme}) => theme.colors.text};
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.21;
    }
    .added-instruction-div {
      display: flex;
      flex-direction: row;
    }
    .instruction-sno {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(222, 222, 222, 0.2);
      border-radius: 0.3125rem;
      width: 1.875rem;
      height: inherit;
      margin-bottom: 1rem;
      margin-right: 0.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      color: #aaaaaa;
    }
    .added-instruction {
      width: 100%;
      align-items: flex-start;
      border: 0.0625rem solid #dedede;
      border-radius: 0.3125rem;
      display: flex;
      margin-bottom: 1rem;
      padding: 1rem;

      & > a,
      & > button {
        background-color: transparent;
        border: none;
        height: 2.5rem;
        position: relative;
        width: 2.5rem;

        :focus,
        :active {
          outline: none;
        }

        .upload-spinner-container {
          align-items: center;
          background-color: rgba(255, 255, 255, 0.8);
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          img {
            height: 1rem;
            width: 1rem;
            -webkit-animation: rotating 1s linear infinite;
            -moz-animation: rotating 1s linear infinite;
            -ms-animation: rotating 1s linear infinite;
            -o-animation: rotating 1s linear infinite;
            animation: rotating 1s linear infinite;
          }
        }
      }

      .play-video-icon {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
          height: 1rem;
          width: 1rem;
        }
      }

      .instruction-icon {
        border-radius: 0.3125rem;
        height: 2.5rem;
        object-fit: cover;
        width: 2.5rem;
      }

      .instruction-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 0.625rem;
        width: 70%;

        span {
          color: #aeaeae;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1.21;
          margin-bottom: 0.5rem;
        }

        p {
          color: ${({theme}) => theme.colors.text};
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.71;
          margin: -0.125rem 0 0 0;
          width: 100%;
          word-break: break-word;

          button {
            background-color: transparent;
            border: none;
            color: #00abff;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.71;

            :focus,
            :active {
              outline: none;
            }
          }
        }
      }

      .instruction-options {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }
      }
    }
  }
`;
