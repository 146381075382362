import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../../Admin/elements-new';

export const ViewCurriculumContainer = styled(AdminListingPageContainer)`
  padding: 5rem 0 4rem 5rem !important;
  @media (max-width: 50.5rem) {
    & {
      padding: 9.5rem 0 4.0625rem 5rem !important;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 9.5rem 0 4.0625rem 0rem !important;
    }
  }
`;
