import styled from 'styled-components';

export const LinkedTaskContainer = styled.button`
  background-color: white;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;

  &.selected {
    border-color: ${({theme}) => theme.colors.primary};
  }

  :active,
  :focus {
    outline: none;
  }

  .selection-bar {
    background-color: ${({theme}) => theme.colors.primary};
    height: 100%;
    left: 0;
    top: 0;
    width: 0.375rem;
  }

  .selection-arrow {
    border-bottom: 0.437rem solid transparent;
    border-left: 0.437rem solid ${({theme}) => theme.colors.primary};
    border-top: 0.437rem solid transparent;
    height: 0;
    right: -0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }

  .task-title {
    margin-bottom: 0.75rem;

    .task-index {
      border: 0.0625rem solid #dedede;
      border-radius: 0.25rem;
      color: ${({theme}) => theme.colors.text};
      font-size: 1rem;
      font-weight: 700;
      height: 1.75rem;
      line-height: 1.25rem;
      margin-right: 0.75rem;
      width: 1.75rem;
    }

    .task-name {
      color: ${({theme}) => theme.colors.text};
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.62rem;
      text-align: left;
    }
  }

  .task-description {
    color: #838383;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: left;
  }

  .evalution-icon {
    height: 1.5rem;
    object-fit: contain;
    width: 1.5rem;
  }

  .task-files-count {
    color: ${({theme}) => theme.colors.text};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.37rem;
  }

  .evaluation-value {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.37rem;

    &.text-for-no {
      color: #ff7e7e;
    }

    &.text-for-yes {
      color: #39ca84;
    }
  }

  .grade-value {
    border-radius: 50%;
    color: white;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }
`;
