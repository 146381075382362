/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {Helmet} from 'react-helmet';
// import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import AddToastSuccessImg from '../../../../../../assets/icons/addtoastadmin.svg';

import {
  getAllCourses,
  coursesSelector,
  updateCourse,
  deleteCourse,
  archiveClasses,
} from '../../../../../../redux/coursesSlice';
import {
  EmptyState,
  MoreOptions,
  Table,
  Modal,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';

import {showToast} from '../../../../../../components/common/Toast';

import {TableCellLink} from '../../../../../../components/common/Table/styles';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {CoursesContainer} from './elements';
import {EditCourseModal} from './components';

const DELAY = 500;

export const ActiveCourses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {courses, isListLoading, isAddCourseLoading} =
    useSelector(coursesSelector);
  const [addUnits, setAddUnits] = useState([]);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [courseId, setCourseId] = useState(null);
  const [courseName, setCourseName] = useState('');
  const [editDetails, setEditDetails] = useState(null);

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Course',
        accessor: 'ocr_course_name',
        className: 'course-name',
      },
      {
        Header: 'Type',
        accessor: 'courseType.typecr_type',
        className: 'course-type',
      },
      {
        Header: 'Qualification',
        accessor: 'qualification.tqual_title',
        className: 'qualification-name',
      },
      {
        Header: 'Total Sessions',
        Cell: ({value}) => <div className="total-count">{value}</div>,
        accessor: 'ocr_sessions_total',
        className: 'total-sessions',
      },
      {
        Header: 'Total Hours',
        Cell: ({value}) => <div className="total-count">{value}</div>,
        accessor: 'ocr_hours_total',
        className: 'total-hours',
      },
      {
        Header: 'No of Classes',
        Cell: ({row: {original}}) => {
          const orgCourseId = original?.ocr_id_orgcourse;
          const orgCourseName = original?.ocr_course_name;
          return (
            <div>
              <TableCellLink
                onClick={() => {
                  history.push({
                    pathname: `active-courses/${orgCourseId}/allclasses`,
                    state: {
                      orgCourseId,
                      orgCourseName,
                    },
                  });
                }}
                type="button"
                className="view-class"
              >
                <span> {original?.classCount} Classes</span>
              </TableCellLink>
            </div>
          );
        },
        accessor: 'classCount',
        className: 'view',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit Course"
                id="edit-course-btn"
                onClick={() => {
                  setEditDetails({
                    id: original?.ocr_id_orgcourse,
                    courseType: {
                      id: original?.courseType?.typecr_id_typecourse,
                      name: original?.courseType?.typecr_type,
                    },
                    qualification: {
                      id: original?.qualification?.oq_id_orgqual,
                      name: original?.qualification?.tqual_title,
                    },

                    courseName: original?.ocr_course_name,
                  });
                }}
              />
              <DropdownItem
                label="Archive Classes"
                id="archive-classes-btn"
                onClick={() => {
                  setCourseId(original?.ocr_id_orgcourse);
                  setCourseName(original?.ocr_course_name);
                }}
              />
              <DropdownItem
                isDanger
                label="Delete"
                id="delete-btn"
                onClick={() => {
                  setDeleteId(original?.ocr_id_orgcourse);
                  setDeleteName(original?.ocr_course_name);
                }}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'courses',
        className: 'more-option',
      },
    ],
    [history],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(courses || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [courses],
  );

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  const onSubmit = useCallback(
    details => {
      const courseData = {
        courseName: details.courseName,
        courseType: details.courseType.value,
      };
      if (!isAddCourseLoading) {
        dispatch(
          updateCourse(editDetails.id, courseData, addUnits, () => {
            clearEditDetails();
            showToast(
              AddToastSuccessImg,
              `${details?.courseName} updated successfully `,
            );
          }),
        );
      }
    },

    [isAddCourseLoading, dispatch, editDetails, addUnits, clearEditDetails],
  );

  return (
    <>
      <Helmet>
        <title>Active Courses | JungleCat</title>
      </Helmet>

      <CoursesContainer>
        <ListingPageHeader
          buttonLabel="Add Course"
          className="mb-4"
          onButtonClick={() => {
            history.push(`active-courses/add-courses`);
          }}
          title="All Courses"
          btnId="add-course-btn"
        />

        <div className="table-container">
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading && !courses?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Courses Found"
            />
          ) : null}

          {!isListLoading &&
          courses?.length &&
          Array.isArray(courses) &&
          !isListLoading ? (
            <Table
              columns={columns}
              data={data}
              itemName="Courses"
              maxWidth="75rem"
              isSortedBy
            />
          ) : null}
        </div>
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this Course?"
          isDelete
          isButtonLoading={isAddCourseLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            dispatch(
              deleteCourse(deleteId, () => {
                setDeleteId(null);
                showToast(false, `${deleteName} has been deleted.`, true);
                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }),
            );
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
        <EditCourseModal
          editDetails={editDetails}
          onHide={clearEditDetails}
          onSubmit={onSubmit}
          addUnits={addUnits}
          setAddUnits={setAddUnits}
        />
        <Modal
          buttonLabel="Archive Classes"
          description="Are you sure you want to archive this all classes under this course?"
          isButtonLoading={isAddCourseLoading}
          loadingButtonLabel="archiving"
          onButtonClick={() => {
            dispatch(
              archiveClasses(courseId, () => {
                setCourseId(null);
                showToast(
                  AddToastSuccessImg,
                  `${courseName} under all classes has been archived successfully.`,
                );
                setTimeout(() => {
                  setCourseName('');
                }, DELAY);
              }),
            );
          }}
          onHide={() => {
            setCourseId(null);

            setTimeout(() => {
              setCourseName('');
            }, DELAY);
          }}
          show={!!courseId}
          title={`Archive ${courseName}`}
          width="41.25rem"
        />
      </CoursesContainer>
    </>
  );
};
