/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const DashBoardContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -15px;
  padding-top: 126px;
  padding-left: 100px;
  padding-bottom: 46px;
  @media (max-width: 37.5rem) {
    & {
      padding-left: unset;
    }
  }
  .container-dashboard {
    padding: 0 15px;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 1344px) {
      & {
        min-width: 1344px;
        width: 93%;
      }
    }

    @media (min-width: 838px) {
      .organisation {
        font-size: 2px;
      }
    }
    @media (max-width: 768px) {
      & {
        max-width: 768px;
        width: 94%;
        padding: 0 10px;
      }
      .left {
        display: flex;
        flex-direction: column-reverse;
      }
      [class*='col-'] {
        width: 100%;
        padding: 10px;
      }
      .offset-7 {
        margin: unset;
      }
      .user-admin {
        .admin-top-div {
          height: 220px;
          padding: 24px;
        }
      }

      .user-teachers {
        .teacher-top-div {
          height: 220px;
          padding: 24px;
        }
      }
      .user-students {
        .student-top-div {
          height: 220px;
          padding: 24px;
        }
      }
      .mb-5 {
        margin-bottom: 1.5rem !important;
      }
      .loader-images {
        display: flex;
        margin-top: 100px;
      }
      .dashboard-head {
        .dashboard-header-bottom {
          margin-left: 2px;
        }
      }
      .dashboard-content-head {
        margin-bottom: -5px;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #3d4457;
        margin-top: -30px;
      }
      .dashboard-right {
        margin: 0 -10px;
        .dashboard-right-content {
          text-align: center;
          padding: 40px 24px;
          .basic-info-logo {
            margin-top: 40px;
          }
        }
      }
    }
  }

  button {
    border: none;
    background-color: transparent;
    outline: none;
  }
  .loader-images {
    margin-top: 127px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-spinner {
    width: 48px;
    height: 48px;
    animation: rotating 1s linear infinite;
  }
  .dashboard-head {
    .dashboard-header-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.4px;
      color: #3d4457;
    }
    .dashboard-header-bottom {
      margin-top: 8px;
      .academic-year-title {
        font-size: 14px;
        line-height: 21px;
        color: #a5a5a5;
      }
      .academic-year {
        font-size: 14px;
        line-height: 21px;
        color: #3d4457;
        margin-left: 8px;
      }
    }
  }
  .dashboard-content-bottom {
  }
  .dashboard-content-head {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #3d4457;
    margin-top: 48px;
  }
  .admin-top-div {
    background-color: #f4f2ff;
    height: 240px;
    border-radius: 12px;
    padding: 24px;
    :hover {
      padding: 22px;
      border: 2px solid #e5e0ff;
    }
  }
  .teacher-top-div {
    background-color: #fff2ec;
    height: 240px;
    border-radius: 12px;
    padding: 24px;
    :hover {
      padding: 22px;
      border: 2px solid #fcddcf;
    }
  }
  .student-top-div {
    background-color: #ecfbff;
    height: 240px;
    border-radius: 12px;
    padding: 24px;
    :hover {
      padding: 22px;
      border: 2px solid #bcebf7;
    }
  }
  .user-top {
    display: flex;
    justify-content: space-between;
    .user-top-content-left-top {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.4px;
      color: #3d4457;
    }
    .user-top-content-left-bottom {
      font-size: 16px;
      line-height: 24px;
      color: #3d4457;
    }
  }
  .footer-delete-break {
    height: 1px;
    background-color: #e0e0e0;
    width: 100%;
  }
  .user-bottom {
    .user-bottom-content-left {
      display: flex;
      text-align: left;
      justify-content: space-between;
      .user-bottom-content-left-top {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #3d4457;
      }
      .user-bottom-content-left-bottom {
        font-size: 12px;
        line-height: 18px;
        color: #a5a5a5;
      }
    }
    .user-bottom-content-left-none {
      display: flex;
      justify-content: space-between;
      margin-top: 74px;
      .user-bottom-content-left-bottom-none {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #3d4457;
      }
    }
  }
  .units-works-lock-div {
    border: none;
    box-shadow: unset;
    height: 240px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 32px;

    .units-works-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 36px;
    }
    .units-works-logo {
      background-color: #ecf6ff;
      border-radius: 12px;
      padding: 21px 24.33px;
    }
    .units-works-top {
      display: flex;
      justify-content: space-between;
    }
    .units-works-bottom-top {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.4px;
      color: #3d4457;
    }
    .units-works-bottom-bottom {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 6px;
      color: #a5a5a5;
    }
    .units-work-bottom-content-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .units-work-bottom-content-right {
      margin-top: -32px;
      margin-right: -32px;
      display: flex;
      align-items: flex-end;
      .work-mark-logo {
        margin-right: -3px;
        margin-top: 22px;
      }
    }
    .view-all-button {
      margin-right: 16px;
      border: 1px solid #dedede;
      border-radius: 8px;
      align-items: flex-end;
      @media (max-width: 500px) {
        & {
          margin-right: unset;
        }
      }
      .viewall-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .units-works-lock {
    position: absolute;
    width: 100%;

    .overlay {
      position: relative;
      display: block;
      z-index: 2;
      cursor: not-allowed;
      pointer-events: unset;
      height: 240px;
      width: 93.6%;
      background-color: rgba(243, 243, 243, 0.9);
      /* background: transparent; */
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
  }
  .text-lock {
    padding: 0px 24px 18px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    top: 50%;
    left: 50%;
    width: 80%;
    font-size: 50px;
    text-align: center;
    color: black;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    .text-content {
      margin-top: 10px;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #3d4457;
    }
  }
  .units-works {
    height: 240px;
    background-color: #ffffff;
    border: 1px solid #cfe7ff;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 32px;
    @media (max-width: 500px) {
      & {
        padding: 24px;
      }
    }

    .units-works-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 36px;
    }
    .units-works-logo {
      background-color: #ecf6ff;
      border-radius: 12px;
      padding: 21px 24.33px;
    }
    .units-works-top {
      display: flex;
      justify-content: space-between;
    }
    .units-works-bottom-top {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.4px;
      color: #3d4457;
    }
    .units-works-bottom-bottom {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-top: 6px;
      color: #a5a5a5;
    }
    .units-work-bottom-content-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .units-work-bottom-content-right {
      margin-top: -32px;
      margin-right: -32px;
      display: flex;
      align-items: flex-end;
      @media (max-width: 500px) {
        & {
          margin-right: -20px;
        }
      }
      .work-mark-logo {
        margin-right: -3px;
        margin-top: 22px;
      }
    }
    .view-all-button {
      margin-right: 16px;
      border: 1px solid #dedede;
      border-radius: 8px;
      align-items: flex-end;
      @media (max-width: 500px) {
        & {
          margin-right: unset;
        }
      }
      .viewall-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .dashboard-right {
    left: 960px;
    top: 112px;
    background-color: #ecf6ff;
    border-radius: 12px;
    .dashboard-right-content {
      text-align: center;
      padding: 40px;
      .basic-info-title-div {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #3d4457;
      }
      .basic-info-logo {
        margin-top: 80px;
        .basic-info-logo-img {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }
      }
      .basic-info-logo-empty {
        /* margin-top: 15px; */
        .basic-info-logo-empty {
          width: 236.93px;
          height: 200px;
        }
      }
      .basic-info-details-div-empty {
        display: flex;
        justify-content: center;
        margin-top: 29px;
        text-align: center;
      }
      .basic-info-name-div {
        padding-top: 24px;
        .basic-info-name {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #3d4457;
        }
      }
      .basic-info-address-div {
        margin-top: 12px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #a5a5a5;
      }
      .basic-info-details-div {
        display: flex;
        justify-content: center;

        margin-top: 24px;
        .basic-info-details {
          padding: 6px;
          display: flex;
          justify-content: center;
          border: none;
          background: transparent;
          outline: none;
          .basic-info-view-details {
            padding-left: 11px;
          }
        }
      }

      .organisation-div {
        margin-top: 65px;
        @media (max-width: 500px) {
          & {
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            margin-top: 25px;
            ::-webkit-scrollbar {
              display: none;
            }
          }
          .organisation1 {
            margin-right: 16px;
          }
        }
        .organisation1,
        .organisation2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
          background-color: white;
          margin-top: 24px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
          border-radius: 12px;
          .organisation-left {
            display: flex;
            align-items: center;
            text-align: left;
            .org-top-content-left-bottom {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;

              color: #a5a5a5;
            }
            .units-works-logo {
              width: 64px;
              height: 64px;
              background-color: #ecf6ff;
              border-radius: 12px;
              .org-logo {
                padding: 12px;
              }
            }
            .org-dept-academic-count {
              font-weight: 500;
              font-size: 32px;
              line-height: 38px;
              letter-spacing: 0.4px;
              color: #3d4457;
            }
          }
        }
      }
    }
  }
`;
