import styled from 'styled-components';

export const CourseDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .container-course {
    padding: 1rem 0;
    flex-shrink: 0;
    width: 69.75rem;
    margin: 7rem 0rem 9rem 0;

    @media (max-width: 69.75rem) {
      & {
        width: 98%;
      }
    }
  }
  .courseheading {
    display: flex;
    flex-direction: column;
    .main-text {
      color: #3d4457;
      font-weight: 500;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }
  .code {
    font-size: 0.875rem;
    color: #8a8c94;
    margin-top: 0.5rem;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .course-name {
      width: 100%;
      margin-bottom: 1.5rem;
    }
    .field-left,
    .field-right {
      width: 100%;
    }
    .field-left {
      margin-right: 1.5rem;
    }
    @media (max-width: 50rem) {
      & {
        display: flex;
        flex-direction: column;
      }
      .field-left {
        margin-bottom: 1.5rem;
      }
    }
  }
`;
