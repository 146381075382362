import styled from 'styled-components';

export const AttendanceCardContainer = styled.div`
  padding: 1.5rem;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0.25rem;
  margin-top: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border: 0.0625rem solid transparent;
  @media (max-width: 500px) {
    & {
      padding: 1rem;
      width: 100%;
    }
  }
  &.is-absent {
    border: 0.0625rem solid #ff8787;
  }
  &.is-present {
    border: 0.0625rem solid #4ec977;
  }
  .left-card {
    display: flex;
    align-items: center;
    .circle {
      width: 3rem;
      height: 3rem;
      margin-right: 0.75rem;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    .name {
      font-weight: 500;
      font-size: 1rem;
      color: #0c181f;
    }
    .ref-id {
      font-size: 0.875rem;
      color: #6d7579;
    }
  }
  .right-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    .circle-button {
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      background-color: transparent;
      height: 36px;
      width: 36px;
    }
  }
  @media (max-width: 32rem) {
    & {
      align-items: unset;
    }
    .left-card {
      display: flex;
      flex-direction: column;
      align-items: unset !important;
      .content {
        margin-top: 0.5rem;
      }
    }
    .right-card {
      display: flex;
      align-items: flex-end !important;
    }
  }
`;
