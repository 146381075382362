export function notificationRedirection(type, history, notification) {
  let direct;
  let emailId;
  // let activityOrgUnitInstanceId;
  let activityId;
  if (type === 11 || type === 12 || type === 23) {
    emailId = notification?.userProfile?.up_email;
    direct = history.push({
      pathname: `/admin/users/admin`,
      state: {
        emailId,
      },
    });
  } else if (type === 10) {
    emailId = notification?.userProfile?.up_email;
    direct = history.push({
      pathname: `/admin/users/student`,
      state: {
        emailId,
      },
    });
  } else if (
    type === 19 ||
    type === 24 ||
    type === 20 ||
    type === 26 ||
    type === 36
  ) {
    activityId = notification?.aoui_id_activity;
    direct = history.push({
      pathname: `/stream/${notification?.aoui_id_orgunitinstance}/live`,
      state: {
        activityId,
      },
    });
  } else if (
    type === 1 ||
    type === 2 ||
    type === 3 ||
    type === 4 ||
    type === 27 ||
    type === 28
  ) {
    direct = history.push(
      `/stream/${notification?.aoui_id_orgunitinstance}/student/assessment/${notification?.ac_id_activity}`,
    );
  } else if (type === 18 || type === 35) {
    direct = history.push(`/classes`);
  } else if (type === 15) {
    direct = history.push(
      `/stream/${notification?.aoui_id_orgunitinstance}/assessment/${notification?.ac_id_activity}/students/${notification?.userProfile?.up_id_userprofile}`,
    );
  } else if (type === 16) {
    direct = history.push(
      `/stream/${notification?.aoui_id_orgunitinstance}/assessment/${notification?.ac_id_activity}/students`,
    );
  } else if (
    type === 25 ||
    type === 21 ||
    type === 31 ||
    type === 32 ||
    type === 13
  ) {
    direct = history.push(
      `/stream/${notification?.oruil_id_orgunitinstance}/sessions/calendar`,
    );
    history.push({
      pathname: `/stream/${notification?.oruil_id_orgunitinstance}/sessions/calendar`,
      state: {
        sessionName: notification?.oruil_name,
      },
    });
  }
  return direct;
}
