import {createSlice} from '@reduxjs/toolkit';

import * as adminTeachersApi from '../api/admin/adminTeacher';
import * as organisationApi from '../api/admin/basicInfo';

const initialFormError = {email: '', referId: ''};

const adminTeachersSlice = createSlice({
  initialState: {
    error: null,
    formError: initialFormError,
    isListLoading: true,
    isAdminTeacherLoading: false,
    adminTeachers: null,
    organisation: null,
    isAddAdminTeacherLoading: false,
    alreadyExistEmail: [],
    alreadyExistRefId: [],
    duplicateData: [],
    emailPattern: [],
    validAdmin: [],
    basicInfo: null,
  },
  name: 'adminTeachers',
  reducers: {
    editAdminTeacher: (state, action) => {
      const adminTeacherIndex = state.adminTeachers.findIndex(
        adminTeacher => adminTeacher.up_id_user === action.payload.up_id_user,
      );
      state.adminTeachers[adminTeacherIndex] = action.payload;
    },
    removeAdminTeacherById(state, action) {
      state.adminTeachers = state.adminTeachers.filter(
        adminTeacher => adminTeacher.up_id_user !== action.payload,
      );
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsAdminTeacherLoading(state, action) {
      state.isAdminTeacherLoading = action.payload;
    },
    setIsAddAdminTeacherLoading(state, action) {
      state.isAddAdminTeacherLoading = action.payload;
    },
    setAdminTeachers(state, action) {
      state.adminTeachers = action.payload;
    },
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },

    setAlreadyExistEmail: (state, action) => {
      state.alreadyExistEmail = action.payload;
    },
    setDuplicateData: (state, action) => {
      state.duplicateData = action.payload;
    },
    setAlreadyExistRef: (state, action) => {
      state.alreadyExistRefId = action.payload;
    },
    setEmailPattern: (state, action) => {
      state.emailPattern = action.payload;
    },
    setValidAdmin: (state, action) => {
      state.validAdmin = action.payload;
    },
  },
});

export const {
  editAdminTeacher,
  removeAdminTeacherById,
  setError,
  setIsListLoading,
  setIsAdminTeacherLoading,
  setAdminTeachers,
  setOrganisation,
  setIsAddAdminTeacherLoading,
  setFormError,
  clearFormError,
  setAlreadyExistEmail,
  setAlreadyExistRef,
  setEmailPattern,
  setValidAdmin,
  setDuplicateData,
} = adminTeachersSlice.actions;

export const fetchAllAdminAndTeachers = () => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const [adminTeacherResponse, organisationResponse] = await Promise.all([
      adminTeachersApi.fetchAllAdminTeachers(),
      organisationApi.fetchBasicInfo(),
    ]);
    dispatch(setAdminTeachers(adminTeacherResponse.data?.orgAllAdmin[0] || []));
    dispatch(setOrganisation(organisationResponse.data?.orgDetail));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const updateAdminTeacher =
  (adminTeacherId, data, callback) => async dispatch => {
    dispatch(setIsAdminTeacherLoading(true));

    try {
      const response = await adminTeachersApi.updateAdminTeacher(
        adminTeacherId,
        {
          referID: data.referId,
          fName: data.firstName,
          lName: data.lastName,
          useremail: data.email,
          admin: data.admin ? 'yes' : 'no',
          teacher: data.teacher ? 'yes' : 'no',
        },
      );

      if (response.data?.orgAdmin[0][0]) {
        dispatch(editAdminTeacher(response.data?.orgAdmin[0][0]));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAdminTeacherLoading(false));
    }
  };

export const deleteAdminTeacher =
  (adminTeacherId, callback) => async dispatch => {
    dispatch(setIsAdminTeacherLoading(true));

    try {
      await adminTeachersApi.deleteAdminTeacher(adminTeacherId);
      dispatch(removeAdminTeacherById(adminTeacherId));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAdminTeacherLoading(false));
    }
  };

export const resendAdminTeacherInvitation =
  (adminTeacherId, data, callback) => async dispatch => {
    dispatch(setIsAdminTeacherLoading(true));

    try {
      const response = await adminTeachersApi.resendAdminTeacherInvitation(
        adminTeacherId,
        {
          refId: data.referId,
          fName: data.firstName,
          lName: data.lastName,
          email: data.email,
        },
      );

      if (response.data.invitationLink[0][0]) {
        dispatch(editAdminTeacher(response.data.invitationLink[0][0]));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAdminTeacherLoading(false));
    }
  };
// Add User Flow
export const individualAdminCheck = (data, callback) => async dispatch => {
  dispatch(setIsAddAdminTeacherLoading(true));
  try {
    await adminTeachersApi.individualAdminValid(data);
    if (callback) {
      callback('');
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
    dispatch(setFormError(error?.response?.data?.error));
  } finally {
    dispatch(setIsAddAdminTeacherLoading(false));
  }
};
export const addAdminManual = (data, callback) => async dispatch => {
  dispatch(setIsAddAdminTeacherLoading(true));
  try {
    await adminTeachersApi.addAdminManual(data);

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddAdminTeacherLoading(false));
  }
};
export const addAdminSpread =
  (data, isAdminSpreadsheet, isTeacherSpreadsheet, callback) =>
  async dispatch => {
    dispatch(setIsAddAdminTeacherLoading(true));
    try {
      await adminTeachersApi.addAdminSpread(
        data,
        isAdminSpreadsheet,
        isTeacherSpreadsheet,
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddAdminTeacherLoading(false));
    }
  };
export const spreadSheetAdminsCheck =
  (data, isAdminSpreadsheet, isTeacherSpreadsheet, callback) =>
  async dispatch => {
    dispatch(setIsAddAdminTeacherLoading(true));
    const resp = await adminTeachersApi.spreadSheetAdminsValid(data);
    dispatch(setAlreadyExistEmail(resp.data.alreadyEmail));
    dispatch(setAlreadyExistRef(resp.data.alreadyRef));
    dispatch(setEmailPattern(resp.data.invalidEmails));
    dispatch(setDuplicateData(resp.data.duplicateData));
    dispatch(setValidAdmin(resp.data.validUsers));
    dispatch(setIsAddAdminTeacherLoading(false));
    if (callback) {
      if (
        resp.data.validUsers.length === data.length &&
        !resp.data.duplicateData.length
      ) {
        dispatch(
          addAdminSpread(
            resp.data.validUsers,
            isAdminSpreadsheet,
            isTeacherSpreadsheet,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };

export const fetchBasicInfo = () => async dispatch => {
  try {
    const response = await organisationApi.fetchBasicInfo();
    dispatch(setIsAddAdminTeacherLoading(true));
    dispatch(setOrganisation(response.data.orgDetail));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddAdminTeacherLoading(false));
  }
};
export const adminTeachersSelector = state => state.adminTeachers;

export default adminTeachersSlice.reducer;
