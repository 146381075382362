/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {Button} from '../../../../../../../../components/common';
import SearchInput from '../../../../../../../../components/shared/Inputs/SearchInput';
import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';

import TimeIcon from '../../../../../../../../assets/icons/session-time.svg';
import TeacherIcon from '../../../../../../../../assets/icons/session-teacher.svg';
import CloseIcon from '../../../../../../../../assets/icons/addadminclose.svg';
import MarkedAll from '../../../../../../../../assets/icons/marked-all.svg';
import {AttendanceHeaderContainer} from './elements';

export const AttendanceHeader = ({
  setSearchTerm,
  searchTerm,
  sessionDetails,
  setAttendance,
  studentsList,
}) => {
  const history = useHistory();

  const [markedAll, setMarkedAll] = useState(null);
  // console.log(markedAll);

  const onCancel = () => {
    history.replace(`/admin/sessions`);
  };

  useEffect(() => {
    if (markedAll === true) {
      studentsList.forEach(item => {
        setAttendance(data => ({
          ...data,
          [item?.up_id_userprofile]: 2,
        }));
      });
    } else if (markedAll === false) {
      studentsList.forEach(item => {
        setAttendance(data => ({
          ...data,
          [item?.up_id_userprofile]: 1,
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAll]);

  return (
    <AttendanceHeaderContainer>
      <div className="header-container fixed-top" id="header-container">
        <div className="header-top">
          <div className="back-container">
            <div>
              <button className="backbutton" onClick={onCancel} type="button">
                <img src={ArrowLeftImg} alt="arrow" />
              </button>
            </div>
            <div className="title">Attendance</div>
          </div>
          <div>
            <button className="btn" onClick={onCancel} type="button">
              <img alt="Options" src={CloseIcon} />
            </button>
          </div>
        </div>
        <div className="header-bottom">
          <div className="left-content">
            <div className="title">{sessionDetails?.oruil_name}</div>
            <div className="d-flex details">
              <div className="class-name">
                {sessionDetails?.orgUnitInstance?.class?.oc_class}
              </div>
              <div className="session-time">
                <img alt="Options" src={TimeIcon} className="mr-1" />
                {`${moment(sessionDetails?.oruil_timestart, 'HH:mm')
                  .format('h:mm A')
                  .replace(/:00/g, '')} - 
              ${moment(sessionDetails?.oruil_timefinish, 'HH:mm')
                .format('h:mm A')
                .replace(/:00/g, '')}`}
              </div>
              <div className="teacher-name">
                <img alt="Options" src={TeacherIcon} className="mr-1" />
                {`${sessionDetails?.teacher?.up_name_first}
              ${sessionDetails?.teacher?.up_name_last}`}
              </div>
            </div>
          </div>
          <div className="right-content">
            <SearchInput
              className="class-search"
              onChange={event => setSearchTerm(event.target.value)}
              placeholder="Search"
              value={searchTerm}
              width="300px"
            />

            {sessionDetails?.oruil_attendance_status === 0 ? (
              <Button
                className="button cancel-button"
                onClick={() => {
                  setMarkedAll(state => !state);
                }}
                isFullWidth={false}
                label="Mark Present All"
                icon={MarkedAll}
                iconPostion="right"
              />
            ) : null}
          </div>
        </div>
      </div>
    </AttendanceHeaderContainer>
  );
};
