import styled from 'styled-components';

const TextModalContainer = styled.div`
  border-radius: 0.75rem;
  overflow: hidden;

  header {
    align-items: center;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;

    h4 {
      color: #091018;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5125rem;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    button {
      background-color: transparent;
      border: none;
      display: flex;
      height: 1.5rem;
      width: 1.5rem;

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  main {
    background-color: #e6e7e8;
    color: #091018;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    padding: 2.5rem;
    text-align: justify;
  }
  .content {
    padding: 1.5rem;
  }
  .top {
    display: flex;
    padding: 1rem 0rem;
  }
  .title {
    text-transform: capitalize;
    padding-left: 1rem;
    font-size: 0.875rem;
  }
  .assessment-condition-content {
    border-bottom: 0.0625rem solid #dedede;
  }
  .assessment-condition-content:last-child {
    border-bottom: unset;
    padding-bottom: unset;
  }
`;

export default TextModalContainer;
