import styled from 'styled-components';

export const TaskSubmissionHeaderContainer = styled.header`
  background-color: #fff;
  border-bottom: 0.0625rem solid #e9e9e9;
  z-index: 1040;
  @media (max-width: 70.25rem) {
    & {
      width: 100%;
    }
  }
  .button-arrow {
    background-color: #fff;
    border: none;
    /* margin-right: 1rem; */

    :active,
    :focus {
      outline: none;
    }

    .header_t-back-icon {
      height: 2rem;
    }
  }
  .basic-details-header {
    padding: 1rem 2rem 0rem 2rem;
    h3 {
      color: #3d4457;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .basic-details-description {
      color: #84888c;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1rem;
      margin-bottom: unset;
    }
  }
  @media (max-width: 40rem) {
    .basic-details-header {
      padding: 1rem 1.5rem 0rem 1.5rem;
    }
  }
`;
