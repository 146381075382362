import HeroImage from '../../../../assets/images/auth/hero.svg';
// import HeroTextArrowImage from '../../../../assets/images/auth/hero-text-arrow.svg';
// import HeroTextExtensionImage from '../../../../assets/images/auth/hero-text-extension.svg';
import JunglecatLogo from '../../../../assets/logos/junglecat-white.svg';
import {HeroSectionContainer} from './elements';

export const HeroSection = () => (
  <HeroSectionContainer className="col-lg-6 d-none d-lg-block position-relative">
    <img
      alt="JungleCat"
      className="junglecat-logo position-absolute"
      src={JunglecatLogo}
    />
    {/* <img
      alt="Social Media of"
      className="hero-text-extension position-absolute"
      src={HeroTextExtensionImage}
    /> */}
    <h2 className="hero-text mb-0 position-absolute">
      The new classroom experience
    </h2>
    <img
      alt="Sitting Person"
      className="position-absolute hero-image"
      src={HeroImage}
    />
  </HeroSectionContainer>
);
