import styled from 'styled-components';

export const MyAssessmentsContainer = styled.div`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 4%;
  }
  .assessment-title {
    width: 40%;
    padding-right: 1rem !important;
  }

  .last-updated {
    width: 20%;
  }
  .date {
    font-weight: 400;
    font-size: 14px;
    color: #aaaaaa;
    margin-bottom: 0;
    font-style: italic;
  }
  .unit {
    width: 30%;
  }

  .more-option {
    width: 2%;
  }

  .table-container {
    border-radius: 0.75rem;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.04) 0.0625rem 0.0625rem 0.25rem;
    padding: 1.5rem;
  }
  table {
    margin: 1rem 0px 1.5rem;
    width: 100%;
    tr {
      width: 100%;
      :hover {
        background-color: unset;
      }
    }
  }
`;
