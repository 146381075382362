/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {Helmet} from 'react-helmet';
// import {Typeahead} from 'react-bootstrap-typeahead';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
// import DatePicker from 'react-datepicker';

import csvjson from 'csvjson';
import CustomCancel from '../../../../../components/shared/Buttons/CustomButton';
// import CustomButtonWithoutText from '../../../../components/shared/CustomButtonWithoutText';
import closeUnit from '../../../../../assets/icons/addadminclose.svg';
import radioIcon from '../../../../../assets/icons/radiodot.svg';
import addIcons from '../../../../../assets/icons/addadmin.svg';
import arrowHide from '../../../../../assets/icons/tablehide.svg';
import arrowShow from '../../../../../assets/icons/tableshow.svg';

import CustomTextInput from '../../../../../components/shared/CustomTextInput';

import {
  AddUnitToQualificationContainer,
  AddUnitToQualificationHeader,
  AddUnitToQualificationFooter,
  ManualUnitTableStyles,
  SpreadSheetUnitTableStyles,
} from '../../../Elements/AddUnitsToQualificationContainer';
import CustomBox from '../../../../../components/shared/CustomBox';

import files from '../../../../../assets/icons/dropfile.svg';
import greenTick from '../../../../../assets/icons/donemark.svg';
import yellowTick from '../../../../../assets/icons/yellow-success.svg';
import deletelogo from '../../../../../assets/icons/delete-logo.svg';
import uploadSuccess from '../../../../../assets/icons/upload-success.svg';
import closeIcon from '../../../../../assets/icons/redcross.svg';

import CustomButton from '../../../../../components/shared/CustomButton';
import Loader from '../../../../../components/shared/BookLoader';
// import arrowLeft from '../../../../assets/icons/arrow_left.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {Table} from '../../../../../components/common/Table';

import {
  //   addNewOrgQualification,
  adminInfo,
  setQualificationToastSuccess,
  individualUnitCheckByQualId,
  //   addQualificationSpread,
  spreadSheetUnitCheckByQualificationId,
  //   spreadSheetQualificationsCheck,
  addUnitsByQualificationIdManual,
  addUnitsByQualIdSpread,
  setValidateUnitError,
} from '../../../adminSlice';

const AddUnitsToQualification = () => {
  const location = useLocation();
  const {qualificationId} = useParams();
  const [unitCode, setUnitCode] = useState('');
  const [unitName, setUnitName] = useState('');
  const [unitType, setUnitType] = useState('');
  const [addUnits, setAddUnits] = useState([]);
  const [spreadSheet, setSpreadSheet] = useState([]);

  const [uCodeError, setUcodeError] = useState([]);

  const [fileImport, setImport] = useState(true);

  const [manual, setManual] = useState(false);

  const [upload, setUpload] = useState(null);

  const [onDrag, setOnDrag] = useState(false);

  const [nextShow, setNextShow] = useState(false);

  const [unitNameError, setUnitNameError] = useState('');
  const [unitCodeError, setUnitCodeError] = useState('');

  const [fileName, setFileName] = useState('');
  const [showShow, setShowShow] = useState(false);

  const [qualificationData] = useState({
    qualificationId: qualificationId || '',
    qualificationName: location.state?.qualificationName || '',
  });

  const dispatch = useDispatch();
  const {alreadyExistUcode, validUnitsToQualification, validateUnitError} =
    useSelector(adminInfo);
  const downloadRef = useRef(null);
  const history = useHistory();

  const [isValidating, setIsValidating] = useState(false);

  const [spreadNext, setSpreadNextShow] = useState(0);

  const [tableData, setTableData] = useState([]);

  const isOnStepTwo = !isValidating && spreadNext === 2;

  useEffect(() => {
    setTableData(addUnits);

    if (spreadSheet?.length > 0) {
      setTableData(spreadSheet);
    }
  }, [addUnits, spreadSheet]);

  useEffect(() => {
    if (validateUnitError?.field !== '') {
      if (validateUnitError?.field === 'unitCode') {
        setUnitCodeError(validateUnitError?.msg);
      }
    }
  }, [validateUnitError]);

  const handleDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] || event.target.files[0];
    const extn = file.name.split('.').pop();
    if (file && extn === 'csv') {
      const reader = new FileReader();
      reader.onload = () => {
        const jsonObj = csvjson.toObject(reader.result);
        setSpreadSheet(jsonObj);
        setFileName(file.name);
      };
      reader.readAsBinaryString(file);
    }
  };

  const removeItems = position => {
    setAddUnits(state =>
      state.filter((item, index) => index !== position.index),
    );
  };

  const toggleShow = () => setShowShow(!showShow);

  const [isCore, setIsCore] = useState(false);
  const [isElective, setIsElective] = useState(false);

  useEffect(() => {
    setUcodeError(alreadyExistUcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyExistUcode]);
  const unitsColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'UNIT CODE',
        accessor: 'unit_code',
        className: 'unit-code',
      },
      {
        Header: 'UNIT NAME',
        accessor: 'unit_name',
        className: 'unit-name',
      },
      {
        Header: 'UNIT TYPE',
        Cell: ({row: {original}}) => {
          const unitTypeInSpread = original?.unit_type;
          return unitTypeInSpread?.toLowerCase() === 'core' ? (
            <div className="type-container">
              <div className="core">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          ) : (
            <div className="type-container">
              <div className="elective">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          );
        },
        accessor: 'unit_type',
        className: 'unit-types',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, alreadyExistUcode],
  );

  const alreadyExistUcodeData = useMemo(
    () => [
      ...alreadyExistUcode.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [alreadyExistUcode],
  );
  const spreadSheetUnitData = useMemo(
    () => [
      ...spreadSheet.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [spreadSheet],
  );
  const unitsManualColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'UNIT CODE',
        accessor: 'unitCode',
        className: 'unit-code',
      },
      {
        Header: 'UNIT NAME',
        accessor: 'unitName',
        className: 'unit-name',
      },
      {
        Header: 'UNIT TYPE',
        Cell: ({row: {original}}) => {
          const unitTypeInSpread = original?.unitType;
          return unitTypeInSpread?.toLowerCase() === 'core' ? (
            <div className="type-container">
              <div className="core">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          ) : (
            <div className="type-container">
              <div className="elective">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          );
        },
        accessor: 'unitType',
        className: 'unit-types',
      },
      {
        heading: '',
        Cell: ({row: position}) => (
          <button
            type="button"
            onClick={() => {
              removeItems(position);
            }}
            className="closeIconBtn"
          >
            <img src={closeIcon} alt="close" />
          </button>
        ),
        accessor: 'addUnits',
        className: 'close',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, alreadyExistUcode],
  );

  const addUnitsData = useMemo(
    () => [
      ...addUnits.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [addUnits],
  );
  const convertFileSize = size => {
    const fileSizeKb = size / 1024;
    if (fileSizeKb > 1024) {
      const fileSizeMb = fileSizeKb / 1024;
      return `${fileSizeMb.toFixed(2)} Mb`;
    }

    return `${fileSizeKb.toFixed(2)} Kb`;
  };
  return (
    <>
      <Helmet>
        <title>Add Units | JungleCat</title>
      </Helmet>
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#f4f5f8',
          margin: '0px -15px',
          padding: '0 15px',
        }}
      >
        <AddUnitToQualificationHeader>
          <div className="add-unit-header">
            <div className="add-unit-title">
              <div>
                <span className="add-unit-name">Add a units</span>
              </div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    history.replace(`/admin/qualifications`);

                    dispatch(setValidateUnitError(''));
                    setUnitName('');
                    setUnitType('');
                    setIsElective(false);
                    setIsCore(false);
                    setUnitCode('');
                    setUnitNameError('');
                    setUnitCodeError('');
                  }}
                  type="button"
                >
                  <img alt="Options" src={closeUnit} />
                </button>
              </div>
            </div>
          </div>
        </AddUnitToQualificationHeader>
        <AddUnitToQualificationContainer>
          <div className="container-unit">
            <div className="unitheading d-flex justify-content-between  mb-2">
              <p style={{fontSize: '24px'}} className="main-text">
                {spreadNext >= 0
                  ? `Add units to ${qualificationData?.qualificationName}`
                  : null}
              </p>
              <p className="sub-text">Add units under a qualification</p>
            </div>
            <CustomBox padding="1px 20px" className="custombox">
              {spreadNext === 0 ? (
                <div className="search-div">
                  <button
                    className={fileImport ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(true);
                      setManual(false);
                      setUnitName('');
                      setUnitType('');
                      setIsElective(false);
                      setIsCore(false);
                      setUnitCode('');
                      setUnitNameError('');
                      setUnitCodeError('');
                    }}
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manual ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(false);
                      setManual(true);
                    }}
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}
              {spreadNext === 1 || isValidating ? (
                <div className="search-div-spread">
                  <p className="imported">Imported units from document</p>
                  <p className="imported-file">{fileName}</p>
                </div>
              ) : null}

              {manual ? (
                <div>
                  {/* {formShow && btnShow ? ( */}
                  <div>
                    <div
                      className="add-unit-modal"
                      style={{padding: '24px 0px 0px 0px'}}
                    >
                      <div className="add-unit-modal-body">
                        <div className="date-wrapper1">
                          <p className="unit-type-title">Unit Type</p>
                          <div className="unit-type">
                            <div
                              style={{display: 'flex', alignItems: 'center'}}
                            >
                              <button
                                type="button"
                                className="radioButton"
                                onClick={() => {
                                  setUnitType('core');
                                  setIsCore(true);
                                  setIsElective(false);
                                }}
                              >
                                {isCore ? (
                                  <img
                                    src={radioIcon}
                                    alt="checked"
                                    width="12px"
                                    height="12px"
                                  />
                                ) : (
                                  ''
                                )}
                              </button>
                              <button
                                type="button"
                                className="text-button"
                                onClick={() => {
                                  setIsCore(true);
                                  setUnitType('core');
                                  setIsElective(false);
                                }}
                              >
                                Core
                              </button>
                            </div>
                            <div
                              style={{display: 'flex', alignItems: 'center'}}
                            >
                              <button
                                type="button"
                                className="radioButton"
                                onClick={() => {
                                  setUnitType('elective');
                                  setIsElective(true);
                                  setIsCore(false);
                                }}
                              >
                                {isElective ? (
                                  <img
                                    src={radioIcon}
                                    alt="checked"
                                    width="12px"
                                    height="12px"
                                  />
                                ) : (
                                  ''
                                )}
                              </button>
                              <button
                                type="button"
                                className="text-button1"
                                onClick={() => {
                                  setUnitType('elective');
                                  setIsElective(true);
                                  setIsCore(false);
                                }}
                              >
                                Elective
                              </button>
                            </div>
                          </div>
                          {/* <Typeahead
                            id="basic-typeahead-single"
                            ref={unitTypeValue}
                            onChange={value => {
                              setUnitType(value[0].type);
                            }}
                            labelKey="type"
                            value={unitType}
                            placeholder="Choose unit type"
                            options={option}
                          /> */}
                        </div>
                        <div className="date-wrapper2">
                          <CustomTextInput
                            label="Unit Code"
                            error={unitCodeError}
                            value={unitCode}
                            onChange={e => {
                              setUnitCode(e.target.value);
                              setValidateUnitError('');
                              setUnitNameError('');
                              setUnitCodeError('');
                            }}
                            placeholder="Enter Unit Code"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                          />
                        </div>
                        <div className="date-wrapper3">
                          <CustomTextInput
                            label="Unit Name"
                            error={unitNameError}
                            value={unitName}
                            onChange={e => {
                              setUnitName(e.target.value);
                              setValidateUnitError('');
                              setUnitNameError('');
                              setUnitCodeError('');
                            }}
                            placeholder="Enter Unit Name"
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                          />
                        </div>
                      </div>
                      <div className="button-container">
                        <CustomCancel
                          className="save-changes"
                          bgColor="#fff"
                          padding="9px 14px"
                          onClick={() => {
                            if (
                              !unitCode?.length ||
                              !unitName?.length ||
                              !unitType?.length
                            ) {
                              return;
                            }
                            const data = {
                              unitCode,
                              unitName,
                              unitType,
                            };

                            dispatch(
                              individualUnitCheckByQualId(
                                data,
                                qualificationData,
                                validate => {
                                  if (validate === '') {
                                    const arraysUnitName = addUnits.map(
                                      student => student.unitName,
                                    );
                                    const arraysUnitCode = addUnits.map(
                                      student => student.unitCode,
                                    );

                                    if (
                                      !arraysUnitName.includes(unitName) &&
                                      !arraysUnitCode.includes(unitCode)
                                    ) {
                                      setAddUnits(state => [
                                        ...state,
                                        {
                                          unitCode,
                                          unitName,
                                          unitType,
                                        },
                                      ]);
                                      setUnitName('');
                                      setUnitType('');
                                      setIsElective(false);
                                      setIsCore(false);
                                      setUnitCode('');
                                      setUnitNameError('');
                                      setUnitCodeError('');
                                      setValidateUnitError('');
                                      // setDisabled(false);
                                    } else {
                                      if (arraysUnitName.includes(unitName)) {
                                        setUnitNameError(
                                          'This unit name Already added in this list',
                                        );
                                      }
                                      if (arraysUnitCode.includes(unitCode)) {
                                        setUnitCodeError(
                                          'This unit code Already added in this list',
                                        );
                                      }
                                    }
                                  }
                                },
                              ),
                            );
                          }}
                        >
                          <img
                            src={addIcons}
                            alt="icon"
                            style={{marginRight: '8px'}}
                          />
                          <span className="add-button-text">
                            {addUnits.length > 0 ? 'Add to list' : 'Add Unit'}
                          </span>
                        </CustomCancel>
                      </div>
                    </div>

                    <div>
                      {addUnits.length > 0 ? (
                        <div className="seperator-table mt-2" />
                      ) : null}
                      {tableData && manual && addUnits.length > 0 ? (
                        <ManualUnitTableStyles>
                          <Table
                            columns={unitsManualColumns}
                            data={addUnitsData}
                            itemName="Units"
                            maxWidth="790px"
                            isSortedBy
                          />
                        </ManualUnitTableStyles>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {/* ) : null} */}
                </div>
              ) : (
                <div
                  className="inputfields"
                  style={spreadNext !== 0 ? {padding: '0px 0px 0px 0px'} : null}
                >
                  {upload && spreadNext === 0 ? (
                    <div className="org-image-full">
                      <div className="org-image-after">
                        <img src={uploadSuccess} alt="logo" />
                        <div className="file-details">
                          <span className="file-name">
                            {upload ? upload?.name : ''}
                          </span>
                          <span className="file-size">
                            {convertFileSize(upload ? upload?.size : '')}
                          </span>
                        </div>
                      </div>
                      <div className="delete-logo">
                        <button
                          type="button"
                          onClick={() => {
                            setUpload(null);
                            setNextShow(true);
                          }}
                        >
                          <img src={deletelogo} alt="logo" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onDragEnter={() => {
                        setOnDrag(true);
                      }}
                      onDragLeave={() => {
                        setOnDrag(false);
                      }}
                    >
                      {spreadNext === 0 ? (
                        <div
                          className={`org-image ${onDrag ? 'logo-drag' : ''}`}
                        >
                          <div className="org-logo">
                            <img src={files} className="logo" alt="logo" />
                          </div>
                          <div className="org-text">
                            Drop your file here or{' '}
                            <input
                              type="file"
                              onChange={e => {
                                setUpload(e.target.files[0]);
                                handleDrop(e);
                                setNextShow(false);
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                            />
                            <span className="box__dragndrop">Browse</span>
                          </div>
                          <span className="supports">Supports : XLS, SLV</span>
                          <div className="org-seperator" />
                          <div className="org-size">
                            <span>Download</span>
                            <a
                              ref={downloadRef}
                              download
                              href="https://junglecat-files.s3.ap-southeast-2.amazonaws.com/CSV-aa4da2f55484a13c8760707f1dffc872_1593394944.csv"
                            >
                              &nbsp;Sample.xls
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                  {isValidating ? (
                    <div className="loader" style={{height: '350px'}}>
                      <Loader />
                      <h6 className="mt-3">Validating imported unit details</h6>
                      <p className="please">Please wait</p>
                    </div>
                  ) : null}
                  {isOnStepTwo &&
                  validUnitsToQualification?.length === spreadSheet?.length &&
                  alreadyExistUcode?.length === 0 ? (
                    <div className="loader" style={{height: '400px'}}>
                      <img src={greenTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        {`${spreadSheet?.length} Units has been added successfully to    
                        ${qualificationData?.qualificationName}
                        `}
                      </h2>
                      <p className="state">
                        Under each qualification, you may now assign a class
                        with relevant units.
                      </p>
                    </div>
                  ) : null}
                  {isOnStepTwo &&
                  validUnitsToQualification?.length === 0 &&
                  alreadyExistUcode?.length > 0 ? (
                    <div className="loader" style={{height: '400px'}}>
                      <img src={yellowTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        No units added to the qualification
                      </h2>
                      <p className="state">
                        All the units already exist in this organisation.
                      </p>
                    </div>
                  ) : null}
                  {isOnStepTwo &&
                  validUnitsToQualification?.length > 0 &&
                  alreadyExistUcode?.length > 0 ? (
                    <div className="loader mt-5 mb-3">
                      <img src={greenTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        {`${validUnitsToQualification?.length}/${spreadSheet?.length} units can be
                        added to ${qualificationData?.qualificationName}`}
                      </h2>
                      <p className="state">
                        Some of the entries are incorrect. You can keep adding
                        valid unit entries or re-upload the document with the
                        changes.
                      </p>
                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow}
                              >
                                <div>
                                  Invalid Entries ({alreadyExistUcode?.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseTwo"
                            className={`collapse ${showShow ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {uCodeError && uCodeError?.length > 0 ? (
                                <SpreadSheetUnitTableStyles>
                                  <Table
                                    columns={unitsColumns}
                                    data={alreadyExistUcodeData}
                                    itemName="Units"
                                    maxWidth="790px"
                                    isSortedBy
                                  />
                                </SpreadSheetUnitTableStyles>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {tableData &&
              !isValidating &&
              spreadSheet?.length > 0 &&
              spreadNext === 1 ? (
                <SpreadSheetUnitTableStyles>
                  <Table
                    columns={unitsColumns}
                    data={spreadSheetUnitData}
                    itemName="Units"
                    maxWidth="790px"
                    isSortedBy
                  />
                </SpreadSheetUnitTableStyles>
              ) : (
                ''
              )}
            </CustomBox>
          </div>
        </AddUnitToQualificationContainer>
        <AddUnitToQualificationFooter>
          <div className="add-unit-footer fixed-bottom">
            {manual ? (
              <div className="add-unit-title">
                <div />

                <div className="button-container">
                  <div />

                  <div className="action-button">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      onClick={() => {
                        setUpload(null);
                        setAddUnits([]);

                        dispatch(setValidateUnitError(''));
                        setUnitName('');
                        setUnitType('');
                        setIsElective(false);
                        setIsCore(false);
                        setUnitCode('');
                        setUnitNameError('');
                        setUnitCodeError('');

                        history.replace(`/admin/qualifications`);
                      }}
                    >
                      <span className="cancel1-button-text">Back</span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={!addUnits?.length}
                      className="save-changes"
                      text="Next"
                      onClick={() => {
                        if (addUnits?.length) {
                          dispatch(
                            addUnitsByQualificationIdManual(
                              addUnits,
                              qualificationData,
                              () => {
                                setAddUnits([]);
                              },
                            ),
                          );
                          history.replace(`/admin/qualifications`);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="add-unit-spread">
                {spreadNext === 0 ? (
                  <div className="add-unit-title">
                    <div />

                    <div className="button-container">
                      <div />

                      <div className="action-button">
                        <CustomCancel
                          className="cancel1"
                          bgColor="#fff"
                          onClick={() => {
                            setUpload(null);
                            setSpreadSheet([]);
                            setAddUnits([]);

                            history.replace(`/admin/qualifications`);
                          }}
                        >
                          <span className="cancel1-button-text">Back</span>
                        </CustomCancel>
                        <CustomButton
                          isDisabled={!spreadSheet?.length || nextShow}
                          className="save-changes"
                          text="Next"
                          onClick={() => {
                            if (spreadSheet?.length) {
                              setSpreadNextShow(state => state + 1);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {spreadNext === 1 ? (
                  <div className="add-unit-title">
                    <div />

                    <div className="button-container">
                      <div />
                      <div className="action-button">
                        <CustomCancel
                          className="cancel1"
                          bgColor="#fff"
                          isDisabled={isValidating}
                          onClick={() => {
                            setUpload(null);
                            setSpreadNextShow(0);
                            setSpreadSheet([]);
                          }}
                        >
                          <span className="cancel1-button-text">Back</span>
                        </CustomCancel>
                        <CustomButton
                          isDisabled={
                            !spreadSheet?.length || nextShow || isValidating
                          }
                          className="save-changes"
                          text="Next"
                          onClick={() => {
                            setIsValidating(true);
                            if (spreadSheet.length) {
                              dispatch(
                                spreadSheetUnitCheckByQualificationId(
                                  spreadSheet,
                                  qualificationData,
                                  () => {
                                    setTimeout(() => {
                                      setIsValidating(false);
                                      setSpreadNextShow(state => state + 1);
                                    }, 3000);
                                  },
                                ),
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {spreadNext === 2 &&
                validUnitsToQualification?.length === spreadSheet?.length &&
                alreadyExistUcode?.length === 0 ? (
                  <div className="add-unit-title">
                    <div />

                    <div className="button-container">
                      <div />
                      <div>
                        <CustomButton
                          isDisabled={!spreadSheet?.length || nextShow}
                          className="save-changes"
                          text="Done"
                          onClick={() => {
                            setSpreadNextShow(state => state + 1);
                            dispatch(
                              setQualificationToastSuccess(
                                `${qualificationData?.qualificationName} has been added successfully with ${validUnitsToQualification?.length} Units `,
                              ),
                            );
                            setTimeout(
                              () => dispatch(setQualificationToastSuccess('')),
                              5000,
                            );

                            history.replace(`/admin/qualifications`);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {spreadNext === 2 &&
                validUnitsToQualification?.length > 0 &&
                alreadyExistUcode?.length > 0 ? (
                  <div className="add-unit-title">
                    <div />

                    <div className="button-container">
                      <CustomCancel
                        className="cancel1"
                        bgColor="#fff"
                        onClick={() => {
                          setUpload(null);
                          setSpreadNextShow(0);
                          setSpreadSheet([]);
                        }}
                      >
                        <span className="cancel1-button-text">
                          Upload again
                        </span>
                      </CustomCancel>
                      <CustomButton
                        isDisabled={!spreadSheet?.length || nextShow}
                        className="save-changes"
                        text="Add Valid Entries"
                        onClick={() => {
                          if (validUnitsToQualification?.length > 0) {
                            if (qualificationData) {
                              dispatch(
                                addUnitsByQualIdSpread(
                                  validUnitsToQualification,
                                  qualificationData,
                                  data => {
                                    dispatch(
                                      setQualificationToastSuccess(
                                        `${qualificationData?.qualificationName} has been added successfully with ${data.length} Units `,
                                      ),
                                    );
                                    setTimeout(
                                      () =>
                                        dispatch(
                                          setQualificationToastSuccess(''),
                                        ),
                                      5000,
                                    );
                                    setSpreadSheet([]);
                                  },
                                ),
                              );
                            }
                          }
                          history.replace(`/admin/qualifications`);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {spreadNext === 2 &&
                validUnitsToQualification?.length === 0 &&
                alreadyExistUcode?.length > 0 ? (
                  <div className="add-unit-title">
                    <div />
                    <div className="button-container">
                      <div />
                      <div>
                        <CustomButton
                          isDisabled={!spreadSheet?.length || nextShow}
                          className="save-changes"
                          text="Done"
                          onClick={() => {
                            history.replace(`/admin/qualifications`);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </AddUnitToQualificationFooter>
      </div>
    </>
  );
};

export default AddUnitsToQualification;
