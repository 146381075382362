import styled from 'styled-components';

export const EvaluationContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .evaluation-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .second-div {
      margin-top: 0.562rem;
    }
    .first-div,
    .second-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content,
    .second-div {
      width: 100%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }

    .content {
      font-weight: 600;
      font-size: 0.875rem;
      margin-left: 1.687rem;
      text-align: justify;
      color: #3d4457;
      margin-top: 0.125rem;
    }
  }
`;
