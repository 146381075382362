import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const AllAcademicYearsContainer = styled(AdminListingPageContainer)`
  .serial {
    width: 5%;
  }

  .start-date {
    width: 45%;
  }

  .end-date {
    width: 45%;
  }

  .more-option {
    width: 5%;
  }
  .table-container,
  .academicyear-header {
    max-width: 55.5rem;
    margin: auto;
    @media (max-width: 55.5rem) {
      .dropdowncontainer {
        right: -4rem;
      }
      .icon {
        transform: rotate(90deg);
      }
    }
  }
`;
