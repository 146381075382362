/* eslint-disable react/prop-types */

import {useState} from 'react';
import {Controller} from 'react-hook-form';

import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import TextModal from '../TextModal';

const dummyText =
  "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.";

const PathwaysForm = ({control, errors, onUpdate, tasStatus}) => {
  const [isRModalOpen, setIsRModalOpen] = useState(false);

  return (
    <div className="section" id="pathways">
      <h3>Pathways</h3>
      <p>
        Where your RTO offers an assessment-only pathway, develop and implement
        strategies that cover:
        <br />
        Assessment methods, timing and resources.
        <br />
        How issues will be addressed (for example, if a student does not achieve
        the competency requirements).
      </p>
      <form>
        <div className="tas-form-row">
          <div className="is-full-width is-read-only tas-form-column">
            <div className="column-title">
              Pathways{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value" style={{whiteSpace: 'pre-line'}}>
              NIL
              {/* {dummyText.slice(0, 720)}
              {dummyText.length > 720 ? '...' : ''}
              <button onClick={() => setIsRModalOpen(true)} type="button">
                See more
              </button> */}
            </div>
            <TextModal
              content={dummyText}
              isVisible={isRModalOpen}
              onClose={() => setIsRModalOpen(false)}
              title="Pathways"
            />
          </div>
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="rtoPathways"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.rtoPathways}
                hasInfo="The RTO’s pathways connect courses for students between vocational training and higher education."
                label="RTO Further Pathways"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled={tasStatus}
                rows={5}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="creditTransfer"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.creditTransfer}
                hasInfo="RPL is the acknowledgement of a person's skills and knowledge acquired through previous relevant training, work or life experience, which may be used to grant status or credit in a subject or a whole program (qualification). A student may apply for RPL after enrolling with the RTO. The student must supply evidence to substantiate the claim for credit.<br/><br/>
Credit transfer is an administrative process, where a training provider recognises and accepts Australian Qualifications Framework (AQF) qualifications and statements of attainment issued by another RTO."
                label="Recognition Prior Learning / Credit Transfer"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled={tasStatus}
                rows={5}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="studentLifecycle"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.studentLifecycle}
                hasInfo="The RTO describes the <b>Key phases of the student journey </b><br/><br/>
<b>Marketing and recruitment</b><br/>
<b>Enrolment</b><br/>
<b>Support and progression</b><br/>
<b>Training and assessment </b><br/>
<b>Completion</b>"
                label="Student Lifecycle"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled={tasStatus}
                rows={5}
              />
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default PathwaysForm;
