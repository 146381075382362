/* eslint-disable react/prop-types */

import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import AssignedIcon from '../../../../../../../assets/icons/assigned.svg';

import GradedIcon from '../../../../../../../assets/icons/graded.svg';
import ProfileIcon from '../../../../../../../assets/icons/profile.svg';
import ReassignedIcon from '../../../../../../../assets/icons/reassigned.svg';
import ReassignedSmallIcon from '../../../../../../../assets/icons/reassign-small.svg';
import RemindIcon from '../../../../../../../assets/icons/remind-small.svg';
import ResubmittedIcon from '../../../../../../../assets/icons/resubmitted.svg';
import WhiteArrow from '../../../../../../../assets/icons/right-arrow.svg';
import GrayArrow from '../../../../../../../assets/icons/right-arrow-alt.svg';
import SubmittedIcon from '../../../../../../../assets/icons/submitted.svg';
// import NoGrade from '../../../../../../../assets/icons/no-data-grade.svg';
import ResubmittApprovedIcon from '../../../../../../../assets/icons/resubmission-approved.svg';
import ResubmittDeclinedIcon from '../../../../../../../assets/icons/resubmission-declined.svg';
import Badge from '../../../../../../../components/shared/Badge';
import SelectBox from '../../../../../../../components/shared/SelectBox';
import {showToast} from '../../../../../../../components/common/Toast';

import {Spacer} from '../../../../../../../components/common';

import SearchInput from '../../../../../../../components/shared/Inputs/SearchInput';

import {
  reassignAssessment,
  resubmitAssessment,
  revokeStudent,
  sendReminder,
} from '../../../Assessment/slices/assessmentSlice';

import {
  StudentsDetailsContainer,
  StudentsDetailsTableContainer,
} from './elements';

import {StudentActionsDropdown} from './components';

const prependZero = value => (value < 10 ? `0${value}` : value.toString());

export const StudentsDetails = ({
  assessment,
  onReassign,
  onRevokeStudent,
  onResubmitAssessment,
  onSendReminder,
  isArchived,
}) => {
  const sortByButton = useRef();
  const [assignedCount, setAssignedCount] = useState(0);
  const [filter, setFilter] = useState(null);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filterLabel, setFilterLabel] = useState('Sort By');
  const [gradedCount, setGradedCount] = useState(0);
  const [reassignedCount, setReassignedCount] = useState(0);
  const [search, setSearch] = useState('');
  const [isSortByOpen, setIsSortByOpen] = useState(false);
  const [submittedCount, setSubmittedCount] = useState(0);

  const history = useHistory();
  const {assessmentID} = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        sortByButton.current &&
        !sortByButton.current.contains(event.target)
      ) {
        setIsSortByOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [sortByButton]);
  useEffect(() => {
    if (assessment.instance) {
      let assignedStudentsCount = 0;
      let gradedStudentsCount = 0;
      let reassignedStudentsCount = 0;
      let submittedStudentsCount = 0;

      assessment.instance.students.forEach(student => {
        // eslint-disable-next-line default-case
        switch (student.aouis_status) {
          case 1:
            assignedStudentsCount += 1;
            break;
          case 2:
            submittedStudentsCount += 1;
            break;
          case 3:
            gradedStudentsCount += 1;
            break;
          case 4:
            reassignedStudentsCount += 1;
            break;
        }
      });

      setAssignedCount(assignedStudentsCount);
      setGradedCount(gradedStudentsCount);
      setReassignedCount(reassignedStudentsCount);
      setSubmittedCount(submittedStudentsCount);
    }
  }, [assessment.instance]);

  useEffect(() => {
    const filteredStudentsByStatus = filter
      ? assessment?.instance?.students.filter(
          item => item.aouis_status === filter,
        )
      : assessment.instance.students;

    const filteredStudentsBySearch = filteredStudentsByStatus.filter(item => {
      const name = `${item.profile.up_name_first} ${item.profile.up_name_last}`;

      return name.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredStudents(filteredStudentsBySearch);

    switch (filter) {
      case 1:
        setFilterLabel('Assigned');
        break;
      case 2:
        setFilterLabel('Submitted');
        break;
      case 3:
        setFilterLabel('Graded');
        break;
      case 4:
        setFilterLabel('Reassigned');
        break;
      default:
        setFilterLabel('Sort By');
    }
  }, [assessment?.instance?.students, filter, search]);

  useEffect(() => ReactTooltip.rebuild(), [filteredStudents]);

  if (!assessment.instance) return null;

  return (
    <StudentsDetailsContainer className="student-details">
      <div className="top-bar">
        <div className="top-bar-hideshow">
          <div className="title-div">
            <h3>{filterLabel === 'Sort By' ? 'All' : filterLabel} Students</h3>
          </div>
          <div className="sort-by-hide">
            <SelectBox
              isOpen={isSortByOpen}
              items={[
                {
                  name: 'Show All',
                  onClick: () => setFilter(null),
                },
                {
                  name: 'Submitted',
                  onClick: () => setFilter(2),
                },
                {
                  name: 'Assigned',
                  onClick: () => setFilter(1),
                },
                {
                  name: 'Reassigned',
                  onClick: () => setFilter(4),
                },
                {
                  name: 'Graded',
                  onClick: () => setFilter(3),
                },
              ]}
              label={filterLabel}
              onClick={() => setIsSortByOpen(previousState => !previousState)}
              reference={sortByButton}
              width="154px"
            />
          </div>
        </div>
        <div className="badges">
          <Badge
            borderColor="#ff677a"
            icon={SubmittedIcon}
            isActive={filter === 2}
            label="Submitted"
            onClick={() => setFilter(current => (current === 2 ? null : 2))}
            value={prependZero(submittedCount)}
          />
          <Badge
            borderColor="#40a1ff"
            icon={AssignedIcon}
            isActive={filter === 1}
            label="Assigned"
            onClick={() => setFilter(current => (current === 1 ? null : 1))}
            value={prependZero(assignedCount)}
          />
          <Badge
            borderColor="#40a1ff"
            icon={ReassignedSmallIcon}
            isActive={filter === 4}
            label="Reassigned"
            onClick={() => setFilter(current => (current === 4 ? null : 4))}
            value={prependZero(reassignedCount)}
          />
          <div className="graded-button">
            <Badge
              borderColor="#28b48c"
              icon={GradedIcon}
              isActive={filter === 3}
              label="Graded"
              onClick={() => setFilter(current => (current === 3 ? null : 3))}
              value={prependZero(gradedCount)}
            />
          </div>
          <div className="sort-by-default">
            <SelectBox
              isOpen={isSortByOpen}
              items={[
                {
                  name: 'Show All',
                  onClick: () => setFilter(null),
                },
                {
                  name: 'Submitted',
                  onClick: () => setFilter(2),
                },
                {
                  name: 'Assigned',
                  onClick: () => setFilter(1),
                },
                {
                  name: 'Reassigned',
                  onClick: () => setFilter(4),
                },
                {
                  name: 'Graded',
                  onClick: () => setFilter(3),
                },
              ]}
              label={filterLabel}
              onClick={() => setIsSortByOpen(previousState => !previousState)}
              reference={sortByButton}
              width="154px"
            />
          </div>
        </div>
      </div>
      <StudentsDetailsTableContainer>
        <div className="table-header">
          <p>
            {filteredStudents.length}{' '}
            {filteredStudents.length === 1 ? 'Student' : 'Students'} Listed
          </p>
          <SearchInput
            onChange={event => setSearch(event.target.value)}
            placeholder="Search for a student"
            value={search}
            width="300px"
            className="search-student"
          />
        </div>
        <table className="student-assess-list">
          <tr>
            <th className="profile">Student</th>
            <th className="id">ID</th>
            <th className="status">Status</th>
            <th className="actions">&nbsp;</th>
          </tr>
          {filteredStudents.map(student => {
            const name = `${student.profile.up_name_first} ${student.profile.up_name_last}`;
            let statusText = 'Assigned';

            if (
              student.aouis_status === 2 ||
              student.aouis_status === 5 ||
              student.aouis_status === 6
            ) {
              statusText = 'Submitted';
            } else if (student.aouis_status === 3) {
              statusText = 'Graded';
            } else if (student.aouis_status === 4) {
              statusText = 'Reassigned';
            }

            return (
              <tbody>
                <tr key={student.aouis_id_activityorgunitinstancestudent}>
                  <td className="profile" data-label="Student">
                    <img
                      alt={name}
                      className="profile-icon rounded-circle"
                      src={student.profile.up_avatar || ProfileIcon}
                    />
                    <span className="profile-name">{name}</span>
                  </td>
                  <td className="id" data-label="ID">
                    {student.profile.up_org_reference}
                  </td>
                  <td className="status" data-label="Status">
                    {statusText === 'Reassigned' ? (
                      <>
                        <div className="status-button">
                          <div
                            className={statusText.toLowerCase()}
                            data-html
                            data-tip={`Reassigned on: <span class="tooltip-value">${moment(
                              student.aouis_timestamp_updated,
                            ).format('D MMMM YYYY')}</span>`}
                            style={{
                              backgroundColor: student.ass_bgcolor,
                              color: student.ass_color,
                            }}
                          >
                            <img alt="Reassigned" src={ReassignedIcon} />
                            {statusText}
                          </div>
                          <ReactTooltip
                            backgroundColor="#3d4457"
                            effect="solid"
                            place="bottom"
                          />
                          {student.aouis_timestamp_reminded &&
                          student.aouis_timestamp_reminded !==
                            '0000-00-00 00:00:00' ? (
                            <div
                              className="reminded-indicator"
                              data-html
                              data-tip={`Reminded on: <span class="tooltip-value">${moment(
                                student.aouis_timestamp_reminded,
                              ).format('D MMMM YYYY')}</span>`}
                            >
                              <img alt="Reminded" src={RemindIcon} />
                            </div>
                          ) : null}
                          <ReactTooltip
                            backgroundColor="#3d4457"
                            effect="solid"
                            place="bottom"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="status-button">
                          <div
                            className={statusText.toLowerCase()}
                            style={{
                              backgroundColor: student.ass_bgcolor,
                              color: student.ass_color,
                            }}
                          >
                            {statusText}
                          </div>
                          {statusText === 'Assigned' &&
                          student.aouis_timestamp_reminded &&
                          student.aouis_timestamp_reminded !==
                            '0000-00-00 00:00:00' ? (
                            <div
                              className="reminded-indicator"
                              data-html
                              data-tip={`Reminded on: <span class="tooltip-value">${moment(
                                student.aouis_timestamp_reminded,
                              ).format('D MMMM YYYY')}</span>`}
                            >
                              <img alt="Reminded" src={RemindIcon} />
                            </div>
                          ) : null}
                          {student.aouis_status === 4 ? (
                            <div className="resubmitted-indicator">
                              <img
                                alt="Resubmission Requested"
                                src={ResubmittedIcon}
                              />
                            </div>
                          ) : null}
                          <ReactTooltip
                            backgroundColor="#3d4457"
                            effect="solid"
                            place="bottom"
                          />
                        </div>
                      </>
                    )}
                  </td>
                  <td className="actions" data-label=" ">
                    <div
                      className={`action-div ${
                        statusText === 'Graded' ? 'flex-row' : ''
                      }`}
                    >
                      {(statusText === 'Submitted' &&
                        student.aouis_status === 2) ||
                      student.aouis_status === 6 ? (
                        <div>
                          <button
                            className="align-items-center d-flex evaluate-button justify-content-center"
                            onClick={() =>
                              history.push({
                                pathname: `${history.location.pathname}/${student.aouis_id_userprofile_student}`,
                                state: {
                                  studentName: `${student.profile.up_name_first} ${student.profile.up_name_last}`,
                                  studentSubmissionTimestamp:
                                    student.aouis_timestamp_submission,
                                },
                              })
                            }
                            type="button"
                          >
                            Evaluate Now
                            <img alt="Evaluate" src={WhiteArrow} />
                          </button>
                        </div>
                      ) : null}
                      <div className="view-button-div">
                        {statusText === 'Graded' ? (
                          <button
                            className="view-button"
                            onClick={() =>
                              history.push({
                                pathname: `${history.location.pathname}/${student.aouis_id_userprofile_student}`,
                                state: {
                                  studentName: `${student.profile.up_name_first} ${student.profile.up_name_last}`,
                                  studentSubmissionTimestamp:
                                    student.aouis_timestamp_submission,
                                },
                              })
                            }
                            type="button"
                          >
                            <img alt="View" src={GrayArrow} />
                          </button>
                        ) : null}
                      </div>
                      {student.aouis_status === 5 ? (
                        <button
                          className="view-request-button"
                          onClick={() =>
                            onResubmitAssessment(
                              (status, feedBack) =>
                                dispatch(
                                  resubmitAssessment(
                                    student.aouis_id_activityorgunitinstancestudent,
                                    assessmentID,
                                    history.location.pathname.split('/')[2],
                                    status,
                                    feedBack,
                                    () => {
                                      if (status === 6) {
                                        showToast(
                                          ResubmittDeclinedIcon,
                                          `Resubmission Declined for ${name}`,
                                          true,
                                        );
                                      }
                                      if (status === 1) {
                                        showToast(
                                          ResubmittApprovedIcon,
                                          `Resubmission approved for ${name}`,
                                        );
                                      }
                                    },
                                  ),
                                ),
                              name,
                              student.aouis_resubmission_note_student,
                              student.aouis_timestamp_request_resubmission_student,
                            )
                          }
                          type="button"
                        >
                          View Request
                          <img alt="Evaluate" src={WhiteArrow} />
                        </button>
                      ) : null}
                      <StudentActionsDropdown
                        isArchived={isArchived}
                        isGraded={statusText === 'Graded'}
                        isSubmitted={statusText === 'Submitted'}
                        onReassign={() =>
                          onReassign(
                            comments =>
                              dispatch(
                                reassignAssessment(
                                  student.aouis_id_activityorgunitinstancestudent,
                                  assessmentID,
                                  history.location.pathname.split('/')[2],
                                  comments,
                                ),
                              ),
                            name,
                          )
                        }
                        onRevokeStudent={() =>
                          onRevokeStudent(
                            () =>
                              dispatch(
                                revokeStudent(
                                  student.aouis_id_activityorgunitinstancestudent,
                                  assessmentID,
                                  history.location.pathname.split('/')[2],
                                ),
                              ),
                            name,
                          )
                        }
                        onSendReminder={() =>
                          onSendReminder(
                            () =>
                              dispatch(
                                sendReminder(
                                  student.aouis_id_activityorgunitinstancestudent,
                                  assessmentID,
                                  history.location.pathname.split('/')[2],
                                ),
                              ),
                            name,
                          )
                        }
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </StudentsDetailsTableContainer>
      <Spacer height="7rem" />
    </StudentsDetailsContainer>
  );
};
