import styled from 'styled-components';

import {Modal} from '../../../../../../../components/common';

export const EditClassContainer = styled(Modal)`
  .row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .field-left,
    .field-right {
      width: 100%;
    }
    .field-left {
      margin-right: 1.5rem;
    }
  }
`;
