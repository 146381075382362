import styled from 'styled-components';
// import Button from '../../../components/shared/Buttons/GreenButton';
import CustomButton from '../../../../components/shared/Buttons/CustomButton';
import TickIcon from '../../../../assets/icons/success-student-invitation.svg';
import BackgroundIcon from '../../../../assets/images/login/background.png';
import jcLogo from '../../../../assets/icons/jclogo.svg';
import googlePlay from '../../../../assets/icons/Google-Play.svg';
import ShiftArrowLogo from '../../../../assets/icons/success-shift-logo.svg';
import SuccessJcLogo from '../../../../assets/icons/success-jc-signup.svg';
import IphoneLogo from '../../../../assets/icons/success-iphone-logo.svg';

const StyledDiv = styled.div`
  margin: 0 -15px;
  background-image: url(${BackgroundIcon});
  min-height: 100vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 660px) {
    & {
      padding: 3rem 1rem;
    }
  }
  .logo {
    margin-bottom: 32px;
  }
  .sucessess_info {
    width: 660px;
    background: #ffffff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    @media (max-width: 680px) {
      & {
        width: 100%;
      }
    }
    @media (max-width: 432px) {
      & {
        width: 100%;
      }
      .logoContainer {
        display: flex;
        flex-direction: column;
      }
    }
    @media (max-width: 300px) {
      & {
        width: 100%;
      }
      .header {
        padding: 0 25px !important;
      }
      .subtitle {
        padding: 0 25px !important;
      }
      .seperator {
        padding: 0 25px !important;
      }
    }
    .top {
      display: flex;
      margin-top: 28px;
      padding: 32px;
      align-items: center;
      justify-content: center;
      .success-icon {
        width: 60px;
        height: 60px;
      }
      .message-container {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        text-align: left;
      }
    }

    h2 {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #3d4457;
    }

    .info {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #8a8c94;
    }
    .logo-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .divider {
        background-color: #dee7f0;
        height: 1px;
        width: 100%;
        margin-bottom: 2rem;
      }
      .googlePlaylogo {
        width: 135px;
        height: 40px;
      }
      .btn-design {
        outline: unset;
        border: unset;
        background-color: unset;
      }
    }
    .seperator {
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      p {
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #3d464c;
      }

      .bottom {
        background: #ecf6ff;
        border-radius: 8px;
        width: 100%;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .action-button {
          font-weight: 500;
          font-size: 14px;
          color: #40a1ff;
          background-color: unset;
          border: 1px solid #40a1ff;
          width: 100%;
          .link {
            color: #40a1ff;
            text-decoration: none;
          }
        }

        .iphone-logo-container {
          width: 60%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2.375rem;
          @media (max-width: 600px) {
            & {
              width: 100%;
            }
          }
          .success-iphone-icon {
            margin-top: 38px;
            margin-bottom: 8px;
          }
          .success-jc-icon {
            margin-top: 38px;
            margin-bottom: 0.2px;
          }
          .text-testflight,
          .text-web {
            font-weight: 500;
            font-size: 16px;
            color: #3d4457;
          }
        }
      }
    }
  }
`;

const SuccessMessage = () => (
  <StyledDiv className="d-flex flex-column justify-content-center align-items-center">
    <img src={jcLogo} alt="logo" className="logo" />
    <div className="text-center sucessess_info">
      <div className="top">
        <div>
          <img src={TickIcon} alt="password-success" className="success-icon" />
        </div>
        <div className="message-container">
          <h2 className="header mb-2">Your account is created</h2>
          <div className="subtitle">
            <span className="info">
              Your account as a student is created successfully.
            </span>
          </div>
        </div>
      </div>

      <div className="seperator">
        <div className="bottom">
          <p>
            If you are using iOS device. Follow the steps below to download the
            mobile app
          </p>
          <div className="iphone-logo-container">
            <div>
              <img
                src={IphoneLogo}
                alt="iphone-logo"
                className="success-iphone-icon"
              />
              <p className="text-testflight">Get Testflight</p>

              <CustomButton
                type="submit"
                className="action-button"
                borderRadius="0.5rem"
                padding="0.5rem 1rem"
              >
                <a
                  href="https://apps.apple.com/us/app/testflight/id899247664"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </CustomButton>
            </div>
            <div>
              <img src={ShiftArrowLogo} alt="arrow" className="success-icon" />
            </div>
            <div>
              <img
                src={SuccessJcLogo}
                alt="jc-web"
                className="success-jc-icon"
              />
              <p className="text-web">Download app</p>
              <CustomButton
                type="submit"
                className="action-button"
                borderRadius="0.5rem"
                padding="0.5rem 1rem"
              >
                <a
                  href="https://testflight.apple.com/join/3LIW0739"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Start Using
                </a>
              </CustomButton>
            </div>
          </div>
          <div className="logo-container">
            <div className="divider" />
            <p>If you have an Android device, download here</p>
            <button type="button" className="btn-design">
              <a
                href="https://play.google.com/store/apps/details?id=com.junglecat"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googlePlay} alt="logo" className="googlePlaylogo" />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </StyledDiv>
);

export default SuccessMessage;
