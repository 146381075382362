import styled from 'styled-components';

import {Colors} from '../../../../../../../../../../../../../constants';

const PerformanceCriteriaContainer = styled.main`
  width: 100%;
  overflow-x: scroll;
  .column-headings {
    border-radius: 0.75rem 0.75rem 0 0;
    @media (max-width: 31.25rem) {
      & {
        display: none !important;
      }
    }

    .column-heading {
      color: ${Colors.Text};
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.0125rem;
      line-height: 1rem;
      text-transform: uppercase;

      &.serial-number {
        width: 4%;
      }

      &.performance-criteria {
        width: 45%;
      }

      &.observable-task {
        width: 51%;
      }
    }
  }
`;

export default PerformanceCriteriaContainer;
