/* eslint-disable camelcase */
import moment from 'moment';
import {useEffect, useState, useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {activeClassSelector} from '../../../../../../redux/activeClassSlice';
import {authSelector} from '../../../../../../redux/authSlice';
import CustomModal from '../../../../../../components/shared/CustomModal';
import SessionSuccessCard from '../../../../../Sessions/components/ScheduleSession/elements/SessionSuccessCard';
import scheduleSuccessIcon from '../../../../../../assets/icons/schedule-success.svg';

import Header from './Header';
import StudentsOnline from './StudentsOnline';
import Timeline from './Timeline';
import CustomButton from '../../../../../../components/shared/CustomButton';
import * as sessionApi from '../../../../../../api/session';
import RecordedSessions from './RecordedSessions';

// Dummy data to be replaced.
const RECORDED_SESSIONS = new Array(10).fill({
  date: new Date(2000, 0, 1),
  duration: '10:00',
  thumbnail: 'https://via.placeholder.com/100/000000?text=%20',
  title: 'Prepare poultry dishes',
});

const StudentSessionSidebarContainer = styled.div`
  align-self: flex-start;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.05);
  max-width: 22rem;
  overflow: hidden;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  @media (max-width: 60rem) {
    max-width: unset;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 2rem;
  }
  @media (max-width: 43.75rem) {
    width: calc(100% - 2rem) !important;
  }
`;

const StudentSessionSidebar = () => {
  const {id} = useParams();
  const authState = useSelector(authSelector);
  const activeClassState = useSelector(activeClassSelector);
  const location = useLocation();
  const [scheduledSession, setScheduledSession] = useState(null);
  const [sessionTimelines, setSessionTimelines] = useState([]);
  const [initialIndex, setInitialIndex] = useState(0);
  const closeModal = () => {
    setScheduledSession(null);
  };

  const activeClassStudents = useMemo(
    () =>
      (activeClassState?.activeClasses || [])
        .find(item => item.orui_id_orgunitinstance === id)
        ?.students?.map(item => item.up_id_userprofile),

    [id, activeClassState],
  );
  const onlineClassStudents = useMemo(
    () =>
      (authState?.onlineStudents || []).filter(item =>
        activeClassStudents?.includes(item.up_id_userprofile),
      ),

    [activeClassStudents, authState],
  );

  useEffect(() => {
    sessionApi
      .getSessions({
        instance: id,
        groupBy: 'date',
      })
      .then(res => {
        const {status, sessions} = res.data;
        if (status && sessions.length) {
          const mappedSessions = sessions.map(({date, values}) => ({
            date,
            sessions: values.map(
              ({
                oruil_id_orgunitinstancelesson,
                oruil_name,
                oruil_timestart,
                oruil_timefinish,
              }) => ({
                from: oruil_timestart,
                link: `sessions/${oruil_id_orgunitinstancelesson}`,
                name: oruil_name,
                to: oruil_timefinish,
                id: oruil_id_orgunitinstancelesson,
              }),
            ),
          }));

          const reversedSessions = [...mappedSessions].reverse();
          reversedSessions.some((item, index) => {
            if (
              moment(item.date).isSameOrAfter(moment(new Date().toDateString()))
            ) {
              setInitialIndex(mappedSessions.length - index - 1);
              return true;
            }
            return false;
          });

          setSessionTimelines(mappedSessions);
        }
      });
  }, [id]);

  useEffect(() => {
    const {state} = location;
    if (state?.scheduledSession) {
      const displayDate = moment(state?.scheduledSession?.date).format('LLL');
      setScheduledSession({
        ...state.scheduledSession,
        displayDate,
      });
    }
    return () => {
      setScheduledSession(null);
    };
  }, [location]);

  const liveSessionPreference = authState?.orgPreferences?.find(
    item =>
      item.pr_slug === 'enable_video_session' && Number(item.pr_value) === 1,
  );
  return (
    <StudentSessionSidebarContainer>
      <Header />

      <Timeline
        timelines={sessionTimelines}
        initialIndex={initialIndex}
        liveSessionPreference={liveSessionPreference}
      />

      <StudentsOnline
        students={onlineClassStudents || []}
        totalNumberOfStudents={activeClassStudents?.length}
      />
      {/* List the first 2 recorded sessions. */}
      {liveSessionPreference ? (
        <RecordedSessions recordedSessions={RECORDED_SESSIONS.slice(0, 2)} />
      ) : null}
      <CustomModal isVisible={!!scheduledSession}>
        <SessionSuccessCard>
          <img
            height={110}
            width={110}
            src={scheduleSuccessIcon}
            alt="Schedule success"
            className="mb-4"
          />
          <div className="success-title mb-1">
            Video class scheduled successfully
          </div>
          <div className="success-description mb-4">
            Class scheduled for {scheduledSession?.displayDate}
          </div>
          <CustomButton text="Done" onClick={closeModal} />
        </SessionSuccessCard>
      </CustomModal>
    </StudentSessionSidebarContainer>
  );
};

export default StudentSessionSidebar;
