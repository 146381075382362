/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import ReactBootstrapModal from 'react-bootstrap/Modal';
import Skeleton from 'react-loading-skeleton';

import CloseModalIcon from '../../../../../../../../../../assets/icons/refactor/close-modal.svg';
import {TextModalContainer} from './elements';

const TextModal = ({content, isVisible, onClose, title}) => (
  <ReactBootstrapModal className="tas-text-modal" centered show={isVisible}>
    <TextModalContainer>
      <header>
        <h4>{title}</h4>
        <button onClick={onClose} type="button">
          <img alt="Close Modal" src={CloseModalIcon} />
        </button>
      </header>
      {/* <main
        dangerouslySetInnerHTML={{__html: content}}
        style={{whiteSpace: 'pre-line'}}
      /> */}
      {content?.length ? (
        <div className="content">
          {content?.map(item => (
            <>
              {item?.tac_is_descriptor === 0 ? (
                <div className="assessment-condition-content">
                  <div className="top">
                    <div className="serial">&bull;</div>
                    <div className="title">{item?.tac_condition}</div>
                  </div>
                </div>
              ) : (
                <div>
                  <h6 className="pt-1 mt-2">{item?.tac_condition}</h6>
                </div>
              )}
            </>
          ))}
        </div>
      ) : (
        <div className="m-3">
          <div className="mb-3">
            <Skeleton
              className="unit-name-skeleton"
              height="1rem"
              width="60%"
            />
            <Skeleton height="5rem" width="100%" />
          </div>
          <div className="mb-3">
            <Skeleton
              className="unit-name-skeleton"
              height="1rem"
              width="60%"
            />
            <Skeleton height="5rem" width="100%" />{' '}
          </div>
          <div className="mb-3">
            <Skeleton
              className="unit-name-skeleton"
              height="1rem"
              width="60%"
            />
            <Skeleton height="5rem" width="100%" />
          </div>
        </div>
      )}
    </TextModalContainer>
  </ReactBootstrapModal>
);

export default TextModal;
