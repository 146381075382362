/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import {useState} from 'react';

import {Card, CardContainer, RemoveFileIco} from './elements';
import DocIcon from '../../../../assets/images/filetypes/doc.svg';
import PdfIcon from '../../../../assets/images/filetypes/pdf.svg';
import VideoIcon from '../../../../assets/images/filetypes/video.svg';
import CsvIcon from '../../../../assets/images/filetypes/csv.svg';
import ImgIcon from '../../../../assets/images/filetypes/img.svg';
import RedCloseIcon from '../../../../assets/icons/close-red-variant-2.svg';
import VideoPlayIcon from '../../../../assets/icons/post-video-play.svg';

const fileTypeToImgMap = {
  'application/pdf': PdfIcon,
  'application/msword': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    DocIcon,
  'text/csv': CsvIcon,
  // Images
  'image/jpeg': ImgIcon,
  'image/png': ImgIcon,
  'image/jpg': ImgIcon,
  'image/svg': ImgIcon,
  'image/svg+xml': ImgIcon,
  'image/gif': ImgIcon,
  'image/webp': ImgIcon,
  'image/tiff': ImgIcon,
  'image/psd': ImgIcon,
  'image/raw': ImgIcon,
  'image/bmp': ImgIcon,
  'image/heif': ImgIcon,
  'image/indd': ImgIcon,
  'image/jpeg 2000': ImgIcon,
  // Video
  'video/webm': VideoIcon,
  'video/mp4': VideoIcon,
  'video/mp2': VideoIcon,
  'video/mpeg': VideoIcon,
  'video/mpe': VideoIcon,
  'video/mpv': VideoIcon,
  'video/ogg': VideoIcon,
  'video/3gp': VideoIcon,
  'video/m4p': VideoIcon,
  'video/m4v': VideoIcon,
  'video/avi': VideoIcon,
  'video/wmv': VideoIcon,
  'video/mov': VideoIcon,
  'video/qt': VideoIcon,
  'video/flv': VideoIcon,
  'video/swf': VideoIcon,
  unknown: DocIcon,
};

const FileCard = ({
  file,
  index,
  className,
  fileURL,
  onCardClick,
  removable,
  onRemoveItemClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card
      key={file?.id}
      className={className}
      onClick={() =>
        onCardClick(file.location, file.type, file.s3Name, file.name)
      }
      id={file?.id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {file.type.includes('video') ? (
        <div className="file-blob">
          <video src={fileURL} />
          <img alt="Play Video" src={VideoPlayIcon} />
        </div>
      ) : (
        <img className="file-blob" src={fileURL} alt="card-img" />
      )}
      <p className="file-name">{`${file.name.substr(0, 13)}${
        file.name.length > 13
          ? ` ..${file.name.substring(file.name.lastIndexOf('.') + 1)}`
          : ''
      }`}</p>
      <p className="file-size pr-2">{(file.size / 1000).toFixed(2)} KB</p>

      {removable && onRemoveItemClick ? (
        <RemoveFileIco
          src={RedCloseIcon}
          onClick={() => onRemoveItemClick(index, file.id)}
          style={
            isHovered
              ? {
                  opacity: 1,
                  visibility: 'visible',
                }
              : {}
          }
          id={`remove-${file.id}`}
        />
      ) : null}
    </Card>
  );
};

export const FilesCard = ({files, ...properties}) => (
  <CardContainer>
    {files.map((file, index) => {
      let fileURL =
        file.type.includes('image') || file.type.includes('video')
          ? file.location
          : fileTypeToImgMap[file.type] || DocIcon;

      if (!fileURL) {
        fileURL = URL.createObjectURL(file);
      }

      return (
        <FileCard
          file={file}
          fileURL={fileURL}
          index={index}
          key={fileURL}
          {...properties}
        />
      );
    })}
  </CardContainer>
);
