/* eslint-disable react/prop-types */

import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import PlusBlueIcon from '../../../../../../assets/icons/refactor/plus-blue.svg';
import {
  EmptyState,
  Modal,
  MoreOptions,
  Table,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {
  TableCellButton,
  TableCellLink,
} from '../../../../../../components/common/Table/styles';
import {showToast} from '../../../../../../components/common/Toast';
import {
  deleteQualification,
  fetchAllQualifications,
  qualificationsSelector,
} from '../../../../../../redux/qualificationsSlice';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {ReleasesCell} from '../../components';
import {EditQualificationModal} from './components';
import {AllQualificationsContainer} from './elements';
import {authSelector} from '../../../../../../redux/authSlice';

const DELAY = 500;

export const AllQualifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isListLoading, isQualificationLoading, qualifications} = useSelector(
    qualificationsSelector,
  );
  const {orgPreferences} = useSelector(authSelector);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [editDetails, setEditDetails] = useState(null);

  useEffect(() => {
    dispatch(fetchAllQualifications());
  }, [dispatch]);

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  // Check org preference for create custom qualification is enable
  const qualificationPreference = orgPreferences?.find(
    item =>
      item.pr_slug === 'create_qualification' && Number(item.pr_value) === 1,
  );

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'index',
        className: 'index',
      },
      {
        Header: 'Code',
        accessor: 'qualification.tqual_code',
        className: 'qualification-code',
      },
      {
        Header: 'Qualification Name',
        accessor: 'qualification.tqual_title',
        className: 'qualification-name',
      },
      {
        Header: 'Releases',
        Cell: ({value}) => <ReleasesCell prefix="tqur" releases={value} />,
        accessor: 'qualification.qualReleases',
        className: 'qualification-releases',
      },
      {
        Header: 'No. of Units',
        Cell: ({row: {original}, value}) => {
          const {qualification} = original;

          const navigateTo = path => {
            history.push({
              pathname: `/admin/qualifications/${qualification?.tqual_id_tgovqualification}/${path}`,
              state: {
                qualificationId: qualification?.tqual_id_tgovqualification,
                qualificationName: qualification?.tqual_title,
              },
            });
          };

          return (
            <div>
              {value ? (
                <TableCellLink
                  onClick={() => {
                    navigateTo('units');
                  }}
                  type="button"
                >
                  {value} Units
                </TableCellLink>
              ) : (
                <TableCellButton
                  className="align-items-center d-flex"
                  onClick={() => {
                    navigateTo('add_units');
                  }}
                  type="button"
                >
                  <img alt="Add Button" className="mr-2" src={PlusBlueIcon} />
                  <span>Add Unit</span>
                </TableCellButton>
              )}
            </div>
          );
        },
        accessor: 'qualification.unitCount',
        className: 'unit-count',
      },
      {
        Header: '',
        Cell: ({row: {original}, value}) =>
          !value?.tqual_is_auto_imported ? (
            <div className="align-items-center d-flex justify-content-end w-100">
              <MoreOptions>
                <DropdownItem
                  label="Edit Basic Details"
                  id="edit-basic-details-btn"
                  onClick={() => {
                    setEditDetails({
                      code: value?.tqual_code,
                      id: value?.tqual_id_tgovqualification,
                      name: value?.tqual_title,
                    });
                  }}
                />
                <DropdownItem
                  isDanger
                  label="Delete"
                  id="delete-btn"
                  isDisabled={original?.courses?.length}
                  toolTipHeading="Qualification Cannot be deleted"
                  toolTipSubText="Qualification is assigned in one or more courses"
                  onClick={() => {
                    setDeleteId(value?.tqual_id_tgovqualification);
                    setDeleteName(value?.tqual_title);
                  }}
                />
              </MoreOptions>
            </div>
          ) : null,
        accessor: 'qualification',
        className: 'more-options',
      },
    ],
    [history],
  );

  const data = useMemo(
    () => [
      ...(qualifications || []).map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [qualifications],
  );

  return (
    <AllQualificationsContainer>
      <ListingPageHeader
        buttonLabel="Add Qualification"
        className="mb-4"
        onButtonClick={() => {
          history.push('/admin/qualifications/add-qualifications');
        }}
        buttonShow={!!qualificationPreference}
        title="All Qualifications"
        btnId="add-qualification"
      />
      <div className="table-container">
        {isListLoading ? <TableSpinner /> : null}
        {!isListLoading &&
        Array.isArray(qualifications) &&
        !qualifications?.length ? (
          <EmptyState
            description="It appears that no qualifications have been added to the organisation."
            title="There are no qualifications yet!"
          />
        ) : null}
        {!isListLoading && qualifications?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Qualifications"
            isSortedBy
            maxWidth="70rem"
          />
        ) : null}
      </div>
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this qualification?"
        isDelete
        isButtonLoading={isQualificationLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          dispatch(
            deleteQualification(deleteId, () => {
              setDeleteId(null);
              showToast(false, `${deleteName} has been deleted.`, true);

              setTimeout(() => {
                setDeleteName('');
              }, DELAY);
            }),
          );
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
      <EditQualificationModal
        editDetails={editDetails}
        onHide={clearEditDetails}
      />
    </AllQualificationsContainer>
  );
};
