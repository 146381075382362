import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {classesSelector, setError} from '../../../../../../redux/classesSlice';
import {
  Select,
  TextInput,
  DateInput,
} from '../../../../../../components/common';

import {useErrorMessage} from '../../../../../../utils/hooks';
import {classSchema} from '../../../../../../utils/validation';
import {EditClassContainer} from './elements';

const FIELDS_IN_ORDER = [
  'department',
  'className',
  'academicYear',
  'classTeacher',
  'startDate',
  'endDate',
];

export const EditClassModal = ({editDetails, onHide, onSubmit}) => {
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      department: null,
      className: '',
      academicYear: null,
      classTeacher: null,
      startDate: '',
      endDate: '',
    },
    resolver: yupResolver(classSchema),
  });

  const {
    error: serverError,
    filterAcademicYears,
    filterDepartments,
    filterTeachers,
    isClassesLoading,
  } = useSelector(classesSelector);

  useEffect(() => {
    if (editDetails) {
      setValue('department', {
        label: editDetails.department?.name,
        value: editDetails.department?.id,
      });

      setValue('className', editDetails.className);

      setValue('academicYear', {
        label: editDetails.academicYear?.name,
        value: editDetails.academicYear?.id,
      });
      setValue('classTeacher', {
        label: editDetails.classTeacher?.name,
        value: editDetails.classTeacher?.id,
      });
      setValue('startDate', editDetails.startDate);
      setValue('endDate', editDetails.endDate);
    }
  }, [editDetails, setValue]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  const departmentsOptions = useMemo(
    () => [
      ...(filterDepartments || [])?.map(item => ({
        label: item.od_department_name,
        value: item.od_id_orgdepartment,
      })),
    ],
    [filterDepartments],
  );
  const academicYearsOptions = useMemo(
    () => [
      ...(filterAcademicYears || [])?.map(item => ({
        label: item.oay_year,
        value: item.oay_id_orgacadyear,
        startDate: item.oay_from_date,
        endDate: item.oay_to_date,
      })),
    ],
    [filterAcademicYears],
  );
  const teachersOptions = useMemo(
    () => [
      ...(filterTeachers || [])?.map(item => ({
        label: `${item.up_name_first} ${item.up_name_last}`,
        value: item.up_id_userprofile,
      })),
    ],
    [filterTeachers],
  );
  const onSelectChange = value => {
    setValue('academicYear', value);
    setMinDate(new Date(value.startDate));
    setMaxDate(new Date(value.endDate));
    setValue('startDate', new Date(value.startDate));
    setValue('endDate', new Date(value.endDate));
  };

  return (
    <EditClassContainer
      buttonLabel="Save Changes"
      description="Make changes to the class details"
      isButtonLoading={isClassesLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit class details"
      width="55.5rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container px-4">
          <div className="row-container pb-4">
            <div className="field-left">
              <Controller
                control={control}
                name="department"
                render={fields => (
                  <Select
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.department}
                    isLarge={false}
                    label="Department"
                    options={departmentsOptions}
                    placeholder="Select the department"
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="className"
                render={fields => (
                  <TextInput
                    {...fields}
                    hasError={hasServerError || !!errors.className}
                    label="Class Name"
                    placeholder="Enter Class Name"
                  />
                )}
              />
            </div>
          </div>
          <div className="row-container pb-4">
            <div className="field-left">
              <Controller
                control={control}
                name="academicYear"
                render={fields => (
                  <Select
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.academicYear}
                    isLarge={false}
                    isDisabled
                    label="Academic Year"
                    options={academicYearsOptions}
                    placeholder="Select the Academic Year"
                    onChange={e => {
                      fields.onChange(e);
                      onSelectChange(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="classTeacher"
                render={fields => (
                  <Select
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.classTeacher}
                    isLarge={false}
                    label="Class Teacher"
                    options={teachersOptions}
                    placeholder="Select the class teacher"
                  />
                )}
              />
            </div>
          </div>
          <div className="row-container pb-4">
            <div className="field-left">
              <Controller
                control={control}
                name="startDate"
                render={fields => (
                  <DateInput
                    {...fields}
                    hasError={hasServerError || !!errors.startDate}
                    label="Start Date"
                    placeholder="Select Start Date"
                    dateFormat="d MMM, yyyy"
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="endDate"
                render={fields => (
                  <DateInput
                    {...fields}
                    hasError={hasServerError || !!errors.endDate}
                    label="End Date"
                    placeholder="Select End Date"
                    dateFormat="d MMM, yyyy"
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </EditClassContainer>
  );
};

EditClassModal.defaultProps = {
  editDetails: null,
};

EditClassModal.propTypes = {
  editDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    department: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string.isRequired,

    academicYear: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    classTeacher: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
