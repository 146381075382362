import styled from 'styled-components';

const TasFormHeader = styled.header`
  align-items: center;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;

  > div {
    :first-child {
      align-items: center;
      display: flex;
      height: 5.375rem;

      button {
        background-color: transparent;
        border: none;
        height: 2rem;
        margin-right: 1rem;
        width: 2rem;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      h2 {
        color: ${({theme}) => theme.colors.text};
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.8125rem;
        margin-bottom: 0;

        span {
          color: ${({theme}) => theme.colors.lightText};
        }
      }
    }

    :last-child {
      align-items: center;
      display: flex;

      div {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-right: 0.5rem;

        span {
          color: ${({theme}) => theme.colors.lightText};
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.3125rem;

          :last-child {
            opacity: 0.6;
          }
        }
        button {
          width: 7.563rem;
          height: 2.625rem;
          color: ${({theme}) => theme.colors.primary};
          font-size: 0.875rem;
          padding: 0px;
          border: 0.063rem solid ${({theme}) => theme.colors.primary};
          border-radius: 0.5rem;
          background-color: white;
        }
      }

      button {
        padding: 0.875rem 1.25rem;
      }
    }
  }
  .task-status {
    /* border: none;
    outline: none;
    border-radius: 0.5rem; */
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    width: unset !important;
    margin-right: unset !important;
    .status-1,
    .status-2,
    .status-3,
    .status-4,
    .status-5 {
      border-radius: 0.5rem;
      display: inline-block;
      font-size: 0.875rem;
      padding: 0.9rem 1.2rem;
      display: flex;
      font-weight: 500;
      text-transform: uppercase;
    }
    img {
      margin-left: 0.5rem;
      height: unset !important;
      width: unset !important;
    }
    .status-1 {
      background-color: #fcf3cf;
      color: #fcca3d;
    }
    .status-2 {
      background-color: #f3f3f3;
      color: #8a8c94;
    }
    .status-3 {
      background-color: #e0fff8;
      color: #15d3c8;
    }
    .status-4 {
      background-color: #ffe1e1;
      color: #ff8787;
    }
    .status-5 {
      background-color: #defae8;
      color: #4ec977;
    }
  }

  /* .invitation-status-0 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }
  .invitation-status-1 {
    background-color: #e4f4f1;
    color: #4ec977;
  }
  .invitation-status-2 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  } */
`;

export default TasFormHeader;
