import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const CurriculumContainer = styled(AdminListingPageContainer)`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 3%;
  }

  .course-name {
    width: 65%;
  }

  .modules-count {
    width: 22%;
  }

  .view {
    width: 10%;
  }

  .view-module {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.312rem;
    padding: 0.75rem 1rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
    .viewmodule-arrow {
      margin-left: 0.75rem;
      vertical-align: middle;
    }
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
  .code {
    font-size: 0.875rem;
    color: #8a8c94;
    margin-top: 0.5rem;
  }
  .header-title {
    color: #3d4457;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .modules {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #3d464c;
    margin-bottom: unset;
  }
`;
