import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {Button, Spacer, TextInput} from '../../../../components/common';
import {
  authSelector,
  superAdminLogIn,
  setError,
} from '../../../../redux/authSlice';
import {useErrorMessage} from '../../../../utils/hooks';
import {superadminloginSchema} from '../../../../utils/validation';
import {ErrorAlert, Heading, HeroSection} from '../../components';
import {AuthContainer} from '../../elements';

const FIELDS_IN_ORDER = ['email', 'username', 'password'];

export const SuperAdminLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const emailAddress = params.get('email') ? params.get('email') : '';
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: emailAddress,
      username: '',
      password: '',
    },
    resolver: yupResolver(superadminloginSchema),
  });
  const history = useHistory();
  const {error: serverError, isLoading} = useSelector(authSelector);

  const onSubmit = useCallback(
    data => {
      dispatch(
        superAdminLogIn(data, userProfile => {
          const isAdmin = userProfile.up_id_typeuserprofile === 4;
          history.push(isAdmin ? '/admin' : '/classes');
        }),
      );
    },
    [dispatch, history],
  );

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {errorMessage, hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <AuthContainer className="no-gutters row">
      <Helmet>
        <title>Super Admin Login | JungleCat</title>
      </Helmet>
      <HeroSection />
      <section className="align-items-center col-lg-6 col-md-12 d-flex flex-column justify-content-center main-section position-relative px-4">
        <ErrorAlert isVisible={!!errorMessage} message={errorMessage} />
        <main className="d-flex flex-column main-content w-100">
          <Spacer height="4rem" />
          <div className="d-flex flex-column justify-content-center flex-grow-1">
            <Heading
              subtitle="Enter your credentials to access your account."
              title="Welcome Back"
            />
            <form
              className="align-items-center d-flex flex-column w-100"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="email"
                render={fields => (
                  <TextInput
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.email}
                    isLarge
                    label="Email"
                    placeholder="Enter user email address"
                    id="email"
                  />
                )}
              />
              <Controller
                control={control}
                name="username"
                render={fields => (
                  <TextInput
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.username}
                    isLarge
                    label="Admin User name"
                    placeholder="Enter your user name"
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={fields => (
                  <TextInput
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.password}
                    isLarge
                    label="Admin Password"
                    placeholder="Enter your password"
                    type="password"
                    id="password"
                  />
                )}
              />
              <Button
                isLarge
                isLoading={isLoading}
                label="Log In"
                type="submit"
                id="submit"
                className="mt-3"
              />
            </form>
          </div>
          <Spacer height="4rem" />
        </main>
      </section>
    </AuthContainer>
  );
};
