import styled, {keyframes} from 'styled-components';

const fadein = keyframes`
    from {
      opacity: 0;
      height: 0%;
    }
    to {
      opacity: 1;
      height: 100%;
    }
`;

export const StreamContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100%;
  margin: 0 -0.9375rem;

  .stream-container {
    display: flex;
    justify-content: space-between;
    padding: 7rem 1rem 3rem 8rem;
    width: 100%;
    &.is-archive {
      justify-content: center;
    }
    .stream-student-content {
      width: calc(85% - 2rem);
    }
    .stream-bottom-content {
      width: calc(75% - 2rem);
    }
    .stream-sidebar {
      width: 25%;
    }
    .stream-student-sidebar {
      width: 25%;
    }
  }

  @media (max-width: 60rem) {
    .stream-container {
      display: flex;
      flex-direction: column-reverse;
      padding: 7rem 1rem 3rem 8rem;
      width: 100%;
    }
    .stream-student-content {
      width: 100% !important;
    }
    .stream-bottom-content {
      width: 100% !important;
    }
    .stream-sidebar {
      width: 100% !important;
    }
    .stream-student-sidebar {
      width: 100% !important;
    }
  }
  @media (max-width: 43.75rem) {
    .stream-container {
      display: flex;
      flex-direction: column-reverse;
      padding: 12rem 0rem 3rem 7rem;
    }
    .stream-student-content {
      width: calc(100% - 2rem) !important;
    }
    .stream-bottom-content {
      width: calc(100% - 2rem) !important;
    }
    .stream-sidebar {
      width: 100% !important;
    }
    .stream-student-sidebar {
      width: 100% !important;
    }
  }
  @media (max-width: 37.5rem) {
    .stream-container {
      display: flex;
      flex-direction: column-reverse;
      padding: 12rem 0rem 3rem 2rem;
    }
    .stream-student-content {
      width: calc(100% - 2rem) !important;
    }
    .stream-bottom-content {
      width: calc(100% - 2rem) !important;
    }
    .stream-sidebar {
      width: 100% !important;
    }
    .stream-student-sidebar {
      width: 100% !important;
    }
  }

  /* .app-download-banner {
    background-image: linear-gradient(155.49deg, #419fff 9.5%, #0779ff 100.49%);
    position: fixed;
    left: 24px;
    top: 599px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    width: 433px;
    .banner-div {
      padding: 24px;
    }
    @media (max-width: 414px) {
      position: fixed;
      & {
        width: 90%;
        margin: 0% 5% 0% -1.5%;
        top: 65% !important;
      }

      .middle-banner-div {
        font-size: 20px !important;
      }
      .bottom-banner-left {
        .logoContainer {
          margin-top: 20px !important;
        }
      }
      .bottom-banner-right {
        .logoContainer {
          margin-top: 50px;
          img {
            width: 37.82px !important;
            height: 16.02px !important;
          }
        }
        img {
          width: 140px;
          height: 140px;
        }
      }
      .closeButton {
        display: flex;
        flex-direction: row;
      }
    }

    .top-banner-div {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #ffde82;
      letter-spacing: 0.2px;
      line-height: 1rem;
    }
    .middle-banner-div {
      margin-top: 4px;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
    }
    .bottom-banner-div {
      display: flex;
      flex-direction: row;
      .bottom-banner-left {
        .install-content {
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          width: 165px;
          opacity: 0.8;
        }
        .logoContainer {
          display: flex;
          margin-left: -0.312rem;
          margin-top: 66px;
          img {
            width: 87.82px;
            height: 26.02px;
          }
        }
      }
    }
    .closeButton {
      background: unset;
      border: none;
      outline: none;
      img {
        vertical-align: super;
        width: 1rem;
        height: 1rem;
      }
    }
  } */
`;

const StreamLive = styled.div`
  /* margin-left: 7rem; */
  .validation-error {
    color: red;
    display: inline-block;
  }
  .multi-select {
    width: 20rem;
  }
  .share-post-closed,
  .share-post-open {
    img {
      height: 2.25rem;
      width: 2.25rem;
    }
  }
  @media (max-width: 43.75rem) {
    .student-select-dropdown-div {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      width: 100%;
      .dropdown-toggler {
        img {
          margin-top: 0.312rem;
        }
      }
      .dropdown {
        width: 100%;
        right: unset;
        .students-search {
          width: 100%;
        }
      }
    }
    .attachment-container {
      input[type='file'] {
        width: 30%;
      }
    }
    @media (min-width: 46.87rem) {
      .attachment-container {
        input[type='file'] {
          width: 30%;
        }
      }
    }
  }

  .share-post-closed {
    align-items: center;
    display: flex;

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
    span {
      font-weight: 400;
      line-height: 1.21;
      font-size: 1rem;
      margin-left: 1rem;
      color: #aaa;
    }
    span:hover {
      cursor: pointer;
    }
  }
  .share-post-open {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    .teacher-name {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.0625rem;
      color: #565656;
    }
    .class-det {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.2;
      color: #aaa;
    }
  }
  .attachment-container {
    align-items: center;
    margin-top: 1rem;

    .anchor-icon {
      height: 0.812rem;
      width: 0.75rem;
    }

    span {
      color: #00abff;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.21;
      margin-left: 0.437rem;
    }
    input[type='file'] {
      position: absolute;
      opacity: 0;
    }
  }
  .fade-in {
    animation-name: ${fadein};
    animation-duration: 0.7s;
  }

  .btn-secondary {
    margin-right: 1.5rem;
    padding: 0;

    :active,
    :focus,
    :hover {
      background-color: #fff !important;
      outline: none;
    }
  }

  .submit-button {
    align-items: center;
    display: flex;

    .button-spinner {
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
      height: 1.25rem;
      margin-right: 0.5rem;
      width: 1.25rem;
    }
  }
  // button:hover {
  //   background: #fafafa;
  // }
`;

export default StreamLive;
