/* eslint-disable react/prop-types */
import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  commentOnAssessment,
  getMoreComments,
  deleteAssessment,
  deleteAssessmentFromList,
  assessmentSelector,
} from '../../../../redux/assessmentSlice';

import {
  streamSelector,
  commentOnPost,
  getMoreComments as getMorePostComments,
  deleteAssessmentFromPosts,
} from '../../../../redux/streamSlice';

import CommentInput from '../../../../components/shared/Inputs/commentInput';
import {
  AssessmentBody,
  AssessmentHeader,
  StudentAssessmentHeader,
} from './components';
import {CommentsSection} from '../CommentsSection';
import {CommentList} from '../../elements';
import {showToast} from '../../../../components/common/Toast';
import {AssessmentCardContainer} from './elements';
import {DeleteModal} from '../DeleteModal';
import {authSelector} from '../../../../redux/authSlice';

const DELAY = 500;

export const AssessmentCard = ({
  assessment,
  classID,
  gradedCount,
  intersectedElement,
  isIntersectedElement,
  isStream,
  submittedCount,
  isAssessmentLoading,
  isArchived,
  // className,
}) => {
  const userType = useRef(localStorage.getItem('jc-user-type')).current;
  const [comment, setComment] = useState('');
  const [commentLength, setCommentLength] = useState(assessment.commentCount);
  const [offset, setOffset] = useState(assessment.comments.length);
  const dispatch = useDispatch();
  const {isStreamLoading} = useSelector(streamSelector);
  const {isLoading} = useSelector(assessmentSelector);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(assessment?.ac_activity_name);
  useEffect(() => {
    setCommentLength(assessment.commentCount);
  }, [assessment.commentCount]);

  const authState = useSelector(authSelector);

  const teacherProfile = authState?.user?.userProfiles?.find(
    item => item.up_id_typeuserprofile === 3,
  );

  const currentUserProfileId =
    authState?.user?.userProfiles?.length > 1
      ? teacherProfile?.up_id_userprofile
      : authState?.user?.userProfiles[0]?.up_id_userprofile;

  const actionHideStatus =
    currentUserProfileId === assessment?.aoui_id_userprofile ? 1 : 0;

  const postComment = event => {
    if (event.keyCode === 13) {
      setComment('');
      const data = [
        assessment.ac_id_activity,
        comment,
        () => {
          setCommentLength(previousValue => previousValue + 1);
          setOffset(previousValue => previousValue + 1);
        },
      ];
      dispatch(
        isStream ? commentOnPost(...data) : commentOnAssessment(...data),
      );
    }
  };

  const deleteAssessmentFunction = useCallback(() => {
    if (!isLoading) {
      dispatch(
        deleteAssessment(
          deleteId,
          isStream
            ? aouiID => {
                dispatch(deleteAssessmentFromPosts(aouiID));
                showToast(false, `${deleteName} deleted successfully`, true);
              }
            : aouiID => {
                dispatch(deleteAssessmentFromList(aouiID));
                showToast(false, `${deleteName} deleted successfully`, true);
              },
        ),
      );
    }
  }, [deleteId, deleteName, dispatch, isLoading, isStream]);

  return (
    <>
      <AssessmentCardContainer
        key={assessment.aoui_id_activityorgunitinstance}
        ref={isIntersectedElement ? intersectedElement : null}
        style={
          (assessment.comments.length
            ? {
                paddingBottom: 16,
              }
            : {},
          userType === '3'
            ? {padding: '1.5rem 1.25rem 1.5rem 1.5rem'}
            : {padding: '0rem 0rem 1.5rem 0rem'})
        }
        // className={className}
        // id={assessment.aoui_id_activityorgunitinstance}
      >
        <DeleteModal
          isButtonLoading={isStreamLoading || isAssessmentLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            deleteAssessmentFunction();
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
        />

        {userType === '2' ? (
          <StudentAssessmentHeader
            description={assessment.ac_activity_description}
            publicationDate={assessment.aoui_timestamp_published}
            linkToView={
              userType === '3'
                ? `/stream/${classID}/assessment/${assessment.ac_id_activity}/students`
                : `/stream/${classID}/student/assessment/${assessment.ac_id_activity}`
            }
            assessment={assessment}
          />
        ) : (
          <>
            <AssessmentHeader
              description={assessment.ac_activity_description}
              dueDate={
                assessment.ac_due_date !== '0000-00-00 00:00:00'
                  ? assessment.ac_due_date
                  : null
              }
              name={assessment.ac_activity_name}
              linkToEdit={`/stream/${classID}/assessment/edit/${assessment.ac_id_activity}`}
              publicationDate={assessment.aoui_timestamp_published}
              handleDeletion={() =>
                setDeleteId(assessment?.aoui_id_activityorgunitinstance)
              }
              isStudent={userType === '2'}
              assessmentID={assessment.ac_id_activity}
              isPublished={
                !!assessment.aoui_timestamp_published &&
                assessment.aoui_timestamp_published !== '0000-00-00 00:00:00'
              }
              linkToView={`/stream/${classID}/assessment/${
                assessment.ac_id_activity
              }/${userType === '2' ? '' : 'students'}`}
              classID={classID}
              draftedDate={assessment.aoui_timestamp_updation}
              isArchived={isArchived}
              currentUserProfileId={currentUserProfileId}
              teacherId={assessment?.aoui_id_userprofile}
              actionHideStatus={actionHideStatus}
              profileName={`${assessment?.up_name_first} ${assessment?.up_name_last}`}
            />
            <AssessmentBody
              assessmentID={assessment.ac_id_activity}
              assignedCount={
                (
                  assessment.students ||
                  assessment.activityOrgUnitInstance[0].students
                ).length
              }
              classID={classID}
              gradedCount={gradedCount}
              isPublished={
                !!assessment.aoui_timestamp_published &&
                assessment.aoui_timestamp_published !== '0000-00-00 00:00:00'
              }
              linkToView={
                userType === '3'
                  ? `/stream/${classID}/assessment/${assessment.ac_id_activity}`
                  : `/stream/${classID}/student/assessment/${assessment.ac_id_activity}`
              }
              submittedCount={submittedCount}
              isArchived={isArchived}
              // actionHideStatus={actionHideStatus}
            />
          </>
        )}
        <div
          style={userType === '2' ? {padding: '1.5rem 1.5rem 0rem 1.5rem'} : {}}
        >
          {!isArchived ? (
            <CommentInput
              onChange={event => setComment(event.target.value)}
              onKeyUp={postComment}
              height="auto"
              placeholder="Write a Comment..."
              value={comment}
              width="100%"
              id="comment-input"
            />
          ) : null}

          {assessment.comments.length ? (
            <CommentList>
              <p className="comment-count">
                {commentLength} Comment{commentLength > 1 ? 's' : ''}
              </p>
              {assessment.comments.map((item, index) => (
                <CommentsSection
                  key={item.acc_id_activity_comment}
                  item={item}
                  index={index}
                  isStream={isStream}
                  setCommentLength={setCommentLength}
                  activityId={assessment.ac_id_activity}
                  commentLength={commentLength}
                  isArchived={isArchived}
                  userName={item?.user?.user?.us_profile_name}
                />
              ))}
              {assessment.comments?.length < commentLength ? (
                <div className="view-more">
                  <button
                    className="view-more-button"
                    onClick={() => {
                      dispatch(
                        isStream
                          ? getMorePostComments(
                              assessment.ac_id_activity,
                              offset,
                            )
                          : getMoreComments(assessment.ac_id_activity, offset),
                      );
                      setOffset(previousValue => previousValue + 10);
                    }}
                    type="button"
                    id="view-more-btn"
                  >
                    View more comments
                  </button>
                </div>
              ) : null}
            </CommentList>
          ) : null}
        </div>
      </AssessmentCardContainer>
    </>
  );
};
