/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import csvjson from 'csvjson';
import {convertFileSize} from '../../../../../../../../../../utils/functions';
import {UploadContainer} from './elements';
import UploadSuccessImg from '../../../../../../../../../../assets/icons/upload-success.svg';
import DeleteImg from '../../../../../../../../../../assets/icons/delete-logo.svg';
import FilesImg from '../../../../../../../../../../assets/icons/dropfile.svg';

export const UploadSectionUnits = ({
  steps,
  setUpload,
  upload,
  onDrag,
  setOnDrag,
  setSpreadSheet,
  setFileName,
  setNextShow,
}) => {
  const downloadRef = useRef(null);

  const handleDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] || event.target.files[0];
    const extn = file.name.split('.').pop();
    if (file && extn === 'csv') {
      const reader = new FileReader();
      reader.onload = () => {
        const jsonObj = csvjson.toObject(reader.result);
        setSpreadSheet(jsonObj);

        setFileName(file.name);
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <UploadContainer>
      {upload && steps === 1 ? (
        <div className="org-image-full">
          <div className="org-image-after">
            <img src={UploadSuccessImg} alt="logo" />
            <div className="file-details">
              <span className="file-name">{upload ? upload.name : ''}</span>
              <span className="file-size">
                {convertFileSize(upload ? upload.size : '')}
              </span>
            </div>
          </div>
          <div className="delete-logo">
            <button
              type="button"
              onClick={() => {
                setUpload(null);
                setNextShow(true);
              }}
            >
              <img src={DeleteImg} alt="logo" />
            </button>
          </div>
        </div>
      ) : (
        <div
          onDragEnter={() => {
            setOnDrag(true);
          }}
          onDragLeave={() => {
            setOnDrag(false);
          }}
        >
          {steps === 1 ? (
            <div className={`org-image ${onDrag ? 'logo-drag' : ''}`}>
              <div className="org-logo">
                <img src={FilesImg} className="logo" alt="logo" />
              </div>
              <div className="org-text">
                Drop your file here or{' '}
                <input
                  type="file"
                  onChange={e => {
                    setUpload(e.target.files[0]);
                    handleDrop(e);
                    setNextShow(false);
                  }}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                  id="browse-units-id"
                />
                <span className="box__dragndrop">Browse</span>
              </div>
              <span className="supports">Supports : XLS, SLV</span>
              <div className="org-seperator" />
              <div className="org-size">
                <span>Download</span>
                <a
                  id="sample-units-id"
                  ref={downloadRef}
                  download
                  href="https://junglecat-files.s3.ap-southeast-2.amazonaws.com/assets/sample-unit.csv"
                >
                  &nbsp;Sample.xls
                </a>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </UploadContainer>
  );
};
