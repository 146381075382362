import styled from 'styled-components';

import {Modal} from '../../../../../../../../../components/common';

export const EditAcademicYearContainer = styled(Modal)`
  .row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .field-left,
    .field-right {
      width: 100%;
    }
    .field-left {
      margin-right: 1.5rem;
    }
    @media (max-width: 37.5rem) {
      width: 100%;
      display: flex;
      flex-direction: column;
      .field-right {
        margin-top: 1.5rem;
      }
    }
  }
`;
