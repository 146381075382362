import PropTypes from 'prop-types';

import LockedImg from '../../../assets/icons/lockedpage.svg';
import PlusIcon from '../../../assets/icons/refactor/plus-white.svg';

import {Button} from '../Button';

import {LockedStateContainer} from './elements';

export const LockedState = ({
  title,
  description,
  onButtonClick,
  buttonLabel,
}) => (
  <LockedStateContainer className="align-items-center d-flex flex-column justify-content-center">
    <div>
      <img src={LockedImg} alt="loader" className="lockedpage" />
    </div>
    <div className="content-locked">
      <div>
        <p className="title">{title}</p>
      </div>
      <div>
        <p className="description">{description}</p>
      </div>
      <div>
        <Button
          icon={PlusIcon}
          isFullWidth={false}
          onClick={onButtonClick}
          label={buttonLabel}
        />
      </div>
    </div>
  </LockedStateContainer>
);

LockedState.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};
