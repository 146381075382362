import styled from 'styled-components';

const FilePreviewContainer = styled.div`
  width: 62.5rem;
  @media (max-width: 65rem) {
    & {
      width: 100%;
    }
    .modal-content-body {
      object-fit: contain;
    }
  }

  .previewContainer {
    &.files {
      height: 80vh;
    }
    .files {
      height: 100%;
      object {
        height: 100%;
      }
    }
  }
  .file-thumbnail {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    img {
      margin: 1.5rem 0rem;
    }
  }

  .attachment-file-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .title {
    font-weight: 500;
    font-size: 1.25rem;
    color: #091018;
  }
  .unit-class-name {
    font-weight: 400;
    font-size: 1rem;
    color: #81838e;
  }
  .attachment-file-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .attachment-time {
    font-size: 0.75rem;
    color: #aaaaaa;
  }
  .preview-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0px 1.5rem;
  }
  .modal-content-body {
    padding: 0px 1.5rem 1.5rem 1.5rem;
    width: 100%;
    margin-top: 1.5rem;
    max-height: 60vh;
    object-fit: contain;
  }

  .videos {
    padding: 1.5rem 1.5rem;
    Video {
      max-height: 38rem;
      border-radius: 0.5rem;
    }
  }
  .seperator {
    height: 0.0625rem;
    width: 100%;
    background-color: #e5e5e5;
    margin: 1.5rem 0px 0px 0px;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }
  .button-seperator {
    height: 1.5125rem;
    width: 0.0625rem;
    background-color: #dedede;
    margin: 0px 1.125rem 0px 1.125rem;
  }
  .closeModalBtn {
    border: none;
    background: transparent;
    outline: none;
    .cross {
      width: 1.5625rem !important;
      height: 1.5625rem !important;
    }
  }
`;

export default FilePreviewContainer;
