import React from 'react';
import {useHistory} from 'react-router-dom';

import CloseIcon from '../../../../../../../../assets/icons/addadminclose.svg';
import {AddSessionHeaderContainer} from './elements';

export const AddSessionHeader = () => {
  const history = useHistory();

  const onCancel = () => {
    history.replace(`/admin/sessions`);
  };

  return (
    <AddSessionHeaderContainer>
      <div className="header-container fixed-top">
        <div className="header">
          <div className="title">Add Session</div>
          <div>
            <button
              className="btn"
              onClick={onCancel}
              type="button"
              id="close-btn"
            >
              <img alt="Options" src={CloseIcon} />
            </button>
          </div>
        </div>
      </div>
    </AddSessionHeaderContainer>
  );
};
