import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLocalStorage} from 'react-use';

import {
  BackLink,
  Button,
  Checkbox,
  Link,
  Spacer,
  TextInput,
} from '../../../../../../components/common';
import {
  authSelector,
  register as registerAdmin,
  setError,
  setOrganisation,
} from '../../../../../../redux/authSlice';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {registerAdminSchema} from '../../../../../../utils/validation';
import {AuthSwitchLink, ErrorAlert, Heading} from '../../../../components';
import {AdminFormContainer} from './elements';

const FIELDS_IN_ORDER = [
  'name',
  'email',
  'password',
  'confirmPassword',
  'accept',
];

export const AdminForm = ({
  onNavigateBack,
  onNavigateBackToChooseType,
  accountType,
}) => {
  const dispatch = useDispatch();
  const [country, , removeCountry] = useLocalStorage('country', '', {
    raw: true,
  });
  const [organisationName, , removeOrganisationName] = useLocalStorage(
    'organisationName',
    '',
    {
      raw: true,
    },
  );
  const [, , removeRtoCode] = useLocalStorage('rtoCode', null, {
    raw: true,
  });
  const [tGovOrgCode, , removeTgovOrgCode] = useLocalStorage(
    'tGovOrgCode',
    '',
    {
      raw: true,
    },
  );
  const [tGovOrgId, , removeTgovOrgId] = useLocalStorage('tGovOrgId', '', {
    raw: true,
  });
  const {
    control,
    formState: {errors},
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      accept: false,
      confirmPassword: '',
      country,
      email: '',
      name: '',
      organisationName,
      password: '',
      tGovOrgCode,
      tGovOrgId,
    },
    resolver: yupResolver(registerAdminSchema),
  });
  const history = useHistory();
  const {error: serverError, isLoading} = useSelector(authSelector);

  const clearLocalStorageValues = useCallback(() => {
    removeCountry();
    removeOrganisationName();
    removeRtoCode();
    removeTgovOrgCode();
    removeTgovOrgId();
  }, [
    removeCountry,
    removeOrganisationName,
    removeRtoCode,
    removeTgovOrgCode,
    removeTgovOrgId,
  ]);

  const onSubmit = useCallback(
    data => {
      const details = {
        ...data,
        orgType: accountType,
      };
      dispatch(
        registerAdmin(details, () => {
          dispatch(setOrganisation(null));
          clearLocalStorageValues();
          history.push(`/register-success?email=${data.email}`);
        }),
      );
    },
    [accountType, clearLocalStorageValues, dispatch, history],
  );

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {errorMessage, hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <AdminFormContainer className="align-items-center col-lg-6 col-md-12 d-flex flex-column justify-content-center main-section position-relative px-4">
      <ErrorAlert isVisible={!!errorMessage} message={errorMessage} />
      <main className="d-flex flex-column main-content w-100">
        <Spacer height="4rem" />
        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <BackLink
            className="mb-4"
            onClick={() => {
              clearLocalStorageValues();
              dispatch(setOrganisation(null));
              onNavigateBack();
            }}
          />
          <Heading
            marginBottom="2rem"
            subtitle="Enter your details to create an account."
            title={organisationName}
          />
          <form
            className="align-items-center d-flex flex-column w-100"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={fields => (
                <TextInput
                  {...fields}
                  autoFocus
                  className="mb-4"
                  hasError={hasServerError || !!errors.name}
                  isLarge
                  label="Full Name"
                  placeholder="Enter your full name"
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.email}
                  isLarge
                  label="Email"
                  placeholder="Enter your email address"
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.password}
                  isLarge
                  label="Password"
                  placeholder="Enter a password"
                  type="password"
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.confirmPassword}
                  isLarge
                  label="Confirm Password"
                  placeholder="Confirm the password"
                  type="password"
                />
              )}
            />
            <div className="align-items-start d-flex mb-4 w-100">
              <Controller
                control={control}
                name="accept"
                render={fields => (
                  <Checkbox hasError={!!errors.accept} {...fields} />
                )}
              />
              <p className="accept-checkbox-label mb-0">
                I accept the <Link to="/">Privacy Policy</Link> and{' '}
                <Link to="/">Terms of Service</Link> of JungleCat
              </p>
            </div>
            <input {...register('country')} type="hidden" />
            <input {...register('organisationName')} type="hidden" />
            <input {...register('tGovOrgCode')} type="hidden" />
            <input {...register('tGovOrgId')} type="hidden" />
            <Button
              isLarge
              isLoading={isLoading}
              label="Register"
              type="submit"
            />
          </form>
          <AuthSwitchLink
            linkHref="/login"
            linkText="Login"
            onLinkClick={() => {
              clearServerError();
              clearLocalStorageValues();
              onNavigateBackToChooseType();
              dispatch(setOrganisation(null));
            }}
            text="Already have an account?"
          />
        </div>
        <Spacer height="4rem" />
      </main>
    </AdminFormContainer>
  );
};

AdminForm.propTypes = {
  onNavigateBack: PropTypes.func.isRequired,
  onNavigateBackToChooseType: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
};
