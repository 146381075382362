import styled from 'styled-components';

export const PortfolioContainer = styled.div`
  -ms-overflow-style: none;
  background-color: #f4f5f8;
  height: 100vh;
  margin: 0 -0.9375rem;
  overflow-y: auto;
  padding: calc(5.0625rem + 2rem) 1.5rem 1.5rem 1.5rem;
  &.is-admin {
    padding: 7.0625rem 2rem 4.0625rem 7rem;
  }
  @media (max-width: 25.5625rem) {
    padding: calc(5.0625rem + 2rem) 0.8rem 1.5rem 0.8rem !important;
  }
  @media (max-width: 60.5rem) {
    .portfolio-container {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      margin: unset;
    }
    .profile-container {
      width: 100%;
      margin-top: 2rem;
    }
    .my-portfolio {
      min-height: unset;
    }
    [class*='col-3'] {
      max-width: 100% !important;
      padding: 0 0.875rem;
    }
    [class*='col-9'] {
      max-width: 100% !important;
      padding: 0 0.875rem;
    }
  }
`;
export const MyPortfolioAndAttendanceConatiner = styled.div`
  @media (max-width: 17rem) {
    & {
      margin: 0 -3.3rem 0 -0.935rem;
      overflow-x: hidden !important;
    }
  }
  @media (max-width: 25rem) {
    .portfolio-attendance-header {
      display: flex;
      flex-direction: column;
    }
    .title {
      width: 100%;
    }
    .academic-year-search {
      width: 100%;
      margin-top: 1rem;
      .academic-year {
        width: 100% !important;
      }
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0c181f;
  }
  .portfolio-attendance-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-top: 1.6875rem;
  }

  .search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    padding: 0rem 0rem 0rem 0rem;
    border-bottom: 0.0625rem solid #dedede;
    .bottom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      font-weight: 500;
      font-size: 1rem;
      outline: none;
      color: #aaaaaa;
      &.link {
        border-bottom: 0.125rem solid #0c181f !important;
        color: #0c181f;
      }
      p {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  .search-div::-webkit-scrollbar {
    display: none;
  }

  .card-class {
    height: 0;
    padding-bottom: calc(33.33333% - 1.875rem);
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    .card-class {
      height: 0;
      padding-bottom: calc(50% - 1.875rem);
    }
  }
  @media screen and (min-width: 760px) and (max-width: 992px) {
    .card-class {
      height: 0;
      padding-bottom: calc(33.33333% - 1.875rem);
    }
  }

  @media screen and (min-width: 0px) and (max-width: 575px) {
    .card-class {
      height: 0;
      padding-bottom: calc(100% - 1.875rem);
    }
  }
`;
