/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import {Color} from '../../../constants';

export const UnitContainer = styled.div`
  button:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const UnitCardContainer = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 0rem 0.187rem 0.25rem rgba(0, 0, 0, 0.03);
  border-radius: 0.3125rem;

  .unstyled-link {
    color: unset;
    text-decoration: none;
  }

  .date {
    font-size: 0.875rem;
    color: #aaaaaa;
  }
  .dateExceed {
    color: #ff6d7d;
  }
`;

// export const CustomDatePicker = styled.div`
//   border: 0.0625rem solid #e6e6e6;
//   border-radius: 8px;
//   color: #515151;
//   overflow: hidden;
//   .openDatepicker {
//     background-color: transparent;
//     outline: none;
//     border: none;
//   }
//   .react-datepicker-wrapper {
//     width: 100%;
//   }
//   .react-datepicker__day--highlighted-custom-1 {
//     border-radius: 0.3rem;
//     background-color: #f0f0f0;
//     color: #000;
//   }
//   .date-picker {
//     border-radius: 8px;
//   }
//   input {
//     color: #515151;
//   }
//   input:focus {
//     border: none !important;
//     outline: none;
//   }
//   .react-datepicker-popper {
//     /* right: 62px !important; */
//     /* left: auto !important; */
//     left: 0% !important;
//   }
//   .react-datepicker__triangle {
//     left: 216px !important;
//   }
// `;

export const CustomDatePicker = styled.div`
  background-color: #ffffff;
  &.disabled {
    background-color: #e9ecef;
    border: 0.0625rem solid #dedede;
  }
  overflow: hidden;
  border: 0.0625rem solid #dedede;
  box-sizing: border-box;
  border-radius: 0.312rem;
  :focus-within {
    border-color: ${Color.PRIMARY};
    box-shadow: 0 0 0 0.125rem ${Color.LIGHT_BLUE};
    outline: none;
  }
  .openDatepicker {
    background-color: transparent;
    outline: none;
    border: none;
  }
  .react-datepicker-popper {
    /* right: 62px !important;
    left: auto !important; */
    left: 1% !important;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
    margin-bottom: 1.5rem;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background: unset;
      border-bottom: unset;
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: #b8bac7;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }
    }
    .react-datepicker__day--outside-month {
      color: #b8bac7 !important;
      font-size: 0.75rem !important;
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.937rem;
      text-align: center;
      color: #3d4457;
      display: inline-block;
      width: 2.233rem;
      line-height: 1.7rem;
      text-align: center;
      margin: 0.166rem;
      font-weight: 400;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background: unset;
    }
  }
  .react-datepicker__day--today {
    font-weight: 800 !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__year-text--in-range {
    background: #f0f0f0;
    font-weight: 900 !important;
  }

  .date-picker {
    border-radius: 0.5rem;
    color: #3d4457;
  }
  .date-picker::placeholder {
    color: #d5d5d5;
    font-size: 0.875rem;
  }
`;

export const AddModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 32.12rem;
    .cancel-btn {
      color: #888787;
    }
    .modal-body {
      .header {
        font-weight: 600;
        font-size: 1.25rem;
      }
      .sub-header {
        font-size: 0.875rem;
        color: #888888;
        text-align: center;
      }
      .form-control {
        border: 0.0625rem solid #e6e6e6;
        height: 2.5rem;
        font-size: 0.875rem;
        color: #aaaaaa;
      }
      .form-control:focus {
        border: 0.0625rem solid #e6e6e6;
      }
    }
  }
`;
