import PropTypes from 'prop-types';

// import OptionsIcon from '../../../../../../assets/icons/options-icon.svg';
import Box from '../../../../../../components/shared/Box';
import UnitDetailsHeaderContainer from '../elements/UnitDetailsHeaderContainer';

const UnitDetailsHeader = ({
  unitCode,
  unitName,
  qualificationCode,
  qualificationName,
}) => (
  <UnitDetailsHeaderContainer>
    <Box className="align-items-center d-flex justify-content-between pl-4 pr-3 py-4">
      <div className="d-flex flex-column w-100">
        <h2 className="mb-1 unit-code">{unitCode}</h2>
        <div className="d-flex  w-100 justify-content-between align-items-center">
          <span className="unit-name">{unitName}</span>
          <div className="d-flex align-items-center flex-end">
            <h2 className="mb-0 qual-code">{qualificationCode}&nbsp; </h2>
            <span className="unit-name"> {qualificationName}</span>
          </div>
        </div>
      </div>
      {/* <button className="d-flex options-button" type="button">
        <img alt="Options" src={OptionsIcon} />
      </button> */}
    </Box>
  </UnitDetailsHeaderContainer>
);

UnitDetailsHeader.defaultProps = {
  unitCode: '',
  unitName: '',
  qualificationCode: '',
  qualificationName: '',
};

UnitDetailsHeader.propTypes = {
  unitCode: PropTypes.string,
  unitName: PropTypes.string,
  qualificationCode: PropTypes.string,
  qualificationName: PropTypes.string,
};

export default UnitDetailsHeader;
