import classNames from 'classnames';
import {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import BackLink from '../../../../../components/shared/BackLink';

import {
  clearUnitDetails,
  fetchAllFilterQualifications,
  fetchOneUnitById,
  setCurrentQualifications,
  unitsSelector,
} from '../../../../../redux/unitsSlice';

import UnitDetailsHeader from './components/UnitDetailsHeader';
import UnitDetailsContainer from './elements/UnitDetailsContainer';
import {AssessmentMap, Assessments} from './pages';

const UnitDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {unitId, qualificationId} = useParams();
  const [assessmentTab, setAssessmentTab] = useState(true);
  const [assessmentMapTab, setAssessmentMapTab] = useState(false);
  const {
    isLoadingUnitDetails,
    unitDetails,
    currentQualification,
    filterQualifications,
  } = useSelector(unitsSelector);

  useEffect(() => {
    const findQualification =
      filterQualifications?.find(
        item =>
          item.qualification.tqual_id_tgovqualification ===
          Number(qualificationId),
      )?.qualification || {};

    dispatch(
      setCurrentQualifications({
        qualificationCode: findQualification.tqual_code,
        qualificationName: findQualification.tqual_title,
      }),
    );
  }, [dispatch, filterQualifications, qualificationId]);

  useEffect(
    () => () => {
      dispatch(clearUnitDetails());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(fetchAllFilterQualifications());
  }, [dispatch]);

  // Fetch the assessment map using the unit's ID.
  useEffect(() => {
    dispatch(fetchOneUnitById(unitId));
  }, [dispatch, unitId]);

  return (
    <UnitDetailsContainer>
      {isLoadingUnitDetails ? (
        <>
          <Skeleton className="mb-3" height="1.312rem" width="3.56rem" />
          <Skeleton height="6.062rem" width="100%" />
        </>
      ) : (
        <>
          <BackLink
            className="mb-3"
            onClick={() => {
              history.push(`/admin/qualifications/${qualificationId}/units`);
            }}
          />
          <UnitDetailsHeader
            unitCode={unitDetails?.tun_code}
            unitName={unitDetails?.tun_title}
            qualificationCode={currentQualification?.qualificationCode}
            qualificationName={currentQualification?.qualificationName}
          />

          <div className="tab-units mb-5">
            <button
              className={classNames('bottom', {
                link: assessmentTab,
              })}
              type="button"
              onClick={() => {
                setAssessmentTab(true);
                setAssessmentMapTab(false);
                // setSearchTerm('');
              }}
            >
              <p>Assessments</p>
            </button>
            <button
              className={classNames('bottom', {
                link: assessmentMapTab,
              })}
              type="button"
              onClick={() => {
                setAssessmentTab(false);
                setAssessmentMapTab(true);
                // setSearchTerm('');
              }}
            >
              <p>Assessment Map</p>
            </button>
          </div>
          {assessmentTab ? (
            <Assessments
              assessments={unitDetails?.assessments || []}
              dispatch={dispatch}
            />
          ) : null}
          {assessmentMapTab ? (
            <AssessmentMap unitDetails={unitDetails} />
          ) : null}
        </>
      )}
    </UnitDetailsContainer>
  );
};

export default UnitDetails;
