/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from 'react';

import {UploadContainer} from './elements';
import RemoveFileIcon from '../../../../../../../../../../assets/icons/cross-curriculum.svg';

import FilesUploadedImg from '../../../../../../../../../../assets/icons/curriculum-uploaded.svg';
import FilesImg from '../../../../../../../../../../assets/icons/curriculum-upload.svg';
// import {FileCard} from './components';

export const UploadSection = ({
  setUpload,
  upload,
  onDrag,
  setOnDrag,
  setFileName,
  accept,
}) => {
  const onFileChange = e => {
    setUpload(e.target.files[0]);
  };

  return (
    <UploadContainer>
      {upload ? (
        <div>
          <div className="org-image-added">
            <div className="org-logo-div">
              <div className="delete-logo">
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    setUpload(null);
                    setFileName('');
                  }}
                  id="remove-id"
                >
                  <img src={RemoveFileIcon} alt="logo" />
                </button>
              </div>
              <div className="org-logo">
                <img src={FilesUploadedImg} className="logo" alt="logo" />
                <div className="org-text">{upload?.name}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          onDragEnter={() => {
            setOnDrag(true);
          }}
          onDragLeave={() => {
            setOnDrag(false);
          }}
          id="drag"
        >
          <div className={`org-image ${onDrag ? 'logo-drag' : ''}`}>
            <div className="org-logo">
              <img src={FilesImg} className="logo" alt="logo" />
            </div>
            <div className="org-text">
              Drop your file here or{' '}
              <input
                type="file"
                onChange={e => {
                  onFileChange(e);
                }}
                accept={accept}
                id="upload-file"
              />
              <span className="box__dragndrop" id="browse">
                Browse
              </span>
            </div>
          </div>
        </div>
      )}
    </UploadContainer>
  );
};
