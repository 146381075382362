import {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {LibraryContainer} from './elements';
import {LibraryCard, EmptyState} from '../../../../components/common';
import {TableSpinner} from '../../../Admin/components-new';
import {
  fetchAllLibraryFiles,
  studentLibrarySelector,
  fetchAllCategory,
} from '../../../../redux/studentLibrarySlice';
import {FilePreview, FilterFiles} from './components';

export const LibraryStudent = () => {
  const dispatch = useDispatch();
  const [classFilesTab, setClassFilesTab] = useState(true);
  const [publicFilesTab, setPublicFilesTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewDocument, setViewDocument] = useState(false);
  const [previewFileData, setPreviewFileData] = useState(null);

  const [filterByCategory, setFilterByCategory] = useState({
    value: '',
    label: 'All Category',
  });
  const [filterByFileType, setFilterByFileType] = useState({
    value: '',
    label: 'All files types',
  });

  const {
    location: {pathname},
  } = useHistory();

  const {
    studentLibrary,
    publicLibraryFiles,
    orgUnitInstance,
    isListLoading,
    category,
  } = useSelector(studentLibrarySelector);

  const publicLibraryFilesGroup = useMemo(
    () =>
      publicLibraryFiles?.filter(
        item =>
          item?.fileType?.ft_type !== 'Text' &&
          `${item?.fi_text_content}`.toLowerCase().includes(searchTerm) &&
          (item?.fileCategory?.fc_id_filecategory === filterByCategory?.value ||
            filterByCategory?.value === '') &&
          (item?.fileType?.ft_id_filetype === filterByFileType?.value ||
            filterByFileType?.value === ''),
      ),
    [
      filterByCategory?.value,
      filterByFileType?.value,
      publicLibraryFiles,
      searchTerm,
    ],
  );

  const studentLibraryFilesGroup = useMemo(
    () =>
      studentLibrary?.filter(
        item =>
          item?.fileType?.ft_type !== 'Text' &&
          (`${item?.fi_text_content}`.toLowerCase().includes(searchTerm) ||
            `${item?.fi_code}`.toLowerCase().includes(searchTerm)) &&
          (item?.fileCategory?.fc_id_filecategory === filterByCategory?.value ||
            filterByCategory?.value === '') &&
          (item?.fileType?.ft_id_filetype === filterByFileType?.value ||
            filterByFileType?.value === ''),
      ),
    [
      studentLibrary,
      searchTerm,
      filterByCategory?.value,
      filterByFileType?.value,
    ],
  );

  const categoryAsOptions = useMemo(
    () => [
      {label: 'All Catergory', value: ''},
      ...(category?.map(item => ({
        label: `${item?.fc_name}`,
        value: item?.fc_id_filecategory,
      })) || []),
    ],
    [category],
  );

  const fileTypeOptions = [
    {label: 'All file types', value: ''},
    {label: 'Image', value: 1},
    {label: 'Video', value: 2},
    {label: 'Document', value: 5},
  ];

  const orgUnitInstanceId = pathname.split('/')[2];
  useEffect(() => {
    dispatch(fetchAllLibraryFiles(orgUnitInstanceId));
  }, [dispatch, orgUnitInstanceId]);

  useEffect(() => {
    dispatch(fetchAllCategory(orgUnitInstanceId));
  }, [dispatch, orgUnitInstanceId]);

  return (
    <>
      {' '}
      <Helmet>
        <title>Library | JungleCat</title>
      </Helmet>
      <LibraryContainer>
        <>
          <div>
            <div className="library-header">
              <span className="flex-shrink-0 mb-0 title">Library</span>
            </div>

            <div className="search-div">
              <button
                className={classNames('bottom', {
                  link: classFilesTab,
                })}
                type="button"
                onClick={() => {
                  setClassFilesTab(true);
                  setPublicFilesTab(false);
                  setSearchTerm('');
                }}
              >
                <p>{`${orgUnitInstance?.class?.oc_class} - ${orgUnitInstance?.orgUnit?.unit?.tun_title}`}</p>
              </button>
              <button
                className={classNames('bottom', {
                  link: publicFilesTab,
                })}
                type="button"
                onClick={() => {
                  setClassFilesTab(false);
                  setPublicFilesTab(true);
                  setSearchTerm('');
                }}
              >
                <p>Public</p>
              </button>
            </div>

            {isListLoading ? <TableSpinner /> : null}

            {classFilesTab && !isListLoading ? (
              <>
                <FilterFiles
                  filesLength={studentLibraryFilesGroup?.length}
                  filterByCategory={filterByCategory}
                  categoryAsOptions={categoryAsOptions}
                  setFilterByCategory={setFilterByCategory}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  fileTypeOptions={fileTypeOptions}
                  setFilterByFileType={setFilterByFileType}
                  filterByFileType={filterByFileType}
                />

                {!isListLoading && !studentLibraryFilesGroup?.length ? (
                  <EmptyState
                    description="It seems that there is no Files has been added to this Class"
                    title="No Files Found!"
                  />
                ) : null}
                <div className="row mt-5">
                  {studentLibraryFilesGroup?.map(item => (
                    <div className="card-class col-sm-6 col-lg-3 col-md-4 col-xl-3 col-xs-6 col-12 mb-4">
                      <LibraryCard
                        data={studentLibraryFilesGroup}
                        height="100%"
                        fileName={item?.fi_filename}
                        uploadedDate={item?.fi_uploadedate}
                        fileLocation={item?.fi_location}
                        fileType={item?.fileType?.ft_type}
                        thumbnail={
                          item?.fi_cover_image
                            ? item?.fi_cover_image
                            : item?.fi_thumbnail
                        }
                        fileTitle={item?.fi_text_content}
                        fileCode={item?.fi_code}
                        onClick={() => {
                          setViewDocument(true);
                          setPreviewFileData(item);
                        }}
                        fileCategory={item?.fileCategory?.fc_name}
                        key={item?.fi_id_file}
                        isStudent
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {publicFilesTab && !isListLoading ? (
              <>
                <FilterFiles
                  filesLength={publicLibraryFilesGroup?.length}
                  filterByCategory={filterByCategory}
                  categoryAsOptions={categoryAsOptions}
                  setFilterByCategory={setFilterByCategory}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  fileTypeOptions={fileTypeOptions}
                  setFilterByFileType={setFilterByFileType}
                  filterByFileType={filterByFileType}
                />
                {!isListLoading && !publicLibraryFilesGroup?.length ? (
                  <EmptyState
                    description="It seems that there is no Files has been added to this Class"
                    title="No Files Found!"
                  />
                ) : null}
                <div className="row mt-4 ">
                  {publicLibraryFilesGroup?.map(item => (
                    <div className="card-class col-sm-6 col-lg-3 col-md-4 col-xl-3 col-xs-6 col-12 mb-4">
                      <LibraryCard
                        data={publicLibraryFilesGroup}
                        height="100%"
                        fileName={item?.fi_filename}
                        uploadedDate={item?.fi_uploadedate}
                        fileLocation={item?.fi_location}
                        fileType={item?.fileType?.ft_type}
                        thumbnail={
                          item?.fi_cover_image
                            ? item?.fi_cover_image
                            : item?.fi_thumbnail
                        }
                        fileTitle={item?.fi_text_content}
                        onClick={() => {
                          setViewDocument(true);
                          setPreviewFileData(item);
                        }}
                        fileCategory={item?.fileCategory?.fc_name}
                        key={item?.fi_id_file}
                        isPublic
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </>

        {viewDocument && previewFileData ? (
          <FilePreview
            isVisible={viewDocument}
            onClose={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setViewDocument(false);
            }}
            previewFileData={previewFileData}
          />
        ) : null}
      </LibraryContainer>
    </>
  );
};
