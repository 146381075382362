/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

// import HistoryIcon from '../../../../../../../../assets/icons/history-icon.svg';
import Box from '../../../../../../../../components/shared/Box';
import PerformanceCriteriaItemContainer from '../elements/PerformanceCriteriaItemContainer';

const PerformanceCriteriaItem = ({item}) => (
  <PerformanceCriteriaItemContainer>
    <div className="d-flex item-title">
      <span className="mr-2">{item.orderId}</span>
      <h4 className="mb-0">{item.title}</h4>
    </div>
    <Box className="rounded-0 sub-items">
      {item.criteria.map(subItem => (
        <div className="sub-item" key={subItem.id}>
          <span className="sub-item-serial-number">
            <span className="serial-number-hash">#</span>
            {subItem.orderId}
          </span>
          <span className="sub-item-title">
            {' '}
            <span className="column-header">Performance Criteria</span>
            {subItem.title}
          </span>
          {subItem.selectedAssessments.map(assessment => (
            <div className="sub-item-task">
              <span className="column-header">Observable Task</span>
              {/* <p>{subItem?.tasks?.ac_activity_name}</p> */}

              {assessment?.assessmentTasks?.length ? (
                <div className="d-flex flex-column">
                  {assessment?.assessmentTasks.map(task => (
                    <div className="d-flex">
                      <Link className="sub-item-linked-task" to="/">
                        {task?.ai_activityitem_title}
                      </Link>
                      {/* <button
                        className="history-button"
                        onClick={() => {
                          onShowHistory(subItem.id, item.id, '1');
                        }}
                        type="button"
                      >
                        <img alt="History" src={HistoryIcon} />
                      </button> */}
                    </div>
                  ))}
                </div>
              ) : (
                <span className="sub-item-empty-task">No task linked</span>
              )}
            </div>
          ))}
        </div>
      ))}
    </Box>
  </PerformanceCriteriaItemContainer>
);

PerformanceCriteriaItem.propTypes = {
  item: PropTypes.shape({
    criteria: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        task: PropTypes.string,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  // onShowHistory: PropTypes.func.isRequired,
};

export default PerformanceCriteriaItem;
