/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
// import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {showToast} from '../../../../../../../../components/common/Toast';

import {
  getClassesByCourseId,
  coursesSelector,
  archiveClassById,
} from '../../../../../../../../redux/coursesSlice';
import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';
import {
  EmptyState,
  MoreOptions,
  Table,
  Modal,
} from '../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../components/common/MoreOptions/components';

import {TableCellLink} from '../../../../../../../../components/common/Table/styles';
import {TableSpinner} from '../../../../../../components-new';
import {ClassesContainer} from './elements';

const DELAY = 500;

export const CourseClassesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {orgCourseId} = useParams();

  const {coursesClasses, isLoading, isAddCourseLoading} =
    useSelector(coursesSelector);

  const [classId, setClassId] = useState(null);
  const [className, setClassName] = useState('');

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Class Name',
        accessor: 'oc_class',
        className: 'course-name',
      },
      {
        Header: 'Total Sessions',
        accessor: 'course.ocr_sessions_total',
        className: 'total-sessions',
      },
      {
        Header: 'Total Hours',
        accessor: 'course.ocr_hours_total',
        className: 'total-hours',
      },
      {
        Header: 'Completed Sessions',
        Cell: ({row: {original}}) => (
          <div>
            <TableCellLink
              onClick={() => {}}
              type="button"
              className="view-class"
            >
              <span> {original?.course?.ocr_sessions_total} </span>
            </TableCellLink>
          </div>
        ),
        accessor: 'course',
        className: 'completed-session',
      },
      {
        Header: 'Completed Hours',
        Cell: ({row: {original}}) => (
          <div>
            <TableCellLink
              onClick={() => {}}
              type="button"
              className="view-class"
            >
              <span> {original?.course?.ocr_hours_total} </span>
            </TableCellLink>
          </div>
        ),
        accessor: 'classes',
        className: 'completed-hours',
      },

      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                isDanger
                label="Archive Now"
                onClick={() => {
                  setClassId(original?.oc_id_orgclass);
                  setClassName(original?.oc_class);
                }}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'oc_id_orgclass',
        className: 'more-option',
      },
    ],
    [],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(coursesClasses || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [coursesClasses],
  );

  useEffect(() => {
    dispatch(getClassesByCourseId(orgCourseId));
  }, [dispatch, orgCourseId]);

  return (
    <>
      <Helmet>
        <title>Classes List | JungleCat</title>
      </Helmet>

      <ClassesContainer>
        <div className="header-container">
          <div className="back-button">
            <button
              className="btn"
              onClick={() => history.replace('/admin/courses/active-courses')}
              style={{
                padding: 0,
              }}
              type="button"
            >
              <img src={ArrowLeftImg} alt="arrow" />
              <span>Back</span>
            </button>
          </div>
          <div>
            <h3 className="header">{`${coursesClasses?.length} Classes in ${location.state?.orgCourseName}`}</h3>
          </div>
        </div>

        <div className="table-container">
          {isLoading ? <TableSpinner /> : null}
          {!isLoading && !coursesClasses?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Classes Found"
            />
          ) : null}

          {!isLoading &&
          coursesClasses?.length &&
          Array.isArray(coursesClasses) &&
          !isLoading ? (
            <Table
              columns={columns}
              data={data}
              itemName="Classes"
              maxWidth="75rem"
              isSortedBy
            />
          ) : null}
        </div>

        <Modal
          buttonLabel="Archive"
          description="Are you sure you want to archive this class?"
          isDelete
          isButtonLoading={isAddCourseLoading}
          loadingButtonLabel="Archiving"
          onButtonClick={() => {
            dispatch(
              archiveClassById(classId, () => {
                setClassId(null);
                showToast(false, `${className} has been archived.`, true);
                setTimeout(() => {
                  setClassName('');
                }, DELAY);
              }),
            );
          }}
          onHide={() => {
            setClassId(null);

            setTimeout(() => {
              setClassName('');
            }, DELAY);
          }}
          show={!!classId}
          title={`Archive ${className}`}
          width="41.25rem"
        />
      </ClassesContainer>
    </>
  );
};
