/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';

import {StudentItemContainer, StudentListContainer} from './elements';
import KickOutIcon from '../../../../../../assets/icons/kick-out.svg';
import StudentMutedIcon from '../../../../../../assets/icons/student-muted.svg';
import StudentUnmutedIcon from '../../../../../../assets/icons/student-unmuted.svg';
import StudentVideoOnIcon from '../../../../../../assets/icons/student-video-on.svg';
import StudentVideoOffIcon from '../../../../../../assets/icons/student-video-off.svg';
import SpeakingIcon from '../../../../../../assets/icons/speaking.svg';
import StudentListSearchIcon from '../../../../../../assets/icons/student-list-search.svg';
import StudentMoreOptionsIcon from '../../../../../../assets/icons/student-more-options.svg';
// import StudentShareScreenIcon from '../../../../../../assets/icons/student-share-screen.svg';
import SidebarHeader from '../SidebarHeader';
import SearchInput from '../../../../../../components/shared/Inputs/SearchInput';
import {MoreOptions} from '../../../../../../components/common/MoreOptions';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components/DropdownItem';

const StudentList = ({
  students,
  onChatOpen,
  onClose,
  onForceAudioMute,
  onForceVideoMute,
  onKickOutUser,
  speakingStates,
  isHost,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const studentListContainerRef = useRef(null);

  useEffect(() => {
    if (searchText.length && students.length) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredStudents(
        students.filter(
          student =>
            student.username.toLowerCase().includes(lowerCaseSearchText) ||
            `${student.firstname} ${student.lastname}`
              .toLowerCase()
              .includes(lowerCaseSearchText),
        ),
      );
    } else setFilteredStudents(students);
  }, [searchText, students]);

  useEffect(() => {
    studentListContainerRef.current.focus();
  }, []);

  return (
    <StudentListContainer tabIndex="0" ref={studentListContainerRef}>
      <SidebarHeader
        active="attendees"
        attendeesCount={students.length}
        onChatOpen={onChatOpen}
        onClose={onClose}
      />
      <SearchInput
        placeholder="Search"
        className="student-list-search"
        width="unset"
        icon={StudentListSearchIcon}
        value={searchText}
        onChange={event => setSearchText(event.target.value)}
      />
      {filteredStudents.map(student => {
        let AudioIcon;
        if (student.isAudioMuted) AudioIcon = StudentMutedIcon;
        else if (speakingStates[student.id]) {
          AudioIcon = SpeakingIcon;
        } else AudioIcon = StudentUnmutedIcon;

        const VideoIcon = student.isVideoMuted
          ? StudentVideoOffIcon
          : StudentVideoOnIcon;

        return (
          <StudentItemContainer key={student.id}>
            <span className="student-avatar">
              {student.avatar ? (
                <img src={student.avatar} alt="profile" />
              ) : (
                `${student.firstname[0]} ${student.lastname[0]}`
              )}
            </span>
            <div className="student-details">
              <span className="student-name">
                {student.firstname} {student.lastname}
              </span>
              <span className="student-username">@{student.username}</span>
            </div>
            <span className="item-icon">
              <img
                src={AudioIcon}
                alt="audio status"
                className="student-icon"
              />
            </span>
            <span className="item-icon">
              <img
                src={VideoIcon}
                alt="video status"
                className="student-icon"
              />
            </span>
            {isHost && (
              <MoreOptions
                OptionIcon={StudentMoreOptionsIcon}
                className="item-icon"
              >
                <DropdownItem
                  icon={AudioIcon}
                  label="Mute Audio"
                  onClick={() => {
                    onForceAudioMute(student.id);
                  }}
                />
                <DropdownItem
                  icon={VideoIcon}
                  label="Turn Video off"
                  onClick={() => {
                    onForceVideoMute(student.id);
                  }}
                />
                {/* <DropdownItem
                icon={StudentShareScreenIcon}
                label="Stop Sharing Screen"
                onClick={() => {}}
              /> */}
                <DropdownItem
                  icon={KickOutIcon}
                  isDanger
                  isSeperated
                  label="Kick Out"
                  onClick={() => onKickOutUser(student.id)}
                />
              </MoreOptions>
            )}
          </StudentItemContainer>
        );
      })}
    </StudentListContainer>
  );
};

StudentList.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChatOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onForceAudioMute: PropTypes.func.isRequired,
  onForceVideoMute: PropTypes.func.isRequired,
  onKickOutUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  speakingStates: PropTypes.object.isRequired,
  isHost: PropTypes.bool.isRequired,
};

export default StudentList;
