/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import ArrowHideImg from '../../../../../../../../../../assets/icons/tablehide.svg';
import GreenTickImg from '../../../../../../../../../../assets/icons/donemark.svg';
import ArrowShowImg from '../../../../../../../../../../assets/icons/tableshow.svg';
import {Table} from '../../../../../../../../../../components/common';
import {AccordionContainer, SpreadSheetTableStyles} from './elements';

export const Accordion = ({
  title,
  subtitle,
  emailPatternErrorData,
  referIDErrorData,
  emailErrorData,
  spreadsheetcolumns,
  alreadyExistRefId,
  alreadyExistEmail,
  emailPattern,
  duplicateDataError,
}) => {
  const [isEmailExpanded, setIsEmailExpanded] = useState(false);
  const [isReferenceIdExpanded, setIsReferenceIdExpanded] = useState(false);
  const [isEmailPatternExpanded, setIsEmailPatternExpanded] = useState(false);
  const [isDuplicateDataExpanded, setIsDuplicateDataExpanded] = useState(false);

  const toggleEmail = () => setIsEmailExpanded(!isEmailExpanded);

  const toggleReferenceId = () =>
    setIsReferenceIdExpanded(!isReferenceIdExpanded);

  const toggleEmailPattern = () =>
    setIsEmailPatternExpanded(!isEmailPatternExpanded);

  const toggleDuplicateData = () =>
    setIsDuplicateDataExpanded(!isDuplicateDataExpanded);

  return (
    <AccordionContainer>
      <img src={GreenTickImg} alt="green tick" />
      <h2 className="mt-3 header">{title}</h2>
      <p className="state">{subtitle}</p>
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleEmail}
              >
                <div>Email Error ({alreadyExistEmail.length})</div>
                <div>
                  <img
                    alt="Show Works"
                    src={isEmailExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className={`collapse ${isEmailExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {alreadyExistEmail && alreadyExistEmail?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={emailErrorData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleReferenceId}
              >
                <div>Reference ID. Error ({alreadyExistRefId.length})</div>
                <div>
                  <img
                    alt="Show Works"
                    src={isReferenceIdExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${isReferenceIdExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {alreadyExistRefId && alreadyExistRefId?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={referIDErrorData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleEmailPattern}
              >
                <div>Email ID Pattern. Error ({emailPattern.length})</div>
                <div>
                  <img
                    alt="Show Works"
                    src={isEmailPatternExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${isEmailPatternExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {emailPattern && emailPattern?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={emailPatternErrorData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleDuplicateData}
              >
                <div>
                  Invalid and Duplicate Entries ({duplicateDataError.length})
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={isDuplicateDataExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${isDuplicateDataExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {duplicateDataError && duplicateDataError?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={duplicateDataError}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </AccordionContainer>
  );
};
