/* eslint-disable react/prop-types */
import React from 'react';
import {useHistory} from 'react-router-dom';
import backIcon from '../../../../../../../../../../assets/icons/arrow_left.svg';

import {TaskSubmissionHeaderContainer} from './elements';

export const TaskSubmissionHeader = ({task}) => {
  const history = useHistory();

  return (
    <TaskSubmissionHeaderContainer>
      <div className="basic-details-header">
        <div>
          <button
            onClick={() => history.goBack()}
            type="button"
            className="button-arrow"
          >
            <img
              alt="Back Button"
              className="header_t-back-icon"
              src={backIcon}
            />
          </button>
        </div>
        <h3>{task.ai_activityitem_title}</h3>

        <p className="basic-details-description">
          {task.ai_activityitem_description}
        </p>
      </div>
    </TaskSubmissionHeaderContainer>
  );
};
