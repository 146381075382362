/* eslint-disable react/prop-types */

import {useEffect, useMemo, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import axios from '../../../../../../../../api';
// import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {Select, Table} from '../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import HumanResourcesFormContainer from './elements/HumanResourcesFormContainer';
import ErrorMessage from '../ErrorMessage';

const HumanResourcesForm = ({
  control,
  errors,
  onUpdate,
  setUnits,
  units,
  tasStatus,
  isDataCompleted,
  showError,
}) => {
  const {orgClassId} = useParams();
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/admin/tas/${orgClassId}/teachers`)
      .then(({data: {oruiTeachers}}) => {
        setTeachers(
          oruiTeachers.map(item => ({
            label: `${item.up_name_first} ${item.up_name_last}`,
            value: item.up_id_userprofile,
          })),
        );
      });
  }, [orgClassId]);

  const tfuTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Cell: ({row: {original}, value}) => (
          <div className="column-table-value" style={{marginRight: '1.5rem'}}>
            {value} {original.otun_unit_name}
          </div>
        ),
        Header: 'Unit',
        accessor: 'otun_unit_code',
        className: 'unit',
      },
      {
        Cell: ({row: {original}, value}) => (
          <Select
            isClearable={false}
            isLarge={false}
            isDisabled={tasStatus}
            isLightBorder
            isMulti
            onChange={async (event, {option, removedValue}) => {
              setUnits(previousValue =>
                previousValue.map(item =>
                  item.otun_id_orgtasunit === original.otun_id_orgtasunit
                    ? {...item, humanResources: event}
                    : item,
                ),
              );
              if (option) {
                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/unit-human-resource`,
                  {
                    teacherId: option.value,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: value,
                  },
                );
              } else if (removedValue) {
                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/delete-unit-human-resource`,
                  {
                    teacherId: removedValue.value,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: event,
                  },
                );
              }
            }}
            options={teachers}
            placeholder=""
            value={value}
          />
        ),
        Header: 'Teachers',
        accessor: 'humanResources',
        className: 'teachers',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teachers, tasStatus],
  );

  return (
    <HumanResourcesFormContainer className="section" id="human-resources">
      <h3>Human Resources</h3>
      <p>
        Either in a strategy or separately, document the human resources
        available to deliver the training product. This ensures suitable
        trainers and assessors are available for all training products on your
        RTO’s scope of registration. Record this at a unit of competency level
        to ensure any specific requirements are met, and to allow your RTO to
        deploy staff efficiently.
      </p>
      <form>
        <div className="tas-form-row">
          <Controller
            control={control}
            name="humanResources"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.humanResources}
                hasInfo="<ul><li>Compare the physical resources required to deliver a training product with the resources available to your RTO. Many units of competency include detailed specifications of the resources required, so conducting this analysis at a unit of competency level ensures these requirements are addressed.</li></ul>"
                label="Human Resources"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                rows={5}
                disabled={tasStatus}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            {showError && isDataCompleted?.includes('mode-of-delivery') ? (
              <ErrorMessage
                errorMessage="Human Resources for units needed to be filled"
                className="error mb-2"
              />
            ) : null}
            <div className="column-table-title">
              Teachers for Unit
              {/* <img alt="Info" src={InfoIcon} /> */}
            </div>
            <Table
              columns={tfuTableColumns}
              data={units}
              isWithoutHeader
              isWithoutPagination
              showAllRows
            />
          </div>
        </div>
      </form>
    </HumanResourcesFormContainer>
  );
};

export default HumanResourcesForm;
