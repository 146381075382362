/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react';
import moment from 'moment';

import * as sessionApi from '../../../../../../../../api/session';

import {TimeLineCardContainer} from './elements';
import Timeline from '../../../../../../../Stream/pages/Stream/components/SessionSidebar/Timeline';

export const TimeLineCard = ({liveSessionPreference}) => {
  // const location = useLocation();
  // const [scheduledSession, setScheduledSession] = useState(null);
  const [sessionTimelines, setSessionTimelines] = useState([]);
  const [initialIndex, setInitialIndex] = useState(0);

  useEffect(() => {
    sessionApi
      .getSessions({
        groupBy: 'date',
      })
      .then(res => {
        const {status, sessions} = res.data;
        if (status && sessions.length) {
          const mappedSessions = sessions.map(({date, values}) => ({
            date,
            sessions: values.map(
              ({
                oruil_id_orgunitinstancelesson,
                oruil_name,
                oruil_timestart,
                oruil_timefinish,
                oruil_id_orgunitinstance,
              }) => ({
                from: oruil_timestart,
                link: `/stream/${oruil_id_orgunitinstance}/sessions/${oruil_id_orgunitinstancelesson}`,
                name: oruil_name,
                to: oruil_timefinish,
                id: oruil_id_orgunitinstancelesson,
              }),
            ),
          }));

          const reversedSessions = [...mappedSessions].reverse();
          reversedSessions.some((item, index) => {
            if (
              moment(item.date).isSameOrAfter(moment(new Date().toDateString()))
            ) {
              setInitialIndex(mappedSessions.length - index - 1);
              return true;
            }
            return false;
          });

          setSessionTimelines(mappedSessions);
        }
      });
  }, []);

  return (
    <TimeLineCardContainer>
      <div className="TimeLineCard">
        <div className="TimeLineCard-bottom">
          <Timeline
            timelines={sessionTimelines}
            initialIndex={initialIndex}
            liveSessionPreference={liveSessionPreference}
          />
        </div>
      </div>
    </TimeLineCardContainer>
  );
};
