import styled from 'styled-components';

const SessionVideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  .peer-info {
    padding: 8px;
    position: absolute;
    bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 15px;
    width: 100%;

    .host {
      height: auto;
      width: auto;
      padding: 15px;
      border-radius: 12px;
      background-color: rgba(13, 14, 16, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      svg {
        margin-right: 10px;
      }
    }
  }

  video {
    height: 100%;
    width: 100%;
    /* object-fit: contain !important; */
    transform: scale(1);
  }
`;

export default SessionVideoWrapper;
