/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
import React, {useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  getAllCourses,
  curriculumSelector,
} from '../../../../../../redux/curriculumSlice';
import {EmptyState, Table} from '../../../../../../components/common';

import ViewClassImg from '../../../../../../assets/icons/viewclass.svg';
// import {showToast} from '../../../../../../components/common/Toast';

import {TableCellLink} from '../../../../../../components/common/Table/styles';
import {TableSpinner} from '../../../../components-new';
import {CurriculumContainer} from './elements';

// const DELAY = 500;

export const CurriculumList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {courses, isListLoading} = useSelector(curriculumSelector);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Courses',
        accessor: 'ocr_course_name',
        className: 'course-name',
      },
      {
        Header: 'Modules',
        Cell: ({value}) => (
          <p className="modules">
            {value?.hasOwnProperty('OrgCourseCurriculumModules')
              ? `${value?.OrgCourseCurriculumModules?.length} Modules`
              : 'NA'}
          </p>
        ),
        accessor: 'OrgCourseCurriculum',
        className: 'modules-count',
      },
      {
        Header: '',
        Cell: ({value}) => (
          <div>
            <TableCellLink
              onClick={() => {
                history.push(`curriculum/view-curriculum/${value}`);
              }}
              type="button"
              className="view-module"
              id="view-module-id"
            >
              <span> View </span>
              <img
                src={ViewClassImg}
                alt="loader"
                className="viewmodule-arrow"
              />
            </TableCellLink>
          </div>
        ),
        accessor: 'ocr_id_orgcourse',
        className: 'view',
      },
    ],
    [history],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(courses || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [courses],
  );

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Curriculum | JungleCat</title>
      </Helmet>

      <CurriculumContainer>
        <h2 className="header-title">Curriculum</h2>
        <div className="table-container">
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading && Array.isArray(courses) && !courses?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No courses Found"
            />
          ) : null}

          {courses?.length && Array.isArray(courses) && !isListLoading ? (
            <Table
              columns={columns}
              data={data}
              itemName="Courses"
              maxWidth="80rem"
              isSortedBy
              globalFilterId="search-curriculum"
            />
          ) : null}
        </div>
      </CurriculumContainer>
    </>
  );
};
