import styled from 'styled-components';

export const WorkContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100vh;
  margin: 0 -0.9375rem;

  .work-container {
    display: flex;
    flex-direction: column;
    padding: 7rem 15rem 3rem 15rem;
    width: 100%;
  }

  @media (max-width: 70rem) {
    .work-container {
      display: flex;
      flex-direction: column;
      padding: 7rem 1rem 3rem 8rem;
      width: 100% !important;
    }
  }
  @media (max-width: 43.75rem) {
    .work-container {
      display: flex;
      flex-direction: column;
      padding: 12rem 1rem 3rem 7rem;
    }
  }
  @media (max-width: 37.5rem) {
    .work-container {
      display: flex;
      flex-direction: column;
      padding: 12rem 1rem 3rem 1rem;
    }
  }
  /* @media (max-width: 43.75rem) {
    & {
      max-width: unset;
    }
    .work-container {
      padding: 8% 5.6% 30% 5% !important;
    }
  }
  @media (min-width: 46.87rem) {
    .work-container {
      flex: unset;
      width: 70% !important;
    }
  } */
`;
