import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  DateInput,
  Select,
  TextInput,
} from '../../../../../../components/common';
import CustomBox from '../../../../../../components/shared/CustomBox';
import {
  createSession,
  fetchClassesByCourseId,
  fetchCourses,
  fetchTeachersByUnitId,
  fetchUnitsByClassId,
} from '../../../../../../redux/sessionsSlice';
import {sessionSchema} from '../../../../../../utils/validation';
import {AddSessionFooter, AddSessionHeader} from './components';
import {AddSessionContainer, AddSessionFormContainer} from './elements';
import {showToast} from '../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../assets/icons/addtoastadmin.svg';
import {TextArea} from '../../../../../../components/common/TextArea';

export const AddSession = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {control, errors, handleSubmit, watch, setValue, setError} = useForm({
    defaultValues: {
      sessionName: '',
      course: null,
      class: null,
      unit: null,
      teacher: null,
      date: '',
      startTime: '',
      endTime: '',
      description: '',
      // recurrence: null,
      // location: '',
    },
    resolver: yupResolver(sessionSchema),
  });
  const {
    courseOptions: courses,
    classOptions: classes,
    unitOptions: units,
    teacherOptions: teachers,
    isLoading,
  } = useSelector(state => state.sessions);

  const courseOptions = useMemo(
    () =>
      (courses || [])?.map(item => ({
        label: item.ocr_course_name,
        value: item.ocr_id_orgcourse,
        qualificationId: item.ocr_id_orgqual,
      })),
    [courses],
  );

  const classOptions = useMemo(
    () =>
      (classes || [])?.map(item => ({
        label: item.oc_class,
        value: item.oc_id_orgclass,
      })),
    [classes],
  );

  const unitOptions = useMemo(
    () =>
      (units || [])?.map(item => ({
        label: item.orgUnit.unit.tun_title,
        value: item.orui_id_orgunitinstance,
      })),
    [units],
  );

  const teacherOptions = useMemo(
    () =>
      (teachers || [])?.map(item => ({
        label: `${item.userProfile.up_name_first} ${item.userProfile.up_name_last}`,
        value: item.userProfile.up_id_userprofile,
      })),
    [teachers],
  );

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  const course = watch('course');
  useEffect(() => {
    dispatch(fetchClassesByCourseId(course?.value));
    setValue('class', null);
    setValue('unit', null);
    setValue('teacher', null);
  }, [course, dispatch, setValue]);

  const classItem = watch('class');
  useEffect(() => {
    dispatch(fetchUnitsByClassId(classItem?.value));
    setValue('unit', null);
    setValue('teacher', null);
  }, [classItem, dispatch, setValue]);

  const unit = watch('unit');
  useEffect(() => {
    dispatch(fetchTeachersByUnitId(unit?.value));
    setValue('teacher', null);
  }, [unit, dispatch, setValue]);

  const onSubmit = useCallback(
    data => {
      const details = {
        ...data,
        course: data.course.value,
        class: data.class.value,
        unit: data.unit.value,
        teacher: data.teacher.value,
      };
      if (!isLoading) {
        dispatch(
          createSession(details, message => {
            if (message.length) {
              setError('startTime', {
                type: 'focus',
                message,
              });
              return;
            }

            showToast(
              AddToastSuccessImg,
              `${data?.sessionName} created successfully`,
            );
            history.replace(`/admin/sessions`);
          }),
        );
      }
    },
    [dispatch, history, isLoading, setError],
  );

  return (
    <>
      <Helmet>
        <title>Add Session | JungleCat</title>
      </Helmet>
      <AddSessionContainer>
        <AddSessionHeader />
        <AddSessionFormContainer>
          <div className="container-class">
            <CustomBox padding="1.5rem">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                  <div className="mb-4">
                    <Controller
                      control={control}
                      name="sessionName"
                      render={fields => (
                        <TextInput
                          {...fields}
                          autoComplete="off"
                          errorMessage={
                            errors.sessionName?.message
                            // || formError?.sessionName
                          }
                          hasError={
                            !!errors.sessionName
                            // || !!formError.sessionName
                          }
                          label="Session Name"
                          placeholder="Enter session name"
                          id="session-name"
                        />
                      )}
                    />
                  </div>
                  <div className="row-container mb-4">
                    <div className="field-left field-left1 d-flex mb-0">
                      <Controller
                        control={control}
                        name="description"
                        render={fields => (
                          <TextArea
                            {...fields}
                            autoComplete="off"
                            errorMessage={
                              errors.description?.message
                              // || formError?.description
                            }
                            hasError={
                              !!errors.description
                              // || !!formError.description
                            }
                            label="Description"
                            placeholder="Enter session description"
                            style={{
                              resize: 'none',
                              height: '100%',
                            }}
                            containerClassName="flex-grow-1"
                            id="description-id"
                          />
                        )}
                      />
                    </div>
                    <div className="field-right">
                      <Controller
                        control={control}
                        name="course"
                        render={fields => (
                          <Select
                            {...fields}
                            hasError={!!errors.course}
                            errorMessage={errors.course?.message}
                            isLarge={false}
                            label="Course"
                            options={courseOptions}
                            placeholder="Choose a course"
                            className="mb-4"
                            id="course-id"
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="class"
                        render={fields => (
                          <Select
                            {...fields}
                            hasError={!!errors.class}
                            errorMessage={errors.class?.message}
                            isLarge={false}
                            label="Class"
                            options={classOptions}
                            placeholder="Choose a class"
                            isDisabled={!classOptions?.length}
                            id="class-id"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row-container">
                    <div className="field-left field-left1">
                      <Controller
                        control={control}
                        name="unit"
                        render={fields => (
                          <Select
                            {...fields}
                            hasError={!!errors.unit}
                            errorMessage={errors.unit?.message}
                            isLarge={false}
                            label="Unit"
                            options={unitOptions}
                            placeholder="Choose a unit"
                            isDisabled={!unitOptions?.length}
                            id="unit-id"
                          />
                        )}
                      />
                    </div>
                    <div className="field-right">
                      <Controller
                        control={control}
                        name="teacher"
                        render={fields => (
                          <Select
                            {...fields}
                            hasError={!!errors.teacher}
                            errorMessage={errors.teacher?.message}
                            isLarge={false}
                            label="Teacher"
                            options={teacherOptions}
                            placeholder="Choose a teacher"
                            isDisabled={!teacherOptions?.length}
                            id="teacher-id"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row-container">
                    <div className="field-left mb-0">
                      <Controller
                        control={control}
                        name="date"
                        render={fields => (
                          <DateInput
                            {...fields}
                            hasError={!!errors.date}
                            errorMessage={errors.date?.message}
                            label="Date"
                            placeholder="Choose a date"
                            dateFormat="d MMMM, yyyy"
                            // minDate={minDate}
                            // maxDate={maxDate}
                            id="date-id"
                          />
                        )}
                      />
                    </div>
                    <div className="field-right">
                      <div className="row-container">
                        <div className="field-left field-left1 mb-0">
                          <Controller
                            control={control}
                            name="startTime"
                            render={fields => (
                              <TextInput
                                {...fields}
                                autoComplete="off"
                                errorMessage={
                                  errors.startTime?.message
                                  // || formError?.startTime
                                }
                                hasError={
                                  !!errors.startTime
                                  // || !!formError.startTime
                                }
                                label="Start Time"
                                placeholder="Choose a start time"
                                type="time"
                                id="start-time-id"
                              />
                            )}
                          />
                        </div>
                        <div className="field-right">
                          <Controller
                            control={control}
                            name="endTime"
                            render={fields => (
                              <TextInput
                                {...fields}
                                autoComplete="off"
                                errorMessage={
                                  errors.endTime?.message
                                  // || formError?.endTime
                                }
                                hasError={
                                  !!errors.endTime
                                  // || !!formError.endTime
                                }
                                label="End Time"
                                placeholder="Choose a end time"
                                type="time"
                                id="end-time-id"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row-container">
                    <div className="field-left field-left1">
                      <Controller
                        control={control}
                        name="recurrence"
                        render={fields => (
                          <Select
                            {...fields}
                            hasError={!!errors.recurrence}
                            errorMessage={errors.recurrence?.message}
                            isLarge={false}
                            label="Recurrence"
                            // options={coursesOptions}
                            placeholder="Choose a recurrence"
                          />
                        )}
                      />
                    </div>
                    <div className="field-right">
                      <Controller
                        control={control}
                        name="location"
                        render={fields => (
                          <TextInput
                            {...fields}
                            autoComplete="off"
                            errorMessage={
                              errors.location?.message
                              // || formError?.location
                            }
                            hasError={
                              !!errors.location
                              // || !!formError.location
                            }
                            label="Location"
                            placeholder="Enter a location"
                          />
                        )}
                      />
                    </div>
                  </div> */}
                </div>
              </form>
            </CustomBox>
          </div>
        </AddSessionFormContainer>
        <AddSessionFooter isLoading={false} onSubmit={handleSubmit(onSubmit)} />
      </AddSessionContainer>
    </>
  );
};
