/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  getAllClasses,
  classesSelector,
  deleteClass,
  updateClass,
} from '../../../../../../redux/classesSlice';
import {
  EmptyState,
  LockedState,
  MoreOptions,
  Table,
  Modal,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import ViewClassImg from '../../../../../../assets/icons/viewclass.svg';
import {showToast} from '../../../../../../components/common/Toast';

import {TableCellLink} from '../../../../../../components/common/Table/styles';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {ClassesContainer} from './elements';
import {EditClassModal} from '../../components/EditClassModal';
import UpdateClassToastIcon from '../../../../../../assets/icons/class-success.svg';

const DELAY = 500;

export const Classes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    filterAcademicYears,
    classes,
    isListLoading,
    unitsCount,
    isClassesLoading,
    filterQualifications,
    filterCourses,
  } = useSelector(classesSelector);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [editDetails, setEditDetails] = useState(null);
  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  const renderLockedState = useCallback(
    (qualificationCount, unitCount, coursesCount) => {
      if (!qualificationCount && !unitCount && !coursesCount) {
        return (
          <LockedState
            title="No Qualification and units Found"
            description="You must first create a Qualification and unit before you can add class"
            onButtonClick={() => {
              history.replace('/admin/qualifications');
            }}
            buttonLabel="Add Qualification"
          />
        );
      }
      if (!unitsCount) {
        return (
          <LockedState
            title="No Units Found"
            description="You must first create a Unit you can add class"
            onButtonClick={() => {
              history.replace('/admin/qualifications/units');
            }}
            buttonLabel="Add Unit"
          />
        );
      }
      if (!qualificationCount) {
        return (
          <LockedState
            title="No Qualification Found"
            description="You must first create a Qualification before you can add class"
            onButtonClick={() => {
              history.replace('/admin/qualifications');
            }}
            buttonLabel="Add Qualification"
          />
        );
      }
      if (!coursesCount) {
        return (
          <LockedState
            title="No course Found"
            description="You must first create a course before you can add class"
            onButtonClick={() => {
              history.replace('/admin/courses/active-courses');
            }}
            buttonLabel="Add Course"
          />
        );
      }
      return null;
    },
    [history, unitsCount],
  );

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Class Name',
        accessor: 'oc_class',
        className: 'class-name',
      },
      {
        Header: 'Course',
        Cell: ({value}) => value || '-',
        accessor: 'course.ocr_course_name',
        className: 'course-name',
      },
      {
        Header: 'Qualification Name',
        accessor: 'qualification.tqual_title',
        className: 'qualification-name',
      },
      {
        Header: 'No of Units',
        Cell: ({value}) => (
          <div>
            <TableCellLink onClick={() => {}} type="button">
              {value}
            </TableCellLink>
          </div>
        ),
        accessor: 'unitCount',
        className: 'unit-count',
      },
      {
        Header: 'No of Students',
        Cell: ({value}) => (
          <div>
            <TableCellLink onClick={() => {}} type="button">
              {value}
            </TableCellLink>
          </div>
        ),
        accessor: 'studentCount',
        className: 'student-count',
      },
      {
        Header: 'No of Teachers',
        Cell: ({value}) => (
          <div>
            <TableCellLink onClick={() => {}} type="button">
              {value}
            </TableCellLink>
          </div>
        ),
        accessor: 'teacherCount',
        className: 'teacher-count',
      },
      {
        Header: 'Academic Year',
        Cell: ({row: {original}}) =>
          `${moment(new Date(original?.academicYear?.oay_from_date)).format(
            'MMM YYYY',
          )} - ${moment(new Date(original?.academicYear?.oay_to_date)).format(
            'MMM YYYY',
          )}`,
        accessor: 'academicYear.oay_id_orgacadyear',
        className: 'academic-year',
      },
      {
        Header: '',
        Cell: ({value}) => (
          <div>
            <TableCellLink
              onClick={() => {
                history.push(`classes/view-class/${value}`);
              }}
              type="button"
              className="view-class"
              id="view-class"
            >
              <span> View Class</span>
              <img
                src={ViewClassImg}
                alt="loader"
                className="viewclass-arrow"
              />
            </TableCellLink>
          </div>
        ),
        accessor: 'oc_id_orgclass',
        className: 'view',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit Class Details"
                id="edit-class-details"
                onClick={() => {
                  setEditDetails({
                    id: original?.oc_id_orgclass,
                    department: {
                      id: original?.department?.od_id_orgdepartment,
                      name: original?.department?.od_department_name,
                    },
                    className: original?.oc_class,

                    academicYear: {
                      id: original?.academicYear?.oay_id_orgacadyear,
                      name: original?.academicYear?.oay_year,
                      label: `${moment(
                        original?.academicYear?.oay_from_date,
                      ).format('MMM YYYY')} - ${moment(
                        original?.academicYear?.oay_to_date,
                      ).format('MMM YYYY')}`,
                    },
                    classTeacher: {
                      id: original?.teacher.up_id_userprofile,
                      name: `${original?.teacher.up_name_first} ${original?.teacher.up_name_last}`,
                    },

                    startDate: original?.oc_date_start,
                    endDate: original?.oc_date_end,
                  });
                }}
              />
              <DropdownItem
                isDanger
                label="Archive"
                id="archive"
                onClick={() => {
                  setDeleteId(original?.oc_id_orgclass);
                  setDeleteName(original?.oc_class);
                }}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'classes',
        className: 'more-option',
      },
    ],
    [history],
  );
  const formatOptionForQualification = ({code, label}, {context}) =>
    context === 'menu' ? (
      <div className="select-option" type="button">
        <div className="label">{label}</div>
        <div className="code">{code}</div>
      </div>
    ) : (
      label
    );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(classes || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [classes],
  );

  useEffect(() => {
    dispatch(getAllClasses());
  }, [dispatch]);

  // Search for academic years
  const academicYearOptions = useMemo(
    () => [
      {label: 'All Academic Years', value: ''},
      ...(filterAcademicYears?.map(item => {
        const academicYear = `${moment(new Date(item?.oay_from_date)).format(
          'MMM YYYY',
        )} - ${moment(new Date(item?.oay_to_date)).format('MMM YYYY')}`;
        return {
          label: academicYear,
          value: item.oay_id_orgacadyear,
        };
      }) || []),
    ],
    [filterAcademicYears],
  );

  // Search for qualification
  const qualificationsOptions = useMemo(
    () => [
      {label: 'All Qualifications', value: ''},
      ...(filterQualifications?.map(item => ({
        label: item.qualification.tqual_title,
        value: item.qualification.tqual_title,
        code: item.qualification.tqual_code,
      })) || []),
    ],
    [filterQualifications],
  );

  const onSubmit = useCallback(
    detail => {
      dispatch(
        updateClass(editDetails.id, detail, () => {
          clearEditDetails();
          showToast(UpdateClassToastIcon, `Class details updated successfully`);
        }),
      );
    },
    [clearEditDetails, dispatch, editDetails],
  );

  return (
    <>
      <Helmet>
        <title>Classes | JungleCat</title>
      </Helmet>

      <ClassesContainer>
        <ListingPageHeader
          buttonLabel="Add Class"
          className="mb-4"
          onButtonClick={() => {
            history.push(`classes/add-class`);
          }}
          title="Classes"
          btnId="add-class"
        />

        <div className="table-container">
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading &&
          Array.isArray(classes) &&
          !classes?.length &&
          filterQualifications?.length &&
          unitsCount &&
          filterCourses?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Classes Found"
            />
          ) : null}

          {(!isListLoading &&
            renderLockedState(
              filterQualifications?.length,
              unitsCount,
              filterCourses?.length,
            )) ||
            (classes?.length && Array.isArray(classes) && !isListLoading ? (
              <Table
                columns={columns}
                data={data}
                filters={[
                  {
                    column: 'academicYear.oay_id_orgacadyear',
                    data: academicYearOptions,
                    itemName: 'Academic Years',
                  },
                  {
                    column: 'qualification.tqual_title',
                    data: qualificationsOptions,
                    itemName: 'Qualifications',
                    formatOptionLabel: formatOptionForQualification,
                  },
                ]}
                itemName="Classes"
                maxWidth="80rem"
                isSortedBy
              />
            ) : null)}
        </div>
        <Modal
          buttonLabel="Archive"
          description="Are you sure you want to archive this class?"
          isDelete
          isButtonLoading={isClassesLoading}
          loadingButtonLabel="Archiving"
          onButtonClick={() => {
            dispatch(
              deleteClass(deleteId, () => {
                setDeleteId(null);
                showToast(false, `${deleteName} has been archived.`, true);
                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }),
            );
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Archive ${deleteName}`}
          width="41.25rem"
        />
        <EditClassModal
          editDetails={editDetails}
          onHide={clearEditDetails}
          onSubmit={onSubmit}
        />
      </ClassesContainer>
    </>
  );
};
