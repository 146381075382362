/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {Typeahead} from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import {Helmet} from 'react-helmet';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  getUnitById,
  adminInfo,
  deleteStudentFromUnit,
  editUnitStudent,
  setCurrentNewUnit,
  unitStudentResendInvitation,
  setViewUnitToastSuccess,
  getAllUnitsClass,
  getAllUnitsDepartments,
  getAllUnitsTeachers,
  editUnitById,
  deleteUnit as deleteUnitById,
  setDeletetUnitToastSuccess,
} from '../../adminSlice';
import arrowLeft from '../../../../assets/icons/arrow_left.svg';
import {
  ViewUnit,
  DropdownBtn,
  StudentTableContainer,
  CustomDatePicker,
} from '../../Elements/UnitsContainer';
import ThreeDotIcon from '../../../../assets/icons/threedot.svg';
import addIcon from '../../../../assets/icons/addIcon.svg';
import CustomIconButton from '../../../../components/shared/CustomIconButton';
import CustomBox from '../../../../components/shared/CustomBox';
import {
  CustomTable,
  tabularize,
} from '../../../../components/shared/CustomTable';
import SearchInput from '../../../../components/shared/Inputs/SearchInput';
import tooltipImg from '../../../../assets/icons/tooltipimg.svg';
import EmptyState from '../../../../components/shared/EmptyState';
import {Dropdown} from '../../../../components/shared/Dropdown';
import DropdownItems from '../../../../components/shared/DropdownItems/DropdownItems';
import DropdownWrapper from '../../../../components/shared/DropdownWrapper/DropdownWrapper';
import prev from '../../../../assets/icons/prev-btn.svg';
import next from '../../../../assets/icons/next-btn.svg';
import CustomButton from '../../../../components/shared/CustomButton';
import CustomTextInput from '../../../../components/shared/CustomTextInput';
import CustomCancel from '../../../../components/shared/Buttons/CustomButton';
import CustomModal from '../../../../components/shared/CustomModal';
import closeModal from '../../../../assets/icons/close-modal.svg';
import deleteModal from '../../../../assets/icons/delete-modal.svg';
import ToastContainer from '../../../../components/shared/ToastMessage';
import toastSuccess from '../../../../assets/icons/toast-add-success.svg';
import loader from '../../../../assets/icons/orgLogoLoader.svg';
import calenderIcon from '../../../../assets/icons/calender-icon.svg';
import profileImg from '../../../../assets/icons/profile.svg';

const StudentModal = ({
  isModalVisible,
  button,
  email,
  setFullName,
  fullName,
  lastName,
  setLastName,
  setEmail,
  refID,
  setReferID,
  setIsModalVisible,
  onClick,
  title,
  subtitle,
}) => (
  <CustomModal isVisible={isModalVisible}>
    <div className="modal-container-top">
      <div className="close-btn">
        <button
          onClick={() => {
            setIsModalVisible(false);
          }}
          type="button"
          className="closeModalResBtn"
        >
          <img src={closeModal} alt="close" />
        </button>
      </div>
      <CustomBox className="modal-container">
        <div className="add-student-modal" style={{padding: '24px'}}>
          <div className="add-student-modal-header">
            <div>
              <h2 style={{fontSize: '24px'}}>{title}</h2>
              <p>{subtitle}</p>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsModalVisible(false);
                  // setFullName('');
                  // setEmail('');
                  // setReferID('');
                }}
                type="button"
                className="closeIconBtn"
              >
                <img src={closeModal} alt="close" />
              </button>
            </div>
          </div>
          <div className="add-student-modal-body mb-3">
            <div className="date-wrapper1">
              <CustomTextInput
                label="Reference ID"
                value={refID}
                onChange={e => {
                  setReferID(e.target.value);
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
              />
            </div>
            <div className="date-wrapper2">
              <CustomTextInput
                label="First Name"
                value={fullName}
                onChange={e => {
                  setFullName(e.target.value);
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
              />
            </div>
            <div className="date-wrapper3">
              <CustomTextInput
                label="Last Name"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value);
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
              />
            </div>
          </div>
          <CustomTextInput
            label="Email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
          />
        </div>
        <div className="modal-seperator" />
        <div className="add-student-modal" style={{padding: '24px'}}>
          <div className="button-container">
            <CustomCancel
              className="cancel"
              bgColor="#fff"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              <span className="cancel-button-text">Cancel</span>
            </CustomCancel>
            <CustomButton
              className="save-changes"
              text={button}
              onClick={onClick}
            />
          </div>
        </div>
      </CustomBox>
    </div>
  </CustomModal>
);
const MoreOption = ({
  unitInstanceStudentId,
  studentId,
  fName,
  lName,
  email,
  refID,
  invitationStatus,
  setDpName,
  setUnitInstanceStudentId,
  setDeleteModalVisible,
  // showToastSuccess,
}) => {
  const [fullName, setFullName] = useState(`${fName}`);
  const [lastName, setLastName] = useState(`${lName}`);
  const [useremail, setEmail] = useState(email);
  const [referID, setReferID] = useState(refID);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  return (
    <div className="dropdowncontainer">
      <DropdownWrapper toggleMenu={setDropdownVisibility}>
        <DropdownBtn>
          <button
            className="btn"
            onClick={() => setDropdownVisibility(!dropdownVisibility)}
            type="submit"
          >
            <img alt="Options" src={ThreeDotIcon} className="threeDot" />
          </button>
        </DropdownBtn>
        {dropdownVisibility && (
          <Dropdown className="dropdown">
            {invitationStatus === 1 ? null : (
              <DropdownItems
                click={() => {
                  setDropdownVisibility(false);
                  const data = {
                    fullname: fullName,
                    lastname: lastName,
                    email: useremail,
                    refId: referID,
                  };
                  dispatch(
                    unitStudentResendInvitation(
                      studentId,
                      unitInstanceStudentId,
                      data,
                    ),
                  );
                }}
                text="Resend Invitation"
              />
            )}
            <DropdownItems
              click={() => {
                setIsEditModalVisible(true);
                setDropdownVisibility(false);
              }}
              text="Edit"
            />
            <DropdownItems
              withoutSeperator
              isDanger
              click={() => {
                setDropdownVisibility(false);
                setDeleteModalVisible(true);
                setDpName(`${fullName} ${lastName}`);
                setUnitInstanceStudentId(unitInstanceStudentId);
                // eslint-disable-next-line no-alert
              }}
              text="Delete"
            />
          </Dropdown>
        )}
      </DropdownWrapper>
      <StudentModal
        title="Edit User details"
        subtitle="Make changes to the user details"
        button="Save Changes"
        isModalVisible={isEditModalVisible}
        email={useremail}
        setEmail={setEmail}
        refID={referID}
        setReferID={setReferID}
        fullName={fullName}
        lastName={lastName}
        setFullName={setFullName}
        setLastName={setLastName}
        setIsModalVisible={setIsEditModalVisible}
        onClick={() => {
          const studentDetails = {};
          if (fullName) {
            studentDetails.fullName = fullName;
          }
          if (lastName) {
            studentDetails.lastName = lastName;
          }
          if (useremail) {
            studentDetails.useremail = useremail;
          }
          if (referID) {
            studentDetails.referID = referID;
          }
          setIsEditModalVisible(false);
          dispatch(
            editUnitStudent(
              studentId,
              unitInstanceStudentId,
              studentDetails,
              () => {
                dispatch(
                  setViewUnitToastSuccess(`User Details updated successfully`),
                );
                setTimeout(() => dispatch(setViewUnitToastSuccess('')), 5000);
              },
            ),
          );
        }}
      />
    </div>
  );
};

const EditUnitModal = ({
  isModalVisible,
  setIsModalVisible,
  orgUnitInstanceId,
  className,
  subjectName,
  unitDepartmentName,
  // setUnitDepartmentName,
  unitTeacherName,
  // setUnitTeacherName,
  unitStartDate,
  setUnitStartDate,
  unitEndDate,
  setUnitEndDate,
  showToastSuccess,
  unitName,
}) => {
  const {unitDepartments} = useSelector(adminInfo);
  const {unitTeachers, unitClass} = useSelector(adminInfo);
  const [addTeacher, setAddTeacher] = useState([
    {
      up_id_userprofile: unitTeacherName[0]?.up_id_userprofile,
      up_name_first: `${unitTeacherName[0]?.up_name_first}`,
      up_name_last: `${unitTeacherName[0]?.up_name_last}`,
      up_org_reference: unitTeacherName[0]?.up_org_reference,
    },
  ]);
  const [addDepartment, setAddDepartment] = useState([
    {
      id: unitDepartmentName?.od_id_orgdepartment,
      name: unitDepartmentName?.od_department_name,
    },
  ]);
  // console.log(unitClass);
  // const [unitClassName, setUnitClassName] = useState(className);
  const [unitSubjectName, setUnitSubjectName] = useState(
    subjectName?.un_unitname,
  );
  const [classData, setClassData] = useState(className);
  const [newClass, setNewClass] = useState();
  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState(false);
  const classDropdown = useRef();
  if (Date.parse(unitEndDate) < Date.parse(unitStartDate)) {
    setUnitEndDate('');
  }
  const openStartDatePicker = () => {
    setStartDatePickerIsOpen(!startDatePickerIsOpen);
  };
  const openEndDatePicker = () => {
    setEndDatePickerIsOpen(!endDatePickerIsOpen);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUnitsDepartments());
    dispatch(getAllUnitsClass());
    return () => {
      dispatch(getAllUnitsClass());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUnitsTeachers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setClassData(className);
  }, [className]);

  return (
    <div>
      <div>
        <CustomModal isVisible={isModalVisible}>
          <div className="modal-container-top">
            <div className="close-btn">
              <button
                onClick={() => {
                  setIsModalVisible(false);
                }}
                type="button"
                className="closeModalResBtn"
              >
                <img src={closeModal} alt="close" />
              </button>
            </div>
            <CustomBox className="modal-container">
              <div className="edit-unit-modal" style={{padding: '24px'}}>
                <div className="edit-unit-modal-header mb-3">
                  <div>
                    <h2 style={{fontSize: '24px'}}>Edit Unit details</h2>
                    <p>Make changes to the user details</p>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setIsModalVisible(false);
                      }}
                      type="button"
                      className="closeIconBtn"
                    >
                      <img src={closeModal} alt="close" />
                    </button>
                  </div>
                </div>
                <div className="add-unit-header">
                  <p className="add-unit-header-title">
                    <span className="edit-unit-title">Unit Name</span>{' '}
                    <span className="edit-unit-name ml-2">
                      {/* {className?.oc_class} - {unitSubjectName} */}
                      {unitName}
                    </span>
                  </p>
                </div>
                <div className="add-unit-custom-box">
                  <div>
                    <div>
                      <form className="add-unit-form">
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="typehead-title">Class Name</p>
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onInputChange={value => {
                                setNewClass(value);
                                if (
                                  !unitClass.find(item =>
                                    item.oc_class
                                      .toLowerCase()
                                      .includes(value.toLowerCase()),
                                  )
                                ) {
                                  classDropdown.current.hideMenu();
                                }
                              }}
                              onChange={value => {
                                setClassData(value);
                              }}
                              ref={classDropdown}
                              placeholder="Choose Class"
                              selected={classData}
                              options={unitClass.map(item => ({
                                id: item.oc_id_orgclass,
                                name: item.oc_class,
                              }))}
                              className="unit-class-name"
                            />
                          </div>
                          <div className="add-unit-col">
                            <CustomTextInput
                              autoComplete="off"
                              label="Subject"
                              height="38px"
                              marginBottom="8px"
                              placeholder="Enter Subject name"
                              onChange={e => setUnitSubjectName(e.target.value)}
                              value={unitSubjectName}
                            />
                          </div>
                        </div>
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="typehead-title ">Department</p>
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onChange={value => {
                                setAddDepartment(value);
                              }}
                              placeholder="Choose Department"
                              selected={addDepartment}
                              options={unitDepartments.map(item => ({
                                id: item.od_id_orgdepartment,
                                name: item.od_department_name,
                              }))}
                              className="unit-class-name"
                            />
                          </div>
                          <div className="add-unit-col">
                            <p className="mb-2" style={{color: '#3d4457'}}>
                              Teacher
                            </p>
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey={option =>
                                `${option?.up_name_first} ${option.up_name_last}`
                              }
                              onChange={value => {
                                setAddTeacher(value);
                              }}
                              placeholder="Choose Teacher"
                              selected={addTeacher}
                              filterBy={() => true}
                              options={unitTeachers}
                              renderMenuItemChildren={option => (
                                <div className="teacher-row">
                                  <div className="profile-img">
                                    <img src={profileImg} alt="src" />
                                  </div>
                                  <div className="profile-name">
                                    <p>
                                      {`${option?.up_name_first} ${option?.up_name_last}`}{' '}
                                    </p>
                                    <span className="ref-id">
                                      {`${option?.up_org_reference}`}
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              Start date
                            </p>
                            <CustomDatePicker className="d-flex">
                              <DatePicker
                                name="dueDate"
                                autoComplete="off"
                                dateFormat="do MMMM yyyy"
                                placeholderText="Choose start date"
                                selected={unitStartDate}
                                className="form-control border-0 w-100  date-picker"
                                popperPlacement="bottom"
                                onChange={date => {
                                  setUnitStartDate(date);
                                  setStartDatePickerIsOpen(false);
                                }}
                                onClickOutside={() =>
                                  setStartDatePickerIsOpen(false)
                                }
                                open={startDatePickerIsOpen}
                                onFocus={() => {
                                  openStartDatePicker();
                                }}
                              />
                              <button
                                className="openDatepicker"
                                type="button"
                                onClick={() => {
                                  openStartDatePicker();
                                }}
                              >
                                <img
                                  src={calenderIcon}
                                  className="mr-1"
                                  alt="date"
                                />
                              </button>
                            </CustomDatePicker>
                          </div>
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              End date
                            </p>
                            <CustomDatePicker className="d-flex">
                              <DatePicker
                                name="dueDate"
                                autoComplete="off"
                                dateFormat="do MMMM yyyy"
                                placeholderText="Choose end date"
                                selected={unitEndDate}
                                onChange={date => {
                                  setUnitEndDate(date);
                                  setEndDatePickerIsOpen(false);
                                }}
                                minDate={new Date(unitStartDate)}
                                onClickOutside={() =>
                                  setEndDatePickerIsOpen(false)
                                }
                                open={endDatePickerIsOpen}
                                className="form-control border-0 w-100  date-picker"
                                popperPlacement="bottom"
                                onFocus={() => {
                                  openEndDatePicker();
                                }}
                              />
                              <button
                                className="openDatepicker"
                                type="button"
                                onClick={() => {
                                  openEndDatePicker();
                                }}
                              >
                                <img
                                  src={calenderIcon}
                                  className="mr-1"
                                  alt="date"
                                />
                              </button>
                            </CustomDatePicker>
                          </div>
                        </div>
                      </form>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-seperator" />
              <div className="add-student-modal" style={{padding: '24px'}}>
                <div className="button-container">
                  <CustomCancel
                    className="cancel"
                    bgColor="#fff"
                    onClick={() => {
                      setIsModalVisible(false);
                    }}
                  >
                    <span className="cancel-button-text">Cancel</span>
                  </CustomCancel>
                  <CustomButton
                    className="save-changes"
                    text="Save Changes"
                    onClick={() => {
                      // const unitClass = {
                      //   id: className?.oc_id_orgclass,
                      //   classname: unitClassName,
                      // };
                      const unit = {
                        id: subjectName?.un_id_unit,
                        unitname: unitSubjectName,
                      };
                      const unitData = {
                        newclass: newClass,
                        classname: classData ? classData[0] : null,
                        subjectname: unit,
                        department: addDepartment[0],
                        teacher: addTeacher[0],
                        startdate: unitStartDate,
                        enddate: unitEndDate,
                      };
                      if (
                        unitClass &&
                        unitStartDate &&
                        unitName &&
                        unitEndDate &&
                        addDepartment &&
                        addTeacher
                      ) {
                        dispatch(
                          editUnitById(unitData, orgUnitInstanceId, () => {
                            setIsModalVisible(false);
                            showToastSuccess(
                              `Unit Details updated successfully`,
                            );
                            setTimeout(() => showToastSuccess(''), 5000);
                          }),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </CustomBox>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};
const EditDeteteOption = ({
  orgUnitInstanceId,
  unit,
  ocClass,
  departmentName,
  teacherName,
  startDate,
  endDate,
  setUnitDeleteModalVisible,
  setUnitName,
  setUnitInstanceId,
  showToastSuccess,
}) => {
  const [unitStartDate, setUnitStartDate] = useState(
    moment(startDate).toDate(),
  );
  const [unitEndDate, setUnitEndDate] = useState(moment(endDate).toDate());
  const [unitTeacherName, setUnitTeacherName] = useState(teacherName);
  const [unitDepartmentName, setUnitDepartmentName] = useState(departmentName);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  return (
    <div className="dropdowncontainer">
      <DropdownWrapper toggleMenu={setDropdownVisibility}>
        <DropdownBtn>
          <button
            className="btn"
            onClick={() => setDropdownVisibility(!dropdownVisibility)}
            type="submit"
          >
            <img alt="Options" src={ThreeDotIcon} className="threeDot" />
          </button>
        </DropdownBtn>
        {dropdownVisibility && (
          <Dropdown className="dropdown">
            <DropdownItems
              click={() => {
                setIsEditModalVisible(true);
                setDropdownVisibility(false);
              }}
              text="Edit"
            />
            <DropdownItems
              withoutSeperator
              isDanger
              click={() => {
                setDropdownVisibility(false);
                // eslint-disable-next-line no-alert
                setUnitInstanceId(orgUnitInstanceId);
                setUnitName(`${ocClass.oc_class} - ${unit.un_unitname}`);
                setUnitDeleteModalVisible(true);
              }}
              text="Delete"
            />
          </Dropdown>
        )}
      </DropdownWrapper>
      <EditUnitModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        orgUnitInstanceId={orgUnitInstanceId}
        className={[{id: ocClass.oc_id_orgclass, name: ocClass.oc_class}]}
        subjectName={unit}
        unitDepartmentName={unitDepartmentName}
        setUnitDepartmentName={setUnitDepartmentName}
        unitTeacherName={unitTeacherName}
        setUnitTeacherName={setUnitTeacherName}
        unitStartDate={unitStartDate}
        setUnitStartDate={setUnitStartDate}
        unitEndDate={unitEndDate}
        setUnitEndDate={setUnitEndDate}
        showToastSuccess={showToastSuccess}
        unitName={`${ocClass?.oc_class} - ${unit?.un_unitname}`}
      />
    </div>
  );
};
const EachUnit = () => {
  const history = useHistory();
  const {orgUnitInstanceId} = useParams();
  const {unit, studentCount, unitDataLoading, viewUnitToastSuccess} =
    useSelector(adminInfo);
  const [searchTerm, setSearchTerm] = useState('');
  const [tableColumns, setTableColumns] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableMaps, setTableMaps] = useState(null);
  const dispatch = useDispatch();
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isUnitDeleteModalVisible, setUnitDeleteModalVisible] = useState(false);
  const [dpName, setDpName] = useState();
  const [unitInstanceStudentId, setUnitInstanceStudentId] = useState('');

  const [pageNumbers, setPageNumbers] = useState([]);
  const [page, setPage] = useState(1);
  const [unitName, setUnitName] = useState('');
  const [unitInstanceId, setUnitInstanceId] = useState('');
  const [isToastSuccess, showToastSuccess] = useState('');
  const [isToastDanger, showToastDanger] = useState('');
  useEffect(() => {
    const pages = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= Math.ceil(studentCount / 5); i++) {
      pages.push(i);
    }
    setPageNumbers(pages);
  }, [studentCount]);
  useEffect(() => {
    dispatch(getUnitById(orgUnitInstanceId));

    const columns = [
      'oruis_id_orgunitinstancestudent',
      {
        heading: '#',
        width: '5%',
      },
      {
        heading: 'ID',
        width: '10%',
      },
      {
        heading: 'FIRST NAME',
        width: '18%',
      },
      {
        heading: 'LAST NAME',
        width: '18%',
      },
      {
        heading: 'EMAIL',
        width: '25%',
      },
      {
        heading: 'STATUS',
        width: '25%',
      },
      {
        heading: '',
        width: '7%',
      },
    ];
    const maps = {
      '#': [studentReference => (page - 1) * 5 + (studentReference + 1)],
      ID: [studentReferenceId => studentReferenceId, 'up_org_reference'],
      // 'FULL NAME': [
      //   (fName, lName) => `${fName} ${lName}`,
      //   'up_name_first',
      //   'up_name_last',
      // ],
      'FIRST NAME': [fName => fName, 'up_name_first'],
      'LAST NAME': [lName => lName, 'up_name_last'],
      EMAIL: [studentEmail => studentEmail, 'up_email'],
      STATUS: [
        (invitationStatus, invitationTime) => (
          <div className="invitation-container">
            <div className={`invitation-status-${invitationStatus}`}>
              {invitationStatus === 1 ? 'Active' : 'Pending'}
            </div>
            {invitationStatus > 1 ? (
              <div>
                <div
                  className="invited"
                  data-html
                  data-tip={`<span className="tooltip-value">Invite resended on : ${moment(
                    invitationTime,
                  ).format('D MMM YYYY')}</span>`}
                >
                  <img alt="Reassigned" src={tooltipImg} />
                </div>
                <ReactTooltip
                  backgroundColor="#3d4457"
                  effect="solid"
                  place="bottom"
                />
              </div>
            ) : null}
          </div>
        ),
        'up_invitation_status',
        'up_timestamp_creation',
      ],
      '': [
        (
          orgUnitInstanceStudentId,
          studentId,
          fName,
          lName,
          email,
          refererId,
          invitationStatus,
        ) => (
          <MoreOption
            unitInstanceStudentId={orgUnitInstanceStudentId}
            orgUnitInstanceId={orgUnitInstanceId}
            studentId={studentId}
            fName={fName}
            lName={lName}
            email={email}
            refID={refererId}
            invitationStatus={invitationStatus}
            setDpName={setDpName}
            setDeleteModalVisible={setDeleteModalVisible}
            setUnitInstanceStudentId={setUnitInstanceStudentId}
            // showToastSuccess={showToastSuccess}
          />
        ),
        'oruis_id_orgunitinstancestudent',
        'up_id_userprofile',
        'up_name_first',
        'up_name_last',
        'up_email',
        'up_org_reference',
        'up_invitation_status',
      ],
    };
    setTableColumns(columns);
    setTableMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const maps = {
      '#': [studentReference => (page - 1) * 5 + (studentReference + 1)],
      ID: [studentReferenceId => studentReferenceId, 'up_org_reference'],
      'FIRST NAME': [fName => fName, 'up_name_first'],
      'LAST NAME': [lName => lName, 'up_name_last'],
      EMAIL: [studentEmail => studentEmail, 'up_email'],
      STATUS: [
        (invitationStatus, invitationTime) => (
          <div className="invitation-container">
            <div className={`invitation-status-${invitationStatus}`}>
              {invitationStatus === 1 ? 'Active' : 'Pending'}
            </div>
            {invitationStatus > 1 ? (
              <div>
                <div
                  className="invited"
                  data-html
                  data-tip={`<span className="tooltip-value">Invite resended on : ${moment(
                    invitationTime,
                  ).format('D MMM YYYY')}</span>`}
                >
                  <img alt="Reassigned" src={tooltipImg} />
                </div>
                <ReactTooltip
                  backgroundColor="#3d4457"
                  effect="solid"
                  place="bottom"
                />
              </div>
            ) : null}
          </div>
        ),
        'up_invitation_status',
        'up_timestamp_creation',
      ],
      '': [
        (
          orgUnitInstanceStudentId,
          studentId,
          fName,
          lName,
          email,
          refererId,
          invitationStatus,
        ) => (
          <MoreOption
            unitInstanceStudentId={orgUnitInstanceStudentId}
            orgUnitInstanceId={orgUnitInstanceId}
            studentId={studentId}
            fName={fName}
            lName={lName}
            email={email}
            refID={refererId}
            invitationStatus={invitationStatus}
            setDpName={setDpName}
            setDeleteModalVisible={setDeleteModalVisible}
            setUnitInstanceStudentId={setUnitInstanceStudentId}
            // showToastSuccess={showToastSuccess}
          />
        ),
        'oruis_id_orgunitinstancestudent',
        'up_id_userprofile',
        'up_name_first',
        'up_name_last',
        'up_email',
        'up_org_reference',
        'up_invitation_status',
      ],
    };
    setTableMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useEffect(() => {
    dispatch(setCurrentNewUnit(unit));
  }, [unit]);

  useEffect(() => {
    if (unit) {
      setTableData(
        unit?.students?.filter(profile =>
          `${profile?.up_name_first} ${profile?.up_name_last} ${profile?.up_email} `
            .toLowerCase()
            .includes(searchTerm),
        ),
      );
    }
  }, [unit, searchTerm]);

  return (
    <>
      <Helmet>
        <title>View Units | JungleCat</title>
      </Helmet>
      <ViewUnit>
        <div className="view-unit-header">
          <div className="back-button">
            <button
              className="btn"
              onClick={() => history.replace('/admin/units')}
              style={{
                padding: 0,
              }}
              type="button"
            >
              <img src={arrowLeft} alt="arrow" />
            </button>
          </div>

          {unit ? (
            <div className="view-unit-title">
              <div>
                <span className="unit-name">
                  {unit?.class?.oc_class} - {unit?.unit?.un_unitname}
                </span>
                <p className="created-date">
                  Created on :{' '}
                  {moment(unit?.ou_timestamp_creation).format('D MMMM, YYYY')}
                </p>
              </div>
              <div>
                <EditDeteteOption
                  orgUnitInstanceId={orgUnitInstanceId}
                  unit={unit?.unit}
                  ocClass={unit?.class}
                  departmentName={unit?.department}
                  teacherName={unit?.teacher}
                  startDate={unit?.orui_date_start}
                  endDate={unit?.orui_date_finish}
                  setUnitDeleteModalVisible={setUnitDeleteModalVisible}
                  setUnitName={setUnitName}
                  setUnitInstanceId={setUnitInstanceId}
                  showToastSuccess={showToastSuccess}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="header-break" />
        {unit ? (
          <div className="view-unit-header-bottom">
            <div className="view-list">
              <span className="unit-list-top">Unit Teacher</span>
              <p className="unit-list-bottom">
                {`${unit?.teacher[0]?.up_name_first} ${unit?.teacher[0]?.up_name_last}`}
              </p>
            </div>
            <div className="vertical-line" />
            <div className="view-list">
              <span className="unit-list-top">Department</span>
              <p className="unit-list-bottom">
                {unit?.department?.od_department_name}
              </p>
            </div>
            <div className="vertical-line" />
            <div className="view-list">
              <span className="unit-list-top">Total Students</span>
              <p className="unit-list-bottom">
                {`${unit?.students?.length} Students`}
              </p>
            </div>
            <div className="vertical-line" />
            <div className="view-list">
              <span className="unit-list-top">Start Date</span>
              <p className="unit-list-bottom">
                {moment(unit?.orui_date_start).format('D MMMM, YYYY')}
              </p>
            </div>
            <div className="vertical-line" />
            <div className="view-list">
              <span className="unit-list-top">End Date</span>
              <p className="unit-list-bottom">
                {moment(unit?.orui_date_finish).format('D MMMM, YYYY')}
              </p>
            </div>
          </div>
        ) : null}
      </ViewUnit>
      <StudentTableContainer>
        {unit ? (
          <div className="student-container-unit">
            <CustomBox padding="24px 24px 0 22px">
              <div className="student-header">
                <div className="student-table-header-right">
                  <div>
                    <p className="student-count">{studentCount} Students</p>
                  </div>
                  <div>
                    <CustomIconButton
                      className="addbtn1"
                      icon={addIcon}
                      text="Add Students"
                      onClick={() => {
                        history.push({
                          pathname: `${orgUnitInstanceId}/add-student`,
                          state: {spreadNext: 1, unitData: unit, progress: 1},
                        });
                      }}
                    >
                      {' '}
                    </CustomIconButton>
                  </div>
                </div>
                <div className="student-table-header-left">
                  <div>
                    <CustomIconButton
                      className="addbtn"
                      icon={addIcon}
                      text="Add Students"
                      onClick={() => {
                        history.push({
                          pathname: `${orgUnitInstanceId}/add-student`,
                          state: {spreadNext: 1, unitData: unit, progress: 1},
                        });
                      }}
                    >
                      {' '}
                    </CustomIconButton>
                  </div>
                  <div>
                    <SearchInput
                      className="student-search"
                      onChange={event => setSearchTerm(event.target.value)}
                      placeholder="Search"
                      value={searchTerm}
                      width="300px"
                    />
                  </div>
                </div>
              </div>
              {tableColumns && tableData && tableMaps && !unitDataLoading ? (
                <CustomTable
                  {...tabularize(tableColumns, tableData, tableMaps)}
                  className="student-table"
                />
              ) : null}
              <div className="student-table-header-line" />
              {!unitDataLoading && tableData?.length === 0 ? (
                <div>
                  <EmptyState
                    body="Try adjusting the search for what you are looking for"
                    title="No students Found"
                  />
                </div>
              ) : null}
              {unitDataLoading ? (
                <div className="loader-images">
                  <img src={loader} alt="loader" className="button-spinner1" />
                </div>
              ) : null}
              {tableData?.length !== 0 ? (
                <div className="bottom-row">
                  <div className="pageno">{`Page ${page} of ${pageNumbers?.length}`}</div>
                  <div>
                    <ul className="pagination">
                      <li key="1" className="page-prev">
                        <button
                          onClick={() => {
                            if (page !== 1) {
                              dispatch(
                                getUnitById(orgUnitInstanceId, (page - 2) * 5),
                              );
                              setPage(state => state - 1);
                            }
                          }}
                          className="btnu"
                          type="button"
                        >
                          <img src={prev} alt="prev" />
                        </button>
                      </li>
                      {pageNumbers?.map(number => (
                        <li
                          key={number}
                          className={`${
                            number === page ? 'text-success' : ''
                          } page-item`}
                        >
                          <button
                            onClick={() => {
                              dispatch(
                                getUnitById(
                                  orgUnitInstanceId,
                                  (number - 1) * 5,
                                ),
                              );
                              setPage(number);
                            }}
                            className="btnu"
                            type="button"
                          >
                            {number}
                          </button>
                        </li>
                      ))}
                      <li key="2" className="page-next">
                        <button
                          onClick={() => {
                            if (page !== pageNumbers?.length) {
                              dispatch(
                                getUnitById(orgUnitInstanceId, page * 5),
                              );
                              setPage(state => state + 1);
                            }
                          }}
                          className="btnu"
                          type="button"
                        >
                          <img src={next} alt="next" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </CustomBox>
            <CustomModal isVisible={isDeleteModalVisible}>
              <div className="modal-container-top">
                <div className="close-btn">
                  <button
                    onClick={() => {
                      setDeleteModalVisible(false);
                    }}
                    type="button"
                    className="closeModalResBtn"
                  >
                    <img src={closeModal} alt="close" />
                  </button>
                </div>
                <CustomBox className="delete-modal">
                  <div className="delete-student-modal">
                    <div className="delete-student-modal-header">
                      <div className="modal-header-left">
                        <div>
                          <img src={deleteModal} alt="close" />
                        </div>
                        <div className="mx-2">
                          <span className="delete-student-modal-title">
                            Delete {dpName}
                          </span>
                          <p className="confirm">
                            Are you sure you want to delete this Student ?
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            setDeleteModalVisible(false);
                          }}
                          type="button"
                          className="closeModalBtn"
                        >
                          <img src={closeModal} alt="close" />
                        </button>
                      </div>
                    </div>
                    <div className="footer-delete-break" />
                    <div
                      className="delete-student-buttons"
                      style={{padding: ' 24px'}}
                    >
                      <CustomCancel
                        className="cancel"
                        bgColor="#fff"
                        onClick={() => {
                          setDeleteModalVisible(false);
                        }}
                      >
                        <span className="cancel-button-text">Cancel</span>
                      </CustomCancel>
                      <CustomButton
                        text="Delete"
                        isDanger
                        onClick={() => {
                          dispatch(
                            deleteStudentFromUnit(
                              orgUnitInstanceId,
                              unitInstanceStudentId,
                              () => {
                                showToastDanger(
                                  `${dpName} Has been Removed successfully`,
                                );
                                setTimeout(() => showToastDanger(''), 5000);
                              },
                            ),
                          );

                          setDeleteModalVisible(false);
                        }}
                      />
                    </div>
                  </div>
                </CustomBox>
              </div>
            </CustomModal>
          </div>
        ) : null}
        <CustomModal isVisible={isUnitDeleteModalVisible}>
          <div className="delete-unit-modal-container-top">
            <div className="close-btn">
              <button
                onClick={() => {
                  setUnitDeleteModalVisible(false);
                }}
                type="button"
                className="closeModalResBtn"
              >
                <img src={closeModal} alt="close" />
              </button>
            </div>
            <CustomBox className="modal-container">
              <div className="delete-department-modal">
                <div className="delete-department-modal-header">
                  <div className="modal-header-left">
                    <div>
                      <img src={deleteModal} alt="close" />
                    </div>
                    <div className="mx-2">
                      <span className="delete-department-modal-title">
                        Delete {unitName} ?
                      </span>
                      <p>Are you sure you want to delete this unit ?</p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setUnitDeleteModalVisible(false);
                      }}
                      type="button"
                      className="closeModalBtn"
                    >
                      <img src={closeModal} alt="close" />
                    </button>
                  </div>
                </div>
                <div className="footer-delete-break" />
                <div
                  className="delete-department-buttons"
                  style={{padding: ' 24px'}}
                >
                  <CustomCancel
                    className="cancel"
                    bgColor="#fff"
                    onClick={() => {
                      setUnitDeleteModalVisible(false);
                    }}
                  >
                    <span className="cancel-button-text">Cancel</span>
                  </CustomCancel>
                  <CustomButton
                    text="Delete"
                    isDanger
                    onClick={() => {
                      dispatch(
                        deleteUnitById(unitInstanceId, () => {
                          setUnitDeleteModalVisible(false);
                          dispatch(
                            setDeletetUnitToastSuccess(
                              `${unitName} Has been deleted `,
                            ),
                          );
                          setTimeout(
                            () => dispatch(setDeletetUnitToastSuccess('')),
                            5000,
                          );
                          history.replace('/admin/units');
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            </CustomBox>
          </div>
        </CustomModal>
      </StudentTableContainer>

      {isToastDanger ? (
        <ToastContainer
          text={isToastDanger}
          isDanger
          onClose={() => showToastDanger('')}
        />
      ) : null}
      {viewUnitToastSuccess || isToastSuccess ? (
        <ToastContainer
          icon={toastSuccess}
          text={viewUnitToastSuccess || isToastSuccess}
          isSuccess
          onClose={() => {
            showToastSuccess('');
            dispatch(setViewUnitToastSuccess(''));
          }}
        />
      ) : null}
    </>
  );
};

export default EachUnit;
