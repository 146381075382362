import styled from 'styled-components';

export const SearchOrAddAssessmentContainer = styled.div`
  margin-left: -0.937rem;
  margin-right: -0.937rem;
  background: #f4f5f8;
  height: 100vh;
  overflow: hidden;

  .main-body {
    -ms-overflow-style: none;
    height: calc(100vh);
    overflow-y: auto;
    padding: 5.06rem 0 2rem 0;
    scrollbar-width: none;
    width: 100%;

    ::-webkit-scrollbar {
      display: none;
    }
  }
  .dropdown-wrapper {
    display: none !important;
  }

  .assessment-nav-left {
    display: flex;
    flex-direction: row;
    .assessment-nav-right-hide {
      display: none;
    }
  }
  .assessment-nav-right {
    display: flex;
    flex-direction: row;
    float: right;
    .save-container {
      .saved-assessment {
        position: unset;
      }
    }
    .save-seperator {
      margin: 0.687rem 0.937rem 0 1rem;
    }
  }
  .navbar {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-device-width: 20rem) {
    .assessment-footer-text {
      padding: 6% !important;
    }
  }

  @media (max-width: 43.75rem) {
    & {
      max-width: 43.75rem;
    }
    .navbar {
      height: auto;
      .assessment-footer-text {
        padding: 2.187rem;
      }
      .btn {
        padding: 1.25rem 0rem 0.812rem 0.437rem !important;
      }
      .assessment-heading {
        margin-left: 0.75rem;
        line-height: 2.375rem;
        padding-bottom: 1rem;
      }
      .assessment-nav-left {
        flex-direction: column;
        width: 100%;
        .assessment-nav-left-content {
          display: flex;
          justify-content: space-between;
          .assessment-nav-right-hide {
            display: flex;
            flex-direction: row;
            .dropdown-wrapper {
              display: block;
              margin-top: 0.187rem;
              .btn {
                margin-right: 0.625rem;
              }
            }
            .save-container {
              margin-top: 0.5rem;
              .saved-assessment {
                position: unset;
              }
            }
            .save-seperator {
              margin: 1.812rem 0.937rem 0 1rem;
            }
          }
        }
      }

      .assessment-nav-right {
        display: none;
      }
    }

    .dropdown-wrapper {
      display: none;
    }
    .assess-detail-section {
      display: flex;
      flex-direction: column;
      margin-top: 2.12rem;
      .assessment-details {
        max-width: unset;
      }
      .assessment-content {
        max-width: unset;
        padding-left: 0.937rem;
        padding-right: 0.937rem;
        margin-top: -1.25rem;
        .assess-addtask-heading {
          margin-bottom: 0.3125rem !important;
        }
        .instruction-types {
          display: flex;
          flex-flow: wrap;
          .instruction-type {
            margin-top: 1rem;
          }
        }
      }
    }
  }
  @media (max-width: 48rem) {
    .instruction-types {
      display: flex;
      flex-flow: wrap;
      .instruction-type {
        margin-top: 1rem;
      }
    }
  }
  .assessment-footer {
    position: fixed;
    bottom: 0;
    margin-left: 0rem;
    width: 100%;
  }
  .assessment-search-content {
    padding-top: 1.37rem;
  }
  .assessment-search-inputDiv {
    padding: 1.5rem;
    margin-top: 1rem;
    background: #ffffff;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
  }
  .assessment-search-list {
    background: #ffffff;
    border: 0.0625rem solid #dedede;
    box-sizing: border-box;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    max-height: 350px;
    overflow-y: auto;
  }
  .add-assessment-button {
    margin-top: -0.125rem;
    height: 3.5rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
  }
`;
