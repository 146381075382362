import styled from 'styled-components';

export const AssessmentSummaryContainer = styled.div`
  width: 78%;
  margin: 2rem auto;
  @media (max-width: 43.75rem) {
    & {
      width: 90%;
      margin: 1.875rem auto;
    }
  }
  @media (max-width: 31.25rem) {
    & {
      width: 90%;
      margin: 5.625rem auto;
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    .header {
      font-size: 1.5rem;
      color: #3d4457;
    }
  }
  .action-btn {
    background: transparent;
    background-color: #ffffff;
    padding: 0.5rem;
    border: 0.0625rem solid #dedede;
    border-radius: 0.312rem;
    outline: none;
  }
`;

export const GeneralContainer = styled.div`
  margin: 2rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
    .content-section {
      .first-div,
      .third-div,
      .fourth-div {
        display: flex;
        flex-direction: column;
        width: 100%;
        .content {
          width: 100%;
        }
      }
      .first-content,
      .third-content,
      .fourth-content {
        width: 100%;
      }
    }
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .general-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .first-div,
    .third-div,
    .fourth-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content,
    .third-content,
    .fourth-content {
      width: 50%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }
    .content {
      font-weight: 600;
      font-size: 0.875rem;
      margin-left: 1.687rem;
      text-align: justify;
      color: #3d4457;
      margin-top: 0.125rem;
    }
  }
`;

export const AssessmentContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .assessment-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .first-div,
    .third-div,
    .second-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content,
    .third-content,
    .second-div {
      width: 100%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }
    .content {
      font-weight: 600;
      font-size: 0.875rem;
      margin-left: 1.687rem;
      text-align: justify;
      color: #3d4457;
      margin-top: 0.125rem;
    }
  }
`;
export const TasksContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .task-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .second-div {
      margin-top: 0.562rem;
    }
    .first-div,
    .second-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content,
    .second-div {
      width: 100%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }
    .content {
      font-weight: 500;
      font-size: 0.875rem;
      margin-left: 1.687rem;
      text-align: justify;
      color: #3d4457;
      text-decoration: underline;
      margin-top: 0.5rem;
      margin-bottom: unset;
    }
    .sub-content {
      font-weight: normal;
      font-size: 0.875rem;
      text-align: justify;
      color: #3d4457;
      margin-left: 1.687rem;
      margin-top: 0.5rem;
    }
  }
`;

export const StudentsContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .user-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .first-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
      border-bottom: 0.0625rem solid #e0e0e0;
    }
    .between-div {
      margin-top: 1.25rem;
      border-bottom: 0.0625rem solid #e0e0e0;
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .last-div {
      margin-top: 1.25rem;
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content {
      width: 100%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }
  }
`;
export const EvaluationContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .evaluation-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .second-div {
      margin-top: 0.562rem;
    }
    .first-div,
    .second-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content,
    .second-div {
      width: 100%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }

    .content {
      font-weight: 600;
      font-size: 0.875rem;
      margin-left: 1.687rem;
      text-align: justify;
      color: #3d4457;
      margin-top: 0.125rem;
    }
  }
`;
