import styled from 'styled-components';

export const StudentsContainer = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  background: #ffffff;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  @media (max-width: 31.25rem) {
  }
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    padding: 1.25rem;
    .user-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
    .image {
      display: flex;
      align-items: center;
      margin-right: 0.937rem;
    }
    .header {
      font-size: 1.125rem;
      font-weight: 500;
      color: #3d4457;
      margin: unset;
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #e7e7e7;
    width: 100%;
  }
  .content-section {
    padding: 1.25rem 1.25rem 0rem 1.25rem;
    .first-div {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
      border-bottom: 0.0625rem solid #e0e0e0;
    }
    .between-div {
      margin-top: 1.25rem;
      border-bottom: 0.0625rem solid #e0e0e0;
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .last-div {
      margin-top: 1.25rem;
      display: flex;
      align-items: center;
      width: 100%;
      align-items: flex-start;
    }
    .first-content {
      width: 100%;
    }
    .title {
      font-size: 0.875rem;
      color: #aaaaaa;
      margin: unset;
    }
    .title-div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      span {
        margin-right: 1.187rem;
        color: #3d4457;
        font-weight: normal;
      }
    }
  }
`;
