import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {AddAssessmentContainer, AssessmentFormContainer} from './elements';
import {
  AddAssessmentHeader,
  AddAssessmentBody,
  AddAssessmentFooter,
} from './components';
import {
  getAssessmentDetails,
  gettingAllAssessmentsDetails,
  resetAction,
  onAssessmentPublish,
  fetchDraftTask,
  onDeletingAssessmentTask,
  getPerformanceCriteria,
  updateAssessmentDetails,
  fetchAssessmentCondition,
  fetchKnowledgeEvidence,
  fetchPerformanceEvidence,
} from '../Assesments/Assessment/slices/assessmentSlice';
import {streamSelector} from '../../../../redux/streamSlice';
import {Spacer} from '../../../../components/common';
import {publishActivityItems} from '../../../../api/assessment';

export const AddAssessment = () => {
  const [currentTab, setCurrentTab] = useState('details');
  const [isLoading, setIsLoading] = useState(false);
  const [openAddTask, setOpenAddTask] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [addedTasks, setAddedTasks] = useState([]);
  const [removedTasks, setRemovedTasks] = useState([]);
  const streamState = useSelector(streamSelector);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    errors,
    setError,
    clearErrors,
    watch,
  } = useForm();
  const history = useHistory();
  const {pathname} = history.location;
  const dispatch = useDispatch();
  const assessmentState = useSelector(state => state.assessment);
  const {createdAssessment, createdTasks, selectedStudents, activityId} =
    assessmentState;

  const isUpdate =
    !createdAssessment?.ac_activity_description?.length ||
    !createdAssessment?.ac_activity_name?.length ||
    !createdAssessment?.ac_due_date?.length ||
    !(
      createdAssessment?.ac_maximum_duration_hours ||
      createdAssessment?.ac_maximum_duration_minutes
    ) ||
    !createdAssessment?.ac_location?.length ||
    !selectedStudents?.length;

  const [selectStudentError, setSelectStudentError] = useState(false);
  const [unitId, setUnitId] = useState('');
  const [isUpdatingStudents, setIsUpdatingStudents] = useState(false);
  const [assessType, setAssessType] = useState({
    value: createdAssessment?.ac_id_activitymethod,
    label: createdAssessment?.am_activitymethod,
  });
  const isPublished = createdAssessment?.aoui_status === 2;
  const [maximumDurationHoursError, setMaximumDurationHoursError] =
    useState(false);
  const [maximumDurationMinutesError, setMaximumDurationMinutesError] =
    useState(false);

  const [maximumDurationHours, setMaximumDurationHours] = useState(0);
  const [maximumDurationMinutes, setMaximumDurationMinutes] = useState(0);
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const {id} = useParams();
  const classId = pathname.split('/')[2];
  const onSubmit = () => {
    // Publishing assessment
    if (createdAssessment?.aoui_status === 2) {
      setIsUpdating(true);

      if (addedTasks.length) {
        publishActivityItems(addedTasks);
      }

      if (removedTasks.length) {
        dispatch(onDeletingAssessmentTask(removedTasks));
      }
    } else {
      dispatch(onAssessmentPublish(assessmentState.activityId, classId));
    }

    history.push(`${pathname.split('/').slice(0, 3).join('/')}/works`);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getAssessmentDetails(id, classId, null, () => {
        setIsLoading(false);
      }),
    );

    // Reseting the state
    return () => {
      dispatch(resetAction());
      dispatch(gettingAllAssessmentsDetails(pathname.split('/')[2]));
    };
  }, [classId, dispatch, id, pathname]);

  useEffect(() => {
    if (assessmentState.activityId)
      dispatch(fetchDraftTask(assessmentState.activityId));
  }, [assessmentState.activityId, dispatch]);

  useEffect(() => {
    dispatch(getPerformanceCriteria(pathname.split('/')[2]));
    dispatch(fetchPerformanceEvidence(pathname.split('/')[2]));
    dispatch(fetchKnowledgeEvidence(pathname.split('/')[2]));
    dispatch(fetchAssessmentCondition(pathname.split('/')[2]));
  }, [dispatch, pathname]);

  const totalStudentsCount = assessmentState.filteredAssessmentStudents.length;

  const formOnSubmit = () => {
    if (createdTasks.length && selectedStudents?.length) {
      return handleSubmit(onSubmit);
    }
    return event => event.preventDefault();
  };

  const isTabSwitch = useCallback(() => {
    if (!getValues('assessTitle')) {
      return false;
    }
    if (!getValues('description')) {
      return false;
    }
    if (!getValues('location')) {
      return false;
    }
    if (!getValues('dueDate')) {
      return false;
    }
    if (!selectedStudents?.length) {
      return false;
    }

    if (
      Number(maximumDurationHours) === 0 &&
      Number(maximumDurationMinutes) === 0
    ) {
      return false;
    }

    return true;
  }, [
    getValues,
    maximumDurationHours,
    maximumDurationMinutes,
    selectedStudents?.length,
  ]);

  const tabSwitchOnClick = useCallback(() => {
    if (!getValues('assessTitle')) {
      setError('assessTitle', {
        message: 'Please Enter Title',
      });
    }
    if (!getValues('description')) {
      setError('description', {
        message: 'Please Enter Description',
      });
    }

    if (!getValues('location')) {
      setError('location', {
        message: 'Please Enter Location',
      });
    }
    if (!getValues('dueDate')) {
      setError('dueDate', {
        message: 'Please Select Due Date',
      });
    }

    if (
      Number(maximumDurationMinutes) === 0 &&
      Number(maximumDurationHours) === 0
    ) {
      setMaximumDurationMinutesError(true);
      setMaximumDurationHoursError(true);
    }

    if (!selectedStudents?.length) {
      setSelectStudentError(true);
    }

    if (
      !Object.keys(errors).length &&
      selectedStudents?.length &&
      !maximumDurationHoursError &&
      !maximumDurationMinutesError &&
      (Number(maximumDurationMinutes) !== 0 ||
        Number(maximumDurationHours) !== 0)
    ) {
      clearErrors();
      setMaximumDurationHoursError(false);
      setMaximumDurationMinutesError(false);
      setSelectStudentError(false);
      setCurrentTab('tasks');
    }
  }, [
    clearErrors,
    errors,
    getValues,
    maximumDurationHours,
    maximumDurationHoursError,
    maximumDurationMinutes,
    maximumDurationMinutesError,
    selectedStudents?.length,
    setError,
  ]);

  const setFieldsError = useCallback(
    (fieldName, errorMessage) => {
      if (!getValues(fieldName)) {
        setError(fieldName, {
          message: errorMessage,
        });
      } else {
        clearErrors(fieldName);
      }
    },
    [clearErrors, getValues, setError],
  );
  useEffect(() => {
    setUnitId(pathname.split('/')[2]);
  }, [pathname]);
  const onSaveBasicDetails = useCallback(
    // eslint-disable-next-line consistent-return
    event => {
      const {value} = event || {};
      const hours = createdAssessment?.ac_maximum_duration_hours;
      const minutes = createdAssessment?.ac_maximum_duration_minutes;
      setUnitId(pathname.split('/')[2]);

      const data = {
        type: value || assessType.value,
        title: getValues('assessTitle'),
        description: getValues('description'),
        dueDate: moment(getValues('dueDate')).format('YYYY-MM-DD'),
        unitId: unitId || pathname.split('/')[2],
        maximumDurationHours:
          maximumDurationHours?.toString() ||
          (hours || hours === 0 ? hours.toString() : ''),
        maximumDurationMinutes:
          maximumDurationMinutes?.toString() ||
          (minutes || minutes === 0 ? minutes.toString() : ''),
        resourceRequired: getValues('resourcerequired'),
        location: getValues('location'),
        studentInstruction: getValues('student-instructions'),
        additionalInformation: getValues('additional-information'),
      };

      if (!isPublished) {
        tabSwitchOnClick();
        setIsSaveClicked(true);
      }
      if (isPublished) {
        if (!data.title) {
          return setFieldsError('assessTitle', 'Please Enter Title');
        }
        clearErrors('assessTitle');

        if (!data.description) {
          return setFieldsError('description', 'Please Enter Description');
        }
        clearErrors('description');

        if (!data.dueDate) {
          return setFieldsError('dueDate', 'Please Select Due Date');
        }
        clearErrors('dueDate');

        if (!data.location) {
          return setFieldsError('location', 'Please Enter Location');
        }
        clearErrors('location');
      }

      const {
        title,
        description,
        dueDate,
        maximumDurationHours: maximumHours,
        maximumDurationMinutes: maximumMinutes,
        location,
      } = data;
      if (
        title?.trim() !== '' &&
        description?.trim() !== '' &&
        dueDate?.trim() !== '' &&
        maximumHours?.trim() !== '' &&
        maximumMinutes?.trim() !== '' &&
        location?.trim() !== '' &&
        selectedStudents.length
      ) {
        dispatch(
          updateAssessmentDetails(activityId, data, () => {
            setCurrentTab('tasks');
          }),
        );
      }
    },

    [
      activityId,
      assessType.value,
      clearErrors,
      createdAssessment?.ac_maximum_duration_hours,
      createdAssessment?.ac_maximum_duration_minutes,
      dispatch,
      getValues,
      isPublished,
      maximumDurationHours,
      maximumDurationMinutes,
      pathname,
      selectedStudents.length,
      setFieldsError,
      tabSwitchOnClick,
      unitId,
    ],
  );

  useEffect(() => {
    if (isUpdating) {
      setIsUpdatingStudents(true);
      setIsUpdating(false);
      onSaveBasicDetails();
    }
  }, [isUpdating, onSaveBasicDetails]);

  return (
    <>
      <Helmet>
        <title>
          {createdAssessment.ac_activity_name || 'Assessment'} | JungleCat
        </title>
      </Helmet>
      <AddAssessmentContainer>
        <AddAssessmentHeader
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          assessmentName={createdAssessment.ac_activity_name}
          isPublished={isPublished}
          assessmentId={createdAssessment.aoui_id_activityorgunitinstance}
          tabSwitchOnClick={onSaveBasicDetails}
          isTabSwitch={isTabSwitch}
          isArchive={streamState?.classDetails?.orui_is_archived}
          isSaveClicked={isSaveClicked}
        />
        <Spacer height="8rem" />
        <AssessmentFormContainer method="POST" onSubmit={formOnSubmit()}>
          <AddAssessmentBody
            currentTab={currentTab}
            isUpdating={isUpdating}
            isLoading={isLoading}
            register={register}
            getValues={getValues}
            setValue={setValue}
            closeAddTask={() => setOpenAddTask(false)}
            isPublished={isPublished}
            addTask={task =>
              setAddedTasks(previousValue => [...previousValue, task])
            }
            control={control}
            errors={errors}
            stopUpdating={() => setIsUpdating(false)}
            removeTask={task =>
              setRemovedTasks(previousValue => [...previousValue, task])
            }
            openAddTask={openAddTask}
            setOpenAddTask={setOpenAddTask}
            setError={setError}
            clearErrors={clearErrors}
            watch={watch}
            setSelectStudentError={setSelectStudentError}
            selectStudentError={selectStudentError}
            maximumDurationHoursError={maximumDurationHoursError}
            maximumDurationMinutesError={maximumDurationMinutesError}
            setMaximumDurationHoursError={setMaximumDurationHoursError}
            setMaximumDurationMinutesError={setMaximumDurationMinutesError}
            maximumDurationHours={maximumDurationHours}
            setMaximumDurationHours={setMaximumDurationHours}
            maximumDurationMinutes={maximumDurationMinutes}
            setMaximumDurationMinutes={setMaximumDurationMinutes}
            setAssessType={setAssessType}
            assessType={assessType}
            unitId={unitId}
            setIsUpdatingStudents={setIsUpdatingStudents}
            isUpdatingStudents={isUpdatingStudents}
            isArchive={streamState?.classDetails?.orui_is_archived}
          />
          <Spacer height="6rem" />
          <AddAssessmentFooter
            totalStudentsCount={totalStudentsCount}
            selectedStudents={selectedStudents}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onButtonClick={formOnSubmit}
            continueButtonOnClick={onSaveBasicDetails}
            isArchive={streamState?.classDetails?.orui_is_archived}
            isTabSwitch={isTabSwitch}
            isSaveClicked={isSaveClicked}
            isUpdate={isUpdate}
          />
        </AssessmentFormContainer>
      </AddAssessmentContainer>
    </>
  );
};
