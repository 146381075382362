import styled from 'styled-components';

export const StudentAssessmentEvaluationContainer = styled.div`
  background-color: ${({evaluationStatusColor}) => evaluationStatusColor};
  border-radius: 0.25rem;

  .criteria-text {
    color: ${({theme}) => theme.colors.text};
    font-size: 0.875rem;
    line-height: 1.37rem;
    text-align: left;
  }

  .criteria-id {
    font-weight: 600;
  }
`;
