import React, {useContext, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import LiveSessionBadge from '../elements/LiveSessionBadge';
import cameraRecordIcon from '../../../../../assets/icons/camera-record.svg';
import timerIcon from '../../../../../assets/icons/timer.svg';
import Avatar from './Avatar';
import LiveSessionBar from '../elements/LiveSessionBar';
import {LiveSessionContext} from '../contexts/LiveSessionContext';

const LiveSessionNavbar = ({
  title,
  subtitle,
  startTime,
  finishTime,
  currentProfile,
}) => {
  const [duration, setDuration] = useState();
  const currDateStr = moment().format('YYYY-MM-DD');
  const startTimeStr = startTime
    ? moment(`${currDateStr} ${startTime}`).format('LT')
    : '';
  const finishTimeStr = finishTime
    ? moment(`${currDateStr} ${finishTime}`).format('LT')
    : '';
  const {isHost} = useContext(LiveSessionContext);

  /**
   * Calculate and set the duration between the session's starting time and the
   * time now.
   *
   * @param {string} from - Starting time of the session.
   */
  const updateDuration = from => {
    const now = moment();
    const start = moment(from, 'hh:mm:ss');
    const hours = now.diff(start, 'hours').toString().padStart(2, '0');
    let minutes = now.diff(start, 'minutes') % 60;
    if (minutes < 10) {
      minutes = minutes.toString().padStart(2, '0');
    }
    setDuration(`${hours}:${minutes}`);
  };

  useEffect(() => {
    let timer;
    if (startTime) {
      updateDuration(startTime);
      timer = setInterval(() => {
        updateDuration(startTime);
      }, 1000);
    }

    return () => (timer ? clearInterval(timer) : null);
  }, [startTime]);

  return (
    <LiveSessionBar className="fixed-top">
      <div>
        <h4 className="m-0">{title}</h4>
        <p className="m-0 text-secondary">
          {subtitle}{' '}
          {startTime && finishTime ? `${startTimeStr} to ${finishTimeStr}` : ''}
        </p>
      </div>
      {isHost && (
        <Button
          variant="outline-secondary"
          className="d-flex align-items-center"
        >
          <img alt="record video" src={cameraRecordIcon} />
          <span className="ml-2">Record Session</span>
        </Button>
      )}
      <div className="d-flex">
        <LiveSessionBadge className="mr-3">
          <img alt="timer" src={timerIcon} />
          <span className="ml-2">{duration}</span>
        </LiveSessionBadge>
        {currentProfile?.up_avatar ? (
          <Avatar srcImage={currentProfile.up_avatar} />
        ) : (
          <div>
            <span
              className="align-items-center circle d-flex justify-content-center student"
              key={String(currentProfile?.up_id_userprofile)}
            >
              {currentProfile?.up_name_first[0]}
              {currentProfile?.up_name_last[0]}
            </span>
          </div>
        )}
      </div>
    </LiveSessionBar>
  );
};

LiveSessionNavbar.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  startTime: PropTypes.string,
  finishTime: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  currentProfile: PropTypes.object,
};

LiveSessionNavbar.defaultProps = {
  title: '',
  subtitle: '',
  startTime: undefined,
  finishTime: undefined,
  currentProfile: null,
};

export default LiveSessionNavbar;
