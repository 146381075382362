/* eslint-disable react/prop-types */
import React from 'react';

import {FooterContainerDiv} from './elements';
import {Button} from '../../../../../../../../components/common';

// const DELAY = 500;

export const Footer = ({
  onSubmit,
  onButtonCancel,
  // upload,
  // documentTitle,
  isDisabled,
  isAdminLibraryLoading,
}) => (
  <FooterContainerDiv className="footer-container fixed-bottom">
    <div className="footer">
      <div />
      <div className="button-container">
        <Button
          className="button cancel-button mr-3"
          isFullWidth={false}
          onClick={onButtonCancel}
          label="Cancel"
          id="cancel"
        />
        <Button
          className="button"
          isFullWidth={false}
          isLoading={isAdminLibraryLoading}
          label="Add Document"
          onClick={onSubmit}
          isDisabled={isDisabled}
          // isDisabled={upload || documentTitle}
          id="add-document"
        />
      </div>
    </div>
  </FooterContainerDiv>
);
