import {createSlice} from '@reduxjs/toolkit';

import * as authApi from '../api/auth';

const authSlice = createSlice({
  initialState: {
    error: null,
    isLoading: false,
    isJoinLoading: false,
    organisation: null,
    joinOrganisation: null,
    userOrganisation: null,
    onlineStudents: [],
    orgPreferences: [],
    user: null,
  },
  name: 'auth',
  reducers: {
    clearUser(state) {
      state.user = null;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsJoinLoading(state, action) {
      state.isJoinLoading = action.payload;
    },
    setJoinOrganisation(state, action) {
      state.joinOrganisation = action.payload;
    },
    setOrganisation(state, action) {
      state.organisation = action.payload;
    },
    setUserOrganisation(state, action) {
      state.userOrganisation = action.payload;
    },
    setOrgPreferences(state, action) {
      state.orgPreferences = action.payload;
    },
    setOnlineStudents(state, action) {
      state.onlineStudents = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const {
  clearUser,
  setError,
  setIsLoading,
  setIsJoinLoading,
  setOrganisation,
  setJoinOrganisation,
  setUserOrganisation,
  setOrgPreferences,
  setUser,
  setOnlineStudents,
} = authSlice.actions;

export const fetchOrganisationByRtoCode =
  (rtoCode, callback) => async dispatch => {
    try {
      if (rtoCode) {
        const response = await authApi.fetchOrganisationByRtoCode(rtoCode);
        dispatch(setOrganisation(response.data?.organisation || null));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(setOrganisation(null));
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const logIn = (data, callback) => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const response = await authApi.logIn({
      us_email: data.email,
      us_password: data.password,
      remember: data.remember,
    });
    const {token, user} = response.data;
    const [userProfile] = user.userProfiles;
    dispatch(setUserOrganisation(user?.organisation));
    dispatch(setOrgPreferences(user?.organisation?.preferences));

    dispatch(setUser(user));

    if (userProfile?.up_id_user && userProfile?.up_id_typeuserprofile) {
      localStorage.setItem('jc-user-type', userProfile.up_id_typeuserprofile);
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('userId', userProfile.up_id_user);

      if (callback) {
        callback(userProfile);
      }
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const logInAfterVerification =
  (user, token, callback) => async dispatch => {
    dispatch(setIsLoading(true));

    try {
      const [userProfile] = user.userProfiles;
      dispatch(setUser(user));

      if (userProfile?.up_id_user && userProfile?.up_id_typeuserprofile) {
        localStorage.setItem('jc-user-type', userProfile.up_id_typeuserprofile);
        localStorage.setItem('jwtToken', token);
        localStorage.setItem('userId', userProfile.up_id_user);

        if (callback) {
          callback(userProfile);
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const register = (data, callback) => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const postData = {
      privacy: data.accept,
      c_password: data.confirmPassword,
      email: data.email,
      full_name: data.name,
      org_name: data.organisationName,
      orgLoca: data.country,
      password: data.password,
      org_type: data.orgType,
    };

    if (data.tGovOrgCode && data.tGovOrgId) {
      postData.tgovOrgCode = data.tGovOrgCode;
      postData.tgovOrgId = data.tGovOrgId;
    }

    await authApi.register(postData);

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const resetPassword = (data, callback) => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    await authApi.resetPassword({
      us_email: data.email,
    });

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const logout = callBack => dispatch => {
  dispatch(clearUser());
  localStorage.removeItem('jc-user-type');
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('userId');
  if (callBack) {
    callBack();
  }
};

export const fetchProfile = callback => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await authApi.fetchProfile();

    const responseOrganisation = await authApi.fetchOrganisation();

    const {user} = response.data;
    const [userProfile] = user.userProfiles;
    dispatch(setUser(user));
    dispatch(setUserOrganisation(responseOrganisation?.data?.organisation));
    dispatch(
      setOrgPreferences(responseOrganisation?.data?.organisation?.preferences),
    );
    let currentProfile;
    if (response.data.user.userProfiles.length > 1) {
      currentProfile = response.data.user.userProfiles.find(
        item => item.up_id_typeuserprofile === 4,
      );
    } else {
      [currentProfile] = response.data.user.userProfiles;
    }
    if (userProfile?.up_id_user && userProfile?.up_id_typeuserprofile) {
      if (!localStorage.getItem('jc-user-type')) {
        localStorage.setItem(
          'jc-user-type',
          currentProfile.up_id_typeuserprofile,
        );
      }
      if (!localStorage.getItem('userId')) {
        localStorage.setItem('userId', userProfile.up_id_user);
      }
    }
  } catch (error) {
    dispatch(logout(callback));

    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const joinOrganisationAdminByRtoCode =
  (rtoCode, callback) => async dispatch => {
    try {
      if (rtoCode) {
        const response = await authApi.joinOrganisationAdminByRtoCode(rtoCode);
        dispatch(setJoinOrganisation(response.data?.organisation || null));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(setJoinOrganisation(null));
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const joinFreeAdmin = (data, callback) => async dispatch => {
  dispatch(setIsJoinLoading(true));

  try {
    const postData = {
      privacy: data.accept,
      email: data.email,
      name: data.name,
      org_name: data.organisationName,
      password: data.password,
      OrgCode: data.OrgCode,
      OrgId: data.OrgId,
    };

    await authApi.joinFreeAdmin(postData);

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsJoinLoading(false));
  }
};

export const superAdminLogIn = (data, callback) => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const response = await authApi.superAdminLogIn({
      us_email: data.email,
      sup_ad_password: data.password,
      sup_ad_profile_name: data.username,
    });
    const {token, user} = response.data;
    const [userProfile] = user.userProfiles;
    dispatch(setUserOrganisation(user?.organisation));
    dispatch(setOrgPreferences(user?.organisation?.preferences));

    dispatch(setUser(user));

    if (userProfile?.up_id_user && userProfile?.up_id_typeuserprofile) {
      localStorage.setItem('jc-user-type', userProfile.up_id_typeuserprofile);
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('userId', userProfile.up_id_user);

      if (callback) {
        callback(userProfile);
      }
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const authSelector = state => state.auth;

export default authSlice.reducer;
