import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const PostCardCont = styled.div`
  @media (max-width: 43.75rem) {
    .divider {
      width: 90%;
      margin: 0rem 0.937rem 0rem 0.937rem;
    }
    .post-body-content {
      overflow-wrap: break-word;
    }
  }
  .post-user-info {
    display: flex;
    align-items: center;
    outline: none;
    background: transparent;
    border: none;
    img {
      height: 2.25rem;
      width: 2.25rem;
      object-fit: unset !important;
    }
  }
  .post-user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .__react_component_tooltip {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 0.312rem;
  }
  .shared-with {
    li {
      margin-top: 0.5rem;
      font-weight: 400;
    }
  }
  .shareperson {
    margin-right: 0.5rem;
  }
  .post-username {
    align-items: center;
    display: flex;
    margin-bottom: 0.187rem;

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.21;
      color: #565656;
      margin-right: 0.25rem;
    }

    img {
      height: 1.125rem;
      width: 1.125rem;
    }
  }
  .post-unit-name {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.21;
    color: #aaaaaa;
  }
  .post-body {
    padding: 1rem;
  }
  .post-body-content {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    overflow-wrap: break-word;
    color: #383838;
  }
  .post-sub-title {
    font-weight: 500;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #bcbcbc;
    margin-bottom: 0.312rem;
  }
  .post-sub-body {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.437rem;
    color: #565656;
  }
`;
