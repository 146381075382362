import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const CoursesContainer = styled(AdminListingPageContainer)`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 5%;
  }

  .course-name {
    width: 25%;
  }

  .qualification-name {
    width: 23%;
  }

  .course-type {
    width: 10%;
  }
  .total-sessions {
    width: 13%;
  }
  .total-hours {
    width: 13%;
  }

  .view {
    width: 11%;
  }
  .more-option {
    width: 3%;
  }
  .view-class {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.312rem;
    padding: 0.75rem 1rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
    .viewclass-arrow {
      margin-left: 0.75rem;
      vertical-align: middle;
    }
  }
  .total-count {
    padding-left: 2rem;
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
`;
