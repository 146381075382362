import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import ToastSuccessIcon from '../../../../../../../../assets/icons/add-academic.svg';
import {Radio, TextInput} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';
import {
  unitsSelector,
  setError,
  updateUnit,
} from '../../../../../../../../redux/unitsSlice';
import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {unitSchema} from '../../../../../../../../utils/validation';
import {EditUnitModalContainer} from './elements';

const FIELDS_IN_ORDER = ['unitType', 'unitCode', 'unitName'];

export const EditUnitModal = ({editDetails, onHide}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      unitCode: '',
      unitName: '',
      unitType: '',
    },
    resolver: yupResolver(unitSchema),
  });
  const {error: serverError, isUnitLoading} = useSelector(unitsSelector);

  useEffect(() => {
    if (editDetails) {
      setValue('unitCode', editDetails.code);
      setValue('unitName', editDetails.name);
      setValue('unitType', editDetails.type);
    }
  }, [editDetails, setValue]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      dispatch(
        updateUnit(editDetails.id, data, () => {
          onHide();
          showToast(ToastSuccessIcon, `${data.unitName} has been updated`);
        }),
      );
    },
    [dispatch, editDetails, onHide],
  );

  const {hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <EditUnitModalContainer
      buttonLabel="Save Changes"
      description="Make changes to the unit details."
      isButtonLoading={isUnitLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit Unit Details"
      width="55.5rem"
    >
      <form
        className="d-flex flex-column pb-4 px-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex">
          <div className="d-flex flex-column unit-type-input-container">
            <span className="mb-2 unit-type-label">Unit Type</span>
            <div className="align-items-center d-flex flex-grow-1">
              <Controller
                control={control}
                name="unitType"
                render={fields => (
                  <Radio
                    {...fields}
                    className="mr-4"
                    currentValue={watch('unitType')}
                    hasError={hasServerError || !!errors.unitType}
                    label="Core"
                    value="core"
                  />
                )}
              />
              <Controller
                control={control}
                name="unitType"
                render={fields => (
                  <Radio
                    {...fields}
                    currentValue={watch('unitType')}
                    hasError={hasServerError || !!errors.unitType}
                    label="Elective"
                    value="elective"
                  />
                )}
              />
            </div>
          </div>
          <Controller
            control={control}
            name="unitCode"
            render={fields => (
              <TextInput
                {...fields}
                className="mr-3"
                errorMessage={errors.unitCode?.message}
                hasError={hasServerError || !!errors.unitCode}
                label="Unit Code"
                minWidth="11.25rem"
                placeholder="Enter the unit code"
                width="21.5%"
              />
            )}
          />
          <Controller
            control={control}
            name="unitName"
            render={fields => (
              <TextInput
                {...fields}
                errorMessage={errors.unitName?.message}
                hasError={hasServerError || !!errors.unitName}
                label="Unit Name"
                placeholder="Enter the unit name"
              />
            )}
          />
        </div>
      </form>
    </EditUnitModalContainer>
  );
};

EditUnitModal.defaultProps = {
  editDetails: null,
};

EditUnitModal.propTypes = {
  editDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  onHide: PropTypes.func.isRequired,
};
