import axios from '../..';

export const fetchAllAdminTeachers = () => axios.get('api/admin/org/allAdmin');

export const updateAdminTeacher = (adminTeacherId, data) =>
  axios.patch(`api/admin/org/editAdmin/${adminTeacherId}`, data);

export const deleteAdminTeacher = adminTeacherId =>
  axios.delete(`api/admin/org/adminDelete/${adminTeacherId}`);

export const resendAdminTeacherInvitation = (adminTeacherId, data) =>
  axios.patch(`api/admin/org/admin/resendInvitation/${adminTeacherId}`, data);

// Add Flow User
export const individualAdminValid = data =>
  axios.post('api/admin/org/admin/adminValidation', data);

export const addAdminManual = data =>
  axios.post('api/admin/org/admin/addAdmin', data);

export const addAdminSpread = (
  data,
  isAdminSpreadsheet,
  isTeacherSpreadsheet,
) =>
  axios.post(
    `api/admin/org/admin/addAdminSpread?isAdminSpreadsheet=${isAdminSpreadsheet}&isTeacherSpreadsheet=${isTeacherSpreadsheet}`,
    data,
  );

export const spreadSheetAdminsValid = data =>
  axios.post('api/admin/org/admin/adminSpreadSheetValidation', data);
