/* eslint-disable react/prop-types */
import {useHistory} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import CustomButton from '../../../../../components/shared/CustomButton';
import SessionFormContainer from '../../../elements/SessionFormContainer';
import * as sessionApi from '../../../../../api/session';
import {scheduleSessionSchema} from '../../../../../utils/validation';
import {DateInput, TextInput} from '../../../../../components/common';
import {TextArea} from '../../../../../components/common/TextArea';

const ScheduleSessionForm = ({instance}) => {
  const history = useHistory();
  const {control, errors, handleSubmit, setError} = useForm({
    defaultValues: {
      title: '',
      date: '',
      startTime: '',
      finishTime: '',
      description: '',
    },
    resolver: yupResolver(scheduleSessionSchema),
  });

  const onSubmit = async data => {
    try {
      const sessionResponse = await sessionApi.createSession({
        ...data,
        instance,
      });
      if (!sessionResponse?.data?.status) {
        throw new Error('Schedule failed');
      }
      history.goBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      if (err?.response?.data?.error?.message) {
        setError('startTime', {
          type: 'focus',
          message: err?.response?.data?.error?.message,
        });
      }
    }
  };

  return (
    <SessionFormContainer>
      <div>
        <h2 className="title">Schedule a Session</h2>
        <p className="subtitle">
          Enter the necessary information to schedule a session with your
          students.
        </p>
        <form className="session-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Controller
              control={control}
              name="date"
              render={fields => (
                <DateInput
                  {...fields}
                  hasError={!!errors.date}
                  errorMessage={errors.date?.message}
                  label="Date"
                  name="date"
                  placeholder="Choose a date"
                  dateFormat="d MMMM yyyy"
                  id="date-id"
                />
              )}
            />
          </div>

          <div className="mb-4">
            <Controller
              control={control}
              name="title"
              render={fields => (
                <TextInput
                  {...fields}
                  autoComplete="off"
                  errorMessage={errors.title?.message}
                  hasError={errors.title?.message}
                  label="Session Title"
                  name="title"
                  placeholder="Enter a title for the video session"
                  id="title"
                />
              )}
            />
          </div>

          <div className="d-flex flex-column mb-4">
            <div className="start-end-time">
              <Controller
                control={control}
                name="startTime"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoComplete="off"
                    errorMessage={errors.startTime?.message}
                    hasError={!!errors.startTime}
                    label="Start Time"
                    type="time"
                    name="startTime"
                    className="mr-4"
                    id="start-time-id"
                  />
                )}
              />

              <Controller
                control={control}
                name="finishTime"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoComplete="off"
                    errorMessage={errors.finishTime?.message}
                    hasError={!!errors.finishTime}
                    label="End Time"
                    type="time"
                    name="finishTime"
                    id="end-time-id"
                  />
                )}
              />
            </div>
          </div>

          <div className="mb-4">
            <Controller
              control={control}
              name="description"
              render={fields => (
                <TextArea
                  {...fields}
                  autoComplete="off"
                  errorMessage={errors.description?.message}
                  hasError={!!errors.description}
                  label="Description"
                  placeholder="Enter a description for the session"
                  name="description"
                  containerClassName="flex-grow-1"
                  id="description"
                  rows="5"
                />
              )}
            />
          </div>
          <CustomButton
            type="submit"
            className="submit-button justify-content-center"
            text="Schedule Session"
            id="submit-btn"
          />
        </form>
      </div>
    </SessionFormContainer>
  );
};

export default ScheduleSessionForm;
