import styled from 'styled-components';

const ModeOfDeliveryFormContainer = styled.div`
  .serial {
    width: 4.3%;
  }

  .unit {
    width: 40.7%;
  }

  .training-methods {
    width: 55%;
  }
`;

export default ModeOfDeliveryFormContainer;
