import styled from 'styled-components';

export const StudentDropdownContainer = styled.div`
  position: relative;
  @media (max-width: 43.75rem) {
    .dropdown-toggler {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .dropdown-toggler {
    background-color: #fff;
    border: 0.0625rem solid #dedede;
    border-radius: 0.312rem;
    color: #515151;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.21;
    padding: 0.5rem 1rem 0.562rem 0.75rem;

    &.is-toggled {
      border-color: ${({theme}) => theme.colors.primary};
      box-shadow: 0 0 0 0.125rem ${({theme}) => theme.colors.lightPrimary}};
      outline: none;

      img {
        transform: rotate(180deg);
      }
    }

    :focus,
    :active {
      outline: none;
    }

    span {
      margin-right: 2.25rem;
    }

    img {
      height: 0.25rem !important;
      width: 0.5rem !important;
    }
  }

  .dropdown {
    background-color: #fff;
    border: 0.0625rem solid #dedede;
    border-radius: 0.312rem;
    padding: 1rem;
    position: absolute;
    right: -0.125rem;
    top: calc(100% + 0.625rem);
    max-height:18.75rem;
    overflow-y:scroll;

    .students-search {
      height: 2.5rem;
      margin-bottom: 0.5rem;
      padding-left: 0.75rem !important;
      width: 22rem;

      img {
        height: 1rem !important;
        margin-right: 0.5rem !important;
        width: 1rem !important;
      }
    }

    
  }
`;
