/* eslint-disable react/prop-types */
import React, {useMemo, useCallback} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  TextInput,
  Checkbox,
} from '../../../../../../../../../../components/common';

import {ManualTableStyles, FormContainer} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import AddIcon from '../../../../../../../../../../assets/icons/addadmin.svg';
import {Table} from '../../../../../../../../../../components/common/Table';
import CloseIcon from '../../../../../../../../../../assets/icons/redcross.svg';
import {useErrorMessage} from '../../../../../../../../../../utils/hooks';
import {adminTeacherSchema} from '../../../../../../../../../../utils/validation';
import ExclaimIcon from '../../../../../../../../../../assets/icons/exclaim.svg';
import {
  individualAdminCheck,
  setError as setServerError,
  adminTeachersSelector,
  clearFormError,
} from '../../../../../../../../../../redux/adminTeacherSlice';
import LineImg from '../../../../../../../../../../assets/icons/userline.svg';
import TickImg from '../../../../../../../../../../assets/icons/usertick.svg';

const FIELDS_IN_ORDER = [
  'referId',
  'firstName',
  'lastName',
  'email',
  'admin',
  'teacher',
];

export const ManualForm = ({
  manual,
  formShow,
  btnShow,
  setFormShow,
  setBtnShow,
  addAdmins,
  setAddAdmins,
}) => {
  const dispatch = useDispatch();
  const {error: serverError, formError} = useSelector(adminTeachersSelector);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  const {
    control,
    formState: {errors},
    setError,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      referId: '',
      firstName: '',
      lastName: '',
      email: '',
      admin: false,
      teacher: false,
    },
    resolver: yupResolver(adminTeacherSchema),
  });
  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const onSubmit = useCallback(
    data => {
      const details = {
        firstName: data.firstName,
        lastName: data.lastName,
        referID: data.referId,
        useremail: data.email,
        is_admin: data.admin ? 'yes' : 'no',
        is_teacher: data.teacher ? 'yes' : 'no',
      };
      dispatch(
        individualAdminCheck(details, validate => {
          if (validate === '') {
            const arraysOfUserEmail = addAdmins.map(admin => admin.useremail);
            const arraysOfRefId = addAdmins.map(admin => admin.referID);
            if (
              !arraysOfUserEmail.includes(data.email) &&
              !arraysOfRefId.includes(data.referId)
            ) {
              setAddAdmins(state => [
                ...state,
                {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  referID: data.referId,
                  useremail: data.email,
                  is_admin: data.admin ? 'yes' : 'no',
                  is_teacher: data.teacher ? 'yes' : 'no',
                },
              ]);

              setFormShow(false);
              setBtnShow(false);
            } else {
              if (arraysOfUserEmail.includes(data.email)) {
                setError('email', {
                  type: 'focus',
                  message: 'This Email Already added in this list',
                });
              }
              if (arraysOfRefId.includes(data.referId)) {
                setError('referId', {
                  type: 'focus',
                  message: 'This Reference id already added in this list',
                });
              }
            }
          }
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addAdmins],
  );

  const removeItems = position => {
    setAddAdmins(state =>
      state.filter((item, index) => index !== position.index),
    );
  };
  // Manual flow mapping
  const manualcolumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'ID',
        accessor: 'referID',
        className: 'ref-id-manual',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        className: 'first-name-manual',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        className: 'last-name-manual',
      },
      {
        Header: 'EMAIL',
        accessor: 'useremail',
        className: 'email-manual',
      },
      {
        Header: 'Admin',
        Cell: ({row: {original}}) => {
          const typeadmin = original?.is_admin;
          return typeadmin?.toLowerCase() === 'yes' ? (
            <img src={TickImg} alt="close" />
          ) : (
            <img src={LineImg} alt="close" />
          );
        },

        accessor: 'is_admin',
        className: 'is-admin',
      },
      {
        Header: 'Teacher',
        Cell: ({row: {original}}) => {
          const typeteacher = original?.is_teacher;
          return typeteacher?.toLowerCase() === 'yes' ? (
            <img src={TickImg} alt="close" />
          ) : (
            <img src={LineImg} alt="close" />
          );
        },

        accessor: 'is_teacher',
        className: 'is-teacher',
      },
      {
        Header: ' ',
        Cell: ({row: position}) => (
          <button
            type="button"
            onClick={() => {
              removeItems(position);
            }}
            className="closeIconBtn"
          >
            <img src={CloseIcon} alt="close" />
          </button>
        ),
        accessor: 'addAdmins',
        className: 'close-row',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Add a serial number to each row and memoize the data.
  const manualdata = useMemo(
    () => [
      ...addAdmins.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [addAdmins],
  );

  return (
    <FormContainer>
      <div>
        {manual && addAdmins?.length ? (
          <ManualTableStyles>
            <Table
              columns={manualcolumns}
              data={manualdata}
              itemName="Users"
              maxWidth="49.37rem"
              isSortedBy
            />
          </ManualTableStyles>
        ) : null}
        {(formShow && btnShow) || !addAdmins?.length ? (
          <div>
            <div
              className="add-student-modal"
              style={{padding: '1.5rem 0rem 0rem 0rem'}}
            >
              <div className="add-student-modal-body">
                <form>
                  <div className="d-flex form-fields">
                    <div className="fields">
                      <Controller
                        control={control}
                        name="referId"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={
                              errors.referId?.message || formError.referId
                            }
                            autoFocus
                            hasError={!!errors.referId || !!formError.referId}
                            label="Reference ID"
                            placeholder="Enter Reference ID"
                            id="referId"
                          />
                        )}
                      />
                    </div>
                    <div className="fields">
                      <Controller
                        control={control}
                        name="firstName"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={errors?.firstName?.message}
                            hasError={!!errors.firstName}
                            label="First Name"
                            placeholder="Enter first name"
                            id="firstName"
                          />
                        )}
                      />
                    </div>
                    <div className="field-last">
                      <Controller
                        control={control}
                        name="lastName"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={errors?.lastName?.message}
                            hasError={!!errors.lastName}
                            label="Last Name"
                            placeholder="Enter last name"
                            id="lastName"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex pb-4">
                    <Controller
                      control={control}
                      name="email"
                      render={fields => (
                        <TextInput
                          {...fields}
                          errorMessage={
                            errors.email?.message || formError.email
                          }
                          hasError={!!errors.email || !!formError.email}
                          label="Email"
                          placeholder="Enter email address"
                          id="emailId"
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="user-type-head">
                      <p className="user-type">User types</p>
                      <p className="exclaim-icon">
                        <img
                          src={ExclaimIcon}
                          alt="exclaim"
                          className="exclaim"
                        />
                      </p>
                      <p className="user-type-instruction">
                        You can select both option for a user.
                      </p>
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name="admin"
                        render={fields => (
                          <Checkbox
                            {...fields}
                            label="Admin"
                            hasError={!!errors.admin}
                          />
                        )}
                      />
                    </div>
                    <div className="mt-2">
                      <Controller
                        control={control}
                        name="teacher"
                        render={fields => (
                          <Checkbox
                            {...fields}
                            label="Teacher"
                            hasError={!!errors.admin}
                          />
                        )}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="button-container"
              style={{padding: '0rem 0rem 1.5rem 0rem'}}
            >
              {addAdmins?.length && formShow ? (
                <CustomCancel
                  id="addUserCancelbtn"
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    reset();
                    setFormShow(false);
                    setBtnShow(false);
                  }}
                >
                  <span className="cancel-button-text">Cancel</span>
                </CustomCancel>
              ) : null}

              <CustomCancel
                className="save-changes"
                id="addUserbtn"
                bgColor="#fff"
                padding="0.562rem 0.875rem"
                onClick={handleSubmit(onSubmit)}
              >
                <img src={AddIcon} alt="icon" style={{marginRight: '0.5rem'}} />
                <span className="add-button-text">
                  {addAdmins.length > 0 ? 'Add to list' : 'Add User'}
                </span>
              </CustomCancel>
            </div>
          </div>
        ) : (
          <div
            className="button-container"
            style={{padding: '1.5rem 0 1.5rem 0rem'}}
          >
            <CustomCancel
              className="save-changes"
              bgColor="#fff"
              id="addAnotherUser"
              padding="0.562rem 0.875rem"
              onClick={() => {
                setFormShow(true);
                setBtnShow(true);
              }}
            >
              <img src={AddIcon} alt="icon" style={{marginRight: '0.5rem'}} />
              <span className="add-button-text">Add another user</span>
            </CustomCancel>
          </div>
        )}
      </div>
    </FormContainer>
  );
};
