import styled from 'styled-components';

import {Color} from '../../../../../constants';

const UnitDetailsContainer = styled.main`
  background-color: #f4f5f8;
  margin: 0 -0.937rem;
  min-height: calc(100vh - 5rem);
  padding: 2rem 11%;

  .back-button {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    margin-bottom: 0.625rem;

    :active,
    :focus {
      outline: none;
    }

    img {
      height: 1.5rem;
      margin-right: 0.25rem;
    }

    span {
      color: #b8bac7;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .unit-title {
    color: ${Color.PRIMARY_TEXT};
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .unit-details {
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;

    .box-column {
      align-items: center;
      display: flex;

      .box-column-title {
        color: ${Color.LIGHT_TEXT};
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0;
      }

      .box-column-bullet {
        color: ${Color.LIGHT_TEXT};
        font-size: 0.875rem;
        margin: 0 0.25rem;
      }

      .box-column-value {
        color: ${Color.PRIMARY_TEXT};
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .box-column-seperator {
      background-color: #b8bac7;
      height: 0.75rem;
      margin: 0 1.5rem;
      width: 0.0625rem;
    }
  }

  .unit-students-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.937rem;

    .unit-students-count {
      color: ${Color.PRIMARY_TEXT};
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    .unit-students-header-right {
      display: flex;

      .add-student-button {
        margin-left: 1rem;
      }
    }
  }

  .unit-students-table {
    tr {
      td button {
        margin-right: 0.125rem;
        opacity: 0;
        transition: opacity 0.1s;
      }

      :hover td button {
        opacity: 1;
      }

      .student-profile-picture {
        height: 2rem;
        margin-right: 0.75rem;
        width: 2rem;
      }
    }
  }

  .add-students-modal-wrapper {
    background-color: #fff;
    border-radius: 0.75rem;
    width: 95%;

    @media (min-width: 60rem) {
      & {
        max-width: 46%;
      }
    }

    .add-students-modal-header {
      background-color: rgba(64, 161, 255, 0.05);
      border-bottom: 0.0625rem solid #d0ddea;
      padding: 1.5rem;

      .add-students-modal-top {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        h3 {
          color: ${Color.PRIMARY_TEXT};
          font-size: 1.25rem;
          font-weight: 500;
          margin-bottom: 0.25rem;
        }

        h4 {
          color: ${Color.LIGHT_TEXT};
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0;
        }

        button {
          background-color: transparent;
          border: none;

          :active,
          :focus {
            outline: none;
          }

          img {
            height: 2rem;
          }
        }
      }
    }

    .add-students-list {
      -ms-overflow-style: none;
      border-bottom: 0.0625rem solid #d0ddea;
      border-top: 0.0625rem solid #d0ddea;
      height: 39vh;
      overflow-y: auto;
      padding: 0.5rem 1.5rem;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      .add-students-list-item {
        align-items: center;
        display: flex;
        padding: 1rem 0;

        :not(:last-child) {
          border-bottom: 0.0625rem solid #dedede;
        }

        .add-students-profile-column {
          align-items: center;
          display: flex;
          width: 53%;

          img {
            height: 2rem;
            margin-right: 0.75rem;
            width: 2rem;
          }

          h5 {
            color: ${Color.PRIMARY_TEXT};
            font-size: 0.875rem;
            font-weight: 400;
            margin-bottom: 0;
          }
        }

        .add-students-id-column {
          color: ${Color.PRIMARY_TEXT};
          flex-grow: 1;
          font-size: 0.875rem;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
    }

    .add-students-footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;

      img {
        height: 1.5rem;
        margin-right: 0.625rem;
      }

      .selected-count {
        color: ${Color.PRIMARY_TEXT};
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
`;

export default UnitDetailsContainer;
