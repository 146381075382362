/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {useMemo, useState} from 'react';
import {Controller} from 'react-hook-form';

import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {Table} from '../../../../../../../../components/common/Table';
import {TextInput} from '../../../../../../../../components/common/TextInput';
import TextModal from '../TextModal';
import DurationAndSchedulingFormContainer from './elements/DurationAndSchedulingFormContainer';

const DurationAndSchedulingForm = ({
  control,
  errors,
  hiddenValues,
  onUpdate,
  volumeOfLearning,
}) => {
  const [isPrModalOpen, setIsPrModalOpen] = useState(false);

  const euTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Cell: ({value}) => (
          <div className="column-table-value mr-4">{value}</div>
        ),
        Header: 'Elective Category',
        accessor: 'electiveCategory',
        className: 'elective-category',
      },
      {
        Cell: ({value}) => <div className="column-table-value">{value}</div>,
        Header: 'Total Number of Units',
        accessor: 'unitCount',
        className: 'unit-count',
      },
    ],
    [],
  );
  const euTableData = useMemo(
    () => [
      {
        serial: 1,
        electiveCategory: 'Category A',
        unitCount: 20,
      },
      {
        serial: 2,
        electiveCategory: 'Category B',
        unitCount: 20,
      },
    ],
    [],
  );

  return (
    <DurationAndSchedulingFormContainer
      className="section"
      id="duration-and-scheduling"
    >
      <h3>Duration and Scheduling</h3>
      <p>
        Analyse the nature of your student cohort. Use the analysis and any
        specific requirements of the training package to determine the amount of
        training for each cohort, the duration of the program and how your RTO
        will schedule training and assessment activities to ensure students are
        able to fully develop the required skills and knowledge prior to being
        assessed.
        <br />
        It may be necessary to indicate variations for some cohorts due to their
        specific learning needs.
      </p>
      <form>
        <div className="tas-form-row">
          <div
            className="is-read-only tas-form-column"
            style={{flexBasis: '15%'}}
          >
            <div className="column-title">
              ANZSCO Code{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value">{hiddenValues.anscoCode}</div>
          </div>
          <div
            className="is-read-only tas-form-column"
            style={{flexBasis: '40%'}}
          >
            <div className="column-title">
              ASCED Qualification/Course Field of Education identifier{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value">{hiddenValues.asedQualification}</div>
          </div>
          <div
            className="flex-grow-0 is-read-only tas-form-column"
            style={{flexBasis: '40%'}}
          >
            <div className="column-title">
              Qualification/Course level of eduction Identifier{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value">{hiddenValues.courseLevel}</div>
          </div>
        </div>

        <div className="tas-form-row">
          <div className="is-full-width is-read-only tas-form-column">
            <div className="column-title">
              Packaging Rules{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues.packagingRules.slice(0, 720)}
              {hiddenValues.packagingRules.length > 720 ? (
                <>
                  ...
                  <button onClick={() => setIsPrModalOpen(true)} type="button">
                    See more
                  </button>
                </>
              ) : (
                ''
              )}
              {!hiddenValues.packagingRules ? 'Not Available' : ''}
            </div>
            <TextModal
              content={hiddenValues.packagingRules}
              isVisible={isPrModalOpen}
              onClose={() => setIsPrModalOpen(false)}
              title="Packaging Rules"
            />
          </div>
        </div>

        {/* <div className="tas-form-row is-read-only"> */}
        {/* <Controller
            control={control}
            name="totalNominalHours"
            render={fields => (
              <TextInput
                {...fields}
                className="flex-grow-0 tas-form-column is-read-only"
                flexBasis="19%"
                hasError={!!errors.totalNominalHours}
                hasInfo="The sum of the nominal hours allocated to each unit for this TAS and comes directly from the NCVER nationally agreed nominal hours.<br/><br/> These nationally agreed hours data have been agreed to by all States and Territories"
                label="Total Nominal Hours"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled
              />
            )}
          /> */}
        {/* <Controller
            control={control}
            name="totalCoreUnits"
            render={fields => (
              <TextInput
                {...fields}
                className="flex-grow-0 tas-form-column is-read-only"
                flexBasis="19%"
                hasError={!!errors.totalCoreUnits}
                hasInfo="The sum of the core units for this TAS that must match the packaging rules for this qualification"
                label="Total Core Units"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled
              />
            )}
          /> */}
        {/* <Controller
            control={control}
            name="totalElectiveUnits"
            render={fields => (
              <TextInput
                {...fields}
                className="flex-grow-0 tas-form-column is-read-only"
                flexBasis="19%"
                hasError={!!errors.totalElectiveUnits}
                hasInfo="The sum of the elective units for this TAS that must match the packaging rules for this qualification"
                label="Total Elective Units"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled
              />
            )}
          /> */}
        {/* </div> */}
        <div className="tas-form-row">
          <div
            className="is-read-only tas-form-column"
            style={{flexBasis: '20%'}}
          >
            <div className="column-title">
              Total Nominal Hours{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The sum of the nominal hours allocated to each unit for this TAS and comes directly from the NCVER nationally agreed nominal hours.<br/><br/> These nationally agreed hours data have been agreed to by all States and Territories"
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues?.totalNominalHours}
            </div>
          </div>
          <div
            className="is-read-only tas-form-column"
            style={{flexBasis: '30%'}}
          >
            <div className="column-title">
              Total Core Units{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The sum of the core units for this TAS that must match the packaging rules for this qualification"
                data-for="global"
              />
            </div>
            <div className="column-value">{hiddenValues.totalCoreUnits}</div>
          </div>
          <div
            className="flex-grow-0 is-read-only tas-form-column"
            style={{flexBasis: '40%'}}
          >
            <div className="column-title">
              Total Elective Units{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The sum of the elective units for this TAS that must match the packaging rules for this qualification"
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues.totalElectiveUnits}
            </div>
          </div>
        </div>
        <div className="tas-form-row">
          <div
            className="flex-grow-0 is-read-only tas-form-column"
            style={{flexBasis: '30%'}}
          >
            <div className="column-title">
              Volume of Learning{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The sum of the delivery hours and non face to face hours"
                data-for="global"
              />
            </div>
            <div className="column-value">
              {volumeOfLearning?.deliveryHours + volumeOfLearning?.nonFaceHours}
            </div>
          </div>
          <div
            className="is-read-only tas-form-column"
            style={{flexBasis: '30%'}}
          >
            <div className="column-title">
              Total Minimum Payable Hours{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The sum of the maximum payable hours allocated to each unit for this TAS and comes directly from the MINMAX Payable nationally agreed maximum payable hours.<br/><br/> These nationally agreed hours data have been agreed to by all States and Territories"
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues?.totalMinimumPayableHours}
            </div>
          </div>
          <div
            className="is-read-only tas-form-column"
            style={{flexBasis: '30%'}}
          >
            <div className="column-title">
              Total Maximum Payable Hours{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The sum of the minimum payable hours allocated to each unit for this TAS and comes directly from the MINMAX Payable nationally agreed minimum payable hours.<br/><br/> These nationally agreed hours data have been agreed to by all States and Territories"
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues?.totalMaximumPayableHours}
            </div>
          </div>
        </div>

        {/* <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            <div className="column-table-title">
              Number of Elective Units <img alt="Info" src={InfoIcon} />
            </div>
            <Table
              columns={euTableColumns}
              data={euTableData}
              isWithoutHeader
              isWithoutPagination
            />
          </div>
        </div>
        <div className="tas-form-row">
          <div className="tas-form-column" style={{paddingLeft: '4%'}}>
            <div className="column-value">Total</div>
          </div>
          <div className="tas-form-column">
            <div className="column-value">40</div>
          </div>
        </div> */}
      </form>
    </DurationAndSchedulingFormContainer>
  );
};

export default DurationAndSchedulingForm;
