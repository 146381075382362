/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {useLocalStorage} from 'react-use';
import {useHistory} from 'react-router-dom';

import TeacherIcon from '../../../../../../assets/icons/post-teacher.svg';
import ProfileImg from '../../../../../../assets/icons/profile.svg';
import TickIcon from '../../../../../../assets/icons/white-green-tick.svg';
import SharePersonIcon from '../../../../../../assets/icons/share-person.svg';
import PublishedButton from '../../../../../../components/shared/Buttons/PublishedButton';
import {MoreOptions} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';

export const PostHeader = ({
  // common use
  unitDetail,
  // Mainly used for posts
  userName,
  profileName,
  time,
  onclickDelete,
  // Mainly used for works unit details
  isPublished,
  profileImg,
  onEdit,
  students,
  isAuthor,
  isTeacher,
  isArchived,
}) => {
  const history = useHistory();
  const timeAgo = time && moment(time).fromNow();
  const [userType] = useLocalStorage('jc-user-type', '', {
    raw: true,
  });
  return (
    <div className="d-flex justify-content-between align-items-center p-3">
      <button
        className="post-user-info"
        type="button"
        onClick={() => {
          if (!isTeacher) {
            history.push(`/portfolio/${userName}`);
          }
        }}
      >
        <img
          src={profileImg || ProfileImg}
          alt="profile-img"
          className="mr-2 rounded-circle"
        />
        <div className="post-user-details">
          <div className="post-username">
            <span>{profileName && profileName}</span>
            {isTeacher ? <img alt="Teacher" src={TeacherIcon} /> : null}
          </div>
          <div className="post-unit-name">
            {`${unitDetail && unitDetail}`} {timeAgo && `${timeAgo}`}
          </div>
        </div>
      </button>
      <div className="d-flex align-items-center options-div">
        {isPublished && (
          <PublishedButton
            className="d-flex align-items-center"
            width="89px"
            height="24px"
          >
            Published
            <img src={TickIcon} alt="Tick" />
          </PublishedButton>
        )}
        {userType !== '2' ? (
          <div
            className="shared-with"
            data-html
            data-for="shared-with-id"
            data-tip={`<div className="tooltip-values">Shared with
                 ${students
                   ?.map(
                     item =>
                       `<li>${item?.profile?.up_name_first} ${item?.profile?.up_name_last}</li>`,
                   )
                   .join('')}
                </div>`}
          >
            <img src={SharePersonIcon} alt="ellipsis" className="shareperson" />
            <ReactTooltip
              backgroundColor="#3d4457"
              effect="solid"
              place="left"
              id="shared-with-id"
            />
          </div>
        ) : null}
        {isAuthor && !isArchived ? (
          <MoreOptions>
            <DropdownItem label="Edit Post" onClick={onEdit} />
            <DropdownItem
              isDanger
              label="Delete Post"
              onClick={onclickDelete}
            />
          </MoreOptions>
        ) : null}
      </div>
    </div>
  );
};
