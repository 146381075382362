/* eslint-disable react/prop-types */
import React from 'react';

// import {useHistory} from 'react-router-dom';
import EmptyState from '../../../../../../../../components/shared/EmptyState';
import {ViewCurriculumMainContainer} from './elements';
import {TableSpinner} from '../../../../../../../Admin/components-new';
import {Module} from './components';

export const ViewCurriculumMain = ({
  moduleData,
  courseName,
  orgUnitInstanceId,
  resetOffset,
  offset,
  isListLoading,
}) => (
  <ViewCurriculumMainContainer>
    {moduleData &&
      !isListLoading &&
      moduleData?.map((item, index) => (
        <Module
          courseName={courseName}
          resetOffset={resetOffset}
          item={item}
          position={offset + index + 1}
          orgUnitInstanceId={orgUnitInstanceId}
          key={item?.ocrm_id_course_curriculum_module}
        />
      ))}
    {!moduleData.length && !isListLoading ? (
      <EmptyState
        body="There is no data about uploaded files and modules available."
        title="No Data Found"
      />
    ) : null}
    {isListLoading ? <TableSpinner /> : null}
  </ViewCurriculumMainContainer>
);
