import moment from 'moment';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import backIcon from '../../../../../assets/icons/back-icon.svg';
import closeModalIcon from '../../../../../assets/icons/close-modal.svg';
import profileIcon from '../../../../../assets/icons/profile.svg';
import studentsIcon from '../../../../../assets/icons/students-blue.svg';
import SearchInput from '../../../../../components/shared/Inputs/SearchInput';
import CustomBox from '../../../../../components/shared/CustomBox';
import CustomButton from '../../../../../components/shared/CustomButton';
import CustomCheckbox from '../../../../../components/shared/CustomCheckbox';
import {
  CustomTable,
  tabularize,
} from '../../../../../components/shared/CustomTable';
import {
  addStudentsToUnit,
  fetchUnitDetails,
  removeStudentFromUnit,
  setUnitDetails,
  streamSelector,
} from '../../../streamSlice';
import UnitDetailsContainer from './UnitDetailsContainer';
import CustomModal from '../../../../../components/shared/CustomModal';

const UnitDetails = () => {
  const isAddingStudents = useRef(false);
  const [endDate, setEndDate] = useState(null);
  const [filteredClassStudents, setFilteredClassStudents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalSearchTerm, setModalSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [students, setStudents] = useState([]);
  const [studentsInClass, setStudentsInClass] = useState([]);
  const [tableColumns, setTableColumns] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableMaps, setTableMaps] = useState(null);
  const dispatch = useDispatch();
  const {classStudents, isLoadingUnitDetails, unitDetails} =
    useSelector(streamSelector);
  const history = useHistory();
  const {unitID} = useParams();

  useEffect(() => {
    dispatch(fetchUnitDetails(unitID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitID]);

  useEffect(() => {
    if (unitDetails) {
      setEndDate(moment(unitDetails.orui_date_finish).format('D MMM YYYY'));
      setStartDate(moment(unitDetails.orui_date_start).format('D MMM YYYY'));
      setStudents(
        unitDetails.students
          .map(student => student.profile)
          .filter(profile => profile.up_id_userprofile),
      );
      const columns = [
        'up_id_userprofile',
        {
          heading: 'Student',
          width: '36%',
        },
        {
          heading: 'Reference ID',
          width: '57%',
        },
        {
          heading: ' ',
          width: '7%',
        },
      ];
      const maps = {
        ' ': [
          userProfileID => (
            <CustomButton
              isDanger
              isSmall
              onClick={() => {
                // eslint-disable-next-line no-alert
                const temporaryPass = window.confirm(
                  'Do you want to remove this student?',
                );

                if (temporaryPass) {
                  dispatch(
                    removeStudentFromUnit(
                      unitDetails.orui_id_orgunitinstance,
                      userProfileID,
                      unitID,
                    ),
                  );
                }
              }}
              text="Remove"
            />
          ),
          'up_id_userprofile',
        ],
        'Reference ID': [
          organizationReference => organizationReference,
          'up_org_reference',
        ],
        Student: [
          (firstName, lastName) => (
            <div>
              <img
                alt={`${firstName} ${lastName}`}
                className="student-profile-picture"
                src={profileIcon}
              />
              <span>
                {firstName} {lastName}
              </span>
            </div>
          ),
          'up_name_first',
          'up_name_last',
        ],
      };
      setTableColumns(columns);
      setTableMaps(maps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitDetails, unitID]);

  useEffect(() => {
    if (classStudents && students) {
      const studentsIdList = students.map(student => student.up_id_userprofile);
      setStudentsInClass(
        classStudents.filter(
          student => !studentsIdList.includes(student.up_id_userprofile),
        ),
      );
    }
  }, [classStudents, students]);

  useEffect(() => {
    if (studentsInClass.length) {
      setFilteredClassStudents(
        studentsInClass.filter(profile =>
          `${profile.up_name_first} ${profile.up_name_last}`
            .toLowerCase()
            .includes(modalSearchTerm),
        ),
      );
    }
  }, [studentsInClass, modalSearchTerm]);

  useEffect(() => {
    if (students.length) {
      setTableData(
        students.filter(
          profile =>
            `${profile.up_name_first} ${profile.up_name_last}`
              .toLowerCase()
              .includes(searchTerm) && profile.up_id_userprofile,
        ),
      );
    }
  }, [searchTerm, students]);

  const selectAllStudents = useCallback(() => {
    if (selectedStudents.length === studentsInClass.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(studentsInClass.map(item => item.up_id_userprofile));
    }
  }, [selectedStudents, studentsInClass]);

  const selectStudents = useCallback(
    userProfileID => {
      if (selectedStudents.includes(userProfileID)) {
        setSelectedStudents(
          selectedStudents.filter(item => item !== userProfileID),
        );
      } else {
        setSelectedStudents([...selectedStudents, userProfileID]);
      }
    },
    [selectedStudents],
  );

  if (!isLoadingUnitDetails && !unitDetails) {
    return null;
  }

  return (
    <UnitDetailsContainer>
      <Helmet>
        <title>
          {isLoadingUnitDetails
            ? 'Loading...'
            : unitDetails.orgUnit.unit.un_unitname}{' '}
          | JungleCat
        </title>
      </Helmet>
      {isLoadingUnitDetails ? null : (
        <CustomModal isVisible={isModalVisible}>
          <div className="add-students-modal-wrapper">
            <div className="add-students-modal-header">
              <div className="add-students-modal-top">
                <div>
                  <h3>Add Student</h3>
                  <h4>{unitDetails.orgUnit.unit.un_unitname}</h4>
                </div>
                <button onClick={() => setIsModalVisible(false)} type="button">
                  <img alt="Close Modal" src={closeModalIcon} />
                </button>
              </div>
              <SearchInput
                onChange={event => setModalSearchTerm(event.target.value)}
                placeholder="Search for student name or ID"
                style={{
                  borderRadius: '8px',
                }}
                value={modalSearchTerm}
                width="100%"
              />
            </div>
            <CustomCheckbox
              isChecked={selectedStudents.length === studentsInClass.length}
              label="Select All Students"
              onChange={() => selectAllStudents()}
              margin="16px 0 16px 24px"
            />
            <div className="add-students-list">
              {filteredClassStudents.map(student => {
                const name = `${student.up_name_first} ${student.up_name_last}`;

                return (
                  <div
                    className="add-students-list-item"
                    key={student.up_id_userprofile}
                  >
                    <div className="add-students-profile-column">
                      <CustomCheckbox
                        isChecked={selectedStudents.includes(
                          student.up_id_userprofile,
                        )}
                        onChange={() =>
                          selectStudents(student.up_id_userprofile)
                        }
                        margin="0 8px 0 0"
                      />
                      <img alt={name} src={profileIcon} />
                      <h5>{name}</h5>
                    </div>
                    <p className="add-students-id-column">
                      {student.up_org_reference}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="add-students-footer">
              <div>
                <img alt="Students" src={studentsIcon} />
                <span className="selected-count">
                  {selectedStudents.length} Students Selected
                </span>
              </div>
              <CustomButton
                isDisabled={!selectedStudents.length}
                onClick={() => {
                  if (isAddingStudents.current) return;
                  isAddingStudents.current = true;

                  if (selectedStudents.length) {
                    dispatch(
                      addStudentsToUnit(
                        unitDetails.orui_id_orgunitinstance,
                        selectedStudents,
                        unitID,
                        () => {
                          isAddingStudents.current = false;
                        },
                      ),
                    );
                    setSelectedStudents([]);
                    setIsModalVisible(false);
                  }
                }}
                text="Add Students"
              />
            </div>
          </div>
        </CustomModal>
      )}
      <button
        className="back-button"
        onClick={() => {
          dispatch(setUnitDetails(null));
          history.goBack();
        }}
        type="button"
      >
        <img alt="Go" src={backIcon} />
        <span>Back</span>
      </button>
      {isLoadingUnitDetails ? (
        <Skeleton
          height="28px"
          style={{
            marginBottom: '16px',
          }}
          width="40%"
        />
      ) : (
        <h2 className="unit-title">{unitDetails.orgUnit.unit.un_unitname}</h2>
      )}
      {isLoadingUnitDetails ? (
        <Skeleton
          height="94px"
          style={{
            marginBottom: '24px',
          }}
        />
      ) : (
        <div className="unit-details">
          <div className="box-column">
            <p className="box-column-title">Start Date</p>
            <p className="box-column-bullet">&bull;</p>
            <p className="box-column-value">{startDate}</p>
          </div>
          <div className="box-column-seperator" />
          <div className="box-column">
            <p className="box-column-title">End Date</p>
            <p className="box-column-bullet">&bull;</p>
            <p className="box-column-value">{endDate}</p>
          </div>
        </div>
      )}
      {isLoadingUnitDetails ? (
        <Skeleton height="136px" />
      ) : (
        <CustomBox padding="23px 24px 0 22px">
          <div className="unit-students-header">
            <p className="unit-students-count">
              {tableData.length || '0'} Students
            </p>
            <div className="unit-students-header-right">
              <SearchInput
                onChange={event => setSearchTerm(event.target.value)}
                placeholder="Search for a student"
                value={searchTerm}
                width="300px"
              />
              <CustomButton
                className="add-student-button"
                onClick={() => setIsModalVisible(true)}
                text="+ Add Student"
              />
            </div>
          </div>
          {tableColumns && tableData && tableMaps ? (
            <CustomTable
              {...tabularize(tableColumns, tableData, tableMaps)}
              className="unit-students-table"
            />
          ) : null}
        </CustomBox>
      )}
    </UnitDetailsContainer>
  );
};

export default UnitDetails;
