import fileDownload from 'js-file-download';

import axios from '../api';

export const handleFileDownload = data => {
  axios
    .get(`api/admin/downloadfile/${data.filePath}`, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res.data, `${data.fileName}`);
    });
};

export const handleFileDownloads = (moduleId, moduleName) => {
  axios
    .get(`api/admin/curriculum/downloadfilesAsZip/${moduleId}`, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res.data, `${moduleName.trim()}.zip`);
    });
};

export const curriculumFilesDownload = (unitId, moduleId, moduleName) => {
  axios
    .get(`/api/class/${unitId}/downloadFilesAsZip/${moduleId}`, {
      responseType: 'blob',
    })
    .then(res => {
      fileDownload(res.data, `${moduleName.trim()}.zip`);
    });
};
