/* eslint-disable react/prop-types */
import React from 'react';
import {Button} from 'react-bootstrap';
import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';

import {AddAssessmentBodyContainer} from './elements';
import {BasicDetails, TaskAndInstruction, AddedTask} from './components';
import SkeltonAssessment from '../../../Assesments/Components/SkeltonAssessment';
import {fetchDraftTask} from '../../../Assesments/Assessment/slices/assessmentSlice';

export const AddAssessmentBody = ({
  currentTab,
  register,
  assessment,
  setValue,
  control,
  getValues,
  errors,
  isPublished,
  isUpdating,
  stopUpdating,
  closeAddTask,
  addTask,
  removeTask,
  openAddTask,
  setOpenAddTask,
  isLoading,
  setError,
  clearErrors,
  watch,
  setSelectStudentError,
  selectStudentError,
  maximumDurationHoursError,
  maximumDurationMinutesError,
  setMaximumDurationHoursError,
  setMaximumDurationMinutesError,
  maximumDurationHours,
  setMaximumDurationHours,
  maximumDurationMinutes,
  setMaximumDurationMinutes,
  setAssessType,
  assessType,
  unitId,
  setIsUpdatingStudents,
  isUpdatingStudents,
  isArchive,
}) => {
  const dispatch = useDispatch();
  const assessmentState = useSelector(state => state.assessment);
  const {createdTasks} = assessmentState;
  return (
    <AddAssessmentBodyContainer>
      {currentTab === 'details' ? (
        <div>
          <div className="mb-4">
            <h2 className="page-title">Basic Details</h2>
            <span className="assessment-detail">
              Assessment and Students Details
            </span>
          </div>
          <div className="detail-task-container">
            <BasicDetails
              register={register}
              assessment={assessment}
              setValue={setValue}
              control={control}
              getValues={getValues}
              errors={errors}
              isPublished={isPublished}
              isUpdating={isUpdating}
              stopUpdating={stopUpdating}
              setError={setError}
              clearErrors={clearErrors}
              watch={watch}
              setSelectStudentError={setSelectStudentError}
              selectStudentError={selectStudentError}
              maximumDurationHoursError={maximumDurationHoursError}
              maximumDurationMinutesError={maximumDurationMinutesError}
              setMaximumDurationHoursError={setMaximumDurationHoursError}
              setMaximumDurationMinutesError={setMaximumDurationMinutesError}
              maximumDurationHours={maximumDurationHours}
              setMaximumDurationHours={setMaximumDurationHours}
              maximumDurationMinutes={maximumDurationMinutes}
              setMaximumDurationMinutes={setMaximumDurationMinutes}
              setAssessType={setAssessType}
              assessType={assessType}
              unitId={unitId}
              setIsUpdatingStudents={setIsUpdatingStudents}
              isUpdatingStudents={isUpdatingStudents}
            />
          </div>
        </div>
      ) : null}
      {currentTab === 'tasks' ? (
        <div className="task-instruction">
          <div className="mb-4">
            <h2 className="page-title">Assessment Tasks</h2>
            <span className="assessment-detail">
              Add / View Multiple Tasks{' '}
            </span>
          </div>
          <div>
            {!isLoading ? (
              <div>
                <AddedTask
                  isPublished={isPublished}
                  removeTask={removeTask}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  isArchive={isArchive}
                  setError={setError}
                  clearErrors={clearErrors}
                  setOpenAddTask={setOpenAddTask}
                />

                {openAddTask ||
                (!createdTasks.length &&
                  !assessmentState.assessmentTasksLoading) ? (
                  <div className="detail-task-container">
                    <TaskAndInstruction
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      closeAddTask={closeAddTask}
                      isPublished={isPublished}
                      addTask={addTask}
                      control={control}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      isArchive={isArchive}
                    />
                  </div>
                ) : (
                  <Button
                    variant="outline-primary"
                    className={classNames('ml-auto add-task-btn', {
                      'is-archive': isArchive,
                    })}
                    onClick={() => {
                      dispatch(fetchDraftTask(assessmentState.activityId));
                      setOpenAddTask(true);
                    }}
                    id="add-another"
                  >
                    + Add Another Task
                  </Button>
                )}
              </div>
            ) : (
              <SkeltonAssessment />
            )}
          </div>
        </div>
      ) : null}
    </AddAssessmentBodyContainer>
  );
};
