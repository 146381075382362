/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import ReactBootstrapModal from 'react-bootstrap/Modal';

import CloseModalIcon from '../../../../../../../../assets/icons/refactor/close-modal.svg';
import {TextModalContainer} from './elements';

const TextModal = ({content, isVisible, onClose, title}) => (
  <ReactBootstrapModal className="tas-text-modal" centered show={isVisible}>
    <TextModalContainer>
      <header>
        <h4>{title}</h4>
        <button onClick={onClose} type="button">
          <img alt="Close Modal" src={CloseModalIcon} />
        </button>
      </header>
      <main
        dangerouslySetInnerHTML={{__html: content}}
        style={{whiteSpace: 'pre-line'}}
      />
    </TextModalContainer>
  </ReactBootstrapModal>
);

export default TextModal;
