/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
  getAllAssessments,
  classesSelector,
} from '../../../../../../../../redux/classesSlice';
import {EmptyState, Table} from '../../../../../../../../components/common';

import ViewClassImg from '../../../../../../../../assets/icons/viewclass.svg';
import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';
import {TableCellLink} from '../../../../../../../../components/common/Table/styles';
import {TableSpinner} from '../../../../../../components-new';
import {AssessmentsListContainer} from './elements';

export const AssessmentsList = () => {
  const {orgClassId} = useParams();
  const [orgUnitInstanceId, setOrgUnitInstanceId] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const {assessments, unitDetails, isAssessmentsLoading} =
    useSelector(classesSelector);
  const {
    location: {pathname},
  } = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Assessment Title',
        accessor: 'ac_activity_name',
        className: 'assessment-name',
      },
      {
        Header: 'Date Added',
        Cell: ({value}) => <div>{moment(value).format('D MMM, YYYY')}</div>,
        accessor: 'ac_timestamp_creation',
        className: 'timestamp_creation',
      },

      {
        Header: 'Teacher',
        Cell: ({row: {original}}) => (
          <div>{`${original.up_name_first} ${original.up_name_last}`}</div>
        ),
        accessor: 'up_name_first',
        className: 'teacher-name',
      },

      {
        Header: '',
        Cell: ({value}) => (
          <div>
            <TableCellLink
              onClick={() => {
                history.push(
                  `/admin/classes/view-class/${orgClassId}/units/${orgUnitInstanceId}/assessments/${value}`,
                );
              }}
              type="button"
              className="view-assessment"
            >
              <span>View Assessment</span>
              <img
                src={ViewClassImg}
                alt="loader"
                className="viewassessment-arrow"
              />
            </TableCellLink>
          </div>
        ),
        accessor: 'ac_id_activity',
        className: 'view',
      },
    ],
    [history, orgClassId, orgUnitInstanceId],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(assessments || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [assessments],
  );

  useEffect(() => {
    const unitId = pathname.split('/')[6];
    dispatch(getAllAssessments(unitId));
    setOrgUnitInstanceId(unitId);
  }, [dispatch, orgClassId, pathname]);

  return (
    <>
      <Helmet>
        <title>Assessments | JungleCat</title>
      </Helmet>

      <AssessmentsListContainer>
        <div className="back-button">
          <button
            className="btn"
            onClick={() =>
              history.replace(`/admin/classes/view-class/${orgClassId}`)
            }
            style={{
              padding: 0,
            }}
            type="button"
          >
            <img src={ArrowLeftImg} alt="arrow" />
            <span>Back</span>
          </button>
        </div>
        <div>
          <h2 className="unit-name">
            {`${unitDetails?.orgUnit?.unit?.tun_title}`}
          </h2>
          <p className="unit-code">
            {`${unitDetails?.orgUnit?.unit?.tun_code}`} •{' '}
            {`${
              unitDetails?.orgUnit.oqu_is_essential === 1 ? 'core' : 'elective'
            } Unit`}
          </p>
        </div>
        <div className="table-container">
          {isAssessmentsLoading ? <TableSpinner /> : null}
          {!isAssessmentsLoading &&
          Array.isArray(assessments) &&
          !assessments?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Assessments Found"
            />
          ) : null}

          {!isAssessmentsLoading &&
          assessments?.length &&
          Array.isArray(assessments) ? (
            <Table
              columns={columns}
              data={data}
              itemName="Assessments"
              maxWidth="75rem"
              isSortedBy
            />
          ) : null}
        </div>
      </AssessmentsListContainer>
    </>
  );
};
