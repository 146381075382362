import styled from 'styled-components';

import {Colors} from '../../../../../constants';

export const LibraryContainer = styled.div`
  background-color: ${Colors.Background};
  min-height: 100vh;
  margin: 0 -0.935rem 0 -0.935rem;
  padding: 7rem 2rem 3rem 7rem;

  @media (max-width: 68.75rem) {
    & {
      padding: 7rem 2rem 2rem 7rem;
    }
  }

  @media (max-width: 43.75rem) {
    & {
      padding: 12rem 1rem 3rem 7rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 12rem 1rem 3rem 1rem;
    }
  }
  .__react_component_tooltip {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 0.312rem;
  }
  .add-doc-button {
    &.is-archive {
      display: none !important;
    }
  }
  @media (max-width: 30rem) {
    .library-searc-add {
      justify-content: flex-end !important;
    }
    .add-doc-button {
      width: 7% !important;
      img {
        margin-right: unset !important ;
      }
      .label {
        display: none;
      }
    }
  }

  @media (max-width: 17rem) {
    & {
      margin: 0 -3.3rem 0 -0.935rem;
      overflow-x: hidden !important;
    }
  }

  .title {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .library-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .library-searc-add {
    display: flex;
    justify-content: space-between;
  }

  .page-title {
    color: ${Colors.Text};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.81rem;
  }
  .search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    padding: 0rem 0rem 0rem 0rem;
    border-bottom: 0.0625rem solid #dedede;
    margin-top: 2rem;
    .bottom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      font-weight: 500;
      font-size: 1rem;
      outline: none;
      color: #aaaaaa;
      &.link {
        border-bottom: 0.125rem solid #40a1ff !important;
        color: #3d4457;
      }
      p {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
  .files-div {
    display: flex;
    flex-flow: wrap;
    margin-left: unset !important;
    margin-right: unset !important;
    justify-content: space-between;
  }
  .search-div::-webkit-scrollbar {
    display: none;
  }
  .file-card {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
  }
  @media (max-width: 36rem) {
    .title-code {
      flex-direction: column;
    }
    .code,
    .document-title {
      width: unset !important;
      margin-left: unset !important;
    }
    .footer .button-container {
      margin-right: unset !important;
    }
  }

  .add-menu-list {
    top: -2.2rem !important;
    .add-menu-item-list {
      margin-top: 0.5rem;
      padding: unset;
      border-radius: 0.3125rem;

      transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s;
      background-color: rgb(64, 161, 255) !important;
      cursor: pointer !important;
    }
  }
  .add-menu {
    padding: unset;
    border: unset;
  }
  .add-menu-item {
    width: unset;
    margin: unset;
    padding: unset;
    border-radius: 0.3125rem;
    transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s;
    background-color: rgb(64, 161, 255) !important;
    cursor: pointer !important;
  }
  .add-category-btn {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    color: #ffffff;
  }
  .header-title {
    padding: 2.5rem 2rem 2rem 2rem;
    background: white;
    border-bottom: 0.0625rem solid ${({theme}) => theme.colors.border};
  }
  .card-class {
    height: 0;
    padding-bottom: calc(25% - 1.875rem);
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    .card-class {
      height: 0;
      padding-bottom: calc(50% - 1.875rem);
    }
  }
  @media screen and (min-width: 760px) and (max-width: 992px) {
    .card-class {
      height: 0;
      padding-bottom: calc(33.33333% - 1.875rem);
    }
  }

  @media screen and (min-width: 0px) and (max-width: 575px) {
    .card-class {
      height: 0;
      padding-bottom: calc(100% - 1.875rem);
    }
  }
  .loader-images {
    display: flex;
  }
  .button-spinner {
    margin-bottom: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    animation: rotating 1s linear infinite;
  }
`;
