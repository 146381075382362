/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-alert */
import AgoraRTC from 'agora-rtc-sdk-ng';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import useAgora from './hooks/useAgora';
import Welcome from './components/Welcome';
import instance from '../../../../api';
import LiveSessionContainer from './elements/LiveSessionContainer';
import LiveSessionFooter from './components/LiveSessionFooter';
import VideoContainer from './components/VideoContainer';
import LiveSessionNavbar from './components/LiveSessionNavbar';
import useSocket from './hooks/useSocket';
import StudentList from './components/StudentList';
import Chat from './components/Chat';
import * as sessionApi from '../../../../api/session';
import useConfirm from './hooks/useConfirm';
import RequestModal from './components/RequestModal';
import 'react-toastify/dist/ReactToastify.css';
import StudentToast from './components/StudentToast';
import StyledToastContainer from './components/StudentToast/elements/StyledToastContainer';
import {LiveSessionContext} from './contexts/LiveSessionContext';

const client = AgoraRTC.createClient({codec: 'h264', mode: 'rtc'});
client.enableAudioVolumeIndicator();
const screenClient = AgoraRTC.createClient({codec: 'h264', mode: 'rtc'});

const LiveSession = () => {
  const {id: roomId} = useParams();
  const history = useHistory();
  const {pathname} = useLocation();
  const [isWaitingForHost, setIsWaitingForHost] = useState(
    +localStorage.getItem('jc-user-type') !== 3,
  );
  const {
    isHost,
    userId,
    hostDetails,
    totalStudentCount,
    sessionDetails,
    currentProfile,
    setScreenSharingUserDetails,
  } = useContext(LiveSessionContext);
  const [students, setStudents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [layoutMode, setLayoutMode] = useState(0);
  const [isAudioMute, setIsAudioMute] = useState(false);
  const [isVideoMute, setIsVideoMute] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isStudentListOpen, setIsStudentListOpen] = useState(false);
  const [screenShareRequestStatus, setScreenShareRequestStatus] =
    useState('idle');
  const [noOfNewMessages, setNoOfNewMessages] = useState(0);

  const {isConfirmed} = useConfirm();
  const leaveFnRef = useRef();
  const localAudioTrackRef = useRef();
  const localVideoTrackRef = useRef();

  let hasHostJoinedTimeout;

  const streamId = useMemo(() => pathname.split('/')[2], [pathname]);

  const {
    createRoom,
    listenStudentsUpdated,
    listenNewStudentJoined,
    listenStudentLeft,
    joinRoom,
    leaveRoom,
    listenCallEndedByHost,
    toggleVideoState,
    toggleAudioState,
    forceVideoMute,
    forceAudioMute,
    listenAudioMutedByHost,
    listenVideoMutedByHost,
    listenKickedOutByHost,
    kickOutUser,
    listenNewMessage,
    sendMessage,
    type,
    onTyping,
    onTypingStop,
    stopTyping,
    removeAllLiveSessionListeners,
    requestScreenShare,
    listenScreenShareRequest,
    sendScreenSharingDetails,
    listenScreeSharingUserDetailsUpdate,
  } = useSocket(roomId, userId, isHost);

  const {
    join,
    leave,
    remoteUsers,
    localVideoTrack,
    localAudioTrack,
    isSharingScreen,
    isRemoteSharingScreen,
    remoteScreenAudioTrack,
    remoteScreenVideoTrack,
    localScreenVideoTrack,
    speakingStates,
    onScreenEnd,
    joinAndPublishScreen,
  } = useAgora(client, screenClient);

  const remoteHost = useMemo(() => {
    if (isHost) return null;
    if (remoteUsers.length && hostDetails) {
      return remoteUsers.find(remoteUser => remoteUser.uid === hostDetails.id);
    }
    return null;
  }, [remoteUsers, hostDetails, isHost]);

  const startScreenSharing = () => {
    try {
      const screenShareId = `screen-${userId}`;
      instance
        .get(`/api/rtc/${roomId}/token?role=publisher&userId=${screenShareId}`)
        .then(async ({data}) => {
          try {
            await joinAndPublishScreen(roomId, data.token, screenShareId);
            setLayoutMode(1);
            sendScreenSharingDetails(
              currentProfile.up_name_first,
              currentProfile.up_name_last,
            );
            setScreenSharingUserDetails({
              firstname: 'You',
              lastname: '',
            });
          } catch (err) {
            if (err.code === 'PERMISSION_DENIED') {
              alert('please give permission to share screen and try again');
            }
          }
        });
    } catch (err) {
      // TODO :- something went wrong error
      setLayoutMode(0);
    }
  };

  const handleScreenShare = async () => {
    if (isSharingScreen) {
      onScreenEnd();
    } else if (isRemoteSharingScreen) {
      alert('only one person can share screen at a time');
    } else if (isHost) {
      startScreenSharing();
    } else {
      setScreenShareRequestStatus('pending');
      const response = await requestScreenShare({
        avatar: currentProfile.up_avatar,
        firstname: currentProfile.up_name_first,
        lastname: currentProfile.up_name_last,
      });

      if (response.error) {
        // TODO:- show error
      } else if (response.answer === 'accepted') {
        setScreenShareRequestStatus('accepted');
        setTimeout(() => {
          setScreenShareRequestStatus('idle');
          startScreenSharing();
        }, 1000);
      } else {
        setScreenShareRequestStatus('rejected');
        setTimeout(() => {
          setScreenShareRequestStatus('idle');
        }, 1000);
      }
    }
  };

  const handleCallEnd = async () => {
    removeAllLiveSessionListeners();
    await leaveFnRef.current();
    history.push(`/stream/${streamId}/live`);
  };

  const handleAudioMute = () => {
    localAudioTrack.setMuted(!localAudioTrack.muted);
    setIsAudioMute(!isAudioMute);
    toggleAudioState(() => {});
  };

  const handleVideoMute = () => {
    localVideoTrack.setMuted(!localVideoTrack.muted);
    setIsVideoMute(!isVideoMute);
    toggleVideoState(() => {});
  };

  const cleanUp = async () => {
    clearTimeout(hasHostJoinedTimeout);
    leaveRoom(
      currentProfile.up_name_first,
      currentProfile.up_name_last,
      currentProfile.up_avatar,
    );
    removeAllLiveSessionListeners();
    await leaveFnRef.current();
  };

  const joinVideoCall = async () => {
    try {
      const {data} = await instance.get(
        `/api/rtc/${roomId}/token` +
          `?role=${isHost ? 'publisher' : 'subscriber'}` +
          `&userId=${userId}`,
      );
      if (isHost) {
        createRoom(async ack => {
          if (ack.error) {
            await cleanUp();
            history.push(`/stream/${streamId}/live`, {
              error: 'something went wrong, please try again later ',
            });
          }
        });
      } else {
        joinRoom(
          {
            firstname: currentProfile.up_name_first,
            lastname: currentProfile.up_name_last,
            avatar: currentProfile.up_avatar,
            username: currentProfile.us_profile_name,
          },
          async ack => {
            if (ack.error) {
              await cleanUp();
              history.push(`/stream/${streamId}/live`, {
                error: 'something went wrong',
              });
            } else {
              setNoOfNewMessages(ack.messages.length);
              setMessages(ack.messages);
            }
          },
        );
      }
      await join(roomId, data.token, currentProfile.up_id_userprofile);
    } catch (err) {
      // handler err
    }
  };

  const checkIfHostHasJoined = async () => {
    try {
      const response = await sessionApi.hasHostJoined(roomId);
      if (response.data.hasHostJoined) {
        joinVideoCall();
        setIsWaitingForHost(false);
      } else {
        hasHostJoinedTimeout = setTimeout(checkIfHostHasJoined, 3000);
      }
    } catch (err) {
      // check err code and show correct error
      console.log(err);
    }
  };

  useEffect(() => {
    leaveFnRef.current = leave;
    localAudioTrackRef.current = localAudioTrack;
    localVideoTrackRef.current = localVideoTrack;
  }, [leave, localAudioTrack, localVideoTrack]);

  useEffect(() => {
    if (!isHost) {
      checkIfHostHasJoined();
      listenCallEndedByHost(async () => {
        history.push(`/stream/${streamId}/live`); // TODO:- show message call ended by host
      });

      listenAudioMutedByHost(() => {
        localAudioTrackRef.current.setMuted(true);
        setIsAudioMute(true);
      });

      listenVideoMutedByHost(() => {
        localVideoTrackRef.current.setMuted(true);
        setIsVideoMute(true);
      });

      listenKickedOutByHost(async () => {
        history.push(`/stream/${streamId}/live`); // TODO:- show message kicked out by host
      });
    } else {
      joinVideoCall();
      listenScreenShareRequest(student =>
        isConfirmed({
          avatar: student.avatar,
          firstname: student.firstname,
          lastname: student.lastname,
        }),
      );
    }

    listenStudentsUpdated(newStudentList => {
      setStudents(newStudentList);
    });

    listenNewStudentJoined(({firstname, lastname, avatar}) => {
      toast(
        <StudentToast
          firstname={firstname}
          lastname={lastname}
          avatar={avatar}
          action="joined"
        />,
        {
          autoClose: 2000,
          position: 'bottom-right',
          hideProgressBar: true,
          pauseOnFocusLoss: false,
        },
      );
    });

    listenStudentLeft(({firstname, lastname, avatar}) => {
      toast(
        <StudentToast
          firstname={firstname}
          lastname={lastname}
          avatar={avatar}
          action="left"
        />,
        {
          autoClose: 2000,
          position: 'bottom-right',
          hideProgressBar: true,
          pauseOnFocusLoss: false,
        },
      );
    });

    listenNewMessage(message => {
      if (!isChatOpen) {
        setNoOfNewMessages(value => value + 1);
      }
      setMessages(previousMessages =>
        [...previousMessages, message].sort((a, b) =>
          a.createdAt < b.createdAt ? -1 : 1,
        ),
      );
    });

    listenScreeSharingUserDetailsUpdate(details => {
      setScreenSharingUserDetails(details);
    });

    return async () => {
      await cleanUp();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRemoteSharingScreen || isSharingScreen) {
      setLayoutMode(1);
    } else {
      setLayoutMode(0);
    }
  }, [isRemoteSharingScreen, isSharingScreen]);

  if (isWaitingForHost) {
    return (
      <Welcome
        classname={sessionDetails?.classname}
        unitname={sessionDetails?.unitname}
        userAvatar={currentProfile.up_avatar}
        teacherAvatar={hostDetails?.avatar}
      />
    );
  }

  return (
    <LiveSessionContainer>
      <StyledToastContainer />
      <RequestModal />
      <LiveSessionNavbar {...sessionDetails} currentProfile={currentProfile} />
      <VideoContainer
        remoteHost={remoteHost}
        layoutMode={layoutMode}
        remoteUsers={remoteUsers}
        localVideoTrack={localVideoTrack}
        localAudioTrack={localAudioTrack}
        isSharingScreen={isSharingScreen}
        remoteScreenAudioTrack={remoteScreenAudioTrack}
        remoteScreenVideoTrack={remoteScreenVideoTrack}
        localScreenVideoTrack={localScreenVideoTrack}
        speakingStates={speakingStates}
      />
      <div
        onBlur={e => {
          if (
            !e.currentTarget.contains(e.relatedTarget) &&
            e.relatedTarget?.id !== 'attendees-btn' &&
            e.relatedTarget?.id !== 'chat-btn'
          ) {
            setIsStudentListOpen(false);
            setIsChatOpen(false);
          }
        }}
        tabIndex="0"
      >
        {isStudentListOpen && (
          <StudentList
            isHost={isHost}
            students={students}
            onChatOpen={() => {
              setIsStudentListOpen(false);
              setIsChatOpen(true);
            }}
            onClose={() => {
              setIsStudentListOpen(false);
              setIsChatOpen(false);
            }}
            speakingStates={speakingStates}
            onForceAudioMute={id => {
              forceAudioMute(id, () => {});
            }}
            onForceVideoMute={id => {
              forceVideoMute(id, () => {});
            }}
            onKickOutUser={id => {
              kickOutUser(id, () => {});
            }}
          />
        )}
        {isChatOpen && (
          <Chat
            userId={userId}
            messages={messages}
            setNoOfNewMessages={setNoOfNewMessages}
            onStudentListOpen={() => {
              setIsChatOpen(false);
              setIsStudentListOpen(true);
            }}
            onClose={() => {
              setIsStudentListOpen(false);
              setIsChatOpen(false);
            }}
            onSendMessage={text => {
              const message = {
                text,
                senderId: userId,
                senderFirstName: currentProfile.up_name_first,
                senderLastName: currentProfile.up_name_last,
                senderAvatar: currentProfile.up_avatar,
                createdAt: Date.now(),
              };
              sendMessage(message, ack => {
                if (!ack.error) {
                  message.id = ack.id;
                  setMessages(previousMessages =>
                    [...previousMessages, message].sort((a, b) =>
                      a.createdAt < b.createdAt ? -1 : 1,
                    ),
                  );
                }
              });
            }}
            type={() => {
              type({
                id: currentProfile.up_id_userprofile,
                avatar: currentProfile.up_avatar,
                firstname: currentProfile.up_name_first,
                lastname: currentProfile.up_name_last,
              });
            }}
            onTyping={onTyping}
            stopTyping={() => {
              stopTyping();
            }}
            onTypingStop={onTypingStop}
            attendeesCount={students.length}
          />
        )}
      </div>
      <LiveSessionFooter
        onCallEnd={handleCallEnd}
        onScreenShare={handleScreenShare}
        isAudioMute={isAudioMute}
        isVideoMute={isVideoMute}
        onAudioMute={handleAudioMute}
        onVideoMute={handleVideoMute}
        studentCount={students?.length}
        totalStudentCount={totalStudentCount}
        isScreenSharingActive={isRemoteSharingScreen || isSharingScreen}
        noOfNewMessages={noOfNewMessages}
        onChatToggle={() =>
          setIsChatOpen(value => {
            if (!value) setIsStudentListOpen(false);
            return !value;
          })
        }
        onStudentListToggle={() =>
          setIsStudentListOpen(value => {
            if (!value) setIsChatOpen(false);
            return !value;
          })
        }
      />
    </LiveSessionContainer>
  );
};

export default LiveSession;
