import styled from 'styled-components';

import {Colors} from '../../../../../constants';

const SessionBarContainer = styled.div`
  background-color: ${Colors.LightPrimary};
  border: 1px solid ${Colors.Primary};
  border-radius: 8px;
  left: 82px;
  height: ${({height}) => `${height}px`};
  padding: 15px 14px 0 14px;
  top: ${({top}) => `${top}px`};
  width: calc(100% - 106px);

  .time-interval {
    color: ${Colors.Primary};
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 1px;
  }

  .session-title {
    color: ${Colors.Text};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
  }
`;

export const SessionBarLessThanHourContainer = styled.div`
  background-color: ${Colors.LightPrimary};
  border: 1px solid ${Colors.Primary};
  border-radius: 8px;
  left: 82px;
  height: ${({height}) => `${height}px`};
  padding: 0px 14px 0 14px;
  top: ${({top}) => `${top}px`};
  width: calc(100% - 106px);

  .time-interval {
    color: ${Colors.Primary};
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 1px;
  }

  .session-title {
    color: ${Colors.Text};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
  }
`;

export default SessionBarContainer;
