/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, {useEffect, useState} from 'react';

import addedTextInstruction from '../../../../assets/icons/text-icon-instruction-new.svg';
import addedVoiceInstruction from '../../../../assets/icons/audio-icon-instruction-new.svg';
import documentInstruction from '../../../../assets/icons/doc-icon-instruction-new.svg';

import closeIcon from '../../../../assets/icons/instruction-modal-close.svg';

import VideoThumbnailIcon from '../../../../assets/icons/video-thumbnail-new.svg';
import CustomModal from '../../../../components/shared/CustomModal';
import CustomBox from '../../../../components/shared/CustomBox';

export const DisplayModal = ({onClose, showData}) => (
  <CustomModal isVisible>
    <div
      className={`display-modal-container ${
        showData.type === 'document' ? 'for-document' : ''
      }`}
    >
      <CustomBox padding="1.5rem" className="custombox">
        <div className="display-modal-header">
          <h5>{showData?.text}</h5>
          <button
            className="modal-close-button"
            onClick={onClose}
            type="button"
          >
            <img alt="Close Modal" src={closeIcon} />
          </button>
        </div>
        {showData.type === 'document' ? (
          <embed className="instruction-document" src={showData.media} />
        ) : (
          <div className="instruction-image">
            <img alt="Instruction" src={showData.media} />
          </div>
        )}
      </CustomBox>
    </div>
  </CustomModal>
);

export const Instruction = ({data, onShowClick, index, isSubmission}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (data.media) {
      setSource(
        typeof data.media === 'string'
          ? data.media
          : URL.createObjectURL(data.media),
      );
    }
  }, [data.media]);

  let element = null;
  let instructionIcon;

  if (data.type === 'audio') {
    instructionIcon = addedVoiceInstruction;
  } else if (data.type === 'document') {
    instructionIcon = documentInstruction;
  } else if (data.type === 'image') {
    instructionIcon = source;

    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
      </button>
    );
  } else if (data.type === 'text') {
    instructionIcon = addedTextInstruction;
  } else if (data.type === 'video') {
    instructionIcon = data.thumbnail;

    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={VideoThumbnailIcon}
        />
      </button>
    );
  }

  if (['audio', 'document', 'text'].includes(data.type)) {
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
      </button>
    );
  }

  return (
    <div className="added-instruction">
      {element}
      {!isSubmission ? (
        <div className="instruction-content">
          <span className="index">{`${index > 9 ? index : `0${index}`}`}</span>
          {/* <div> {subtitle}</div> */}
          <p className="title-container">
            {data?.text?.length > 128 && !isExpanded
              ? `${data?.text?.slice(0, 128)}...`
              : data?.text}{' '}
            {data?.text?.length > 128 ? (
              !isExpanded ? (
                <button onClick={() => setIsExpanded(true)} type="button">
                  See more
                </button>
              ) : (
                <button onClick={() => setIsExpanded(false)} type="button">
                  See less
                </button>
              )
            ) : null}
          </p>
        </div>
      ) : (
        <div className="instruction-content">
          <p className="title-container">
            {data?.title?.length > 128 && !isExpanded
              ? `${data?.title?.slice(0, 128)}...`
              : data?.title}{' '}
            {data?.title?.length > 128 ? (
              !isExpanded ? (
                <button onClick={() => setIsExpanded(true)} type="button">
                  See more
                </button>
              ) : (
                <button onClick={() => setIsExpanded(false)} type="button">
                  See less
                </button>
              )
            ) : null}
          </p>
          <span className="type-file">{data.type}</span>
        </div>
      )}
    </div>
  );
};
