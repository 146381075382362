import styled from 'styled-components';

export const AddAssessmentBodyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 93.33%;
  padding: 2rem 2rem 4.8rem 2rem;
  min-height: 100vh;
  .task-instruction {
    width: 100%;
  }
  .detail-task-container {
    flex-shrink: 0;
    width: 100%;
    background: #fff;
    padding: 2rem;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    /* @media (min-width: 84rem) {
      & {
        min-width: 84rem;
      }
    } */
    @media (max-width: 45) {
      & {
        width: 98%;
        padding: 0 0.9375 7.5rem 0.9375;
      }
    }
  }
  .page-title {
    color: ${({theme}) => theme.colors.text};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.81rem;
  }

  .assessment-detail {
    color: ${({theme}) => theme.colors.lightText};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
    margin-bottom: 2rem;
  }
  .task-attach-file {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .save-task-btn {
    width: 5.06rem;
    height: 2.5rem;
    background: #ffffff;
    border: 0.0625rem solid #40a1ff;
    box-sizing: border-box;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.37rem;
    color: #40a1ff;
    margin-left: 1.5rem;
  }
  .add-task-btn {
    width: 11.437rem;
    height: 2.5rem;
    background: #f4f5f8;
    border: 0.0625rem solid #40a1ff;
    box-sizing: border-box;
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.37rem;
    color: #40a1ff;
    float: right;
    &.is-archive {
      display: none !important;
    }
  }
  .added-instruction {
    button {
      width: unset !important;
      height: unset !important;
    }
  }
  .cancel-save-update {
    &.is-archive {
      display: none !important;
    }
  }
`;
