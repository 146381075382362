/* eslint-disable react/prop-types */
import React, {useState, useEffect, useMemo} from 'react';

import {Helmet} from 'react-helmet';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {ViewCurriculumContainer} from './elements';
import {
  ViewCurriculumHeader,
  ViewCurriculumFooter,
  ViewCurriculumMain,
} from './components';
import {
  getCurriculumByOrgUnitInstanceId,
  teacherCurriculumSelector,
} from '../../../../../../redux/teacherCurriculumSlice';

export const ViewCurriculumTeacher = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const {id: orgUnitInstanceId} = useParams();
  const [perPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const {courseCurriculum, isListLoading} = useSelector(
    teacherCurriculumSelector,
  );

  const moduleData = useMemo(
    () => [...(data || [])?.map(item => item)],
    [data],
  );

  const onNextPageClick = () => {
    setCurrentPage(state => state + 1);
    setOffset(currentPage * perPage);
  };
  const onPreviousPageClick = () => {
    setCurrentPage(state => state - 1);
    setOffset(state => state - perPage);
  };

  const onCancel = () => {
    history.goBack();
  };
  const notPublishedFiles = useMemo(
    () => [
      ...(
        courseCurriculum?.OrgCourseCurriculum?.OrgCourseCurriculumModules || []
      ).filter(item =>
        item?.OrgCourseCurriculumFiles?.find(
          list => list?.occf_is_published === 0,
        ),
      ),
    ],
    [courseCurriculum],
  );

  useEffect(() => {
    const filterData =
      courseCurriculum?.OrgCourseCurriculum?.OrgCourseCurriculumModules?.filter(
        item =>
          item.ocrm_module_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item?.OrgCourseCurriculumFiles?.find(list =>
            list?.fi_text_content
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()),
          ),
      );

    const curriculumModuleData = filterData?.slice(offset, offset + perPage);
    setData(curriculumModuleData);
    setPageCount(Math.ceil(filterData?.length / perPage));
  }, [courseCurriculum, offset, pageCount, perPage, searchTerm]);

  const resetOffset = () => {
    if (data.length === 1) {
      setOffset(0);
      setCurrentPage(1);
    }
  };
  useEffect(() => {
    setCurrentPage(1);
    setOffset(0);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getCurriculumByOrgUnitInstanceId(orgUnitInstanceId));
  }, [orgUnitInstanceId, dispatch]);

  return (
    <>
      <Helmet>
        <title>View Curriculum | JungleCat</title>
      </Helmet>

      <ViewCurriculumContainer>
        <ViewCurriculumHeader
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          orgUnitInstanceId={orgUnitInstanceId}
          orgClassCurriculumId={
            courseCurriculum?.OrgCourseCurriculum?.occl_id_class_curriculum
          }
          moduleDataLength={
            courseCurriculum?.OrgCourseCurriculum?.OrgCourseCurriculumModules
              ?.length
          }
          academicYear={courseCurriculum?.oay_year}
          notPublishedFiles={notPublishedFiles?.length}
          onBack={onCancel}
        />
        <ViewCurriculumMain
          moduleData={moduleData}
          courseName={courseCurriculum?.ocr_course_name}
          orgUnitInstanceId={orgUnitInstanceId}
          resetOffset={resetOffset}
          offset={offset}
          isListLoading={isListLoading}
        />
        {moduleData.length ? (
          <ViewCurriculumFooter
            pageCount={pageCount}
            currentPage={currentPage}
            onNextPageClick={onNextPageClick}
            onPreviousPageClick={onPreviousPageClick}
            canPreviousPage={currentPage > 1}
            canNextPage={currentPage < pageCount}
          />
        ) : null}
      </ViewCurriculumContainer>
    </>
  );
};
