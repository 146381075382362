import {createSlice} from '@reduxjs/toolkit';

import * as streamApi from '../api/stream-new';

const streamSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: true,
    isPaginationLoading: false,
    isStreamLoading: false,
    stream: null,
    streamCount: 0,
    isCommentLoading: false,
    classStudents: [],
    isFormModalOpen: false,
    selectedStudents: null,
    studentSelectDropDownTitle: 'All Students',
    classDetails: {},
  },
  name: 'streamNew',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsPaginationLoading(state, action) {
      state.isPaginationLoading = action.payload;
    },
    setIsStreamLoading(state, action) {
      state.isStreamLoading = action.payload;
    },
    appendStream: (state, action) => {
      state.stream = [...state.stream, ...action.payload];
    },
    setStream: (state, action) => {
      state.stream = action.payload;
    },
    setStreamCount: (state, action) => {
      state.streamCount = action.payload;
    },
    setComment: (state, action) => {
      const post = state.stream.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      post.comments = [action.payload.comment, ...(post?.comments || [])];
    },
    setComments: (state, action) => {
      const post = state.stream.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      post.comments = [...(post?.comments || []), ...action.payload.comments];
    },
    deleteAssessmentFromPosts: (state, action) => {
      state.stream = state.stream.filter(
        assessment =>
          assessment.aoui_id_activityorgunitinstance !== action.payload,
      );
    },
    deleteCommentById: (state, action) => {
      const post = state.stream.find(
        item => item.ac_id_activity === action.payload.activityId,
      );

      post.comments = post.comments.filter(
        item =>
          item.acc_id_activity_comment !== Number(action.payload.commentId),
      );
    },
    setIsCommentLoading(state, action) {
      state.isCommentLoading = action.payload;
    },
    setClassStudents: (state, action) => {
      state.classStudents = action.payload;
    },
    setSelectedStudents: (state, action) => {
      state.selectedStudents = action.payload;
    },
    setStudentDropdownTitle: (state, action) => {
      state.studentSelectDropDownTitle = action.payload;
    },
    setClassDetails: (state, action) => {
      state.classDetails = action.payload;
    },
    setIsFormModalOpen: (state, action) => {
      state.isFormModalOpen = action.payload;
    },
  },
});

export const {
  setError,
  setIsListLoading,
  setIsPaginationLoading,
  setIsStreamLoading,
  appendStream,
  setStream,
  setStreamCount,
  setComment,
  setComments,
  deleteAssessmentFromPosts,
  deleteCommentById,
  setIsCommentLoading,
  setClassStudents,
  setSelectedStudents,
  setStudentDropdownTitle,
  setClassDetails,
  setIsFormModalOpen,
} = streamSlice.actions;

export const fetchAllStream =
  (classId, type = 3, offset, searchActivityOrgUnitInstanceId) =>
  async dispatch => {
    if (offset) {
      dispatch(setIsPaginationLoading(true));
    } else {
      dispatch(setIsListLoading(true));
    }
    try {
      let response;

      if (type === 3) {
        response = await streamApi.fetchPost(
          classId,
          offset || 0,
          searchActivityOrgUnitInstanceId,
        );
      } else if (type === 2) {
        response = await streamApi.fetchPostsForStudent(
          classId,
          offset || 0,
          searchActivityOrgUnitInstanceId,
        );
      }

      if (offset) {
        dispatch(appendStream(response.data.streams));
      } else {
        dispatch(setStream(response.data.streams));
      }

      dispatch(setStreamCount(response.data.streamCount));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      if (offset) {
        dispatch(setIsPaginationLoading(false));
      } else {
        dispatch(setIsListLoading(false));
      }
    }
  };

export const commentOnPost =
  (activityId, comment, callback) => async dispatch => {
    dispatch(setIsCommentLoading(true));
    try {
      const {data} = await streamApi.commentOnPost(activityId, comment);
      dispatch(
        setComment({
          activityId,
          comment: data.comment,
        }),
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsCommentLoading(false));
    }
  };

export const getMoreComments = (activityId, offset) => async dispatch => {
  dispatch(setIsCommentLoading(true));
  try {
    const {data} = await streamApi.getComments(activityId, offset);
    dispatch(
      setComments({
        activityId,
        comments: data.comments,
      }),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsCommentLoading(false));
  }
};

export const deleteComment =
  (commentId, activityId, callback) => async dispatch => {
    dispatch(setIsCommentLoading(true));
    try {
      const {data} = await streamApi.deleteComment(commentId);
      dispatch(deleteCommentById({commentId: data.commentId, activityId}));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsCommentLoading(false));
    }
  };

export const deletePost =
  (unitInstanceId, posts, callback) => async dispatch => {
    dispatch(setIsStreamLoading(true));
    try {
      await streamApi.deleteStreamPost(unitInstanceId);
      const updatedPosts = [...posts];
      const deleteIndex = updatedPosts.findIndex(
        post => post.aoui_id_activityorgunitinstance === unitInstanceId,
      );
      updatedPosts.splice(deleteIndex, 1);
      dispatch(setStream(updatedPosts));
      if (callback) {
        callback(null);
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsStreamLoading(false));
    }
  };

export const createPost = (classId, data, userType, cb) => async dispatch => {
  dispatch(setIsStreamLoading(true));
  try {
    await streamApi.createPost(classId, data);
    // Getting post when submitting
    dispatch(fetchAllStream(classId, userType));
    if (cb) cb(null);
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsFormModalOpen(false));
    dispatch(setIsStreamLoading(false));
  }
};

export const updatePost =
  (
    classID,
    activityID,
    data,
    fileIDs,
    files,
    oruiID,
    aouiID,
    students,
    userType,
    callback,
  ) =>
  async dispatch => {
    dispatch(setIsStreamLoading(true));
    try {
      if (data) {
        await streamApi.updatePost(activityID, data);
      }

      if (fileIDs) {
        await streamApi.removeFilesFromPost({
          fileIDs,
        });
      }

      if (files) {
        await streamApi.addFilesToPost(activityID, files);
      }

      if (students) {
        await streamApi.updateStudentsOfPost(aouiID, {
          oruiID,
          students,
        });
      }
      dispatch(fetchAllStream(classID, userType));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsStreamLoading(false));
    }
  };

export const fetchClassDetails = (id, cb) => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await streamApi.fetchClassDetails(id);
    dispatch(setClassDetails(response.data.unitDetails));
    dispatch(
      setClassStudents(
        response.data.unitDetails.students.map(student => student.profile),
      ),
    );

    if (cb) cb(response.data.classDetails?.oay_id_orgacadyear);
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const streamSelector = state => state.streamNew;

export default streamSlice.reducer;
