import styled from 'styled-components';

export const AttendenceContainer = styled.div`
  min-height: 100vh;
  background-color: #f5f5f5;
  margin: 0rem -0.9375rem;
`;

export const AttendenceCardListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  margin-left: -0.8rem;
  .container-card {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin: 13rem 0rem 9rem 0rem;
    .card-container {
      width: 100%;
      padding: 0rem 3.5rem 2rem 0;
    }
    .alphabet-list {
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 1000;
      padding: 0rem 0.8rem 0rem 1rem;
      margin-top: 1rem;
      height: 58vh;
      overflow-y: scroll;
      button {
        text-decoration: none;
        color: #9ea3a5;
        background-color: transparent;
        border: unset;
        outline: unset;
        font-weight: bold;
        font-size: 10px;
        padding: 0.25rem;
        cursor: pointer;
        :focus {
          color: #0c181f !important;
        }

        &.scrolled {
          color: #0c181f;
        }
      }
    }
    [class*='col-'] {
      padding: 0 0.75rem 0 0.75rem;
      @media (max-width: 32rem) {
        & {
          padding: 0 0 0 0.75rem !important;
        }
      }
    }

    @media (max-width: 32rem) {
      & {
        margin: 20rem 0rem 9rem 0rem !important;
      }
      .alphabet-list {
        margin: 6rem 0rem 9rem 0rem;
        height: 20vh;
        overflow-y: scroll;
      }
    }
    @media (max-width: 60rem) {
      & {
        margin: 15rem 0rem 9rem 0rem;
      }
      .alphabet-list {
        margin: 1rem 0rem 9rem 0rem;
        height: 50vh;
        overflow-y: scroll;
      }
    }
    @media (max-width: 40rem) {
      & {
        margin: 16rem 0rem 9rem 0rem;
      }
      .alphabet-list {
        margin: 1rem 0rem 9rem 0rem;
        height: 35vh;
        overflow-y: scroll;
      }
    }
  }
`;
