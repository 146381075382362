import styled from 'styled-components';

export const FormSectionContainer = styled.div`
  .evaluation-title {
    display: flex;
    justify-content: space-between;
    .evaluation-type {
      width: 20%;
    }
    .task-title {
      width: 80%;
      margin-left: 1rem;
    }
  }
  @media(max-width:56rem){
    .evaluation-title {
    flex-direction:column;
    .evaluation-type {
      width: 100%;
    }
    .task-title {
      width: 100%;
      margin-left: unset;
      margin-bottom:1rem;
    }
  }
`;
