import axios from '../index';

export const fetchActiveClasses = () => axios.get('/api/class/active');

export const fetchActiveClassesOfStudent = () =>
  axios.get('/api/class/getUnitsForStudent');

export const fetchOrgClasses = academicYearId =>
  axios.get(`/api/class/${academicYearId}`);

export const fetchAcademicYears = () => axios.get('/api/org/academic_year');

export const fetchAllAssessments = () => axios.get('/api/class/assessments');

export const createAssessmentByTitle = (unitId, title) =>
  axios.post(`api/class/assessment/create/${unitId}?title=${title}`);

export const updateAssessment = (activityId, data) =>
  axios.post(`api/class/assessment/${activityId}/update`, data);

export const getUnits = () => axios.get('/api/class/add-assessment/getunits');

export const fetchAllActivityMethods = () =>
  axios.get(`api/class/assessment/activity-method`);

export const fetchAllEvaluationType = () =>
  axios.get(`api/class/assessment/evaluation-type`);

export const getPerformanceCriteria = unitId =>
  axios.get(`/api/class/${unitId}/add-assessment/assessment-map`);

export const getPerformanceEvidence = unitID =>
  axios.get(
    `/api/class/${unitID}/add-assessment/assessment-map/performance-evidence`,
  );

export const getKnowledgeEvidence = unitID =>
  axios.get(
    `/api/class/${unitID}/add-assessment/assessment-map/knowledge-evidence`,
  );

export const getAssessmentCondition = unitID =>
  axios.get(
    `/api/class/${unitID}/add-assessment/assessment-map/assessment-condition`,
  );

export const getAssessmentDatas = activityId =>
  axios.get(`api/class/add-assessment/${activityId}/getDetails`);

export const getDraftTask = activityID =>
  axios.get(`api/class/${activityID}/assessment/getDraftTask`);

export const removeAllInstructions = taskID =>
  axios.delete(`api/class/${taskID}/assessment/removeAllInstructions`);

export const updateTask = (taskID, data) =>
  axios.put(`api/class/${taskID}/assessment/updateTask`, data);

export const addFileInstruction = (activityID, taskID, body) =>
  axios.post(
    `api/class/${activityID}/${taskID}/assessment/addFileInstruction`,
    body,
  );

export const addTextInstruction = (activityID, taskID, textInstruction) =>
  axios.post(
    `api/class/${activityID}/${taskID}/assessment/addTextInstruction`,
    {
      textInstruction,
    },
  );
export const updateInstruction = (fileID, data) =>
  axios.put(`api/class/${fileID}/assessment/updateInstruction`, data);

export const deleteAssessmentTask = activityItemId =>
  axios.delete(`api/class/${activityItemId}/assessment/deleteTask`);

export const removeInstruction = fileID =>
  axios.delete(`api/class/${fileID}/assessment/removeInstruction`);

export const deleteAssessment = activityId =>
  axios.delete(`api/class/assessment/${activityId}/delete`);
