import styled from 'styled-components';

export const FilterListContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  .filter-tag {
    padding: 0.5rem;
    background: #e0eeff;
    border-radius: 3px;
    margin-right: 0.75rem;
    font-weight: 500;
    font-size: 14px;
    color: #40a1ff;
    button {
      outline: unset;
      border: unset;
      background-color: transparent;
      img {
        vertical-align: middle;
        margin-left: 0.5rem;
      }
    }
  }
`;
