/* eslint-disable react/prop-types */

import {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {motion, AnimatePresence} from 'framer-motion';
import {useForm} from 'react-hook-form';
import {useClickAway} from 'react-use';

import CloseSidebarIcon from '../../../../../../assets/icons/close-modal.svg';
import {EditDocumentContainer} from './elements';
import {EditForm, Footer} from './components';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {
  setError as setServerError,
  teacherLibrarySelector,
  updateLibraryDocument,
} from '../../../../../../redux/teacherLibrarySlice';
import {showToast} from '../../../../../../components/common/Toast';
import UpdateIcon from '../../../../../../assets/icons/updateadmintoast.svg';

const FIELDS_IN_ORDER = [
  'documentTitle',
  'fileCode',
  'fileCategory',
  'publicLibrary',
];

export const EditDocument = ({
  isVisible,
  onClose,
  editDocument,
  isPublic,
  onSuccess,
}) => {
  const {
    error: serverError,
    orgUnitInstance,
    isTeacherLibraryLoading,
    formError,
    category,
  } = useSelector(teacherLibrarySelector);
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    getValues,
    setValue,
    watch,
    register,
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      documentTitle: '',
      fileCode: '',
      fileCategory: null,
      publicLibrary: false,
    },
  });

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadCoverImage, setUploadCoverImage] = useState(null);
  const [isEditCoverImage, setIsEditCoverImage] = useState(false);
  const [selectCategory, setSelectCategory] = useState(null);
  const categoryDropdown = useRef();
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });
  useEffect(() => {
    setUploadDocument({
      type: editDocument?.fileType?.ft_type,
      name: editDocument?.fi_filename,
      location: editDocument?.fi_location,
    });
    if (editDocument?.fi_thumbnail) {
      setUploadCoverImage({
        type: 'image',
        name: editDocument?.fi_filename,
        location: editDocument?.fi_cover_image
          ? editDocument?.fi_cover_image
          : editDocument?.fi_thumbnail,
      });
    }
    setSelectCategory([
      {
        name: editDocument?.fileCategory?.fc_name,
        id: editDocument?.fileCategory?.fc_id_filecategory,
      },
    ]);
    setValue('documentTitle', editDocument?.fi_text_content);
    setValue('fileCode', editDocument?.fi_code);
    setValue('fileCategory', {
      id: editDocument?.fileCategory?.fc_id_filecategory,
      name: editDocument?.fileCategory?.fc_name,
    });
  }, [editDocument, setValue]);

  const setFieldError = (fieldName, errorMessage) => {
    setError(fieldName, {message: errorMessage});
  };

  const orgUnitInstanceId = orgUnitInstance?.orui_id_orgunitinstance;

  const onSubmit = data => {
    if (!data?.documentTitle) {
      setFieldError('documentTitle', 'Enter Document Title');
    }
    if (!data?.fileCode) {
      setFieldError('fileCode', 'Enter Code');
    }

    if (!data?.fileCategory) {
      setFieldError('fileCategory', 'Select the Category');
    }

    const formData = new FormData();

    if (data?.documentTitle || data?.fileCode || data?.fileCategory) {
      formData.append('documentTitle', data?.documentTitle);

      formData.append('fileCode', data?.fileCode);

      formData.append('category', data?.fileCategory?.id);

      if (isEditCoverImage) {
        if (uploadCoverImage) {
          formData.append(
            'uploadCoverImage',
            uploadCoverImage,
            uploadCoverImage?.name,
          );
        }
      }
      formData.append('isEditCoverImage', isEditCoverImage);

      if (
        !isTeacherLibraryLoading &&
        !formError?.fileCode?.length &&
        data?.fileCategory !== ''
      ) {
        dispatch(
          updateLibraryDocument(
            orgUnitInstanceId,
            editDocument?.fi_id_file,
            formData,
            () => {
              showToast(UpdateIcon, 'Document updated successfully', false);
              setUploadDocument(null);
              setUploadCoverImage(null);
              onSuccess();
              onClose();
            },
          ),
        );
      }
    }
  };

  useEffect(() => {
    getValues('documentTitle');
    getValues('fileCode');
    getValues('fileCategory');
  }, [getValues]);

  const categoryAsOptions = useMemo(
    () =>
      category?.map(item => ({
        name: `${item?.fc_name}`,
        id: item?.fc_id_filecategory,
      })) || [],

    [category],
  );

  useEffect(() => {
    const bodyScrollBar = document.body;
    bodyScrollBar.style.overflow = 'hidden';
  }, []);

  return (
    <AnimatePresence>
      {isVisible ? (
        <EditDocumentContainer
          className="d-flex justify-content-end position-fixed"
          animate={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          exit={{
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          }}
          initial={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          transition={{
            duration: 0.4,
            type: 'keyframes',
          }}
        >
          <motion.aside
            className="sidebar"
            animate={{
              x: '0',
            }}
            exit={{
              x: '100%',
            }}
            initial={{
              x: '100%',
            }}
            transition={{
              duration: 0.4,
              type: 'keyframes',
            }}
            ref={ref}
          >
            <header className="header-title align-items-center d-flex justify-content-between mb-4 fixed-top">
              <div className="sidebar-head d-flex">
                <div className="sidebar-title">Edit a File</div>
                {!isPublic ? (
                  <div className="class-name">{`${editDocument?.orgUnitInstance?.class?.oc_class} - ${editDocument?.orgUnitInstance?.orgUnit?.unit?.tun_title}`}</div>
                ) : null}
              </div>
              <button
                className="close-sidebar-button"
                onClick={onClose}
                type="button"
                id="close-btn"
              >
                <img alt="Close Sidebar" src={CloseSidebarIcon} />
              </button>
            </header>
            <EditForm
              uploadDocument={uploadDocument}
              uploadCoverImage={uploadCoverImage}
              setUploadCoverImage={setUploadCoverImage}
              onClose={onClose}
              register={register}
              setValue={setValue}
              categoryDropdown={categoryDropdown}
              errors={errors}
              categoryAsOptions={categoryAsOptions}
              control={control}
              formError={formError}
              watch={watch}
              setSelectCategory={setSelectCategory}
              selectCategory={selectCategory}
              isPublic={isPublic}
              classAndUnitName={`${editDocument?.orgUnitInstance?.class?.oc_class} - ${editDocument?.orgUnitInstance?.orgUnit?.unit?.tun_title}`}
              setIsEditCoverImage={setIsEditCoverImage}
              isEditCoverImage={isEditCoverImage}
              editDocument={editDocument}
              orgUnitInstanceId={orgUnitInstanceId}
            />
            <Footer
              onButtonCancel={onClose}
              onSubmit={handleSubmit(onSubmit)}
              isDisabled={formError?.fileCode}
              isadminLibraryLoading={isTeacherLibraryLoading}
            />
          </motion.aside>
        </EditDocumentContainer>
      ) : null}
    </AnimatePresence>
  );
};
