import styled from 'styled-components';

const EntryRequirementsFormContainer = styled.div`
  .label-column {
    width: 60%;
  }

  .input-column {
    width: 20%;

    &.is-last {
      padding-left: 0.75rem;
    }
  }
`;

export default EntryRequirementsFormContainer;
