import styled from 'styled-components';

export const AddUnitsToQualificationFooter = styled.div`
  @media (max-width: 37.5rem) {
    & {
      width: 100vw;
    }
  }
  .footer-seperator {
    height: 0.0625rem;
    background-color: #e0e0e0;
    width: 100%;
    display: none;
  }
  .add-unit-footer {
    padding: 1.5rem 3rem 1.5rem 3rem;
    width: 100%;
    background-color: #ffffff;
    .add-unit-title {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      .add-unit-name {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.375rem;
      }
    }
    .action-button {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @media (max-width: 46.875rem) {
      & {
        padding: 1.5rem 0 1.37rem 0;
      }

      .progress-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;
        padding: 0 1.25rem;
        justify-content: center;
        p {
          font-size: 0.875rem;
          color: #3d4457;
          margin-right: 0.625rem;
          margin-bottom: unset;
        }
        .progress-bar {
          width: 15.93rem;
        }
      }
      .add-unit-spread {
        display: flex;
        flex-direction: column-reverse;
        .button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 1.25rem;
          .action-button {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        .footer-seperator {
          margin-top: 1.5rem;
          display: block;
        }
      }
      .add-unit-title {
        display: flex;
        flex-direction: column-reverse;
        .button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 1.25rem;
          .action-button {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
      .footer-seperator {
        margin-top: 1.5rem;
        display: block;
      }
    }
    @media (max-width: 25rem) {
      .progress-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin-bottom: 0.625rem;
        }
      }
    }
    .add-unit-spread {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-unit-name {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.37rem;
      }
    }
    .add-unit-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .progress-bar {
      width: 19.8rem;
      height: 0.5rem;
      background-color: #dedede;
      border-radius: 0.5rem;
      .progress-loader {
        width: 2%;
        height: 100%;
        background-color: #40a1ff;
        &.progress-loader-1 {
          width: 50%;
        }
        &.progress-loader-2 {
          width: 100%;
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .save-changes {
        margin-right: 0.25rem;
      }
      .cancel {
        margin-right: 1rem;
        border: 0.0625rem solid transparent;
        border-radius: 0.5rem;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
      .cancel1 {
        margin-right: 1rem;
        border: 0.0625rem solid #dedede;
        border-radius: 0.5rem;
        align-items: flex-end;
        .cancel1-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
    }
  }
  .skip-this-step {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.3125rem;
    padding: 0.5rem 0.75rem;
    color: #80d5ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
  }
`;
