/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {useContext} from 'react';
import {LiveSessionContext} from '../../../../contexts/LiveSessionContext';
import SessionPeerVideo from '../SessionPeerVideo';
import {VideoGridWrapper} from './elements';

const VideoGrid = ({
  remoteUsers,
  localVideoTrack,
  localAudioTrack,
  speakingStates,
}) => {
  const {userId} = useContext(LiveSessionContext);
  const noOfVideos = localVideoTrack
    ? remoteUsers.length + 1
    : remoteUsers.length;
  return (
    <VideoGridWrapper>
      {noOfVideos === 0 && <div>No one has joined yet</div>}
      {localVideoTrack && (
        <SessionPeerVideo
          key={localVideoTrack.getTrackId()}
          videoTrack={localVideoTrack}
          audioTrack={localAudioTrack}
          speakingStates={speakingStates}
          uid={userId}
        />
      )}
      {remoteUsers.map(remoteUser => (
        <SessionPeerVideo
          key={remoteUser.uid}
          uid={remoteUser.uid}
          audioTrack={remoteUser.audioTrack}
          videoTrack={remoteUser.videoTrack}
          speakingStates={speakingStates}
        />
      ))}
    </VideoGridWrapper>
  );
};

VideoGrid.defaultProps = {
  remoteUsers: [],
  localVideoTrack: null,
  localAudioTrack: null,
  speakingStates: {},
};

VideoGrid.propTypes = {
  remoteUsers: PropTypes.arrayOf(PropTypes.object),
  localVideoTrack: PropTypes.objectOf(PropTypes.any),
  localAudioTrack: PropTypes.objectOf(PropTypes.any),
  speakingStates: PropTypes.objectOf(PropTypes.any),
};

export default VideoGrid;
