import styled from 'styled-components';

export const AssessmentDetailsContainer = styled.main`
  margin: 0rem auto;
  width: 80%;

  margin-bottom: 8rem;
  @media (max-width: 60rem) {
    & {
      width: 95%;
    }
  }
  @media (max-width: 50rem) {
    & {
      width: 95%;
    }
  }
  @media (max-width: 43.75rem) {
    & {
      width: 92%;
    }
  }
  @media (max-width: 33rem) {
    & {
      width: 92%;
    }
  }
  .assessment-task-header {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 1.5rem 0 0 0;
    color: #3d4457;
    display: flex;
    align-items: center;
  }
  .task-length {
    padding: 0.05rem 0.5rem;
    border-radius: 50%;
    background-color: #e5e5e5;
    margin: 0 0 0 0.25rem;
    font-size: 0.875rem;
    text-align: center;
    color: #6d7579;
  }
`;

export const AssessmentTasksContainer = styled.div``;
