/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactBootstrapModal from 'react-bootstrap/Modal';

import {PortFolioFilesCardContainer, FileContainer} from './elements';
import PlayVideoIcon from '../../../../assets/icons/play-video-circle.svg';
import DocIcon from '../../../../assets/images/filetypes/doc-image.svg';
import VideoIcon from '../../../../assets/images/filetypes/video-image.svg';
import AddedVoiceInstructionIcon from '../../../../assets/images/filetypes/voice-image.svg';
import {FilePreview} from './components';

export const PortFolioFilesCard = ({
  className,
  width,
  height,
  fileName,
  uploadedDate,
  fileLocation,
  fileType,
  thumbnail,
  onClick,
  data,
  deleteOnClick,
  editOnClick,
  fileTitle,
  isPublic,
  fileCode,
  unitAndClassName,
  isAdmin,
  fileCategory,
  isStudent,
  fileItem,
  isArchive,

  ...rest
}) => {
  let title;
  let fileUploadedDate;
  let thumbnailImg;
  const [viewDocument, setViewDocument] = useState(false);
  const fileExt = fileName?.substring(fileName?.lastIndexOf('.') + 1) || '';
  if (fileType === 'Voice') {
    thumbnailImg = thumbnail || AddedVoiceInstructionIcon;
    title = fileName;
    fileUploadedDate = moment(uploadedDate)
      .format('D MMM YYYY, h:mm A')
      .replace(/:00/g, '');
  } else if (fileType === 'Document') {
    thumbnailImg = thumbnail || DocIcon;
    title = fileName;
    fileUploadedDate = moment(uploadedDate)
      .format('D MMM YYYY, h:mm A')
      .replace(/:00/g, '');
  } else if (fileType === 'Image') {
    thumbnailImg = fileLocation;
    title = fileName;
    fileUploadedDate = moment(uploadedDate)
      .format('D MMM YYYY, h:mm A')
      .replace(/:00/g, '');
  } else if (fileType === 'Video') {
    thumbnailImg = thumbnail || VideoIcon;
    title = fileName;
    fileUploadedDate = moment(uploadedDate)
      .format('D MMM YYYY, h:mm A')
      .replace(/:00/g, '');
  }
  title = title?.replace(`.${fileExt}`, '');
  return (
    <>
      <PortFolioFilesCardContainer
        className={classNames('flex-shrink-0', className)}
        onClick={() => {
          setViewDocument(true);
        }}
        {...rest}
      >
        <FileContainer height={height}>
          <div className="card-content-top">
            <div className="card-detail-top">
              <div className="card-left">{title}</div>
              <div className="class-name-div">
                <div className="class-name">{unitAndClassName}</div>
              </div>
            </div>
          </div>
          <button className="file-content" onClick={onClick} type="button">
            <div className="file-container">
              {fileType === 'Video' ? (
                <div className="file-blob">
                  <img
                    alt="Instruction"
                    className="thumnail-icon"
                    src={thumbnailImg}
                  />
                  <div className="play-video-icon">
                    <img
                      alt="Play Video"
                      src={PlayVideoIcon}
                      className="play-video"
                    />
                  </div>
                  <div className="badge">
                    <span>{fileType}</span>
                  </div>
                </div>
              ) : (
                <div className="file-blob">
                  <img
                    src={thumbnailImg}
                    alt=""
                    className="files"
                    width="100%"
                  />
                  <div className="badge">
                    <span>{fileType}</span>
                  </div>
                </div>
              )}
            </div>
          </button>
          <div className="card-content">
            <div className="card-detail-bottom">
              <div className="date">{fileUploadedDate}</div>
            </div>
          </div>
        </FileContainer>
      </PortFolioFilesCardContainer>
      {viewDocument && data ? (
        <ReactBootstrapModal
          centered
          onHide={() => {}}
          show={viewDocument}
          contentClassName="preview-post-file-modal"
        >
          <FilePreview
            onClose={() => {
              setViewDocument(false);
            }}
            previewFileData={fileItem}
            fileUrl={fileItem?.fi_location}
            title={title}
            unitAndClassName={unitAndClassName}
            fileType={fileItem?.fileType?.ft_type}
          />
        </ReactBootstrapModal>
      ) : null}
    </>
  );
};
PortFolioFilesCard.defaultProps = {
  className: '',
  height: '',
  width: '',
  thumbnail: '',
  onClick: () => {},
  deleteOnClick: () => {},
  editOnClick: () => {},
  data: {},
  isPublic: false,
  unitAndClassName: '',
  isAdmin: false,
  fileCategory: '',
  fileName: '',
  uploadedDate: '',
  fileLocation: '',
  fileType: '',
  fileTitle: '',
  fileCode: '',
  isStudent: false,
  isArchive: false,
};

PortFolioFilesCard.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fileName: PropTypes.string,
  uploadedDate: PropTypes.string,
  fileLocation: PropTypes.string,
  fileType: PropTypes.string,
  thumbnail: PropTypes.string,
  onClick: PropTypes.func,
  deleteOnClick: PropTypes.func,
  editOnClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  fileTitle: PropTypes.string,
  fileCode: PropTypes.string,
  unitAndClassName: PropTypes.string,
  isPublic: PropTypes.bool,
  isAdmin: PropTypes.bool,
  fileCategory: PropTypes.string,
  isStudent: PropTypes.bool,
  isArchive: PropTypes.bool,
};
