/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';

import {StudentAssessmentHeaderContainer} from './elements';
import {AssessmentStatus} from './component';

export const StudentAssessmentHeader = ({
  publicationDate,
  description,
  linkToView,
  assessment,
}) => {
  // const history = useHistory();

  const dateOfPublished = moment(publicationDate).fromNow();

  return (
    <StudentAssessmentHeaderContainer>
      <div className="header-top">
        <div className="header-left">
          <div>
            {!assessment?.up_avatar ? (
              <span className="align-items-center circle d-flex justify-content-center student">
                {assessment?.up_name_first[0]}
                {assessment?.up_name_last[0]}
              </span>
            ) : (
              <img
                alt="Assessment Icon"
                className="header_t-icon rounded-circle"
                src={assessment?.up_avatar}
              />
            )}
          </div>
          <div>
            <div className="user-details">
              <h2 className="teacher-name">{`${assessment?.up_name_first} 
            ${assessment?.up_name_last}`}</h2>
            </div>
            <p className="username">
              {`@${assessment?.up_name_first} `}•{' '}
              <span className="activity-type">
                {assessment?.at_activitytype}
              </span>
            </p>
          </div>
        </div>

        <div className="header-right">
          <span>{dateOfPublished}</span>
        </div>
      </div>

      <div className="header-middle">
        <span className="assessment-name">{assessment?.ac_activity_name}</span>
        <p className="header-description">{description}</p>
      </div>
      {/* <div className="header-last">
        <div className="outline-container">
          <div className="outline-left">
            <div>
              <p>{`${
                assessment?.tasks?.length > 9
                  ? assessment?.tasks?.length
                  : `0${assessment?.tasks?.length}`
              }`}</p>
              <span>Tasks</span>
            </div>
            <div className="time">
              <p>{`${assessment?.ac_maximum_duration_hours}.${assessment?.ac_maximum_duration_minutes}hrs`}</p>
              <span>Time</span>
            </div>
          </div>
          <div>
            <Link className="view-assessment" to={linkToView}>
              View Assessment{' '}
              <img alt="View" className="body_a-icon" src={ArrowIcon} />
            </Link>
          </div>
        </div>
      </div> */}
      <AssessmentStatus
        assessment={assessment}
        linkToView={linkToView}
        assessmentStatus={assessment?.aouis_status}
      />
    </StudentAssessmentHeaderContainer>
  );
};
