import styled from 'styled-components';

export const ViewCurriculumMainContainer = styled.div`
  padding: 0.5rem 1.5rem 4.0625rem 2rem !important;
  @media (max-width: 55.5rem) {
    & {
      padding: 0.5rem 1.5rem 4.0625rem 2rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 0.5rem 1.5rem 4.0625rem 1.5rem !important;
    }
  }
`;
