/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {Color} from '../../../constants';

export const DropdownBtn = styled.div`
  .btn {
    outline: none;
    padding: 0px;
    box-shadow: unset;
    :focus {
      background: #fafafa;
      box-shadow: unset;
      outline: none;
    }
  }
`;
export const AddUnitsHeader = styled.div`
  margin: 0px -15px;
  .add-unit-header {
    width: 100%;
    background-color: #ffffff;
    padding: 24px 48px 24px 48px;
    .back-button {
      margin-bottom: 16px;
    }
    @media (max-width: 500px) {
      & {
        padding: 24px;
      }
    }

    .add-unit-title {
      display: flex;
      justify-content: space-between;
      .add-unit-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
  }
`;
export const AddUnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -15px;
  padding-top: 32px;
  background-color: #f4f5f8;

  .container-unit {
    padding: 0 15px;
    flex-shrink: 0;
    width: 55.5rem;
    @media (max-width: 55.5rem) {
      & {
        width: 100%;
        overflow-y: scroll;
      }
    }
    @media (max-width: 790px) {
      .type-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
      }
    }
  }
  .seperator-table {
    width: 100%;
    height: 1px;
    background-color: #c9c9c9;
  }
  .unitheading {
    display: flex;
    flex-direction: column;
    .main-text {
      color: #3d4457;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 8px;
    }
    .sub-text {
      font-size: 14px;
      color: #8a8c94;
      /* margin-bottom: 8px; */
    }
  }
  .custombox {
    @media (max-width: 700px) {
      & {
        width: 100%;
        margin: 20px 0;
      }
      .add-unit-modal-body {
        display: flex;
        flex-direction: column;
        .div-fields1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .date-wrapper1 {
            display: flex;
            flex-grow: unset;
            margin-bottom: 1rem;
            width: 100%;
          }
          .date-wrapper2 {
            flex-grow: unset;
            margin-left: unset;
            margin-bottom: 1rem;
            width: 100%;
          }
          .date-wrapper3 {
            flex-grow: unset;
            margin-left: unset;
            width: 100%;
          }
        }
      }
    }
    .unit-type-title {
      color: #3d4457;
      margin-bottom: 9px;
    }
    .rbt input {
      height: 46px;
      border-radius: 5px;
      border: 1px solid #e0e0e0;
      :active,
      :focus {
        border-color: ${Color.PRIMARY};
        box-shadow: 0 0 0 2px ${Color.LIGHT_BLUE};
        outline: none;
        transition: border-color 0.1s, box-shadow 0.1s;
      }
      ::placeholder {
        font-size: 14px;
        line-height: 18px;
        color: #c0c0c0;
      }
    }
    .dropdown-item {
      padding: 10px 24px;
    }
    .add-unit-custom-box {
      .add-unit-title {
        font-weight: 500;
        font-size: 20px;
        color: #3d4457;
      }

      .add-unit-header {
        margin-top: 24px;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #aaaaaa;
        .add-unit-header-title {
          margin: 0px;
        }

        span {
          font-weight: 500;
          color: #3d4457;
          margin-left: 11px;
        }
      }
      .add-unit-row {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        font-size: 14px;
        line-height: 17px;
        color: #aaaaaa;
        .add-unit-col {
          width: 49%;
          position: relative;

          .teacher-row {
            display: flex;
            .profile-img {
              /* margin-left: -15px; */
              margin-right: 12px;
            }
            .profile-name {
              margin-top: 3px;
              p {
                margin-bottom: 0.5rem;
              }
              .ref-id {
                color: #b8bac7;
              }
            }
          }

          .typehead-title {
            margin-bottom: 9px;
            color: #3d4457;
          }
          input {
            border-radius: 8px;
            ::placeholder {
              font-size: 14px;
              line-height: 18px;

              color: #c0c0c0;
            }
          }
          .units-field {
            border-radius: 8px;
            height: 42px;
          }

          .rbt input {
            height: 42px;
            border-radius: 8px;
            border: 1px solid #e0e0e0;
            .rbt-input-main form-control rbt-input {
            }
            :active,
            :focus {
              border-color: ${Color.PRIMARY};
              box-shadow: 0 0 0 2px ${Color.LIGHT_BLUE};
              outline: none;
              transition: border-color 0.1s, box-shadow 0.1s;
            }
            ::placeholder {
              font-size: 14px;
              line-height: 18px;

              color: #c0c0c0;
            }
          }
          .dropdown-item {
            padding: 16px 24px;
          }

          .openDatepicker {
            background-color: transparent;
            outline: none;
            border: none;
          }
        }
      }
    }
    .code {
      font-size: 0.875rem;
      color: #8a8c94;
    }
    .type-container {
      display: flex;
      flex-direction: row;
    }

    .unit-type {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      align-items: center;

      .radioButton {
        width: 20px;
        height: 20px;
        outline: unset;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: unset;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        img {
          vertical-align: unset;
        }
      }

      .text-button {
        margin-left: 8px;
        outline: unset;
        border: unset;
        background-color: unset;
        font-size: 14px;
        color: #3d4457;
        margin-right: 24px;
      }
      .text-button1 {
        margin-left: 8px;
        outline: unset;
        border: unset;
        background-color: unset;
        font-size: 14px;
        color: #3d4457;
      }
    }
    .core {
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 16px;
      background: #ecf6ff;
      text-align: center;
      text-transform: uppercase;
      color: #54abff;
      border-radius: 5px;
    }
    .elective {
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 16px;
      background: #defae8;
      text-align: center;
      text-transform: uppercase;
      color: #4ec977;
      border-radius: 5px;
    }
    .add-button {
      text-align: center;
    }
    .count-units {
      color: #aaaaaa;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }
    .top-border {
      background-color: #c9c9c9;
      height: 1px;
      width: 100%;
    }
    .manual-table {
      height: 300px;
      width: 100%;
    }
    @media (max-width: 600px) {
      .add-unit-custom-box {
        .add-unit-row {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-top: unset;

          .unit-class-name {
            margin-bottom: 8px;
          }
          .add-unit-col {
            width: 100%;
            padding-top: 10px;
          }
        }
      }
    }
    .loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .please {
        font-size: 14px;
        color: #888888;
      }
      .header {
        font-size: 24px;
        text-align: center;
      }
      .state {
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        width: 500px;
        @media (max-width: 500px) {
          & {
            width: 300px;
          }
        }
        text-align: center;
      }
    }
    .search-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
      padding: 24px 0px 0px 0px;
      .buttom {
        margin-right: 40px;
        border: none;
        background-color: unset;
        outline: none;
        color: #bcbcbc;
        &.link {
          border-bottom: 1px solid black;
          color: #3d4457;
        }
      }
    }

    .search-div-spread {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 0px 24px 0px;
      .imported {
        padding: 0;
        margin: 0;
        color: #3d4457;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }
      .imported-file {
        margin: 0;
        padding: 0;
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }

    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }

    .threeDotIcon {
      border: none;
      background: transparent;
      outline: none;
    }
  }

  .add-unit-modal {
    width: 100%;
  }
  .add-unit-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .div-fields1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .date-wrapper1 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-right: 8px;
      }
      .date-wrapper2 {
        flex-grow: 1;
        width: 25%;
        margin-left: 8px;
        margin-right: 8px;
      }
      .date-wrapper3 {
        width: 40%;
        flex-grow: 1;
        margin-left: 8px;
      }
      p {
        color: #3d4457;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 24px 0 24px 24px;
    .save-changes {
      border: 1px solid #40a1ff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      .add-button-text {
        color: #40a1ff;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .cancel {
      margin-right: 16px;
      border: 1px solid transparent;
      border-radius: 8px;
      align-items: flex-end;
      .cancel-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .logo-label {
    color: #aaa;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .org-image-full {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    align-items: center;
    border: 1px dashed #dedede;
    padding: 16px;
    justify-content: space-between;
    border-radius: 5px;
    .org-image-after {
      display: flex;
      flex-direction: row;
      .file-details {
        display: flex;
        padding-left: 16px;
        flex-direction: column;

        .file-name {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #3d4457;
        }
        .file-size {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          padding-top: 8px;
          color: #aaaaaa;
        }
      }
      img {
        object-fit: contain;
        width: 40px;
        height: 48px;
      }
    }
    .delete-logo {
      button {
        border: none;
        background-color: transparent;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .org-size {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    span {
      color: #adadad;
    }
    a {
      color: #40a1ff;
      :hover {
        color: #40a1ff;
        text-decoration: none;
      }
    }
  }
  .error-size {
    color: red;
  }

  .inputfields {
    padding: 24px 0px;
    .logo-label {
      color: #aaa;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .org-image-full {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      align-items: center;
      border: 1px dashed #dedede;
      padding: 16px;
      justify-content: space-between;
      border-radius: 5px;
      .org-image-after {
        display: flex;
        flex-direction: row;
        .file-details {
          display: flex;
          padding-left: 16px;
          flex-direction: column;

          .file-name {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #3d4457;
          }
          .file-size {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            padding-top: 8px;
            color: #aaaaaa;
          }
        }
        img {
          object-fit: contain;
          width: 40px;
          height: 48px;
        }
      }
      .delete-logo {
        button {
          border: none;
          background-color: transparent;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .org-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 24px 24px;
      background: #ffffff;
      border: 1px dashed #dedede;
      box-sizing: border-box;
      border-radius: 5px;
      .org-logo {
        padding: 32px 0px 0px 0px;
      }
      &.logo-drag {
        background: #f7fbff;
        border-color: #40a1ff;
      }
      .org-text {
        padding: 12px 0 0px 0;
        text-align: center;
        position: relative;
        overflow: hidden;
      }
      .org-seperator {
        margin-top: 36px;
        width: 300px;
        height: 1px;
        background-color: #eaeaea;
        margin-bottom: 10px;
      }

      .org-text input {
        position: absolute;
        color: black;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 200px;
      }
      span {
        color: #40a1ff;
      }
      .supports {
        padding: 6px 0px 0px 0px;
        color: #adadad;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .org-size {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      span {
        color: #adadad;
      }
      a {
        color: #40a1ff;
        :hover {
          color: #40a1ff;
          text-decoration: none;
        }
      }
    }
    .error-size {
      color: red;
    }
  }
  .choose-qualification {
    color: #3d4457;
    font-weight: 400;
    font-size: 14px;
  }

  .accordion {
    width: 100%;
    border-radius: 8px;
    .card-header {
      background-color: #ffffff;
      padding: 0.4rem 0px;
      @media (max-width: 500px) {
        & {
          padding: 0.3rem 0px;
        }
      }
    }
    .btn {
      line-height: 1;
      color: #3d4457;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }
    }
  }
`;
export const AddUnitsFooter = styled.div`
  bottom: 0;
  height: 62px;
  margin-top: 100px;
  width: 100vw;

  @media (max-width: 600px) {
    & {
      width: 100vw;
    }
  }
  .footer-seperator {
    height: 1px;
    background-color: #e0e0e0;
    width: 100%;
    display: none;
  }
  .add-unit-footer {
    padding: 24px 48px 24px 48px;
    width: 100%;
    background-color: #ffffff;
    .add-unit-title {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      .add-unit-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
    .action-button {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @media (max-width: 750px) {
      & {
        padding: 24px 0px 22px 0px;
      }

      .progress-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        padding: 0 20px;
        justify-content: center;
        p {
          font-size: 14px;
          color: #3d4457;
          margin-right: 10px;
          margin-bottom: unset;
        }
        .progress-bar {
          width: 255px;
        }
      }
      .add-unit-spread {
        display: flex;
        flex-direction: column-reverse;
        .button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          .action-button {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
        .footer-seperator {
          margin-top: 24px;
          display: block;
        }
      }
      .add-unit-title {
        display: flex;
        flex-direction: column-reverse;
        .button-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          .action-button {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }
      .footer-seperator {
        margin-top: 24px;
        display: block;
      }
    }
    @media (max-width: 400px) {
      .progress-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin-bottom: 10px;
        }
      }
    }
    .add-unit-spread {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-unit-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
    .add-unit-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .progress-bar {
      width: 318px;
      height: 8px;
      background-color: #dedede;
      border-radius: 8px;
      .progress-loader {
        width: 2%;
        height: 100%;
        background-color: #40a1ff;
        &.progress-loader-1 {
          width: 50%;
        }
        &.progress-loader-2 {
          width: 100%;
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .save-changes {
        margin-right: 4px;
      }
      .cancel {
        margin-right: 16px;
        border: 1px solid transparent;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .cancel1 {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel1-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .skip-this-step {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 5px;
    padding: 8px 12px;
    color: #80d5ff;
    font-weight: 500;
    font-size: 14px;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
  }
`;
export const SpreadSheetUnitTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 1.5rem 0;
  width: 100%;
  border-top: 0.0625rem solid #c9c9c9;

  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;
    @media (max-width: 790px) {
      td:last-child {
        display: flex !important;
        justify-content: space-between !important;
      }
    }

    .serial {
      width: 5%;
    }

    .unit-code {
      width: 20%;
    }

    .unit-name {
      width: 40%;
    }
    .unit-types {
      width: 35%;
    }
  }
`;
export const ManualUnitTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 1.5rem 0;
  width: 100%;
  border-top: 0.0625rem solid #c9c9c9;

  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;

    .serial {
      width: 5%;
    }

    .unit-code {
      width: 15%;
    }

    .unit-name {
      width: 35%;
    }
    .Qualification {
      width: 25%;
    }
    .unit-types {
      width: 15%;
    }
    .close {
      width: 5%;
    }
    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }
  }
`;
