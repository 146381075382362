import styled from 'styled-components';

export const AddAdminHeaderContainer = styled.div`
  margin: 0rem -0.9375rem;
  .add-admin-header {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 3rem 1.5rem 3rem;
    @media (max-width: 41.25rem) {
      & {
        width: 100%;
        padding: 1.5rem;
      }
    }
    .back-button {
      margin-bottom: 1rem;
    }

    .add-admin-title {
      display: flex;
      justify-content: space-between;
      .add-admin-name {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.375rem;
      }
    }
  }
`;
