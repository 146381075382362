/* eslint-disable react/prop-types */
import React from 'react';

import {useHistory} from 'react-router-dom';

import CloseImg from '../../../../../../../../../../assets/icons/addadminclose.svg';

import {AddQualificationUnitHeader} from './elements';

export const AddQualificationHeader = ({steps}) => {
  const history = useHistory();

  return (
    <AddQualificationUnitHeader>
      <div className="add-unit-header fixed-top">
        <div className="add-unit-title">
          <div>
            <span className="add-unit-name">
              {steps === 0 ? 'Add a Qualification' : 'Add a Qualification'}{' '}
            </span>
          </div>
          <div>
            <button
              className="btn"
              onClick={() => {
                history.replace(`/admin/qualifications`);
                // dispatch(setValidateQualificationError(''));
                // dispatch(setValidateUnitError(''));
              }}
              type="button"
              id="close-page-btn"
            >
              <img alt="Options" src={CloseImg} />
            </button>
          </div>
        </div>
      </div>
    </AddQualificationUnitHeader>
  );
};
