import styled from 'styled-components';

export const UnitsContainer = styled.div`
  .unit-code {
    width: 10%;
  }

  .unit-name {
    width: 30%;
  }

  .essential {
    width: 17%;
  }
  .teachers {
    width: 25%;
  }
  .assessment-count {
    width: 18%;
  }

  .core,
  .elective {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0.312rem;
  }
  .core {
    color: #54abff;
    background: #ecf6ff;
  }
  .elective {
    background: #defae8;
    color: #4ec977;
  }
  .teacher,
  .more {
    font-weight: normal;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    color: #3d4457;
    text-align: center;
    border-radius: 0.25rem;
    background: #ecf6ff;
  }
  .teacher {
    margin-right: 0.5rem;
  }
  .type-container {
    display: flex;
    flex-direction: row;
  }
  .teachers-container {
    display: flex;
    flex-direction: row;
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
  .add-button {
    height: 2.75rem;
  }
  .table-container {
    border-radius: unset;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  @media (max-width: 75rem) {
    .type-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .teachers-container {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      .teacher {
        margin: 0.5rem 0 0 0;
      }
      .more {
        margin: 0.5rem 0 0 0;
      }
    }
    .table-container {
      table {
        td {
          :last-child,
          :first-child {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
`;
