import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import CurrentTime from './CurrentTime';

import SessionBar from './SessionBar';
import TimeInterval from './TimeInterval';

// Hours from 12 AM to 12 PM in 24 hour format.
const TIME_INTERVALS = Array.from(new Array(25).keys());

const ScheduleContainer = styled.div`
  border: 0.0625rem solid #f4f5f8;
  border-radius: 0.5rem;
  height: 4.25rem;
  overflow: hidden;

  .schedule {
    cursor: grab;
    overflow: auto;

    &.is-dragging {
      cursor: grabbing;
    }
  }
`;

const Schedule = ({currentSessionIndex, isToday, sessions}) => {
  const isOnFirstRender = useRef(true);
  const scrollContainer = useRef();
  const [isDragging, setIsDragging] = useState(false);

  // Reset the flag when the timeline changes.
  useEffect(() => {
    if (isToday) {
      isOnFirstRender.current = true;
    }
  }, [isToday]);

  useEffect(() => {
    let hoursToStart;
    let minutesToStart;

    if (isToday && isOnFirstRender.current) {
      // Scroll to the current time indicator on the first render.
      const currentTime = moment().format('HH:mm');
      [hoursToStart, minutesToStart] = currentTime.split(':').map(Number);
      isOnFirstRender.current = false;
    } else {
      // Scroll to the current activated session on every subsequent renders.
      [hoursToStart, minutesToStart] = sessions[currentSessionIndex].from
        .split(':')
        .map(Number);
    }

    const scrollPosition = hoursToStart * 60 + minutesToStart;
    scrollContainer.current.getElement().scrollTo({
      behavior: 'smooth',
      left: scrollPosition,
      top: 0,
    });
  }, [currentSessionIndex, isToday, sessions]);

  return (
    <ScheduleContainer className="d-flex w-100">
      <ScrollContainer
        className={`d-flex position-relative px-1 py-2 schedule ${
          isDragging ? 'is-dragging' : ''
        }`}
        horizontal
        onEndScroll={() => {
          setIsDragging(false);
        }}
        onStartScroll={() => {
          setIsDragging(true);
        }}
        ref={scrollContainer}
      >
        {TIME_INTERVALS.map((item, index) => (
          <TimeInterval
            key={String(item)}
            time24={item}
            withSeperator={index !== TIME_INTERVALS.length - 1}
          />
        ))}
        {sessions.map(session =>
          session.from && session.to ? (
            <SessionBar from={session.from} key={session?.id} to={session.to} />
          ) : null,
        )}
        {isToday && <CurrentTime />}
      </ScrollContainer>
    </ScheduleContainer>
  );
};

Schedule.propTypes = {
  currentSessionIndex: PropTypes.number.isRequired,
  isToday: PropTypes.bool.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Schedule;
