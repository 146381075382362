import styled from 'styled-components';

const LiveSessionBar = styled.nav`
  padding: 1rem 3rem;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  .circle {
    border: 0.125rem solid white;
    border-radius: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    height: 3rem;
    line-height: 1.5;
    margin-left: -0.375rem;
    width: 3rem;
    text-transform: uppercase;
  }

  .student {
    background-color: #40a1ff;
    color: white;
  }

  .chat-btn {
    position: relative;
    span {
      min-height: 25px;
      min-width: 25px;
      width: auto;
      height: auto;
      position: absolute;
      right: -5px;
      top: -10px;
      background-color: #40a1ff;
      color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }
`;

export default LiveSessionBar;
