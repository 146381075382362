/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Color} from '../../../constants';

export const FilePreviewContainer = styled.div`
  .preview-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
  }
  @media (max-width: 40rem) {
    .preview-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .file-name {
    font-weight: 500;
    font-size: 1.125rem;
    color: #091018;
    margin-bottom: 0.5rem;
    overflow-wrap: anywhere;
  }
  .file-size {
    font-weight: 500;
    font-size: 0.75rem;
    color: #aeaeae;
    margin-bottom: unset;
  }
  .portfolio-btn {
    background-color: unset;
    outline: unset;
    border: unset;
  }
  .container-preview {
    height: 100%;
    width: 100%;
  }
  .container-preview {
    width: 100%;

    img {
      object-fit: contain;
      height: 400px;
      width: 100%;
    }
  }

  img {
    vertical-align: middle;
    margin-right: 0.75rem;
  }
  .left-div {
    display: flex;
    flex-direction: row;
    .left-div-content {
      margin: 0 1.5rem 0 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #091018;
    }

    .checkbox {
      width: 1.31rem;
      height: 1.31rem;
      outline: unset;
      padding: 0;
      background-color: unset;
      border-radius: 0.25rem;
      border: 0.0625rem solid #e0e0e0;
      img {
        vertical-align: unset;
      }
    }
  }
  .footer-button {
    background-color: #40a1ff;
    border: none;
    border-radius: 0.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem !important;

    :active,
    :focus {
      outline: none;
    }
  }
  .iframe {
    height: 100%;
    width: 100%;
  }
  .audios {
    height: 400px;
    background-color: #d9ecff;
    display: flex;
    align-items: center;
    flex-direction: column;
    audio {
      width: 100%;
      bottom: 0;
      height: 100%;
      ::-webkit-media-controls-enclosure {
        background-color: #5ba1f2;
        border-radius: unset;
      }
      ::-webkit-media-controls-current-time-display,
      ::-webkit-media-controls-time-remaining-display {
        text-shadow: unset;
      }
    }
  }
  .videos {
    height: 500px;
    width: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .spinner {
    animation: spin 1s linear infinite;
    height: 0.875rem;
    width: 0.875rem;
    margin-left: 0.75rem;
  }
  .seperator {
    height: 1px;
    width: 100%;
    background-color: #e6e7e8;
    margin: 1rem 0;
  }
  .container-task {
    font-weight: 500;
    font-size: 1.125rem;
    color: #091018;
    margin-bottom: unset;
  }
  .task-length {
    font-weight: 400;
    font-size: 0.875rem;
    color: #5ba1f2;
    margin-left: 0.5rem;
  }
`;
