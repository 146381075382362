/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import backIcon from '../../../../../../assets/icons/arrow_left.svg';
import DueDateIcon from '../../../../../../assets/icons/calender-new.svg';
import LocationIcon from '../../../../../../assets/icons/location-new.svg';
import TimeIcon from '../../../../../../assets/icons/sand-bottle-logo.svg';
import StudentIcon from '../../../../../../assets/icons/student-logo-new.svg';
import ArrowHideImg from '../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../assets/icons/tableshow.svg';

// import SideArrowShowImg from '../../../../../../assets/icons/side-arrow-new.svg';
import ResourcesActiveIcon from '../../../../../../assets/icons/resource.svg';

import {AssessmentStatus} from './components';

import {AssessmentViewHeaderContainer} from './elements';

export const AssessmentViewHeader = ({assessment, classID}) => {
  const assignedStudentsCount = assessment.instance?.students?.length;

  const totalStudentsCount = assessment.instance?.totalStudents?.length;
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);
  const [isStudentInstructionExpanded, setIsStudentInstructionExpanded] =
    useState(false);
  const [isAdditionalExpanded, setIsAdditionalExpanded] = useState(false);
  const [isRequiredResourceExpanded, setIsRequiredResourceExpanded] =
    useState(false);
  const history = useHistory();
  const toggleResources = () => setIsResourcesExpanded(!isResourcesExpanded);

  const toggleStudentInstruction = () =>
    setIsStudentInstructionExpanded(!isStudentInstructionExpanded);

  const toggleAdditionalInstruction = () =>
    setIsAdditionalExpanded(!isAdditionalExpanded);

  const toggleResourceRequired = () =>
    setIsRequiredResourceExpanded(!isRequiredResourceExpanded);

  // Remove html tags using regex
  const formatFunction = data => {
    const formattedData = data?.replace(/(<([^>]+)>)/gi, '');
    return formattedData;
  };

  return (
    <AssessmentViewHeaderContainer>
      <div className="basic-details-header">
        <div>
          <button
            onClick={() => history.replace(`/stream/${classID}/live`)}
            type="button"
            className="button-arrow"
          >
            <img
              alt="Back Button"
              className="header_t-back-icon"
              src={backIcon}
            />
          </button>
        </div>
        <h3>{assessment.ac_activity_name}</h3>

        <p className="basic-details-description">
          {assessment.ac_activity_description}

          <p className="published-text">
            Published on :{' '}
            <span className="published-time">
              {moment(assessment?.aoui_timestamp_published).format(
                'D MMM YYYY',
              )}
            </span>
          </p>
        </p>
        {assessment?.aouis_status !== 3 ? (
          <div className="outline-container">
            <div className="basic-details-container">
              <div className="time-container">
                <img alt="timeicon" src={TimeIcon} />
                <span className="text-data">
                  {assessment?.ac_maximum_duration_hours !== 0
                    ? `${assessment?.ac_maximum_duration_hours} h`
                    : '0 h'}{' '}
                  {assessment?.ac_maximum_duration_minutes !== 0
                    ? `${assessment?.ac_maximum_duration_minutes} mns`
                    : '0 mns'}{' '}
                </span>
              </div>
              <div className="duedate-container">
                <img
                  alt="duedate-img"
                  src={DueDateIcon}
                  className="duedate-img"
                />{' '}
                &nbsp;
                <span className="text-data">
                  {moment(assessment.ac_due_date).format('D MMM YYYY')}
                </span>
              </div>
              <div className="assigned-student-container">
                <img alt="Selected Students" src={StudentIcon} />
                <span className="text-data">
                  &nbsp; Assigned for{' '}
                  {assignedStudentsCount === totalStudentsCount
                    ? 'all'
                    : assignedStudentsCount}{' '}
                  {assignedStudentsCount === 1 ? 'student' : 'students'}
                </span>
              </div>
              <div className="location-container">
                <img alt="location-icon" src={LocationIcon} />
                <span className="text-data">{assessment?.ac_location}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="basic-datail-footer">
        {' '}
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div
              className={classNames('card-header', {
                clicked: isResourcesExpanded,
              })}
              id="headingOne"
            >
              <h2 className="mb-0">
                <button
                  className="btn btn-block d-flex justify-content-between align-items-center"
                  type="button"
                  onClick={toggleResources}
                >
                  <div className="accordion-header">
                    <img
                      alt="instructions"
                      className="resource"
                      src={ResourcesActiveIcon}
                    />
                    Instructions and Resources
                  </div>
                  <div>
                    <img
                      alt="Show Works"
                      className="arrow"
                      src={isResourcesExpanded ? ArrowShowImg : ArrowHideImg}
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className={classNames('collapse', {
                show: isResourcesExpanded,
              })}
            >
              <div className="card-body">
                {formatFunction(assessment?.ac_student_instructions)?.length ? (
                  <div
                    className={classNames('card-header', {
                      clicked: isStudentInstructionExpanded,
                    })}
                    id="headingOne"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block d-flex justify-content-between align-items-center"
                        type="button"
                        onClick={toggleStudentInstruction}
                      >
                        <div className="title">Student Instructions</div>
                        <div>
                          <img
                            alt="Show Works"
                            className="arrow"
                            src={
                              isStudentInstructionExpanded
                                ? ArrowShowImg
                                : ArrowHideImg
                            }
                          />
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={classNames('collapse data', {
                        show: isStudentInstructionExpanded,
                      })}
                    >
                      <div className="instruction-content">
                        {formatFunction(assessment?.ac_student_instructions)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {formatFunction(assessment?.ac_additional_information)
                  .length ? (
                  <div
                    className={classNames('card-header', {
                      clicked: isAdditionalExpanded,
                    })}
                    id="headingOne"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block d-flex justify-content-between align-items-center"
                        type="button"
                        onClick={toggleAdditionalInstruction}
                      >
                        <div className="title">Additional Instructions</div>
                        <div>
                          <img
                            alt="Show Works"
                            className="arrow"
                            src={
                              isAdditionalExpanded ? ArrowShowImg : ArrowHideImg
                            }
                          />
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={classNames('collapse data', {
                        show: isAdditionalExpanded,
                      })}
                    >
                      <div className="instruction-content">
                        {formatFunction(assessment?.ac_additional_information)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {assessment?.ac_resource_required?.length ? (
                  <div
                    className={classNames('card-header-last', {
                      clicked: isRequiredResourceExpanded,
                    })}
                    id="headingOne"
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-block d-flex justify-content-between align-items-center"
                        type="button"
                        onClick={toggleResourceRequired}
                      >
                        <div className="title">Resources Required</div>
                        <div>
                          <img
                            alt="Show Works"
                            className="arrow"
                            src={
                              isRequiredResourceExpanded
                                ? ArrowShowImg
                                : ArrowHideImg
                            }
                          />
                        </div>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={classNames('collapse data-last', {
                        show: isRequiredResourceExpanded,
                      })}
                    >
                      <div className="instruction-content">
                        {' '}
                        {assessment?.ac_resource_required}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <AssessmentStatus
            assessmentStatus={assessment?.aouis_status}
            assessment={assessment}
          />
        </div>
      </div>
    </AssessmentViewHeaderContainer>
  );
};
