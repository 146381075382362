/* eslint-disable react/prop-types */
import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import {AddAssessmentFooterContainer} from './elements';
import {Button} from '../../../../../../components/common';
import UserIcon from '../../../../../../assets/icons/user.svg';
import ArrowIcon from '../../../../../../assets/icons/nextarrow.svg';

export const AddAssessmentFooter = ({
  totalStudentsCount,
  currentTab,
  onButtonClick,
  continueButtonOnClick,
  isArchive,
  isTabSwitch,
  isUpdate,
}) => {
  const assessmentState = useSelector(state => state.assessment);
  const {createdAssessment, selectedStudents, createdTasks} = assessmentState;

  let studentsCount = totalStudentsCount;

  if (totalStudentsCount) {
    if (selectedStudents.length === totalStudentsCount) {
      studentsCount = 'All';
    } else {
      studentsCount = selectedStudents.length;
    }
  }

  return (
    <AddAssessmentFooterContainer>
      <div className="footer-container fixed-bottom">
        <div className="selected-students">
          <img src={UserIcon} alt="arrow" />
          <p className="assessment-footer-text mb-0 mr-0 ml-2">
            Selected
            {` ${studentsCount} `}
            Students
          </p>
        </div>
        <div className="manage-footer">
          <div className="button-container">
            {currentTab === 'details' ? (
              <Button
                className={classNames('button', {
                  'is-archive': isArchive,
                })}
                isFullWidth={false}
                label={isUpdate ? 'Save & Continue' : 'Update & Continue'}
                onClick={continueButtonOnClick}
                icon={ArrowIcon}
                iconPostion="right"
                type="button"
                key="continue"
                isDisabled={!isTabSwitch() || isArchive}
                id="save-update-id"
              />
            ) : (
              <Button
                className={classNames('button', {
                  'is-archive': isArchive,
                })}
                isFullWidth={false}
                label={
                  createdAssessment?.aoui_status === 2 ? 'Update' : 'Publish'
                }
                onClick={onButtonClick}
                type="submit"
                isDisabled={!createdTasks?.length || !selectedStudents?.length}
                iconPostion="right"
                key="publish"
                id="update-publish-id"
              />
            )}
          </div>
        </div>
      </div>
    </AddAssessmentFooterContainer>
  );
};
