import styled from 'styled-components';
import {Color, Colors} from '../../../../../constants';

export const AssessmentViewContainer = styled.div`
  background-color: #f4f5f8;
  display: flex;
  flex-direction: column;
  ${({fixedHeight}) =>
    `
      ${fixedHeight ? 'height: 100vh' : 'min-height: 100vh'};
    `}
  ${({flexEnd}) => (flexEnd ? 'justify-content: flex-end;' : '')}
  margin: 0 -0.937rem;
  overflow-y: hidden;
  padding-top: ${({paddingTop}) => paddingTop}px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 43.75rem) {
    .footer {
      padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
      .footer-reassign-button {
        display: none;
      }
    }
    .evaluate-assessment-modal-wrapper {
      align-items: center;
      .evaluate-assessment-modal {
        width: 90%;
        padding: 2.5rem 2.5rem 1.5rem 2.5rem !important;
        .assessment-modal-div {
          margin: 0rem -1rem !important;
          .mark-input-response {
            width: 80%;
            word-wrap: wrap auto;
            .mark-input {
              input {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
  .evaluate-assessment-modal-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2000;

    .evaluate-assessment-modal {
      background-color: #fff;
      border-radius: 0.75rem;
      box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
      display: flex;
      flex-direction: column;
      max-width: 25rem;
      padding: 2.5rem;
      width: 100%;

      h5 {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }

      form {
        label {
          margin-bottom: 0;
        }

        label,
        .label {
          display: flex;
          flex-direction: column;

          & > span {
            color: #aaa;
            font-size: 0.875rem;
            font-weight: normal;
            margin-bottom: 0.562rem;
          }

          & > input,
          & > textarea {
            color: #3d4457;
            border: 0.0625rem solid #dedede;
            border-radius: 0.312rem;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 1.15;
            transition: border-color 0.1s, box-shadow 0.1s;

            ::-webkit-input-placeholder {
              color: #aaa;
            }
            ::-moz-placeholder {
              color: #aaa;
            }
            :-ms-input-placeholder {
              color: #aaa;
            }
            :-moz-placeholder {
              color: #aaa;
            }

            :active,
            :focus {
              border-color: ${Colors.Primary};
              box-shadow: 0 0 0 0.125rem ${Color.LIGHT_BLUE};
              outline: none;
            }
          }

          & > input {
            padding: 0.6875rem 1rem 0.75rem 1rem;
          }

          & > textarea {
            resize: none;
            padding: 0.6875rem 1rem 0.75rem 1rem;
          }

          & > .input {
            align-items: center;
            border: 0.0625rem solid #dedede;
            border-radius: 0.312rem;
            color: #fff;
            display: flex;
            font-size: 1.5rem;
            font-weight: 500;
            height: 100%;
            justify-content: center;
            min-width: 3.75rem;
            width: 100%;
          }

          .mark-input {
            align-items: center;
            border: 0.0625rem solid #dedede;
            border-radius: 0.312rem;
            display: flex;
            padding: 0.6875rem 1rem 0.75rem 1rem;

            input {
              border: none;
              color: #3d4457;
              font-size: 0.875rem;
              font-weight: normal;
              line-height: 1.15;

              :active,
              :focus {
                outline: none;
              }
            }

            span {
              color: #aaa;
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.15;
            }
          }
        }

        .right-row {
          display: flex;
          margin-bottom: 1.5rem;

          label {
            margin-right: 1.5rem;
          }

          .label {
            flex-grow: 1;
          }
        }

        .comments {
          margin-bottom: 1.5rem;
        }

        button {
          background-color: #40a1ff;
          border: none;
          border-radius: 0.5rem;
          color: #fff;
          font-size: 1rem;
          font-weight: 500;
          padding: 0.5rem;
          width: 100%;

          :active,
          :focus {
            outline: none;
          }
        }
      }

      .cancel-modal {
        /* align-self: center; */
        background-color: transparent;
        border: none;
        width: 100%;
        margin-top: 1.5rem;
        span {
          border-bottom: 0.0625rem solid #40a1ff;
          color: #40a1ff;
          font-size: 0.875rem;
          font-weight: 500;
        }

        :active,
        :focus {
          outline: none;
        }
      }
    }
  }

  .more-option {
    &.is-archived {
      display: none;
    }
    &.is-student {
      display: none;
    }
  }
`;
