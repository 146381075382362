import styled from 'styled-components';

const PublishAssessmentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem;
  @media (max-width: 43.75rem) {
    & {
      max-width: 43.75rem;
    }
    .assessment-head {
      margin-top: 5rem;
    }
  }
  .assessment-head {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #3d4457;
  }
  .assessment-subhead {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.187rem;
    color: #aaaaaa;
  }
  .publish-buttons {
    display: flex;
    align-items: center;
  }
`;

export default PublishAssessmentsContainer;
