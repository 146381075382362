/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import * as sessionApi from '../../../../api/session';
import SessionContainer from '../../elements/SessionContainer';
import SessionsChart from '../SessionsChart';
import InstantSessionForm from './components/InstantSessionForm';

const InstantSession = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const {id: instance} = useParams();
  const location = useLocation();
  const [sessionsToday, setSessionsToday] = useState([]);

  useEffect(() => {
    sessionApi
      .getSessions({
        instance,
        date: moment(new Date()).format('YYYY-MM-DD'),
        groupBy: 'date',
      })
      .then(res => {
        const {status, sessions} = res.data;
        if (status && sessions.length) {
          setSessionsToday(
            sessions.flatMap(({values}) =>
              values.map(
                ({
                  oruil_id_orgunitinstancelesson,
                  oruil_name,
                  oruil_timestart,
                  oruil_timefinish,
                }) => ({
                  from: oruil_timestart,
                  link: `sessions/${oruil_id_orgunitinstancelesson}`,
                  name: oruil_name,
                  to: oruil_timefinish,
                  id: oruil_id_orgunitinstancelesson,
                }),
              ),
            ),
          );
        }
      });
  }, [instance]);

  const onSubmit = async data => {
    try {
      const response = await sessionApi.createSession({
        ...data,
        instance,
      });
      const {oruil_id_orgunitinstancelesson: lessonId} =
        response?.data?.session;
      if (lessonId) history.push(`${location.path}/../${lessonId}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      if (err?.response?.data?.error?.message) {
        setError(err?.response?.data?.error?.message);
      }
    }
  };

  return (
    <SessionContainer>
      <InstantSessionForm
        onSubmit={onSubmit}
        error={error}
        setError={setError}
      />
      <SessionsChart date={new Date()} sessions={sessionsToday} />
    </SessionContainer>
  );
};

export default InstantSession;
