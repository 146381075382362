/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useForm} from 'react-hook-form';

import CustomButton from '../../../../../components/shared/CustomButton';
import CustomNumberInput from '../../../../../components/shared/CustomNumberInput';
// import CustomTextInput from '../../../../../components/shared/CustomTextInput';
import SessionFormContainer from '../../../elements/SessionFormContainer';
import {TextInput} from '../../../../../components/common';

const InstantSessionForm = ({onSubmit, error, setError}) => {
  const {register, errors, handleSubmit} = useForm();
  const [maximumDurationHoursError, setMaximumDurationHoursError] =
    useState(false);
  const [maximumDurationMinutesError, setMaximumDurationMinutesError] =
    useState(false);

  const [maximumDurationHours, setMaximumDurationHours] = useState(0);
  const [maximumDurationMinutes, setMaximumDurationMinutes] = useState(0);

  const onFormSubmit = ({title}) => {
    if (
      Number(maximumDurationMinutes) === 0 &&
      Number(maximumDurationHours) === 0
    ) {
      setMaximumDurationMinutesError(true);
      setMaximumDurationHoursError(true);
      return;
    }
    const addMin = +(maximumDurationHours || 0) * 60 + +maximumDurationMinutes;
    const currentTime = moment(moment()).format('H:mm');
    const endTime = moment(moment().add(addMin, 'minutes')).format('H:mm');
    onSubmit?.({
      startTime: currentTime,
      finishTime: endTime,
      title,
    });
  };
  return (
    <SessionFormContainer>
      <div>
        <h2 className="title">Start class Instantly</h2>
        <p className="subtitle">
          Enter the necessary information to begin the class with your students.
        </p>
        <form className="session-form" onSubmit={handleSubmit(onFormSubmit)}>
          <TextInput
            className="mb-4"
            type="text"
            id="title"
            name="title"
            label="Session Title"
            errorMessage={errors?.title ? 'Title is required' : null}
            hasError={errors?.title}
            ref={register({required: true})}
            placeholder="Enter a title for the video session"
          />
          <div className="d-flex flex-column mb-4">
            <span className="label mb-2">Duration</span>
            <div className="d-flex">
              <CustomNumberInput
                containerClassName="flex-grow-1 mr-4"
                inputClassName="input-container text-input"
                placeholder="Hrs"
                onChange={event => {
                  setError(null);
                  setMaximumDurationHoursError(false);
                  if (+event.target.value < 0) {
                    return;
                  }
                  setMaximumDurationHours(event.target.value);
                  if (event) {
                    setMaximumDurationHoursError(false);
                    setMaximumDurationMinutesError(false);
                  }
                }}
                value={maximumDurationHours}
                type="number"
                onControl={setMaximumDurationHours}
                min={0}
                error={maximumDurationHoursError}
                id="max-hrs"
              />
              <CustomNumberInput
                containerClassName="flex-grow-1"
                inputClassName="input-container text-input"
                placeholder="Min"
                onChange={event => {
                  setError(null);
                  setMaximumDurationMinutesError(false);
                  if (+event.target.value > 59 || +event.target.value < 0) {
                    setMaximumDurationMinutes('59');
                  } else {
                    setMaximumDurationMinutes(event.target.value);
                  }
                  if (event) {
                    setMaximumDurationMinutesError(false);
                    setMaximumDurationHoursError(false);
                  }
                }}
                value={maximumDurationMinutes}
                type="number"
                onControl={setMaximumDurationMinutes}
                min={0}
                max={59}
                error={maximumDurationMinutesError}
                id="min-hrs"
              />
            </div>
            {error ? <span className="error-time mt-2">{error}</span> : null}
          </div>
          <CustomButton
            type="submit"
            className="submit-button justify-content-center"
            text="Start Now"
            id="submit-btn"
          />
        </form>
      </div>
    </SessionFormContainer>
  );
};

InstantSessionForm.propTypes = {
  onSubmit: PropTypes.func,
};
InstantSessionForm.defaultProps = {
  onSubmit: undefined,
};

export default InstantSessionForm;
