import styled from 'styled-components';

export const MappedTaskContainer = styled.div`
  .title {
    font-weight: 500;
    font-size: 1rem;
    color: #3d4457;
    margin-bottom: 1rem;
  }
  .map-column {
    display: flex;
    justify-content: space-between;
    .left-field,
    .right-field {
      width: 49%;
    }
  }
  @media (max-width: 47.625rem) {
    .map-column {
      flex-direction: column;
      .left-field,
      .right-field {
        width: 100%;
      }
    }
  }
`;
