/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const TaskStatusContainer = styled.div`
  display: flex;
  flex-direction: row;

  .status-1,
  .status-2,
  .status-3,
  .status-4,
  .status-5 {
    border-radius: 0.3125rem;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.21;
    padding: 0.5rem 1rem;
  }

  .status-1 {
    background-color: #fcf3cf;
    color: #fcca3d;
  }
  .status-2 {
    background-color: #f3f3f3;
    color: #8a8c94;
  }
  .status-3 {
    background-color: #e0fff8;
    color: #15d3c8;
  }
  .status-4 {
    background-color: #ffe1e1;
    color: #ff8787;
  }
  .status-5 {
    background-color: #defae8;
    color: #4ec977;
  }
`;

export const TaskStatus = ({statusId}) => {
  let taskLogStatus;
  function renderStatus(taskStatus) {
    if (taskStatus === 1) {
      taskLogStatus = 'Pending';
    } else if (taskStatus === 2) {
      taskLogStatus = 'Assigned';
    } else if (taskStatus === 3) {
      taskLogStatus = 'Approved';
    } else if (taskStatus === 4) {
      taskLogStatus = 'Rejected';
    } else if (taskStatus === 5) {
      taskLogStatus = 'Completed';
    }
    return taskLogStatus;
  }

  return (
    <TaskStatusContainer>
      <div className={`status-${statusId}`}>{renderStatus(statusId)}</div>
    </TaskStatusContainer>
  );
};
