import styled from 'styled-components';

export const AuthContainer = styled.div`
  margin: 0 -0.9375rem;
  height: 100vh;
  overflow-y: hidden;
  width: 100vw;

  .main-section {
    height: 100%;
    overflow-y: auto;

    .main-content {
      height: 100%;
      max-width: 27rem;
    }
  }
`;
