/* eslint-disable react/prop-types */
import {AnimatePresence, motion} from 'framer-motion';
import React, {useMemo, useEffect, useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {Select, Button} from '../../../../../../../../components/common';

import FiltersCloseIcon from '../../../../../../../../assets/icons/close-filters.svg';
import {
  fetchSessionsByFilters,
  fetchClassesByCourseId,
  fetchCourses,
  fetchTeachersByUnitId,
  fetchUnitsByClassId,
  sessionsSelector,
} from '../../../../../../../../redux/sessionsSlice';

import {FilterSliderContainer} from './elements';

export const FilterSlider = ({
  isVisible,
  onClose,
  courseId,
  setClassId,
  classId,
  setCourseId,
  setUnitId,
  unitId,
  setTeacherId,
  teacherId,
  setIsFilterApplied,
}) => {
  const dispatch = useDispatch();

  const {
    courseOptions: courses,
    classOptions: classes,
    unitOptions: units,
    teacherOptions: teachers,
    locationOptions,
  } = useSelector(sessionsSelector);

  const courseOptions = useMemo(
    () =>
      (courses || [])?.map(item => ({
        label: item.ocr_course_name,
        value: item.ocr_id_orgcourse,
        qualificationId: item.ocr_id_orgqual,
      })),
    [courses],
  );

  const classOptions = useMemo(
    () =>
      (classes || [])?.map(item => ({
        label: item.oc_class,
        value: item.oc_id_orgclass,
      })),
    [classes],
  );

  const unitOptions = useMemo(
    () =>
      (units || [])?.map(item => ({
        label: item.orgUnit.unit.tun_title,
        value: item.orui_id_orgunitinstance,
      })),
    [units],
  );

  const teacherOptions = useMemo(
    () =>
      (teachers || [])?.map(item => ({
        label: `${item.userProfile.up_name_first} ${item.userProfile.up_name_last}`,
        value: item.userProfile.up_id_userprofile,
      })),
    [teachers],
  );
  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseId) {
      dispatch(fetchClassesByCourseId(courseId.value));
    }
  }, [courseId, dispatch]);

  useEffect(() => {
    if (classId) {
      dispatch(fetchUnitsByClassId(classId.value));
    }
  }, [classId, dispatch]);

  useEffect(() => {
    if (unitId) {
      dispatch(fetchTeachersByUnitId(unitId.value));
    }
  }, [unitId, dispatch]);

  const onSubmit = useCallback(() => {
    const filterData = {
      course: courseId?.value,
      class: classId?.value,
      unit: unitId?.value,
      teacher: teacherId?.value,
      // location: data?.location?.value,
    };
    dispatch(
      fetchSessionsByFilters(filterData, () => {
        setIsFilterApplied(true);
        onClose();
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId, courseId, dispatch, onClose, teacherId, unitId]);
  return (
    <AnimatePresence>
      {isVisible ? (
        <FilterSliderContainer
          className="d-flex justify-content-end position-fixed"
          animate={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          exit={{
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          }}
          initial={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          transition={{
            duration: 0.4,
            type: 'keyframes',
          }}
        >
          <motion.aside
            className="d-flex flex-column position-absolute sidebar"
            animate={{
              x: '0',
            }}
            exit={{
              x: '100%',
            }}
            initial={{
              x: '100%',
            }}
            transition={{
              duration: 0.4,
              type: 'keyframes',
            }}
          >
            <div className="top-part">
              <div className="filters">
                <h4>Filters</h4>
              </div>
              <div className="close-icon">
                <button type="button" className="close-btn" onClick={onClose}>
                  <img src={FiltersCloseIcon} alt="close" />
                </button>
              </div>
            </div>

            <div>
              <Select
                isLarge={false}
                value={courseId}
                className="mb-4"
                label="Course"
                options={courseOptions}
                placeholder="Choose a Course"
                id="course"
                onChange={e => {
                  setCourseId(e);
                  setTeacherId(null);
                  setClassId(null);
                  setUnitId(null);
                }}
                isDisabled={!courseOptions?.length}
              />

              <Select
                className="mb-4"
                value={classId}
                isLarge={false}
                label="Class"
                options={classOptions}
                placeholder="Choose a Class"
                id="class"
                onChange={e => {
                  setClassId(e);
                  setTeacherId(null);
                  setUnitId(null);
                }}
                isDisabled={!classOptions?.length || !courseId}
              />

              <Select
                className="mb-4"
                value={unitId}
                isLarge={false}
                label="Unit"
                options={unitOptions}
                placeholder="Choose a Unit"
                id="unit"
                onChange={e => {
                  setUnitId(e);
                  setTeacherId(null);
                }}
                isDisabled={!unitOptions?.length || !classId}
              />

              <Select
                className="mb-4"
                value={unitId}
                isLarge={false}
                label="Location"
                options={locationOptions}
                placeholder="Choose a Location"
                id="location"
                isDisabled
              />

              <Select
                isLarge={false}
                value={teacherId}
                label="Teacher"
                options={teacherOptions}
                placeholder="Choose a Teacher"
                id="teacher"
                onChange={e => {
                  setTeacherId(e);
                }}
                isDisabled={!teacherOptions?.length || !unitId}
              />
            </div>

            <div className="footer fixed-bottom">
              <div />
              <div className="button-container">
                <Button
                  className="button cancel-button mr-3"
                  isFullWidth={false}
                  onClick={onClose}
                  label="Cancel"
                  id="cancel-course-button"
                />
                <Button
                  className="button"
                  isFullWidth={false}
                  // isLoading={isAddCourseLoading}
                  label="Apply"
                  onClick={onSubmit}
                  id="add-course-button"
                />
              </div>
            </div>
          </motion.aside>
        </FilterSliderContainer>
      ) : null}
    </AnimatePresence>
  );
};
