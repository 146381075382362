/* eslint-disable react/prop-types */
import React from 'react';
import AssessmentIcon from '../../../../../../../../../../assets/icons/assessment-icon.svg';

import {AssessmentContainer} from './elements';

export const AssessmentSection = ({assessment}) => (
  <AssessmentContainer>
    <div className="header-section">
      <div className="image">
        <img src={AssessmentIcon} alt="general" className="assessment-icon" />
      </div>
      <div>
        <p className="header">Assessment</p>
      </div>
    </div>
    <div className="seperator" />
    <div className="content-section">
      <div className="first-div">
        <div className="first-content">
          <div className="title-div">
            <span>1</span>
            <p className="title">Overview</p>
          </div>
          <p className="content">{assessment[0]?.assessmentDescription}</p>
        </div>
      </div>
      <div className="second-div">
        <div className="second-content">
          <div className="title-div">
            <span>2</span>
            <p className="title">Resources Required</p>
          </div>
          <p className="content">{assessment[0]?.resourceRequired || '-'}</p>
        </div>
      </div>
      <div className="third-div">
        <div className="third-content">
          <div className="title-div">
            <span>3</span>
            <p className="title">Student Instructions</p>
          </div>
          <p className="content">
            {assessment[0]?.studentInstruction?.replace(/(<([^>]+)>)/gi, '') ||
              '-'}
          </p>
        </div>
      </div>
    </div>
  </AssessmentContainer>
);
