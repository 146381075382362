import {createSlice} from '@reduxjs/toolkit';

import * as assessmentApi from '../api/assessment-new';

const assessmentSlice = createSlice({
  initialState: {
    error: null,
    isLoading: false,
    isPaginationLoading: false,
    isAssessmentLoading: false,
    assessment: [],
    isCommentLoading: false,
    assessmentCount: 0,
  },
  name: 'assessmentNew',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsPaginationLoading(state, action) {
      state.isPaginationLoading = action.payload;
    },
    setIsAssessmentLoading(state, action) {
      state.isAssessmentLoading = action.payload;
    },
    setAssessment: (state, action) => {
      state.assessment = action.payload;
    },
    setComment: (state, action) => {
      const assessment = state.assessment.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      assessment.comments = [
        action.payload.comment,
        ...(assessment?.comments || []),
      ];
    },
    setComments: (state, action) => {
      const assessment = state.assessment.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      assessment.comments = [
        ...(assessment?.comments || []),
        ...action.payload.comments,
      ];
    },
    deleteAssessmentFromList: (state, action) => {
      state.assessment = state.assessment.filter(
        assessment =>
          assessment.aoui_id_activityorgunitinstance !== action.payload,
      );
    },
    deleteCommentById: (state, action) => {
      const assessment = state.assessment.find(
        item => item.ac_id_activity === action.payload.activityId,
      );

      assessment.comments = assessment.comments.filter(
        item =>
          item.acc_id_activity_comment !== Number(action.payload.commentId),
      );
    },
    setIsCommentLoading(state, action) {
      state.isLoading = action.payload;
    },
    setAssessmentCount: (state, action) => {
      state.assessmentCount = action.payload;
    },
    appendAssessment: (state, action) => {
      state.assessment = [...state.assessment, ...action.payload];
    },
  },
});

export const {
  setError,
  setIsLoading,
  setIsPaginationLoading,
  setIsAssessmentLoading,
  setAssessment,
  setComment,
  setComments,
  deleteAssessmentFromList,
  deleteCommentById,
  setIsCommentLoading,
  setAssessmentCount,
  appendAssessment,
} = assessmentSlice.actions;

export const commentOnAssessment =
  (activityId, comment, callback) => async dispatch => {
    dispatch(setIsCommentLoading(true));
    try {
      const {data} = await assessmentApi.commentOnActivity(activityId, comment);
      dispatch(
        setComment({
          activityId,
          comment: data.comment,
        }),
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsCommentLoading(false));
    }
  };

export const getMoreComments = (activityId, offset) => async dispatch => {
  dispatch(setIsCommentLoading(true));
  try {
    const {data} = await assessmentApi.getComments(activityId, offset);
    dispatch(
      setComments({
        activityId,
        comments: data.comments,
      }),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsCommentLoading(false));
  }
};

export const deleteAssessment = (aouiID, callback) => async dispatch => {
  dispatch(setIsLoading(true));
  dispatch(setIsAssessmentLoading(true));
  try {
    await assessmentApi.deleteAssessment(aouiID);
    if (callback) {
      callback(aouiID);
    } else {
      dispatch(deleteAssessmentFromList(aouiID));
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
    dispatch(setIsAssessmentLoading(false));
  }
};

export const deleteAssessmentComment =
  (commentId, activityId, callback) => async dispatch => {
    dispatch(setIsCommentLoading(true));
    try {
      const {data} = await assessmentApi.deleteAssessmentComment(commentId);
      dispatch(deleteCommentById({commentId: data.commentId, activityId}));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsCommentLoading(false));
    }
  };

export const fetchAllAssessment =
  (classId, offset, type = 3, callback) =>
  async dispatch => {
    if (offset) {
      dispatch(setIsPaginationLoading(true));
    } else {
      dispatch(setIsLoading(true));
    }

    try {
      let response;
      if (type === 3) {
        response = await assessmentApi.fetchAllAssessment(classId, offset || 0);
      } else if (type === 2) {
        response = await assessmentApi.fetchAssessmentForStudent(
          classId,
          offset || 0,
        );
      }
      if (offset) {
        dispatch(appendAssessment(response.data.assessments));
      } else {
        dispatch(setAssessment(response.data.assessments));
      }

      dispatch(setAssessmentCount(response.data.assessmentCount));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      if (offset) {
        dispatch(setIsPaginationLoading(false));
      } else {
        dispatch(setIsLoading(false));
      }
    }
  };

export const assessmentSelector = state => state.assessmentNew;

export default assessmentSlice.reducer;
