/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import classNames from 'classnames';
import ArrowHideImg from '../../../../../../../../../../assets/icons/tablehide.svg';
import GreenTickImg from '../../../../../../../../../../assets/icons/donemark.svg';
import ArrowShowImg from '../../../../../../../../../../assets/icons/tableshow.svg';
import {Table} from '../../../../../../../../../../components/common';
import {AccordionContainer, SpreadSheetTableStyles} from './elements';

export const Accordion = ({
  title,
  subtitle,
  emailPatternErrorData,
  spreadsheetcolumns,
  studentEmailPattern,
  duplicateDataError,
  otherOrgEmailErrorData,
  otherOrgReferIdErrorData,
}) => {
  const [isEmailPatternExpanded, setIsEmailPatternExpanded] = useState(false);
  const [isDuplicateExpanded, setIsDuplicateExpanded] = useState(false);
  const [isOtherOrgEmailExpanded, setIsOtherOrgEmailExpanded] = useState(false);
  const [isOtherOrgReferenceIdExpanded, setIsOtherOrgReferenceIdExpanded] =
    useState(false);

  const toggleEmailPattern = () =>
    setIsEmailPatternExpanded(!isEmailPatternExpanded);

  const toggleDuplicateData = () =>
    setIsDuplicateExpanded(!isDuplicateExpanded);

  const toggleOtherOrgEmail = () =>
    setIsOtherOrgEmailExpanded(!isOtherOrgEmailExpanded);

  const toggleOtherOrgReferenceId = () =>
    setIsOtherOrgReferenceIdExpanded(!isOtherOrgReferenceIdExpanded);

  return (
    <AccordionContainer>
      <img src={GreenTickImg} alt="green tick" />
      <h2 className="mt-3 header">{title}</h2>
      <p className="state">{subtitle}</p>
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleEmailPattern}
                id="email-error"
              >
                <div>
                  Email ID Pattern. Error ({studentEmailPattern.length})
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={isEmailPatternExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={classNames('collapse', {
              show: isEmailPatternExpanded,
            })}
          >
            <div className="card-body">
              {studentEmailPattern && studentEmailPattern?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={emailPatternErrorData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleDuplicateData}
                id="invalid-duplicate-error"
              >
                <div>
                  Invalid and Duplicate Entries ({duplicateDataError.length})
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={isDuplicateExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={classNames('collapse', {
              show: isDuplicateExpanded,
            })}
          >
            <div className="card-body">
              {duplicateDataError && duplicateDataError?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={duplicateDataError}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleOtherOrgEmail}
              >
                <div>
                  Other Organisation Email Error (
                  {otherOrgEmailErrorData.length})
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={isOtherOrgEmailExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className={classNames('collapse', {
              show: isOtherOrgEmailExpanded,
            })}
          >
            <div className="card-body">
              {otherOrgEmailErrorData && otherOrgEmailErrorData?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={otherOrgEmailErrorData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleOtherOrgReferenceId}
              >
                <div>
                  Other Organisation Reference ID Error (
                  {otherOrgReferIdErrorData.length})
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={
                      isOtherOrgReferenceIdExpanded
                        ? ArrowShowImg
                        : ArrowHideImg
                    }
                  />
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className={classNames('collapse', {
              show: isOtherOrgReferenceIdExpanded,
            })}
          >
            <div className="card-body">
              {otherOrgReferIdErrorData && otherOrgReferIdErrorData?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={otherOrgReferIdErrorData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </AccordionContainer>
  );
};
