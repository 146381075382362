import styled from 'styled-components';

export const TaskFileContainer = styled.div`
  .decision-div {
    width: 50%;
    border-radius: 0.3125rem;
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;

    .grade-value {
      border-radius: 0.625rem;
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
      height: 1.25rem;
      line-height: 1.125rem;
      width: 1.25rem;
    }

    .grade-label {
      color: ${({theme}) => theme.colors.text};
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.125rem;
    }
  }
  .page-prev {
    background: #ffffff;
    display: flex;
    padding: 0rem 0.75rem;
    align-items: center;
    justify-content: center;
    border: 0.0625rem solid #bdbdbd;
    border-radius: 0.3125rem 0rem 0rem 0.3125rem;
    height: 2rem;
    margin-right: -0.0625rem;

    .btnu {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #bdbdbd;
      background: unset;
    }
  }
  .page-next {
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.75rem;
    background: #ffffff;
    border: 0.0625rem solid #bdbdbd;
    border-radius: 0rem 0.3125rem 0.3125rem 0rem;
    .btnu {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #bdbdbd;
      background: unset;
    }
  }
  .right-title {
    color: ${({theme}) => theme.colors.text};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.625rem;
    margin-bottom: 2.06rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .right {
    .timecode {
      background-color: ${({theme}) => theme.colors.primary};
      border-radius: 0.187rem 0.187rem 0rem 0rem;
      color: white;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 0.906rem;
      right: 0;
      bottom: 100%;
    }

    .file {
      background-color: black;
      height: 24rem;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
    .video-js {
      height: 24rem;
      overflow: hidden;
      width: 100%;
    }
  }
  .assessment-task-files {
    display: flex;
    margin-bottom: 0.875rem;

    .current-indicator {
      padding: -0.125rem;
    }
    button {
      border-radius: 0.187rem;
      position: relative;
      width: 2.75rem;
      height: 2.75rem;

      :not(:last-child) {
        margin-right: 1rem;
      }

      :active,
      :focus {
        outline: none;
      }

      .current-indicator {
        top: calc(100% + 0.125rem);
        height: 0.3125rem;
        left: 50%;
        position: absolute;
        transform: rotate(180deg) translateX(50%);
        width: 0.625rem;
      }

      .thumbnail {
        border-radius: 0.187rem;
        height: 100%;
        width: 100%;
      }
    }
  }
  form {
    label {
      margin-bottom: 0;
    }

    label,
    .label {
      display: flex;
      flex-direction: column;

      & > span {
        color: #aaa;
        font-size: 0.875rem;
        font-weight: normal;
        margin-bottom: 0.56rem;
      }

      & > input,
      & > textarea {
        color: #3d4457;
        border: 0.0625rem solid #dedede;
        border-radius: 0.3125rem;
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 1.15;
        transition: border-color 0.1s, box-shadow 0.1s;

        ::-webkit-input-placeholder {
          color: #aaa;
        }
        ::-moz-placeholder {
          color: #aaa;
        }
        :-ms-input-placeholder {
          color: #aaa;
        }
        :-moz-placeholder {
          color: #aaa;
        }

        :active,
        :focus {
          border-color: ${({theme}) => theme.colors.primary};
          box-shadow: 0 0 0 0.125rem ${({theme}) => theme.colors.lightPrimary};
          outline: none;
        }
      }

      & > input {
        padding: 0.6825rem 1rem 0.75rem 1rem;
      }

      & > textarea {
        resize: none;
        padding: 0.6825rem 1rem 0.75rem 1rem;
      }

      & > .input {
        align-items: center;
        border: 0.0625rem solid #dedede;
        border-radius: 0.3125rem;
        color: #fff;
        display: flex;
        font-size: 1.5rem;
        font-weight: 500;
        height: 100%;
        justify-content: center;
        width: 100%;
      }

      .mark-input {
        align-items: center;
        border: 0.0625rem solid #dedede;
        border-radius: 0.3125rem;
        display: flex;
        padding: 0.6825rem 1rem 0.75rem 1rem;

        input {
          border: none;
          color: #3d4457;
          flex-grow: 1;
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 1.15;

          :active,
          :focus {
            outline: none;
          }
        }

        span {
          color: #aaa;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.15;
        }
      }
    }

    .right-row {
      display: flex;
      margin-bottom: 1.5rem;

      label {
        flex-grow: 1;
        margin-right: 1.5rem;
      }

      .label {
        width: 24%;
      }
    }

    .comments {
      margin-top: 1.5rem;
    }

    button {
      background-color: #40a1ff;
      border: none;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.5rem 1.687rem;

      :active,
      :focus {
        outline: none;
      }
    }
  }
  @media (max-width: 43.75rem) {
    .decision-div {
      width: 100% !important;
    }
  }
`;
