import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {saveAs} from 'file-saver';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import debounce from 'lodash.debounce';
import axios from '../../../../../../api';
import PageBackIcon from '../../../../../../assets/icons/page-back.svg';
import CheckFillIcon from '../../../../../../assets/icons/tas-check-fill.svg';
import CheckOutlineIcon from '../../../../../../assets/icons/tas-check-outline.svg';
import ApprovedStatusIcon from '../../../../../../assets/icons/approved-status.svg';
import AssignedStatusIcon from '../../../../../../assets/icons/assigned-status.svg';
import RejectedStatusIcon from '../../../../../../assets/icons/rejected-status.svg';
import PendingStatusIcon from '../../../../../../assets/icons/pending-status.svg';
import CompletedStatusIcon from '../../../../../../assets/icons/completed-status.svg';
import {Button} from '../../../../../../components/common';
import {Spacer} from '../../../../../../components/common/Spacer';
import {tasSchema} from '../../../../../../utils/validation';
import {
  AssessmentResourcesForm,
  ChangeHistoryModal,
  CoreAndElectiveComponentsForm,
  DurationAndSchedulingForm,
  EntryRequirementsForm,
  IndustryEngagementsForm,
  LearningResourcesForm,
  ModeOfDeliveryForm,
  PathwaysForm,
  TargetGroupForm,
  TrainingProductForm,
  TasSubmissionModal,
  VerificationHistoryModal,
} from './components';
import HumanResourcesForm from './components/HumanResourcesForm';
import {TasFormContainer, TasFormHeader} from './elements';
import TasFormMain from './elements/TasFormMain';
import TasFormSidebar from './elements/TasFormSidebar';
import {
  fetchAllTaskStatus,
  taskSelector,
  fetchTaskWithAllTaskLogs,
  fetchAllUser,
} from '../../../../../../redux/taskSlice';

const sectionFieldsMap = {
  'training-product': [
    'otv_cohort_name',
    'otv_course_dur',
    'otv_delivery_date',
    'otv_department',
    'otv_end_date',
    'otv_type',
  ],
  'target-group': ['otv_target_group'],
  'entry-requirements': [
    'otv_rto_entry_requirements',
    'otv_literacy',
    'otv_numeracy',
  ],
  pathways: [
    'otv_credit_transfer',
    'otv_rto_pathways',
    'otv_student_lifecycle',
  ],
  // 'duration-and-scheduling': [
  //   'otv_core_unit_count',
  //   'otv_elective_unit_count',
  //   'otv_nominal_hours',
  // ],
  'core-and-elective-components': ['otv_stage_name', 'otv_stage_count'],
  'mode-of-delivery': ['otv_assessment_resources'],
  'assessment-resources': [
    'otv_assessment_strategies',
    'otv_reasonable_adjustment',
    'otv_assessment_validation',
  ],
  'learning-resources': ['otv_learning_resources'],
  'human-resources': ['otv_human_resources'],
  'industry-engagements': ['otv_industry_engagements'],
};

const sectionIdsInOrder = [
  'training-product',
  'target-group',
  'entry-requirements',
  'pathways',
  'duration-and-scheduling',
  'core-and-elective-components',
  'mode-of-delivery',
  'assessment-resources',
  'learning-resources',
  'human-resources',
  'industry-engagements',
];

const electiveUnitsValidation = [
  'otun_id_tasstagetype',
  'otun_prerequisite',
  'ou_id_orgqual',
  'ou_id_unit_type',
  'prerequisitesUnit',
  'otun_minimum_payable_hours',
  'otun_maximum_payable_hours',
];

const coreUnitsValidation = [
  'otun_id_tasstagetype',
  'otun_prerequisite',
  'otun_elective_category',
  'ou_id_orgqual',
  'ou_id_unit_type',
  'prerequisitesUnit',
  'otun_minimum_payable_hours',
  'otun_maximum_payable_hours',
];

const tasUnitValidation = [
  'core-and-elective-components',
  'mode-of-delivery',
  'assessment-resources',
  'learning-resources',
  'human-resources',
  'industry-engagements',
];

const TasForm = () => {
  const {
    control,
    formState: {errors},
    getValues,
    // handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      // Training Product
      cohortName: '',
      courseDuration: '',
      deliveryDate: null,
      department: '',
      endDate: null,
      rtoName: '',
      rtoNumber: '',
      type: '',

      // Target Group
      targetGroup: '',

      // Entry Requirements
      rtoEntryRequirements: '',

      // Pathways
      creditTransfer: '',
      rtoPathways: '',
      studentLifecycle: '',

      // Duration and Scheduling
      totalCoreUnits: '',
      totalElectiveUnits: '',
      totalNominalHours: '',
      totalMinimumPayableHours: '',
      totalMaximumPayableHours: '',

      // Core and Elective Components
      stageName: '',
      totalStageCount: '',

      // Mode of Delivery
      assessmentDetails: '',

      // Assessment Resources
      assessmentStrategies: '',
      reasonableAdjustment: '',
      assessmentValidation: '',

      // Learning Resources
      learningResources: '',

      // Human Resources
      humanResources: '',

      // Industry Engagements
      industryEngagements: '',
    },
    resolver: yupResolver(tasSchema),
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const {taskStatus, task, isListLoading, error, user} =
    useSelector(taskSelector);

  const {orgClassId} = useParams();
  // const [isPublishDisabled, setIsPublishDisabled] = useState(true);
  const [lastSavedTime, setLastSavedTime] = useState();
  const [units, setUnits] = useState([]);
  // const [tasStatus, setTasStatus] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [classDetails, setClassDetails] = useState(null);
  const [isDataCompleted, setIsDataCompleted] = useState([]);

  const [industryContacts, setIndustryContacts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [tasId, setTasId] = useState('');
  const [isModalOPen, setIsModalOpen] = useState(false);
  const [isVerficationModalOpen, setIsVerficationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [sections, setSections] = useState([
    {
      completedFieldCount: 0,
      id: 'training-product',
      isActive: true,
      label: 'Training Product',
      totalFieldCount: 6,
    },
    {
      completedFieldCount: 0,
      id: 'target-group',
      isActive: false,
      label: 'Target Group',
      totalFieldCount: 1,
    },
    {
      completedFieldCount: 0,
      id: 'entry-requirements',
      isActive: false,
      label: 'Entry Requirements',
      totalFieldCount: 3,
    },
    {
      completedFieldCount: 0,
      id: 'pathways',
      isActive: false,
      label: 'Pathways',
      totalFieldCount: 3,
    },
    {
      completedFieldCount: 3,
      id: 'duration-and-scheduling',
      isActive: false,
      label: 'Duration and Scheduling',
      totalFieldCount: 3,
    },
    {
      completedFieldCount: 0,
      id: 'core-and-elective-components',
      isActive: false,
      label: 'Core and Elective Components',
      totalFieldCount: 3,
    },
    {
      completedFieldCount: 0,
      id: 'mode-of-delivery',
      isActive: false,
      label: 'Mode of Delivery',
      totalFieldCount: 2,
    },
    {
      completedFieldCount: 0,
      id: 'assessment-resources',
      isActive: false,
      label: 'Assessment Resources',
      totalFieldCount: 4,
    },
    {
      completedFieldCount: 0,
      id: 'learning-resources',
      isActive: false,
      label: 'Learning Resources',
      totalFieldCount: 2,
    },
    {
      completedFieldCount: 0,
      id: 'human-resources',
      isActive: false,
      label: 'Human Resources',
      totalFieldCount: 2,
    },
    {
      completedFieldCount: 0,
      id: 'industry-engagements',
      isActive: false,
      label: 'Industry Engagements',
      totalFieldCount: 2,
    },
  ]);

  const [hiddenValues, setHiddenValues] = useState({
    durationAndScheduling: {
      packagingRules: '',
    },
    entryRequirements: {
      entryRequirements: '',
    },
    trainingProduct: {
      licensingInfo: '',
      qualification: '',
      trainingPackage: '',
      trainingProductDescription: '',
    },
  });

  const updateCompletedFieldCount = useCallback(data => {
    setTimeout(
      () =>
        Object.keys(sectionFieldsMap)?.forEach(section => {
          let completedFieldCount = 0;

          sectionFieldsMap[section]?.forEach(field => {
            if (
              typeof data[field] === 'object' && !(data[field] instanceof Date)
                ? data[field]?.value
                : data[field]
            ) {
              completedFieldCount += 1;
            }
          });
          setSections(previousValue => {
            let isCompleted = true;
            return previousValue?.map(item => {
              if (isCompleted) {
                if (item.id === section) {
                  isCompleted = item.totalFieldCount === completedFieldCount;
                } else {
                  isCompleted =
                    item.totalFieldCount === item.completedFieldCount;
                }
              }

              // if (index === previousValue.length - 1 && isCompleted) {
              //   setIsPublishDisabled(false);
              // } else setIsPublishDisabled(true);

              return item.id === section
                ? {...item, completedFieldCount}
                : item;
            });
          });
        }),
      0,
    );
  }, []);

  const onSubmit = taskData => {
    if (!isDataCompleted?.length) {
      setShowError(false);
      axios.post(`/api/admin/tas/${orgClassId}/publish`, taskData).then(() => {
        history.push('/admin/tas');
      });
    }
    return setShowError(true);
  };

  const onDownload = () => {
    if (!downloadLoader) {
      axios
        .get(`/api/admin/tas/${orgClassId}/download`, {
          responseType: 'blob',
        })
        .then(res => {
          const pdfBlob = new Blob([res.data], {type: 'application/pdf'});
          saveAs(pdfBlob, `TAS_${orgClassId}_${new Date().getTime()}.pdf`);
          setDownloadLoader(false);
        });
    }
  };

  useEffect(() => {
    const value = [];
    units.forEach(unit => {
      Object.keys(unit).forEach(field => {
        if (unit.otun_unit_type === 'Elective') {
          if (
            // please add in array if the fields are not mandatory
            !electiveUnitsValidation.includes(field) &&
            !(unit[field] === 0 || unit[field])
          ) {
            if (!value.includes('core-and-elective-components')) {
              value.push('core-and-elective-components');
            }
          }
        } else if (
          // please add in array if the fields are not mandatory
          !coreUnitsValidation.includes(field) &&
          !(unit[field] === 0 || unit[field])
        ) {
          if (!value.includes('core-and-elective-components')) {
            value.push('core-and-elective-components');
          }
        }

        if (
          field === 'assessmentResources' &&
          !unit[field].length &&
          !value.includes('assessment-resources')
        ) {
          value.push('assessment-resources');
        }
        if (
          field === 'learningResources' &&
          !unit[field].length &&
          !value.includes('learning-resources')
        ) {
          value.push('learning-resources');
        }
        if (
          field === 'humanResources' &&
          !unit[field].length &&
          !value.includes('human-resources')
        ) {
          value.push('human-resources');
        }
        if (
          field === 'trainingMethods' &&
          !unit[field].length &&
          !value.includes('mode-of-delivery')
        ) {
          value.push('mode-of-delivery');
        }
      });
    });
    industryContacts.forEach(industryContact => {
      Object.keys(industryContact).forEach(field => {
        if (!industryContact[field]) {
          if (!value.includes('industry-engagements')) {
            value.push('industry-engagements');
          }
        }
      });
    });

    setIsDataCompleted(value);
  }, [units, industryContacts]);

  const onUpdate = useCallback(async () => {
    let data = getValues();
    data = {
      // Training Product
      otv_cohort_name: data.cohortName,
      otv_course_dur: data.courseDuration?.value,
      otv_delivery_date: data.deliveryDate,
      otv_department: data.department,
      otv_end_date: data.endDate,
      otv_rto_name: data.rtoName,
      otv_rto_no: data.rtoNumber,
      otv_type: data.type,

      // Target Group
      otv_target_group: data.targetGroup,

      // Entry Requirements
      otv_literacy: data.localStudentsLiteracy,
      otv_numeracy: Number(data.localStudentsNumeracy),
      otv_rto_entry_requirements: data.rtoEntryRequirements,

      // Pathways
      otv_credit_transfer: data.creditTransfer,
      otv_rto_pathways: data.rtoPathways,
      otv_student_lifecycle: data.studentLifecycle,

      // Duration and Scheduling
      // otv_core_unit_count: data.totalCoreUnits || null,
      // otv_elective_unit_count: data.totalElectiveUnits || null,
      // otv_nominal_hours: data.totalNominalHours || null,

      // Core and Elective Components
      otv_stage_name: data.stageName?.value,
      otv_stage_count: data.totalStageCount?.value || null,

      // Mode of Delivery
      otv_assessment_resources: data.assessmentDetails,

      // Assessment Resources
      otv_assessment_strategies: data.assessmentStrategies,
      otv_reasonable_adjustment: data.reasonableAdjustment,
      otv_assessment_validation: data.assessmentValidation,

      // Learning Resources
      otv_learning_resources: data.learningResources,

      // Human Resources
      otv_human_resources: data.humanResources,

      // Industry Engagement
      otv_industry_engagements: data.industryEngagements,
    };

    const {
      data: {tasValues, orgClass},
    } = await axios.patch(`/api/admin/update-tas-values/${orgClassId}`, data);

    setLastSavedTime(
      moment(new Date(tasValues.otv_updated_at)).format('H:mm A MMM D YYYY'),
    );
    setClassDetails(orgClass);
    updateCompletedFieldCount(tasValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgClassId]);

  const setActiveSection = useCallback(sectionId => {
    setSections(previousValue =>
      previousValue.map(item =>
        item.id === sectionId
          ? {...item, isActive: true}
          : {...item, isActive: false},
      ),
    );
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setActiveSectionFn = useCallback(debounce(setActiveSection, 300), []);
  const checkElementVisibility = useCallback(element => {
    const rectangle = element.getBoundingClientRect();
    const absoluteTop = rectangle.top < 0 ? rectangle.top * -1 : rectangle.top;
    return absoluteTop < rectangle.height && rectangle.top < 120;
  }, []);

  useEffect(() => {
    if (orgClassId) {
      axios
        .get(`/api/admin/tas/${orgClassId}`)
        .then(({data: {tas, orgClass}}) => {
          setIsLoading(false);
          setLastSavedTime(
            moment(new Date(tas?.otas_updatedat)).format('h:mm A MMM D YYYY'),
          );
          setClassDetails(orgClass);
          setTasId(tas?.otas_id_orgtas);
          // setTasStatus(task?.task_current_taskstatus);
          // Training Product
          setValue('cohortName', tas?.otv_cohort_name);
          setValue('courseDuration', {
            label: tas?.otv_course_dur,
            value: tas?.otv_course_dur,
          });
          setValue(
            'deliveryDate',
            tas?.otv_delivery_date ? new Date(tas?.otv_delivery_date) : null,
          );
          setValue('department', tas?.otv_department);
          setValue(
            'endDate',
            tas?.otv_end_date ? new Date(tas?.otv_end_date) : null,
          );
          setValue('rtoName', tas?.otv_rto_name);
          setValue('rtoNumber', tas?.otv_rto_no);
          setValue('type', tas?.otv_type);

          // Target Group
          setValue('targetGroup', tas?.otv_target_group);

          // Entry Requirements
          setValue('localStudentsLiteracy', tas?.otv_literacy);
          setValue('localStudentsNumeracy', tas?.otv_numeracy);
          setValue('rtoEntryRequirements', tas?.otv_rto_entry_requirements);

          // Pathways
          setValue('creditTransfer', tas?.otv_credit_transfer);
          setValue('rtoPathways', tas?.otv_rto_pathways);
          setValue('studentLifecycle', tas?.otv_student_lifecycle);

          // Duration and Scheduling
          setValue('totalCoreUnits', tas?.otv_core_unit_count);
          setValue('totalElectiveUnits', tas?.otv_elective_unit_count);
          setValue('totalNominalHours', tas?.otv_nominal_hours);
          setValue('totalMinimumPayableHours', tas?.otv_minimum_payable_hours);
          setValue('totalMaximumPayableHours', tas?.otv_maximum_payable_hours);

          // Core and Elective Components
          setValue('stageName', {
            label: tas?.otv_stage_name,
            value: tas?.otv_stage_name,
          });
          setValue('totalStageCount', {
            label: tas?.otv_stage_count,
            value: tas?.otv_stage_count,
          });

          // Mode of Delivery
          setValue('assessmentDetails', tas?.otv_assessment_resources);

          // Assessment Resources
          setValue('assessmentStrategies', tas?.otv_assessment_strategies);
          setValue('reasonableAdjustment', tas?.otv_reasonable_adjustment);
          setValue('assessmentValidation', tas?.otv_assessment_validation);

          // Learning Resources
          setValue('learningResources', tas?.otv_learning_resources);

          // Human Resources
          setValue('humanResources', tas?.otv_human_resources);

          // Industry Engagements
          setValue('industryEngagements', tas?.otv_industry_engagements);

          setHiddenValues({
            durationAndScheduling: {
              packagingRules: tas?.otv_packaging_rules || '',
              anscoCode: tas?.otv_ansco_code || '',
              asedQualification: tas?.otv_ased_qualification,
              courseLevel: tas?.otv_course_level,
              totalCoreUnits: tas?.otv_core_unit_count,
              totalElectiveUnits: tas?.otv_elective_unit_count,
              totalNominalHours: tas?.otv_nominal_hours,
              totalMinimumPayableHours: tas?.otv_minimum_payable_hours,
              totalMaximumPayableHours: tas?.otv_maximum_payable_hours,
            },
            entryRequirements: {
              entryRequirements: tas?.otv_entry_requirements || '',
            },
            trainingProduct: {
              licensingInfo: tas?.otv_licensing_info || '',
              qualification: tas?.otv_qualification || '',
              trainingPackage: tas?.otv_training_package || '',
              trainingProductDescription:
                tas?.otv_training_product_description || '',
              rtoName: tas?.otv_rto_name,
              rtoNumber: tas?.otv_rto_no,
            },
          });

          updateCompletedFieldCount(tas);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgClassId]);

  useEffect(() => {
    const tasFormMain = document?.getElementById('tas-form-main');

    const listener = () => {
      sectionIdsInOrder.forEach((id, index) => {
        const previousElementId = sectionIdsInOrder[index - 1];
        const previousElement = index
          ? document.getElementById(previousElementId)
          : null;
        const element = document.getElementById(id);

        const isPreviousElementInView = previousElement
          ? checkElementVisibility(previousElement)
          : null;
        const isElementInView = checkElementVisibility(element);

        if (isPreviousElementInView && !isElementInView) {
          setActiveSectionFn(previousElementId);
        } else if (isElementInView) setActiveSectionFn(id);
      });
    };

    tasFormMain?.addEventListener('scroll', listener);
    return () => tasFormMain?.removeEventListener('scroll', listener);
  }, [checkElementVisibility, setActiveSectionFn]);

  useEffect(() => {
    dispatch(fetchAllTaskStatus());
    dispatch(fetchAllUser());
  }, [dispatch]);

  useEffect(() => {
    if (tasId) {
      dispatch(fetchTaskWithAllTaskLogs(tasId));
    }
  }, [dispatch, tasId]);

  let taskLogStatus;
  function renderStatus(status) {
    if (status === 1) {
      taskLogStatus = (
        <>
          <div>Pending</div>
          <img src={PendingStatusIcon} alt="pending" />
        </>
      );
    } else if (status === 2) {
      taskLogStatus = (
        <>
          <div>Assigned For Verification</div>
          <img src={AssignedStatusIcon} alt="assigned" />
        </>
      );
    } else if (status === 3) {
      taskLogStatus = (
        <>
          <div>Verfication Approved</div>
          <img src={ApprovedStatusIcon} alt="approved" />
        </>
      );
    } else if (status === 4) {
      taskLogStatus = (
        <>
          <div>Verfication Rejected</div>
          <img src={RejectedStatusIcon} alt="rejected" />
        </>
      );
    } else if (status === 5) {
      taskLogStatus = (
        <>
          <div>Verfication Completed</div>
          <img src={CompletedStatusIcon} alt="competed" />
        </>
      );
    }
    return taskLogStatus;
  }

  const volumeOfLearning = useMemo(() => {
    let values = {};

    if (units.length) {
      units.forEach(unit => {
        values = {
          deliveryHours:
            (values.deliveryHours || 0) + Number(unit.otun_delivery_hours || 0),

          nonFaceHours:
            (values.nonFaceHours || 0) + Number(unit.otun_nonf2f_hours || 0),
        };
      });
    }
    return values;
  }, [units]);

  return (
    <>
      <TasFormContainer>
        <ChangeHistoryModal
          tasid={tasId}
          show={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          title={`Modification History - ${classDetails?.oc_class}`}
        />
        <VerificationHistoryModal
          taskLogs={task?.taskLog}
          show={isVerficationModalOpen}
          onHide={() => setIsVerficationModalOpen(false)}
          title="Verification history"
          isLoading={isListLoading}
          error={error}
        />
        {isLoading ? (
          <Skeleton height="5rem" width="100%" />
        ) : (
          <TasFormHeader>
            <div>
              <button
                onClick={() => history.replace('/admin/tas')}
                type="button"
              >
                <img alt="Go Back" src={PageBackIcon} />
              </button>
              <h2>
                {classDetails?.oc_class}{' '}
                <span>- Training and Assessment Strategy</span>
              </h2>
              <button
                className="task-status"
                type="button"
                onClick={() => {
                  setIsVerficationModalOpen(true);
                }}
              >
                <div className={`status-${task?.task_current_taskstatus}`}>
                  {renderStatus(task?.task_current_taskstatus)}
                </div>
              </button>
            </div>
            <div>
              {lastSavedTime ? (
                <div>
                  <span>Last saved</span>
                  <span>{lastSavedTime}</span>
                </div>
              ) : null}
              <div>
                <button
                  disabled={!tasId}
                  type="button"
                  onClick={() => setIsModalVisible(!isModalVisible)}
                >
                  Audit Trail
                </button>
              </div>
              {/* <div>
              <button
                disabled={!tasId}
                type="button"
                onClick={() => setIsChangeHistoryModalVisible(!isModalVisible)}
              >
                Change History
              </button>
            </div> */}
              {task?.task_current_taskstatus !== 5 ? (
                <Button
                  isDisabled={!!isDataCompleted?.length}
                  // label="Publish"
                  // isDisabled={isPublishDisabled}
                  label="Submit"
                  // onClick={handleSubmit(onSubmit)}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                />
              ) : (
                <Button
                  label={downloadLoader === false ? 'Download' : null}
                  onClick={() => {
                    onDownload();
                    setDownloadLoader(true);
                  }}
                  isLoading={downloadLoader}
                />
              )}
            </div>
          </TasFormHeader>
        )}

        <main>
          {' '}
          <TasFormSidebar>
            {isLoading ? (
              <>
                <Spacer height="2rem" />
                <Skeleton height="37rem" width="100%" />
              </>
            ) : (
              <>
                <Spacer height="2rem" />
                {sections?.map(section => {
                  let StatusIcon;
                  let completedFieldCountUpdate = section.completedFieldCount;
                  if (tasUnitValidation.includes(section.id)) {
                    if (!isDataCompleted.includes(section.id)) {
                      completedFieldCountUpdate =
                        section.completedFieldCount + 1;
                    } else {
                      completedFieldCountUpdate = section.completedFieldCount;
                    }
                  }

                  if (completedFieldCountUpdate === section.totalFieldCount) {
                    if (section.isActive) StatusIcon = CheckFillIcon;
                    else StatusIcon = CheckOutlineIcon;
                  }

                  return (
                    <button
                      className={section.isActive ? 'is-active' : ''}
                      key={section.id}
                      onClick={() => {
                        setActiveSection(section.id);
                        document
                          .getElementById(section.id)
                          .scrollIntoView({behavior: 'smooth'});
                      }}
                      type="button"
                    >
                      <span className="section-label">{section.label}</span>
                      {StatusIcon ? (
                        <img alt="Status" src={StatusIcon} />
                      ) : (
                        <span className="section-status">
                          {completedFieldCountUpdate}/{section.totalFieldCount}
                        </span>
                      )}
                    </button>
                  );
                })}
                <Spacer height="2rem" />
              </>
            )}
          </TasFormSidebar>
          <TasFormMain id="tas-form-main">
            {isLoading ? (
              <>
                <Spacer height="2rem" />
                <Skeleton height="45rem" width="100%" />
              </>
            ) : (
              <>
                <Spacer height="2rem" />
                <TrainingProductForm
                  control={control}
                  errors={errors}
                  hiddenValues={hiddenValues.trainingProduct}
                  onUpdate={onUpdate}
                  setValue={setValue}
                  watch={watch}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <TargetGroupForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <EntryRequirementsForm
                  control={control}
                  errors={errors}
                  hiddenValues={hiddenValues.entryRequirements}
                  onUpdate={onUpdate}
                  setValue={setValue}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <PathwaysForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <DurationAndSchedulingForm
                  control={control}
                  errors={errors}
                  hiddenValues={hiddenValues.durationAndScheduling}
                  onUpdate={onUpdate}
                  tasStatus={task?.task_current_taskstatus === 5}
                  volumeOfLearning={volumeOfLearning}
                />
                <CoreAndElectiveComponentsForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  setUnits={setUnits}
                  units={units}
                  watch={watch}
                  showError={showError}
                  isDataCompleted={isDataCompleted}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <ModeOfDeliveryForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  setUnits={setUnits}
                  units={units}
                  showError={showError}
                  isDataCompleted={isDataCompleted}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <AssessmentResourcesForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  setUnits={setUnits}
                  units={units}
                  showError={showError}
                  isDataCompleted={isDataCompleted}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <LearningResourcesForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  setUnits={setUnits}
                  units={units}
                  showError={showError}
                  isDataCompleted={isDataCompleted}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <HumanResourcesForm
                  control={control}
                  errors={errors}
                  onUpdate={onUpdate}
                  setUnits={setUnits}
                  units={units}
                  showError={showError}
                  isDataCompleted={isDataCompleted}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <IndustryEngagementsForm
                  control={control}
                  setIndustryContacts={setIndustryContacts}
                  industryContacts={industryContacts}
                  errors={errors}
                  onUpdate={onUpdate}
                  showError={showError}
                  isDataCompleted={isDataCompleted}
                  tasStatus={task?.task_current_taskstatus === 5}
                />
                <Spacer height="6rem" />
              </>
            )}
          </TasFormMain>
        </main>
        <ReactTooltip
          className="adp-tooltip--component"
          id="global"
          place="right"
          html
        />
      </TasFormContainer>
      <TasSubmissionModal
        isVisible={isModalOPen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        taskStatus={taskStatus}
        onButtonClick={data => {
          onSubmit(data);
        }}
        user={user}
      />
    </>
  );
};

export default TasForm;
