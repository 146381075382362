/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
import React, {useEffect, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import ReactTooltip from 'react-tooltip';
import {Helmet} from 'react-helmet';
import {showToast} from '../../../../../../components/common/Toast';
import CloseIcon from '../../../../../../assets/icons/closeIcon/Icon/X.svg';
import DisabledImg from '../../../../../../assets/icons/disabled-close.svg';

import {AllDeparmentsContainer} from './elements';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {Modal, Table, EmptyState} from '../../../../../../components/common';

import {
  getAllDepartments,
  deleteDepartment,
  departmentsSelector,
} from '../../../../../../redux/departmentsSlice';
import {AddDepartmentsModal} from './components';

const DELAY = 500;

export const Departments = () => {
  const dispatch = useDispatch();

  const {isListLoading, isDepartmentsLoading, departments} =
    useSelector(departmentsSelector);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteName, setDeleteName] = useState();

  useEffect(() => {
    dispatch(getAllDepartments());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'index',
        className: 'index',
      },
      {
        Header: 'Department Name',
        accessor: 'od_department_name',
        className: 'department-name',
      },

      {
        Header: '',
        Cell: ({value: deleteDepartmentId, row: {original}}) => {
          const departmentName = original?.od_department_name;
          const orgClasses = original?.orgClasses;

          return (
            <div>
              {orgClasses?.length > 0 ? (
                <ReactTooltip
                  backgroundColor="#3d4457"
                  effect="solid"
                  place="top"
                />
              ) : null}
              <button
                type="button"
                data-html
                data-tip={
                  orgClasses?.length > 0
                    ? `<span className="tooltip-value">Department cannot be deleted, one or more classes exists in this department </span>`
                    : null
                }
                onClick={() => {
                  if (orgClasses?.length === 0) {
                    setDeleteId(deleteDepartmentId);
                    setDeleteName(departmentName);
                  }
                }}
                className="close-icon-btn"
              >
                <img
                  src={orgClasses?.length ? DisabledImg : CloseIcon}
                  alt="close"
                  id={deleteDepartmentId}
                />
              </button>
            </div>
          );
        },
        accessor: 'od_id_orgdepartment',
        className: 'close',
      },
    ],

    [],
  );
  // Add a serial number to each row and memoize the data.
  const Data = useMemo(
    () => [
      ...(departments || []).map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [departments],
  );

  return (
    <>
      <Helmet>
        <title>Departments | JungleCat</title>
      </Helmet>

      <AllDeparmentsContainer>
        <ListingPageHeader
          buttonLabel="Add Department"
          className="mb-4 department-header"
          onButtonClick={() => {
            setIsAddModalOpen(true);
          }}
          title="Departments"
        />
        <div className="table-container">
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading &&
          Array.isArray(departments) &&
          !departments?.length ? (
            <EmptyState
              description="It appears that no departments have been added to the organisation."
              title="There are no departments yet!"
            />
          ) : null}
          {!isListLoading && departments?.length ? (
            <Table
              columns={columns}
              data={Data}
              isSortedBy
              itemName="Departments"
              maxWidth="55.5rem"
            />
          ) : null}
        </div>

        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this department?"
          isDelete
          isButtonLoading={isDepartmentsLoading}
          loadingButtonLabel="Deleting..."
          onButtonClick={() => {
            if (!isDepartmentsLoading) {
              dispatch(
                deleteDepartment(deleteId, () => {
                  setDeleteId(null);
                  showToast(false, `${deleteName} has been deleted.`, true);

                  setTimeout(() => {
                    setDeleteName('');
                  }, DELAY);
                }),
              );
            }
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
        <AddDepartmentsModal
          isOpen={isAddModalOpen}
          buttonLabel="Add Departments"
          description="Add departments to organisation"
          isButtonLoading={isDepartmentsLoading}
          loadingButtonLabel="Adding..."
          title="Add Department"
          setIsAddModalOpen={setIsAddModalOpen}
        />
      </AllDeparmentsContainer>
    </>
  );
};
