import styled from 'styled-components';

import {Colors} from '../../../../../constants';

const TimeIntervalContainer = styled.div`
  :not(:last-of-type) {
    margin-bottom: 51px;
  }

  .time {
    color: ${Colors.LightText};
    font-size: 12px;
    line-height: 19px;
    text-align: right;
    width: 32px;
  }

  .time-indicator {
    border-bottom: 1px dashed ${Colors.Border};
  }
`;

export default TimeIntervalContainer;
