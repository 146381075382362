import React, {useRef, useState, useEffect, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useSelector, useDispatch} from 'react-redux';
import {useLocalStorage} from 'react-use';
import {useHistory} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import PaginationSpinnerIcon from '../../../../assets/icons/pagination-spinner.svg';
import {
  assessmentSelector,
  fetchAllAssessment,
} from '../../../../redux/assessmentSlice';
import {WorkContainer} from './elements';
import EmptyState from '../../../../components/shared/EmptyState';
import {AssessmentCard} from '../../components';
import {fetchAllStream, streamSelector} from '../../../../redux/streamSlice';

export const Works = () => {
  const [userType] = useLocalStorage('jc-user-type', '', {
    raw: true,
  });
  const intersectedElementObserver = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [key] = useState('assessments');
  const [pageTitle, setPageTitle] = useState('Works');
  const [classID, setClassID] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const {pathname} = history.location;
  const {
    assessmentCount,
    assessment,
    isPaginationLoading,
    isAssessmentLoading,
  } = useSelector(assessmentSelector);

  const {classDetails} = useSelector(streamSelector);
  const intersectedElement = useCallback(
    node => {
      if (intersectedElementObserver.current) {
        intersectedElementObserver.current.disconnect();
      }

      intersectedElementObserver.current = new window.IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            if (!isPaginationLoading && assessmentCount > assessment?.length) {
              dispatch(
                fetchAllAssessment(
                  classID,
                  // assessment?.length,
                  Number(userType),
                ),
              );
            }
          }
        },
      );

      if (node) {
        intersectedElementObserver.current.observe(node);
      }
    },

    [
      assessment?.length,
      assessmentCount,
      classID,
      dispatch,
      isPaginationLoading,
      userType,
    ],
  );

  useEffect(() => {
    if (/stream/.test(pathname)) {
      const classId = pathname.split('/')[2];
      setClassID(classId);
      dispatch(fetchAllStream(classId, Number(userType)));
      dispatch(
        fetchAllAssessment(classId, null, Number(userType), () =>
          setIsLoading(false),
        ),
      );
    }
  }, [dispatch, pathname, userType]);

  useEffect(() => {
    switch (key) {
      case 'allworks':
        setPageTitle('All Works');
        break;
      case 'assessments':
        setPageTitle('Assessments');
        break;
      case 'quiz':
        setPageTitle('Quiz');
        break;
      case 'question':
        setPageTitle('Question');
        break;
      case 'material':
        setPageTitle('Material');
        break;
      default:
        setPageTitle('Works');
    }
  }, [key]);

  const sortedAssessments = [...assessment];
  return (
    <>
      <Helmet>
        <title>{pageTitle} | JungleCat</title>
      </Helmet>
      <WorkContainer className="row position-relative">
        <div className="work-container">
          <h2
            style={{
              marginBottom: '1rem',
            }}
          >
            Works
          </h2>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isLoading && sortedAssessments.length ? (
            sortedAssessments.map((assessments, index) => {
              const gradedCount = (
                assessments.students ||
                assessments.activityOrgUnitInstance[0].students
              ).filter(student => student.aouis_status === 3).length;
              const isIntersectedElement =
                index === sortedAssessments.length - 2;
              const submittedCount = (
                assessments.students ||
                assessments.activityOrgUnitInstance[0].students
              ).filter(student => student.aouis_status === 2).length;

              return (
                <AssessmentCard
                  assessment={assessments}
                  classID={classID}
                  gradedCount={gradedCount}
                  intersectedElement={intersectedElement}
                  isIntersectedElement={isIntersectedElement}
                  key={assessments.ac_id_activity}
                  submittedCount={submittedCount}
                  isAssessmentLoading={isAssessmentLoading}
                  isArchived={classDetails?.orui_is_archived}
                />
              );
            })
          ) : isLoading ? (
            <div>
              <div className="mb-3">
                <Skeleton height={223} width="100%" />
              </div>
              <div className="mb-3">
                <Skeleton height={223} width="100%" />
              </div>
              <div className="mb-3">
                <Skeleton height={223} width="100%" />
              </div>
              <div className="mb-3">
                <Skeleton height={223} width="100%" />
              </div>
            </div>
          ) : (
            <EmptyState
              body="It seems that no works have been added to this class yet. Add a work to get started with evaluating your students' performance."
              title="No Works Added"
            />
          )}
          {isPaginationLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 24,
              }}
            >
              <img
                alt="Paginating"
                src={PaginationSpinnerIcon}
                style={{
                  animation: 'rotating 1s linear infinite',
                  height: 32,
                  width: 32,
                }}
              />
            </div>
          ) : null}
        </div>
      </WorkContainer>
    </>
  );
};
