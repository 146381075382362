/* eslint-disable react/prop-types */

import moment from 'moment';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showToast} from '../../../../../../../../../../../../components/common/Toast';
import {TableCellLink} from '../../../../../../../../../../../../components/common/Table/styles';
import {handleFileDownload} from '../../../../../../../../../../../../utils/downloadFile';

import {Button} from '../../../../../../../../../../../../components/common';
import ViewClassImg from '../../../../../../../../../../../../assets/icons/viewclass.svg';
import CurriculumDocumentIcon from '../../../../../../../../../../../../assets/icons/CurriculumDocument.svg';
import CurriculumAudioIcon from '../../../../../../../../../../../../assets/icons/CurriculumAudio.svg';
import CurriculumImageIcon from '../../../../../../../../../../../../assets/icons/CurriculumImage.svg';
import CurriculumVideoIcon from '../../../../../../../../../../../../assets/icons/CurriculumVideo.svg';
import CurriculumDownloadIcon from '../../../../../../../../../../../../assets/icons/curriculum-download-icon.svg';

import {
  getCurriculumByOrgUnitInstanceId,
  publishCurriculumModuleFileById,
  teacherCurriculumSelector,
} from '../../../../../../../../../../../../redux/teacherCurriculumSlice';

import UpdateIcon from '../../../../../../../../../../../../assets/icons/updateadmintoast.svg';

// const DELAY = 500;

export const FileDetail = ({files, show, position, orgUnitInstanceId}) => {
  const {isLoading} = useSelector(teacherCurriculumSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  function IconforFile(type) {
    let instructionIcon;

    if (type === 'Voice') {
      instructionIcon = CurriculumAudioIcon;
    } else if (type === 'Document') {
      instructionIcon = CurriculumDocumentIcon;
    } else if (type === 'Image') {
      instructionIcon = CurriculumImageIcon;
    } else if (type === 'Video') {
      instructionIcon = CurriculumVideoIcon;
    } else {
      instructionIcon = CurriculumDocumentIcon;
    }
    return instructionIcon;
  }

  return (
    <div>
      {show ? (
        <div className="file-container">
          {files.map((data, index) => (
            <div
              className="file-detail"
              key={data?.ocrf_id_course_curriculum_file}
            >
              <div className="d-flex mb-0 name-container">
                <p className="mb-0 fileposition mr-5">{`${position}.${
                  index + 1
                }`}</p>
                <div className="d-flex align-items-center">
                  <img
                    className="mb-0 fileicon mr-3"
                    alt="arrow"
                    src={IconforFile(data?.ft_type)}
                  />
                  <p className="mb-0 filename">{data?.fi_text_content}</p>
                </div>
              </div>

              <div className="d-flex btn-container justify-content-end">
                <div className="d-flex align-items-center ">
                  <div className="date">
                    Uploaded on{' '}
                    {moment(data?.occf_created_at).format('DD/MM/YYYY')}
                  </div>
                  {data?.occf_is_published === 1 ? (
                    <div className="published-badge">Published</div>
                  ) : (
                    <Button
                      className="button add-button"
                      onClick={() => {
                        if (!isLoading) {
                          dispatch(
                            publishCurriculumModuleFileById(
                              orgUnitInstanceId,
                              data?.occf_id_class_curriculum_file,
                              () => {
                                showToast(
                                  UpdateIcon,
                                  `${data?.fi_text_content} file Published successfully `,
                                  false,
                                );
                                dispatch(
                                  getCurriculumByOrgUnitInstanceId(
                                    orgUnitInstanceId,
                                  ),
                                );
                              },
                            ),
                          );
                        }
                      }}
                      id="publish-now-id"
                      isFullWidth={false}
                      label="Publish Now"
                    />
                  )}
                </div>
                <div className="container-second d-flex align-items-center">
                  <button
                    type="button"
                    className="button-download d-flex align-items-center"
                    title="download"
                    onClick={() => {
                      handleFileDownload({
                        filePath: `stream-${data?.fi_S3_filename}`,
                        fileName: data?.fi_filename,
                      });
                    }}
                    id="download-id"
                  >
                    <img
                      className="mb-0 downloadicon"
                      alt="arrow"
                      src={CurriculumDownloadIcon}
                    />
                  </button>
                  <TableCellLink
                    onClick={() => {
                      history.push(
                        `curriculum/viewFileToSharedClass/${data?.occf_id_class_curriculum_file}`,
                      );
                    }}
                    type="button"
                    className="view-module ml-4"
                    id="view-id"
                  >
                    <span> View </span>
                    <img
                      src={ViewClassImg}
                      alt="loader"
                      className="viewmodule-arrow"
                    />
                  </TableCellLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
