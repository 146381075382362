import styled from 'styled-components';

export const BasicDetailsContainer = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  width: 100%;
  @media (max-width: 56.25rem) {
    & {
      width: 100%;
    }
  }

  .basic-details-header {
    padding: 1.5rem;
    h3 {
      color: #3d4457;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .basic-details-description {
      color: #6d7579;
      font-size: 1rem;
      padding-bottom: 1rem;
      margin-bottom: unset;
    }
    .published-text {
      font-size: 0.875rem;
      color: #6d7579;
      margin: 0.75rem 0 0 0;
    }
    .published-time {
      font-size: 0.875rem;
      color: #39ca84;
    }
    .outline-container {
      width: 100%;
      display: flex;
      align-items: center;
      border: 0.0625rem solid #e6e7e8;
      box-sizing: border-box;
      border-radius: 0.5rem;

      .basic-details-container {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem;
        @media (max-width: 33rem) {
          & {
            display: flex;
            flex-direction: column;
            padding: 1rem;
          }
          .time-container {
            width: 100%;
            border: unset;
            justify-content: flex-start;
          }
          .duedate-container,
          .assigned-student-container,
          .location-container {
            width: 100%;
            border: unset;
            align-items: center;
            justify-content: flex-start;
            margin-top: 1rem;
          }
        }
      }
      .time-container,
      .duedate-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15%;
        border-right: 1px solid #e6e7e8;
      }
      .assigned-student-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e6e7e8;
        width: 30%;
      }
      .location-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
      }
      .text-data {
        font-size: 0.875rem;
        color: #0c181f;
      }
    }
  }
  .seperator {
    height: 0.0625rem;
    background-color: #dedede;
    width: 100%;
  }
  .basic-datail-footer {
    .accordion {
      width: 100%;
      .card {
        border: unset;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
      }
      .card-header {
        padding: 0.75rem 0.875rem;
        background-color: unset;
        &.clicked {
          background-color: #fbfafa;
        }
      }
      .card-body {
        padding: 1rem 1.5rem;
      }
      .accordion-header {
        display: flex;
        align-items: center;
      }
      .btn {
        line-height: 1;
        font-weight: 500;
        font-size: 0.875rem;
        color: #0c181f;
        :focus {
          outline: 0;
          box-shadow: 0 0 0 0.2rem transparent;
        }
        .arrow {
          width: 1.1rem;
          height: 1.1rem;
        }
        .resource {
          margin-right: 0.93rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    .student-instruction {
      display: flex;
      padding: 0 0 1rem 0;
      border-bottom: 0.0625rem solid #e6e7e8;
    }
    .required-resources {
      display: flex;
      padding: 1rem 0;
      border-bottom: 0.0625rem solid #e6e7e8;
    }
    .additional-resources {
      display: flex;
      padding: 1rem 0 0 0;
    }
    .instruction-header {
      width: 40%;
      font-size: 0.875rem;
      color: #0c181f;
    }
    .instruction-content {
      width: 55%;
      font-size: 0.875rem;
      color: #6d7579;
    }
    @media (max-width: 33rem) {
      .student-instruction,
      .required-resources,
      .additional-resources {
        display: flex;
        flex-direction: column;
      }
      .instruction-header {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      .instruction-content {
        width: 100%;
      }
    }
  }
`;
