/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import classNames from 'classnames';

import MappedArrowIcon from '../../../../../../../../../../assets/icons/instructions-arrow.svg';
import {Hr} from '../../../../../../../../../../components/shared/Hr';
import {AddedMappedTaskContainer} from './elements';

// const MappedTaskData = [{}];
export const AddedMappedTask = ({
  performanceCriteriaName = '',
  performanceEvidenceName = '',
  knowledgeEvidenceName = '',
  assessmentConditionName = '',
}) => {
  const [mappedTaskOpen, setMappedTaskOpen] = useState(false);
  const convertName = fullName => {
    if (fullName) {
      const arr = fullName.split(' ');

      arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1).toLowerCase();

      const formattedName = arr.join(' ');

      return formattedName;
    }
    return '';
  };
  return (
    <AddedMappedTaskContainer>
      <div>
        <div className="pt-3 pb-3 px-4">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <div className="added-mapped-task">
                <span className="mapped-task-title">Mapped Task</span>
                <button
                  className={classNames('arrow-btn', {
                    'is-open': mappedTaskOpen,
                  })}
                  onClick={() =>
                    setMappedTaskOpen(previousValue => !previousValue)
                  }
                  type="button"
                >
                  <img alt="View Instructions" src={MappedArrowIcon} />
                </button>
              </div>
            </div>
          </div>
        </div>

        {mappedTaskOpen ? (
          <div>
            <Hr />
            <div className="content">
              <div className="title">Performance Criteria</div>
              <div className="map-data">{performanceCriteriaName}</div>
            </div>
            <Hr />
            {/* <div className="content">
              <div className="title">Foundation Skills</div>
              <div className="map-data">
                3.2 Reading skills to: locate information in food preparation
                lists and standard recipes to determine food preparation
                requirements locate and read date codes and rotation labels on
                food products.
              </div>
            </div>
            <Hr /> */}
            <div className="content">
              <div className="title">Performance Evidence</div>
              <div className="map-data">
                {convertName(performanceEvidenceName)}
              </div>
            </div>
            <Hr />
            <div className="content">
              <div className="title">Knowledge Evidence</div>
              <div className="map-data">
                {convertName(knowledgeEvidenceName)}
              </div>
            </div>
            <Hr />
            <div className="content">
              <div className="title">Assessment Condition</div>
              <div className="map-data">
                {convertName(assessmentConditionName)}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </AddedMappedTaskContainer>
  );
};
