/* eslint-disable react/prop-types */

import React from 'react';

import {StudentAssessmentEvaluationContainer} from './elements';

const Status = {
  No: 'No',
  Yes: 'Yes',
};

export const EvaluationStatus = ({status, criteria, criteriaId}) => {
  let evaluationStatusColor = '#f0f8ff';

  if (status === Status.Yes) {
    evaluationStatusColor = 'rgba(57, 202, 132, 0.12)';
  } else if (status === Status.No) {
    evaluationStatusColor = 'rgba(255, 126, 126, 0.12)';
  }

  return (
    <StudentAssessmentEvaluationContainer
      className="d-flex p-3 w-100"
      evaluationStatusColor={evaluationStatusColor}
    >
      <span className="criteria-id criteria-text mr-2">{criteriaId}</span>
      <span className="criteria-text">{criteria}</span>
    </StudentAssessmentEvaluationContainer>
  );
};
