/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */

import {useEffect, useMemo, useState, useRef} from 'react';
import {Controller} from 'react-hook-form';

import axios from '../../../../../../../../api';
// import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {Select, Table} from '../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import ColumnTableValue from './components/ColumnTableValue';
import LearningResourcesFormContainer from './elements/LearningResourcesFormContainer';
import ErrorMessage from '../ErrorMessage';

const LearningResourcesForm = ({
  control,
  errors,
  onUpdate,
  setUnits,
  units,
  tasStatus,
  showError,
  isDataCompleted,
}) => {
  const [learningResources, setLearningResources] = useState([]);
  const reselectRef = useRef();
  useEffect(() => {
    axios
      .get(`/api/admin/tas/learning-resources`)
      .then(({data: {tasLearningResources}}) => {
        setLearningResources(
          tasLearningResources.map(item => ({
            label: item.tlr_name,
            value: item.tlr_name,
          })),
        );
      });
  }, [units]);

  const acTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Cell: ({row: {original}, value}) => (
          <div className="column-table-value" style={{marginRight: '1.5rem'}}>
            {value} {original.otun_unit_name}
          </div>
        ),
        Header: 'Unit',
        accessor: 'otun_unit_code',
        className: 'unit',
      },
      {
        Cell: ({row: {original}, value}) => (
          <Select
            ref={reselectRef}
            className="table-input"
            isClearable={false}
            isLarge={false}
            isLightBorder
            isMulti
            isDisabled={tasStatus}
            onChange={async (event, {option, removedValue}) => {
              setUnits(previousValue =>
                previousValue.map(item =>
                  item.otun_id_orgtasunit === original.otun_id_orgtasunit
                    ? {...item, learningResources: event}
                    : item,
                ),
              );
              if (option) {
                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/unit-learning-resource`,
                  {
                    learningResource: option.value,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: value,
                  },
                );
              } else if (removedValue) {
                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/delete-unit-learning-resource`,
                  {
                    learningResource: removedValue.value,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: event,
                  },
                );
              }
            }}
            addIfNoOptions
            options={learningResources}
            placeholder=""
            value={value}
            // eslint-disable-next-line consistent-return
            onKeyDown={async event => {
              // On certain phones the enter key is not selecting the input text, so do it by hand.
              if (event.key === 'Enter') {
                const {value: targetValue} = event.target;
                const isExisting = learningResources.find(
                  method => method.value === targetValue,
                );

                if (!targetValue && isExisting) {
                  return false;
                }

                // event.preventDefault();
                setUnits(previousValue =>
                  previousValue.map(item =>
                    item.otun_id_orgtasunit === original.otun_id_orgtasunit
                      ? {
                          ...item,
                          learningResources: [
                            ...item.learningResources,
                            {
                              label: targetValue,
                              value: targetValue,
                            },
                          ],
                        }
                      : item,
                  ),
                );

                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/unit-learning-resource`,
                  {
                    learningResource: targetValue,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: value,
                  },
                );

                // if (request && request.data && request.data.status) {
                // setUnits(previousValue =>
                //   previousValue.map(item =>
                //     item.otun_id_orgtasunit === original.otun_id_orgtasunit
                //       ? {
                //           ...item,
                //           learningResources: [
                //             ...item.learningResources,
                //             {
                //               label: targetValue,
                //               value: targetValue,
                //             },
                //           ],
                //         }
                //       : item,
                //   ),
                // );
                // }
              }
            }}
          />
        ),
        Header: 'Learning Resource',
        accessor: 'learningResources',
        className: 'learning-resource',
      },
      {
        Cell: ({row: {original}, value}) => {
          let assessmentCondition = '';
          for (let index = 0; index < value.length; index += 1) {
            assessmentCondition +=
              value[index].otua_assessment_condition + '<br />';
          }

          return (
            <ColumnTableValue
              text={assessmentCondition || 'Not Available'}
              title="Assessment Condition"
              value={value}
              unitId={original?.otun_id_unit}
            />
          );
        },
        Header: 'Assessment Condition',
        accessor: 'assessmentConditions',
        className: 'assessment-condition',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [learningResources],
  );

  return (
    <LearningResourcesFormContainer className="section" id="learning-resources">
      <h3>Learning Resources</h3>
      <p>
        To ensure students are able to obtain and absorb the required knowledge
        and skills prior to assessment, carefully choose and plan the learning
        resources you will use to guide them.
        <br />
        Identify these resources in your strategy to ensure you obtain full
        coverage of all required areas.
      </p>
      <form>
        <div className="tas-form-row">
          <Controller
            control={control}
            name="learningResources"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.learningResources}
                hasInfo="<ul><li>Compare the physical resources required to deliver a training product with the resources available to your RTO. Many units of competency include detailed specifications of the resources required, so conducting this analysis at a unit of competency level ensures these requirements are addressed.</li></ul>"
                label="Learning and Physical Resources"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                rows={5}
                disabled={tasStatus}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            {showError && isDataCompleted?.includes('mode-of-delivery') ? (
              <ErrorMessage
                errorMessage="Learning Resources for units needed to be filled"
                className="error mb-2"
              />
            ) : null}
            <div className="column-table-title">
              Assessment Conditions
              {/* <img alt="Info" src={InfoIcon} /> */}
            </div>
            <Table
              columns={acTableColumns}
              data={units}
              isWithoutHeader
              isWithoutPagination
              showAllRows
            />
          </div>
        </div>
      </form>
    </LearningResourcesFormContainer>
  );
};

export default LearningResourcesForm;
