/* eslint-disable react/prop-types */
import React, {useMemo, useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
// import PrintIcon from '../../../../../../../assets/icons/print-icon.svg';

import {AssessmentSummaryContainer} from './elements';
import {
  GeneralSection,
  TasksSection,
  AssessmentSection,
  EvaluationSection,
  StudentsSection,
  ExportSpreadSheetButton,
  PrintAssessmentSummary,
} from './components';

export const AssessmentSummary = ({assessmentSummary, isArchived}) => {
  const assessment = useMemo(
    () => [
      {
        assessmentDescription: assessmentSummary?.ac_activity_description,
        studentInstruction: assessmentSummary?.ac_student_instructions,
        resourceRequired: assessmentSummary?.ac_resource_required,
      },
    ],
    [assessmentSummary],
  );
  const assesmentTasks = useMemo(
    () => assessmentSummary?.tasks,
    [assessmentSummary],
  );

  const studentsInUnits = useMemo(
    () => [
      {
        students: assessmentSummary?.instance?.students,
        totalStudentsInUnit: assessmentSummary?.instance?.totalStudents,
      },
    ],
    [assessmentSummary],
  );
  const studentsSubmission = useMemo(() => {
    const result = {};
    assessmentSummary?.tasks?.forEach(item => {
      if (Object.keys(item?.performanceCriteria || {}).length) {
        item.submissions.forEach(submission => {
          result[submission.up_id_userprofile] = submission;
        });
      }
    });
    return Object.values(result);
  }, [assessmentSummary]);

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: A4;
    margin: 11mm 17mm 17mm 17mm;
  }
  
  @media print {
    footer {
      position: fixed;
      bottom: 0;
    }
  
    .content-block, p {
      page-break-inside: avoid;
    }
  
    html, body {
      width: 210mm;
      height: 297mm;
    }
  }
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
  });

  return (
    <AssessmentSummaryContainer>
      <div className="header-container">
        <div>
          <h2 className="header">Assessment Summary</h2>
        </div>
        {isArchived ? (
          <div className="button-container">
            <ExportSpreadSheetButton assessmentSummary={assessmentSummary} />
            <PrintAssessmentSummary handlePrint={handlePrint} />
          </div>
        ) : null}
      </div>
      <div ref={componentRef}>
        <GeneralSection assessmentSummary={assessmentSummary} />
        <AssessmentSection assessment={assessment} />
        <TasksSection assesmentTasks={assesmentTasks} />
        <StudentsSection
          students={studentsInUnits}
          studentsSubmission={studentsSubmission}
        />
        <EvaluationSection assessmentSubmission={assessmentSummary?.instance} />
      </div>
    </AssessmentSummaryContainer>
  );
};
