import {createSlice} from '@reduxjs/toolkit';

import * as studentsApi from '../api/admin/student';
import * as organisationApi from '../api/admin/basicInfo';

const initialFormError = {email: '', referId: ''};

const studentsSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: true,
    isStudentLoading: false,
    students: null,
    organisation: null,
    isAddStudentsLoading: false,
    formError: initialFormError,
    alreadyExistEmail: [],
    alreadyExistRefId: [],
    duplicateData: [],
    emailPattern: [],
    validStudent: [],
  },
  name: 'students',
  reducers: {
    editStudent: (state, action) => {
      const studentIndex = state.students.findIndex(
        student =>
          student.up_id_userprofile === action.payload.up_id_userprofile,
      );
      state.students[studentIndex] = action.payload;
    },
    removeStudentById(state, action) {
      state.students = state.students.filter(
        student => student.up_id_userprofile !== action.payload,
      );
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsStudentLoading(state, action) {
      state.isStudentLoading = action.payload;
    },
    setStudents(state, action) {
      state.students = action.payload;
    },
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },
    setIsAddStudentsLoading(state, action) {
      state.isAddStudentsLoading = action.payload;
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setAlreadyExistEmail: (state, action) => {
      state.alreadyExistEmail = action.payload;
    },
    setAlreadyExistRef: (state, action) => {
      state.alreadyExistRefId = action.payload;
    },
    setDuplicateData: (state, action) => {
      state.duplicateData = action.payload;
    },
    setEmailPattern: (state, action) => {
      state.emailPattern = action.payload;
    },
    setValidStudents: (state, action) => {
      state.validStudent = action.payload;
    },
  },
});

export const {
  editStudent,
  removeStudentById,
  setError,
  setIsListLoading,
  setIsStudentLoading,
  setStudents,
  setOrganisation,
  setIsAddStudentsLoading,
  setFormError,
  clearFormError,
  setAlreadyExistEmail,
  setAlreadyExistRef,
  setEmailPattern,
  setValidStudents,
  setDuplicateData,
} = studentsSlice.actions;

export const fetchAllStudents = () => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const response = await studentsApi.fetchAllStudents();
    dispatch(setStudents(response.data.orgAllStudent || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const updateStudent = (studentId, data, callback) => async dispatch => {
  dispatch(setIsStudentLoading(true));

  try {
    const response = await studentsApi.updateStudent(studentId, {
      referID: data.referId,
      fName: data.firstName,
      lName: data.lastName,
      useremail: data.email,
    });

    if (response.data?.orgStudent) {
      dispatch(editStudent(response.data?.orgStudent));

      if (callback) {
        callback();
      }
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsStudentLoading(false));
  }
};

export const deleteStudent = (studentId, callback) => async dispatch => {
  dispatch(setIsStudentLoading(true));

  try {
    await studentsApi.deleteStudent(studentId);
    dispatch(removeStudentById(studentId));

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsStudentLoading(false));
  }
};

export const resendStudentInvitation =
  (studentId, data, callback) => async dispatch => {
    dispatch(setIsStudentLoading(true));

    try {
      const response = await studentsApi.resendStudentInvitation(studentId, {
        refId: data.referId,
        fName: data.firstName,
        lName: data.lastName,
        email: data.email,
      });

      if (response.data.invitationLink) {
        dispatch(editStudent(response.data.invitationLink));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsStudentLoading(false));
    }
  };

// Add Student flow

export const individualStudentCheck = (data, callback) => async dispatch => {
  dispatch(setIsAddStudentsLoading(true));
  try {
    await studentsApi.individualStudentCheck(data);
    if (callback) {
      callback('');
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
    dispatch(setFormError(error?.response?.data?.error));
  } finally {
    dispatch(setIsAddStudentsLoading(false));
  }
};

export const addStudentManual = (data, callback) => async dispatch => {
  dispatch(setIsAddStudentsLoading(true));
  try {
    await studentsApi.addStudentsManual(data);
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddStudentsLoading(false));
  }
};

export const addStudentSpread = (data, callback) => async dispatch => {
  dispatch(setIsAddStudentsLoading(true));
  try {
    await studentsApi.addStudentsSpread(data);
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddStudentsLoading(false));
  }
};

export const spreadSheetStudentsCheck = (data, callback) => async dispatch => {
  dispatch(setIsAddStudentsLoading(true));
  const resp = await studentsApi.spreadSheetStudentsCheck(data);
  dispatch(setAlreadyExistEmail(resp.data.alreadyEmail));
  dispatch(setAlreadyExistRef(resp.data.alreadyRef));
  dispatch(setEmailPattern(resp.data.invalidEmails));
  dispatch(setValidStudents(resp.data.validUsers));
  dispatch(setDuplicateData(resp.data.duplicateData));
  dispatch(setIsAddStudentsLoading(false));
  if (callback) {
    if (
      resp.data.validUsers.length === data.length &&
      !resp.data.duplicateData.length
    ) {
      dispatch(addStudentSpread(resp.data.validUsers, callback));
    } else {
      callback();
    }
  }
};

export const fetchBasicInfo = () => async dispatch => {
  dispatch(setIsAddStudentsLoading(true));
  try {
    const response = await organisationApi.fetchBasicInfo();

    dispatch(setOrganisation(response.data.orgDetail));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddStudentsLoading(false));
  }
};

export const studentsSelector = state => state.students;

export default studentsSlice.reducer;
