/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, {useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {
  onDeletingAssessmentTask,
  removeInstruction,
} from '../../../../../../../../../../redux/activeClassSlice';
import InstructionsIcon from '../../../../../../../../../../assets/icons/instructions.svg';
import InstructionsArrowImg from '../../../../../../../../../../assets/icons/instructions-arrow.svg';
import {Hr} from '../../../../../../../../../../components/shared/Hr';
import {
  AudioModal,
  VideoModal,
  DisplayModal,
} from '../../../TaskAndInstruction/components/AddedInstructions/components';
import {Instruction} from '../Instructions';
import {MoreOptions} from '../../../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../../../components/common/MoreOptions/components';
import {theme} from '../../../../../../../../../../elements';
import {AddedMappedTask} from '../AddedMappedTask';
import {showToast} from '../../../../../../../../../../components/common/Toast';
import {authSelector} from '../../../../../../../../../../redux/authSlice';

export const AddTaskSection = ({
  id,
  isLoading,
  name,
  desc,
  files,
  isPublished,
  removeTask,
  setIdOfTaskToUpdate,
  evaluationType,
  performanceCriteriaName,
  performanceEvidenceName,
  knowledgeEvidenceName,
  assessmentConditionName,
  isArchive,
  setOpenAddTask,
}) => {
  const dispatch = useDispatch();
  const [instructions, setInstructions] = useState([]);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [audioModalVisibility, setAudioModalVisibility] = useState(false);
  const [audioShowData, setAudioShowData] = useState(null);
  const [displayModalVisibility, setDisplayModalVisibility] = useState(false);
  const [displayShowData, setDisplayShowData] = useState(null);
  const [videoModalVisibility, setVideoModalVisibility] = useState(false);
  const [videoShowData, setVideoShowData] = useState(null);
  const {orgPreferences} = useSelector(authSelector);

  const assessmentMapPreference = orgPreferences?.find(
    item =>
      item.pr_slug === 'create_assessment_map' && Number(item.pr_value) === 1,
  );

  useEffect(() => {
    if (files && files?.length) {
      setInstructions(
        files.map(item => {
          const file = item.fi_id_filetype ? item : item.file;

          switch (file.fi_id_filetype) {
            case 1:
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'image',
              };
            case 2:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                thumbnail: file.fi_thumbnail,
                type: 'video',
              };
            case 3:
              return {
                id: file.fl_id_filelink,
                text: file.fi_text_content,
                type: 'text',
              };
            case 5:
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'document',
              };
            case 7:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'audio',
              };
            default:
              return null;
          }
        }),
      );
    }
  }, [files]);

  const onClickDelete = () => {
    if (isPublished) {
      removeTask(id);
      dispatch(
        onDeletingAssessmentTask(id, true, () => {
          showToast(false, 'Task Has been Removed successfully', true);
        }),
      );
    } else {
      dispatch(
        onDeletingAssessmentTask(id, false, () => {
          showToast(false, 'Task Has been Removed successfully', true);
        }),
      );
    }
  };
  function parseUrls(text) {
    const regularExpression = /(https?:\/\/[^\s]+)/g;
    return text?.split(regularExpression).map((url, index) => {
      if (url?.match(regularExpression)) {
        return (
          <a
            href={url}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            target="_blank"
            rel="noreferrer"
            style={{
              color: theme?.colors?.primary,
            }}
          >
            {url}
          </a>
        );
      }

      return url;
    });
  }
  if (isLoading)
    return (
      <div className="task-data-container">
        <div>
          <Skeleton height="3rem" width="3rem" />
        </div>
        <div className="w-100 pl-3">
          <div
            style={{
              paddingBottom: '0.6875rem',
            }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <Skeleton
                  height="1.25rem"
                  style={{
                    display: 'block',
                    marginBottom: '0.25rem',
                  }}
                  width="329px"
                />
                <Skeleton
                  height="0.937rem"
                  style={{
                    display: 'block',
                    marginBottom: '1rem',
                  }}
                  width="73px"
                />
              </div>
            </div>
            <div className="task-desc mb-1">
              <Skeleton
                height="1.25rem"
                style={{
                  display: 'block',
                  marginBottom: '0.5rem',
                }}
                width="100%"
              />
              <Skeleton
                height="1.25rem"
                style={{
                  display: 'block',
                }}
                width="333px"
              />
            </div>
          </div>
          <Hr />
          <div className="pt-3">
            <div className="d-flex justify-content-between">
              <div>
                <Skeleton
                  height="2.5rem"
                  style={{
                    marginRight: '0.5rem',
                  }}
                  width="2.5rem"
                />
                <Skeleton
                  height="2.5rem"
                  style={{
                    marginRight: '0.5rem',
                  }}
                  width="2.5rem"
                />
                <Skeleton
                  height="2.5rem"
                  style={{
                    marginRight: '0.5rem',
                  }}
                  width="2.5rem"
                />
                <Skeleton height="2.5rem" width="2.5rem" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {name !== '' && (
        <div
          className="task-data-container"
          key={id}
          style={{
            paddingBottom: instructionsOpen ? 24 : 16,
          }}
        >
          {displayModalVisibility ? (
            <DisplayModal
              onClose={() => {
                setDisplayModalVisibility(false);
                setDisplayShowData(null);
              }}
              showData={displayShowData}
            />
          ) : null}
          {videoModalVisibility ? (
            <VideoModal
              onClose={() => {
                setVideoModalVisibility(false);
                setVideoShowData(null);
              }}
              showData={videoShowData}
            />
          ) : null}
          {audioModalVisibility ? (
            <AudioModal
              onClose={() => {
                setAudioModalVisibility(false);
                setAudioShowData(null);
              }}
              showData={audioShowData}
            />
          ) : null}
          <div className="w-100">
            <div className={`px-4 ${files.length ? 'pb-3' : ''}`}>
              <div
                className="d-flex align-items-start justify-content-between"
                style={{
                  marginBottom: 4,
                }}
              >
                <div className="task-added-div">
                  <div className="task-top-content">
                    <div className="task-name mb-3">{name}</div>
                    <div className="d-flex">
                      {evaluationType ? (
                        <div className="evaluation-type-badge">
                          {evaluationType}
                        </div>
                      ) : null}
                      {!isArchive ? (
                        <MoreOptions>
                          <DropdownItem
                            label="Edit"
                            onClick={() => {
                              setIdOfTaskToUpdate(id);
                              setOpenAddTask(false);
                            }}
                          />
                          <DropdownItem
                            isDanger
                            label="Delete"
                            onClick={() => {
                              onClickDelete();
                            }}
                          />
                        </MoreOptions>
                      ) : null}
                    </div>
                  </div>
                  <div className="task-desc">{parseUrls(desc)}</div>
                </div>
              </div>
            </div>
            {assessmentMapPreference ? (
              <div className="pt-3 pb-4 px-4">
                <AddedMappedTask
                  performanceCriteriaName={performanceCriteriaName}
                  performanceEvidenceName={performanceEvidenceName}
                  knowledgeEvidenceName={knowledgeEvidenceName}
                  assessmentConditionName={assessmentConditionName}
                />
              </div>
            ) : null}
            {files.length ? (
              <Hr
                style={{
                  background: '#f2f2f4',
                }}
              />
            ) : null}
            {files.length ? (
              <div className="pt-3 px-4">
                <div className="d-flex justify-content-between">
                  <div className="w-100">
                    {instructions?.length ? (
                      <div className="added-task-instructions">
                        <div
                          className={`added-instructions-meta ${
                            instructionsOpen ? 'mb-3' : ''
                          }`}
                        >
                          <div>
                            <img alt="Instructions" src={InstructionsIcon} />
                            <h3>{instructions.length} Instructions Added</h3>
                          </div>
                          <button
                            className={instructionsOpen ? 'is-open' : ''}
                            onClick={() =>
                              setInstructionsOpen(
                                previousValue => !previousValue,
                              )
                            }
                            type="button"
                          >
                            <img
                              alt="View Instructions"
                              src={InstructionsArrowImg}
                            />
                          </button>
                        </div>
                        {instructionsOpen
                          ? instructions.map(instruction => (
                              <Instruction
                                data={instruction}
                                key={instruction?.text}
                                onDelete={() => {
                                  dispatch(
                                    removeInstruction(id, instruction.id),
                                  );
                                }}
                                onShowClick={() => {
                                  if (instruction?.type === 'audio') {
                                    setAudioShowData(instruction);
                                    setAudioModalVisibility(true);
                                  } else if (
                                    (instruction?.type === 'document' &&
                                      instruction.pdf) ||
                                    instruction?.type === 'image'
                                  ) {
                                    setDisplayShowData(instruction);
                                    setDisplayModalVisibility(true);
                                  } else if (instruction?.type === 'video') {
                                    setVideoShowData(instruction);
                                    setVideoModalVisibility(true);
                                  }
                                }}
                              />
                            ))
                          : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
