/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import AddedTextInstructionIcon from '../../../../../../../../../../assets/icons/added-text-instruction.svg';
import AddedVoiceInstructionIcon from '../../../../../../../../../../assets/icons/added-voice-instruction.svg';
import DocumentInstructionIcon from '../../../../../../../../../../assets/icons/document-instruction.svg';
import PlayVideoIcon from '../../../../../../../../../../assets/icons/play-video-instruction.svg';
import {MoreOptions} from '../../../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../../../components/common/MoreOptions/components';
import {
  convertFileSize,
  calculateTimeDuration,
} from '../../../../../../../../../../utils/functions';

export const Instruction = ({data, onDelete, onShowClick}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (data.media) {
      setSource(
        typeof data.media === 'string'
          ? data.media
          : URL.createObjectURL(data.media),
      );
    }
  }, [data.media]);

  let element = null;
  let instructionIcon;
  let subtitle;
  let title;

  if (data.type === 'audio') {
    instructionIcon = AddedVoiceInstructionIcon;
    subtitle = `• ${calculateTimeDuration(data.duration)}`;
    title = 'Voice';
  } else if (data.type === 'document') {
    instructionIcon = DocumentInstructionIcon;
    subtitle = `• ${convertFileSize(data.duration)}`;
    title = 'Document';
  } else if (data.type === 'image') {
    instructionIcon = source;
    subtitle = `• ${convertFileSize(data.duration)}`;
    title = 'Image';
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
      </button>
    );
  } else if (data.type === 'text') {
    instructionIcon = AddedTextInstructionIcon;
    subtitle = 'Instruction';
    title = 'Text';
  } else if (data.type === 'video') {
    instructionIcon = data.thumbnail;
    subtitle = `• ${calculateTimeDuration(data.duration)}`;
    title = 'Video';
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
        <div className="play-video-icon">
          <img alt="Play Video" src={PlayVideoIcon} />
        </div>
      </button>
    );
  }

  if (['audio', 'document', 'text'].includes(data.type)) {
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
      </button>
    );
  }

  return (
    <div className="added-instruction">
      {element}
      <div className="instruction-content">
        <span>
          {title} {subtitle}
        </span>
        <p>
          {data.text.length > 128 && !isExpanded
            ? `${data.text.slice(0, 128)}...`
            : data.text}{' '}
          {data.text.length > 128 ? (
            !isExpanded ? (
              <button onClick={() => setIsExpanded(true)} type="button">
                See more
              </button>
            ) : (
              <button onClick={() => setIsExpanded(false)} type="button">
                See less
              </button>
            )
          ) : null}
        </p>
      </div>
      {onDelete ? (
        <MoreOptions>
          <DropdownItem isDanger label="Delete" onClick={onDelete} />
        </MoreOptions>
      ) : null}
    </div>
  );
};
