import styled from 'styled-components';
import {Color} from '../../../../../../../../constants';

export const StudentsDetailsContainer = styled.main`
  margin: 2rem auto;
  width: 80%;
  margin-top: 11rem;
  @media (max-width: 60rem) {
    & {
      width: 95%;
    }
  }
  @media (max-width: 50rem) {
    & {
      width: 95%;
      margin-top: 12rem;
    }
  }
  @media (max-width: 43.75rem) {
    & {
      margin-top: 15rem;
      width: 92%;
    }
  }
  @media (max-width: 33rem) {
    & {
      margin-top: 15rem;
      width: 92%;
    }

    .top-bar {
      display: flex;
      flex-direction: column;

      .top-bar-hideshow {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        .title-div {
          display: flex;
          align-items: center;
        }
        .sort-by-hide {
          display: block !important;
          .badges-hide {
            /* visibility: hidden; */
          }
        }
      }
      .badges {
        display: flex;
        width: 100%;
        overflow-x: scroll;
        flex-shrink: 0;
        .graded-button {
          margin-right: -1rem !important;
        }
        ::-webkit-scrollbar {
          display: none;
        }
        .sort-by-default {
          display: none;
          margin-left: unset;
        }
      }
    }
  }
  .top-bar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .top-bar-hideshow {
      .sort-by-hide {
        display: none;
      }
    }
    h3 {
      color: #3d4457;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0;
    }
    .badges {
      display: flex;
      & > *:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
`;

export const StudentsDetailsTableContainer = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  padding: 1.5rem 1.5rem 0 1.5rem;
  @media (max-width: 43.75rem) {
    .table-header {
      display: flex;
      flex-direction: column;
      align-items: unset !important;
      .search-student {
        width: 100%;
      }
      p {
        padding-bottom: 1rem;
      }
    }
  }

  .__react_component_tooltip {
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0.5rem;

    .tooltip-value {
      font-weight: 500;
    }
  }

  .table-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    p {
      color: #3d4457;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  table {
    width: 100%;

    tr {
      :first-child {
        border-bottom: 0.0625rem solid #dedede;
        border-top: 0.0625rem solid #dedede;
      }

      :not(:last-child) {
        border-bottom: 0.0625rem solid #dedede;
      }

      th {
        color: #aaa;
        font-size: 0.875rem;
        font-weight: 500;
        padding: 1rem 0;
        text-transform: uppercase;

        &.profile {
          width: 27.5%;
        }

        &.id {
          width: 20%;
        }

        &.status {
          width: 22.5%;
        }

        &.actions {
          width: 20%;
        }
      }

      td {
        padding: 1.25rem 0;

        .profile-icon {
          height: 2rem;
          width: 2rem;
        }
        &.profile {
          width: 27.5%;

          img {
            height: 2rem;
            margin-right: 0.75rem;
          }

          span {
            color: #3d4457;
            font-size: 0.875rem;
            font-weight: normal;
          }
        }

        &.id {
          color: #3d4457;
          font-size: 0.875rem;
          font-weight: normal;
          width: 20%;
        }

        &.status {
          display: flex;
          width: 22.5%;
          .status-button {
            display: flex;
            div:first-child {
              align-items: center;
              border-radius: 0.312rem;
              display: flex;
              font-size: 0.875rem;
              font-weight: normal;
              padding: 0.4375rem 1.062rem 0.5rem 1.062rem;
            }
          }

          .reassigned {
            img {
              height: 1rem;
              margin-right: 0.375rem;
            }
          }

          .reminded-indicator,
          .resubmitted-indicator {
            align-items: center;
            background-color: #fef2e8;
            border-radius: 0.312rem;
            display: flex;
            margin-left: 0.5rem;
            padding: 0 0.625rem;

            img {
              height: 1rem;
              width: 1rem;
            }
          }

          .resubmitted-indicator {
            background-color: #fff5d6;
          }
        }

        &.grade {
          color: #3d4457;
          font-size: 1rem;
          font-weight: 500;
        }

        &.actions {
          width: 20%;

          & > div {
            align-items: center;
            display: flex;
            justify-content: flex-end;

            .evaluate-button,
            .view-request-button {
              background-color: #40a1ff;
              border: none;
              border-radius: 0.312rem;
              color: #fff;
              font-size: 0.875rem;
              font-weight: 500;
              margin-right: 1.5rem;
              padding: 0.4375rem 0.6875rem 0.5rem 0.75rem;
              display: flex;
              justify-content: center;
              align-items: center;

              :active,
              :focus {
                outline: none;
              }

              img {
                height: 1rem;
                margin-left: 0.5rem;
              }
            }

            .dropdown-wrapper {
              position: relative;
            }

            .btn {
              border: none;
              padding: 0;

              :active,
              :focus {
                box-shadow: none;
              }

              img {
                vertical-align: unset;
              }
            }

            .view-button {
              align-items: center;
              background-color: #fff;
              border: 0.0625rem solid #dedede;
              border-radius: 0.312rem;
              display: flex;
              height: 2rem;
              justify-content: center;
              margin-right: 1.5rem;
              width: 2rem;

              :active,
              :focus {
                outline: none;
              }

              img {
                height: 1rem;
              }
            }
          }
        }
      }
    }
    @media (max-width: 43.75rem) {
      border: 0;
      th {
        border: none;
        height: 0.0625rem;
        margin: -0.0625rem;
        overflow: hidden;
        padding: 0;
        clip: rect(0 0 0 0);
        position: absolute;
        width: 0.0625rem;
      }

      tbody tr {
        display: block;
        padding: 0 1rem;
        :hover {
          background-color: transparent;
        }
      }
      tr:first-child {
        border-top: unset;
      }
      tr {
        :last-child {
          border-bottom: 0.0625rem solid #dedede;
        }
      }
      tbody {
        :last-child {
          tr:first-child {
            margin-bottom: 5.12rem;
          }
        }
      }
      tr {
        td {
          color: ${Color.PRIMARY_TEXT};
          font-size: 0.875rem;
          font-weight: 400;
          display: block;
          text-align: right;
          :last-child {
            width: 100%;
            div {
              width: 100%;
            }
            .action-div {
              display: flex;
              /* flex-direction: row; */
              .view-button-div {
                width: 12%;
                order: 2;
                button {
                  margin-top: 1.25rem;
                }
              }
              .dropdown-wrapper {
                order: 1;
              }
              &.flex-row .dropdown-wrapper {
                margin-right: -1.875rem;
              }
            }
          }

          &.profile {
            width: 100%;
          }

          &.id {
            width: 100%;
          }

          &.status {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          &.actions {
            width: 100%;
            div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .view-request-button,
              .evaluate-button,
              .view-button {
                margin-bottom: 1.5rem;
                margin-right: unset;
                width: 100%;
              }
            }

            .three-dot-btn {
              transform: rotate(90deg);
            }
            .dropdown-list {
              min-width: unset;
              right: unset;
              top: 100%;
              left: 15%;
              width: 70%;
              /* left: -100px; */
              button {
                padding: 0.625rem !important;
              }
              border-radius: 0.5rem;
              .dropdown-item-seperator {
                display: none;
              }
            }
          }
        }
      }

      td:before {
        /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
        color: ${Color.LIGHT_TEXT};
        font-size: 0.875rem;
        font-weight: 500;
      }

      td:first-child::before {
        clear: both;
        margin-right: 0.25rem;
      }

      td:last-child {
        border-bottom: 0;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
