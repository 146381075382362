import styled from 'styled-components';

export const ViewFileSharedToClassMainContainer = styled.div`
  padding: 2rem 1.5rem 2rem 2rem !important;
  @media (max-width: 55.5rem) {
    & {
      padding: 2rem 1.5rem 2rem 2rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 2rem 1.5rem 2rem 1.5rem !important;
    }
  }
  .container-file {
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    background-color: #ffffff;
    padding: 1.5rem;
    .empty-state {
      padding: unset !important;
    }

    .shared-class-heading {
      font-weight: 500;
      font-size: 1rem;
      color: #3d4457;
    }
    .shared-class-title {
      padding: 0 0 1.125rem 0;
      border-bottom: 0.0625rem solid #e0e0e0;
    }

    .file-div {
      display: flex;
      .file-data {
        width: 65%;
        height: 100%;
      }
      .shared-classes {
        width: 35%;
        overflow-x: hidden;
        padding: 0 0 0 1.5rem;
      }
      @media (max-width: 65rem) {
        & {
          display: flex;
          flex-direction: column;
        }
        .file-data,
        .shared-classes {
          padding: 1.5rem 0 0 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .container-scroll {
    overflow-y: auto;
    max-height: 500px;

    .class-shared {
      background: #f5f5f5;
      border-radius: 0.25rem;
      padding: 0.4375rem 0.9375rem;
      display: flex;
      justify-content: space-between;

      .class-name-shared {
        font-weight: 400;
        font-size: 0.875rem;
        color: #6d7579;
        text-transform: uppercase;
      }
    }
  }
`;
