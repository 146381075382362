import {createSlice} from '@reduxjs/toolkit';
import * as portfolioApi from '../api/portfolio';

const portfolioSlice = createSlice({
  initialState: {
    error: null,
    portfolioFiles: null,
    user: null,
    userOrganisation: null,
    unitAttendance: null,
    enrolledQualification: null,
    enrolledCourse: null,
    isListLoading: true,
    academicYears: null,
    orgUnitInstance: null,
    isLoading: true,
    presentSessions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    absentSessions: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  name: 'portfolio',
  reducers: {
    setPortfolio: (state, action) => {
      state.portfolioFiles = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserOrganisation: (state, action) => {
      state.userOrganisation = action.payload;
    },
    setUnitAttendance: (state, action) => {
      state.unitAttendance = action.payload;
    },
    setEnrolledQualification: (state, action) => {
      state.enrolledQualification = action.payload;
    },
    setEnrolledCourses: (state, action) => {
      state.enrolledCourse = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    setAcademicYears: (state, action) => {
      state.academicYears = action.payload;
    },
    setOrgUnitInstance: (state, action) => {
      state.orgUnitInstance = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPresentSessions: (state, action) => {
      state.presentSessions = action.payload;
    },
    setAbsentSessions: (state, action) => {
      state.absentSessions = action.payload;
    },
  },
});

export const {
  setPortfolio,
  setUser,
  setUserOrganisation,
  setUnitAttendance,
  setEnrolledQualification,
  setEnrolledCourses,
  setError,
  setIsListLoading,
  setAcademicYears,
  setOrgUnitInstance,
  setIsLoading,
  setPresentSessions,
  setAbsentSessions,
} = portfolioSlice.actions;

export const resetReduxValue = () => async dispatch => {
  dispatch(setPortfolio(null));
  dispatch(setIsListLoading(true));
  dispatch(setIsLoading(false));
  dispatch(setAcademicYears(null));
};

export const fetchAllPortfolioFiles =
  (username, academicYearId) => async dispatch => {
    try {
      dispatch(setIsListLoading(true));
      const [portfolioResponse, qualificationResponse] = await Promise.all([
        portfolioApi.fetchAllPortfolioFiles(username, academicYearId),
        portfolioApi.fetchEnrolledQualification(username),
      ]);
      dispatch(setPortfolio(portfolioResponse?.data?.portfolioFiles || []));
      dispatch(setUser(portfolioResponse?.data?.userProfileData || []));
      dispatch(
        setUserOrganisation(portfolioResponse?.data?.organisation || []),
      );

      dispatch(
        setUnitAttendance(portfolioResponse?.data?.classAndSession || []),
      );
      dispatch(
        setAbsentSessions(
          portfolioResponse?.data?.absentSessions || [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        ),
      );
      dispatch(
        setPresentSessions(
          portfolioResponse?.data?.presentSessions || [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        ),
      );
      dispatch(
        setEnrolledQualification(
          qualificationResponse?.data?.orgQualification || [],
        ),
      );
      dispatch(
        setEnrolledCourses(qualificationResponse?.data?.orgCourse || []),
      );
      dispatch(
        setOrgUnitInstance(portfolioResponse?.data?.orgUnitInstance || []),
      );
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsListLoading(false));
    }
  };

export const fetchAcademicYears = username => async dispatch => {
  try {
    const response = await portfolioApi.fetchAcademicYears(username);
    dispatch(setAcademicYears(response.data.academicYearArray));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const portfolioSelector = state => state.portfolio;

export default portfolioSlice.reducer;
