/* eslint-disable react/prop-types */
import moment from 'moment';
import TimeIntervalContainer from '../elements/TimeIntervalContainer';

const TimeInterval = ({time, isLast}) => (
  <>
    <TimeIntervalContainer isLast={isLast}>
      <div className="d-flex align-items-start">
        <span className="time d-flex">{moment(time, 'H').format('h A')}</span>
        <span className="flex-grow-1 time-indicator" />
      </div>
      <div className="half-time-indicator" />
    </TimeIntervalContainer>
  </>
);

export default TimeInterval;
