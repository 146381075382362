import axios from '../index';

export const fetchPost = (classId, page, aouiId) =>
  axios.get(
    `/api/class/${classId}/stream?page=${page}&limit=10&aouiId=${aouiId || ''}`,
  );

export const fetchPostsForStudent = (classId, page, aouiId) =>
  axios.get(
    `/api/class/${classId}/streamsForStudent?page=${page}&limit=10&aouiId=${
      aouiId || ''
    }`,
  );

export const commentOnPost = (activityId, comment) =>
  axios.post(`api/class/${activityId}/comment`, {
    comment,
  });

export const getComments = (activityId, offset) =>
  axios.get(`api/class/${activityId}/getComments?limit=10&offset=${offset}`);

export const deleteComment = commentId =>
  axios.delete(`api/class/${commentId}/comment`);

export const deleteStreamPost = id =>
  axios.delete(`/api/class/${id}/deleteStream`);

export const createPost = (classId, formData) =>
  axios.post(`/api/class/${classId}/stream/post`, formData);

export const updatePost = (activityID, body) =>
  axios.put(`api/class/${activityID}/updatePost`, body);

export const removeFilesFromPost = body =>
  axios.put(`api/class/removeFilesFromPost`, body);

export const addFilesToPost = (activityID, body) =>
  axios.post(`api/class/${activityID}/addFilesToPost`, body);

export const updateStudentsOfPost = (aouiID, body) =>
  axios.put(`api/class/${aouiID}/updateStudentsOfPost`, body);

export const fetchClassDetails = classId =>
  axios.get(`/api/class/${classId}/details`);
