import styled from 'styled-components';

export const StudentAssessmentHeaderContainer = styled.header`
  .header-top {
    border-bottom: 0.0625rem solid #dedede;
    padding: 1.5rem 1.25rem 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      .teacher-name {
        font-weight: 500;
        font-size: 1rem;
        color: #3d4457;
        margin-bottom: 0.2rem;
      }
      .username,
      activity-type {
        font-weight: 400;
        font-size: 0.875rem;
        color: #84888c;
        margin: unset;
      }
    }
    .header-right {
      font-weight: 400;
      font-size: 0.875rem;
      color: #84888c;
    }
  }
  @media (max-width: 43rem) {
    .header-top {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start;
      .header-right {
        margin-top: 0.5rem;
      }
    }
    .outline-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
  .header-middle {
    padding: 1rem 1.5rem;
    .header-description {
      margin: 0.5rem 0 0 0;
    }
    .assessment-name {
      font-weight: 500;
      font-size: 1.25rem;
      color: #091018;
    }
  }
  .header-last {
    padding: 0 1.5rem 0rem 1.5rem;
  }
  .outline-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.0625rem solid #e6e7e8;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
  }
  /* .outline-left {
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 0.25rem;
      font-weight: 500;
      font-size: 1rem;
      color: #091018;
    }
    .time {
      margin-left: 3rem;
    }
    span {
      font-weight: 400;
      font-size: 0.875rem;
      color: #84888c;
    }
  } */
  .view-assessment {
    font-weight: 600;
    font-size: 1rem;
    color: #5ba1f2;
    :hover {
      text-decoration: unset;
    }
  }

  .header-description {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #84888c;
    display: -webkit-box;
    font-size: 1rem;
    margin-top: 1rem;
    overflow: hidden;
  }
  .header_t-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 0.65rem;
  }

  .circle {
    border: 0.125rem solid white;
    border-radius: 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    height: 2.5rem;
    margin-right: 0.65rem;
    width: 2.5rem;
    text-transform: uppercase;
  }

  .student {
    background-color: ${({theme}) => theme.colors.primary};
    color: white;
  }
`;
