/* eslint-disable react/prop-types */
import React from 'react';
import {Button} from 'react-bootstrap';
import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';

import {AddAssessmentBodyContainer} from './elements';
import {
  SkeltonAssessment,
  BasicDetails,
  TaskAndInstruction,
  AddedTask,
} from './components';

import {
  activeClassSelector,
  fetchDraftTask,
} from '../../../../../../redux/activeClassSlice';

export const AddAssessmentBody = ({
  currentTab,
  register,
  assessment,
  setValue,
  control,
  getValues,
  errors,
  isPublished,
  isUpdating,
  stopUpdating,
  closeAddTask,
  addTask,
  removeTask,
  openAddTask,
  setOpenAddTask,
  isLoading,
  setError,
  clearErrors,
  watch,
  isArchive,
}) => {
  const dispatch = useDispatch();

  const {createdTasks, activityId, AssessmentLoading} =
    useSelector(activeClassSelector);

  return (
    <AddAssessmentBodyContainer>
      {currentTab === 'details' ? (
        <div style={{width: '100%'}}>
          <div className="mb-4">
            <h2 className="page-title">Basic Details</h2>
            <span className="assessment-detail">Assessment Details</span>
          </div>
          <div className="detail-task-container">
            <BasicDetails
              register={register}
              assessment={assessment}
              setValue={setValue}
              control={control}
              getValues={getValues}
              errors={errors}
              isPublished={isPublished}
              isUpdating={isUpdating}
              stopUpdating={stopUpdating}
              setError={setError}
              clearErrors={clearErrors}
              watch={watch}
            />
          </div>
        </div>
      ) : null}
      {currentTab === 'tasks' ? (
        <div className="task-instruction">
          <div className="mb-4">
            <h2 className="page-title">Assessment Tasks</h2>
            <span className="assessment-detail">
              Add / View Multiple Tasks{' '}
            </span>
          </div>
          <div>
            {!isLoading ? (
              <div>
                <AddedTask
                  isPublished={isPublished}
                  removeTask={removeTask}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  isArchive={isArchive}
                  setError={setError}
                  clearErrors={clearErrors}
                  setOpenAddTask={setOpenAddTask}
                />

                {openAddTask || (!createdTasks.length && !AssessmentLoading) ? (
                  <div className="detail-task-container">
                    <TaskAndInstruction
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      closeAddTask={closeAddTask}
                      isPublished={isPublished}
                      addTask={addTask}
                      control={control}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      isArchive={isArchive}
                    />
                  </div>
                ) : (
                  <Button
                    variant="outline-primary"
                    className={classNames('ml-auto add-task-btn', {
                      'is-archive': isArchive,
                    })}
                    onClick={() => {
                      dispatch(fetchDraftTask(activityId));
                      setOpenAddTask(true);
                    }}
                  >
                    + Add Another Task
                  </Button>
                )}
              </div>
            ) : (
              <SkeltonAssessment />
            )}
          </div>
        </div>
      ) : null}
    </AddAssessmentBodyContainer>
  );
};
