/* eslint-disable react/prop-types */

import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar';
import CalendarPreviousIcon from '../../../../../../../../assets/icons/calendar-previous.svg';
import {CalendarContainer} from './elements';
import {EventWrapper} from './components';
import {clearCalendarSlots} from '../../../../../../../../redux/sessionsSlice';

const Calendar = ({events}) => {
  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment);
  const [update, triggerUpdate] = useState(null);
  useEffect(() => {
    dispatch(clearCalendarSlots());
  }, [dispatch]);

  useEffect(() => {
    const navigationButtons = document.querySelectorAll(
      '.rbc-btn-group:first-child button:not(:first-child)',
    );

    // Change navigation button labels to icons.
    navigationButtons.forEach(button => {
      button.textContent = '';
      const image = document.createElement('img');
      image.classList.add('calendar-icon');
      image.src = CalendarPreviousIcon;
      button.appendChild(image);
    });

    // Append 'ly' suffix to 'Week' and 'Month'.
    document
      .querySelectorAll('.rbc-btn-group:last-child button')
      ?.forEach(button => {
        if (!button.textContent.includes('ly')) button.textContent += 'ly';
      });
  }, []);

  useEffect(() => {
    const toolbarLabel = document.querySelector('.rbc-toolbar-label');
    const month = toolbarLabel.textContent;

    if (toolbarLabel && month) {
      toolbarLabel.textContent = '';
      const calendarMonthElement = document.querySelector('.calendar-month');

      // Change the month's position from center to left.
      if (calendarMonthElement) {
        calendarMonthElement.textContent = month;
      } else {
        const monthElement = document.createElement('span');
        monthElement.textContent = month;
        monthElement.classList.add('calendar-month');
        const toolbar = document.querySelector('.rbc-btn-group');
        toolbar.insertAdjacentElement('afterbegin', monthElement);
      }
    }

    dispatch(clearCalendarSlots());
  }, [dispatch, update]);

  return (
    <CalendarContainer>
      <BigCalendar
        components={{eventWrapper: EventWrapper}}
        defaultView="month"
        events={events}
        formats={{dateFormat: 'D'}}
        localizer={localizer}
        onNavigate={() => triggerUpdate(Math.random())}
        onView={() => triggerUpdate(Math.random())}
        popup
        // views={['week', 'month']}
        views={['month']}
        // check design also commented
      />
    </CalendarContainer>
  );
};

export {Calendar};
