/* eslint-disable react/prop-types */
import React from 'react';

import {useHistory} from 'react-router-dom';

import CloseImg from '../../../../../../../../../../assets/icons/addadminclose.svg';

import {AddUnitToQualificationHeader} from './elements';

export const AddUnitsHeader = () => {
  const history = useHistory();

  return (
    <AddUnitToQualificationHeader>
      <div className="add-unit-header fixed-top">
        <div className="add-unit-title">
          <div>
            <span className="add-unit-name">Add units</span>
          </div>
          <div>
            <button
              className="btn"
              onClick={() => {
                history.replace(`/admin/qualifications`);
              }}
              type="button"
            >
              <img alt="Options" src={CloseImg} />
            </button>
          </div>
        </div>
      </div>
    </AddUnitToQualificationHeader>
  );
};
