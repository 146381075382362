import React from 'react';
import {useRouteMatch, Route, Switch, Redirect} from 'react-router-dom';
import Navbar from '../../components/shared/Navbar';
import Dashboard from './Components/Dashboard';
import AddUnits from './Components/Qualifications/AllUnits/AddUnit';
import AddQualificationAndUnit from './Components/Qualifications/AllQualifications/AddQualificationAndUnit';
import AddUnitsToQualification from './Components/Qualifications/AllQualifications/AddUnitsToQualification';
// import AddAdminTeacherDetails from './Components/Users/AdminTeacher/AddAdminTeacher';
import TeacherDetails from './Components/Users/Teacher';
import AddTeacherDetails from './Components/Users/Teacher/AddTeacher';
// import AddStudentDetails from './Components/Users/Student/AddStudent';
import Units from './Components/Units';
import ViewUnit from './Components/Units/ViewUnit';
import AddUnitAndStudent from './Components/Units/AddUnitAndStudent';
import {AllNotification} from '../Notification';
import UnitDetails from './Components/Qualifications/UnitDetails';
import {AddAssessmentTitle} from './Components/Qualifications/UnitDetails/pages/AddAssessmentTitle';
import {AddAssessment} from './Components/Qualifications/UnitDetails/pages/AddAssessment';

import {
  AllQualifications,
  AllUnits,
  AdminTeacher,
  Departments,
  AcademicYear,
  Student,
  BasicInfo,
  Classes,
  AddClass,
  ViewClass,
  AssessmentsList,
  AssessmentSummary,
  ManageUnits,
  AddStudentsToViewClass,
  AddAdminTeacher,
  AddQualificationsAndUnit,
  AddUnitsToQualification as AddUnitsToQualificationNew,
  AddStudents,
  DashboardNew,
  ActiveCourses,
  AddCourse,
  CourseClassesList,
  Sessions,
  AddSession,
  Library,
  Attendance,
  // SessionsCalendar,
  CurriculumList,
  ViewCurriculum,
  ViewFileSharedToClass,
  ListAssessments,
  AddAssessmentAdmin,
  AddAssessmentByQualUnit,
} from './pages';
import {TasForm, TasList} from './pages/Tas';
import {TaskList} from './pages/Task';
import {RestrictedRoute} from '../../PrivateRoute';

const Admin = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          exact
          path={[
            `${match.path}/units/view-unit/:orgUnitInstanceId`,
            `${match.path}/qualifications/add-qualifications-old`,
            `${match.path}/qualifications/add-qualifications`,
            `${match.path}/qualifications/singleQualification/:qualificationId/addUnits`,
            `${match.path}/users/admin/addAdmin`,
            `${match.path}/users/teacher/addTeacher`,
            `${match.path}/users/student/addStudent`,
            `${match.path}/units/add-unit`,
            `${match.path}/qualifications/:qualificationId/add_units`,
            `${match.path}/qualifications/units/add-units`,
            `${match.path}/qualifications/:qualificationId/units/add-units`,
            `${match.path}/classes/add-class`,
            `${match.path}/units/view-unit/:orgUnitInstanceId/add-student`,
            `${match.path}/classes/view-class/:orgClassId/manage-units`,
            `${match.path}/classes/view-class/:orgClassId/add-students`,
            `${match.path}/courses/active-courses/add-courses`,
            `${match.path}/sessions/add-session`,
            `${match.path}/sessions/:sessionId/attendance`,
            `${match.path}/:orgClassId/tas-form`,
            `${match.path}/assessments/create`,
            `${match.path}/assessments/:id/update`,
            `${match.path}/qualifications/:qualificationId/units/:unitId/assessment/create`,
            `${match.path}/qualifications/:qualificationId/units/:unitId/assessment/:assessmentId/update`,
            '/PageNotFound',
          ]}
          render={() => null}
        />
        <Route component={Navbar} />
      </Switch>
      <Switch>
        <Route component={Dashboard} exact path={`${match.path}/`} />
        <Route
          component={BasicInfo}
          exact
          path={`${match.path}/organisation/basic-info`}
        />
        <Route
          component={Departments}
          exact
          path={`${match.path}/organisation/departments`}
        />
        <Route
          component={AcademicYear}
          exact
          path={`${match.path}/organisation/academicyear`}
        />
        <Route
          component={AddQualificationAndUnit}
          exact
          path={`${match.path}/qualifications/add-qualifications-old`}
        />
        <Route
          component={AddQualificationsAndUnit}
          exact
          path={`${match.path}/qualifications/add-qualifications`}
        />
        <Route
          component={AddUnitsToQualification}
          exact
          path={`${match.path}/qualifications/:qualificationId/add_units-old`}
        />
        <Route
          component={AddUnitsToQualificationNew}
          exact
          path={`${match.path}/qualifications/:qualificationId/add_units`}
        />
        <Route
          component={AddUnits}
          exact
          path={[
            `${match.path}/qualifications/units/add-units`,
            `${match.path}/qualifications/:qualificationId/units/add-units`,
          ]}
        />
        <Route
          component={UnitDetails}
          exact
          path={`${match.path}/qualifications/:qualificationId/units/:unitId`}
        />
        <Route
          component={AddAssessmentTitle}
          exact
          path={`${match.path}/qualifications/:qualificationId/units/:unitId/assessment/create`}
        />
        <Route
          component={AddAssessment}
          exact
          path={`${match.path}/qualifications/:qualificationId/units/:unitId/assessment/:assessmentId/update`}
        />
        <Route
          component={AdminTeacher}
          exact
          path={`${match.path}/users/admin`}
        />

        <Route
          component={AddAdminTeacher}
          exact
          path={`${match.path}/users/admin/addAdmin`}
        />
        <Route
          component={TeacherDetails}
          exact
          path={`${match.path}/users/teacher`}
        />
        <Route
          component={AddTeacherDetails}
          exact
          path={`${match.path}/users/teacher/addTeacher`}
        />
        <Route component={Student} exact path={`${match.path}/users/student`} />
        <Route
          component={AddStudents}
          exact
          path={`${match.path}/users/student/addStudent`}
        />
        <Route component={Units} exact path={`${match.path}/units`} />
        <Route
          component={ViewUnit}
          exact
          path={`${match.path}/units/view-unit/:orgUnitInstanceId`}
        />
        <Route
          component={AddUnitAndStudent}
          exact
          path={[
            `${match.path}/units/add-unit`,
            `${match.path}/units/view-unit/:orgUnitInstanceId/add-student`,
          ]}
        />
        <Route component={Classes} exact path={`${match.path}/classes`} />
        <Route
          component={AddClass}
          exact
          path={`${match.path}/classes/add-class`}
        />
        <Route
          component={ViewClass}
          exact
          path={`${match.path}/classes/view-class/:orgClassId`}
        />
        <Route
          component={AssessmentsList}
          exact
          path={`${match.path}/classes/view-class/:orgClassId/units/:orgUnitInstanceId/assessments`}
        />
        <Route
          component={AssessmentSummary}
          exact
          path={`${match.path}/classes/view-class/:orgClassId/units/:orgUnitInstanceId/assessments/:id`}
        />
        <Route
          component={ManageUnits}
          exact
          path={`${match.path}/classes/view-class/:orgClassId/manage-units`}
        />
        <Route
          component={AddStudentsToViewClass}
          exact
          path={`${match.path}/classes/view-class/:orgClassId/add-students`}
        />
        <RestrictedRoute
          component={CurriculumList}
          exact
          path={`${match.path}/curriculum`}
          preferenceName="show_curriculum"
        />
        <Route
          component={ViewCurriculum}
          exact
          path={`${match.path}/curriculum/view-curriculum/:orgCourseId`}
        />
        <Route
          component={ViewFileSharedToClass}
          exact
          path={`${match.path}/curriculum/view-curriculum/:orgCourseId/viewFileToSharedClass/:curriculumFileId`}
        />
        <Route
          component={AllQualifications}
          exact
          path={`${match.path}/qualifications`}
        />
        <Route
          component={AllUnits}
          exact
          path={[
            `${match.path}/qualifications/units`,
            `${match.path}/qualifications/:qualificationId/units`,
          ]}
        />

        <Route component={DashboardNew} exact path={`${match.path}/new`} />

        <Route
          component={ActiveCourses}
          exact
          path={`${match.path}/courses/active-courses`}
        />
        <Route
          component={AddCourse}
          exact
          path={`${match.path}/courses/active-courses/add-courses`}
        />
        <Route
          component={CourseClassesList}
          exact
          path={`${match.path}/courses/active-courses/:orgCourseId/allclasses`}
        />
        <Route component={Sessions} exact path={`${match.path}/sessions`} />
        <Route
          component={AddSession}
          exact
          path={`${match.path}/sessions/add-session`}
        />
        <RestrictedRoute
          component={Library}
          exact
          path={`${match.path}/library`}
          preferenceName="show_library"
        />
        <Route
          component={Attendance}
          exact
          path={`${match.path}/sessions/:sessionId/attendance`}
        />
        {/* <Route
          component={SessionsCalendar}
          exact
          path={`${match.path}/sessions/calendar`}
        /> */}
        <Route
          path={`${match.path}/all-notification`}
          exact
          component={AllNotification}
        />
        <RestrictedRoute
          component={TasList}
          exact
          path={`${match.path}/tas`}
          preferenceName="show_tas"
        />
        <Route
          component={TasForm}
          exact
          path={`${match.path}/:orgClassId/tas-form`}
        />
        <Route
          component={ListAssessments}
          exact
          path={`${match.path}/assessments`}
        />
        <Route
          component={ListAssessments}
          exact
          path={`${match.path}/assessments`}
        />
        <Route
          component={AddAssessmentByQualUnit}
          exact
          path={`${match.path}/assessments/create`}
        />
        <Route
          component={AddAssessmentAdmin}
          exact
          path={`${match.path}/assessments/:id/update`}
        />
        <Route component={TaskList} exact path={`${match.path}/task`} />
        <Route path="*">
          <Redirect to="/PageNotFound" />
        </Route>
      </Switch>
    </>
  );
};

export default Admin;
