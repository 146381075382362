import axios from '../..';

export const fetchAllLibraryFiles = unitId =>
  axios.get(`/api/admin/library-files/${unitId}`);

export const fetchPublicLibraryFiles = () =>
  axios.get(`/api/admin/public-library`);

export const addLibraryDocument = formData =>
  axios.post(`/api/admin/library/add-document`, formData);

export const deleteLibraryClassFile = fileId =>
  axios.post(`/api/admin/library/delete-document/${fileId}`);

export const updateLibraryDocument = (unitId, fileId, data) =>
  axios.post(`/api/admin/library/update-document/${fileId}`, data);

export const fetchActiveClasses = () =>
  axios.get('/api/admin/library/get-class-list');

export const fetchAllCategory = () =>
  axios.get('/api/admin/library/get-category');

export const addNewCategory = newCategory =>
  axios.post('/api/admin/library/add-category', newCategory);

export const individualFileCodeValid = data =>
  axios.post('/api/admin/library/add-document/file-code-validation', data);

export const individualFileCodeValidationInEdit = (data, fileId) =>
  axios.post(
    `/api/admin/library/edit-document/file-code-validation/${fileId}`,
    data,
  );
