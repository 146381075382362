import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const AdminTeacherContainer = styled(AdminListingPageContainer)`
  .serial {
    width: 5%;
  }

  .ref-id {
    width: 10%;
  }

  .first-name {
    width: 12%;
  }
  .last-name {
    width: 12%;
  }

  .email {
    width: 27%;
  }

  .admin {
    width: 9%;
  }

  .teacher {
    width: 10%;
  }
  .status {
    width: 12%;
  }
  .more-option {
    width: 5%;
  }
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
`;
