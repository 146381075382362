/* eslint-disable react/prop-types */
// import {useHistory} from 'react-router-dom';
// import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import {ViewCurriculumFooterContainer} from './elements';

import ArrowLeftIcon from '../../../../../../../../assets/icons/pagination-arrow-left.svg';

export const ViewCurriculumFooter = ({
  pageCount,
  currentPage,
  canPreviousPage,
  canNextPage,
  onNextPageClick,
  onPreviousPageClick,
}) => (
  <ViewCurriculumFooterContainer>
    <div className="footer-container fixed-bottom">
      <div className="footer">
        <div>
          <p className="page">
            Page {currentPage} of {pageCount}
          </p>
        </div>
        <div className="button-container">
          <button
            type="button"
            className={classNames('previous-button', {
              'is-disabled': !canPreviousPage,
            })}
            onClick={() => {
              onPreviousPageClick();
            }}
            id="prev-id"
            disabled={!canPreviousPage}
          >
            <img alt="Previous" src={ArrowLeftIcon} />
            <span>Prev.</span>
          </button>{' '}
          {/* Go to the next page. */}
          <button
            type="button"
            className={classNames('next-button', {
              'is-disabled': !canNextPage,
            })}
            onClick={() => {
              onNextPageClick();
            }}
            id="next-id"
            disabled={!canNextPage}
          >
            <span>Next</span>
            <img alt="Next" src={ArrowLeftIcon} />
          </button>
        </div>
      </div>
    </div>
  </ViewCurriculumFooterContainer>
);
