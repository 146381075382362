import styled from 'styled-components';
import {motion} from 'framer-motion';

export const FilterSliderContainer = styled(motion.div)`
  background-color: #ffffff;
  overflow-x: hidden;
  height: 100vh;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2000;
  .sidebar {
    background-color: white;
    height: 100%;
    width: 28rem;
    overflow: auto;
    padding: 2.5rem 2.5rem;
    @media (max-width: 30rem) {
      & {
        width: 100%;
        padding: 1.5rem 1.5rem;
      }
    }
  }

  .top-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
  }
  .close-btn {
    outline: unset;
    border: unset;
    background-color: transparent;
  }
  .footer {
    display: flex;
    padding: 2.5rem;
    justify-content: space-between;
    @media (max-width: 30rem) {
      & {
        width: 100%;
        padding: 1.5rem 1.5rem;
      }
    }

    .button-container {
      display: flex;
      flex-direction: row;
      .button {
        padding: 0.875rem 1.25rem;
        &.cancel-button {
          background-color: white;
          border: 0.0625rem solid ${({theme}) => theme.colors.primary};

          :active,
          :focus {
            border-color: ${({theme}) => theme.colors.primary};
          }

          .label {
            color: ${({theme}) => theme.colors.primary};
          }
        }
      }
    }
  }
`;
