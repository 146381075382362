import {createSlice} from '@reduxjs/toolkit';

import * as studentLibraryApi from '../api/admin/studentLibrary';

const studentLibrarySlice = createSlice({
  initialState: {
    error: null,
    studentLibrary: null,
    publicLibraryFiles: null,
    orgUnitInstance: null,
    isListLoading: false,
  },
  name: 'studentLibrary',
  reducers: {
    setStudentLibrary: (state, action) => {
      state.studentLibrary = action.payload;
    },
    setStudentPublicLibrary: (state, action) => {
      state.publicLibraryFiles = action.payload;
    },
    setOrgUnitInstance: (state, action) => {
      state.orgUnitInstance = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

export const {
  setStudentLibrary,
  setStudentPublicLibrary,
  setOrgUnitInstance,
  setError,
  setIsListLoading,
  setCategory,
} = studentLibrarySlice.actions;

export const fetchAllLibraryFiles = orgUnitInstanceId => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const [classLibraryResponse, publicLibraryResponse] = await Promise.all([
      studentLibraryApi.fetchAllLibraryFiles(orgUnitInstanceId),
      studentLibraryApi.fetchPublicLibraryFiles(orgUnitInstanceId),
    ]);
    dispatch(setStudentLibrary(classLibraryResponse?.data?.libraryFiles || []));
    dispatch(
      setStudentPublicLibrary(
        publicLibraryResponse?.data?.publicLibraryFiles || [],
      ),
    );
    dispatch(
      setOrgUnitInstance(classLibraryResponse?.data?.orgUnitInstance || []),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const fetchAllCategory = orgUnitInstanceId => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const response = await studentLibraryApi.fetchAllCategory(
      orgUnitInstanceId,
    );
    dispatch(setCategory(response.data.allCategory));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const studentLibrarySelector = state => state.studentLibrary;

export default studentLibrarySlice.reducer;
