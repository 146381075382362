/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Modal} from 'react-bootstrap';
import {Color} from '../../../constants';

export const UnitsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px -15px;
  align-items: center;
  padding-top: 126px;
  padding-bottom: 46px;
  background-color: #f4f5f8;
  overflow-y: scroll;
  .container-unit {
    padding: 0 15px;
    flex-shrink: 0;
    width: 100%;

    @media (min-width: 1344px) {
      & {
        min-width: 1344px;
        width: 93%;
      }
    }
    @media (max-width: 720px) {
      .btn {
        padding: unset;
      }
      .threeDot {
        transform: rotate(90deg);
      }
      .dropdown {
        right: -60px;
      }
    }
  }
  .lockedContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 450px;
    .content-locked {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 35px;
      width: 370px;
      @media (max-width: 400px) {
        & {
          padding: 0 15px;
          width: 300px;
        }
      }
      .statement {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: #3d4457;
        margin-bottom: 8px;
      }
      .info {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #8a8c94;
      }
    }
  }

  .button-spinner1 {
    width: 40px;
    height: 40px;
    animation: rotating 1s linear infinite;
  }
  .loader-images {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
  @media (max-width: 500px) {
    .unit-search {
      width: 200px;
    }
  }
  @media (max-width: 500px) {
    .unit-search {
      width: 200px;
    }
  }

  .modal-container-top {
    width: 660px;
    text-align: left;
    @media (max-width: 660px) {
      & {
        width: 94%;
      }
    }
    .close-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 0px 0px 18px -5px;
    }
    .closeModalResBtn {
      display: none;
      border: none;
      background: transparent;
      outline: none;
    }
  }
  .delete-modal-container-top {
    width: 660px;
    text-align: left;
    @media (max-width: 660px) {
      & {
        width: 94%;
      }
    }
    @media (max-width: 500px) {
      & {
        text-align: left;
        width: 90%;
        max-width: 500px;
      }
      .closeIconBtn {
        display: none;
      }
      .close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 0px 18px -5px;
      }
      .closeModalResBtn {
        display: block;
        border: none;
        background: transparent;
        outline: none;
      }
      .closeModalBtn {
        display: none;
      }
    }
  }
  .closeModalResBtn {
    display: none;
    border: none;
    background: transparent;
    outline: none;
  }
  .modal-container-top {
    @media (max-width: 500px) {
      & {
        text-align: left;
        width: 90%;
        max-width: 500px;
        padding: 20px 0;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          display: none;
        }
        height: 100%;
      }
      .add-unit-row {
        display: flex;
        flex-direction: column;
        padding-top: unset;

        .unit-class-name {
          margin-bottom: 8px;
        }
        .add-unit-col {
          width: 100%;
          padding-top: 10px;
        }
      }
      .closeIconBtn {
        display: none;
      }
      .close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 0px 18px -5px;
      }
      .closeModalResBtn {
        display: block;
        border: none;
        background: transparent;
        outline: none;
      }
      .closeModalBtn {
        display: none;
      }
    }
  }
  .unit-header1 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    @media (max-width: 370px) {
      & {
        display: flex;
        flex-direction: column;
      }

      .count-div {
        width: 100%;
      }
      .search-box {
        width: 100%;
        .unit-search {
          width: 100%;
        }
      }
    }
  }
  .unit-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .unit-header-title {
      color: #3d4457;
      font-size: 24px;
      font-weight: 500;
      line-height: 38px;
    }

    .unit-count {
      color: ${Color.PRIMARY_TEXT};
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0;
    }

    .unit-search {
      height: 40px;
    }
  }

  .unit-table {
    tr {
      :hover {
        background: unset;
      }
    }
    @media (max-width: 720px) {
      td:last-child {
        display: flex;
        justify-content: center;
      }
    }
  }
  .view-unit {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 5px;
    padding: 8px 12px;
    color: #40a1ff;
    font-weight: 500;
    font-size: 14px;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
  }
  /* .addbtn {
    padding: 8px 16px;
    border-radius: 8px;
  } */
  .dropdowncontainer {
    position: relative;
  }
  .dropdown {
    position: absolute;
    right: 1.9rem;
    top: auto;
    min-width: 10rem;
    z-index: 2;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 8px;
  }
  .unit-table-header-line {
    width: 100%;
    background-color: #dedede;
    height: 1px;
  }
  .add-unit-row {
    display: flex;
    justify-content: space-between;
    :first-child {
      padding-top: 0px;
    }
    padding-top: 20px;
    font-size: 14px;
    color: #aaaaaa;
    .add-unit-col {
      width: 49%;
      position: relative;
      .teacher-row {
        display: flex;
        .profile-img {
          /* margin-left: -15px; */
          margin-right: 12px;
        }
        .profile-name {
          margin-top: 3px;
          p {
            margin-bottom: 0.5rem;
          }
          .ref-id {
            color: #b8bac7;
          }
        }
      }
      .typehead-title {
        margin-bottom: 9px;
        color: #3d4457;
      }
      input {
        border-radius: 5px;
        ::placeholder {
          font-size: 14px;

          color: #c0c0c0;
        }
      }
      .units-field {
        border-radius: 5px;
        height: 42px;
      }
      .rbt input {
        height: 42px;
        border-radius: 5px;
        border: 1px solid #dedede;
        .rbt-input-main form-control rbt-input {
        }
        :active,
        :focus {
          border-color: ${Color.PRIMARY};
          box-shadow: 0 0 0 2px ${Color.LIGHT_BLUE};
          outline: none;
          transition: border-color 0.1s, box-shadow 0.1s;
        }
        ::placeholder {
          font-size: 14px;
          line-height: 18px;

          color: #c0c0c0;
        }
      }
      .dropdown-item {
        padding: 16px 24px;
      }
    }
  }
  .modal-seperator {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
  }
  .edit-unit-modal-header {
    display: flex;
    justify-content: space-between;
    p {
      color: #aaaaaa;
    }
    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }
  }
  .edit-unit-title {
    color: #aaaaaa;
  }
  .edit-unit-name {
    color: #3d4457;
    font-weight: 500;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .save-changes {
      border: 1px solid #40a1ff;
      border-radius: 8px;
      .add-button-text {
        color: #40a1ff;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .cancel {
      margin-right: 16px;
      border: 1px solid #c1ced4;
      border-radius: 8px;
      align-items: flex-end;
      .cancel-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .delete-department-modal {
    .delete-department-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      .modal-header-left {
        display: flex;
      }
      .delete-department-modal-title {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
      }
      .closeModalBtn {
        border: none;
        background: transparent;
        outline: none;
        top: 26px;
        left: 26px;
      }
      p {
        color: #aaaaaa;
      }
    }
    .footer-delete-break {
      top: 40px;
      height: 1px;
      background-color: #e0e0e0;
      width: 100%;
    }
    .delete-department-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
`;
export const DropdownBtn = styled.div`
  .btn {
    outline: none;
    box-shadow: unset;
    :focus {
      background: #fafafa;
      box-shadow: unset;
      outline: none;
    }
  }
`;

export const ViewUnit = styled.div`
  background-color: #f4f5f8;
  margin: 0px -15px;
  .view-unit-header {
    background-color: #ffffff;
    padding: 23px 48px 24px 48px;
    @media (max-width: 750px) {
      & {
        padding: 24px;
      }
    }
    .back-button {
      margin-bottom: 16px;
    }

    .view-unit-title {
      display: flex;
      justify-content: space-between;
      .unit-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
      .created-date {
        margin-top: 8px;
        font-size: 14px;
        line-height: 17px;
        color: #bdbdbd;
      }
    }
    @media (max-width: 500px) {
      padding: 24px 20px;
    }
  }
  .header-break {
    height: 1px;
    background-color: #e9e9e9;
    width: 100%;
  }
  .view-unit-header-bottom {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    justify-content: space-between;
    background-color: #ffffff;
    /* padding: 26px 48px 26px 48px; */
    .view-list {
      margin: 24px 48px 24px 48px;
      :first-child {
        border-left: none;
      }
      .unit-list-top {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #aaaaaa;
      }
      .unit-list-bottom {
        padding-top: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #3d4457;
      }
    }
    .vertical-line {
      margin-top: 16px;
      height: 60px;
      width: 1px;
      background-color: #e9e9e9;
      flex-shrink: 0;
    }
  }
  .dropdowncontainer {
    position: relative;
  }
  .dropdown {
    position: absolute;
    right: 1.9rem;
    top: auto;
    min-width: 10rem;
    z-index: 2;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 8px;
  }
  .unit-table-header-line {
    width: 100%;
    background-color: #dedede;
    height: 1px;
  }
  .add-unit-row {
    display: flex;
    justify-content: space-between;
    :first-child {
      padding-top: 0px;
    }
    padding-top: 20px;
    font-size: 14px;
    color: #aaaaaa;
    .add-unit-col {
      width: 49%;
      position: relative;
      .teacher-row {
        display: flex;
        .profile-img {
          /* margin-left: -15px; */
          margin-right: 12px;
        }
        .profile-name {
          margin-top: 3px;
          p {
            margin-bottom: 0.5rem;
          }
          .ref-id {
            color: #b8bac7;
          }
        }
      }
      .typehead-title {
        margin-bottom: 9px;
        color: #3d4457;
      }
      input {
        border-radius: 5px;
        ::placeholder {
          font-size: 14px;

          color: #c0c0c0;
        }
      }
      .units-field {
        border-radius: 5px;
        height: 42px;
      }
      .rbt input {
        height: 42px;
        border-radius: 5px;
        border: 1px solid #dedede;
        .rbt-input-main form-control rbt-input {
        }
        :active,
        :focus {
          border-color: ${Color.PRIMARY};
          box-shadow: 0 0 0 2px ${Color.LIGHT_BLUE};
          outline: none;
          transition: border-color 0.1s, box-shadow 0.1s;
        }
        ::placeholder {
          font-size: 14px;
          line-height: 18px;

          color: #c0c0c0;
        }
      }
      .dropdown-item {
        padding: 16px 24px;
      }
    }
  }
  .modal-seperator {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
  }
  .edit-unit-modal-header {
    display: flex;
    justify-content: space-between;
    p {
      color: #aaaaaa;
    }
    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }
  }
  .edit-unit-title {
    color: #aaaaaa;
  }
  .edit-unit-name {
    color: #3d4457;
    font-weight: 500;
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .save-changes {
      border: 1px solid #40a1ff;
      border-radius: 8px;
      .add-button-text {
        color: #40a1ff;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .cancel {
      margin-right: 16px;
      border: 1px solid #c1ced4;
      border-radius: 8px;
      align-items: flex-end;
      .cancel-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .close-btn {
    display: none;
  }
  .modal-container-top {
    @media (max-width: 500px) {
      & {
        text-align: left;
        width: 90%;
        max-width: 500px;
        padding: 20px 0;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          display: none;
        }
        height: 100%;
      }
      .add-unit-row {
        display: flex;
        flex-direction: column;
        padding-top: unset;

        .unit-class-name {
          margin-bottom: 8px;
        }
        .add-unit-col {
          width: 100%;
          padding-top: 10px;
        }
      }
      .closeIconBtn {
        display: none;
      }
      .close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 0px 18px -5px;
      }
      .closeModalResBtn {
        display: block;
        border: none;
        background: transparent;
        outline: none;
      }
      .closeModalBtn {
        display: none;
      }
    }
  }
`;
export const StudentTableContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px -15px;
  align-items: center;
  padding-top: 24px;
  background-color: #f4f5f8;
  overflow-y: scroll;
  .student-container-unit {
    padding: 0 15px;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 1344px) {
      & {
        min-width: 1344px;
        width: 93%;
      }
    }
    @media (max-width: 720px) {
      .btn {
        padding: unset;
      }
      .threeDot {
        transform: rotate(90deg);
      }
      .dropdown {
        right: -60px;
      }
    }
  }
  .modal-container-top {
    .close-btn {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 0px 0px 18px -5px;
    }
    .closeModalResBtn {
      display: none;
      border: none;
      background: transparent;
      outline: none;
    }
  }
  .button-spinner1 {
    width: 40px;
    height: 40px;
    animation: rotating 1s linear infinite;
  }
  .loader-images {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
  .modal-container-top {
    /* width: 660px; */
    text-align: left;
    @media (max-width: 660px) {
      & {
        width: 94%;
      }
      .add-student-modal-body {
        display: flex;
        flex-direction: column;
        .date-wrapper1 {
          flex-grow: unset;
          margin-right: unset;
          margin-bottom: 1rem;
        }
        .date-wrapper2 {
          flex-grow: unset;
          margin-left: unset;
          margin-bottom: 1rem;
        }
        .date-wrapper3 {
          flex-grow: unset;
          margin-left: unset;
        }
      }
      .closeModalResBtn {
        display: block;
      }
      .closeModalBtn {
        display: none;
      }
      .closeIconBtn {
        display: none;
      }
    }
    @media (max-width: 414px) {
      & {
        text-align: left;
        width: 90%;
        max-width: 414px;
        height: auto;
      }

      .closeIconBtn {
        display: none;
      }
      .close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 0px 18px -5px;
      }
      .closeModalResBtn {
        display: block;
        border: none;
        background: transparent;
        outline: none;
      }
      .closeModalBtn {
        display: none;
      }
    }
  }
  @media (max-width: 720px) {
    .student-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        .add-button {
          margin-left: 50px;
        }
        .addbtn {
          margin: 0px 8px 0px 8px;
          display: none;
        }
        .addbtn1 {
          margin: 0px 8px 0px 8px;
          display: block;
        }
      }
      .student-table-header-right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
      }
    }

    .invitation-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .invitation-status-0,
    .invitation-status-1,
    .invitation-status-2 {
      border-radius: 5px;
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.21;
      padding: 8px 16px;
    }
    .invitation-status-0 {
      background-color: #f4f4f4;
      color: #8c8c8c;
    }
    .invitation-status-1 {
      background-color: #e4f4f1;
      color: #4ec977;
    }
    .invitation-status-2 {
      background-color: #f4f4f4;
      color: #8c8c8c;
    }
  }
  .student-table-header-left {
    display: flex;
    .addbtn {
      margin: 0px 16px 0px 0px;
    }
  }
  .student-table-header-right {
    display: flex;
    .addbtn1 {
      display: none;
      margin: 0px 16px 0px 0px;
    }
  }

  .add-student-modal-body {
    display: flex;
    justify-content: space-between;
    .date-wrapper1 {
      flex-grow: 1;
      margin-right: 8px;
    }
    .date-wrapper2 {
      flex-grow: 1;
      margin-left: 8px;
      margin-right: 8px;
    }
    .date-wrapper3 {
      flex-grow: 1;
      margin-left: 8px;
    }
    p {
      color: #aaaaaa;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .student-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .student-count {
      color: #3d4457;
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 0;
    }

    .student-search {
      height: 40px;
      @media (max-width: 370px) {
        & {
          width: 250px;
        }
      }
      .add-button {
        margin-left: 50px;
      }
    }
  }

  .student-table {
    tr {
      :hover {
        background: unset;
      }
    }
    @media (max-width: 720px) {
      td:last-child {
        display: flex;
        justify-content: center;
      }
    }
  }

  .dropdowncontainer {
    position: relative;
  }
  .dropdown {
    position: absolute;
    right: 1.9rem;
    top: auto;
    min-width: 10rem;
    z-index: 2;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 8px;
  }

  .invitation-container {
    display: flex;
    flex-direction: row;
  }
  .invitation-status-0,
  .invitation-status-1,
  .invitation-status-2 {
    border-radius: 5px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.21;
    padding: 8px 16px;
  }
  .invitation-status-0 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }
  .invitation-status-1 {
    background-color: #e4f4f1;
    color: #4ec977;
  }
  .invitation-status-2 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }
  .invited {
    background-color: #ebf5ff;
    color: #40a1ff;
    margin-left: 8px;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 24px;
      width: 24px;
    }
  }
  .student-table-header-line {
    width: 100%;
    background-color: #dedede;
    height: 1px;
  }
  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px 24px 0px;
    .pageno {
      font-size: 13px;
      color: #8c8c8c;
    }
    .pagination {
      width: 100%;
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px;
      .page-prev {
        background: #ffffff;
        display: flex;
        padding: 0px 12px;
        align-items: center;
        justify-content: center;
        border: 1px solid #bdbdbd;
        border-radius: 5px 0px 0px 5px;
        height: 32px;
        .btnu {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          color: #bdbdbd;
          background: unset;
        }
      }
      .page-next {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 12px;
        background: #ffffff;
        border: 1px solid #bdbdbd;
        border-radius: 0px 5px 5px 0px;
        .btnu {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          color: #bdbdbd;
          background: unset;
        }
      }
      .page-item {
        height: 32px;
        align-items: center;
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #bdbdbd;
        p {
          color: #bdbdbd;
        }
        .btnu {
          width: 100%;
          border: none;
          outline: none;
          color: #bdbdbd;
          background: unset;
        }
      }
      .text-success {
        background: #ecf6ff;
        border: 1px solid #40a1ff;
        .btnu {
          color: #40a1ff;
        }
      }
    }
  }

  .add-student-modal {
    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .modal-seperator {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
  }
  .add-student-modal-header {
    display: flex;
    justify-content: space-between;
    p {
      color: #aaaaaa;
    }
  }
  .delete-modal {
    width: 660px;
    @media (max-width: 660px) {
      & {
        width: 100%;
      }
    }
  }
  .delete-student-modal {
    .delete-student-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      .modal-header-left {
        display: flex;
        .confirm {
          color: #aaaaaa;
        }
      }
      .delete-student-modal-title {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
      }
      .closeModalBtn {
        outline: none;
        background-color: transparent;
        border: none;
      }
    }
    .footer-delete-break {
      top: 40px;
      height: 1px;
      background-color: #e0e0e0;
      width: 100%;
    }
    .delete-student-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .close-btn {
    display: none;
  }
  .delete-unit-modal-container-top {
    width: 660px;
    text-align: left;
    @media (max-width: 660px) {
      & {
        width: 94%;
      }
    }
    @media (max-width: 500px) {
      & {
        text-align: left;
        width: 90%;
        max-width: 500px;
      }
      .closeIconBtn {
        display: none;
      }
      .close-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 0px 0px 18px -5px;
      }
      .closeModalResBtn {
        display: block;
        border: none;
        background: transparent;
        outline: none;
      }
      .closeModalBtn {
        display: none;
      }
    }
  }
  .delete-department-modal {
    .delete-department-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      .modal-header-left {
        display: flex;
      }
      .delete-department-modal-title {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
      }
      .closeModalBtn {
        border: none;
        background: transparent;
        outline: none;
        top: 26px;
        left: 26px;
      }
      p {
        color: #aaaaaa;
      }
    }
    .footer-delete-break {
      top: 40px;
      height: 1px;
      background-color: #e0e0e0;
      width: 100%;
    }
    .delete-department-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
`;

export const AddUnitHeader = styled.div`
  background-color: #f4f5f8;
  margin: 0px -15px;
  min-height: 8vh;
  .add-unit-header {
    width: 100%;
    background-color: #ffffff;
    padding: 23px 48px 24px 48px;

    .add-unit-title {
      display: flex;
      justify-content: space-between;
      .add-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
  }
`;
export const AddUnitContainer = styled.div`
  min-height: 82vh;
  display: flex;
  flex-direction: column;
  margin: 0px -15px;
  align-items: center;
  padding-top: 24px;
  background-color: #f4f5f8;
  .add-unit-container {
    padding: 0 15px;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 888px) {
      & {
        min-width: 888px;
        width: 61.5%;
      }
    }
    .add-unit-custom-box {
      padding: 24px;

      .add-unit-title {
        font-weight: 500;
        font-size: 20px;
        color: #3d4457;
      }
      .add-unit-header {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #aaaaaa;

        span {
          font-weight: 500;
          color: #3d4457;
          margin-left: 11px;
        }
      }
      .add-unit-row {
        display: flex;
        justify-content: space-between;
        padding-top: 24px;
        font-size: 14px;
        line-height: 17px;
        color: #aaaaaa;
        .add-unit-col {
          width: 49%;
          position: relative;
          .suggestion {
            position: absolute;
            background: #ffffff;
            border: 1px solid #dedede;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
            border-radius: 5px;
            z-index: 2;
            margin-top: 8px;
            padding: 8px;
            border-radius: 4px;
            width: 100%;
            .search-table {
              tr {
                border-bottom: 1px solid transparent;
                border-top: 1px solid transparent;
                border-radius: 4px;

                .select-teacher {
                  border: none;
                  background: transparent;
                  outline: none;
                  text-align: left;
                  height: 5vh;
                  margin-top: -4px;
                  .teacher-row {
                    display: flex;
                    .profile-img {
                      margin-left: -15px;
                      margin-right: 9px;
                    }
                    .profile-name {
                      margin-top: 6px;
                      .ref-id {
                        color: #b8bac7;
                        margin-top: 2px;
                      }
                    }
                  }
                }
                .select-department {
                  border: none;
                  background: transparent;
                  outline: none;
                  text-align: left;
                  height: 3vh;
                  margin-top: -4px;
                  .department-row {
                    display: flex;

                    .department-name {
                      margin-top: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const CustomDatePicker = styled.div`
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 5px;
  :focus-within {
    border-color: ${Color.PRIMARY};
    box-shadow: 0 0 0 2px ${Color.LIGHT_BLUE};
    outline: none;
  }
  .openDatepicker {
    background-color: transparent;
    outline: none;
    border: none;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker {
    margin-left: 26px;
    .react-datepicker__navigation {
      height: 15px;
      width: 15px;
      outline: none;
    }
    .react-datepicker__triangle {
      border: none;
      ::before {
        border: none;
      }
    }
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
    margin-bottom: 24px;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background: unset;
      border-bottom: unset;
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: #b8bac7;
          font-size: 12px;
        }
      }
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #3d4457;
      display: inline-block;
      width: 2.233rem;
      line-height: 1.7rem;
      text-align: center;
      margin: 0.166rem;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      color: #3d4457;
      font-weight: bold;
      background: unset;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__year-text--in-range {
    background: #f0f0f0;
  }

  .date-picker {
    border-radius: 8px;
    color: #3d4457;
  }
  .date-picker::placeholder {
    color: #d5d5d5;
  }
`;
export const AddUnitFooter = styled.div`
  .float-left {
    padding: 21px;
    .progress-bar {
      width: 318px;
      height: 8px;
      background-color: #dedede;
      border-radius: 8px;
      .progress-loader {
        width: 2%;
        height: 100%;
        background-color: #40a1ff;
        &.progress-loader-1 {
          width: 50%;
        }
        &.progress-loader-2 {
          width: 100%;
        }
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 22px;
    .save-changes {
      /* margin-right: 4px; */
      margin-right: 24.5px;
    }
    .cancel {
      margin-right: 16px;
      border: 1px solid #dedede;
      border-radius: 8px;
      align-items: flex-end;
      .cancel-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
`;
