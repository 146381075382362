import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../../../../../../Admin/elements-new';

export const ViewFileSharedToClassContainer = styled(AdminListingPageContainer)`
  padding: 5rem 0 0rem 5rem !important;
  @media (max-width: 55.5rem) {
    & {
      padding: 5rem 0 0rem 5rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 5rem 0 0rem 0rem !important;
    }
  }
`;
