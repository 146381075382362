/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from 'react';
import moment from 'moment';

import {useHistory, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {MoreOptions, Modal} from '../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../components/common/MoreOptions/components';

import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';
import {ViewClassHeaderContainer} from './elements/ViewClassHeaderContainer';
import {Section} from './components/SectionComponent';
import {EditClassModal} from '../../../../components/EditClassModal';
import CourseIcon from '../../../../../../../../assets/icons/course-icon.svg';
import SessionIcon from '../../../../../../../../assets/icons/session-icon.svg';
import EndDateIcon from '../../../../../../../../assets/icons/endDate.svg';
import UpdateClassToastIcon from '../../../../../../../../assets/icons/class-success.svg';
import DepartmentIcon from '../../../../../../../../assets/icons/departmentIcon.svg';
import {showToast} from '../../../../../../../../components/common/Toast';
import {
  classesSelector,
  deleteClass,
  getClassById,
  updateViewClass,
} from '../../../../../../../../redux/classesSlice';

const DELAY = 500;
export const Header = () => {
  const history = useHistory();
  const {orgClassId} = useParams();
  const {singleClass, isSingleClassLoading} = useSelector(classesSelector);
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [editDetails, setEditDetails] = useState(null);

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  const onSubmit = useCallback(
    detail => {
      dispatch(
        updateViewClass(editDetails.id, detail, () => {
          clearEditDetails();
          showToast(UpdateClassToastIcon, `Class details updated successfully`);
        }),
      );
    },
    [clearEditDetails, dispatch, editDetails],
  );

  useEffect(() => {
    dispatch(getClassById(orgClassId));
  }, []);

  function NameFormatter(firstName, lastName) {
    if (firstName && lastName) {
      return `${firstName[0]}${lastName[0]}`;
    }
    return firstName[0];
  }

  return (
    <ViewClassHeaderContainer>
      <div className="view-class-header">
        <div className="back-button">
          <button
            className="btn"
            onClick={() => history.replace('/admin/classes')}
            style={{
              padding: 0,
            }}
            type="button"
          >
            <img src={ArrowLeftImg} alt="arrow" />
            <span>Back</span>
          </button>
        </div>
        <div className="overview-data">
          {singleClass ? (
            <div className="top">
              <div className="view-class-title">
                <div>
                  <span className="class-name">{singleClass?.oc_class}</span>
                  <p className="qualification-name">
                    {singleClass?.qualification?.tqual_title}
                  </p>
                </div>
                <div className="overview-right">
                  <div className="teacher-info">
                    <div>
                      <span className="teacher-name">
                        {`${singleClass?.teacher?.up_name_first} ${singleClass?.teacher?.up_name_last}`}
                      </span>
                      <p className="class-teacher">Class Teacher</p>
                    </div>
                    <div className="letters">
                      {NameFormatter(
                        singleClass?.teacher?.up_name_first,
                        singleClass?.teacher?.up_name_last,
                      )}
                    </div>
                  </div>

                  <div className="threedots">
                    <MoreOptions>
                      <DropdownItem
                        label="Edit Class Details"
                        onClick={() => {
                          setEditDetails({
                            id: singleClass?.oc_id_orgclass,
                            department: {
                              id: singleClass?.department?.od_id_orgdepartment,
                              name: singleClass?.department?.od_department_name,
                            },
                            className: singleClass?.oc_class,
                            academicYear: {
                              id: singleClass?.academicYear?.oay_id_orgacadyear,
                              name: singleClass?.academicYear?.oay_year,
                            },
                            classTeacher: {
                              id: singleClass?.teacher.up_id_userprofile,
                              name: `${singleClass?.teacher.up_name_first} ${singleClass?.teacher.up_name_last}`,
                            },
                            startDate: singleClass?.oc_date_start,
                            endDate: singleClass?.oc_date_end,
                          });
                        }}
                      />
                      <DropdownItem
                        isDanger
                        label="Delete"
                        onClick={() => {
                          setDeleteId(singleClass?.oc_id_orgclass);
                          setDeleteName(singleClass?.oc_class);
                        }}
                      />
                    </MoreOptions>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="header-break" />
          {singleClass ? (
            <div className="view-class-header-bottom">
              <Section
                Icon={DepartmentIcon}
                header="Department"
                data={singleClass?.department?.od_department_name}
              />
              <div className="vertical-line" />
              <Section
                Icon={CourseIcon}
                header="Course"
                data={singleClass?.course?.ocr_course_name || '-'}
              />
              <div className="vertical-line" />
              <Section
                Icon={SessionIcon}
                header="No of Sessions"
                data={singleClass?.course?.ocr_sessions_total || '-'}
              />
              <div className="vertical-line" />

              <div className="date">
                <div className="start-date">
                  <div className="view-img mt-1">
                    <img
                      src={EndDateIcon}
                      alt="close"
                      className="view-list-img"
                    />
                  </div>
                  <div className="mx-2">
                    <span className="overview-bottom-header">Start Date</span>
                    <p>
                      {moment(singleClass?.oc_date_start).format('MMM D YYYY')}
                    </p>
                  </div>

                  <div className="mx-2">
                    <span className="overview-bottom-header">End Date</span>
                    <p>
                      {moment(singleClass?.oc_date_end).format('MMM D YYYY')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this class?"
        isDelete
        isButtonLoading={isSingleClassLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          dispatch(
            deleteClass(deleteId, () => {
              setDeleteId(null);
              history.replace('/admin/classes');
              showToast(false, `${deleteName} has been archived.`, true);
              setTimeout(() => {
                setDeleteName('');
              }, DELAY);
            }),
          );
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
      <EditClassModal
        editDetails={editDetails}
        onHide={clearEditDetails}
        onSubmit={onSubmit}
      />
    </ViewClassHeaderContainer>
  );
};
