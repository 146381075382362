/* eslint-disable react/prop-types */
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';

import {useHistory, useParams} from 'react-router-dom';

import {DropdownItem} from '../../../../../../../components/common/MoreOptions/components';
import {
  Table,
  EmptyState,
  MoreOptions,
  Modal,
} from '../../../../../../../components/common';
import {showToast} from '../../../../../../../components/common/Toast';
import PlusIcon from '../../../../../../../assets/icons/refactor/plus-white.svg';
import {MyAssessmentsContainer} from './elements';
import {
  deleteAssessment,
  // fetchOneUnitById,
  unitsSelector,
} from '../../../../../../../redux/unitsSlice';

export const Assessments = ({assessments, dispatch}) => {
  const history = useHistory();
  const {isDeleteLoading} = useSelector(unitsSelector);
  const {unitId, qualificationId} = useParams();

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');

  const commonPath = `/admin/qualifications/${qualificationId}/units/${unitId}`;

  const onButtonClick = () => {
    history.push(`${commonPath}/assessment/create`);
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'TITLE',
        accessor: 'ac_activity_name',
        className: 'assessment-title',
      },
      {
        Header: 'TYPE',
        Cell: ({value}) => <p className="column-text">{value}</p>,
        accessor: 'am_activitymethod',
        className: 'type',
      },

      {
        Header: 'LAST UPDATED',
        Cell: ({row: {original}}) => {
          const date = original?.ac_timestamp_creation;
          return <p className="date"> {moment(date).format('D/MM/YYYY')}</p>;
        },
        accessor: 'ac_timestamp_creation',
        className: 'last-updated',
      },
      {
        Header: 'CREATED BY',
        Cell: ({row: {original}}) => (
          <p className="column-text">
            {original?.up_name_first} {original?.up_name_last}
          </p>
        ),
        accessor: 'up_name_first',
        className: 'created-by',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <MoreOptions>
            <DropdownItem
              label="Edit"
              onClick={() => {
                history.replace(
                  `${commonPath}/assessment/${original?.ac_id_activity}/update`,
                );
              }}
            />
            <DropdownItem
              isDanger
              label="Delete"
              onClick={() => {
                setDeleteId(original?.ac_id_activity);
                setDeleteName(original?.ac_activity_name);
              }}
            />
          </MoreOptions>
        ),
        accessor: 'ac_id_activity',
        className: 'more-option',
      },
    ],
    [commonPath, history],
  );

  //   Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(assessments || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [assessments],
  );
  // const onDeleteAssessment = () => dispatch(fetchOneUnitById(unitId));

  return (
    <MyAssessmentsContainer>
      <div className="table-container">
        {!data.length ? (
          <EmptyState
            description="It seems that there is no Assessments has been added"
            title="No Assessments are added yet!"
            buttonLabel="Add Assessment"
            buttonIcon={PlusIcon}
            onButtonClick={onButtonClick}
          />
        ) : null}
        {data?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Assessments"
            maxWidth="75rem"
            isSortedBy
            buttonLabel="Add Assessment"
            buttonIcon={PlusIcon}
            onButtonClick={onButtonClick}
          />
        ) : null}
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this assessment?"
          isButtonLoading={isDeleteLoading}
          isDelete
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isDeleteLoading) {
              dispatch(
                deleteAssessment(
                  deleteId,
                  () => {
                    // onDeleteAssessment();
                    showToast(
                      false,
                      `${deleteName} deleted successfully`,
                      true,
                    );
                  },
                  unitId,
                ),
              );
              setDeleteId(null);
              setDeleteName('');
            }
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, 500);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
      </div>
    </MyAssessmentsContainer>
  );
};
