import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import {InvitationStatusContainer} from './elements';
import ToolTipImg from '../../../assets/icons/tooltipimg.svg';

export const InvitationStatus = ({value, time}) => (
  <InvitationStatusContainer className="invitation-container">
    <div className={`invitation-status-${value}`}>
      {value === 1 ? 'Active' : 'Pending'}
    </div>
    {value === 2 ? (
      <div>
        <div
          className="invited ml-2"
          data-html
          data-tip={`<span className="tooltip-value">Invite resent on: ${moment(
            time,
          ).format('D MMM YYYY')}</span>`}
        >
          <img alt="Reassigned" src={ToolTipImg} />
        </div>
        <ReactTooltip backgroundColor="#3d4457" effect="solid" place="bottom" />
      </div>
    ) : null}
  </InvitationStatusContainer>
);

InvitationStatus.propTypes = {
  time: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
