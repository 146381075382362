/* eslint-disable react/prop-types */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {TextInput, Modal} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';
import {
  studentsSelector,
  setError,
  updateStudent,
} from '../../../../../../../../redux/studentSlice';
import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {studentSchema} from '../../../../../../../../utils/validation';
import UpdateUserToastIcon from '../../../../../../../../assets/icons/updateadmintoast.svg';

const FIELDS_IN_ORDER = ['referId', 'firstName', 'lastName', 'email'];

export const EditStudentModal = ({editDetails, onHide}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      referId: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    resolver: yupResolver(studentSchema),
  });

  const {error: serverError, isStudentLoading} = useSelector(studentsSelector);

  useEffect(() => {
    if (editDetails) {
      setValue('referId', editDetails.referId);
      setValue('firstName', editDetails.firstName);
      setValue('lastName', editDetails.lastName);
      setValue('email', editDetails.email);
    }
  }, [editDetails, setValue]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      if (!isStudentLoading) {
        dispatch(
          updateStudent(editDetails?.studentId, data, () => {
            onHide();
            showToast(
              UpdateUserToastIcon,
              `Student details updated successfully`,
            );
          }),
        );
      }
    },
    [dispatch, editDetails, isStudentLoading, onHide],
  );

  const {hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <Modal
      buttonLabel="Save Changes"
      description="Make changes to the user details"
      isButtonLoading={isStudentLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit User details"
      width="55.5rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="d-flex pb-4 px-4">
            <Controller
              control={control}
              name="referId"
              render={fields => (
                <TextInput
                  {...fields}
                  autoFocus
                  className="mr-4"
                  hasError={hasServerError || !!errors.referId}
                  label="Reference ID"
                  placeholder="Enter Reference ID"
                />
              )}
            />
            <Controller
              control={control}
              name="firstName"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mr-4"
                  hasError={hasServerError || !!errors.firstName}
                  label="First Name"
                  placeholder="Enter first name"
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={fields => (
                <TextInput
                  {...fields}
                  hasError={hasServerError || !!errors.lastName}
                  label="Last Name"
                  placeholder="Enter last name"
                />
              )}
            />
          </div>
          <div className="d-flex pb-4 px-4">
            <Controller
              control={control}
              name="email"
              render={fields => (
                <TextInput
                  {...fields}
                  hasError={hasServerError || !!errors.email}
                  label="Email"
                  placeholder="Enter email address"
                  isDisabled
                />
              )}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

EditStudentModal.defaultProps = {
  editDetails: null,
};

EditStudentModal.propTypes = {
  editDetails: PropTypes.shape({
    referID: PropTypes.string.isRequired,
    studentId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onHide: PropTypes.func.isRequired,
};
