import styled from 'styled-components';

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    font-size: 1.5rem;
    text-align: center;
  }
  .state {
    color: #aaaaaa;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    text-align: center;
    width: 500px;
    @media (max-width: 35.25rem) {
      & {
        padding: 0rem 0.5rem !important;
        width: 100%;
      }
    }
  }
  .accordion {
    width: 100%;
    border-radius: 0.5rem;
    .card-header {
      background-color: #ffffff;
      padding: 0.75rem 0rem;
      @media (max-width: 31.25rem) {
        & {
          padding: 0.3rem 0rem;
        }
      }
    }
    .btn {
      line-height: 1;
      color: #3d4457;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }
    }
  }
`;

export const SpreadSheetTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 0 0 1.5rem 0;
  width: 100%;
  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;
    td {
      :last-child {
        display: flex !important;
        justify-content: space-between;
      }
    }

    .serial {
      width: 5%;
    }

    .ref-id-manual {
      width: 13%;
    }

    .first-name-manual {
      width: 21%;
    }
    .last-name-manual {
      width: 21%;
    }

    .email-manual {
      width: 33%;
    }
    .close-row {
      width: 5%;
    }

    .closeIconBtn {
      display: block;
      border: none;
      background: transparent;
      outline: none;
    }
  }
`;
