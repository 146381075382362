import styled from 'styled-components';

export const TaskSubmissionContainer = styled.div`
  background-color: #f4f5f8;
  display: flex;
  flex-direction: column;
  ${({fixedHeight}) =>
    `
      ${fixedHeight ? 'height: 100vh' : 'min-height: 100vh'};
    `}
  ${({flexEnd}) => (flexEnd ? 'justify-content: flex-end;' : '')}
  margin: 0 -0.937rem;
  overflow-y: hidden;
  padding-top: ${({paddingTop}) => paddingTop}px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 43.75rem) {
    .footer {
      padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
      .footer-reassign-button {
        display: none;
      }
    }
  }

  .more-option {
    &.is-archived {
      display: none;
    }
    &.is-student {
      display: none;
    }
  }
`;
