/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';

import ArrowIcon from '../../../../../../../../assets/icons/students-dropdown-arrow.svg';
import SearchInput from '../../../../../../../../components/shared/Inputs/SearchInput';
import {StudentsDropdownItem} from './components';
import {StudentDropdownContainer} from './elements';

export const StudentsDropdown = ({
  onSelect,
  postStudents,
  students,
  title,
  onChanged,
}) => {
  const container = useRef();
  const [filteredStudents, setFilteredStudents] = useState(
    students?.map(student => student.id) || [],
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudents, setSelectedStudents] = useState(
    postStudents?.map(student => student.aouis_id_userprofile_student) ||
      students?.map(student => student.id) ||
      [],
  );
  const [isToggled, setIsToggled] = useState(false);

  const handleClick = event => {
    if (!container.current.contains(event.target)) {
      setIsToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  useEffect(() => {
    onSelect(selectedStudents);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStudents]);

  useEffect(() => {
    if (students) {
      const regularExpression = new RegExp(searchTerm, 'i');

      const searchResults = students.filter(item =>
        regularExpression.test(item.value),
      );

      setFilteredStudents(searchResults);
    }
  }, [searchTerm, students]);

  const handleSelect = id => {
    if (selectedStudents.includes(id)) {
      setSelectedStudents(
        selectedStudents.filter(studentId => studentId !== id),
      );
    } else {
      setSelectedStudents([...selectedStudents, id]);
    }

    onChanged();
  };

  const handleSelectAll = () => {
    if (selectedStudents.length === students.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(students.map(student => student.id));
    }

    onChanged();
  };

  return (
    <StudentDropdownContainer ref={container}>
      <button
        className={`dropdown-toggler ${isToggled ? 'is-toggled' : ''}`}
        onClick={() => setIsToggled(previousState => !previousState)}
        type="button"
        id="dropdown-student-id"
      >
        <span>{title}</span>
        <img alt="Toggle" src={ArrowIcon} className="toggle-img" />
      </button>
      {isToggled ? (
        <div className="dropdown">
          <SearchInput
            className="students-search"
            onChange={event => setSearchTerm(event.target.value)}
            placeholder="Search student"
            value={searchTerm}
            id="search-student"
          />
          {searchTerm ? null : (
            <StudentsDropdownItem
              isChecked={selectedStudents.length === students.length}
              isForAllStudents
              name="All Students"
              onClick={handleSelectAll}
              referenceId={`${students.length} Students`}
            />
          )}
          {filteredStudents.map((student, index) => (
            <StudentsDropdownItem
              key={student.id}
              isChecked={selectedStudents.includes(student.id)}
              isLastItem={index === filteredStudents.length - 1}
              onClick={() => handleSelect(student.id)}
              name={student.value}
              referenceId={student.referenceId}
            />
          ))}
        </div>
      ) : null}
    </StudentDropdownContainer>
  );
};

StudentsDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};
