import styled from 'styled-components';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10.625rem;
  padding: 2rem;

  .circle {
    border: 0.125rem solid white;
    border-radius: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    height: 3rem;
    line-height: 1.5;
    margin-left: -0.375rem;
    width: 3rem;
    text-transform: uppercase;
  }

  .student {
    background-color: #40a1ff;
    color: white;
  }

  p {
    width: 240px;
    text-align: center;
    margin-top: 2rem;
  }
  img {
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
  }
`;

export default BodyWrapper;
