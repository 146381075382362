import styled from 'styled-components';

export const OrganisationLogoContainer = styled.div`
  .inputfields {
    padding: 0 4px;
    margin-bottom: 24px;
    .inputfields1 {
      padding: 0 4px;
    }
    .logo-label {
      color: #3d4457;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .organisation-image-div {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      align-items: center;
      border: 1px dashed #dedede;
      padding: 16px;
      justify-content: space-between;
      border-radius: 5px;
      .organisation-image-after {
        display: flex;
        flex-direction: row;
        .file-details {
          display: flex;
          padding-left: 16px;
          flex-direction: column;

          .file-name {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #3d4457;
          }
          .file-size {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            padding-top: 8px;
            color: #aaaaaa;
          }
        }
        img {
          object-fit: contain;
          width: 40px;
          height: 48px;
        }
      }
      .delete-logo {
        button {
          border: none;
          background-color: transparent;
          :focus,
          :active {
            outline: none;
          }
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .organisation-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 24px;
      background: #ffffff;
      border: 1px dashed #dedede;
      box-sizing: border-box;
      border-radius: 5px;
      &.logo-drag {
        background: #f7fbff;
        border-color: #40a1ff;
      }
      &.logo-drag-error {
        background: #fff9f7;
        border-color: #d64727;
      }
      .organisation-text {
        padding: 12px 0 16px 0;
        text-align: center;
        position: relative;
        overflow: hidden;
      }
      .organisation-seperator {
        width: 300px;
        height: 1px;
        background-color: #eaeaea;
        margin-bottom: 10px;
        @media (max-width: 500px) {
          width: 100%;
        }
      }

      .organisation-text input {
        position: absolute;
        color: black;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 200px;
      }
      span {
        color: #40a1ff;
      }
    }
    .organisation-images {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 158px;
      padding: 24px;
      background: #f7fbff;
      border: 1px dashed #40a1ff;
      box-sizing: border-box;
      border-radius: 5px;
      img {
        animation: rotating 1s linear infinite;
      }
    }
    .organisation-size {
      color: #adadad;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
    .error-size {
      color: red;
    }
  }
`;
