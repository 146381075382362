/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {reassignAssessment} from '../../../Assessment/slices/assessmentSlice';

export const AssessmentViewFooter = ({
  assessmentID,
  classID,
  onEvaluate,
  onReassign,
  student,
  tasks,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  if (!student) return null;

  const name = `${student.profile.up_name_first} ${student.profile.up_name_last}`;
  const submittedTasksCount = tasks.filter(
    item => item.submissions?.length,
  ).length;

  if (submittedTasksCount < tasks.length) {
    return null;
  }

  if (student.aouis_status === 3) {
    return null;
  }
  return (
    <footer className="footer">
      <p className="footer-text">
        {submittedTasksCount}/{tasks.length} Tasks Evaluated
      </p>
      <div className="footer-buttons">
        <button
          className="footer-reassign-button"
          onClick={() => {
            if (student?.aouis_status === 3) return;
            onReassign(comments => {
              dispatch(
                reassignAssessment(
                  student.aouis_id_activityorgunitinstancestudent,
                  assessmentID,
                  classID,
                  comments,
                ),
              );
              history.replace(
                `/stream/${classID}/assessment/${assessmentID}/students`,
              );
            }, name);
          }}
          type="button"
        >
          Reassign Assessment
        </button>
        <button
          className="footer-button"
          onClick={
            submittedTasksCount === tasks.length
              ? (...rest) => {
                  if (student?.aouis_status === 3) return;
                  onEvaluate(rest);
                }
              : () => {}
          }
          style={
            submittedTasksCount !== tasks.length
              ? {
                  backgroundColor: '#80d5ff',
                  cursor: 'not-allowed',
                }
              : {}
          }
          type="button"
        >
          Complete Evaluation
        </button>
      </div>
    </footer>
  );
};
