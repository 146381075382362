import styled from 'styled-components';

export const ViewFileSharedToClassMainContainer = styled.div`
  padding: 2rem 1.5rem 2rem 2rem !important;
  @media (max-width: 55.5rem) {
    & {
      padding: 2rem 1.5rem 2rem 2rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 2rem 1.5rem 2rem 1.5rem !important;
    }
  }
  .container-file {
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    background-color: #ffffff;
    padding: 1.5rem;

    .file-div {
      display: flex;
      .file-data {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 65rem) {
        & {
          display: flex;
          flex-direction: column;
        }
        .file-data,
        .shared-classes {
          padding: 1.5rem 0 0 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
