/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import AddedTextInstructionIcon from '../../../../../../../../../../../../../../../assets/icons/added-text-instruction.svg';
import AddedVoiceInstructionIcon from '../../../../../../../../../../../../../../../assets/icons/added-voice-instruction.svg';
import PlayVideoIconIcon from '../../../../../../../../../../../../../../../assets/icons/play-video-instruction.svg';
import SpinnerIcon from '../../../../../../../../../../../../../../../assets/icons/spinner.svg';
import DocumentInstructionIcon from '../../../../../../../../../../../../../../../assets/icons/document-instruction.svg';
import {MoreOptions} from '../../../../../../../../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../../../../../../../../components/common/MoreOptions/components';
import {
  convertFileSize,
  calculateTimeDuration,
} from '../../../../../../../../../../../../../../../utils/functions';

export const Instruction = ({
  data,
  sno,
  onDelete,
  onEditClick,
  onShowClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (data.media) {
      setSource(
        typeof data.media === 'string'
          ? data.media
          : URL.createObjectURL(data.media),
      );
    }
  }, [data.media]);

  let element = null;
  let instructionIcon;
  let subtitle;
  let title;

  if (data.type === 'audio') {
    instructionIcon = AddedVoiceInstructionIcon;
    subtitle = `• ${calculateTimeDuration(data.duration)}`;
    title = 'Voice';
  } else if (data.type === 'document') {
    instructionIcon = DocumentInstructionIcon;
    subtitle = `• ${convertFileSize(data.duration)}`;
    title = 'Document';
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
        {data.uploaded ? null : (
          <div className="upload-spinner-container">
            <img alt="Uploading" className="upload-spinner" src={SpinnerIcon} />
          </div>
        )}
      </button>
    );
  } else if (data.type === 'image') {
    instructionIcon = source;
    subtitle = `• ${convertFileSize(data.duration)}`;
    title = 'Image';
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
        {data.uploaded ? null : (
          <div className="upload-spinner-container">
            <img alt="Uploading" className="upload-spinner" src={SpinnerIcon} />
          </div>
        )}
      </button>
    );
  } else if (data.type === 'text') {
    instructionIcon = AddedTextInstructionIcon;
    subtitle = 'Instruction';
    title = 'Text';
  } else if (data.type === 'video') {
    element = (
      <button onClick={onEditClick} type="button">
        <video className="instruction-icon" src={source} />
        {data.uploaded ? (
          <div className="play-video-icon">
            <img alt="Play Video" src={PlayVideoIconIcon} />
          </div>
        ) : (
          <div className="upload-spinner-container">
            <img alt="Uploading" className="upload-spinner" src={SpinnerIcon} />
          </div>
        )}
      </button>
    );
    subtitle = `• ${calculateTimeDuration(data.duration)}`;
    title = 'Video';
  }

  if (['audio', 'text'].includes(data.type)) {
    element = (
      <button onClick={onEditClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
        {data.uploaded ? null : (
          <div className="upload-spinner-container">
            <img alt="Uploading" className="upload-spinner" src={SpinnerIcon} />
          </div>
        )}
      </button>
    );
  }

  return (
    <div className="added-instruction-div">
      <div className="instruction-sno">{sno + 1}</div>
      <div className="added-instruction">
        {element}
        <div className="instruction-content">
          <span>
            {title} {subtitle}
          </span>
          <p>
            {data?.text?.length > 128 && !isExpanded
              ? `${data?.text?.slice(0, 128)}...`
              : data?.text}{' '}
            {data?.text?.length > 128 && !isExpanded ? (
              <button onClick={() => setIsExpanded(true)} type="button">
                See more
              </button>
            ) : null}
          </p>
        </div>
        <MoreOptions>
          {['text', 'audio', 'video'].includes(data.type) ? (
            <DropdownItem label="Edit" onClick={onEditClick} />
          ) : null}
          <DropdownItem isDanger label="Delete" onClick={onDelete} />
        </MoreOptions>
      </div>
    </div>
  );
};
