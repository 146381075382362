import styled from 'styled-components';

export const AddAssessmentHeaderContainer = styled.header`
  background-color: #fff;
  border-bottom: 0.0625rem solid #e9e9e9;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  .view-assessment-nav {
    padding: 1.5rem 3rem 0rem 3rem;
  }
  .delete-assessement {
    &.is-archive {
      display: none;
    }
  }
  @media (max-width: 43.75rem) {
    .view-assessment-nav {
      padding: 0.8rem 1.5rem 0rem 1.5rem;
    }
    .dropdown-wrapper {
      button {
        width: 100%;
      }
    }
    .header-top {
      padding: 1rem 0.187rem 0rem 0.125rem !important;
      justify-content: space-between;

      .header_t-back-icon {
        margin-left: -0.187rem;
      }
    }
  }

  .header-top {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    .go-back-btn {
      background-color: #fff;
      border: none;
      margin-right: 1rem;
      flex-direction: column;

      :active,
      :focus {
        outline: none;
      }

      .header_t-back-icon {
        height: 2rem;
        margin-top: 0.125rem;
      }
    }

    .btn {
      :active,
      :focus {
        box-shadow: none;
      }
    }
  }
  .left-head {
    display: flex;

    .assessment-head {
      ${({theme}) => theme.colors.text};
      font-weight: 500;
      font-size: 1.5rem;
    }
  }
  @media (max-width: 47.625rem) {
    .assessment-head {
      font-size: 1.3rem !important;
    }
  }
  .search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 1rem 0rem 0rem 0rem;
    /* border-bottom: 0.0625rem solid #dedede; */
    .buttom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      font-weight: 500;
      font-size: 1rem;
      outline: none;
      color: #b8bac7;
      display: flex;
      align-items: center;
      &.link {
        border-bottom: 0.125rem solid #40a1ff;
        color: #3d4457;
      }
    }
  }
  .count {
    font-weight: 500;
    border-radius: 50%;
    font-size: 0.75rem;
    font-weight: 600;
    background: #b8bac7;
    color: ${({theme}) => theme.colors.primary};
    /* background: #5ba1f2; */
    padding: 0.17rem 0.6rem 0.17rem 0.55rem;
    color: #ffffff;
    margin-left: 0.125rem;
    margin-bottom: 1rem;
    &.is-current {
      background: #5ba1f2;
    }
  }
  .check-fill-icon {
    width: 1.7rem;
    height: 1.7rem;
    margin-bottom: 1rem;
  }
`;
