/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import CustomButton from '../../../../../../components/shared/Buttons/CustomButton';
import PublishAssessmentsContainer from '../../../../elements/Assessment';
import MenuCloseImg from '../../../../../../assets/icons/menu-close.svg';
import SyncingIcon from '../../../../../../assets/icons/syncing.svg';
import SyncingDoneIcon from '../../../../../../assets/icons/syncing-done.svg';
import SyncingErrorIcon from '../../../../../../assets/icons/syncing-error.svg';

export const PublishAssessments = ({isButtonShow, isLoading}) => {
  const {AssessmentSuccess, AssessmentLoading} = useSelector(
    state => state.assessment,
  );
  const [classId, setClassId] = useState('');
  const history = useHistory();
  const {pathname} = history.location;

  useEffect(() => {
    if (/stream/.test(pathname)) {
      const Id = pathname.split('/')[2];
      setClassId(Id);
    } else {
      setClassId('');
    }
  }, [pathname]);
  return (
    <>
      <PublishAssessmentsContainer>
        <div className="text-center w-100">
          <div className="assessment-head mb-1">
            Search or Add an Assessment
          </div>
          <div className="assessment-subhead mb-3">
            You can add an assessment by search or add a new assessment
            {isButtonShow && AssessmentLoading ? (
              <img src={SyncingIcon} className="ml-2" alt="syncing-icon" />
            ) : isButtonShow && AssessmentSuccess ? (
              <img
                src={SyncingDoneIcon}
                className="ml-2"
                alt="syncing-done-icon"
              />
            ) : (
              isButtonShow && (
                <img
                  src={SyncingErrorIcon}
                  alt="syncing-error-icon"
                  className="ml-2"
                />
              )
            )}
          </div>
        </div>
        {isButtonShow && !isLoading && (
          <div className="publish-buttons">
            <CustomButton
              bgColor="#00ABFF"
              opacity={0.3}
              className="mr-3"
              type="submit"
            >
              Publish
            </CustomButton>
            <img
              style={{cursor: 'pointer'}}
              src={MenuCloseImg}
              alt="menu-close"
              onClick={() => history.push(`/stream/${classId}/assessment`)}
            />
          </div>
        )}
      </PublishAssessmentsContainer>
    </>
  );
};
