import styled from 'styled-components';

export const AssessmentHeaderContainer = styled.header`
  border-bottom: 0.0625rem solid #dedede;
  @media (max-width: 32.25rem) {
    .header-top {
      .publish-draft-dropdown-div {
        margin-left: 1rem;
      }
      .draft-data,
      .publish-data {
        width: 90%;
      }
    }
  }

  @media (max-width: 43.75rem) {
    .header-top {
      .header_tr-text {
        justify-content: space-between;
      }
      .header_t-right {
        .header_trt-drafted-date-hide {
          display: block !important;
        }
        .header_trt-drafted-date {
          display: none !important;
        }
        .header_trt-publication-date-hide {
          display: block !important;
        }
        .header_trt-publication-date {
          display: none !important;
        }
      }
      .header_t-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: unset !important;
      }
      .header_trt-title {
        display: none !important;
      }
      .header_tr-due-date {
        display: none;
      }
    }
    .dropdown-wrapper {
      flex-shrink: 0;
    }
    .header_trt-hide {
      display: block !important;
      margin-top: 1rem;
      .header_trt-title {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        flex-grow: 1;
        font-size: 1.25rem;
        margin-bottom: 0;
        overflow: hidden;
      }
      .header_tr-due-date-hide {
        display: block !important;
        color: #aaa;
        font-size: 0.875rem;
        margin-bottom: 0;
        margin-top: 0.25rem;
        .header_trd-value {
          color: ${({theme}) => theme.colors.text};
        }
      }
    }
    .body-action {
      display: block !important;
      color: #40a1ff;
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 1rem;

      &:active,
      &:hover {
        text-decoration: none;
      }

      .body_a-icon {
        margin-left: 0.625rem;
      }
    }
  }
  .body-action {
    display: none;
  }
  .header_trt-hide {
    display: none;
    .header_tr-due-date-hide {
      display: none;
    }
  }
  .header-top {
    align-items: center;
    color: #3d4457;
    display: flex;

    .header_t-icon {
      margin-right: 1rem;
    }

    .header_t-right {
      flex-grow: 1;

      .draft-data {
        /* width: 100%; */
      }
      .header_tr-text {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.187rem;
        width: 98%;
        .header_trt-title_head {
          display: flex;
        }
        .header_trt-title {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          flex-grow: 1;
          font-size: 1.25rem;
          margin-bottom: 0;
          overflow: hidden;
        }
        .seperator {
          height: 1.1rem;
          margin-left: 0.5rem;
          border-left: 0.12rem solid #aaaaaa;
          margin-right: 0.8rem;
        }
        .assessment-username {
          display: flex;
          align-items: center;
          margin-left: 0.5rem;
          font-weight: 500;
          font-size: 0.875rem;
          margin-bottom: 0.0185rem;
          color: #565656;
          .teacher-icon {
            padding-left: 0.3rem;
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        .header_trt-publication-date {
          display: block;
          color: #aaa;
          font-size: 0.875rem;
          margin-right: 1.625rem;
          margin-left: 1.625rem;
          &.is-archive {
            margin-right: unset;
          }

          .header_trtp-value {
            color: ${({theme}) => theme.colors.text};
          }
        }

        .header_trt-publication-date-hide {
          display: none;
          color: #aaa;
          font-size: 0.875rem;
          margin-right: 1.625rem;
          .header_trtp-value {
            color: ${({theme}) => theme.colors.text};
          }
        }

        .header_trt-drafted-date {
          display: block;
          color: #aaa;
          font-size: 0.875rem;
          margin-right: 1.625rem;
          margin-left: 1.625rem;
          .header_trtd-value {
            color: #b8bac7;
          }
        }
        .header_trt-drafted-date-hide {
          display: none;
          color: #aaa;
          font-size: 0.875rem;
          margin-right: 1.625rem;

          .header_trtd-value {
            color: #b8bac7;
          }
        }
        .header_trt-draft {
          background-color: #ebf5ff;
          border-radius: 0.3125rem;
          color: #40a1ff;
          font-size: 0.875rem;
          margin-right: 1.625rem;

          .header_trtd-value {
            color: #b8bac7;
          }
        }

        img {
          vertical-align: unset;
        }
      }

      .header_tr-due-date {
        color: #aaa;
        font-size: 0.875rem;
        margin-bottom: 0;

        .header_trd-value {
          color: ${({theme}) => theme.colors.text};
        }
      }
    }
  }

  .header-description {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #aaa;
    display: -webkit-box;
    font-size: 1rem;
    margin-top: 1rem;
    overflow: hidden;
  }
  .publish-draft-dropdown-div {
    display: flex;
  }
`;
