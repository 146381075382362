import styled from 'styled-components';
import {ToastContainer} from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    height: 65px;
    width: auto;
    min-width: 215px;
    border-radius: 8px;
    background-color: 'white';
  }
  .toast-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .Toastify__toast-body {
    height: auto;
    width: auto;
    font-weight: 300;

    .student-avatar {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: rgb(64, 161, 255);
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      color: white;
      margin: 0 8px;
      img {
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
      }
    }
  }
`;

export default StyledToastContainer;
