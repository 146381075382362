import styled from 'styled-components';

const StudentListContainer = styled.aside`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  flex-grow: 1;
  height: 70vh;
  overflow-y: auto;
  position: absolute;
  z-index: 30;
  bottom: 12%;
  right: 3%;
  min-width: 22%;

  .student-list-search {
    margin: 1.5rem 1rem 0 1rem;

    img {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }

    input {
      ::placeholder {
        color: ${({theme}) => theme.colors.placeholder};
      }
    }
  }
`;

export default StudentListContainer;
