/* eslint-disable react/prop-types */
import React from 'react';

import {ViewFileSharedToClassMainContainer} from './elements';
import {FilePreview} from './components';

export const ViewFileSharedToClassMain = ({curriculumFile}) => (
  <ViewFileSharedToClassMainContainer>
    <div className="container-file">
      <div className="d-flex file-div">
        <div className="file-data">
          <FilePreview curriculumFile={curriculumFile} />
        </div>
      </div>
    </div>
  </ViewFileSharedToClassMainContainer>
);
