/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import MoreOptionsIcon from '../../../../../../../../../assets/icons/refactor/more-options.svg';
import {Dropdown} from '../../../../../../../elements/Dropdown';
import DropdownItems from '../../../../../../../../../components/shared/DropdownItems/DropdownItems';
import DropdownWrapper from '../../../../../../../../../components/shared/DropdownWrapper/DropdownWrapper';

export const StudentActionsDropdown = ({
  isGraded,
  isSubmitted,
  onReassign,
  onRevokeStudent,
  onSendReminder,
  isArchived,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  if (!isArchived) return null;
  return (
    <DropdownWrapper toggleMenu={setIsVisible}>
      <button
        className="btn"
        onClick={event => {
          event.preventDefault();
          setIsVisible(state => !state);
        }}
        type="submit"
      >
        <img alt="Options" src={MoreOptionsIcon} className="three-dot-btn" />
      </button>
      {isVisible ? (
        <Dropdown right="0" width="200px" className="dropdown-list">
          <DropdownItems
            click={() => {
              onReassign();
              setIsVisible(false);
            }}
            text="Reassign"
          />
          {!isGraded && !isSubmitted ? (
            <DropdownItems
              click={() => {
                onSendReminder();
                setIsVisible(false);
              }}
              text="Send Reminder"
            />
          ) : null}
          <DropdownItems
            click={() => {
              onRevokeStudent();
              setIsVisible(false);
            }}
            isDanger
            text="Revoke Student"
          />
        </Dropdown>
      ) : null}
    </DropdownWrapper>
  );
};
