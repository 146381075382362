import {createSlice} from '@reduxjs/toolkit';

import * as notificationApi from '../api/admin/notification';

// const DELAY = 500;

const notificationSlice = createSlice({
  initialState: {
    error: null,
    isLoading: false,
    notification: [],
    newNotifications: [],
  },
  name: 'notification',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setNotification(state, action) {
      state.notification = action.payload;
    },
    setNewNotifications(state, action) {
      state.newNotifications = action.payload;
    },
    appendNotifications: (state, action) => {
      const existingNotification = state?.notification?.find(
        item => item.al_id_auditlog === action?.payload?.al_id_auditlog,
      );
      if (!existingNotification)
        state.notification = [action.payload, ...state.notification];
    },
    appendNewNotifications: (state, action) => {
      const existingNotification = state?.newNotifications?.find(
        item => item.al_id_auditlog === action?.payload?.al_id_auditlog,
      );
      if (!existingNotification)
        state.newNotifications = [action.payload, ...state.newNotifications];
    },
    setUnseenToSeenNotificationById: (state, action) => {
      state.newNotifications = state.newNotifications.filter(
        item => item.al_id_auditlog !== action.payload,
      );
    },
  },
});

export const {
  setError,
  setIsLoading,
  setNotification,
  setNewNotifications,
  appendNotifications,
  appendNewNotifications,
  setUnseenToSeenNotificationById,
} = notificationSlice.actions;

export const fetchAllNotification = () => async dispatch => {
  try {
    const response = await notificationApi.fetchAllNotification();
    dispatch(setIsLoading(false));
    dispatch(setNotification(response?.data?.allNotifications));
    dispatch(setNewNotifications(response?.data?.unseenNotifications));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const markAllAsRead = () => async dispatch => {
  dispatch(setIsLoading(false));
  try {
    const response = await notificationApi.markAllAsReadNotification();
    dispatch(setNewNotifications([]));
    dispatch(setNotification(response?.data?.allNotifications));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const markAsRead = notificationId => async dispatch => {
  dispatch(setIsLoading(false));
  try {
    await notificationApi.markAsReadNotification(notificationId);
    dispatch(setUnseenToSeenNotificationById(notificationId));
    dispatch(fetchAllNotification());
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const notificationSelector = state => state.notification;

export default notificationSlice.reducer;
