/* eslint-disable camelcase */
import {createSlice} from '@reduxjs/toolkit';
import * as activeClassApi from '../api/activeClass';

export const activeClassSlice = createSlice({
  name: 'activeClass',
  initialState: {
    isLoading: true,
    activeClasses: [],
    allAssessments: [],
    orgUnits: [],
    searchTerm: '',
    createdAssessment: {},
    academicYears: [],
    error: null,
    activityId: null,
    assessmentUnitId: null,
    // Form Data
    isDeleteLoading: false,
    AssessmentLoading: false,
    AssessmentError: null,
    performanceCriteria: null,
    knowledgeEvidences: null,
    performanceEvidences: null,
    assessmentConditions: null,
    assessmentType: [],
    evaluationType: [],
    createdTasks: [],
    createdFiles: [],
    draftTask: null,
    assessmentTasksError: '',
    assessmentTasksLoading: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setActiveClasses: (state, action) => {
      state.activeClasses = action.payload;
    },

    setOrgClasses: (state, action) => {
      state.orgClasses = action.payload;
    },
    setAcademicYears: (state, action) => {
      state.academicYears = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsDeleteLoading: (state, action) => {
      state.isDeleteLoading = action.payload;
    },
    setAllAssessments: (state, action) => {
      state.allAssessments = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchTerm = action.payload;
    },
    setOrgUnits: (state, action) => {
      state.orgUnits = action.payload;
    },
    setAssessmentType: (state, action) => {
      state.assessmentType = action.payload;
    },
    setEvaluationType: (state, action) => {
      state.evaluationType = action.payload;
    },
    setPerformanceCriteria: (state, action) => {
      state.performanceCriteria = action.payload;
    },
    setPerformanceEvidences: (state, action) => {
      state.performanceEvidences = action.payload;
    },
    setKnowledgeEvidences: (state, action) => {
      state.knowledgeEvidences = action.payload;
    },
    setAssessmentConditions: (state, action) => {
      state.assessmentConditions = action.payload;
    },
    setAssessmentLoading: (state, action) => {
      state.AssessmentLoading = action.payload;
    },
    setAssessmentError: (state, action) => {
      state.AssessmentError = action.payload;
    },
    fetchCreatedAssessment: (state, action) => {
      state.createdAssessment = action.payload;
    },
    fetchUpdatedAssessment: (state, action) => {
      state.createdAssessment = action.payload;
    },

    setAssessmentTask: (state, action) => {
      const {
        ai_id_activityitem,
        ai_id_activityitemtype,
        ai_activityitem,
        ai_activityitem_description,
        ai_is_published,
        ai_id_evaltype,
        aipc_id_tcriteria,
        aipe_id_tpevidence,
        aike_id_tkevidence,
        aiac_id_tacondition,
        tpc_id_tgovelement,
        tpc_criteria_id,
        tpc_criteria_value,
        et_eval_name,
        performanceCriteria,
        performanceEvidence,
        knowledgeEvidence,
        assessmentCondition,
        linkedFiles,
        isEdit,
      } = action.payload;
      let taskType;
      switch (ai_id_activityitemtype) {
        case 1:
          taskType = 'Image';
          break;
        case 2:
          taskType = 'Video';
          break;
        case 3:
          taskType = 'Document';
          break;
        default:
          taskType = '';
      }

      const task = {
        id: ai_id_activityitem,
        type: taskType,
        name: ai_activityitem,
        description: ai_activityitem_description,
        isPublished: ai_is_published,
        taskFiles: linkedFiles,
        evaluationType: ai_id_evaltype,
        evaluationName: et_eval_name,
        performanceCriteriaId: performanceCriteria
          ? performanceCriteria.tpc_id_tgovperformancecriteria
          : aipc_id_tcriteria,
        // eslint-disable-next-line no-nested-ternary
        performanceCriteriaName: performanceCriteria
          ? `${performanceCriteria.tpc_criteria_id} ${performanceCriteria.tpc_criteria_value}`
          : tpc_criteria_id
          ? `${tpc_criteria_id} ${tpc_criteria_value}`
          : null,
        elementId: performanceCriteria
          ? performanceCriteria.tpc_id_tgovelement
          : tpc_id_tgovelement,
        performanceEvidenceId:
          performanceEvidence?.tpe_id_tgovperformanceevidence ||
          aipe_id_tpevidence,
        performanceEvidenceName: performanceEvidence
          ? performanceEvidence.tpe_evidence_value
          : action.payload?.tpe_evidence_value,
        knowledgeEvidenceId: knowledgeEvidence
          ? knowledgeEvidence.tke_id_tgovknowledgeevidence
          : aike_id_tkevidence,
        knowledgeEvidenceName: knowledgeEvidence
          ? knowledgeEvidence?.tke_evidence_value
          : action.payload?.tke_evidence_value,
        assessmentConditionId:
          assessmentCondition?.tac_id_tgovassessmentcondition ||
          aiac_id_tacondition,
        assessmentConditionName: assessmentCondition
          ? assessmentCondition.tac_condition
          : action?.payload?.tac_condition,
      };

      if (isEdit) {
        const indexOfTaskToReplace = state.createdTasks.findIndex(
          item => item.id === task.id,
        );
        state.createdTasks[indexOfTaskToReplace] = task;
      } else {
        state.createdTasks.push(task);
      }
    },

    resetAction: state => {
      state.createdTasks = [];
      state.createdFiles = [];
      state.createdAssessment = {};
    },
    setAssessmentFiles: (state, action) => {
      const {fi_id_file, fi_filename, fi_filesize, fi_location} =
        action.payload;

      state.createdFiles.push({
        id: fi_id_file,
        name: fi_filename,
        size: fi_filesize,
        location: fi_location,
      });

      // Removing if any duplicated array of obj found
      const file = [
        ...new Map(
          state.createdFiles.map(obj => [JSON.stringify(obj), obj]),
        ).values(),
      ];

      state.createdFiles = file;
    },
    setAssessmentUnitId: (state, action) => {
      state.assessmentUnitId = action.payload;
    },
    setActivityId: (state, action) => {
      state.activityId = action.payload;
    },
    setDraftTask: (state, action) => {
      state.draftTask = action.payload;
    },
    updateLinkedFile: (state, action) => {
      const {file, linkId, taskId} = action.payload;
      let fileLink;

      if (taskId) {
        const task = state.createdTasks.find(item => item.id === taskId);
        fileLink = task.taskFiles.find(
          linkedFile => linkedFile.fl_id_filelink === linkId,
        );
      } else {
        fileLink = state.draftTask.linkedFiles.find(
          linkedFile => linkedFile.fl_id_filelink === linkId,
        );
      }

      fileLink.file = file;
    },
    clearAllTaskInstructions: state => {
      state.draftTask.linkedFiles = [];
    },
    setAssessmentTasksError: (state, action) => {
      state.assessmentTasksError = action.payload;
    },
    setAssessmentTasksLoading: (state, action) => {
      state.assessmentTasksLoading = action.payload;
    },
    setLinkedFile: (state, action) => {
      const {file, taskId} = action.payload;

      if (taskId) {
        const task = state.createdTasks.find(item => item.id === taskId);
        task.taskFiles.push(file);
      } else {
        state.draftTask.linkedFiles.push(file);
      }
    },
    clearTaskInstruction: (state, action) => {
      const {fileID, idOfTaskToEdit, taskID} = action.payload;
      let task;

      if (idOfTaskToEdit) {
        task = state.createdTasks.find(item => item.id === idOfTaskToEdit);
        task.taskFiles = task.taskFiles.filter(
          item => item.fl_id_filelink !== fileID,
        );
      } else {
        task = state.createdTasks.find(item => item.id === taskID);

        if (task) {
          task.taskFiles = task.taskFiles.filter(
            item => item.fl_id_filelink !== fileID,
          );
        } else {
          state.draftTask.linkedFiles = state.draftTask.linkedFiles.filter(
            item => item.fl_id_filelink !== fileID,
          );
        }
      }
    },
    deleteSelectedTaskItem: (state, action) => {
      state.createdTasks = state.createdTasks.filter(
        task => task.id !== action.payload,
      );
    },
    deleteAssessmentFromList: (state, action) => {
      state.allAssessments = state.allAssessments.filter(
        assessment => assessment.ac_id_activity !== action.payload,
      );
    },
  },
});

export const {
  setIsLoading,
  setActiveClasses,
  setOrgClasses,
  setAcademicYears,
  setError,
  setIsDeleteLoading,
  setAllAssessments,
  setOrgUnits,
  setSearchField,
  setPerformanceCriteria,
  setKnowledgeEvidences,
  setAssessmentConditions,
  setPerformanceEvidences,
  setEvaluationType,
  setAssessmentType,
  setAssessmentLoading,
  setAssessmentError,
  fetchCreatedAssessment,
  fetchUpdatedAssessment,
  setAssessmentTask,
  setAssessmentUnitId,
  setActivityId,
  setAssessmentFiles,
  resetAction,
  setDraftTask,
  clearAllTaskInstructions,
  setAssessmentTasksError,
  setAssessmentTasksLoading,
  setLinkedFile,
  deleteSelectedTaskItem,
  clearTaskInstruction,
  updateLinkedFile,
  deleteAssessmentFromList,
} = activeClassSlice.actions;

export const fetchActiveClasses =
  (type = 3, history = null) =>
  async dispatch => {
    try {
      let response;

      if (type === 3) {
        response = await activeClassApi.fetchActiveClasses();
      } else if (type === 2) {
        response = await activeClassApi.fetchActiveClassesOfStudent();
      }
      if (history) {
        const {units} = response.data;
        const validUnit = units?.find(
          item =>
            item?.orui_id_orgunitinstance ===
            history.location.pathname.split('/')[2],
        );
        if (!validUnit) {
          history.replace('/classes');
        }
      }
      dispatch(setActiveClasses(response.data.units));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const fetchAllAssessments = () => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await activeClassApi.fetchAllAssessments();
    dispatch(setIsLoading(false));
    dispatch(setAllAssessments(response.data.assessments));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const fetchOrgClasses = academicYearId => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await activeClassApi.fetchOrgClasses(academicYearId);
    dispatch(setIsLoading(false));
    dispatch(setOrgClasses(response.data.orgClasses));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const fetchAcademicYears = () => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await activeClassApi.fetchAcademicYears();
    dispatch(setIsLoading(false));
    dispatch(setAcademicYears(response.data.academicYears));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

// Add Assessment without student and class
export const getOrgUnits = () => async dispatch => {
  const {
    data: {orgUnits},
  } = await activeClassApi.getUnits();
  dispatch(setOrgUnits(orgUnits));
};

export const getAllActivityMethods = () => async dispatch => {
  try {
    const resp = await activeClassApi.fetchAllActivityMethods();
    dispatch(setAssessmentType(resp.data.allActivityMethods));
  } catch (err) {
    dispatch(setError(true));
  }
};

export const getAllEvaluationType = () => async dispatch => {
  try {
    const resp = await activeClassApi.fetchAllEvaluationType();
    dispatch(setEvaluationType(resp.data.allEvaluationType));
  } catch (err) {
    dispatch(setError(err));
  }
};

export const getPerformanceCriteria = unitId => async dispatch => {
  const {
    data: {
      assessmentMap: {elements: performanceCriteria},
    },
  } = await activeClassApi.getPerformanceCriteria(unitId);

  dispatch(setPerformanceCriteria(performanceCriteria));
};

/** Fetch the performance evidence of a unit. */
export const fetchPerformanceEvidence = unitId => async dispatch => {
  const resp = await activeClassApi.getPerformanceEvidence(unitId);
  dispatch(setPerformanceEvidences(resp.data.performanceEvidence));
};

/** Fetch the knowledge evidence of a unit. */
export const fetchKnowledgeEvidence = unitId => async dispatch => {
  const resp = await activeClassApi.getKnowledgeEvidence(unitId);
  dispatch(setKnowledgeEvidences(resp.data.knowledgeEvidence));
};

/** Fetch the knowledge evidence of a unit. */
export const fetchAssessmentCondition = unitId => async dispatch => {
  const resp = await activeClassApi.getAssessmentCondition(unitId);
  dispatch(setAssessmentConditions(resp.data.assessmentCondition));
};

export const setAssessmentTitle =
  (unitId, history) => async (dispatch, getState) => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    try {
      const {searchTerm: title} = getState().activeClass;

      const resp = await activeClassApi.createAssessmentByTitle(unitId, title);

      const activityId = resp.data.activity.ac_id_activity;

      // dispatch(fetchCreatedAssessment(resp.data.activity));
      dispatch(setActivityId(activityId));

      dispatch(setSearchField('')); // cleaning searchField

      history.replace(`/classes/assessment/${activityId}/update`);
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    } finally {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
    }
  };

export const updateAssessmentDetails =
  (activityId, data, callBack) => async dispatch => {
    try {
      dispatch(setAssessmentLoading(true));
      const resp = await activeClassApi.updateAssessment(activityId, data);
      dispatch(fetchUpdatedAssessment(resp.data.activity));
      dispatch(setAssessmentUnitId(data.unitId));
      if (callBack) {
        callBack();
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(err));
    } finally {
      dispatch(setAssessmentLoading(false));
    }
  };

export const getAssessmentDetails =
  (activityId, history, callback) => async dispatch => {
    dispatch(setAssessmentLoading(true));

    try {
      const resp = await activeClassApi.getAssessmentDatas(activityId);
      if (callback) callback();
      dispatch(setActivityId(activityId));
      dispatch(fetchCreatedAssessment(resp.data.assessments));
      const availableTask = resp.data.assessments.tasks.filter(
        task => task.ai_is_deleted !== 1,
      );
      availableTask.forEach(task => {
        dispatch(setAssessmentTask(task));
      });
      const availableFiles = resp.data.assessments.files.filter(
        file => file.fi_is_deleted !== 1,
      );
      availableFiles.forEach(file => {
        dispatch(setAssessmentFiles(file));
      });
      if (history) {
        // history.push(
        //   `/stream/${classId}/assessment/create/${activityId}/#details`,
        // );
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(err));
    } finally {
      dispatch(setAssessmentLoading(false));
    }
  };
export const fetchDraftTask = activityID => async dispatch => {
  const {data} = await activeClassApi.getDraftTask(activityID);
  dispatch(setDraftTask(data.task));
};
export const removeAllInstructions = taskID => async dispatch => {
  await activeClassApi.removeAllInstructions(taskID);
  dispatch(clearAllTaskInstructions());
};
export const updateTask =
  (taskID, body, isPublished, isEdit, callback) => async dispatch => {
    dispatch(setAssessmentTasksLoading(true));
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));

    try {
      const {data} = await activeClassApi.updateTask(taskID, {
        ...body,
        isPublished,
      });
      dispatch(setDraftTask(null));
      dispatch(setAssessmentTasksLoading(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(setAssessmentTask({...data.task, isEdit}));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(setAssessmentTasksLoading(false));
      dispatch(setAssessmentTasksError(error.message));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const addFileInstruction =
  (activityID, taskID, body, callback, idOfTaskToEdit) => async dispatch => {
    const {data} = await activeClassApi.addFileInstruction(
      activityID,
      taskID,
      body,
    );
    if (callback) callback(data.linkedFile.fl_id_filelink);
    dispatch(
      setLinkedFile({
        file: data.linkedFile,
        taskId: idOfTaskToEdit,
      }),
    );
  };

export const onDeletingAssessmentTask =
  (activityItemId, isQueued, callback) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));

    try {
      if (!isQueued) {
        const resp = await activeClassApi.deleteAssessmentTask(activityItemId);
        if (resp.data.draftTask) {
          dispatch(setDraftTask(resp.data.draftTask));
        }
      }

      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));

      if (!Array.isArray(activityItemId)) {
        dispatch(deleteSelectedTaskItem(activityItemId));
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };
export const removeInstruction =
  (taskID, fileID, idOfTaskToEdit, callback) => async dispatch => {
    await activeClassApi.removeInstruction(fileID);
    dispatch(
      clearTaskInstruction({
        taskID,
        fileID,
        idOfTaskToEdit,
      }),
    );

    if (callback) {
      callback();
    }
  };

export const deleteAssessment = (activityID, callback) => async dispatch => {
  try {
    dispatch(setIsDeleteLoading(true));
    await activeClassApi.deleteAssessment(activityID);

    if (callback) {
      callback(activityID);
      dispatch(deleteAssessmentFromList(activityID));
    }
  } catch (error) {
    dispatch(setAssessmentError(true));
    dispatch(setIsDeleteLoading(false));
  } finally {
    dispatch(setIsDeleteLoading(false));
  }
};

export const addTextInstruction =
  (activityID, taskID, textInstruction, callback, idOfTaskToEdit) =>
  async dispatch => {
    const {data} = await activeClassApi.addTextInstruction(
      activityID,
      taskID,
      textInstruction,
    );

    if (callback) {
      callback(data.linkedFile.fl_id_filelink);
    }

    dispatch(
      setLinkedFile({
        file: data.linkedFile,
        taskId: idOfTaskToEdit,
      }),
    );
  };

export const updateInstruction =
  (fileID, body, taskId, callback, idOfTaskToEdit) => async dispatch => {
    const {data} = await activeClassApi.updateInstruction(fileID, body);
    dispatch(
      updateLinkedFile({
        file: data.linkedFile.file,
        linkId: data.linkedFile.fl_id_filelink,
        taskId: idOfTaskToEdit,
      }),
    );

    if (callback) {
      callback(fileID, data.linkedFile);
    }
  };

export const activeClassSelector = state => state.activeClass;

export default activeClassSlice.reducer;
