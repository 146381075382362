/* eslint-disable react/prop-types */
import moment from 'moment';
import React, {useMemo, useState} from 'react';
import {Tab, Tabs} from 'react-bootstrap';

import PerformanceCriteriaIcon from '../../../../../../../assets/icons/performance-criteria-icon.svg';
import TabsContainer from '../../../../../../../components/shared/TabsContainer';
import TabTitle from '../../../../../../../components/shared/TabTitle';

import PerformanceCriteria from '../../components/PerformanceCriteria';
import {
  AssessmentCondition,
  //   FoundationSkills,
  KnowledgeEvidence,
  PerformanceEvidence,
} from '../../../../../../Stream/pages/AssessmentMap/components';

export const AssessmentMap = ({unitDetails}) => {
  // Restructure the assessment map.
  const assessmentMapGroup = useMemo(
    () =>
      (unitDetails?.unitRelease?.elements || []).map(item => ({
        criteria: item.performanceCriteria.map(criteria => ({
          id: criteria.tpc_id_tgovperformancecriteria,
          selectedAssessments: criteria.selectedAssessments,
          orderId: criteria.tpc_criteria_id,
          tasks:
            criteria.tasks?.map(task => ({
              date: moment(task.ai_timestamp_creation).format(
                'dddd D MMM YYYY, h:mma',
              ),
              id: task.ai_id_activityitem,
              title: task.ai_activityitem_title,
              assessmentType: task.ac_id_activitymethod,
              assessmentId: task.ac_id_activity,
              user: `${task.up_name_first} ${task.up_name_last}`,
            })) || [],
          title: criteria.tpc_criteria_value,
        })),
        id: item.tel_id_tgovelement,
        orderId: item.tel_element_id,
        title: item.tel_element_value,
        // TODO: Find a way to pass this in.
        // orgUnitInstanceId: orgUnitInstanceId,
      })),
    [unitDetails],
  );

  const knowledgeEvidenceGroup = useMemo(
    () => unitDetails?.unitRelease?.knowledgeEvidences,
    [unitDetails],
  );
  const performanceEvidenceGroup = useMemo(
    () => unitDetails?.unitRelease?.performanceEvidences,
    [unitDetails],
  );
  const assessmentConditionGroup = useMemo(
    () => unitDetails?.unitRelease?.assessmentConditions,
    [unitDetails],
  );
  // const FoundationSkillsGroup = useMemo(
  //   () => unitDetails?.foundationSkills,
  //   [unitDetails?.foundationSkills],
  // );

  const tabs = useMemo(
    () => [
      {
        activeIcon: PerformanceCriteriaIcon,
        icon: PerformanceCriteriaIcon,
        key: 'performance-criteria',
        field: 'elements',
        title: 'Performance Criteria',
        component: () => (
          <PerformanceCriteria
            assessmentMap={assessmentMapGroup}
            assessments={unitDetails?.assessments || []}
            className="mt-4"
          />
        ),
      },
      // {
      //   activeIcon: PerformanceCriteriaIcon,
      //   icon: PerformanceCriteriaIcon,
      //   key: 'foundation-skills',
      //   title: 'Foundation Skills',
      //   component: () => (
      //     <FoundationSkills
      //       foundationSkills={FoundationSkillsGroup}
      //       className="mt-4"
      //     />
      //   ),
      // },
      {
        activeIcon: PerformanceCriteriaIcon,
        icon: PerformanceCriteriaIcon,
        key: 'performance-evidence',
        title: 'Performance Evidence',
        field: 'performanceEvidences',
        component: () => (
          <PerformanceEvidence
            performanceEvidence={performanceEvidenceGroup}
            assessments={unitDetails?.assessments || []}
            className="mt-4"
          />
        ),
      },
      {
        activeIcon: PerformanceCriteriaIcon,
        icon: PerformanceCriteriaIcon,
        key: 'knowledge-evidence',
        field: 'knowledgeEvidences',
        title: 'Knowledge Evidence',
        component: () => (
          <KnowledgeEvidence
            knowledgeEvidence={knowledgeEvidenceGroup}
            assessments={unitDetails?.assessments || []}
            className="mt-4"
          />
        ),
      },
      {
        activeIcon: PerformanceCriteriaIcon,
        icon: PerformanceCriteriaIcon,
        key: 'assessment-condition',
        field: 'assessmentConditions',
        title: 'Assessment Conditions',
        component: () => (
          <AssessmentCondition
            assessmentCondition={assessmentConditionGroup}
            assessments={unitDetails?.assessments || []}
            className="mt-4"
          />
        ),
      },
    ],
    [
      assessmentMapGroup,
      unitDetails,
      performanceEvidenceGroup,
      knowledgeEvidenceGroup,
      assessmentConditionGroup,
    ],
  );

  const [key, setKey] = useState(tabs[0].key);

  return (
    <div style={{width: '100%'}}>
      <TabsContainer>
        <Tabs activeKey={key} onSelect={setKey}>
          {tabs.map(tab => (
            <Tab
              eventKey={tab.key}
              key={tab.key}
              title={
                <TabTitle
                  icon={key === tab.key ? tab.activeIcon : tab.icon}
                  isActive={key === tab.key}
                  itemCount={unitDetails?.unitRelease[tab.field]?.length || 0}
                  title={tab.title}
                />
              }
            >
              {tab.component()}
            </Tab>
          ))}
        </Tabs>
      </TabsContainer>
    </div>
  );
};
