/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
// import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import CustomButton from '../../../../../components/shared/CustomButton';
import CustomIconButton from '../../../../../components/shared/CustomIconButton';
import CustomCancel from '../../../../../components/shared/Buttons/CustomButton';
import ThreeDotIcon from '../../../../../assets/icons/threedot.svg';
import addIcons from '../../../../../assets/icons/addIcon.svg';
import closeModal from '../../../../../assets/icons/close-modal.svg';
import updateToastSuccess from '../../../../../assets/icons/updateadmintoast.svg';
import addToastSuccess from '../../../../../assets/icons/addtoastadmin.svg';
import deleteModal from '../../../../../assets/icons/delete-modal.svg';
import tooltipImg from '../../../../../assets/icons/tooltipimg.svg';
import 'react-datepicker/dist/react-datepicker.css';
import CustomTextInput from '../../../../../components/shared/CustomTextInput';
import DropdownItems from '../../../../../components/shared/DropdownItems/DropdownItems';
import DropdownWrapper from '../../../../../components/shared/DropdownWrapper/DropdownWrapper';
import {
  CustomTable,
  tabularize,
} from '../../../../../components/shared/CustomTable';
import {
  TeacherContainer,
  DropdownBtn,
} from '../../../Elements/TeacherContainer';
import CustomBox from '../../../../../components/shared/CustomBox';
import SearchInput from '../../../../../components/shared/Inputs/SearchInput';
import {
  getAllTeacher,
  adminInfo,
  editTeacher,
  teacherInvitationResend,
  deleteTeacher,
} from '../../../adminSlice';
import {Dropdown} from '../../../../../components/shared/Dropdown';
import CustomModal from '../../../../../components/shared/CustomModal';
import ToastContainer from '../../../../../components/shared/ToastMessage';
import EmptyState from '../../../../../components/shared/EmptyState';

const TeacherModal = ({
  isModalVisible,
  button,
  email,
  setFullName,
  fullName,
  setEmail,
  refID,
  setReferID,
  setIsModalVisible,
  onClick,
  title,
  subtitle,
}) => (
  <CustomModal isVisible={isModalVisible}>
    <CustomBox>
      <div className="add-teacher-modal" style={{padding: '24px'}}>
        <div className="add-teacher-modal-header">
          <div>
            <h2 style={{fontSize: '24px'}}>{title}</h2>
            <p>{subtitle}</p>
          </div>
          <div>
            <button
              onClick={() => {
                setIsModalVisible(false);
                setFullName('');
                setEmail('');
                setReferID('');
              }}
              type="button"
              className="closeIconBtn"
            >
              <img src={closeModal} alt="close" />
            </button>
          </div>
        </div>
        <div className="add-teacher-modal-body mb-3">
          <div className="date-wrapper" style={{marginRight: '8px'}}>
            {/* <p className="mb-2 mt-2">Start date</p> */}
            <CustomTextInput
              label="Reference ID"
              value={refID}
              onChange={e => {
                setReferID(e.target.value);
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
            />
          </div>
          <div className="date-wrapper" style={{marginLeft: '8px'}}>
            {/* <p className="mb-2 mt-2">End date</p> */}
            <CustomTextInput
              label="Full Name"
              value={fullName}
              onChange={e => {
                setFullName(e.target.value);
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
            />
          </div>
        </div>
        <CustomTextInput
          label="Email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
        />
      </div>
      <div className="modal-seperator" />
      <div className="add-teacher-modal" style={{padding: '24px'}}>
        <div className="button-container">
          <CustomCancel
            className="cancel"
            bgColor="#fff"
            onClick={() => {
              setIsModalVisible(false);
              setFullName('');
              setEmail('');
              setReferID('');
            }}
          >
            <span className="cancel-button-text">Cancel</span>
          </CustomCancel>
          <CustomButton
            className="save-changes"
            text={button}
            onClick={onClick}
          />
        </div>
      </div>
    </CustomBox>
  </CustomModal>
);

const MoreOption = ({
  teacherId,
  fName,
  lName,
  email,
  refID,
  setDpId,
  setDpName,
  showToastSuccess,
  setDeleteModalVisible,
  invitationStatus,
}) => {
  const [fullName, setFullName] = useState(`${fName} ${lName}`);
  const [useremail, setEmail] = useState(email);
  const [referID, setReferID] = useState(refID);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  return (
    <div className="dropdowncontainer">
      <DropdownWrapper toggleMenu={setDropdownVisibility}>
        <DropdownBtn>
          <button
            className="btn"
            onClick={() => setDropdownVisibility(!dropdownVisibility)}
            type="submit"
          >
            <img alt="Options" src={ThreeDotIcon} />
          </button>
        </DropdownBtn>
        {dropdownVisibility && (
          <Dropdown right="0.4rem">
            {invitationStatus === 1 ? null : (
              <DropdownItems
                click={() => {
                  setDropdownVisibility(false);
                  const data = {
                    fullname: fullName,
                    email: useremail,
                    refId: referID,
                  };
                  dispatch(teacherInvitationResend(teacherId, data));
                }}
                text="Resend Invitation"
              />
            )}
            <DropdownItems
              click={() => {
                setIsEditModalVisible(true);
                setDropdownVisibility(false);
              }}
              text="Edit"
            />
            <DropdownItems
              withoutSeperator
              isDanger
              click={() => {
                setDropdownVisibility(false);
                setDeleteModalVisible(true);
                setDpId(teacherId);
                setDpName(fullName);
              }}
              text="Delete"
            />
          </Dropdown>
        )}
      </DropdownWrapper>
      <TeacherModal
        title="Edit User details"
        subtitle="Make changes to the user details"
        button="Save Changes"
        isModalVisible={isEditModalVisible}
        email={useremail}
        setEmail={setEmail}
        refID={referID}
        setReferID={setReferID}
        fullName={fullName}
        setFullName={setFullName}
        setIsModalVisible={setIsEditModalVisible}
        onClick={() => {
          const teacherDetails = {};
          if (fullName) {
            teacherDetails.fullName = fullName;
          }
          if (useremail) {
            teacherDetails.useremail = useremail;
          }
          if (referID) {
            teacherDetails.referID = referID;
          }
          setIsEditModalVisible(false);
          dispatch(
            editTeacher(teacherId, teacherDetails, () => {
              showToastSuccess(`User Details updated successfully`);
              setTimeout(() => showToastSuccess(''), 5000);
            }),
          );
        }}
      />
    </div>
  );
};
const TeacherDetails = () => {
  const [tableColumns, setTableColumns] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableMaps, setTableMaps] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const {teachers, teacherToastSuccess, setTeacherToastSuccess} =
    useSelector(adminInfo);
  const history = useHistory();
  const [isToastSuccess, showToastSuccess] = useState('');
  const [isToastDanger, showToastDanger] = useState('');
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [dpId, setDpId] = useState();
  const [dpName, setDpName] = useState();
  useEffect(() => {
    dispatch(getAllTeacher());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllTeacher());
    const columns = [
      'up_id_userprofile',
      {
        heading: '#',
        width: '9%',
      },
      {
        heading: 'ID',
        width: '14%',
      },
      {
        heading: 'NAME',
        width: '20%',
      },
      {
        heading: 'EMAIL',
        width: '33%',
      },
      {
        heading: 'STATUS',
        width: '28%',
      },
      {
        heading: '',
        width: '5%',
      },
    ];
    const maps = {
      '#': [organizationReference => organizationReference + 1],
      ID: [
        organizationStartAcademicYear => organizationStartAcademicYear,
        // 'up_org_reference',
        'up_org_reference',
      ],
      NAME: [
        (fName, lName) => `${fName} ${lName}`,
        'up_name_first',
        'up_name_last',
      ],
      EMAIL: [
        organizationEndAcademicYear => organizationEndAcademicYear,
        'up_email',
      ],
      STATUS: [
        (invitationStatus, invitationTime) => (
          <div className="invitation-container">
            <div className={`invitation-status-${invitationStatus}`}>
              {invitationStatus === 1 ? 'Active' : 'Pending'}
            </div>
            {invitationStatus > 1 ? (
              <div>
                <div
                  className="invited"
                  data-html
                  data-tip={`<span className="tooltip-value">Invite resended on : ${moment(
                    invitationTime,
                  ).format('D MMM YYYY')}</span>`}
                >
                  <img alt="Reassigned" src={tooltipImg} />
                </div>
                <ReactTooltip
                  backgroundColor="#3d4457"
                  effect="solid"
                  place="bottom"
                />
              </div>
            ) : null}
          </div>
        ),
        'up_invitation_status',
        'up_timestamp_creation',
      ],
      '': [
        (teacherId, fName, lName, email, refererId, invitationStatus) => (
          <MoreOption
            teacherId={teacherId}
            fName={fName}
            lName={lName}
            email={email}
            refID={refererId}
            setDpId={setDpId}
            setDpName={setDpName}
            setDeleteModalVisible={setDeleteModalVisible}
            showToastSuccess={showToastSuccess}
            invitationStatus={invitationStatus}
          />
        ),
        'up_id_userprofile',
        'up_name_first',
        'up_name_last',
        'up_email',
        'up_org_reference',
        'up_invitation_status',
      ],
    };
    setTableColumns(columns);
    setTableMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teachers) {
      if (teachers.length) {
        setTableData(
          teachers.filter(profile =>
            `${profile.up_name_first} ${profile.up_name_last}`
              .toLowerCase()
              .includes(searchTerm),
          ),
        );
      }
    }
  }, [teachers, searchTerm]);

  return (
    <>
      <Helmet>
        <title>Teacher | JungleCat</title>
      </Helmet>
      <TeacherContainer>
        <div className="container-teacher">
          <div className="teacherheading d-flex justify-content-between  mb-4">
            <h2 style={{fontSize: '24px'}}>Teachers</h2>
            <CustomIconButton
              icon={addIcons}
              className="save-changes"
              text="Add Teacher"
              onClick={() => {
                history.push(`/admin/users/teacher/addTeacher`);
              }}
            />
            {/* </button> */}
          </div>
          <CustomBox padding="1px 20px" className="custombox">
            {tableData.length > 0 ? (
              <div className="search-div">
                <div>
                  <p>
                    {teachers?.length > 1
                      ? `${teachers?.length} Teachers`
                      : `${teachers?.length} Teacher`}
                  </p>
                </div>
                <div>
                  <SearchInput
                    onChange={event => setSearchTerm(event.target.value)}
                    placeholder="Search for a Teacher"
                    value={searchTerm}
                    width="300px"
                  />
                </div>
              </div>
            ) : null}
            {tableData.length > 0 ? (
              <div className="seperator-search mt-2" />
            ) : null}
            {tableColumns && tableData && tableMaps && tableData.length > 0 ? (
              <CustomTable
                {...tabularize(tableColumns, tableData, tableMaps)}
                className="org-teacher-table"
              />
            ) : null}
            {tableData.length === 0 ? (
              <div>
                <EmptyState
                  body="It seems that there is no teachers has been added to the organisation"
                  title="No teachers are added yet!"
                />
              </div>
            ) : null}
          </CustomBox>
          <CustomModal isVisible={isDeleteModalVisible}>
            <CustomBox>
              <div className="delete-teacher-modal">
                <div className="delete-teacher-modal-header">
                  <div className="modal-header-left">
                    <div>
                      <img src={deleteModal} alt="close" />
                    </div>
                    <div className="mx-2">
                      <span className="delete-teacher-modal-title">
                        Delete {dpName}
                      </span>
                      <p className="confirm">
                        Are you sure you want to delete this Teachers ?
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setDeleteModalVisible(false);
                      }}
                      type="button"
                      className="closeModalBtn"
                    >
                      <img src={closeModal} alt="close" />
                    </button>
                  </div>
                </div>
                <div className="footer-delete-break" />
                <div
                  className="delete-teacher-buttons"
                  style={{padding: ' 24px'}}
                >
                  <CustomCancel
                    className="cancel"
                    bgColor="#fff"
                    onClick={() => {
                      setDeleteModalVisible(false);
                    }}
                  >
                    <span className="cancel-button-text">Cancel</span>
                  </CustomCancel>
                  <CustomButton
                    text="Delete"
                    isDanger
                    onClick={() => {
                      dispatch(
                        deleteTeacher(dpId, () => {
                          showToastDanger(
                            `${dpName} Has been Removed successfully`,
                          );
                          setTimeout(() => showToastDanger(''), 5000);
                        }),
                      );
                      setDeleteModalVisible(false);
                    }}
                  />
                </div>
              </div>
            </CustomBox>
          </CustomModal>
          {isToastSuccess || teacherToastSuccess ? (
            <ToastContainer
              icon={teacherToastSuccess ? addToastSuccess : updateToastSuccess}
              text={isToastSuccess || teacherToastSuccess}
              isSuccess
              onClose={() => {
                showToastSuccess('');
                if (teacherToastSuccess) {
                  dispatch(setTeacherToastSuccess(''));
                }
              }}
            />
          ) : null}
          {isToastDanger ? (
            <ToastContainer
              text={isToastDanger}
              isDanger
              onClose={() => showToastDanger('')}
            />
          ) : null}
        </div>
      </TeacherContainer>
    </>
  );
};

export default TeacherDetails;
