/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Color} from '../../../constants';

export const FullContainer = styled.div`
  min-height: 100vh;
  background-color: #f4f5f8;
  margin: 0rem -0.9375rem;
  padding: 0 0.9375rem;
`;

export const AddAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -0.9375rem;
  padding-top: 2rem;
  background-color: #f4f5f8;
  .container-admin {
    padding: 0 0.9375rem;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 55.5rem) {
      & {
        width: 62%;
        min-width: 55.5rem;
      }
    }
  }
  .adminheading {
    h2 {
      color: #3d4457;
    }
  }
  .custombox {
    .search-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: 0.0625rem solid #e0e0e0;
      padding: 1.5rem 0rem 0rem 0rem;
      .buttom {
        margin-right: 2.5rem;
        border: none;
        background-color: unset;
        outline: none;
        color: #bcbcbc;
        &.link {
          border-bottom: 0.0625rem solid black;
          color: #3d4457;
        }
      }
    }

    .search-div-spread {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1.5rem 0rem 1.125rem 0;
      .imported {
        padding: 0;
        margin: 0;
        color: #3d4457;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
      }
      .imported-file {
        margin: 0;
        padding: 0;
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
      }
    }

    .threeDotIcon {
      border: none;
      background: transparent;
      outline: none;
    }
  }

  .inputfields {
    padding: 1.5rem 0 1.5rem 0rem;
    .logo-label {
      color: #aaa;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .org-size {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      span {
        color: #adadad;
      }
      a {
        color: #40a1ff;
        :hover {
          color: #40a1ff;
          text-decoration: none;
        }
      }
    }
    .error-size {
      color: red;
    }
  }
`;

export const SpreadSheetTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 0 0 1.5rem 0;
  width: 100%;
  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;

    td {
      :last-child {
        display: flex !important;
        justify-content: space-between;
      }
    }

    .serial {
      width: 5%;
    }

    .ref-id-manual {
      width: 13%;
    }

    .first-name-manual {
      width: 21%;
    }
    .last-name-manual {
      width: 21%;
    }

    .email-manual {
      width: 33%;
    }
    .close-row {
      width: 5%;
    }

    .closeIconBtn {
      display: block;
      border: none;
      background: transparent;
      outline: none;
    }
  }
`;
