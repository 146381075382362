/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showToast} from '../../../../../../../../../../components/common/Toast';
import ToastSuccessIcon from '../../../../../../../../../../assets/icons/class-success.svg';

import {
  spreadSheetUnitCheckByQualificationId,
  addUnitsByQualificationIdManual,
  addUnitsByQualIdSpread,
  qualificationsSelector,
  fetchAllQualifications,
} from '../../../../../../../../../../redux/qualificationsSlice';

import {AddUnitsToQualificationFooter} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import CustomButton from '../../../../../../../../../../components/shared/CustomButton';

export const AddUnitsFooter = ({
  addUnits,
  setAddUnits,
  spreadSheet,
  setSpreadSheet,
  manual,
  steps,
  setSteps,
  setUpload,
  setIsValidating,
  qualificationData,
  nextShow,
  isValidating,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    alreadyExistUcode,
    validUnitsToQualification,
    duplicateUnitData,
    isAddQualificationLoading,
  } = useSelector(qualificationsSelector);

  return (
    <AddUnitsToQualificationFooter>
      <div className="add-unit-footer fixed-bottom">
        {manual ? (
          <div className="add-unit-title">
            <div />

            <div className="button-container">
              <div />

              <div className="action-button">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setAddUnits([]);

                    history.replace(`/admin/qualifications`);
                  }}
                >
                  <span className="cancel1-button-text">Back</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!addUnits?.length}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    if (addUnits?.length && !isAddQualificationLoading) {
                      dispatch(
                        addUnitsByQualificationIdManual(
                          addUnits,
                          qualificationData,
                          () => {
                            showToast(
                              ToastSuccessIcon,
                              `${qualificationData?.qualificationName} has been added with ${addUnits?.length} units successfully.`,
                              false,
                            );
                            dispatch(fetchAllQualifications());
                          },
                        ),
                      );
                      history.replace(`/admin/qualifications`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="add-unit-spread">
            {steps === 1 ? (
              <div className="add-unit-title">
                <div />

                <div className="button-container">
                  <div />

                  <div className="action-button">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      onClick={() => {
                        setUpload(null);
                        setSpreadSheet([]);
                        setAddUnits([]);

                        history.replace(`/admin/qualifications`);
                      }}
                    >
                      <span className="cancel1-button-text">Back</span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={!spreadSheet?.length || nextShow}
                      className="save-changes"
                      text="Next"
                      onClick={() => {
                        if (spreadSheet?.length) {
                          setSteps(2);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {steps === 2 ? (
              <div className="add-unit-title">
                <div />

                <div className="button-container">
                  <div />
                  <div className="action-button">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      isDisabled={isValidating}
                      onClick={() => {
                        setUpload(null);
                        setSteps(1);
                        setSpreadSheet([]);
                      }}
                    >
                      <span className="cancel1-button-text">Back</span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={
                        !spreadSheet?.length || nextShow || isValidating
                      }
                      className="save-changes"
                      text="Next"
                      onClick={() => {
                        setIsValidating(true);
                        if (spreadSheet.length) {
                          dispatch(
                            spreadSheetUnitCheckByQualificationId(
                              spreadSheet,
                              qualificationData,
                              () => {
                                setTimeout(() => {
                                  setIsValidating(false);
                                  setSteps(state => state + 1);
                                }, 3000);
                              },
                            ),
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {steps === 3 &&
            validUnitsToQualification?.length === spreadSheet?.length &&
            !alreadyExistUcode?.length &&
            !duplicateUnitData?.length ? (
              <div className="add-unit-title">
                <div />

                <div className="button-container">
                  <div />
                  <div>
                    <CustomButton
                      isDisabled={!spreadSheet?.length || nextShow}
                      className="save-changes"
                      text="Done"
                      onClick={() => {
                        setSteps(state => state + 1);
                        showToast(
                          ToastSuccessIcon,
                          `${qualificationData?.qualificationName} has been added with ${spreadSheet?.length} units successfully.`,
                          false,
                        );
                        history.replace(`/admin/qualifications`);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {steps === 3 &&
            validUnitsToQualification?.length &&
            (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
              <div className="add-unit-title">
                <div />

                <div className="button-container">
                  <CustomCancel
                    className="cancel1"
                    bgColor="#fff"
                    onClick={() => {
                      setUpload(null);
                      setSteps(1);
                      setSpreadSheet([]);
                    }}
                  >
                    <span className="cancel1-button-text">Upload again</span>
                  </CustomCancel>
                  <CustomButton
                    isDisabled={!spreadSheet?.length || nextShow}
                    className="save-changes"
                    text="Add Valid Entries"
                    onClick={() => {
                      if (
                        validUnitsToQualification?.length &&
                        !isAddQualificationLoading
                      ) {
                        if (qualificationData) {
                          dispatch(
                            addUnitsByQualIdSpread(
                              validUnitsToQualification,
                              qualificationData,
                              () => {
                                showToast(
                                  ToastSuccessIcon,
                                  `${qualificationData?.qualificationName} has been added with ${validUnitsToQualification?.length} units successfully.`,
                                  false,
                                );
                                dispatch(fetchAllQualifications());
                                history.replace(`/admin/qualifications`);
                              },
                            ),
                          );
                        }
                      }
                    }}
                  />
                </div>
              </div>
            ) : null}
            {steps === 3 &&
            !validUnitsToQualification?.length &&
            (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
              <div className="add-unit-title">
                <div />
                <div className="button-container">
                  <div />
                  <div>
                    <CustomButton
                      isDisabled={!spreadSheet?.length || nextShow}
                      className="save-changes"
                      text="Done"
                      onClick={() => {
                        history.replace(`/admin/qualifications`);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </AddUnitsToQualificationFooter>
  );
};
