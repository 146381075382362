import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import micIcon from '../../../../../assets/icons/mic.svg';
import micOffIcon from '../../../../../assets/icons/mic-off.svg';
import videoIcon from '../../../../../assets/icons/video-black.svg';
import videoOffIcon from '../../../../../assets/icons/video-off.svg';
import threeDotIcon from '../../../../../assets/icons/threedot.svg';
import phoneIcon from '../../../../../assets/icons/phone.svg';
import screenIcon from '../../../../../assets/icons/screen.svg';
import chatIcon from '../../../../../assets/icons/chat.svg';
import chatActiveIcon from '../../../../../assets/icons/chat-active.svg';
import usersIcon from '../../../../../assets/icons/users.svg';
import LiveSessionBar from '../elements/LiveSessionBar';
import IconButton from './IconButton';
import ScreenIconGreen from '../../../../../assets/icons/screen-monitor-green.svg';

const LiveSessionFooter = ({
  onCallEnd,
  onScreenShare,
  isAudioMute,
  isVideoMute,
  onAudioMute,
  onVideoMute,
  isChatOpen,
  onChatToggle,
  onStudentListToggle,
  studentCount,
  totalStudentCount,
  isScreenSharingActive,
  noOfNewMessages,
}) => {
  const [time, setTime] = useState(moment(new Date()).format('h:mm A'));

  useEffect(() => {
    const timer = setInterval(
      () => setTime(moment(new Date()).format('h:mm A')),
      1000,
    );

    return () => clearInterval(timer);
  }, []);

  return (
    <LiveSessionBar className="fixed-bottom">
      <div className="d-flex">
        <span className="pr-3 border-right border-secondary">{time}</span>
        <span className="pl-3">
          {studentCount}/{totalStudentCount} Joined
        </span>
      </div>
      <div className="d-flex">
        <IconButton
          src={isAudioMute ? micOffIcon : micIcon}
          alt="Mute audio"
          onClick={onAudioMute}
          title={isAudioMute ? 'Turn on microphone' : 'Turn off microphone'}
        />
        <IconButton
          src={isVideoMute ? videoOffIcon : videoIcon}
          alt="Disable video"
          className="ml-2"
          onClick={onVideoMute}
          title={isVideoMute ? 'Turn on camera' : 'Turn off camera'}
        />
        <IconButton
          src={phoneIcon}
          variant="danger"
          alt="End call"
          className="rounded-pill px-4 mx-4"
          onClick={onCallEnd}
          title="Leave call"
        />
        <IconButton
          active={isScreenSharingActive}
          src={isScreenSharingActive ? ScreenIconGreen : screenIcon}
          alt="Screen share"
          onClick={onScreenShare}
          title={isScreenSharingActive ? 'stop presenting' : 'Present now'}
        />
        <IconButton
          src={threeDotIcon}
          rotate
          alt="More"
          className="ml-2"
          title="Settings"
        />
      </div>
      <div className="d-flex">
        <IconButton
          src={usersIcon}
          alt="Users"
          onClick={onStudentListToggle}
          title="Show everyone"
          id="attendees-btn"
        />
        <div className="chat-btn">
          <IconButton
            src={isChatOpen ? chatActiveIcon : chatIcon}
            alt="Chat"
            className="ml-2"
            onClick={onChatToggle}
            title={isChatOpen ? 'Close chat' : 'Open chat'}
            id="chat-btn"
          />
          {noOfNewMessages ? <span>{noOfNewMessages}</span> : null}
        </div>
      </div>
    </LiveSessionBar>
  );
};

LiveSessionFooter.propTypes = {
  onCallEnd: PropTypes.func,
  onScreenShare: PropTypes.func,
  isAudioMute: PropTypes.bool,
  isVideoMute: PropTypes.bool,
  onAudioMute: PropTypes.func,
  onVideoMute: PropTypes.func,
  isChatOpen: PropTypes.bool,
  onChatToggle: PropTypes.func,
  onStudentListToggle: PropTypes.func,
  studentCount: PropTypes.number,
  totalStudentCount: PropTypes.number,
  isScreenSharingActive: PropTypes.bool,
  noOfNewMessages: PropTypes.number,
};

LiveSessionFooter.defaultProps = {
  onCallEnd: undefined,
  onScreenShare: undefined,
  isAudioMute: false,
  isVideoMute: false,
  onAudioMute: undefined,
  onVideoMute: undefined,
  isChatOpen: false,
  onChatToggle: () => {},
  onStudentListToggle: () => {},
  studentCount: 0,
  totalStudentCount: 0,
  isScreenSharingActive: false,
  noOfNewMessages: PropTypes.number,
};

export default LiveSessionFooter;
