import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useDebounce, useLocalStorage} from 'react-use';

import {
  Button,
  Checkbox,
  Select,
  Spacer,
  TextInput,
  BackLink,
} from '../../../../../../components/common';
import {
  authSelector,
  fetchOrganisationByRtoCode,
  setError,
  setIsLoading,
  setOrganisation,
} from '../../../../../../redux/authSlice';
import {COUNTRIES} from '../../../../../../utils/dummy';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {registerOrganisationSchema} from '../../../../../../utils/validation';
import {AuthSwitchLink, ErrorAlert, Heading} from '../../../../components';

const FIELDS_IN_ORDER = ['country', 'rtoCode', 'organisationName'];

export const OrganisationForm = ({onClickNext, onNavigateBackToChooseType}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    reset,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      country: null,
      organisationName: '',
      rto: false,
      rtoCode: '',
      tGovOrgCode: '',
      tGovOrgId: '',
    },
    resolver: yupResolver(registerOrganisationSchema),
  });
  const [, setCountry] = useLocalStorage('country', null, {
    raw: true,
  });
  const [, setRtoCode] = useLocalStorage('rtoCode', null, {
    raw: true,
  });
  const [, setTgovOrgCode] = useLocalStorage('tGovOrgCode', null, {
    raw: true,
  });
  const [, setTgovOrgId] = useLocalStorage('tGovOrgId', null, {
    raw: true,
  });
  const {
    error: serverError,
    isLoading,
    organisation,
  } = useSelector(authSelector);
  const rtoCode = watch('rtoCode');

  const onSubmit = useCallback(
    data => {
      setCountry(data.country.value);
      setRtoCode(data.rtoCode);
      setTgovOrgCode(data.tGovOrgCode);
      setTgovOrgId(data.tGovOrgId);
      onClickNext(data.organisationName);
    },
    [onClickNext, setCountry, setRtoCode, setTgovOrgCode, setTgovOrgId],
  );
  const goBack = useCallback(() => {
    setCountry(null);
    setRtoCode('');
    setTgovOrgCode('');
    setTgovOrgId('');
    onClickNext('');
    dispatch(setOrganisation(null));
    reset();
  }, [
    setCountry,
    setRtoCode,
    setTgovOrgCode,
    setTgovOrgId,
    onClickNext,
    dispatch,
    reset,
  ]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {errorMessage, hasServerError, setHasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  useDebounce(
    async () => {
      dispatch(fetchOrganisationByRtoCode(rtoCode, setHasServerError));
    },
    1000,
    [dispatch, rtoCode],
  );

  useEffect(() => {
    dispatch(setOrganisation(null));
  }, [dispatch]);

  useEffect(() => {
    if (hasServerError) {
      setValue('organisationName', '');
    }
  }, [hasServerError, setValue]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    setValue('organisationName', '');
  }, [dispatch, rtoCode, setValue]);

  useEffect(() => {
    if (organisation) {
      setValue('organisationName', organisation?.legal_person_name || '');
      setValue('tGovOrgCode', organisation?.code || '');
      setValue('tGovOrgId', organisation?.id || '');
    }
  }, [organisation, setValue]);

  const CountriesOptions = useMemo(
    () => [
      ...(COUNTRIES || []).map(item => ({
        label: item.name,
        value: item.name,
      })),
    ],
    [],
  );

  return (
    <section className="align-items-center col-lg-6 col-md-12 d-flex flex-column justify-content-center main-section position-relative px-4">
      <ErrorAlert isVisible={!!errorMessage} message={errorMessage} />
      <main className="d-flex flex-column main-content w-100">
        <Spacer height="4rem" />
        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <BackLink
            className="mb-4"
            onClick={() => {
              onNavigateBackToChooseType();
              goBack();
            }}
          />
          <Heading
            marginBottom="2rem"
            subtitle="Enter your details to create an account."
            title="Create Your Account"
          />
          <form
            className="align-items-center d-flex flex-column w-100"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="country"
              render={fields => (
                <Select
                  {...fields}
                  className="mb-4"
                  hasError={!!errors.country}
                  label="Select Your Country"
                  options={CountriesOptions}
                  placeholder="Where is your organisation?"
                />
              )}
            />
            {watch('country')?.value === 'AUSTRALIA' ? (
              <div className="align-items-start d-flex mb-4 w-100">
                <Controller
                  control={control}
                  name="rto"
                  render={fields => (
                    <Checkbox {...fields} label="My organisation is an RTO" />
                  )}
                />
              </div>
            ) : null}
            {watch('rto') ? (
              <Controller
                control={control}
                name="rtoCode"
                render={fields => (
                  <TextInput
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.rtoCode}
                    hasFailed={hasServerError}
                    hasSucceeded={!!organisation}
                    isLarge
                    isLoading={isLoading}
                    label="RTO Code"
                    placeholder="Enter the RTO code"
                  />
                )}
              />
            ) : null}
            <Controller
              control={control}
              name="organisationName"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={!!errors.organisationName}
                  isLarge
                  label="Organisation Name"
                  placeholder="Enter the name of your organisation"
                />
              )}
            />
            <input {...register('tGovOrgCode')} type="hidden" />
            <input {...register('tGovOrgId')} type="hidden" />
            <Button isLarge label="Next" type="submit" />
          </form>
          <AuthSwitchLink
            linkHref="/login"
            linkText="Login"
            onLinkClick={() => {
              dispatch(setOrganisation(null));
              onNavigateBackToChooseType();
              clearServerError();
            }}
            text="Already have an account?"
          />
        </div>
        <Spacer height="4rem" />
      </main>
    </section>
  );
};

OrganisationForm.propTypes = {
  onClickNext: PropTypes.func.isRequired,
  onNavigateBackToChooseType: PropTypes.func.isRequired,
};
