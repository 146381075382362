/* eslint-disable react/require-default-props */

import React, {createContext, useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';

const ConfirmContext = createContext();
const ConfirmContextProvider = ({children}) => {
  const [confirm, setConfirm] = useState({
    promptData: null,
    isOpen: false,
    proceed: null,
    cancel: null,
  });

  useEffect(
    () => () => {
      if (confirm.cancel) {
        confirm.cancel();
      }
    },
    [confirm],
  );

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
  );
};

ConfirmContextProvider.propTypes = {
  children: PropTypes.node,
};

export {ConfirmContext, ConfirmContextProvider};
