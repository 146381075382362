import styled from 'styled-components';

export const TaskSubmissionFooterContainer = styled.footer`
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: 1.25rem 3rem 1.5rem 3rem;
  width: 100%;
  @media (max-width: 40rem) {
    & {
      padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    }
  }

  .footer-text {
    color: #3d4457;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  .footer-buttons {
    align-items: center;
    display: flex;

    .footer-resub-button {
      background-color: transparent;
      padding: 0.5rem 1.5rem;
      color: #40a1ff;
      font-size: 1rem;
      font-weight: 500;
      margin-right: 2.5rem;
      color: #474747;
      border: 0.0625rem solid #c1ced4;
      box-sizing: border-box;
      border-radius: 0.5rem;

      :active,
      :focus {
        outline: none;
      }
    }

    .footer-button {
      background-color: #40a1ff;
      border: none;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.5rem 1.5rem !important;

      :active,
      :focus {
        outline: none;
      }
    }
  }
`;
