import styled from 'styled-components';

export const RevokeStudentModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 41.25rem;
  @media (max-width: 41.25rem) {
    & {
      padding: 1.25rem 1.25rem;
      overflow-y: scroll;
      height: 100%;
      width: 100%;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    padding-bottom: 5%;
    ::-webkit-scrollbar {
      display: none;
    }
    .revoke-modal-custom-full {
      width: 100% !important;
      margin-top: unset;
      padding-bottom: unset;
      .revoke-student-modal-wrapper {
        width: 100%;
      }
      .revoke-modal-close {
        display: block;
      }

      .close-btn-revoke {
        margin-bottom: 0.375rem;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: transparent;
          border: none;
          padding: 0;

          :active,
          :focus {
            outline: none;
          }

          img {
            height: 1.5rem;
            vertical-align: unset;
            width: 1.5rem;
          }
        }
      }
    }
  }

  .revoke-modal-close {
    display: none;
  }
  .revoke-student-modal-wrapper {
    align-items: center;
    background-color: #fff;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    padding: 4.56rem 0 1.5rem 0;
    width: 95%;

    @media (max-width: 43.75rem) {
      /* & {
        max-width: %;
      } */
      p {
        padding: 0rem 3.06rem;
        text-align: center;
      }
      img {
        height: 4.5rem !important;
        width: 4.5rem !important;
      }
      .to-arrow {
        height: 0.687rem !important;
        width: 1.375rem !important;
      }
      h2 {
        padding: 0rem 1.75rem;
        text-align: center;
      }

      .graphics {
        margin-bottom: 1.5rem !important;
      }
      .send-button {
        background-color: #eb5757 !important;
      }
      .cancel-button {
        color: #aaaaaa !important ;
      }
    }
    .graphics {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;

      img:not(:nth-child(2)) {
        height: 6rem;
        width: 6rem;
      }

      img:nth-child(2) {
        height: 1.06rem;
        margin: 0 2.25rem;
        width: 2.156rem;
      }
    }

    h2 {
      color: #3d4457;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.5;
      margin-bottom: 0;

      :last-of-type {
        margin-bottom: 1rem;
      }
    }

    p {
      color: #aaa;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 0;

      :last-of-type {
        margin-bottom: 3.5rem;
      }
    }

    button {
      :active,
      :focus {
        outline: none;
      }
    }

    .cancel-button {
      background-color: transparent;
      border: none;
      color: #40a1ff;
      font-size: 0.875rem;
      font-weight: 500;
    }

    .send-button {
      background-color: #eb5757;
      border: none;
      border-radius: 0.5rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2rem;
      padding: 0.5rem 1.5rem;
    }
  }
`;
