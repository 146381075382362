import styled from 'styled-components';

const VideoContainerWrapper = styled.div`
  max-height: calc(100vh - 188px);
  overflow-y: hidden;
  width: 100%;
  margin: 94px 0px;
`;

export default VideoContainerWrapper;
