import axios from '../..';

export const fetchAllCourses = () => axios.get('api/admin/curriculum');

export const fetchCurriculumByCourseId = courseId =>
  axios.get(`api/admin/curriculum/view-curriculum/${courseId}`);

export const addCurriculumByCourseId = (courseId, data) =>
  axios.post(
    `api/admin/curriculum/view-curriculum/${courseId}/add-curriculum`,
    data,
  );

export const findModuleNameAlreadyExisted = (courseId, data) =>
  axios.post(
    `api/admin/curriculum/view-curriculum/${courseId}/findModuleName`,
    data,
  );

export const deleteCurriculumModuleById = (courseId, moduleId) =>
  axios.delete(
    `api/admin/curriculum/view-curriculum/${courseId}/delete-curriculum-module/${moduleId}`,
  );

export const editCurriculumModuleFileById = (courseId, fileId, data) =>
  axios.patch(
    `api/admin/curriculum/view-curriculum/${courseId}/edit-curriculum-module-file/${fileId}`,
    data,
  );

export const deleteCurriculumModuleFileById = (courseId, moduleFileId) =>
  axios.delete(
    `api/admin/curriculum/view-curriculum/${courseId}/delete-curriculum-module-file/${moduleFileId}`,
  );

export const fetchCurriculumFileByFileId = (courseId, curriculumFileId) =>
  axios.get(
    `api/admin/curriculum/view-curriculum/${courseId}/viewFileToSharedClass/${curriculumFileId}`,
  );
