/* eslint-disable react/prop-types */
import React from 'react';
import ViewImg from '../../../../../../../../assets/icons/viewclass.svg';

export const AttendanceColumn = ({
  sessionDate,
  today,
  sessionId,
  presentStudents,
  absentStudents,
  students,
  history,
}) => {
  if (sessionDate > today) {
    return (
      <div className="count-container">
        <div className="upcoming">Upcoming</div>
        {/* <div>
          <button
            onClick={() => {
              history.push(`sessions/${sessionId}/attendance`);
            }}
            type="button"
            className="view-session"
          >
            <span>View</span>
            <img src={ViewImg} alt="loader" className="viewsession-arrow" />
          </button>
        </div> */}
      </div>
    );
  }
  if (sessionDate < today && (presentStudents || absentStudents)) {
    return (
      <div className="count-container">
        <div className="attendance-report">
          <div className="counts-present-absent">
            <div className="counts-absent">{absentStudents}</div>
            <div className="counts-present">{presentStudents}</div>
          </div>

          <p className="mb-0 detail">{`${presentStudents}/${students}`}</p>
        </div>
        <button
          onClick={() => {
            history.push(`sessions/${sessionId}/attendance`);
          }}
          type="button"
          className="view-session"
          id="view-attendance-id"
        >
          <span>View Attendance</span>
          <img src={ViewImg} alt="loader" className="viewsession-arrow" />
        </button>
      </div>
    );
  }
  if ((!presentStudents || !absentStudents) && sessionDate < today) {
    return (
      <div className="count-container">
        <div className="counts-present-absent">
          <div className="counts-absent">Not Submitted</div>
        </div>

        <button
          onClick={() => {
            history.push(`sessions/${sessionId}/attendance`);
          }}
          type="button"
          className="view-session"
          id="view-attendance-id1"
        >
          <span>View Attendance</span>
          <img src={ViewImg} alt="loader" className="viewsession-arrow" />
        </button>
      </div>
    );
  }

  return null;
};
