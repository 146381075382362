/* eslint-disable camelcase */
import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import moment from 'moment';

import SessionsChart from '../SessionsChart';
import ScheduleSessionForm from './components/ScheduleSessionForm';
import SessionContainer from '../../elements/SessionContainer';
import * as sessionApi from '../../../../api/session';

const ScheduleSession = () => {
  const {id: instance} = useParams();

  const [sessionsToday, setSessionsToday] = useState([]);

  useEffect(() => {
    sessionApi
      .getSessions({
        instance,
        date: moment(new Date()).format('YYYY-MM-DD'),
        groupBy: 'date',
      })
      .then(res => {
        const {status, sessions} = res.data;
        if (status && sessions.length) {
          setSessionsToday(
            sessions.flatMap(({values}) =>
              values.map(
                ({
                  oruil_id_orgunitinstancelesson,
                  oruil_name,
                  oruil_timestart,
                  oruil_timefinish,
                }) => ({
                  from: oruil_timestart,
                  link: `sessions/${oruil_id_orgunitinstancelesson}`,
                  name: oruil_name,
                  to: oruil_timefinish,
                  id: oruil_id_orgunitinstancelesson,
                }),
              ),
            ),
          );
        }
      });
  }, [instance]);

  return (
    <SessionContainer className="row">
      <ScheduleSessionForm instance={instance} />
      <SessionsChart date={new Date()} sessions={sessionsToday} />
    </SessionContainer>
  );
};

export default ScheduleSession;
