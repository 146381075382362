/* eslint-disable react/prop-types */
import moment from 'moment';
import {useState} from 'react';
import {FeedBackModal} from '../../../../../../Components';
import AssessmentSubmittedIcon from '../../../../../../../../assets/icons/assessment-submitted.svg';
import resubmissionRequestedIcon from '../../../../../../../../assets/icons/resubmissionRequested.svg';
import workReassignIcon from '../../../../../../../../assets/icons/workReassign.svg';
import AssessmentEvaluationIcon from '../../../../../../../../assets/icons/assessment-evaluation.svg';
import feedBackIcon from '../../../../../../../../assets/icons/feedback.svg';

export const AssessmentStatus = ({assessmentStatus, assessment}) => {
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [feedBackData, setFeedBackData] = useState(null);
  if (assessmentStatus === 2) {
    return (
      <div className="assessment-status">
        <div>
          <p className="heading">Assessment Submitted</p>
          <p className="subtext">
            {moment(assessment?.aouis_timestamp_submission).format(
              'DD MMMM YYYY',
            )}
            {` • ${assessment?.aouis_hours}.${assessment?.aouis_minutes} Hrs`}
          </p>
        </div>
        <img
          src={AssessmentSubmittedIcon}
          alt="assessment"
          width="44px"
          height="44px"
        />
      </div>
    );
  }
  if (assessmentStatus === 5) {
    return (
      <div className="assessment-status">
        <div>
          <p className="heading">Resubmission Requested</p>
          <p className="subtext">
            {moment(
              assessment?.aouis_timestamp_request_resubmission_student,
            ).format('DD MMMM YYYY')}
          </p>
        </div>
        <img
          src={resubmissionRequestedIcon}
          alt="assessment"
          width="44px"
          height="44px"
        />
      </div>
    );
  }
  if (assessmentStatus === 4) {
    return (
      <div className="work-resigned">
        <div className="d-flex">
          <img
            src={workReassignIcon}
            alt="assessment"
            width="44px"
            height="44px"
          />
          <div className="ml-2">
            <p className="heading">Work Reassigned</p>
            <p className="subtext">
              {moment(assessment?.aouis_timestamp_updated).format(
                'DD MMMM YYYY',
              )}
            </p>
          </div>
        </div>
        <div className="button-right">
          <button
            className="footer-resub-button"
            onClick={() => {
              setFeedBackModal(true);
              setFeedBackData(assessment?.aouis_resubmission_note);
            }}
            type="button"
          >
            <img
              src={feedBackIcon}
              alt="assessment"
              width="20px"
              height="20px"
            />
            View Feedback
          </button>
        </div>
        {feedBackModal ? (
          <FeedBackModal
            onClose={() => {
              setFeedBackModal(false);
              setFeedBackData(null);
            }}
            showData={feedBackData}
          />
        ) : null}
      </div>
    );
  }
  if (assessmentStatus === 3) {
    return (
      <div className="work-evaluation">
        <div className="d-flex">
          <img
            src={AssessmentEvaluationIcon}
            alt="assessment"
            width="44px"
            height="44px"
          />
          <div className="ml-2">
            <p className="heading">Assessment Evaluated</p>
            <p className="subtext">
              {moment(assessment?.aouis_timestamp_updated).format(
                'DD MMMM YYYY',
              )}
            </p>
          </div>
        </div>
        <div className="button-right">
          <button
            className="footer-resub-button"
            onClick={() => {
              setFeedBackModal(true);
              setFeedBackData(assessment?.aouis_notes);
            }}
            type="button"
          >
            <img
              src={feedBackIcon}
              alt="assessment"
              width="20px"
              height="20px"
            />
            View Feedback
          </button>
        </div>
        {feedBackModal ? (
          <FeedBackModal
            onClose={() => {
              setFeedBackModal(false);
              setFeedBackData(null);
            }}
            showData={feedBackData}
          />
        ) : null}
      </div>
    );
  }
  return null;
};
