import AgoraRTC from 'agora-rtc-sdk-ng';
import {useCallback, useEffect, useRef, useState} from 'react';

const useAgora = (client, screenClient) => {
  const [speakingStates, setSpeakingStates] = useState({});
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localScreenVideoTrack, setLocalScreenVideoTrack] = useState(null);
  const [localScreenAudioTrack, setLocalScreenAudioTrack] = useState(null);
  const [remoteScreenVideoTrack, setRemoteScreenVideoTrack] = useState(null);
  const [remoteScreenAudioTrack, setRemoteScreenAudioTrack] = useState(null);
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const [isRemoteSharingScreen, setIsRemoteSharingScreen] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const screenClientUidRef = useRef(null);

  async function createLocalTracks(audioConfig, videoConfig) {
    const [microphoneTrack, cameraTrack] =
      await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig);
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  }

  const onScreenEnd = useCallback(async () => {
    await screenClient.leave();
    if (localScreenAudioTrack) {
      localScreenAudioTrack.close();
    }
    if (localScreenVideoTrack) {
      localScreenVideoTrack.close();
    }
    setLocalScreenAudioTrack(false);
    setLocalScreenVideoTrack(false);
    setIsSharingScreen(false);
    screenClientUidRef.current = null;
  }, [localScreenAudioTrack, localScreenVideoTrack, screenClient]);

  const createLocalScreenTracks = useCallback(
    async (screenConfig = {}) => {
      const screenTracks = await AgoraRTC.createScreenVideoTrack(
        screenConfig,
        'auto',
      );

      if (screenTracks instanceof Array) {
        setLocalScreenVideoTrack(screenTracks[0]);
        setLocalScreenAudioTrack(screenTracks[1]);
        screenTracks[0].on('track-ended', onScreenEnd);
      } else {
        setLocalScreenVideoTrack(screenTracks);
        setLocalScreenAudioTrack(null);
        screenTracks.on('track-ended', onScreenEnd);
      }
      return screenTracks;
    },
    [onScreenEnd],
  );

  const join = useCallback(
    async (channel, token, uid) => {
      const [microphoneTrack, cameraTrack] = await createLocalTracks();
      await client.join(
        process.env.REACT_APP_AGORA_APP_ID,
        channel,
        token,
        uid,
      );
      await client.publish([microphoneTrack, cameraTrack]);
    },
    [client],
  );

  const joinAndPublishScreen = useCallback(
    async (channel, token, uid) => {
      const screenTracks = await createLocalScreenTracks();

      await screenClient.join(
        process.env.REACT_APP_AGORA_APP_ID,
        channel,
        token,
        uid,
      );

      if (screenTracks instanceof Array) {
        await screenClient.publish([screenTracks[0], screenTracks[1]]);
      } else {
        await screenClient.publish(screenTracks);
      }
      screenClientUidRef.current = uid;
      setIsSharingScreen(true);
    },
    [screenClient, createLocalScreenTracks],
  );

  const leave = useCallback(async () => {
    await client.leave();
    await screenClient.leave();
    client.removeAllListeners();
    screenClient.removeAllListeners();
    if (localAudioTrack) {
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.close();
    }
    if (localScreenAudioTrack) {
      localScreenAudioTrack.close();
    }
    if (localScreenVideoTrack) {
      localScreenVideoTrack.close();
    }
    if (remoteScreenAudioTrack) {
      remoteScreenAudioTrack.stop();
    }
    if (remoteScreenVideoTrack) {
      remoteScreenVideoTrack.stop();
    }
  }, [
    client,
    localScreenAudioTrack,
    localScreenVideoTrack,
    remoteScreenAudioTrack,
    remoteScreenVideoTrack,
    localAudioTrack,
    localVideoTrack,
    screenClient,
  ]);

  const isUidOfScreenClient = useCallback(id => {
    const splitted = id.split('-');
    if (splitted[0] === 'screen') {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    client.on('user-joined', async user => {
      if (user.uid === screenClient.uid) {
        return;
      }
      if (isUidOfScreenClient(user.uid)) {
        return;
      }
      setRemoteUsers(
        Array.from(
          client.remoteUsers.filter(
            remoteUser => !isUidOfScreenClient(remoteUser.uid),
          ),
        ),
      );
    });

    client.on('user-published', async (user, mediaType) => {
      if (user.uid === screenClient.uid) {
        return;
      }

      await client.subscribe(user, mediaType);

      if (isUidOfScreenClient(user.uid)) {
        if (user.audioTrack) {
          setRemoteScreenAudioTrack(user.audioTrack);
        }
        setRemoteScreenVideoTrack(user.videoTrack);
        setIsRemoteSharingScreen(true);
        return;
      }

      setRemoteUsers(
        Array.from(
          client.remoteUsers.filter(
            remoteUser => !isUidOfScreenClient(remoteUser.uid),
          ),
        ),
      );
    });

    client.on('user-unpublished', user => {
      if (user.uid === screenClientUidRef.current) {
        return;
      }
      if (isUidOfScreenClient(user.uid)) {
        setIsRemoteSharingScreen(false);
        setRemoteScreenAudioTrack(null);
        setRemoteScreenVideoTrack(null);
        return;
      }

      setRemoteUsers(
        Array.from(
          client.remoteUsers.filter(
            remoteUser => !isUidOfScreenClient(remoteUser.uid),
          ),
        ),
      );
    });

    client.on('user-left', user => {
      // we cannot compare user.uid===screenClient.uid here because screenClient.uid will be undefined, since the screen client has left
      if (user.uid === screenClientUidRef.current) {
        return;
      }
      if (isUidOfScreenClient(user.uid)) {
        setIsRemoteSharingScreen(false);
        setRemoteScreenAudioTrack(null);
        setRemoteScreenVideoTrack(null);
        return;
      }

      setRemoteUsers(
        Array.from(
          client.remoteUsers.filter(
            remoteUser => !isUidOfScreenClient(remoteUser.uid),
          ),
        ),
      );
    });

    client.on('volume-indicator', volumes => {
      volumes.forEach(volume => {
        if (volume && volume.level > 5) {
          setSpeakingStates(value => {
            const draft = {...value};
            draft[volume.uid] = true;
            return draft;
          });
        } else {
          setSpeakingStates(value => {
            const draft = {...value};
            draft[volume.uid] = false;
            return draft;
          });
        }
      });
    });
  }, [client, screenClient, isUidOfScreenClient]);

  return {
    localAudioTrack,
    localVideoTrack,
    localScreenAudioTrack,
    localScreenVideoTrack,
    speakingStates,
    isSharingScreen,
    remoteScreenAudioTrack,
    remoteScreenVideoTrack,
    isRemoteSharingScreen,
    join,
    joinAndPublishScreen,
    onScreenEnd,
    remoteUsers,
    leave,
  };
};

export default useAgora;
