/* eslint-disable react/prop-types */
import moment from 'moment';
import {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import SessionRecordIcon from '../../../../../../../assets/icons/session-record.svg';
// import SessionShareIcon from '../../../../../../../assets/icons/session-share.svg';
import {Color} from '../../../../../../../constants';

const SessionContainer = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
  padding: 0.625rem 0.5rem;
  transition: background-color 0.1s;

  &.is-active {
    background-color: #ecf6ff;
  }

  :hover {
    background-color: #ecf6ff;
  }

  :active {
    background-color: #ecf6ff;
    outline: none;
  }

  :focus {
    outline: none;
  }
  .time-session {
    max-width: 82%;
  }

  .blue-circle {
    background-color: #54abff;
    border-radius: 0.21875rem;
    height: 0.4375rem;
    margin-right: 0.3125rem;
    width: 0.4375rem;
  }

  .time-interval {
    color: ${Color.PRIMARY_TEXT};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3;
    margin-right: 0.3125rem;
  }

  .session-name {
    color: ${Color.PRIMARY_TEXT};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .session-button {
    background-color: transparent;
    border: none;
    text-decoration: none;

    :active {
      outline: none;
    }

    :focus {
      outline: none;
    }

    &.record-button {
      height: 1.25rem;
      width: 1.25rem;
    }

    &.share-button {
      height: 1rem;
      width: 1rem;
    }

    img {
      height: 100%;
      object-fit: contain;
      vertical-align: unset;
      width: 100%;
    }
  }
`;

const Session = ({
  index,
  isActive,
  onActivate,
  session,
  selectedTimeLine,
  liveSessionPreference,
}) => {
  // Cache the formatted times.
  const fromTime = useMemo(
    () => moment(session.from, 'HH:mm').format('hh:mm'),
    [session],
  );
  const toTime = useMemo(
    () => moment(session.to, 'HH:mm').format('hh:mm A'),
    [session],
  );

  const isUpcomingSession = useMemo(() => {
    if (selectedTimeLine && moment(selectedTimeLine.date).isAfter(moment())) {
      return true;
    }
    if (session && moment(session.from, 'HH:mm').isAfter(moment())) {
      return true;
    }
    return false;
  }, [selectedTimeLine, session]);

  const [isHovered, setIsHovered] = useState(false);
  return (
    <SessionContainer
      className={`align-items-center d-flex justify-content-between mt-2 w-100 ${
        isActive ? 'is-active' : ''
      }`}
      onClick={() => {
        onActivate(index);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <div
        className="align-items-center w-100 d-flex time-session"
        data-for={session.id}
        data-tip
      >
        <span className="blue-circle flex-shrink-0" />
        <span className="time-interval flex-shrink-0">
          {fromTime} - {toTime}
        </span>
        <span className="session-name">{session.name}</span>
      </div>
      <Link
        // as={button}
        data-for={session.id}
        data-tip
        onClick={e => {
          if (isUpcomingSession) {
            e.preventDefault();
          }
        }}
        to={session?.link}
        className="d-flex session-button align-items-center justify-content-between"
        type="button"
      >
        {(isHovered || isActive) && liveSessionPreference && (
          <div className="align-items-center d-flex flex-shrink-0 record-button">
            {/* <button
            className="d-flex mr-2 session-button share-button"
            type="button"
          >
            <img alt="Share" src={SessionShareIcon} />
          </button> */}

            <img alt="Record" src={SessionRecordIcon} />
          </div>
        )}
      </Link>
      <ReactTooltip id={session.id} effect="solid" place="top">
        {isUpcomingSession
          ? 'Upcoming session - unable to join now'
          : session.name}
      </ReactTooltip>
    </SessionContainer>
  );
};

export default Session;
