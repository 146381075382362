/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import MessageContainer from './elements/MessageContainer';

const Message = ({message, previousMessage, nextMessage, isUser}) => {
  const isPreviousSender = previousMessage.senderId === message.senderId;
  const isNextSender = nextMessage.senderId === message.senderId;

  const ProfilePicture = (
    <span className="sender-avatar">
      {message.senderAvatar ? (
        <img src={message.senderAvatar} alt="sender-avatar" />
      ) : (
        `${message.senderFirstName[0]} ${message.senderLastName[0]}`
      )}
    </span>
  );

  return (
    <MessageContainer>
      <div
        key={message.id}
        className={classNames('message', {
          'is-user': isUser,
          'is-previous-sender': !isUser && isPreviousSender,
        })}
      >
        {!isUser && !isPreviousSender ? ProfilePicture : null}
        <span className="message-text">{message.text}</span>
      </div>
      {!isNextSender ? (
        <span className={classNames('message-time', {'is-user': isUser})}>
          {moment(message.createdAt).fromNow()}
        </span>
      ) : null}
    </MessageContainer>
  );
};

const messageType = PropTypes.shape({
  text: PropTypes.string,
  id: PropTypes.string,
  senderId: PropTypes.string,
  senderFirstName: PropTypes.string,
  senderLastName: PropTypes.string,
  senderAvatar: PropTypes.string,
  createdAt: PropTypes.number,
});

Message.propTypes = {
  message: messageType.isRequired,
  previousMessage: messageType.isRequired,
  nextMessage: messageType.isRequired,
  isUser: PropTypes.bool.isRequired,
};

export default Message;
