/* eslint-disable react/prop-types */

import {useMemo, useState} from 'react';
import {Controller} from 'react-hook-form';

import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {Table} from '../../../../../../../../components/common/Table';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import {TextInput} from '../../../../../../../../components/common/TextInput';
import TextModal from '../TextModal';
import EntryRequirementsFormContainer from './elements/EntryRequirementsFormContainer';

const EntryRequirementsForm = ({
  control,
  errors,
  hiddenValues,
  onUpdate,
  setValue,
  tasStatus,
}) => {
  const [isErModalOpen, setIsErModalOpen] = useState(false);

  const isTableColumns = useMemo(
    () => [
      {
        Cell: ({value}) => (
          <div className="column-table-value mr-4">{value}</div>
        ),
        Header: 'Qualification',
        accessor: 'qualification',
        className: 'label-column',
      },
      {
        Cell: ({value}) => (
          <div className="column-table-value" style={{marginRight: '0.75rem'}}>
            {value}
          </div>
        ),
        Header: 'Score',
        accessor: 'score',
        className: 'input-column',
      },
      {
        Cell: ({value}) => (
          <div className="column-table-value" style={{marginLeft: '0.75rem'}}>
            {value}
          </div>
        ),
        Header: 'Degree',
        accessor: 'degree',
        className: 'input-column is-last',
      },
    ],
    [],
  );
  const isTableData = useMemo(
    () => [
      {
        degree: 5.5,
        qualification: 'IELTS (Academic Module)',
        score: 5.5,
      },
    ],
    [],
  );

  const lsTableColumns = useMemo(
    () => [
      {
        Cell: ({value}) => (
          <div className="column-table-value mr-4">{value}</div>
        ),
        Header: 'Qualification',
        accessor: 'qualification',
        className: 'label-column',
      },
      {
        Cell: () => (
          <Controller
            control={control}
            name="localStudentsLiteracy"
            rules={{
              required: true,
              pattern: /^\d+$/,
            }}
            render={fields => (
              <TextInput
                {...fields}
                className="table-input"
                onKeyUp={e => {
                  if (+e.target.value <= 5 && +e.target.value >= 1) {
                    setValue('localStudentsLiteracy', e.target.value);
                  } else {
                    setValue('localStudentsLiteracy', '');
                  }
                }}
                isDisabled={tasStatus}
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
              />
            )}
          />
        ),
        Header: 'Literacy',
        accessor: 'literacy',
        className: 'input-column',
      },
      {
        Cell: () => (
          <Controller
            control={control}
            name="localStudentsNumeracy"
            // rules={{required: true, pattern: /[A-Za-z]{3}/}}
            render={fields => (
              <TextInput
                {...fields}
                className="table-input is-last"
                onKeyUp={e => {
                  if (+e.target.value <= 5 && +e.target.value >= 1) {
                    setValue('localStudentsNumeracy', e.target.value);
                  } else {
                    setValue('localStudentsNumeracy', '');
                  }
                }}
                isDisabled={tasStatus}
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
              />
            )}
          />
        ),
        Header: 'Numeracy',
        accessor: 'numeracy',
        className: 'input-column is-last',
      },
    ],
    [control, onUpdate, setValue, tasStatus],
  );
  const lsTableData = useMemo(
    () => [
      {
        literacy: 0,
        numeracy: 0,
        qualification: 'Australian Core Skills Framework',
      },
    ],
    [],
  );

  return (
    <EntryRequirementsFormContainer className="section" id="entry-requirements">
      <h3>Entry Requirements</h3>
      <p>
        Identify any mandatory requirements for students commencing the program,
        such as qualifications that must be held or a period of industry
        experience. You should also clearly identify where any entry
        requirements are set by your RTO and are not an entry requirement of the
        training product. <br /> <br />
        At this stage, it can also be useful to identify:
        <br />
        Any areas where students may need additional support (e.g. if they have
        limited English-language skills).
        <br />
        Whether students’ physical attributes may influence their ability to
        complete the training and assessment (e.g. if heavy lifting is
        required).
      </p>
      <form>
        <div className="tas-form-row">
          <div className="is-full-width is-read-only tas-form-column">
            <div className="column-title">
              Entry Requirements{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value" style={{whiteSpace: 'pre-line'}}>
              {hiddenValues.entryRequirements.slice(0, 720)}
              {hiddenValues.entryRequirements.length > 720 ? (
                <>
                  ...
                  <button onClick={() => setIsErModalOpen(true)} type="button">
                    See more
                  </button>
                </>
              ) : (
                ''
              )}
              {!hiddenValues.entryRequirements ? 'Not Available' : ''}
            </div>
            <TextModal
              content={hiddenValues.entryRequirements}
              isVisible={isErModalOpen}
              onClose={() => setIsErModalOpen(false)}
              title="Entry Requirements"
            />
          </div>
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="rtoEntryRequirements"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.rtoEntryRequirements}
                hasInfo="<ul><li>To ensure students fully understand their obligations, inform prospective students of any entry requirements and/or specific requirements they need to meet to successfully complete the program.</li><br/><li>You must also make it clear if students need to provide any materials and/or equipment, such as personal protective equipment.</li><br/><li>Make it clear whether the training includes mandatory work placements. If mandatory work placements are part of the training, you must provide students with clear information on who will arrange these.</li></ul>"
                label="RTO Further Entry Requirements"
                disabled={tasStatus}
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                rows={5}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            <div className="column-table-title">
              Entry Requirement for International Students{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="International English Language Testing System (IELTS) is one of the top language tests conducted to test English proficiency around the world. This test has 4 sections, that are <br /><br />
                <ul>
                <li>Listening</li>
<li>Speaking</li>
<li>Reading and</li>
<li>Writing</li>
</ul>
<b>Diploma level (Certificates, Diplomas, Advanced diplomas)</b> - Overall score of 5.5 (no band less)
"
                data-for="global"
              />
            </div>
            <Table
              columns={isTableColumns}
              data={isTableData}
              isWithoutHeader
              isWithoutPagination
            />
          </div>
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            <div className="column-table-title">
              Entry Requirement for Local Students{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The Australian Core Skills Framework (ACSF) is a tool which assists both specialist and non-specialist English language, literacy and numeracy practitioners describe an individual’s performance in the five core skills of learning, reading, writing, oral communication and numeracy."
                data-for="global"
              />
            </div>
            <Table
              columns={lsTableColumns}
              data={lsTableData}
              isWithoutHeader
              isWithoutPagination
            />
          </div>
        </div>
      </form>
    </EntryRequirementsFormContainer>
  );
};

export default EntryRequirementsForm;
