/* eslint-disable react/prop-types */
import {createContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import AgoraRTC from 'agora-rtc-sdk-ng';
import * as sessionApi from '../../../../../api/session';
import {TableSpinner} from '../../../../Admin/components-new';
import {authSelector} from '../../../../AuthOld/authSlice';

const LiveSessionContext = createContext();

const LiveSessionContextProvider = ({children}) => {
  const {id: roomId} = useParams();
  const [sessionParticipants, setSessionParticipants] = useState([]);
  const [hostDetails, setHostDetails] = useState(null);
  const [isSessionDetailsLoading, setIsSessionDetailsLoading] = useState(true);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [totalStudentCount, setTotalStudentCount] = useState(0);
  const [error, setError] = useState(null);
  const {user} = useSelector(authSelector);
  const [userId, setUserId] = useState(null);
  const [screenSharingUserDetails, setScreenSharingUserDetails] = useState({});

  const currentProfileId = useMemo(
    () => +localStorage.getItem('jc-user-type'),
    [],
  );

  const isHost = !!(currentProfileId === 3);

  const currentProfile = useMemo(
    () =>
      user.userProfiles.find(
        ({up_id_typeuserprofile: profileType}) =>
          profileType === currentProfileId,
      ),
    [currentProfileId, user],
  );

  const init = async () => {
    try {
      const cameras = await AgoraRTC.getCameras();
      const microphones = await AgoraRTC.getMicrophones();

      if (!cameras.length) {
        setError("No camera's were found on your device");
        return;
      }

      if (!microphones.length) {
        setError('No microphones were found on your device');
        return;
      }

      const data = await sessionApi
        .getSession(roomId)
        .then(res => res.data.session);

      const {
        oruil_timestart: startTime,
        oruil_timefinish: finishTime,
        oruil_name: title,
        oruil_description: description,
        oruil_id_userprofile_teacher: hostId,
        teacher: {
          up_name_first: firstname,
          up_name_last: lastname,
          up_avatar: avatar,
        },
        orgUnitInstance: {
          students: studentsToBeInCall,
          class: {oc_class: classname},
          orgUnit: {
            unit: {tun_title: unitname},
          },
        },
        teacher,
      } = data;
      const participants = [teacher, ...studentsToBeInCall];
      const canStudentJoin = participants.some(
        participant =>
          participant.up_id_userprofile === currentProfile.up_id_userprofile,
      );
      if (!canStudentJoin) {
        setError('you are not a student of this class');
        return;
      }
      setSessionDetails({
        startTime,
        finishTime,
        title,
        description,
        classname,
        unitname,
      });
      setHostDetails({id: hostId, firstname, lastname, avatar});
      setTotalStudentCount(studentsToBeInCall.length);
      setSessionParticipants(participants);
      setUserId(currentProfile.up_id_userprofile);
    } catch (err) {
      if (err.code === 'PERMISSION_DENIED') {
        setError('please give permission for accessing camera and microphone');
        return;
      }
      setError('something went wrong, please try again later');
    } finally {
      setIsSessionDetailsLoading(false);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return error; // replace with modal
  }

  if (isSessionDetailsLoading) {
    return <TableSpinner />;
  }

  return (
    <LiveSessionContext.Provider
      value={{
        isHost,
        currentProfile,
        sessionParticipants,
        hostDetails,
        totalStudentCount,
        sessionDetails,
        userId,
        screenSharingUserDetails,
        setScreenSharingUserDetails,
      }}
    >
      {children}
    </LiveSessionContext.Provider>
  );
};

export {LiveSessionContext, LiveSessionContextProvider};
