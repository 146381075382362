import styled from 'styled-components';
import {motion} from 'framer-motion';

export const AddDocumentContainer = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 2000;

  .sidebar-head {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    background-color: #ffffff;
    height: 100%;
    width: 38rem;
    overflow: auto;

    .sidebar-title {
      color: ${({theme}) => theme.colors.text};
      font-weight: 500;
      font-size: 1.5rem;
    }
    .class-name {
      font-weight: 500;
      font-size: 1rem;
      color: #aaaaaa;
    }

    .close-sidebar-button {
      background-color: transparent;
      border: none;
      height: 1.5rem;
      width: 1.5rem;

      :active,
      :focus {
        outline: none;
      }

      img {
        height: 100%;
        object-fit: contain;
        vertical-align: unset;
        width: 100%;
      }
    }
  }
`;
