/* eslint-disable react/prop-types */
import React from 'react';

import {Modal} from '../../../../../../../../components/common';

export const DeleteModal = ({
  isButtonLoading,
  onButtonClick,
  onHide,
  show,
  title,
}) => (
  <Modal
    buttonLabel="Delete"
    description="Are you sure you want to delete this assessment?"
    isDelete
    isButtonLoading={isButtonLoading}
    loadingButtonLabel="Deleting"
    onButtonClick={onButtonClick}
    onHide={onHide}
    show={show}
    title={title}
    width="41.25rem"
  />
);
