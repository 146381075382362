import {createSlice} from '@reduxjs/toolkit';

import * as dashboardApi from '../api/admin/dashboard';

// const DELAY = 500;

const dashboardSlice = createSlice({
  initialState: {
    error: null,
    isLoading: true,
    dashboard: null,
  },
  name: 'dashboard',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
  },
});

export const {setError, setIsLoading, setDashboard} = dashboardSlice.actions;

export const fetchDashboard = () => async dispatch => {
  try {
    const response = await dashboardApi.fetchDashboard();
    dispatch(setIsLoading(false));
    dispatch(setDashboard(response.data));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const dashboardSelector = state => state.dashboard;

export default dashboardSlice.reducer;
