import styled from 'styled-components';

export const StudentsContainer = styled.div`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 4%;
  }
  .ref-id {
    width: 13%;
  }

  .first-name {
    width: 20%;
  }
  .last-name {
    width: 20%;
  }
  .email {
    width: 38%;
  }
  .more-option {
    width: 4%;
  }
  .table-container {
    border-radius: unset;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
`;
