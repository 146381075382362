import styled from 'styled-components';

const AssessmentResourcesFormContainer = styled.div`
  .serial {
    width: 4.3%;
  }

  .unit {
    width: 35%;
  }

  .assessments-used {
    width: 38.7%;
  }
  .assessment-map {
    width: 22%;
  }
  .view-class {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.312rem;
    padding: 0.75rem 1rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
    .viewclass-arrow {
      margin-left: 0.75rem;
      vertical-align: middle;
    }
  }
`;

export default AssessmentResourcesFormContainer;
