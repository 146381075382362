/* eslint-disable no-nested-ternary */

import {Helmet} from 'react-helmet';

import {HeroSection} from '../../components';
import {AuthContainer} from '../../elements';
import {JoinForm} from './components';

export const Join = () => (
  <AuthContainer className="no-gutters row">
    <Helmet>
      <title>Join | JungleCat</title>
    </Helmet>
    <HeroSection />
    <JoinForm />
  </AuthContainer>
);
