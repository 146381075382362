/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import {AttendanceCardContainer} from './elements';
import ProfileIcon from '../../../../../../../../assets/icons/profile.svg';
import RedCrossIcon from '../../../../../../../../assets/icons/attendance-red-cross.svg';
import RedCrossActiveIcon from '../../../../../../../../assets/icons/attendance-red-cross-active.svg';
import GreenTickIcon from '../../../../../../../../assets/icons/attendance-green-tick.svg';
import GreenTickActiveIcon from '../../../../../../../../assets/icons/attendance-green-tick-active.svg';

export const AttendanceCard = ({
  studentName,
  referenceId,
  attendType,
  onPresent,
  onAbsent,
  profileImg,
}) => {
  const absentOnclick = () => {
    onAbsent();
  };
  const presentOnclick = () => {
    onPresent();
  };

  return (
    <AttendanceCardContainer
      className={classNames('card-name', {
        'is-absent': attendType === 3,
        'is-present': attendType === 2,
      })}
    >
      <div className="left-card">
        <div className="align-items-center circle d-flex justify-content-center">
          <img
            style={{height: '40px', width: '40px'}}
            src={profileImg || ProfileIcon}
            alt="icon"
            className="rounded-circle"
          />
        </div>
        <div className="content">
          <span className="name">{studentName}</span>
          <span className="ref-id">{referenceId}</span>
        </div>
      </div>
      <div className="right-card">
        <button className="circle-button" type="button" onClick={absentOnclick}>
          <img
            src={attendType === 3 ? RedCrossActiveIcon : RedCrossIcon}
            alt="icon"
          />
        </button>
        <button
          className="circle-button  ml-3 mr-1"
          type="button"
          onClick={presentOnclick}
        >
          <img
            src={attendType === 2 ? GreenTickActiveIcon : GreenTickIcon}
            alt="icon"
          />
        </button>
      </div>
    </AttendanceCardContainer>
  );
};
