/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React from 'react';
import classNames from 'classnames';

import NoIcon from '../../../../../../assets/icons/no.svg';
import TaskFilesIcon from '../../../../../../assets/icons/task-files-icon.svg';
import YesIcon from '../../../../../../assets/icons/yes.svg';
import {EvaluationStatus} from './components';
import {LinkedTaskContainer} from './elements';
import {SeeMoreButton} from '../../../../../../components/common';

export const LinkedTask = ({
  isLinkedToFile,
  isSelected,
  index,
  item,
  onSelectLinkedTask,
  submissiondetails,
}) => {
  const [submission = {}] = submissiondetails || [];
  const isGrade = [3, 4, 5, 6, 7, 8].includes(
    submission.ais_id_eval_type_value,
  );
  const isYesOrNo = [1, 2].includes(submission.ais_id_eval_type_value);
  // const [isExpanded, setIsExpanded] = useState(false);

  return (
    <LinkedTaskContainer
      className={classNames(
        'd-flex',
        'flex-column',
        'mb-3',
        'p-4',
        'position-relative',
        {
          selected: isLinkedToFile || isSelected,
        },
      )}
      onClick={() => {
        if (!isSelected) onSelectLinkedTask(item.ai_id_activityitem);
      }}
      type="button"
    >
      {isSelected && (
        <>
          <div className="position-absolute selection-bar" />
          <div className="position-absolute selection-arrow" />
        </>
      )}
      <div className="align-items-center d-flex task-title">
        <span className="align-items-center d-flex justify-content-center task-index">
          {index}
        </span>
        <h4 className="mb-0 task-name">{item.ai_activityitem_title}</h4>
      </div>
      <p className="mb-3 task-description">
        <SeeMoreButton
          data={item.ai_activityitem_description}
          lettersCount={400}
        />
      </p>
      {Object.keys(item?.performanceCriteria || {}).length ? (
        <EvaluationStatus
          status={submission.etv_type_value}
          criteria={item.performanceCriteria?.tpc_criteria_value}
          criteriaId={item.performanceCriteria?.tpc_criteria_id}
        />
      ) : null}
      <div className="align-items-center d-flex justify-content-between mt-3 w-100">
        <div className="align-items-center d-flex">
          <img
            alt="Files"
            className="evaluation-icon mr-2"
            src={TaskFilesIcon}
          />
          <span className="task-files-count">
            {item.linkedFiles.length} File{item.linkedFiles.length > 1 && 's'}
          </span>
        </div>
        {submission?.ais_id_eval_type_value && (
          <div className="align-items-center d-flex">
            <span
              className={classNames('evaluation-value', {
                'text-for-no': submission.ais_id_eval_type_value === 2,
                'text-for-yes': submission.ais_id_eval_type_value === 1,
              })}
              style={{color: submission.etv_type_color}}
            >
              {isYesOrNo && submission.etv_type_value}
              {isGrade && submission.etv_type_label}
            </span>
            {isYesOrNo && (
              <img
                alt="Evaluation Value"
                className="evaluation-icon ml-2"
                src={submission.ais_id_eval_type_value === 1 ? YesIcon : NoIcon}
              />
            )}
            {isGrade && (
              <div
                className="align-items-center d-flex grade-value justify-content-center ml-2"
                style={{backgroundColor: submission.etv_type_color}}
              >
                {submission.etv_type_value}
              </div>
            )}
          </div>
        )}
      </div>
    </LinkedTaskContainer>
  );
};
