/* eslint-disable react/prop-types */
import React from 'react';

import AdminTypeImg from '../../../../../../../../../../assets/icons/admin-type.svg';
import teacherTypeImg from '../../../../../../../../../../assets/icons/teacher-type.svg';
import CheckImg from '../../../../../../../../../../assets/icons/checked.svg';
import {UserTypeSelectContainer} from './elements';

export const UserTypeSelect = ({
  setIsTeacherSpreadsheet,
  setIsAdminSpreadsheet,
  isAdminSpreadsheet,
  isTeacherSpreadsheet,
}) => (
  <UserTypeSelectContainer>
    <div className="user-type-div">
      <div className="usertype-context">
        <span className="user-type">Choose User type</span>
        <span className="select-user-type">
          Select the user type that you wish to add.
        </span>
      </div>
      <button
        className={`admin-type-div ${isAdminSpreadsheet ? ' active-div' : ''}`}
        type="button"
        onClick={() => {
          setIsAdminSpreadsheet(state => !state);
        }}
      >
        <div className="left-div">
          <button type="button" className="checkbox">
            {isAdminSpreadsheet ? (
              <img src={CheckImg} alt="checked" width="19px" height="19px" />
            ) : (
              ''
            )}
          </button>
          <div className="left-div-content">
            <span className="admin-type">Admin</span>
            <span className="select-admin-type">
              Admin has the ability to invite additional users and manage your
              organisation.
            </span>
          </div>
        </div>
        <div className="right-div">
          <img src={AdminTypeImg} alt="admin-logo" className="right-div-img" />
        </div>
      </button>
      <button
        className={`teacher-type-div${
          isTeacherSpreadsheet ? ' active-div' : ''
        }`}
        type="button"
        onClick={() => {
          setIsTeacherSpreadsheet(state => !state);
        }}
      >
        <div className="left-div">
          <button type="button" className="checkbox">
            {isTeacherSpreadsheet ? (
              <img src={CheckImg} alt="checked" width="19px" height="19px" />
            ) : (
              ''
            )}
          </button>
          <div className="left-div-content">
            <span className="teacher-type">Teacher</span>
            <span className="select-teacher-type">
              The teacher can add pupils to the class and assign assignments to
              them.
            </span>
          </div>
        </div>
        <div className="right-div">
          <img
            src={teacherTypeImg}
            alt="admin-logo"
            className="right-div-img"
          />
        </div>
      </button>
    </div>
  </UserTypeSelectContainer>
);
