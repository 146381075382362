import styled from 'styled-components';
import {AdminListingPageContainer} from '../../../../../../Admin/elements-new';

export const ClassAttendanceTableConatiner = styled(AdminListingPageContainer)`
  margin: unset !important;
  width: 100% !important;
  padding: unset !important;
  .table-container {
    width: 100% !important;
  }
  .serial {
    width: 4%;
  }

  .class-name {
    width: 30%;
  }
  .unit-code {
    width: 15%;
  }
  .total-session {
    width: 10%;
  }

  .present {
    width: 8%;
  }

  .absent {
    width: 8%;
  }
  table {
    tbody {
      tr {
        td:last-child {
          justify-content: space-between;
        }
      }
    }
  }
  .class-unit-name-div {
    width: 98%;
    .class-unit-name {
      font-weight: 400;
    }
  }
  .absent-session-div {
    .absent-session {
      font-weight: 500;
      color: #ff6969;
    }
  }
  .present-session-div {
    .present-session {
      font-weight: 500;
      color: #4ec977;
    }
  }
`;
