/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const TasStatusContainer = styled.div`
  display: flex;
  align-items: center;

  .Published {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      background: #defae8;
      border-radius: 0.3125rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #4ec977;
    }
  }
  .Pending {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      background: #ffe1e1;
      border-radius: 0.3125rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #ff8787;
    }
  }
  .Draft {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      background: #fcf3cf;
      border-radius: 0.3125rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #d9b00e;
    }
  }
`;

export const TasStatus = ({status}) => {
  if (status === 'Published') {
    return (
      <TasStatusContainer>
        <div className="Published">
          <p>{status}</p>
        </div>
      </TasStatusContainer>
    );
  }
  if (status === 'Draft') {
    return (
      <TasStatusContainer>
        <div className="Draft">
          <p>{status}</p>
        </div>
      </TasStatusContainer>
    );
  }
  return (
    <TasStatusContainer>
      <div className="Pending">
        <p>Pending</p>
      </div>
    </TasStatusContainer>
  );
};
