/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import CheckIcon from '../../../../../../../../assets/icons/checked.svg';

export const CheckBox = ({onCheck, orgUnit, orgUnitId, isDisabled}) => {
  const [addUnitState, setAddUnitState] = useState(
    Object.keys(orgUnit).includes(orgUnitId),
  );

  return (
    <button
      type="button"
      className="checkbox"
      onClick={() => {
        setAddUnitState(state => !state);
        onCheck();
      }}
      disabled={isDisabled}
    >
      {addUnitState ? (
        <img src={CheckIcon} alt="checked" width="19px" height="19px" />
      ) : (
        ''
      )}
    </button>
  );
};
