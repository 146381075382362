import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const StudentContainer = styled(AdminListingPageContainer)`
  .serial {
    width: 4%;
  }

  .ref-id {
    width: 10%;
  }

  .first-name {
    width: 14%;
  }

  .last-name {
    width: 14%;
  }

  .email {
    width: 27%;
  }
  .view {
    width: 13%;
  }

  .status {
    width: 12%;
  }

  .view-profile {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.875rem;
    justify-content: space-between;
    .view-arrow {
      margin-left: 0.5rem;
    }
    padding: unset;
  }
  .invited {
    display: flex;
    align-items: center;
    span {
      color: #aaa;
    }
    .view-profile {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 0.875rem;
      justify-content: space-between;
      opacity: 0.5;

      .view-arrow {
        margin-left: 0.5rem;
      }
      padding: unset;
    }
  }
  .more-option {
    width: 8%;
  }
  .view-profile-div {
    padding: 0.3rem 0;
  }
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
    .view-profile-div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
