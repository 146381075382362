/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
  getAllUnitsTeachers,
  getUnitsByType,
  classesSelector,
} from '../../../../../../redux/classesSlice';
import {EmptyState, Table, Spacer} from '../../../../../../components/common';
import {
  CheckBox,
  AddTeacherDropdown,
  ManageUnitHeader,
  ManageUnitFooter,
} from './components';

import {TableSpinner} from '../../../../components-new';
import {UnitsContainer} from './elements';

// const DELAY = 500;

export const ManageUnits = () => {
  const dispatch = useDispatch();
  const {orgClassId} = useParams();

  const {
    unitTeachers,
    manageUnits,
    singleClass,
    isSingleClassLoading,
    isClassesLoading,
  } = useSelector(classesSelector);
  const [orgUnit, setOrgUnit] = useState({});
  const [removeUnits, setRemoveUnit] = useState([]);
  const [addUnits, setAddUnits] = useState([]);

  useEffect(() => {
    const unitsWithTeachers = {};
    manageUnits.forEach(item => {
      if (item?.orgUnitInstance) {
        unitsWithTeachers[item.oqu_id_orgunit] =
          item?.orgUnitInstance?.oruiTeachers?.map(orui => ({
            teacherId: orui?.userProfile?.up_id_userprofile,
            fname: orui?.userProfile?.up_name_first,
            lname: orui?.userProfile?.up_name_last,
          })) || [];
      }
    });
    setOrgUnit(unitsWithTeachers);
  }, [manageUnits]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        Cell: ({row: {original}}) => {
          const orgUnitId = original?.oqu_id_orgunit;
          return (
            <CheckBox
              onCheck={() => {
                if (original?.orgUnitInstance) {
                  if (orgUnit[orgUnitId]) {
                    setRemoveUnit(state => [...state, orgUnitId]);
                  } else {
                    setRemoveUnit(state =>
                      state.filter(item => item !== orgUnitId),
                    );
                  }
                }
                if (!original?.orgUnitInstance) {
                  if (!orgUnit[orgUnitId]) {
                    setAddUnits(state => [...state, orgUnitId]);
                  } else {
                    setAddUnits(state =>
                      state.filter(item => item !== orgUnitId),
                    );
                  }
                }
                setOrgUnit(state => {
                  if (state[orgUnitId]) {
                    const newOrgUnit = {...state};
                    delete newOrgUnit[orgUnitId];
                    return newOrgUnit;
                  }
                  const newOrgUnit = {...state, [orgUnitId]: []};
                  return newOrgUnit;
                });
              }}
              orgUnit={orgUnit}
              orgUnitId={orgUnitId}
              setOrgUnit={setOrgUnit}
              isDisabled={original.unitType === 'core'}
              isChecked={Object.keys(orgUnit).includes(orgUnitId)}
            />
          );
        },
        accessor: 'oqu_id_orgqualunit',
        className: 'check-box',
      },
      {
        Header: 'Unit Code',
        accessor: 'orgUnit.unit.tun_code',
        className: 'unit-code',
      },
      {
        Header: 'Unit Name',
        accessor: 'orgUnit.unit.tun_title',
        className: 'unit-name',
      },
      {
        Header: 'Teacher Name',
        Cell: ({value}) => (
          <AddTeacherDropdown
            unitTeachers={unitTeachers}
            onAddTeacher={(teacherId, fname, lname) => {
              setOrgUnit(state => {
                const newOrgUnit = {...state};
                newOrgUnit[value] = [
                  ...newOrgUnit[value],
                  {teacherId, fname, lname},
                ];
                return newOrgUnit;
              });
            }}
            orgUnit={orgUnit}
            onRemoveTeacher={teacherId => {
              setOrgUnit(state => {
                const newOrgUnit = {...state};
                newOrgUnit[value] = newOrgUnit[value].filter(
                  item => item.teacherId !== teacherId,
                );
                return newOrgUnit;
              });
            }}
            isChecked={Object.keys(orgUnit).includes(value)}
            orgUnitId={value}
          />
        ),
        accessor: 'oqu_id_orgunit',
        className: 'teachers',
      },
      {
        Header: 'Unit Type',
        Cell: ({value}) => (
          <div className="type-container">
            <div className={value}>{value.toUpperCase()}</div>
          </div>
        ),
        accessor: 'unitType',
        className: 'essentials',
      },
    ],
    [orgUnit, unitTeachers],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(manageUnits || []).map((item, index) => ({
        ...item,
        serial: index + 1,
        unitType: item?.oqu_is_essential === 1 ? 'core' : 'elective',
      })),
    ],
    [manageUnits],
  );

  useEffect(() => {
    dispatch(getAllUnitsTeachers());
    dispatch(getUnitsByType(orgClassId));
  }, [dispatch, orgClassId]);

  return (
    <>
      <Helmet>
        <title>Manage Units | JungleCat</title>
      </Helmet>
      <ManageUnitHeader orgClassId={orgClassId} />
      <Spacer height="6rem" />
      <UnitsContainer>
        <h2 className="class-name">Manage Units of {singleClass?.oc_class}</h2>
        <p className="qualification-name">
          {singleClass?.qualification?.tqual_title}
        </p>
        <p className="unit-list">unit list</p>
        <div className="table-container">
          {isClassesLoading ? <TableSpinner /> : null}
          {!isClassesLoading &&
          Array.isArray(manageUnits) &&
          !manageUnits?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Units Found"
            />
          ) : null}
          {manageUnits?.length &&
          Array.isArray(manageUnits) &&
          !isClassesLoading ? (
            <Table
              columns={columns}
              data={data}
              itemName="Classes"
              maxWidth="75rem"
              isSortedBy
              defaultSorted={[
                {
                  id: 'unitType',
                  desc: false,
                },
              ]}
              filterTabs={[
                {
                  column: 'unitType',
                  itemName: 'All',
                },
                {
                  column: 'unitType',
                  itemName: 'Core',
                },
                {
                  column: 'unitType',
                  itemName: 'Elective',
                },
              ]}
            />
          ) : null}
        </div>
        <Spacer height="6rem" />
      </UnitsContainer>
      <Spacer height="4rem" />
      <ManageUnitFooter
        orgUnit={orgUnit}
        addUnits={addUnits}
        removeUnits={removeUnits}
        isSingleClassLoading={isSingleClassLoading}
      />
    </>
  );
};
