import {createSlice} from '@reduxjs/toolkit';
import requestCode from '../../api/authOld/requestCode';

export const requestCodeSlice = createSlice({
  name: 'requestCode',
  initialState: {
    isSubmitting: false,
    errorMessage: '',
    isCodeSubmitted: false,
  },
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setCodeSubmitted: (state, action) => {
      state.isCodeSubmitted = action.payload;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
  },
});

export const {setCodeSubmitted, setErrorMessage, setUserData, setSubmitting} =
  requestCodeSlice.actions;

// sends a req to auth/forgot-password/recovery-email
export const sendRequestCode = data => async dispatch => {
  try {
    dispatch(setSubmitting(true));

    await requestCode(data);

    dispatch(setSubmitting(false));
    dispatch(setCodeSubmitted(true));
    dispatch(setErrorMessage(''));
  } catch (err) {
    dispatch(
      setErrorMessage('Failed submitting.please try again after sometime.'),
    );
  }
};

// state access fn's
export const requestCodeSelector = state => state.requestCode;

export default requestCodeSlice.reducer;
