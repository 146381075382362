/* eslint-disable react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import TimelineArrowIcon from '../../../../../../../assets/icons/timeline-arrow.svg';
import {Color} from '../../../../../../../constants';
import Schedule from './Schedule';
import Session from './Session';

const TimelineContainer = styled.div`
  border-bottom: 0.0625rem solid #e0e0e0;
  padding-bottom: 0.375rem;

  .timeline-date {
    color: ${Color.PRIMARY_TEXT};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .timeline-button {
    background-color: transparent;
    border: none;
    height: 1.25rem;
    width: 1.25rem;

    :focus {
      outline: none;
    }

    img {
      object-fit: contain;
      vertical-align: unset;
    }

    .rotated-image {
      transform: rotate(180deg);
    }
  }
`;

const Timeline = ({timelines, initialIndex, liveSessionPreference}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0);

  useEffect(() => {
    if (initialIndex) setCurrentIndex(initialIndex);
  }, [initialIndex]);

  // Decrement the index to show the schedule of the previous day.
  const decrementCurrentIndex = useCallback(previousIndex => {
    if (previousIndex > 0) {
      return previousIndex - 1;
    }

    return previousIndex;
  }, []);

  // Increment the index to show the schedule for the next day.
  const incrementCurrentIndex = useCallback(
    previousIndex => {
      if (previousIndex < timelines.length - 1) {
        return previousIndex + 1;
      }

      return previousIndex;
    },
    [timelines],
  );

  // Cache the current timeline and the formatted dates.
  const timeline = timelines[currentIndex];
  const timelineDate = useMemo(
    () => (timeline ? moment(timeline.date).format('D MMM.') : null),
    [timeline],
  );
  const todayDate = useMemo(() => moment().format('D MMM.'), []);

  if (!timelines?.length) return null;

  return (
    <TimelineContainer className="pt-3">
      <div className="align-items-center d-flex justify-content-between mb-2 px-3">
        <h4 className="mb-0 timeline-date">
          {timelineDate === todayDate ? 'Today - ' : ''}
          {timelineDate}
        </h4>
        <div className="d-flex">
          <button
            className="timeline-button"
            onClick={() => {
              setCurrentIndex(incrementCurrentIndex);
              setCurrentSessionIndex(0);
            }}
            type="button"
          >
            <img alt="Previous Date" src={TimelineArrowIcon} />
          </button>
          <button
            className="ml-1 timeline-button"
            onClick={() => {
              setCurrentIndex(decrementCurrentIndex);
              setCurrentSessionIndex(0);
            }}
            type="button"
          >
            <img
              alt="Next Date"
              className="rotated-image"
              src={TimelineArrowIcon}
            />
          </button>
        </div>
      </div>
      <div className="px-3">
        <Schedule
          currentSessionIndex={currentSessionIndex}
          isToday={timelineDate === todayDate}
          sessions={timeline?.sessions}
        />
      </div>
      <div className="px-2">
        {timeline?.sessions?.map((session, index) => (
          <Session
            index={index}
            isActive={index === currentSessionIndex}
            onActivate={setCurrentSessionIndex}
            key={session?.id}
            session={session}
            selectedTimeLine={timeline}
            liveSessionPreference={liveSessionPreference}
          />
        ))}
      </div>
    </TimelineContainer>
  );
};

Timeline.defaultProps = {
  timelines: [],
  initialIndex: 0,
};

Timeline.propTypes = {
  timelines: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      sessions: PropTypes.arrayOf(
        PropTypes.shape({
          from: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  initialIndex: PropTypes.number,
};

export default Timeline;
