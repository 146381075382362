import {createSlice} from '@reduxjs/toolkit';

import * as coursesApi from '../api/admin/courses';
import * as unitsApi from '../api/admin/units';

const initialFormError = {courseName: ''};

const coursesSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: false,
    isLoading: false,
    courses: null,
    isAddCourseLoading: false,
    allQualifications: null,
    allUnits: null,
    courseUnits: null,
    allCourseType: null,
    formError: initialFormError,
    coursesClasses: null,
  },
  name: 'courses',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setIsAddCourseLoading(state, action) {
      state.isAddCourseLoading = action.payload;
    },
    setAllQualifications: (state, action) => {
      state.allQualifications = action.payload;
    },
    setAllUnits: (state, action) => {
      state.allUnits = action.payload;
    },
    setAllCourseType: (state, action) => {
      state.allCourseType = action.payload;
    },
    removeCourseById(state, action) {
      state.courses = state.courses.filter(
        item => item.ocr_id_orgcourse !== action.payload,
      );
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setCourseClasses(state, action) {
      state.coursesClasses = action.payload;
    },
    setCourseUnits: (state, action) => {
      state.courseUnits = action.payload;
    },
    editCourse(state, action) {
      const courseIndex = state.courses.findIndex(
        item => item.ocr_id_orgcourse === action.payload.ocr_id_orgcourse,
      );

      state.courses[courseIndex] = action.payload;
    },
    removeClassById(state, action) {
      state.coursesClasses = state.coursesClasses.filter(
        item => item.oc_id_orgclass !== action.payload,
      );
    },
  },
});

export const {
  setError,
  setIsListLoading,
  setIsLoading,
  setCourses,
  setIsAddCourseLoading,
  setAllQualifications,
  setAllUnits,
  setAllCourseType,
  removeCourseById,
  setFormError,
  clearFormError,
  setCourseClasses,
  removeClassById,
  setCourseUnits,
  editCourse,
} = coursesSlice.actions;

export const getAllCourses = () => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await coursesApi.fetchAllCourses();

    dispatch(setCourses(response.data.allCourses));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const getDataForAddCourse = () => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const [filterQualificationsResponse, courseTypeResponse] =
      await Promise.all([
        unitsApi.fetchAllFilterQualifications(),
        coursesApi.fetchAllCourseType(),
      ]);
    dispatch(
      setAllQualifications(
        filterQualificationsResponse.data.allQualificationsList || [],
      ),
    );
    dispatch(setAllCourseType(courseTypeResponse.data.allCourseType || []));
    dispatch(setAllUnits([]));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getUnitByCourseId = courseId => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await coursesApi.fetchAllUnitByCourseId(courseId);

    dispatch(setCourseUnits(response?.data?.oneCourseUnits || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getUnitByQualificationId = qualificationId => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await coursesApi.fetchAllUnitByQualificationId(
      qualificationId,
    );
    dispatch(
      setAllUnits(
        response?.data?.oneQualificationUnits[0].orgQualificationUnits || [],
      ),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const addCourse = (courseData, addUnits, callback) => async dispatch => {
  dispatch(setIsAddCourseLoading(true));
  try {
    const courseDetails = {
      courseData,
      Units: addUnits,
    };
    const resp = await coursesApi.addCourse(courseDetails);

    if (resp) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
    dispatch(setFormError(error?.response?.data?.error));
  } finally {
    dispatch(setIsAddCourseLoading(false));
  }
};

export const archiveClasses = (orgCourseId, callback) => async dispatch => {
  dispatch(setIsAddCourseLoading(true));

  try {
    const resp = await coursesApi.archiveClasses(orgCourseId);
    dispatch(editCourse(resp.data.course));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddCourseLoading(false));
  }
};
export const deleteCourse = (orgCourseId, callback) => async dispatch => {
  dispatch(setIsAddCourseLoading(true));

  try {
    await coursesApi.deleteCourse(orgCourseId);
    dispatch(removeCourseById(orgCourseId));

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddCourseLoading(false));
  }
};
// classes list for course
export const getClassesByCourseId = orgCourseId => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const response = await coursesApi.fetchClassesByCourseId(orgCourseId);

    dispatch(setCourseClasses(response.data.allClasses));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const archiveClassById = (orgClassId, callback) => async dispatch => {
  dispatch(setIsAddCourseLoading(true));

  try {
    await coursesApi.archiveClasseById(orgClassId);
    dispatch(removeClassById(orgClassId));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddCourseLoading(false));
  }
};
export const updateCourse =
  (courseId, data, addUnits, callback) => async dispatch => {
    dispatch(setIsAddCourseLoading(true));
    try {
      const courseDetails = {
        courseData: data,
        Units: addUnits,
      };
      const resp = await coursesApi.updateCourse(courseId, courseDetails);

      dispatch(editCourse(resp.data.course));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddCourseLoading(false));
    }
  };

export const coursesSelector = state => state.courses;

export default coursesSlice.reducer;
