/* eslint-disable react/prop-types */
import {useMemo} from 'react';

import {Button} from '../../../../../../../../components/common';
import {AttendanceFooterContainer} from './elements';

export const AttendanceFooter = ({
  isLoading,
  onSubmit,
  onDraft,
  sessionDetails,
  studentsList,
  attendance,
}) => {
  const absentCount = useMemo(
    () =>
      Object?.keys(attendance)?.filter(item => attendance[item] === 3) || [],
    [attendance],
  );
  const presentCount = useMemo(
    () =>
      Object?.keys(attendance)?.filter(item => attendance[item] === 2) || [],
    [attendance],
  );
  const markedCount = useMemo(
    () =>
      Object?.keys(attendance)?.filter(
        item => attendance[item] === 2 || attendance[item] === 3,
      ) || [],
    [attendance],
  );

  return (
    <AttendanceFooterContainer>
      <div className="footer-container fixed-bottom">
        <div className="footer">
          <div className="left-side">
            <div className="marked">
              {markedCount?.length}/{studentsList?.length} Marked
            </div>
            <div className="absent">{absentCount?.length} Absent</div>
            <div className="present">{presentCount?.length} Present</div>
          </div>
          <div className="button-container">
            {sessionDetails?.oruil_attendance_status === 0 ? (
              <>
                <Button
                  className="button cancel-button mr-3"
                  isFullWidth={false}
                  isDisabled={!markedCount.length}
                  onClick={() => {
                    if (markedCount?.length) {
                      onDraft();
                    }
                  }}
                  label="Save as Draft"
                />
                <Button
                  className="button"
                  isFullWidth={false}
                  isLoading={isLoading}
                  isDisabled={!markedCount.length}
                  label="Submit Attendance"
                  onClick={() => {
                    if (markedCount?.length) {
                      onSubmit();
                    }
                  }}
                />
              </>
            ) : (
              <div className="attendance-submission-badge">
                <span>Attendance Submitted</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </AttendanceFooterContainer>
  );
};
