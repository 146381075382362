/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import {UploadContainer} from './elements';
import {TextInput} from '../../../../../../../../../../components/common';
import RemoveFileIcon from '../../../../../../../../../../assets/icons/delete-logo.svg';
import FilesImg from '../../../../../../../../../../assets/icons/file-submission-upload.svg';
import {FileCard} from './components';

export const UploadSection = ({
  setUpload,
  upload,
  onDrag,
  setOnDrag,
  setFileName,
  accept,
}) => {
  const [fileNewName, setFileNewName] = useState('');
  const onFileChange = e => {
    setUpload(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setFileNewName(e.target.files[0].name);
  };

  return (
    <UploadContainer>
      {upload ? (
        <div className="full-container">
          <div className="org-image-full">
            <div className="org-image-after">
              <FileCard file={upload} />
            </div>
            <div className="delete-logo">
              <button
                type="button"
                onClick={() => {
                  setUpload(null);
                }}
              >
                <img src={RemoveFileIcon} alt="logo" />
              </button>
            </div>
          </div>
          <TextInput
            label="Edit File Name"
            className="mt-3"
            autoComplete="off"
            type="text"
            maxLength="50"
            id="fileName"
            value={fileNewName}
            onChange={e => {
              setFileNewName(e?.target?.value);
              setFileName(e?.target?.value);
            }}
          />
        </div>
      ) : (
        <div
          onDragEnter={() => {
            setOnDrag(true);
          }}
          onDragLeave={() => {
            setOnDrag(false);
          }}
        >
          <div className={`org-image ${onDrag ? 'logo-drag' : ''}`}>
            <div className="org-logo">
              <img src={FilesImg} className="logo" alt="logo" />
            </div>
            <div className="org-text">
              Drop your file here or{' '}
              <input
                type="file"
                onChange={e => {
                  onFileChange(e);
                }}
                accept={accept}
              />
              <span className="box__dragndrop">Browse</span>
            </div>
            <span className="supports">Supports : MP4, DOC, JPG, PNG</span>
          </div>
        </div>
      )}
    </UploadContainer>
  );
};
