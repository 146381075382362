/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import addedTextInstruction from '../../../../../../assets/icons/added-text-instruction.svg';
import addedVoiceInstruction from '../../../../../../assets/icons/added-voice-instruction.svg';
import documentInstruction from '../../../../../../assets/icons/document-instruction.svg';
import ellipsis from '../../../../../../assets/icons/ellipsis.svg';
import closeIcon from '../../../../../../assets/icons/instruction-modal-close.svg';
import playVideoIcon from '../../../../../../assets/icons/play-video-instruction.svg';
import CustomModal from '../../../../../../components/shared/CustomModal';
import CustomBox from '../../../../../../components/shared/CustomBox';
import DropdownItems from '../../../../../../components/shared/DropdownItems/DropdownItems';
import DropdownWrapper from '../../../../../../components/shared/DropdownWrapper/DropdownWrapper';
import {Dropdown} from '../../../../elements/Dropdown';

function calculateTimeDuration(_seconds) {
  let hours = Math.floor(_seconds / 3600);
  let minutes = Math.floor((_seconds - hours * 3600) / 60);
  let seconds = Math.floor(_seconds - hours * 3600 - minutes * 60);

  if (minutes < 10) minutes = `0${minutes}`;

  if (seconds < 10) seconds = `0${seconds}`;

  if (hours === 0) return `${minutes}:${seconds} Min`;

  if (hours < 10) hours = `0${hours}`;

  return `${hours}:${minutes} Hrs`;
}

const convertFileSize = size => {
  const fileSizeKb = size / 1024;
  if (fileSizeKb > 1024) {
    const fileSizeMb = fileSizeKb / 1024;
    return `${fileSizeMb.toFixed(2)} Mb`;
  }

  return `${fileSizeKb.toFixed(2)} Kb`;
};

const DisplayModal = ({onClose, showData}) => (
  <CustomModal isVisible>
    <div
      className={`display-modal-container ${
        showData.type === 'document' ? 'for-document' : ''
      }`}
    >
      <CustomBox padding="1.5rem">
        <div className="display-modal-header">
          <button
            className="modal-close-button"
            onClick={onClose}
            type="button"
          >
            <img alt="Close Modal" src={closeIcon} />
          </button>
        </div>
        {showData.type === 'document' ? (
          <embed className="instruction-document" src={showData.media} />
        ) : (
          <div className="instruction-image">
            <img alt="Instruction" src={showData.media} />
          </div>
        )}
      </CustomBox>
    </div>
  </CustomModal>
);

const Instruction = ({data, onDelete, onShowClick}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [menuOpen, toggleMenu] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (data.media) {
      setSource(
        typeof data.media === 'string'
          ? data.media
          : URL.createObjectURL(data.media),
      );
    }
  }, [data.media]);

  let element = null;
  let instructionIcon;
  let subtitle;
  let title;

  if (data.type === 'audio') {
    instructionIcon = addedVoiceInstruction;
    subtitle = `• ${calculateTimeDuration(data.duration)}`;
    title = 'Voice';
  } else if (data.type === 'document') {
    instructionIcon = documentInstruction;
    subtitle = `• ${convertFileSize(data.duration)}`;
    title = 'Document';
  } else if (data.type === 'image') {
    instructionIcon = source;
    subtitle = `• ${convertFileSize(data.duration)}`;
    title = 'Image';
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
      </button>
    );
  } else if (data.type === 'text') {
    instructionIcon = addedTextInstruction;
    subtitle = 'Instruction';
    title = 'Text';
  } else if (data.type === 'video') {
    instructionIcon = data.thumbnail;
    subtitle = `• ${calculateTimeDuration(data.duration)}`;
    title = 'Video';
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
        <div className="play-video-icon">
          <img alt="Play Video" src={playVideoIcon} />
        </div>
      </button>
    );
  }

  if (['audio', 'document', 'text'].includes(data.type)) {
    element = (
      <button onClick={onShowClick} type="button">
        <img
          alt="Instruction"
          className="instruction-icon"
          src={instructionIcon}
        />
      </button>
    );
  }

  return (
    <div className="added-instruction">
      {element}
      <div className="instruction-content">
        <span>
          {title} {subtitle}
        </span>
        <p>
          {data?.text?.length > 128 && !isExpanded
            ? `${data?.text?.slice(0, 128)}...`
            : data?.text}{' '}
          {data?.text?.length > 128 ? (
            !isExpanded ? (
              <button onClick={() => setIsExpanded(true)} type="button">
                See more
              </button>
            ) : (
              <button onClick={() => setIsExpanded(false)} type="button">
                See less
              </button>
            )
          ) : null}
        </p>
      </div>
      {onDelete ? (
        <DropdownWrapper toggleMenu={toggleMenu}>
          <button
            type="submit"
            className="instruction-options"
            onClick={event => {
              event.preventDefault();
              toggleMenu(!menuOpen);
            }}
          >
            <img src={ellipsis} alt="ellipsis" />
          </button>
          {menuOpen && (
            <Dropdown right="1.9rem">
              <DropdownItems
                isDanger
                withoutSeperator
                text="Delete"
                click={onDelete}
              />
            </Dropdown>
          )}
        </DropdownWrapper>
      ) : null}
    </div>
  );
};

export {DisplayModal, Instruction};
