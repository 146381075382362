import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {Select} from '../../../../../../../../components/common';

import DuplicateTasModalContainer from './elements';
import DuplicateTasIcon from '../../../../../../../../assets/icons/duplicate-tas.svg';

export const DuplicateTasModal = ({
  duplicateDetails,
  onHide,
  onSubmit,
  classDropdownData,
  classError,
  isDuplicateLoading,
}) => {
  const {
    control,
    // formState: {errors},
    handleSubmit,
    // register,
    watch,
  } = useForm({
    defaultValues: {
      className: null,
    },
  });

  const classesOptions = useMemo(
    () => [
      ...(classDropdownData || [])?.map(item => ({
        label: item?.oc_class,
        value: item?.oc_id_orgclass,
      })),
    ],
    [classDropdownData],
  );

  return (
    <DuplicateTasModalContainer
      buttonLabel="Duplicate"
      description={`You  want to rename this  ${duplicateDetails?.className}  ?`}
      isButtonLoading={isDuplicateLoading}
      loadingButtonLabel="Duplicating..."
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!duplicateDetails}
      title={`Duplicate ${duplicateDetails?.className}`}
      width="41.25rem"
      isIcon
      icon={DuplicateTasIcon}
      isDisabled={!watch('className')}
    >
      <div className="divider" />
      <form className="form-main-container">
        <div className="form-container px-4">
          <div className="row-container ">
            <div className="field-right">
              <Controller
                control={control}
                name="className"
                render={fields => (
                  <Select
                    {...fields}
                    className="mb-4"
                    // hasError={!!errors?.className}
                    isLarge={false}
                    label="Choose Class for this TAS"
                    options={classesOptions}
                    placeholder="Select the class name"
                    // ref={register({require: true})}
                    // errorMessage={
                    //   errors?.className ? 'Title is required' : null
                    // }
                  />
                )}
              />
            </div>
          </div>
          <div className="error">{classError}</div>
        </div>
      </form>
    </DuplicateTasModalContainer>
  );
};

DuplicateTasModal.defaultProps = {
  duplicateDetails: null,
  classDropdownData: [],
  classError: null,
  isDuplicateLoading: false,
};

DuplicateTasModal.propTypes = {
  duplicateDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
  }),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classDropdownData: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  classError: PropTypes.any,
  isDuplicateLoading: PropTypes.bool,
};
