import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AllStudentsIcon from '../../../../../../../../../../assets/icons/all-students.svg';
import CheckedIcon from '../../../../../../../../../../assets/icons/checked-blue.svg';
import ProfileIcon from '../../../../../../../../../../assets/icons/profile.svg';
import UncheckedIcon from '../../../../../../../../../../assets/icons/unselected_checkbox.svg';
import {StudentDropdownItemContainer} from './elements';

export const StudentsDropdownItem = ({
  isChecked,
  isForAllStudents,
  isLastItem,
  name,
  onClick,
  referenceId,
}) => (
  <StudentDropdownItemContainer
    className={classNames('students-dropdown-item', {'is-last': isLastItem})}
    onClick={onClick}
    type="button"
    id={`student-${referenceId}`}
  >
    <div className="students-dropdown-details">
      <img
        alt="All Students"
        className="all-students-image"
        src={isForAllStudents ? AllStudentsIcon : ProfileIcon}
      />
      <div>
        <div className="student-name">{name}</div>
        <p className="reference-id">{referenceId}</p>
      </div>
    </div>
    <div
      className={`students-dropdown-checkbox ${isChecked ? 'is-checked' : ''}`}
    >
      {isChecked ? (
        <img
          alt="Checked"
          className="checkbox-icon"
          src={isChecked ? CheckedIcon : UncheckedIcon}
        />
      ) : null}
    </div>
  </StudentDropdownItemContainer>
);

StudentsDropdownItem.defaultProps = {
  isForAllStudents: false,
  isLastItem: false,
};

StudentsDropdownItem.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isForAllStudents: PropTypes.bool,
  isLastItem: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  referenceId: PropTypes.string.isRequired,
};
