/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {classesSelector} from '../../../../../../../../../../redux/classesSlice';
import {
  Table,
  EmptyState,
} from '../../../../../../../../../../components/common';

import {TableSpinner} from '../../../../../../../../components-new';

import {TeachersContainer} from './elements';

export const Teachers = ({classTeachers}) => {
  //   const history = useHistory();
  const {isSingleClassLoading} = useSelector(classesSelector);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Id',
        accessor: 'userProfile.up_org_reference',
        className: 'ref-id',
      },
      {
        Header: 'First Name',
        accessor: 'userProfile.up_name_first',
        className: 'first-name',
      },
      {
        Header: 'Last Name',
        accessor: 'userProfile.up_name_last',
        className: 'last-name',
      },
      {
        Header: 'Email',
        accessor: 'userProfile.up_email',
        className: 'email',
      },
      {
        Header: 'Active in units',
        Cell: ({value}) => {
          const teachers = value;
          return (
            <div
              className="invited"
              data-html
              data-for="tooltip"
              data-tip={`<div className="tooltip-values"><p className="heading">Teacher active in units : </p>
                 ${teachers
                   ?.map(
                     item =>
                       `<li>${item?.orgUnitInstance?.orgUnit?.unit?.tun_title}</li>`,
                   )
                   .join('')}
                </div>`}
            >
              {value?.length}
              <ReactTooltip
                backgroundColor="#3d4457"
                effect="solid"
                place="right"
                id="tooltip"
              />
            </div>
          );
        },
        accessor: 'userProfile.teacherUnits',
        className: 'units-count',
      },
    ],
    [],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(classTeachers || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [classTeachers],
  );

  return (
    <TeachersContainer>
      <div className="table-container">
        {isSingleClassLoading ? <TableSpinner /> : null}
        {!isSingleClassLoading &&
        Array.isArray(classTeachers) &&
        !classTeachers?.length ? (
          <EmptyState
            description="try adjusting the search or filter for what you are looking for"
            title="No Teachers Found"
          />
        ) : null}

        {!isSingleClassLoading && classTeachers?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Teachers"
            maxWidth="75rem"
            isSortedBy
          />
        ) : null}
      </div>
    </TeachersContainer>
  );
};
