import styled from 'styled-components';

export const AddAssessmentContainer = styled.div`
  margin: 0px -15px;
  background-color: ${({theme}) => theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AssessmentFormContainer = styled.form`
  margin: 0px -15px;
  background-color: ${({theme}) => theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
