import styled from 'styled-components';

export const CheckboxContainer = styled.button`
  background-color: transparent;
  border: none;

  :active,
  :focus {
    outline: none;

    .icon-container {
      border-color: ${({theme}) => theme.colors.primary} !important;
      box-shadow: 0 0 0 0.125rem ${({theme}) => theme.colors.lightPrimary} !important;
    }
  }

  .icon-container {
    border: 0.0625rem solid ${({theme}) => theme.colors.border};
    border-radius: 0.25rem;
    cursor: pointer;
    height: 1.25rem;
    overflow: hidden;
    transition: border-color 0.1s, box-shadow 0.1s;
    width: 1.25rem;

    &.has-error {
      border-color: ${({theme}) => theme.colors.danger};
    }

    &.is-checked {
      background-color: ${({theme}) => theme.colors.primary};
      border-color: ${({theme}) => theme.colors.primary};

      .icon {
        opacity: 1;
      }
    }

    .icon {
      height: 100%;
      opacity: 0;
      transition: opacity 0.1s;
      width: 100%;
    }
  }

  .label {
    color: ${({theme}) => theme.colors.text};
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;
