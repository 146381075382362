import instance from '..';

export const getSearchAssessment = (classId, title) =>
  instance.get(`api/class/${classId}/assessments?title=${title}`);

export const createAssessmentByTitle = (classId, data) =>
  instance.post(`api/class/${classId}/assessment/create`, data);

export const deleteAssessment = aouiID =>
  instance.delete(`api/class/${aouiID}/assessment/delete`);

export const updateBasicAssessment = (activityId, data) =>
  instance.put(`api/class/${activityId}/assessment/update`, data);

export const createTaskAssessment = (activityId, data) =>
  instance.post(`api/class/${activityId}/assessment/addTask`, data);

export const addFilesAssessment = (activityId, data) =>
  instance.post(`api/class/${activityId}/assessment/addFiles`, data);

export const publishActivityItems = activityItems =>
  instance.put('api/class/assessment/publishActivityItems', {activityItems});

export const getAssessmentDatas = (activityId, classId) =>
  instance.get(`api/class/${classId}/assessment/${activityId}/getDetails`);

export const getAssessmentSummaryDetails = activityId =>
  instance.get(`api/class/${activityId}/assessment/getSummaryDetails`);

export const getAllAssessmentDatas = (classId, offset) =>
  instance.get(
    `api/class/${classId}/assessments/getAllAssessments?page=${offset}`,
  );

export const deleteAssessmentTask = activityItemId =>
  instance.delete(`api/class/${activityItemId}/assessment/deleteTask`);

export const deleteAssessmentFile = fileId =>
  instance.delete(`api/class/${fileId}/assessment/deleteFile`);

export const listAssessmentStudents = (unitId, activityOrgUnitInstanceId) =>
  instance.get(
    `api/class/${unitId}/assessment/listStudents?activityOrgUnitInstanceId=${activityOrgUnitInstanceId}`,
  );

export const assessmentAddAllStudents = (unitId, activityOrgUnitInstanceId) =>
  instance.post(
    `api/class/${unitId}/assessment/addAllStudents?activityOrgUnitInstanceId=${activityOrgUnitInstanceId}`,
  );

export const assessmentAddStudent = (activityOrgUnitInstanceId, data) =>
  instance.post(
    `api/class/${activityOrgUnitInstanceId}/assessment/addStudent`,
    data,
  );

export const assessmentRemoveStudent = orgunitinstancestudentId =>
  instance.put(
    `api/class/${orgunitinstancestudentId}/assessment/removeStudent`,
  );

export const assessmentAddStudents = (activityOrgUnitInstanceId, data) =>
  instance.post(
    `api/class/${activityOrgUnitInstanceId}/assessment/addStudents`,
    data,
  );

export const assessmentRemoveStudents = data =>
  instance.put(`api/class/assessment/removeStudents`, data);

export const assessmentRemoveAllStudent = activityOrgUnitInstanceId =>
  instance.put(
    `api/class/${activityOrgUnitInstanceId}/assessment/removeAllStudents`,
  );

export const assessmentPublish = (activityId, classId) =>
  instance.put(`api/class/${classId}/assessment/${activityId}/publish`);

export const assessmentDuplicatedData = (classId, activityId) =>
  instance.post(
    `api/class/${classId}/assessment/${activityId}/duplicateAssessment`,
  );

export const myAssessmentToclass = (classId, activityId) =>
  instance.post(
    `api/class/${classId}/assessment/${activityId}/myAssessementAddToClass`,
  );

export const evaluateTask = (taskID, studentID, data) =>
  instance.post(`api/class/${taskID}/activityItem/${studentID}/evaluate`, data);

export const updateTaskEvaluation = (taskId, studentId, data) =>
  instance.put(
    `api/class/${taskId}/activityItem/${studentId}/updateEvaluation`,
    data,
  );

export const evaluateAssessment = (assessmentID, studentID, data) =>
  instance.put(
    `api/class/${assessmentID}/assessment/${studentID}/evaluate`,
    data,
  );

export const reassignActivity = (aouisID, comments) =>
  instance.put(`api/class/${aouisID}/reassign`, {
    comments,
  });

export const revokeStudentFromAssessment = aouisID =>
  instance.put(`api/class/${aouisID}/revoke`);

export const acceptResubmissionRequest = (aouisID, resubmissionDetails) =>
  instance.put(
    `api/class/${aouisID}/acceptResubmissionRequest`,
    resubmissionDetails,
  );

export const declineResubmissionRequest = (aouisID, resubmissionDetails) =>
  instance.put(
    `api/class/${aouisID}/declineResubmissionRequest`,
    resubmissionDetails,
  );

export const sendReminderToStudent = aouisID =>
  instance.put(`api/class/${aouisID}/remind`);

export const getAssessmentTasksOfStudent = (assessmentID, studentID) =>
  instance.get(`api/class/${assessmentID}/assessment/${studentID}/tasks`);

export const addTextInstruction = (activityID, taskID, textInstruction) =>
  instance.post(
    `api/class/${activityID}/${taskID}/assessment/addTextInstruction`,
    {
      textInstruction,
    },
  );

export const addFileInstruction = (activityID, taskID, body) =>
  instance.post(
    `api/class/${activityID}/${taskID}/assessment/addFileInstruction`,
    body,
  );

export const getDraftTask = activityID =>
  instance.get(`api/class/${activityID}/assessment/getDraftTask`);

export const updateTask = (taskID, data) =>
  instance.put(`api/class/${taskID}/assessment/updateTask`, data);

export const updateInstruction = (fileID, data) =>
  instance.put(`api/class/${fileID}/assessment/updateInstruction`, data);

export const removeInstruction = fileID =>
  instance.delete(`api/class/${fileID}/assessment/removeInstruction`);

export const removeAllInstructions = taskID =>
  instance.delete(`api/class/${taskID}/assessment/removeAllInstructions`);

export const fetchAllActivityMethods = () =>
  instance.get(`api/class/assessment/activity-method`);

export const fetchAllEvaluationType = () =>
  instance.get(`api/class/assessment/evaluation-type`);

export const getTasksLinkedToFiles = (fileIds, assessmentId, studentId) =>
  instance.get(
    `api/class/files/linked-tasks?fileIds=${fileIds}&assessmentId=${assessmentId}&studentId=${studentId}`,
  );

export const getUnit = unitId => instance.get(`/api/class/units/${unitId}`);

export const getPerformanceCriteria = unitId =>
  instance.get(`/api/class/${unitId}/assessment-map`);

export const getPerformanceEvidence = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/performance-evidence`);

export const getKnowledgeEvidence = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/knowledge-evidence`);

export const getAssessmentCondition = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/assessment-condition`);
