/* eslint-disable react/prop-types */
import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {qualificationSchema} from '../../../../../../../../utils/validation';
import 'react-datepicker/dist/react-datepicker.css';

import {
  individualQualificationCheck,
  addNewOrgQualification,
  qualificationsSelector,
} from '../../../../../../../../redux/qualificationsSlice';

import {
  FullContainer,
  AddQualificationUnitContainer,
  SpreadSheetTableStyles,
  SpreadSheetUnitTableStyles,
} from './elements';
import {
  AddQualificationFooter,
  AddQualificationHeader,
  ManualFormForQualification,
  UploadSection,
  Accordion,
  AccordionForUnits,
  UploadSectionUnits,
  ManualFormForUnits,
} from './components';

import {
  ValidateLoader,
  SuccessMessage,
  NoUserAdded,
} from '../../../../components';

import CustomBox from '../../../../../../../../components/shared/CustomBox';

import {fetchBasicInfo} from '../../../../../../../../redux/adminTeacherSlice';
import {Table} from '../../../../../../../../components/common';

export const AddQualificationsAndUnit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    currentQualification,
    alreadyExistQname,
    alreadyExistQcode,
    validQualification,
    alreadyExistUcode,
    duplicateData,
    duplicateUnitData,
    validUnitsToQualification,
  } = useSelector(qualificationsSelector);

  const [steps, setSteps] = useState(0);
  const [stepsForQualification, setStepsForQualification] = useState(0);
  const [upload, setUpload] = useState(null);
  const [uploadQualification, setUploadQualification] = useState(null);
  const [spreadSheet, setSpreadSheet] = useState([]);
  const [spreadSheetQualification, setSpreadSheetQualification] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isQuaValidating, setIsQuaValidating] = useState(false);
  const [fileImportQua, setImportQua] = useState(true);
  const [fileImport, setImport] = useState(true);
  const [nextShow, setNextShow] = useState(false);
  const [nextShowQua, setNextShowQua] = useState(false);
  const [manual, setManual] = useState(false);
  const [manualQua, setManualQua] = useState(false);
  const [formShow, setFormShow] = useState(true);
  const [btnShow, setBtnShow] = useState(true);
  const [fileName, setFileName] = useState('');
  const [addUnits, setAddUnits] = useState([]);
  const [onDrag, setOnDrag] = useState(false);

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      qualificationCode: '',
      qualificationName: '',
    },
    resolver: yupResolver(qualificationSchema),
  });

  const onSubmit = useCallback(
    data => {
      const quaDetail = {
        quCode: data.qualificationCode,
        quName: data.qualificationName,
      };
      dispatch(
        individualQualificationCheck(quaDetail, () => {
          dispatch(
            addNewOrgQualification(quaDetail, () => {
              setSteps(1);
            }),
          );
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    dispatch(fetchBasicInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Spreadsheet flow mapping ,existing id checking and error mapping

  const qualificationsSpreadColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Qualification Code',
        accessor: 'qualification_code',
        className: 'qualification-code',
      },
      {
        Header: 'Qualification Name',
        accessor: 'qualification_name',
        className: 'qualification-name',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history],
  );

  // Add a serial number to each row and memoize the data.
  // spreadsheet map
  const spreadsheetQualificationdata = useMemo(
    () => [
      ...spreadSheetQualification.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [spreadSheetQualification],
  );

  // Error map
  const alreadyExistQnameData = useMemo(
    () => [
      ...alreadyExistQname.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [alreadyExistQname],
  );
  const duplicateDataError = useMemo(
    () => [
      ...duplicateData.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [duplicateData],
  );

  const alreadyExistQcodeData = useMemo(
    () => [
      ...alreadyExistQcode.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [alreadyExistQcode],
  );

  const unitsColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'UNIT CODE',
        accessor: 'unit_code',
        className: 'unit-code',
      },
      {
        Header: 'UNIT NAME',
        accessor: 'unit_name',
        className: 'unit-name',
      },
      {
        Header: 'UNIT TYPE',
        Cell: ({row: {original}}) => {
          const unitTypeInSpread = original?.unit_type;
          return unitTypeInSpread?.toLowerCase() === 'core' ? (
            <div className="type-container">
              <div className="core">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          ) : (
            <div className="type-container">
              <div className="elective">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          );
        },
        accessor: 'unit_type',
        className: 'unit-types',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history],
  );

  const alreadyExistUcodeData = useMemo(
    () => [
      alreadyExistUcode.map((item, index) => ({
        item,
        serial: index + 1,
      })),
    ],
    [alreadyExistUcode],
  );

  const duplicateUnitDataError = useMemo(
    () => [
      ...duplicateUnitData.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [duplicateUnitData],
  );

  const spreadSheetUnitData = useMemo(
    () => [
      ...spreadSheet.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [spreadSheet],
  );

  return (
    <>
      <Helmet>
        <title>Add Qualification | JungleCat</title>
      </Helmet>
      <FullContainer>
        <AddQualificationHeader steps={steps} />

        <AddQualificationUnitContainer>
          <div className="container-admin">
            <div className="adminheading d-flex justify-content-between  mb-2">
              <h2 style={{fontSize: '1.5rem'}}>
                {steps === 0 ? 'Qualification Details' : null}
                {steps >= 1
                  ? `Add units to ${currentQualification?.tqual_title}`
                  : null}
              </h2>
              <p className="sub-text">
                Add qualifications to your organisation in order to assign
                students and organise classes.
              </p>
            </div>
            <CustomBox padding="0.0625rem 1.25rem" className="custombox">
              {stepsForQualification === 0 && steps === 0 ? (
                <div className="search-div">
                  <button
                    className={fileImportQua ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImportQua(true);
                      setManualQua(false);
                      setNextShowQua(true);
                      setStepsForQualification(0);
                    }}
                    id="import-from-spreadsheet-id"
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manualQua ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImportQua(false);
                      setManualQua(true);
                      setUploadQualification(null);
                      setStepsForQualification(0);
                    }}
                    id="add-manually-id"
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}
              {stepsForQualification === 1 || isQuaValidating ? (
                <div className="search-div-spread">
                  <p className="imported">
                    Imported qualifications from document
                  </p>
                  <p className="imported-file">{fileName}</p>
                </div>
              ) : null}
              {manualQua && stepsForQualification === 0 && steps === 0 ? (
                <ManualFormForQualification control={control} errors={errors} />
              ) : (
                <div
                  className="inputfields"
                  style={
                    stepsForQualification === 1 || steps > 0
                      ? {padding: '0rem 0rem 0rem 0rem'}
                      : null
                  }
                >
                  {stepsForQualification === 0 && fileImportQua ? (
                    <UploadSection
                      stepsForQualification={stepsForQualification}
                      setUploadQualification={setUploadQualification}
                      uploadQualification={uploadQualification}
                      onDrag={onDrag}
                      setOnDrag={setOnDrag}
                      setSpreadSheetQualification={setSpreadSheetQualification}
                      setFileName={setFileName}
                      setNextShowQua={setNextShowQua}
                    />
                  ) : null}

                  {isQuaValidating ? (
                    <ValidateLoader
                      title="Validating imported qualification details"
                      subtitle="Please wait"
                    />
                  ) : null}

                  {!isQuaValidating &&
                  stepsForQualification === 2 &&
                  !alreadyExistQname?.length &&
                  !alreadyExistQcode?.length &&
                  !duplicateData?.length &&
                  validQualification?.length ===
                    spreadSheetQualification?.length ? (
                    <SuccessMessage
                      title={`${spreadSheetQualification?.length} Qualifications has been added successfully`}
                      subtitle=" From the Qualifications List page, you may continue to add
                      units under each qualification separately."
                    />
                  ) : null}
                  {!isQuaValidating &&
                  stepsForQualification === 2 &&
                  validQualification?.length &&
                  (alreadyExistQcode?.length ||
                    duplicateData?.length ||
                    alreadyExistQname?.length) ? (
                    <Accordion
                      title={`${validQualification?.length}/${spreadSheetQualification?.length} Qualifications can be added to this organisation`}
                      subtitle="Some of the entries are incorrect. You can keep adding valid
                      qualifications entries or re-upload the document with the changes."
                      alreadyExistQcodeData={alreadyExistQcodeData}
                      alreadyExistQnameData={alreadyExistQnameData}
                      spreadsheetcolumns={qualificationsSpreadColumns}
                      alreadyExistQname={alreadyExistQname}
                      alreadyExistQcode={alreadyExistQcode}
                      duplicateDataError={duplicateDataError}
                    />
                  ) : null}
                  {!isQuaValidating &&
                  stepsForQualification === 2 &&
                  !validQualification?.length &&
                  (alreadyExistQcode?.length ||
                    duplicateData?.length ||
                    alreadyExistQname?.length) ? (
                    <NoUserAdded
                      title="No Qualifications added"
                      subtitle="All the qualifications are already exists in this
                      organisation."
                    />
                  ) : null}
                </div>
              )}
              {!isQuaValidating &&
              spreadSheetQualification?.length &&
              stepsForQualification === 1 ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={qualificationsSpreadColumns}
                    data={spreadsheetQualificationdata}
                    itemName="qualifications"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
              {/* Add Unit flow Started */}
              {steps === 1 ? (
                <div className="search-div">
                  <button
                    className={fileImport ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(true);
                      setManual(false);
                    }}
                    id="import-from-spreadsheet-id-1"
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manual ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(false);
                      setManual(true);
                      setProgress(1);
                    }}
                    id="add-manually-id-1"
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}
              {manual && stepsForQualification === 0 && steps === 1 ? (
                <ManualFormForUnits
                  manual={manual}
                  formShow={formShow}
                  btnShow={btnShow}
                  setFormShow={setFormShow}
                  setBtnShow={setBtnShow}
                  addUnits={addUnits}
                  setAddUnits={setAddUnits}
                  qualificationId={
                    currentQualification?.tqual_id_tgovqualification
                  }
                />
              ) : (
                <div
                  className="inputfields"
                  style={steps !== 1 ? {padding: '0rem 0rem 0rem 0rem'} : null}
                >
                  {steps === 1 && fileImport ? (
                    <UploadSectionUnits
                      steps={steps}
                      setUpload={setUpload}
                      upload={upload}
                      onDrag={onDrag}
                      setOnDrag={setOnDrag}
                      setSpreadSheet={setSpreadSheet}
                      setFileName={setFileName}
                      setNextShow={setNextShow}
                    />
                  ) : null}
                  {steps === 2 || isValidating ? (
                    <div className="search-div-spread">
                      <p className="imported">Imported units from document</p>
                      <p className="imported-file">{fileName}</p>
                    </div>
                  ) : null}
                  {isValidating ? (
                    <ValidateLoader
                      title="Validating imported units details"
                      subtitle="Please wait"
                    />
                  ) : null}

                  {!isValidating &&
                  steps === 3 &&
                  !alreadyExistUcode?.length &&
                  !duplicateUnitData?.length &&
                  validUnitsToQualification?.length === spreadSheet?.length ? (
                    <SuccessMessage
                      title={`${spreadSheet?.length} units has been added successfully to ${currentQualification?.tqual_title}`}
                      subtitle=" Under each qualification, you may now assign a class with relevant units."
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 3 &&
                  validUnitsToQualification?.length &&
                  (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
                    <AccordionForUnits
                      title={`${validUnitsToQualification?.length}/${spreadSheet?.length} units can be added to ${currentQualification?.tqual_title}`}
                      subtitle="Some of the entries are incorrect. You can keep adding valid unit entries or re-upload the document with the changes."
                      alreadyExistUcodeData={alreadyExistUcodeData}
                      spreadsheetcolumns={unitsColumns}
                      alreadyExistUcode={alreadyExistUcode}
                      duplicateUnitDataError={duplicateUnitDataError}
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 3 &&
                  !validUnitsToQualification?.length &&
                  (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
                    <NoUserAdded
                      title="No Units added"
                      subtitle="All the Units are already exists in this
                      organisation."
                    />
                  ) : null}
                </div>
              )}

              {!isValidating && spreadSheet?.length && steps === 2 ? (
                <SpreadSheetUnitTableStyles>
                  <Table
                    columns={unitsColumns}
                    data={spreadSheetUnitData}
                    itemName="Units"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetUnitTableStyles>
              ) : null}
            </CustomBox>
          </div>
        </AddQualificationUnitContainer>

        <AddQualificationFooter
          fileImportQua={fileImportQua}
          stepsForQualification={stepsForQualification}
          setStepsForQualification={setStepsForQualification}
          spreadSheetQualification={spreadSheetQualification}
          setSpreadSheetQualification={setSpreadSheetQualification}
          setUploadQualification={setUploadQualification}
          uploadQualification={uploadQualification}
          progress={progress}
          addUnits={addUnits}
          setAddUnits={setAddUnits}
          setProgress={setProgress}
          spreadSheet={spreadSheet}
          setSpreadSheet={setSpreadSheet}
          manual={manual}
          setUpload={setUpload}
          upload={upload}
          steps={steps}
          setSteps={setSteps}
          setIsValidating={setIsValidating}
          nextShowQua={nextShowQua}
          nextShow={nextShow}
          isValidating={isValidating}
          isQuaValidating={isQuaValidating}
          setIsQuaValidating={setIsQuaValidating}
          setManual={setManual}
          QualificationFormOnClick={handleSubmit(onSubmit)}
          setNextShow={setNextShow}
        />
      </FullContainer>
    </>
  );
};
