import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

const TasListContainer = styled(AdminListingPageContainer)`
  .serial {
    width: 3%;
  }

  .course-name {
    width: 25%;
  }

  .status {
    width: 12%;
  }
  .assignee-name {
    width: 13%;
  }
  .user-name {
    width: 13%;
  }

  .last-update-at {
    width: 15%;
  }

  .actions {
    width: 17%;
  }

  .moreoptions {
    width: 3%;
  }
  .spinner {
    animation: spin 1s linear infinite;
    height: 1rem;
    width: 1rem;
  }
`;

export default TasListContainer;
