import styled from 'styled-components';

const ChatContainer = styled.aside`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  z-index: 30;
  height: 70vh;
  bottom: 12%;
  right: 3%;
  min-width: 22%;

  .message-list {
    flex-grow: 1;
    padding: 1rem 1rem 0 1rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 470px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .users-typing {
    margin: 0 0 0.625rem 1rem;
    display: flex;
    align-items: center;

    .typing-user {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({theme}) => theme.colors.primary};
      color: white;
      font-size: 0.5rem;
      line-height: 0.625rem;
      font-weight: 600;
      text-transform: uppercase;
      img {
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .typing-indicator {
      padding: 0.5rem;
      border-radius: 1.5625rem;
      display: flex;
      background-color: #e6e7e8;

      @keyframes changecolor {
        0% {
          background-color: #3d464c;
        }

        25% {
          background-color: #6d7579;
        }

        50% {
          background-color: #9ea3a5;
        }

        75% {
          background-color: #6d7579;
        }

        100% {
          background-color: #3d464c;
        }
      }

      span {
        width: 0.375rem;
        height: 0.375rem;
        background-color: #6d7579;
        border-radius: 50%;
        opacity: 0.5;
        animation-duration: 1.2s;
        animation-name: changecolor;
        animation-iteration-count: infinite;

        :first-child {
          margin-right: 0.125rem;
          background-color: #3d464c;
          animation-delay: 0s;
        }

        :nth-child(2) {
          animation-delay: 0.4s;
        }

        :last-child {
          margin-left: 0.125rem;
          background-color: #9ea3a5;
          animation-delay: 0.8s;
        }
      }
    }
  }

  .message-input {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem 1rem 1.375rem;
    border-top: 0.0625rem solid ${({theme}) => theme.colors.border};
    position: relative;

    .emoji-button {
      border: none;
      background-color: transparent;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.625rem;

      :active,
      :focus {
        outline: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .save-changes {
      border: 0.0625rem solid #40a1ff;
      border-radius: 0.5rem;
      img {
        vertical-align: middle;
      }
    }

    input {
      border: none;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: ${({theme}) => theme.colors.text};
      flex-grow: 1;

      ::placeholder {
        color: ${({theme}) => theme.colors.placeholder};
      }

      :focus,
      :active {
        outline: none;
      }
    }
  }
`;

export default ChatContainer;
