import styled from 'styled-components';

import {Colors} from '../../../../../../../../../../../../../constants';

const PerformanceCriteriaItemContainer = styled.div`
  .item-title {
    margin: 1.25rem 0;

    span {
      color: ${Colors.Text};
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.125;
    }

    h4 {
      color: ${Colors.Text};
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.125;
    }
  }

  .sub-items {
    padding: 0 1.5rem;

    @media (max-width: 31.25rem) {
      & {
        padding: 1.5rem 1.5rem 0.5rem 1.5rem;
        box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
        border-radius: 0.75rem !important;
      }
    }

    .sub-item {
      display: flex;
      padding: 1.25rem 0;

      .column-header {
        display: none;
      }
      .serial-number-hash {
        display: none;
      }
      @media (max-width: 31.25rem) {
        display: flex;
        flex-direction: column;
        padding: 0 0;
        & {
          border-top: 0.0625rem solid #c9c9c9;
        }
        .sub-item-serial-number {
          padding: 1.25rem 0;
          background-color: #f4f5f8;
          border-bottom: 0.0625rem solid #c9c9c9;
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .serial-number-hash {
          display: block;
          margin-right: 0.3125rem;
          font-size: 0.875rem;
          color: #aaaaaa;
        }
        .column-header {
          display: block;
          font-size: 0.875rem;
          text-transform: uppercase;
          color: #aaaaaa;
          margin-bottom: 0.5rem;
        }
        .sub-item-title,
        .sub-item-task {
          width: 100% !important;
          padding: 1rem 0 !important;
          display: flex;
          align-items: flex-start !important;
          flex-direction: column;
          :not(:last-child) {
            border-bottom: 0.0625rem solid #c9c9c9;
          }

          .sub-item-linked-task {
            align-self: unset !important;
          }

          .sub-item-empty-task {
            align-self: unset !important;
          }
        }
      }

      :not(:first-child) {
        border-top: 0.0625rem solid #dedede;
      }

      .sub-item-serial-number,
      .sub-item-title {
        color: ${Colors.Text};
        font-size: 0.875rem;
        line-height: 1.375rem;
      }

      .sub-item-serial-number {
        width: 4%;
      }

      .sub-item-title {
        width: 45%;
        padding-right: 2.5rem;
      }

      .sub-item-task {
        display: flex;
        align-items: center;
        width: 51%;
        padding-right: 1.875rem;

        .sub-item-linked-task {
          color: ${Colors.Primary};
          font-size: 0.875rem;
          line-height: 1.375rem;
          text-decoration: underline;
          display: flex;
          align-self: center;
        }

        .sub-item-empty-task {
          color: #aaa;
          font-size: 0.875rem;
          font-style: italic;
          line-height: 1.375rem;
          display: flex;
          align-self: center;
        }

        .history-button {
          background-color: transparent;
          border: none;
          height: 1.5rem;
          width: 1.5rem;
          margin-left: 0.625rem;

          :active,
          :focus {
            outline: none;
          }

          img {
            height: 100%;
            object-fit: contain;
            vertical-align: unset;
            width: 100%;
          }
        }
      }
    }
  }
`;

export default PerformanceCriteriaItemContainer;
