import instance from '..';

export const getActiveClasses = () => instance.get('/api/class/active');

export const getActiveClassesOfStudent = () =>
  instance.get('/api/class/getUnitsForStudent');

export const getOrgClasses = academicYearId =>
  instance.get(`/api/class/${academicYearId}`);

export const getAcademicYears = () => instance.get('/api/org/academic_year');

export const getClassDetails = classId =>
  instance.get(`/api/class/${classId}/details`);

export const inviteSingleStudent = (
  classId,
  orgUnitInstanceId,
  studentDetails,
) =>
  instance.post(
    `/api/class/${classId}/unit/${orgUnitInstanceId}/addStudent`,
    studentDetails,
  );

export const inviteStudentBulk = (classId, orgUnitInstanceId, fromData) =>
  instance.post(
    `/api/class/${classId}/unit/${orgUnitInstanceId}/addStudentBulk`,
    fromData,
  );

export const updateStudent = (profileId, studentDetails) =>
  instance.put(`/api/class/${profileId}/updateStudent`, studentDetails);

export const deactivateClassStudent = profileId =>
  instance.delete(`/api/class/${profileId}/deactivateStudent`);

export const addClassUnitInstance = (classId, unitDetails) =>
  instance.post(`/api/class/${classId}/addUnitInstance`, unitDetails);

export const updateClassUnitInstance = (unitInstanceId, unitDetails) =>
  instance.put(`/api/class/${unitInstanceId}/updateUnitInstance`, unitDetails);

export const getUnitDetails = unitID =>
  instance.get(`/api/class/units/${unitID}`);

export const addStudentsToUnit = (orgUnitInstanceId, userProfileIdList) =>
  instance.post(`/api/class/${orgUnitInstanceId}/addStudentsToUnit`, {
    userProfileIdList,
  });

export const deleteStudentFromUnit = (unitInstanceID, userProfileID) =>
  instance.delete(
    `/api/class/${unitInstanceID}/units/${userProfileID}/deleteStudent`,
  );

export const deactivateClassUnitInstance = unitInstanceId =>
  instance.delete(`/api/class/${unitInstanceId}/deactivateUnitInstance`);

export const findStudentByToken = token =>
  instance.get(`/api/class/findStudentByToken?token=${token}`);

export const acceptInvitation = data =>
  instance.put('api/class/acceptInvitation', data);

export const getElementsByUnit = unitId =>
  instance.get(`/api/tgov/units/${unitId}/elements`);

export const getAssessmentMap = unitId =>
  instance.get(`/api/class/${unitId}/assessment-map`);

export const getPerformanceEvidence = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/performance-evidence`);

export const getKnowledgeEvidence = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/knowledge-evidence`);

export const getFoundationSkills = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/foundation-skill`);

export const getAssessmentCondition = unitID =>
  instance.get(`/api/class/${unitID}/assessment-map/assessment-condition`);

export const getLibraryFiles = unitID =>
  instance.get(`/api/class/${unitID}/library`);
