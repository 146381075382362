/* eslint-disable react/prop-types */
import {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {motion, AnimatePresence} from 'framer-motion';
import {useForm} from 'react-hook-form';
import {useClickAway} from 'react-use';

import CloseSidebarIcon from '../../../../../../assets/icons/close-modal.svg';
import {AddDocumentContainer} from './elements';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {showToast} from '../../../../../../components/common/Toast';
import UpdateIcon from '../../../../../../assets/icons/updateadmintoast.svg';
import {
  setError as setServerError,
  teacherLibrarySelector,
  addLibraryDocument,
} from '../../../../../../redux/teacherLibrarySlice';
import {AddForm, Footer} from './components';

const FIELDS_IN_ORDER = [
  'documentTitle',
  'fileCode',
  'fileCategory',
  'publicLibrary',
];

export const AddDocument = ({isVisible, onClose, onSuccess}) => {
  const {
    error: serverError,
    isTeacherLibraryLoading,
    category,
    formError,
    orgUnitInstance,
  } = useSelector(teacherLibrarySelector);
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    getValues,
    setValue,
    watch,
    register,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      documentTitle: '',
      fileCode: '',
      fileCategory: null,
      publicLibrary: false,
    },
  });
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadCoverImage, setUploadCoverImage] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const categoryDropdown = useRef();

  const setFieldError = (fieldName, errorMessage) => {
    setError(fieldName, {message: errorMessage});
  };

  const orgUnitInstanceId = orgUnitInstance?.orui_id_orgunitinstance;

  const onSubmit = data => {
    if (!data?.documentTitle) {
      setFieldError('documentTitle', 'Enter Document Title');
    }
    if (!data?.fileCode) {
      setFieldError('fileCode', 'Enter Code');
    }

    if (!data?.fileCategory) {
      setFieldError('fileCategory', 'Select the Category');
    }

    const formData = new FormData();
    if (
      uploadDocument &&
      getValues('documentTitle')?.length &&
      getValues('fileCode') &&
      data?.fileCategory !== null
    ) {
      formData.append('documentTitle', data?.documentTitle);
      formData.append('fileCode', data?.fileCode);

      formData.append('category', data?.fileCategory?.id);

      formData.append('uploadDocument', uploadDocument, uploadDocument?.name);
      if (uploadCoverImage) {
        formData.append(
          'uploadCoverImage',
          uploadCoverImage,
          uploadCoverImage?.name,
        );
      }

      if (!isTeacherLibraryLoading && !formError?.fileCode?.length) {
        dispatch(
          addLibraryDocument(orgUnitInstanceId, formData, () => {
            showToast(UpdateIcon, 'Document added successfully', false);
            setUploadDocument(null);
            setUploadCoverImage(null);
            onSuccess();
            onClose();
          }),
        );
      }
    }
  };

  useEffect(() => {
    getValues('documentTitle');
    getValues('fileCategory');
  }, [getValues]);

  const categoryAsOptions = useMemo(
    () =>
      category?.map(item => ({
        name: `${item?.fc_name}`,
        id: item?.fc_id_filecategory,
      })) || [],

    [category],
  );

  useEffect(() => {
    const b = document.body;
    b.style.overflow = 'hidden';
  }, []);

  return (
    <AnimatePresence>
      {isVisible ? (
        <AddDocumentContainer
          className="d-flex justify-content-end position-fixed"
          animate={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          exit={{
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          }}
          initial={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          transition={{
            duration: 0.4,
            type: 'keyframes',
          }}
        >
          <motion.aside
            className="sidebar"
            animate={{
              x: '0',
            }}
            exit={{
              x: '100%',
            }}
            initial={{
              x: '100%',
            }}
            transition={{
              duration: 0.4,
              type: 'keyframes',
            }}
            ref={ref}
          >
            <header className="header-title align-items-center d-flex justify-content-between mb-4 fixed-top">
              <div className="sidebar-head d-flex">
                <div className="sidebar-title mb-1">Add a File</div>
              </div>
              <button
                className="close-sidebar-button"
                onClick={onClose}
                type="button"
                id="close-btn"
              >
                <img alt="Close Sidebar" src={CloseSidebarIcon} />
              </button>
            </header>
            <AddForm
              uploadDocument={uploadDocument}
              setUploadDocument={setUploadDocument}
              uploadCoverImage={uploadCoverImage}
              setUploadCoverImage={setUploadCoverImage}
              onDrag={onDrag}
              setOnDrag={setOnDrag}
              onClose={onClose}
              register={register}
              setValue={setValue}
              categoryDropdown={categoryDropdown}
              errors={errors}
              categoryAsOptions={categoryAsOptions}
              control={control}
              formError={formError}
              clearErrors={clearErrors}
              setFieldError={setFieldError}
              watch={watch}
              orgUnitInstanceId={orgUnitInstanceId}
            />

            <Footer
              onButtonCancel={onClose}
              onSubmit={handleSubmit(onSubmit)}
              isDisabled={!uploadDocument}
              isTeacherLibraryLoading={isTeacherLibraryLoading}
            />
          </motion.aside>
        </AddDocumentContainer>
      ) : null}
    </AnimatePresence>
  );
};
