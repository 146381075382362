/* eslint-disable react/prop-types */

import {useCallback, useEffect, useMemo, useState} from 'react';
import DatePicker from 'react-datepicker';
import {Controller} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import axios from '../../../../../../../../api';
import CalenderIcon from '../../../../../../../../assets/icons/tas-calendar-icon.svg';
import AddIcon from '../../../../../../../../assets/icons/tas-table-add.svg';
import DeleteIcon from '../../../../../../../../assets/icons/tas-table-delete.svg';
import {Table, TextInput} from '../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import {CustomDatePicker} from '../../../../../../../Stream/elements/Units';
import {IndustryEngagementsFormContainer} from './elements';
import ErrorMessage from '../ErrorMessage';

const IndustryEngagementsForm = ({
  control,
  errors,
  onUpdate,
  tasStatus,
  setIndustryContacts,
  industryContacts,
  isDataCompleted,
  showError,
}) => {
  const {orgClassId} = useParams();

  useEffect(() => {
    if (orgClassId) {
      axios
        .get(`/api/admin/tas/${orgClassId}/industry-contacts`)
        .then(({data: {tasIndustryContacts}}) => {
          setIndustryContacts(tasIndustryContacts);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgClassId]);

  const updateIndustryContactApi = useCallback((unitId, data) => {
    axios.patch(`/api/admin/tas/industry-contacts/${unitId}`, {data});
  }, []);

  const updateIndustryContactValue = useCallback(
    (industryContactId, fieldName) => event => {
      setIndustryContacts(previousValue =>
        previousValue.map(item => {
          if (item.otic_id_orgtasindustrycontact === industryContactId) {
            return {
              ...item,
              [fieldName]: event.target.value,
            };
          }
          return item;
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const caeuTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateIndustryContactApi(original.otic_id_orgtasindustrycontact, {
                column: 'otic_first_name',
                value: event.target?.value,
              });
            }}
            isDisabled={tasStatus}
            onChange={updateIndustryContactValue(
              original.otic_id_orgtasindustrycontact,
              'otic_first_name',
            )}
            defaultValue={value}
          />
        ),
        Header: 'First Name',
        accessor: 'otic_first_name',
        className: 'first-name',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateIndustryContactApi(original.otic_id_orgtasindustrycontact, {
                column: 'otic_last_name',
                value: event.target?.value,
              });
            }}
            isDisabled={tasStatus}
            onChange={updateIndustryContactValue(
              original.otic_id_orgtasindustrycontact,
              'otic_last_name',
            )}
            defaultValue={value}
          />
        ),
        Header: 'Last Name',
        accessor: 'otic_last_name',
        className: 'last-name',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateIndustryContactApi(original.otic_id_orgtasindustrycontact, {
                column: 'otic_position',
                value: event.target?.value,
              });
            }}
            isDisabled={tasStatus}
            onChange={updateIndustryContactValue(
              original.otic_id_orgtasindustrycontact,
              'otic_position',
            )}
            defaultValue={value}
          />
        ),
        Header: 'Position',
        accessor: 'otic_position',
        className: 'position',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateIndustryContactApi(original.otic_id_orgtasindustrycontact, {
                column: 'otic_organisation',
                value: event.target?.value,
              });
            }}
            isDisabled={tasStatus}
            onChange={updateIndustryContactValue(
              original.otic_id_orgtasindustrycontact,
              'otic_organisation',
            )}
            defaultValue={value}
          />
        ),
        Header: 'Organisation',
        accessor: 'otic_organisation',
        className: 'organisation',
      },
      {
        Cell: ({row: {original}, value}) => {
          const [isDateOpen, setIsDateOpen] = useState(false);

          return (
            <CustomDatePicker
              className={`d-flex tas-date-picker table-input${
                tasStatus ? ' disabled' : ''
              }`}
            >
              <DatePicker
                autoComplete="off"
                className="form-control border-0 w-100 date-picker"
                dateFormat="yyyy-MM-dd"
                highlightDates={{
                  'react-datepicker__day--highlighted-custom-1': [new Date()],
                }}
                name="deliveryDate"
                disabled={tasStatus}
                onChange={date => {
                  updateIndustryContactValue(
                    original.otic_id_orgtasindustrycontact,
                    'otic_date',
                  )({target: {value: date.getTime()}});

                  updateIndustryContactApi(
                    original.otic_id_orgtasindustrycontact,
                    {
                      column: 'otic_date',
                      value: date.getTime(),
                    },
                  );

                  setIsDateOpen(false);
                }}
                onClickOutside={() => setIsDateOpen(false)}
                onFocus={() => {
                  setIsDateOpen(previousValue => !previousValue);
                }}
                open={isDateOpen}
                placeholderText=""
                popperPlacement="top"
                selected={value ? new Date(value) : null}
              />
              <button
                className="align-items-center d-flex openDatepicker"
                disabled={tasStatus}
                onClick={() => {
                  setIsDateOpen(previousValue => !previousValue);
                }}
                type="button"
              >
                <img alt="date" className="calendar-icon" src={CalenderIcon} />
              </button>
            </CustomDatePicker>
          );
        },
        Header: 'Date',
        accessor: 'otic_date',
        className: 'date',
      },
      {
        Cell: ({value}) => {
          if (tasStatus) {
            return null;
          }
          return (
            <button
              className="delete"
              onClick={() => {
                axios
                  .delete(`/api/admin/tas/industry-contacts/${value}`)
                  .then(() => {
                    setIndustryContacts(previousValue =>
                      previousValue.filter(
                        item => item.otic_id_orgtasindustrycontact !== value,
                      ),
                    );
                  });
              }}
              type="button"
            >
              <img alt="Delete" src={DeleteIcon} />
            </button>
          );
        },
        accessor: 'otic_id_orgtasindustrycontact',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasStatus, updateIndustryContactApi, updateIndustryContactValue],
  );

  const data = useMemo(
    () => [
      ...(industryContacts || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [industryContacts],
  );

  return (
    <IndustryEngagementsFormContainer
      className="section"
      id="industry-engagements"
    >
      <h3>Industry Engagements</h3>
      <p>
        RTOs must be able to demonstrate that all strategies for training and
        assessment have been developed in response to information obtained
        through engaging with industry stakeholders. This engagement may involve
        seeking advice about:
        <br />
        The qualification, course or skill set that best meets the skill and
        knowledge needs of industry
        <br />
        The most relevant electives for the training (in accordance with any
        packaging rules for the training product)
        <br />
        The mode of study and the training and assessment methods to be used.
        <br />
        <br />
        You must be able to demonstrate that industry representatives have had
        input into:
        <br />
        The skills and knowledge you require trainers and assessors to hold
        <br />
        The resources your RTO uses for training and assessment.
      </p>
      <form>
        <div className="tas-form-row">
          <Controller
            control={control}
            name="industryEngagements"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.industryEngagements}
                //                 hasInfo="RTOs must be able to demonstrate that all strategies for training and assessment have been developed in response to information obtained through engaging with industry stakeholders. This engagement may involve seeking advice about:<br/><br/>
                // <ul>
                // <li>The qualification, course or skill set that best meets the skill and knowledge needs of industry</li>
                // <li>The most relevant electives for the training (in accordance with any packaging rules for the training product)</li>
                // <li>The mode of study and the training and assessment methods to be used.</li>
                // </ul>
                // You must be able to demonstrate that industry representatives have had input into:<br/>
                // <ul>
                // <li>The skills and knowledge you require trainers and assessors to hold</li>
                // <li>The resources your RTO uses for training and assessment.</li>
                // </ul>
                // "
                label="Industry Engagements"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                rows={5}
                disabled={tasStatus}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            {showError && isDataCompleted?.includes('mode-of-delivery') ? (
              <ErrorMessage
                errorMessage="Industry engagements needed to be filled"
                className="error mb-2"
              />
            ) : null}
            <Table
              columns={caeuTableColumns}
              data={data}
              isWithoutHeader
              isWithoutPagination
              showAllRows
            />
            {!tasStatus ? (
              <div className="add-teacher-section">
                <button
                  onClick={() => {
                    axios
                      .post(`/api/admin/tas/${orgClassId}/industry-contacts`)
                      .then(({data: {tasIndustryContacts}}) => {
                        const tasInd = tasIndustryContacts.map(
                          (item, index) => ({
                            ...item,
                            serial: index + 1,
                          }),
                        );
                        setIndustryContacts(tasInd);
                      });
                  }}
                  type="button"
                >
                  <img alt="Add" src={AddIcon} /> Add
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </IndustryEngagementsFormContainer>
  );
};

export default IndustryEngagementsForm;
