/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import classNames from 'classnames';
import {useEffect, useMemo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import PerformanceCriteria from '../../../Admin/Components/Qualifications/UnitDetails/components/PerformanceCriteria';
import {AssessmentMapContainer} from './elements';

import {
  clearAssessmentMap,
  fetchAssessmentMap,
  streamSelector,
} from '../../streamSlice';

import {
  AssessmentCondition,
  // FoundationSkills,
  KnowledgeEvidence,
  PerformanceEvidence,
} from './components';

const AssessmentMap = () => {
  const dispatch = useDispatch();
  const {
    assessmentMap,
    isLoadingAssessmentMap,
    unitOfAssessmentMap: unit,
  } = useSelector(streamSelector);
  const {
    location: {pathname},
  } = useHistory();
  const [performanceCriteraTab, setPerformanceCriteraTab] = useState(true);
  const [performanceEvidenceTab, setPerformanceEvidenceTab] = useState(false);
  const [knowledgeEvidenceTab, setKnowledgeEvidenceTab] = useState(false);
  // const [foundationSkillsTab, setFoundationSkillsTab] = useState(false);
  const [assessmentConditionTab, setAssessmentConditionTab] = useState(false);

  const orgUnitInstanceIds = pathname.split('/')[2];

  // Restructure the assessment map.
  const assessmentMapGroup = useMemo(
    () =>
      assessmentMap?.elements?.map(item => ({
        criteria: item.performanceCriteria.map(criteria => ({
          id: criteria.tpc_id_tgovperformancecriteria,
          selectedAssessments: criteria.selectedAssessments,
          orderId: criteria.tpc_criteria_id,
          tasks:
            criteria.tasks?.map(task => ({
              date: moment(task.ai_timestamp_creation).format(
                'dddd D MMM YYYY, h:mma',
              ),
              id: task.ai_id_activityitem,
              title: task.ai_activityitem_title,
              assessmentType: task.ac_id_activitymethod,
              assessmentId: task.ac_id_activity,
              user: `${task.up_name_first} ${task.up_name_last}`,
            })) || [],
          title: criteria.tpc_criteria_value,
        })),
        id: item.tel_id_tgovelement,
        orderId: item.tel_element_id,
        title: item.tel_element_value,
        orgUnitInstanceId: orgUnitInstanceIds,
      })),
    [assessmentMap, orgUnitInstanceIds],
  );

  const performanceEvidenceGroup = useMemo(
    () =>
      assessmentMap?.performanceEvidences?.map(item => ({
        ...item,
        orgUnitInstanceId: orgUnitInstanceIds,
      })),
    [assessmentMap, orgUnitInstanceIds],
  );

  const knowledgeEvidenceGroup = useMemo(
    () =>
      assessmentMap?.knowledgeEvidences?.map(item => ({
        ...item,
        orgUnitInstanceId: orgUnitInstanceIds,
      })),
    [assessmentMap, orgUnitInstanceIds],
  );

  const assessmentConditionGroup = useMemo(
    () =>
      assessmentMap?.assessmentConditions?.map(item => ({
        ...item,
        orgUnitInstanceId: orgUnitInstanceIds,
      })),
    [assessmentMap, orgUnitInstanceIds],
  );

  useEffect(
    () => () => {
      dispatch(clearAssessmentMap());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Fetch the assessment map using the unit instance's ID in the organization.
  useEffect(() => {
    const orgUnitInstanceId = pathname.split('/')[2];
    dispatch(fetchAssessmentMap(orgUnitInstanceId));
  }, [dispatch, pathname]);

  return (
    <>
      <Helmet>
        <title>Assessment Map | JungleCat</title>
      </Helmet>
      <AssessmentMapContainer className="d-flex flex-column">
        {isLoadingAssessmentMap ? (
          <>
            <Skeleton className="mb-2" height="1.812rem" width="30%" />
            <Skeleton
              className="unit-name-skeleton"
              height="1rem"
              width="60%"
            />
            <Skeleton height="4rem" width="100%" />
          </>
        ) : (
          <>
            <div className="search-div">
              <button
                // className={performanceCriteraTab ? 'bottom link' : 'bottom'}
                className={classNames('bottom', {
                  link: performanceCriteraTab,
                })}
                type="button"
                onClick={() => {
                  setPerformanceCriteraTab(true);
                  setPerformanceEvidenceTab(false);
                  setKnowledgeEvidenceTab(false);
                  // setFoundationSkillsTab(false);
                  setAssessmentConditionTab(false);
                }}
              >
                <p>Performance Criteria</p>
              </button>
              {/* <button
              className={classNames('bottom', {
                link: foundationSkillsTab,
              })}
              type="button"
              onClick={() => {
                setPerformanceCriteraTab(false);
                setFoundationSkillsTab(true);
                setPerformanceEvidenceTab(false);
                setKnowledgeEvidenceTab(false);
                setAssessmentConditionTab(false);
              }}
            >
              <p>Foundation Skills</p>
            </button> */}
              <button
                className={classNames('bottom', {
                  link: performanceEvidenceTab,
                })}
                type="button"
                onClick={() => {
                  setPerformanceCriteraTab(false);
                  // setFoundationSkillsTab(false);
                  setPerformanceEvidenceTab(true);
                  setKnowledgeEvidenceTab(false);
                  setAssessmentConditionTab(false);
                }}
              >
                <p>Performance Evidence</p>
              </button>
              <button
                className={classNames('bottom', {
                  link: knowledgeEvidenceTab,
                })}
                type="button"
                onClick={() => {
                  setPerformanceCriteraTab(false);
                  // setFoundationSkillsTab(false);
                  setPerformanceEvidenceTab(false);
                  setKnowledgeEvidenceTab(true);
                  setAssessmentConditionTab(false);
                }}
              >
                <p>Knowledge Evidence</p>
              </button>
              <button
                className={classNames('bottom', {
                  link: assessmentConditionTab,
                })}
                type="button"
                onClick={() => {
                  setPerformanceCriteraTab(false);
                  // setFoundationSkillsTab(false);
                  setPerformanceEvidenceTab(false);
                  setKnowledgeEvidenceTab(false);
                  setAssessmentConditionTab(true);
                }}
              >
                <p>Assessment Conditions</p>
              </button>
            </div>
            {performanceCriteraTab ? (
              <div className="mt-4">
                <div className="mb-4">
                  <h2 className="page-title">Performance Criteria</h2>
                  <span className="unit-name">
                    {unit?.tun_code} - {unit?.tun_title}
                  </span>
                </div>
                <div>
                  <PerformanceCriteria
                    assessmentMap={assessmentMapGroup}
                    assessments={assessmentMap?.assessments || []}
                  />
                </div>
              </div>
            ) : null}
            {/* {foundationSkillsTab ? (
            <div className="mt-4">
              <div className="mb-4">
                <h2 className="page-title">Foundation Skills</h2>
                <span className="unit-name">
                  Foundation skills essential to performance in this unit, but
                  not explicit in the performance criteria are listed here,
                  along with a brief context statement.
                </span>
              </div>
              <div>
                <FoundationSkills foundationSkills={foundationSkillsGroup} />
              </div>
            </div>
          ) : null} */}
            {performanceEvidenceTab ? (
              <div className="mt-4">
                <div className="mb-4">
                  <h2 className="page-title">Performance Evidence</h2>
                  <span className="unit-name">
                    Evidence of the ability to complete tasks outlined in
                    elements and performance criteria of this unit in the
                    context of the job role, and
                  </span>
                </div>
                <div>
                  <PerformanceEvidence
                    performanceEvidence={performanceEvidenceGroup}
                    assessments={assessmentMap?.assessments || []}
                  />
                </div>
              </div>
            ) : null}
            {knowledgeEvidenceTab ? (
              <div className="mt-4">
                <div className="mb-4">
                  <h2 className="page-title">Knowledge Evidence</h2>
                  <span className="unit-name">
                    Demonstrated knowledge required to complete the tasks
                    outlined in elements and performance criteria of this unit
                  </span>
                </div>
                <div>
                  <KnowledgeEvidence
                    knowledgeEvidence={knowledgeEvidenceGroup}
                    assessments={assessmentMap?.assessments || []}
                  />
                </div>
              </div>
            ) : null}
            {assessmentConditionTab ? (
              <div className="mt-4">
                <div className="mb-4">
                  <h2 className="page-title">Assessment Conditions</h2>
                  <span className="unit-name">
                    Demonstrated knowledge required to complete the tasks
                    outlined in elements and performance criteria of this unit
                  </span>
                </div>
                <div>
                  <AssessmentCondition
                    assessmentCondition={assessmentConditionGroup}
                    assessments={assessmentMap?.assessments || []}
                  />
                </div>
              </div>
            ) : null}
          </>
        )}
      </AssessmentMapContainer>
    </>
  );
};

export default AssessmentMap;
