import styled from 'styled-components';

export const ModuleContainer = styled.div`
  .title-container {
    margin: 2rem 0 1.25rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #3d4457;
    width: 100%;
    text-overflow: ellipsis;
  }
  .title-data {
    width: 25%;
  }
  .btn-container {
    width: 5%;
  }
  .seperator {
    height: 0.0625rem;
    width: 70%;
    background-color: #e0e0e0;
  }
  .fileposition {
    font-weight: 400;
    font-size: 0.875rem;
    color: #3d4457;
    width: 1.25rem;
  }
  .filename {
    font-weight: 400;
    font-size: 0.875rem;
    color: #3d4457;
  }
  .fileicon {
    width: 22px;
    height: 28px;
    vertical-align: unset;
  }
  .icon {
    transform: rotate(90deg);
  }
  .icon-module {
    width: 1rem;
    height: 0.25rem;
    vertical-align: unset;
  }
  .button-download {
    background-color: transparent;
    border: unset;
    outline: unset;
    padding: unset;
  }
  .button {
    &.add-button {
      margin-right: 2rem;
      background-color: #ffffff;
      margin-left: 1rem;
      border: 0.0625rem solid ${({theme}) => theme.colors.primary};

      :active,
      :focus {
        border-color: ${({theme}) => theme.colors.primary};
      }

      .label {
        color: #40a1ff;
      }
    }
  }
  .downloadicon {
    width: 1.125rem;
    height: 1.125rem;
    vertical-align: unset;
  }
  .arrowicon {
    width: 1.125rem;
    height: 1.125rem;
    vertical-align: unset;
  }
  .date {
    font-style: italic;
    font-weight: 400;
    font-size: 0.875rem;
    color: #aaaaaa;
  }
  .file-container {
    padding: 0 1.5rem;
    background: #ffffff;
    border-radius: 0.25rem;
    .published-badge {
      background-color: #edfaf1;
      border-radius: 0.3125rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #3ea15f;
      padding: 0.43rem 1.5rem;
      margin-right: 2rem;
    }

    .file-detail {
      padding: 1.25rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      :not(:last-child) {
        border-bottom: 1px solid #dedede;
      }
      .btn-container {
        display: flex;
        width: 20%;
      }
      .name-container {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .date {
        width: 30%;
      }
      .view-module {
        border: none;
        background: transparent;
        outline: none;
        border-radius: 0.312rem;
        color: #40a1ff;
        font-weight: 500;
        font-size: 0.875rem;
        padding: unset;
        a {
          text-decoration: none;
          color: #40a1ff;
        }
        .viewmodule-arrow {
          margin-left: 0.75rem;
          vertical-align: middle;
        }
      }
      @media (max-width: 55rem) {
        & {
          display: flex;
          flex-direction: column;

          .btn-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center !important;
            margin-right: 0.5rem;
          }
          .add-button,
          .published-badge {
            margin: unset;
          }

          .name-container {
            width: 100%;
          }
          .container-second {
            margin-top: 0.75rem !important;
          }
          .date {
            margin-top: 0.75rem;
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 80rem) {
    .seperator {
      width: 50%;
    }
  }
  @media (max-width: 45rem) {
    .seperator {
      width: 40%;
    }
  }
  @media (max-width: 37.5rem) {
    .seperator {
      display: none;
    }
    .title-data {
      width: 75%;
    }
    .btn-container {
      width: 15%;
    }
  }
`;
