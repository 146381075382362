import styled from 'styled-components';

export const AddedMappedTaskContainer = styled.div`
  background: #ffffff;
  border: 0.0625rem solid #e6e7e8;
  box-sizing: border-box;
  border-radius: 0.5rem;
  .mapped-task-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: ${({theme}) => theme.colors.text};
  }
  .content {
    display: flex;
    width: 100%;
    padding: 1rem;
  }
  .title {
    width: 30%;
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({theme}) => theme.colors.text};
  }
  .map-data {
    width: 70%;
    font-size: 0.875rem;
    color: ${({theme}) => theme.colors.text};
  }
  .added-mapped-task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrow-btn {
      background: transparent;
      border: none;
      outline: none;
      img {
        vertical-align: middle;
      }
      &.is-open img {
        transform: rotate(180deg);
      }
    }
  }
`;
