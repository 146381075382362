import styled from 'styled-components';

const VideoGridWrapper = styled.div`
  width: 50%;
  margin-left: 1rem;
  display: grid;
  /* grid-template-columns: ${({noOfVideos}) =>
    noOfVideos <= 3 ? '1fr' : 'repeat(auto-fill, minmax(200px, 1fr))'}; */

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 250px;
  justify-content: center;
  gap: 1rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default VideoGridWrapper;
