/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showToast} from '../../../../../../../../../../components/common/Toast';
import ToastSuccessIcon from '../../../../../../../../../../assets/icons/class-success.svg';

import {
  addUnitsToQualificationManual,
  spreadSheetUnitCheckToQualification,
  addUnitsToQualificationSpread,
  addQualificationSpread,
  spreadSheetQualificationsCheck,
  qualificationsSelector,
  fetchAllQualifications,
} from '../../../../../../../../../../redux/qualificationsSlice';

import {AddQualificationUnitFooter} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import CustomButton from '../../../../../../../../../../components/shared/CustomButton';

export const AddQualificationFooter = ({
  fileImportQua,
  spreadSheetQualification,
  setSpreadSheetQualification,
  stepsForQualification,
  setStepsForQualification,
  setUploadQualification,
  progress,
  addUnits,
  setAddUnits,
  spreadSheet,
  setSpreadSheet,
  manual,
  steps,
  setSteps,
  setUpload,
  setIsValidating,
  nextShowQua,
  nextShow,
  isValidating,
  isQuaValidating,
  setIsQuaValidating,
  QualificationFormOnClick,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    currentQualification,
    isAddQualificationLoading,
    alreadyExistQname,
    alreadyExistQcode,
    validQualification,
    alreadyExistUcode,
    duplicateData,
    duplicateUnitData,
    validUnitsToQualification,
  } = useSelector(qualificationsSelector);

  return (
    <AddQualificationUnitFooter>
      <div className="add-unit-footer fixed-bottom">
        {fileImportQua && stepsForQualification === 0 ? (
          <div className="add-unit-title">
            <div />
            <div className="button-container">
              <div />
              <div className="action-button">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUploadQualification(null);
                    history.replace(`/admin/qualifications`);
                    setSpreadSheetQualification([]);
                  }}
                  id="back-btn-0"
                >
                  <span className="cancel1-button-text">Back</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheetQualification.length || nextShowQua}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    setStepsForQualification(1);
                  }}
                  id="next-btn-0"
                />
              </div>
            </div>
          </div>
        ) : null}
        {fileImportQua && stepsForQualification === 1 ? (
          <div className="add-unit-title">
            <div />

            <div className="button-container">
              <div />

              <div className="action-button">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  isDisabled={isQuaValidating}
                  onClick={() => {
                    setUploadQualification(null);
                    setSpreadSheetQualification([]);
                    setStepsForQualification(0);
                  }}
                  id="back-btn-1"
                >
                  <span className="cancel1-button-text">Back</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={
                    !spreadSheetQualification.length ||
                    nextShowQua ||
                    isQuaValidating
                  }
                  className="save-changes"
                  id="next-btn-1"
                  text="Next"
                  onClick={() => {
                    setIsQuaValidating(true);

                    dispatch(
                      spreadSheetQualificationsCheck(
                        spreadSheetQualification,
                        () => {
                          setTimeout(() => {
                            setIsQuaValidating(false);
                            setStepsForQualification(2);
                          }, 3000);
                        },
                      ),
                    );
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {stepsForQualification === 2 &&
        !validQualification?.length &&
        (alreadyExistQcode?.length ||
          alreadyExistQname?.length ||
          duplicateData?.length) ? (
          <div className="add-unit-title">
            <div />
            <div className="button-container">
              <div />

              <div className="action-button">
                <CustomButton
                  isDisabled={
                    !spreadSheetQualification.length ||
                    nextShowQua ||
                    isQuaValidating
                  }
                  className="save-changes"
                  text="Done"
                  id="done-btn-id"
                  onClick={() => {
                    history.replace(`/admin/qualifications`);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {!isQuaValidating &&
        stepsForQualification === 2 &&
        validQualification?.length &&
        (alreadyExistQcode?.length ||
          duplicateData?.length ||
          alreadyExistQname?.length) ? (
          <div className="add-unit-title">
            <div />

            <div className="button-container">
              <div />

              <div className="action-button">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setSpreadSheetQualification([]);
                    setUploadQualification(null);
                    setStepsForQualification(0);
                  }}
                  id="upload-again-btn"
                >
                  <span className="cancel1-button-text">Upload Again</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={
                    !spreadSheetQualification.length ||
                    nextShowQua ||
                    isQuaValidating
                  }
                  className="save-changes"
                  text="Add Valid Entries"
                  id="add-valid-entries-btn"
                  onClick={() => {
                    if (!isAddQualificationLoading) {
                      dispatch(
                        addQualificationSpread(validQualification, () => {
                          showToast(
                            ToastSuccessIcon,
                            `${validQualification?.length} qualifications has been added successfully.`,
                            false,
                          );
                        }),
                      );
                    }
                    history.replace(`/admin/qualifications`);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {!isQuaValidating &&
        stepsForQualification === 2 &&
        !alreadyExistQname?.length &&
        !alreadyExistQcode?.length &&
        !duplicateData?.length &&
        validQualification?.length === spreadSheetQualification?.length ? (
          <div className="button-container">
            <div />
            <div>
              <CustomButton
                isDisabled={!spreadSheetQualification?.length || nextShowQua}
                className="save-changes mr-3"
                text="Done"
                id="done-btn"
                onClick={() => {
                  showToast(
                    ToastSuccessIcon,
                    `${validQualification?.length} qualifications has been added successfully.`,
                    false,
                  );
                  history.replace(`/admin/qualifications`);
                }}
              />
            </div>
          </div>
        ) : null}
        {!fileImportQua && manual ? (
          <div className="add-unit-title">
            <div className="progress-div">
              <p>{progress}/2 Completed</p>
              <div className="progress-bar">
                <div
                  className={
                    progress === 0
                      ? 'progress-loader'
                      : `progress-loader progress-loader-${progress}`
                  }
                />
              </div>
            </div>

            <div className="footer-seperator" />
            <div className="button-container">
              <div />

              <div className="action-button">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setAddUnits([]);

                    history.replace(`/admin/qualifications`);
                  }}
                  id="back-btn-manual"
                >
                  <span className="cancel1-button-text">Back</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!addUnits?.length}
                  className="save-changes"
                  text="Save"
                  id="save-btn"
                  onClick={() => {
                    if (addUnits?.length && !isAddQualificationLoading) {
                      const qualificationId =
                        currentQualification?.tqual_id_tgovqualification;
                      dispatch(
                        addUnitsToQualificationManual(
                          addUnits,
                          qualificationId,
                          () => {
                            showToast(
                              ToastSuccessIcon,
                              `${currentQualification?.tqual_title} has been added with ${addUnits?.length} units successfully.`,
                              false,
                            );
                            setAddUnits([]);
                            dispatch(fetchAllQualifications());
                          },
                        ),
                      );
                      history.replace(`/admin/qualifications`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="add-unit-spread">
            {!fileImportQua ? (
              <div className="progress-div">
                <div>
                  <p>{progress}/2 Completed</p>
                </div>
                <div className="progress-bar">
                  <div
                    className={
                      progress === 0
                        ? 'progress-loader'
                        : `progress-loader progress-loader-${progress}`
                    }
                  />
                </div>
              </div>
            ) : (
              <div />
            )}
            {!fileImportQua ? <div className="footer-seperator" /> : null}
            {steps === 3 ? <div className="button-container" /> : null}
            {steps === 0 && !fileImportQua ? (
              <div className="button-container">
                <div />
                <div className="action-button">
                  <CustomCancel
                    className="cancel1"
                    bgColor="#fff"
                    onClick={() => {
                      history.replace(`/admin/qualifications`);
                    }}
                    id="back-btn-steps-3"
                  >
                    <span className="cancel1-button-text">Back</span>
                  </CustomCancel>

                  <CustomButton
                    className="save-changes"
                    text="Next"
                    id="next-btn-steps-3"
                    onClick={QualificationFormOnClick}
                  />
                </div>
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      history.replace(`/admin/qualifications`);
                      showToast(
                        ToastSuccessIcon,
                        `${currentQualification?.tqual_title} has been added successfully.`,
                        false,
                      );
                    }}
                    className="skip-this-step mr-3"
                    id="skip-this-step-1"
                  >
                    Skip this step
                  </button>
                </div>

                <div className="action-button">
                  <CustomCancel
                    className="cancel1"
                    bgColor="#fff"
                    onClick={() => {
                      setUpload(null);
                      setSpreadSheet([]);
                      setAddUnits([]);
                      showToast(
                        ToastSuccessIcon,
                        `${currentQualification?.tqual_title} has been added successfully.`,
                        false,
                      );
                      history.replace(`/admin/qualifications`);
                    }}
                    id="cancel-btn-step-1"
                  >
                    <span className="cancel1-button-text">cancel</span>
                  </CustomCancel>
                  <CustomButton
                    isDisabled={!spreadSheet?.length || nextShow}
                    className="save-changes"
                    text="Next"
                    onClick={() => {
                      if (spreadSheet?.length) {
                        setSteps(state => state + 1);
                      }
                    }}
                    id="next-btn-step-1"
                  />
                </div>
              </div>
            ) : null}
            {steps === 2 ? (
              <div className="button-container">
                <div />
                <div className="action-button">
                  <CustomCancel
                    className="cancel1"
                    bgColor="#fff"
                    isDisabled={isValidating}
                    onClick={() => {
                      setUpload(null);
                      setSteps(1);
                      setSpreadSheet([]);
                    }}
                    id="back-btn-steps-2"
                  >
                    <span className="cancel1-button-text">Back</span>
                  </CustomCancel>
                  <CustomButton
                    isDisabled={
                      !spreadSheet?.length || nextShow || isValidating
                    }
                    className="save-changes"
                    text="Next"
                    id="next-btn-step-2"
                    onClick={() => {
                      setIsValidating(true);
                      if (spreadSheet.length) {
                        const qualificationId =
                          currentQualification?.tqual_id_tgovqualification;
                        dispatch(
                          spreadSheetUnitCheckToQualification(
                            spreadSheet,
                            qualificationId,
                            () => {
                              setTimeout(() => {
                                setIsValidating(false);
                                setSteps(state => state + 1);
                              }, 3000);
                            },
                          ),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            ) : null}
            {steps === 3 &&
            validUnitsToQualification?.length === spreadSheet?.length &&
            !duplicateUnitData?.length &&
            !alreadyExistUcode?.length ? (
              <div className="button-container">
                <div />
                <div>
                  <CustomButton
                    isDisabled={!spreadSheet?.length || nextShow}
                    className="save-changes"
                    text="Done"
                    id="done-units-btn"
                    onClick={() => {
                      setSteps(state => state + 1);
                      showToast(
                        ToastSuccessIcon,
                        `${currentQualification?.tqual_title} has been added with ${spreadSheet?.length} units successfully.`,
                        false,
                      );

                      history.replace(`/admin/qualifications`);
                    }}
                  />
                </div>
              </div>
            ) : null}
            {steps === 3 &&
            validUnitsToQualification?.length &&
            (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(1);
                    setSpreadSheet([]);
                  }}
                  id="upload-again-step-3"
                >
                  <span className="cancel1-button-text">Upload again</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet?.length || nextShow}
                  className="save-changes"
                  text="Add Valid Entries"
                  id="add-valid-entries-step-3"
                  onClick={() => {
                    const qualificationId =
                      currentQualification?.tqual_id_tgovqualification;
                    if (
                      validUnitsToQualification?.length &&
                      !isAddQualificationLoading
                    ) {
                      if (currentQualification) {
                        dispatch(
                          addUnitsToQualificationSpread(
                            validUnitsToQualification,
                            qualificationId,
                            () => {
                              showToast(
                                ToastSuccessIcon,
                                `${currentQualification?.tqual_title} has been added with ${validUnitsToQualification?.length} units successfully.`,
                                false,
                              );
                              dispatch(fetchAllQualifications());
                            },
                          ),
                        );
                      }
                    }
                    history.replace(`/admin/qualifications`);
                  }}
                />
              </div>
            ) : null}
            {steps === 3 &&
            !validUnitsToQualification?.length &&
            duplicateUnitData?.length &&
            alreadyExistUcode?.length ? (
              <div className="button-container">
                <div />
                <div>
                  <CustomButton
                    isDisabled={!spreadSheet?.length || nextShow}
                    className="save-changes"
                    text="Done"
                    id="done-btn-step-3"
                    onClick={() => {
                      if (currentQualification) {
                        history.replace(`/admin/qualifications`);
                      }
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </AddQualificationUnitFooter>
  );
};
