/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';

import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {TextInput} from '../../../../../../../../../../components/common';

import {FormContainer} from './elements';

import {useErrorMessage} from '../../../../../../../../../../utils/hooks';

import {
  clearFormError,
  setError as setServerError,
  qualificationsSelector,
} from '../../../../../../../../../../redux/qualificationsSlice';

const FIELDS_IN_ORDER = ['qualificationCode', 'qualificationName'];

export const ManualFormForQualification = ({errors, control}) => {
  const dispatch = useDispatch();
  const {error: serverError, formError} = useSelector(qualificationsSelector);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  return (
    <FormContainer>
      <div>
        <div>
          <div
            className="add-student-modal"
            style={{padding: '1.5rem 0rem 0.5rem 0rem'}}
          >
            <div className="add-student-modal-body">
              <form>
                <div className="d-flex form-fields">
                  <div className="fields">
                    <Controller
                      control={control}
                      name="qualificationCode"
                      render={fields => (
                        <TextInput
                          {...fields}
                          errorMessage={
                            errors?.qualificationCode?.message ||
                            formError.qualificationCode
                          }
                          hasError={
                            !!errors.qualificationCode ||
                            !!formError.qualificationCode
                          }
                          label="Qualification Code"
                          placeholder="Enter qualification code"
                          id="qualification-code-id"
                        />
                      )}
                    />
                  </div>
                  <div className="field-last">
                    <Controller
                      control={control}
                      name="qualificationName"
                      render={fields => (
                        <TextInput
                          {...fields}
                          errorMessage={
                            errors?.qualificationName?.message ||
                            formError.qualificationName
                          }
                          hasError={
                            !!errors.qualificationName ||
                            !!formError.qualificationName
                          }
                          label="Qualification Name"
                          placeholder="Enter qualification name"
                          id="qualification-name-id"
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};
