import styled from 'styled-components';

export const AllSessionsContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100%;
  margin: 0 -0.9375rem;
  padding: 7.0625rem 1.5rem 4.0625rem 1.5rem;

  @media (max-width: 55rem) {
    & {
      padding: 7.0625rem 1rem 4.0625rem 1rem;
    }
  }

  .calendar-container {
    width: calc(100% - 29.4375rem);
    margin-left: auto;
    margin-right: 24rem;
    /* &.is-archive {
      width: 100vh;
    } */
  }

  .table-container {
    background-color: white;
    border: 0.0625rem solid #e5e5e5;
    border-radius: 0.25rem;
    overflow: hidden;

    .calendar-header {
      align-items: center;
      border-bottom: 0.0625rem solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.25rem;

      .session-count {
        color: #0c181f;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75rem;
        margin: 0;
      }
    }

    table {
      margin: 1rem 0 1.5rem 0;
      width: 100%;
    }
  }

  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    .title {
      color: ${({theme}) => theme.colors.text};
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.8125rem;
    }
    .right-side {
      display: flex;
    }
    .filter-button {
      outline: unset;
      background-color: transparent;
      border: unset;
      margin-right: 1rem;
      height: 2.625rem;
    }
    @media (max-width: 31.2rem) {
      .add-button {
        .label {
          display: none;
        }
        .icon {
          margin-right: unset !important;
        }
      }
    }
  }
  .add-button {
    &.is-archive {
      display: none !important;
    }
  }
  .index {
    width: 4%;
  }

  .session-name {
    width: 14%;
  }

  .course-name {
    width: 9%;
  }
  .unit-code {
    width: 9%;
  }

  .teacher {
    width: 10%;
  }
  .class-name {
    width: 10%;
  }
  .location {
    width: 10%;
  }
  .date {
    width: 8%;
  }
  .time {
    width: 12%;
  }
  .attendance {
    width: 10%;
  }

  .more-options {
    width: 4%;
  }
`;
