import styled from 'styled-components';

export const CommentList = styled.div`
  .comment-count {
    color: #565656;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3;
    margin: 1.5rem 0 1rem 0;
  }
  @media (max-width: 43.75rem) {
    .comment-text {
      word-break: break-word;
    }
  }
  .comment {
    align-items: flex-start;
    display: flex;
    margin-bottom: 1.5rem;
    width: 100%;
    justify-content: space-between;

    &.is-alone {
      margin-bottom: 0.5rem;
    }

    .profile-picture {
      height: 2.25rem;
      /* margin-right: 0.5rem; */
      width: 2.25rem;
      object-fit: unset !important;
    }

    .comment-body {
      flex-grow: 1;
      /* padding-right: 0.5rem; */
      width: 90%;
      padding-left: 0.5rem;

      .comment-metadata {
        align-items: center;
        display: flex;
        margin-bottom: 0.3125rem;
        text-align: left;
        .user-name {
          color: ${({theme}) => theme.colors.text};
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.21;
          margin-right: 0.625rem;
        }

        .comment-time {
          color: #b8bac7;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.21;
        }
      }

      .comment-text {
        color: #aaaaaa;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.42;
        margin-bottom: 0;

        word-break: break-word;
      }
    }

    .comment-options {
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      height: 1rem;
      justify-content: center;
      width: 1rem;

      :focus,
      :active {
        outline: none;
      }
    }
  }

  .view-more {
    border-top: 0.0625rem solid #e5e5e5;
    display: flex;
    justify-content: center;
    padding: 1rem 0 0.5rem 0;

    .view-more-button {
      background-color: transparent;
      border: none;
      color: #565656;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.21;

      :focus,
      :active {
        outline: none;
      }
    }
  }
  .comment-user-info {
    outline: none;
    background: transparent;
    border: none;
    display: flex;
  }
  .comment-text {
    text-align: left;
  }
`;
