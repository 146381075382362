/* eslint-disable react/prop-types */
import React, {useMemo, useCallback} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {TextInput, Select} from '../../../../../../../../components/common';

import {ManualTableStyles, FormContainer} from './elements';
import CustomCancel from '../../../../../../../../components/shared/Buttons/CustomButton';
import AddIcon from '../../../../../../../../assets/icons/addadmin.svg';
import {Table} from '../../../../../../../../components/common/Table';
import CloseIcon from '../../../../../../../../assets/icons/redcross.svg';
import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {classStudentSchema} from '../../../../../../../../utils/validation';

import {
  setError as setServerError,
  classesSelector,
  clearFormError,
} from '../../../../../../../../redux/classesSlice';

const FIELDS_IN_ORDER = ['referId', 'firstName', 'lastName', 'email'];

export const ManualForm = ({
  manual,
  formShow,
  btnShow,
  setFormShow,
  setBtnShow,
  addStudents,
  setAddStudents,
}) => {
  const dispatch = useDispatch();
  const {
    error: serverError,
    formError,
    classStudents,
    singleClass,
  } = useSelector(classesSelector);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  let studentOptions = useMemo(() => {
    // already exist students in class
    const alreadyExistStudentId = singleClass?.orgStudents?.map(
      data => data.ocs_id_userprofile_student,
    );
    // already exist students in org without in same class
    const newStudents = classStudents?.filter(
      item => !alreadyExistStudentId?.includes(item?.up_id_userprofile),
    );

    return [
      ...(newStudents || [])?.map(item => ({
        label: `${item.up_org_reference}`,
        name: `${item.up_name_first} ${item.up_name_last}`,
        value: item.up_org_reference,
        email: item.up_email,
        item,
      })),
    ];
  }, [classStudents, singleClass]);

  studentOptions = useMemo(() => {
    const arraysOfStudents = addStudents.map(item => item.email);
    return studentOptions?.filter(
      item => !arraysOfStudents.includes(item.email),
    );
  }, [addStudents, studentOptions]);

  const formatOptionLabel = ({name, label}, {context}) =>
    context === 'menu' ? (
      <div className="select-option" type="button">
        <div className="username">{name}</div>
        <div className="referID">{label}</div>
      </div>
    ) : (
      label
    );

  const {
    control,
    formState: {errors},
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      referId: null,
      firstName: '',
      lastName: '',
      email: '',
    },
    resolver: yupResolver(classStudentSchema),
  });
  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const onSubmit = useCallback(
    data => {
      setAddStudents(state => [
        ...state,
        {
          last_name: data.lastName,
          first_name: data.firstName,
          org_ref_id: data.referId?.item?.up_org_reference,
          email: data.email,
        },
      ]);

      setFormShow(false);
      setBtnShow(false);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addStudents],
  );

  const removeItems = position => {
    setAddStudents(state =>
      state.filter((item, index) => index !== position.index),
    );
  };
  // Manual flow mapping
  const manualcolumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'ID',
        accessor: 'org_ref_id',
        className: 'ref-id-manual',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
        className: 'first-name-manual',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        className: 'last-name-manual',
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        className: 'email-manual',
      },
      {
        Header: ' ',
        Cell: ({row: position}) => (
          <button
            type="button"
            onClick={() => {
              removeItems(position);
            }}
            className="closeIconBtn"
          >
            <img src={CloseIcon} alt="close" />
          </button>
        ),
        accessor: 'addStudents',
        className: 'close-row',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const onSelectChange = value => {
    setValue('email', value?.item?.up_email);
    setValue('firstName', value?.item?.up_name_first);
    setValue('lastName', value?.item?.up_name_last);
  };

  // Add a serial number to each row and memoize the data.
  const manualdata = useMemo(
    () => [
      ...addStudents.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [addStudents],
  );

  return (
    <FormContainer>
      <div>
        {manual && addStudents?.length ? (
          <ManualTableStyles>
            <Table
              columns={manualcolumns}
              data={manualdata}
              itemName="Students"
              maxWidth="49.37rem"
              isSortedBy
            />
          </ManualTableStyles>
        ) : null}
        {(formShow && btnShow) || !addStudents?.length ? (
          <div>
            <div
              className="add-student-modal"
              style={{padding: '1.5rem 0rem 0rem 0rem'}}
            >
              <div className="add-student-modal-body">
                <form>
                  <div className="d-flex form-fields">
                    <div className="fields">
                      <Controller
                        control={control}
                        name="referId"
                        render={fields => (
                          <Select
                            {...fields}
                            height="10rem"
                            isLarge={false}
                            errorMessage={
                              errors.referId?.message || formError.referId
                            }
                            autoFocus
                            formatOptionLabel={formatOptionLabel}
                            hasError={!!errors.referId || !!formError.referId}
                            label="Reference ID"
                            options={studentOptions}
                            placeholder="Enter Reference ID"
                            onChange={e => {
                              fields.onChange(e);
                              onSelectChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="fields">
                      <Controller
                        control={control}
                        name="firstName"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={errors?.firstName?.message}
                            hasError={!!errors.firstName}
                            label="First Name"
                            placeholder="Enter first name"
                            disabled
                          />
                        )}
                      />
                    </div>
                    <div className="field-last">
                      <Controller
                        control={control}
                        name="lastName"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={errors?.lastName?.message}
                            hasError={!!errors.lastName}
                            label="Last Name"
                            placeholder="Enter last name"
                            disabled
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex pb-4">
                    <Controller
                      control={control}
                      name="email"
                      render={fields => (
                        <TextInput
                          {...fields}
                          errorMessage={
                            errors.email?.message || formError.email
                          }
                          hasError={!!errors.email || !!formError.email}
                          label="Email"
                          placeholder="Enter email address"
                          disabled
                        />
                      )}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div
              className="button-container"
              style={{padding: '0rem 0rem 1.5rem 0rem'}}
            >
              {addStudents?.length && formShow ? (
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    reset();
                    setFormShow(false);
                    setBtnShow(false);
                  }}
                >
                  <span className="cancel-button-text">Cancel</span>
                </CustomCancel>
              ) : null}

              <CustomCancel
                className="save-changes"
                bgColor="#fff"
                padding="0.562rem 0.875rem"
                onClick={handleSubmit(onSubmit)}
              >
                <img src={AddIcon} alt="icon" style={{marginRight: '0.5rem'}} />
                <span className="add-button-text">
                  {addStudents.length > 0 ? 'Add to list' : 'Add Student'}
                </span>
              </CustomCancel>
            </div>
          </div>
        ) : (
          <div
            className="button-container"
            style={{padding: '1.5rem 0 1.5rem 0rem'}}
          >
            <CustomCancel
              className="save-changes"
              bgColor="#fff"
              padding="0.562rem 0.875rem"
              onClick={() => {
                setFormShow(true);
                setBtnShow(true);
              }}
            >
              <img src={AddIcon} alt="icon" style={{marginRight: '0.5rem'}} />
              <span className="add-button-text">Add another Student</span>
            </CustomCancel>
          </div>
        )}
      </div>
    </FormContainer>
  );
};
