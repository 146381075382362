import instance from '..';

export const verifyOrgAdmin = data =>
  instance.post('/auth/org-admin/verify', data);

export const verifyFreeAccountApi = data =>
  instance.post('/auth/org-admin/verify-free-join', data);

export const getImportStatus = organisationId =>
  instance.get(`/auth/${organisationId}/import-status`);
