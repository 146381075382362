import styled from 'styled-components';

import {AdminListingPageContainer} from '../../Admin/elements-new';

export const NotificationContainer = styled(AdminListingPageContainer)`
  padding: 7.0625rem 2rem 4.0625rem 2rem;
  &.is-admin {
    padding: 7.0625rem 2rem 4.0625rem 7rem;
  }
  .add-button {
    display: none !important;
  }
  .circle {
    border: 0.125rem solid white;
    border-radius: 1.25rem;
    font-size: 0.875rem;
    font-weight: 600;
    height: 2.5rem;
    width: 2.5rem;
    text-transform: uppercase;
  }
  .unseen {
    &.unseen-dot {
      background: #40a1ff;
      background: #40a1ff;
      margin-right: 2rem;
      width: 0.375rem;
      height: 0.375em;
      position: absolute;
      border-radius: 50%;
    }
  }

  .profile-creator {
    background-color: ${({theme}) => theme.colors.primary};
    color: white;
  }
  .notication-messages-data {
    display: flex;
    width: 100%;
    justify-content: space-between;
    outline: none;
    background: transparent;
    border: none;
  }
  .notication-messages {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .message-details {
      width: 100%;
      margin-left: 0.75rem;
      text-align: left;
      .message {
        width: 100%;
        font-weight: 400;
        font-size: 0.875rem;
        .message-title {
          font-weight: 600;
          font-size: 0.875rem;
          color: #0c181f;
        }
        &.delete {
          color: #ff6969;
        }
      }
      .received-details {
        display: flex;
        font-weight: 400;
        font-size: 0.75rem;
        color: #9ea3a5;
        margin-top: 0.25rem;
        .time {
          margin-left: 0.5rem;
        }
      }
    }
  }

  table {
    thead {
      display: none;
    }
    tbody {
      border-top: 0.0625rem solid #e0e0e0;
    }
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
  /* @media (max-width: 75rem) {
    table {
      tr {
        display: flex;
        align-items: center;
      }
      td {
        background: unset !important;
        text-align: left;
        :first-child {
          margin-right: 1rem;
        }
      }
    }
  } */
  @media (max-width: 45rem) {
    & {
      padding: 7.0625rem 1.5rem 4.0625rem 1.5rem !important;
    }
    &.is-admin {
      padding: 7.0625rem 2rem 4.0625rem 7rem !important;
    }
    table {
      tr {
        display: flex;
        align-items: center;
      }
      td {
        background: unset !important;
        text-align: left;
        /* :first-child {
          border-bottom: unset;
          margin-right: 1rem;
        } */
      }
    }
  }
  @media (max-width: 37.5rem) {
    &.is-admin {
      padding: 7.0625rem 1.5rem 4.0625rem 1.5rem !important;
    }
  }
`;
