import styled from 'styled-components';

export const UploadContainer = styled.div`
  .upload-title {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  .org-image-full {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    align-items: center;
    border: 0.0625rem solid #dedede;
    padding: 0.75rem 0.625rem;
    justify-content: space-between;
    border-radius: 0.3125rem;
    .org-image-after {
      display: flex;
      flex-direction: row;
    }
  }
`;
