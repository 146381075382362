/* eslint-disable react/prop-types */

import moment from 'moment';

import {useState} from 'react';
import {TableCellLink} from '../../../../../../../../../../../../components/common/Table/styles';
import {handleFileDownload} from '../../../../../../../../../../../../utils/downloadFile';

import ViewClassImg from '../../../../../../../../../../../../assets/icons/viewclass.svg';
import CurriculumDocumentIcon from '../../../../../../../../../../../../assets/icons/CurriculumDocument.svg';
import CurriculumAudioIcon from '../../../../../../../../../../../../assets/icons/CurriculumAudio.svg';
import CurriculumImageIcon from '../../../../../../../../../../../../assets/icons/CurriculumImage.svg';
import CurriculumVideoIcon from '../../../../../../../../../../../../assets/icons/CurriculumVideo.svg';
import CurriculumDownloadIcon from '../../../../../../../../../../../../assets/icons/curriculum-download-icon.svg';

import {FilePreviewModal} from './components';

// const DELAY = 500;

export const FileDetail = ({files, show, position}) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);

  function IconforFile(type) {
    let instructionIcon;

    if (type === 'Voice') {
      instructionIcon = CurriculumAudioIcon;
    } else if (type === 'Document') {
      instructionIcon = CurriculumDocumentIcon;
    } else if (type === 'Image') {
      instructionIcon = CurriculumImageIcon;
    } else if (type === 'Video') {
      instructionIcon = CurriculumVideoIcon;
    } else {
      instructionIcon = CurriculumDocumentIcon;
    }
    return instructionIcon;
  }

  return (
    <div>
      {show ? (
        <div className="file-container">
          {files.map((data, index) => (
            <div
              className="file-detail"
              key={data?.ocrf_id_course_curriculum_file}
            >
              <div className="d-flex mb-0 name-container">
                <p className="mb-0 fileposition mr-5">{`${position}.${
                  index + 1
                }`}</p>
                <div className="d-flex align-items-center">
                  <img
                    className="mb-0 fileicon mr-3"
                    alt="arrow"
                    src={IconforFile(data?.ft_type)}
                  />
                  <p className="mb-0 filename">{data?.fi_text_content}</p>
                </div>
              </div>
              <div className="d-flex date">
                Published on{' '}
                {moment(data?.occf_created_at).format('DD/MM/YYYY')}
              </div>
              <div className="d-flex btn-container justify-content-end">
                <div className="d-flex align-items-center " />
                <div className="container-second d-flex align-items-center">
                  <button
                    type="button"
                    className="button-download d-flex align-items-center"
                    title="download"
                    onClick={() => {
                      handleFileDownload({
                        filePath: `stream-${data?.fi_S3_filename}`,
                        fileName: data?.fi_filename,
                      });
                    }}
                  >
                    <img
                      className="mb-0 downloadicon"
                      alt="arrow"
                      src={CurriculumDownloadIcon}
                    />
                  </button>
                  <TableCellLink
                    onClick={() => {
                      setModalData(data);
                      setModalShow(true);
                    }}
                    type="button"
                    className="view-module ml-4"
                  >
                    <span> View </span>
                    <img
                      src={ViewClassImg}
                      alt="loader"
                      className="viewmodule-arrow"
                    />
                  </TableCellLink>
                </div>
              </div>
            </div>
          ))}
          <FilePreviewModal
            show={modalShow}
            curriculumFile={modalData}
            title="Confirm work submission"
            width="80rem"
            onHide={() => {
              setModalShow(false);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
