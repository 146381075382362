// import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import bg from '../../assets/images/login/background.png';
import jcLogo from '../../assets/icons/jclogo.svg';
import errorPage from '../../assets/images/login/404error.png';

const StyledDiv = styled.div`
  margin: 0 -15px;
  background-image: url(${bg});
  height: 100vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 500px) {
    & {
      padding: 0 15px;
    }
  }

  .error-message {
    width: 1055px;
    height: 100%;
    @media (max-width: 1055px) {
      & {
        width: 100%;
      }
    }
  }
  .container-img {
    height: 70%;
  }
  .logo {
    margin-bottom: 32px;
    width: 137px;
    height: 30px;
    @media (max-width: 400px) {
      & {
        width: 100%;
      }
    }
  }
  .message p {
    color: #ffffff;
    text-align: center;
  }
  .main-text {
    font-weight: 600;
    font-size: 32px;
    @media (max-width: 500px) {
      & {
        font-size: 24px;
      }
    }
  }
  .sub-text {
    font-size: 14px;
  }
`;

const ErrorMessage = () => (
  <StyledDiv className="d-flex flex-column justify-content-center align-items-center">
    <img src={jcLogo} alt="logo" className="logo" />
    <div className="container-img">
      <img src={errorPage} alt="logo" className="error-message" />
    </div>
    <div className="message">
      <p className="main-text">Whoops... This page is not available</p>
      <p className="sub-text">
        We cannot find the page what you are looking for
      </p>
    </div>
  </StyledDiv>
);

export default ErrorMessage;
