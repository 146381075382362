/* eslint-disable no-plusplus */
import {createSlice} from '@reduxjs/toolkit';

// import {useSelector} from 'react-redux';
import {
  fetchOrgBasicInfo,
  updateOrgBasicInfo,
  delOrgLogo,
  fetchOrgDepartments,
  deleteOrgDepartment,
  fetchAllDepartmentsList,
  addOrgDepartment,
  addOrgNewDepartment,
  fetchOrgAcademicYear,
  addOrgAcademicYear,
  editOrgAcademicYear,
  deleteOrgAcademicYear,
  fetchOrgAdmins,
  individualAdminValid,
  spreadSheetAdminsValid,
  addOrgAdmin,
  addOrgAdminSpread,
  resendAdminInvitation,
  editOrgAdmin,
  deleteOrgAdmin,
  fetchOrgTeachers,
  addOrgTeacher,
  editOrgTeacher,
  deleteOrgTeacher,
  individualTeacherValid,
  spreadSheetTeachersValid,
  resendTeacherInvitation,
  fetchOrgStudents,
  addOrgStudentSpread,
  addOrgStudentManual,
  individualStudentValid,
  spreadSheetStudentsValid,
  editOrgStudent,
  resendStudentInvitation,
  deleteOrgStudent,
  fetchAllUnits,
  fetchUnitById,
  fetchAllUnitsClass,
  fetchAllUnitsDepartments,
  fetchAllUnitsTeachers,
  addNewUnit,
  individualUnitStudentValid,
  individualUnitStudentSpreadsheetValid,
  addNewUnitStudent,
  fetchStudentForUnits,
  editUnitDetails,
  deleteUnitDetails,
  deleteOrgUnitStudent,
  editOrgUnitStudent,
  unitResendStudentInvitation,
  getDashboardData,
  editUnitDetailsById,
  fetchAllQualifications,
  addOrgQualification,
  individualQualificationValid,
  addUnitsUnderQualificationManual,
  addUnitsUnderQualificationSpread,
  editOrgQualification,
  fetchAllClassAcademicYear,
  fetchAllOrgQualifications,
  addNewClass,
  fetchAllClasses,
  fetchUnitsByType,
  addUnitsClass,
  deleteOrgQualification,
  spreadSheetQualificationsValid,
  addQualificationsSpread,
  individualUnitValid,
  unitSpreadsheetValidation,
  fetchQualificationUnits,
  editQualificationUnit,
  deleteQualificationUnit,
  fetchStudentForClass,
  fetchAllStudents,
  addClassStudents,
  individualClassStudentSpreadsheetValid,
  fetchAllUnitsWithQualification,
  fetchAllQualificationData,
  addUnitsManualQual,
  fetchClassById,
  getUnitById as getOneUnitById,
} from '../../api/admin';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    basicInfo: null,
    basicInfoLoading: true,
    basicInfoError: null,
    basicInfoLogoLoading: false,
    departments: [],
    departmentsLoading: false,
    departmentDataLoading: true,
    departmentsError: null,
    departmentsList: [],
    academicYear: [],
    academicYearCount: 0,
    adminTeacherCount: 0,
    academicYearLoading: false,
    academicYearError: null,
    admins: [],
    adminToastSuccess: '',
    adminsValidate: '',
    adminsLoading: true,
    adminsError: null,
    alreadyExistEmail: [],
    alreadyExistRefId: [],
    emailPattern: [],
    validAdmin: [],
    teachers: null,
    teacherToastSuccess: '',
    teachersLoading: true,
    teachersError: null,
    teacherValidate: '',
    validTeacher: [],
    students: [],
    studentsCount: 0,
    studentsLoading: true,
    studentsError: null,
    studentValidate: '',
    validStudent: [],
    studentToastSuccess: '',
    units: [],
    unitsLoading: true,
    unitsError: null,
    unit: null,
    unitLoading: true,
    unitError: null,
    studentCount: null,
    unitClass: [],
    unitClassLoading: true,
    unitClassError: null,
    unitDepartments: [],
    unitDepartmentsLoading: true,
    unitDepartmentsError: null,
    unitTeachers: [],
    unitTeachersLoading: true,
    unitTeachersError: null,
    unitStudentValidate: '',
    alreadyExistStudentEmail: [],
    alreadyExistStudentRefId: [],
    studentEmailPattern: [],
    setExistingStudentInUnit: [],
    validUnitStudent: [],
    currentUnit: [],
    unitStudentValidateError: '',
    unitToastSuccess: '',
    deleteUnitToastSuccess: '',
    viewUnitToastSuccess: '',
    dashboardData: null,
    dashboardDataLoading: true,
    academicDataLoading: false,
    userDataLoading: true,
    studentDataLoading: true,
    unitDataLoading: true,
    dashboardDataError: null,
    qualifications: [],
    qualificationsCount: null,
    qualificationsLoading: false,
    qualificationsDataLoading: false,

    classAcademicYear: [],
    classAcademicYearLoading: false,
    classAcademicYearError: null,
    orgQualifications: null,
    orgQualificationsLoading: false,
    orgQualificationsDataLoading: false,
    currentClass: [],
    classes: [],
    classesLoading: true,
    classesError: null,
    singleClass: null,
    classLoading: true,
    classError: null,
    classDataLoading: true,
    qualificationUnits: [],
    currentClassUnits: [],
    currentQualification: [],
    alreadyExistQname: [],
    alreadyExistQcode: [],
    validQualification: [],
    alreadyExistUname: [],
    alreadyExistUcode: [],
    duplicateUnitData: [],
    validUnitsToQualification: [],
    validateQualificationError: '',
    validateUnitError: '',
    qualificationToastSuccess: '',
    oneQualifications: null,
    oneQualificationsDataLoading: false,
    oneQualificationsLoading: false,
    classStudentValidate: '',
    classStudentValidateError: '',
    classStudent: null,
    allUnits: [],
    allUnitsCount: null,
    allUnitsLoading: false,
    allUnitsDataLoading: false,
    qualificationsList: null,
    validateUnitByQualError: '',
    allUnitToastSuccess: '',
    alreadyExistUnitName: [],
    alreadyExistUnitCode: [],
    validUnitsQualification: [],
    unitDetails: null,
    isLoadingUnitDetails: true,
    classToastSuccess: '',
  },
  reducers: {
    setBasicInfo: (state, action) => {
      state.basicInfo = action.payload;
    },
    setBasicInfoLoading: (state, action) => {
      state.basicInfoLoading = action.payload;
    },
    setBasicInfoError: (state, action) => {
      state.basicInfoError = action.payload;
    },
    setBasicInfoLogoLoading: (state, action) => {
      state.basicInfoLogoLoading = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setDepartmentsLoading: (state, action) => {
      state.departmentsLoading = action.payload;
    },
    setDepartmentsError: (state, action) => {
      state.departmentsError = action.payload;
    },
    setDepartmentsList: (state, action) => {
      state.departmentsList = action.payload;
    },
    setDepartmentsListLoading: (state, action) => {
      state.departmentsLoading = action.payload;
    },
    setDepartmentsListError: (state, action) => {
      state.departmentsError = action.payload;
    },
    appendDepartments: (state, action) => {
      action.payload.forEach(dept => state.departments.push(dept));
    },
    appendNewDepartment: (state, action) => {
      state.departmentsList = [action.payload, ...state.departmentsList];
    },
    clearDepartments: (state, action) => {
      state.departments = state.departments.filter(
        department => department.od_id_orgdepartment !== action.payload,
      );
    },
    setAcademicYear: (state, action) => {
      state.academicYear = action.payload;
    },
    setAcademicYearPaginate: (state, action) => {
      state.academicYear = [...state.academicYear, ...action.payload];
    },
    setAcademicYearCount: (state, action) => {
      state.academicYearCount = action.payload;
    },
    setAdminTeacherCount: (state, action) => {
      state.adminTeacherCount = action.payload;
    },
    appendAcademicYear: (state, action) => {
      state.academicYear = [action.payload, ...state.academicYear];
    },
    changeAcademicYear: (state, action) => {
      state.academicYear = state.academicYear.map(year =>
        year.oay_id_orgacadyear === action.payload[0].oay_id_orgacadyear
          ? action.payload[0]
          : year,
      );
    },
    setDuplicateUnitData: (state, action) => {
      state.duplicateUnitData = action.payload;
    },
    clearAcademicYear: (state, action) => {
      state.academicYear = state.academicYear.filter(
        year => year.oay_id_orgacadyear !== action.payload,
      );
    },
    setAcademicYearLoading: (state, action) => {
      state.academicYearLoading = action.payload;
    },
    setAcademicYearError: (state, action) => {
      state.academicYearError = action.payload;
    },
    setAdmins: (state, action) => {
      state.admins = action.payload;
    },
    validateAdmins: (state, action) => {
      state.adminsValidate = action.payload;
    },
    setAlreadyExistEmail: (state, action) => {
      state.alreadyExistEmail = action.payload;
    },
    setAlreadyExistRef: (state, action) => {
      state.alreadyExistRefId = action.payload;
    },
    setEmailPattern: (state, action) => {
      state.emailPattern = action.payload;
    },
    setValidAdmin: (state, action) => {
      state.validAdmin = action.payload;
    },
    setAdminsLoading: (state, action) => {
      state.adminsLoading = action.payload;
    },
    setAdminsError: (state, action) => {
      state.adminsError = action.payload;
    },
    setAdminToastSuccess: (state, action) => {
      state.adminToastSuccess = action.payload;
    },
    appendAdminDetails: (state, action) => {
      state.admins = [...state.admins, ...action.payload];
    },
    changeAdminDetail: (state, action) => {
      state.admins = state.admins.map(admin =>
        admin.up_id_user === action.payload.up_id_user ? action.payload : admin,
      );
    },
    clearAdmin: (state, action) => {
      state.admins = state.admins.filter(
        admin => admin.up_id_user !== action.payload,
      );
    },
    setTeachers: (state, action) => {
      state.teachers = action.payload;
    },
    setTeachersLoading: (state, action) => {
      state.teachersLoading = action.payload;
    },
    setTeachersError: (state, action) => {
      state.teachersError = action.payload;
    },
    appendTeacherDetails: (state, action) => {
      state.teachers = [...state.teachers, ...action.payload];
    },
    setTeacherToastSuccess: (state, action) => {
      state.teacherToastSuccess = action.payload;
    },
    changeTeacherDetail: (state, action) => {
      state.teachers = state.teachers.map(admin =>
        admin.up_id_userprofile === action.payload.up_id_userprofile
          ? action.payload
          : admin,
      );
    },
    clearTeacher: (state, action) => {
      state.teachers = state.teachers.filter(
        admin => admin.up_id_userprofile !== action.payload,
      );
    },
    validateTeacher: (state, action) => {
      state.teacherValidate = action.payload;
    },
    setValidTeacher: (state, action) => {
      state.validTeacher = action.payload;
    },
    setStudents: (state, action) => {
      state.students = action.payload;
    },
    setStudentsCount: (state, action) => {
      state.studentsCount = action.payload;
    },
    setStudentsLoading: (state, action) => {
      state.studentsLoading = action.payload;
    },
    setStudentsError: (state, action) => {
      state.studentsError = action.payload;
    },
    validateStudent: (state, action) => {
      state.studentValidate = action.payload;
    },
    setValidStudent: (state, action) => {
      state.validStudent = action.payload;
    },
    setStudentToastSuccess: (state, action) => {
      state.studentToastSuccess = action.payload;
    },
    appendStudentDetails: (state, action) => {
      state.students = [...state.students, ...action.payload];
    },
    changeStudentDetail: (state, action) => {
      state.students = state.students.map(student =>
        student.up_id_userprofile === action.payload.up_id_userprofile
          ? action.payload
          : student,
      );
    },
    clearStudent: (state, action) => {
      state.students = state.students.filter(
        student => student.up_id_userprofile !== action.payload,
      );
    },
    setUnits: (state, action) => {
      state.units = action.payload;
    },
    setUnitsLoading: (state, action) => {
      state.unitsLoading = action.payload;
    },
    setUnitsError: (state, action) => {
      state.unitsError = action.payload;
    },
    setSingleUnit: (state, action) => {
      state.unit = action.payload;
    },
    setSingleUnitLoading: (state, action) => {
      state.unitLoading = action.payload;
    },
    setSingleUnitError: (state, action) => {
      state.unitError = action.payload;
    },
    validateUnitStudent: (state, action) => {
      state.unitStudentValidate = action.payload;
    },
    validateUnitStudentError: (state, action) => {
      state.unitStudentValidateError = action.payload;
    },

    setStudentCount: (state, action) => {
      state.studentCount = action.payload;
    },
    deleteStudentCount: (state, action) => {
      state.studentCount -= action.payload;
    },
    deleteStudentsCount: (state, action) => {
      state.studentsCount -= action.payload;
    },
    setUnitClass: (state, action) => {
      state.unitClass = action.payload;
    },
    setUnitClassLoading: (state, action) => {
      state.unitClassLoading = action.payload;
    },
    setUnitClassError: (state, action) => {
      state.unitClassError = action.payload;
    },
    setUnitDepartments: (state, action) => {
      state.unitDepartments = action.payload;
    },
    setUnitDepartmentsLoading: (state, action) => {
      state.unitDepartmentsLoading = action.payload;
    },
    setUnitDepartmentsError: (state, action) => {
      state.unitDepartmentsError = action.payload;
    },
    setUnitTeachers: (state, action) => {
      state.unitTeachers = action.payload;
    },
    setUnitTeachersLoading: (state, action) => {
      state.unitTeachersLoading = action.payload;
    },
    setUnitTeachersError: (state, action) => {
      state.unitTeachersError = action.payload;
    },
    setAlreadyExistStudentEmail: (state, action) => {
      state.alreadyExistStudentEmail = action.payload;
    },
    setAlreadyExistStudentRef: (state, action) => {
      state.alreadyExistStudentRefId = action.payload;
    },
    setStudentEmailPattern: (state, action) => {
      state.studentEmailPattern = action.payload;
    },
    setExistingStudentInUnit: (state, action) => {
      state.existingStudentInUnit = action.payload;
    },
    setValidUnitStudent: (state, action) => {
      state.validUnitStudent = action.payload;
    },
    setCurrentNewUnit: (state, action) => {
      state.currentUnit = action.payload;
    },
    setEditUnit: (state, action) => {
      state.units = state.units.map(unit => {
        if (
          unit.orui_id_orgunitinstance ===
          action.payload.orui_id_orgunitinstance
        ) {
          return action.payload;
        }
        return unit;
      });
    },
    clearUnit: (state, action) => {
      state.units = state.units.filter(
        unit => unit.orui_id_orgunitinstance !== action.payload,
      );
    },
    clearUnitStudent: (state, action) => {
      state.unit.students = state.unit.students.filter(
        student => student.oruis_id_orgunitinstancestudent !== action.payload,
      );
    },
    changeUnitStudentDetail: (state, action) => {
      state.unit.students = state.unit.students.map(student =>
        student.oruis_id_orgunitinstancestudent ===
        action.payload.oruis_id_orgunitinstancestudent
          ? action.payload
          : student,
      );
    },
    setUnitToastSuccess: (state, action) => {
      state.unitToastSuccess = action.payload;
    },
    setDeletetUnitToastSuccess: (state, action) => {
      state.deleteUnitToastSuccess = action.payload;
    },
    setViewUnitToastSuccess: (state, action) => {
      state.viewUnitToastSuccess = action.payload;
    },
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    setDashboardDataLoading: (state, action) => {
      state.dashboardDataLoading = action.payload;
    },
    setDepartmentDataLoading: (state, action) => {
      state.departmentDataLoading = action.payload;
    },
    setAcademicDataLoading: (state, action) => {
      state.academicDataLoading = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    },
    setStudentDataLoading: (state, action) => {
      state.studentDataLoading = action.payload;
    },
    setUnitDataLoading: (state, action) => {
      state.unitDataLoading = action.payload;
    },
    setDashboardDataError: (state, action) => {
      state.dashboardDataError = action.payload;
    },
    setQualifications: (state, action) => {
      state.qualifications = action.payload;
    },
    setQualificationsCount: (state, action) => {
      state.qualificationsCount = action.payload;
    },
    setQualificationsLoading: (state, action) => {
      state.qualificationsLoading = action.payload;
    },
    setQualificationsDataLoading: (state, action) => {
      state.qualificationsDataLoading = action.payload;
    },
    appendQualification: (state, action) => {
      state.qualifications = [action.payload, ...state.qualifications];
    },
    changeQualification: (state, action) => {
      state.qualifications = state.qualifications.map(item =>
        item.oq_id_orgqual === action.payload.oq_id_orgqual
          ? action.payload
          : item,
      );
    },

    setClassAcademicYear: (state, action) => {
      state.classAcademicYear = action.payload;
    },
    setClassAcademicYearLoading: (state, action) => {
      state.classAcademicYearLoading = action.payload;
    },
    setClassAcademicYearError: (state, action) => {
      state.classAcademicYearError = action.payload;
    },
    setOrgQualifications: (state, action) => {
      state.orgQualifications = action.payload;
    },
    setOrgQualificationsLoading: (state, action) => {
      state.orgQualificationsLoading = action.payload;
    },
    setOrgQualificationsDataLoading: (state, action) => {
      state.orgQualificationsDataLoading = action.payload;
    },
    setCurrentNewClass: (state, action) => {
      state.currentClass = action.payload;
    },
    setClassesError: (state, action) => {
      state.classesError = action.payload;
    },
    setClasses: (state, action) => {
      state.classes = action.payload;
    },
    setClassesLoading: (state, action) => {
      state.classesLoading = action.payload;
    },
    setClassDataLoading: (state, action) => {
      state.classDataLoading = action.payload;
    },
    viewQualificationUnits: (state, action) => {
      state.qualificationUnits = action.payload;
    },
    setCurrentNewClassUnits: (state, action) => {
      state.currentClassUnits = action.payload;
    },
    clearQualification: (state, action) => {
      state.qualifications = state.qualifications.filter(
        item => item.oq_id_qual !== action.payload,
      );
    },
    setCurrentNewQualification: (state, action) => {
      state.currentQualification = action.payload;
    },
    setAlreadyExistQcode: (state, action) => {
      state.alreadyExistQcode = action.payload;
    },
    setAlreadyExistQname: (state, action) => {
      state.alreadyExistQname = action.payload;
    },
    setValidQualification: (state, action) => {
      state.validQualification = action.payload;
    },
    setQualificationToastSuccess: (state, action) => {
      state.qualificationToastSuccess = action.payload;
    },
    setAlreadyExistUcode: (state, action) => {
      state.alreadyExistUcode = action.payload;
    },
    setAlreadyExistUname: (state, action) => {
      state.alreadyExistUname = action.payload;
    },
    setValidUnitsToQualification: (state, action) => {
      state.validUnitsToQualification = action.payload;
    },
    setValidateUnitError: (state, action) => {
      state.validateUnitError = action.payload;
    },
    setValidateQualificationError: (state, action) => {
      state.validateQualificationError = action.payload;
    },
    setOneQualification: (state, action) => {
      state.oneQualifications = action.payload;
    },

    setOneQualificationsDataLoading: (state, action) => {
      state.oneQualificationsDataLoading = action.payload;
    },
    setOneQualificationsLoading: (state, action) => {
      state.oneQualificationsLoading = action.payload;
    },
    appendOneQualification: (state, action) => {
      state.qualifications = [action.payload, ...state.qualifications];
    },
    setAllUnits: (state, action) => {
      state.allUnits = action.payload;
    },

    setAllUnitsCount: (state, action) => {
      state.allUnitsCount = action.payload;
    },
    setAllUnitsLoading: (state, action) => {
      state.allUnitsLoading = action.payload;
    },
    setAllUnitsDataLoading: (state, action) => {
      state.allUnitsDataLoading = action.payload;
    },
    setAllUnitToastSuccess: (state, action) => {
      state.allUnitToastSuccess = action.payload;
    },
    setQualificationsList: (state, action) => {
      state.qualificationsList = action.payload;
    },
    changeQualificationOneUnit: (state, action) => {
      state.allUnits = state.allUnits.map(item =>
        item.ou_id_unit === action.payload.ou_id_unit ? action.payload : item,
      );
    },
    clearQualificationOneUnit: (state, action) => {
      state.allUnits = state.allUnits.filter(
        item => item.ou_id_unit !== action.payload,
      );
    },
    validateClassStudent: (state, action) => {
      state.classStudentValidate = action.payload;
    },
    validateClassStudentError: (state, action) => {
      state.classStudentValidateError = action.payload;
    },
    setSingleClass: (state, action) => {
      state.singleClass = action.payload;
    },
    setSingleClassLoading: (state, action) => {
      state.classLoading = action.payload;
    },
    setClassStudent: (state, action) => {
      state.classStudent = action.payload;
    },
    setValidateUnitByQualError: (state, action) => {
      state.validateUnitByQualError = action.payload;
    },
    setValidUnitsQualification: (state, action) => {
      state.validUnitsQualification = action.payload;
    },
    setAlreadyExistUnitCode: (state, action) => {
      state.alreadyExistUnitCode = action.payload;
    },
    setAlreadyExistUnitName: (state, action) => {
      state.alreadyExistUnitName = action.payload;
    },
    setSingleClassError: (state, action) => {
      state.classError = action.payload;
    },
    setUnitDetails: (state, action) => {
      state.unitDetails = action.payload;
    },
    clearUnitDetails: state => {
      state.unitDetails = null;
    },
    setIsLoadingUnitDetails: (state, action) => {
      state.isLoadingUnitDetails = action.payload;
    },
    setClassToastSuccess: (state, action) => {
      state.classToastSuccess = action.payload;
    },
  },
});

export const {
  setAdminTeacherCount,
  setStudents,
  setStudentsLoading,
  setStudentsError,
  setStudentsCount,
  validateStudent,
  setValidStudent,
  setStudentToastSuccess,
  appendStudentDetails,
  changeStudentDetail,
  clearStudent,
  setTeachers,
  setTeachersLoading,
  setTeachersError,
  validateTeacher,
  setValidTeacher,
  setTeacherToastSuccess,
  appendTeacherDetails,
  changeTeacherDetail,
  clearTeacher,
  clearAdmin,
  setValidAdmin,
  setAdminToastSuccess,
  validateAdmins,
  changeAdminDetail,
  appendAdminDetails,
  setAlreadyExistEmail,
  setAlreadyExistRef,
  setEmailPattern,
  setAdminsLoading,
  setAdminsError,
  setAdmins,
  setAcademicYear,
  setAcademicYearCount,
  setDuplicateUnitData,
  setAcademicYearPaginate,
  setAcademicYearLoading,
  setAcademicYearError,
  setDepartments,
  appendAcademicYear,
  changeAcademicYear,
  setDepartmentsLoading,
  setDepartmentsError,
  clearAcademicYear,
  setBasicInfo,
  setBasicInfoLoading,
  setBasicInfoError,
  setBasicInfoLogoLoading,
  setDepartmentsList,
  setDepartmentsListLoading,
  setDepartmentsListError,
  appendDepartments,
  appendNewDepartment,
  clearDepartments,
  setUnits,
  setUnitsLoading,
  setUnitsError,
  setSingleUnit,
  setSingleUnitLoading,
  setSingleUnitError,
  setStudentCount,
  deleteStudentCount,
  deleteStudentsCount,
  setUnitClass,
  setUnitClassLoading,
  setUnitClassError,
  setUnitDepartments,
  setUnitDepartmentsLoading,
  setUnitDepartmentsError,
  setUnitTeachers,
  setUnitTeachersLoading,
  setUnitTeachersError,
  validateUnitStudent,
  validateUnitStudentError,
  setAlreadyExistStudentEmail,
  setAlreadyExistStudentRef,
  setStudentEmailPattern,
  setExistingStudentInUnit,
  setValidUnitStudent,
  setCurrentNewUnit,
  setEditUnit,
  clearUnit,
  clearUnitStudent,
  changeUnitStudentDetail,
  setUnitToastSuccess,
  setDeletetUnitToastSuccess,
  setViewUnitToastSuccess,
  setDashboardData,
  setDashboardDataLoading,
  setDashboardDataError,
  setDepartmentDataLoading,
  setAcademicDataLoading,
  setUserDataLoading,
  setStudentDataLoading,
  setUnitDataLoading,
  setQualifications,
  setQualificationsCount,
  setQualificationsLoading,
  setQualificationsDataLoading,
  appendQualification,
  changeQualification,

  setClassAcademicYear,
  setClassAcademicYearLoading,
  setClassAcademicYearError,
  setOrgQualifications,
  setOrgQualificationsLoading,
  setOrgQualificationsDataLoading,
  setCurrentNewClass,
  setClassesError,
  setClasses,
  setClassesLoading,
  setClassDataLoading,
  viewQualificationUnits,
  setCurrentNewClassUnits,
  clearQualification,
  setCurrentNewQualification,
  setAlreadyExistQcode,
  setAlreadyExistQname,
  setValidQualification,
  setAlreadyExistUcode,
  setAlreadyExistUname,
  setValidateQualificationError,
  setValidateUnitError,
  setValidUnitsToQualification,
  setQualificationToastSuccess,
  setOneQualification,
  setOneQualificationsDataLoading,
  setOneQualificationsLoading,
  changeQualificationOneUnit,
  clearOneQualificationOneUnit,
  setSingleClass,
  setSingleClassLoading,
  validateClassStudent,
  validateClassStudentError,
  setClassStudent,
  clearQualificationOneUnit,
  setAllUnits,
  setQualificationsList,
  setAllUnitsCount,
  setAllUnitsLoading,
  setAllUnitsDataLoading,
  setAllUnitToastSuccess,
  setValidateUnitByQualError,
  setValidUnitsQualification,
  setAlreadyExistUnitCode,
  setAlreadyExistUnitName,
  setSingleClassError,
  setUnitDetails,
  clearUnitDetails,
  setIsLoadingUnitDetails,
  setClassToastSuccess,
} = adminSlice.actions;

export const getBasicInfo = () => async dispatch => {
  dispatch(setBasicInfoLoading(true));
  dispatch(setBasicInfoError(''));
  try {
    const resp = await fetchOrgBasicInfo();
    dispatch(setBasicInfoLoading(false));
    dispatch(setBasicInfo(resp.data.orgDetail));
  } catch (err) {
    dispatch(setBasicInfoLoading(false));
    dispatch(setBasicInfoError(err));
  }
};
export const getDashboard = () => async dispatch => {
  dispatch(setDashboardDataError(''));
  try {
    const resp = await getDashboardData();
    dispatch(setDashboardData(resp.data));
    dispatch(setDashboardDataLoading(false));
  } catch (err) {
    dispatch(setDashboardDataLoading(false));
    dispatch(setDashboardDataError(err));
  }
};
export const updateBasicInfo = (data, callback) => async dispatch => {
  dispatch(setBasicInfoLoading(true));
  try {
    const formData = new FormData();
    formData.append('orgname', data.orgname);
    formData.append('address', data.address);
    formData.append('logo', data.logo);
    if (data.logo) {
      dispatch(setBasicInfoLogoLoading(true));
    }
    const resp = await updateOrgBasicInfo(formData);
    dispatch(getDashboard());
    setTimeout(() => {
      dispatch(setBasicInfo(resp.data.basicInfo));
      dispatch(setBasicInfoLogoLoading(false));
      dispatch(setBasicInfoLoading(false));
      if (callback) {
        callback();
      }
    });
  } catch (err) {
    dispatch(setBasicInfoLoading(false));
    dispatch(setBasicInfoError(err));
    dispatch(setBasicInfoLogoLoading(false));
  }
};

export const deleteOrgLogo = () => async dispatch => {
  dispatch(setBasicInfoLoading(true));
  try {
    const resp = await delOrgLogo();
    dispatch(setBasicInfo(resp.data.basicInfo));
    dispatch(getDashboard());
    dispatch(setBasicInfoLoading(false));
  } catch (err) {
    dispatch(setBasicInfoLoading(false));
    dispatch(setBasicInfoError(err));
  }
};
export const getAcademicYear = offset => async dispatch => {
  dispatch(setAcademicYearLoading(true));
  dispatch(setAcademicDataLoading(true));
  try {
    const resp = await fetchOrgAcademicYear(offset, 10);
    dispatch(setAcademicYear(resp.data.orgAllAcademicYear));
    dispatch(setAcademicYearCount(resp.data.orgAcademicYearCount));
    dispatch(setAcademicYearLoading(false));
    dispatch(setAcademicDataLoading(false));
  } catch (err) {
    dispatch(setAcademicYearLoading(false));
    dispatch(setAcademicDataLoading(false));
    dispatch(setAcademicYearError(err));
  }
};
export const deleteAcademicYear =
  (orgAcadYearId, callback) => async dispatch => {
    dispatch(setAcademicYearLoading(true));
    dispatch(setAcademicDataLoading(true));
    try {
      const resp = await deleteOrgAcademicYear(orgAcadYearId);
      dispatch(clearAcademicYear(resp.data.orgAllAcademicYear));
      dispatch(setAcademicYearLoading(false));
      dispatch(setAcademicDataLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setAcademicYearLoading(false));
      dispatch(setAcademicDataLoading(false));
      dispatch(setAcademicYearError(err));
    }
  };
export const addAcademicYear = (data, callback) => async dispatch => {
  dispatch(setAcademicYearLoading(true));
  try {
    const resp = await addOrgAcademicYear(data);
    setTimeout(() => {
      dispatch(appendAcademicYear(resp.data.orgAllAcademicYear));
      dispatch(setAcademicYearLoading(false));
      if (callback) {
        callback();
      }
    }, 3000);
  } catch (err) {
    dispatch(setAcademicYearLoading(false));
    dispatch(setAcademicYearError(err));
  }
};

export const editAcademicYear =
  (orgAcadYearId, data, callback) => async dispatch => {
    dispatch(setAcademicYearLoading(true));
    try {
      const resp = await editOrgAcademicYear(orgAcadYearId, data);
      dispatch(changeAcademicYear(resp.data.orgAllAcademicYear));
      dispatch(setAcademicYearLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setAcademicYearLoading(false));
      dispatch(setAcademicYearError(err));
    }
  };

export const getAllDepartments = () => async dispatch => {
  dispatch(setDepartmentsLoading(true));
  dispatch(setDepartmentDataLoading(true));
  dispatch(setDepartmentsError(''));
  try {
    const resp = await fetchOrgDepartments();
    dispatch(setDepartmentsLoading(false));

    dispatch(setDepartmentDataLoading(false));
    dispatch(setDepartments(resp.data.orgDepartments));
  } catch (err) {
    dispatch(setDepartmentsLoading(false));
    dispatch(setDepartmentsError(err));
  }
};

export const deleteDepartment = (departmentId, callback) => async dispatch => {
  dispatch(setDepartmentsLoading(true));
  dispatch(setDepartmentsError(''));
  try {
    const resp = await deleteOrgDepartment(departmentId);
    dispatch(setDepartmentsLoading(false));
    dispatch(clearDepartments(resp.data.orgDepartments));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setDepartmentsLoading(false));
    dispatch(setDepartmentsError(err));
  }
};

export const getAllDepartmentsList = () => async dispatch => {
  dispatch(setDepartmentsListLoading(true));

  dispatch(setDepartmentsListError(''));
  try {
    const resp = await fetchAllDepartmentsList();
    dispatch(setDepartmentsListLoading(false));

    dispatch(setDepartmentsListLoading(false));
    dispatch(setDepartmentsList(resp.data.departments));
  } catch (err) {
    dispatch(setDepartmentsListLoading(false));
    dispatch(setDepartmentsListError(err));
  }
};

export const addDepartment = (data, callback) => async dispatch => {
  dispatch(setDepartmentsLoading(true));
  try {
    const resp = await addOrgDepartment(data);
    setTimeout(() => {
      dispatch(appendDepartments(resp.data.orgDepartments));
      dispatch(setDepartmentsLoading(false));
      if (callback) {
        callback(resp.data.departmentsCount);
      }
    }, 3000);
  } catch (err) {
    dispatch(setDepartmentsLoading(false));
    dispatch(setDepartmentsError(err));
  }
};

export const addNewDepartment = (data, callback) => async dispatch => {
  dispatch(setDepartmentsLoading(true));
  try {
    const newdept = {deptName: data};
    const resp = await addOrgNewDepartment(newdept);
    dispatch(appendNewDepartment(resp.data.newDepartment));
    dispatch(setDepartmentsLoading(false));
    if (callback) {
      callback(resp.data.newDepartment);
    }
  } catch (err) {
    dispatch(setDepartmentsLoading(false));
    dispatch(setDepartmentsError(err));
  }
};

// Admin Section

export const getAllAdmins = () => async dispatch => {
  dispatch(setAdminsLoading(true));
  dispatch(setUserDataLoading(true));
  try {
    const resp = await fetchOrgAdmins();
    dispatch(setAdmins(resp.data.orgAllAdmin[0]));
    dispatch(setAdminTeacherCount(resp.data.orgUsersCount[0][0]['COUNT(*)']));
    dispatch(setUserDataLoading(false));
    dispatch(setAdminsLoading(false));
  } catch (err) {
    dispatch(setAdminsLoading(false));
    dispatch(setAdminsError(err));
  }
};
export const addAdmin = (data, callback) => async dispatch => {
  dispatch(setAdminsLoading(true));
  try {
    await addOrgAdmin(data);
    // dispatch(setAdmins(resp.data.newUsers));
    dispatch(setAdminsLoading(false));
    dispatch(setAdminToastSuccess(`${data.length} Users added successfully`));
    setTimeout(() => dispatch(setAdminToastSuccess('')), 5000);
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setAdminsLoading(false));
    dispatch(setAdminsError(err));
  }
};

export const individualAdminCheck = (data, callback) => async dispatch => {
  dispatch(setAdminsLoading(true));
  try {
    await individualAdminValid(data);
    dispatch(setAdminsLoading(false));
    dispatch(validateAdmins(''));
    if (callback) {
      callback('');
    }
  } catch (err) {
    dispatch(setAdminsLoading(false));
    dispatch(validateAdmins(err.response.data.error));
    if (callback) {
      callback(err.response.data.error);
    }
  }
};
export const addAdminSpread =
  (data, isAdminSpreadsheet, isTeacherSpreadsheet, callback) =>
  async dispatch => {
    dispatch(setAdminsLoading(true));
    try {
      await addOrgAdminSpread(data, isAdminSpreadsheet, isTeacherSpreadsheet);
      // dispatch(setAdmins(resp.data.newUsers));
      dispatch(setAdminsLoading(false));
      dispatch(setAdminToastSuccess(`${data.length} Users added successfully`));

      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setAdminsLoading(false));
      dispatch(setAdminsError(err));
    }
  };
export const spreadSheetAdminsCheck =
  (data, isAdminSpreadsheet, isTeacherSpreadsheet, callback) =>
  async dispatch => {
    dispatch(setAdminsLoading(true));
    const resp = await spreadSheetAdminsValid(data);
    dispatch(setAlreadyExistEmail(resp.data.alreadyEmail));
    dispatch(setAlreadyExistRef(resp.data.alreadyRef));
    dispatch(setEmailPattern(resp.data.invalidEmails));
    dispatch(setValidAdmin(resp.data.validUsers));
    if (callback) {
      if (resp.data.validUsers.length === data.length) {
        dispatch(
          addAdminSpread(
            resp.data.validUsers,
            isAdminSpreadsheet,
            isTeacherSpreadsheet,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };
export const invitationResend = (adminId, data) => async dispatch => {
  dispatch(setAdminsLoading(true));
  try {
    dispatch(setAdminsLoading(true));
    const resp = await resendAdminInvitation(adminId, data);
    dispatch(changeAdminDetail(resp.data.invitationLink[0][0]));
  } catch (error) {
    dispatch(setAdminsLoading(false));
    dispatch(setAdminsError(error));
  }
};

export const editAdmin = (adminId, data, callback) => async dispatch => {
  dispatch(setAdminsLoading(true));
  try {
    const resp = await editOrgAdmin(adminId, data);
    dispatch(changeAdminDetail(resp.data.orgAdmin[0][0]));
    dispatch(setAdminsLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setAdminsLoading(false));
    dispatch(setAdminsError(err));
  }
};
export const deleteAdmin = (adminId, callback) => async dispatch => {
  dispatch(setAdminsLoading(true));
  try {
    const resp = await deleteOrgAdmin(adminId);
    dispatch(clearAdmin(resp.data.orgAdmin));
    dispatch(setAdminsLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setAdminsLoading(false));
    dispatch(setAdminsError(err));
  }
};

// Teacher section

export const getAllTeacher = () => async dispatch => {
  dispatch(setTeachersLoading(true));
  try {
    const resp = await fetchOrgTeachers();
    dispatch(setTeachers(resp.data.orgAllTeacher));
    dispatch(setTeachersLoading(false));
  } catch (err) {
    dispatch(setTeachersLoading(false));
    dispatch(setTeachersError(err));
  }
};
export const addTeacher = (data, callback) => async dispatch => {
  dispatch(setTeachersLoading(true));
  try {
    const resp = await addOrgTeacher(data);
    dispatch(appendTeacherDetails(resp.data.newTeachers));
    dispatch(setTeachersLoading(false));
    dispatch(
      setTeacherToastSuccess(
        `${resp.data.newTeachers.length} Teachers added successfully`,
      ),
    );
    setTimeout(() => dispatch(setTeacherToastSuccess('')), 5000);
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setTeachersLoading(false));
    dispatch(setTeachersError(err));
  }
};
export const editTeacher = (teacherId, data, callback) => async dispatch => {
  dispatch(setTeachersLoading(true));
  try {
    const resp = await editOrgTeacher(teacherId, data);
    dispatch(changeTeacherDetail(resp.data.orgTeacher));
    dispatch(setTeachersLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setTeachersLoading(false));
    dispatch(setTeachersError(err));
  }
};
export const deleteTeacher = (teacherId, callback) => async dispatch => {
  dispatch(setTeachersLoading(true));
  try {
    const resp = await deleteOrgTeacher(teacherId);
    dispatch(clearTeacher(resp.data.orgTeacher));
    dispatch(setTeachersLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setTeachersLoading(false));
    dispatch(setTeachersError(err));
  }
};
export const individualTeacherCheck = (data, callback) => async dispatch => {
  dispatch(setTeachersLoading(true));
  try {
    await individualTeacherValid(data);
    dispatch(setTeachersLoading(false));
    dispatch(validateTeacher(''));
    if (callback) {
      callback('');
    }
  } catch (err) {
    dispatch(setTeachersLoading(false));
    dispatch(validateTeacher(err.response.data.error));
    if (callback) {
      callback(err.response.data.error);
    }
  }
};
export const spreadSheetTeachersCheck = (data, callback) => async dispatch => {
  dispatch(setTeachersLoading(true));
  const resp = await spreadSheetTeachersValid(data);
  dispatch(setAlreadyExistEmail(resp.data.alreadyEmail));
  dispatch(setAlreadyExistRef(resp.data.alreadyRef));
  dispatch(setEmailPattern(resp.data.invalidEmails));
  dispatch(setValidTeacher(resp.data.validUsers));
  if (callback) {
    if (resp.data.validUsers.length === data.length) {
      dispatch(addTeacher(resp.data.validUsers, callback));
    } else {
      callback();
    }
  }
};
export const teacherInvitationResend = (teacherId, data) => async dispatch => {
  dispatch(setTeachersLoading(true));
  try {
    dispatch(setTeachersLoading(true));
    const resp = await resendTeacherInvitation(teacherId, data);
    dispatch(changeTeacherDetail(resp.data.invitationLink));
  } catch (error) {
    dispatch(setTeachersLoading(false));
    dispatch(setTeachersError(error));
  }
};

// Student Section
export const getAllStudent = () => async dispatch => {
  dispatch(setStudentsLoading(true));
  dispatch(setStudentDataLoading(true));
  try {
    const resp = await fetchOrgStudents();
    dispatch(setStudents(resp.data.orgAllStudent));
    dispatch(setStudentsCount(resp.data.studentCount));
    dispatch(setStudentsLoading(false));
    dispatch(setStudentDataLoading(false));
  } catch (err) {
    dispatch(setStudentsLoading(false));
    dispatch(setStudentsError(err));
  }
};
export const individualStudentCheck = (data, callback) => async dispatch => {
  dispatch(setStudentsLoading(true));
  try {
    await individualStudentValid(data);
    dispatch(setStudentsLoading(false));
    dispatch(validateStudent(''));
    if (callback) {
      callback('');
    }
  } catch (err) {
    dispatch(setStudentsLoading(false));
    dispatch(validateStudent(err.response.data.error));
    if (callback) {
      callback(err.response.data.error);
    }
  }
};
export const addStudentSpread = (data, callback) => async dispatch => {
  dispatch(setStudentsLoading(true));
  try {
    await addOrgStudentSpread(data);
    dispatch(
      setStudentToastSuccess(`${data.length} Students added successfully`),
    );
    setTimeout(() => dispatch(setStudentToastSuccess('')), 5000);
    dispatch(setStudentsLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setStudentsLoading(false));
    dispatch(setStudentsError(err));
  }
};
export const addStudentSpreadToast = validStudent => async dispatch => {
  dispatch(setStudentsLoading(true));
  dispatch(
    setStudentToastSuccess(
      `${validStudent.length} ${
        validStudent.length > 1 ? 'Students' : 'Student'
      } added successfully`,
    ),
  );
  setTimeout(() => dispatch(setStudentToastSuccess('')), 5000);
};
export const addStudentManual = (data, callback) => async dispatch => {
  dispatch(setStudentsLoading(true));
  try {
    const resp = await addOrgStudentManual(data);
    // dispatch(appendStudentDetails(resp.data.newStudents));
    dispatch(setStudentsLoading(false));
    dispatch(
      setStudentToastSuccess(
        `${resp.data.newStudents.length} Students added successfully`,
      ),
    );
    setTimeout(() => dispatch(setStudentToastSuccess('')), 5000);
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setStudentsLoading(false));
    dispatch(setStudentsError(err));
  }
};
export const spreadSheetStudentsCheck = (data, callback) => async dispatch => {
  dispatch(setStudentsLoading(true));
  const resp = await spreadSheetStudentsValid(data);
  dispatch(setAlreadyExistEmail(resp.data.alreadyEmail));
  dispatch(setAlreadyExistRef(resp.data.alreadyRef));
  dispatch(setEmailPattern(resp.data.invalidEmails));
  dispatch(setValidStudent(resp.data.validUsers));
  if (callback) {
    if (resp.data.validUsers.length === data.length) {
      dispatch(addStudentSpread(resp.data.validUsers, callback));
    } else {
      callback();
    }
  }
};
export const editStudent = (studentId, data, callback) => async dispatch => {
  dispatch(setStudentsLoading(true));
  try {
    const resp = await editOrgStudent(studentId, data);
    dispatch(changeStudentDetail(resp.data.orgStudent));
    dispatch(setStudentsLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setStudentsLoading(false));
    dispatch(setStudentsError(err));
  }
};
export const studentInvitationResend = (studentId, data) => async dispatch => {
  dispatch(setStudentsLoading(true));
  try {
    dispatch(setStudentsLoading(true));
    const resp = await resendStudentInvitation(studentId, data);
    dispatch(changeStudentDetail(resp.data.invitationLink));
  } catch (error) {
    dispatch(setStudentsLoading(false));
    dispatch(setStudentsError(error));
  }
};
export const deleteStudent = (studentId, callback) => async dispatch => {
  dispatch(setStudentsLoading(true));
  try {
    const resp = await deleteOrgStudent(studentId);
    dispatch(clearStudent(resp.data.studentId));
    dispatch(setStudentsLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setStudentsLoading(false));
    dispatch(setStudentsError(err));
  }
};

// Unit Section

export const getAllUnits = () => async dispatch => {
  dispatch(setUnitsLoading(true));
  dispatch(setUnitDataLoading(true));
  dispatch(setUnitsError(''));
  try {
    const resp = await fetchAllUnits();
    dispatch(setUnitsLoading(false));
    dispatch(setUnits(resp.data.allUnits));
    dispatch(setUnitDataLoading(false));
  } catch (err) {
    dispatch(setUnitsLoading(false));
    dispatch(setUnitsError(err));
  }
};

export const getUnitById = (unitId, offset) => async dispatch => {
  dispatch(setSingleUnitLoading(true));
  dispatch(setSingleUnitError(''));
  dispatch(setUnitDataLoading(true));
  try {
    const resp = await fetchUnitById(unitId, offset, 5);
    dispatch(setSingleUnitLoading(false));
    dispatch(setSingleUnit(resp.data.singleUnit));
    dispatch(setStudentCount(resp.data.studentCount));
    dispatch(setUnitDataLoading(false));
  } catch (err) {
    dispatch(setSingleUnitLoading(false));
    dispatch(setSingleUnitError(err));
  }
};

export const editUnitById =
  (data, orgUnitInstanceId, callback) => async dispatch => {
    dispatch(setUnitTeachersError(''));
    try {
      const resp = await editUnitDetailsById(data, orgUnitInstanceId);
      dispatch(setSingleUnit(resp.data.singleUnit));
      dispatch(setStudentCount(resp.data.studentCount));
      if (resp) {
        callback();
      }
    } catch (err) {
      dispatch(setUnitsError(err));
    }
  };

export const getAllUnitsClass = () => async dispatch => {
  dispatch(setUnitClassLoading(true));
  dispatch(setUnitClassError(''));
  try {
    const resp = await fetchAllUnitsClass();
    dispatch(setUnitClassLoading(false));
    dispatch(setUnitClass(resp.data.allClass));
  } catch (err) {
    dispatch(setUnitClassLoading(false));
    dispatch(setUnitClassError(err));
  }
};

export const getAllUnitsDepartments = () => async dispatch => {
  dispatch(setUnitDepartmentsLoading(true));
  dispatch(setUnitDepartmentsError(''));
  try {
    const resp = await fetchAllUnitsDepartments();
    dispatch(setUnitDepartmentsLoading(false));
    dispatch(setUnitDepartments(resp.data.allDepartments));
  } catch (err) {
    dispatch(setUnitDepartmentsLoading(false));
    dispatch(setUnitDepartmentsError(err));
  }
};

export const getAllUnitsTeachers = () => async dispatch => {
  dispatch(setUnitTeachersLoading(true));
  dispatch(setUnitTeachersError(''));
  try {
    const resp = await fetchAllUnitsTeachers();
    dispatch(setUnitTeachersLoading(false));
    dispatch(setUnitTeachers(resp.data.allTeachers));
  } catch (err) {
    dispatch(setUnitTeachersLoading(false));
    dispatch(setUnitTeachersError(err));
  }
};

export const addUnit = (data, callback) => async dispatch => {
  dispatch(setUnitTeachersError(''));
  try {
    const resp = await addNewUnit(data);
    dispatch(setCurrentNewUnit(resp.data));
    if (resp) {
      callback();
    }
  } catch (err) {
    dispatch(setUnitsError(err));
  }
};

export const fetchStudentInOrg = (data, callback) => async dispatch => {
  dispatch(setSingleUnitLoading(true));
  try {
    const resp = await fetchStudentForUnits(data);
    dispatch(setSingleUnit(false));
    dispatch(validateUnitStudent(''));
    dispatch(setSingleUnitLoading(false));
    dispatch(validateUnitStudent(resp.data.existingData));
    if (callback && resp.data.field) {
      callback(resp.data.field);
    }
  } catch (err) {
    // if (callback) {
    //   callback(err.response.data.error);
    // }
  }
};
export const individualUnitStudentCheck =
  (data, callback) => async (dispatch, getState) => {
    const {
      admin: {currentUnit},
    } = getState();
    dispatch(setSingleUnitLoading(true));
    try {
      await individualUnitStudentValid({
        ...data,
        orgUnitInstanceId:
          currentUnit?.orgUnitInstance?.orui_id_orgunitinstance ||
          currentUnit.orui_id_orgunitinstance,
      });
      dispatch(setSingleUnit(false));
      dispatch(validateUnitStudentError(''));
      dispatch(validateUnitStudent(''));
      if (callback) {
        callback('');
      }
    } catch (err) {
      dispatch(setSingleUnitLoading(false));
      dispatch(validateUnitStudentError(err.response.data.error));
      dispatch(validateUnitStudent(''));
      if (callback) {
        callback(err.response.data.error);
      }
    }
  };

export const addStudentsUnderNewUnit =
  (data, oruiIdOrgunitinstance, existingUnit, callback) =>
  async (dispatch, getState) => {
    const {
      admin: {currentUnit},
    } = getState();
    dispatch(setUnitsLoading(true));
    dispatch(setUnitsError(''));
    try {
      await addNewUnitStudent(data, oruiIdOrgunitinstance);
      dispatch(setUnitsLoading(false));

      if (!existingUnit) {
        // {
        //   dispatch(
        //     setUnitToastSuccess(
        //       `${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname} unit has been added successfully with ${data.length} Students `,
        //     ),
        //   );
        //   setTimeout(() => dispatch(setUnitToastSuccess('')), 5000);
        // }
        //  else
        dispatch(
          setUnitToastSuccess(
            `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} has been added successfully with ${data.length} Students `,
          ),
        );
        setTimeout(() => dispatch(setUnitToastSuccess('')), 5000);
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setUnitsLoading(false));
      dispatch(setUnitsError(err));
    }
  };

export const spreadSheetStudentCheck =
  (data, existingUnit, callback) => async (dispatch, getState) => {
    const {
      admin: {currentUnit},
    } = getState();
    dispatch(setUnitsLoading(true));
    const orgInstanceId = existingUnit
      ? existingUnit.orui_id_orgunitinstance
      : currentUnit.orgUnitInstance.orui_id_orgunitinstance;
    const resp = await individualUnitStudentSpreadsheetValid(
      data,
      orgInstanceId,
    );
    dispatch(setAlreadyExistStudentEmail(resp.data.alreadyEmail));
    dispatch(setAlreadyExistStudentRef(resp.data.alreadyRef));
    dispatch(setStudentEmailPattern(resp.data.invalidEmails));
    dispatch(setExistingStudentInUnit(resp.data.alreadyInUnit));
    dispatch(setValidUnitStudent(resp.data.validUsers));
    if (callback) {
      if (resp.data.validUsers.length === data.length) {
        dispatch(
          addStudentsUnderNewUnit(
            resp.data.validUsers,
            orgInstanceId,
            existingUnit,
            callback,
          ),
        );
        dispatch(
          setUnitToastSuccess(
            `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} Physics Unit has been added successfully with ${resp.data.validUsers.length} Students `,
          ),
        );
        setTimeout(() => dispatch(setUnitToastSuccess('')), 5000);
      } else {
        callback();
      }
    }
  };

export const editUnit =
  (data, orgUnitInstanceId, callback) => async dispatch => {
    dispatch(setUnitTeachersError(''));
    try {
      const resp = await editUnitDetails(data, orgUnitInstanceId);
      dispatch(setEditUnit(resp.data.singleUnit));
      dispatch(getAllUnitsClass());
      if (resp) {
        callback();
      }
    } catch (err) {
      dispatch(setUnitsError(err));
    }
  };

export const deleteUnit = (orgUnitInstanceId, callback) => async dispatch => {
  dispatch(setUnitsLoading(true));
  try {
    const resp = await deleteUnitDetails(orgUnitInstanceId);
    dispatch(clearUnit(resp.data.orgUnitInstanceId));
    dispatch(setUnitsLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setUnitsLoading(false));
    dispatch(setUnitsError(err));
  }
};

export const deleteStudentFromUnit =
  (orgUnitInstanceId, orgUnitInstanceStudentId, callback) => async dispatch => {
    dispatch(setUnitsLoading(true));
    try {
      const resp = await deleteOrgUnitStudent(
        orgUnitInstanceId,
        orgUnitInstanceStudentId,
      );
      dispatch(clearUnitStudent(resp.data.orgUnitInstanceStudentId));
      dispatch(deleteStudentCount(1));
      dispatch(setUnitsLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setUnitsLoading(false));
      dispatch(setUnitsError(err));
    }
  };
export const editUnitStudent =
  (studentId, unitInstanceStudentId, data, callback) => async dispatch => {
    dispatch(setUnitsLoading(true));
    try {
      const resp = await editOrgUnitStudent(
        studentId,
        unitInstanceStudentId,
        data,
      );
      dispatch(changeUnitStudentDetail(resp.data.orgUnitStudent));
      dispatch(setUnitsLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setUnitsLoading(false));
      dispatch(setUnitsError(err));
    }
  };

export const unitStudentResendInvitation =
  (studentId, unitInstanceStudentId, data) => async dispatch => {
    dispatch(setUnitsLoading(true));
    try {
      dispatch(setStudentsLoading(true));
      const resp = await unitResendStudentInvitation(
        studentId,
        unitInstanceStudentId,
        data,
      );
      dispatch(changeUnitStudentDetail(resp.data.invitationLink));
    } catch (error) {
      dispatch(setUnitsLoading(false));
      dispatch(setUnitsError(error));
    }
  };

// qualifications section
export const getAllQualifications = () => async dispatch => {
  dispatch(setQualificationsLoading(true));
  try {
    const resp = await fetchAllQualifications();
    dispatch(setQualificationsLoading(false));
    dispatch(setQualifications(resp.data.allQualifications));
    dispatch(setQualificationsCount(resp.data.allQualificationsCount));
    dispatch(setQualificationsLoading(false));
  } catch (err) {
    dispatch(setQualificationsLoading(false));
  }
};

export const addNewOrgQualification = (data, callback) => async dispatch => {
  dispatch(setQualificationsDataLoading(true));
  try {
    const resp = await addOrgQualification(data);
    dispatch(setCurrentNewQualification(resp.data.oneQualifications));
    dispatch(setQualificationsDataLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setQualificationsDataLoading(false));
  }
};
export const addUnitsToQualificationManual =
  (data, qualificationId, callback) => async (dispatch, getState) => {
    dispatch(setQualificationsDataLoading(true));
    try {
      const {
        admin: {currentQualification},
      } = getState();
      await addUnitsUnderQualificationManual(data, qualificationId);
      dispatch(
        setQualificationToastSuccess(
          `${currentQualification?.Qualifications?.qu_qualification_name} has been added successfully with ${data.length} Units `,
        ),
      );
      setTimeout(() => dispatch(setQualificationToastSuccess('')), 5000);
      dispatch(setQualificationsDataLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
    }
  };
export const addUnitsToQualificationSpread =
  (data, qualificationId, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await addUnitsUnderQualificationSpread(data, qualificationId);

      dispatch(setQualificationsDataLoading(false));
      if (callback) {
        callback(data);
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
    }
  };
export const spreadSheetUnitCheckToQualification =
  (data, qualificationId, callback) => async (dispatch, getState) => {
    dispatch(setUnitsLoading(true));

    const {
      admin: {currentQualification},
    } = getState();
    const resp = await unitSpreadsheetValidation(data, qualificationId);
    dispatch(setAlreadyExistUcode(resp.data.alreadyUnitCode));
    dispatch(setValidUnitsToQualification(resp.data.validUnits));
    if (callback) {
      if (resp.data.validUnits.length === data.length) {
        dispatch(
          addUnitsToQualificationSpread(
            resp.data.validUnits,
            currentQualification?.Qualifications?.qu_id,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };

export const editOrgQualifications =
  (qualificationId, data, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      const resp = await editOrgQualification(qualificationId, data);
      dispatch(changeQualification(resp.data.oneQualifications));
      dispatch(setQualificationsDataLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
    }
  };
export const addQualificationSpread = (data, callback) => async dispatch => {
  dispatch(setQualificationsDataLoading(true));
  try {
    await addQualificationsSpread(data);
    dispatch(
      setQualificationToastSuccess(
        `${data.length} Qualifications has been added successfully`,
      ),
    );
    setTimeout(() => dispatch(setQualificationToastSuccess('')), 5000);
    dispatch(setQualificationsDataLoading(false));
    if (callback) {
      callback();
    }
  } catch (err) {
    dispatch(setQualificationsDataLoading(false));
  }
};
export const spreadSheetQualificationsCheck =
  (data, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    const resp = await spreadSheetQualificationsValid(data);
    dispatch(setAlreadyExistQname(resp.data.alreadyQualificationName));
    dispatch(setAlreadyExistQcode(resp.data.alreadyQualificationCode));
    dispatch(setValidQualification(resp.data.validQualifications));
    dispatch(setQualificationsDataLoading(false));

    if (callback) {
      if (resp.data.validQualifications.length === data.length) {
        dispatch(
          addQualificationSpread(resp.data.validQualifications, callback),
        );
      } else {
        callback();
      }
    }
  };
export const individualQualificationCheck =
  (data, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await individualQualificationValid(data);
      dispatch(setValidateQualificationError(''));
      if (callback) {
        callback('');
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
      dispatch(setValidateQualificationError(err.response.data.error));
      if (callback) {
        callback(err.response.data.error);
      }
    }
  };
export const individualUnitCheck =
  (data, qualificationId, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await individualUnitValid(data, qualificationId);
      dispatch(setValidateUnitError(''));
      if (callback) {
        callback('');
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
      dispatch(setValidateUnitError(err.response.data.error));
      if (callback) {
        callback(err.response.data.error);
      }
    }
  };
export const deleteOrgQualifications =
  (qualificationId, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      const resp = await deleteOrgQualification(qualificationId);
      dispatch(clearQualification(resp.data.OrgQualificationId));
      dispatch(setQualificationsCount(resp.data.allQualificationsCount));
      dispatch(setQualificationsDataLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
    }
  };
export const getQualificationUnits = qualificationId => async dispatch => {
  dispatch(setOneQualificationsLoading(true));
  try {
    const resp = await fetchQualificationUnits(qualificationId);
    dispatch(setOneQualification(resp.data.oneQualificationUnits));
    dispatch(setOneQualificationsLoading(false));
  } catch (err) {
    dispatch(setOneQualificationsLoading(false));
  }
};

export const getAllUnitsWithQualification = () => async dispatch => {
  dispatch(setAllUnitsDataLoading(true));
  try {
    const resp = await fetchAllUnitsWithQualification();
    dispatch(setQualificationsLoading(false));
    dispatch(setAllUnits(resp.data.allUnits));
    dispatch(setAllUnitsCount(resp.data.allUnitsCount));
    dispatch(setAllUnitsDataLoading(false));
  } catch (err) {
    dispatch(setAllUnitsDataLoading(false));
  }
};
export const fetchAllQualification = () => async dispatch => {
  dispatch(setAllUnitsLoading(true));
  try {
    const resp = await fetchAllQualificationData();
    dispatch(setAllUnitsLoading(false));
    dispatch(setQualificationsList(resp.data.allQualificationsList));
    dispatch(setAllUnitsLoading(false));
  } catch (err) {
    dispatch(setAllUnitsLoading(false));
  }
};
export const editQualificationUnitById =
  (unitId, data, callback) => async dispatch => {
    dispatch(setAllUnitsLoading(true));
    try {
      const resp = await editQualificationUnit(unitId, data);
      dispatch(changeQualificationOneUnit(resp.data.editUnit));
      dispatch(setAllUnitsLoading(false));
      callback();
    } catch (err) {
      dispatch(setAllUnitsLoading(false));
    }
  };
export const deleteQualificationUnitById =
  (unitId, callback) => async dispatch => {
    dispatch(setOneQualificationsDataLoading(true));
    try {
      const resp = await deleteQualificationUnit(unitId);
      dispatch(clearQualificationOneUnit(resp.data.UnitId));
      dispatch(setAllUnitsCount(resp.data.allUnitsCount));
      dispatch(setOneQualificationsDataLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setOneQualificationsDataLoading(false));
    }
  };
export const individualUnitCheckByQualId =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await individualUnitValid(data, qualificationData.qualificationId);
      dispatch(setValidateUnitError(''));
      if (callback) {
        callback('');
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
      dispatch(setValidateUnitError(err.response.data.error));
      if (callback) {
        callback(err.response.data.error);
      }
    }
  };
export const addUnitsByQualificationIdManual =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await addUnitsUnderQualificationManual(
        data,
        qualificationData.qualificationId,
      );
      dispatch(
        setQualificationToastSuccess(
          `${qualificationData.qualificationName} has been added successfully with ${data.length} Units `,
        ),
      );
      setTimeout(() => dispatch(setQualificationToastSuccess('')), 5000);
      dispatch(setQualificationsDataLoading(false));
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
    }
  };
export const addUnitsByQualIdSpread =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await addUnitsUnderQualificationSpread(
        data,
        qualificationData.qualificationId,
      );

      dispatch(setQualificationsDataLoading(false));
      if (callback) {
        callback(data);
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
    }
  };
export const spreadSheetUnitCheckByQualificationId =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setUnitsLoading(true));
    const resp = await unitSpreadsheetValidation(
      data,
      qualificationData.qualificationId,
    );
    dispatch(setAlreadyExistUcode(resp.data.alreadyUnitCode));
    dispatch(setValidUnitsToQualification(resp.data.validUnits));
    if (callback) {
      if (resp.data.validUnits.length === data.length) {
        dispatch(
          addUnitsByQualIdSpread(
            resp.data.validUnits,
            qualificationData,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };
export const individualUnitCheckByQualificationId =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setQualificationsDataLoading(true));
    try {
      await individualUnitValid(
        data,
        qualificationData[0].tqual_id_tgovqualification,
      );
      dispatch(setValidateUnitByQualError(''));
      if (callback) {
        callback('');
      }
    } catch (err) {
      dispatch(setQualificationsDataLoading(false));
      dispatch(setValidateUnitByQualError(err.response.data.error));
      if (callback) {
        callback(err.response.data.error);
      }
    }
  };
export const addUnitsByQualManual = (data, callback) => async dispatch => {
  dispatch(setAllUnitsLoading(true));
  try {
    await addUnitsManualQual(data);
    setTimeout(() => {
      dispatch(setAllUnitToastSuccess(''));
    }, 5000);
    dispatch(setAllUnitsLoading(false));
    if (callback) {
      callback(data);
    }
  } catch (err) {
    dispatch(setAllUnitsLoading(false));
  }
};
export const addUnitsByQualSpread =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setAllUnitsLoading(true));
    try {
      await addUnitsUnderQualificationSpread(
        data,
        qualificationData[0].tqual_id_tgovqualification,
      );

      dispatch(setAllUnitsLoading(false));
      if (callback) {
        callback(data);
      }
    } catch (err) {
      dispatch(setAllUnitsLoading(false));
    }
  };
export const spreadSheetUnitCheckByQualification =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setAllUnitsLoading(true));
    const resp = await unitSpreadsheetValidation(
      data,
      qualificationData[0].tqual_id_tgovqualification,
    );
    dispatch(setAlreadyExistUnitCode(resp.data.alreadyUnitCode));
    dispatch(setValidUnitsQualification(resp.data.validUnits));
    dispatch(setDuplicateUnitData(resp.data.duplicateUnitData));
    dispatch(setAllUnitsLoading(false));
    if (callback) {
      if (resp.data.validUnits.length === data.length) {
        dispatch(
          addUnitsByQualSpread(
            resp.data.validUnits,
            qualificationData,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };
// Classes

export const getAllAcademicYear = () => async dispatch => {
  dispatch(setClassAcademicYearLoading(true));
  dispatch(setClassAcademicYearError(''));
  try {
    const resp = await fetchAllClassAcademicYear();
    dispatch(setClassAcademicYearLoading(false));
    dispatch(setClassAcademicYear(resp.data.classAllAcademicYear));
  } catch (err) {
    dispatch(setClassAcademicYearLoading(false));
    dispatch(setClassAcademicYearError(err));
  }
};

export const getAllOrgQualifications = () => async dispatch => {
  dispatch(setOrgQualificationsLoading(true));
  try {
    const resp = await fetchAllOrgQualifications();
    dispatch(setOrgQualificationsLoading(false));
    dispatch(setOrgQualifications(resp.data.allOrgQualifications));
    dispatch(setOrgQualificationsLoading(false));
  } catch (err) {
    dispatch(setOrgQualificationsLoading(false));
  }
};

export const addClass = (data, callback) => async dispatch => {
  dispatch(setUnitTeachersError(''));
  try {
    const resp = await addNewClass(data);
    dispatch(setCurrentNewClass(resp.data));
    dispatch(viewQualificationUnits(resp.data.allQualificationUnits[0]));
    if (resp) {
      callback();
    }
  } catch (err) {
    dispatch(setClassesError(err));
  }
};

export const getUnitsByType = type => async (dispatch, getState) => {
  try {
    const {
      admin: {currentClass},
    } = getState();
    const qualId = currentClass?.allQualificationUnits[0]?.oq_id_qual;
    const resp = await fetchUnitsByType(qualId, type);
    dispatch(viewQualificationUnits(resp.data.allQualificationUnits[0]));
  } catch (err) {
    dispatch(setClassesError(err));
  }
};

export const getAllClasses =
  (academicYearId, qualificationId) => async dispatch => {
    dispatch(setClassesLoading(true));
    dispatch(setClassDataLoading(true));
    dispatch(setClassesError(''));
    try {
      const resp = await fetchAllClasses(academicYearId, qualificationId);
      dispatch(setClasses(resp.data.allClasses));
      dispatch(setClassesLoading(false));
      dispatch(setClassDataLoading(false));
    } catch (err) {
      dispatch(setClassesLoading(false));
      dispatch(setClassesError(err));
    }
  };

export const addUnitsToClass =
  (currentClass, orgUnit, callback) => async dispatch => {
    dispatch(setUnitTeachersError(''));
    try {
      const unitData = {currentclass: currentClass, orgunit: orgUnit};
      const resp = await addUnitsClass(unitData);
      dispatch(setCurrentNewClassUnits(resp.data));
      // dispatch(viewQualificationUnits(resp.data.allQualificationUnits[0]));
      if (resp) {
        callback();
      }
    } catch (err) {
      dispatch(setClassesError(err));
    }
  };

export const setStudentDataByIds = (data, callback) => async dispatch => {
  dispatch(setSingleClassLoading(true));
  try {
    const resp = await fetchStudentForClass(data);
    dispatch(setSingleClass(false));
    dispatch(validateClassStudent(''));
    dispatch(setSingleClassLoading(false));
    dispatch(validateClassStudent(resp.data.existingData));
    if (callback && resp.data.field) {
      callback(resp.data.field);
    }
  } catch (err) {
    // if (callback) {
    //   callback(err.response.data.error);
    // }
  }
};

export const getAllOrgStudents = () => async dispatch => {
  dispatch(setClassesLoading(true));
  dispatch(setClassDataLoading(true));
  dispatch(setClassesError(''));
  try {
    const resp = await fetchAllStudents();
    dispatch(setClassesLoading(false));
    dispatch(setClassStudent(resp.data.orgAllStudent));
    dispatch(setClassDataLoading(false));
  } catch (err) {
    dispatch(setClassesLoading(false));
    dispatch(setClassesError(err));
  }
};

export const addStudentsUnderNewClass =
  (data, callback) => async (dispatch, getState) => {
    const {
      admin: {currentClass},
    } = getState();
    dispatch(setClassesLoading(true));
    dispatch(setClassesError(''));
    try {
      const classData = {currentclass: currentClass, students: data};
      await addClassStudents(classData);
      dispatch(setClassesLoading(false));

      // if (!existingUnit) {
      //   // {
      //   //   dispatch(
      //   //     setUnitToastSuccess(
      //   //       `${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname} unit has been added successfully with ${data.length} Students `,
      //   //     ),
      //   //   );
      //   //   setTimeout(() => dispatch(setUnitToastSuccess('')), 5000);
      //   // }
      //   //  else
      //   dispatch(
      //     setUnitToastSuccess(
      //       `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} has been added successfully with ${data.length} Students `,
      //     ),
      //   );
      //   setTimeout(() => dispatch(setUnitToastSuccess('')), 5000);
      // }
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setClassesLoading(false));
      dispatch(setClassesError(err));
    }
  };

export const spreadSheetClassStudentCheck =
  (
    data,
    // existingUnit,
    callback,
  ) =>
  async dispatch => {
    // const {
    //   admin: {currentClass},
    // } = getState();
    dispatch(setClassesLoading(true));
    // const orgInstanceId = existingUnit
    //   ? existingUnit.orui_id_orgunitinstance
    //   : currentUnit.orgUnitInstance.orui_id_orgunitinstance;
    const resp = await individualClassStudentSpreadsheetValid(data);
    // dispatch(setAlreadyExistStudentEmail(resp.data.alreadyEmail));
    // dispatch(setAlreadyExistStudentRef(resp.data.alreadyRef));
    dispatch(setStudentEmailPattern(resp.data.invalidEmails));
    // dispatch(setExistingStudentInUnit(resp.data.alreadyInUnit));
    dispatch(setValidUnitStudent(resp.data.validUsers));
    if (callback) {
      if (data.length === resp.data.validUsers.length) {
        dispatch(addStudentsUnderNewClass(resp.data.validUsers, callback));
      } else {
        callback();
      }
    }
  };

export const getClassById = orgClassId => async dispatch => {
  dispatch(setSingleClassLoading(true));
  dispatch(setSingleClassError(''));
  dispatch(setClassDataLoading(true));
  try {
    const resp = await fetchClassById(orgClassId);
    dispatch(setSingleClassLoading(false));
    dispatch(setSingleClass(resp.data.singleClassData));
    // dispatch(setStudentCount(resp.data.studentCount));
    dispatch(setClassDataLoading(false));
  } catch (err) {
    dispatch(setSingleClassLoading(false));
    dispatch(setSingleClassError(err));
  }
};

/** Fetch details of one unit by its ID. */
export const fetchOneUnitById = unitId => async dispatch => {
  try {
    dispatch(setIsLoadingUnitDetails(true));
    const {
      data: {unit},
    } = await getOneUnitById(unitId);
    dispatch(setUnitDetails(unit));
  } finally {
    dispatch(setIsLoadingUnitDetails(false));
  }
};

export const adminInfo = state => state.admin;

export default adminSlice.reducer;
