import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const ClassesContainer = styled(AdminListingPageContainer)`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 3%;
  }

  .class-name {
    width: 11%;
  }
  .course-name {
    width: 10%;
  }

  .qualification-name {
    width: 17%;
  }

  .unit-count {
    width: 9%;
  }
  .student-count {
    width: 12%;
  }
  .teacher-count {
    width: 12%;
  }
  .academic-year {
    width: 12%;
  }
  .view {
    width: 12%;
  }
  .more-option {
    width: 2%;
  }
  .view-class {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.312rem;
    padding: 0.75rem 1rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
    .viewclass-arrow {
      margin-left: 0.75rem;
      vertical-align: middle;
    }
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
  .code {
    font-size: 0.875rem;
    color: #8a8c94;
    margin-top: 0.5rem;
  }
`;
