import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Color} from '../../../../../../../constants';
import Session from './Session';

const RecordedSessionsContainer = styled.div`
  padding-bottom: 0.625rem;

  a:hover {
    text-decoration: none;
  }

  .title {
    color: ${Color.PRIMARY_TEXT};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .link {
    color: ${Color.PRIMARY};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.6;
  }
`;

const RecordedSessions = ({recordedSessions}) => (
  <RecordedSessionsContainer className="pt-3 px-3">
    <div className="align-items-center d-flex justify-content-between mb-2">
      <h3 className="title mb-0">Recorded Sessions</h3>
      <Link className="link" to="/">
        View all
      </Link>
    </div>
    {recordedSessions.map((session, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Session index={index} key={String(index)} session={session} />
    ))}
  </RecordedSessionsContainer>
);

RecordedSessions.defaultProps = {
  recordedSessions: [],
};

RecordedSessions.propTypes = {
  recordedSessions: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date).isRequired,
      duration: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
};

export default RecordedSessions;
