/* eslint-disable react/prop-types */
import {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {motion, AnimatePresence} from 'framer-motion';
import {useForm} from 'react-hook-form';
import {useClickAway} from 'react-use';

import CloseSidebarIcon from '../../../../../../assets/icons/close-modal.svg';
import {AddDocumentContainer} from './elements';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {showToast} from '../../../../../../components/common/Toast';
import UpdateIcon from '../../../../../../assets/icons/updateadmintoast.svg';
import {
  setError as setServerError,
  adminLibrarySelector,
  addLibraryDocument,
  fetchAllLibraryFiles,
} from '../../../../../../redux/adminLibrarySlice';
import {AddForm, Footer} from './components';

const FIELDS_IN_ORDER = [
  'documentTitle',
  ' fileCode',
  'fileStatus',
  'className',
  'fileCategory',
  'publicLibrary',
];

export const AddDocument = ({isVisible, onClose, onSuccess, classValue}) => {
  const {
    error: serverError,
    isAdminLibraryLoading,
    activeClasses,
    category,
    formError,
  } = useSelector(adminLibrarySelector);
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    getValues,
    setValue,
    watch,
    register,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      documentTitle: '',
      fileCode: '',
      fileStatus: null,
      className: null,
      fileCategory: null,
      publicLibrary: false,
    },
  });

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
  }, [dispatch]);
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });
  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadCoverImage, setUploadCoverImage] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const categoryDropdown = useRef();

  const setFieldError = (fieldName, errorMessage) => {
    setError(fieldName, {message: errorMessage});
  };
  const onSubmit = data => {
    if (!data?.documentTitle) {
      setFieldError('documentTitle', 'Enter Document Title');
    }
    if (!data?.fileCode) {
      setFieldError('fileCode', 'Enter Code');
    }
    if (!data?.fileStatus?.value) {
      setFieldError('fileStatus', 'Select the File type');
    }
    if (data?.fileStatus?.value === 0) {
      clearErrors('fileStatus');
      if (!data?.className) {
        setFieldError('className', 'Select the Class Name');
      }
    }
    if (!data?.fileCategory?.id) {
      setFieldError('fileCategory', 'Select the Category');
    }
    const formData = new FormData();

    if (
      uploadDocument &&
      getValues('documentTitle')?.length &&
      getValues('fileCode')?.length &&
      data?.fileCategory !== null
    ) {
      formData.append('documentTitle', data?.documentTitle);
      formData.append('fileStatus', data?.fileStatus?.value);
      formData.append('fileCode', data?.fileCode);

      formData.append('category', data?.fileCategory?.id);

      if (data?.fileStatus?.value === 0) {
        formData.append('className', data?.className?.value);
      }
      formData.append('uploadDocument', uploadDocument, uploadDocument?.name);
      if (uploadCoverImage) {
        formData.append(
          'uploadCoverImage',
          uploadCoverImage,
          uploadCoverImage?.name,
        );
      }

      if (!isAdminLibraryLoading && !formError?.fileCode?.length) {
        dispatch(
          addLibraryDocument(formData, () => {
            showToast(UpdateIcon, 'Document added successfully', false);
            setUploadDocument(null);
            setUploadCoverImage(null);
            if (data?.fileStatus?.value === 0) {
              onSuccess(data?.className);
              dispatch(fetchAllLibraryFiles(data?.className.value));
            } else {
              onSuccess();
            }
            onClose();
          }),
        );
      }
    }
  };
  useEffect(() => {
    const bodyScrollBar = document.body;
    bodyScrollBar.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    getValues('documentTitle');
    getValues('fileCategory');
  }, [getValues]);

  const fileStatusOptions = [
    {label: 'Public', value: 1},
    {label: 'Add to a Class', value: 0},
  ];

  const classesAsOptions = useMemo(
    () =>
      activeClasses?.map(item => ({
        label: `${item?.class?.oc_class} - ${item?.orgUnit?.unit?.tun_title}`,
        value: item?.orui_id_orgunitinstance,
      })) || [],

    [activeClasses],
  );
  const categoryAsOptions = useMemo(
    () =>
      category?.map(item => ({
        name: `${item?.fc_name}`,
        id: item?.fc_id_filecategory,
      })) || [],

    [category],
  );

  return (
    <AnimatePresence>
      {isVisible ? (
        <AddDocumentContainer
          className="d-flex justify-content-end position-fixed"
          animate={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          exit={{
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          }}
          initial={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          transition={{
            duration: 0.4,
            type: 'keyframes',
          }}
        >
          <motion.aside
            className="sidebar"
            animate={{
              x: '0',
            }}
            exit={{
              x: '100%',
            }}
            initial={{
              x: '100%',
            }}
            transition={{
              duration: 0.4,
              type: 'keyframes',
            }}
            ref={ref}
          >
            <header className="header-title align-items-center d-flex justify-content-between fixed-top">
              <div className="sidebar-head d-flex">
                <div className="sidebar-title">Add a File</div>
              </div>
              <button
                className="close-sidebar-button"
                onClick={onClose}
                type="button"
              >
                <img alt="Close Sidebar" src={CloseSidebarIcon} />
              </button>
            </header>
            <AddForm
              uploadDocument={uploadDocument}
              setUploadDocument={setUploadDocument}
              uploadCoverImage={uploadCoverImage}
              setUploadCoverImage={setUploadCoverImage}
              onDrag={onDrag}
              setOnDrag={setOnDrag}
              onClose={onClose}
              register={register}
              setValue={setValue}
              categoryDropdown={categoryDropdown}
              errors={errors}
              categoryAsOptions={categoryAsOptions}
              control={control}
              fileStatusOptions={fileStatusOptions}
              classesAsOptions={classesAsOptions}
              formError={formError}
              clearErrors={clearErrors}
              setFieldError={setFieldError}
              watch={watch}
              classValue={classValue}
            />
            <Footer
              onButtonCancel={onClose}
              onSubmit={handleSubmit(onSubmit)}
              isDisabled={!uploadDocument}
              isAdminLibraryLoading={isAdminLibraryLoading}
            />
          </motion.aside>
        </AddDocumentContainer>
      ) : null}
    </AnimatePresence>
  );
};
