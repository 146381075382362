/* eslint-disable react/prop-types */
import {useState} from 'react';
import moment from 'moment';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  MoreOptions,
  Modal,
  TextInput,
} from '../../../../../../../../../../../../components/common';
import {DropdownItem} from '../../../../../../../../../../../../components/common/MoreOptions/components';
import {showToast} from '../../../../../../../../../../../../components/common/Toast';
import {TableCellLink} from '../../../../../../../../../../../../components/common/Table/styles';
import {handleFileDownload} from '../../../../../../../../../../../../utils/downloadFile';

import ViewClassImg from '../../../../../../../../../../../../assets/icons/viewclass.svg';
import UpdateIcon from '../../../../../../../../../../../../assets/icons/updateadmintoast.svg';
import CurriculumDocumentIcon from '../../../../../../../../../../../../assets/icons/CurriculumDocument.svg';
import CurriculumAudioIcon from '../../../../../../../../../../../../assets/icons/CurriculumAudio.svg';
import CurriculumImageIcon from '../../../../../../../../../../../../assets/icons/CurriculumImage.svg';
import CurriculumVideoIcon from '../../../../../../../../../../../../assets/icons/CurriculumVideo.svg';
import CurriculumDownloadIcon from '../../../../../../../../../../../../assets/icons/curriculum-download-icon.svg';

import {
  deleteCurriculumModuleFileById,
  getCurriculumByCourseId,
  editCurriculumModuleFileById,
  curriculumSelector,
} from '../../../../../../../../../../../../redux/curriculumSlice';

import {EditFileContainer} from '../../elements';

const DELAY = 500;

export const FileDetail = ({files, show, position, moduleId, courseId}) => {
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [editFileId, setEditFileId] = useState(null);
  const [editFileName, setEditFileName] = useState('');
  const {isAddCurriculumLoading} = useSelector(curriculumSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  function IconforFile(type) {
    let instructionIcon;

    if (type === 'Voice') {
      instructionIcon = CurriculumAudioIcon;
    } else if (type === 'Document') {
      instructionIcon = CurriculumDocumentIcon;
    } else if (type === 'Image') {
      instructionIcon = CurriculumImageIcon;
    } else if (type === 'Video') {
      instructionIcon = CurriculumVideoIcon;
    } else {
      instructionIcon = CurriculumDocumentIcon;
    }
    return instructionIcon;
  }

  return (
    <div>
      {show ? (
        <div className="file-container">
          {files.map((data, index) => (
            <div
              className="file-detail"
              key={data?.ocrf_id_course_curriculum_file}
            >
              <div className="d-flex mb-0 name-container">
                <p className="mb-0 fileposition mr-5">{`${position}.${
                  index + 1
                }`}</p>
                <div className="d-flex align-items-center">
                  <img
                    className="mb-0 fileicon mr-3"
                    alt="arrow"
                    src={IconforFile(data?.ft_type)}
                  />
                  <p className="mb-0 filename">{data?.fi_text_content}</p>
                </div>
              </div>
              <div className="date">
                Uploaded on {moment(data?.fi_uploadedate).format('DD/MM/YYYY')}
              </div>
              <div className="d-flex align-items-center btn-container">
                <TableCellLink
                  onClick={() => {
                    history.push(
                      `${courseId}/viewFileToSharedClass/${data?.ocrf_id_course_curriculum_file}`,
                    );
                  }}
                  type="button"
                  className="view-module"
                  id="view"
                >
                  <span> View </span>
                  <img
                    src={ViewClassImg}
                    alt="loader"
                    className="viewmodule-arrow"
                  />
                </TableCellLink>
                <div className="d-flex align-items-center">
                  <MoreOptions className="ml-4 mr-3" id="more-options-id">
                    <DropdownItem
                      label="Edit File"
                      onClick={() => {
                        setEditFileId(data?.fi_id_file);
                        setEditFileName(data?.fi_text_content);
                      }}
                      id="edit-file"
                    />
                    <DropdownItem
                      isDanger
                      label="Delete file"
                      onClick={() => {
                        setDeleteId(data?.ocrf_id_course_curriculum_file);
                        setDeleteName(data?.fi_text_content);
                      }}
                      id="delete-file"
                    />
                  </MoreOptions>
                  <button
                    type="button"
                    className="button-download d-flex align-items-center"
                    title="download"
                    onClick={() => {
                      handleFileDownload({
                        filePath: `stream-${data?.fi_S3_filename}`,
                        fileName: data?.fi_filename,
                      });
                    }}
                    id="arrow-id"
                  >
                    <img
                      className="mb-0 downloadicon"
                      alt="arrow"
                      src={CurriculumDownloadIcon}
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this file?"
        isDelete
        isButtonLoading={isAddCurriculumLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          if (!isAddCurriculumLoading) {
            dispatch(
              deleteCurriculumModuleFileById(
                courseId,
                moduleId,
                deleteId,
                () => {
                  setDeleteId(null);
                  showToast(
                    false,
                    `${deleteName} - file has been deleted.`,
                    true,
                  );
                  setTimeout(() => {
                    setDeleteName('');
                  }, DELAY);
                },
              ),
            );
          }
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
      <EditFileContainer
        buttonLabel="Save"
        description="Rename module file"
        isButtonLoading={isAddCurriculumLoading}
        loadingButtonLabel="Saving"
        onButtonClick={() => {
          if (!isAddCurriculumLoading && editFileName?.length) {
            dispatch(
              editCurriculumModuleFileById(
                courseId,
                editFileId,
                editFileName,
                () => {
                  dispatch(getCurriculumByCourseId(courseId));
                  setEditFileId(null);
                  showToast(
                    UpdateIcon,
                    `${editFileName} file name updated successfully`,
                    false,
                  );

                  setTimeout(() => {
                    setEditFileName('');
                  }, DELAY);
                },
              ),
            );
          }
        }}
        onHide={() => {
          setEditFileId(null);
          setTimeout(() => {
            setEditFileName('');
          }, DELAY);
        }}
        show={!!editFileId}
        title="Rename File"
        width="45rem"
      >
        <div className="row-container">
          <TextInput
            label="File Name"
            autoComplete="off"
            type="text"
            id="fileName"
            hasError={!editFileName?.length}
            value={editFileName}
            onBlur={e => {
              setEditFileName(e?.target?.value);
            }}
            onChange={e => {
              setEditFileName(e?.target?.value);
            }}
          />
        </div>
      </EditFileContainer>
    </div>
  );
};
