import styled from 'styled-components';

export const FooterContainer = styled.div`
  border-top: 0.0625rem solid ${({theme}) => theme.colors.border};
  width: 100%;
  background-color: #ffffff;
  padding: 1.5rem 0;
  @media (max-width: 41.25rem) {
    & {
      width: 100%;
      padding: 1.5rem;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .button-container {
      display: flex;
      flex-direction: row;
      margin-right: 1.5rem;
    }
    .button {
      padding: 0.875rem 1.25rem;
      &.cancel-button {
        background-color: white;
        border: 0.0625rem solid ${({theme}) => theme.colors.darkBorder};

        :active,
        :focus {
          border-color: ${({theme}) => theme.colors.primary};
        }

        .label {
          color: ${({theme}) => theme.colors.text};
        }
      }
    }
  }
  @media (max-width: 41.25rem) {
    .footer {
      display: flex;
      flex-direction: column;
    }
    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
