/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import classNames from 'classnames';

import ArrowHideImg from '../../../../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../../../../assets/icons/tableshow.svg';
import MappingIcon from '../../../../../../../../../assets/icons/mapping-disabled.svg';
import MappingActiveIcon from '../../../../../../../../../assets/icons/mapping-active.svg';

import {AddedMappedTaskContainer} from './elements';

export const MappedTask = ({task}) => {
  const [isMappingExpanded, setIsMappingExpanded] = useState(false);
  const toggleResources = () => setIsMappingExpanded(!isMappingExpanded);

  const convertName = fullName => {
    const arr = fullName.split(' ');

    arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1).toLowerCase();

    const formattedName = arr.join(' ');

    return formattedName;
  };

  return (
    <AddedMappedTaskContainer>
      <div className="seperator" />

      <div className="accordion" id="accordionExample">
        <div className="card">
          <div
            className={classNames('card-header', {
              clicked: isMappingExpanded,
            })}
            id="headingOne"
          >
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleResources}
              >
                <div className="accordion-header">
                  <img
                    alt="instructions"
                    className="resource"
                    src={isMappingExpanded ? MappingActiveIcon : MappingIcon}
                  />
                  Mappings
                </div>
                <div>
                  <img
                    alt="Show Works"
                    className="arrow"
                    src={isMappingExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={classNames('collapse', {
              show: isMappingExpanded,
            })}
          >
            <div className="card-body">
              <div className="performance-criteria">
                <div className="mapping-header">Performance Criteria</div>
                <div className="mapping-content">
                  {`${task?.tpc_criteria_id} 
                  ${task?.tpc_criteria_value}`}
                </div>
              </div>

              <div className="other-mapping">
                <div className="mapping-header">Performance Evidence</div>
                <div className="mapping-content">
                  {convertName(`${task?.tpe_evidence_value}`)}
                </div>
              </div>

              <div className="other-mapping">
                <div className="mapping-header">Knowledge Evidence</div>
                <div className="mapping-content">
                  {convertName(`${task?.tke_evidence_value}`)}
                </div>
              </div>

              <div className="assessment-conditions">
                <div className="mapping-header">Assessment Conditions</div>
                <div className="mapping-content">
                  {convertName(`${task?.tac_condition}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AddedMappedTaskContainer>
  );
};
