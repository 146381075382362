/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {TableCellLink} from '../../../../../../../../../../components/common/Table/styles';
import {classesSelector} from '../../../../../../../../../../redux/classesSlice';
import {
  Table,
  EmptyState,
} from '../../../../../../../../../../components/common';

import {TableSpinner} from '../../../../../../../../components-new';
// import PlusIcon from '../../../../../../../../../../assets/icons/refactor/plus-white.svg';

import {UnitsContainer} from './elements';

export const Units = ({orgUnitInstance}) => {
  const {orgClassId} = useParams();
  const history = useHistory();
  const {isSingleClassLoading} = useSelector(classesSelector);

  const onButtonClick = () => {
    history.push(`/admin/classes/view-class/${orgClassId}/manage-units`);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Unit Code',
        accessor: 'orgUnit.unit.tun_code',
        className: 'unit-code',
      },
      {
        Header: 'Unit Name',
        accessor: 'orgUnit.unit.tun_title',
        className: 'unit-name',
      },
      {
        Header: 'Essential',
        Cell: ({value}) => {
          const uType = value;
          return (
            <div className="type-container">
              <div className={uType}>{uType?.toUpperCase()}</div>
            </div>
          );
        },
        accessor: 'unitType',
        className: 'essential',
      },
      {
        Header: 'Teachers',
        Cell: ({value}) => (
          <div className="teachers-container">
            {value?.length > 0 ? (
              value?.slice(0, 2).map(item => {
                const userProfile = item?.userProfile;
                return (
                  <div className="teacher">{`${userProfile?.up_name_first} ${userProfile?.up_name_last}`}</div>
                );
              })
            ) : (
              <span className="no-students">No Teacher</span>
            )}
            {value?.length > 2 && (
              <span className="more">+{value?.length - 2} more</span>
            )}
          </div>
        ),
        accessor: 'oruiTeachers',
        className: 'teachers',
      },
      {
        Header: 'Assessments',
        Cell: ({row: {original}, value}) => {
          const orgUnitInstanceId = original?.orui_id_orgunitinstance;
          return (
            <TableCellLink
              onClick={() => {
                history.push(
                  `/admin/classes/view-class/${orgClassId}/units/${orgUnitInstanceId}/assessments`,
                );
              }}
              type="button"
            >
              {value}
            </TableCellLink>
          );
        },
        accessor: 'aouiCount',
        className: 'assessment-count',
      },
    ],
    [history, orgClassId],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(orgUnitInstance || []).map((item, index) => ({
        ...item,
        serial: index + 1,
        unitType: item.orgUnit.oqu_is_essential === 1 ? 'core' : 'elective',
      })),
    ],
    [orgUnitInstance],
  );

  return (
    <UnitsContainer>
      <div className="table-container">
        {isSingleClassLoading ? <TableSpinner /> : null}
        {!isSingleClassLoading &&
        Array.isArray(orgUnitInstance) &&
        !orgUnitInstance?.length ? (
          <EmptyState
            description="It seems that there is no units has been added to the class"
            title="No Units added to the class"
            buttonLabel="Manage Units"
            onButtonClick={onButtonClick}
          />
        ) : null}

        {!isSingleClassLoading && orgUnitInstance?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Units"
            maxWidth="75rem"
            isSortedBy
            buttonLabel="Manage Units"
            onButtonClick={onButtonClick}
          />
        ) : null}
      </div>
    </UnitsContainer>
  );
};
