/* eslint-disable react/prop-types */

import React from 'react';

import {AssessmentDetailsContainer, AssessmentTasksContainer} from './elements';

import {AssessmentTask, BasicDetails} from './components';

export const AssessmentDetails = ({assessment}) => (
  <AssessmentDetailsContainer className="basic-detail">
    <BasicDetails assessment={assessment} />
    {assessment?.tasks?.length ? (
      <div>
        <p className="assessment-task-header">
          Assessment Tasks{' '}
          <p className="task-length">{assessment?.tasks?.length}</p>
        </p>
        <AssessmentTasksContainer>
          {assessment.tasks?.map(task => (
            <AssessmentTask key={task.ai_id_activityitem} task={task} />
          ))}
        </AssessmentTasksContainer>
      </div>
    ) : null}
  </AssessmentDetailsContainer>
);
