/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Color} from '../../../constants';

export const TeacherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -15px;
  min-height: 100vh;
  padding-top: 126px;
  padding-bottom: 46px;
  background-color: #f4f5f8;
  .container-teacher {
    padding: 0 15px;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 888px) {
      & {
        width: 888px;
      }
    }
  }
  .teacherheading {
    h2 {
      color: #3d4457;
    }
  }
  .custombox {
    .search-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0px 0px 0px;
    }
    .seperator-search {
      height: 1px;
      background: #e0e0e0;
    }
    .org-teacher-table {
      tbody tr {
        :last-child {
          border-bottom: none;
        }
        :hover {
          background-color: transparent;
        }
      }
      tr {
        :first-child {
          border-bottom: 1px solid #dedede;
          border-top: 1px solid transparent;
        }
      }
    }
    .threeDotIcon {
      border: none;
      background: transparent;
      outline: none;
    }
  }
  .invitation-container {
    display: flex;
    flex-direction: row;
  }
  .invitation-status-0,
  .invitation-status-1,
  .invitation-status-2 {
    border-radius: 5px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.21;
    padding: 8px 16px;
  }
  .invitation-status-0 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }
  .invitation-status-1 {
    background-color: #e4f4f1;
    color: #4ec977;
  }
  .invitation-status-2 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }

  .dropdowncontainer {
    position: relative;
  }
  .add-teacher-modal {
    width: 660px;
    .closeIconBtn {
      border: none;
      background: transparent;
      outline: none;
    }
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .modal-seperator {
    width: 660px;
    height: 1px;
    background-color: #e6e6e6;
  }
  .add-teacher-modal-header {
    display: flex;
    justify-content: space-between;
    p {
      color: #aaaaaa;
    }
  }
  .add-teacher-modal-body {
    display: flex;
    justify-content: space-between;
    .date-wrapper {
      flex-grow: 1;
    }
    p {
      color: #aaaaaa;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .delete-teacher-modal {
    width: 660px;
    border-radius: 12px;
    .delete-teacher-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      .modal-header-left {
        display: flex;
        .confirm {
          color: #aaaaaa;
        }
      }
      .delete-teacher-modal-title {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
      }
      .closeModalBtn {
        outline: none;
        background-color: transparent;
        border: none;
      }
    }
    .footer-delete-break {
      top: 40px;
      height: 1px;
      background-color: #e0e0e0;
      width: 100%;
    }
    .delete-teacher-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancel {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .invited {
    background-color: #ebf5ff;
    color: #40a1ff;
    margin-left: 8px;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 24px;
      width: 24px;
    }
  }
  .__react_component_tooltip {
    font-size: 14px;
    font-weight: normal;
    padding: 8px;
    color: #ffffff;
    border-radius: 5px;
  }
`;
export const DropdownBtn = styled.div`
  .btn {
    outline: none;
    padding: 0px;
    box-shadow: unset;
    :focus {
      background: #fafafa;
      box-shadow: unset;
      outline: none;
    }
  }
`;
export const AddTeacherHeader = styled.div`
  margin: 0px -15px;
  .add-teacher-header {
    width: 100%;
    background-color: #ffffff;
    padding: 24px 48px 24px 48px;
    .back-button {
      margin-bottom: 16px;
    }

    .add-teacher-title {
      display: flex;
      justify-content: space-between;
      .add-teacher-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
  }
`;
export const AddTeacherContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px -15px;
  padding-top: 32px;
  background-color: #f4f5f8;
  .container-teacher {
    padding: 0 15px;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 888px) {
      & {
        width: 888px;
      }
    }
  }
  .adminheading {
    h2 {
      color: #3d4457;
    }
  }
  .custombox {
    .loader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .please {
        font-size: 14px;
        color: #888888;
      }
      .header {
        font-size: 24px;
      }
      .state {
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
      }
    }
    .search-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #e0e0e0;
      padding: 24px 0px 0px 0px;
      .buttom {
        margin-right: 40px;
        border: none;
        background-color: unset;
        outline: none;
        color: #bcbcbc;
        &.link {
          border-bottom: 1px solid black;
          color: #3d4457;
        }
      }
    }
    .org-teacher-table {
      tbody tr {
        button {
          display: none;
        }
        :hover {
          button {
            display: block;
          }
        }
      }
      tr {
        :first-child {
          border-bottom: 1px solid #dedede;
          border-top: 1px solid transparent;
        }
        :last-child {
          border-bottom: 1px solid #dedede;
        }
      }
      .closeIconBtn {
        border: none;
        background: transparent;
        outline: none;
      }
    }
    .search-div-spread {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 0px 24px 0px;
      .imported {
        padding: 0;
        margin: 0;
        color: #3d4457;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }
      .imported-file {
        margin: 0;
        padding: 0;
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
      }
    }
    .org-teacher-spreadtable {
      tbody tr {
        button {
          display: none;
        }
        :hover {
          button {
            display: block;
          }
        }
      }
      tbody tr {
        :last-child {
          border-bottom: 1px solid transparent;
          border-top: 1px solid #dedede;
        }
      }
      .closeIconBtn {
        border: none;
        background: transparent;
        outline: none;
      }
    }
    .threeDotIcon {
      border: none;
      background: transparent;
      outline: none;
    }
  }

  .add-teacher-modal {
    width: 100%;
  }
  .add-teacher-modal-body {
    display: flex;
    justify-content: space-between;
    .date-wrapper {
      flex-grow: 1;
    }
    p {
      color: #aaaaaa;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .save-changes {
      border: 1px solid #40a1ff;
      border-radius: 8px;
      .add-button-text {
        color: #40a1ff;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .cancel {
      margin-right: 16px;
      border: 1px solid transparent;
      border-radius: 8px;
      align-items: flex-end;
      .cancel-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .inputfields {
    padding: 24px 0px;
    .logo-label {
      color: #aaa;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .org-image-full {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      align-items: center;
      border: 1px dashed #dedede;
      padding: 16px;
      justify-content: space-between;
      border-radius: 5px;
      .org-image-after {
        display: flex;
        flex-direction: row;
        .file-details {
          display: flex;
          padding-left: 16px;
          flex-direction: column;

          .file-name {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #3d4457;
          }
          .file-size {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            padding-top: 8px;
            color: #aaaaaa;
          }
        }
        img {
          object-fit: contain;
          width: 40px;
          height: 48px;
        }
      }
      .delete-logo {
        button {
          border: none;
          background-color: transparent;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .org-image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 24px 24px;
      background: #ffffff;
      border: 1px dashed #dedede;
      box-sizing: border-box;
      border-radius: 5px;
      .org-logo {
        padding: 58px 0px 0px 0px;
      }
      &.logo-drag {
        background: #f7fbff;
        border-color: #40a1ff;
      }
      .org-text {
        padding: 12px 0 0px 0;
        text-align: center;
        position: relative;
        overflow: hidden;
      }
      .org-seperator {
        margin-top: 85px;
        width: 300px;
        height: 1px;
        background-color: #eaeaea;
        margin-bottom: 10px;
      }

      .org-text input {
        position: absolute;
        color: black;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 200px;
      }
      span {
        color: #40a1ff;
      }
      .supports {
        padding: 6px 0px 0px 0px;
        color: #adadad;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .org-size {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      span {
        color: #adadad;
      }
      a {
        color: #40a1ff;
        :hover {
          color: #40a1ff;
          text-decoration: none;
        }
      }
    }
    .error-size {
      color: red;
    }
  }
  .accordion {
    width: 100%;
    border-radius: 8px;
    .card-header {
      background-color: #ffffff;
      padding: 0.75rem 0px;
    }
    .btn {
      line-height: 1;
      color: #3d4457;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }
    }
    .org-error-table {
      tr {
        :first-child {
          border-bottom: 1px solid #dedede;
          border-top: 1px solid #dedede;
        }
        :last-child {
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }
`;
export const AddTeacherFooter = styled.div`
  bottom: 0;
  height: 62px;
  margin-top: 100px;
  .add-teacher-footer {
    padding: 24px 48px 24px 48px;
    width: 100%;
    background-color: #ffffff;
    .add-teacher-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .add-teacher-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
    .add-teacher-spread {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-teacher-name {
        color: #3d4457;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
      }
    }
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .save-changes {
        margin-right: 4px;
      }
      .cancel {
        margin-right: 16px;
        border: 1px solid transparent;
        border-radius: 8px;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .cancel1 {
        margin-right: 16px;
        border: 1px solid #dedede;
        border-radius: 8px;
        align-items: flex-end;
        .cancel1-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
`;
