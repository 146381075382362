import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import Helmet from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useLocalStorage, useLocation} from 'react-use';
import {
  fetchAllSessions,
  sessionsSelector,
  setSearch,
} from '../../../../../../redux/sessionsSlice';

import {
  Button,
  HideScrollbar,
  Spacer,
} from '../../../../../../components/common';

import FilterIcon from '../../../../../../assets/icons/filter-icon.svg';
import PlusIcon from '../../../../../../assets/icons/refactor/plus-white.svg';
import {TableSpinner} from '../../../../components-new';
import {AllSessionsContainer} from './elements';
import {
  FilterSlider,
  FilterList,
  Calendar,
  ScheduledSessionsChart,
} from './components';
import SearchInput from '../../../../../../components/shared/Inputs/SearchInput';
import {streamSelector} from '../../../../../../redux/streamSlice';
import {authSelector} from '../../../../../../redux/authSlice';

export const SessionsCalendar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [userType] = useLocalStorage('jc-user-type', null, {raw: true});

  const {state} = useLocation();
  const sessionNameState = state?.state;

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [classId, setClassId] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const {sessions, isListLoading, search} = useSelector(sessionsSelector);
  const streamState = useSelector(streamSelector);
  const authState = useSelector(authSelector);
  // Check org preference for video class page is enable
  const liveSessionPreference = authState?.orgPreferences?.find(
    item =>
      item.pr_slug === 'enable_video_session' && Number(item.pr_value) === 1,
  );

  const sessionList = useMemo(
    () =>
      sessions?.map(session => {
        const {orgUnitInstance, teacher} = session;
        return {
          id: session.oruil_id_orgunitinstancelesson,
          title: session.oruil_name,
          description: session?.oruil_description,
          start: new Date(session.oruil_date),
          end: new Date(session.oruil_date),
          time: `${moment(session.oruil_timestart, 'HH:mm:ss').format(
            'h.mm a',
          )} - ${moment(session.oruil_timefinish, 'HH:mm:ss').format(
            'h.mm a',
          )}`,
          teacher: `${teacher.up_name_first} ${teacher.up_name_last}`,
          class: `${orgUnitInstance.class.oc_class} • ${orgUnitInstance.orgUnit.unit.tun_title}`,
          color: null,
          attendance: {
            students: session.students,
            presentStudents: session.presentStudents,
            absentStudents: session.absentStudents,
          },
        };
      }) || [],
    [sessions],
  );

  const currentSessions = useMemo(
    () =>
      sessions?.filter(
        session =>
          new Date(session.oruil_date).toDateString() ===
          currentDate.toDateString(),
      ),
    [currentDate, sessions],
  );

  useEffect(() => {
    if (sessionNameState?.sessionName) {
      dispatch(setSearch(sessionNameState?.sessionName));
    }
  }, [dispatch, sessionNameState]);

  useEffect(() => {
    dispatch(fetchAllSessions(params?.id));
  }, [dispatch, params?.id]);

  const isStudent = +userType === 2;
  const isTeacher = +userType === 3;

  return (
    <AllSessionsContainer>
      <Helmet>
        <title>Session | JungleCat</title>
      </Helmet>
      <HideScrollbar />
      <div
        className={classNames('calendar-container', {
          'is-archive': streamState?.classDetails?.orui_is_archived,
        })}
      >
        <div className="header-container">
          <div className="title">
            All Sessions
            {isFilterApplied && !isStudent ? (
              <FilterList
                isVisible={isFilterOpen}
                courseId={courseId}
                setClassId={setClassId}
                classId={classId}
                setCourseId={setCourseId}
                setUnitId={setUnitId}
                unitId={unitId}
                setTeacherId={setTeacherId}
                teacherId={teacherId}
                setIsFilterApplied={setIsFilterApplied}
                onClose={() => {
                  setIsFilterOpen(false);
                }}
              />
            ) : null}
          </div>
          {!isStudent ? (
            <div className="right-side">
              {!isTeacher ? (
                <button
                  type="button"
                  className="filter-button"
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                  id="filter-isStudent-id"
                >
                  <img src={FilterIcon} alt="filter" />
                </button>
              ) : null}
              <Button
                icon={PlusIcon}
                className={classNames('add-button', {
                  'is-archive': streamState?.classDetails?.orui_is_archived,
                })}
                isFullWidth={false}
                onClick={() => {
                  const url = isTeacher
                    ? 'schedule'
                    : '/admin/sessions/add-session';
                  history.push(url);
                }}
                label="Add Session"
                id="add-session-id"
              />
            </div>
          ) : null}
        </div>

        <div className="table-container">
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading ? (
            <>
              <div className="calendar-header">
                <h3 className="session-count">{sessionList.length} Sessions</h3>
                <SearchInput
                  onChange={event => dispatch(setSearch(event.target.value))}
                  placeholder="Search"
                  value={search}
                  width="16rem"
                  id="search-id"
                />
              </div>
              <Calendar events={sessionList} />
            </>
          ) : null}
          <FilterSlider
            isVisible={isFilterOpen}
            courseId={courseId}
            setClassId={setClassId}
            classId={classId}
            setCourseId={setCourseId}
            setUnitId={setUnitId}
            unitId={unitId}
            setTeacherId={setTeacherId}
            teacherId={teacherId}
            setIsFilterApplied={setIsFilterApplied}
            onClose={() => {
              setIsFilterOpen(false);
            }}
          />
        </div>
        <Spacer height="10rem" />
      </div>
      <ScheduledSessionsChart
        date={currentDate}
        setDate={setCurrentDate}
        sessions={currentSessions}
        liveSessionPreference={liveSessionPreference}
      />
    </AllSessionsContainer>
  );
};
