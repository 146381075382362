import styled from 'styled-components';

import {Colors} from '../../../../../../constants';
import rem from '../../../../../../utils/rem';

const UnitDetailsHeaderContainer = styled.header`
  margin-bottom: ${rem(24)};

  .unit-code {
    color: ${Colors.Text};
    font-size: ${rem(20)};
    font-weight: 500;
    line-height: 1.2;
  }

  .unit-name {
    color: ${Colors.LightText};
    font-size: ${rem(14)};
    line-height: 1.5;
  }
  .qual-code {
    color: ${Colors.LightText};
    font-size: 1rem;
    line-height: 1.5;
  }

  .options-button {
    background-color: transparent;
    border: none;
    border-radius: ${rem(8)};
    padding: ${rem(11)};
    transition: background-color 0.1s;

    :hover,
    :active,
    :focus {
      background-color: ${Colors.LightPrimary};
    }

    :active,
    :focus {
      outline: none;
    }

    img {
      height: ${rem(20)};
      object-fit: contain;
      vertical-align: unset;
      width: ${rem(20)};
    }
  }
`;

export default UnitDetailsHeaderContainer;
