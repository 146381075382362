import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../../../elements-new';

export const ClassesContainer = styled(AdminListingPageContainer)`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 4%;
  }

  .course-name {
    width: 25%;
  }

  .total-sessions {
    width: 17%;
  }
  .total-hours {
    width: 17%;
  }
  .completed-session {
    width: 17%;
  }

  .completed-hours {
    width: 17%;
  }
  .more-option {
    width: 3%;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    .back-button {
      margin-bottom: 1.8rem;
      img {
        width: 1rem;
        height: 1rem;
        margin-bottom: 0.1275rem;
        vertical-align: middle;
      }

      span {
        font-size: 0.875rem;
        color: #8a8c94;
        margin-left: 0.6875rem;
      }
    }
    .header {
      font-size: 1.5rem;
      color: #3d4457;
    }
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
`;
