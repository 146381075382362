import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import ToastSuccessIcon from '../../../../../../../../assets/icons/add-academic.svg';
import {Modal, TextInput} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';
import {
  qualificationsSelector,
  setError,
  updateQualification,
} from '../../../../../../../../redux/qualificationsSlice';
import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {qualificationSchema} from '../../../../../../../../utils/validation';

const FIELDS_IN_ORDER = ['code', 'name'];

export const EditQualificationModal = ({editDetails, onHide}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      qualificationCode: '',
      qualificationName: '',
    },
    resolver: yupResolver(qualificationSchema),
  });
  const {error: serverError, isQualificationLoading} = useSelector(
    qualificationsSelector,
  );

  useEffect(() => {
    if (editDetails) {
      setValue('qualificationCode', editDetails.code);
      setValue('qualificationName', editDetails.name);
    }
  }, [editDetails, setValue]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      dispatch(
        updateQualification(editDetails.id, data, () => {
          onHide();
          showToast(
            ToastSuccessIcon,
            `${data.qualificationName} has been updated`,
          );
        }),
      );
    },
    [dispatch, editDetails, onHide],
  );

  const {hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <Modal
      buttonLabel="Save Changes"
      description="Make changes to the qualification details."
      isButtonLoading={isQualificationLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit Qualification Details"
      width="55.5rem"
    >
      <form className="d-flex pb-4 px-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="qualificationCode"
          render={fields => (
            <TextInput
              {...fields}
              autoFocus
              className="mr-4"
              hasError={hasServerError || !!errors.qualificationCode}
              label="Qualification Code"
              placeholder="Enter the qualification code"
            />
          )}
        />
        <Controller
          control={control}
          name="qualificationName"
          render={fields => (
            <TextInput
              {...fields}
              hasError={hasServerError || !!errors.qualificationName}
              label="Qualification Name"
              placeholder="Enter the qualification name"
            />
          )}
        />
      </form>
    </Modal>
  );
};

EditQualificationModal.defaultProps = {
  editDetails: null,
};

EditQualificationModal.propTypes = {
  editDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onHide: PropTypes.func.isRequired,
};
