/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import ArrowLeftImg from '../../../../../../../../../../../../assets/icons/arrow_left.svg';
import {Button} from '../../../../../../../../../../../../components/common';
import FileDownloadIcon from '../../../../../../../../../../../../assets/icons/file-download-shared-class.svg';
import {handleFileDownload} from '../../../../../../../../../../../../utils/downloadFile';
import {ViewFileSharedToClassHeaderContainer} from './elements';

export const ViewFileSharedToClassHeader = ({
  curriculumFile,
  onBack,
  onPublish,
}) => (
  <ViewFileSharedToClassHeaderContainer>
    <div className="header-container" id="header-container">
      <div className="header-top">
        <div className="left-content">
          <div className="back-container">
            <div>
              <button
                className="backbutton"
                onClick={onBack}
                type="button"
                id="back-btn-id"
              >
                <img src={ArrowLeftImg} alt="arrow" />
              </button>
            </div>
            <div>
              <h2 className="title">{curriculumFile?.ocrm_module_name}</h2>
              <p className="year">
                {`${curriculumFile?.ft_type} Document - ${moment(
                  curriculumFile?.fi_uploadedate,
                ).format('DD MMMM YYYY')}`}
              </p>
            </div>
          </div>
        </div>
        <div className="right-content">
          {curriculumFile?.occf_is_published === 1 ? (
            <div className="published-badge">Published</div>
          ) : (
            <Button
              className="button add-button mr-2"
              onClick={onPublish}
              label="Publish to Class"
              id="publish-id"
            />
          )}
          <button
            type="button"
            className="file-download"
            title="download"
            id="download-file-id"
            onClick={() => {
              handleFileDownload({
                filePath: `stream-${curriculumFile?.fi_S3_filename}`,
                fileName: curriculumFile?.fi_filename,
              });
            }}
          >
            <img src={FileDownloadIcon} alt="download icon" />
          </button>
        </div>
      </div>
    </div>
  </ViewFileSharedToClassHeaderContainer>
);
