import styled from 'styled-components';

const PostBodyContainer = styled.div`
  @media (max-width: 43.75rem) {
    .divider-body {
      display: none;
    }
    .post-comment-input {
      margin: 1rem 0rem 0.5rem 0rem !important;
    }
  }
  .divider-body {
    margin: 1rem 0px;
  }
`;

export default PostBodyContainer;
