import React from 'react';
import {Helmet} from 'react-helmet';
import {Header} from './elements';
import Navbar from '../../components/shared/Navbar';

const AwaitingAssessment = () => (
  <>
    <Helmet>
      <title>Awaiting Assessments | JungleCat</title>
    </Helmet>
    <Navbar />
    <div className="row">
      <Header className="header offset-1">Awaiting Assessments</Header>
    </div>
  </>
);

export default AwaitingAssessment;
