import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../../../../../elements-new';
import {Modal} from '../../../../../../../../../../../components/common';

export const ViewFileSharedToClassContainer = styled(AdminListingPageContainer)`
  padding: 5rem 0 0rem 5rem !important;
  @media (max-width: 55.5rem) {
    & {
      padding: 5rem 0 0rem 5rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 5rem 0 0rem 0rem !important;
    }
  }
`;
export const EditFileContainer = styled(Modal)`
  .row-container {
    padding: 0rem 1.5rem 0.5rem 1.5rem;
  }
`;
