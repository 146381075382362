/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {Typeahead} from 'react-bootstrap-typeahead';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import DatePicker from 'react-datepicker';

import csvjson from 'csvjson';
import CustomCancel from '../../../../components/shared/Buttons/CustomButton';
import closeUnit from '../../../../assets/icons/addadminclose.svg';
import addIcons from '../../../../assets/icons/addadmin.svg';
import arrowHide from '../../../../assets/icons/tablehide.svg';
import arrowShow from '../../../../assets/icons/tableshow.svg';

import CustomTextInput from '../../../../components/shared/CustomTextInput';
import {
  CustomTable,
  tabularize,
} from '../../../../components/shared/CustomTable';
import {
  AddUnitStudentContainer,
  AddUnitStudentHeader,
  AddUnitStudentFooter,
} from '../../Elements/AddUnitStudentContainer';
import CustomBox from '../../../../components/shared/CustomBox';

import files from '../../../../assets/icons/dropfile.svg';
import greenTick from '../../../../assets/icons/donemark.svg';
import yellowTick from '../../../../assets/icons/yellow-success.svg';
import deletelogo from '../../../../assets/icons/delete-logo.svg';
import uploadSuccess from '../../../../assets/icons/upload-success.svg';
import closeIcon from '../../../../assets/icons/redcross.svg';
import CustomButton from '../../../../components/shared/CustomButton';
import Loader from '../../../../components/shared/BookLoader';
import arrowLeft from '../../../../assets/icons/arrow_left.svg';
import 'react-datepicker/dist/react-datepicker.css';
import {CustomDatePicker} from '../../Elements/UnitsContainer';
// import CustomTextInputWithIcon from '../../../../components/shared/CustomTextInputWithIcon';
// import searchIcon from '../../../../assets/icons/search-icon.svg';
import profile from '../../../../assets/icons/profile.svg';
// import dropdownIcon from '../../../../assets/icons/unit-down-arrow.svg';
import calenderIcon from '../../../../assets/icons/calender-icon.svg';
import {
  getAllUnitsClass,
  getAllUnitsDepartments,
  getAllUnitsTeachers,
  addUnit,
  adminInfo,
  individualUnitStudentCheck,
  addStudentsUnderNewUnit,
  spreadSheetStudentCheck,
  fetchStudentInOrg,
  setUnitToastSuccess,
  validateUnitStudent,
  validateUnitStudentError,
  setViewUnitToastSuccess,
} from '../../adminSlice';
// import {
//   CustomTableWithoutHeader,
//   tabularizeTable,
// } from '../../../../components/shared/CustomTableWithoutHeader';
// import CustomTextInputWithButton from '../../../../components/shared/CustomTextInputWithButton';

const AddUnitAndStudent = () => {
  // const [deptColumns, setDeptColumns] = useState(null);
  // const [deptMaps, setDeptMaps] = useState(null);
  // const [deptData, setDeptData] = useState([]);
  const [fullName, setFullName] = useState('');
  const [lastName, setLastName] = useState('');
  const [useremail, setEmail] = useState('');
  const [referID, setReferID] = useState('');
  const [addStudents, setAddStudents] = useState([]);
  const [spreadSheet, setSpreadSheet] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [refIdError, setRefIdError] = useState([]);
  const [emailPatternError, setEmailPatternError] = useState([]);
  const [errorColumns, setErrorColumns] = useState(null);
  const [errorMaps, setErrorMaps] = useState(null);
  const [fileImport, setImport] = useState(true);
  const [manual, setManual] = useState(false);
  const [upload, setUpload] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const [formShow, setFormShow] = useState(true);
  const [btnShow, setBtnShow] = useState(true);
  const [nextShow, setNextShow] = useState(false);
  const [userError, setUserError] = useState('');
  const [referror, setRefError] = useState('');
  const [fileName, setFileName] = useState('');
  const [showShow, setShowShow] = useState(false);
  const [showShow1, setShowShow1] = useState(false);
  const [showShow2, setShowShow2] = useState(false);
  const [showShow3, setShowShow3] = useState(false);
  const dispatch = useDispatch();
  const {
    alreadyExistStudentRefId,
    alreadyExistStudentEmail,
    validUnitStudent,
    unitStudentValidate,
    unitStudentValidateError,
    currentUnit,
    studentEmailPattern,
    existingStudentInUnit,
    unitClass,
  } = useSelector(adminInfo);
  const downloadRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [isValidating, setIsValidating] = useState(false);
  const [spreadNext, setSpreadNextShow] = useState(
    location.state?.spreadNext || 0,
  );
  const existingUnit = location.state?.unitData;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [className, setClassName] = useState(null);
  const [newClass, setNewClass] = useState();
  const [classNameTemp, setClassNameTemp] = useState();
  const [subjectName, setSubjectName] = useState();
  const [subjectNameTemp, setSubjectNameTemp] = useState();
  const {unitDepartments} = useSelector(adminInfo);
  const {unitTeachers} = useSelector(adminInfo);
  // const [isSuggestion, setIsSuggestion] = useState(false);
  const [unitClassName, setUnitClassName] = useState([]);
  const [unitDepartmentName, setUnitDepartmentName] = useState([]);
  // const [isDepartment, setIsDepartment] = useState(false);
  // const [searchTeacher, setSearchTeacher] = useState('');
  // const [searchDepartment, setSearchDepartment] = useState('');
  // const [listColumns, setListColumns] = useState(null);
  // const [listData, setListData] = useState([]);
  // const [listMaps, setListMaps] = useState(null);
  const [addTeacher, setAddTeacher] = useState([]);
  const [addDepartment, setAddDepartment] = useState([]);
  const [tableColumns, setTableColumns] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableMaps, setTableMaps] = useState(null);
  const [studentTableColumns, setStudentTableColumns] = useState(null);
  const [studentTableMaps, setStudentTableMaps] = useState(null);
  const [progress, setProgress] = useState(location.state?.progress || 0);
  const [disabled, setDisabled] = useState(false);
  const classDropdown = useRef();
  if (Date.parse(endDate) < Date.parse(startDate)) {
    setEndDate('');
  }
  useEffect(() => {
    dispatch(getAllUnitsDepartments());
    dispatch(getAllUnitsClass());
    //   const departmentColumns = [
    //     'od_id_orgdepartment',

    //     {
    //       heading: 'DEPARTMENT NAME',
    //     },
    //   ];
    //   const mapDepartment = {
    //     'DEPARTMENT NAME': [
    //       (orgDepartmentId, orgDepartmentName, departmentId) => (
    //         <button
    //           type="button"
    //           className="select-department"
    //           onClick={() => {
    //             setAddDepartment(state => [
    //               ...state,
    //               {
    //                 id: orgDepartmentId,
    //                 department_name: orgDepartmentName,
    //                 department_Id: departmentId,
    //               },
    //             ]);
    //             setSearchDepartment(`${orgDepartmentName}`);
    //             // setIsDepartment(false);
    //           }}
    //         >
    //           <div className="department-row">
    //             <div className="department-name">
    //               <p>{orgDepartmentName}</p>
    //             </div>
    //           </div>
    //         </button>
    //       ),
    //       'od_id_orgdepartment',
    //       'od_department_name',
    //       'od_id_department',
    //       'od_id_org',
    //     ],
    //   };
    //   setDeptColumns(departmentColumns);
    //   setDeptMaps(mapDepartment);

    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  // useEffect(() => {
  //   if (unitDepartments.length) {
  //     setDeptData(
  //       unitDepartments.filter(item =>
  //         item.od_department_name
  //           .toLowerCase()
  //           .includes(searchDepartment.toLowerCase()),
  //       ),
  //     );
  //   }
  // }, [unitDepartments, searchDepartment]);

  useEffect(() => {
    if (unitClass?.length) {
      setUnitClassName(unitClass?.map(item => item));
    }
  }, [unitClass]);

  useEffect(() => {
    if (unitDepartments?.length) {
      setUnitDepartmentName(unitDepartments?.map(item => item));
    }
  }, [unitDepartments]);

  useEffect(() => {
    dispatch(getAllUnitsTeachers());
    // const teacherColumns = [
    //   'up_id_userprofile',

    //   {
    //     heading: 'TEACHER NAME',
    //   },
    // ];
    // const mapTeacher = {
    //   'TEACHER NAME': [
    //     (firstName, lastName, referenceId, teacherProfileId) => (
    //       <button
    //         type="button"
    //         className="select-teacher"
    //         onClick={() => {
    //           setAddTeacher(state => [
    //             ...state,
    //             {
    //               id: teacherProfileId,
    //               teacher_fname: firstName,
    //               teacher_lname: lastName,
    //               ref_id: referenceId,
    //             },
    //           ]);
    //           setSearchTeacher(`${firstName} ${lastName}`);
    //           setIsSuggestion(false);
    //         }}
    //       >
    //         <div className="teacher-row">
    //           <div className="profile-img">
    //             <img src={profile} alt="src" />
    //           </div>
    //           <div className="profile-name">
    //             <p>
    //               {`${firstName} ${lastName}`} <br />
    //               <span className="ref-id">{referenceId}</span>
    //             </p>
    //           </div>
    //         </div>
    //       </button>
    //     ),
    //     'up_name_first',
    //     'up_name_last',
    //     'up_org_reference',
    //     'up_id_userprofile',
    //   ],
    // };
    // setListColumns(teacherColumns);
    // setListMaps(mapTeacher);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (unitTeachers.length) {
  //     setListData(
  //       unitTeachers.filter(item =>
  //         item.up_name_first
  //           .toLowerCase()
  //           .includes(searchTeacher.toLowerCase()),
  //       ),
  //     );
  //   }
  // }, [unitTeachers, searchTeacher]);

  useEffect(() => {
    setTableData(addStudents);
    if (addStudents?.length === 0) {
      setBtnShow(true);
      setFormShow(true);
    }
    if (spreadSheet?.length > 0) {
      setTableData(spreadSheet);
    }
  }, [addStudents, spreadSheet]);

  useEffect(() => {
    if (unitStudentValidateError?.field !== '') {
      if (unitStudentValidateError?.field === 'useremail') {
        setUserError(unitStudentValidateError?.msg);
      } else if (unitStudentValidateError?.field === 'referID') {
        setRefError(unitStudentValidateError?.msg);
      }
    }
  }, [unitStudentValidateError]);

  useEffect(() => {
    if (unitStudentValidate) {
      if (unitStudentValidate?.up_email !== '') {
        setEmail(unitStudentValidate?.up_email);

        if (
          unitStudentValidate?.up_name_first !== '' &&
          unitStudentValidate?.up_name_last !== ''
        ) {
          setFullName(` ${unitStudentValidate?.up_name_first}`);
          setLastName(` ${unitStudentValidate?.up_name_last}`);
        }
        if (unitStudentValidate?.up_org_reference !== '') {
          setReferID(unitStudentValidate.up_org_reference);
        }
      }
    }
  }, [unitStudentValidate]);

  const handleDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] || event.target.files[0];
    const extn = file.name.split('.').pop();
    if (file && extn === 'csv') {
      const reader = new FileReader();
      reader.onload = () => {
        const jsonObj = csvjson.toObject(reader.result);
        setSpreadSheet(jsonObj);
        setFileName(file.name);
      };
      reader.readAsBinaryString(file);
    }
  };

  const removeItems = position => {
    setAddStudents(state => state.filter((item, index) => index !== position));
  };

  const toggleShow = () => setShowShow(!showShow);
  const toggleShow1 = () => setShowShow1(!showShow1);
  const toggleShow2 = () => setShowShow2(!showShow2);
  const toggleShow3 = () => setShowShow3(!showShow3);
  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState(false);
  const openStartDatePicker = () => {
    setStartDatePickerIsOpen(!startDatePickerIsOpen);
  };
  const openEndDatePicker = () => {
    setEndDatePickerIsOpen(!endDatePickerIsOpen);
  };
  useEffect(() => {
    const columns = [
      'up_id_userprofile',
      {
        heading: '#',
        width: '9%',
      },
      {
        heading: 'ID',
        width: '15%',
      },
      {
        heading: 'FIRST NAME',
        width: '18%',
      },
      {
        heading: 'LAST NAME',
        width: '18%',
      },
      {
        heading: 'EMAIL',
        width: '37%',
      },
      {
        heading: '',
        width: '4%',
      },
    ];
    const maps = {
      '#': [organizationReference => organizationReference + 1],
      ID: [
        organizationStartAcademicYear => organizationStartAcademicYear,
        'referID',
      ],
      'FIRST NAME': [fName => fName, 'fullName'],
      'LAST NAME': [lName => lName, 'lastName'],
      EMAIL: [
        organizationEndAcademicYear => organizationEndAcademicYear,
        'useremail',
      ],
      '': [
        position => (
          <button
            type="button"
            onClick={() => {
              removeItems(position);
            }}
            className="closeIconBtn"
          >
            <img src={closeIcon} alt="close" />
          </button>
        ),
      ],
    };
    setTableColumns(columns);
    setTableMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const studentColumns = [
      'up_id_userprofile',
      {
        heading: '#',
        width: '9%',
      },
      {
        heading: 'ID',
        width: '15%',
      },
      {
        heading: 'FIRST NAME',
        width: '18%',
      },
      {
        heading: 'LAST NAME',
        width: '18%',
      },
      {
        heading: 'EMAIL',
        width: '37%',
      },
    ];
    const studentMaps = {
      '#': [organizationReference => organizationReference + 1],
      ID: [
        organizationStartAcademicYear => organizationStartAcademicYear,
        'referID',
      ],
      'FIRST NAME': [fName => fName, 'fullName'],
      'LAST NAME': [lName => lName, 'lastName'],
      EMAIL: [
        organizationEndAcademicYear => organizationEndAcademicYear,
        'useremail',
      ],
    };
    setStudentTableColumns(studentColumns);
    setStudentTableMaps(studentMaps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEmailError(alreadyExistStudentEmail);
    setRefIdError(alreadyExistStudentRefId);
    setEmailPatternError(studentEmailPattern);
    const columns = [
      'up_id_userprofile',
      {
        heading: '#',
        width: '9%',
      },
      {
        heading: 'ID',
        width: '15%',
      },
      {
        heading: 'FIRST NAME',
        width: '18%',
      },
      {
        heading: 'LAST NAME',
        width: '18%',
      },
      {
        heading: 'EMAIL',
        width: '37%',
      },
    ];
    const maps = {
      '#': [organizationReference => organizationReference + 1],
      ID: [
        organizationStartAcademicYear => organizationStartAcademicYear,
        'referID',
      ],
      'FIRST NAME': [fName => fName, 'fullName'],
      'LAST NAME': [lName => lName, 'lastName'],
      EMAIL: [
        organizationEndAcademicYear => organizationEndAcademicYear,
        'useremail',
      ],
    };
    setErrorColumns(columns);
    setErrorMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alreadyExistStudentEmail,
    alreadyExistStudentRefId,
    studentEmailPattern,
    existingStudentInUnit,
  ]);
  const convertFileSize = size => {
    const fileSizeKb = size / 1024;
    if (fileSizeKb > 1024) {
      const fileSizeMb = fileSizeKb / 1024;
      return `${fileSizeMb.toFixed(2)} Mb`;
    }

    return `${fileSizeKb.toFixed(2)} Kb`;
  };
  return (
    <>
      <Helmet>
        <title>Add Students | JungleCat</title>
      </Helmet>
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#f4f5f8',
          margin: '0px -15px',
          padding: '0 15px',
        }}
      >
        <AddUnitStudentHeader>
          <div className="add-unit-header">
            <div className="add-unit-title">
              <div>
                <span className="add-unit-name">
                  {spreadNext === 0 ? (
                    'Add Unit'
                  ) : (
                    <div className="back-button mt-2">
                      <button
                        className="btn"
                        onClick={() => {
                          history.goBack();
                          dispatch(validateUnitStudentError(''));
                        }}
                        style={{
                          padding: 0,
                        }}
                        type="button"
                      >
                        <img src={arrowLeft} alt="arrow" />
                      </button>
                      <span className="add-unit-name ml-2">Add Students</span>
                    </div>
                  )}{' '}
                </span>
              </div>
              <div>
                <button
                  className="btn"
                  onClick={() => {
                    history.goBack();
                    dispatch(validateUnitStudent(''));
                    dispatch(validateUnitStudentError(''));
                    setReferID('');
                    setFullName('');
                    setLastName('');
                    setEmail('');
                  }}
                  type="button"
                >
                  <img alt="Options" src={closeUnit} />
                </button>
              </div>
            </div>
          </div>
        </AddUnitStudentHeader>
        <AddUnitStudentContainer>
          <div className="container-unit">
            <div className="unitheading d-flex justify-content-between  mb-4">
              <p style={{fontSize: '24px'}} className="main-text">
                {spreadNext === 0 ? 'Add Unit to your organisation' : null}
                {spreadNext >= 1
                  ? existingUnit
                    ? `Add students to ${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname}`
                    : `Add students to ${currentUnit?.orgClassId?.oc_class} - ${currentUnit?.unitId?.un_unitname}`
                  : null}
              </p>
              <p className="sub-text">
                Start introducing users to the newly added units, or do so
                later.
              </p>
            </div>
            <CustomBox padding="1px 20px" className="custombox">
              {spreadNext === 0 ? (
                <div className="add-unit-custom-box">
                  <div className="add-unit-header">
                    <p className="add-unit-header-title">
                      Unit Name{' '}
                      <span>
                        {!classNameTemp && !className
                          ? 'Class name'
                          : classNameTemp || className[0]?.name}{' '}
                        - {!subjectNameTemp ? 'Subject' : subjectNameTemp}
                      </span>
                    </p>
                  </div>

                  <div>
                    <div>
                      <form className="add-unit-form">
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="typehead-title">Class Name</p>
                            {/* <CustomTextInput
                              autoComplete="off"
                              label="Class Name"
                              marginBottom="8px"
                              placeholder="Enter Class name"
                              onChange={e => setClassName(e.target.value)}
                              value={className}
                            /> */}
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onInputChange={value => {
                                setClassName(null);
                                setNewClass(value);
                                setClassNameTemp(value);
                                if (
                                  !unitClassName.find(item =>
                                    item.oc_class
                                      .toLowerCase()
                                      .includes(value.toLowerCase()),
                                  )
                                ) {
                                  classDropdown.current.hideMenu();
                                }
                              }}
                              ref={classDropdown}
                              onChange={value => {
                                setClassName(value);
                              }}
                              placeholder="Choose Class name"
                              selected={className}
                              options={unitClassName?.map(item => ({
                                id: item?.oc_id_orgclass,
                                name: item?.oc_class,
                              }))}
                              emptyLabel=""
                              className="unit-class-name"
                            />
                          </div>
                          <div className="add-unit-col">
                            <CustomTextInput
                              autoComplete="off"
                              label="Subject"
                              marginBottom="8px"
                              placeholder="Enter Subject name"
                              onChange={e => setSubjectName(e.target.value)}
                              onBlur={e => setSubjectNameTemp(e.target.value)}
                              value={subjectName}
                            />
                          </div>
                        </div>
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="typehead-title mt-2">Department</p>
                            {/* <CustomTextInputWithButton
                              autoComplete="off"
                              style={{margin: '0px'}}
                              icon={dropdownIcon}
                              placeholder="Enter Department name"
                              onChange={e => {
                                if (e.target.value.length > 0) {
                                  setIsDepartment(true);
                                } else {
                                  setIsDepartment(false);
                                }
                                setSearchDepartment(e.target.value);
                              }}
                              iconClick={() => {
                                setIsDepartment(state => !state);
                              }}
                              value={searchDepartment}
                            />
                            {isDepartment && deptData.length > 0 ? (
                              <div className="suggestion">
                                {deptColumns && deptData && deptMaps ? (
                                  <CustomTableWithoutHeader
                                    {...tabularizeTable(
                                      deptColumns,
                                      deptData,
                                      deptMaps,
                                    )}
                                    className="search-table"
                                  />
                                ) : null}
                              </div>
                            ) : null} */}
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onChange={value => {
                                setAddDepartment(value);
                              }}
                              placeholder="Choose Department name"
                              selected={addDepartment}
                              options={unitDepartmentName?.map(item => ({
                                id: item?.od_id_orgdepartment,
                                name: item?.od_department_name,
                              }))}
                            />
                          </div>
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              Teacher
                            </p>
                            {/* <CustomTextInputWithIcon
                              className="units-field"
                              autoComplete="off"
                              placeholder="Search for teacher"
                              icon={searchIcon}
                              onChange={e => {
                                if (e.target.value.length > 0) {
                                  setIsSuggestion(true);
                                } else {
                                  setIsSuggestion(false);
                                }
                                setSearchTeacher(e.target.value);
                              }}
                              value={searchTeacher}
                            />
                            {isSuggestion && listData.length > 0 ? (
                              <div className="suggestion">
                                {listColumns && listData && listMaps ? (
                                  <CustomTableWithoutHeader
                                    {...tabularizeTable(
                                      listColumns,
                                      listData,
                                      listMaps,
                                    )}
                                    className="search-table"
                                  />
                                ) : null}
                              </div>
                            ) : null} */}
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey={option =>
                                `${option?.up_name_first} ${option?.up_name_last}`
                              }
                              onChange={value => {
                                setAddTeacher(value);
                              }}
                              placeholder="Choose Teacher"
                              selected={addTeacher}
                              // options={unitTeacherName.map(item => ({
                              //   id: item.up_id_userprofile,
                              //   name: `${item.up_name_first} ${item.up_name_last} ${item.up_org_reference}`,
                              // }))}
                              filterBy={() => true}
                              options={unitTeachers}
                              renderMenuItemChildren={option => (
                                <div className="teacher-row">
                                  <div className="profile-img">
                                    <img src={profile} alt="src" />
                                  </div>
                                  <div className="profile-name">
                                    <p>
                                      {`${option?.up_name_first} ${option?.up_name_last}`}{' '}
                                    </p>
                                    <span className="ref-id">
                                      {`${option?.up_org_reference}`}
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              Start date
                            </p>
                            <CustomDatePicker className="d-flex units-field">
                              <DatePicker
                                name="dueDate"
                                autoComplete="off"
                                dateFormat="do MMMM yyyy"
                                placeholderText="Choose start date"
                                selected={startDate}
                                onChange={date => {
                                  setStartDate(date);
                                  setStartDatePickerIsOpen(false);
                                }}
                                className="form-control border-0 w-100 date-picker"
                                popperPlacement="bottom"
                                onClickOutside={() =>
                                  setStartDatePickerIsOpen(false)
                                }
                                open={startDatePickerIsOpen}
                                onFocus={() => {
                                  openStartDatePicker();
                                }}
                              />
                              <button
                                className="openDatepicker"
                                type="button"
                                onClick={() => {
                                  openStartDatePicker();
                                }}
                              >
                                <img
                                  src={calenderIcon}
                                  className="mr-1"
                                  alt="date"
                                />
                              </button>
                            </CustomDatePicker>
                          </div>
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              End date
                            </p>
                            <CustomDatePicker className="d-flex units-field">
                              <DatePicker
                                name="dueDate"
                                dateFormat="do MMMM yyyy"
                                placeholderText="Choose end date"
                                selected={endDate}
                                onChange={date => {
                                  setEndDate(date);
                                  setEndDatePickerIsOpen(false);
                                }}
                                className="form-control border-0 w-100  date-picker"
                                popperPlacement="bottom"
                                autoComplete="off"
                                minDate={startDate}
                                onClickOutside={() =>
                                  setEndDatePickerIsOpen(false)
                                }
                                open={endDatePickerIsOpen}
                                onFocus={() => {
                                  openEndDatePicker();
                                }}
                              />
                              <button
                                className="openDatepicker"
                                type="button"
                                onClick={() => {
                                  openEndDatePicker();
                                }}
                              >
                                <img
                                  src={calenderIcon}
                                  className="mr-1"
                                  alt="date"
                                />
                              </button>
                            </CustomDatePicker>
                          </div>
                        </div>
                      </form>
                      <br />
                    </div>
                  </div>
                </div>
              ) : null}
              {spreadNext === 1 ? (
                <div className="search-div">
                  <button
                    className={fileImport ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(true);
                      setManual(false);
                      setFullName('');
                      setLastName('');
                      setReferID('');
                      setFormShow(false);
                      setBtnShow(false);
                      setEmail('');
                      setUserError('');
                      setRefError('');
                    }}
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manual ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(false);
                      setManual(true);
                      setProgress(1);
                    }}
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}
              {spreadNext === 2 || isValidating ? (
                <div className="search-div-spread">
                  <p className="imported">Imported users from document</p>
                  <p className="imported-file">{fileName}</p>
                </div>
              ) : null}
              {tableColumns &&
              tableData &&
              tableMaps &&
              manual &&
              addStudents.length > 0 ? (
                <CustomTable
                  {...tabularize(tableColumns, tableData, tableMaps)}
                  className="org-unit-table"
                />
              ) : (
                ''
              )}
              {manual ? (
                <div>
                  {formShow && btnShow ? (
                    <div>
                      <div
                        className="add-unit-modal"
                        style={{padding: '24px 0px 0px 0px'}}
                      >
                        <div className="add-unit-modal-body mb-3">
                          <div className="date-wrapper1">
                            {/* <p className="mb-2 mt-2">Start date</p> */}
                            <CustomTextInput
                              label="Reference ID"
                              autoComplete="off"
                              error={referror}
                              value={referID}
                              disabled={disabled === 'useremail'}
                              onChange={e => {
                                setReferID(e.target.value);
                                setRefError('');
                                setUserError('');
                                if (disabled === 'referID') {
                                  setEmail('');
                                  setFullName('');
                                  setLastName('');
                                }
                                setDisabled(false);
                              }}
                              onBlur={() => {
                                const data = {referId: referID};
                                dispatch(
                                  fetchStudentInOrg(data, field => {
                                    setDisabled(field);
                                  }),
                                );
                              }}
                              placeholder="Enter Reference ID"
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                            />
                          </div>
                          <div className="date-wrapper2">
                            <CustomTextInput
                              label="First Name"
                              value={fullName}
                              disabled={
                                disabled === 'useremail' ||
                                disabled === 'referID'
                              }
                              onChange={e => {
                                setFullName(e.target.value);
                                setDisabled(false);
                              }}
                              placeholder="Enter first name"
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                            />
                          </div>
                          <div className="date-wrapper3">
                            <CustomTextInput
                              label="Last Name"
                              value={lastName}
                              disabled={
                                disabled === 'useremail' ||
                                disabled === 'referID'
                              }
                              onChange={e => {
                                setLastName(e.target.value);
                                setDisabled(false);
                              }}
                              placeholder="Enter last name"
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                            />
                          </div>
                        </div>
                        <CustomTextInput
                          label="Email"
                          value={useremail}
                          disabled={disabled === 'referID'}
                          autoComplete="off"
                          error={userError}
                          onChange={e => {
                            setEmail(e.target.value);
                            setUserError('');
                            setRefError('');
                            if (disabled === 'useremail') {
                              setReferID('');
                              setFullName('');
                              setLastName('');
                            }
                            setDisabled(false);
                          }}
                          onBlur={() => {
                            const data = {userEmail: useremail};
                            dispatch(
                              fetchStudentInOrg(data, field => {
                                setDisabled(field);
                              }),
                            );
                          }}
                          placeholder="Enter Email of the user"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                        />
                      </div>
                      <div
                        className="add-unit-modal"
                        style={{padding: '24px 0px 24px 0px'}}
                      >
                        <div className="button-container">
                          {addStudents?.length && formShow ? (
                            <CustomCancel
                              className="cancel"
                              bgColor="#fff"
                              onClick={() => {
                                setFullName('');
                                setLastName('');
                                setReferID('');
                                setEmail('');
                                setFormShow(false);
                                setBtnShow(false);
                                setUpload(null);
                                setUserError('');
                                setRefError('');
                              }}
                            >
                              <span className="cancel-button-text">Cancel</span>
                            </CustomCancel>
                          ) : null}
                          <CustomCancel
                            className="save-changes"
                            bgColor="#fff"
                            padding="9px 14px"
                            onClick={() => {
                              if (
                                !fullName?.length ||
                                !lastName?.length ||
                                !referID?.length ||
                                !useremail?.length
                              ) {
                                return;
                              }
                              const data = {
                                fullName,
                                lastName,
                                referID,
                                useremail,
                              };
                              // dispatch(
                              //   individualUnitStudentCheck(data, validate => {
                              //     if (validate === '') {
                              //       setAddStudents(state => [
                              //         ...state,
                              //         {fullName, referID, useremail},
                              //       ]);
                              //       setFullName('');
                              //       setReferID('');
                              //       setEmail('');
                              //       setFormShow(false);
                              //       setBtnShow(false);
                              //       setDisabled(false);
                              //     }
                              //   }),
                              // );
                              dispatch(
                                individualUnitStudentCheck(data, validate => {
                                  if (validate === '') {
                                    const arraysOfUserEmail = addStudents.map(
                                      student => student.useremail,
                                    );
                                    const arraysOfRefId = addStudents.map(
                                      student => student.referID,
                                    );
                                    if (
                                      !arraysOfUserEmail.includes(useremail) &&
                                      !arraysOfRefId.includes(referID)
                                    ) {
                                      setAddStudents(state => [
                                        ...state,
                                        {
                                          fullName,
                                          lastName,
                                          referID,
                                          useremail,
                                        },
                                      ]);
                                      setFullName('');
                                      setLastName('');
                                      setReferID('');
                                      setEmail('');
                                      setFormShow(false);
                                      setBtnShow(false);
                                      setDisabled(false);
                                    } else {
                                      if (
                                        arraysOfUserEmail.includes(useremail)
                                      ) {
                                        setUserError(
                                          'This Email Already added in this list',
                                        );
                                      }
                                      if (arraysOfRefId.includes(referID)) {
                                        setRefError(
                                          'This Reference id Already added in this list',
                                        );
                                      }
                                    }
                                  }
                                }),
                              );
                            }}
                          >
                            <img
                              src={addIcons}
                              alt="icon"
                              style={{marginRight: '8px'}}
                            />
                            <span className="add-button-text">Add User</span>
                          </CustomCancel>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="button-container"
                      style={{padding: '24px 0px'}}
                    >
                      <CustomCancel
                        className="save-changes"
                        bgColor="#fff"
                        padding="9px 14px"
                        onClick={() => {
                          setFullName('');
                          setLastName('');
                          setReferID('');
                          setEmail('');
                          setFormShow(true);
                          setBtnShow(true);
                          setDisabled(false);
                        }}
                      >
                        <img
                          src={addIcons}
                          alt="icon"
                          style={{marginRight: '8px'}}
                        />
                        <span className="add-button-text">
                          Add another user
                        </span>
                      </CustomCancel>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="inputfields"
                  style={spreadNext === 2 ? {padding: '0px 0px 0px 0px'} : null}
                >
                  {upload && spreadNext === 1 ? (
                    <div className="org-image-full mt-2">
                      <div className="org-image-after">
                        <img src={uploadSuccess} alt="logo" />
                        <div className="file-details">
                          <span className="file-name">
                            {upload ? upload?.name : ''}
                          </span>
                          <span className="file-size">
                            {convertFileSize(upload ? upload?.size : '')}
                          </span>
                        </div>
                      </div>
                      <div className="delete-logo">
                        <button
                          type="button"
                          onClick={() => {
                            setUpload(null);
                            setNextShow(true);
                          }}
                        >
                          <img src={deletelogo} alt="logo" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onDragEnter={() => {
                        setOnDrag(true);
                      }}
                      onDragLeave={() => {
                        setOnDrag(false);
                      }}
                    >
                      {spreadNext === 1 ? (
                        <div
                          className={`org-image ${onDrag ? 'logo-drag' : ''}`}
                        >
                          <div className="org-logo">
                            <img src={files} className="logo" alt="logo" />
                          </div>
                          <div className="org-text">
                            Drop your file here or{' '}
                            <input
                              type="file"
                              onChange={e => {
                                setUpload(e.target.files[0]);
                                handleDrop(e);
                                setNextShow(false);
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                            />
                            <span className="box__dragndrop">Browse</span>
                          </div>
                          <span className="supports">Supports: XLS, SLV</span>
                          <div className="org-seperator" />
                          <div className="org-size">
                            <span>Download</span>
                            <a
                              ref={downloadRef}
                              download
                              href="https://junglecat-files.s3.ap-southeast-2.amazonaws.com/CSV-aa4da2f55484a13c8760707f1dffc872_1593394944.csv"
                            >
                              &nbsp;Sample.xls
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                  {isValidating ? (
                    <div className="loader" style={{height: '350px'}}>
                      <Loader />
                      <h6 className="mt-3">Validating imported unit details</h6>
                      <p className="please">Please wait</p>
                    </div>
                  ) : null}
                  {!isValidating &&
                  spreadNext === 3 &&
                  alreadyExistStudentRefId?.length === 0 &&
                  emailPatternError?.length === 0 &&
                  existingStudentInUnit?.length === 0 &&
                  alreadyExistStudentEmail?.length === 0 ? (
                    <div className="loader" style={{height: '400px'}}>
                      <img src={greenTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        {existingUnit
                          ? `${spreadSheet?.length} students added to unit ${existingUnit?.class?.oc_class} ${existingUnit?.unit?.un_unitname}`
                          : `${spreadSheet?.length} students added to unit ${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname}`}
                      </h2>
                      <p className="state">
                        All the users in the document has been added as student
                      </p>
                    </div>
                  ) : null}
                  {!isValidating &&
                  spreadNext === 3 &&
                  validUnitStudent?.length === 0 &&
                  (alreadyExistStudentEmail?.length > 0 ||
                    emailPatternError?.length > 0 ||
                    existingStudentInUnit?.length > 0 ||
                    alreadyExistStudentRefId?.length > 0) ? (
                    <div className="loader" style={{height: '400px'}}>
                      <img src={yellowTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        No students added to the unit
                      </h2>
                      <p className="state">
                        All the students already exist in this unit.
                      </p>
                    </div>
                  ) : null}
                  {!isValidating &&
                  spreadNext === 3 &&
                  validUnitStudent?.length > 0 &&
                  (alreadyExistStudentEmail?.length > 0 ||
                    emailPatternError?.length > 0 ||
                    existingStudentInUnit?.length > 0 ||
                    alreadyExistStudentRefId?.length > 0) ? (
                    <div className="loader">
                      <img src={greenTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        {existingUnit
                          ? `${validUnitStudent?.length}/${spreadSheet?.length} students can be
                        added to unit ${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname}`
                          : `${validUnitStudent?.length}/${spreadSheet?.length} students can be
                        added to unit ${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname}`}
                      </h2>
                      <p className="state">
                        Some of the user entries are faulty. you can continue
                        add valid user entries or reupload the document with
                        changes
                      </p>
                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow}
                              >
                                <div>
                                  Email Error (
                                  {alreadyExistStudentEmail?.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>

                          <div
                            id="collapseOne"
                            className={`collapse ${showShow ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              emailError &&
                              errorMaps &&
                              emailError?.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    emailError,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow1}
                              >
                                <div>
                                  Reference ID. Error (
                                  {alreadyExistStudentRefId?.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow1 ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseTwo"
                            className={`collapse ${showShow1 ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              refIdError &&
                              errorMaps &&
                              refIdError?.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    refIdError,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow2}
                              >
                                <div>
                                  Email Pattern Error (
                                  {emailPatternError?.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow2 ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>

                          <div
                            id="collapseOne"
                            className={`collapse ${showShow2 ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              emailPatternError &&
                              errorMaps &&
                              emailPatternError?.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    emailPatternError,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow3}
                              >
                                <div>
                                  Existing Student In This Unit (
                                  {existingStudentInUnit?.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow3 ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>

                          <div
                            id="collapseOne"
                            className={`collapse ${showShow3 ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              errorMaps &&
                              existingStudentInUnit?.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    existingStudentInUnit,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {studentTableColumns &&
              tableData &&
              studentTableMaps &&
              spreadSheet?.length > 0 &&
              spreadNext === 2 &&
              !isValidating ? (
                <CustomTable
                  {...tabularize(
                    studentTableColumns,
                    tableData,
                    studentTableMaps,
                  )}
                  className="org-unit-spreadtable"
                />
              ) : (
                ''
              )}
            </CustomBox>
          </div>
        </AddUnitStudentContainer>
        <AddUnitStudentFooter>
          <div className="add-unit-footer fixed-bottom">
            {manual ? (
              <div className="add-unit-title">
                {!existingUnit ? (
                  <div className="progress-div">
                    <p>{progress}/2 Completed</p>
                    <div className="progress-bar">
                      <div
                        className={
                          progress === 0
                            ? 'progress-loader'
                            : `progress-loader progress-loader-${progress}`
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <div className="footer-seperator" />
                <div className="button-container">
                  {!existingUnit ? (
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          history.replace('/admin/units');

                          if (!existingUnit) {
                            dispatch(
                              setUnitToastSuccess(
                                `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} added successfully`,
                              ),
                            );
                            setTimeout(
                              () => dispatch(setUnitToastSuccess('')),
                              5000,
                            );
                          }
                        }}
                        className="skip-this-step ml-3"
                      >
                        Skip this step
                      </button>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="action-button">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      onClick={() => {
                        setUpload(null);
                        setAddStudents([]);
                        dispatch(validateUnitStudent(''));
                        dispatch(validateUnitStudentError(''));
                        setReferID('');
                        setFullName('');
                        setLastName('');
                        setEmail('');
                        if (existingUnit) {
                          history.replace(
                            `/admin/units/view-unit/${existingUnit?.orui_id_orgunitinstance}`,
                          );
                        } else {
                          history.replace(`/admin/units`);
                        }
                      }}
                    >
                      <span className="cancel1-button-text">Cancel</span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={!addStudents?.length}
                      className="save-changes"
                      text="Next"
                      onClick={() => {
                        if (addStudents?.length) {
                          if (existingUnit) {
                            dispatch(
                              addStudentsUnderNewUnit(
                                addStudents,
                                existingUnit?.orui_id_orgunitinstance,
                                existingUnit,
                                () => {
                                  history.replace(
                                    `/admin/units/view-unit/${existingUnit.orui_id_orgunitinstance}`,
                                  );
                                  dispatch(
                                    setViewUnitToastSuccess(
                                      `${addStudents?.length} Students added successfully on ${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname}`,
                                    ),
                                  );
                                  setTimeout(
                                    () => dispatch(setViewUnitToastSuccess('')),
                                    5000,
                                  );
                                },
                              ),
                            );
                          } else {
                            dispatch(
                              addStudentsUnderNewUnit(
                                addStudents,
                                currentUnit?.orgUnitInstance
                                  ?.orui_id_orgunitinstance,
                                existingUnit,
                              ),
                            );
                          }
                          history.replace('/admin/units');
                          setAddStudents([]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="add-unit-spread">
                {!existingUnit ? (
                  <div className="progress-div">
                    <div>
                      <p>{progress}/2 Completeds</p>
                    </div>
                    <div className="progress-bar">
                      <div
                        className={
                          progress === 0
                            ? 'progress-loader'
                            : `progress-loader progress-loader-${progress}`
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <div className="footer-seperator" />
                {spreadNext === 3 ? <div className="button-container" /> : null}
                {spreadNext === 0 ? (
                  <div className="button-container">
                    <div />
                    <div className="action-button">
                      <CustomCancel
                        className="cancel"
                        bgColor="#fff"
                        onClick={() => {
                          history.goBack();
                          dispatch(validateUnitStudentError(''));
                        }}
                      >
                        <span className="cancel-button-text">Cancel</span>
                      </CustomCancel>

                      <CustomButton
                        isDisabled={
                          !startDate ||
                          !endDate ||
                          !subjectName ||
                          (!className && !newClass) ||
                          !addDepartment?.length > 0 ||
                          !addTeacher?.length > 0
                        }
                        className="save-changes"
                        text="Next"
                        onClick={() => {
                          const unitData = {
                            newclass: newClass,
                            classname: className ? className[0] : null,
                            subjectname: subjectName,
                            department: addDepartment[0],
                            teacher: addTeacher[0],
                            startdate: startDate,
                            enddate: endDate,
                          };
                          setClassName(null);
                          dispatch(
                            addUnit(unitData, () => {
                              setProgress(state => state + 1);
                              setSpreadNextShow(state => state + 1);
                              setClassName([]);
                              setSubjectName('');
                              setAddDepartment([]);
                              setAddTeacher([]);
                              setStartDate('');
                              setEndDate('');
                            }),
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {spreadNext === 1 ? (
                  <div className="button-container">
                    {!existingUnit ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            history.replace('/admin/units');

                            if (!existingUnit) {
                              dispatch(
                                setUnitToastSuccess(
                                  `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} added successfully`,
                                ),
                              );
                              setTimeout(
                                () => dispatch(setUnitToastSuccess('')),
                                5000,
                              );
                              history.replace('/admin/units');
                            } else {
                              history.replace(
                                `/admin/units/view-unit/${existingUnit.orui_id_orgunitinstance}`,
                              );
                            }
                          }}
                          className="skip-this-step ml-3"
                        >
                          Skip this step
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div className="action-button">
                      <CustomCancel
                        className="cancel1"
                        bgColor="#fff"
                        onClick={() => {
                          setUpload(null);
                          setSpreadSheet([]);
                          setAddStudents([]);
                          if (existingUnit) {
                            history.replace(
                              `/admin/units/view-unit/${existingUnit?.orui_id_orgunitinstance}`,
                            );
                          } else {
                            history.replace('/admin/units');
                          }
                        }}
                      >
                        <span className="cancel1-button-text">Cancel</span>
                      </CustomCancel>
                      <CustomButton
                        isDisabled={!spreadSheet?.length || nextShow}
                        className="save-changes"
                        text="Next"
                        onClick={() => {
                          if (spreadSheet?.length) {
                            setSpreadNextShow(state => state + 1);
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {spreadNext === 2 ? (
                  <div className="button-container">
                    {!existingUnit ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            if (!existingUnit) {
                              dispatch(
                                setUnitToastSuccess(
                                  `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} added successfully`,
                                ),
                              );
                              setTimeout(
                                () => dispatch(setUnitToastSuccess('')),
                                5000,
                              );
                              history.replace('/admin/units');
                            } else {
                              history.replace(
                                `/admin/units/view-unit/${existingUnit?.orui_id_orgunitinstance}`,
                              );
                            }
                          }}
                          className="skip-this-step ml-3"
                        >
                          Skip this step
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div className="action-button">
                      <CustomCancel
                        className="cancel1"
                        bgColor="#fff"
                        isDisabled={isValidating}
                        onClick={() => {
                          setUpload(null);
                          setSpreadNextShow(1);
                          setSpreadSheet([]);
                        }}
                      >
                        <span className="cancel1-button-text">Cancel</span>
                      </CustomCancel>
                      <CustomButton
                        isDisabled={
                          !spreadSheet?.length || nextShow || isValidating
                        }
                        className="save-changes"
                        text="Next"
                        onClick={() => {
                          setIsValidating(true);
                          if (existingUnit) {
                            dispatch(
                              spreadSheetStudentCheck(
                                spreadSheet,
                                existingUnit,
                                () => {
                                  setTimeout(() => {
                                    if (
                                      validUnitStudent?.length ===
                                      spreadSheet?.length
                                    ) {
                                      setProgress(2);
                                    }
                                    setIsValidating(false);
                                    setSpreadNextShow(state => state + 1);
                                  }, 3000);
                                },
                              ),
                            );
                          } else {
                            dispatch(
                              spreadSheetStudentCheck(
                                spreadSheet,
                                existingUnit,
                                () => {
                                  setTimeout(() => {
                                    if (
                                      validUnitStudent?.length ===
                                      spreadSheet?.length
                                    ) {
                                      setProgress(2);
                                    }
                                    setIsValidating(false);
                                    setSpreadNextShow(state => state + 1);
                                  }, 3000);
                                },
                              ),
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {spreadNext === 3 &&
                alreadyExistStudentEmail?.length === 0 &&
                emailPatternError?.length === 0 &&
                existingStudentInUnit?.length === 0 &&
                alreadyExistStudentRefId?.length === 0 ? (
                  <div className="button-container">
                    <div />
                    <div>
                      <CustomButton
                        isDisabled={
                          !spreadSheet?.length || nextShow || isValidating
                        }
                        className="save-changes"
                        text="Done"
                        onClick={() => {
                          setSpreadNextShow(state => state + 1);
                          setTimeout(setProgress(2), 5000);

                          if (!existingUnit) {
                            history.replace(`/admin/units`);
                            dispatch(
                              setUnitToastSuccess(
                                `${currentUnit?.orgClassId?.oc_class} ${currentUnit?.unitId?.un_unitname} added successfully with ${spreadSheet?.length} Students `,
                              ),
                            );
                            setTimeout(
                              () => dispatch(setUnitToastSuccess('')),
                              5000,
                            );
                          } else {
                            history.replace(
                              `/admin/units/view-unit/${existingUnit.orui_id_orgunitinstance}`,
                            );
                            dispatch(
                              setViewUnitToastSuccess(
                                `${spreadSheet?.length} Students added successfully on ${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname}`,
                              ),
                            );
                            setTimeout(
                              () => dispatch(setViewUnitToastSuccess('')),
                              5000,
                            );
                          }
                          // setIsValidating(true);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {spreadNext === 3 &&
                !isValidating &&
                validUnitStudent?.length > 0 &&
                (alreadyExistStudentEmail?.length > 0 ||
                  emailPatternError?.length > 0 ||
                  existingStudentInUnit?.length > 0 ||
                  alreadyExistStudentRefId?.length > 0) ? (
                  <div className="button-container">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      onClick={() => {
                        setUpload(null);
                        setSpreadNextShow(1);
                        setSpreadSheet([]);
                      }}
                    >
                      <span className="cancel1-button-text">
                        Discard and Reupload
                      </span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={
                        !spreadSheet?.length ||
                        nextShow ||
                        isValidating ||
                        !validUnitStudent?.length
                      }
                      className="save-changes"
                      text="Add valid users"
                      onClick={() => {
                        if (validUnitStudent?.length > 0) {
                          if (existingUnit) {
                            dispatch(
                              addStudentsUnderNewUnit(
                                validUnitStudent,
                                existingUnit?.orui_id_orgunitinstance,
                                existingUnit,
                                () => {
                                  history.replace(
                                    `/admin/units/view-unit/${existingUnit.orui_id_orgunitinstance}`,
                                  );
                                  dispatch(
                                    setViewUnitToastSuccess(
                                      `${validUnitStudent?.length} Students added successfully on ${existingUnit?.class?.oc_class} - ${existingUnit?.unit?.un_unitname}`,
                                    ),
                                  );
                                  setTimeout(
                                    () => dispatch(setViewUnitToastSuccess('')),
                                    5000,
                                  );
                                  setSpreadSheet([]);
                                },
                              ),
                            );
                          } else {
                            dispatch(
                              addStudentsUnderNewUnit(
                                validUnitStudent,
                                currentUnit?.orgUnitInstance
                                  ?.orui_id_orgunitinstance,
                                () => {
                                  setProgress(2);
                                  setSpreadSheet([]);
                                },
                              ),
                            );
                          }
                        }
                      }}
                    />
                  </div>
                ) : null}
                {spreadNext === 3 &&
                !isValidating &&
                validUnitStudent?.length === 0 &&
                (alreadyExistStudentEmail?.length > 0 ||
                  emailPatternError?.length > 0 ||
                  existingStudentInUnit?.length > 0 ||
                  alreadyExistStudentRefId?.length > 0) ? (
                  <CustomButton
                    isDisabled={
                      !spreadSheet?.length || nextShow || isValidating
                    }
                    className="save-changes"
                    text="Done"
                    onClick={() => {
                      if (!existingUnit) {
                        history.replace(`/admin/units`);
                      } else {
                        history.replace(
                          `/admin/units/view-unit/${existingUnit.orui_id_orgunitinstance}`,
                        );
                      }
                    }}
                  />
                ) : null}
              </div>
            )}
          </div>
        </AddUnitStudentFooter>
      </div>
    </>
  );
};

export default AddUnitAndStudent;
