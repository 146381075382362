/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Helmet from 'react-helmet';
import {AttendenceContainer, AttendenceCardListContainer} from './elements';
import {AttendanceFooter, AttendanceHeader, AttendanceCard} from './components';
import {
  fetchStudentsForSessionAttendance,
  attendanceSubmissionForSession,
  sessionsSelector,
} from '../../../../../../redux/sessionsSlice';
import {showToast} from '../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../assets/icons/addtoastadmin.svg';

const alphabetList = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const Attendance = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const [attendance, setAttendance] = useState({});
  const history = useHistory();

  const letters = {};
  // const scrollLetter = {};
  const {sessionId} = useParams();
  const {studentsForAttendance, isLoadingSubmission, isLoading} =
    useSelector(sessionsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStudentsForSessionAttendance(sessionId));
  }, [dispatch, sessionId]);

  // based on scroll highlight a tag

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const headerOffset =
  //       document.getElementById('header-container')?.offsetHeight;

  //     const [...classes] = document.getElementsByClassName('alphabets');
  //     classes?.map(item => {
  //       const letter = (item.textContent || item.innerText || '').toLowerCase();
  //       if (letter && !(letter in scrollLetter)) {
  //         scrollLetter[letter] = item;
  //         return scrollLetter[letter];
  //       }
  //       return null;
  //     });

  //     classes?.map(item => {
  //       const letter = (item.textContent || item.innerText || '').toLowerCase();

  //       const elementPosition =
  //         scrollLetter[letter].getBoundingClientRect().top;

  //       const offsetPosition =
  //         elementPosition + window.pageYOffset - headerOffset;

  //       if (letters[letter] && offsetPosition >= elementPosition) {
  //         return item.classList.add('scrolled');
  //       }

  //       return item.classList.remove('scrolled');
  //     });
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // });

  // push first letters of unique elements

  useEffect(() => {
    const [...classes] = document.getElementsByClassName('card-name');

    classes?.map(item => {
      const letter = (
        item.textContent ||
        item.innerText ||
        ''
      ).toLowerCase()[0];

      if (letter && !(letter in letters)) {
        letters[letter] = item;
        return letters[letter];
      }
      return null;
    });
  }, [letters, studentsForAttendance]);

  // onclick function for current element of letter and scroll property

  const letterFunc = e => {
    e.preventDefault();

    const letter = e.target.textContent.toLowerCase();

    if (letters[letter]) {
      const headerOffset =
        document.getElementById('header-container')?.offsetHeight;

      const elementPosition = letters[letter].getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  // onclick function for attendance submission

  const attendanceSubmission = () => {
    if (!isLoadingSubmission) {
      const details = {
        attendance,
        status: 1,
      };
      dispatch(
        attendanceSubmissionForSession(sessionId, details, () => {
          showToast(
            AddToastSuccessImg,
            `${studentsForAttendance?.session?.oruil_name} attendance submitted successfully`,
          );
          history.replace(`/admin/sessions`);
        }),
      );
    }
  };
  const attendanceSaveDraft = () => {
    if (!isLoadingSubmission) {
      const details = {
        attendance,
        status: 0,
      };
      dispatch(
        attendanceSubmissionForSession(sessionId, details, () => {
          showToast(
            AddToastSuccessImg,
            `${studentsForAttendance?.session?.oruil_name} attendance saved as draft successfully`,
          );
          history.replace(`/admin/sessions`);
        }),
      );
    }
  };

  // student data list binding function

  const studentsList = useMemo(
    () =>
      studentsForAttendance?.allStudents?.filter(
        item =>
          item?.up_org_reference?.toLowerCase().includes(searchTerm) ||
          `${item?.up_name_first} ${item?.up_name_last}`
            .toLowerCase()
            .includes(searchTerm),
      ) || [],
    [searchTerm, studentsForAttendance],
  );

  // set data for initial setup

  useEffect(() => {
    studentsForAttendance?.allStudents?.map(item => {
      setAttendance(data => {
        if ([3].includes(item?.Attendance?.typat_id_typeattendance)) {
          return {
            ...data,
            [item?.up_id_userprofile]: 3,
          };
        }
        if ([2].includes(item?.Attendance?.typat_id_typeattendance)) {
          return {
            ...data,
            [item?.up_id_userprofile]: 2,
          };
        }
        return {
          ...data,
          [item?.up_id_userprofile]: 1,
        };
      });
      return null;
    });
  }, [studentsForAttendance]);

  return (
    <div>
      <Helmet>
        <title>Attendance | JungleCat</title>
      </Helmet>
      <AttendenceContainer>
        {isLoading ? (
          <Skeleton
            height={200}
            width="100%"
            className="header-container fixed-top"
          />
        ) : (
          <AttendanceHeader
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            sessionDetails={studentsForAttendance?.session}
            setAttendance={setAttendance}
            studentsList={studentsList}
          />
        )}
        <AttendenceCardListContainer>
          <div className="container-card">
            <div className="alphabet-list">
              {alphabetList.map(item => (
                <button
                  className="alphabets"
                  href={item.toLocaleLowerCase}
                  onClick={letterFunc}
                  type="button"
                >
                  {item}
                </button>
              ))}
            </div>
            <div className="row card-container" id="card-container">
              {(searchTerm?.length
                ? studentsList
                : studentsForAttendance?.allStudents
              )?.map(item => (
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                  <AttendanceCard
                    studentName={`${item?.up_name_first} ${item?.up_name_last}`}
                    profileImg={item?.up_avatar}
                    referenceId={item?.up_org_reference}
                    attendType={attendance[item?.up_id_userprofile] || 1}
                    onPresent={() => {
                      setAttendance(data => {
                        if (
                          !data[item.up_id_userprofile] ||
                          [1, 3].includes(data[item.up_id_userprofile])
                        ) {
                          return {
                            ...data,
                            [item?.up_id_userprofile]: 2,
                          };
                        }

                        return {
                          ...data,
                          [item?.up_id_userprofile]: 1,
                        };
                      });
                    }}
                    onAbsent={() => {
                      setAttendance(data => {
                        if (
                          !data[item.up_id_userprofile] ||
                          [1, 2].includes(data[item.up_id_userprofile])
                        ) {
                          return {
                            ...data,
                            [item?.up_id_userprofile]: 3,
                          };
                        }

                        return {
                          ...data,
                          [item?.up_id_userprofile]: 1,
                        };
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </AttendenceCardListContainer>
        <AttendanceFooter
          studentsList={studentsList}
          attendance={attendance}
          onSubmit={attendanceSubmission}
          sessionDetails={studentsForAttendance?.session}
          onDraft={attendanceSaveDraft}
          isLoading={isLoadingSubmission}
        />
      </AttendenceContainer>
    </div>
  );
};
