/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Chart, registerables} from 'chart.js';
import {Bar} from 'react-chartjs-2';

import {SessionAttendanceGraphConatiner} from './elements';
import {portfolioSelector} from '../../../../../../redux/portfolioSlice';

Chart.register(...registerables);

const options = {
  responsive: true,
  // maintainAspectRatio: false,
  barPercentage: 1,
  categoryPercentage: 0.7,
  type: 'bar',
  skipNull: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        fontSize: 25,
        color: '#9EA3A5',
        fontWeight: 500,
        stepSize: 10,
      },
      min: 0,
      max: 50,
    },

    x: {
      beginAtZero: true,
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        fontSize: 25,
        color: '#9EA3A5',
        fontWeight: 500,
      },
    },
    // xAxes: [
    //   {
    //     ticks: {
    //       fontSize: 40,
    //     },
    //   },
    // ],
    // yAxes: [
    //   {
    //     ticks: {
    //       fontSize: 40,
    //     },
    //   },
    // ],
  },
};

export const SessionAttendanceGraph = () => {
  const {presentSessions, absentSessions} = useSelector(portfolioSelector);

  const data = useMemo(
    () => ({
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: 'Sessions attended',
          backgroundColor: '#B7D8FF',
          // borderColor: 'rgba(255,99,132,1)',
          // borderWidth: 1,
          // stack: 1,
          // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          //   hoverBackgroundColor: '#1973DA',
          data: presentSessions,
          // borderRadius: '0.5rem',
        },
        {
          label: 'Sessions not attended',
          backgroundColor: '#FFC3C3',
          // borderColor: 'rgba(255,99,132,1)',
          // borderWidth: 1,
          // stack: 1,
          // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          // hoverBorderColor: 'rgba(255,99,132,1)',
          data: absentSessions,
        },
      ],
    }),
    [presentSessions, absentSessions],
  );
  return (
    <>
      <SessionAttendanceGraphConatiner>
        <Bar data={data} options={options} className="bar-chart" />
      </SessionAttendanceGraphConatiner>
    </>
  );
};
