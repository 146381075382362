import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import MainInput from '../../../../components/shared/Inputs/MainInput';
import CustomButton from '../../../../components/shared/Buttons/CustomButton';
import {Nav} from '../../../../components/shared/Navbar/Elements/Navbar';
import DropdownWrapper from '../../../../components/shared/DropdownWrapper/DropdownWrapper';
import EllipsisImg from '../../../../assets/icons/ellipsis.svg';
import ArrowLeftImg from '../../../../assets/icons/arrow_left.svg';

import {
  sendSearchField,
  setSearchField,
  setAssessmentTitle,
} from '../Assesments/Assessment/slices/assessmentSlice';
import useNavbarScroll from '../../../../hooks/useNavbarScroll';
import {SearchOrAddAssessmentContainer} from './elements';
import {PublishAssessments, AssessmentSearchItems} from './components';

export const SearchOrAddAssessment = () => {
  const input = useRef();
  const [classId, setClassId] = useState('');
  const [menuOpen, toggleMenu] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const dispatch = useDispatch();
  const assessmentState = useSelector(state => state.assessment);
  const history = useHistory();
  const {pathname} = history.location;

  const {handleScroll, navbarTop} = useNavbarScroll(16, 81);

  useEffect(() => {
    if (input) input.current.focus();
  }, []);
  useEffect(() => {
    dispatch(setSearchField(''));
  }, [dispatch]);

  useEffect(() => {
    // Grabing a classId
    if (/stream/.test(pathname)) {
      const Id = pathname.split('/')[2];
      setClassId(Id);
    } else {
      setClassId('');
    }
  }, [pathname]);

  const onSubmitPress = () => {
    setIsClicked(true);
    if (isClicked) return;
    dispatch(setAssessmentTitle(classId, history));
  };

  return (
    <>
      <Helmet>
        <title>Search or Add an Assessment | JungleCat</title>
      </Helmet>
      <SearchOrAddAssessmentContainer className="row position-relative assessment-search-body">
        <Nav
          className="navbar navbar-expand-sm p-0 fixed-top row px-lg-4 ml-0 w-100"
          style={{
            top: navbarTop,
          }}
        >
          <button
            className="btn"
            onClick={() => history.goBack()}
            style={{
              padding: 0,
            }}
            type="button"
          >
            <img src={ArrowLeftImg} alt="arrow" />
          </button>
          <p className="assessment-heading mb-0 mr-auto">
            Publish an Assessment to the class
          </p>
          <DropdownWrapper toggleMenu={toggleMenu}>
            <button
              type="submit"
              className="btn"
              onClick={() => toggleMenu(!menuOpen)}
            >
              <img src={EllipsisImg} alt="ellipsis" />
            </button>
          </DropdownWrapper>
        </Nav>
        <main className="main-body" onScroll={handleScroll}>
          <div className="col-lg-6 col-12 mx-auto assessment-search-content">
            <PublishAssessments isButtonShow={false} />
            <div className="assessment-search-inputDiv">
              <MainInput
                type="text"
                id="search-add"
                label=""
                name="search-add"
                placeholder="Search for an assessment here"
                autoComplete="off"
                onChange={e =>
                  dispatch(sendSearchField(classId, e.target.value))
                }
                value={assessmentState.searchTerm}
                register={input}
                style={
                  assessmentState.searchTerm !== '' &&
                  !assessmentState.searchAssessments.length
                    ? {
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',
                      }
                    : {}
                }
              />

              {assessmentState.searchAssessments.length ? (
                <AssessmentSearchItems
                  searchAssessments={assessmentState.searchAssessments}
                  assessmentLoading={assessmentState.AssessmentLoading}
                />
              ) : (
                <></>
              )}
              {assessmentState.searchTerm !== '' && (
                <CustomButton
                  type="button"
                  width="100%"
                  padding="0.625rem 1.125rem"
                  bgColor="#40A1FF"
                  onClick={onSubmitPress}
                  className="add-assessment-button"
                >
                  Add `{assessmentState.searchTerm}` as a New Assessment
                </CustomButton>
              )}
            </div>
          </div>
        </main>
      </SearchOrAddAssessmentContainer>
    </>
  );
};
