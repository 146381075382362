import styled from 'styled-components';

export const ViewFileSharedToClassHeaderContainer = styled.div`
  .header-container {
    width: 100%;
    background-color: #ffffff;
  }
  .back-container {
    display: flex;
  }
  .backbutton {
    outline: none;
    border: none;
    background-color: transparent;

    img {
      vertical-align: middle;
      margin-right: 0.75rem;
    }
  }

  .button {
    &.add-button {
      background-color: #40a1ff;
      margin-left: 1rem;
      border: 0.0625rem solid ${({theme}) => theme.colors.primary};

      :active,
      :focus {
        border-color: ${({theme}) => theme.colors.primary};
      }

      .label {
        color: #ffffff;
      }
    }
  }
  .header-top {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    justify-content: space-between;
    .left-content {
      display: flex;
      flex-direction: column;
    }
    .right-content {
      display: flex;
    }
    .class-name {
      font-size: 16px;
      color: #5ba1f2;
    }

    .title {
      font-weight: 500;
      font-size: 1.5rem;
      color: #0c181f;
      margin-bottom: 0.25rem;
      text-transform: capitalize;
    }
    .year {
      font-weight: 400;
      font-size: 0.75rem;
      color: #aaaaaa;
      font-style: normal;
      font-weight: 400;
      margin-bottom: unset;
    }
    .file-download {
      background-color: unset;
      border: unset;
      outline: unset;
      border: 0.0625rem solid #e0e0e0;
      border-radius: 0.5rem;
      padding: 0.625rem 0.625rem;
      display: flex;
      text-align: unset;
      img {
        vertical-align: unset;
      }
    }
    .menu-dot {
      background-color: unset !important;
    }
    .menu-btn {
      padding: 0.45rem 0.5rem;
      background-color: unset;
      border: unset;
      outline: unset;
      border: 0.0625rem solid #e0e0e0;
      border-radius: 0.5rem;
      display: flex;
      text-align: unset;
    }
    @media (max-width: 900px) {
      & {
        display: flex;
        flex-direction: column;
        padding: 1rem 1.5rem 1rem 1.5rem;
        width: 100%;
      }
    }
    @media (max-width: 500px) {
      & {
        padding: 1rem 1.5rem 1rem 1.5rem;
        width: 100%;
      }
      .right-content {
        margin-top: 0.5rem;
        .button {
          &.add-button {
            margin-left: unset !important;
            margin-top: 0.5rem !important;
          }
        }
      }
      .left-content {
        .details {
          flex-wrap: wrap;
          .teacher-name {
            margin-left: unset;
          }
        }
      }
    }
  }
`;
