/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Color} from '../../../constants';

export const FullContainer = styled.div`
  min-height: 100vh;
  background-color: #f4f5f8;
  margin: 0rem -0.9375rem;
  padding: 0 0.9375rem;
`;

export const AddQualificationUnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0rem -0.9375rem 0rem -0.9375rem;
  padding: 8rem 0 12rem 0;
  background-color: #f4f5f8;
  .container-admin {
    padding: 0 0.9375rem;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 55.5rem) {
      & {
        width: 62%;
        min-width: 55.5rem;
      }
    }
  }
  .adminheading {
    display: flex;
    flex-direction: column;
    h2 {
      color: #3d4457;
    }
    .sub-text {
      font-size: 0.875rem;
      color: #8a8c94;
    }
  }
  .custombox {
    .search-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-bottom: 0.0625rem solid #e0e0e0;
      padding: 1.5rem 0rem 0rem 0rem;
      .buttom {
        margin-right: 2.5rem;
        border: none;
        background-color: unset;
        outline: none;
        color: #bcbcbc;
        &.link {
          border-bottom: 0.0625rem solid black;
          color: #3d4457;
        }
      }
    }

    .search-div-spread {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1.5rem 0rem 1.125rem 0;
      .imported {
        padding: 0;
        margin: 0;
        color: #3d4457;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
      }
      .imported-file {
        margin: 0;
        padding: 0;
        color: #aaaaaa;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
      }
    }

    .threeDotIcon {
      border: none;
      background: transparent;
      outline: none;
    }
  }

  .inputfields {
    padding: 1.5rem 0 1.5rem 0rem;
    .logo-label {
      color: #aaa;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .org-size {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      span {
        color: #adadad;
      }
      a {
        color: #40a1ff;
        :hover {
          color: #40a1ff;
          text-decoration: none;
        }
      }
    }
    .error-size {
      color: red;
    }
  }
  .type-container {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 49.37rem) {
    .type-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
  .core {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 1rem;
    background: #ecf6ff;
    text-align: center;
    text-transform: uppercase;
    color: #54abff;
    border-radius: 0.3125rem;
  }
  .elective {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 1rem;
    background: #defae8;
    text-align: center;
    text-transform: uppercase;
    color: #4ec977;
    border-radius: 0.3125rem;
  }
`;

export const SpreadSheetTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 0 0 1.5rem 0;
  width: 100%;
  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;

    td {
      :last-child {
        display: flex !important;
        justify-content: space-between;
      }
    }

    .serial {
      width: 5%;
    }

    .qualification-code {
      width: 35%;
    }

    .qualification-name {
      width: 60%;
    }
  }
`;

export const SpreadSheetUnitTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 0 0 1.5rem 0;
  width: 100%;

  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;
    @media (max-width: 49.37rem) {
      td:last-child {
        display: flex !important;
        justify-content: space-between !important;
      }
    }

    .serial {
      width: 5%;
    }

    .unit-code {
      width: 20%;
    }

    .unit-name {
      width: 40%;
    }
    .unit-types {
      width: 35%;
    }
  }
`;
