/* eslint-disable react/prop-types */
import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showToast} from '../../../../../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../../../../../assets/icons/addtoastadmin.svg';
import {
  spreadSheetClassStudentCheck,
  addStudentsUnderNewClass,
  classesSelector,
} from '../../../../../../../../../../redux/classesSlice';
import {ProgressLine} from '../../../../components';
import {AddStudentFooterContainer} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import CustomButton from '../../../../../../../../../../components/shared/CustomButton';

export const AddStudentFooter = ({
  addStudents,
  setAddStudents,
  spreadSheet,
  setSpreadSheet,
  manual,
  steps,
  setSteps,
  setUpload,
  setIsValidating,
  nextShow,
  isValidating,
  studentEmailPattern,
  setProgress,
  progress,
  otherOrgEmailErrorData,
  otherOrgReferIdErrorData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAddingStudents = useRef(false);
  const {
    validClassStudent,
    currentClass,
    duplicateData,
    newClassUnitsCount,
    isAddClassLoading,
  } = useSelector(classesSelector);
  return (
    <AddStudentFooterContainer>
      <div className="add-student-footer fixed-bottom">
        {manual ? (
          <div className="add-student-spread">
            <div className="progress-section">
              <ProgressLine progress={progress} />
            </div>
            <div className="add-student-title">
              <div className="button-container">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      showToast(
                        AddToastSuccessImg,
                        `${currentClass?.classData?.classname} - class  added successfully with ${newClassUnitsCount} Units`,
                      );
                      history.replace(`/admin/classes`);
                    }}
                    className="skip-this-step mr-3"
                    id="skip-this-step-manual"
                  >
                    Skip this step
                  </button>
                </div>
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setAddStudents([]);
                    showToast(
                      AddToastSuccessImg,
                      `${currentClass?.classData?.classname} - class  added successfully with ${newClassUnitsCount} Units`,
                    );
                    history.replace(`/admin/classes`);
                  }}
                  id="cancel-manual"
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!addStudents?.length}
                  className="save-changes"
                  text="Add Students"
                  onClick={() => {
                    if (isAddingStudents.current) return;
                    isAddingStudents.current = true;

                    if (addStudents?.length > 0 && !isAddClassLoading) {
                      dispatch(
                        addStudentsUnderNewClass(addStudents, () => {
                          isAddingStudents.current = false;
                          showToast(
                            AddToastSuccessImg,
                            `${currentClass?.classData?.classname} - class added successfully with ${addStudents?.length} Students`,
                          );

                          history.replace('/admin/classes');

                          setProgress(4);
                          setAddStudents([]);
                        }),
                      );
                    }
                  }}
                  id="add-students-manual"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="add-student-spread">
            {steps === 0 ? (
              <div className="progress-section">
                <ProgressLine progress={progress} />
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(0);
                    setSpreadSheet([]);
                    setUpload(null);
                    setAddStudents([]);
                  }}
                  id="back"
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validClassStudent?.length &&
            (studentEmailPattern.length ||
              duplicateData?.length ||
              otherOrgEmailErrorData?.length ||
              otherOrgReferIdErrorData?.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                    setProgress(3);
                  }}
                  id="back-2"
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : (
              <div className="button-container" />
            )}
            {steps === 3 ? <div className="button-container" /> : null}

            {steps === 0 ? (
              <div className="button-container">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      showToast(
                        AddToastSuccessImg,
                        `${currentClass?.classData?.classname} - class  added successfully with ${newClassUnitsCount} Units`,
                      );
                      history.replace(`/admin/classes`);
                    }}
                    className="skip-this-step mr-3"
                    id="skip-this-step-0"
                  >
                    Skip this step
                  </button>
                </div>
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(0);
                    setSpreadSheet([]);
                    setAddStudents([]);
                    setUpload(null);
                    showToast(
                      AddToastSuccessImg,
                      `${currentClass?.classData?.classname} - class  added successfully with ${newClassUnitsCount} Units`,
                    );
                    history.replace(`/admin/classes`);
                  }}
                  id="cancel-0"
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    setProgress(3);
                    setSteps(state => state + 1);
                  }}
                  id="next-0"
                />
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  isDisabled={isValidating}
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                    setProgress(3);
                  }}
                  id="upload-again"
                >
                  <span className="cancel1-button-text">Upload Again</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Add Students"
                  onClick={() => {
                    setIsValidating(true);
                    dispatch(
                      spreadSheetClassStudentCheck(spreadSheet, () => {
                        setTimeout(() => {
                          setProgress(4);
                          setIsValidating(false);
                          setSteps(state => state + 1);
                        }, 3000);
                      }),
                    );
                  }}
                  id="add-students-spreadsheet"
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validClassStudent?.length === spreadSheet.length &&
            (!studentEmailPattern?.length ||
              !otherOrgEmailErrorData?.length ||
              !otherOrgReferIdErrorData?.length ||
              !duplicateData?.length) ? (
              <div className="button-container">
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    showToast(
                      AddToastSuccessImg,
                      `${currentClass?.classData?.classname} - class  added successfully with ${validClassStudent?.length} Students`,
                    );

                    setSteps(state => state + 1);
                    history.replace('/admin/classes');
                    setIsValidating(true);
                  }}
                  id="done"
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validClassStudent?.length &&
            (studentEmailPattern?.length ||
              duplicateData?.length ||
              otherOrgEmailErrorData?.length ||
              otherOrgReferIdErrorData?.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                    setProgress(3);
                  }}
                  id="discard-reupload"
                >
                  <span className="cancel1-button-text">
                    Discard and Reupload
                  </span>
                </CustomCancel>
                <CustomButton
                  isDisabled={
                    !spreadSheet?.length ||
                    nextShow ||
                    isValidating ||
                    !validClassStudent?.length
                  }
                  id="add-valid-entries"
                  className="save-changes"
                  text="Add valid students"
                  onClick={() => {
                    if (isAddingStudents.current) return;
                    isAddingStudents.current = true;

                    if (validClassStudent.length > 0 && !isAddClassLoading) {
                      dispatch(
                        addStudentsUnderNewClass(validClassStudent, () => {
                          isAddingStudents.current = false;
                          showToast(
                            AddToastSuccessImg,
                            `${currentClass?.classData?.classname} - class added successfully with ${validClassStudent?.length} Students`,
                          );
                          history.replace('/admin/classes');
                          setSpreadSheet([]);
                        }),
                      );
                    }
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            !validClassStudent?.length &&
            studentEmailPattern.length &&
            otherOrgEmailErrorData?.length &&
            otherOrgReferIdErrorData?.length &&
            duplicateData?.length ? (
              <div className="button-container">
                <CustomButton
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    history.replace('/admin/classes');
                  }}
                  id="done-2"
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </AddStudentFooterContainer>
  );
};
