import axios from '../..';

export const fetchAllStudents = () => axios.get(`api/admin/org/all-students`);

export const updateStudent = (studentId, data) =>
  axios.patch(`api/admin/org/edit-student/${studentId}`, data);

export const deleteStudent = studentId =>
  axios.delete(`api/admin/org/delete-student/${studentId}`);

export const resendStudentInvitation = (studentId, data) =>
  axios.patch(`api/admin/org/student/resend-invitation/${studentId}`, data);

// Add Student flow

export const individualStudentCheck = data =>
  axios.post(`api/admin/org/student/student-validation`, data);

export const spreadSheetStudentsCheck = data =>
  axios.post(`api/admin/org/student/student-spreadsheet-validation`, data);

export const addStudentsSpread = data =>
  axios.post(`api/admin/org/student/add-students-spread`, data);

export const addStudentsManual = data =>
  axios.post(`api/admin/org/student/add-students-manual`, data);
