import styled from 'styled-components';

export const AllSessionsContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100%;
  margin: 0 -0.9375rem;
  padding: 7.0625rem 1.5rem 4.0625rem 7rem;
  @media (max-width: 37.5rem) {
    & {
      padding: 7.0625rem 1.5rem 4.0625rem 1.5rem !important;
    }
  }
  @media (max-width: 55rem) {
    & {
      padding: 7.0625rem 1.5rem 4.0625rem 7rem;
    }
  }
  .view-session {
    border: none;
    width: 45%;
    background: transparent;
    outline: none;
    border-radius: 0.312rem;
    padding: 0.5rem 0.5rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;

    display: flex;
    align-items: center;

    @media (max-width: 80rem) {
      & {
        width: 50%;
      }
    }
    .viewsession-arrow {
      vertical-align: middle;
    }
  }

  .table-container {
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    padding: 1.5rem;

    table {
      margin: 1rem 0 1.5rem 0;
      width: 100%;
      tr {
        :hover {
          background-color: transparent;
        }
      }
      @media (max-width: 80rem) {
        td {
          display: flex;
          justify-content: space-between;
        }
        .icon {
          transform: rotate(90deg);
        }
        .dropdowncontainer {
          right: -4rem;
        }
      }
    }
  }

  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    .title {
      color: ${({theme}) => theme.colors.text};
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.8125rem;
    }
    .right-side {
      display: flex;
    }
    .filter-button {
      outline: unset;
      background-color: transparent;
      border: unset;
      margin-right: 1rem;
      height: 2.625rem;
    }
    @media (max-width: 31.2rem) {
      .add-button {
        .label {
          display: none;
        }
        .icon {
          margin-right: unset !important;
        }
      }
    }
  }
  .count-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .attendance-report {
      display: flex;
      align-items: center;
      width: 55%;
    }
    @media (max-width: 500px) {
      & {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .detail {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .counts-present-absent {
    display: flex;
  }
  .counts-present {
    padding: 0.25rem 0.75rem;
    border-radius: 0.1875rem;
    background: #edfaf1;
    color: #4ec977;
    margin-left: 0.0625rem;
  }
  .counts-absent {
    padding: 0.25rem 0.75rem;
    border-radius: 0.1875rem;
    background: #ffe1e1;
    color: #ff6969;
  }
  .upcoming {
    display: flex;
    align-items: center;
    margin-right: 3.5rem;
    @media (max-width: 500px) {
      & {
        margin-right: unset;
      }
    }
  }
  .index {
    width: 3%;
  }

  .session-name {
    width: 13%;
  }

  .course-name {
    width: 9%;
  }
  .unit-code {
    width: 9%;
  }

  .teacher {
    width: 11%;
  }
  .class-name {
    width: 10%;
  }
  /* .location {
    width: 8%;
  } */
  .date {
    width: 9%;
  }
  .time {
    width: 13%;
  }
  .attendance {
    width: 21%;
  }

  .more-options {
    width: 2%;
  }
`;
