/* eslint-disable react/prop-types */
import React from 'react';

import SearchInput from '../../../../../../../../components/shared/Inputs/SearchInput';
import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';

import {ViewCurriculumHeaderContainer} from './elements';

export const ViewCurriculumHeader = ({
  setSearchTerm,
  searchTerm,
  onBack,
  courseCurriculum,
  academicYear,
}) => (
  <ViewCurriculumHeaderContainer>
    <div className="header-container" id="header-container">
      <div className="header-top">
        <div className="left-content">
          <div className="back-container">
            <div>
              <button
                className="backbutton"
                onClick={onBack}
                type="button"
                id="back-btn-id"
              >
                <img src={ArrowLeftImg} alt="arrow" />
              </button>
            </div>
            <div>
              <h2 className="title">{courseCurriculum?.ocr_course_name}</h2>
              <p className="year">{academicYear}</p>
            </div>
          </div>
        </div>
        <div className="right-content">
          <SearchInput
            className="class-search"
            onChange={event => setSearchTerm(event.target.value)}
            placeholder="Search files or modules"
            value={searchTerm}
            width="300px"
            id="search-file-id"
          />
        </div>
      </div>
    </div>
  </ViewCurriculumHeaderContainer>
);
