import styled from 'styled-components';

import rem from '../../utils/rem';

const Box = styled.div`
  background-color: white;
  border-radius: ${rem(12)};
  box-shadow: ${rem(1)} ${rem(1)} ${rem(4)} rgba(0, 0, 0, 0.04);
`;

export default Box;
