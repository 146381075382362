import styled from 'styled-components';

export const ProfileCardContainer = styled.div`
  .profilecard {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 0.25rem;
    align-items: center;
    width: 100%;
  }
  .profilecard-top {
    height: 6.68rem;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    width: 100%;
    background-color: #54abff;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .profilecard-center {
    position: absolute;
    margin-top: 2.5rem;
    width: 7.25rem;
    height: 7.25rem;

    img {
      border-radius: 50%;
      border: 0.125rem solid #ffffff;
    }

    .circle {
      border: 0.125rem solid #ffffff;
      border-radius: 50%;
      font-size: 2rem;
      font-weight: 600;
      background-color: #b7d8ff;
      margin-left: -0.375rem;
      color: #1973da;
      height: 100%;
      width: 100%;
      text-transform: uppercase;
    }
  }
  .profilecard-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
      margin-top: 4rem;
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
      color: #0c181f;
    }
    p {
      font-weight: 500;
      font-size: 1rem;
      text-align: center;
      color: #9ea3a5;
    }
  }
`;
