import {configureStore} from '@reduxjs/toolkit';
import homeReducer from '../pages/Home/homeSlice';
import authOldReducer from '../pages/AuthOld/authSlice';
import adminReducer from '../pages/Admin/adminSlice';
import forgotPasswordReducer from '../pages/ForgotPasswordOld/forgotPasswordSlice';
import activeClassReducer from './activeClassSlice';
import resetPasswordReducer from '../pages/PasswordReset/passwordResetSlice';
import streamReducer from '../pages/Stream/streamSlice';
import reqCodeReducer from '../pages/RequestCode/requestCodeSlice';
import signupReducer from '../pages/SignupOld/signupSlice';
import activateUserReducer from '../pages/ActivateUser/activateUserSlice';
import assessmentReducer from '../pages/Stream/pages/Assesments/Assessment/slices/assessmentSlice';
import authReducer from './authSlice';
import qualificationsReducer from './qualificationsSlice';
import unitsReducer from './unitsSlice';
import departmentsReducer from './departmentsSlice';
import academicYearsReducer from './academicYearsSlice';
import adminTeachersReducer from './adminTeacherSlice';
import studentsReducer from './studentSlice';
import classesReducer from './classesSlice';
import basicInfoReducer from './basicInfoSlice';
import dashoardReducer from './dashboardSlice';
import streamReducerNew from './streamSlice';
import assessmentReducerNew from './assessmentSlice';
import teacherLibraryReducer from './teacherLibrarySlice';
import coursesReducer from './coursesSlice';
import curriculumReducer from './curriculumSlice';
import sessionsReducer from './sessionsSlice';
import adminLibraryReducer from './adminLibrarySlice';
import studentLibraryReducer from './studentLibrarySlice';
import notificationReducer from './notificationSlice';
import portfolioReducer from './portfolioSlice';
import studentWebReducer from './studentWebSlice';
import teacherCurriculumReducer from './teacherCurriculumSlice';
import studentCurriculumReducer from './studentCurriculumSlice';
import taskReducer from './taskSlice';
import adminAssessmentsSlice from './adminAssessmentsSlice';

export default configureStore({
  reducer: {
    home: homeReducer,
    admin: adminReducer,
    authOld: authOldReducer,
    forgotPassword: forgotPasswordReducer,
    activeClass: activeClassReducer,
    resetPassword: resetPasswordReducer,
    stream: streamReducer,
    requestCode: reqCodeReducer,
    signup: signupReducer,
    activateUser: activateUserReducer,
    assessment: assessmentReducer,
    auth: authReducer,
    qualifications: qualificationsReducer,
    units: unitsReducer,
    departments: departmentsReducer,
    academicYears: academicYearsReducer,
    adminTeachers: adminTeachersReducer,
    students: studentsReducer,
    classes: classesReducer,
    basicInfo: basicInfoReducer,
    dashboard: dashoardReducer,
    streamNew: streamReducerNew,
    assessmentNew: assessmentReducerNew,
    teacherLibrary: teacherLibraryReducer,
    courses: coursesReducer,
    curriculum: curriculumReducer,
    sessions: sessionsReducer,
    adminLibrary: adminLibraryReducer,
    studentLibrary: studentLibraryReducer,
    notification: notificationReducer,
    portfolio: portfolioReducer,
    studentWeb: studentWebReducer,
    teacherCurriculum: teacherCurriculumReducer,
    studentCurriculum: studentCurriculumReducer,
    task: taskReducer,
    adminAssessments: adminAssessmentsSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({serializableCheck: false}),
});
