/* eslint-disable react/prop-types */
import {AnimatePresence} from 'framer-motion';
import PropTypes from 'prop-types';
import {useRef} from 'react';
import {useClickAway} from 'react-use';

import LogoutIcon from '../../../../../assets/icons/logout-btn.svg';
import ChevronRightIcon from '../../../../../assets/icons/go-to-teacher.svg';
import ToggleProfileIcon from '../../../../../assets/icons/profile-shift.svg';
import {ProfileMenuContainer} from './elements';

export const ProfileMenu = ({
  email,
  name,
  isVisible,
  onClose,
  onLogout,
  onToggle,
  type,
  profileType,
}) => {
  const containerElement = useRef(null);
  useClickAway(containerElement, onClose);

  return (
    <AnimatePresence>
      {isVisible ? (
        <ProfileMenuContainer
          animate={{
            opacity: 1,
            translateY: 0,
          }}
          exit={{
            opacity: 0,
            translateY: '-0.5rem',
          }}
          className="d-flex flex-column position-absolute"
          initial={{
            opacity: 0,
            translateY: '-0.5rem',
          }}
          ref={containerElement}
          transition={{
            duration: 0.2,
            type: 'keyframes',
          }}
        >
          <div className="d-flex flex-column p-3">
            <h5 className="mb-1 name">{name}</h5>
            <p className="email mb-0">{email}</p>
          </div>
          {type && profileType !== 2 ? (
            <button
              className="align-items-center d-flex is-first justify-between menu-button"
              onClick={onToggle}
              type="button"
            >
              <img
                alt="Toggle Profile"
                className="button-icon mr-2"
                src={ToggleProfileIcon}
              />
              <span className="button-label">Go to {type}</span>
              <img
                alt={`Go to ${type}`}
                className="button-icon"
                src={ChevronRightIcon}
              />
            </button>
          ) : null}
          <button
            className="align-items-center d-flex justify-between menu-button"
            onClick={onLogout}
            type="button"
          >
            <img
              alt="Toggle Profile"
              className="button-icon mr-2"
              src={LogoutIcon}
            />
            <span className="button-label">Logout</span>
          </button>
        </ProfileMenuContainer>
      ) : null}
    </AnimatePresence>
  );
};

ProfileMenu.defaultProps = {
  isVisible: false,
  type: null,
};

ProfileMenu.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  type: PropTypes.string,
};
