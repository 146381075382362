/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import ReactBootstrapModal from 'react-bootstrap/Modal';
import ArrowIcon from '../../../../../../../../assets/icons/act-to.svg';
import ProfileIcon from '../../../../../../../../assets/icons/profile.svg';
import ReassignIcon from '../../../../../../../../assets/icons/reassign-big.svg';
import closeModal from '../../../../../../../../assets/icons/close-modal.svg';
// import CustomModal from '../../../../../../../../components/shared/CustomModal';
import {ReassignModalContainer} from './elements';

export const ReassignModal = ({
  onClose,
  reassignCallback,
  studentName,
  isVisible,
}) => {
  const [comments, setComments] = useState('');

  return (
    <ReactBootstrapModal centered onHide={() => {}} show={isVisible}>
      <ReassignModalContainer className="reassign-modal-container">
        <div className="reassign-modal-custom-full">
          <div className="close-btn-reassign">
            <button
              onClick={onClose}
              type="button"
              className="reassign-modal-close"
            >
              <img alt="Close" src={closeModal} />
            </button>
          </div>
          <div className="reassign-modal-wrapper">
            <div className="top">
              <div className="graphics">
                <img alt="Reassign" src={ReassignIcon} />
                <img alt="To" src={ArrowIcon} className="to-arrow" />
                <img alt={studentName} src={ProfileIcon} />
              </div>
              <h2>Do you want to reassign this</h2>
              <h2>assessment to {studentName}?</h2>
              <p>Reassigning the assessment will inform the student to</p>
              <p>submit the class work as fresh.</p>
            </div>
            <div className="bottom">
              <label htmlFor="comments">
                <p>
                  Feedback
                  {/* <span>(Optional)</span> */}
                </p>
                <textarea
                  id="comments"
                  onChange={event => setComments(event.target.value)}
                  placeholder="Add your feedback here"
                  rows="5"
                  value={comments}
                />
              </label>
              <div className="buttons">
                <button
                  className="cancel-button"
                  onClick={() => {
                    onClose();
                    setComments('');
                  }}
                  type="button"
                >
                  No, I don&apos;t
                </button>
                <button
                  className="send-button"
                  onClick={() => {
                    if (!comments) return;

                    reassignCallback(comments);
                    setComments('');
                    onClose();
                  }}
                  type="button"
                >
                  Reassign Student
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReassignModalContainer>
    </ReactBootstrapModal>
  );
};
