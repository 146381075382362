import styled from 'styled-components';

export const DropdownBtn = styled.div`
  .btn {
    outline: none;
    padding: 0rem;
    box-shadow: unset;
    :focus {
      background: #fafafa;
      box-shadow: unset;
      outline: none;
    }
  }
`;
export const DropdownContainer = styled.div`
  .menuDropdown {
    max-width: 26.37rem;
    max-height: 12rem;
    overflow-y: hidden;
    border-radius: 0.5rem;
    margin-top: 0.875rem;
    right: 25%;
    @media (max-width: 75rem) {
      & {
        width: 20rem;
        right: 8%;
      }
      .class-search {
        width: 10rem;
        border-radius: 0.5rem;
      }
    }
    .class-search {
      width: 18.75rem;
      border-radius: 0.5rem;
    }
    .teacher-table-container {
      overflow-y: scroll;
      max-height: calc(12rem - 3.25rem);
      cursor: pointer;
      width: 100%;
      .teacher-table {
        margin-bottom: unset;
        tbody {
          tr {
            display: flex;
            justify-content: start;
            border: none !important;
            background-color: #ecf6ff;
            margin-bottom: 0.5rem;
            border-radius: 0.25rem;
          }
          td {
            background-color: transparent;
            border: none !important;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
  .class-count1 {
    font-size: 0.875rem;
    color: #3d4457 !important;
    border: none;
    background-color: transparent;
    outline: none;
    img {
      vertical-align: middle;
    }
  }
  .teacher-row {
    display: flex;
    border: none;
    outline: none;
    background: transparent;
    .profile-img {
      width: 2rem;
      height: 2rem;
    }
    .profile-name {
      margin-left: 1.56rem;
      font-weight: 500;
      font-size: 0.875rem;
      text-align: left;
      p {
        margin-bottom: 0.25rem;
      }
      .ref-id {
        font-size: 0.75rem;
        color: #8a8c94;
      }
    }
  }
  .added-user-div {
    .added-user:not(:first-child) {
      margin-left: 0.5rem;
    }
    @media (max-width: 45rem) {
      & {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .added-user {
        margin-top: 0.5rem;
      }
    }
  }
  .added-user {
    border: none;
    background-color: transparent;
    outline: none;
    background: #ecf6ff;
    border-radius: 0.25rem;
    padding: 0.187rem 0.125rem 0.187rem 0.625rem;
    span {
      font-size: 0.75rem;
    }
    .remove-user {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
      margin-bottom: 0.187rem;
      vertical-align: middle;
    }
  }
  .add-new-btn {
    margin-left: 0.5rem;
    border: none;
    outline: none;
    background-color: transparent;
    vertical-align: middle;
    img {
      width: 1.62rem;
      height: 1.62rem;
      margin-top: 0.3125rem;
    }
  }
`;
