import styled from 'styled-components';

export const BasicDetailContainer = styled.form`
  width: 100%;
  .basic-details {
    display: flex;
    .basic-left {
      width: 40%;
    }
    .basic-right {
      width: 58%;
      margin-left: 3rem;
    }
  }
  .date-hrs {
    display: flex;

    .date-input {
      width: 40%;
      margin-top: 0.3rem;
      .due-date-div {
        margin-bottom: unset !important;
      }
    }
  }
  .max-min-duration-inputs {
    display: flex;
    width: 60%;
    margin-left: 1rem;
    align-items: flex-end;
    margin-bottom: 2rem;
    .custom-text-input-field {
      height: 2.77rem;
      border-radius: 0.4rem;
    }
    .maximum-duration-input {
      width: calc(50% - 0.5rem);

      :first-child {
        margin-right: 1rem;
      }
    }
  }
  @media (max-width: 84.4375rem) {
    .date-hrs {
      flex-direction: column;
      .date-input {
        width: 100%;
      }
      .max-min-duration-inputs {
        width: 100%;
        margin-left: unset;
        margin-top: 1rem !important;
      }
    }
  }
  @media (max-width: 54rem) {
    .basic-details {
      flex-direction: column;
      .basic-left {
        width: 100%;
      }
      .basic-right {
        width: 100%;
        margin-left: unset;
      }
    }
  }
  .assess-label {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #aaaaaa;
  }

  .select-students-arrow {
    margin-left: -1.56rem;
    align-items: center;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .arrow-down {
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
      border-style: none;
    }
  }
  .assessment-basic-details {
    position: relative;
    margin-top: 2rem;
  }
  .validation-error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.625rem;
    display: inline-block;
  }
  .assessment-students-dropdown {
    position: absolute;
    inset: auto 1.5rem 65% auto;
    margin: 0rem;
    padding: 1.125rem 1rem;
    background: rgb(255, 255, 255);
    border: 0.0625rem solid rgb(222, 222, 222);
    border-radius: 0.3125rem;
    max-height: 25rem;
    overflow-y: scroll;
    width: calc(100% - 3rem);
  }
`;
