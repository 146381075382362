import PropTypes from 'prop-types';
import {useState, useEffect, useRef} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {
  departmentsSelector,
  checkDepartmentExists,
  addDepartment,
} from '../../../../../../../../redux/departmentsSlice';

// import {
//   CustomTableWithoutHeader,
//   tabularizeTable,
// } from '../../../../../../../../components/shared/CustomTableWithoutHeader';

import {AddDepartmentsContainer} from './elements';
// import AddPlusIcon from '../../../../../../../../assets/icons/add-plus.svg';
import CustomTextInput from '../../../../../../../../components/shared/CustomTextInput';
import CustomButton from '../../../../../../../../components/shared/CustomButton';
import CloseBtn from '../../../../../../../../assets/icons/close.svg';
import {showToast} from '../../../../../../../../components/common/Toast';
import ToastSuccessImg from '../../../../../../../../assets/icons/toast-add-success.svg';

export const AddDepartmentsModal = ({
  isOpen,
  buttonLabel,
  description,
  isButtonLoading,
  loadingButtonLabel,
  title,
  setIsAddModalOpen,
}) => {
  const dispatch = useDispatch();

  const {isAddDepartmentLoading} = useSelector(departmentsSelector);
  const searchInputRef = useRef(null);

  const [addDepartments, setAddDepartments] = useState([]);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [search, setSearch] = useState('');
  // const [listColumns, setListColumns] = useState(null);
  // const [listData, setListData] = useState([]);
  // const [listMaps, setListMaps] = useState(null);
  const [deptError, setDeptError] = useState(false);
  const [existDepartmentError, setExistDepartmentError] = useState(null);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, [isOpen]);

  const onHideModal = () => {
    setIsAddModalOpen(false);
    setAddDepartments([]);
    setIsSuggestion(false);
    setSearch('');
  };
  const handleSubmit = () => {
    if (addDepartments.length > 0 && !isButtonLoading) {
      dispatch(
        addDepartment({departments: addDepartments}, count => {
          showToast(ToastSuccessImg, `${count} Departments added successfully`);
          onHideModal();
        }),
      );
    }
  };
  // Dropdown data in add department modal
  // useEffect(() => {
  //   const departmentColumns = [
  //     'dp_id_department',
  //     {
  //       heading: '',
  //       width: '100%',
  //     },
  //   ];
  //   const mapDepartment = {
  //     '': [
  //       (addDepartmentId, departmentName) => (
  //         <button
  //           type="button"
  //           onClick={() => {
  //             setAddDepartments(state => [
  //               ...state,
  //               {id: addDepartmentId, department_name: departmentName},
  //             ]);

  //             setIsSuggestion(false);
  //             setSearch('');
  //             searchInputRef.current?.focus();
  //           }}
  //           className="addPlusBtn"
  //         >
  //           <div>{departmentName}</div>
  //           <div className="add-btn">
  //             <img src={AddPlusIcon} alt="close" id={addDepartmentId} />
  //           </div>
  //         </button>
  //       ),
  //       'dp_id_department',
  //       'dp_name',
  //     ],
  //   };
  //   setListColumns(departmentColumns);
  //   setListMaps(mapDepartment);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   if (departmentsList?.length && departments) {
  //     const arraysOfDepartId = departments?.map(data => data.od_id_department);
  //     const arraysOfDepartName = departments?.map(data =>
  //       data.od_department_name.toLowerCase(),
  //     );
  //     const addedDepartmentId = addDepartments?.map(data => data.id);
  //     const addedDepartmentName = addDepartments?.map(
  //       data => data.department_name,
  //     );
  //     setListData(
  //       departmentsList.filter(
  //         item =>
  //           item?.dp_name
  //             ?.toLowerCase()
  //             .includes(search.trim().toLowerCase()) &&
  //           !arraysOfDepartId?.includes(item?.dp_id_department) &&
  //           !addedDepartmentId?.includes(item?.dp_id_department) &&
  //           !addedDepartmentName?.includes(item?.dp_name) &&
  //           !arraysOfDepartName?.includes(item?.dp_name?.toLowerCase()),
  //       ),
  //     );
  //   }
  // }, [departmentsList, search, departments, addDepartments]);

  useEffect(() => {
    const addedDepartmentName = addDepartments?.map(data =>
      data.department_name.toLowerCase(),
    );
    addedDepartmentName?.forEach(data => {
      if (data === search.toLowerCase()) {
        return setDeptError(true);
      }
      return setDeptError(false);
    });
  }, [search, addDepartments, deptError]);

  return (
    <AddDepartmentsContainer
      buttonLabel={buttonLabel}
      description={description}
      isButtonLoading={isButtonLoading}
      loadingButtonLabel={loadingButtonLabel}
      onButtonClick={handleSubmit}
      onHide={onHideModal}
      show={isOpen}
      title={title}
      width="41.25rem"
    >
      <form>
        <div className="form-container px-4">
          <div>
            <CustomTextInput
              autoComplete="off"
              label="Department"
              marginBottom="0.5rem"
              ref={searchInputRef}
              onChange={e => {
                if (e.target.value.length > 0) {
                  setIsSuggestion(true);
                } else {
                  setIsSuggestion(false);
                }
                setSearch(e.target.value);
                setExistDepartmentError('');
              }}
              placeholder="Search for department"
              value={search}
              error={existDepartmentError}
            />
          </div>
          {isSuggestion ? (
            <div className="suggestion">
              {/* {listColumns && listData && listMaps ? (
                <CustomTableWithoutHeader
                  {...tabularizeTable(listColumns, listData, listMaps)}
                  className="department-table"
                />
              ) : null} */}
              {!deptError ? (
                <CustomButton
                  type="button"
                  // isDisabled={listData.length}
                  bgColor="#40A1FF"
                  onClick={() => {
                    setIsSuggestion(false);
                    if (
                      // !listData.length &&
                      !deptError &&
                      !isAddDepartmentLoading
                    )
                      dispatch(
                        checkDepartmentExists(search.trim(), data => {
                          if (data !== 'error') {
                            setAddDepartments(state => [
                              ...state,
                              {
                                department_name: search,
                              },
                            ]);
                            searchInputRef.current?.focus();
                            setSearch('');
                            setExistDepartmentError('');
                          } else {
                            setExistDepartmentError(
                              'Entered department already exist!',
                            );
                          }
                        }),
                      );
                  }}
                  className="add-assessment-button"
                  text={`Add ${search} as a new department`}
                />
              ) : null}
              {deptError ? (
                <div className="error-dept">
                  <p>
                    {' '}
                    {`You have already selected '${search.trim()}', Please try a different department name`}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
          {addDepartments.length > 0 ? (
            <div className="mt-4">
              <p>
                {addDepartments.length > 1
                  ? `${addDepartments.length} Departments Selected`
                  : `${addDepartments.length} Department Selected`}
              </p>
              <div className="div-line" />
            </div>
          ) : null}
          <div className="department-selected-div">
            {addDepartments.map(item => (
              <div className="added-departments-div">
                <div>{item.department_name}</div>
                <div>
                  {' '}
                  <button
                    type="button"
                    onClick={() => {
                      setAddDepartments(state =>
                        state.filter(
                          department =>
                            department.department_name !== item.department_name,
                        ),
                      );
                    }}
                    className="department-cancel"
                  >
                    <img src={CloseBtn} alt="close" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </AddDepartmentsContainer>
  );
};

AddDepartmentsModal.propTypes = {
  // onHide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isButtonLoading: PropTypes.bool.isRequired,
  loadingButtonLabel: PropTypes.string.isRequired,
  setIsAddModalOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
