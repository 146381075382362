/* eslint-disable no-prototype-builtins */
/* eslint-disable react/self-closing-comp */
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// import {useForm} from 'react-hook-form';
import queryString from 'query-string';
import CustomButton from '../../../components/shared/Buttons/CustomButton';
import StyledResetForm from '../elements/styles/resetForm';
// import Eye from '../../../components/shared/Eye';
// import Button from '../../../components/shared/Buttons/GreenButton';
// import MaterialInput from '../../../components/shared/Inputs/MaterialInput';
import {
  // setShowValidationBox,
  resetPasswordSelector,
  resetPassword,
  // setShowPassword,
} from '../passwordResetSlice';
import {findUserByToken} from '../../../api/authOld/resetPassword';
import buttonLoader from '../../../assets/icons/button-spinner.svg';
// import SuccessTick from '../../../components/shared/SuccessTick';
// import hasError from '../../../utils/hasError';
import jcLogo from '../../../assets/icons/jclogo.svg';
import CustomTextInput from '../../../components/shared/CustomTextInput';

const ResetForm = () => {
  // const {register, errors, getValues, formState, handleSubmit} = useForm({
  //   mode: 'all',
  //   reValidateMode: 'onChange',
  //   criteriaMode: 'all',
  // });
  const dispatch = useDispatch();
  const history = useHistory();

  const pwResetState = useSelector(resetPasswordSelector);
  const {token} = queryString.parse(history.location.search);
  const [userDetails, setUserDetails] = useState('');

  useEffect(() => {
    findUserByToken(token).then(response => {
      setUserDetails(response.data.user);
    });
  }, [token]);

  const [data, setData] = useState({
    password: {
      error: '',
      value: '',
    },
    confirmpassword: {
      error: '',
      value: '',
    },
  });
  const handleChange = event =>
    setData(state => ({
      ...state,
      [event.target.name]: {
        error: '',
        value: event.target.value,
      },
    }));
  const handleError = response => {
    if (response?.data) {
      setData(state => {
        const cleanState = {...state};

        Object.keys(cleanState).forEach(key => {
          cleanState[key].error = '';
        });

        return {
          ...cleanState,
          [response.data.field]: {
            error: response.data.error,
          },
        };
      });
    }
  };

  const handleSubmission = event => {
    event.preventDefault();
    const info = {
      c_password: data.confirmpassword.value,
      password: data.password.value,
      token,
    };
    dispatch(resetPassword(info, handleError));
  };

  // const onPasswordFocus = () => {
  //   dispatch(setShowValidationBox(true));
  // };

  // const onPasswordBlur = () => {
  //   dispatch(setShowValidationBox(false));
  // };

  // const toggleShowPassword = () => {
  //   dispatch(setShowPassword(!pwResetState.showPassword));
  // };

  return (
    <StyledResetForm className="d-flex flex-column justify-content-center align-items-center">
      <img src={jcLogo} alt="logo" className="logo" />
      <div className="sucessess_info">
        <div className="password-card">
          <div>
            <h2 className="header-text">Reset password</h2>
            <div className="subtitle-div">
              <span className="subtitle">Enter a new password for</span>
              <br />
              <span className="mail">{userDetails?.us_email}</span>
            </div>
            <form
              className="mb-4"
              onSubmit={handleSubmission}
              autoComplete="off"
            >
              <div className="fields">
                <CustomTextInput
                  label="Password"
                  autoFocus
                  autoComplete="off"
                  borderRadius="8px"
                  padding="19px 24px 19px 24px"
                  name="password"
                  ErrorRight
                  error={data.password.error}
                  onChange={handleChange}
                  value={data.password.value}
                  type="password"
                  data-testid="password"
                  placeholder="Enter password"
                  maxLength="15"
                />
              </div>
              {/* <div className="d-flex mt-4 align-items-center position-relative">
                  <MaterialInput textContent="Enter new password">
                    <input
                      type={pwResetState.showPassword ? 'text' : 'password'}
                      name="password"
                      onFocus={onPasswordFocus}
                      onBlur={onPasswordBlur}
                      data-testid="password"
                      ref={register({
                        required: true,
                        validate: {
                          atleastEightChar: value => value.length >= 8,
                          atleastAnUpperCaseLetter: value =>
                            !!/[A-Z]+/.test(value),
                          atleastANumber: value => !!/[0-9]+/.test(value),
                          atleastASpecialChar: value =>
                            !!/[*@!#%&$()^~{}]+/.test(value),
                        },
                      })}
                    />
                  </MaterialInput>
                  <Eye
                    open={pwResetState.showPassword}
                    onClick={toggleShowPassword}
                  />
                </div> */}

              {/* {pwResetState?.showValidationBox && (
                <div className="d-flex mt-3">
                  <SuccessTick
                    checked={
                      getValues('password').length > 1 &&
                      hasError(errors, 'atleastEightChar')
                    }
                    tickName="Atleast 8 character"
                  />
                  <SuccessTick
                    checked={
                      getValues('password').length > 1 &&
                      hasError(errors, 'atleastAnUpperCaseLetter')
                    }
                    tickName="An uppercase letter"
                  />
                  <SuccessTick
                    checked={
                      getValues('password').length > 1 &&
                      hasError(errors, 'atleastASpecialChar')
                    }
                    tickName="A Special character"
                  />
                  <SuccessTick
                    checked={
                      getValues('password').length > 1 &&
                      hasError(errors, 'atleastANumber')
                    }
                    tickName="A number"
                  />
                </div>
              )} */}
              {/* <div className="input-field">
                <MaterialInput textContent="Confirm Password">
                  <input
                    type="password"
                    name="c_password"
                    data-testid="c-password"
                    ref={register({required: true})}
                  />
                </MaterialInput> 
              </div> */}
              <div className="fields mt-3">
                <CustomTextInput
                  label="Confirm Password"
                  autoComplete="off"
                  borderRadius="8px"
                  ErrorRight
                  padding="19px 24px 19px 24px"
                  name="confirmpassword"
                  error={data.confirmpassword.error}
                  onChange={handleChange}
                  value={data.confirmpassword.value}
                  type="password"
                  data-testid="password"
                  placeholder="Enter confirm password"
                  maxLength="15"
                />
              </div>
              {/* {pwResetState.errorMessage && (
                <div className="invalid-feedback mt-3">
                  <span>{pwResetState.errorMessage}</span>
                </div>
              )} */}
              <div className="button-container">
                <CustomButton
                  width="100%"
                  type="submit"
                  bgColor="#40A1FF"
                  borderRadius="8px"
                  padding="16px 24px"
                >
                  <>
                    {pwResetState.isLoading ? (
                      <img
                        alt="Processing"
                        className="button-spinner"
                        src={buttonLoader}
                      />
                    ) : (
                      'Update password'
                    )}
                  </>
                </CustomButton>
              </div>
            </form>
          </div>
          <div></div>
        </div>
      </div>
    </StyledResetForm>
  );
};

export default ResetForm;
