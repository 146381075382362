/* eslint-disable react/prop-types */
import React from 'react';

import {FooterContainer} from './elements';
import {Button} from '../../../../../../../../components/common';

export const Footer = ({
  onSubmit,
  onButtonCancel,
  isDisabled,
  isAdminLibraryLoading,
}) => (
  <FooterContainer className="footer-container fixed-bottom">
    <div className="footer">
      <div />
      <div className="button-container">
        <Button
          className="button cancel-button mr-3"
          isFullWidth={false}
          onClick={onButtonCancel}
          label="Cancel"
          id="cancel"
        />
        <Button
          className="button"
          isFullWidth={false}
          isLoading={isAdminLibraryLoading}
          label="Update Document"
          onClick={onSubmit}
          isDisabled={isDisabled}
          id="update-document"
        />
      </div>
    </div>
  </FooterContainer>
);
