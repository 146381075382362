/* eslint-disable react/prop-types */
import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import styled from 'styled-components';

export default function ErrorMessage({errorMessage, className}) {
  const ErrorMessageContainer = styled.div`
    .error-message {
      color: ${({theme}) => theme.colors.danger};
      font-size: 0.75rem;
      left: 0;
      line-height: 0.9075rem;
    }
  `;
  return (
    <ErrorMessageContainer className={className}>
      <AnimatePresence>
        <motion.span
          animate={{
            opacity: 1,
            translateY: 0,
          }}
          className="error-message"
          exit={{
            opacity: 0,
            translateY: '-0.25rem',
          }}
          initial={{
            opacity: 0,
            translateY: '-0.25rem',
          }}
          transition={{
            duration: 0.2,
            type: 'keyframes',
          }}
        >
          {errorMessage}
        </motion.span>
      </AnimatePresence>
    </ErrorMessageContainer>
  );
}
