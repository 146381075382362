/* eslint-disable react/prop-types */
import React from 'react';

import {useHistory} from 'react-router-dom';

import CloseImg from '../../../../../../../../assets/icons/addadminclose.svg';

import {AddStudentHeaderContainer} from './elements';

export const AddStudentHeader = ({orgClassId}) => {
  const history = useHistory();

  return (
    <AddStudentHeaderContainer>
      <div className="add-student-header">
        <div className="add-student-title">
          <div>
            <span className="add-student-name">Add Student</span>
          </div>
          <div>
            <button
              className="btn"
              onClick={() => {
                history.replace(`/admin/classes/view-class/${orgClassId}`);
              }}
              type="button"
            >
              <img alt="Options" src={CloseImg} />
            </button>
          </div>
        </div>
      </div>
    </AddStudentHeaderContainer>
  );
};
