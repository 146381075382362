/* eslint-disable react/prop-types */
import React from 'react';
import {Helmet} from 'react-helmet';

import {ViewClassContainer} from './elements';
import {Header, ClassTables} from './components';

export const ViewClass = () => (
  <>
    <Helmet>
      <title>View Class | JungleCat</title>
    </Helmet>

    <ViewClassContainer>
      <Header />
      <ClassTables />
    </ViewClassContainer>
  </>
);
