/* eslint-disable react/prop-types */

import {useEffect, useMemo, useState, useRef} from 'react';
import {Controller} from 'react-hook-form';

import axios from '../../../../../../../../api';
// import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {Select} from '../../../../../../../../components/common';
import {Table} from '../../../../../../../../components/common/Table';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import ErrorMessage from '../ErrorMessage';
import ModeOfDeliveryFormContainer from './elements/ModeOfDeliveryFormContainer';

const ModeOfDeliveryForm = ({
  control,
  errors,
  onUpdate,
  setUnits,
  units,
  tasStatus,
  isDataCompleted,
  showError,
}) => {
  const [trainingMethods, setTrainingMethods] = useState([]);
  const reselectRef = useRef([]);
  useEffect(() => {
    axios
      .get(`/api/admin/tas/training-methods`)
      .then(({data: {tasTrainingMethods}}) => {
        setTrainingMethods(
          tasTrainingMethods.map(item => ({
            label: item.ttm_name,
            value: item.ttm_name,
          })),
        );
      });
  }, [units]);

  const tmTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Cell: ({row: {original}, value}) => (
          <div className="column-table-value" style={{marginRight: '1.5rem'}}>
            {value} {original.otun_unit_name}
          </div>
        ),
        Header: 'Unit',
        accessor: 'otun_unit_code',
        className: 'unit',
      },
      {
        Cell: ({row: {original}, value}) => (
          <Select
            // eslint-disable-next-line no-return-assign
            // ref={reselectRef[original.otun_id_orgtas.toString()]}
            ref={ref => {
              reselectRef.current[original.otun_id_orgtas] = ref; // took this from your guide's example.
            }}
            isClearable={false}
            isLarge={false}
            isLightBorder
            isMulti
            isDisabled={tasStatus}
            onChange={async (event, {option, removedValue}) => {
              setUnits(previousValue =>
                previousValue.map(item =>
                  item.otun_id_orgtasunit === original.otun_id_orgtasunit
                    ? {...item, trainingMethods: event}
                    : item,
                ),
              );
              if (option) {
                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/unit-training-method`,
                  {
                    trainingMethod: option.value,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: value,
                  },
                );
              } else if (removedValue) {
                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/delete-unit-training-method`,
                  {
                    trainingMethod: removedValue.value,
                    unitId: original.otun_id_unit,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: event,
                  },
                );
              }
            }}
            addIfNoOptions
            options={trainingMethods}
            placeholder=""
            value={value}
            // eslint-disable-next-line consistent-return
            onKeyDown={async event => {
              // On certain phones the enter key is not selecting the input text, so do it by hand.
              if (event.key === 'Enter') {
                const {value: targetValue} = event.target;
                const isExisting = trainingMethods.find(
                  method => method.value === targetValue,
                );

                if (!targetValue.trim() && isExisting) {
                  return false;
                }

                event.preventDefault();
                setUnits(previousValue =>
                  previousValue.map(item =>
                    item.otun_id_orgtasunit === original.otun_id_orgtasunit
                      ? {
                          ...item,
                          trainingMethods: [
                            ...item.trainingMethods,
                            {
                              label: targetValue,
                              value: targetValue,
                            },
                          ],
                        }
                      : item,
                  ),
                );

                await axios.post(
                  `/api/admin/tas/${original.otun_id_orgtas}/unit-training-method`,
                  {
                    trainingMethod: targetValue,
                    unitId: original.otun_id_unit,
                    oldvalue: value,
                    unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                    oldValue: value,
                  },
                );

                // if (request && request.data && request.data.status) {
                // setUnits(previousValue =>
                //   previousValue.map(item =>
                //     item.otun_id_orgtasunit === original.otun_id_orgtasunit
                //       ? {
                //           ...item,
                //           trainingMethods: [
                //             ...item.trainingMethods,
                //             {
                //               label: targetValue,
                //               value: targetValue,
                //             },
                //           ],
                //         }
                //       : item,
                //   ),
                // );
                // setTimeout(() => {
                //   console.log('event :', event);
                //   // event.focus();
                // }, 1000);
                // }
              }
            }}
          />
        ),
        Header: 'Current Training Method Used',
        accessor: 'trainingMethods',
        className: 'training-methods',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trainingMethods],
  );

  return (
    <ModeOfDeliveryFormContainer className="section" id="mode-of-delivery">
      <h3>Mode of Delivery</h3>
      <p>
        Identify how the training and assessment is to be
        delivered—face-to-face, online, through workplace training or a mixture
        of different modes.
      </p>
      <form>
        <div className="tas-form-row">
          <Controller
            control={control}
            name="assessmentDetails"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.assessmentDetails}
                hasInfo="<ul>
    <li>Training packages and VET accredited courses often specify resources that must be used in assessment at a unit of competency level. Include details of how you will ensure students have access to the resources that will give them the best chance of completing their study.</li>
    <br/>
 <li>Identify:
<ul>
<li>Assessment resources</li>
<li>Assessment methods</li>
<li>Timing of assessment</li>
<li>Any training package requirements in relation to the training and assessment practices (such as mandatory work placement hours and how this is structured into the course delivery)</li>
<li>Any adjustments that may be needed to cater for different student characteristics.</li></ul></li></ul>"
                label="Assessment Resources, Methods, and Timing"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled={tasStatus}
                rows={5}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            {showError && isDataCompleted?.includes('mode-of-delivery') ? (
              <ErrorMessage
                errorMessage="Mode of delivery for unit details needed to be filled"
                className="error mb-2"
              />
            ) : null}
            <div className="column-table-title">
              Training Methods
              {/* <img alt="Info" src={InfoIcon} /> */}
            </div>
            <Table
              columns={tmTableColumns}
              data={units}
              isWithoutHeader
              isWithoutPagination
              showAllRows
            />
          </div>
        </div>
      </form>
    </ModeOfDeliveryFormContainer>
  );
};

export default ModeOfDeliveryForm;
