/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import csvjson from 'csvjson';
import CustomCancel from '../../../../../components/shared/Buttons/CustomButton';
import closeTeacher from '../../../../../assets/icons/addadminclose.svg';
import addIcons from '../../../../../assets/icons/addadmin.svg';
import arrowHide from '../../../../../assets/icons/tablehide.svg';
import arrowShow from '../../../../../assets/icons/tableshow.svg';
import 'react-datepicker/dist/react-datepicker.css';
import CustomTextInput from '../../../../../components/shared/CustomTextInput';
import {
  CustomTable,
  tabularize,
} from '../../../../../components/shared/CustomTable';
import {
  AddTeacherHeader,
  AddTeacherContainer,
  AddTeacherFooter,
} from '../../../Elements/TeacherContainer';
import CustomBox from '../../../../../components/shared/CustomBox';
import {
  addTeacher,
  individualTeacherCheck,
  adminInfo,
  spreadSheetTeachersCheck,
} from '../../../adminSlice';
import files from '../../../../../assets/icons/dropfile.svg';
import greenTick from '../../../../../assets/icons/donemark.svg';
import deletelogo from '../../../../../assets/icons/delete-logo.svg';
import uploadSuccess from '../../../../../assets/icons/upload-success.svg';
import closeIcon from '../../../../../assets/icons/redcross.svg';
import CustomButton from '../../../../../components/shared/CustomButton';
import Loader from '../../../../../components/shared/BookLoader';

const AddTeacherDetails = () => {
  const [tableColumns, setTableColumns] = useState(null);
  const [tableMaps, setTableMaps] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [fullName, setFullName] = useState('');
  const [useremail, setEmail] = useState('');
  const [referID, setReferID] = useState('');
  const [addTeachers, setAddTeachers] = useState([]);
  const [spreadSheet, setSpreadSheet] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [emailPatternError, setEmailPatternError] = useState([]);
  const [refIdError, setRefIdError] = useState([]);
  const [errorColumns, setErrorColumns] = useState(null);
  const [errorMaps, setErrorMaps] = useState(null);
  const [fileImport, setImport] = useState(true);
  const [manual, setManual] = useState(false);
  const [upload, setUpload] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const [formShow, setFormShow] = useState(true);
  const [btnShow, setBtnShow] = useState(true);
  const [nextShow, setNextShow] = useState(false);
  const [userError, setUserError] = useState('');
  const [referror, setRefError] = useState('');
  const [fileName, setFileName] = useState('');
  const [showShow, setShowShow] = useState(false);
  const [showShow1, setShowShow1] = useState(false);
  const [showShow2, setShowShow2] = useState(false);
  const dispatch = useDispatch();
  const {
    teacherValidate,
    alreadyExistRefId,
    alreadyExistEmail,
    emailPattern,
    validTeacher,
  } = useSelector(adminInfo);

  const downloadRef = useRef(null);
  const history = useHistory();
  const [isValidating, setIsValidating] = useState(false);
  const [spreadNext, setSpreadNextShow] = useState(0);
  useEffect(() => {
    setTableData(addTeachers);
    if (addTeachers.length === 0) {
      setBtnShow(true);
      setFormShow(true);
    }
    if (spreadSheet.length > 0) {
      setTableData(spreadSheet);
    }
  }, [addTeachers, spreadSheet]);

  useEffect(() => {
    if (teacherValidate.field !== '') {
      if (teacherValidate.field === 'useremail') {
        setUserError(teacherValidate.msg);
      } else if (teacherValidate.field === 'referID') {
        setRefError(teacherValidate.msg);
      }
    }
  }, [teacherValidate]);

  const handleDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer?.files[0] || event.target.files[0];
    const extn = file.name.split('.').pop();
    if (file && extn === 'csv') {
      const reader = new FileReader();
      reader.onload = () => {
        const jsonObj = csvjson.toObject(reader.result);
        setSpreadSheet(jsonObj);
        setFileName(file.name);
      };
      reader.readAsBinaryString(file);
    }
  };

  const removeItems = position => {
    setAddTeachers(state => state.filter((item, index) => index !== position));
  };

  const toggleShow = () => setShowShow(!showShow);
  const toggleShow1 = () => setShowShow1(!showShow1);
  const toggleShow2 = () => setShowShow2(!showShow2);

  useEffect(() => {
    const columns = [
      'up_id_userprofile',
      {
        heading: '#',
        width: '9%',
      },
      {
        heading: 'ID',
        width: '20%',
      },
      {
        heading: 'FULL NAME',
        width: '25%',
      },
      {
        heading: 'EMAIL',
        width: '42%',
      },
      {
        heading: '',
        width: '4%',
      },
    ];
    const maps = {
      '#': [organizationReference => organizationReference + 1],
      ID: [
        organizationStartAcademicYear => organizationStartAcademicYear,
        'referID',
      ],
      'FULL NAME': [fName => fName, 'fullName'],
      EMAIL: [
        organizationEndAcademicYear => organizationEndAcademicYear,
        'useremail',
      ],
      '': [
        position => (
          <button
            type="button"
            onClick={() => {
              removeItems(position);
            }}
            className="closeIconBtn"
          >
            <img src={closeIcon} alt="close" />
          </button>
        ),
      ],
    };
    setTableColumns(columns);
    setTableMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setEmailError(alreadyExistEmail);
    setRefIdError(alreadyExistRefId);
    setEmailPatternError(emailPattern);
    const columns = [
      'up_id_userprofile',
      {
        heading: '#',
        width: '9%',
      },
      {
        heading: 'ID',
        width: '20%',
      },
      {
        heading: 'FULL NAME',
        width: '25%',
      },
      {
        heading: 'EMAIL',
        width: '42%',
      },
    ];
    const maps = {
      '#': [organizationReference => organizationReference + 1],
      ID: [
        organizationStartAcademicYear => organizationStartAcademicYear,
        'referID',
      ],
      'FULL NAME': [fName => fName, 'fullName'],
      EMAIL: [
        organizationEndAcademicYear => organizationEndAcademicYear,
        'useremail',
      ],
    };
    setErrorColumns(columns);
    setErrorMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyExistEmail, alreadyExistRefId, emailPattern]);

  const convertFileSize = size => {
    const fileSizeKb = size / 1024;
    if (fileSizeKb > 1024) {
      const fileSizeMb = fileSizeKb / 1024;
      return `${fileSizeMb.toFixed(2)} Mb`;
    }

    return `${fileSizeKb.toFixed(2)} Kb`;
  };
  return (
    <>
      <Helmet>
        <title>Teacher | JungleCat</title>
      </Helmet>
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#f4f5f8',
          margin: '0px -15px',
          padding: '0 15px',
        }}
      >
        <AddTeacherHeader>
          <div className="add-teacher-header">
            <div className="add-teacher-title">
              <div>
                <span className="add-teacher-name">Add Teacher</span>
              </div>
              <div>
                <button
                  className="btn"
                  onClick={() => history.goBack()}
                  type="button"
                >
                  <img alt="Options" src={closeTeacher} />
                </button>
              </div>
            </div>
          </div>
        </AddTeacherHeader>
        <AddTeacherContainer>
          <div className="container-teacher">
            <div className="teacherheading d-flex justify-content-between  mb-4">
              <h2 style={{fontSize: '24px'}}>
                Add teachers to your organisation
              </h2>
            </div>
            <CustomBox padding="1px 20px" className="custombox">
              {spreadNext === 0 ? (
                <div className="search-div">
                  <button
                    className={fileImport ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(true);
                      setManual(false);
                    }}
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manual ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(false);
                      setManual(true);
                    }}
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}
              {spreadNext === 1 || isValidating ? (
                <div className="search-div-spread">
                  <p className="imported">Imported users from document</p>
                  <p className="imported-file">{fileName}</p>
                </div>
              ) : null}
              {tableColumns &&
              tableData &&
              tableMaps &&
              manual &&
              addTeachers.length > 0 ? (
                <CustomTable
                  {...tabularize(tableColumns, tableData, tableMaps)}
                  className="org-teacher-table"
                />
              ) : (
                ''
              )}
              {manual ? (
                <div>
                  {formShow && btnShow ? (
                    <div>
                      <div
                        className="add-teacher-modal"
                        style={{padding: '24px 0px 0px 0px'}}
                      >
                        <div className="add-teacher-modal-body mb-3">
                          <div
                            className="date-wrapper"
                            style={{marginRight: '8px'}}
                          >
                            {/* <p className="mb-2 mt-2">Start date</p> */}
                            <CustomTextInput
                              label="Reference ID"
                              autoComplete="off"
                              error={referror}
                              value={referID}
                              onChange={e => {
                                setReferID(e.target.value);
                                setRefError('');
                              }}
                              placeholder="Enter Reference ID"
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                            />
                          </div>
                          <div
                            className="date-wrapper"
                            style={{marginLeft: '8px'}}
                          >
                            {/* <p className="mb-2 mt-2">End date</p> */}
                            <CustomTextInput
                              label="Full Name"
                              value={fullName}
                              onChange={e => {
                                setFullName(e.target.value);
                              }}
                              placeholder="Enter full name"
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                            />
                          </div>
                        </div>
                        <CustomTextInput
                          label="Email"
                          value={useremail}
                          autoComplete="off"
                          error={userError}
                          onChange={e => {
                            setEmail(e.target.value);
                            setUserError('');
                          }}
                          placeholder="Enter Email of the user"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                        />
                      </div>
                      <div
                        className="add-teacher-modal"
                        style={{padding: '24px 0px 24px 0px'}}
                      >
                        <div className="button-container">
                          {addTeachers.length && formShow ? (
                            <CustomCancel
                              className="cancel"
                              bgColor="#fff"
                              onClick={() => {
                                setFullName('');
                                setReferID('');
                                setEmail('');
                                setFormShow(false);
                                setBtnShow(false);
                                setUpload(null);
                                setRefError('');
                                setUserError('');
                              }}
                            >
                              <span className="cancel-button-text">Cancel</span>
                            </CustomCancel>
                          ) : null}
                          <CustomCancel
                            className="save-changes"
                            bgColor="#fff"
                            padding="9px 14px"
                            onClick={() => {
                              if (
                                !fullName.length ||
                                !referID.length ||
                                !useremail.length
                              ) {
                                return;
                              }
                              const data = {
                                fullName,
                                referID,
                                useremail,
                              };
                              if (
                                fullName.length > 0 &&
                                referID.length > 0 &&
                                useremail.length > 0
                              ) {
                                dispatch(
                                  individualTeacherCheck(data, validate => {
                                    if (validate === '') {
                                      const arraysOfUserEmail = addTeachers.map(
                                        teacher => teacher.useremail,
                                      );
                                      const arraysOfRefId = addTeachers.map(
                                        teacher => teacher.referID,
                                      );
                                      if (
                                        !arraysOfUserEmail.includes(
                                          useremail,
                                        ) &&
                                        !arraysOfRefId.includes(referID)
                                      ) {
                                        setAddTeachers(state => [
                                          ...state,
                                          {fullName, referID, useremail},
                                        ]);
                                        setFullName('');
                                        setReferID('');
                                        setEmail('');
                                        setFormShow(false);
                                        setBtnShow(false);
                                      } else {
                                        if (
                                          arraysOfUserEmail.includes(useremail)
                                        ) {
                                          setUserError(
                                            'This Email Already added in this list',
                                          );
                                        }
                                        if (arraysOfRefId.includes(referID)) {
                                          setRefError(
                                            'This Reference id Already added in this list',
                                          );
                                        }
                                      }
                                    }
                                  }),
                                );
                              }
                            }}
                          >
                            <img
                              src={addIcons}
                              alt="icon"
                              style={{marginRight: '8px'}}
                            />
                            <span className="add-button-text">Add Teacher</span>
                          </CustomCancel>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="button-container"
                      style={{padding: '24px 0px'}}
                    >
                      <CustomCancel
                        className="save-changes"
                        bgColor="#fff"
                        padding="9px 14px"
                        onClick={() => {
                          setFormShow(true);
                          setBtnShow(true);
                        }}
                      >
                        <img
                          src={addIcons}
                          alt="icon"
                          style={{marginRight: '8px'}}
                        />
                        <span className="add-button-text">
                          Add another user
                        </span>
                      </CustomCancel>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="inputfields"
                  style={spreadNext === 1 ? {padding: '0px 0px 0px 0px'} : null}
                >
                  {upload && spreadNext === 0 ? (
                    <div className="org-image-full mt-2">
                      <div className="org-image-after">
                        <img src={uploadSuccess} alt="logo" />
                        <div className="file-details">
                          <span className="file-name">
                            {upload ? upload.name : ''}
                          </span>
                          <span className="file-size">
                            {convertFileSize(upload ? upload.size : '')}
                          </span>
                        </div>
                      </div>
                      <div className="delete-logo">
                        <button
                          type="button"
                          onClick={() => {
                            setUpload(null);
                            setNextShow(true);
                          }}
                        >
                          <img src={deletelogo} alt="logo" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onDragEnter={() => {
                        setOnDrag(true);
                      }}
                      onDragLeave={() => {
                        setOnDrag(false);
                      }}
                    >
                      {spreadNext === 0 ? (
                        <div
                          className={`org-image ${onDrag ? 'logo-drag' : ''}`}
                        >
                          <div className="org-logo">
                            <img src={files} className="logo" alt="logo" />
                          </div>
                          <div className="org-text">
                            Drop your file here or{' '}
                            <input
                              type="file"
                              onChange={e => {
                                setUpload(e.target.files[0]);
                                handleDrop(e);
                                setNextShow(false);
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                            />
                            <span className="box__dragndrop">Browse</span>
                          </div>
                          <span className="supports">Supports : XLS, SLV</span>
                          <div className="org-seperator" />
                          <div className="org-size">
                            <span>Download</span>
                            <a
                              ref={downloadRef}
                              download
                              href="https://junglecat-files.s3.ap-southeast-2.amazonaws.com/CSV-aa4da2f55484a13c8760707f1dffc872_1593394944.csv"
                            >
                              &nbsp;Sample.xls
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                  {isValidating ? (
                    <div className="loader" style={{height: '350px'}}>
                      <Loader />
                      <h6 className="mt-3">
                        Validating imported Teachers details
                      </h6>
                      <p className="please">Please wait</p>
                    </div>
                  ) : null}
                  {!isValidating &&
                  spreadNext === 2 &&
                  alreadyExistRefId.length === 0 &&
                  emailPatternError.length === 0 &&
                  alreadyExistEmail.length === 0 ? (
                    <div className="loader" style={{height: '400px'}}>
                      <img src={greenTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        {spreadSheet.length} teacher added to Bititude learning
                      </h2>
                      <p className="state">
                        All the users in the document has been added as teacher
                      </p>
                    </div>
                  ) : null}
                  {!isValidating &&
                  spreadNext === 2 &&
                  (alreadyExistEmail.length > 0 ||
                    alreadyExistRefId.length > 0 ||
                    emailPatternError.length > 0) ? (
                    <div className="loader">
                      <img src={greenTick} alt="green tick" />
                      <h2 className="mt-3 header">
                        {validTeacher.length}/{spreadSheet.length} teacher added
                        to Bititude learning
                      </h2>
                      <p className="state">
                        All the users in the document has been added as teacher
                      </p>
                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow}
                              >
                                <div>
                                  Email Error ({alreadyExistEmail.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>

                          <div
                            id="collapseOne"
                            className={`collapse ${showShow ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              emailError &&
                              errorMaps &&
                              emailError.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    emailError,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow1}
                              >
                                <div>
                                  Reference ID. Error (
                                  {alreadyExistRefId.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow1 ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseTwo"
                            className={`collapse ${showShow1 ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              refIdError &&
                              errorMaps &&
                              refIdError.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    refIdError,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button
                                className="btn btn-block d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={toggleShow2}
                              >
                                <div>
                                  Email ID Pattern. Error (
                                  {emailPatternError.length})
                                </div>
                                <div>
                                  <img
                                    alt="Show Works"
                                    src={showShow2 ? arrowShow : arrowHide}
                                  />
                                </div>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseTwo"
                            className={`collapse ${showShow2 ? 'show' : ''}`}
                          >
                            <div className="card-body">
                              {errorColumns &&
                              emailPatternError &&
                              errorMaps &&
                              emailPatternError.length > 0 ? (
                                <CustomTable
                                  {...tabularize(
                                    errorColumns,
                                    emailPatternError,
                                    errorMaps,
                                  )}
                                  className="org-error-table"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {tableColumns &&
              tableData &&
              tableMaps &&
              spreadSheet.length > 0 &&
              spreadNext === 1 &&
              !isValidating ? (
                <CustomTable
                  {...tabularize(tableColumns, tableData, tableMaps)}
                  className="org-teacher-spreadtable"
                />
              ) : (
                ''
              )}
            </CustomBox>
          </div>
        </AddTeacherContainer>
        <AddTeacherFooter>
          <div className="add-teacher-footer fixed-bottom">
            {manual ? (
              <div className="add-teacher-title">
                <div className="button-container">
                  <CustomCancel
                    className="cancel1"
                    bgColor="#fff"
                    onClick={() => {
                      setUpload(null);
                      setAddTeachers([]);
                      setFullName('');
                      setReferID('');
                      setEmail('');
                    }}
                  >
                    <span className="cancel1-button-text">Cancel</span>
                  </CustomCancel>
                  <CustomButton
                    isDisabled={!addTeachers.length || spreadNext}
                    className="save-changes"
                    text="Add Teachers"
                    onClick={() => {
                      if (addTeachers.length > 0) {
                        dispatch(addTeacher(addTeachers));
                        history.replace('/admin/users/teacher');
                        setAddTeachers([]);
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="add-teacher-spread">
                {spreadNext === 0 ? <div className="button-container" /> : null}
                {spreadNext === 1 ? (
                  <div className="button-container">
                    <CustomCancel
                      className="cancel"
                      bgColor="#fff"
                      onClick={() => {
                        setSpreadNextShow(0);
                        setSpreadSheet([]);
                        setAddTeachers([]);
                      }}
                    >
                      <span className="cancel-button-text">Back</span>
                    </CustomCancel>
                  </div>
                ) : null}
                {spreadNext === 2 &&
                (alreadyExistEmail.length > 0 ||
                  alreadyExistRefId.length > 0 ||
                  emailPatternError.length > 0) ? (
                  <div className="button-container">
                    <CustomCancel
                      className="cancel"
                      bgColor="#fff"
                      onClick={() => {
                        setSpreadNextShow(1);
                      }}
                    >
                      <span className="cancel-button-text">Back</span>
                    </CustomCancel>
                  </div>
                ) : (
                  <div className="button-container" />
                )}
                {spreadNext === 3 ? <div className="button-container" /> : null}
                {spreadNext === 0 ? (
                  <div className="button-container">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      onClick={() => {
                        setUpload(null);
                        setSpreadSheet([]);
                        setAddTeachers([]);
                      }}
                    >
                      <span className="cancel1-button-text">Cancel</span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={!spreadSheet.length || nextShow}
                      className="save-changes"
                      text="Next"
                      onClick={() => {
                        setSpreadNextShow(state => state + 1);
                      }}
                    />
                  </div>
                ) : null}
                {spreadNext === 1 ? (
                  <div className="button-container">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      isDisabled={isValidating}
                      onClick={() => {
                        setUpload(null);
                        setSpreadNextShow(0);
                        setSpreadSheet([]);
                      }}
                    >
                      <span className="cancel1-button-text">Upload Again</span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={
                        !spreadSheet.length || nextShow || isValidating
                      }
                      className="save-changes"
                      text="Add Teachers"
                      onClick={() => {
                        setIsValidating(true);
                        dispatch(
                          spreadSheetTeachersCheck(spreadSheet, () => {
                            setTimeout(() => {
                              setIsValidating(false);
                              setSpreadNextShow(state => state + 1);
                            }, 3000);
                          }),
                        );
                      }}
                    />
                  </div>
                ) : null}
                {spreadNext === 2 &&
                alreadyExistEmail.length === 0 &&
                emailPatternError.length === 0 &&
                alreadyExistRefId.length === 0 ? (
                  <div className="button-container">
                    <CustomButton
                      isDisabled={
                        !spreadSheet.length || nextShow || isValidating
                      }
                      className="save-changes"
                      text="Done"
                      onClick={() => {
                        setSpreadNextShow(state => state + 1);
                        history.replace('/admin/users/teacher');
                        setIsValidating(true);
                      }}
                    />
                  </div>
                ) : null}
                {spreadNext === 2 &&
                !isValidating &&
                (alreadyExistEmail.length > 0 ||
                  alreadyExistRefId.length > 0 ||
                  emailPatternError.length > 0) ? (
                  <div className="button-container">
                    <CustomCancel
                      className="cancel1"
                      bgColor="#fff"
                      onClick={() => {
                        setUpload(null);
                        setSpreadNextShow(0);
                        setSpreadSheet([]);
                      }}
                    >
                      <span className="cancel1-button-text">
                        Discard and Reupload
                      </span>
                    </CustomCancel>
                    <CustomButton
                      isDisabled={
                        !spreadSheet.length ||
                        nextShow ||
                        isValidating ||
                        !validTeacher.length
                      }
                      className="save-changes"
                      text="Add valid Teachers"
                      onClick={() => {
                        if (validTeacher.length > 0) {
                          dispatch(addTeacher(validTeacher));
                        }
                        setSpreadSheet([]);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </AddTeacherFooter>
      </div>
    </>
  );
};

export default AddTeacherDetails;
