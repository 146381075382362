/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {FooterContainer} from './elements';
import {Button} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';
import {manageUnits} from '../../../../../../../../redux/classesSlice';
import UpdateClassToastIcon from '../../../../../../../../assets/icons/class-success.svg';

// const DELAY = 500;

export const ManageUnitFooter = ({
  orgUnit,
  addUnits,
  removeUnits,
  isSingleClassLoading,
}) => {
  const {orgClassId} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const onButtonCancel = () => {
    history.replace(`/admin/classes/view-class/${orgClassId}`);
  };
  const onButtonClick = () => {
    if (!isSingleClassLoading) {
      dispatch(
        manageUnits(orgClassId, orgUnit, addUnits, removeUnits, () => {
          history.replace(`/admin/classes/view-class/${orgClassId}`);
          showToast(UpdateClassToastIcon, `Units details updated successfully`);
        }),
      );
    }
  };
  return (
    <FooterContainer>
      <div className="footer-container fixed-bottom">
        <div className="manage-footer">
          <div className="button-container">
            <Button
              className="button cancel-button mr-3"
              isFullWidth={false}
              onClick={onButtonCancel}
              label="Cancel"
            />
            <Button
              className="button"
              isFullWidth={false}
              isLoading={isSingleClassLoading}
              label="Update Units"
              onClick={onButtonClick}
            />
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};
