/* eslint-disable react/prop-types */
// import React from 'react';
import moment from 'moment';
// export const ClassBasicDetails = () => <div ></div>;
import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useMemo, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  getDataForAddClass,
  getAllOrgStudents,
  classesSelector,
  setError as setServerError,
  addClass,
  clearFormError,
} from '../../../../../../../../redux/classesSlice';
import {
  Select,
  TextInput,
  DateInput,
  // Spacer,
} from '../../../../../../../../components/common';
import CustomBox from '../../../../../../../../components/shared/CustomBox';

import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {addClassSchema} from '../../../../../../../../utils/validation';
import {ClassDetailsContainer} from './elements';
import {Header, Footer} from './components';

const FIELDS_IN_ORDER = [
  'course',
  'qualification',
  'department',
  'className',
  'academicYear',
  'classTeacher',
  'startDate',
  'endDate',
];

export const AddClassDetails = ({setAddClassStep, setProgress, progress}) => {
  const dispatch = useDispatch();
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  const {
    control,
    formState: {errors},
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      course: null,
      qualification: null,
      department: null,
      className: '',
      academicYear: null,
      classTeacher: null,
      startDate: '',
      endDate: '',
    },
    resolver: yupResolver(addClassSchema),
  });

  const {
    error: serverError,
    filterQualifications,
    filterCourses,
    filterAcademicYears,
    filterDepartments,
    filterTeachers,
    isAddClassLoading,
    formError,
  } = useSelector(classesSelector);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  useEffect(() => {
    dispatch(getDataForAddClass());
    dispatch(getAllOrgStudents());
  }, [dispatch]);

  const courseValue = watch('course');

  useEffect(() => {
    if (courseValue) {
      const orgQualification = filterQualifications.find(
        item => item.oq_id_orgqual === courseValue.qualificationId,
      );
      setValue('qualification', {
        label: orgQualification.qualification.tqual_title,
        value: orgQualification.oq_id_orgqual,
      });
    } else setValue('qualification', null);
  }, [courseValue, filterQualifications, setValue]);

  const onSelectChange = value => {
    setValue('academicYear', value);
    setMinDate(new Date(value.startDate));
    setMaxDate(new Date(value.endDate));
    setValue('startDate', new Date(value.startDate));
    setValue('endDate', new Date(value.endDate));
  };

  const qualificationOptions = useMemo(
    () =>
      filterQualifications?.map(item => ({
        label: item.qualification.tqual_title,
        value: item.oq_id_orgqual,
      })) || [],
    [filterQualifications],
  );
  const coursesOptions = useMemo(
    () => [
      ...(filterCourses || [])?.map(item => ({
        label: item.ocr_course_name,
        value: item.ocr_id_orgcourse,
        qualificationId: item.ocr_id_orgqual,
      })),
    ],
    [filterCourses],
  );

  const departmentsOptions = useMemo(
    () => [
      ...(filterDepartments || [])?.map(item => ({
        label: item.od_department_name,
        value: item.od_id_orgdepartment,
      })),
    ],
    [filterDepartments],
  );

  const academicYearsOptions = useMemo(
    () => [
      ...(filterAcademicYears || [])?.map(item => ({
        label: `${moment(item.oay_from_date).format('MMM YYYY')} - ${moment(
          item.oay_to_date,
        ).format('MMM YYYY')}`,
        value: item.oay_id_orgacadyear,
        startDate: item.oay_from_date,
        endDate: item.oay_to_date,
      })),
    ],
    [filterAcademicYears],
  );

  const teachersOptions = useMemo(
    () => [
      ...(filterTeachers || [])?.map(item => ({
        label: `${item.up_name_first} ${item.up_name_last}`,
        value: item.up_id_userprofile,
      })),
    ],
    [filterTeachers],
  );

  const onSubmit = useCallback(
    data => {
      const classData = {
        classname: data.className,
        department: data.department.value,
        qualification: data.qualification.value,
        course: data.course.value,
        academicYear: data.academicYear.value,
        teacher: data.classTeacher.value,
        startdate: data.startDate,
        enddate: data.endDate,
      };
      dispatch(
        addClass(classData, () => {
          setAddClassStep(1);
          setProgress(1);
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Header />
      {/* <Spacer height="7rem" /> */}
      <ClassDetailsContainer>
        <div className="container-class">
          <div className="classheading d-flex justify-content-between  mb-2">
            <p className="main-text">Basic Details</p>
            <p className="sub-text">
              To add a class, fill out the necessary information.
            </p>
          </div>
          <CustomBox padding="1.5rem 1.5rem" className="custombox">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container">
                <div className="row-container">
                  <div className="field-left field-left1">
                    <Controller
                      control={control}
                      name="course"
                      render={fields => (
                        <Select
                          {...fields}
                          hasError={!!errors.course}
                          errorMessage={errors.course?.message}
                          isLarge={false}
                          label="Course"
                          options={coursesOptions}
                          placeholder="Select the course"
                          id="course"
                        />
                      )}
                    />
                  </div>
                  <div className="field-right">
                    <Controller
                      control={control}
                      name="qualification"
                      render={fields => (
                        <Select
                          {...fields}
                          hasError={!!errors.qualification}
                          isDisabled
                          errorMessage={errors.qualification?.message}
                          isLarge={false}
                          label="Qualification"
                          options={qualificationOptions}
                          placeholder="Select the qualification"
                          id="qualification"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row-container">
                  <div className="field-left field-left1">
                    <Controller
                      control={control}
                      name="department"
                      render={fields => (
                        <Select
                          {...fields}
                          height="13rem"
                          hasError={!!errors.department}
                          errorMessage={errors.department?.message}
                          isLarge={false}
                          label="Department"
                          options={departmentsOptions}
                          placeholder="Select the department"
                          noOptionMessage="Create Department"
                          noOptionRedirectLink="/admin/organisation/departments"
                          id="department"
                        />
                      )}
                    />
                  </div>
                  <div className="field-right">
                    <Controller
                      control={control}
                      name="className"
                      render={fields => (
                        <TextInput
                          {...fields}
                          autoComplete="off"
                          errorMessage={
                            errors.className?.message || formError?.className
                          }
                          hasError={!!errors.className || !!formError.className}
                          label="Class Name"
                          placeholder="Enter Class Name"
                          id="className"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row-container">
                  <div className="field-left field-left1">
                    <Controller
                      control={control}
                      name="academicYear"
                      render={fields => (
                        <Select
                          {...fields}
                          height="13rem"
                          hasError={!!errors.academicYear}
                          errorMessage={errors.academicYear?.message}
                          isLarge={false}
                          label="Academic Year"
                          options={academicYearsOptions}
                          placeholder="Select the Academic Year"
                          onChange={e => {
                            fields.onChange(e);
                            onSelectChange(e);
                          }}
                          noOptionMessage="Create Academic Year"
                          noOptionRedirectLink="/admin/organisation/academicyear"
                          id="academicYear"
                        />
                      )}
                    />
                  </div>
                  <div className="field-right">
                    <Controller
                      control={control}
                      name="classTeacher"
                      render={fields => (
                        <Select
                          {...fields}
                          height="10rem"
                          hasError={!!errors.classTeacher}
                          errorMessage={errors.classTeacher?.message}
                          isLarge={false}
                          label="Class Teacher"
                          options={teachersOptions}
                          placeholder="Select the class teacher"
                          id="classTeacher"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row-container">
                  <div className="field-left">
                    <Controller
                      control={control}
                      name="startDate"
                      render={fields => (
                        <DateInput
                          {...fields}
                          hasError={!!errors.startDate}
                          errorMessage={errors.startDate?.message}
                          label="Start Date"
                          placeholder="Select Start Date"
                          dateFormat="d MMMM, yyyy"
                          minDate={minDate}
                          maxDate={maxDate}
                          id="startDate"
                        />
                      )}
                    />
                  </div>
                  <div className="field-right">
                    <Controller
                      control={control}
                      name="endDate"
                      render={fields => (
                        <DateInput
                          {...fields}
                          hasError={!!errors.endDate}
                          errorMessage={errors.endDate?.message}
                          label="End Date"
                          placeholder="Select End Date"
                          dateFormat="d MMMM, yyyy"
                          minDate={minDate}
                          maxDate={maxDate}
                          id="endDate"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </form>
          </CustomBox>
        </div>
      </ClassDetailsContainer>
      {/* <Spacer height="9rem" /> */}
      <Footer
        progress={progress}
        isAddClassLoading={isAddClassLoading}
        onSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
};
