import {yupResolver} from '@hookform/resolvers/yup';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Controller, useForm} from 'react-hook-form';
import HeaderContainer from '../../../../Elements/HeaderContainer';
import {BoxContainer} from '../../../../../../components/common/Box';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {TextArea} from '../../../../../../components/common/TextArea';
import {TextInput, Button} from '../../../../../../components/common';
import {BasicInfoContainer} from './elements';
import {basicInfoSchema} from '../../../../../../utils/validation';
import {UploadOrganisationLogo} from './components';
import UpdateBasicInfoIcon from '../../../../../../assets/icons/updateadmintoast.svg';

import {showToast} from '../../../../../../components/common/Toast';
import {
  fetchBasicInfo,
  basicInfoSelector,
  updateBasicInfo,
  setError,
} from '../../../../../../redux/basicInfoSlice';

const FIELDS_IN_ORDER = ['organisationName', 'address'];

export const BasicInfo = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBasicInfo());
  }, [dispatch]);

  const {
    basicInfo,
    isLoading,
    isLogoLoading,
    error: serverError,
  } = useSelector(basicInfoSelector);

  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      organisationName: '',
      address: '',
      organisationLogo: null,
    },
    resolver: yupResolver(basicInfoSchema),
  });

  useEffect(() => {
    if (basicInfo) {
      setValue('organisationName', basicInfo?.or_orgname || '');
      setValue('address', basicInfo?.or_address || '');
    }
  }, [basicInfo, setValue]);

  const resetData = () => {
    if (basicInfo) {
      setValue('organisationName', basicInfo?.or_orgname || '');
      setValue('address', basicInfo?.or_address || '');
    }
  };

  const [upload, setUpload] = useState(null);

  const onSubmit = useCallback(
    data => {
      if (!isLoading) {
        dispatch(
          updateBasicInfo({...data, upload}, () => {
            showToast(
              UpdateBasicInfoIcon,
              `Organisation details updated successfully`,
              false,
            );
            setUpload(null);
          }),
        );
      }
    },
    [dispatch, isLoading, upload],
  );

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <>
      <Helmet>
        <title>Basic Info | JungleCat</title>
      </Helmet>

      <BasicInfoContainer className="mt-4">
        <HeaderContainer>
          <h2 style={{fontSize: '24px'}}>Basic Info</h2>
        </HeaderContainer>
        <BoxContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="organisationName"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mr-4"
                  hasError={hasServerError || !!errors.organisationName}
                  label="Organisation Name"
                  id="organisation-name"
                />
              )}
            />

            <UploadOrganisationLogo
              basicInfo={basicInfo}
              isLogoLoading={isLogoLoading}
              upload={upload}
              setUpload={setUpload}
            />

            <Controller
              control={control}
              name="address"
              render={fields => (
                <TextArea
                  {...fields}
                  className="mr-4 mt-4"
                  hasError={hasServerError || !!errors.address}
                  label="Address"
                  rows="5"
                  id="address"
                />
              )}
            />

            {watch('address') !== basicInfo?.or_address ||
            watch('organisationName') !== basicInfo?.or_orgname ||
            upload ? (
              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="button cancel-button mr-3"
                  isFullWidth={false}
                  label="Cancel"
                  isCancel
                  id="cancel-btn"
                  onClick={resetData}
                />
                <Button
                  isFullWidth={false}
                  isLoading={isLoading}
                  onClick={handleSubmit(onSubmit)}
                  label={isLoading ? 'Saving...' : 'Save Changes'}
                  id="save-btn"
                />
              </div>
            ) : null}
          </form>
        </BoxContainer>
      </BasicInfoContainer>
    </>
  );
};
