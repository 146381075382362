import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import MainInput from '../../../../../../../components/shared/Inputs/MainInput';
import CustomButton from '../../../../../../../components/shared/Buttons/CustomButton';
import {Nav} from '../../../../../../../components/shared/Navbar/Elements/Navbar';
import DropdownWrapper from '../../../../../../../components/shared/DropdownWrapper/DropdownWrapper';
import EllipsisImg from '../../../../../../../assets/icons/ellipsis.svg';
import ArrowLeftImg from '../../../../../../../assets/icons/arrow_left.svg';

import {
  fetchOneUnitById,
  unitsSelector,
  setAssessmentTitle,
  setSearchField,
} from '../../../../../../../redux/unitsSlice';

import useNavbarScroll from '../../../../../../../hooks/useNavbarScroll';
import {AddAssessmentByUnitContainer} from './elements';
import {PublishAssessments} from './components';

export const AddAssessmentTitle = () => {
  const input = useRef();
  const history = useHistory();

  const [menuOpen, toggleMenu] = useState(false);
  const [unitId, setUnitId] = useState(null);
  const dispatch = useDispatch();
  const {unitDetails, searchTerm, assessmentLoading} =
    useSelector(unitsSelector);

  const {
    location: {pathname},
  } = useHistory();

  const tgovUnitId = pathname.split('/')[5];
  const tgovQualId = pathname.split('/')[3];

  const {handleScroll, navbarTop} = useNavbarScroll(16, 81);

  useEffect(() => {
    if (input) input.current.focus();
  }, []);

  useEffect(() => {
    if (unitDetails?.orgUnit) setUnitId(unitDetails?.orgUnit?.ou_id_orgunit);
  }, [unitDetails]);

  useEffect(() => {
    dispatch(setSearchField(''));
  }, [dispatch]);

  // Fetch the assessment map using the unit's ID.
  useEffect(() => {
    dispatch(fetchOneUnitById(tgovUnitId));
  }, [dispatch, pathname, tgovUnitId]);

  const onSubmitPress = useCallback(() => {
    if (searchTerm.trim() !== '' && unitId && !assessmentLoading) {
      dispatch(
        setAssessmentTitle(unitId, activityId => {
          history.push(
            `/admin/qualifications/${tgovQualId}/units/${tgovUnitId}/assessment/${activityId}/update`,
          );
        }),
      );
    }
  }, [
    assessmentLoading,
    dispatch,
    history,
    searchTerm,
    tgovQualId,
    tgovUnitId,
    unitId,
  ]);

  return (
    <>
      <Helmet>
        <title>Add an Assessment | JungleCat</title>
      </Helmet>
      <AddAssessmentByUnitContainer className="row position-relative assessment-search-body">
        <Nav
          className="navbar navbar-expand-sm p-0 fixed-top row px-lg-4 ml-0 w-100"
          style={{
            top: navbarTop,
          }}
        >
          <button
            className="btn"
            onClick={() => history.goBack()}
            style={{
              padding: 0,
            }}
            type="button"
          >
            <img src={ArrowLeftImg} alt="arrow" />
          </button>
          <p className="assessment-heading mb-0 mr-auto">
            Create an Assessment
          </p>
          <DropdownWrapper toggleMenu={toggleMenu}>
            <button
              type="submit"
              className="btn"
              onClick={() => toggleMenu(!menuOpen)}
            >
              <img src={EllipsisImg} alt="ellipsis" />
            </button>
          </DropdownWrapper>
        </Nav>
        <main className="main-body" onScroll={handleScroll}>
          <div className="col-lg-6 col-12 mx-auto assessment-search-content">
            <PublishAssessments isButtonShow={false} />
            <div className="assessment-search-inputDiv">
              <MainInput
                type="text"
                id="search-add"
                label=""
                name="search-add"
                placeholder="Enter assessment title"
                autoComplete="off"
                onChange={e => dispatch(setSearchField(e.target.value))}
                value={searchTerm}
                register={input}
                style={
                  searchTerm !== ''
                    ? {
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',
                      }
                    : {}
                }
              />

              {searchTerm !== '' && (
                <CustomButton
                  type="button"
                  width="100%"
                  padding="0.625rem 1.125rem"
                  bgColor="#40A1FF"
                  onClick={onSubmitPress}
                  className="add-assessment-button"
                >
                  Add `{searchTerm}` as a New Assessment
                </CustomButton>
              )}
            </div>
          </div>
        </main>
      </AddAssessmentByUnitContainer>
    </>
  );
};
