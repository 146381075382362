import styled from 'styled-components';

const ScheduledSessionsChartContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  position: fixed;
  top: 5rem;
  right: 0;
  height: calc(100vh - 5rem);
  /* &.is-archive {
    display: none !important;
  } */
  .scheduled-sessions-chart {
    background-color: white;
    border-left: 0.0625rem solid ${({theme}) => theme.colors.border};
    width: 24rem;
    height: 100%;

    .chart-header {
      padding: 2.1875rem 2.5rem 2.5rem 2.5rem;

      .selected-date {
        color: #9ea3a5;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.625rem;

        .week {
          color: #0c181f;
        }
      }

      .navigation-buttons {
        display: flex;

        .navigation-button {
          border: 0.0625rem solid #dedede;
          background-color: white;
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.125rem;

          &:last-child {
            margin-left: 0.75rem;

            img {
              transform: rotate(180deg);
            }
          }

          img {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    .chart-body {
      height: calc(100% - 6.15625rem);
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0 2.5rem 2.5rem 2.5rem;
    }
  }
`;

export default ScheduledSessionsChartContainer;
