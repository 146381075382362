/* eslint-disable react/prop-types */
import React from 'react';

import ProfileIcon from '../../../../../../../../assets/icons/jc_logo.svg';

import {OrganisationCardContainer} from './elements';

export const OrganisationCard = ({organisationDetails}) => (
  <OrganisationCardContainer>
    <div className="organisationcard">
      {organisationDetails?.or_logo_url ? (
        <div className="organisationcard-top">
          <img
            src={
              organisationDetails?.or_logo_url
                ? organisationDetails?.or_logo_url
                : ProfileIcon
            }
            alt="organisation icon"
            width="100px"
            height="60px"
          />
        </div>
      ) : null}

      <div className="organisationcard-bottom">
        <span>{organisationDetails?.or_orgname}</span>
        <p>{organisationDetails?.or_address}</p>
      </div>
    </div>
  </OrganisationCardContainer>
);
