import {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';

import {LibraryContainer} from './elements';
import {
  LibraryCard,
  EmptyState,
  Button,
  Modal,
  Dropdown,
} from '../../../../components/common';
import PlusIcon from '../../../../assets/icons/refactor/plus-white.svg';
import {TableSpinner} from '../../components-new';
import {
  fetchActiveClasses,
  fetchAllLibraryFiles,
  adminLibrarySelector,
  deleteLibraryClassFile,
  fetchAllCategory,
  clearFormError,
} from '../../../../redux/adminLibrarySlice';
import {
  AddDocument,
  EditDocument,
  FilePreview,
  FilterFiles,
} from './components';
import {showToast} from '../../../../components/common/Toast';

const DELAY = 500;

export const Library = () => {
  const dispatch = useDispatch();
  const [classFilesTab, setClassFilesTab] = useState(true);
  const [publicFilesTab, setPublicFilesTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [addDocument, setAddDocument] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const [previewFileData, setPreviewFileData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteDocumentName, setDeleteDocumentName] = useState(null);
  const [deleteFileSection, setDeleteFileSection] = useState(null);
  const [editDocument, setEditDocument] = useState(null);
  const [className, setClassName] = useState({
    label: 'All Classes',
    value: 'all-files',
  });

  useEffect(() => {
    dispatch(fetchAllLibraryFiles(className.value));
  }, [dispatch, className.value]);
  useEffect(() => {
    dispatch(fetchActiveClasses());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchAllCategory());
  }, [dispatch]);

  const {
    adminLibrary,
    publicLibraryFiles,
    isListLoading,
    isAdminLibraryLoading,
    activeClasses,
    category,
  } = useSelector(adminLibrarySelector);
  const [filterByCategory, setFilterByCategory] = useState({
    value: '',
    label: 'All Category',
  });
  const [filterByFileType, setFilterByFileType] = useState({
    value: '',
    label: 'All files types',
  });

  const publicLibraryFilesGroup = useMemo(
    () =>
      publicLibraryFiles?.filter(
        item =>
          item?.fileType?.ft_type !== 'Text' &&
          `${item?.fi_text_content}`.toLowerCase().includes(searchTerm) &&
          (item?.fileCategory?.fc_id_filecategory === filterByCategory?.value ||
            filterByCategory?.value === '') &&
          (item?.fileType?.ft_id_filetype === filterByFileType?.value ||
            filterByFileType?.value === ''),
      ),
    [publicLibraryFiles, searchTerm, filterByCategory, filterByFileType],
  );

  const adminLibraryFilesGroup = useMemo(
    () =>
      adminLibrary?.filter(
        item =>
          item?.fileType?.ft_type !== 'Text' &&
          (`${item?.fi_text_content}`.toLowerCase().includes(searchTerm) ||
            `${item?.fi_code}`.toLowerCase().includes(searchTerm)) &&
          (item?.fileCategory?.fc_id_filecategory === filterByCategory?.value ||
            filterByCategory?.value === '') &&
          (item?.fileType?.ft_id_filetype === filterByFileType?.value ||
            filterByFileType?.value === ''),
      ),
    [adminLibrary, searchTerm, filterByCategory, filterByFileType],
  );

  const classesAsOptions = useMemo(
    () => [
      {label: 'All Classes', value: 'all-files'},
      ...(activeClasses?.map(item => ({
        label: `${item?.class?.oc_class} - ${item?.orgUnit?.unit?.tun_title}`,
        value: item?.orui_id_orgunitinstance,
      })) || []),
    ],
    [activeClasses],
  );

  const categoryAsOptions = useMemo(
    () => [
      {label: 'All Catergory', value: ''},
      ...(category?.map(item => ({
        label: `${item?.fc_name}`,
        value: item?.fc_id_filecategory,
      })) || []),
    ],
    [category],
  );

  const fileTypeOptions = [
    {label: 'All file types', value: ''},
    {label: 'Image', value: 1},
    {label: 'Video', value: 2},
    {label: 'Document', value: 5},
    // {label: 'Voice', value: 7},
  ];

  return (
    <>
      <Helmet>
        <title>Library | JungleCat</title>
      </Helmet>
      <LibraryContainer>
        <>
          <div>
            <div className="library-header" id="library-header-div">
              <span className="flex-shrink-0 mb-0 title" id="title">
                Library
              </span>
              <div className="library-searc-add" id="library-search-add">
                <Button
                  icon={PlusIcon}
                  className="add-doc-button"
                  isFullWidth={false}
                  label="Add Document"
                  onClick={() => {
                    setAddDocument(true);
                  }}
                  id="add-doc-button"
                />
              </div>
            </div>

            <div className="search-div mb-5" id="search-div">
              <div className="library-tab" id="library-tab">
                <button
                  id="bottom"
                  className={classNames('bottom', {
                    link: classFilesTab,
                  })}
                  type="button"
                  onClick={() => {
                    setClassFilesTab(true);
                    setPublicFilesTab(false);
                    setSearchTerm('');
                    setFilterByCategory({
                      value: '',
                      label: 'All Category',
                    });
                    setFilterByFileType({
                      value: '',
                      label: 'All file types',
                    });
                  }}
                >
                  <Dropdown
                    isLarge={false}
                    value={className}
                    options={classesAsOptions}
                    menuWidth="13.95rem"
                    isOptionsSingleLine
                    isSearchable={false}
                    isTooltipShown
                    onChange={e => {
                      setClassName(e);
                    }}
                    id="dropdown"
                  />
                </button>
              </div>
              <div className="library-tab" id="library-tab">
                <button
                  id="bottom-public"
                  className={classNames('bottom public', {
                    link: publicFilesTab,
                  })}
                  type="button"
                  onClick={() => {
                    setClassFilesTab(false);
                    setPublicFilesTab(true);
                    setSearchTerm('');
                    setFilterByCategory({
                      value: '',
                      label: 'All Category',
                    });
                    setFilterByFileType({
                      value: '',
                      label: 'All file types',
                    });
                  }}
                >
                  <span>Public</span>
                </button>
              </div>
            </div>

            {isListLoading ? <TableSpinner /> : null}

            {classFilesTab && !isListLoading ? (
              <>
                <FilterFiles
                  filesLength={adminLibraryFilesGroup?.length}
                  filterByCategory={filterByCategory}
                  categoryAsOptions={categoryAsOptions}
                  setFilterByCategory={setFilterByCategory}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  fileTypeOptions={fileTypeOptions}
                  setFilterByFileType={setFilterByFileType}
                  filterByFileType={filterByFileType}
                />

                {!isListLoading && !adminLibraryFilesGroup?.length ? (
                  <EmptyState
                    description="It seems that there is no Files has been added to this Class"
                    title="No Files Found!"
                  />
                ) : null}

                <div className="row mt-4">
                  {adminLibraryFilesGroup?.map(item => (
                    <div className="card-class col-sm-6 col-lg-3 col-md-4 col-xl-3 col-xs-6 col-12 mb-4">
                      <LibraryCard
                        id="library-card-files"
                        data={adminLibraryFilesGroup}
                        height="100%"
                        fileName={item?.fi_filename}
                        uploadedDate={item?.fi_uploadedate}
                        fileLocation={item?.fi_location}
                        fileType={item?.fileType?.ft_type}
                        thumbnail={
                          item?.fi_cover_image
                            ? item?.fi_cover_image
                            : item?.fi_thumbnail
                        }
                        fileTitle={item?.fi_text_content}
                        fileCode={item?.fi_code}
                        fileCategory={item?.fileCategory?.fc_name}
                        onClick={() => {
                          setViewDocument(true);
                          setPreviewFileData(item);
                        }}
                        deleteOnClick={() => {
                          setDeleteId(item?.fi_id_file);
                          setDeleteDocumentName(item?.fi_text_content);
                          setDeleteFileSection(1);
                        }}
                        editOnClick={() => {
                          setEditDocument(item);
                        }}
                        unitAndClassName={`${item?.orgUnitInstance?.class?.oc_class} - ${item?.orgUnitInstance?.orgUnit?.unit?.tun_title}`}
                        isAdmin
                        key={item?.fi_id_file}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {publicFilesTab && !isListLoading ? (
              <>
                <FilterFiles
                  filesLength={publicLibraryFilesGroup?.length}
                  filterByCategory={filterByCategory}
                  categoryAsOptions={categoryAsOptions}
                  setFilterByCategory={setFilterByCategory}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  fileTypeOptions={fileTypeOptions}
                  setFilterByFileType={setFilterByFileType}
                  filterByFileType={filterByFileType}
                />

                {!isListLoading && !publicLibraryFilesGroup?.length ? (
                  <EmptyState
                    description="It seems that there is no Files has been added to this Class"
                    title="No Files Found!"
                  />
                ) : null}

                <div className="row mt-4 ">
                  {publicLibraryFilesGroup?.map(item => (
                    <div className="card-class col-sm-6 col-lg-3 col-md-4 col-xl-3 col-xs-6 col-12 mb-4">
                      <LibraryCard
                        id="library-card-public-files"
                        data={publicLibraryFilesGroup}
                        height="100%"
                        fileName={item?.fi_filename}
                        uploadedDate={item?.fi_uploadedate}
                        fileLocation={item?.fi_location}
                        fileType={item?.fileType?.ft_type}
                        thumbnail={
                          item?.fi_cover_image
                            ? item?.fi_cover_image
                            : item?.fi_thumbnail
                        }
                        fileTitle={item?.fi_text_content}
                        fileCategory={item?.fileCategory?.fc_name}
                        fileCode={item?.fi_code}
                        onClick={() => {
                          setViewDocument(true);
                          setPreviewFileData(item);
                        }}
                        deleteOnClick={() => {
                          setDeleteId(item?.fi_id_file);
                          setDeleteDocumentName(item?.fi_text_content);
                          setDeleteFileSection(2);
                        }}
                        editOnClick={() => {
                          setEditDocument(item);
                        }}
                        key={item?.fi_id_file}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </>
        {addDocument ? (
          <AddDocument
            isVisible={addDocument}
            onClose={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setAddDocument(false);
              clearFormError();
            }}
            onSuccess={e => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';

              if (e) {
                setClassFilesTab(true);
                setPublicFilesTab(false);
                setClassName({label: e.label, value: e.value});
              } else {
                setClassFilesTab(false);
                setPublicFilesTab(true);
              }
              clearFormError();
            }}
          />
        ) : null}
        {editDocument ? (
          <EditDocument
            isVisible={editDocument}
            onClose={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setEditDocument(false);
            }}
            editDocument={editDocument}
            isPublic={editDocument?.fi_is_public}
            onSuccess={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setClassFilesTab(true);
              setPublicFilesTab(false);
            }}
          />
        ) : null}
        {viewDocument && previewFileData ? (
          <FilePreview
            isVisible={viewDocument}
            onClose={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setViewDocument(false);
            }}
            previewFileData={previewFileData}
          />
        ) : null}
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this Document ?"
          isDelete
          width="41.25rem"
          isButtonLoading={isAdminLibraryLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isAdminLibraryLoading)
              dispatch(
                deleteLibraryClassFile(deleteId, deleteFileSection, () => {
                  setDeleteId(null);
                  setDeleteFileSection(null);
                  showToast(
                    false,
                    `${deleteDocumentName} deleted successfully`,
                    true,
                  );
                }),
              );
          }}
          onHide={() => {
            setDeleteId(null);
            setTimeout(() => {
              setDeleteDocumentName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteDocumentName}`}
        />
      </LibraryContainer>
    </>
  );
};
