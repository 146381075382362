/* eslint-disable react/prop-types */
import React from 'react';

import {useSelector} from 'react-redux';

import TeacherProfileImg from '../../../../../../assets/icons/teacherprofilebackground.png';
import {authSelector} from '../../../../../../redux/authSlice';
import {ProfileCardContainer} from './elements';
import {OrganisationCard, TimeLineCard} from './components';

export const ProfileCard = ({liveSessionPreference}) => {
  const authState = useSelector(authSelector);
  const userProfile = authState?.user?.userProfiles[0];
  const organisationDetails = authState?.userOrganisation;

  return (
    <>
      <ProfileCardContainer>
        <div className="profilecard">
          <div className="profilecard-top">
            <img src={TeacherProfileImg} alt="profile icon" />
          </div>
          <div className="profilecard-center">
            {!userProfile?.up_avatar ? (
              <div className="circle d-flex align-items-center justify-content-center">
                {userProfile?.up_name_first[0]}
                {userProfile?.up_name_last[0]}
              </div>
            ) : (
              <img
                src={userProfile?.up_avatar}
                alt="profile icon"
                width="116px"
                height="116px"
              />
            )}
          </div>
          <div className="profilecard-bottom">
            <span>{`${userProfile?.up_name_first} ${userProfile?.up_name_last}`}</span>
            <p>{userProfile?.up_email}</p>
          </div>
        </div>
      </ProfileCardContainer>
      <OrganisationCard organisationDetails={organisationDetails} />
      <TimeLineCard liveSessionPreference={liveSessionPreference} />
    </>
  );
};
