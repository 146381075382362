import {createSlice} from '@reduxjs/toolkit';

import * as curriculumApi from '../api/curriculumTeacher';

const teacherCurriculumSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: false,
    isLoading: false,
    courseCurriculum: null,
    isAddCurriculumLoading: false,
    curriculumFile: null,
  },
  name: 'teacherCurriculum',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setCourseCurriculum(state, action) {
      state.courseCurriculum = action.payload;
    },
    setIsAddCurriculumLoading(state, action) {
      state.isAddCurriculumLoading = action.payload;
    },

    setCurriculumFile(state, action) {
      state.curriculumFile = action.payload;
    },
  },
});

export const {
  setError,
  setIsListLoading,
  setIsLoading,
  setCourseCurriculum,
  setIsAddCurriculumLoading,
  setCurriculumFile,
} = teacherCurriculumSlice.actions;

export const getCurriculumByOrgUnitInstanceId =
  orgUnitInstanceId => async dispatch => {
    dispatch(setIsListLoading(true));
    try {
      const response = await curriculumApi.fetchCurriculumByOrgUnitInstanceId(
        orgUnitInstanceId,
      );

      dispatch(setCourseCurriculum(response.data.allCurriculum));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsListLoading(false));
    }
  };

export const publishCurriculumModuleFileById =
  (orgUnitInstanceId, orgClassCurriculumFileId, callback) => async dispatch => {
    dispatch(setIsLoading(true));
    try {
      const resp = await curriculumApi.publishCurriculumModuleFileById(
        orgUnitInstanceId,
        orgClassCurriculumFileId,
      );

      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const publishAllCurriculumModuleFiles =
  (orgUnitInstanceId, orgClassCurriculumId, callback) => async dispatch => {
    dispatch(setIsLoading(true));
    try {
      const resp = await curriculumApi.publishAllCurriculumModuleFiles(
        orgUnitInstanceId,
        orgClassCurriculumId,
      );
      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getCurriculumFileByFileId =
  (orgUnitInstanceId, curriculumFileId) => async dispatch => {
    dispatch(setIsListLoading(true));
    try {
      const response = await curriculumApi.fetchCurriculumFileByFileId(
        orgUnitInstanceId,
        curriculumFileId,
      );
      dispatch(setCurriculumFile(response.data.curriculumFile));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsListLoading(false));
    }
  };

export const teacherCurriculumSelector = state => state.teacherCurriculum;

export default teacherCurriculumSlice.reducer;
