import styled from 'styled-components';

const HeaderContainer = styled.div`
  padding: 1.125rem 1.125rem 0rem 1.125rem;
  width: 100%;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    color: #091018;
  }

  .description {
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 400;
    color: #84888c;
  }
`;

const Header = () => (
  <HeaderContainer>
    <h2 className="title mb-2">Upcoming Sessions</h2>
    <p className="description mb-2">
      You can schedule, record or start a class instantly to this unit.
    </p>
  </HeaderContainer>
);

export default Header;
