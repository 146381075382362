/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import {ViewFileSharedToClassMainContainer} from './elements';
import {FilePreview} from './components';
import EmptyState from '../../../../../../../../../../../../components/shared/EmptyState';

export const ViewFileSharedToClassMain = ({curriculumFile}) => (
  <ViewFileSharedToClassMainContainer>
    <div className="container-file">
      <div className="d-flex file-div">
        <div className="file-data">
          <FilePreview curriculumFile={curriculumFile} />
        </div>
        <div className="shared-classes">
          <div className="shared-class-title">
            <span className="shared-class-heading">Shared Classes</span>
          </div>
          {curriculumFile?.publishedClasses?.length ? (
            <div className="container-scroll">
              {curriculumFile?.publishedClasses?.map(item => (
                <div className="class-shared mt-2">
                  <span className="class-name-shared">{item.oc_class}</span>
                  <span className="class-name-shared">
                    {moment(item.occf_created_at).format('DD/MM/YYYY')}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <EmptyState
              className="empty-state"
              body="There is no data about shered classes available."
              title="No Share History"
            />
          )}
        </div>
      </div>
    </div>
  </ViewFileSharedToClassMainContainer>
);
