/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactBootstrapModal from 'react-bootstrap/Modal';

// import CustomBox from '../../../../../../../../../../../../components/shared/CustomBox';
import ModalCloseIcon from '../../../../../../../../../../../../../../../assets/icons/instruction-modal-close.svg';
import {DisplayModalConatiner} from './elements';

export const DisplayModal = ({onClose, showData}) => (
  <ReactBootstrapModal centered show contentClassName="task-display-modal">
    <DisplayModalConatiner
      className={`display-modal-container ${
        showData.type === 'document' ? 'for-document' : ''
      }`}
    >
      <div className="display-modal-header">
        <div>
          <button
            className="modal-close-button"
            onClick={onClose}
            type="button"
          >
            <img alt="Close Modal" src={ModalCloseIcon} />
          </button>
        </div>
      </div>
      {showData.type === 'document' ? (
        <object
          data={showData.media}
          type="application/*"
          className="object-file"
        >
          <iframe
            src={`${showData.media}#toolbar=0&navpanes=0&scrollbar=0&zoom=0&embedded=true`}
            type="application/*"
            className="iframe-file"
          >
            <p>This browser does not support PDF!</p>
          </iframe>
        </object>
      ) : (
        <div className="instruction-image">
          <img alt="Instruction" src={showData.media} />
        </div>
      )}
    </DisplayModalConatiner>
  </ReactBootstrapModal>
);
