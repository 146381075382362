import styled from 'styled-components';

export const ViewClassHeaderContainer = styled.div`
  @media (max-width: 44rem) {
    .top {
      padding: 1rem !important;
    }
    .overview-data {
      .overview-right {
        margin-top: 1rem;
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        width: 100%;
        .teacher-info {
          display: flex;
          flex-direction: row-reverse !important;
        }
      }
    }
    .letters {
      margin-left: unset !important;
    }
    .view-class-title {
      display: flex;
      flex-direction: column;
    }
  }
  .back-button {
    display: flex;
    img {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.1275rem;
      vertical-align: middle;
    }

    span {
      font-size: 0.875rem;
      color: #8a8c94;
      margin-left: 0.6875rem;
    }
  }
  .overview-data {
    background-color: #ffffff;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    .top {
      padding: 1.5rem;

      .overview-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .teacher-info {
          display: flex;
          .teacher-name {
            font-weight: 500;
            font-size: 1rem;
            color: #3d4457;
          }
          .class-teacher {
            font-size: 0.875rem;
            line-height: 1.31rem;
            color: #8a8c94;
          }
        }
      }

      .letters {
        width: 3rem;
        height: 3rem;
        margin: 0 1rem;
        display: flex;
        justify-content: center;
        font-weight: 500;
        border-radius: 0.75rem;
        text-transform: uppercase;
        font-size: 1rem;
        color: #3c97f0;
        background: #ecf6ff;
        padding: 0.75rem 0.812rem;
      }
    }
  }

  .header-break {
    height: 0.0625rem;
    background-color: #e0e0e0;
    width: 100%;
  }
  .date {
    display: flex;
    flex-direction: row;
    align-items: center;

    .overview-bottom-header {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #aaaaaa;
    }
    .start-date {
      display: flex;
      flex-direction: row;
      .view-img {
        margin-right: 1rem;
      }
      .view-list-img {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.187rem;
      }
    }
    .end-date {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.6rem;
    }
  }
  .view-class-header {
    .back-button {
      margin-bottom: 1rem;
    }

    .view-class-title {
      display: flex;
      justify-content: space-between;
      .class-name {
        color: #3d4457;
        font-weight: 500;
        font-size: 1.25rem;
      }
      .qualification-name {
        margin-top: 0.25rem;
        font-size: 0.875rem;
        color: #8a8c94;
        margin-bottom: unset;
      }
    }
  }

  .view-class-header-bottom {
    display: flex;
    border-radius: 0.75rem;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    @media (max-width: 63rem) {
      width: 100%;
      overflow-x: scroll;
    }
    .vertical-line {
      height: 3.125rem;
      width: 0.125rem;
      background-color: #e9e9e9;
      flex-shrink: 0;
      @media (max-width: 71.187rem) {
        height: 4.37rem;
      }
    }
  }
`;
export const SectionContainer = styled.div`
  .view-list {
    display: flex;
    flex: 1;
    .view-img {
      margin-right: 1rem;
    }
    .view-list-img {
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.187rem;
    }
    :first-child {
      border-left: none;
    }
    :not(:first-child) {
      margin: 0rem 1.5rem 0rem 1.5rem;
    }
    :last-child {
      margin-right: 3.125rem;
    }

    .overview-bottom-header {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #aaaaaa;
    }
    .unit-list-bottom {
      padding-top: 0.25rem;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.187rem;
      color: #3d4457;
    }
  }
`;
