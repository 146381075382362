import styled from 'styled-components';

const WelcomeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: ${({theme}) => theme.colors.background};
  margin: 0px -15px;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 80px;
    padding: 0px 48px;

    .logoAndClass {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: auto;

      .orgLogo {
        width: 80px;
        height: 40px;
        margin-right: 20px;
      }

      .divider {
        height: 30px;
        width: 2px;
        background-color: #e0e0e0;
        margin-right: 20px;
      }

      .jc-logo {
        margin-right: 30px;
      }

      .cap-image {
        width: 42px;
        height: 42px;
        margin-right: 12px;
        border-radius: 8px;
      }

      .class-unit {
        height: 42px;
        p:first-child {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 0px;
          color: ${({theme}) => theme.colors.text};
        }
        p:last-child {
          font-size: 12px;
          color: gray;
          margin-bottom: 0px;
          color: ${({theme}) => theme.colors.lightText};
        }
      }
    }
  }

  main {
    margin: auto;
    margin-top: 30px;
    width: 95%;
    height: calc(100vh - 140px);
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: ${({theme}) => theme.colors.text};

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 100%;

      .half-ellipse {
        height: 135px;
        width: 135px;
        border-radius: 50%;
        position: relative;

        .time-badge {
          position: absolute;
          width: 82px;
          height: 32px;
          border-radius: 37px;
          bottom: 0px;
          left: 26px;
          background-color: ${({theme}) => theme.colors.primary};
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          color: white;
          outline: 5px solid white;
        }

        svg:first-child {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: -15px;
          margin: auto;
          height: 135px;
          width: 135px;
        }
        svg:nth-child(2),
        img:first-child {
          height: 100px;
          width: 100px;
          position: absolute;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0px;
        }
      }

      .welcome-msg {
        margin-top: 20px;
        text-align: center;
      }
    }

    .test-msg {
      color: ${({theme}) => theme.colors.primary};
      margin-bottom: 30px;
      cursor: pointer;
    }
  }
`;

export default WelcomeWrapper;
