/* eslint-disable react/prop-types */

import React from 'react';

import {AssessmentDetailsContainer, AssessmentTasksContainer} from './elements';

import {AssessmentTask} from './components';

export const AssessmentEvaluation = ({assessment, assessmentStatus}) => (
  <AssessmentDetailsContainer className="basic-detail">
    {assessment?.tasks?.length ? (
      <div>
        <p className="assessment-task-header">Assessment Tasks </p>
        <AssessmentTasksContainer>
          {assessment.tasks?.map((task, index) => (
            <AssessmentTask
              assessmentStatus={assessmentStatus}
              key={task.ai_id_activityitem}
              task={task}
              position={index + 1}
            />
          ))}
        </AssessmentTasksContainer>
      </div>
    ) : null}
  </AssessmentDetailsContainer>
);
