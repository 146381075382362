/* eslint-disable camelcase */
import {createSlice} from '@reduxjs/toolkit';
import * as assessmentAPI from '../../../../../../api/assessment';
import {
  commentOnActivity,
  getComments,
  deleteComment,
} from '../../../../../../api/stream/post';

const {
  getSearchAssessment,
  createAssessmentByTitle,
  updateBasicAssessment,
  createTaskAssessment,
  addFilesAssessment,
  getAssessmentDatas,
  getAssessmentSummaryDetails,
  deleteAssessmentTask,
  deleteAssessmentFile,
  listAssessmentStudents,
  assessmentAddAllStudents,
  assessmentAddStudent,
  assessmentRemoveStudent,
  assessmentRemoveAllStudent,
  assessmentPublish,
  assessmentDuplicatedData,
  myAssessmentToclass,
  getAllAssessmentDatas,
  evaluateTask,
  updateTaskEvaluation,
  evaluateAssessment,
  reassignActivity,
  revokeStudentFromAssessment,
  acceptResubmissionRequest,
  declineResubmissionRequest,
  sendReminderToStudent,
  getAssessmentTasksOfStudent,
  assessmentRemoveStudents,
  assessmentAddStudents,
  fetchAllActivityMethods,
  fetchAllEvaluationType,
  ...api
} = assessmentAPI;

const initialState = {
  searchTerm: '',
  searchAssessments: [],
  activityId: null,
  activityOrgunitInstanceId: null,
  assessmentUnitId: null,
  getAllAssessments: [],
  assessmentCount: 0,
  createdAssessment: {},
  updatedAssessment: {},
  createdTasks: [],
  createdFiles: [],
  assessmentStudents: [],
  filteredAssessmentStudents: [],
  selectedStudents: [],
  assessmentTasks: null,
  draftTask: null,
  tasksLinkedToFiles: [],
  // Loadings
  searchLoading: false,
  AssessmentLoading: false,
  AssessmentSuccess: false,
  assessmentTasksLoading: false,
  isPaginating: false,
  isLoadingTasksLinkedToFiles: true,
  // Errors
  AssessmentError: false,
  searchError: false,
  assessmentTasksError: '',
  assessmentType: [],
  evaluationType: [],
  assessmentSummary: [],
  // Form Data
  performanceCriteria: null,
  knowledgeEvidences: null,
  performanceEvidences: null,
  assessmentConditions: null,
};

const assessmentSlice = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    clearCreatedAssessment: state => {
      state.createdAssessment = {};
      state.createdFiles = [];
      state.createdTasks = [];
    },
    fetchSearchAssessment: (state, action) => {
      state.searchAssessments = action.payload;
    },
    fetchCreatedAssessment: (state, action) => {
      state.createdAssessment = action.payload;
    },
    fetchAssessmentSummary: (state, action) => {
      state.assessmentSummary = action.payload;
    },
    setSelectedStudents: (state, action) => {
      state.selectedStudents = action.payload;
    },
    setAssessmentCount: (state, action) => {
      state.assessmentCount = action.payload;
    },
    fetchAllAssessmentData: (state, action) => {
      state.getAllAssessments = action.payload;
    },
    deleteAssessmentFromList: (state, action) => {
      state.getAllAssessments = state.getAllAssessments.filter(
        assessment =>
          assessment.aoui_id_activityorgunitinstance !== action.payload,
      );
    },
    setActivityId: (state, action) => {
      state.activityId = action.payload;
    },
    activityUnitInstIdSetter: (state, action) => {
      state.activityOrgunitInstanceId = action.payload;
    },
    fetchUpdatedAssessment: (state, action) => {
      state.createdAssessment = action.payload;
    },
    setAssessmentUnitId: (state, action) => {
      state.assessmentUnitId = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchTerm = action.payload;
    },
    resetAction: state => {
      state.createdTasks = [];
      state.createdFiles = [];
      state.createdAssessment = {};
      state.assessmentStudents = [];
      state.filteredAssessmentStudents = [];
    },
    setAssessmentTask: (state, action) => {
      const {
        ai_id_activityitem,
        ai_id_activityitemtype,
        ai_activityitem,
        ai_activityitem_description,
        ai_is_published,
        ai_id_evaltype,
        aipc_id_tcriteria,
        aipe_id_tpevidence,
        aike_id_tkevidence,
        aiac_id_tacondition,
        tpc_id_tgovelement,
        tpc_criteria_id,
        tpc_criteria_value,
        et_eval_name,
        performanceCriteria,
        performanceEvidence,
        knowledgeEvidence,
        assessmentCondition,
        linkedFiles,
        isEdit,
      } = action.payload;
      let taskType;
      switch (ai_id_activityitemtype) {
        case 1:
          taskType = 'Image';
          break;
        case 2:
          taskType = 'Video';
          break;
        case 3:
          taskType = 'Document';
          break;
        default:
          taskType = '';
      }
      const task = {
        id: ai_id_activityitem,
        type: taskType,
        name: ai_activityitem,
        description: ai_activityitem_description,
        isPublished: ai_is_published,
        taskFiles: linkedFiles,
        evaluationType: ai_id_evaltype,
        evaluationName: et_eval_name,
        performanceCriteriaId: performanceCriteria
          ? performanceCriteria.tpc_id_tgovperformancecriteria
          : aipc_id_tcriteria,
        // eslint-disable-next-line no-nested-ternary
        performanceCriteriaName: performanceCriteria
          ? `${performanceCriteria.tpc_criteria_id} ${performanceCriteria.tpc_criteria_value}`
          : tpc_criteria_id
          ? `${tpc_criteria_id} ${tpc_criteria_value}`
          : null,
        elementId: performanceCriteria
          ? performanceCriteria.tpc_id_tgovelement
          : tpc_id_tgovelement,
        performanceEvidenceId:
          performanceEvidence?.tpe_id_tgovperformanceevidence ||
          aipe_id_tpevidence,
        performanceEvidenceName: performanceEvidence
          ? performanceEvidence.tpe_evidence_value
          : action.payload?.tpe_evidence_value,
        knowledgeEvidenceId:
          knowledgeEvidence?.tke_id_tgovknowledgeevidence || aike_id_tkevidence,
        knowledgeEvidenceName: knowledgeEvidence
          ? knowledgeEvidence?.tke_evidence_value
          : action.payload?.tke_evidence_value,
        assessmentConditionId:
          assessmentCondition?.tac_id_tgovassessmentcondition ||
          aiac_id_tacondition,
        assessmentConditionName: assessmentCondition
          ? assessmentCondition.tac_condition
          : action?.payload?.tac_condition,
      };

      if (isEdit) {
        const indexOfTaskToReplace = state.createdTasks.findIndex(
          item => item.id === task.id,
        );
        state.createdTasks[indexOfTaskToReplace] = task;
      } else {
        state.createdTasks.push(task);
      }
    },
    setDraftTask: (state, action) => {
      state.draftTask = action.payload;
    },
    setAssessmentFiles: (state, action) => {
      const {fi_id_file, fi_filename, fi_filesize, fi_location} =
        action.payload;

      state.createdFiles.push({
        id: fi_id_file,
        name: fi_filename,
        size: fi_filesize,
        location: fi_location,
      });

      // Removing if any duplicated array of obj found
      const file = [
        ...new Map(
          state.createdFiles.map(obj => [JSON.stringify(obj), obj]),
        ).values(),
      ];

      state.createdFiles = file;
    },
    deleteSelectedTaskItem: (state, action) => {
      state.createdTasks = state.createdTasks.filter(
        task => task.id !== action.payload,
      );
    },
    deleteSelectedFile: (state, action) => {
      state.createdFiles = state.createdFiles.filter(
        file => file.id !== action.payload,
      );
    },
    getAssessmentStudents: (state, action) => {
      state.assessmentStudents = action.payload;
      state.filteredAssessmentStudents = action.payload;
    },
    onFilteredAssessmentStudents: (state, action) => {
      state.filteredAssessmentStudents = [...state.assessmentStudents];
      state.filteredAssessmentStudents = state.assessmentStudents.filter(
        student => {
          const fullName = `${student.up_name_first} ${student.up_name_last}`;
          return fullName.toLowerCase().includes(action.payload.toLowerCase());
        },
      );
    },
    setSearchLoading: (state, action) => {
      state.searchLoading = action.payload;
    },
    setSearchError: (state, action) => {
      state.searchError = action.payload;
    },
    setAssessmentLoading: (state, action) => {
      state.AssessmentLoading = action.payload;
    },
    setAssessmentSuccess: (state, action) => {
      state.AssessmentSuccess = action.payload;
    },
    setAssessmentError: (state, action) => {
      state.AssessmentError = action.payload;
    },
    setAssessmentTasks: (state, action) => {
      state.assessmentTasks = action.payload;
    },
    clearAssessmentTasks: state => {
      state.assessmentTasks = null;
    },
    setAssessmentTasksError: (state, action) => {
      state.assessmentTasksError = action.payload;
    },
    setAssessmentTasksLoading: (state, action) => {
      state.assessmentTasksLoading = action.payload;
    },
    updateAssessmentTaskSubmission: (state, action) => {
      const task = state.assessmentTasks.find(
        item => item.ai_id_activityitem === action.payload.taskId,
      );
      const submission = task.submissions.find(
        item => item.ais_id_userprofile_student === action.payload.studentId,
      );

      if (submission) {
        submission.ais_id_eval_type_value = action.payload.evaluationId;
        submission.ais_notes = action.payload.comments;
        submission.etv_id_eval_type_value = action.payload.evaluationId;
        submission.etv_type_color = action.payload.evaluationColor;
        submission.etv_type_label = action.payload.evaluationLabel;
        submission.etv_type_value = action.payload.evaluationValue;
      } else {
        task.submissions = [
          {
            ais_id_eval_type_value: action.payload.evaluationId,
            ais_id_userprofile_student: action.payload.studentId,
            ais_notes: action.payload.comments,
            etv_id_eval_type_value: action.payload.evaluationId,
            etv_type_color: action.payload.evaluationColor,
            etv_type_label: action.payload.evaluationLabel,
            etv_type_value: action.payload.evaluationValue,
          },
        ];
      }
    },
    setIsPaginating: (state, action) => {
      state.isPaginating = action.payload;
    },
    setComment: (state, action) => {
      const assessment = state.getAllAssessments.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      assessment.comments = [
        action.payload.comment,
        ...(assessment?.comments || []),
      ];
    },
    setComments: (state, action) => {
      const assessment = state.getAllAssessments.find(
        item => item.ac_id_activity === action.payload.activityId,
      );
      assessment.comments = [
        ...(assessment?.comments || []),
        ...action.payload.comments,
      ];
    },
    deleteCommentById: (state, action) => {
      const assessment = state.getAllAssessments.find(
        item => item.ac_id_activity === action.payload.activityId,
      );

      assessment.comments = assessment.comments.filter(
        item =>
          item.acc_id_activity_comment !== Number(action.payload.commentId),
      );
    },
    setLinkedFile: (state, action) => {
      const {file, taskId} = action.payload;

      if (taskId) {
        const task = state.createdTasks.find(item => item.id === taskId);
        task.taskFiles.push(file);
      } else {
        state.draftTask.linkedFiles.push(file);
      }
    },
    updateLinkedFile: (state, action) => {
      const {file, linkId, taskId} = action.payload;
      let fileLink;

      if (taskId) {
        const task = state.createdTasks.find(item => item.id === taskId);
        fileLink = task.taskFiles.find(
          linkedFile => linkedFile.fl_id_filelink === linkId,
        );
      } else {
        fileLink = state.draftTask.linkedFiles.find(
          linkedFile => linkedFile.fl_id_filelink === linkId,
        );
      }

      fileLink.file = file;
    },
    clearTaskInstruction: (state, action) => {
      const {fileID, idOfTaskToEdit, taskID} = action.payload;
      let task;

      if (idOfTaskToEdit) {
        task = state.createdTasks.find(item => item.id === idOfTaskToEdit);
        task.taskFiles = task.taskFiles.filter(
          item => item.fl_id_filelink !== fileID,
        );
      } else {
        task = state.createdTasks.find(item => item.id === taskID);

        if (task) {
          task.taskFiles = task.taskFiles.filter(
            item => item.fl_id_filelink !== fileID,
          );
        } else {
          state.draftTask.linkedFiles = state.draftTask.linkedFiles.filter(
            item => item.fl_id_filelink !== fileID,
          );
        }
      }
    },
    clearAllTaskInstructions: state => {
      state.draftTask.linkedFiles = [];
    },
    setAssessmentType: (state, action) => {
      state.assessmentType = action.payload;
    },
    setEvaluationType: (state, action) => {
      state.evaluationType = action.payload;
    },
    setIsLoadingTasksLinkedToFiles: (state, action) => {
      state.isLoadingTasksLinkedToFiles = action.payload;
    },
    setTasksLinkedToFiles: (state, action) => {
      state.tasksLinkedToFiles = action.payload;
      state.isLoadingTasksLinkedToFiles = false;
    },
    clearTasksLinkedToFiles: state => {
      state.tasksLinkedToFiles = [];
      state.isLoadingTasksLinkedToFiles = true;
    },
    setPerformanceCriteria: (state, action) => {
      state.performanceCriteria = action.payload;
    },
    setPerformanceEvidences: (state, action) => {
      state.performanceEvidences = action.payload;
    },
    setKnowledgeEvidences: (state, action) => {
      state.knowledgeEvidences = action.payload;
    },
    setAssessmentConditions: (state, action) => {
      state.assessmentConditions = action.payload;
    },
  },
});

export const {
  setPerformanceEvidences,
  clearCreatedAssessment,
  setAssessmentConditions,
  setKnowledgeEvidences,
  fetchSearchAssessment,
  fetchCreatedAssessment,
  fetchAssessmentSummary,
  setSelectedStudents,
  setAssessmentCount,
  deleteAssessmentFromList,
  fetchAllAssessmentData,
  setActivityId,
  setAssessmentUnitId,
  activityUnitInstIdSetter,
  resetAction,
  fetchUpdatedAssessment,
  setSearchField,
  setAssessmentTask,
  setAssessmentFiles,
  deleteSelectedTaskItem,
  deleteSelectedFile,
  getAssessmentStudents,
  onFilteredAssessmentStudents,
  setSearchLoading,
  setSearchError,
  setAssessmentLoading,
  setAssessmentSuccess,
  setAssessmentError,
  setAssessmentTasks,
  setAssessmentTasksError,
  setAssessmentTasksLoading,
  setIsPaginating,
  setComment,
  setComments,
  deleteCommentById,
  setLinkedFile,
  setDraftTask,
  updateLinkedFile,
  clearTaskInstruction,
  clearAllTaskInstructions,
  setAssessmentType,
  setEvaluationType,
  setIsLoadingTasksLinkedToFiles,
  setTasksLinkedToFiles,
  clearTasksLinkedToFiles,
  updateAssessmentTaskSubmission,
  clearAssessmentTasks,
  setPerformanceCriteria,
} = assessmentSlice.actions;

// Sending search field to backend it will returns published assessments details and store it on searchAssessments state
export const sendSearchField = (classId, searchField) => async dispatch => {
  dispatch(setSearchField(searchField));
  dispatch(setSearchLoading(true));
  dispatch(setSearchError(false));
  try {
    const resp =
      searchField.trim() !== '' &&
      (await getSearchAssessment(classId, searchField));

    dispatch(fetchSearchAssessment(resp.data.assessments));
    dispatch(setSearchLoading(false));
    dispatch(setSearchError(false));
  } catch (err) {
    dispatch(setSearchLoading(false));
    dispatch(setSearchError('Something went wrong'));
  }
  // clean up when seachfield is empty
  if (searchField.trim() === '') {
    dispatch(fetchSearchAssessment([]));
  }
};

export const setTaskAssessment = (activityId, data) => async dispatch => {
  dispatch(setAssessmentTasksLoading(true));
  dispatch(setAssessmentLoading(true));
  dispatch(setAssessmentError(false));
  dispatch(setAssessmentSuccess(false));
  try {
    const resp = await createTaskAssessment(activityId, data);
    dispatch(setAssessmentTasksLoading(false));
    dispatch(setAssessmentSuccess(true));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentTask(resp.data.activityItem));
  } catch (err) {
    dispatch(setAssessmentTasksLoading(false));
    dispatch(setAssessmentTasksError(err.message));
    dispatch(setAssessmentSuccess(false));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(true));
  }
};

export const fetchDraftTask = activityID => async dispatch => {
  const {data} = await api.getDraftTask(activityID);
  dispatch(setDraftTask(data.task));
};

export const addingAssessmentFiles = (activityId, data) => async dispatch => {
  dispatch(setAssessmentLoading(true));
  dispatch(setAssessmentError(false));
  dispatch(setAssessmentSuccess(false));
  try {
    const resp = await addFilesAssessment(activityId, data);
    dispatch(setAssessmentSuccess(true));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(false));
    resp.data.uploadedFiles.forEach(file => {
      dispatch(setAssessmentFiles(file));
    });
  } catch (err) {
    dispatch(setAssessmentSuccess(false));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(true));
  }
};

export const getAssessmentDetails =
  (activityId, classId, history, callback) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const resp = await getAssessmentDatas(activityId, classId);
      const activityOrgUnitInstanceId =
        resp.data.assessments.aoui_id_activityorgunitinstance;
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      if (callback) callback();
      dispatch(setActivityId(activityId));
      dispatch(activityUnitInstIdSetter(activityOrgUnitInstanceId));
      dispatch(fetchCreatedAssessment(resp.data.assessments));
      const availableTask = resp.data.assessments.tasks.filter(
        task => task.ai_is_deleted !== 1,
      );
      availableTask.forEach(task => {
        dispatch(setAssessmentTask(task));
      });
      const availableFiles = resp.data.assessments.files.filter(
        file => file.fi_is_deleted !== 1,
      );
      availableFiles.forEach(file => {
        dispatch(setAssessmentFiles(file));
      });
      if (history) {
        history.push(
          `/stream/${classId}/assessment/create/${activityId}/#details`,
        );
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const updateAssessmentDetails =
  (activityId, data, callBack) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const resp = await updateBasicAssessment(activityId, data);
      // dispatch(getAssessmentDetails(assessmentId));
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(fetchUpdatedAssessment(resp.data.activity));
      dispatch(setAssessmentUnitId(data.unitId));
      if (callBack) {
        callBack();
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const getAssessmentSummary = activityId => async dispatch => {
  dispatch(setAssessmentLoading(true));
  dispatch(setAssessmentError(false));
  dispatch(setAssessmentSuccess(false));
  try {
    const resp = await getAssessmentSummaryDetails(activityId);
    dispatch(fetchAssessmentSummary(resp.data.assessmentSummary));
  } catch (err) {
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(true));
    dispatch(setAssessmentSuccess(false));
  }
};

export const evaluateActivityItem =
  (assessmentID, classID, taskID, studentID, data, callback) =>
  async dispatch => {
    try {
      await evaluateTask(taskID, studentID, data);
      dispatch(getAssessmentDetails(assessmentID, classID));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const evaluateActivity =
  (assessmentID, aouiID, classID, studentID, data) => async dispatch => {
    try {
      await evaluateAssessment(aouiID, studentID, data);
      dispatch(getAssessmentDetails(assessmentID, classID));
    } catch (error) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

// This will trigger only on the published assessment. We can get the details published assessment details
export const getDulipatedAssessement =
  (activityId, classId, history) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const resp = await assessmentDuplicatedData(classId, activityId);
      const duplicatedActivityId = resp.data.newAssessment.ac_id_activity;
      const activityOrgUnitInstanceId =
        resp.data.newAssessment.aoui_id_activityorgunitinstance;
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(setActivityId(duplicatedActivityId));
      dispatch(activityUnitInstIdSetter(activityOrgUnitInstanceId));
      dispatch(fetchCreatedAssessment(resp.data.newAssessment));
      // only store if tasks are not deleted
      const availableTask = resp.data.newAssessment.tasks.filter(
        task => task.ai_is_deleted !== 1,
      );
      availableTask.forEach(task => {
        dispatch(setAssessmentTask(task));
      });
      // only store if files are not deleted
      const availableFiles = resp.data.newAssessment.files.filter(
        file => file.fi_is_deleted !== 1,
      );
      availableFiles.forEach(file => {
        dispatch(setAssessmentFiles(file));
      });
      if (history) {
        history.replace(
          `/stream/${classId}/assessment/edit/${duplicatedActivityId}/#details`,
        );
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
    dispatch(fetchSearchAssessment([]));
    dispatch(setSearchField(''));
    dispatch(setSearchLoading(false));
    dispatch(setSearchError(false));
  };
export const myAssessementAddToClass =
  (activityId, classId, history) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const resp = await myAssessmentToclass(classId, activityId);
      const assignedActivityId = resp.data.newAssessment.ac_id_activity;

      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));

      // only store if tasks are not deleted

      if (history) {
        history.replace(
          `/stream/${classId}/assessment/edit/${assignedActivityId}/#details`,
        );
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
    dispatch(fetchSearchAssessment([]));
    dispatch(setSearchField(''));
    dispatch(setSearchLoading(false));
    dispatch(setSearchError(false));
  };

export const onDeletingAssessmentTask =
  (activityItemId, isQueued, callback) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      if (!isQueued) {
        const resp = await deleteAssessmentTask(activityItemId);
        if (resp.data.draftTask) {
          dispatch(setDraftTask(resp.data.draftTask));
        }
      }

      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));

      if (!Array.isArray(activityItemId)) {
        dispatch(deleteSelectedTaskItem(activityItemId));
      }
      if (callback) {
        callback();
      }
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const onDeletingAssessmentFile = fileId => async dispatch => {
  dispatch(setAssessmentLoading(true));
  dispatch(setAssessmentError(false));
  dispatch(setAssessmentSuccess(false));
  try {
    const resp = await deleteAssessmentFile(fileId);
    dispatch(setAssessmentSuccess(true));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(false));
    if (resp.data.status) {
      dispatch(deleteSelectedFile(fileId));
    }
  } catch (err) {
    dispatch(setAssessmentSuccess(false));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(true));
  }
};

export const getListAssessmentStudents =
  (unitId, activityOrgUnitInstanceId) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const resp = await listAssessmentStudents(
        unitId,
        activityOrgUnitInstanceId,
      );
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(getAssessmentStudents(resp.data.allStudents));
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const addAllAssessmentStudents =
  (unitId, activityOrgunitInstanceId) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      await assessmentAddAllStudents(unitId, activityOrgunitInstanceId);
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(getListAssessmentStudents(unitId, activityOrgunitInstanceId));
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const addAssessmentStudent =
  (activityOrgUnitInstanceId, unitId, data) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      await assessmentAddStudent(activityOrgUnitInstanceId, data);
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(getListAssessmentStudents(unitId, activityOrgUnitInstanceId));
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const removeAssessmentStudent =
  (orgunitinstancestudentId, unitId, activityOrgUnitInstanceId) =>
  async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      await assessmentRemoveStudent(orgunitinstancestudentId);
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(getListAssessmentStudents(unitId, activityOrgUnitInstanceId));
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const addAssessmentStudents =
  (activityOrgUnitInstanceId, unitId, data) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      await assessmentAddStudents(activityOrgUnitInstanceId, data);
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(getListAssessmentStudents(unitId, activityOrgUnitInstanceId));
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const deleteAssessment = (aouiID, callback) => async dispatch => {
  dispatch(setAssessmentError(false));
  dispatch(setAssessmentLoading(true));
  dispatch(setAssessmentSuccess(false));
  try {
    await api.deleteAssessment(aouiID);

    if (callback) {
      callback(aouiID);
    } else {
      dispatch(deleteAssessmentFromList(aouiID));
    }

    dispatch(setAssessmentError(false));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentSuccess(true));
  } catch (error) {
    dispatch(setAssessmentError(true));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentSuccess(false));
  }
};

export const setAssessmentTitle =
  (classId, history) => async (dispatch, getState) => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    const {searchTerm: title} = getState().assessment;
    try {
      const resp = await createAssessmentByTitle(classId, {title});
      const activityId = resp.data.activity.ac_id_activity;
      const activityOrgUnitInstanceId =
        resp.data.activityOrgunitInstance.aoui_id_activityorgunitinstance;
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(fetchCreatedAssessment(resp.data.activity));
      dispatch(setActivityId(activityId));
      dispatch(activityUnitInstIdSetter(activityOrgUnitInstanceId));
      dispatch(setSearchField('')); // cleaning searchField
      dispatch(
        addAllAssessmentStudents(
          resp.data.activityOrgunitInstance.aoui_id_orgunitinstance,
          activityOrgUnitInstanceId,
        ),
      );

      history.replace(
        `/stream/${classId}/assessment/create/${activityId}/#details`,
      );
    } catch (err) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
    dispatch(fetchSearchAssessment([]));
    dispatch(setSearchField(''));
    dispatch(setSearchLoading(false));
    dispatch(setSearchError(false));
  };

export const removeAssessmentStudents =
  (data, unitId, activityOrgUnitInstanceId) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      await assessmentRemoveStudents(data);
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(getListAssessmentStudents(unitId, activityOrgUnitInstanceId));
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const removeAllAssessmentStudent =
  (unitId, activityOrgUnitInstanceId, noFetch) => async dispatch => {
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      await assessmentRemoveAllStudent(activityOrgUnitInstanceId);
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));

      if (!noFetch) {
        dispatch(getListAssessmentStudents(unitId, activityOrgUnitInstanceId));
      }
    } catch (err) {
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const onAssessmentPublish = (activityId, classId) => async dispatch => {
  dispatch(setAssessmentLoading(true));
  dispatch(setAssessmentError(false));
  dispatch(setAssessmentSuccess(false));
  try {
    await assessmentPublish(activityId, classId);
    dispatch(setAssessmentSuccess(true));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(false));
  } catch (err) {
    dispatch(setAssessmentSuccess(false));
    dispatch(setAssessmentLoading(false));
    dispatch(setAssessmentError(true));
  }
};

// This for getting all assessment
export const gettingAllAssessmentsDetails =
  (classId, offset, callback) => async (dispatch, getState) => {
    if (offset) {
      dispatch(setIsPaginating(true));
    } else {
      dispatch(setAssessmentLoading(true));
    }

    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const {assessment} = getState();
      const resp = await getAllAssessmentDatas(classId, offset || 0);

      if (offset) {
        dispatch(
          fetchAllAssessmentData([
            ...assessment.getAllAssessments,
            ...resp.data.assessments,
          ]),
        );
      } else {
        dispatch(fetchAllAssessmentData(resp.data.assessments));
      }

      dispatch(setAssessmentCount(resp.data.assessmentCount));

      if (offset) {
        dispatch(setIsPaginating(false));
      } else {
        dispatch(setAssessmentLoading(false));
      }

      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentError(false));

      if (callback) {
        callback();
      }
    } catch (err) {
      if (offset) {
        dispatch(setIsPaginating(false));
      } else {
        dispatch(setAssessmentLoading(false));
      }

      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentError(true));
    }
  };

export const reassignAssessment =
  (aouisID, assessmentID, classID, comments) => async dispatch => {
    try {
      await reassignActivity(aouisID, comments);
      dispatch(getAssessmentDetails(assessmentID, classID));
    } catch (error) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const resubmitAssessment =
  (aouisID, assessmentID, classID, resubmissionStatus, feedBack, callBack) =>
  async dispatch => {
    try {
      const resubmissionDetails = {
        resubmissionStatus,
        feedBack,
      };
      if (resubmissionStatus === 6) {
        await declineResubmissionRequest(aouisID, resubmissionDetails);
      }
      if (resubmissionStatus === 1) {
        await acceptResubmissionRequest(aouisID, resubmissionDetails);
      }
      if (callBack) {
        callBack();
      }
      dispatch(getAssessmentDetails(assessmentID, classID));
    } catch (error) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const revokeStudent =
  (aouisID, assessmentID, classID) => async dispatch => {
    try {
      await revokeStudentFromAssessment(aouisID);
      dispatch(getAssessmentDetails(assessmentID, classID));
    } catch (error) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const sendReminder =
  (aouisID, assessmentID, classID) => async dispatch => {
    try {
      await sendReminderToStudent(aouisID);
      dispatch(getAssessmentDetails(assessmentID, classID));
    } catch (error) {
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
      dispatch(setAssessmentSuccess(false));
    }
  };

export const fetchAssessmentTasksOfStudent =
  (assessmentID, studentID) => async dispatch => {
    try {
      dispatch(setAssessmentTasksLoading(true));
      const {data} = await getAssessmentTasksOfStudent(assessmentID, studentID);
      dispatch(setAssessmentTasks(data.assessmentTasks));
    } catch (error) {
      dispatch(setAssessmentTasksError(error.message));
    } finally {
      dispatch(setAssessmentTasksLoading(false));
    }
  };

export const commentOnAssessment =
  (activityId, comment, callback) => async dispatch => {
    const {data} = await commentOnActivity(activityId, comment);
    dispatch(
      setComment({
        activityId,
        comment: data.comment,
      }),
    );

    if (callback) {
      callback();
    }
  };
export const assessmentCommentDelete =
  (commentId, activityId, callback) => async dispatch => {
    const {data} = await deleteComment(commentId);
    dispatch(deleteCommentById({commentId: data.commentId, activityId}));
    if (callback) {
      callback();
    }
  };

export const getMoreComments = (activityId, offset) => async dispatch => {
  const {data} = await getComments(activityId, offset);
  dispatch(
    setComments({
      activityId,
      comments: data.comments,
    }),
  );
};

export const addFileInstruction =
  (activityID, taskID, body, callback, idOfTaskToEdit) => async dispatch => {
    const {data} = await api.addFileInstruction(activityID, taskID, body);
    if (callback) callback(data.linkedFile.fl_id_filelink);
    dispatch(
      setLinkedFile({
        file: data.linkedFile,
        taskId: idOfTaskToEdit,
      }),
    );
  };

export const addTextInstruction =
  (activityID, taskID, textInstruction, callback, idOfTaskToEdit) =>
  async dispatch => {
    const {data} = await api.addTextInstruction(
      activityID,
      taskID,
      textInstruction,
    );

    if (callback) {
      callback(data.linkedFile.fl_id_filelink);
    }

    dispatch(
      setLinkedFile({
        file: data.linkedFile,
        taskId: idOfTaskToEdit,
      }),
    );
  };

export const removeInstruction =
  (taskID, fileID, idOfTaskToEdit, callback) => async dispatch => {
    await api.removeInstruction(fileID);
    dispatch(
      clearTaskInstruction({
        taskID,
        fileID,
        idOfTaskToEdit,
      }),
    );

    if (callback) {
      callback();
    }
  };

export const removeAllInstructions = taskID => async dispatch => {
  await api.removeAllInstructions(taskID);
  dispatch(clearAllTaskInstructions());
};

export const updateTask =
  (taskID, body, isPublished, isEdit, callback) => async dispatch => {
    dispatch(setAssessmentTasksLoading(true));
    dispatch(setAssessmentLoading(true));
    dispatch(setAssessmentError(false));
    dispatch(setAssessmentSuccess(false));
    try {
      const {data} = await api.updateTask(taskID, {...body, isPublished});
      dispatch(setDraftTask(null));
      dispatch(setAssessmentTasksLoading(false));
      dispatch(setAssessmentSuccess(true));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(false));
      dispatch(setAssessmentTask({...data.task, isEdit}));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(setAssessmentTasksLoading(false));
      dispatch(setAssessmentTasksError(error.message));
      dispatch(setAssessmentSuccess(false));
      dispatch(setAssessmentLoading(false));
      dispatch(setAssessmentError(true));
    }
  };

export const updateInstruction =
  (fileID, body, taskId, callback, idOfTaskToEdit) => async dispatch => {
    const {data} = await api.updateInstruction(fileID, body);
    dispatch(
      updateLinkedFile({
        file: data.linkedFile.file,
        linkId: data.linkedFile.fl_id_filelink,
        taskId: idOfTaskToEdit,
      }),
    );

    if (callback) {
      callback(fileID, data.linkedFile);
    }
  };

export const getAllActivityMethods = () => async dispatch => {
  try {
    const resp = await fetchAllActivityMethods();
    dispatch(setAssessmentType(resp.data.allActivityMethods));
  } catch (err) {
    dispatch(setAssessmentError(true));
  }
};

export const getAllEvaluationType = () => async dispatch => {
  try {
    const resp = await fetchAllEvaluationType();
    dispatch(setEvaluationType(resp.data.allEvaluationType));
  } catch (err) {
    dispatch(setAssessmentError(true));
  }
};

export const fetchTasksLinkedToFiles =
  (fileIds, assessmentId, studentId) => async dispatch => {
    try {
      const {data} = await api.getTasksLinkedToFiles(
        fileIds,
        assessmentId,
        studentId,
      );
      dispatch(setTasksLinkedToFiles(data.tasksLinkedToFiles));
    } finally {
      dispatch(setIsLoadingTasksLinkedToFiles(false));
    }
  };

export const getPerformanceCriteria = orgUnitInstanceId => async dispatch => {
  const {
    data: {
      unitDetails: {
        orgUnit: {
          unit: {tun_id_tgovunit: unitId},
        },
      },
    },
  } = await api.getUnit(orgUnitInstanceId);
  const {
    data: {
      assessmentMap: {elements: performanceCriteria},
    },
  } = await api.getPerformanceCriteria(unitId);
  dispatch(setPerformanceCriteria(performanceCriteria));
};

/** Fetch the performance evidence of a unit. */
export const fetchPerformanceEvidence = orgUnitInstanceId => async dispatch => {
  const resp = await api.getPerformanceEvidence(orgUnitInstanceId);
  dispatch(setPerformanceEvidences(resp.data.performanceEvidence));
};

/** Fetch the knowledge evidence of a unit. */
export const fetchKnowledgeEvidence = orgUnitInstanceId => async dispatch => {
  const resp = await api.getKnowledgeEvidence(orgUnitInstanceId);
  dispatch(setKnowledgeEvidences(resp.data.knowledgeEvidence));
};

/** Fetch the knowledge evidence of a unit. */
export const fetchAssessmentCondition = orgUnitInstanceId => async dispatch => {
  const resp = await api.getAssessmentCondition(orgUnitInstanceId);
  dispatch(setAssessmentConditions(resp.data.assessmentCondition));
};

export default assessmentSlice.reducer;
