import styled from 'styled-components';

const LearningResourcesFormContainer = styled.div`
  .serial {
    width: 4.3%;
  }

  .unit {
    width: 39%;
  }

  .learning-resource {
    width: 33%;
  }

  .assessment-condition {
    width: 23.7%;
  }
`;

export default LearningResourcesFormContainer;
