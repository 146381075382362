/* eslint react/prop-types: 0 */

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Redirect, useHistory} from 'react-router-dom';
import {authSelector, fetchProfile} from './redux/authSlice';
import {getProfile} from './pages/AuthOld/authSlice';

/** checks and returns the token in the localstorage */
const isAuthenticated = () => {
  const token = localStorage.getItem('jwtToken');
  return token;
};

export const PrivateRoute = ({
  component: Component,
  isAdmin,
  isTeacher,
  ...rest
}) => {
  const token = React.useRef(isAuthenticated()).current;
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const authOldState = useSelector(state => state.authOld);

  React.useEffect(() => {
    if (token && !authOldState.isLoggedIn) {
      dispatch(getProfile(token));
      dispatch(
        fetchProfile(() => {
          history.push('/login');
        }),
      );
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authOldState.isLoggedIn, token]);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (token) {
          if (isAdmin) {
            if (localStorage.getItem('jc-user-type') === '4') {
              return <Component {...props} />;
            }

            return (
              <Redirect
                to={{
                  pathname: '/classes',
                  state: {from: props.location},
                }}
              />
            );
          }
          if (isTeacher) {
            if (['2', '3'].includes(localStorage.getItem('jc-user-type'))) {
              return <Component {...props} />;
            }

            return (
              <Redirect
                to={{
                  pathname: '/admin',
                  state: {from: props.location},
                }}
              />
            );
          }
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location},
            }}
          />
        );
      }}
    />
  );
};

export const LoginRoute = ({component: Component, ...rest}) =>
  isAuthenticated() ? (
    <Redirect to={{pathname: '/classes'}} />
  ) : (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  );

export const RestrictedRoute = ({
  component: Component,
  preferenceName,
  ...rest
}) => {
  const authState = useSelector(authSelector);
  const token = React.useRef(isAuthenticated()).current;
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPreference, setIsPreference] = React.useState(true);

  React.useEffect(() => {
    if (!authState.isLoading) {
      setIsLoading(false);
    }
  }, [authState.isLoading]);

  React.useEffect(() => {
    if (token && authState) {
      const showTasPreferences = authState?.orgPreferences?.find(
        item => item.pr_slug === preferenceName && Number(item.pr_value) === 1,
      );
      if (showTasPreferences) {
        setIsPreference(true);
      } else {
        setIsPreference(false);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, token]);

  if (isLoading) {
    return null;
  }

  return !isPreference ? (
    <Redirect
      to={{
        pathname: '/PageNotFound',
        state: {from: rest.location},
      }}
    />
  ) : (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  );
};
