/* eslint-disable react/prop-types */

import React, {useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import ReactBootstrapModal from 'react-bootstrap/Modal';

import {FilesCard} from '../../../FilesCard';
import CommentInput from '../../../../../../components/shared/Inputs/commentInput';
import PreviewFile from '../../../../../../components/shared/PreviewFile';
import {getFileType} from '../../../../../../utils/fileTypes';
import {
  commentOnPost,
  getMoreComments,
} from '../../../../../../redux/streamSlice';
import {CommentsSection} from '../../../CommentsSection';

import {Hr} from '../../../../../../components/shared/Hr';
import {CommentList} from '../../../../elements';
import PostBodyContainer from './elements';
import {Color} from '../../../../../../constants';

function parseUrls(text) {
  const regularExpression = /(https?:\/\/[^\s]+)/g;
  return text.split(regularExpression).map((url, index) => {
    if (url.match(regularExpression)) {
      return (
        <a
          href={url}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          target="_blank"
          rel="noreferrer"
          style={{
            color: Color.PRIMARY,
          }}
        >
          {url}
        </a>
      );
    }

    return url;
  });
}

export const PostBody = ({
  activity,
  activityId,
  commentCount,
  comments,
  files,
  profileName,
  time,
  isArchived,
}) => {
  const timeAgo = time && moment(time).fromNow();
  const [fileClickInfo, setFileClickInfo] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [comment, setComment] = useState('');
  const [commentLength, setCommentLength] = useState(commentCount);
  const [offset, setOffset] = useState(comments?.length || 0);
  const dispatch = useDispatch();
  const formattedFiles = useMemo(
    () =>
      (files || []).map(file => {
        const fileName = file.fi_filename;
        const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);
        let fileType;
        if (file.fi_id_filetype === 2) fileType = 'video';
        return {
          name: file.fi_filename,
          size: file.fi_filesize,
          type: fileType || getFileType(fileExt),
          location: file.fi_location,
          s3Name: file.fi_S3_filename,
          thumbnail: file.fi_thumbnail,
          uploaded: true,
        };
      }),
    [files],
  );

  const postComment = event => {
    if (event.keyCode === 13) {
      setComment('');
      dispatch(
        commentOnPost(activityId, comment, () => {
          setCommentLength(previousValue => previousValue + 1);
          setOffset(previousValue => previousValue + 1);
        }),
      );
    }
  };

  const onCardClick = (flLocation, flType, s3Name, fileName) => {
    setFileClickInfo([flType, flLocation, s3Name, fileName]);
    setModalShow(true);
  };

  return (
    <div className="post-body">
      <div className="post-body-content">
        {files?.length === 0 ? (
          <>
            <div className="post-body-content mb-3">{parseUrls(activity)}</div>
          </>
        ) : (
          <>
            <div className="post-body-content">{parseUrls(activity)}</div>
            <FilesCard
              files={formattedFiles}
              className="mt-2"
              onCardClick={onCardClick}
            />
            <ReactBootstrapModal
              centered
              onHide={() => {}}
              show={modalShow}
              contentClassName="preview-post-file-modal"
            >
              <PreviewFile
                profileName={profileName}
                time={timeAgo}
                fileType={fileClickInfo[0]}
                fileUrl={fileClickInfo[1]}
                fileS3Name={fileClickInfo[2]}
                fileName={fileClickInfo[3]}
                setModalShow={setModalShow}
              />
            </ReactBootstrapModal>
          </>
        )}
      </div>
      {!isArchived ? (
        <PostBodyContainer className="post-body-comment">
          <Hr className="divider-body" />
          <CommentInput
            onChange={event => setComment(event.target.value)}
            onKeyUp={postComment}
            height="auto"
            placeholder="Write a Comment..."
            value={comment}
            width="100%"
            className="post-comment-input"
            id="post-comment-input-id"
          />
        </PostBodyContainer>
      ) : null}
      {comments?.length ? (
        <CommentList>
          <p className="comment-count">
            {commentLength} Comment{commentLength > 1 ? 's' : ''}
          </p>
          {comments.map((item, index) => (
            <CommentsSection
              key={item.acc_id_activity_comment}
              item={item}
              isStream
              index={index}
              activityId={activityId}
              setCommentLength={setCommentLength}
              commentLength={commentLength}
              userName={item?.user?.user?.us_profile_name}
            />
          ))}
          {comments?.length < commentLength ? (
            <div className="view-more">
              <button
                className="view-more-button"
                onClick={() => {
                  dispatch(getMoreComments(activityId, offset));
                  setOffset(previousValue => previousValue + 10);
                }}
                type="button"
                id="view-more-btn"
              >
                View more comments
              </button>
            </div>
          ) : null}
        </CommentList>
      ) : null}
    </div>
  );
};
