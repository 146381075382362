/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import {LibraryCardContainer, FileContainer} from './elements';
import {MoreOptions} from '../MoreOptions';
import {DropdownItem} from '../MoreOptions/components/DropdownItem';
import PlayVideoIcon from '../../../assets/icons/play-video-circle.svg';
import DocIcon from '../../../assets/images/filetypes/doc.svg';
import PdfIcon from '../../../assets/images/filetypes/pdf.svg';
import VideoIcon from '../../../assets/images/filetypes/video.svg';
import CsvIcon from '../../../assets/images/filetypes/csv.svg';
import ImgIcon from '../../../assets/images/filetypes/img.svg';
import {getFileType} from '../../../utils/fileTypes';
import AddedVoiceInstructionIcon from '../../../assets/icons/added-voice-instruction.svg';
import DowloadLibraryFile from '../../../assets/icons/download-library-file.svg';
import ImageBadgeIcon from '../../../assets/icons/image-icon.svg';
import VideoBadgeIcon from '../../../assets/icons/video-icon.svg';
import DocumentBadgeIcon from '../../../assets/icons/document-icon.svg';

const fileTypeToImgMap = {
  'application/pdf': PdfIcon,
  'application/msword': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    DocIcon,
  'text/csv': CsvIcon,
  // Images
  'image/jpeg': ImgIcon,
  'image/png': ImgIcon,
  'image/jpg': ImgIcon,
  'image/svg': ImgIcon,
  'image/svg+xml': ImgIcon,
  'image/gif': ImgIcon,
  'image/webp': ImgIcon,
  'image/tiff': ImgIcon,
  'image/psd': ImgIcon,
  'image/raw': ImgIcon,
  'image/bmp': ImgIcon,
  'image/heif': ImgIcon,
  'image/indd': ImgIcon,
  'image/jpeg 2000': ImgIcon,
  // Video
  'video/webm': VideoIcon,
  'video/mp4': VideoIcon,
  'video/mp2': VideoIcon,
  'video/mpeg': VideoIcon,
  'video/mpe': VideoIcon,
  'video/mpv': VideoIcon,
  'video/ogg': VideoIcon,
  'video/3gp': VideoIcon,
  'video/m4p': VideoIcon,
  'video/m4v': VideoIcon,
  'video/avi': VideoIcon,
  'video/wmv': VideoIcon,
  'video/mov': VideoIcon,
  'video/qt': VideoIcon,
  'video/flv': VideoIcon,
  'video/swf': VideoIcon,
  unknown: DocIcon,
};

const fileTypeBadgeIcon = {
  'application/pdf': DocumentBadgeIcon,
  'application/msword': DocumentBadgeIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    DocumentBadgeIcon,
  'text/csv': DocumentBadgeIcon,
  // Images
  'image/jpeg': ImageBadgeIcon,
  'image/png': ImageBadgeIcon,
  'image/jpg': ImageBadgeIcon,
  'image/svg': ImageBadgeIcon,
  'image/svg+xml': ImageBadgeIcon,
  'image/gif': ImageBadgeIcon,
  'image/webp': ImageBadgeIcon,
  'image/tiff': ImageBadgeIcon,
  'image/psd': ImageBadgeIcon,
  'image/raw': ImageBadgeIcon,
  'image/bmp': ImageBadgeIcon,
  'image/heif': ImageBadgeIcon,
  'image/indd': ImageBadgeIcon,
  'image/jpeg 2000': ImageBadgeIcon,
  // Video
  'video/webm': VideoBadgeIcon,
  'video/mp4': VideoBadgeIcon,
  'video/mp2': VideoBadgeIcon,
  'video/mpeg': VideoBadgeIcon,
  'video/mpe': VideoBadgeIcon,
  'video/mpv': VideoBadgeIcon,
  'video/ogg': VideoBadgeIcon,
  'video/3gp': VideoBadgeIcon,
  'video/m4p': VideoBadgeIcon,
  'video/m4v': VideoBadgeIcon,
  'video/avi': VideoBadgeIcon,
  'video/wmv': VideoBadgeIcon,
  'video/mov': VideoBadgeIcon,
  'video/qt': VideoBadgeIcon,
  'video/flv': VideoBadgeIcon,
  'video/swf': VideoBadgeIcon,
  unknown: DocumentBadgeIcon,
};

export const LibraryCard = ({
  className,
  width,
  height,
  fileName,
  uploadedDate,
  fileLocation,
  fileType,
  thumbnail,
  onClick,
  data,
  deleteOnClick,
  editOnClick,
  fileTitle,
  isPublic,
  fileCode,
  unitAndClassName,
  isAdmin,
  fileCategory,
  isStudent,
  isArchive,
  id,
  ...rest
}) => {
  let title;
  let fileUploadedDate;
  let thumbnailImg;
  let fileTypeIcon;

  const fileExt = fileName?.substring(fileName?.lastIndexOf('.') + 1) || '';

  if (fileType === 'Voice') {
    thumbnailImg = thumbnail || AddedVoiceInstructionIcon;
    fileTypeIcon = fileTypeBadgeIcon[getFileType(fileExt)];
    title = fileTitle;
    fileUploadedDate = moment(uploadedDate).format('D MMM YYYY');
  } else if (fileType === 'Document') {
    thumbnailImg = thumbnail || fileTypeToImgMap[getFileType(fileExt)];
    fileTypeIcon = fileTypeBadgeIcon[getFileType(fileExt)];
    title = fileTitle;
    fileUploadedDate = moment(uploadedDate).format('D MMM YYYY');
  } else if (fileType === 'Image') {
    thumbnailImg = thumbnail || fileLocation;
    fileTypeIcon = fileTypeBadgeIcon[getFileType(fileExt)];
    title = fileTitle;
    fileUploadedDate = moment(uploadedDate).format('D MMM YYYY');
  } else if (fileType === 'Video') {
    thumbnailImg = thumbnail || fileTypeToImgMap[getFileType(fileExt)];
    fileTypeIcon = fileTypeBadgeIcon[getFileType(fileExt)];
    title = fileTitle;
    fileUploadedDate = moment(uploadedDate).format('D MMM YYYY');
  }
  return (
    <LibraryCardContainer
      className={classNames('flex-shrink-0', className)}
      {...rest}
    >
      <FileContainer height={height} id={id}>
        <button className="file-content" onClick={onClick} type="button">
          <div className="file-container">
            {fileType === 'Video' ? (
              <div className="file-blob">
                <img
                  alt="Instruction"
                  className="thumnail-icon"
                  src={thumbnailImg}
                />
                <div className="play-video-icon">
                  <img
                    alt="Play Video"
                    src={PlayVideoIcon}
                    className="play-video"
                  />
                </div>
                <div className="file-type">
                  <div className="icon-badge">
                    <img src={fileTypeIcon} alt="" />
                  </div>
                  <div className="badge">
                    <span>{fileCategory}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="file-blob">
                <img src={thumbnailImg} alt="" className="files" width="100%" />
                <div className="file-type">
                  <div className="icon-badge">
                    <img src={fileTypeIcon} alt="" />
                  </div>
                  <div className="badge">
                    <span>{fileCategory}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* {fileType === 'Docuement' ? (
              <object
                data={thumbnailImg}
                width="100%"
                height="40.375rem"
                style={{marginBottom: '-0.4375rem'}}
                type="application/pdf"
              >
                <embed src={fileLocation} type="application/pdf" width="100%" />
              </object>
            ) : null} */}
        </button>
        <div className="card-content">
          <div className="card-detail">
            {' '}
            <div className="card-left">{title}</div>
            {!isPublic && !isStudent && !isArchive ? (
              <div className="card-right">
                <MoreOptions id="more-option">
                  <DropdownItem label="Edit" onClick={editOnClick} id="edit" />
                  <DropdownItem
                    isDanger
                    label="Delete"
                    onClick={deleteOnClick}
                    id="delete"
                  />
                </MoreOptions>
              </div>
            ) : null}
            {isStudent ? (
              <div className="card-right">
                <img
                  src={DowloadLibraryFile}
                  alt="Download"
                  className="download-file"
                  width="100%"
                />
              </div>
            ) : null}
          </div>

          <div className="card-detail-bottom">
            {fileCode ? <div className="code">{fileCode} </div> : null}
            {isAdmin ? (
              <div className="class-name">• {unitAndClassName}</div>
            ) : null}
            <div className="date"> • {fileUploadedDate}</div>
          </div>
        </div>
      </FileContainer>
    </LibraryCardContainer>
  );
};
LibraryCard.defaultProps = {
  className: '',
  height: '',
  width: '',
  thumbnail: '',
  onClick: () => {},
  deleteOnClick: () => {},
  editOnClick: () => {},
  data: {},
  isPublic: false,
  unitAndClassName: '',
  isAdmin: false,
  fileCategory: '',
  fileName: '',
  uploadedDate: '',
  fileLocation: '',
  fileType: '',
  fileTitle: '',
  fileCode: '',
  isStudent: false,
  isArchive: false,
  id: '',
};

LibraryCard.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fileName: PropTypes.string,
  uploadedDate: PropTypes.string,
  fileLocation: PropTypes.string,
  fileType: PropTypes.string,
  thumbnail: PropTypes.string,
  onClick: PropTypes.func,
  deleteOnClick: PropTypes.func,
  editOnClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  fileTitle: PropTypes.string,
  fileCode: PropTypes.string,
  unitAndClassName: PropTypes.string,
  isPublic: PropTypes.bool,
  isAdmin: PropTypes.bool,
  fileCategory: PropTypes.string,
  isStudent: PropTypes.bool,
  isArchive: PropTypes.bool,
  id: PropTypes.string,
};
