import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../../../elements-new';

export const AssessmentsListContainer = styled(AdminListingPageContainer)`
  .serial {
    width: 5%;
  }
  .assessment-name {
    width: 35%;
  }
  .timestamp_creation {
    width: 20%;
  }
  .teacher-name {
    width: 20%;
  }

  .view {
    width: 20%;
  }
  .back-button {
    display: flex;
    margin-bottom: 1.812rem;
    img {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.1275rem;
      vertical-align: middle;
    }

    span {
      font-size: 0.875rem;
      color: #8a8c94;
      margin-left: 0.6875rem;
    }
  }
  .view-assessment {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.312rem;
    padding: 0.75rem 1rem;
    color: #40a1ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
    .viewassessment-arrow {
      margin-left: 0.75rem;
      vertical-align: middle;
    }
  }
  .unit-name {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    color: #3d4457;
  }
  .unit-code {
    font-weight: normal;
    font-size: 0.875rem;
    color: #8a8c94;
    text-transform: capitalize;
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
`;
