import styled from 'styled-components';

import {Color} from '../../../../../constants';

const VideoModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 62%;
  @media (max-width: 43.75rem) {
    & {
      overflow-y: scroll;
      height: 100%;
      width: 87% !important;
      padding-bottom: 10%;
      ::-webkit-scrollbar {
        display: none;
      }
      .video-modal-custom-full {
        width: 100% !important;
        margin-top: 7%;
        .video-modal-close {
          display: block;
        }
        .modal-close-button {
          display: none;
        }
        .close-btn-video {
          margin-bottom: 0.375rem;
          display: flex;
          justify-content: flex-end;
          button {
            background-color: transparent;
            border: none;
            padding: 0;

            :active,
            :focus {
              outline: none;
            }

            img {
              height: 1.5rem;
              vertical-align: unset;
              width: 1.5rem;
            }
          }
        }
      }
    }
  }

  .video-modal-close {
    display: none;
  }

  .video-modal-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0 1.5rem;

    h2 {
      color: ${Color.PRIMARY_TEXT};
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.21;
      margin-bottom: 0;
    }

    .modal-close-button {
      background-color: transparent;
      border: none;

      :focus,
      :active {
        outline: none;
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .video-modal-body {
    padding: 0 1.5rem;
    width: 100%;

    .video-container {
      background-color: #000;
      border-radius: 0.375rem;
      height: 62vh;
      overflow: hidden;
      position: relative;
      width: 100%;

      .video-record-time {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 2rem;
        display: flex;
        justify-content: space-between;
        left: 50%;
        padding: 0.5rem 0.75rem;
        position: absolute;
        top: 1rem;
        transform: translateX(-50%);
        width: 4.87rem;

        div {
          background-color: #d64727;
          border-radius: 50%;
          height: 0.5rem;
          width: 0.5rem;
        }

        span {
          color: #fff;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.21;
        }
      }

      .retake-video-button-container {
        left: 1.5rem;
        position: absolute;
        top: 1.5rem;
        z-index: 3000;

        .retake-video-button {
          align-items: center;
          background-color: rgba(61, 68, 87, 0.25);
          border: none;
          border-radius: 0.5rem;
          color: #fff;
          display: flex;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.21;
          padding: 0.5rem;
          transition: background-color 0.1s;

          &.is-toggled {
            background-color: rgba(61, 68, 87, 0.71);
          }

          :focus,
          :active {
            outline: none;
          }

          img {
            height: 1.5rem;
            width: 1.5rem;
          }

          span {
            margin-left: 0.5rem;
          }
        }

        .retake-confirmation {
          background-color: ${Color.PRIMARY_TEXT};
          border-radius: 0.3125rem;
          margin-top: 1rem;
          padding: 1rem 1rem 0.5rem 1rem;
          position: relative;

          .retake-confirmation-arrow {
            background-color: ${Color.PRIMARY_TEXT};
            height: 0.625rem;
            left: 1.31rem;
            position: absolute;
            top: -0.312rem;
            transform: rotate(45deg);
            width: 0.625rem;
          }

          p {
            color: #fff;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.21;
            margin-bottom: 1rem;
          }

          .retake-button-group {
            display: flex;
            justify-content: flex-end;

            button {
              background-color: transparent;
              border: none;
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.71;

              :focus,
              :active {
                outline: none;
              }
            }

            .retake-cancel-button {
              color: #aeaeae;
              margin-right: 1rem;
            }

            .retake-confirm-button {
              color: ${Color.PRIMARY};
            }
          }
        }
      }

      video {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      .video-modal-controls {
        align-items: center;
        background-color: rgba(61, 68, 87, 0.25);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        padding: 1.25rem 0 1.187rem 0;
        position: absolute;
        width: 100%;

        button {
          background-color: transparent;
          border: none;
          height: 3.5rem;
          width: 3.5rem;

          &.small-button {
            height: 2.5rem;
            width: 2.5rem;
          }

          :focus,
          :active {
            outline: none;
          }

          img {
            height: 100%;
            vertical-align: super !important;
            width: 100%;
          }
        }

        .stop-recording-button {
          margin: 0 1rem;
        }
      }
    }
  }

  .video-modal-footer {
    margin-top: 1.5rem;

    .video-title {
      padding: 0 1.5rem;
    }

    .video-instruction-buttons {
      align-items: center;
      border-top: 0.0625rem solid #f2f2f4;
      display: flex;
      justify-content: flex-end;
      margin-top: 1.5rem;
      padding: 1.5rem 1.5rem 0 1.5rem;

      .cancel-button {
        background-color: transparent;
        border: none;
        color: ${Color.PRIMARY_TEXT};
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.625;
        margin-right: 2.5rem;

        :focus,
        :active {
          outline: none;
        }
      }
    }
  }
`;

export default VideoModalContainer;
