import {createSlice} from '@reduxjs/toolkit';

import * as curriculumApi from '../api/admin/curriculum';

const initialFormError = {moduleName: ''};

const curriculumSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: false,
    isLoading: false,
    courses: null,
    courseCurriculum: null,
    isAddCurriculumLoading: false,
    formError: initialFormError,
    curriculumFile: null,
  },
  name: 'curriculum',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCourses(state, action) {
      state.courses = action.payload;
    },
    setCourseCurriculum(state, action) {
      state.courseCurriculum = action.payload;
    },
    setIsAddCurriculumLoading(state, action) {
      state.isAddCurriculumLoading = action.payload;
    },

    removeCourseCurriculumModuleById(state, action) {
      state.courseCurriculum.OrgCourseCurriculum.OrgCourseCurriculumModules =
        state.courseCurriculum.OrgCourseCurriculum.OrgCourseCurriculumModules.filter(
          item => item.ocrm_id_course_curriculum_module !== action.payload,
        );
    },

    removeCourseCurriculumFileById(state, action) {
      // first we have find index of curriculum mudule
      const curriculumModuleIndex =
        state.courseCurriculum.OrgCourseCurriculum.OrgCourseCurriculumModules.findIndex(
          item =>
            item.ocrm_id_course_curriculum_module === action.payload.moduleId,
        );

      // curriculum mudule files remove file by id
      const curriculumModule =
        state.courseCurriculum.OrgCourseCurriculum.OrgCourseCurriculumModules[
          curriculumModuleIndex
        ];
      curriculumModule.OrgCourseCurriculumFiles =
        curriculumModule.OrgCourseCurriculumFiles.filter(
          item =>
            item.ocrf_id_course_curriculum_file !== action.payload.moduleFileId,
        );
    },

    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setCurriculumFile(state, action) {
      state.curriculumFile = action.payload;
    },
    editCourse(state, action) {
      const courseIndex = state.courses.findIndex(
        item => item.ocr_id_orgcourse === action.payload.ocr_id_orgcourse,
      );

      state.courses[courseIndex] = action.payload;
    },
    removeClassById(state, action) {
      state.coursesClasses = state.coursesClasses.filter(
        item => item.oc_id_orgclass !== action.payload,
      );
    },
  },
});

export const {
  setError,
  setIsListLoading,
  setIsLoading,
  setCourses,
  setCourseCurriculum,
  setIsAddCurriculumLoading,
  removeCourseCurriculumModuleById,
  removeCourseCurriculumFileById,
  setFormError,
  clearFormError,
  setCurriculumFile,
  editCourse,
} = curriculumSlice.actions;

export const getAllCourses = () => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await curriculumApi.fetchAllCourses();

    dispatch(setCourses(response.data.allCourses));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};
export const getCurriculumByCourseId = courseId => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await curriculumApi.fetchCurriculumByCourseId(courseId);
    dispatch(setCourseCurriculum(response.data.allCurriculum));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const addCurriculumByCourseId =
  (courseId, data, callback) => async dispatch => {
    dispatch(setIsAddCurriculumLoading(true));
    try {
      const resp = await curriculumApi.addCurriculumByCourseId(courseId, data);

      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddCurriculumLoading(false));
    }
  };

export const deleteCurriculumModuleById =
  (courseId, moduleId, callback) => async dispatch => {
    dispatch(setIsAddCurriculumLoading(true));

    try {
      await curriculumApi.deleteCurriculumModuleById(courseId, moduleId);

      dispatch(removeCourseCurriculumModuleById(moduleId));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddCurriculumLoading(false));
    }
  };

export const editCurriculumModuleFileById =
  (courseId, fileId, editFileName, callback) => async dispatch => {
    dispatch(setIsAddCurriculumLoading(true));

    const data = {
      fileName: editFileName,
    };
    try {
      await curriculumApi.editCurriculumModuleFileById(courseId, fileId, data);

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddCurriculumLoading(false));
    }
  };
export const deleteCurriculumModuleFileById =
  (courseId, moduleId, moduleFileId, callback) => async dispatch => {
    dispatch(setIsAddCurriculumLoading(true));

    try {
      await curriculumApi.deleteCurriculumModuleFileById(
        courseId,
        moduleFileId,
      );

      dispatch(removeCourseCurriculumFileById({moduleId, moduleFileId}));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddCurriculumLoading(false));
    }
  };

export const findModuleNameAlreadyExisted =
  (courseId, moduleName, callback) => async dispatch => {
    dispatch(setIsAddCurriculumLoading(true));
    try {
      const moduleDetails = {
        moduleName,
      };
      const resp = await curriculumApi.findModuleNameAlreadyExisted(
        courseId,
        moduleDetails,
      );

      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );

      dispatch(setFormError(error?.response?.data?.error));
    } finally {
      dispatch(setIsAddCurriculumLoading(false));
    }
  };

export const getCurriculumFileByFileId =
  (courseId, curriculumFileId) => async dispatch => {
    dispatch(setIsLoading(true));
    try {
      const response = await curriculumApi.fetchCurriculumFileByFileId(
        courseId,
        curriculumFileId,
      );
      dispatch(setCurriculumFile(response.data.curriculumFile));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const curriculumSelector = state => state.curriculum;

export default curriculumSlice.reducer;
