/* eslint-disable react/prop-types */
import React from 'react';

import RecordSessionIcon from '../../../../../../../../assets/icons/record-session.svg';
import ScheduleSessionIcon from '../../../../../../../../assets/icons/schedule-session.svg';
import StartInstantSessionIcon from '../../../../../../../../assets/icons/start-live-session.svg';
import TransparentButton from '../TransparentButton';

export const NonRtoHeader = ({classId, history}) => (
  <div>
    <h2 className="title mb-1">Start Session</h2>
    <p className="description mb-3">
      You can schedule, record, or start a session instantly to this unit.
    </p>
    <TransparentButton
      className="mb-3"
      fullWidth
      icon={StartInstantSessionIcon}
      onClick={() => {
        if (classId) {
          history.push(`/stream/${classId}/sessions/create`);
        }
      }}
      text="Start Live Session"
    />
    <div className="row px-3">
      <TransparentButton
        className="col"
        icon={ScheduleSessionIcon}
        onClick={() => {
          if (classId) {
            history.push(`/stream/${classId}/sessions/schedule`);
          }
        }}
        text="Schedule"
      />
      <TransparentButton
        className="col ml-3"
        icon={RecordSessionIcon}
        onClick={() => {}}
        text="Record"
      />
    </div>
  </div>
);
