/* eslint-disable react/prop-types */

import {useEffect, useMemo, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {
  EmptyState,
  MoreOptions,
  Table,
  Modal,
  InvitationStatus,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {
  fetchAllAdminAndTeachers,
  adminTeachersSelector,
  deleteAdminTeacher,
  resendAdminTeacherInvitation,
} from '../../../../../../redux/adminTeacherSlice';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {AdminTeacherContainer} from './elements';
import UserLineIcon from '../../../../../../assets/icons/userline.svg';
import UserTickIcon from '../../../../../../assets/icons/usertick.svg';
import {EditAdminTeacherModal} from './components/EditAdminTeacherModal';
import {showToast} from '../../../../../../components/common/Toast';

const DELAY = 500;

export const AdminTeacher = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {adminTeachers, isListLoading, isAdminTeacherLoading, organisation} =
    useSelector(adminTeachersSelector);
  const [editDetails, setEditDetails] = useState(null);

  const {state} = useLocation();
  const searchEmailId = state?.emailId;

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');

  const [isSearch, setIsSearch] = useState('');
  useEffect(() => {
    if (searchEmailId) {
      setIsSearch(searchEmailId);
    }
  }, [searchEmailId]);

  useEffect(() => {
    dispatch(fetchAllAdminAndTeachers());
  }, [dispatch]);

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'ID',
        accessor: 'up_org_reference',
        className: 'ref-id',
      },
      {
        Header: 'First Name',
        accessor: 'up_name_first',
        className: 'first-name',
      },
      {
        Header: 'Last Name',
        accessor: 'up_name_last',
        className: 'last-name',
      },
      {
        Header: 'Email',
        accessor: 'up_email',
        className: 'email',
      },
      {
        Header: 'Admin',
        Cell: ({value}) => (
          <div>
            <img
              src={value.includes('4') ? UserTickIcon : UserLineIcon}
              alt="close"
            />
          </div>
        ),
        accessor: 'profiles',
        className: 'admin',
        id: 'admin',
      },
      {
        Header: 'Teacher',
        Cell: ({value}) => (
          <div>
            <img
              src={value.includes('3') ? UserTickIcon : UserLineIcon}
              alt="close"
            />
          </div>
        ),
        accessor: 'profiles',
        className: 'teacher',
        id: 'teacher',
      },
      {
        Header: 'Status',
        Cell: ({row: {original}, value}) => (
          <InvitationStatus
            value={value}
            time={original?.up_timestamp_creation}
          />
        ),
        accessor: 'up_invitation_status',
        className: 'status',
      },
      {
        Header: '',
        Cell: ({row: {original}, value}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit User"
                onClick={() => {
                  setEditDetails({
                    adminTeacherId: value,
                    firstName: original?.up_name_first,
                    lastName: original?.up_name_last,
                    email: original?.up_email,
                    referId: original?.up_org_reference,
                    profiles: original?.profiles,
                  });
                }}
              />
              {original?.up_invitation_status === 1 ? null : (
                <DropdownItem
                  label="Resend Invitation"
                  onClick={() => {
                    dispatch(
                      resendAdminTeacherInvitation(value, {
                        firstName: original?.up_name_first,
                        lastName: original?.up_name_last,
                        email: original?.up_email,
                        referId: original?.up_org_reference,
                      }),
                    );
                  }}
                />
              )}
              {organisation?.or_email !== original?.up_email ? (
                <DropdownItem
                  isDanger
                  label="Delete"
                  isDisabled={original?.classCount}
                  toolTipHeading="User Cannot be deleted"
                  toolTipSubText="Teacher is assigned in one or more class"
                  onClick={() => {
                    setDeleteId(value);
                    setDeleteName(
                      `${original?.up_name_first} ${original?.up_name_last}`,
                    );
                  }}
                />
              ) : null}
            </MoreOptions>
          </div>
        ),
        accessor: 'up_id_user',
        className: 'more-option',
      },
    ],
    [organisation?.or_email, dispatch],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(adminTeachers || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [adminTeachers],
  );

  return (
    <AdminTeacherContainer>
      <ListingPageHeader
        buttonLabel="Add User"
        className="mb-4"
        btnId="addUserBtn"
        onButtonClick={() => {
          history.push('/admin/users/admin/addAdmin');
        }}
        title="Admin & Teacher"
      />
      <div className="table-container">
        {isListLoading ? <TableSpinner /> : null}
        {!isListLoading &&
        Array.isArray(adminTeachers) &&
        !adminTeachers?.length ? (
          <EmptyState
            body="It seems that there is no admins has been added to the organisation"
            title="No admins/Teachers are added yet!"
          />
        ) : null}
        {!isListLoading && adminTeachers?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Users"
            isSortedBy
            maxWidth="75rem"
            autoSearch={isSearch}
          />
        ) : null}
      </div>
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this user ?"
        isDelete
        isButtonLoading={isAdminTeacherLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          if (!isAdminTeacherLoading) {
            dispatch(
              deleteAdminTeacher(deleteId, () => {
                setDeleteId(null);
                showToast(
                  false,
                  `${deleteName} has been deleted successfully`,
                  true,
                );

                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }),
            );
          }
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
      <EditAdminTeacherModal
        editDetails={editDetails}
        onHide={clearEditDetails}
      />
    </AdminTeacherContainer>
  );
};
