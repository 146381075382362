/* eslint-disable react/prop-types */
import moment from 'moment';
import React, {useEffect, useMemo, useState, useCallback} from 'react';
import Helmet from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  fetchAllSessions,
  updateSession,
  deleteSession,
  sessionsSelector,
} from '../../../../../../redux/sessionsSlice';

import {
  EmptyState,
  MoreOptions,
  Table,
  Modal,
  Button,
} from '../../../../../../components/common';

import FilterIcon from '../../../../../../assets/icons/filter-icon.svg';
import PlusIcon from '../../../../../../assets/icons/refactor/plus-white.svg';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {TableSpinner} from '../../../../components-new';
import {AllSessionsContainer} from './elements';
import UpdateSessionToastIcon from '../../../../../../assets/icons/class-success.svg';
import {showToast} from '../../../../../../components/common/Toast';
import {
  FilterSlider,
  FilterList,
  AttendanceColumn,
  EditSessionModal,
} from './components';

const DELAY = 500;

export const Sessions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [classId, setClassId] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [teacherId, setTeacherId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const {sessions, isListLoading, isLoading} = useSelector(sessionsSelector);

  const [editDetails, setEditDetails] = useState(null);
  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);
  const onSubmit = useCallback(
    detail => {
      const details = {
        ...detail,
        course: detail.course.value,
        class: detail.class.value,
        unit: detail.unit.value,
        teacher: detail.teacher.value,
      };
      if (!isLoading) {
        dispatch(
          updateSession(editDetails.id, details, () => {
            clearEditDetails();
            showToast(
              UpdateSessionToastIcon,
              `Session details updated successfully`,
            );
          }),
        );
      }
    },
    [clearEditDetails, dispatch, editDetails, isLoading],
  );

  useEffect(() => {
    dispatch(fetchAllSessions());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'index',
        className: 'index',
      },
      {
        Header: 'Session Name',
        accessor: 'oruil_name',
        className: 'session-name',
      },
      {
        Header: 'Course',
        accessor: 'orgUnitInstance.class.course.ocr_course_name',
        className: 'course-name',
      },
      {
        Header: 'Unit',
        accessor: 'orgUnitInstance.orgUnit.unit.tun_code',
        className: 'unit-code',
      },
      {
        Header: 'Teachers',
        Cell: ({value}) => (
          <div>
            {`${value?.up_name_first} 
            ${value?.up_name_last}`}
          </div>
        ),
        accessor: 'teacher',
        className: 'teacher-name',
      },
      {
        Header: 'Class',
        accessor: 'orgUnitInstance.class.oc_class',
        className: 'class-name',
      },
      // {
      //   Header: 'Location',
      //   Cell: () => <div />,
      //   accessor: 'oruil_id_orglocationroom',
      //   className: 'location',
      // },
      {
        Header: 'Date',
        Cell: ({row: {original}}) => {
          const dateOfSession = original?.oruil_date;
          return moment(dateOfSession).format('D MMM, YYYY');
        },
        accessor: 'oruil_date',
        className: 'date',
      },
      {
        Header: 'Time',
        Cell: ({row: {original}}) => {
          const endTime = original?.oruil_timefinish;
          const startTime = original?.oruil_timestart;

          return (
            <div>
              {`${moment(startTime, 'HH:mm')
                .format('h:mm A')
                .replace(/:00/g, '')} - 
              ${moment(endTime, 'HH:mm').format('h:mm A').replace(/:00/g, '')}`}
            </div>
          );
        },
        accessor: 'oruil_timefinish',
        className: 'time',
      },
      {
        Header: 'Attendance',
        Cell: ({row: {original}}) => {
          const sessionId = original?.oruil_id_orgunitinstancelesson;
          const sessionDate = new Date(original?.oruil_date);
          const today = new Date();
          const students = original?.students?.length;
          const presentStudents = original?.presentStudents?.length;
          const absentStudents = original?.absentStudents?.length;

          return (
            <AttendanceColumn
              sessionDate={sessionDate}
              today={today}
              sessionId={sessionId}
              presentStudents={presentStudents}
              absentStudents={absentStudents}
              students={students}
              history={history}
            />
          );
        },
        accessor: 'oruil_id_orgunitinstancelesson',
        className: 'attendance',
      },

      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions id="more-option">
              <DropdownItem
                label="Edit Session"
                onClick={() => {
                  setEditDetails({
                    id: original?.oruil_id_orgunitinstancelesson,
                    class: {
                      id: original?.orgUnitInstance?.class?.oc_id_orgclass,
                      name: original?.orgUnitInstance?.class?.oc_class,
                    },
                    course: {
                      id: original?.orgUnitInstance?.class?.course
                        ?.ocr_id_orgcourse,
                      name: original?.orgUnitInstance?.class?.course
                        ?.ocr_course_name,
                    },
                    sessionName: original?.oruil_name,
                    sessionDescription: original?.oruil_description,
                    location: original?.oruil_id_orglocationroom || 'location',

                    unit: {
                      id: original?.orgUnitInstance?.orui_id_orgunitinstance,
                      name: original?.orgUnitInstance?.orgUnit?.unit.tun_title,
                    },
                    recurrence: {
                      id: '',
                      name: '',
                    },
                    teacher: {
                      id: original?.teacher.up_id_userprofile,
                      name: `${original?.teacher.up_name_first} ${original?.teacher.up_name_last}`,
                    },

                    date: original?.oruil_date,
                    startTime: original?.oruil_timestart,
                    endTime: original?.oruil_timefinish,
                  });
                }}
                id="edit-session"
              />
              <DropdownItem
                label="Delete Session"
                isDanger
                onClick={() => {
                  setDeleteId(original?.oruil_id_orgunitinstancelesson);
                  setDeleteName(original?.oruil_name);
                }}
                id="delete-session"
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'session',
        className: 'more-options',
      },
    ],
    [history],
  );

  const data = useMemo(
    () => [
      ...(sessions || []).map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [sessions],
  );

  return (
    <AllSessionsContainer>
      <Helmet>
        <title>Session | JungleCat</title>
      </Helmet>

      <div className="header-container">
        <div className="title">
          All Sessions
          {isFilterApplied ? (
            <FilterList
              isVisible={isFilterOpen}
              courseId={courseId}
              setClassId={setClassId}
              classId={classId}
              setCourseId={setCourseId}
              setUnitId={setUnitId}
              unitId={unitId}
              setTeacherId={setTeacherId}
              teacherId={teacherId}
              setIsFilterApplied={setIsFilterApplied}
              onClose={() => {
                setIsFilterOpen(false);
              }}
            />
          ) : null}
        </div>
        <div className="right-side">
          <button
            type="button"
            className="filter-button"
            onClick={() => {
              setIsFilterOpen(true);
            }}
            id="filter-id"
          >
            <img src={FilterIcon} alt="filter" />
          </button>
          <Button
            icon={PlusIcon}
            className="add-button"
            isFullWidth={false}
            onClick={() => {
              history.push('/admin/sessions/add-session');
            }}
            id="add-session-id"
            label="Add Session"
          />
        </div>
      </div>

      <div className="table-container">
        {isListLoading ? <TableSpinner /> : null}
        {!isListLoading && Array.isArray(sessions) && !sessions?.length ? (
          <EmptyState
            description="It appears that no sessions have been added to the organisation."
            title="There are no sessions yet!"
          />
        ) : null}
        {!isListLoading && sessions?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Sessions"
            isSortedBy
            maxWidth="80rem"
            globalFilterId="search-session"
          />
        ) : null}
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this session?"
          isDelete
          isButtonLoading={isLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isLoading) {
              dispatch(
                deleteSession(deleteId, () => {
                  setDeleteId(null);
                  showToast(false, `${deleteName} has been deleted.`, true);
                  setTimeout(() => {
                    setDeleteName('');
                  }, DELAY);
                }),
              );
            }
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
        <EditSessionModal
          editDetails={editDetails}
          onHide={clearEditDetails}
          onSubmit={onSubmit}
        />
        <FilterSlider
          isVisible={isFilterOpen}
          courseId={courseId}
          setClassId={setClassId}
          classId={classId}
          setCourseId={setCourseId}
          setUnitId={setUnitId}
          unitId={unitId}
          setTeacherId={setTeacherId}
          teacherId={teacherId}
          setIsFilterApplied={setIsFilterApplied}
          onClose={() => {
            setIsFilterOpen(false);
          }}
        />
      </div>
    </AllSessionsContainer>
  );
};
