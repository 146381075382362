import {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import {LibraryContainer} from './elements';
import {
  LibraryCard,
  EmptyState,
  Button,
  Modal,
} from '../../../../components/common';
import PlusIcon from '../../../../assets/icons/refactor/plus-white.svg';
import {TableSpinner} from '../../../Admin/components-new';
import {
  fetchAllLibraryFiles,
  teacherLibrarySelector,
  deleteLibraryClassFile,
  fetchAllCategory,
  clearFormError,
} from '../../../../redux/teacherLibrarySlice';
import {
  AddDocument,
  EditDocument,
  FilePreview,
  FilterFiles,
} from './components';
import {showToast} from '../../../../components/common/Toast';
import {streamSelector} from '../../../../redux/streamSlice';
import loader from '../../../../assets/icons/orgLogoLoader.svg';

const DELAY = 500;

export const Library = () => {
  const dispatch = useDispatch();
  const [classFilesTab, setClassFilesTab] = useState(true);
  const [publicFilesTab, setPublicFilesTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [addDocument, setAddDocument] = useState(false);
  const [viewDocument, setViewDocument] = useState(false);
  const [previewFileData, setPreviewFileData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteDocumentName, setDeleteDocumentName] = useState(null);
  const [editDocument, setEditDocument] = useState(null);

  const [filterByCategory, setFilterByCategory] = useState({
    value: '',
    label: 'All Category',
  });
  const [filterByFileType, setFilterByFileType] = useState({
    value: '',
    label: 'All files types',
  });

  const {
    location: {pathname},
  } = useHistory();

  const {
    teacherLibrary,
    publicLibraryFiles,
    orgUnitInstance,
    isListLoading,
    isTeacherLibraryLoading,
    category,
  } = useSelector(teacherLibrarySelector);
  const streamState = useSelector(streamSelector);

  const publicLibraryFilesGroup = useMemo(
    () =>
      publicLibraryFiles?.filter(
        item =>
          item?.fileType?.ft_type !== 'Text' &&
          `${item?.fi_text_content}`.toLowerCase().includes(searchTerm) &&
          (item?.fileCategory?.fc_id_filecategory === filterByCategory?.value ||
            filterByCategory?.value === '') &&
          (item?.fileType?.ft_id_filetype === filterByFileType?.value ||
            filterByFileType?.value === ''),
      ),
    [
      filterByCategory?.value,
      filterByFileType?.value,
      publicLibraryFiles,
      searchTerm,
    ],
  );

  const teacherLibraryFilesGroup = useMemo(
    () =>
      teacherLibrary?.filter(
        item =>
          item?.fileType?.ft_type !== 'Text' &&
          (`${item?.fi_text_content}`.toLowerCase().includes(searchTerm) ||
            `${item?.fi_code}`.toLowerCase().includes(searchTerm)) &&
          (item?.fileCategory?.fc_id_filecategory === filterByCategory?.value ||
            filterByCategory?.value === '') &&
          (item?.fileType?.ft_id_filetype === filterByFileType?.value ||
            filterByFileType?.value === ''),
      ),
    [
      teacherLibrary,
      searchTerm,
      filterByCategory?.value,
      filterByFileType?.value,
    ],
  );

  const categoryAsOptions = useMemo(
    () => [
      {label: 'All Catergory', value: ''},
      ...(category?.map(item => ({
        label: `${item?.fc_name}`,
        value: item?.fc_id_filecategory,
      })) || []),
    ],
    [category],
  );

  const fileTypeOptions = [
    {label: 'All file types', value: ''},
    {label: 'Image', value: 1},
    {label: 'Video', value: 2},
    {label: 'Document', value: 5},
    // {label: 'Voice', value: 7},
  ];

  const orgUnitInstanceId = pathname.split('/')[2];
  useEffect(() => {
    dispatch(fetchAllLibraryFiles(orgUnitInstanceId));
  }, [dispatch, orgUnitInstanceId]);

  useEffect(() => {
    dispatch(fetchAllCategory(orgUnitInstanceId));
  }, [dispatch, orgUnitInstanceId]);
  return (
    <>
      <Helmet>
        <title>Library | JungleCat</title>
      </Helmet>
      <LibraryContainer>
        <>
          <div>
            <div className="library-header">
              <span className="flex-shrink-0 mb-0 title">Library</span>
              <div className="library-searc-add">
                <Button
                  icon={PlusIcon}
                  className={classNames('add-doc-button', {
                    'is-archive': streamState?.classDetails?.orui_is_archived,
                  })}
                  isFullWidth={false}
                  label="Add Document"
                  onClick={() => {
                    setAddDocument(true);
                  }}
                  id="add-document"
                />
              </div>
            </div>

            <div className="search-div">
              <button
                className={classNames('bottom', {
                  link: classFilesTab,
                })}
                type="button"
                onClick={() => {
                  setClassFilesTab(true);
                  setPublicFilesTab(false);
                  setSearchTerm('');
                }}
                id="class-file-tab"
              >
                {isListLoading ? (
                  <div className="loader-images">
                    <img src={loader} alt="loader" className="button-spinner" />
                  </div>
                ) : (
                  <p>{`${orgUnitInstance?.class?.oc_class} - ${orgUnitInstance?.orgUnit?.unit?.tun_title}`}</p>
                )}
              </button>

              <button
                className={classNames('bottom', {
                  link: publicFilesTab,
                })}
                type="button"
                onClick={() => {
                  setClassFilesTab(false);
                  setPublicFilesTab(true);
                  setSearchTerm('');
                }}
                id="public-file-tab"
              >
                <p>Public</p>
              </button>
            </div>

            {isListLoading ? <TableSpinner /> : null}

            {classFilesTab && !isListLoading ? (
              <>
                <FilterFiles
                  filesLength={teacherLibraryFilesGroup?.length}
                  filterByCategory={filterByCategory}
                  categoryAsOptions={categoryAsOptions}
                  setFilterByCategory={setFilterByCategory}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  fileTypeOptions={fileTypeOptions}
                  setFilterByFileType={setFilterByFileType}
                  filterByFileType={filterByFileType}
                />

                {!isListLoading && !teacherLibraryFilesGroup?.length ? (
                  <EmptyState
                    description="It seems that there is no Files has been added to this Class"
                    title="No Files Found!"
                  />
                ) : null}
                <div className="row mt-5">
                  {teacherLibraryFilesGroup?.map(item => (
                    <div className="card-class col-sm-6 col-lg-3 col-md-4 col-xl-3 col-xs-6 col-12 mb-4">
                      <LibraryCard
                        id="library-card-files"
                        data={teacherLibraryFilesGroup}
                        height="100%"
                        fileName={item?.fi_filename}
                        uploadedDate={item?.fi_uploadedate}
                        fileLocation={item?.fi_location}
                        fileType={item?.fileType?.ft_type}
                        thumbnail={
                          item?.fi_cover_image
                            ? item?.fi_cover_image
                            : item?.fi_thumbnail
                        }
                        fileTitle={item?.fi_text_content}
                        fileCode={item?.fi_code}
                        onClick={() => {
                          setViewDocument(true);
                          setPreviewFileData(item);
                        }}
                        deleteOnClick={() => {
                          setDeleteId(item?.fi_id_file);
                          setDeleteDocumentName(item?.fi_text_content);
                        }}
                        editOnClick={() => {
                          setEditDocument(item);
                        }}
                        fileCategory={item?.fileCategory?.fc_name}
                        key={item?.fi_id_file}
                        isArchive={streamState?.classDetails?.orui_is_archived}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {publicFilesTab && !isListLoading ? (
              <>
                <FilterFiles
                  filesLength={publicLibraryFilesGroup?.length}
                  filterByCategory={filterByCategory}
                  categoryAsOptions={categoryAsOptions}
                  setFilterByCategory={setFilterByCategory}
                  setSearchTerm={setSearchTerm}
                  searchTerm={searchTerm}
                  fileTypeOptions={fileTypeOptions}
                  setFilterByFileType={setFilterByFileType}
                  filterByFileType={filterByFileType}
                />
                {!isListLoading && !publicLibraryFilesGroup?.length ? (
                  <EmptyState
                    description="It seems that there is no Files has been added to this Class"
                    title="No Files Found!"
                  />
                ) : null}
                <div className="row mt-4 ">
                  {publicLibraryFilesGroup?.map(item => (
                    <div className="card-class col-sm-6 col-lg-3 col-md-4 col-xl-3 col-xs-6 col-12 mb-4">
                      <LibraryCard
                        id="library-card-public-files"
                        data={publicLibraryFilesGroup}
                        height="100%"
                        fileName={item?.fi_filename}
                        uploadedDate={item?.fi_uploadedate}
                        fileLocation={item?.fi_location}
                        fileType={item?.fileType?.ft_type}
                        thumbnail={
                          item?.fi_cover_image
                            ? item?.fi_cover_image
                            : item?.fi_thumbnail
                        }
                        fileTitle={item?.fi_text_content}
                        onClick={() => {
                          setViewDocument(true);
                          setPreviewFileData(item);
                        }}
                        fileCategory={item?.fileCategory?.fc_name}
                        key={item?.fi_id_file}
                        isPublic
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </>
        {addDocument ? (
          <AddDocument
            isVisible={addDocument}
            onClose={() => {
              const b = document.body;
              b.style.overflow = 'visible';
              setAddDocument(false);
            }}
            onSuccess={() => {
              const b = document.body;
              b.style.overflow = 'visible';
              setClassFilesTab(true);
              setPublicFilesTab(false);
            }}
          />
        ) : null}
        {editDocument ? (
          <EditDocument
            isVisible={editDocument}
            onClose={() => {
              setEditDocument(false);
              clearFormError();
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
            }}
            editDocument={editDocument}
            onSuccess={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setClassFilesTab(true);
              setPublicFilesTab(false);
              // clearFormError();
            }}
          />
        ) : null}
        {viewDocument && previewFileData ? (
          <FilePreview
            isVisible={viewDocument}
            onClose={() => {
              const bodyScrollBar = document.body;
              bodyScrollBar.style.overflow = 'visible';
              setViewDocument(false);
            }}
            previewFileData={previewFileData}
          />
        ) : null}
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this Document ?"
          isDelete
          width="41.25rem"
          isButtonLoading={isTeacherLibraryLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isTeacherLibraryLoading)
              dispatch(
                deleteLibraryClassFile(deleteId, orgUnitInstanceId, () => {
                  setDeleteId(null);
                  showToast(
                    false,
                    `${deleteDocumentName} deleted successfully`,
                    true,
                  );
                }),
              );
          }}
          onHide={() => {
            setDeleteId(null);
            setTimeout(() => {
              setDeleteDocumentName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteDocumentName}`}
        />
      </LibraryContainer>
    </>
  );
};
