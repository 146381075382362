/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';

import {
  EmptyState,
  MoreOptions,
  Table,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {
  ListingPageHeader,
  // TableSpinner
} from '../../../../components-new';
import TaskListContainer from './elements';

const courses = [
  {
    taskName: 'Cookery-FT-2022',
    status: 1,
  },
  {
    taskName: 'Restaurant Service Diploma - 651535',
    status: 2,
  },
  {
    taskName: 'Restaurant Service Diploma',
    status: 1,
  },
  {
    taskName: 'Cookery-FT-2022',
    status: 1,
  },
  {
    taskName: 'Lead and manage people',
    status: 3,
  },
  {
    taskName: 'Restaurant Service Diploma',
    status: 1,
  },
  {
    taskName: 'Lead and manage people',
    status: 3,
  },
  {
    taskName: 'Restaurant Service Diploma',
    status: 1,
  },
];

export const TaskList = () => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Task Name',
        accessor: 'taskName',
        className: 'task-name',
      },
      {
        Header: 'Status',
        Cell: ({row: {original}}) => {
          const invitationStatus = original?.status;
          let status;
          if (invitationStatus === 1) {
            status = 'Approved';
          }
          if (invitationStatus === 2) {
            status = 'Rejected';
          }
          if (invitationStatus === 3) {
            status = 'Reassigned';
          }
          return (
            <div className="status-container">
              <div className={`status-${invitationStatus}`}>{status}</div>
              {/* {invitationStatus > 1 ? (
                <div>
                  <div
                    className="invited"
                    data-html
                    data-tip={`<span className="tooltip-value">Invite resended on : ${moment(
                      invitationTime,
                    ).format('D MMM YYYY')}</span>`}
                  >
                    <img alt="Reassigned" src={tooltipImg} />
                  </div>
                  <ReactTooltip
                    backgroundColor="#3d4457"
                    effect="solid"
                    place="bottom"
                  />
                </div>
              ) : null} */}
            </div>
          );
        },
        accessor: 'up-profile',
        className: 'status',
      },

      {
        Header: '',
        Cell: () => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit Task"
                id="edit-class-details"
                // onClick={() => {
                //   console.log(original);
                // }}
              />
              <DropdownItem
                isDanger
                label="Delete Task"
                id="archive"
                onClick={() => {}}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'classes',
        className: 'more-option',
      },
    ],
    [],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(courses || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [],
  );

  return (
    <>
      <Helmet>
        <title>Classes | JungleCat</title>
      </Helmet>

      <TaskListContainer>
        <ListingPageHeader
          buttonLabel="Add Course"
          className="mb-4"
          onButtonClick={() => {
            history.push(`classes/add-class`);
          }}
          title="Your Tasks"
          btnId="add-class"
        />

        <div className="table-container">
          {/* {isListLoading ? <TableSpinner /> : null} */}
          {Array.isArray(courses) && !courses?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No courses Found"
            />
          ) : null}
          {courses?.length && Array.isArray(courses) ? (
            <Table
              columns={columns}
              data={data}
              itemName="Tasks"
              maxWidth="80rem"
              isSortedBy
            />
          ) : null}
        </div>
      </TaskListContainer>
    </>
  );
};
