import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

const CurrentTimeContainer = styled.div`
  bottom: 0.5rem;
  left: ${({left}) => `${left}px`};
  height: 1.875rem;

  .circle {
    background-color: #d64727;
    border-radius: 0.15625rem;
    height: 0.3125rem;
    width: 0.3125rem;
  }

  .line {
    background-color: #d64727;
    width: 0.0625rem;
  }
`;

const CurrentTime = () => {
  const [leftOffset, setLeftOffset] = useState(0);

  // Set the left offset of the indicator based on the current time.
  const setLeftOffsetUsingCurrentTime = useCallback(() => {
    const currentTime = moment().format('HH:mm');
    const [hoursNow, minutesNow] = currentTime.split(':').map(Number);
    setLeftOffset(hoursNow * 60 + minutesNow + 18);
  }, []);

  // Update the position of the indicator every minute.
  useEffect(() => {
    setLeftOffsetUsingCurrentTime();

    const intervalId = setInterval(() => {
      setLeftOffsetUsingCurrentTime();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [setLeftOffsetUsingCurrentTime]);

  return (
    <CurrentTimeContainer
      className="align-items-center d-flex flex-column position-absolute"
      left={leftOffset}
    >
      <span className="circle" />
      <span className="flex-grow-1 line" />
    </CurrentTimeContainer>
  );
};

export default CurrentTime;
