/* eslint-disable react/prop-types */
import React, {useCallback, useEffect} from 'react';

import {Helmet} from 'react-helmet';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {ViewFileSharedToClassContainer} from './elements';
import {showToast} from '../../../../../../../../../../components/common/Toast';

import {
  ViewFileSharedToClassHeader,
  ViewFileSharedToClassMain,
} from './components';
import {
  getCurriculumFileByFileId,
  publishCurriculumModuleFileById,
  teacherCurriculumSelector,
} from '../../../../../../../../../../redux/teacherCurriculumSlice';
import UpdateIcon from '../../../../../../../../../../assets/icons/updateadmintoast.svg';

// const DELAY = 500;

export const ViewFileSharedToClass = () => {
  const {id: orgUnitInstanceId, curriculumFileId} = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const {curriculumFile, isLoading} = useSelector(teacherCurriculumSelector);

  useEffect(() => {
    dispatch(getCurriculumFileByFileId(orgUnitInstanceId, curriculumFileId));
  }, [orgUnitInstanceId, curriculumFileId, dispatch]);

  const onCancel = useCallback(() => {
    history.replace(`/stream/${orgUnitInstanceId}/curriculum`);
  }, [history, orgUnitInstanceId]);

  const onButtonClick = useCallback(() => {
    if (!isLoading) {
      dispatch(
        publishCurriculumModuleFileById(
          orgUnitInstanceId,
          curriculumFile?.occf_id_class_curriculum_file,
          () => {
            showToast(
              UpdateIcon,
              `${curriculumFile?.fi_text_content} file Published successfully `,
              false,
            );
            onCancel();
          },
        ),
      );
    }
  }, [curriculumFile, dispatch, isLoading, onCancel, orgUnitInstanceId]);

  return (
    <>
      <Helmet>
        <title>View Curriculum File | JungleCat</title>
      </Helmet>

      <ViewFileSharedToClassContainer>
        <ViewFileSharedToClassHeader
          onBack={onCancel}
          curriculumFile={curriculumFile}
          onPublish={onButtonClick}
        />
        <ViewFileSharedToClassMain curriculumFile={curriculumFile} />
      </ViewFileSharedToClassContainer>
    </>
  );
};
