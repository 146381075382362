import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Color} from '../../../../../../constants';

const StudentsOnlineContainer = styled.div`
  border-bottom: 0.0625rem solid #e0e0e0;

  .title {
    color: ${Color.PRIMARY_TEXT};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .ratio {
    color: #8a8c94;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3;
  }

  .students {
    .img-student {
      width: 2.25rem;
      height: 2.25rem;
      margin-left: -0.375rem;
    }
    .circle {
      border: 0.125rem solid white;
      border-radius: 1.125rem;
      font-size: 0.75rem;
      font-weight: 500;
      height: 2.25rem;
      line-height: 1.5;
      margin-left: -0.375rem;
      width: 2.25rem;
      text-transform: uppercase;
      cursor: default;
    }

    .student {
      background-color: ${Color.PRIMARY};
      color: white;
    }

    .remaining {
      background-color: #ecf6ff;
      color: ${Color.PRIMARY};
    }
  }
`;
const convertName = fullName => {
  const arr = fullName.split(' ');

  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }

  const formattedName = arr.join(' ');
  return formattedName;
};

const StudentsOnline = ({students, totalNumberOfStudents}) => (
  <StudentsOnlineContainer className="pb-3 pt-4 px-3">
    <div className="align-items-center d-flex justify-content-between mb-2">
      <h3 className="title mb-0">Students Online</h3>
      <span className="ratio">
        {students.length}/{totalNumberOfStudents}
      </span>
    </div>
    <div className="d-flex students">
      {/* Show the first 7 students. */}
      {students.slice(0, 7).map(student => {
        if (student?.up_avatar) {
          return (
            <img
              src={student?.up_avatar}
              className="rounded-circle img-student"
              alt="profile-img"
              title={`${convertName(
                `${student?.up_name_first} ${student?.up_name_last}`,
              )}`}
            />
          );
        }
        return (
          <span
            className="align-items-center circle d-flex justify-content-center student"
            key={student?.up_id_userprofile}
            title={`${convertName(
              `${student?.up_name_first} ${student?.up_name_last}`,
            )}`}
          >
            {student.up_name_first[0]}
            {student.up_name_last[0]}
          </span>
        );
      })}
      {/* Show the count of the remaining students. */}
      {students.length > 7 && (
        <span className="align-items-center circle d-flex justify-content-center remaining">
          +{students.length - 7}
        </span>
      )}
    </div>
  </StudentsOnlineContainer>
);

StudentsOnline.defaultProps = {
  students: [],
};

StudentsOnline.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  ),
  totalNumberOfStudents: PropTypes.number.isRequired,
};

export default StudentsOnline;
