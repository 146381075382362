/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import {yupResolver} from '@hookform/resolvers/yup';
import {useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {TasSubmissionModalContainer} from './elements';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import {Select} from '../../../../../../../../components/common';
import {TaskVerficationSchema} from '../../../../../../../../utils/validation';
// import addPlus from '../../../../../../../../assets/icons/add-plus.svg';
// import {SelectUserDropdown} from './components';

const TasSubmissionModal = ({
  isVisible,
  onHide,
  taskStatus,
  onButtonClick,
  user,
}) => {
  const {
    control,
    handleSubmit,
    // register,
    // getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      comments: '',
      assignee: null,
      status: null,
      // tasVerified: false,
      //   minutes: '',
      //   hours: '',
    },
    resolver: yupResolver(TaskVerficationSchema),
  });

  // const [isSuggestion, setIsSuggestion] = useState(false);
  // const [listColumns, setListColumns] = useState(null);
  // const [listData, setListData] = useState([]);
  // const [listMaps, setListMaps] = useState(null);
  // const [search, setSearch] = useState('');
  // const [addUser, setAddUser] = useState([]);

  // // Dropdown data in assign user modal
  // useEffect(() => {
  //   // dispatch(getAllDepartmentsList());
  //   const departmentColumns = [
  //     'dp_id_department',
  //     {
  //       heading: '',
  //       width: '100%',
  //     },
  //   ];
  //   const mapDepartment = {
  //     '': [
  //       (userId, userName) => (
  //         <button
  //           type="button"
  //           onClick={() => {
  //             setAddUser(state => [
  //               ...state,
  //               {id: userId, user_name: userName},
  //             ]);
  //             setIsSuggestion(false);
  //             setSearch('');
  //           }}
  //           className="addPlusBtn"
  //         >
  //           <div>{userName}</div>
  //           <div className="add-btn">
  //             <img src={addPlus} alt="close" id={userId} />
  //           </div>
  //         </button>
  //       ),
  //       'userId',
  //       'userName',
  //     ],
  //   };
  //   setListColumns(departmentColumns);
  //   setListMaps(mapDepartment);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   const addedUserId = addUser?.map(data => data.id);
  //   setListData(
  //     userOptions.filter(
  //       options =>
  //         options?.userName?.toLowerCase().includes(search) &&
  //         !addedUserId?.includes(options?.userId),
  //     ),
  //   );
  // }, [addUser, search]);

  const statusOptions = useMemo(
    () => [
      ...(taskStatus || [])?.map(item => ({
        label: item?.ts_name,
        value: item?.ts_id_taskstatus,
      })),
    ],
    [taskStatus],
  );

  const userOptions = useMemo(
    () => [
      ...(user || [])?.map(item => ({
        label: `${item?.up_name_first} ${item?.up_name_last}`,
        value: item?.up_id_userprofile,
      })),
    ],
    [user],
  );

  return (
    <TasSubmissionModalContainer
      buttonLabel="Submit"
      description="Select a user you want to assign this TAS to"
      // isButtonLoading={isClassesLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onButtonClick)}
      onHide={onHide}
      show={isVisible}
      title="Submit TAS"
      width="43.25rem"
      //   isIcon
      //   icon={DuplicateTasIcon}
      //   isDisabled={!watch('className')}
    >
      <div className="submit-modal-fields">
        <Controller
          control={control}
          name="status"
          render={fields => (
            <Select
              {...fields}
              className="mb-4"
              hasError={!!errors?.status}
              isLarge={false}
              label="Select Status"
              options={statusOptions}
              placeholder="Select the TAS status"
              // ref={register({require: true})}
              errorMessage={errors?.status?.message}
            />
          )}
        />
        <div className="comments">
          <Controller
            control={control}
            name="comments"
            render={fields => (
              <TextArea
                {...fields}
                hasError={!!errors?.comments}
                className="mr-4 mt-4"
                label="Your comments"
                // placeholder="State your reason for resubmission"
                rows="6"
                errorMessage={errors?.comments?.message}
              />
            )}
          />
        </div>
        {watch('status')?.value !== 5 ? (
          <Controller
            control={control}
            name="assignee"
            render={fields => (
              <Select
                {...fields}
                className="mt-4 mb-5"
                hasError={!!errors?.assignee}
                isLarge={false}
                label="Select verifier"
                options={userOptions}
                placeholder="Select the user"
                // ref={register({require: true})}
                errorMessage={errors?.assignee?.message}
              />
            )}
          />
        ) : null}
        {/* <div className="row-container">
          <div className="field-left">
            <Controller
              control={control}
              name="tasVerified"
              render={fields => (
                <Checkbox
                  {...fields}
                  label="Mark TAS is complete and Verified"
                  hasError={!!errors.tasVerified}
                />
              )}
            />
          </div>
          <div className="or">or</div>
          <div className="field-right">
            <Controller
              control={control}
              name="user"
              render={fields => (
                <Select
                  {...fields}
                  className="mb-4"
                  // hasError={!!errors?.className}
                  isLarge={false}
                  label="Select user to assign"
                  options={userOptions}
                  placeholder="Select the user"
                  // ref={register({require: true})}
                  // errorMessage={
                  //   errors?.className ? 'Title is required' : null
                  // }
                />
              )}
            />
            <SelectUserDropdown
              isSuggestion={isSuggestion}
              listColumns={listColumns}
              listData={listData}
              listMaps={listMaps}
              setIsSuggestion={setIsSuggestion}
              setSearch={setSearch}
              search={search}
              addUser={addUser}
              setAddUser={setAddUser}
            />
          </div> */}
        {/* </div> */}
      </div>
    </TasSubmissionModalContainer>
  );
};

export default TasSubmissionModal;
