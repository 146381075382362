/* eslint-disable react/prop-types */

import {useState} from 'react';
import DatePicker from 'react-datepicker';
import {Controller} from 'react-hook-form';

import CalenderIcon from '../../../../../../../../assets/icons/tas-calendar-icon.svg';
import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {Radio, Select} from '../../../../../../../../components/common';
import {TextInput} from '../../../../../../../../components/common/TextInput';
import {CustomDatePicker} from '../../../../../../../Stream/elements/Units';
import TextModal from '../TextModal';

const courseDurationOptions = [
  {label: '1 Week', value: '1 Week'},
  {label: '2 Weeks', value: '2 Weeks'},
  {label: '1 Month', value: '1 Month'},
  {label: '3 Months', value: '3 Months'},
  {label: '12 Weeks', value: '12 Weeks'},
  {label: '6 Months', value: '6 Months'},
  {label: '8 Months', value: '8 Months'},
  {label: '9 Months', value: '9 Months'},
  {label: '12 Months', value: '12 Months'},
  {label: '18 Months', value: '18 Months'},
  {label: '24 Months', value: '24 Months'},
  {label: '30 Months', value: '30 Months'},
  {label: '36 Months', value: '36 Months'},
  {label: '42 Months', value: '42 Months'},
  {label: '48 Months', value: '48 Months'},
];

const TrainingProductForm = ({
  control,
  errors,
  hiddenValues,
  onUpdate,
  setValue,
  watch,
  tasStatus,
}) => {
  const [isDeliveryDateOpen, setIsDeliveryDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [isTpdModalOpen, setIsTpdModalOpen] = useState(false);
  const [isLriModalOpen, setIsLriModalOpen] = useState(false);

  return (
    <div className="section" id="training-product">
      <h3>Training Product</h3>
      <p>
        Ensure that you clearly identify the training product to which the
        strategy relates.
        <br />
        Include the code and full title to ensure this is clear.
      </p>
      <form>
        {/* <div className="tas-form-row">
          <Controller
            control={control}
            name="rtoName"
            render={fields => (
              <TextInput
                {...fields}
                disabled
                className="tas-form-column is-read-only border-0 shadow-none"
                hasError={!!errors.rtoName}
                hasInfo="This data comes from an RTO’s Scope of Registration<br/><br/>Registered training organisations (RTOs) deliver nationally recognised training in the VET sector. To deliver this training, they need to be approved by ASQA.<br/><br/>Then they are listed on the National Register which is training.gov.au"
                label="RTO Name"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                width="auto"
              />
            )}
          />
          <Controller
            control={control}
            name="rtoNumber"
            render={fields => (
              <TextInput
                {...fields}
                disabled
                className="tas-form-column is-read-only shadow-none"
                hasError={!!errors.rtoNumber}
                hasInfo="This data comes from an RTO’s Scope of Registration<br/><br/>Registered training organisations (RTOs) deliver nationally recognised training in the VET sector. To deliver this training, they need to be approved by ASQA.<br/><br/>Then they are listed on the National Register which is training.gov.au"
                label="RTO No."
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                width="auto"
              />
            )}
          />
        </div> */}

        <div className="tas-form-row">
          <div className="is-read-only tas-form-column">
            <div className="column-title">
              RTO Name{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes from an RTO’s Scope of Registration<br/><br/>Registered training organisations (RTOs) deliver nationally recognised training in the VET sector. To deliver this training, they need to be approved by ASQA.<br/><br/>Then they are listed on the National Register which is training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value">{hiddenValues.rtoName}</div>
          </div>
          <div className="is-read-only tas-form-column">
            <div className="column-title">
              RTO No.{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes from an RTO’s Scope of Registration<br/><br/>Registered training organisations (RTOs) deliver nationally recognised training in the VET sector. To deliver this training, they need to be approved by ASQA.<br/><br/>Then they are listed on the National Register which is training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value">{hiddenValues.rtoNumber}</div>
          </div>
        </div>

        <div className="tas-form-row">
          <div className="is-read-only tas-form-column">
            <div className="column-title">
              Training Package{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes from training.gov.au <br/>Training packages specify the knowledge and skills required by individuals to perform effectively in the workplace, expressed in units of competency. Training packages also detail how units of competency can be packaged into nationally recognised and portable qualifications that comply with the Australian Qualifications Framework (AQF). <br/><br/> Training packages are used for a range of purposes, but predominantly:<br/><br/>
                <ul><li>By training providers, to design training curriculum tailored to support individual learner needs, and the needs of employers and industry</li>
<li>By employers, to assist with workforce design, development, and structure.</li></ul>Training packages consist of the following nationally endorsed training package products:<br/><br/>
<ul><li>Units of competency, which specify the standard of performance required in the workplace</li>
<li>Assessment requirements (associated with each unit of competency)</li>
<li>Qualifications aligned to the AQF (Certificate I to Advanced Diploma, and Graduate Certificate and Graduate Diploma)</li>
<li>Credit arrangements.</li></ul>Registered training providers can only deliver training package qualifications and units of competency if those products are on their scope of registration."
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues.trainingPackage || 'Not Available'}
            </div>
          </div>
          <div className="is-read-only tas-form-column">
            <div className="column-title">
              Qualification Code & Title{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes from training.gov.au <br/><br/> Qualifications are <b>occupational skills standards against which training delivery and assessment of competency can take place.</b> They are developed through a process of national consultation with industry."
                data-for="global"
              />
            </div>
            <div className="column-value">
              {hiddenValues.qualification || 'Not Available'}
            </div>
          </div>
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="department"
            render={fields => (
              <TextInput
                {...fields}
                className="tas-form-column"
                hasError={!!errors.department}
                hasInfo="The name of the RTO’s internal department implementing the training and assessment strategy"
                label="Department"
                isDisabled={tasStatus}
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                width="auto"
              />
            )}
          />
          <Controller
            control={control}
            name="cohortName"
            render={fields => (
              <TextInput
                {...fields}
                className="tas-form-column"
                hasError={!!errors.cohortName}
                hasInfo="The RTO’s name for each Cohort.<br/><br/>In education, cohort is typically applied to students who are educated at the same period of time—<b>a grade level or class of students</b>"
                label="Cohort Name"
                isDisabled={tasStatus}
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                width="auto"
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="tas-form-column">
            <div className="column-title">
              Delivery Date{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The start date when the cohort for this TAS will begin"
                data-for="global"
              />
            </div>
            <Controller
              className="form-control"
              control={control}
              name="deliveryDate"
              render={() => (
                <CustomDatePicker
                  className={`d-flex tas-date-picker${
                    tasStatus ? ' disabled' : ''
                  }`}
                >
                  <DatePicker
                    autoComplete="off"
                    className="form-control border-0 w-100 date-picker"
                    dateFormat="dd-MM-yyyy"
                    // highlightDates={{
                    //   'react-datepicker__day--highlighted-custom-1': [
                    //     new Date(),
                    //   ],
                    // }}
                    disabled={tasStatus}
                    name="deliveryDate"
                    onChange={date => {
                      setValue('deliveryDate', date);
                      onUpdate();
                      setIsDeliveryDateOpen(false);
                    }}
                    onClickOutside={() => setIsDeliveryDateOpen(false)}
                    onFocus={() => {
                      setIsDeliveryDateOpen(previousValue => !previousValue);
                    }}
                    open={isDeliveryDateOpen}
                    placeholderText="Select"
                    popperPlacement="top"
                    selected={
                      watch('deliveryDate')
                        ? new Date(watch('deliveryDate'))
                        : null
                    }
                  />
                  <button
                    className="align-items-center d-flex openDatepicker"
                    disabled={tasStatus}
                    onClick={() => {
                      setIsDeliveryDateOpen(previousValue => !previousValue);
                    }}
                    type="button"
                  >
                    <img
                      alt="date"
                      className="calendar-icon"
                      src={CalenderIcon}
                    />
                  </button>
                </CustomDatePicker>
              )}
            />
          </div>
          <div className="tas-form-column">
            <div className="column-title">
              End Date{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="The date the students in this cohort will complete this TAS"
                data-for="global"
              />
            </div>
            <Controller
              className="form-control"
              control={control}
              name="endDate"
              render={() => (
                <CustomDatePicker
                  className={`d-flex tas-date-picker${
                    tasStatus ? ' disabled' : ''
                  }`}
                >
                  <DatePicker
                    autoComplete="off"
                    className="form-control border-0 w-100  date-picker"
                    dateFormat="dd-MM-yyyy"
                    // highlightDates={{
                    //   'react-datepicker__day--highlighted-custom-1': [
                    //     new Date(),
                    //   ],
                    // }}
                    disabled={tasStatus}
                    name="endDate"
                    onChange={date => {
                      setValue('endDate', date);
                      onUpdate();
                      setIsEndDateOpen(false);
                    }}
                    onClickOutside={() => setIsEndDateOpen(false)}
                    onFocus={() => {
                      setIsEndDateOpen(previousValue => !previousValue);
                    }}
                    open={isEndDateOpen}
                    placeholderText="Select"
                    popperPlacement="top"
                    selected={watch('endDate')}
                  />
                  <button
                    className="align-items-center d-flex openDatepicker"
                    onClick={() => {
                      setIsEndDateOpen(previousValue => !previousValue);
                    }}
                    disabled={tasStatus}
                    type="button"
                  >
                    <img
                      alt="date"
                      className="calendar-icon"
                      src={CalenderIcon}
                    />
                  </button>
                </CustomDatePicker>
              )}
            />
          </div>
          <Controller
            className="form-control"
            control={control}
            name="courseDuration"
            render={fields => (
              <Select
                {...fields}
                className="flex-grow-0 tas-form-column"
                hasError={!!errors.courseDuration}
                hasInfo="How long the cohort will take to complete this TAS expressed in months"
                isLarge={false}
                isLightBorder
                isDisabled={tasStatus}
                label="Course Duration"
                onChange={event => {
                  fields?.onChange?.(event);
                  onUpdate();
                }}
                placeholder="Select"
                options={courseDurationOptions}
                flexBasis="19%"
              />
            )}
          />
        </div>

        <div className="is-column tas-form-row">
          <div className="is-full-width tas-form-column">
            <div className="column-title">
              Type{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="What capacity the cohort will undertake this TAS"
                data-for="global"
              />
            </div>
          </div>
          <div className="d-flex">
            <Controller
              className="form-control"
              control={control}
              name="type"
              render={fields => (
                <Radio
                  {...fields}
                  className="mr-4"
                  currentValue={watch('type')}
                  hasError={!!errors.type}
                  label="Full Time"
                  onChange={event => {
                    fields?.onChange?.(event);
                    onUpdate();
                  }}
                  value="fullTime"
                  isDisabled={tasStatus}
                />
              )}
            />
            <Controller
              className="form-control"
              control={control}
              name="type"
              render={fields => (
                <Radio
                  {...fields}
                  currentValue={watch('type')}
                  hasError={!!errors.type}
                  label="Part Time"
                  onChange={event => {
                    fields?.onChange?.(event);
                    onUpdate();
                  }}
                  value="partTime"
                  isDisabled={tasStatus}
                />
              )}
            />
          </div>
        </div>

        <div className="tas-form-row">
          <div className="is-full-width is-read-only tas-form-column">
            <div className="column-title">
              Training Product Description{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value" style={{whiteSpace: 'pre-line'}}>
              {hiddenValues.trainingProductDescription
                ? hiddenValues.trainingProductDescription.slice(0, 720)
                : ''}
              {hiddenValues.trainingProductDescription.length > 720 ? (
                <>
                  ...
                  <button onClick={() => setIsTpdModalOpen(true)} type="button">
                    See more
                  </button>
                </>
              ) : (
                ''
              )}
              {!hiddenValues.trainingProductDescription ? 'Not Available' : ''}
            </div>
            <TextModal
              content={hiddenValues.trainingProductDescription}
              isVisible={isTpdModalOpen}
              onClose={() => setIsTpdModalOpen(false)}
              title="Training Product Description"
            />
          </div>
        </div>

        <div className="tas-form-row">
          <div className="is-full-width is-read-only tas-form-column">
            <div className="column-title">
              Licensing / Regulatory Info.{' '}
              <img
                alt="Info"
                src={InfoIcon}
                data-tip="This data comes directly from training.gov.au"
                data-for="global"
              />
            </div>
            <div className="column-value" style={{whiteSpace: 'pre-line'}}>
              {hiddenValues.licensingInfo.slice(0, 720)}
              {hiddenValues.licensingInfo.length > 720 ? (
                <>
                  ...
                  <button onClick={() => setIsLriModalOpen(true)} type="button">
                    See more
                  </button>
                </>
              ) : (
                ''
              )}
              {!hiddenValues.licensingInfo ? 'NIL' : ''}
            </div>
            <TextModal
              content={hiddenValues.licensingInfo}
              isVisible={isLriModalOpen}
              onClose={() => setIsLriModalOpen(false)}
              title="Licensing / Regulatory Info."
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TrainingProductForm;
