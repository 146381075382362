/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';

import ButtonSpinnerIcon from '../../../../../../../../../../assets/icons/refactor/button-spinner.svg';
import CheckImg from '../../../../../../../../../../assets/icons/checked.svg';
import TickWhiteImg from '../../../../../../../../../../assets/icons/tick-new.svg';
import {FilePreviewContainer} from './elements';
import {
  unlinkFileFromTask,
  reusedFileLinkedToTask,
  setNewLinkedFileToTask,
  studentWebSelector,
} from '../../../../../../../../../../redux/studentWebSlice';
import ImageIcon from '../../../../../../../../../../assets/images/filetypes/img.svg';
import DocIcon from '../../../../../../../../../../assets/images/filetypes/doc.svg';
import CsvIcon from '../../../../../../../../../../assets/images/filetypes/csv.svg';
import FileIcon from '../../../../../../../../../../assets/icons/document-instruction.svg';
import imageAudio from '../../../../../../../../../../assets/images/filetypes/audio-modal-image.png';
import {LinkedTask} from './components';
import {Modal} from '../../../../../../../../../../components/common';
import {showToast} from '../../../../../../../../../../components/common/Toast';

const DELAY = 500;
export const FilePreviewReuse = ({
  previewFileData,
  addToPortFolio,
  setAddToPortFolio,
  setIsTaskLinked,
  isTaskLinked,
  assessmentID,
  taskId,
}) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [deleteId, setDeleteId] = useState(null);
  const [timeOfVideo, setTimeOfVideo] = useState(null);
  const [deleteLinked, setDeleteLinked] = useState(null);
  const {isAddingFiles, isDeleteFiles, selectedFile, newLinkedFile} =
    useSelector(studentWebSelector);

  useEffect(() => {
    dispatch(setNewLinkedFileToTask(null));
  }, [dispatch]);

  const onStop = () => {
    if (videoRef?.current) {
      const secs = Math.floor(videoRef?.current?.currentTime);
      const hours = Math.floor(secs / (60 * 60));
      const divisorForMinutes = secs % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);
      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.floor(divisorForSeconds);
      if (hours > 0)
        setTimeOfVideo(
          `${hours}:${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`,
        );
      setTimeOfVideo(`${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`);
    }
  };

  const renderPreview = useCallback(() => {
    const fileExt = previewFileData?.fi_filename?.substring(
      previewFileData?.fi_filename?.lastIndexOf('.') + 1,
    );
    const renderIcon = ext => {
      if (ext === 'svg') {
        return ImageIcon;
      }
      if (ext === 'csv') {
        return CsvIcon;
      }
      if (ext === 'doc' || ext === 'docx') {
        return DocIcon;
      }
      return FileIcon;
    };
    if (previewFileData?.fileType?.ft_type?.includes('Image')) {
      return (
        <img
          src={previewFileData?.fi_location}
          alt="cloud"
          className="image-file"
        />
      );
    }
    if (previewFileData?.fileType?.ft_type?.includes('Video')) {
      return (
        <div className="videos">
          <video
            controls
            src={previewFileData?.fi_location}
            width="100%"
            height="100%"
            ref={videoRef}
            onPause={onStop}
          >
            <track
              default
              kind="captions"
              srcLang="en"
              src={previewFileData?.fi_location}
            />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      );
    }
    if (previewFileData?.fileType?.ft_type?.includes('Voice')) {
      return (
        <div className="audios" src={previewFileData?.fi_location} width="100%">
          <img
            src={imageAudio}
            alt="audio icon"
            style={{
              height: '200px',
              width: '200px',
              marginTop: '80px',
            }}
          />
          <audio controls>
            <source
              src={previewFileData?.fi_location}
              type="audio/mpeg"
              className="source-audio"
            />
          </audio>
        </div>
      );
    }

    if (previewFileData?.fileType?.ft_type?.includes('Document')) {
      return (
        <div className="file-data">
          {fileExt === 'pdf' ? (
            <div style={{height: '500px'}}>
              <embed
                src={previewFileData?.fi_location}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <img
              src={previewFileData?.fi_thumbnail || renderIcon(fileExt)}
              alt=""
              className="preview-Icon"
            />
          )}
        </div>
      );
    }
    return FileIcon;
  }, [
    previewFileData?.fi_filename,
    previewFileData?.fileType?.ft_type,
    previewFileData?.fi_location,
    previewFileData?.fi_thumbnail,
  ]);

  const linkedTask = useMemo(
    () => [
      ...selectedFile?.fileLinks.filter(item => {
        if (item.fl_id_activity_item === Number(taskId)) {
          return {...item};
        }
        return null;
      }),
    ],
    [selectedFile, taskId],
  );

  return (
    <FilePreviewContainer>
      <div
        className="container-preview"
        style={
          previewFileData?.fileType?.ft_type?.includes('Image')
            ? {backgroundColor: '#535659'}
            : {}
        }
      >
        {renderPreview()}
      </div>
      <div className="preview-bottom">
        <div>
          <p className="file-name">{previewFileData?.fi_filename}</p>
          <p className="file-size">
            {moment(previewFileData?.fi_uploadedate).format('DD MMMM YYYY')}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="portfolio-btn"
            type="button"
            onClick={() => {
              setAddToPortFolio(state => !state);
            }}
          >
            <div className="left-div">
              <button type="button" className="checkbox">
                {addToPortFolio ? (
                  <img
                    src={CheckImg}
                    alt="checked"
                    width="19px"
                    height="19px"
                  />
                ) : (
                  ''
                )}
              </button>
              <div className="left-div-content">
                <span className="select-teacher-type">Add to portfolio</span>
              </div>
            </div>
          </button>
          <button
            className="footer-button"
            onClick={() => {
              if (!isTaskLinked && !isAddingFiles) {
                const data = {
                  previewFileData,
                };
                if (videoRef?.current) {
                  data.currentTime = videoRef?.current?.currentTime;
                }
                dispatch(
                  reusedFileLinkedToTask(
                    data,
                    addToPortFolio,
                    assessmentID,
                    taskId,
                    () => {
                      setIsTaskLinked(true);
                    },
                  ),
                );
              }
              if (isTaskLinked && newLinkedFile) {
                dispatch(
                  unlinkFileFromTask(
                    newLinkedFile.fl_id_filelink,
                    newLinkedFile,
                    assessmentID,
                    taskId,
                    () => {
                      setIsTaskLinked(false);
                      dispatch(setNewLinkedFileToTask(null));
                    },
                  ),
                );
              }
            }}
            style={
              isTaskLinked
                ? {
                    backgroundColor: '#4EC977',
                  }
                : {}
            }
            type="button"
          >
            {isTaskLinked ? <img src={TickWhiteImg} alt="tick" /> : null}

            {!isTaskLinked ? 'Link Task ' : 'Task Linked '}
            {timeOfVideo || null}
            {!isTaskLinked && isAddingFiles ? (
              <img
                alt="Loading"
                className={classNames('spinner')}
                src={ButtonSpinnerIcon}
              />
            ) : null}
          </button>
        </div>
      </div>
      {linkedTask?.length ? (
        <div className="d-flex flex-column">
          <div className="seperator" />
          <p className="container-task">
            Linked Tasks{' '}
            <span className="task-length">{linkedTask?.length} Task</span>
          </p>
          {linkedTask?.map(item => (
            <LinkedTask
              linkTask={item}
              onClick={() => {
                setDeleteId(item?.fl_id_filelink);
                setDeleteLinked(item);
              }}
            />
          ))}
        </div>
      ) : null}
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this following task ?"
        isDelete
        width="55.5rem"
        isButtonLoading={isDeleteFiles}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          if (!isDeleteFiles)
            dispatch(
              unlinkFileFromTask(
                deleteId,
                deleteLinked,
                assessmentID,
                taskId,
                () => {
                  setDeleteId(null);
                  showToast(false, `Linked task deleted successfully`, true);
                },
              ),
            );
        }}
        onHide={() => {
          setDeleteId(null);
          setTimeout(() => {
            setDeleteLinked(null);
          }, DELAY);
        }}
        show={!!deleteId}
        title="Delete Linked Task"
      >
        <div
          style={{
            padding: '1rem',
            border: '0.0625rem solid #e0e0e0',
            boxSizing: 'border-box',
            borderRadius: '0.5rem',
            margin: '1rem',
          }}
        >
          <div className="d-flex">
            <div
              style={{
                fontWeight: '500',
                fontSize: '1rem',
                color: '#0c181f',
              }}
            >
              {deleteLinked?.activityItem?.ai_activityitem}
            </div>
          </div>
          <div
            style={{
              fontWeight: '400',
              fontSize: '0.875rem',
              color: '#6d7579',
              marginTop: '0.5rem',
            }}
          >
            {deleteLinked?.activityItem?.ai_activityitem_description}
          </div>
        </div>
      </Modal>
    </FilePreviewContainer>
  );
};
