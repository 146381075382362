import {Modal} from 'react-bootstrap';
import {BodyWrapper, FooterWrapper} from './elements';
import useConfirm from '../../hooks/useConfirm';
import Avatar from '../Avatar';

const RequestModal = () => {
  const {promptData, isOpen = false, proceed, cancel} = useConfirm();

  if (!promptData) {
    return null;
  }
  return (
    <Modal show={isOpen} centered>
      <Modal.Body>
        <BodyWrapper>
          {promptData.avatar ? (
            <Avatar srcImage={promptData.avatar} />
          ) : (
            <div>
              <span className="align-items-center circle d-flex justify-content-center student">
                {promptData.firstname[0]}
                {promptData.lastname[0]}
              </span>
            </div>
          )}
          <p>
            {`${promptData.firstname} ${promptData.lastname} `}
            <span style={{color: 'gray'}}>
              is requesting to share the screen with the class
            </span>
          </p>
        </BodyWrapper>
      </Modal.Body>
      <Modal.Footer>
        <FooterWrapper>
          <button type="button" onClick={cancel}>
            Not now
          </button>
          <button type="button" onClick={proceed}>
            Accept
          </button>
        </FooterWrapper>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestModal;
