import styled from 'styled-components';

export const LibraryContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100vh;
  margin: 0 -0.9375rem;
  padding: 7.0625rem 2rem 4.0625rem 7rem;

  @media (max-width: 55.5rem) {
    & {
      padding: 7.0625rem 2rem 4.0625rem 7rem;
    }
    .search-div {
      .library-tab {
        width: 13.5rem;
        max-width: 100%;
      }
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 7.0625rem 1.5rem 4.0625rem 1.5rem;
    }
  }
  @media (max-width: 30rem) {
    & {
      padding: 7.0625rem 1.5rem 4.0625rem 1.5rem;
    }
    .library-searc-add {
      justify-content: flex-end !important;
    }
    .add-doc-button {
      width: 7% !important;
      img {
        margin-right: unset !important ;
      }
      .label {
        display: none;
      }
    }
    .search-div {
      .library-tab {
        width: 12rem;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (max-width: 17rem) {
    & {
      margin: 0 -3.3rem 0 -0.935rem;
      overflow-x: hidden !important;
    }
  }
  @media (max-width: 23rem) {
    .search-div {
      .library-tab {
        width: 9rem;
        max-width: 100%;
      }
    }
  }
  /* @media (max-width: 17rem) {
    & {
      margin: 0 -3.3rem 0 -0.935rem;
      overflow-x: hidden !important;
    }
    .search-div {
      .library-tab {
        width: 6rem;
        max-width: 100%;
      }
    }
  } */

  .title {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .library-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .library-searc-add {
    display: flex;
    justify-content: space-between;
  }

  .page-title {
    color: ${({theme}) => theme.colors.text};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.81rem;
  }

  .search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    /* overflow-x: scroll; */
    padding: 0rem 0rem 0rem 0rem;
    border-bottom: 0.0625rem solid #dedede;
    margin-top: 2rem;
    height: 3rem;

    .public {
      width: 5.75rem !important;
      max-width: 100%;
    }
    .bottom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      font-weight: 500;
      font-size: 1rem;
      outline: none;
      color: #aaaaaa;
      height: 100%;
      width: 15.75rem;
      max-width: 100%;
      .css-1hb7zxy-IndicatorsContainer {
        margin-left: 2rem;
        .dropdown-indicator-icon {
          margin-right: unset !important;
        }
      }
      &.link {
        border-bottom: 0.125rem solid #40a1ff !important;
        color: #3d4457;
        height: 100%;
      }
      p {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
  .library-tab {
    height: 100%;
  }
  .files-div {
    display: flex;
    flex-flow: wrap;
    margin-left: unset !important;
    margin-right: unset !important;
    justify-content: space-between;
  }

  .file-card {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
  }
  @media (max-width: 36rem) {
    .title-code {
      flex-direction: column;
    }
    .code,
    .document-title {
      width: unset !important;
      margin-left: unset !important;
    }
    .footer .button-container {
      margin-right: unset !important;
    }
  }
  .add-menu-list {
    top: -2.2rem !important;

    .add-menu-item-list {
      margin-top: 0.5rem;
      padding: unset;
      border-radius: 0.3125rem;

      transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s;
      background-color: rgb(64, 161, 255) !important;
      cursor: pointer !important;
    }
  }
  .add-menu {
    padding: unset;
    border: unset;
  }
  .add-menu-item {
    width: unset;
    margin: unset;
    padding: unset;
    border-radius: 0.3125rem;
    transition: background-color 0.1s ease 0s, box-shadow 0.1s ease 0s;
    background-color: rgb(64, 161, 255) !important;
    cursor: pointer !important;
  }
  .add-category-btn {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    color: #ffffff;
  }
  .header-title {
    padding: 2.5rem 2rem 2rem 2rem;
    background: white;
    border-bottom: 0.0625rem solid ${({theme}) => theme.colors.border};
  }
  .card-class {
    height: 0;
    padding-bottom: calc(25% - 1.875rem);
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    .card-class {
      height: 0;
      padding-bottom: calc(50% - 1.875rem);
    }
  }
  @media screen and (min-width: 760px) and (max-width: 992px) {
    .card-class {
      height: 0;
      padding-bottom: calc(33.33333% - 1.875rem);
    }
  }

  @media screen and (min-width: 0px) and (max-width: 575px) {
    .card-class {
      height: 0;
      padding-bottom: calc(100% - 1.875rem);
    }
  }
`;
