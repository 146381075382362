/* eslint-disable react/prop-types */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {TextInput, Checkbox} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';
import {
  adminTeachersSelector,
  setError,
  updateAdminTeacher,
} from '../../../../../../../../redux/adminTeacherSlice';
import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {adminTeacherSchema} from '../../../../../../../../utils/validation';
import ExclaimIcon from '../../../../../../../../assets/icons/exclaim.svg';
import {EditAdminTeacherModalContainer} from './elements';
import UpdateUserToastIcon from '../../../../../../../../assets/icons/updateadmintoast.svg';

const FIELDS_IN_ORDER = [
  'referId',
  'firstName',
  'lastName',
  'email',
  'admin',
  'teacher',
];

export const EditAdminTeacherModal = ({editDetails, onHide}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      referId: '',
      firstName: '',
      lastName: '',
      email: '',
      admin: false,
      teacher: false,
    },
    resolver: yupResolver(adminTeacherSchema),
  });
  const {error: serverError, isAdminTeacherLoading} = useSelector(
    adminTeachersSelector,
  );

  useEffect(() => {
    if (editDetails) {
      setValue('referId', editDetails.referId);
      setValue('firstName', editDetails.firstName);
      setValue('lastName', editDetails.lastName);
      setValue('email', editDetails.email);
      setValue('admin', editDetails.profiles.includes('4'));
      setValue('teacher', editDetails.profiles.includes('3'));
    }
  }, [editDetails, setValue]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const onSubmit = useCallback(
    data => {
      if (!isAdminTeacherLoading) {
        dispatch(
          updateAdminTeacher(editDetails?.adminTeacherId, data, () => {
            onHide();
            window.location.reload(false);
            showToast(UpdateUserToastIcon, `User Details updated successfully`);
          }),
        );
      }
    },
    [dispatch, editDetails, isAdminTeacherLoading, onHide],
  );

  const {hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <EditAdminTeacherModalContainer
      buttonLabel="Save Changes"
      description="Make changes to the user details"
      isButtonLoading={isAdminTeacherLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit User details"
      width="55.5rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="d-flex pb-4 px-4">
            <Controller
              control={control}
              name="referId"
              render={fields => (
                <TextInput
                  {...fields}
                  autoFocus
                  className="mr-4"
                  hasError={hasServerError || !!errors.referId}
                  label="Reference ID"
                  placeholder="Enter Reference ID"
                />
              )}
            />
            <Controller
              control={control}
              name="firstName"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mr-4"
                  hasError={hasServerError || !!errors.firstName}
                  label="First Name"
                  placeholder="Enter first name"
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              render={fields => (
                <TextInput
                  {...fields}
                  hasError={hasServerError || !!errors.lastName}
                  label="Last Name"
                  placeholder="Enter last name"
                />
              )}
            />
          </div>
          <div className="d-flex pb-4 px-4">
            <Controller
              control={control}
              name="email"
              render={fields => (
                <TextInput
                  {...fields}
                  hasError={hasServerError || !!errors.email}
                  label="Email"
                  placeholder="Enter email address"
                  isDisabled
                />
              )}
            />
          </div>
          <div className="d-flex flex-column px-4">
            <div className="user-type-head">
              <p className="user-type">User types</p>
              <p className="exclaim-icon">
                <img src={ExclaimIcon} alt="exclaim" className="exclaim" />
              </p>
              <p className="user-type-instruction">
                You can select both option for a user.
              </p>
            </div>
            <div>
              <Controller
                control={control}
                name="admin"
                render={fields => (
                  <Checkbox
                    {...fields}
                    label="Admin"
                    hasError={hasServerError || !!errors.admin}
                  />
                )}
              />
            </div>
            <div className="mt-2">
              <Controller
                control={control}
                name="teacher"
                render={fields => (
                  <Checkbox
                    {...fields}
                    label="Teacher"
                    hasError={hasServerError || !!errors.admin}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </EditAdminTeacherModalContainer>
  );
};

EditAdminTeacherModal.defaultProps = {
  editDetails: null,
};

EditAdminTeacherModal.propTypes = {
  editDetails: PropTypes.shape({
    referID: PropTypes.string.isRequired,
    adminId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    admin: PropTypes.string.isRequired,
    teacher: PropTypes.string.isRequired,
  }),
  onHide: PropTypes.func.isRequired,
};
