import {useState} from 'react';

const useNavbarScroll = (threshold, navbarHeight) => {
  const [navbarTop, setNavbarTop] = useState(0);

  const handleScroll = event => {
    const currentScrollPosition = event.target.scrollTop;

    if (currentScrollPosition <= threshold) {
      setNavbarTop(0);
    } else {
      setNavbarTop(navbarHeight * -1);
    }
  };

  return {
    navbarTop,
    handleScroll,
  };
};

export default useNavbarScroll;
