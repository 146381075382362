import {createSlice} from '@reduxjs/toolkit';

import * as curriculumApi from '../api/curriculumStudent';

const studentCurriculumSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: false,
    isLoading: false,
    courseCurriculum: null,
    isAddCurriculumLoading: false,
    curriculumFile: null,
  },
  name: 'studentCurriculum',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setCourseCurriculum(state, action) {
      state.courseCurriculum = action.payload;
    },
    setIsAddCurriculumLoading(state, action) {
      state.isAddCurriculumLoading = action.payload;
    },

    setCurriculumFile(state, action) {
      state.curriculumFile = action.payload;
    },
  },
});

export const {
  setError,
  setIsListLoading,
  setIsLoading,
  setCourseCurriculum,
  setIsAddCurriculumLoading,
  setCurriculumFile,
} = studentCurriculumSlice.actions;

export const getCurriculumByOrgUnitInstanceId =
  orgUnitInstanceId => async dispatch => {
    dispatch(setIsListLoading(true));
    try {
      const response = await curriculumApi.fetchCurriculumByOrgUnitInstanceId(
        orgUnitInstanceId,
      );

      dispatch(setCourseCurriculum(response.data.allCurriculum));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsListLoading(false));
    }
  };

export const studentCurriculumSelector = state => state.studentCurriculum;

export default studentCurriculumSlice.reducer;
