/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {useDropArea} from 'react-use';

import FilesImage from '../../../../../../../../assets/icons/org-logo.svg';
import DeleteLogoIcon from '../../../../../../../../assets/icons/delete-logo.svg';
import LoaderIcon from '../../../../../../../../assets/icons/orgLogoLoader.svg';
import {OrganisationLogoContainer} from './elements';
import {convertFileSize} from '../../../../../../../../utils/functions/convertFileSize';
import {deleteOrganisationLogo} from '../../../../../../../../redux/basicInfoSlice';

export const UploadOrganisationLogo = ({
  basicInfo,
  isLogoLoading,
  upload,
  setUpload,
}) => {
  const [onDrag, setOnDrag] = useState(false);
  const [fileError, setFileError] = useState(false);

  const dispatch = useDispatch();

  const handleChange = useCallback(
    file => {
      const image = new Image();
      image.addEventListener('load', () => {
        setUpload(file);
        setFileError(false);
      });
      image.src = URL.createObjectURL(file);
    },
    [setUpload],
  );

  const [bond] = useDropArea({
    onFiles: file => {
      handleChange(file[0]);
      setOnDrag(false);
    },
  });

  return (
    <OrganisationLogoContainer className="mt-3">
      <div className="inputfields">
        <span className="logo-label">Organisation Logo</span>
        {upload || basicInfo?.or_logo_url ? (
          isLogoLoading ? (
            <div className="organisation-images mt-2">
              <img src={LoaderIcon} alt="loader" />
            </div>
          ) : (
            <div className="organisation-image-div mt-2">
              <div className="organisation-image-after">
                <img
                  src={
                    upload ? URL.createObjectURL(upload) : basicInfo.or_logo_url
                  }
                  alt="logo"
                />
                <div className="file-details">
                  <span className="file-name">
                    {upload ? upload.name : basicInfo.or_logo_name}
                  </span>
                  <span className="file-size">
                    {convertFileSize(
                      upload ? upload.size : basicInfo.or_logo_size,
                    )}
                  </span>
                </div>
              </div>
              <div className="delete-logo">
                <button
                  type="button"
                  onClick={() => {
                    setUpload(null);
                    dispatch(deleteOrganisationLogo());
                  }}
                  id="delete-logo-btn"
                >
                  <img src={DeleteLogoIcon} alt="logo" />
                </button>
              </div>
            </div>
          )
        ) : (
          <div
            {...bond}
            onDragEnter={() => {
              setOnDrag(true);
            }}
            onDragLeave={() => {
              setOnDrag(false);
            }}
          >
            <div
              className={classNames('organisation-image', 'mt-2', {
                'logo-drag': onDrag,
                'logo-drag-error': fileError,
              })}
            >
              <div className="organisation-logo">
                <img src={FilesImage} className="logo" alt="logo" />
              </div>
              <div className="organisation-text">
                {fileError
                  ? `Drop another file or ${' '}`
                  : `Drop your logo here or ${' '}`}
                <input
                  type="file"
                  onChange={e => {
                    handleChange(e.target.files[0]);
                  }}
                  accept="image/png, image/gif, image/jpeg"
                />
                <span>Browse</span>
              </div>
              <div className="organisation-seperator" />
              <div className="organisation-size">
                Image should be 140 x 160 resolution
              </div>
            </div>
          </div>
        )}
      </div>
    </OrganisationLogoContainer>
  );
};
