import PropTypes from 'prop-types';
import styled from 'styled-components';

const TransparentButtonContainer = styled.button`
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  border: 0.0625rem solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  padding: 0.75rem 0;
  transition: background-color 0.1s;

  &.full-width {
    width: 100%;
  }

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.5);
  }

  :active {
    background-color: #1c80f0;
    border-color: rgba(255, 255, 255, 0.3);
    outline: none;
  }

  :focus {
    outline: none;
  }

  .icon {
    height: 1.25rem;
    width: 1.25rem;
  }

  .text {
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
  }
`;

const TransparentButton = ({className, fullWidth, icon, onClick, text}) => (
  <TransparentButtonContainer
    className={`${fullWidth ? 'full-width' : ''} ${className}`}
    onClick={onClick}
    type="button"
  >
    <img alt="Icon" className="icon mr-2" src={icon} />
    <span className="text">{text}</span>
  </TransparentButtonContainer>
);

TransparentButton.defaultProps = {
  className: '',
  fullWidth: false,
};

TransparentButton.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default TransparentButton;
