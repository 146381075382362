import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  clearFormError,
  academicYearsSelector,
  setError,
} from '../../../../../../../../redux/academicYearsSlice';
import {DateInput} from '../../../../../../../../components/common';

import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {academicYearSchema} from '../../../../../../../../utils/validation';
import {EditAcademicYearContainer} from './elements';

const FIELDS_IN_ORDER = ['startDate', 'endDate'];

export const EditAcademicYearModal = ({
  editDetails,
  isOpen,
  onHide,
  buttonLabel,
  description,
  isButtonLoading,
  loadingButtonLabel,
  onSubmit,
  title,
}) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
    resolver: yupResolver(academicYearSchema),
  });

  const {error: serverError, formError} = useSelector(academicYearsSelector);

  useEffect(() => {
    if (editDetails) {
      setValue('startDate', editDetails.startDate);
      setValue('endDate', editDetails.endDate);
    }
  }, [editDetails, setValue]);

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  return (
    <EditAcademicYearContainer
      buttonLabel={buttonLabel}
      description={description}
      isButtonLoading={isButtonLoading}
      loadingButtonLabel={loadingButtonLabel}
      onButtonClick={handleSubmit(onSubmit)}
      onHide={() => {
        onHide();
        clearServerError();
      }}
      show={isOpen}
      title={title}
      width="41.25rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container px-4">
          <div className="row-container pb-4">
            <div className="field-left">
              <Controller
                control={control}
                name="startDate"
                render={fields => (
                  <DateInput
                    {...fields}
                    errorMessage={
                      errors.startDate?.message || formError.startDate
                    }
                    hasError={!!errors.startDate || !!formError.startDate}
                    label="Start Date"
                    placeholder="-Select-"
                    dateFormat="dd MMMM yyyy"
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="endDate"
                render={fields => (
                  <DateInput
                    {...fields}
                    label="End Date"
                    errorMessage={errors.endDate?.message || formError.endDate}
                    hasError={!!errors.endDate || !!formError.endDate}
                    placeholder="-Select-"
                    dateFormat="dd MMMM yyyy"
                    minDate={watch('startDate')}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </EditAcademicYearContainer>
  );
};

EditAcademicYearModal.defaultProps = {
  editDetails: null,
};

EditAcademicYearModal.propTypes = {
  editDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  onHide: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isButtonLoading: PropTypes.bool.isRequired,
  loadingButtonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
