/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import ArrowHideImg from '../../../../../../../../../../assets/icons/tablehide.svg';
import GreenTickImg from '../../../../../../../../../../assets/icons/donemark.svg';
import ArrowShowImg from '../../../../../../../../../../assets/icons/tableshow.svg';
import {Table} from '../../../../../../../../../../components/common';
import {AccordionContainer, SpreadSheetTableStyles} from './elements';

export const Accordion = ({
  title,
  subtitle,
  alreadyExistQcodeData,
  alreadyExistQnameData,
  spreadsheetcolumns,
  alreadyExistQname,
  alreadyExistQcode,
  duplicateDataError,
}) => {
  const [isQnameExpanded, setIsQnameExpanded] = useState(false);
  const [isQcodeExpanded, setIsQcodeExpanded] = useState(false);
  const [isDuplicateDataExpanded, setIsDuplicateDataExpanded] = useState(false);

  const toggleQualificationName = () => setIsQnameExpanded(!isQnameExpanded);

  const toggleQualificationCode = () => setIsQcodeExpanded(!isQcodeExpanded);

  const toggleDuplicateData = () =>
    setIsDuplicateDataExpanded(!isDuplicateDataExpanded);

  return (
    <AccordionContainer>
      <img src={GreenTickImg} alt="green tick" />
      <h2 className="mt-3 header">{title}</h2>
      <p className="state">{subtitle}</p>
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleQualificationCode}
              >
                <div>qualification code error ({alreadyExistQcode.length})</div>
                <div>
                  <img
                    alt="Show Works"
                    src={isQcodeExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            className={`collapse ${isQcodeExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {alreadyExistQcode && alreadyExistQcode?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={alreadyExistQcodeData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleQualificationName}
              >
                <div>qualification name error ({alreadyExistQname.length})</div>
                <div>
                  <img
                    alt="Show Works"
                    src={isQnameExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${isQnameExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {alreadyExistQname && alreadyExistQname?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={alreadyExistQnameData}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingOne">
            <h2 className="mb-0">
              <button
                className="btn btn-block d-flex justify-content-between align-items-center"
                type="button"
                onClick={toggleDuplicateData}
              >
                <div>
                  Invalid and Duplicate Entries({duplicateDataError.length})
                </div>
                <div>
                  <img
                    alt="Show Works"
                    src={isDuplicateDataExpanded ? ArrowShowImg : ArrowHideImg}
                  />
                </div>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            className={`collapse ${isDuplicateDataExpanded ? 'show' : ''}`}
          >
            <div className="card-body">
              {duplicateDataError && duplicateDataError?.length ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={duplicateDataError}
                    itemName="Users"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </AccordionContainer>
  );
};
