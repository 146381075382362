import styled from 'styled-components';

const HumanResourcesFormContainer = styled.div`
  .serial {
    width: 4.3%;
  }

  .unit {
    width: 41%;
  }

  .teachers {
    width: 54.7%;
  }
`;

export default HumanResourcesFormContainer;
