// ModalContainer.js
import styled from 'styled-components';

const ModalContainer = styled.div`
  padding: 1.5rem;
  width: 90vw;
  height: auto;

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h1 {
      font-weight: 400;
      font-size: 1.5rem;
    }
    p {
      color: #aaaaaa;
    }

    svg {
      cursor: pointer;
    }
  }
  table {
    margin: 1.5rem 0;
    td:nth-child(1) {
      width: 3.125rem;
    }
    td:nth-child(4) {
      max-width: 12.5rem;
      padding-right: 1rem;
      button {
        background: transparent;
        color: ${({theme}) => theme.colors.primary};
        border: none;
      }
    }
  }
  .status-container {
    display: flex;
    flex-direction: row;
  }

  .status-1,
  .status-2,
  .status-3,
  .status-4,
  .status-5 {
    border-radius: 0.3125rem;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.21;
    padding: 0.5rem 1rem;
  }
  .status-1 {
    background-color: #fcf3cf;
    color: #fcca3d;
  }
  .status-2 {
    background-color: #f3f3f3;
    color: #8a8c94;
  }
  .status-3 {
    background-color: #e0fff8;
    color: #15d3c8;
  }
  .status-4 {
    background-color: #ffe1e1;
    color: #ff8787;
  }
  .status-5 {
    background-color: #defae8;
    color: #4ec977;
  }
`;

export default ModalContainer;
