/* eslint-disable react/prop-types */
// import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AssessmentSearchItemsContainer} from './elements';
import {
  // getDulipatedAssessement,
  myAssessementAddToClass,
} from '../../../Assesments/Assessment/slices/assessmentSlice';

// THIS IS ASSESSMENT SEARCH ITEMS COMPONENT

export const AssessmentSearchItems = ({
  searchAssessments,
  assessmentLoading,
}) => {
  const [classId, setClassId] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();
  const {pathname} = history.location;
  useEffect(() => {
    if (/stream/.test(pathname)) {
      const Id = pathname.split('/')[2];
      setClassId(Id);
    } else {
      setClassId('');
    }
  }, [pathname]);

  if (!classId) return null;

  return (
    <div className="mt-3 assessment-search-list">
      {searchAssessments?.map(searchAssessment => (
        <AssessmentSearchItemsContainer
          key={searchAssessment.aoui_id_activity}
          onClick={() => {
            // getting duplicated data for assessment
            // if (searchAssessment?.aoui_id_activityorgunitinstance) {
            //   console.log('duplicate');
            //   dispatch(
            //     getDulipatedAssessement(
            //       searchAssessment.aoui_id_activity,
            //       classId,
            //       history,
            //     ),
            //   );
            // } else {
            if (!assessmentLoading) {
              dispatch(
                myAssessementAddToClass(
                  searchAssessment?.ac_id_activity,
                  classId,
                  history,
                ),
              );
            }
            // }
          }}
        >
          <p className="search-assessment-title">
            {searchAssessment?.ac_activity_name}
          </p>
          <div className="publish-details">
            {/* <p className="publish-text">Published</p>
            <p className="publish-seperator">&bull;</p>
            <p className="publish-date">
              {moment(searchAssessment.aoui_timestamp_published).format(
                'D MMM YYYY',
              )}
            </p> */}
          </div>
        </AssessmentSearchItemsContainer>
      ))}
    </div>
  );
};
