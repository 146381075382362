/* eslint-disable react/prop-types */
import React from 'react';
import CheckImg from '../../../../../../../../../../assets/icons/checked.svg';

export const CheckBox = ({isChecked, onCheck, id, isDisabled}) => (
  <button
    type="button"
    className="checkbox"
    onClick={() => {
      onCheck();
    }}
    disabled={isDisabled}
    id={id}
  >
    {isChecked ? (
      <img src={CheckImg} alt="checked" width="19px" height="19px" />
    ) : (
      ''
    )}
  </button>
);
