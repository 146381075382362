/* eslint-disable react/prop-types */

import React, {useRef, useEffect} from 'react';
import ReactBootstrapModal from 'react-bootstrap/Modal';

import CustomBox from '../../../../../../../../../../../../../../../components/shared/CustomBox';
import ModalCloseIcon from '../../../../../../../../../../../../../../../assets/icons/instruction-modal-close.svg';
import TextModalImg from '../../../../../../../../../../../../../../../assets/icons/text-modal.svg';
import CustomButton from '../../../../../../../../../../../../../../../components/shared/CustomButton';
import {TextModalContainer} from './elements';

export const TextModal = ({
  isEdit,
  onClose,
  onSubmit,
  setTextInstruction,
  textInstruction,
  visibility,
}) => {
  const focusDiv = useRef();

  useEffect(() => {
    if (visibility) focusDiv?.current?.focus();
  }, [visibility]);

  return (
    <ReactBootstrapModal
      centered
      show={visibility}
      contentClassName="task-text-modal"
    >
      <TextModalContainer className="text-modal-custom-full">
        <CustomBox className="text-modal-container" padding="1.5rem">
          <div className="text-modal-header">
            <div className="text-modal-title">
              <img alt="Text Instruction" src={TextModalImg} />
              <h2>{isEdit ? 'Edit' : 'Add'} Text Instruction</h2>
            </div>
            <button
              className="modal-close-button"
              onClick={onClose}
              type="button"
            >
              <img alt="Close Modal" src={ModalCloseIcon} />
            </button>
          </div>
          <textarea
            ref={focusDiv}
            className="text-instruction-input"
            onChange={event => setTextInstruction(event.target.value)}
            placeholder="Enter a text instruction here"
            rows={8}
            value={textInstruction}
          />
          <div className="modal-buttons">
            <button
              className="cancel-button"
              onClick={
                textInstruction.length ? () => setTextInstruction('') : () => {}
              }
              style={{
                color: !textInstruction.length ? '#b8bac7' : '#3d4457',
                cursor: !textInstruction.length ? 'not-allowed' : 'pointer',
              }}
              type="button"
            >
              Clear
            </button>
            <CustomButton
              isDisabled={!textInstruction.length}
              onClick={onSubmit}
              text={isEdit ? 'Update' : 'Add Instruction'}
            />
          </div>
        </CustomBox>
      </TextModalContainer>
    </ReactBootstrapModal>
  );
};
