/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import CustomBox from '../../../../../../../../components/shared/CustomBox';
import ProfileImg from '../../../../../../../../assets/icons/profile.svg';

export const ClosedPostForm = ({isEditing, toggleShareBox, avatar}) => (
  <CustomBox padding="1.5rem" margin={isEditing ? '0 0 1.5rem 0' : null}>
    <div className="share-post-closed fade-in">
      <img
        src={avatar || ProfileImg}
        alt="profile-img"
        className="rounded-circle"
      />

      <span role="button" onClick={() => toggleShareBox(true)} id="post-box">
        Share something with your class...
      </span>
    </div>
  </CustomBox>
);
