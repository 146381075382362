import styled from 'styled-components';

export const RadioContainer = styled.button`
  background-color: transparent;
  border: none;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')} !important;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')} !important;
  :active,
  :focus {
    outline: none;

    .radio-button-container {
      box-shadow: 0 0 0 0.125rem
        ${({disabled, theme}) => (disabled ? '' : theme.colors.lightPrimary)} !important;
      border-color: ${({disabled, theme}) =>
        disabled ? '' : theme.colors.primary} !important;
    }
  }

  .radio-button-container {
    border: 0.0625rem solid ${({theme}) => theme.colors.border};
    border-radius: 0.625rem;
    cursor: ${({disabled}) =>
      disabled ? 'not-allowed' : 'pointer'} !important;
    height: 1.25rem;
    overflow: hidden;
    transition: border-color 0.1s, box-shadow 0.1s;
    width: 1.25rem;

    &.has-error {
      border-color: ${({theme}) => theme.colors.danger};
    }

    &.is-chosen {
      border-color: ${({disabled, theme}) =>
        disabled ? '#9e9e9e' : theme.colors.primary} !important;

      .radio-button-active-indicator {
        background-color: ${({disabled, theme}) =>
          disabled ? '#9e9e9e' : theme.colors.primary} !important;
      }
    }

    .radio-button-active-indicator {
      background-color: transparent;
      border-radius: 50%;
      height: 100%;
      transition: background-color 0.1s;
    }
  }

  .label {
    color: ${({theme}) => theme.colors.text};
    cursor: ${({disabled}) =>
      disabled ? 'not-allowed' : 'pointer'} !important;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
`;
