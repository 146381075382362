import styled from 'styled-components';

export const ReassignModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 41.25rem;
  @media (max-width: 41.25rem) {
    & {
      padding: 1.25rem 1.25rem;
      overflow-y: scroll;
      height: 100%;
      width: 100%;
      ::-webkit-scrollbar {
        display: none;
      }
    }
    padding-bottom: 10%;
    ::-webkit-scrollbar {
      display: none;
    }
    .reassign-modal-custom-full {
      width: 100% !important;
      margin-top: unset;
      padding-bottom: unset;
      .reassign-modal-wrapper {
        width: 100%;
      }
      .reassign-modal-close {
        display: block;
      }

      .close-btn-reassign {
        margin-bottom: 0.375rem;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: transparent;
          border: none;
          padding: 0;

          :active,
          :focus {
            outline: none;
          }

          img {
            height: 1.5rem;
            vertical-align: unset;
            width: 1.5rem;
          }
        }
      }
    }
  }

  .reassign-modal-close {
    display: none;
  }
  @media (max-width: 56.25rem) {
    .reassign-modal-wrapper {
      width: 100% !important;
    }
  }
  .reassign-modal-wrapper {
    background-color: #fff;
    border-radius: 0.75rem;

    @media (max-width: 43.75rem) {
      /* & {
        max-width: %;
      } */
      .top {
        p {
          padding: 0rem 3.06rem;
          text-align: center;
        }
        img {
          height: 4.5rem !important;
          width: 4.5rem !important;
        }
        .to-arrow {
          height: 0.687rem !important;
          width: 1.375rem !important;
        }
        h2 {
          padding: 0rem 1.75rem;
          text-align: center;
        }
      }
      .graphics {
        margin-bottom: 1.5rem !important;
      }
      .bottom {
        padding: 1rem 1.5rem 1.5rem 1.5rem !important;
      }
    }

    .top {
      align-items: center;
      background-color: rgba(64, 161, 255, 0.05);
      border-bottom: 0.0625rem solid rgba(64, 161, 255, 0.2);
      display: flex;
      flex-direction: column;
      padding: 4.56rem 0 2rem 0;

      .graphics {
        align-items: center;
        display: flex;
        margin-bottom: 2rem;

        img:not(:nth-child(2)) {
          height: 6rem;
          width: 6rem;
        }

        img:nth-child(2) {
          height: 1.06rem;
          margin: 0 2.25rem;
          width: 2.156rem;
        }
      }

      h2 {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 0;

        :last-of-type {
          margin-bottom: 1rem;
        }
      }

      p {
        color: #aaa;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }

    .bottom {
      padding: 1.5rem;

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        p {
          color: #aaa;
          font-size: 0.875rem;
          font-weight: normal;
          margin-bottom: 0.562rem;

          span {
            font-style: italic;
          }
        }

        textarea {
          border: 0.0625rem solid #dedede;
          border-radius: 0.312rem;
          color: #3d4457;
          font-size: 0.875rem;
          font-weight: normal;
          padding: 0.687rem 1rem;
          resize: none;
          width: 100%;

          :active,
          :focus {
            outline: none;
          }

          ::-webkit-input-placeholder {
            color: #aaa;
          }
          ::-moz-placeholder {
            color: #aaa;
          }
          :-ms-input-placeholder {
            color: #aaa;
          }
          :-moz-placeholder {
            color: #aaa;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;

        button {
          :active,
          :focus {
            outline: none;
          }
        }

        .cancel-button {
          background-color: transparent;
          border: none;
          color: #40a1ff;
          font-size: 0.875rem;
          font-weight: 500;
          margin-right: 1.5rem;
        }

        .send-button {
          background-color: #40a1ff;
          border: none;
          border-radius: 0.5rem;
          color: #fff;
          font-size: 1rem;
          font-weight: 500;
          padding: 0.5rem 1.5rem;
        }
      }
    }
  }
`;
