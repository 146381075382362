/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Dropdown = styled.div`
  position: absolute;
  right: ${props => props.right};
  top: ${props => (props.top ? props.top : 'auto')};
  min-width: ${props => (props.width ? props.width : '10rem')};
  z-index: 2;
  background: #fff;
  border: 0.0625rem solid #dedede;
  border-radius: 0.3125rem;
  padding: 0.5rem;

  .dropdown-item-seperator {
    background-color: #dedede;
    height: 0.0625rem;
    width: 100%;
  }

  .btn {
    border-radius: 0.25rem;
    font-size: 0.875rem;

    :active,
    :hover {
      background-color: rgba(64, 161, 255, 0.1);
    }

    &.button-is-danger {
      color: #eb5757;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 0.5rem 0.75rem;
    .btn {
      font-size: 0.875rem;
    }
  }

  li:hover {
    background-color: rgba(64, 161, 255, 0.1);
    cursor: pointer;
  }
  .form-control,
  .form-control:focus {
    height: 2.5rem;
    border: 0.0625rem solid #e6e6e6;
  }
`;
