/* eslint-disable react/prop-types */
import React, {useEffect, useState, useMemo} from 'react';

import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';

import {useHistory, useLocation, useParams} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import {qualificationsSelector} from '../../../../../../../../redux/qualificationsSlice';

import {
  FullContainer,
  AddQualificationUnitContainer,
  SpreadSheetUnitTableStyles,
} from './elements';

import {
  AccordionForUnits,
  UploadSectionUnits,
} from '../AddQualificationsAndUnit/components';

import {AddUnitsFooter, AddUnitsHeader, ManualFormForUnit} from './components';

import {
  ValidateLoader,
  SuccessMessage,
  NoUserAdded,
} from '../../../../components';

import CustomBox from '../../../../../../../../components/shared/CustomBox';

import {fetchBasicInfo} from '../../../../../../../../redux/adminTeacherSlice';
import {Table} from '../../../../../../../../components/common';

export const AddUnitsToQualification = () => {
  const location = useLocation();
  const {qualificationId} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {alreadyExistUcode, validUnitsToQualification, duplicateUnitData} =
    useSelector(qualificationsSelector);

  const [steps, setSteps] = useState(1);

  const [upload, setUpload] = useState(null);

  const [spreadSheet, setSpreadSheet] = useState([]);

  const [isValidating, setIsValidating] = useState(false);

  const [fileImport, setImport] = useState(true);
  const [nextShow, setNextShow] = useState(false);

  const [manual, setManual] = useState(false);

  const [formShow, setFormShow] = useState(true);
  const [btnShow, setBtnShow] = useState(true);
  const [fileName, setFileName] = useState('');
  const [addUnits, setAddUnits] = useState([]);
  const [onDrag, setOnDrag] = useState(false);

  const [qualificationData] = useState({
    qualificationId: qualificationId || '',
    qualificationName: location.state?.qualificationName || '',
  });

  useEffect(() => {
    dispatch(fetchBasicInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add a serial number to each row and memoize the data.
  // spreadsheet map

  const unitsColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'UNIT CODE',
        accessor: 'unit_code',
        className: 'unit-code',
      },
      {
        Header: 'UNIT NAME',
        accessor: 'unit_name',
        className: 'unit-name',
      },
      {
        Header: 'UNIT TYPE',
        Cell: ({row: {original}}) => {
          const unitTypeInSpread = original?.unit_type;
          return unitTypeInSpread?.toLowerCase() === 'core' ? (
            <div className="type-container">
              <div className="core">{unitTypeInSpread?.toUpperCase()}</div>
            </div>
          ) : (
            <div className="type-container">
              <div className="elective">{unitTypeInSpread?.toUpperCase()}</div>
            </div>
          );
        },
        accessor: 'unit_type',
        className: 'unit-types',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, duplicateUnitData],
  );

  const alreadyExistUcodeData = useMemo(
    () => [
      alreadyExistUcode.map((item, index) => ({
        item,
        serial: index + 1,
      })),
    ],
    [alreadyExistUcode],
  );
  const duplicateUnitDataError = useMemo(
    () => [
      ...duplicateUnitData.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [duplicateUnitData],
  );
  const spreadSheetUnitData = useMemo(
    () => [
      ...spreadSheet.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [spreadSheet],
  );

  return (
    <>
      <Helmet>
        <title>Add Units | JungleCat</title>
      </Helmet>
      <FullContainer>
        <AddUnitsHeader />

        <AddQualificationUnitContainer>
          <div className="container-admin">
            <div className="adminheading d-flex justify-content-between  mb-2">
              <h2 style={{fontSize: '1.5rem'}}>
                {steps >= 1
                  ? `Add units to ${qualificationData?.qualificationName}`
                  : null}
              </h2>
              <p className="sub-text">Add units under a qualificationss</p>
            </div>
            <CustomBox padding="0.0625rem 1.25rem" className="custombox">
              {/* Add Unit flow Started */}
              {steps === 1 ? (
                <div className="search-div">
                  <button
                    className={fileImport ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(true);
                      setManual(false);
                    }}
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manual ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(false);
                      setManual(true);
                    }}
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}
              {manual && steps === 1 ? (
                <ManualFormForUnit
                  manual={manual}
                  qualificationData={qualificationData}
                  formShow={formShow}
                  btnShow={btnShow}
                  setFormShow={setFormShow}
                  setBtnShow={setBtnShow}
                  addUnits={addUnits}
                  setAddUnits={setAddUnits}
                />
              ) : (
                <div
                  className="inputfields"
                  style={steps !== 1 ? {padding: '0rem 0rem 0rem 0rem'} : null}
                >
                  {steps === 1 && fileImport ? (
                    <UploadSectionUnits
                      steps={steps}
                      setUpload={setUpload}
                      upload={upload}
                      onDrag={onDrag}
                      setOnDrag={setOnDrag}
                      setSpreadSheet={setSpreadSheet}
                      setFileName={setFileName}
                      setNextShow={setNextShow}
                    />
                  ) : null}
                  {steps === 2 || isValidating ? (
                    <div className="search-div-spread">
                      <p className="imported">Imported units from document</p>
                      <p className="imported-file">{fileName}</p>
                    </div>
                  ) : null}
                  {isValidating && steps === 2 ? (
                    <ValidateLoader
                      title="Validating imported units details"
                      subtitle="Please wait"
                    />
                  ) : null}

                  {!isValidating &&
                  steps === 3 &&
                  !alreadyExistUcode?.length &&
                  !duplicateUnitData?.length &&
                  validUnitsToQualification?.length === spreadSheet?.length ? (
                    <SuccessMessage
                      title={`${spreadSheet?.length} units has been added successfully`}
                      subtitle="Under each qualification, you may now assign a class with relevant units."
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 3 &&
                  validUnitsToQualification?.length &&
                  (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
                    <AccordionForUnits
                      title={`${validUnitsToQualification?.length}/${spreadSheet?.length} units can be added to this organisation`}
                      subtitle="Some of the entries are incorrect. You can keep adding valid unit entries or re-upload the document with the changes."
                      alreadyExistUcodeData={alreadyExistUcodeData}
                      spreadsheetcolumns={unitsColumns}
                      alreadyExistUcode={alreadyExistUcode}
                      duplicateUnitDataError={duplicateUnitDataError}
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 3 &&
                  !validUnitsToQualification?.length &&
                  (duplicateUnitData?.length || alreadyExistUcode?.length) ? (
                    <NoUserAdded
                      title="No Units added"
                      subtitle="All the Units are already exists in this
                      organisation."
                    />
                  ) : null}
                </div>
              )}

              {!isValidating && spreadSheet?.length && steps === 2 ? (
                <SpreadSheetUnitTableStyles>
                  <Table
                    columns={unitsColumns}
                    data={spreadSheetUnitData}
                    itemName="Units"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetUnitTableStyles>
              ) : null}
            </CustomBox>
          </div>
        </AddQualificationUnitContainer>

        <AddUnitsFooter
          addUnits={addUnits}
          setAddUnits={setAddUnits}
          spreadSheet={spreadSheet}
          setSpreadSheet={setSpreadSheet}
          manual={manual}
          setUpload={setUpload}
          steps={steps}
          setSteps={setSteps}
          setIsValidating={setIsValidating}
          nextShow={nextShow}
          isValidating={isValidating}
          setManual={setManual}
          setNextShow={setNextShow}
          qualificationData={qualificationData}
        />
      </FullContainer>
    </>
  );
};
