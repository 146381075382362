/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import {useRef, useEffect, useContext} from 'react';
import {LiveSessionContext} from '../../../../contexts/LiveSessionContext';
import {SessionPeerVideoScreenWrapper} from './elements';
import {ReactComponent as ScreenIcon} from '../../../../../../../../assets/icons/screen.svg';

const SessionPeerVideoScreen = ({
  audioTrack,
  videoTrack,
  isRemoteSharingScreen,
}) => {
  const ref = useRef();

  const {screenSharingUserDetails} = useContext(LiveSessionContext);

  useEffect(() => {
    if (!ref.current) return null;
    if (videoTrack) videoTrack?.play(ref.current);
    return () => {
      if (videoTrack) {
        videoTrack?.stop();
      }
    };
  }, [ref, videoTrack]);

  useEffect(() => {
    if (audioTrack) audioTrack?.play();
    return () => audioTrack?.stop();
  }, [audioTrack, isRemoteSharingScreen]);

  return (
    <SessionPeerVideoScreenWrapper ref={ref}>
      <div className="peer-info">
        {screenSharingUserDetails ? (
          <span className="screen-sharing">
            <ScreenIcon />
            {`${screenSharingUserDetails.firstname} ${screenSharingUserDetails.lastname}`}
          </span>
        ) : null}
      </div>
    </SessionPeerVideoScreenWrapper>
  );
};

SessionPeerVideoScreen.defaultProps = {
  audioTrack: null,
  videoTrack: null,
  isRemoteSharingScreen: false,
};

SessionPeerVideoScreen.propTypes = {
  audioTrack: PropTypes.objectOf(PropTypes.any),
  videoTrack: PropTypes.objectOf(PropTypes.any),
  isRemoteSharingScreen: PropTypes.bool,
};

export default SessionPeerVideoScreen;
