import styled from 'styled-components';

export const AssessmentSearchItemsContainer = styled.div`
  padding: 1.5rem 0 1.5rem 1.187rem;
  border-bottom: 0.0625rem solid #dedede;
  cursor: pointer;

  .search-assessment-title {
    color: #3d4457;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.187rem;
    margin-bottom: 0;
  }

  .publish-details {
    align-items: center;
    display: flex;
    margin-top: 0.5rem;

    .publish-text {
      color: #aaa;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.21;
      margin-bottom: 0;
    }

    .publish-seperator {
      color: #b8bac7;
      font-size: 0.75rem;
      line-height: 1.21;
      margin: 0 0.5rem;
    }

    .publish-date {
      color: #27ae60;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.21;
      margin-bottom: 0;
    }
  }
`;
