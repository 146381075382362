// eslint-disable-next-line import/prefer-default-export
export const Color = {
  DANGER: '#eb5757',
  DISABLED_PRIMARY: '#80d5ff',
  LIGHT_BLUE: '#cfe7ff',
  LIGHT_TEXT: '#aaa',
  PRIMARY: '#40a1ff',
  PRIMARY_TEXT: '#3D4457',
  SECONDARY_TEXT: '#8A8C94',
  SUCCESS: '#4EC977',
};

export const Colors = {
  Background: '#f4f5f8',
  Border: '#e0e0e0',
  LightPrimary: '#ecf6ff',
  LightText: '#8a8c94',
  LighterText: '#aaa',
  Placeholder: '#c0c0c0',
  Primary: '#40a1ff',
  Text: '#3d4457',
};
const CLASS_STUDENT_PREFIX = 'class-student';
const LIVE_SESSION_PREFIX = 'live-session';

export const SOCKET_EVENTS = {
  CS_ONLINE: `${CLASS_STUDENT_PREFIX}-online`,
  CS_OFFLINE: `${CLASS_STUDENT_PREFIX}-offline`,
  CS_LEAVED: `${CLASS_STUDENT_PREFIX}-leaved`,
  CS_LIST: `${CLASS_STUDENT_PREFIX}-list`,

  LS_CREATE: `${LIVE_SESSION_PREFIX}-create`,
  LS_JOIN: `${LIVE_SESSION_PREFIX}-join`,
  LS_STUDENTS_UPDATED: `${LIVE_SESSION_PREFIX}-students-updated`,
  LS_LEAVE: `${LIVE_SESSION_PREFIX}-leave`,
  LS_NEW_STUDENT_JOINED: `${LIVE_SESSION_PREFIX}-new-student-joined`,
  LS_STUDENT_LEFT: `${LIVE_SESSION_PREFIX}-student-left`,
  LS_CALL_ENDED_BY_HOST: `${LIVE_SESSION_PREFIX}-call-ended-by-host`,
  LS_TOGGLE_VIDEO_STATE: `${LIVE_SESSION_PREFIX}-toggle-video-state`,
  LS_TOGGLE_AUDIO_STATE: `${LIVE_SESSION_PREFIX}-toggle-audio-state`,
  LS_FORCE_VIDEO_MUTE: `${LIVE_SESSION_PREFIX}-force-video-mute`,
  LS_FORCE_AUDIO_MUTE: `${LIVE_SESSION_PREFIX}-force-audio-mute`,
  LS_KICK_OUT_USER: `${LIVE_SESSION_PREFIX}-kickout-user`,
  LS_VIDEO_MUTED_BY_HOST: `${LIVE_SESSION_PREFIX}-video-muted-by-host`,
  LS_AUDIO_MUTED_BY_HOST: `${LIVE_SESSION_PREFIX}-audio-muted-by-host`,
  LS_KICKED_OUT_BY_HOST: `${LIVE_SESSION_PREFIX}-kicked-out-by-host`,
  LS_SEND_MESSAGE: `${LIVE_SESSION_PREFIX}-send-message`,
  LS_NEW_MESSAGE: `${LIVE_SESSION_PREFIX}-new-message`,
  LS_TYPE: `${LIVE_SESSION_PREFIX}-type`,
  LS_TYPING: `${LIVE_SESSION_PREFIX}-typing`,
  LS_STOP_TYPING: `${LIVE_SESSION_PREFIX}-stop-typing`,
  LS_TYPING_STOP: `${LIVE_SESSION_PREFIX}-typing-stop`,
  LS_REQUEST_SCREENSHARE_PERMISSION: `${LIVE_SESSION_PREFIX}-screenshare-request`,
  LS_NEW_SCREENSHARE_REQUEST: `${LIVE_SESSION_PREFIX}-new-screenshare-request`,
  LS_SCREENSHARING_USER_DETAILS: `${LIVE_SESSION_PREFIX}-screensharing-user-details`,
  LS_UPDATE_SCREENSHARING_USER_DETAILS: `${LIVE_SESSION_PREFIX}-update-screensharing-user-details`,
};
