/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';
import {useEffect, useRef, useState} from 'react';

import EmojiIcon from '../../../../../../assets/icons/emoji.svg';
import SidebarHeader from '../SidebarHeader';
import Message from './components/Message';
import {ChatContainer, emojiPickerStyles} from './elements';
import CustomCancel from '../../../../../../components/shared/Buttons/CustomButton';
import chatSendIcon from '../../../../../../assets/icons/sendlogo-chat.svg';

const Chat = ({
  messages,
  onSendMessage,
  userId,
  onStudentListOpen,
  onClose,
  attendeesCount,
  type,
  onTyping,
  stopTyping,
  onTypingStop,
  setNoOfNewMessages,
}) => {
  const isTyping = useRef(false);
  const userTimers = useRef({});
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [text, setText] = useState('');
  const [usersTyping, setUsersTyping] = useState([]);
  const [, triggerRender] = useState();
  const messageContainerRef = useRef(null);
  const messageInputRef = useRef(null);

  const setIsTyping = value => {
    isTyping.current = value;
  };

  const scheduleUserRemoval = id => {
    clearTimeout(userTimers.current[id]);
    userTimers.current[id] = setTimeout(() => {
      setUsersTyping(value => value.filter(item => item.id !== id));
    }, 5000);
  };

  useEffect(() => {
    let timer;
    if (messages?.length) {
      timer = setInterval(() => triggerRender(Math.random()), 60000);
    }
    messageContainerRef.current.scroll({
      top: messageContainerRef.current.scrollHeight,
    });
    setNoOfNewMessages(0);
    return () => timer && clearInterval(timer);
  }, [messages]);

  useEffect(() => {
    let typingTimeout;
    if (text && !isTyping.current) {
      type();
      setIsTyping(true);
      typingTimeout = setTimeout(() => setIsTyping(false), 3000);
    }
    return () => typingTimeout && clearTimeout(typingTimeout);
  }, [text]);

  useEffect(() => {
    onTyping(user => {
      setUsersTyping(value => {
        const isUserNotInList = !value.find(item => item.id === user.id);
        if (isUserNotInList) {
          scheduleUserRemoval(user.id);
          return [...value, user];
        }
        scheduleUserRemoval(user.id);
        return value;
      });
    });

    onTypingStop(id => {
      clearTimeout(userTimers.current[id]);
      setUsersTyping(value => value.filter(item => item.id !== id));
    });
  }, []);

  return (
    <ChatContainer>
      <SidebarHeader
        active="chat"
        onStudentListOpen={onStudentListOpen}
        onClose={onClose}
        attendeesCount={attendeesCount}
      />
      <div className="message-list" ref={messageContainerRef}>
        {messages.map((message, index) => (
          <Message
            key={message.id}
            message={message}
            previousMessage={messages[index - 1] || {}}
            nextMessage={messages[index + 1] || {}}
            isUser={message.senderId === userId}
          />
        ))}
      </div>
      {usersTyping.length ? (
        <div className="users-typing">
          {usersTyping.map(user => (
            <div key={user.id} className="typing-user">
              {user.avatar ? (
                <img src={user.avatar} alt="user avatar" />
              ) : (
                `${user.firstname[0]} ${user.lastname[0]}`
              )}
            </div>
          ))}
          <div className="typing-indicator">
            <span />
            <span />
            <span />
          </div>
        </div>
      ) : null}
      <div className="message-input">
        {isEmojiPickerVisible ? (
          <div
            onBlur={e => {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                setIsEmojiPickerVisible(false);
              }
            }}
          >
            <EmojiPicker
              onEmojiClick={(event, data) => {
                setText(previousValue => previousValue + data.emoji);
                setIsEmojiPickerVisible(!isEmojiPickerVisible);
                messageInputRef.current.focus();
              }}
              pickerStyle={emojiPickerStyles}
            />
          </div>
        ) : null}
        <button
          className="emoji-button"
          onClick={() => {
            setIsEmojiPickerVisible(previousValue => !previousValue);
          }}
          type="button"
        >
          <img src={EmojiIcon} alt="Emoji" />
        </button>
        <input
          ref={messageInputRef}
          type="text"
          placeholder="Type something..."
          value={text}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onChange={event => setText(event.target.value)}
          onKeyUp={event => {
            if (event.code === 'Enter' || event.keyCode === 13) {
              if (text.trim().length) {
                stopTyping();
                onSendMessage(text);
                setText('');
              }
            }
          }}
        />
        <CustomCancel
          className="save-changes"
          bgColor="#40a1ff"
          padding="0.437rem 1rem"
          onClick={() => {
            if (text.trim().length) {
              stopTyping();
              onSendMessage(text);
              setText('');
            }
          }}
        >
          <img src={chatSendIcon} alt="icon" />
        </CustomCancel>
      </div>
    </ChatContainer>
  );
};

Chat.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSendMessage: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  onStudentListOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  attendeesCount: PropTypes.number.isRequired,
  type: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
  stopTyping: PropTypes.func.isRequired,
  onTypingStop: PropTypes.func.isRequired,
  setNoOfNewMessages: PropTypes.func.isRequired,
};

export default Chat;
