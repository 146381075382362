/* eslint-disable react/prop-types */
import React, {useEffect, useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  Select,
  TextInput,
} from '../../../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../../../components/common/TextArea';
import {MappedTask} from './components';
import {FormSectionContainer} from './elements';
import {getAllEvaluationType} from '../../../../../../../Assesments/Assessment/slices/assessmentSlice';
import {authSelector} from '../../../../../../../../../../redux/authSlice';

export const FormSection = ({
  elementGroup,
  register,
  control,
  errors,
  clearErrors,
  performanceCriteriaId,
  performanceEvidenceId,
  knowledgeEvidenceId,
  assessmentConditionId,
  setPerformanceCriteriaId,
  setPerformanceEvidenceId,
  setKnowledgeEvidenceId,
  setAssessmentConditionId,
  elementId,
}) => {
  const assessment = useSelector(state => state.assessment);
  const {orgPreferences} = useSelector(authSelector);
  const {evaluationType} = assessment;
  const dispatch = useDispatch();

  // Check org preference for assessment map is enable
  const assessmentMapPreference = orgPreferences?.find(
    item =>
      item.pr_slug === 'create_assessment_map' && Number(item.pr_value) === 1,
  );

  // console.log(evalType);
  useEffect(() => {
    dispatch(getAllEvaluationType());
  }, [dispatch]);
  const evaluationTypeOption = useMemo(
    () =>
      evaluationType?.map(
        item =>
          ({
            value: item.et_id_eval_type,
            label: item.et_eval_name,
          } || []),
      ),
    [evaluationType],
  );

  const removeErrors = fieldName => {
    if (errors?.[fieldName]) clearErrors(fieldName);
  };
  return (
    <FormSectionContainer>
      <div className="evaluation-title">
        <div className="evaluation-type">
          <Controller
            control={control}
            name="evaluationtype"
            render={fields => (
              <Select
                {...fields}
                hasError={errors?.evaluationtype?.message}
                errorMessage={errors?.evaluationtype?.message}
                className="mb-4"
                isLarge={false}
                label="Evaluation Type"
                placeholder="Select Type"
                options={evaluationTypeOption}
                register={register}
                onChange={e => {
                  fields?.onChange(e);
                  removeErrors('evaluationtype');
                }}
                id="evaluation-type-id"
                // value={evalType}
              />
            )}
          />
        </div>
        <div className="task-title">
          <Controller
            control={control}
            name="taskName"
            render={fields => (
              <TextInput
                {...fields}
                type="text"
                id="taskName"
                label="Task Title"
                placeholder="Enter a title"
                errorMessage={errors?.taskName?.message}
                hasError={errors?.taskName?.message}
                register={register}
                ref={register({required: true})}
                onChange={e => {
                  fields.onChange(e);
                  removeErrors('taskName');
                }}
                // autoFocus
              />
            )}
          />
        </div>
      </div>
      <div>
        <Controller
          control={control}
          name="taskDescription"
          render={fields => (
            <TextArea
              {...fields}
              type="text"
              id="taskDescription"
              label="Description"
              placeholder="Enter a description"
              rows="5"
              height="93px"
              register={register}
              ref={register({required: true})}
              errorMessage={errors?.taskDescription?.message}
              hasError={errors?.taskDescription?.message}
              className="mb-4"
              onChange={e => {
                fields.onChange(e);
                removeErrors('taskDescription');
              }}
            />
          )}
        />
      </div>
      {assessmentMapPreference ? (
        <MappedTask
          elementId={elementId}
          elementGroup={elementGroup}
          performanceCriteriaId={performanceCriteriaId}
          performanceEvidenceId={performanceEvidenceId}
          knowledgeEvidenceId={knowledgeEvidenceId}
          assessmentConditionId={assessmentConditionId}
          setPerformanceCriteriaId={setPerformanceCriteriaId}
          setPerformanceEvidenceId={setPerformanceEvidenceId}
          setKnowledgeEvidenceId={setKnowledgeEvidenceId}
          setAssessmentConditionId={setAssessmentConditionId}
        />
      ) : null}
    </FormSectionContainer>
  );
};
