/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from 'react';

import {UploadContainer} from './elements';
import {FileCard} from '../FileCard';

export const UploadSection = ({upload, title}) => (
  <UploadContainer>
    <div className="upload-title">{title}</div>
    <div className="org-image-full">
      <div className="org-image-after">
        <FileCard file={upload} />
      </div>
    </div>
  </UploadContainer>
);
