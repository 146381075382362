/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

import ProfilePicture from '../../../../assets/icons/profile.svg';
import {MoreOptions} from '../../../../components/common';
import {DropdownItem} from '../../../../components/common/MoreOptions/components';

import {theme} from '../../../../elements';
import {deleteComment, streamSelector} from '../../../../redux/streamSlice';
import {deleteAssessmentComment} from '../../../../redux/assessmentSlice';
import {showToast} from '../../../../components/common/Toast';

function parseUrls(text) {
  const regularExpression = /(https?:\/\/[^\s]+)/g;
  return text.split(regularExpression).map((url, index) => {
    if (url.match(regularExpression)) {
      return (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{
            color: theme.colors.text,
          }}
        >
          {url}
        </a>
      );
    }

    return url;
  });
}

function timeFormat(time) {
  return time && moment(time).fromNow();
}

export const CommentsSection = ({
  item,
  index,
  commentLength,
  setCommentLength,
  activityId,
  isStream,
  userName,
}) => {
  const dispatch = useDispatch();
  const streamState = useSelector(streamSelector);
  const history = useHistory();
  return (
    <div
      className={`comment ${index !== commentLength - 1 ? '' : 'is-alone'}`}
      key={item.acc_id_activity_comment}
    >
      <button
        className="comment-user-info"
        type="button"
        onClick={() => {
          if (item.user.up_id_typeuserprofile === 2) {
            history.push(`/portfolio/${userName}`);
          }
        }}
        id="comment-user-info-id"
      >
        <div>
          <img
            alt={`${item.user.up_name_first} ${item.user.up_name_last}`}
            className="profile-picture rounded-circle"
            src={item?.user.up_avatar ? item?.user.up_avatar : ProfilePicture}
          />
        </div>
        <div className="comment-body">
          <div className="comment-metadata">
            <span className="user-name">
              {item.user.up_name_first} {item.user.up_name_last}
            </span>
            <span className="comment-time">
              • {timeFormat(item.acc_timestamp_creation)}
            </span>
          </div>
          <p className="comment-text">{parseUrls(item.acc_comment)}</p>
        </div>
      </button>
      {!streamState?.classDetails?.orui_is_archived ? (
        <MoreOptions width="1rem" height="1rem" id="more-option">
          <DropdownItem
            id="delete-id"
            isDanger
            label="Delete"
            onClick={() => {
              dispatch(
                isStream
                  ? deleteComment(
                      item?.acc_id_activity_comment,
                      activityId,
                      () => {
                        setCommentLength(prev => prev - 1);
                        showToast(false, 'Comment deleted successfully', true);
                      },
                    )
                  : deleteAssessmentComment(
                      item?.acc_id_activity_comment,
                      activityId,
                      () => {
                        setCommentLength(prev => prev - 1);
                        showToast(false, 'Comment deleted successfully', true);
                      },
                    ),
              );
            }}
          />
        </MoreOptions>
      ) : null}
    </div>
  );
};
