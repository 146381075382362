import {createSlice} from '@reduxjs/toolkit';

import * as academicYearsApi from '../api/admin/academicYears';

const initialFormError = {startDate: '', endDate: ''};

const academicYearsSlice = createSlice({
  initialState: {
    error: null,
    academicYears: null,
    isAcademicYearsLoading: false,
    isListLoading: true,
    formError: initialFormError,
  },
  name: 'academicYears',
  reducers: {
    setAcademicYears: (state, action) => {
      state.academicYears = action.payload;
    },
    setIsAcademicYearsLoading: (state, action) => {
      state.isAcademicYearsLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    appendAcademicYear: (state, action) => {
      state.academicYears = [action.payload, ...state.academicYears];
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    editAcademicYear(state, action) {
      const academicYearIndex = state.academicYears.findIndex(
        item => item.oay_id_orgacadyear === action.payload.oay_id_orgacadyear,
      );
      state.academicYears[academicYearIndex] = action.payload;
    },
    removeAcademicYearById: (state, action) => {
      state.academicYears = state.academicYears.filter(
        academicYear => academicYear.oay_id_orgacadyear !== action.payload,
      );
    },
  },
});

export const {
  setAcademicYears,
  setIsAcademicYearsLoading,
  setError,
  setDepartmentsList,
  setIsListLoading,
  appendAcademicYear,
  editAcademicYear,
  removeAcademicYearById,
  setFormError,
  clearFormError,
} = academicYearsSlice.actions;

export const getAllAcademicYears = () => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const resp = await academicYearsApi.fetchAllAcademicYears();
    dispatch(setIsListLoading(false));
    dispatch(setAcademicYears(resp.data?.orgAllAcademicYear || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};
export const addAcademicYear = (data, callback) => async dispatch => {
  dispatch(setIsAcademicYearsLoading(true));
  try {
    const response = await academicYearsApi.addAcademicYear(data);
    if (response.data?.academicYear) {
      dispatch(appendAcademicYear(response.data?.academicYear));

      if (callback) {
        callback();
      }
    }
  } catch (error) {
    dispatch(
      setError(
        error?.response?.data?.error?.message || 'Something went wrong.',
      ),
    );
    dispatch(setFormError(error?.response?.data?.error));
  } finally {
    dispatch(setIsAcademicYearsLoading(false));
  }
};

export const updateAcademicYear =
  (academicYearId, data, callback) => async dispatch => {
    dispatch(setIsAcademicYearsLoading(true));
    try {
      const response = await academicYearsApi.editAcademicYear(
        academicYearId,
        data,
      );
      if (response.data?.academicYear) {
        dispatch(editAcademicYear(response.data?.academicYear));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAcademicYearsLoading(false));
    }
  };

export const deleteAcademicYear =
  (academicYearId, callback) => async dispatch => {
    dispatch(setIsAcademicYearsLoading(true));

    try {
      const resp = await academicYearsApi.deleteAcademicYear(academicYearId);
      dispatch(setIsAcademicYearsLoading(false));
      dispatch(removeAcademicYearById(resp.data.orgAllAcademicYear));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAcademicYearsLoading(false));
    }
  };

export const academicYearsSelector = state => state.academicYears;

export default academicYearsSlice.reducer;
