import styled from 'styled-components';

const TasFormMain = styled.div`
  overflow-y: auto;
  padding: 0 3rem 0 1.5rem;
  width: 78%;

  .section {
    :not(.last-section) {
      margin-bottom: 4rem;
    }

    > h3 {
      color: ${({theme}) => theme.colors.text};
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.5rem;
      margin-bottom: 0.5rem;
    }

    > p {
      color: #aaa;
      font-size: 0.8rem;
      line-height: 1.21rem;
      margin-bottom: 1.5rem;
    }

    > form {
      background-color: white;
      border-radius: 0.75rem;
      box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
      padding: 1.5rem;

      .tas-form-row {
        display: flex;

        :not(:first-of-type) {
          margin-top: 1.5rem;
        }

        &.is-column {
          flex-direction: column;
        }

        .tas-form-column {
          display: flex;
          flex-basis: 0;
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 0;
          margin: 0 0.75rem;

          :first-child {
            margin-left: 0;
            margin-right: 0.75rem;
          }

          :last-child {
            margin-left: 0.75rem;
            margin-right: 0;
          }

          &.is-read-only {
            background-color: #f6f8fa;
            border-radius: 0.5rem;
            padding: 0.75rem 1rem;
          }

          &.is-full-width {
            margin-left: 0;
            width: 100%;

            &.is-read-only {
              padding: 1rem;
            }

            .column-value {
              line-height: 1.3125rem;
            }
          }

          .label {
            color: ${({theme}) => theme.colors.lightText};
            img {
              height: 1rem;
              margin-left: 0.5rem;
              object-fit: contain;
              width: 1rem;
              cursor: pointer;
            }
          }

          .column-title {
            align-items: center;
            color: ${({theme}) => theme.colors.lightText};
            display: flex;
            font-size: 0.875rem;
            line-height: 1.05875rem;
            margin-bottom: 0.5rem;

            img {
              height: 1rem;
              margin-left: 0.5rem;
              object-fit: contain;
              width: 1rem;
              cursor: pointer;
            }
          }

          .column-value {
            color: ${({theme}) => theme.colors.text};
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.125rem;
          }

          .column-table-title {
            align-items: center;
            color: ${({theme}) => theme.colors.text};
            display: flex;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5rem;
            margin-bottom: 1.5rem;

            img {
              height: 1rem;
              margin-left: 0.5rem;
              object-fit: contain;
              width: 1rem;
              cursor: pointer;
            }
          }

          .column-table-value {
            background-color: #f6f8fa;
            border-radius: 0.5rem;
            color: ${({theme}) => theme.colors.text};
            font-size: 0.875rem;
            line-height: 1.3125rem;
            padding: 0.75rem 1rem;
          }

          .column-value button,
          .column-table-value button {
            background-color: transparent;
            border: none;
            color: ${({theme}) => theme.colors.primary};
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.125rem;
            margin-left: 0.25rem;
          }

          tr:hover {
            background-color: unset;
          }

          .tas-date-picker {
            border-radius: 0.5rem;

            .date-picker {
              font-size: 0.875rem;
              line-height: 1.125rem;
              min-height: 2.625rem;
              padding-left: 1rem;
            }

            .calendar-icon {
              height: 1.25rem;
              margin-right: 0.5rem;
              width: 1.25rem;
            }
          }

          .tas-table-extended {
            overflow-y: auto;
          }

          .table-input {
            width: calc(100% - 1.5rem) !important;

            &.is-last {
              width: calc(100% - 0.75rem) !important;
              margin-left: auto;
            }
          }

          .tas-table-list {
            align-items: flex-start;
            display: flex;
            flex-direction: column;

            .tas-table-list-item {
              align-items: center;
              background-color: ${({theme}) => theme.colors.lighterPrimary};
              border: none;
              border-radius: 0.25rem;
              color: ${({theme}) => theme.colors.text};
              display: flex;
              font-size: 0.75rem;
              line-height: 1.125rem;
              padding: 0.25rem 0.5rem;

              :not(:last-child) {
                margin-bottom: 0.5rem;
              }

              img {
                height: 1rem;
                margin-left: 0.5rem;
                object-fit: contain;
                width: 1rem;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
`;

export default TasFormMain;
