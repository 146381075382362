/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import {FileDetail} from './components';
import {ModuleContainer} from './elements';

import ArrowHideImg from '../../../../../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../../../../../assets/icons/tableshow.svg';
import ModuleDownloadIcon from '../../../../../../../../../../assets/icons/module-download-icon.svg';
import {curriculumFilesDownload} from '../../../../../../../../../../utils/downloadFile';

export const Module = ({item, courseName, position, orgUnitInstanceId}) => {
  const [show, setShow] = useState(true);

  return (
    <ModuleContainer>
      <div className="title-container">
        <div className="title-data">
          <h5 className="title">
            {position}&ensp;
            {`${courseName} | ${item?.ocrm_module_name}`}
          </h5>
        </div>
        <div className="seperator" />
        <div className="d-flex align-items-center justify-content-end btn-container">
          {' '}
          <button
            type="button"
            className="button-download d-flex align-items-center ml-2 mr-3"
            title="download zip"
            onClick={() => {
              curriculumFilesDownload(
                orgUnitInstanceId,
                item?.ocrm_id_course_curriculum_module,
                item?.ocrm_module_name,
              );
            }}
          >
            <img
              className="mb-0 downloadicon"
              alt="arrow"
              src={ModuleDownloadIcon}
            />
          </button>
          {item?.OrgCourseCurriculumFiles.length ? (
            <button
              type="button"
              className="button-download d-flex align-items-center"
              onClick={() => {
                setShow(state => !state);
              }}
            >
              <img
                className="mb-0 mt-1 arrowicon"
                alt="arrow"
                src={show ? ArrowShowImg : ArrowHideImg}
              />
            </button>
          ) : null}
        </div>
      </div>
      <FileDetail
        show={show}
        files={item?.OrgCourseCurriculumFiles}
        position={position}
        orgUnitInstanceId={orgUnitInstanceId}
      />
    </ModuleContainer>
  );
};
