/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ExcelIcon from '../../../../../../../../../../../assets/icons/excel-icon.svg';

export const ExportExcel = ({exportData, fileName}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (csv, fileNewName) => {
    const firstSheetHeading = [
      [
        'Unit Code',
        'Unit Title',
        'Assessment Title',
        'Date',
        'Duration',
        'Location',
        'Teacher Name',
      ],
    ];
    const firstSheetValue = [
      {
        'Unit Code': csv[0]?.instance?.orgUnitInstance?.orgUnit?.unit?.tun_code,
        'Unit Title':
          csv[0]?.instance?.orgUnitInstance?.orgUnit?.unit?.tun_title,
        'Assessment Title': csv[0]?.ac_activity_name,
        Date: moment(csv[0]?.aoui_timestamp_creation).format('Do MMM YYYY'),
        Duration: `${
          csv[0]?.ac_maximum_duration_hours
            ? csv[0]?.ac_maximum_duration_hours
            : 0
        }hrs : ${
          csv[0]?.ac_maximum_duration_minutes
            ? csv[0]?.ac_maximum_duration_minutes
            : 0
        }mns`,
        Location: csv[0]?.ac_location,
        'Teacher Name': `${csv[0]?.up_name_first} ${csv[0]?.up_name_last}`,
      },
    ];

    const secondSheetHeading = [
      ['Overview', 'Resources Required', 'Student Instructions'],
    ];

    const secondSheetValue = [
      {
        Overview: csv[0]?.ac_activity_description,
        'Resources Required': csv[0]?.ac_resource_required,
        'Student Instructions': csv[0]?.ac_student_instructions
          ? csv[0]?.ac_student_instructions.replace(/(<([^>]+)>)/gi, '')
          : 'Nil',
      },
    ];

    const thirdSheetHeading = [
      ['Task No', 'Title', 'Description', 'Performance Criteria'],
    ];

    const thirdSheetValue = csv[0].tasks.map((item, index) => ({
      'Task No': index + 1,
      Title: item.ai_activityitem,
      Description: item.ai_activityitem_description,
      'Performance Criteria': item.tpc_criteria_value,
    }));

    const fourthSheetHeading = [
      [
        'Total No of Students in Unit',
        'Assigned Students',
        'No of Students submitted',
        'No of Students who completed all tasks against perfomance criteria',
        'Students Pending to submit',
      ],
    ];

    const submittedStudents = csv[0]?.instance?.students?.filter(
      data => data.aouis_status === 2,
    );
    const pendingStudents = csv[0]?.instance?.students?.filter(
      data => data.aouis_status === 1,
    );

    const fourthSheetValue = [
      {
        'total student': csv[0]?.instance?.totalStudents.length,
        'assigned students': csv[0]?.instance?.students.length,
        'submitted students': submittedStudents.length,
        'perfomance criteria': submittedStudents.length,
        'Students Pending to submit': pendingStudents.length,
      },
    ];
    const evaluated = csv[0]?.instance?.students.filter(
      data => data.aouis_status === 3,
    );

    const submitted = csv[0]?.instance?.students.filter(
      data => data.aouis_status === 2,
    );

    const fifthSheetHeading = [
      ['No of Submission Evaluated', 'Pending Submission to be evaluated'],
    ];
    const fifthSheetValue = [
      {
        'No of Submission Evaluated': evaluated?.length,
        'Pending Submission to be evaluated': submitted?.length,
      },
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    const ws1 = XLSX.utils.json_to_sheet([]);
    const ws2 = XLSX.utils.json_to_sheet([]);
    const ws3 = XLSX.utils.json_to_sheet([]);
    const ws4 = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(ws, firstSheetHeading);
    XLSX.utils.sheet_add_json(ws, firstSheetValue, {
      skipHeader: true,
      origin: 'A2',
    });

    XLSX.utils.sheet_add_aoa(ws1, secondSheetHeading);
    XLSX.utils.sheet_add_json(ws1, secondSheetValue, {
      skipHeader: true,
      origin: 'A2',
    });

    XLSX.utils.sheet_add_aoa(ws2, thirdSheetHeading);
    XLSX.utils.sheet_add_json(ws2, thirdSheetValue, {
      skipHeader: true,
      origin: 'A2',
    });

    XLSX.utils.sheet_add_aoa(ws3, fourthSheetHeading);
    XLSX.utils.sheet_add_json(ws3, fourthSheetValue, {
      skipHeader: true,
      origin: 'A2',
    });
    XLSX.utils.sheet_add_aoa(ws4, fifthSheetHeading);
    XLSX.utils.sheet_add_json(ws4, fifthSheetValue, {
      skipHeader: true,
      origin: 'A2',
    });

    XLSX.utils.book_append_sheet(wb, ws, 'General');
    XLSX.utils.book_append_sheet(wb, ws1, 'Assessment');
    XLSX.utils.book_append_sheet(wb, ws2, 'Tasks');
    XLSX.utils.book_append_sheet(wb, ws3, 'Students');
    XLSX.utils.book_append_sheet(wb, ws4, 'Evaluation');

    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    const downloadFileName = `${fileNewName}_${moment(new Date())
      .format('YYYY-MM-DD')
      .toString()}${fileExtension}`;

    FileSaver.saveAs(data, downloadFileName);
  };

  return (
    <button
      type="button"
      className="action-btn"
      style={{marginRight: '1rem'}}
      onClick={() => exportToExcel(exportData, fileName)}
    >
      <img src={ExcelIcon} alt="logo" />
    </button>
  );
};
