/* eslint-disable react/prop-types */
import React from 'react';

import ScheduleSessionIcon from '../../../../../../../../assets/icons/schedule-session.svg';

import TransparentButton from '../TransparentButton';

export const RtoHeader = ({classId, history}) => (
  <div>
    <h2 className="title mb-1">Start Session</h2>
    <p className="description mb-3">You can schedule a session to this unit.</p>
    <TransparentButton
      className="mb-1"
      fullWidth
      icon={ScheduleSessionIcon}
      onClick={() => {
        if (classId) {
          history.push(`/stream/${classId}/sessions/schedule`);
        }
      }}
      text="Schedule"
    />
  </div>
);
