import styled from 'styled-components';

export const UploadContainer = styled.div`
  .logo-label {
    color: #aaa;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .org-image-full {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    align-items: center;
    border: 0.0625rem dashed #dedede;
    padding: 1rem;
    justify-content: space-between;
    border-radius: 0.3125rem;
    .org-image-after {
      display: flex;
      flex-direction: row;
      .file-details {
        display: flex;
        padding-left: 1rem;
        flex-direction: column;

        .file-name {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.062rem;
          color: #3d4457;
        }
        .file-size {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.062rem;
          padding-top: 0.5rem;
          color: #aaaaaa;
        }
      }
      img {
        object-fit: contain;
        width: 2.5rem;
        height: 3rem;
      }
    }
    .delete-logo {
      button {
        border: none;
        background-color: transparent;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .org-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 1.5rem;
    background: #ffffff;
    border: 0.0625rem dashed #dedede;
    box-sizing: border-box;
    border-radius: 0.3125rem;

    .org-logo {
      padding: 3.62rem 0rem 0rem 0rem;
    }
    &.logo-drag {
      background: #f7fbff;
      border-color: #40a1ff;
    }
    .org-text {
      padding: 0.75rem 0 0rem 0;
      text-align: center;
      position: relative;
      overflow: hidden;
    }
    .org-seperator {
      margin-top: 2.25rem;
      width: 18.75rem;
      height: 0.0625rem;
      background-color: #eaeaea;
      margin-bottom: 0.625rem;
      @media (max-width: 31.25rem) {
        & {
          width: 100%;
        }
      }
    }

    .org-text input {
      position: absolute;
      color: black;
      top: 0;
      right: 0;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      font-size: 18.75rem;
      height: 12.5rem;
    }
    span {
      color: #40a1ff;
    }
    .supports {
      padding: 0.375rem 0rem 0rem 0rem;
      color: #adadad;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.062rem;
    }
  }
  .org-size {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    span {
      color: #adadad;
    }
    a {
      color: #40a1ff;
      :hover {
        color: #40a1ff;
        text-decoration: none;
      }
    }
  }
  .error-size {
    color: red;
  }
`;
