/* eslint-disable react/prop-types */
import React from 'react';

import ReactBootstrapModal from 'react-bootstrap/Modal';
import ArrowIcon from '../../../../../../../../assets/icons/act-to.svg';
import ProfileIcon from '../../../../../../../../assets/icons/profile.svg';
import ReminderIcon from '../../../../../../../../assets/icons/reminder-big.svg';
import CloseModal from '../../../../../../../../assets/icons/close-modal.svg';

import {SendReminderModalContainer} from './elements';

export const SendReminderModal = ({
  onClose,
  remindCallback,
  studentName,
  isVisible,
}) => (
  <ReactBootstrapModal centered onHide={() => {}} show={isVisible}>
    <SendReminderModalContainer className="send-reminder-modal">
      <div className="reminder-modal-custom-full">
        <div className="close-btn-reminder">
          <button
            onClick={onClose}
            type="button"
            className="reminder-modal-close"
          >
            <img alt="Close" src={CloseModal} />
          </button>
        </div>
        <div className="send-reminder-modal-wrapper">
          <div className="graphics">
            <img alt="Send Reminder" src={ReminderIcon} />
            <img alt="To" src={ArrowIcon} className="to-arrow" />
            <img alt={studentName} src={ProfileIcon} />
          </div>
          <h2>Do you want to send a</h2>
          <h2>reminder to {studentName}?</h2>
          <p>Reminding this assessment will inform the student to submit</p>
          <p>their assessment as quick as possible.</p>
          <button
            className="send-button"
            onClick={() => {
              remindCallback();
              onClose();
            }}
            type="button"
          >
            Send Reminder
          </button>
          <button className="cancel-button" onClick={onClose} type="button">
            No, Cancel
          </button>
        </div>
      </div>
    </SendReminderModalContainer>
  </ReactBootstrapModal>
);
