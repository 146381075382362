/* eslint-disable */
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {useCallback, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import axios from '../../../../../../../../api';
import {ModalContainer} from './elements';
import {ReactComponent as CloseBtn} from '../../../../../../../../assets/icons/close-modal.svg';
import {Table} from '../../../../../../../../components/common';
import {TableSpinner} from '../../../../../../components-new';
import TextModal from '../TextModal';

const ChangeHistoryModal = ({show, onHide, title, tasid}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  var [data, setData] = useState([]);
  const [isTpdModalOpen, setIsTpdModalOpen] = useState(false);
  const [textContent, setTextContent] = useState('');

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `/api/admin/tas/audit-logs?tasid=${tasid}`,
      );
      setData(response.data.auditLogs);
    } catch (err) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [tasid]);

  const changeColumnName = column => {
    let changedName = '';
    switch (column) {
      case 'otv_end_date':
        changedName = 'End Date';
        break;
      case 'otv_delivery_date':
        changedName = 'Delivery Date';
        break;
      case 'otv_industry_engagements':
        changedName = 'Industry Engagements';
        break;
      case 'otv_human_resources':
        changedName = 'Human Resources';
        break;
      case 'otv_learning_resources':
        changedName = 'Learning Resources';
        break;
      case 'otv_assessment_validation':
        changedName = 'Assessment Validation';
        break;
      case 'otv_reasonable_adjustment':
        changedName = 'Reasonable Adjustment';
        break;
      case 'otv_assessment_strategies':
        changedName = 'Assessment Strategies';
        break;
      case 'otv_assessment_resources':
        changedName = 'Assessment Resources, Methods, and Timing';
        break;
      case 'otv_stage_count':
        changedName = 'Stage Count';
        break;
      case 'otv_stage_name':
        changedName = 'Stage Name';
        break;
      case 'otv_student_lifecycle':
        changedName = 'Student Lifecycle';
        break;
      case 'otv_credit_transfer':
        changedName = 'Recognition Prior Learning / Credit Transfer';
        break;
      case 'otv_rto_pathways':
        changedName = 'RTO Further Pathways';
        break;
      case 'otv_rto_entry_requirements':
        changedName = 'RTO Further Entry Requirements';
        break;
      case 'otv_target_group':
        changedName = 'Target Group';
        break;
      case 'otv_course_dur':
        changedName = 'Course Duration';
        break;
      case 'otv_type':
        changedName = 'Type';
        break;
      case 'otv_department':
        changedName = 'Department';
        break;
      case 'otv_cohort_name':
        changedName = 'Cohort Name';
        break;
      case 'otv_literacy':
        changedName = '	Literacy';
        break;
      case 'otv_numeracy':
        changedName = 'Numeracy';
        break;
      case 'otic_first_name':
        changedName = 'Industry Engagements - First Name';
        break;
      case 'otic_last_name':
        changedName = 'Industry Engagements - Last Name';
        break;
      case 'otic_organisation':
        changedName = 'Industry Engagements - Organisation';
        break;
      case 'otic_position':
        changedName = 'Industry Engagements - Position';
        break;
      case 'otic_date':
        changedName = 'Industry Engagements - Date';
        break;
      case 'otun_elective_category':
        changedName = 'Elective Category';
        break;
      case 'otun_f2f_hours':
        changedName = 'Face to Face Hours';
        break;
      case 'otun_nonf2f_hours':
        changedName = 'Non Face to Face Hours';
        break;
      case 'otun_assessment_hours':
        changedName = 'Assessment Hours';
        break;
      case 'otun_delivery_hours':
        changedName = 'Delivery Hours';
        break;
      case 'otun_lesson_count':
        changedName = 'Lessons';
        break;
      case 'otum_training_method':
        changedName = 'Current Training Method Used';
        break;
      case 'otur_assessment_title':
        changedName = 'Current Assesments Being Used';
        break;
      case 'otul_learning_resource':
        changedName = 'Learning Resource';
        break;
      case 'otuh_id_userprofile_teacher':
        changedName = 'Human Resources - Teachers';
        break;
      case 'otun_unit_code - otun_unit_name':
        changedName = 'Core And Elective Components';
        break;
      case 'otun_id_orgtasunit':
        changedName = 'Core And Elective Components';
        break;
      case 'otic_id_orgtasindustrycontact':
        changedName = 'Industry Enagement New user';
        break;

      default:
        changedName = column;
    }
    return changedName;
  };

  const columns = useMemo(
    () => [
      {Header: '#', accessor: (_row, i) => i + 1, className: 'serial'},
      {
        Header: 'CHANGED COLUMN',
        accessor: 'otal_column_name',
        Cell: ({value}) => (
          <div style={{whiteSpace: 'pre-line'}}>{changeColumnName(value)}</div>
        ),
        className: 'column-name',
      },

      {
        Header: 'UNIT',
        accessor: 'otal_unit_name',
        Cell: ({value}) => <div>{value}</div>,
        className: 'unit-name',
      },
      {
        Header: 'NEW VALUE',
        accessor: 'otal_column_value',

        Cell: ({row: {original}, value}) => {
          const isDate = Date.parse(value);
          if (!isNaN(isDate)) {
            return (
              <div style={{whiteSpace: 'pre-line'}} className="pr-3">
                {moment(value).format('DD-MM-YYYY')}
              </div>
            );
          }
          return (
            <div style={{whiteSpace: 'pre-line'}} className="pr-3">
              {value.slice(0, 100)}
              {value.length > 100 ? (
                <>
                  ...
                  <button
                    onClick={() => {
                      setTextContent(value);
                      setIsTpdModalOpen(true);
                    }}
                    type="button"
                  >
                    See more
                  </button>
                </>
              ) : (
                ''
              )}
            </div>
          );
        },
        className: 'new-value',
      },
      {
        Header: 'UPDATED BY',
        accessor: 'otal_changed_by',
        className: 'updated-by',
      },
      {
        Header: 'UPDATED TIME',
        accessor: 'otal_created_at',
        Cell: ({value}) => moment(value).format('D/M/YYYY HH:mm:ss'),
        className: 'updated-time',
      },
    ],
    [],
  );

  useEffect(() => {
    if (show) {
      getData();
    }
  }, [show, getData]);
  return (
    <>
      <TextModal
        content={textContent}
        isVisible={isTpdModalOpen}
        onClose={() => setIsTpdModalOpen(false)}
        title="New Value"
      />
      <Modal centered show={show} onHide={onHide}>
        <ModalContainer>
          <header>
            <div>
              <h1>{title}</h1>
              <p>Detailed Preview of change log in the TAS Document</p>
            </div>
            <CloseBtn onClick={onHide} />
          </header>
          {error && 'Something went wrong, please try again later'}
          {(() => {
            if (isLoading) return <TableSpinner />;
            if (data.length === 0) return <p>No changes to show.</p>;
            return <Table columns={columns} data={data} isWithoutHeader />;
          })()}
        </ModalContainer>
      </Modal>
    </>
  );
};

ChangeHistoryModal.defaultProps = {
  show: false,
  onHide: () => {},
  title: 'Change History',
  tasid: '',
};

ChangeHistoryModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  tasid: PropTypes.string,
};

export default ChangeHistoryModal;
