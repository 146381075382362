import styled from 'styled-components';

export const ModalContainer = styled.div`
  ${({width}) => (width ? `width: ${width};` : '')}

  @media (max-width:${({width}) => width || ''}) {
    width: 100%;
  }

  .header {
    padding: 1.5rem;
    border-bottom: 0.0625rem solid #dedede;
    .details {
      .trash-icon {
        height: 3.6875rem;
        width: 3.6875rem;
      }

      .title {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: #091018;
        display: -webkit-box !important;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.8125rem;
        overflow: hidden;
        overflow-wrap: anywhere;
        text-overflow: ellipsis;
        white-space: normal;
      }

      .description {
        font-size: 1rem;
        font-weight: 400;
        color: #aaaaaa;
        margin-bottom: unset;
      }
    }

    .close-modal-button {
      background-color: transparent;
      border: none;
      height: 1.5rem;
      width: 1.5rem;

      :active,
      :focus {
        outline: none;
      }

      .close-modal-icon {
        height: 100%;
        width: 100%;
      }
    }
  }

  .footer {
    .button {
      padding: 0.75rem 1.25rem;

      &.cancel-button {
        background-color: white;
        border: unset;
        .label {
          color: #3d4457;
        }
      }

      &.next-btn {
        background-color: #ffffff;
        border-color: ${({theme}) => theme.colors.primary};
        .label {
          color: #40a1ff;
        }
      }
    }
  }

  .summary {
    //opcatity with hex code last two digit
    background-color: #deecfc7f;
    padding: 1rem;

    margin: 0 1.5rem;
    border-radius: 0.5rem;
    p {
      margin-bottom: unset;
      font-weight: 400;
      font-size: 1rem;
      color: #6d7579;
    }
  }
  .filesection {
    /* padding: 1.5rem 1.5rem 0 1.5rem; */
    .field-middle {
      width: 100%;
    }
    .fields {
      padding: 1.5rem;
    }
    .fileupload {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }
    .add-files {
      img {
        margin-right: unset !important;
      }
    }
    .row {
      margin: unset;
    }
    .file-list {
      height: 7rem;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      overflow-y: scroll;
      border-top: 0.0625rem solid #dedede;
    }
  }
  .upload-title {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  .org-image-full {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    align-items: center;
    border: 0.0625rem solid #dedede;
    padding: 0.75rem 0.625rem;
    justify-content: space-between;
    border-radius: 0.3125rem;
    .org-image-after {
      display: flex;
      flex-direction: row;
    }
    .delete-logo {
      margin-right: 0.625rem;
      button {
        border: none;
        background-color: transparent;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .modulename {
    padding: 1.5rem 1.5rem 0 1.5rem;
    .field-middle {
      width: 100%;
    }
    p {
      font-weight: 500;
      font-size: 1rem;
      color: #091018;
      margin-bottom: unset;
    }
  }
  .Subtitle {
    margin: 0 1.5rem;
    border-radius: 0.5rem;
    p {
      margin-bottom: unset;
      font-weight: 400;
      font-size: 1rem;
      color: #838383;
    }
  }
  .feed-back {
    margin: 1.5rem 1.5rem 0 1.5rem;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .counts {
    color: #5ba1f2;
    font-weight: 500;
    font-size: 1rem;
  }
`;
