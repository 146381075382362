import {createSlice} from '@reduxjs/toolkit';

import * as sessionsApi from '../api/admin/sessions';

const sessionsSlice = createSlice({
  initialState: {
    courseOptions: [],
    classOptions: [],
    unitOptions: [],
    teacherOptions: [],
    locationOptions: [],
    sessions: [],
    error: null,
    isListLoading: true,
    isLoading: false,
    isLoadingOptions: false,
    studentsForAttendance: [],
    isLoadingSubmission: false,
    calendarSlots: [],
    search: '',
  },
  name: 'sessions',
  reducers: {
    setCourseOptions(state, action) {
      state.courseOptions = action.payload;
    },
    setClassOptions(state, action) {
      state.classOptions = action.payload;
    },
    setUnitOptions(state, action) {
      state.unitOptions = action.payload;
    },
    setTeacherOptions(state, action) {
      state.teacherOptions = action.payload;
    },
    setLocationOptions(state, action) {
      state.locationOptions = action.payload;
    },
    setSessions(state, action) {
      state.sessions = action.payload;
    },
    appendSession(state, action) {
      state.sessions.push(action.payload);
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoadingOptions(state, action) {
      state.isLoadingOptions = action.payload;
    },
    setStudentsForAttendance(state, action) {
      state.studentsForAttendance = action.payload;
    },
    setIsLoadingSubmission(state, action) {
      state.isLoadingSubmission = action.payload;
    },
    updateSessionById(state, action) {
      const sessionIndex = state.sessions.findIndex(
        item =>
          item.oruil_id_orgunitinstancelesson ===
          action.payload.oruil_id_orgunitinstancelesson,
      );
      state.sessions[sessionIndex] = action.payload;
    },
    deleteSessionById: (state, action) => {
      state.sessions = state.sessions.filter(
        session => session.oruil_id_orgunitinstancelesson !== action.payload,
      );
    },
    setCalendarSlot(state, action) {
      if (!state.calendarSlots.includes(action.payload)) {
        state.calendarSlots.push(action.payload);
      }
    },
    clearCalendarSlots(state) {
      state.calendarSlots = [];
    },
    setSearch(state, action) {
      state.search = action.payload;
    },
  },
});

export const {
  setCourseOptions,
  setClassOptions,
  setUnitOptions,
  setTeacherOptions,
  setLocationOptions,
  setSessions,
  appendSession,
  setError,
  setIsListLoading,
  setIsLoading,
  setIsLoadingOptions,
  setStudentsForAttendance,
  setIsLoadingSubmission,
  updateSessionById,
  deleteSessionById,
  setCalendarSlot,
  clearCalendarSlots,
  setSearch,
} = sessionsSlice.actions;

// view list of sessions

export const fetchAllSessions = oruiId => async dispatch => {
  try {
    dispatch(setIsListLoading(true));
    const sessionsResponse = await sessionsApi.fetchAllSessions(oruiId);
    dispatch(setSessions(sessionsResponse.data.getAllSessions || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};
export const fetchSessionsByFilters = (data, callback) => async dispatch => {
  try {
    dispatch(setIsListLoading(true));
    let sessionsResponse;
    if (Object.keys(data).length) {
      sessionsResponse = await sessionsApi.fetchSessionsByFilters(data);
    } else {
      sessionsResponse = await sessionsApi.fetchAllSessions();
    }
    dispatch(setSessions(sessionsResponse.data.getAllSessions || []));

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

// add session flow

export const createSession = (data, callback) => async dispatch => {
  try {
    dispatch(setIsLoading(true));
    const coursesResponse = await sessionsApi.createSession(data);
    dispatch(appendSession(coursesResponse.data.session));
    if (callback) {
      callback('');
    }
  } catch (error) {
    callback(error?.response?.data?.error?.message);
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const fetchCourses = () => async dispatch => {
  try {
    dispatch(setIsLoadingOptions(true));
    const coursesResponse = await sessionsApi.fetchCourses();
    dispatch(setCourseOptions(coursesResponse.data.allCourses || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoadingOptions(false));
  }
};

export const fetchClassesByCourseId = courseId => async dispatch => {
  try {
    dispatch(setIsLoadingOptions(true));
    const classesResponse = await sessionsApi.fetchClassesByCourseId(courseId);
    dispatch(setClassOptions(classesResponse.data.classes || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoadingOptions(false));
  }
};

export const fetchUnitsByClassId = classId => async dispatch => {
  try {
    dispatch(setIsLoadingOptions(true));
    const unitsResponse = await sessionsApi.fetchUnitsByClassId(classId);
    dispatch(setUnitOptions(unitsResponse.data.units || []));
    dispatch(setLocationOptions([]));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoadingOptions(false));
  }
};

export const fetchTeachersByUnitId = classId => async dispatch => {
  try {
    dispatch(setIsLoadingOptions(true));
    const teachersResponse = await sessionsApi.fetchTeachersByUnitId(classId);
    dispatch(setTeacherOptions(teachersResponse.data.teachers || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoadingOptions(false));
  }
};

// attendance flow
export const fetchStudentsForSessionAttendance =
  sessionId => async dispatch => {
    try {
      dispatch(setIsLoading(true));
      const attendanceResponse =
        await sessionsApi.fetchStudentsForSessionAttendance(sessionId);
      dispatch(
        setStudentsForAttendance(attendanceResponse.data.sessionData[0] || []),
      );
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const attendanceSubmissionForSession =
  (sessionId, data, callback) => async dispatch => {
    try {
      if (data.status === 1) {
        dispatch(setIsLoadingSubmission(true));
      }
      await sessionsApi.attendanceSubmissionForSession(sessionId, data);
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoadingSubmission(false));
    }
  };

export const updateSession = (sessionId, data, callback) => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const resp = await sessionsApi.updateSession(sessionId, data);
    dispatch(updateSessionById(resp.data.session));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteSession = (sessionId, callback) => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const resp = await sessionsApi.deleteSession(sessionId);
    dispatch(deleteSessionById(resp.data.sessionId));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const sessionsSelector = state => state.sessions;

export default sessionsSlice.reducer;
