import styled from 'styled-components';

export const AttendanceFooterContainer = styled.div`
  .footer-container {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 3rem 1.5rem 3rem;
    border-top: 1px solid #e9e9e9;

    @media (max-width: 41.25rem) {
      & {
        width: 100%;
        padding: 1.5rem;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side {
      display: flex;
    }
    .marked,
    .absent,
    .present {
      font-weight: 500;
      font-size: 16px;
    }
    .attendance-submission-badge {
      padding: 0.5rem 1.5rem;
      background-color: #edfaf1;
      border-radius: 0.3125rem;
      span {
        color: #3ea15f;
        font-weight: 500;
        font-size: 1rem;
      }
    }
    .marked {
      margin-right: 3rem;
    }
    .absent {
      margin-right: 1.5rem;
      color: #cc5454;
    }
    .present {
      color: #4ec977;
    }

    .button-container {
      display: flex;
      flex-direction: row;
    }
    .button {
      padding: 0.875rem 1.25rem;

      &.cancel-button {
        background-color: white;
        border: 0.0625rem solid ${({theme}) => theme.colors.primary};

        :active,
        :focus {
          border-color: ${({theme}) => theme.colors.primary};
        }

        .label {
          color: ${({theme}) => theme.colors.primary};
        }
      }
    }
  }

  @media (max-width: 41.25rem) {
    .footer {
      display: flex;
      flex-direction: column;
    }
    .left-side {
      margin-bottom: 0.75rem;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
