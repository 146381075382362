/* eslint-disable react/prop-types */

import React, {useEffect} from 'react';

import {Helmet} from 'react-helmet';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {
  getTaskDetailsById,
  reuseAlreadyExistFiles,
  studentWebSelector,
} from '../../../../../../redux/studentWebSlice';

import {TaskSubmissionContainer} from './elements';

import {FileSubmission} from './components';

export const TaskSubmission = () => {
  const dispatch = useDispatch();

  const {task, linkedFileId} = useSelector(studentWebSelector);

  const {id: assessmentID, taskID: taskId} = useParams();

  useEffect(() => {
    dispatch(getTaskDetailsById(assessmentID, taskId));
    dispatch(reuseAlreadyExistFiles());
  }, [assessmentID, dispatch, taskId]);

  return (
    <TaskSubmissionContainer>
      {task ? (
        <div>
          <Helmet>
            <title>Task Submission | JungleCat</title>
          </Helmet>
          {task ? (
            <FileSubmission
              task={task}
              assessmentID={assessmentID}
              taskId={taskId}
              linkedFileId={linkedFileId}
            />
          ) : null}
        </div>
      ) : null}
    </TaskSubmissionContainer>
  );
};
