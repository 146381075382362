/* eslint-disable no-nested-ternary */
import {useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useLocalStorage} from 'react-use';

import {HeroSection} from '../../components';
import {AuthContainer} from '../../elements';
import {
  AdminForm,
  OrganisationForm,
  ChooseAccountType,
  TeacherForm,
} from './components';

export const Register = () => {
  const [organisationName, setOrganisationName, removeOrganisationName] =
    useLocalStorage('organisationName', null, {
      raw: true,
    });
  const [accountType, setAccountType, removeAccountType] = useLocalStorage(
    'accountType',
    null,
    {
      raw: true,
    },
  );

  const onClickNext = useCallback(
    newOrganisationName => {
      setOrganisationName(newOrganisationName);
    },
    [setOrganisationName],
  );

  const onClickAccountType = useCallback(
    selectedAccountType => {
      setAccountType(selectedAccountType);
    },
    [setAccountType],
  );

  const onNavigateBack = useCallback(() => {
    removeOrganisationName();
  }, [removeOrganisationName]);

  const onNavigateBackToChooseType = useCallback(() => {
    removeAccountType();
  }, [removeAccountType]);

  return (
    <AuthContainer className="no-gutters row">
      <Helmet>
        <title>Register | JungleCat</title>
      </Helmet>
      <HeroSection />
      {!accountType ? (
        <ChooseAccountType onClickAccountType={onClickAccountType} />
      ) : null}
      {accountType !== 'Teacher' ? (
        !organisationName ? (
          <OrganisationForm
            onClickNext={onClickNext}
            onNavigateBack={onNavigateBack}
            onNavigateBackToChooseType={onNavigateBackToChooseType}
          />
        ) : (
          <AdminForm
            onNavigateBack={onNavigateBack}
            onNavigateBackToChooseType={onNavigateBackToChooseType}
            accountType={accountType}
          />
        )
      ) : (
        <TeacherForm
          onNavigateBack={onNavigateBackToChooseType}
          accountType={accountType}
        />
      )}
    </AuthContainer>
  );
};
