/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {useMemo, useState, useRef, useEffect} from 'react';

import {Controller} from 'react-hook-form';

import {useHistory, useParams} from 'react-router-dom';
// import AsyncSelect from 'react-select/async';
import axios from '../../../../../../../../api';

import {TableCellLink} from '../../../../../../../../components/common/Table/styles';
// import LinkIcon from '../../../../../../../../assets/icons/tas-button-link.svg';
// import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {AsySelect, Table} from '../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import {ViewAssessmentMapModal, AssessmentMap} from './components';
import ViewClassImg from '../../../../../../../../assets/icons/viewclass.svg';
import AssessmentResourcesFormContainer from './elements/AssessmentResourcesFormContainer';
import ErrorMessage from '../ErrorMessage';

// reactSelect
const AssessmentResourcesForm = ({
  control,
  errors,
  onUpdate,
  units,
  setUnits,
  tasStatus,
  showError,
  isDataCompleted,
}) => {
  const history = useHistory();

  const [modalShow, setModalShow] = useState(false);

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isLoadingAssessmentMap, setIsLoadingAssessmentMap] = useState(true);
  const [assessmentMap, setAssessmentMap] = useState(null);
  const [assessmentsId, setAssessmentsId] = useState(null);
  const {orgClassId} = useParams();
  const [assessments, setAssessments] = useState([]);
  const [isAssessmentsLoading, setIsAssessmentsLoading] = useState(false);
  const refInner = useRef([]);

  const cabuTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Cell: ({row: {original}, value}) => (
          <div className="column-table-value" style={{marginRight: '1.5rem'}}>
            {value} {original.otun_unit_name}
          </div>
        ),
        Header: 'Unit',
        accessor: 'otun_unit_code',
        className: 'unit',
      },
      {
        Cell: ({row: {original}, value}) => {
          // console.log(original);
          const options = value.map(
            ({otur_assessment_title, otur_id_assessment}) => ({
              value: otur_id_assessment,
              label: otur_assessment_title,
            }),
          );

          return (
            <div className="tas-table-list">
              <AsySelect
                isDisabled={tasStatus}
                isMulti
                isClearable={false}
                className="table-input"
                isLarge={false}
                isLightBorder
                value={options}
                placeholder="Select"
                isLoading={isAssessmentsLoading}
                defaultOptions
                onChange={async (event, {option, removedValue}) => {
                  const optionList = event.map(item => ({
                    otur_assessment_title: item.label,
                    otur_id_assessment: item.value,
                  }));
                  if (option) {
                    await axios.post(
                      `/api/admin/tas/${original.otun_id_orgtas}/unit-assesemnt-resource`,
                      {
                        ac_activity_name: option.label,
                        ac_id_activity: option.value,
                        unitId: original.otun_id_unit,
                        unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                        oldValue: value,
                      },
                    );
                  } else if (removedValue) {
                    await axios.post(
                      `/api/admin/tas/${original.otun_id_orgtas}/delete-unit-assesemnt-resource`,
                      {
                        ac_id_activity: removedValue.value,
                        unitId: original.otun_id_unit,
                        unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                        oldValue: event,
                      },
                    );
                  }
                  // /tas/:tasId/delete-unit-assesemnt-resource
                  // setUnits(previousValue =>
                  //   previousValue.map(item =>
                  //     item.otun_id_unit === original.otun_id_unit
                  //       ? {...item, assessmentResources: optionList}
                  //       : item,
                  //   ),
                  // );

                  setUnits(previousValue =>
                    previousValue.map(item =>
                      item.otun_id_unit === original.otun_id_unit
                        ? {...item, assessmentResources: optionList}
                        : item,
                    ),
                  );
                }}
                // onInputChange={e => console.log(e)}
                // value={options}
                loadOptions={async (inputValue = '', callback) => {
                  try {
                    setIsAssessmentsLoading(true);
                    const response = await axios.get(
                      `/api/admin/tas/get-assesments/${original.ou_id_orgunit}`,
                    );

                    if (response.data.status) {
                      const assessmentList = response.data.assesments.map(
                        ({ac_activity_name, ac_id_activity}) => ({
                          value: ac_id_activity,
                          label: ac_activity_name,
                        }),
                      );

                      return assessmentList;
                    }
                  } catch (e) {
                    setIsAssessmentsLoading(false);
                  } finally {
                    setIsAssessmentsLoading(false);
                  }
                }}
              />
            </div>
          );
        },
        Header: 'Current Assessments Being Used',
        accessor: 'assessmentResources',
        className: 'assessments-used',
      },
      {
        Cell: ({row: {original}}) => {
          if (!original?.assessmentResources?.length) {
            return null;
          }
          return (
            <TableCellLink
              onClick={() => {
                setModalShow(true);
                setSelectedUnit({
                  unitId: original.otun_id_unit,
                  unitCode: original.otun_unit_code,
                  unitName: original.otun_unit_name,
                });
                setAssessmentsId(
                  original?.assessmentResources?.map(
                    item => item.otur_id_assessment,
                  ),
                );
              }}
              type="button"
              className="view-class"
              title={
                !original?.assessmentResources?.length
                  ? 'Choose assessment'
                  : 'View assessment map'
              }
            >
              <span> View Assessment Map</span>
              <img
                src={ViewClassImg}
                alt="loader"
                className="viewclass-arrow"
              />
            </TableCellLink>
          );
        },
        Header: 'Assessment map',
        accessor: 'otun_id_unit',
        className: 'assessment-map',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUnits, tasStatus],
  );

  useEffect(() => {
    if (selectedUnit?.unitId) {
      axios
        .post(`/api/admin/tas/assessment-map/${selectedUnit?.unitId}`, {
          assessmentsId,
        })
        .then(({data}) => {
          setAssessmentMap(data.assessmentMap);
          setAssessments(data.assessments);
          setIsLoadingAssessmentMap(false);
        });
    }
  }, [assessmentsId, selectedUnit]);

  const onCancel = () => {
    setModalShow(false);
  };

  return (
    <AssessmentResourcesFormContainer
      className="section"
      id="assessment-resources"
    >
      <h3>Assessment Resources</h3>
      <p>
        Assessment resources, methods and timing
        <br />
        Training packages and VET accredited courses often specify resources
        that must be used in assessment at a unit of competency level. Include
        details of how you will ensure students have access to the resources
        that will give them the best chance of completing their study.
        <br />
        <br />
        Identify:
        <br />
        Assessment resources.
        <br />
        Assessment methods. <br />
        Timing of assessment. <br />
        Any training package requirements in relation to the training and
        assessment practices (such as mandatory work placement hours and how
        this is structured into the course delivery). <br />
        Any adjustments that may be needed to cater for different student
        characteristics.
      </p>
      <form>
        <div className="tas-form-row">
          <Controller
            control={control}
            name="assessmentStrategies"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.assessmentStrategies}
                hasInfo="<ul>
    <li>Training packages and VET accredited courses often specify resources that must be used in assessment at a unit of competency level. Include details of how you will ensure students have access to the resources that will give them the best chance of completing their study.</li>
    <br/>
 <li>Identify:
<ul>
<li>Assessment resources</li>
<li>Assessment methods</li>
<li>Timing of assessment</li>
<li>Any training package requirements in relation to the training and assessment practices (such as mandatory work placement hours and how this is structured into the course delivery)</li>
<li>Any adjustments that may be needed to cater for different student characteristics.</li></ul></li></ul>"
                label="Assessment Strategies"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled={tasStatus}
                rows={5}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            {showError && isDataCompleted?.includes('mode-of-delivery') ? (
              <ErrorMessage
                errorMessage="Assessment Resources for units needed to be filled"
                className="error mb-2"
              />
            ) : null}
            <div className="column-table-title">
              Assessments Used
              {/* <img alt="Info" src={InfoIcon} /> */}
            </div>
            <Table
              columns={cabuTableColumns}
              data={units}
              isWithoutHeader
              isWithoutPagination
              showAllRows
            />
          </div>
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="reasonableAdjustment"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.reasonableAdjustment}
                hasInfo="If a person does choose to disclose to you, you then have a responsibility to consult with them and make reasonable adjustments** to your usual processes, environment and practices. Consider who will have the responsibility of negotiating these adjustments within your organisation. It is important that all staff are aware of their responsibilities and that appropriate professional development is provided to help staff feel comfortable in negotiating and implementing adjustments.<br/><br/>Reasonable adjustments should be negotiated effectively—they are very individual. Usually the person with disability will be the best person to explain the impact of their disability on their study and what adjustments may help them, or they have found useful in the past. You can also ask students to provide documentation from a medical or other health professional to help guide this process.<br/><br/>** Reasonable adjustments are those that would not cause unjustifiable hardship on the RTO. For example, if the costs of making adjustments to the premises are such that they would cause hardship to the RTO, it would not be expected that such adjustments are made. RTOs are encouraged to seek advice from the Australian Human Rights Commission on the individual circumstance"
                label="Reasonable Adjustment"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                rows={5}
                disabled={tasStatus}
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <Controller
            control={control}
            name="assessmentValidation"
            render={fields => (
              <TextArea
                {...fields}
                className="is-full-width tas-form-column"
                hasError={!!errors.assessmentValidation}
                hasInfo="Assessment Validation<br/><br/>
The RTO implements a plan for ongoing systematic validation of assessment practices and judgements that includes for each training product on the RTO’s scope of registration:<br/><br/>
<ol>
<li>When assessment validation will occur</li>
<li>Which training products will be the focus of the validation</li>
<li>Who will lead and participate in validation activities</li>
<li>How the outcomes of these activities will be documented and acted upon.</li></ol>
Each training product is validated at least once every five years, with at least 50 per cent of products validated within the first three years of each five year cycle, taking into account the relative risks of all of the training products on the RTO’s scope of registration, including those risks identified by the VET regulator."
                label="Assessment Validation"
                onBlur={event => {
                  fields?.onBlur?.(event);
                  onUpdate();
                }}
                disabled={tasStatus}
                rows={5}
              />
            )}
          />
        </div>
      </form>
      <ViewAssessmentMapModal
        show={modalShow}
        title={`${selectedUnit?.unitCode} - ${selectedUnit?.unitName}`}
        width="85rem"
        onHide={onCancel}
      >
        <AssessmentMap
          assessmentMap={assessmentMap}
          assessments={assessments}
          isLoadingAssessmentMap={isLoadingAssessmentMap}
          selectedUnit={selectedUnit}
        />
      </ViewAssessmentMapModal>
    </AssessmentResourcesFormContainer>
  );
};

export default AssessmentResourcesForm;
