/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
// import {useMemo, useState} from 'react';

import Box from '../../../../../../../components/shared/Box';
// import PerformanceCriteriaHistory from '../PerformanceCriteriaHistory';
import PerformanceCriteriaItem from './components/PerformanceCriteriaItem';
import PerformanceCriteriaContainer from './elements/PerformanceCriteriaContainer';

const PerformanceCriteria = ({
  assessmentMap = [],
  assessments = [],
  ...rest
}) => (
  // const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  // const [historyItem, setHistoryItem] = useState({
  //   criteriaId: null,
  //   elementId: null,
  //   assessmentType: null,
  // });

  // const activeMap = useMemo(() => {
  //   if (historyItem.criteriaId) {
  //     const criteria = assessmentMap
  //       .find(element => element.id === historyItem.elementId)
  //       ?.criteria.find(item => item.id === historyItem.criteriaId);
  //     const tasks = criteria.tasks.filter(
  //       data => data.assessmentType === historyItem.assessmentType,
  //     );
  //     // const orgId = assessmentMap.find(item => item.orgUnitInstanceId);
  //     const orgId = assessmentMap.find(
  //       element => element.id === historyItem.elementId,
  //     );
  //     return {
  //       tasks,
  //       title: criteria.title,
  //       orgUnitInstanceId: orgId.orgUnitInstanceId,
  //     };
  //   }
  //   return null;
  // }, [assessmentMap, historyItem]);

  <PerformanceCriteriaContainer {...rest}>
    <div style={{width: '200vw'}}>
      <Box className="column-headings d-flex p-4">
        <span className="column-heading serial-number">#</span>
        <span className="column-heading performance-criteria">
          Performance Criteria
        </span>
        {assessments.map(item => (
          <div className="column-heading observable-task">
            <div className="d-flex flex-column">
              <div className="column-heading">{item.ac_activity_name}</div>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: '#3d4457',
                }}
                className="mb-0 mt-1"
              >
                {item.am_activitymethod}
              </p>
            </div>
          </div>
        ))}
      </Box>
      {assessmentMap.map(item => (
        <PerformanceCriteriaItem
          item={item}
          // onShowHistory={(criteriaId, elementId, assessmentType, taskLink) => {
          //   setHistoryItem({
          //     criteriaId,
          //     elementId,
          //     assessmentType: Number(assessmentType),
          //     taskLink,
          //   });
          //   setIsHistoryVisible(true);
          // }}
          key={item.id}
        />
      ))}
    </div>
    {/* <PerformanceCriteriaHistory
      onHideHistory={() => {
        setIsHistoryVisible(false);
      }}
      isVisible={isHistoryVisible}
      tasks={activeMap?.tasks}
      title={activeMap?.title}
      orgUnitInstanceId={activeMap?.orgUnitInstanceId}
    /> */}
  </PerformanceCriteriaContainer>
);
PerformanceCriteria.propTypes = {
  assessmentMap: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          tasks: PropTypes.arrayOf(
            PropTypes.shape({
              date: PropTypes.string.isRequired,
              id: PropTypes.number.isRequired,
              title: PropTypes.string.isRequired,
              user: PropTypes.string.isRequired,
            }),
          ).isRequired,
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PerformanceCriteria;
