import styled from 'styled-components';

const LiveSessionBadge = styled.div.attrs(() => ({
  className: 'd-flex align-items-center rounded-pill',
}))`
  padding: 0.75rem 0.8125rem;
  background-color: #f3f3f3;
`;

export default LiveSessionBadge;
