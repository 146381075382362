/* eslint-disable no-nested-ternary */
import {useHistory, useRouteMatch} from 'react-router-dom';
import React, {useEffect, useRef, useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocalStorage} from 'react-use';
import classNames from 'classnames';
import {SOCKET_EVENTS} from '../../../constants';

import {
  fetchActiveClasses,
  fetchOrgClasses,
  fetchAllAssessments,
  fetchAcademicYears,
  activeClassSelector,
} from '../../../redux/activeClassSlice';
import {fetchClassDetails, streamSelector} from '../../../redux/streamSlice';
import {
  fetchAllNotification,
  notificationSelector,
  appendNotifications,
  appendNewNotifications,
  markAllAsRead,
} from '../../../redux/notificationSlice';
import SideDrawer from './Components/SideDrawer';
import {logout as logoutOld} from '../../../pages/AuthOld/authSlice';
import {Nav} from './Elements/Navbar';
// import addAssessment from '../../../assets/icons/add-assessment.svg';
// import addMaterial from '../../../assets/icons/add-material.svg';
// import addQuestion from '../../../assets/icons/add-question.svg';
// import addQuiz from '../../../assets/icons/add-quiz.svg';
// import arrowDown from '../../../assets/icons/arrow-down-white.svg';
import profile from '../../../assets/icons/profile.svg';
import addIcon from '../../../assets/icons/addIcon.svg';
// import settings from '../../../assets/icons/Navbar/settings.svg';
import homeIco from '../../../assets/icons/home.svg';
// import arrowBlue from '../../../assets/icons/arrowblue.svg';
// import arrowGrey from '../../../assets/icons/arrowgrey.svg';
import logo from '../../../assets/logos/jcw_logo.png';
import jcLogo from '../../../assets/icons/jc_logo.svg';
import threeLine from '../../../assets/icons/menu-logo.svg';
import CustomIconButton from '../CustomIconButton';
// import CustomButton from '../CustomButton';
// import CustomCancel from '../Buttons/CustomButton';
// import {getDashboard, adminInfo} from '../../../pages/Admin/adminSlice';
import {fetchDashboard, dashboardSelector} from '../../../redux/dashboardSlice';
import {
  ClassSelect,
  ProfileMenu,
  ProfileMenuSlide,
  Notification,
} from '../../common/Navbar/components';
import {SideNavBar} from '../../common';
import {
  setOnlineStudents,
  authSelector,
  logout,
} from '../../../redux/authSlice';
import {socket} from '../../../pages/Sessions/components/LiveSession/hooks/useSocket';
import NotificationIconEmpty from '../../../assets/icons/notification-bell.svg';
import NotificationActiveIcon from '../../../assets/icons/notification-active.svg';
import UnseenNotificationIcon from '../../../assets/icons/notification-bell-full.svg';

const Navbar = properties => {
  const authState = useSelector(authSelector);

  // const socket = io(process.env.REACT_APP_API_ENDPOINT, {
  //   transports: ['websocket'],
  // });
  const [userType, setUserType] = useLocalStorage('jc-user-type', null, {
    raw: true,
  });
  const roomId = authState?.userOrganisation?.or_id_org;
  // const userId = authState?.user?.userProfiles[0]?.up_id_userprofile;

  // console.log(typeof userProfileType);
  const userId = useMemo(
    () =>
      authState?.user?.userProfiles?.find(
        item => item?.up_id_typeuserprofile === Number(userType),
      )?.up_id_userprofile,
    [authState?.user?.userProfiles, userType],
  );

  const {notification, newNotifications} = useSelector(notificationSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    const notificationCallBack = notifications => {
      if (!notification.includes(notifications)) {
        dispatch(appendNotifications(notifications));
      }
      if (!newNotifications.includes(notifications)) {
        dispatch(appendNewNotifications(notifications));
      }
    };
    if (authState?.user?.userProfiles) {
      socket.emit(SOCKET_EVENTS.CS_ONLINE, {
        roomId,
        user: authState?.user?.userProfiles[0],
      });
      socket.on(SOCKET_EVENTS.CS_LIST, usrs => {
        dispatch(setOnlineStudents(usrs));
      });
      socket.on(SOCKET_EVENTS.CS_LEAVED, usrs => {
        dispatch(setOnlineStudents(usrs));
      });

      socket.on(userId, notificationCallBack);

      // socket.on(teacherId, notifications => {
      //   console.log('teacherId', teacherId, notifications);
      //   if (!notification.includes(notifications)) {
      //     dispatch(appendNotifications(notifications));
      //   }
      //   if (!newNotifications.includes(notifications)) {
      //     dispatch(appendNewNotifications(notifications));
      //   }
      // });
      // socket.on('notification', notifications => {
      //   console.log('notification  by string', notifications);
      //   const existingNotification = notification?.find(
      //     item => item.al_id_auditlog === notifications?.al_id_auditlog,
      //   );
      //   const existingNewNotification = newNotifications?.find(
      //     item => item.al_id_auditlog === notifications?.al_id_auditlog,
      //   );
      //   if (!existingNotification) {
      //     dispatch(appendNotifications(notifications));
      //   }
      //   if (!existingNewNotification) {
      //     dispatch(appendNewNotifications(notifications));
      //   }
      // });
    }
    return () => {
      socket.off(userId, notificationCallBack);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.user, dispatch]);

  window.onbeforeunload = () => {
    socket.emit(SOCKET_EVENTS.CS_OFFLINE, {
      roomId,
      userId,
    });
  };

  // const getProfileType = authState.user.userProfiles[0].up_id_typeuserprofile;

  const classState = useSelector(activeClassSelector);
  const streamState = useSelector(streamSelector);

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isResponsiveProfileMenuOpen, setIsResponsiveProfileMenuOpen] =
    useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [
    isResponsiveNotificationMenuOpen,
    setIsResponsiveNotificationMenuOpen,
  ] = useState(false);
  const [isStream, setIsStream] = useState(false);
  const [classId, setClassId] = useState('');
  // const [isWorkDropdownOpen, setIsWorkDropdownOpen] = useState(false);
  // const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);
  // const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  // const [isSessionDropdownOpen, setIsSessionDropdownOpen] = useState(false);
  const [profileType, setProfileType] = useState(null);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const match = useRouteMatch();
  const history = useHistory();

  const isArchived = streamState?.classDetails?.class?.oc_is_archived;

  const wrapperRef = useRef(null);
  // const addWorksDropdownContainer = useRef(null);
  const {pathname} = history.location;
  /** checks and returns the token in the localstorage to hide and shoe the navbar */
  const isAuthenticated = () => {
    const token = localStorage.getItem('jwtToken');
    return !!token;
  };

  const {dashboard} = useSelector(dashboardSelector);
  useEffect(() => {
    dispatch(fetchDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentProfile = Number(userType);

    setProfileType(currentProfile);
    dispatch(fetchActiveClasses(currentProfile, history));
    dispatch(fetchAllAssessments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * fetch Academic years for dropdown
   */
  useEffect(() => {
    dispatch(fetchAcademicYears());
  }, [dispatch]);

  /**
   * fetch Class Details on class Id change
   *  fetch organisation classes using academic year id get from class details for class dropdown
   */
  useEffect(() => {
    if (classId) {
      dispatch(
        fetchClassDetails(classId, academicYear => {
          dispatch(fetchOrgClasses(academicYear));
        }),
      );
    }
  }, [dispatch, classId]);

  useEffect(() => {
    dispatch(fetchAllNotification());
  }, [dispatch]);

  /** Set Class Id thats' fetch from url */
  useEffect(() => {
    isAuthenticated();
    if (/stream/.test(pathname)) {
      setIsStream(true);
      const Id = pathname.split('/')[2];
      setClassId(Id);
    } else {
      setIsStream(false);
      setClassId('');
    }
  }, [match.url, pathname, classState.orgClasses]);

  /** Logout user */
  const logoutUser = () => {
    setIsProfileMenuOpen(false);
    dispatch(logout());
    dispatch(logoutOld());
    history.push('/login');
    socket.emit(SOCKET_EVENTS.CS_OFFLINE, {
      roomId,
      userId,
    });
  };
  const activeClassGroup = useMemo(
    () =>
      classState?.activeClasses?.filter(
        item =>
          item?.class?.oc_is_archived === 0 && item?.class?.oc_is_active === 1,
      ),
    [classState],
  );

  // const notificationGroup = useMemo(
  //   () => [
  //     ...notification?.map(item => ({
  //       ...item,
  //     })),
  //   ],
  //   [notification],
  // );

  const newNotificationGroup = useMemo(
    () => [
      ...newNotifications?.map(item => ({
        ...item,
      })),
    ],
    [newNotifications],
  );

  /** Redirect to selected class details screen */
  const onSubmit = data => {
    setClassId(data.class);
    history.push(`/stream/${data.class}`);
  };

  const isAdmin = profileType === 4;
  const isTeacher = profileType === 3;
  const isStudent = profileType === 2;
  const userProfile = authState?.user?.userProfiles[0];

  // Check org preference for tas page is enable
  const showTasPreference = authState?.orgPreferences?.find(
    item => item.pr_slug === 'show_tas' && Number(item.pr_value) === 1,
  );

  // Check org preference for assessment map page is enable
  const assessmentMapPreference = authState?.orgPreferences?.find(
    item =>
      item.pr_slug === 'show_assessment_map' && Number(item.pr_value) === 1,
  );
  // Check org preference for library page is enable
  const libraryPreference = authState?.orgPreferences?.find(
    item => item.pr_slug === 'show_library' && Number(item.pr_value) === 1,
  );
  // Check org preference for curriculum page is enable
  const curriculumPreference = authState?.orgPreferences?.find(
    item => item.pr_slug === 'show_curriculum' && Number(item.pr_value) === 1,
  );

  return (
    <>
      {isAuthenticated() && (
        <Nav
          className={`navbar navbar-expand-sm p-0 ${
            !isStream ? 'fixed-top' : 'fixed-top isStream'
          }`}
          {...properties}
          id="navbar-container"
        >
          {(isStream && (isTeacher || isStudent)) || isAdmin ? (
            <SideDrawer
              profileType={profileType}
              classId={classId}
              sideDrawerOpen={sideDrawerOpen}
              setSideDrawerOpen={setSideDrawerOpen}
              showTasPreference={showTasPreference}
              assessmentMapPreference={assessmentMapPreference}
              libraryPreference={libraryPreference}
              curriculumPreference={curriculumPreference}
            />
          ) : null}
          {(isStream && (isTeacher || isStudent)) || isAdmin ? (
            <SideNavBar
              profileType={profileType}
              isStream={isStream}
              classId={classId}
              showTasPreference={showTasPreference}
              assessmentMapPreference={assessmentMapPreference}
              libraryPreference={libraryPreference}
              curriculumPreference={curriculumPreference}
            />
          ) : null}
          <div className="col p-0 d-flex logoAndClass">
            {profileType !== 4 ? (
              isStream ? (
                <div className="logo-jc logo-class">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <button
                      className="btn2"
                      type="submit"
                      onClick={() => setSideDrawerOpen(true)}
                    >
                      <img src={threeLine} alt="logo" className="product" />
                    </button>
                  </div>
                  <button
                    className="btn pl-0"
                    type="submit"
                    onClick={() => history.push(`/classes`)}
                  >
                    {dashboard?.basicInfoDetail?.or_logo_url ? (
                      <div className="orglogo">
                        <img
                          src={dashboard?.basicInfoDetail?.or_logo_url}
                          alt="logo"
                          className="productorg"
                        />
                      </div>
                    ) : null}
                  </button>
                </div>
              ) : (
                <div className="logo-jc">
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <button
                      className="btn1"
                      type="submit"
                      onClick={() => history.push(`/classes`)}
                    >
                      <img src={jcLogo} alt="logo" className="product" />
                    </button>
                  </div>
                  <button
                    className="btn pl-0"
                    type="submit"
                    onClick={() => history.push(`/classes`)}
                  >
                    <img src={logo} alt="logo" className="product1" />
                  </button>
                </div>
              )
            ) : (
              <div className="logo-jc logo-class">
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <button
                    className="btn2"
                    type="submit"
                    onClick={() => setSideDrawerOpen(true)}
                  >
                    <img src={threeLine} alt="logo" className="product" />
                  </button>
                </div>
                <button
                  className="btn pl-0"
                  type="submit"
                  onClick={() => history.push(`/admin`)}
                >
                  {dashboard?.basicInfoDetail?.or_logo_url ? (
                    <div className="orglogo">
                      <img
                        src={dashboard?.basicInfoDetail?.or_logo_url}
                        alt="logo"
                        className="productorg"
                      />
                    </div>
                  ) : null}
                </button>
              </div>
            )}

            {isStream && profileType !== 4 ? (
              <ClassSelect
                classes={activeClassGroup}
                isLoading={
                  streamState.isLoading || !streamState.classDetails.class
                }
                onSelect={classItemId =>
                  onSubmit({
                    class: classItemId,
                  })
                }
                selectedClass={streamState.classDetails}
                className="class-list"
              />
            ) : null}
          </div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isStream ? (
            profileType !== 4 ? (
              <ul className="col p-0 navbar-nav justify-content-center nav-pills h-100 d-none d-lg-flex" />
            ) : (
              <ul className="col p-0 navbar-nav justify-content-center nav-pills h-100 d-none d-lg-flex">
                {/* <li className="nav-item admin-navitem active">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    activeClassName="activeLink"
                    to="/admin"
                    exact
                    id="dashboard-id"
                  >
                    <span>Dashboard</span>
                  </NavLink>
                </li>
                <li className="nav-item admin-navitem active">
                  <button
                    type="button"
                    className={`activebtn nav-link d-flex align-items-center${
                      pathname.includes('/organisation') ? ' activeLink' : ''
                    }`}
                    onMouseEnter={() => {
                      setIsOrgDropdownOpen(true);
                    }}
                    onMouseLeave={() => {
                      setIsOrgDropdownOpen(false);
                    }}
                    id="organisation-id"
                  >
                    <span>Organisation</span>
                    <img
                      alt="Show Works"
                      className="organisation-button-icon"
                      src={
                        pathname.includes('/organisation')
                          ? arrowBlue
                          : arrowGrey
                      }
                    />
                    {isOrgDropdownOpen ? (
                      <div className="orgdropdown">
                        <ul className="dropdown-content">
                          <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsOrgDropdownOpen(false);
                                history.push(`/admin/organisation/basic-info`);
                              }}
                              type="button"
                              id="basic-info-id"
                            >
                              <span className="dropdown-text">Basic Info</span>
                            </button>
                          </li>
                          <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsOrgDropdownOpen(false);
                                history.push(`/admin/organisation/departments`);
                              }}
                              type="button"
                              id="department-id"
                            >
                              <span className="dropdown-text">Departments</span>
                            </button>
                          </li>
                          <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                history.push(
                                  `/admin/organisation/academicyear`,
                                );
                                setIsOrgDropdownOpen(false);
                              }}
                              type="button"
                              id="academic-year-id"
                            >
                              <span className="dropdown-text">
                                Academic Years
                              </span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </button>
                </li>
                <li className="nav-item admin-navitem active">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    activeClassName="activeLink"
                    to="/admin/qualifications"
                    id="qualifications-id"
                  >
                    <span>Qualifications</span>
                  </NavLink>
                </li>
                <li className="nav-item admin-navitem active">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    activeClassName="activeLink"
                    to="/admin/courses/active-courses"
                    id="classes-id"
                  >
                    <span>Courses</span>
                  </NavLink>
                </li>
                <li className="nav-item admin-navitem active">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    activeClassName="activeLink"
                    to="/admin/classes"
                    id="classes-id"
                  >
                    <span>Classes</span>
                  </NavLink>
                </li>
                <li className="nav-item admin-navitem active">
                  <button
                    type="button"
                    className={`activebtn nav-link d-flex align-items-center${
                      pathname.includes('/users') ? ' activeLink' : ''
                    }`}
                    onMouseEnter={() => {
                      setIsUserDropdownOpen(true);
                    }}
                    onMouseLeave={() => {
                      setIsUserDropdownOpen(false);
                    }}
                    id="users-id"
                  >
                    <span>Users</span>
                    <img
                      alt="Show Works"
                      className="add-works-button-icon"
                      src={pathname.includes('/users') ? arrowBlue : arrowGrey}
                    />
                    {isUserDropdownOpen ? (
                      <div className="orgdropdown">
                        <ul className="dropdown-content">
                          <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsUserDropdownOpen(false);
                                history.push(`/admin/users/admin`);
                              }}
                              type="button"
                              id="admin-teacher-id"
                            >
                              <span className="dropdown-text">
                                Admin & Teacher
                              </span>
                            </button>
                          </li> */}
                {/* <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsUserDropdownOpen(false);
                                history.push(`/admin/users/teacher`);
                              }}
                              type="button"
                            >
                              <span className="dropdown-text">Teacher</span>
                            </button>
                          </li> */}
                {/* <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsUserDropdownOpen(false);
                                history.push(`/admin/users/student`);
                              }}
                              type="button"
                              id="student-id"
                            >
                              <span className="dropdown-text">Student</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </button>
                </li>
                <li className="nav-item admin-navitem active">
                  <button
                    type="button"
                    className={`activebtn nav-link d-flex align-items-center${
                      pathname.includes('/sessions') ? ' activeLink' : ''
                    }`}
                    onMouseEnter={() => {
                      setIsSessionDropdownOpen(true);
                    }}
                    onMouseLeave={() => {
                      setIsSessionDropdownOpen(false);
                    }}
                    id="users-id"
                  >
                    <span>Sessions</span>
                    <img
                      alt="Show Works"
                      className="add-works-button-icon"
                      src={
                        pathname.includes('/sessions') ? arrowBlue : arrowGrey
                      }
                    />
                    {isSessionDropdownOpen ? (
                      <div className="orgdropdown">
                        <ul className="dropdown-content">
                          <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsSessionDropdownOpen(false);
                                history.push(`/admin/sessions`);
                              }}
                              type="button"
                              id="admin-teacher-id"
                            >
                              <span className="dropdown-text">
                                All Sessions
                              </span>
                            </button>
                          </li>
                          <li href="#">
                            <button
                              className="organisation-dropdown-item"
                              onClick={() => {
                                setIsSessionDropdownOpen(false);
                                history.push(`/admin/sessions/calendar`);
                              }}
                              type="button"
                              id="student-id"
                            >
                              <span className="dropdown-text">Calendar</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </button>
                </li>

                <li className="nav-item admin-navitem active">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    activeClassName="activeLink"
                    to="/admin/library"
                    id="admin-library-id"
                  >
                    <span>Library</span>
                  </NavLink>
                </li> */}

                {/* <li className="nav-item admin-navitem active">
                  <NavLink
                    className="nav-link d-flex align-items-center"
                    activeClassName="activeLink"
                    to="/admin/units"
                  >
                    <span>Units</span>
                  </NavLink>
                </li> */}
              </ul>
            )
          ) : (
            <ul className="col p-0 navbar-nav justify-content-center nav-pills h-100 d-none d-lg-flex">
              {isTeacher ? (
                <>
                  {/* <li className="nav-item active">
                    <NavLink
                      className="nav-link d-flex align-items-center"
                      activeClassName="activeLink"
                      to={`/stream/${classId}/live`}
                      id="stream-id"
                    >
                      <span>Stream</span>
                    </NavLink>
                  </li>
                  <li className="nav-item active">
                    <NavLink
                      className="nav-link d-flex align-items-center"
                      activeClassName="activeLink"
                      to={`/stream/${classId}/works`}
                      id="work-id"
                    >
                      <span>Works</span>
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item active">
            <NavLink
              className="nav-link d-flex align-items-center"
              activeClassName="activeLink"
              to={`/stream/${classId}/units`}
            >
              <span>Units</span>
            </NavLink>
          </li> */}
                  {/* <li className="nav-item active">
                    <NavLink
                      className="nav-link d-flex align-items-center"
                      activeClassName="activeLink"
                      to={`/stream/${classId}/students`}
                      id="student-teachapp-id"
                    >
                      <span>Students</span>
                    </NavLink>
                  </li>
                  <li className="nav-item active ">
                    <NavLink
                      className="nav-link d-flex align-items-center flex-shrink-0"
                      activeClassName="activeLink"
                      to={`/stream/${classId}/assessment-map`}
                      id="assessment-map-id"
                    >
                      <span className="flex-shrink-0">Assessment Map</span>
                    </NavLink>
                  </li>
                  <li className="nav-item active ml-3">
                    <NavLink
                      className="nav-link d-flex align-items-center"
                      activeClassName="activeLink"
                      to={`/stream/${classId}/library`}
                      id="teacher-library-id"
                    >
                      <span className="flex-shrink-0">Library</span>
                    </NavLink>
                  </li> */}
                </>
              ) : null}
            </ul>
          )}
          <div className="rightSideNavbar-resp">
            <div className="d-flex p-0 col rightSideNavbar">
              {/* <div className="add-notification"> */}
              {isTeacher && isStream && !isArchived ? (
                <>
                  <CustomIconButton
                    icon={addIcon}
                    className="add-assessment-web"
                    text="Add Assessment"
                    onClick={() => {
                      history.push(`/stream/${classId}/assessment`);
                    }}
                    id="add-assessment-id"
                  />
                  {/* <div
                  className="add-works-dropdown-container"
                  ref={addWorksDropdownContainer}
                >
                  <CustomButton
                    onClick={() =>
                      setIsWorkDropdownOpen(previousState => !previousState)
                    }
                    className="add-work-btn"
                    text={
                      <>
                        <span className="add-works-button-text">Add Work</span>
                        <img
                          alt="Show Works"
                          className="add-works-button-icon"
                          src={arrowDown}
                        />
                      </>
                    }
                  />
                  <div
                    className={`add-works-dropdown ${
                      isWorkDropdownOpen ? 'is-open' : ''
                    }`}
                  >
                    <button
                      className="add-works-dropdown-item"
                      onClick={() =>
                        history.push(`/stream/${classId}/assessment`)
                      }
                      type="button"
                    >
                      <img alt="Add Assessment" src={addAssessment} />
                      <span>Assessment</span>
                    </button>
                    <button className="add-works-dropdown-item" type="button">
                      <img alt="Add Quiz" src={addQuiz} />
                      <span>Quiz</span>
                    </button>
                    <button className="add-works-dropdown-item" type="button">
                      <img alt="Add Question" src={addQuestion} />
                      <span>Question</span>
                    </button>
                    <button className="add-works-dropdown-item" type="button">
                      <img alt="Add Material" src={addMaterial} />
                      <span>Material</span>
                    </button>
                  </div>
                </div> */}
                </>
              ) : null}
              {/* <img
              src={settings}
              className="cursor-pointer mr-3"
              alt="settings"
            />
            <img src={message} className="cursor-pointer mr-3" alt="message" /> */}
              {/* {!isTeacher ? ( */}
              <div
                ref={wrapperRef}
                className={classNames('notification-dropdown', {
                  isstream: isStream,
                })}
              >
                <button
                  className={classNames(
                    'bg-white border-0 d-flex notification-button ',
                    {
                      active: isNotificationMenuOpen,
                    },
                  )}
                  onClick={() => {
                    setIsNotificationMenuOpen(!isNotificationMenuOpen);
                    setIsResponsiveNotificationMenuOpen(
                      !isResponsiveNotificationMenuOpen,
                    );
                  }}
                  type="submit"
                  id="notification-id"
                >
                  {newNotifications?.length === 0 ? (
                    <img
                      src={
                        isNotificationMenuOpen
                          ? NotificationActiveIcon
                          : NotificationIconEmpty
                      }
                      alt="notification"
                    />
                  ) : (
                    <img src={UnseenNotificationIcon} alt="notification" />
                  )}
                </button>

                <Notification
                  isVisible={isNotificationMenuOpen}
                  onClose={() => {
                    setIsNotificationMenuOpen(false);
                  }}
                  unSeenNotification={newNotificationGroup}
                  isAdmin={isAdmin}
                  isTeacher={isTeacher}
                  isStudent={isStudent}
                  notification={notification}
                  onClick={() => {
                    dispatch(markAllAsRead());
                  }}
                />
              </div>
              {/* </div> */}
              {/* ) : null} */}
              <div ref={wrapperRef} className="wrapperForlogout">
                {isTeacher && isStream ? (
                  <button
                    className="bg-white border-0 home-icon"
                    onClick={() => history.push('/classes')}
                    type="submit"
                    id="home-icon-id"
                  >
                    <img src={homeIco} alt="homeIco" />
                  </button>
                ) : null}

                <button
                  className="bg-white border-0 d-flex"
                  onClick={() => {
                    setIsProfileMenuOpen(!isProfileMenuOpen);
                    setIsResponsiveProfileMenuOpen(
                      !isResponsiveProfileMenuOpen,
                    );
                  }}
                  type="submit"
                  id="profile-id"
                >
                  <img
                    src={
                      userProfile?.up_avatar ? userProfile?.up_avatar : profile
                    }
                    className="cursor-pointer rounded-circle"
                    alt="profile"
                  />
                </button>

                <ProfileMenu
                  email={userProfile?.up_email}
                  isVisible={isProfileMenuOpen}
                  name={`${userProfile?.up_name_first} ${userProfile?.up_name_last}`}
                  onClose={() => {
                    setIsProfileMenuOpen(false);
                  }}
                  profileType={profileType}
                  onLogout={logoutUser}
                  onToggle={() => {
                    if (isAdmin) {
                      setUserType(3);
                      history.push('/classes');
                    } else if (isTeacher) {
                      setUserType(4);
                      history.push('/admin');
                    }
                  }}
                  type={
                    authState?.user?.userProfiles.length > 1
                      ? isAdmin
                        ? 'Teacher'
                        : 'Admin'
                      : null
                  }
                />
              </div>
            </div>
            <ProfileMenuSlide
              email={userProfile?.up_email}
              userProfile={userProfile}
              profileType={profileType}
              isVisible={isResponsiveProfileMenuOpen}
              name={`${userProfile?.up_name_first} ${userProfile?.up_name_last}`}
              onClose={() => {
                setIsResponsiveProfileMenuOpen(false);
              }}
              onLogout={logoutUser}
              onToggle={() => {
                if (isAdmin) {
                  setUserType(3);
                  history.push('/classes');
                } else if (isTeacher) {
                  setUserType(4);
                  history.push('/admin');
                }
              }}
              type={
                authState?.user?.userProfiles.length > 1
                  ? isAdmin
                    ? 'Teacher'
                    : 'Admin'
                  : null
              }
            />
            {isTeacher && isStream && !isArchived ? (
              <>
                <CustomIconButton
                  icon={addIcon}
                  className="add-assessment-responsive"
                  text="Add Assessment"
                  onClick={() => {
                    history.push(`/stream/${classId}/assessment`);
                  }}
                  id="add-assessment-id"
                />
              </>
            ) : null}
          </div>
        </Nav>
      )}
    </>
  );
};
export default Navbar;
