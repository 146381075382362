import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 21.8rem;
  .please {
    font-size: 0.875rem;
    color: #888888;
  }
  .header {
    font-size: 1.5rem;
    text-align: center;
  }
  .state {
    color: #aaaaaa;
    font-size: 1rem;
    text-align: center;
  }
`;
