/* eslint-disable react/prop-types */
import React from 'react';

import {ClassAttendanceTable, SessionAttendanceGraph} from './components';

export const MyAttendance = () => (
  <>
    <SessionAttendanceGraph />
    <ClassAttendanceTable />
  </>
);
