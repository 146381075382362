import styled from 'styled-components';

const SessionBarContainer = styled.div`
  background-color: ${({altColor}) => altColor || '#e0eeff'};
  border-radius: 0.25rem;
  left: 6.5rem;
  height: ${({height}) => `${height}px`};
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  top: ${({top}) => `${top}px`};
  width: calc(100% - 9.5rem);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition-delay: 0.2s;

  .one-hour {
    z-index: 30;
  }
  .less-half-hour {
    z-index: 20;
  }
  &.less-half-hour-hover {
    height: 76px;
    z-index: 40;
    transition: 0.3s ease-in-out;
  }

  .left-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0.25rem;
    background-color: ${({theme, primaryColor}) =>
      primaryColor || theme.colors.primaryAlt};
  }

  .time-interval {
    display: flex;
    align-items: center;
    color: #6d7579;
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: 0.5rem;

    &::before {
      content: '';
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
      background-color: ${({theme, primaryColor}) =>
        primaryColor || theme.colors.primaryAlt};
      margin-right: 0.375rem;
    }
  }

  .session-title {
    color: ${({theme}) => theme.colors.text};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tooltip-button {
    align-items: center;
    border: 0.0625rem solid #95c6ff;
    border-radius: 0.125rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;
    height: 1.75rem;
    justify-content: center;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 1.75rem;
    background-color: white;

    img {
      height: 1.25rem;
      object-fit: contain;
      width: 1.25rem;
    }
  }

  .tooltip-link {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    text-decoration: none;

    img {
      width: 1rem;
      height: 1rem;
      object-fit: contain;
      margin-right: 0.375rem;
    }

    span {
      color: #1973da;
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }

  .session-class {
    color: #6d7579;
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .attendance-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${({primaryColor}) => primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: 1.25rem;
      height: 1.25rem;
      object-fit: contain;
    }
  }
`;

export default SessionBarContainer;
