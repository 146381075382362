/* eslint-disable react/prop-types */
import React, {useMemo, useCallback} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  TextInput,
  Radio,
} from '../../../../../../../../../../components/common';

import {ManualUnitTableStyles, FormContainer} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import AddIcon from '../../../../../../../../../../assets/icons/addadmin.svg';
import {Table} from '../../../../../../../../../../components/common/Table';
import CloseIcon from '../../../../../../../../../../assets/icons/redcross.svg';
import {useErrorMessage} from '../../../../../../../../../../utils/hooks';
import {QualificationUnitSchema} from '../../../../../../../../../../utils/validation';

import {
  individualUnitCheckByQualId,
  setError as setServerError,
  qualificationsSelector,
  clearUnitFormError,
} from '../../../../../../../../../../redux/qualificationsSlice';

const FIELDS_IN_ORDER = ['unitType', 'unitCode', 'unitName'];

export const ManualFormForUnit = ({
  manual,
  formShow,
  btnShow,
  setFormShow,
  setBtnShow,
  addUnits,
  setAddUnits,
  qualificationData,
}) => {
  const dispatch = useDispatch();
  const {error: serverError, formError} = useSelector(qualificationsSelector);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearUnitFormError());
  }, [dispatch]);

  const {
    control,
    formState: {errors},
    setError,
    reset,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      unitCode: '',
      unitName: '',
      unitType: '',
    },
    resolver: yupResolver(QualificationUnitSchema),
  });
  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const onSubmit = useCallback(
    data => {
      const details = {
        unitCode: data.unitCode,
        unitName: data.unitName,
        unitType: data.unitType,
      };

      dispatch(
        individualUnitCheckByQualId(details, qualificationData, () => {
          const arraysOfUnitCode = addUnits.map(admin => admin.unitCode);
          const arraysOfUnitName = addUnits.map(admin => admin.unitName);

          if (
            !arraysOfUnitCode.includes(data.unitCode) &&
            !arraysOfUnitName.includes(data.unitName)
          ) {
            setAddUnits(state => [
              ...state,
              {
                unitCode: data.unitCode,
                unitName: data.unitName,
                unitType: data.unitType,
              },
            ]);

            setFormShow(false);
            setBtnShow(false);
          } else {
            if (arraysOfUnitName.includes(data.unitName)) {
              setError('unitName', {
                type: 'focus',
                message: 'This unit name already added in this list',
              });
            }
            if (arraysOfUnitCode.includes(data.unitCode)) {
              setError('unitCode', {
                type: 'focus',
                message: 'This unit code already added in this list',
              });
            }
          }
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addUnits],
  );

  const removeItems = position => {
    setAddUnits(state =>
      state.filter((item, index) => index !== position.index),
    );
  };
  // Manual flow mapping
  const unitsManualColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'UNIT CODE',
        accessor: 'unitCode',
        className: 'unit-code',
      },
      {
        Header: 'UNIT NAME',
        accessor: 'unitName',
        className: 'unit-name',
      },
      {
        Header: 'UNIT TYPE',
        Cell: ({row: {original}}) => {
          const unitTypeInSpread = original?.unitType;
          return unitTypeInSpread?.toLowerCase() === 'core' ? (
            <div className="type-container">
              <div className="core">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          ) : (
            <div className="type-container">
              <div className="elective">{unitTypeInSpread.toUpperCase()}</div>
            </div>
          );
        },
        accessor: 'unitType',
        className: 'unit-types',
      },
      {
        heading: '',
        Cell: ({row: position}) => (
          <button
            type="button"
            onClick={() => {
              removeItems(position);
            }}
            className="closeIconBtn"
          >
            <img src={CloseIcon} alt="close" />
          </button>
        ),
        accessor: 'addUnits',
        className: 'close',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Add a serial number to each row and memoize the data.
  const unitManualdata = useMemo(
    () => [
      ...addUnits.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [addUnits],
  );

  return (
    <FormContainer>
      <div>
        {manual && addUnits?.length ? (
          <ManualUnitTableStyles>
            <Table
              columns={unitsManualColumns}
              data={unitManualdata}
              itemName="Units"
              maxWidth="49.37rem"
              isSortedBy
            />
          </ManualUnitTableStyles>
        ) : null}
        {(formShow && btnShow) || !addUnits?.length ? (
          <div>
            <div
              className="add-student-modal"
              style={{padding: '1.5rem 0rem 0rem 0rem'}}
            >
              <div className="add-student-modal-body">
                <form>
                  <div className="d-flex form-fields">
                    <div className="d-flex flex-column unit-type-input-container">
                      <span className="mb-3 unit-type-label">Unit Type</span>
                      <div className="align-items-center d-flex fields">
                        <Controller
                          control={control}
                          name="unitType"
                          render={fields => (
                            <Radio
                              {...fields}
                              className="mr-4"
                              currentValue={watch('unitType')}
                              hasError={!!errors.unitType}
                              label="Core"
                              value="core"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="unitType"
                          render={fields => (
                            <Radio
                              {...fields}
                              currentValue={watch('unitType')}
                              hasError={!!errors.unitType}
                              label="Elective"
                              value="elective"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="fields">
                      <Controller
                        control={control}
                        name="unitCode"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={
                              errors?.unitCode?.message || formError.unitCode
                            }
                            hasError={!!errors.unitCode || !!formError.unitCode}
                            label="Unit Code"
                            placeholder="Enter unit code"
                          />
                        )}
                      />
                    </div>
                    <div className="field-last">
                      <Controller
                        control={control}
                        name="unitName"
                        render={fields => (
                          <TextInput
                            {...fields}
                            errorMessage={errors?.unitName?.message}
                            hasError={!!errors.unitName}
                            label="Unit Name"
                            placeholder="Enter unit name"
                          />
                        )}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div
              className="button-container"
              style={{padding: '0rem 0rem 1.5rem 0rem'}}
            >
              {addUnits?.length && formShow ? (
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    reset();
                    setFormShow(false);
                    setBtnShow(false);
                  }}
                >
                  <span className="cancel-button-text">Cancel</span>
                </CustomCancel>
              ) : null}

              <CustomCancel
                className="save-changes"
                bgColor="#fff"
                padding="0.562rem 0.875rem"
                onClick={handleSubmit(onSubmit)}
              >
                <img src={AddIcon} alt="icon" style={{marginRight: '0.5rem'}} />
                <span className="add-button-text">
                  {addUnits.length > 0 ? 'Add to list' : 'Add Unit'}
                </span>
              </CustomCancel>
            </div>
          </div>
        ) : (
          <div
            className="button-container"
            style={{padding: '1.5rem 0 1.5rem 0rem'}}
          >
            <CustomCancel
              className="save-changes"
              bgColor="#fff"
              padding="0.562rem 0.875rem"
              onClick={() => {
                setFormShow(true);
                setBtnShow(true);
              }}
            >
              <img src={AddIcon} alt="icon" style={{marginRight: '0.5rem'}} />
              <span className="add-button-text">Add another unit</span>
            </CustomCancel>
          </div>
        )}
      </div>
    </FormContainer>
  );
};
