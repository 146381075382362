import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

const TaskListContainer = styled(AdminListingPageContainer)`
  .status-container {
    display: flex;
    flex-direction: row;
  }
  .status-1,
  .status-2,
  .status-3 {
    border-radius: 5px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 8px 16px;
    text-transform: uppercase;
  }
  .status-1 {
    background-color: #defae8;
    color: #4ec977;
  }
  .status-2 {
    background-color: #ffe1e1;
    color: #ff8787;
  }
  .status-3 {
    background-color: #f3f3f3;
    color: #8a8c94;
  }
  .serial {
    width: 5%;
  }

  .course-name {
    width: 68%;
  }

  .status {
    width: 20%;
  }

  .moreoptions {
    width: 8%;
  }
`;

export default TaskListContainer;
