import axios from '../..';

export const fetchAllDepartments = () => axios.get('api/admin/org/departments');

export const deleteDepartment = departmentId =>
  axios.delete(`api/admin/org/departments/${departmentId}`);

export const fetchAllDepartmentsList = () =>
  axios.get('api/admin/org/departments/alldepartments');

export const addDepartments = data =>
  axios.post('api/admin/org/departments/addDepartment', data);

export const checkDepartmentExists = data =>
  axios.post('api/admin/org/departments/addNewDepartment', data);
