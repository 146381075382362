/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {ExportExcel} from './components';

export const ExportSpreadSheetButton = ({assessmentSummary}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([assessmentSummary]);
  }, [assessmentSummary]);

  return <ExportExcel exportData={data} fileName="assessment-summary" />;
};
