import styled from 'styled-components';

import {Modal} from '../../../../../../../../../../../components/common';

export const ModuleContainer = styled.div`
  .title-container {
    margin: 2rem 0 1.25rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-container {
      width: 10%;
    }
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #3d4457;
    width: 100%;
    text-overflow: ellipsis;
  }
  .title-data {
    width: 25%;
  }

  .seperator {
    height: 0.0625rem;
    width: 65%;
    background-color: #e0e0e0;
  }
  .fileposition {
    font-weight: 400;
    font-size: 0.875rem;
    color: #3d4457;
    width: 1.25rem;
  }
  .filename {
    font-weight: 400;
    font-size: 0.875rem;
    color: #3d4457;
  }
  .fileicon {
    width: 22px;
    height: 28px;
    vertical-align: unset;
  }
  .icon {
    transform: rotate(90deg);
  }
  .form-container {
    padding: 1.5rem;
  }
  .icon-module {
    width: 1rem;
    height: 0.25rem;
    vertical-align: unset;
  }
  .button-download {
    background-color: transparent;
    border: unset;
    outline: unset;
    padding: unset;
  }
  .downloadicon {
    width: 1.125rem;
    height: 1.125rem;
    vertical-align: unset;
  }
  .arrowicon {
    width: 1.125rem;
    height: 1.125rem;
    vertical-align: unset;
  }
  .date {
    font-style: italic;
    font-weight: 400;
    font-size: 0.875rem;
    color: #aaaaaa;
  }
  .file-container {
    padding: 0 1.5rem;
    background: #ffffff;
    border-radius: 0.25rem;

    .file-detail {
      padding: 1.25rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      :not(:last-child) {
        border-bottom: 1px solid #dedede;
      }
      .btn-container {
        display: flex;
        justify-content: end;
        width: 20%;
      }
      .name-container {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .date {
        width: 30%;
      }
      .view-module {
        border: none;
        background: transparent;
        outline: none;
        border-radius: 0.312rem;
        color: #40a1ff;
        font-weight: 500;
        font-size: 0.875rem;
        padding: unset;
        a {
          text-decoration: none;
          color: #40a1ff;
        }
        .viewmodule-arrow {
          margin-left: 0.75rem;
          vertical-align: middle;
        }
      }
      @media (max-width: 35rem) {
        & {
          display: flex;
          flex-direction: column;
          .date,
          .btn-container {
            margin-top: 0.75rem;
            width: 100%;
            justify-content: unset !important;
          }
          .name-container {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 80rem) {
    .seperator {
      width: 50%;
    }
  }
  @media (max-width: 45rem) {
    .seperator {
      width: 40%;
    }
  }
  @media (max-width: 37.5rem) {
    .seperator {
      display: none;
    }
    .title-data {
      width: 75%;
    }
    .btn-container {
      width: 15%;
    }
  }
`;

export const EditFileContainer = styled(Modal)`
  .row-container {
    padding: 0rem 1.5rem 0.5rem 1.5rem;
  }
`;
