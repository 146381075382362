/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import {Helmet} from 'react-helmet';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {ViewFileSharedToClassContainer, EditFileContainer} from './elements';
import {showToast} from '../../../../../../../../../../components/common/Toast';
import {
  Modal,
  TextInput,
} from '../../../../../../../../../../components/common';
import {
  ViewFileSharedToClassHeader,
  ViewFileSharedToClassMain,
} from './components';
import {
  getCurriculumFileByFileId,
  deleteCurriculumModuleFileById,
  editCurriculumModuleFileById,
  curriculumSelector,
} from '../../../../../../../../../../redux/curriculumSlice';
import UpdateIcon from '../../../../../../../../../../assets/icons/updateadmintoast.svg';

const DELAY = 500;

export const ViewFileSharedToClass = () => {
  const {orgCourseId: courseId, curriculumFileId} = useParams();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [editFileId, setEditFileId] = useState(null);
  const [editFileName, setEditFileName] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const {curriculumFile, isAddCurriculumLoading} =
    useSelector(curriculumSelector);

  useEffect(() => {
    dispatch(getCurriculumFileByFileId(courseId, curriculumFileId));
  }, [courseId, curriculumFileId, dispatch]);

  const onCancel = () => {
    history.replace(`/admin/curriculum/view-curriculum/${courseId}`);
  };

  return (
    <>
      <Helmet>
        <title>View Curriculum File | JungleCat</title>
      </Helmet>

      <ViewFileSharedToClassContainer>
        <ViewFileSharedToClassHeader
          onBack={onCancel}
          curriculumFile={curriculumFile}
          setDeleteName={setDeleteName}
          setDeleteId={setDeleteId}
          setEditFileName={setEditFileName}
          setEditFileId={setEditFileId}
        />
        <ViewFileSharedToClassMain curriculumFile={curriculumFile} />
        <EditFileContainer
          buttonLabel="Save"
          description="Rename module file"
          isButtonLoading={isAddCurriculumLoading}
          loadingButtonLabel="Saving"
          onButtonClick={() => {
            if (!isAddCurriculumLoading && editFileName?.length) {
              dispatch(
                editCurriculumModuleFileById(
                  courseId,
                  editFileId,
                  editFileName,
                  () => {
                    dispatch(
                      getCurriculumFileByFileId(courseId, curriculumFileId),
                    );
                    setEditFileId(null);
                    showToast(
                      UpdateIcon,
                      `${editFileName} file name updated successfully`,
                      false,
                    );

                    setTimeout(() => {
                      setEditFileName('');
                    }, DELAY);
                  },
                ),
              );
            }
          }}
          onHide={() => {
            setEditFileId(null);
            setTimeout(() => {
              setEditFileName('');
            }, DELAY);
          }}
          show={!!editFileId}
          title="Rename File"
          width="45rem"
        >
          <div className="row-container">
            <TextInput
              label="File Name"
              autoComplete="off"
              type="text"
              id="fileName"
              hasError={!editFileName?.length}
              value={editFileName}
              onBlur={e => {
                setEditFileName(e?.target?.value);
              }}
              onChange={e => {
                setEditFileName(e?.target?.value);
              }}
            />
          </div>
        </EditFileContainer>
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this file?"
          isDelete
          isButtonLoading={isAddCurriculumLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isAddCurriculumLoading) {
              dispatch(
                deleteCurriculumModuleFileById(
                  courseId,
                  curriculumFile?.ocrf_id_course_curriculum_module,
                  deleteId,
                  () => {
                    setDeleteId(null);
                    showToast(
                      false,
                      `${deleteName} - file has been deleted.`,
                      true,
                    );
                    onCancel();
                    setTimeout(() => {
                      setDeleteName('');
                    }, DELAY);
                  },
                ),
              );
            }
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
      </ViewFileSharedToClassContainer>
    </>
  );
};
