import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const AllDeparmentsContainer = styled(AdminListingPageContainer)`
  .index {
    width: 10%;
  }

  .department-name {
    width: 85%;
  }

  .close {
    width: 5%;
  }
  .close-icon-btn {
    border: none;
    background: transparent;
    outline: none;
  }
  .table-container,
  .department-header {
    max-width: 55.5rem;
    margin: auto;
  }
  .__react_component_tooltip {
    font-size: 0.875rem;
    font-weight: normal;
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 0.312rem;
    width: 20rem !important;
    text-align: justify;
  }

  table {
    tr {
      button {
        display: none;
      }
      :hover {
        button {
          display: block;
        }
      }
      td {
        word-break: break-all;
      }
    }
  }
`;
