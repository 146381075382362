import {createSlice} from '@reduxjs/toolkit';
import login from '../../api/authOld/login';
import profile from '../../api/profile';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    isLoading: false,
    user: {},
    jwtToken: null,
    errorMessage: '',
  },
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserDetails: (state, action) => {
      state.user = action.payload.user;
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId !== action.payload.user.us_id_user) {
        localStorage.setItem('userId', action.payload.user.us_id_user);
        const userType = action.payload.currentProfile.up_id_typeuserprofile;
        localStorage.setItem('jc-user-type', userType);
      }
    },
    setToken: (state, action) => {
      state.jwtToken = action.payload;
      localStorage.setItem('jwtToken', action.payload);
    },
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    clearToken: () => {
      localStorage.removeItem('jwtToken');
      // localStorage.removeItem('user');
      localStorage.removeItem('userId');
      localStorage.removeItem('jc-user-type');
    },
  },
});

export const {
  setLoadingStatus,
  setUserDetails,
  setToken,
  setLoggedIn,
  setErrorMessage,
  clearToken,
} = authSlice.actions;

// sends a req to auth/api
export const loginAfterInvitation =
  (user, token, history, setUser, setUserOrganisation, setOrgPreferences) =>
  async dispatch => {
    dispatch(clearToken());
    dispatch(setLoadingStatus(true));
    dispatch(setErrorMessage(''));
    try {
      let currentProfile;
      if (user.userProfiles.length > 1) {
        currentProfile = user.userProfiles.find(
          item => item.up_id_typeuserprofile === 4,
        );
      } else {
        [currentProfile] = user.userProfiles;
      }
      dispatch(setLoadingStatus(false));
      dispatch(setUserDetails({user, currentProfile}));
      if (setUser) dispatch(setUser(user));
      if (setUserOrganisation)
        dispatch(setUserOrganisation(user?.organisation));
      if (setOrgPreferences)
        dispatch(setOrgPreferences(user?.organisation?.preferences));

      dispatch(setToken(token));
      dispatch(setLoggedIn(true));
      dispatch(setErrorMessage(''));
      if (currentProfile.up_id_typeuserprofile === 4) {
        history.push('/admin');
      } else {
        history.push('/classes');
      }
    } catch (err) {
      dispatch(setLoadingStatus(false));
      dispatch(setErrorMessage('Oops!. Invalid login credentials!'));
    }
  };
export const authenticate = (data, history, callback) => async dispatch => {
  dispatch(setLoadingStatus(true));
  dispatch(setErrorMessage(''));
  try {
    const resp = await login({
      us_email: data.email,
      us_password: data.password,
    });
    let currentProfile;
    if (resp.data.user.userProfiles.length > 1) {
      currentProfile = resp.data.user.userProfiles.find(
        item => item.up_id_typeuserprofile === 4,
      );
    } else {
      [currentProfile] = resp.data.user.userProfiles;
    }

    dispatch(setLoadingStatus(false));
    dispatch(setUserDetails({user: resp.data.user, currentProfile}));
    dispatch(setToken(resp.data.token));
    dispatch(setLoggedIn(true));
    dispatch(setErrorMessage(''));

    if (currentProfile.up_id_typeuserprofile === 4) {
      history.push('/admin');
    } else {
      history.push('/classes');
    }
  } catch (err) {
    callback(err.response);
    dispatch(setLoadingStatus(false));
    dispatch(
      setErrorMessage(err.response.data.message || err.response.data.error),
    );
  }
};

// Getting user profile info when visiting the app
export const getProfile = token => async dispatch => {
  dispatch(setLoadingStatus(true));
  dispatch(setErrorMessage(''));
  try {
    const resp = await profile();
    let currentProfile;
    if (resp.data.user.userProfiles.length > 1) {
      currentProfile = resp.data.user.userProfiles.find(
        item => item.up_id_typeuserprofile === 4,
      );
    } else {
      [currentProfile] = resp.data.user.userProfiles;
    }
    dispatch(setLoadingStatus(false));
    dispatch(setUserDetails({user: resp.data.user, currentProfile}));

    if (token) {
      dispatch(setToken(token));
      dispatch(setLoggedIn(true));
      dispatch(setErrorMessage(''));
    }
  } catch (err) {
    dispatch(setLoadingStatus(false));
    dispatch(setErrorMessage(err.message));
  }
};

export const logout = () => dispatch => {
  dispatch(clearToken());
};

// state access fn's
export const authSelector = state => state.authOld;

export default authSlice.reducer;
