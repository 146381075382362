/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';

import closeIcon from '../../../../assets/icons/instruction-modal-close.svg';
import CustomModal from '../../../../components/shared/CustomModal';
import CustomBox from '../../../../components/shared/CustomBox';

export const TextModal = ({onClose, showData}) => (
  <CustomModal isVisible>
    <div className="text-modal-container">
      <CustomBox className="custombox">
        <div className="text-modal-header">
          <h5>Text Instruction</h5>
          <button
            className="modal-close-button"
            onClick={onClose}
            type="button"
          >
            <img alt="Close Modal" src={closeIcon} />
          </button>
        </div>

        <div className="text-content">
          <p>{showData?.text}</p>
        </div>
      </CustomBox>
    </div>
  </CustomModal>
);
