import styled from 'styled-components';

export const StudentAssessmentEvaluationContainer = styled.main`
  flex-grow: 1;
  margin: 2rem auto;
  width: 100%;
  @media (max-width: 60.5rem) {
    & {
      width: 80%;
    }
  }
  @media (max-width: 43.75rem) {
    & {
      margin-top: 10%;
      margin-left: 5%;
      margin-right: 7%;
      margin-bottom: 10%;
      width: 90%;
    }
    .left {
      display: flex !important;
      flex-direction: column !important;
      .back-btn-hide {
        display: block !important;
        margin-bottom: 1.5rem;
        width: 100%;
        & > button {
          background-color: transparent;
          border: none;
          border-bottom: 0.0625rem solid #40a1ff;
          color: #40a1ff;
          font-size: 0.875rem;

          :active,
          :focus {
            outline: none;
          }
        }
      }
    }
    .back-btn {
      display: none !important;
    }
  }

  @media (min-width: 60rem) {
    & {
      max-width: 75%;
    }
  }

  header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.75rem;
    .back-btn {
      display: block;
      & > button {
        background-color: transparent;
        border: none;
        border-bottom: 0.0625rem solid #40a1ff;
        color: #40a1ff;
        font-size: 0.875rem;

        :active,
        :focus {
          outline: none;
        }
      }
    }
    .left {
      align-items: center;
      display: flex;
      .profile-details {
        display: flex;

        span {
          line-height: 1;
        }
      }

      .back-btn-hide {
        display: none;
      }

      img {
        height: 3rem;
        margin-right: 0.75rem;
      }

      .name {
        color: #3d4457;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.15;
      }

      .submission-date {
        color: #aaa;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.15;
        margin-bottom: 0;
      }
    }

    & > button {
      background-color: transparent;
      border: none;
      border-bottom: 0.0625rem solid #40a1ff;
      color: #40a1ff;
      font-size: 0.875rem;

      :active,
      :focus {
        outline: none;
      }
    }
  }
`;

export const StudentAssessmentEvaluationTaskContainer = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  padding: 1.5rem 1.5rem 1.187rem 1.5rem;
  @media (max-width: 43.75rem) {
    .content {
      .assessment-task-files {
        display: flex;
        flex-direction: column;
        .file-list {
          margin-bottom: 1.5rem;
        }
        .btn-div {
          width: 100%;
          margin-bottom: 0.375rem;
        }
        .evaluate-button {
          height: 2.625rem;
          width: 100%;
        }
        .submitted-button {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
  :not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .title {
    align-items: center;
    display: flex;
    margin-bottom: 0.75rem;

    .text {
      h4 {
        color: #3d4457;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.187rem;
      }
    }
  }

  .content {
    p {
      color: #838383;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    .assessment-task-files {
      display: flex;
      justify-content: space-between;
      padding-top: 1.56rem;
      .file-list {
        display: flex;

        button {
          background-color: transparent;
          border: none;
          border-radius: 0.187rem;
          height: 2.5rem;
          outline: none;
          overflow: hidden;
          width: 2.5rem;

          :not(:last-child) {
            margin-right: 1rem;
          }

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      .submitted-button {
        align-items: center;
        border: none;
        border-radius: 0.75rem;
        color: #fff;
        display: flex;
        padding: 0.75rem 0.625rem 0.75rem 1rem;

        :active,
        :focus {
          outline: none;
        }

        .marks {
          font-size: 1rem;
          font-weight: 500;
          margin-right: 1.5rem;
        }

        .grade {
          align-items: center;
          border-radius: 0.125rem;
          display: flex;
          font-size: 1.5rem;
          font-weight: 500;
          height: 1.937rem;
          justify-content: center;
          width: 1.937rem;
        }
      }

      .evaluate-button {
        background-color: #fff;
        border: 0.0625rem solid #40a1ff;
        border-radius: 0.75rem;
        color: #40a1ff;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.75rem 1.5rem;

        :active,
        :focus {
          outline: none;
        }
      }
    }
  }
`;
