/* eslint-disable react/prop-types */
import React from 'react';

import {Select} from '../../../../../../components/common';
import SearchInput from '../../../../../../components/shared/Inputs/SearchInput';
import {FilterFilesContainer} from './elements';

export const FilterFiles = ({
  filesLength,
  filterByCategory,
  categoryAsOptions,
  setFilterByCategory,
  setSearchTerm,
  searchTerm,
  fileTypeOptions,
  setFilterByFileType,
  filterByFileType,
}) => (
  <FilterFilesContainer>
    <div className="filter-head-left">
      <div className="files-count">{filesLength} Files</div>
      <SearchInput
        className="class-search-responsive"
        onChange={event => setSearchTerm(event.target.value)}
        placeholder="Search"
        value={searchTerm}
      />
    </div>
    <div className="filter-head-right">
      <div className="filter-fields">
        <div className="type-filter">
          <Select
            isLarge={false}
            value={filterByFileType}
            options={fileTypeOptions}
            onChange={e => {
              setFilterByFileType(e);
            }}
            width="100%"
          />
        </div>
        <div className="catergory-filter">
          <Select
            isLarge={false}
            value={filterByCategory}
            options={categoryAsOptions}
            onChange={e => {
              setFilterByCategory(e);
            }}
            width="100%"
          />
        </div>
      </div>
      <SearchInput
        className="class-search"
        onChange={event => setSearchTerm(event.target.value)}
        placeholder="Search"
        value={searchTerm}
      />
    </div>
  </FilterFilesContainer>
);
