import styled from 'styled-components';

export const CurrentTimeContainer = styled.div`
  top: ${({top}) => `${top}px`};
  right: 2.5rem;
  height: 1.875rem;
  width: calc(100% - 8.15625rem);

  .circle {
    background-color: ${({theme}) => theme.colors.dangerButtonHover};
    border-radius: 50%;
    height: 0.5625rem;
    width: 0.5625rem;
    flex-shrink: 0;
  }

  .line {
    background-color: ${({theme}) => theme.colors.dangerButtonHover};
    width: 100%;
    height: 0.0625rem;
  }
`;
