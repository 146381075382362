import styled from 'styled-components';

export const AddUnitToQualificationHeader = styled.div`
  margin: 0px -0.937rem;
  .add-unit-header {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 3rem 1.5rem 3rem;
    .back-button {
      margin-bottom: 1rem;
    }
    @media (max-width: 31.25rem) {
      & {
        padding: 1.5rem;
      }
    }

    .add-unit-title {
      display: flex;
      justify-content: space-between;
      .add-unit-name {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.37rem;
      }
    }
  }
`;
