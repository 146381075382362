import React from 'react';
import PropTypes from 'prop-types';
import profileIcon from '../../../../../assets/icons/profile.svg';

const Avatar = React.forwardRef(({srcImage}, ref) => (
  <button className="border-0" type="button">
    <img
      ref={ref}
      src={srcImage}
      className="rounded-circle"
      alt="profile"
      width="48px"
      height="48px"
    />
  </button>
));

Avatar.propTypes = {
  srcImage: PropTypes.string,
};
Avatar.defaultProps = {
  srcImage: profileIcon,
};

export default Avatar;
