import styled from 'styled-components';

export const OrganisationCardContainer = styled.div`
  .organisationcard {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 0.25rem;
    padding: 1rem 1rem 0 1rem;
    margin-top: 1.5rem;
  }
  .organisationcard-top {
    display: flex;
    border-radius: 0.25rem;
    img {
      object-fit: contain;
    }
  }
  .qualification-details {
    display: flex;
    padding-bottom: 1rem;
  }
  .qualification-details-right {
    margin-left: 0.75rem;
    line-height: 1.7;
  }
  .qualification-title {
    font-weight: 500;
    font-size: 1rem;
    color: #0c181f;
  }
  .qualification-year {
    font-weight: 400;
    font-size: 0.875rem;
    color: #9ea3a5;
  }

  .organisationcard-bottom {
    display: flex;
    font-weight: 400;
    font-size: 0.75rem;
    color: #3d464c;
  }
`;
