import styled from 'styled-components';

import {Modal} from '../../../../../../../../../components/common';

export const AddDepartmentsContainer = styled(Modal)`
  .suggestion {
    position: absolute;
    background: #ffffff;
    border: 0.0625rem solid #dedede;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.5rem;
    width: 93%;
    overflow-x: hidden;
    max-height: 15.62rem !important;
    @media (max-width: 41.25rem) {
      & {
        width: 86%;
      }
    }
    .department-table {
      width: 100%;
      tbody {
        tr {
          border-bottom: 0.0625rem solid transparent;
          border-top: 0.0625rem solid transparent;
          padding: 0.875rem 1.125rem;
          td {
            :hover {
              background-color: #ecf6ff;
              .add-btn {
                display: block !important;
              }
            }
          }
          .addPlusBtn {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border: none;
            background: transparent;
            outline: none;
            text-align: left;
            .add-btn {
              display: none;
            }
          }
        }
      }
    }
  }
  .add-assessment-button {
    width: 100%;
    border-radius: 0.312rem;
  }
  .error-dept {
    width: 100%;
    border-radius: 0.312rem;
    p {
      color: #eb5757;
      padding: 0.75rem 1.5rem;
    }
  }
  .department-selected-div {
    max-height: 14.37rem !important;
    overflow-y: scroll;
    margin-bottom: 0.937rem;
  }

  .added-departments-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.812rem 0.75rem;
    color: #3d4457;
    border-radius: 0.25rem;
    :hover {
      background-color: #ecf6ff;
      .department-cancel {
        display: block;
      }
    }
    .department-cancel {
      border: none;
      background: transparent;
      outline: none;
      display: none;
    }
  }
`;
