/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {
  getAllUnitsTeachers,
  classesSelector,
} from '../../../../../../../../redux/classesSlice';
import {
  EmptyState,
  Table,
  Spacer,
} from '../../../../../../../../components/common';
import {CheckBox, AddTeacherDropdown, Header, Footer} from './components';

import {TableSpinner} from '../../../../../../components-new';
import {UnitsContainer} from './elements';

// const DELAY = 500;

export const AddUnitsToClass = ({setProgress, progress, setAddClassStep}) => {
  const dispatch = useDispatch();

  const {
    unitTeachers,
    qualificationUnits,
    currentClass,
    isSingleClassLoading,
    isAddClassLoading,
    newClassUnitsCount,
  } = useSelector(classesSelector);

  const [orgUnit, setOrgUnit] = useState({});

  const columns = useMemo(
    () => [
      {
        Header: '',
        Cell: ({row: {original}}) => {
          const orgUnitId = original?.oqu_id_orgunit;

          return (
            <CheckBox
              onCheck={() => {
                setOrgUnit(state => {
                  if (state[orgUnitId]) {
                    const newOrgUnit = {...state};
                    delete newOrgUnit[orgUnitId];
                    return newOrgUnit;
                  }
                  const newOrgUnit = {...state, [orgUnitId]: []};
                  return newOrgUnit;
                });
              }}
              isChecked={Object.keys(orgUnit).includes(orgUnitId)}
              // id={orgUnitId}
              isDisabled={original.unitType === 'core'}
              id="check-box"
            />
          );
        },
        accessor: 'oqu_id_orgqualificationunit',
        className: 'check-box',
      },
      {
        Header: 'Unit Code',
        accessor: 'orgUnit.unit.tun_code',
        className: 'unit-code',
      },
      {
        Header: 'Unit Name',
        accessor: 'orgUnit.unit.tun_title',
        className: 'unit-name',
      },
      {
        Header: 'Teacher Name',
        Cell: ({value}) => (
          <AddTeacherDropdown
            unitTeachers={unitTeachers}
            onAddTeacher={(teacherId, fname, lname) => {
              setOrgUnit(state => {
                const newOrgUnit = {...state};
                newOrgUnit[value] = [
                  ...newOrgUnit[value],
                  {teacherId, fname, lname},
                ];
                return newOrgUnit;
              });
            }}
            orgUnit={orgUnit}
            onRemoveTeacher={teacherId => {
              setOrgUnit(state => {
                const newOrgUnit = {...state};
                newOrgUnit[value] = newOrgUnit[value].filter(
                  item => item.teacherId !== teacherId,
                );
                return newOrgUnit;
              });
            }}
            isChecked={Object.keys(orgUnit).includes(value)}
            orgUnitId={value}
          />
        ),
        accessor: 'oqu_id_orgunit',
        className: 'teachers',
      },
      {
        Header: 'Unit Type',
        Cell: ({value}) => (
          <div className="type-container">
            <div className={value}>{value.toUpperCase()}</div>
          </div>
        ),
        accessor: 'unitType',
        className: 'essentials',
      },
    ],
    [orgUnit, unitTeachers],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(qualificationUnits?.orgQualificationUnits || []).map(
        (item, index) => ({
          ...item,
          serial: index + 1,
          unitType: item.oqu_is_essential === 1 ? 'core' : 'elective',
        }),
      ),
    ],
    [qualificationUnits],
  );

  useEffect(() => {
    dispatch(getAllUnitsTeachers());
  }, [dispatch]);

  useEffect(() => {
    const unitsWithTeachers = {};
    qualificationUnits?.orgQualificationUnits?.forEach(item => {
      if (item?.oqu_is_essential === 1) {
        unitsWithTeachers[item.oqu_id_orgunit] = [];
      }
    });
    setOrgUnit(unitsWithTeachers);
  }, [qualificationUnits]);

  return (
    <>
      <Header />
      <Spacer height="6rem" />
      <UnitsContainer>
        <h2 className="class-name">
          Add Units to {currentClass?.classData?.classname}
        </h2>
        <p className="qualification-name">
          {qualificationUnits?.qualification?.tqual_title}
        </p>
        <p className="unit-list">unit list</p>
        <div className="table-container">
          {isSingleClassLoading ? <TableSpinner /> : null}
          {!isSingleClassLoading &&
          Array.isArray(qualificationUnits?.orgQualificationUnits) &&
          !qualificationUnits?.orgQualificationUnits?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Units Found"
            />
          ) : null}
          {qualificationUnits?.orgQualificationUnits?.length &&
          Array.isArray(qualificationUnits?.orgQualificationUnits) &&
          !isSingleClassLoading ? (
            <Table
              columns={columns}
              data={data}
              maxWidth="75rem"
              isSortedBy
              defaultSorted={[
                {
                  id: 'unitType',
                  desc: false,
                },
              ]}
              itemName="Units"
              filterTabs={[
                {
                  column: 'unitType',
                  itemName: 'All',
                },
                {
                  column: 'unitType',
                  itemName: 'Core',
                },
                {
                  column: 'unitType',
                  itemName: 'Elective',
                },
              ]}
            />
          ) : null}
        </div>
      </UnitsContainer>
      <Spacer height="9rem" />
      <Footer
        orgUnit={orgUnit}
        isAddClassLoading={isAddClassLoading}
        progress={progress}
        setProgress={setProgress}
        setAddClassStep={setAddClassStep}
        newClassUnitsCount={newClassUnitsCount}
        currentClass={currentClass}
      />
    </>
  );
};
