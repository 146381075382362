import axios from '../..';

export const fetchAllCourses = () => axios.get('api/admin/courses');

export const fetchAllCourseType = () =>
  axios.get('api/admin/courses/course-type');

export const fetchAllUnitByQualificationId = qualificationId =>
  axios.get(`api/admin/courses/add-course/all-units/${qualificationId}`);

export const addCourse = data =>
  axios.post(`api/admin/courses/add-course`, data);

export const updateCourse = (courseId, data) =>
  axios.patch(`api/admin/courses/update-course/${courseId}`, data);

export const fetchAllUnitByCourseId = courseId =>
  axios.get(`api/admin/courses/update-course/all-units/${courseId}`);

export const deleteCourse = orgCourseId =>
  axios.delete(`api/admin/courses/delete-course/${orgCourseId}`);

export const archiveClasses = orgCourseId =>
  axios.patch(`api/admin/courses/archived-classes/${orgCourseId}`);

// classes list
export const fetchClassesByCourseId = orgCourseId =>
  axios.get(`api/admin/courses/classes/${orgCourseId}`);

export const archiveClasseById = orgClassId =>
  axios.patch(`api/admin/courses/classes/archived-class/${orgClassId}`);

export const deleteClassById = orgClassId =>
  axios.delete(`api/admin/courses/classes/delete-class/${orgClassId}`);
