/* eslint-disable react/prop-types */
import React from 'react';

import ProfileIcon from '../../../../../../assets/icons/jc_logo.svg';

import {OrganisationCardContainer} from './elements';

export const OrganisationCard = ({
  organisationDetails,
  enrolledQualification,
}) => (
  <OrganisationCardContainer>
    <div className="organisationcard">
      {enrolledQualification?.map(item => (
        <div className="qualification-details">
          <div>
            {organisationDetails?.or_logo_url ? (
              <div className="organisationcard-top">
                <img
                  src={
                    organisationDetails?.or_logo_url
                      ? organisationDetails?.or_logo_url
                      : ProfileIcon
                  }
                  alt="organisation icon"
                  width="48px"
                  height="48px"
                />
              </div>
            ) : null}
          </div>
          <div className="qualification-details-right">
            <div className="qualification-title">{item?.tqual_title}</div>
            <div className="qualification-year">{item?.oay_year}</div>
            <div className="organisationcard-bottom">
              <span>
                {organisationDetails?.or_orgname},{' '}
                {organisationDetails?.or_address}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  </OrganisationCardContainer>
);
