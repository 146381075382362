/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {Color} from '../../../../../constants';

export const Card = styled.div`
  border: 0.0625rem solid #dedede;
  margin-right: 0.5rem;
  position: relative;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 0.4375rem;
  padding: 0.5rem;
  width: 7.31rem;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }
  .file-blob {
    border-radius: 0.3125rem;
    display: block;
    height: 4.62rem;
    margin-bottom: 0.875rem;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    width: 100%;

    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    img {
      height: 1.25rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.103rem;
    }
  }
  .file-name {
    color: ${Color.PRIMARY_TEXT};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.21;
    margin-bottom: 0.125rem;
  }
  .file-size {
    color: ${Color.LIGHT_TEXT};
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.2;
  }
  /* &:hover {
    box-shadow: -3125rem 3125rem 9px 0px rgba(230, 230, 230, 1);
  } */
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({width}) => width};
`;

export const RemoveFileIco = styled.img`
  position: absolute;
  width: 1.25rem !important;
  right: -0.4375rem;
  margin: 0;
  top: -0.875rem;
  transition: all 0.1s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
`;
