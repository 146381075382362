import axios from '../..';

export const deleteUnit = (qualificationId, unitId) =>
  axios.delete(
    `api/admin/qualifications/${qualificationId}/deleteUnit/${unitId}`,
  );

export const fetchAllFilterQualifications = () =>
  axios.get('api/admin/qualifications/all-units/all-qualifications');

export const fetchAllUnits = qualificationId =>
  axios.get(
    `api/admin/qualifications/all-units${
      qualificationId ? `?qu_id=${qualificationId}` : ''
    }`,
  );
export const getUnitById = unitId => axios.get(`api/admin/units/${unitId}`);

export const updateUnit = (unitId, data) =>
  axios.patch(`api/admin/qualifications/all-units/editUnit/${unitId}`, data);
