/* eslint-disable react/prop-types */
import React, {useEffect, useState, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import {
  AddStudentContainer,
  SpreadSheetTableStyles,
  FullContainer,
} from './elements';
import {
  AddStudentFooter,
  AddStudentHeader,
  ManualForm,
  UploadSection,
  Accordion,
} from './components';

import {
  ValidateLoader,
  SuccessMessage,
  NoUserAdded,
} from '../AddClass/components';

import CustomBox from '../../../../../../components/shared/CustomBox';

import {
  classesSelector,
  getAllOrgStudents,
  getClassById,
} from '../../../../../../redux/classesSlice';

import {Table, Spacer} from '../../../../../../components/common';

export const AddStudentsToViewClass = () => {
  const [addStudents, setAddStudents] = useState([]);
  const [spreadSheet, setSpreadSheet] = useState([]);
  const [fileImport, setImport] = useState(true);
  const [manual, setManual] = useState(false);
  const [upload, setUpload] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const [formShow, setFormShow] = useState(true);
  const [btnShow, setBtnShow] = useState(true);
  const [nextShow, setNextShow] = useState(false);
  const [fileName, setFileName] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const {orgClassId} = useParams();
  useEffect(() => {
    dispatch(getClassById(orgClassId));
  }, [dispatch, orgClassId]);

  const {
    studentEmailPattern,
    validClassStudent,
    singleClass,
    duplicateData,
    alreadyExistStudentRefId,
    alreadyExistStudentEmail,
    otherOrganisationStudentEmail,
    otherOrganisationStudentReferId,
  } = useSelector(classesSelector);

  const [isValidating, setIsValidating] = useState(false);
  const [steps, setSteps] = useState(0);

  useEffect(() => {
    if (addStudents.length === 0) {
      setBtnShow(true);
      setFormShow(true);
    }
  }, [addStudents, spreadSheet]);

  useEffect(() => {
    dispatch(getAllOrgStudents());
  }, [dispatch]);

  // Spreadsheet flow mapping ,existing id checking and error mapping
  const spreadsheetcolumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'ID',
        accessor: 'org_ref_id',
        className: 'ref-id',
      },
      {
        Header: 'First Name',
        accessor: 'first_name',
        className: 'first-name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        className: 'last-name',
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        className: 'email',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, studentEmailPattern],
  );

  // Add a serial number to each row and memoize the data.
  // spreadsheet map
  const spreadsheetdata = useMemo(
    () => [
      ...spreadSheet.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [spreadSheet],
  );

  // Error map in spreadsheet
  const emailErrorData = useMemo(
    () => [
      ...alreadyExistStudentEmail.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [alreadyExistStudentEmail],
  );
  const referIDErrorData = useMemo(
    () => [
      ...alreadyExistStudentRefId.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [alreadyExistStudentRefId],
  );

  const otherOrgEmailErrorData = useMemo(
    () => [
      ...otherOrganisationStudentEmail.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [otherOrganisationStudentEmail],
  );
  const otherOrgReferIdErrorData = useMemo(
    () => [
      ...otherOrganisationStudentReferId.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [otherOrganisationStudentReferId],
  );

  const emailPatternErrorData = useMemo(
    () => [
      ...studentEmailPattern.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [studentEmailPattern],
  );
  const duplicateDataError = useMemo(
    () => [
      ...duplicateData.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [duplicateData],
  );

  return (
    <>
      <Helmet>
        <title>Add Students | JungleCat</title>
      </Helmet>
      <FullContainer>
        <AddStudentHeader orgClassId={orgClassId} />
        <AddStudentContainer>
          <div className="container-student">
            <div className="studentheading d-flex justify-content-between  mb-4">
              <h2 style={{fontSize: '1.5rem'}}>
                Add students to {singleClass?.oc_class}
              </h2>
              <p className="sub-text">
                Add students from your organisation to this class
              </p>
            </div>
            <CustomBox padding="0.0625rem 1.25rem" className="custombox">
              {steps === 0 ? (
                <div className="search-div">
                  <button
                    className={fileImport ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(true);
                      setManual(false);
                      setSteps(0);
                    }}
                  >
                    <p>Import From Spreadsheet</p>
                  </button>
                  <button
                    className={manual ? 'buttom link' : 'buttom'}
                    type="button"
                    onClick={() => {
                      setImport(false);
                      setManual(true);
                    }}
                  >
                    <p>Add Manually</p>
                  </button>
                  <div className="seperator-search mt-2" />
                </div>
              ) : null}

              {steps === 1 || isValidating ? (
                <div className="search-div-spread">
                  <p className="imported">Imported students from document</p>
                  <p className="imported-file">{fileName}</p>
                </div>
              ) : null}

              {manual ? (
                <ManualForm
                  manual={manual}
                  formShow={formShow}
                  btnShow={btnShow}
                  setFormShow={setFormShow}
                  setBtnShow={setBtnShow}
                  addStudents={addStudents}
                  setAddStudents={setAddStudents}
                />
              ) : (
                <div
                  className="inputfields"
                  style={steps === 1 ? {padding: '0rem 0rem 0rem 0rem'} : null}
                >
                  {steps === 0 && fileImport ? (
                    <UploadSection
                      steps={steps}
                      setUpload={setUpload}
                      upload={upload}
                      onDrag={onDrag}
                      setOnDrag={setOnDrag}
                      setSpreadSheet={setSpreadSheet}
                      setFileName={setFileName}
                      setNextShow={setNextShow}
                    />
                  ) : null}

                  {isValidating ? (
                    <ValidateLoader
                      title="Validating imported students details"
                      subtitle="Please wait"
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 2 &&
                  validClassStudent?.length === spreadSheet?.length &&
                  !duplicateData?.length &&
                  !alreadyExistStudentRefId?.length &&
                  !alreadyExistStudentEmail?.length &&
                  !studentEmailPattern?.length &&
                  !otherOrgEmailErrorData?.length &&
                  !otherOrgReferIdErrorData?.length ? (
                    <SuccessMessage
                      title={`Class created successfully with ${spreadSheet?.length} students `}
                      subtitle="all entries are created successfully"
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 2 &&
                  validClassStudent?.length &&
                  (duplicateData?.length ||
                    studentEmailPattern?.length ||
                    alreadyExistStudentRefId?.length ||
                    alreadyExistStudentEmail?.length ||
                    otherOrgEmailErrorData?.length ||
                    otherOrgReferIdErrorData?.length) ? (
                    <Accordion
                      title={`${validClassStudent.length}/${spreadSheet.length} students can be added to
                    ${singleClass?.oc_class}`}
                      subtitle="Some of the user entries are faulty. you can continue add valid user entries or reupload the document with changes"
                      emailPatternErrorData={emailPatternErrorData}
                      referIDErrorData={referIDErrorData}
                      emailErrorData={emailErrorData}
                      spreadsheetcolumns={spreadsheetcolumns}
                      studentEmailPattern={studentEmailPattern}
                      alreadyExistStudentRefId={alreadyExistStudentRefId}
                      alreadyExistStudentEmail={alreadyExistStudentEmail}
                      duplicateDataError={duplicateDataError}
                      otherOrgEmailErrorData={otherOrgEmailErrorData}
                      otherOrgReferIdErrorData={otherOrgReferIdErrorData}
                    />
                  ) : null}
                  {!isValidating &&
                  steps === 2 &&
                  !validClassStudent?.length &&
                  (duplicateData?.length ||
                    studentEmailPattern?.length ||
                    alreadyExistStudentRefId?.length ||
                    alreadyExistStudentEmail?.length ||
                    otherOrgEmailErrorData?.length ||
                    otherOrgReferIdErrorData?.length) ? (
                    <NoUserAdded
                      title="No students added to this class"
                      subtitle="all the user entries are faulty"
                    />
                  ) : null}
                </div>
              )}

              {!isValidating && spreadSheet?.length && steps === 1 ? (
                <SpreadSheetTableStyles>
                  <Table
                    columns={spreadsheetcolumns}
                    data={spreadsheetdata}
                    itemName="Students"
                    maxWidth="49.37rem"
                    isSortedBy
                    isWithoutHeader
                  />
                </SpreadSheetTableStyles>
              ) : null}
            </CustomBox>
          </div>
        </AddStudentContainer>
        <Spacer height="4rem" />
        <AddStudentFooter
          addStudents={addStudents}
          setAddStudents={setAddStudents}
          spreadSheet={spreadSheet}
          setSpreadSheet={setSpreadSheet}
          manual={manual}
          setUpload={setUpload}
          upload={upload}
          steps={steps}
          setSteps={setSteps}
          setIsValidating={setIsValidating}
          nextShow={nextShow}
          isValidating={isValidating}
          studentEmailPattern={studentEmailPattern}
          alreadyExistStudentRefId={alreadyExistStudentRefId}
          alreadyExistStudentEmail={alreadyExistStudentEmail}
          otherOrgEmailErrorData={otherOrgEmailErrorData}
          otherOrgReferIdErrorData={otherOrgReferIdErrorData}
        />
      </FullContainer>
    </>
  );
};
