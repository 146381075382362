import styled from 'styled-components';

export const SessionAttendanceGraphConatiner = styled.div`
  background: #fff !important;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
  .bar-chart {
    height: 100% !important;
  }
`;
