import styled from 'styled-components';

const DurationAndSchedulingFormContainer = styled.div`
  .serial {
    width: 4%;
  }

  .elective-category {
    width: 49%;
  }

  .unit-count {
    width: 47%;
  }
`;

export default DurationAndSchedulingFormContainer;
