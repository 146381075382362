import styled from 'styled-components';

export const AssessmentStatusContainer = styled.header`
  .header-last {
    padding: 0 1.5rem 0rem 1.5rem;
  }
  .outline-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.0625rem solid #e6e7e8;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
  }
  .outline-left {
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 0.25rem;
      font-weight: 500;
      font-size: 1rem;
      color: #091018;
    }
    .time {
      margin-left: 3rem;
    }
    span {
      font-weight: 400;
      font-size: 0.875rem;
      color: #84888c;
    }
    &.status {
      display: flex !important;
      flex-direction: column !important;

      .status-text {
        font-weight: 500;
        font-size: 1rem;

        margin-bottom: 0.25rem;
        &.resubmission-requested,
        &.submitted {
          color: #5ba1f2 !important;
        }
        &.reassigned {
          color: #5355f6 !important;
        }
        &.request-declined {
          color: #ff6969 !important;
        }
      }
      .time {
        margin-left: unset !important;
        font-weight: 400;
        font-size: 0.875rem;
        display: flex;

        .date {
          &.submitted,
          &.resubmission-requested {
            color: #5ba1f2 !important;
          }
          &.reassigned {
            color: #5355f6 !important;
          }
          &.request-declined {
            color: #ff6969 !important;
          }
        }
        .hrs {
          margin-left: 0.5rem;
          color: #5ba1f2 !important;
          span {
            color: #5ba1f2 !important;
          }
        }
      }
    }
  }

  .view-assessment {
    font-weight: 600;
    font-size: 1rem;
    color: #5ba1f2;
    :hover {
      text-decoration: unset;
    }
    .body_a-icon {
      margin-left: 0.3125rem;
    }
  }
  .assessment-evaluate {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #091018;
  }
`;
