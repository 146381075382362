import styled, {css} from 'styled-components';

export const LibraryCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width:calc(100% - 1.875rem);
  position: absolute;
  left:0.9375rem;
  top:0;
  height:100%;


  
  :not(:last-child){
    padding-right: 0.5rem;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;
    width: 98%;
    text-align: left;
    .card-detail {
      width: 100%;
      display: flex;
      .card-left {
        width: 92%;
        font-weight: 500;
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .dropdowncontainer{
        top:-6rem !important;
        min-width: 8rem;
      }
      }
      /* .card-right{
        width:1.35rem;
        height:1.35rem;
        button{
          width:1.35rem;
          height:1.35rem;
        }
      } */
    }
    .card-detail-bottom {
      font-weight: normal;
      font-size: 0.75rem;
      margin-top: 0.125rem;
      color: #9EA3A5;
      text-transform:capitalize;
      display: flex;
     .class-name,.date{
        margin-left:0.2rem ;
      }
      .class-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       max-width: 46%;
      }
      .code{
        max-width: 20%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      /* 
      .date{
        width: 20%;
      } */
    }
  }
`;

export const LibraryCardListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({width}) => width};
  margin-bottom: 1rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);
`;

export const FileContainer = styled.button`
  outline: none;

  border: unset;
  margin-right: 0.5rem;
  position: relative;
  background: white;
  box-sizing: border-box;
  border-radius: 0.4375rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({height}) =>
    height
      ? css`
          height: ${height};
        `
      : '8.75rem'};
  .file-content {
    width: 100%;
    outline: none;
    height: 100%;
    border: none;
    .file-container {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .files {
      object-fit: contain;
    }
  }
  .file-blob {
    border-radius: 0.3125rem;
    display: block;
    height: 4.62rem;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;

    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .file-blob,
    .files,
    .thumnail-icon,
    .play-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover !important;
    }
    .play-video {
      width: 3rem;
      height: 3rem;
    }
  }
  /* @media only screen and (min-width: 77.5rem) and (min-height: 50rem) and (-webkit-min-device-pixel-ratio: 1.5) {
    & {
      height: 12rem;
    }
  } */
  .file-type {
    position: absolute;
    bottom: 0.625rem;
    left: 0.625rem;
    display: flex;
    flex-direction: row;
  }
  .icon-badge {
    max-width: 1.5rem;
    max-height: 1.375rem;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    border-radius: 0.15rem;
    justify-content: center;
    img {
      padding: 0.1875rem 0.25rem;
    }
  }
  .badge {
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    height: 1.375rem;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.15rem;
    span {
      font-size: 0.75rem;
      color: #ffffff;
      text-transform: capitalize;
      font-weight: normal;
    }
  }
`;
