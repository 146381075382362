import {io} from 'socket.io-client';
import {SOCKET_EVENTS} from '../../../../../constants';

export const socket = io('https://socket.junglecat.com');
// export const socket = io('http://192.168.1.19:9441');

const useSocket = (roomId, userId, isHost) => {
  const createRoom = callback => {
    socket.emit(SOCKET_EVENTS.LS_CREATE, {roomId, userId}, callback);
  };

  const joinRoom = ({firstname, lastname, avatar, username}, callback) => {
    socket.emit(
      SOCKET_EVENTS.LS_JOIN,
      {
        roomId,
        userId,
        firstname,
        lastname,
        username,
        avatar,
      },
      callback,
    );
  };

  const listenStudentsUpdated = callback => {
    socket.on(SOCKET_EVENTS.LS_STUDENTS_UPDATED, payload => {
      callback(payload);
    });
  };

  const listenNewStudentJoined = callback => {
    socket.on(SOCKET_EVENTS.LS_NEW_STUDENT_JOINED, payload => {
      callback(payload);
    });
  };

  const listenStudentLeft = callback => {
    socket.on(SOCKET_EVENTS.LS_STUDENT_LEFT, payload => {
      callback(payload);
    });
  };

  const listenCallEndedByHost = callback => {
    socket.on(SOCKET_EVENTS.LS_CALL_ENDED_BY_HOST, payload => {
      callback(payload);
    });
  };

  const leaveRoom = (firstname, lastname, avatar) => {
    socket.emit(SOCKET_EVENTS.LS_LEAVE, {
      roomId,
      userId,
      isHost,
      firstname,
      lastname,
      avatar,
    });
  };

  const toggleVideoState = callback => {
    socket.emit(
      SOCKET_EVENTS.LS_TOGGLE_VIDEO_STATE,
      {roomId, userId},
      callback,
    );
  };

  const toggleAudioState = callback => {
    socket.emit(
      SOCKET_EVENTS.LS_TOGGLE_AUDIO_STATE,
      {roomId, userId},
      callback,
    );
  };

  const forceAudioMute = (id, callback) => {
    socket.emit(
      SOCKET_EVENTS.LS_FORCE_AUDIO_MUTE,
      {roomId, userId: id},
      callback,
    );
  };

  const forceVideoMute = (id, callback) => {
    socket.emit(
      SOCKET_EVENTS.LS_FORCE_VIDEO_MUTE,
      {roomId, userId: id},
      callback,
    );
  };

  const listenAudioMutedByHost = callback => {
    socket.on(SOCKET_EVENTS.LS_AUDIO_MUTED_BY_HOST, payload => {
      callback(payload);
    });
  };

  const listenVideoMutedByHost = callback => {
    socket.on(SOCKET_EVENTS.LS_VIDEO_MUTED_BY_HOST, payload => {
      callback(payload);
    });
  };

  const kickOutUser = (id, callback) => {
    socket.emit(SOCKET_EVENTS.LS_KICK_OUT_USER, {userId: id, roomId}, callback);
  };

  const listenKickedOutByHost = callback => {
    socket.on(SOCKET_EVENTS.LS_KICKED_OUT_BY_HOST, payload => {
      callback(payload);
    });
  };

  const sendMessage = (message, callback) => {
    socket.emit(
      SOCKET_EVENTS.LS_SEND_MESSAGE,
      {roomId, userId, ...message},
      callback,
    );
  };

  const listenNewMessage = callback => {
    socket.on(SOCKET_EVENTS.LS_NEW_MESSAGE, payload => {
      callback(payload);
    });
  };

  const type = user => {
    socket.emit(SOCKET_EVENTS.LS_TYPE, {roomId, user});
  };

  const onTyping = callback => {
    socket.on(SOCKET_EVENTS.LS_TYPING, callback);
  };

  const stopTyping = () => {
    socket.emit(SOCKET_EVENTS.LS_STOP_TYPING, {roomId, id: userId});
  };

  const onTypingStop = callback => {
    socket.on(SOCKET_EVENTS.LS_TYPING_STOP, callback);
  };

  const removeAllLiveSessionListeners = () => {
    Object.values(SOCKET_EVENTS).forEach(event => {
      socket.off(event);
    });
  };

  const requestScreenShare = student =>
    new Promise(resolve => {
      socket.emit(
        SOCKET_EVENTS.LS_REQUEST_SCREENSHARE_PERMISSION,
        {roomId, student},
        response => {
          resolve(response);
        },
      );
    });

  const listenScreenShareRequest = callback => {
    socket.on(SOCKET_EVENTS.LS_NEW_SCREENSHARE_REQUEST, async (payload, cb) => {
      const response = await callback(payload);
      cb(response);
    });
  };

  const sendScreenSharingDetails = (firstname, lastname) => {
    socket.emit(SOCKET_EVENTS.LS_SCREENSHARING_USER_DETAILS, {
      roomId,
      userId,
      firstname,
      lastname,
    });
  };

  const listenScreeSharingUserDetailsUpdate = callback => {
    socket.on(SOCKET_EVENTS.LS_UPDATE_SCREENSHARING_USER_DETAILS, payload => {
      callback(payload);
    });
  };

  return {
    id: socket.id,
    createRoom,
    joinRoom,
    leaveRoom,
    listenNewStudentJoined,
    listenStudentLeft,
    listenStudentsUpdated,
    listenCallEndedByHost,
    toggleVideoState,
    toggleAudioState,
    forceAudioMute,
    forceVideoMute,
    listenAudioMutedByHost,
    listenVideoMutedByHost,
    kickOutUser,
    listenKickedOutByHost,
    sendMessage,
    listenNewMessage,
    type,
    onTyping,
    stopTyping,
    onTypingStop,
    removeAllLiveSessionListeners,
    requestScreenShare,
    listenScreenShareRequest,
    sendScreenSharingDetails,
    listenScreeSharingUserDetailsUpdate,
  };
};

export default useSocket;
