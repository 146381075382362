/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {useContext} from 'react';
import {LiveSessionContext} from '../../../../contexts/LiveSessionContext';
import SessionPeerVideo from '../SessionPeerVideo';
import VideoGrid from '../VideoGrid';
import {DefaultLayoutWrapper} from './elements';

const DefaultLayout = ({
  localVideoTrack,
  localAudioTrack,
  remoteUsers,
  remoteHost,
  speakingStates,
}) => {
  const {isHost, userId} = useContext(LiveSessionContext);

  return (
    <DefaultLayoutWrapper>
      {isHost ? (
        <>
          <SessionPeerVideo
            videoTrack={localVideoTrack}
            audioTrack={localAudioTrack}
            speakingStates={speakingStates}
            key={localVideoTrack?.getTrackId()}
            uid={userId}
          />
          <VideoGrid
            remoteUsers={remoteUsers}
            speakingStates={speakingStates}
          />
        </>
      ) : (
        <>
          <SessionPeerVideo
            videoTrack={remoteHost?.videoTrack}
            audioTrack={remoteHost?.audioTrack}
            speakingStates={speakingStates}
            key={remoteHost?.uid}
            uid={remoteHost?.uid}
          />
          <VideoGrid
            localVideoTrack={localVideoTrack}
            localAudioTrack={localAudioTrack}
            speakingStates={speakingStates}
            remoteUsers={remoteUsers.filter(
              remoteUser => remoteUser?.uid !== remoteHost?.uid,
            )}
          />
        </>
      )}
    </DefaultLayoutWrapper>
  );
};

DefaultLayout.defaultProps = {
  localVideoTrack: null,
  localAudioTrack: null,
  remoteUsers: [],
  remoteHost: null,
  speakingStates: {},
};

DefaultLayout.propTypes = {
  localVideoTrack: PropTypes.objectOf(PropTypes.any),
  localAudioTrack: PropTypes.objectOf(PropTypes.any),
  remoteUsers: PropTypes.arrayOf(PropTypes.object),
  remoteHost: PropTypes.objectOf(PropTypes.any),
  speakingStates: PropTypes.objectOf(PropTypes.any),
};

export default DefaultLayout;
