import styled from 'styled-components';

export const AddedMappedTaskContainer = styled.div`
  .seperator {
    height: 0.0625rem;
    background-color: #dedede;
    width: 100%;
  }

  .accordion {
    width: 100%;
    .card {
      border: unset;
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
    .card-header {
      padding: 0.75rem 0.875rem;
      background-color: unset;
      &.clicked {
        background-color: #fbfafa;
      }
    }
    .card-body {
      padding: 1rem 1.5rem;
    }
    .accordion-header {
      display: flex;
      align-items: center;
    }
    .btn {
      line-height: 1;
      font-weight: 500;
      font-size: 0.875rem;
      color: #0c181f;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }
      .arrow {
        width: 1.1rem;
        height: 1.1rem;
      }
      .resource {
        margin-right: 0.93rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .performance-criteria {
    display: flex;
    padding: 0 0 1rem 0;
    border-bottom: 0.0625rem solid #e6e7e8;
  }
  .other-mapping {
    display: flex;
    padding: 1rem 0;
    border-bottom: 0.0625rem solid #e6e7e8;
  }
  .assessment-conditions {
    display: flex;
    padding: 1rem 0 0 0;
  }
  .mapping-header {
    width: 40%;
    font-size: 0.875rem;
    color: #0c181f;
  }
  .mapping-content {
    width: 55%;
    font-size: 0.875rem;
    color: #6d7579;
  }
  @media (max-width: 33rem) {
    .performance-criteria,
    .other-mapping,
    .assessment-conditions {
      display: flex;
      flex-direction: column;
    }
    .mapping-header {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .mapping-content {
      width: 100%;
    }
  }
`;
