/* eslint-disable react/prop-types */
import React, {useMemo, useState} from 'react';
// import {useSelector} from 'react-redux';
import moment from 'moment';
import {useLocalStorage} from 'react-use';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {
  Table,
  EmptyState,
  MoreOptions,
  Modal,
} from '../../../../../../components/common';
import {showToast} from '../../../../../../components/common/Toast';
import PlusIcon from '../../../../../../assets/icons/refactor/plus-white.svg';
import {MyAssessmentsContainer} from './elements';
import {
  activeClassSelector,
  deleteAssessment,
  fetchActiveClasses,
} from '../../../../../../redux/activeClassSlice';

export const MyAssessments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const {allAssessments, isDeleteLoading} = useSelector(activeClassSelector);
  const [userType] = useLocalStorage('jc-user-type', '', {
    raw: true,
  });
  const currentProfile = Number(userType);

  const onButtonClick = () => {
    history.replace('/classes/assessment/create');
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'TITLE',
        accessor: 'ac_activity_name',
        className: 'assessment-title',
      },

      {
        Header: 'LAST UPDATED',
        Cell: ({row: {original}}) => {
          const date = original?.ac_timestamp_creation;
          return <p className="date"> {moment(date).format('D/MM/YYYY')}</p>;
        },
        accessor: 'ac_timestamp_creation',
        className: 'last-updated',
      },
      {
        Header: 'UNIT',
        Cell: ({row: {original}}) => (
          <p className="column-text">
            {original?.tun_code} - {original?.tun_title}
          </p>
        ),
        accessor: 'tun_title',
        className: 'unit',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <MoreOptions>
            <DropdownItem
              label="Edit"
              onClick={() => {
                history.replace(
                  `/classes/assessment/${original?.ac_id_activity}/update`,
                );
              }}
            />
            <DropdownItem
              isDanger
              label="Delete"
              onClick={() => {
                setDeleteId(original?.ac_id_activity);
                setDeleteName(original?.ac_activity_name);
              }}
            />
          </MoreOptions>
        ),
        accessor: 'ac_id_activity',
        className: 'more-option',
      },
    ],
    [history],
  );

  //   Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(allAssessments || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [allAssessments],
  );

  //   const academicYearsOptions = useMemo(
  //     () => [
  //       {label: 'All Academic Years', value: ''},
  //       ...(classState?.academicYears || [])?.map(item => ({
  //         label: `${moment(new Date(item.oay_from_date)).format(
  //           'MMM YYYY',
  //         )} - ${moment(new Date(item.oay_to_date)).format('MMM YYYY')}`,
  //         value: item.oay_id_orgacadyear,
  //       })),
  //     ],
  //     [classState],
  //   );

  //   console.log(academicYearsOptions);

  return (
    <MyAssessmentsContainer>
      <div className="table-container">
        {!allAssessments.length ? (
          <EmptyState
            description="It seems that there is no Assessments has been added"
            title="No Assessments are added yet!"
            buttonLabel="Add Assessment"
            buttonIcon={PlusIcon}
            onButtonClick={onButtonClick}
          />
        ) : null}
        {allAssessments?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Assessments"
            maxWidth="75rem"
            isSortedBy
            buttonLabel="Add Assessment"
            buttonIcon={PlusIcon}
            onButtonClick={onButtonClick}
          />
        ) : null}
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this assessment?"
          isButtonLoading={isDeleteLoading}
          isDelete
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isDeleteLoading) {
              dispatch(
                deleteAssessment(deleteId, () => {
                  dispatch(fetchActiveClasses(currentProfile));
                  showToast(false, `${deleteName} deleted successfully`, true);
                }),
              );
              setDeleteId(null);
              setDeleteName('');
            }
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, 500);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
      </div>
    </MyAssessmentsContainer>
  );
};
