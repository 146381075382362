import styled from 'styled-components';
import {Color} from '../../../../../../../../../../constants';

export const AssessmentTaskContainer = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  width: 100%;
  margin-top: 1rem;
  @media (max-width: 43.75rem) {
    .assessment-task-details {
      :last-child {
        margin-bottom: unset;
      }
    }
  }

  .added-task-instructions {
    border-top: 0.0625rem solid #dedede;
  }
  .assessment-task-details {
    border-radius: 0.5rem;
    padding: 1.5rem;
    .assessment-task-meta {
      .badge-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
      }
      .assessment-task-title {
        color: ${Color.PRIMARY_TEXT};
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: unset;
      }
      .evaluation-type {
        font-weight: normal;
        font-size: 0.75rem;
        border: 0.0625rem solid #dedede;
        box-sizing: border-box;
        border-radius: 0.25rem;
        color: #aeaeae;
        padding: 0.25rem 0.375rem;
      }

      p {
        color: #838383;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.71;
        margin-bottom: 0;
      }
      @media (max-width: 33rem) {
        .badge-container {
          flex-direction: column-reverse;
          margin-bottom: 0.5rem;
          align-items: flex-start;
          .evaluation-type {
            width: 3.5rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .accordion {
    width: 100%;
    .card {
      border: unset;
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
    }
    .card-header {
      padding: 0.75rem 0.875rem;
      background-color: unset;
      &.clicked {
        background-color: #fbfafa;
      }
    }
    .card-body {
      padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    }
    .accordion-header {
      display: flex;
      align-items: center;
    }
    .btn {
      line-height: 1;
      font-weight: 500;
      font-size: 0.875rem;
      color: #0c181f;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }
      .arrow {
        width: 1.1rem;
        height: 1.1rem;
      }
      .resource {
        margin-right: 0.93rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .instruction-container {
    display: flex;
    margin-top: 1rem;
    .data-container {
      width: 97.5%;
    }
    .index-container {
      width: 2.5%;
      display: flex;
      border-radius: 0.3125rem;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      font-size: 0.75rem;
      color: #6d7579;
      margin-right: 0.5rem;
      height: 5rem;
    }
    @media (max-width: 33rem) {
      .index-container {
        width: 1.5rem;
      }
      .data-container {
        width: 97.5%;
      }
    }
  }
  .added-instruction {
    align-items: flex-start;
    border: 0.0625rem solid #dedede;
    border-radius: 0.3125rem;
    display: flex;
    padding: 1rem;

    :not(:last-child) {
      margin-bottom: 1rem;
    }

    & > a,
    & > button {
      background-color: transparent;
      border: none;
      height: 2.5rem;
      position: relative;
      width: 2.5rem;

      :focus,
      :active {
        outline: none;
      }
    }

    .play-video-icon {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        height: 1rem;
        width: 1rem;
      }
    }

    .instruction-icon {
      border-radius: 0.3125rem;
      height: 2.5rem;
      object-fit: cover;
      width: 2.5rem;
    }

    .instruction-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 0.625rem;

      span {
        color: #aeaeae;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.21;
        margin-bottom: 0.5rem;
      }

      p {
        color: ${Color.PRIMARY_TEXT};
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.71;
        margin: -0.125rem 0 0 0;

        button {
          background-color: transparent;
          border: none;
          color: #00abff;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.71;

          :focus,
          :active {
            outline: none;
          }
        }
      }
    }

    .instruction-options {
      background-color: transparent;
      border: none;

      :focus,
      :active {
        outline: none;
      }
    }
  }

  .display-modal-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }
    &.for-document {
      width: 62%;
      @media (max-width: 33rem) {
        & {
          width: 90%;
        }
      }
    }

    .display-modal-header {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;

      .modal-close-button {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .instruction-document {
      border-radius: 0.375rem;
      height: 62vh;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .instruction-image {
      align-items: center;
      background-color: #000;
      border-radius: 0.375rem;
      display: flex;
      max-height: 62vh;
      justify-content: center;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
`;
