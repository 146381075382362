/* eslint-disable react/prop-types */

import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useMemo, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  getDataForAddCourse,
  getUnitByQualificationId,
  coursesSelector,
  setError as setServerError,
  addCourse,
  clearFormError,
} from '../../../../../../../../../../redux/coursesSlice';
import {
  Select,
  TextInput,
} from '../../../../../../../../../../components/common';
import CustomBox from '../../../../../../../../../../components/shared/CustomBox';

import {showToast} from '../../../../../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../../../../../assets/icons/addtoastadmin.svg';

import {useErrorMessage} from '../../../../../../../../../../utils/hooks';
import {courseDetailsSchema} from '../../../../../../../../../../utils/validation';
import {CourseDetailsContainer} from './elements';
import {AddUnitsToCourse, Header, Footer} from './components';

const FIELDS_IN_ORDER = ['courseName', 'qualification', 'courseType'];

export const BasicDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addUnits, setAddUnits] = useState([]);

  const {
    control,
    formState: {errors},

    handleSubmit,
  } = useForm({
    defaultValues: {
      courseName: '',
      qualification: null,
      courseType: null,
    },
    resolver: yupResolver(courseDetailsSchema),
  });

  const {
    error: serverError,
    allQualifications,
    allCourseType,
    formError,
    isAddCourseLoading,
  } = useSelector(coursesSelector);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  useEffect(() => {
    dispatch(getDataForAddCourse());
  }, [dispatch]);

  const qualificationOptions = useMemo(
    () => [
      ...(allQualifications || [])?.map(item => ({
        label: `${item.qualification.tqual_title} # ${item.qualification.tqual_code}`,
        code: item.qualification.tqual_code,
        value: item.oq_id_orgqual,
      })),
    ],
    [allQualifications],
  );
  const courseTypeOptions = useMemo(
    () => [
      ...(allCourseType || [])?.map(item => ({
        label: item.typecr_type,
        value: item.typecr_id_typecourse,
      })),
    ],
    [allCourseType],
  );
  const formatOptionForQualification = ({code, label}, {context}) =>
    context === 'menu' ? (
      <div className="select-option" type="button">
        <div className="label">{label?.split?.(' # ')?.[0]}</div>
        <div className="code mt-1">{code}</div>
      </div>
    ) : (
      label?.split(' # ')?.[0]
    );

  const onSubmit = useCallback(
    data => {
      const courseData = {
        courseName: data.courseName,
        courseType: data.courseType.value,
        qualification: data.qualification.value,
      };
      if (!isAddCourseLoading) {
        dispatch(
          addCourse(courseData, addUnits, () => {
            showToast(
              AddToastSuccessImg,
              `${data?.courseName} added successfully with ${addUnits?.length} Units`,
            );
            history.replace(`/admin/courses/active-courses`);
          }),
        );
      }
    },
    [addUnits, dispatch, history, isAddCourseLoading],
  );

  return (
    <>
      <Header />
      <CourseDetailsContainer>
        <div className="container-course">
          <div className="courseheading d-flex justify-content-between  mb-2">
            <p className="main-text">Basic Details</p>
          </div>
          <CustomBox padding="1.5rem 1.5rem" className="custombox">
            <form>
              <div className="form-container">
                <div className="row-container">
                  <div className="course-name">
                    <Controller
                      control={control}
                      name="courseName"
                      render={fields => (
                        <TextInput
                          {...fields}
                          autoComplete="off"
                          errorMessage={
                            errors.courseName?.message || formError?.courseName
                          }
                          hasError={
                            !!errors.courseName || !!formError.courseName
                          }
                          label="Course Name"
                          placeholder="Enter course name"
                          id="course-name"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row-container">
                  <div className="field-left">
                    <Controller
                      control={control}
                      name="qualification"
                      render={fields => (
                        <Select
                          {...fields}
                          hasError={!!errors.qualification}
                          errorMessage={errors.qualification?.message}
                          isLarge={false}
                          label="Qualification"
                          formatOptionLabel={formatOptionForQualification}
                          options={qualificationOptions}
                          placeholder="Choose a qualification"
                          onChange={e => {
                            fields.onChange(e);
                            setAddUnits([]);
                            dispatch(getUnitByQualificationId(e?.value));
                          }}
                          id="qualification-name"
                        />
                      )}
                    />
                  </div>
                  <div className="field-right">
                    <Controller
                      control={control}
                      name="courseType"
                      render={fields => (
                        <Select
                          {...fields}
                          hasError={!!errors.courseType}
                          errorMessage={errors.courseType?.message}
                          isLarge={false}
                          label="Type"
                          options={courseTypeOptions}
                          placeholder="Choose a type"
                          id="course-type"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </form>
          </CustomBox>
          <AddUnitsToCourse addUnits={addUnits} setAddUnits={setAddUnits} />
        </div>
      </CourseDetailsContainer>
      <Footer
        isAddCourseLoading={isAddCourseLoading}
        onSubmit={handleSubmit(onSubmit)}
        addUnits={addUnits}
      />
    </>
  );
};
