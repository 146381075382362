/* eslint-disable react/prop-types */

import {Controller} from 'react-hook-form';

import {TextArea} from '../../../../../../../../components/common/TextArea';

const TargetGroupForm = ({control, errors, onUpdate, tasStatus}) => (
  <div className="section" id="target-group">
    <h3>Target Group</h3>
    <p>
      Ensure that you clearly identify the target group and student
      characteristics in each strategy. Each strategy needs to be tailored to
      each target group. For example, a recognition of prior learning strategy
      may be utilised for students with previous knowledge, skills and work
      experience.
    </p>
    <form>
      <div className="tas-form-row">
        <Controller
          control={control}
          name="targetGroup"
          render={fields => (
            <TextArea
              {...fields}
              className="is-full-width tas-form-column"
              hasError={!!errors.targetGroup}
              hasInfo="The characteristics of the student who will be in enrolled into this cohort"
              label="Target Group (Cohort)"
              disabled={tasStatus}
              onBlur={event => {
                fields?.onBlur?.(event);
                onUpdate();
              }}
              rows={5}
            />
          )}
        />
      </div>
    </form>
  </div>
);

export default TargetGroupForm;
