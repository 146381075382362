import styled from 'styled-components';

export const UploadCoverImageContainer = styled.div`
  .upload-title {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  .org-image-full {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    align-items: center;
    border: 0.0625rem solid #dedede;
    padding: 0.75rem 0.625rem;
    justify-content: space-between;
    border-radius: 0.3125rem;
    .org-image-after {
      display: flex;
      flex-direction: row;
    }
    .delete-logo {
      margin-right: 0.625rem;
      button {
        border: none;
        background-color: transparent;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .org-text {
    padding: 0.75rem 0 0rem 0;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  .add-cover-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .org-text input {
    position: absolute;
    color: black;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 18.75rem;
    height: 2rem;
  }
  span {
    color: #40a1ff;
  }
`;
