import styled from 'styled-components';

import {Modal} from '../../../../../../../../../components/common';

export const EditUnitModalContainer = styled(Modal)`
  .unit-type-input-container {
    margin-right: 2.5rem;

    .unit-type-label {
      color: ${({theme}) => theme.colors.text};
      font-size: 0.875rem;
      line-height: 1.05875rem;
    }
  }
`;
