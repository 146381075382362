import styled from 'styled-components';

import {Colors} from '../../../constants';

const SessionsChartContainer = styled.div`
  background-color: ${Colors.Background};

  .close-button {
    background-color: transparent;
    border: none;
    height: 32px;
    right: 48px;
    top: 48px;
    width: 32px;

    img {
      height: 100%;
      object-fit: contain;
      vertical-align: unset;
      width: 100%;
    }
  }

  .scheduled-sessions {
    .title {
      color: ${Colors.Text};
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 2px;
    }

    .subtitle {
      color: ${Colors.LightText};
      font-size: 14px;
      line-height: 1.5;

      .unit-name {
        color: ${Colors.Text};
      }
    }

    .sessions-chart {
      background-color: white;
      border-radius: 12px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.04);

      .chart-header {
        border-bottom: 1px solid ${Colors.Border};
        padding-top: 26px;

        .selected-date {
          color: ${Colors.Text};
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
        }

        .calendar-link {
          color: ${Colors.Primary};
          font-size: 12px;
          line-height: 1.5;

          :hover,
          :active {
            text-decoration: none;
          }

          :focus {
            outline: none;
          }
        }
      }

      .chart-body {
        height: 56vh;
        min-height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }
`;

export default SessionsChartContainer;
