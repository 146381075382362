import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeltonAssessment = () => (
  <div className="d-flex">
    <div>
      <div className="mb-3">
        <Skeleton height={42} width={192} />
      </div>
      <div className="mb-3">
        <Skeleton height={42} width={192} />
      </div>
      <div className="mb-3">
        <Skeleton height={42} width={192} />
      </div>
      <div className="mb-3">
        <Skeleton height={42} width={192} />
      </div>
    </div>
    <div className="ml-4">
      <div className="mb-3">
        <Skeleton height={42} width={625} />
      </div>
      <div className="mb-3">
        <Skeleton height={267} width={625} />
      </div>
      <div className="mb-5">
        <Skeleton height={77} width={625} />
      </div>
      <div className="mb-3">
        <Skeleton height={42} width={625} />
      </div>
      <div className="mb-5">
        <Skeleton height={267} width={625} />
      </div>
      <div className="mb-3">
        <Skeleton height={42} width={625} />
      </div>
      <div className="mb-5">
        <Skeleton height={267} width={625} />
      </div>
      <div className="mb-3">
        <Skeleton height={42} width={625} />
      </div>
      <div className="mb-5">
        <Skeleton height={267} width={625} />
      </div>
    </div>
  </div>
);

export default SkeltonAssessment;
