import styled from 'styled-components';

import {Color} from '../../../../constants';
import bg from '../../../../assets/images/login/background.png';

const TeacherInvitationContainer = styled.main`
  margin: 0 -15px;
  background-image: url(${bg});
  min-height: 100vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 500px) {
    & {
      padding: 0 15px;
    }
  }
  .set-credentials-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 432px;
    @media (max-width: 462px) {
      & {
        width: 100%;
      }
      .set-credentials-description {
        padding: 0 40px !important;
      }
    }
    .logo {
      margin-bottom: 32px;
    }
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .set-credentials-title {
      color: ${Color.PRIMARY_TEXT};
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .set-credentials-description {
      font-family: Inter;
      padding: 0 75px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #8a8c94;
    }
    .button-container {
      margin-top: 30px;
    }
    .button-spinner {
      width: 24px;
      height: 24px;
      animation: rotating 1s linear infinite;
    }
    .set-credentials-form {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default TeacherInvitationContainer;
