/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import {useCallback, useEffect, useRef} from 'react';
import classNames from 'classnames';
// import * as FileSaver from 'file-saver';
import {motion, AnimatePresence} from 'framer-motion';
import moment from 'moment';
import {useClickAway} from 'react-use';

import CloseSidebarIcon from '../../../../../../assets/icons/profile-close.svg';
// import ProfileIcon from '../../../../../../assets/icons/user-profile.png';
import {convertFileSize} from '../../../../../../utils/functions';
import VideoIcon from '../../../../../../assets/icons/videoImg.svg';
import DownloadIcon from '../../../../../../assets/icons/download-file.svg';
import FileImageIcon from '../../../../../../assets/icons/imageIcon.svg';
import FileDocIcon from '../../../../../../assets/icons/docIcon.svg';
import ExpandFileIcon from '../../../../../../assets/icons/viewFile.svg';
import CustomCancel from '../../../../../../components/shared/Buttons/CustomButton';
import {ViewDocumentContainer} from './elements';
import ImageIcon from '../../../../../../assets/images/filetypes/img.svg';
import DocIcon from '../../../../../../assets/images/filetypes/doc.svg';
import CsvIcon from '../../../../../../assets/images/filetypes/csv.svg';
import FileIcon from '../../../../../../assets/icons/document-instruction.svg';
import {handleFileDownload} from '../../../../../../utils/downloadFile';

export const FilePreview = ({isVisible, onClose, previewFileData}) => {
  const renderPreview = useCallback(() => {
    const fileExt = previewFileData?.fi_filename?.substring(
      previewFileData?.fi_filename?.lastIndexOf('.') + 1,
    );
    const renderIcon = ext => {
      if (ext === 'svg') {
        return ImageIcon;
      }
      if (ext === 'csv') {
        return CsvIcon;
      }
      if (ext === 'doc' || ext === 'docx') {
        return DocIcon;
      }
      return FileIcon;
    };
    if (previewFileData?.fileType?.ft_type?.includes('Image')) {
      return (
        <img
          src={previewFileData?.fi_location}
          alt="cloud"
          className="image-file"
        />
      );
    }
    if (previewFileData?.fileType?.ft_type?.includes('Video')) {
      return (
        <div className="videos">
          <video controls src={previewFileData?.fi_location} width="100%">
            <track
              default
              kind="captions"
              srcLang="en"
              src={previewFileData?.fi_location}
            />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      );
    }
    if (previewFileData?.fileType?.ft_type?.includes('Voice')) {
      return (
        <div className="audios" src={previewFileData?.fi_location} width="100%">
          <audio controls>
            <source
              src={previewFileData?.fi_location}
              type="audio/mpeg"
              className="source-audio"
            />
          </audio>
        </div>
      );
    }

    if (previewFileData?.fileType?.ft_type?.includes('Document')) {
      return (
        <div className="file-data">
          <div className="file-header">
            <div> {previewFileData?.fi_filename}</div>
            <button type="button" className="view-file" id="view-file">
              <a
                href={previewFileData?.fi_location}
                className="closeModalBtn"
                download
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={ExpandFileIcon}
                  alt="view file"
                  className="expand-icon"
                />
              </a>
            </button>
          </div>
          {/* <object
            data={previewFileData?.fi_location}
            width="500"
            height="603"
            style={{marginBottom: '-0.4375rem'}}
            type="application/pdf"
          >
            <embed
              src={previewFileData?.fi_location}
              width="500"
              height="603"
              type="application/pdf"
            />
          </object> */}
          {fileExt === 'pdf' ? (
            <object
              data={previewFileData?.fi_location}
              type="application/*"
              className="object-file"
            >
              <iframe
                src={`${previewFileData?.fi_location}#toolbar=0&navpanes=0&scrollbar=0&zoom=0&embedded=true`}
                type="application/*"
                className="iframe-file"
              >
                <p>This browser does not support PDF!</p>
              </iframe>
            </object>
          ) : (
            <img
              src={previewFileData?.fi_thumbnail || renderIcon(fileExt)}
              alt=""
              className="preview-Icon"
            />
          )}
        </div>
      );
    }
    return FileIcon;
  }, [
    previewFileData?.fi_filename,
    previewFileData?.fileType?.ft_type,
    previewFileData?.fi_location,
    previewFileData?.fi_thumbnail,
  ]);

  const renderFileIcon = file => {
    const fileExt = file?.fi_filename?.substring(
      file?.fi_filename?.lastIndexOf('.') + 1,
    );
    if (
      fileExt === 'svg' ||
      fileExt === 'png' ||
      fileExt === 'jpg' ||
      fileExt === 'jpeg'
    ) {
      return FileImageIcon;
    }
    if (fileExt === 'mp4') {
      return VideoIcon;
    }
    if (
      fileExt === 'doc' ||
      fileExt === 'docx' ||
      fileExt === 'pdf' ||
      fileExt === 'csv'
    ) {
      return FileDocIcon;
    }
    return FileIcon;
  };
  const ref = useRef(null);
  useClickAway(ref, () => {
    onClose();
  });
  useEffect(() => {
    const bodyScrollBar = document.body;
    bodyScrollBar.style.overflow = 'hidden';
  }, []);
  return (
    <AnimatePresence>
      {isVisible ? (
        <ViewDocumentContainer
          className="d-flex justify-content-end position-fixed"
          animate={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          exit={{
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          }}
          initial={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
          transition={{
            duration: 0.4,
            type: 'keyframes',
          }}
        >
          <motion.aside
            className="sidebar"
            animate={{
              x: '0',
            }}
            exit={{
              x: '100%',
            }}
            initial={{
              x: '100%',
            }}
            transition={{
              duration: 0.4,
              type: 'keyframes',
            }}
            ref={ref}
          >
            <div className="header-title fixed-top">
              <header className="align-items-center d-flex justify-content-between">
                <div className="sidebar-head d-flex">
                  <div className="sidebar-title">File Preview</div>
                  <div className="class-name">
                    {previewFileData?.orgUnitInstance
                      ? `${previewFileData?.orgUnitInstance?.class?.oc_class} - ${previewFileData?.orgUnitInstance?.orgUnit?.unit?.tun_title}`
                      : null}
                  </div>
                </div>
                <button
                  className="close-sidebar-button"
                  onClick={onClose}
                  type="button"
                  id="close-sidebar-btn"
                >
                  <img alt="Close Sidebar" src={CloseSidebarIcon} />
                </button>
              </header>
            </div>
            <div className="document-view">
              <div
                className={classNames('previewContainer', {
                  'is-video': previewFileData?.fileType?.ft_type === 'Video',
                  'is-image': previewFileData?.fileType?.ft_type === 'Image',
                  'is-document':
                    previewFileData?.fileType?.ft_type === 'Document',
                  'is-audio': previewFileData?.fileType?.ft_type === 'Voice',
                })}
              >
                {renderPreview()}
              </div>
              <div className="file-content">
                <span className="file-name">
                  {previewFileData?.fi_text_content}
                </span>
                <div className="file-data">
                  <span className="file-code">{previewFileData?.fi_code}</span>
                  {/* <span className="class-name">
                  •{' '}
                  {`${previewFileData?.orgUnitInstance?.class?.oc_class} - ${previewFileData?.orgUnitInstance?.orgUnit?.unit?.tun_title}`}{' '}
                </span> */}
                  <span className="uploaded-date">
                    •{' '}
                    {moment(previewFileData?.fi_uploadedate).format(
                      'D MMM YYYY',
                    )}
                  </span>
                </div>
              </div>
              <div className="file-size-profile">
                <div className="file-size-div">
                  <img
                    src={renderFileIcon(previewFileData)}
                    alt="icon"
                    style={{marginRight: '0.5rem'}}
                    className="file-icon"
                  />
                  <span className="add-button-text">
                    {convertFileSize(previewFileData?.fi_filesize)}
                  </span>
                </div>
                <div className="profile-div">
                  <span className="profile-name">
                    {`${previewFileData?.userProfile?.up_name_first} ${previewFileData?.userProfile?.up_name_last}`}
                  </span>
                  {/* <img
                    src={previewFileData?.userProfile?.up_avatar || ProfileIcon}
                    alt="icon"
                    className="profile-image"
                  /> */}
                  <span className="align-items-center circle d-flex justify-content-center profile-creator">
                    {previewFileData?.userProfile?.up_name_first[0]}
                    {previewFileData?.userProfile?.up_name_last[0]}
                  </span>
                </div>
              </div>
            </div>
            <div className="download-file-div fixed-bottom">
              <CustomCancel
                className="download-btn"
                bgColor="#fff"
                padding="0.562rem 0.875rem"
                onClick={() => {
                  handleFileDownload({
                    filePath: `doclib-${previewFileData?.fi_S3_filename}`,
                    fileName: previewFileData?.fi_filename,
                  });
                  // FileSaver.saveAs(
                  //   previewFileData?.fi_location,
                  //   previewFileData?.fi_filename,
                  // );
                }}
                id="download-btn"
              >
                <img src={DownloadIcon} alt="icon" />
                <span className="download-button-text">Download</span>
              </CustomCancel>
            </div>
          </motion.aside>
        </ViewDocumentContainer>
      ) : null}
    </AnimatePresence>
  );
};
