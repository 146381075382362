import styled from 'styled-components';
import {Color, Colors} from '../../../../../../constants';

export const AssessmentViewHeaderContainer = styled.header`
  background-color: #fff;
  border-bottom: 0.0625rem solid #e9e9e9;

  @media (max-width: 43.75rem) {
    .view-assessment-nav {
      /* margin-left: -35px; */
    }
    .header_t-text {
      display: none !important;
    }
    .dropdown-wrapper {
      button {
        width: 100%;
      }
    }
    .header-top {
      padding: 2rem 0.187rem 1rem 1.25rem !important;
      justify-content: space-between;
      .view-assess-top {
        display: flex;
        flex-direction: column !important;
        .header_t-text-view {
          display: block;
          margin-top: 1rem;
          width: auto;
          h1 {
            color: #3d4457;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0.375rem;
          }

          h2 {
            color: #aaa;
            font-size: 1rem;
            font-weight: normal;
            margin-bottom: 0;
          }
        }
      }
      .header_t-text-task {
        margin-top: -0.625rem;
        h2 {
          margin-bottom: 0.375rem;
        }
      }

      .header_t-back-icon {
        margin-left: -0.187rem;
      }
      .closeIcon-task {
        height: 1.5rem;
        width: 1.5rem;
        margin-top: -0.625rem;
      }
    }
    .header-bottom {
      padding-left: 1.25rem !important;
    }
  }
  .header_t-text-view {
    display: none;
  }
  .header_t-text-task {
    flex-grow: 1;

    h1 {
      color: #3d4457;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.375rem;
    }

    h2 {
      color: #aaa;
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 0;
    }
  }
  .header-top {
    align-items: flex-start;
    display: flex;
    padding: ${({isTaskEvaluationPage}) =>
      isTaskEvaluationPage ? '2.5rem 3rem' : '1.5rem 3rem 2rem 3rem'};
    .view-assess-top-head {
      display: none;
    }
    button {
      background-color: #fff;
      border: none;
      /* margin-right: 1rem; */

      :active,
      :focus {
        outline: none;
      }

      .header_t-back-icon {
        height: 2rem;
      }
    }

    .header_t-text {
      flex-grow: 1;

      h1 {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0.375rem;
      }

      h2 {
        color: #aaa;
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: 0;
      }

      span {
        line-height: 1;
      }
    }

    .btn {
      :active,
      :focus {
        box-shadow: none;
      }

      img {
        height: ${({isTaskEvaluationPage}) =>
          isTaskEvaluationPage ? '2.5rem' : '1rem'};
        vertical-align: unset;
      }
    }
  }

  .header-bottom {
    display: flex;
    padding-left: 6rem;

    a {
      border-bottom: 0.125rem solid transparent;
      color: #aaa;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: -0.125rem;
      padding-bottom: 1rem;
      text-decoration: none;
      transition: border-bottom-color 0.02s, color 0.02s;

      &.active {
        border-bottom-color: #40a1ff;
        color: #3d4457;
      }

      :not(:last-child) {
        margin-right: 2.5rem;
        @media (max-width: 43.75rem) {
          & {
            margin-right: 2rem;
          }
        }
      }
    }
  }
`;
export const AssessmentViewContainer = styled.div`
  background-color: #f4f5f8;
  display: flex;
  flex-direction: column;
  ${({fixedHeight}) =>
    `
      ${fixedHeight ? 'height: 100vh' : 'min-height: 100vh'};
    `}
  ${({flexEnd}) => (flexEnd ? 'justify-content: flex-end;' : '')}
  margin: 0 -0.937rem;
  overflow-y: hidden;
  padding-top: ${({paddingTop}) => paddingTop}px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 43.75rem) {
    .footer {
      padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
      .footer-reassign-button {
        display: none;
      }
    }
    .evaluate-assessment-modal-wrapper {
      align-items: center;
      .evaluate-assessment-modal {
        width: 90%;
        padding: 2.5rem 2.5rem 1.5rem 2.5rem !important;
        .assessment-modal-div {
          margin: 0rem -1rem !important;
          .mark-input-response {
            width: 80%;
            word-wrap: wrap auto;
            .mark-input {
              input {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
  .evaluate-assessment-modal-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2000;

    .evaluate-assessment-modal {
      background-color: #fff;
      border-radius: 0.75rem;
      box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
      display: flex;
      flex-direction: column;
      max-width: 25rem;
      padding: 2.5rem;
      width: 100%;

      h5 {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }

      form {
        label {
          margin-bottom: 0;
        }

        label,
        .label {
          display: flex;
          flex-direction: column;

          & > span {
            color: #aaa;
            font-size: 0.875rem;
            font-weight: normal;
            margin-bottom: 0.562rem;
          }

          & > input,
          & > textarea {
            color: #3d4457;
            border: 0.0625rem solid #dedede;
            border-radius: 0.312rem;
            font-size: 0.875rem;
            font-weight: normal;
            line-height: 1.15;
            transition: border-color 0.1s, box-shadow 0.1s;

            ::-webkit-input-placeholder {
              color: #aaa;
            }
            ::-moz-placeholder {
              color: #aaa;
            }
            :-ms-input-placeholder {
              color: #aaa;
            }
            :-moz-placeholder {
              color: #aaa;
            }

            :active,
            :focus {
              border-color: ${Colors.Primary};
              box-shadow: 0 0 0 0.125rem ${Color.LIGHT_BLUE};
              outline: none;
            }
          }

          & > input {
            padding: 0.6875rem 1rem 0.75rem 1rem;
          }

          & > textarea {
            resize: none;
            padding: 0.6875rem 1rem 0.75rem 1rem;
          }

          & > .input {
            align-items: center;
            border: 0.0625rem solid #dedede;
            border-radius: 0.312rem;
            color: #fff;
            display: flex;
            font-size: 1.5rem;
            font-weight: 500;
            height: 100%;
            justify-content: center;
            min-width: 3.75rem;
            width: 100%;
          }

          .mark-input {
            align-items: center;
            border: 0.0625rem solid #dedede;
            border-radius: 0.312rem;
            display: flex;
            padding: 0.6875rem 1rem 0.75rem 1rem;

            input {
              border: none;
              color: #3d4457;
              font-size: 0.875rem;
              font-weight: normal;
              line-height: 1.15;

              :active,
              :focus {
                outline: none;
              }
            }

            span {
              color: #aaa;
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.15;
            }
          }
        }

        .right-row {
          display: flex;
          margin-bottom: 1.5rem;

          label {
            margin-right: 1.5rem;
          }

          .label {
            flex-grow: 1;
          }
        }

        .comments {
          margin-bottom: 1.5rem;
        }

        button {
          background-color: #40a1ff;
          border: none;
          border-radius: 0.5rem;
          color: #fff;
          font-size: 1rem;
          font-weight: 500;
          padding: 0.5rem;
          width: 100%;

          :active,
          :focus {
            outline: none;
          }
        }
      }

      .cancel-modal {
        /* align-self: center; */
        background-color: transparent;
        border: none;
        width: 100%;
        margin-top: 1.5rem;
        span {
          border-bottom: 0.0625rem solid #40a1ff;
          color: #40a1ff;
          font-size: 0.875rem;
          font-weight: 500;
        }

        :active,
        :focus {
          outline: none;
        }
      }
    }
  }

  .footer {
    align-items: center;
    background-color: #fff;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 1.25rem 3rem 1.5rem 3rem;
    position: sticky;
    width: 100%;

    .footer-text {
      color: #3d4457;
      font-size: 0.875rem;
      font-weight: normal;
      margin-bottom: 0;
    }

    .footer-buttons {
      align-items: center;
      display: flex;

      .footer-reassign-button {
        background-color: transparent;
        border: none;
        color: #40a1ff;
        font-size: 1rem;
        font-weight: 500;
        margin-right: 2.5rem;

        :active,
        :focus {
          outline: none;
        }
      }

      .footer-button {
        background-color: #40a1ff;
        border: none;
        border-radius: 0.5rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem 1.5rem !important;

        :active,
        :focus {
          outline: none;
        }
      }
    }
  }
  .more-option {
    &.is-archived {
      display: none;
    }
    &.is-student {
      display: none;
    }
  }
`;
