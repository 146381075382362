import styled from 'styled-components';
// import profileIcon from '../../../../../../assets/icons/profile.svg';
import {Color} from '../../../../../../../../../../../../constants';

export const StudentsProfileContainer = styled.div`
  display: flex;
  padding: 0.5rem 0 1rem 0;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.687rem;
  .title {
    color: ${Color.PRIMARY_TEXT};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .ratio {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.37rem;
    text-decoration-line: underline;
    color: #3d4457;
  }
  .profile-teacher {
    margin-left: -0.375rem;
    border-radius: 1.125rem;
    font-size: 0.75rem;
    font-weight: 500;
    height: 2.25rem;
    line-height: 1.5;

    img {
      width: 2.25rem;
      height: 2.25rem;
      object-fit: contain;
      vertical-align: unset;
    }
  }
  .students {
    .img-student {
      width: 2.25rem;
      height: 2.25rem;
      margin-left: -0.375rem;
    }
    .circle {
      border: 0.0625rem solid #cfe7ff;
      border-radius: 1.125rem;
      font-size: 0.75rem;
      font-weight: 500;
      height: 2.25rem;
      line-height: 1.5;
      background-color: ${Color.PRIMARY};
      margin-left: -0.375rem;
      color: white;
      width: 2.25rem;
    }
    .no-students {
      font-weight: 600;
      font-size: 0.875rem;
      color: #3d4457;
    }
    .student {
      background-color: ${Color.PRIMARY};
      color: white;
    }

    .remaining {
      background-color: white;
      color: ${Color.PRIMARY};
    }
  }
`;
