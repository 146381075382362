/* eslint-disable react/prop-types */
import React from 'react';
import Loader from '../../../../../../../../components/shared/BookLoader';
import {LoaderContainer} from './elements';

export const ValidateLoader = ({title, subtitle}) => (
  <LoaderContainer>
    <Loader />
    <h6 className="mt-3">{title}</h6>
    <p className="please">{subtitle}</p>
  </LoaderContainer>
);
