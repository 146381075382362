import PropTypes from 'prop-types';
import moment from 'moment';
import {useMemo} from 'react';
import styled from 'styled-components';

const MidpointIndicator = styled.div`
  height: 1.5625rem;
  width: 1.75rem;

  .label-seperator {
    background-color: #e0e0e0;
    height: 100%;
    width: 0.0625rem;
  }
`;

const TimeIntervalContainer = styled.div`
  width: 2rem;

  .time {
    color: #c0c0c0;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.8;
    margin-bottom: 0.4375rem;
  }

  .time-indicator {
    background-color: #e0e0e0;
    width: 0.0625rem;
  }
`;

const TimeInterval = ({time24, withSeperator}) => {
  // Convert the time from 24 hour to 12 hour format.
  const time12 = useMemo(() => moment(time24, 'H').format('h A'), [time24]);
  return (
    <>
      <TimeIntervalContainer className="align-items-center d-flex flex-column flex-shrink-0 justify-content-center">
        <span className="time">{time12}</span>
        <span className="flex-grow-1 time-indicator" />
      </TimeIntervalContainer>
      {/* Indicator for 30 minutes after every hour except the last. */}
      {withSeperator && (
        <MidpointIndicator className="align-self-end d-flex flex-shrink-0 justify-content-center">
          <div className="label-seperator" />
        </MidpointIndicator>
      )}
    </>
  );
};

TimeInterval.propTypes = {
  time24: PropTypes.number.isRequired,
  withSeperator: PropTypes.bool.isRequired,
};

export default TimeInterval;
