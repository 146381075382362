/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  MoreOptions,
  Modal,
} from '../../../../../../../../../../components/common';
import {showToast} from '../../../../../../../../../../components/common/Toast';
import {DropdownItem} from '../../../../../../../../../../components/common/MoreOptions/components';

import {FileDetail} from './components';
import {ModuleContainer} from './elements';

import ArrowHideImg from '../../../../../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../../../../../assets/icons/tableshow.svg';
import ModuleDownloadIcon from '../../../../../../../../../../assets/icons/module-download-icon.svg';
import ModuleOptionIcon from '../../../../../../../../../../assets/icons/module-option-icon.svg';

import {
  deleteCurriculumModuleById,
  curriculumSelector,
} from '../../../../../../../../../../redux/curriculumSlice';
import {handleFileDownloads} from '../../../../../../../../../../utils/downloadFile';

const DELAY = 500;

export const Module = ({
  item,
  courseName,
  position,
  moduleId,
  courseId,
  resetOffset,
}) => {
  const [show, setShow] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const {isAddCurriculumLoading} = useSelector(curriculumSelector);
  const dispatch = useDispatch();
  return (
    <ModuleContainer>
      <div className="title-container">
        <div className="title-data">
          <h5 className="title">
            {position}&ensp;
            {`${courseName} | ${item?.ocrm_module_name}`}
          </h5>
        </div>
        <div className="seperator" />
        <div className="d-flex align-items-center justify-content-end btn-container">
          {' '}
          <button
            type="button"
            className="button-download d-flex align-items-center  ml-4 mr-3"
            id="module-download"
            title="download zip"
            onClick={() => {
              handleFileDownloads(
                item?.ocrm_id_course_curriculum_module,
                item?.ocrm_module_name,
              );
            }}
          >
            <img
              className="mb-0 downloadicon"
              alt="arrow"
              src={ModuleDownloadIcon}
            />
          </button>
          <MoreOptions
            OptionIcon={ModuleOptionIcon}
            iconClassName="icon-module"
            id="more-options-btn"
          >
            {/* <DropdownItem label="Edit Module" onClick={() => {}} /> */}
            <DropdownItem
              isDanger
              label="Delete Module"
              onClick={() => {
                setDeleteId(item?.ocrm_id_course_curriculum_module);
                setDeleteName(item?.ocrm_module_name);
              }}
              id="delete-module"
            />
          </MoreOptions>
          {item?.OrgCourseCurriculumFiles.length ? (
            <button
              type="button"
              className="button-download d-flex align-items-center ml-4"
              onClick={() => {
                setShow(state => !state);
              }}
              id="arrow"
            >
              <img
                className="mb-0 mt-1 arrowicon"
                alt="arrow"
                src={show ? ArrowShowImg : ArrowHideImg}
              />
            </button>
          ) : null}
        </div>
      </div>
      <FileDetail
        show={show}
        moduleId={moduleId}
        files={item?.OrgCourseCurriculumFiles}
        position={position}
        courseId={courseId}
      />
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this module?"
        isDelete
        isButtonLoading={isAddCurriculumLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          if (!isAddCurriculumLoading) {
            dispatch(
              deleteCurriculumModuleById(courseId, deleteId, () => {
                setDeleteId(null);
                showToast(false, `${deleteName} has been deleted.`, true);
                resetOffset();
                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }),
            );
          }
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
    </ModuleContainer>
  );
};
