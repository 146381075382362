/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FilesInstructionIcon from '../../../../../../../../../../assets/icons/files-instruction.svg';
import InstructionsTipIcon from '../../../../../../../../../../assets/icons/instructions-tip.svg';
import TextInstructionIcon from '../../../../../../../../../../assets/icons/text-instruction.svg';
import VideoInstructionIcon from '../../../../../../../../../../assets/icons/video-instruction.svg';
import VoiceInstructionIcon from '../../../../../../../../../../assets/icons/voice-instruction.svg';
import {
  activeClassSelector,
  addFileInstruction,
  addTextInstruction,
  removeInstruction,
  updateInstruction,
} from '../../../../../../../../../../redux/activeClassSlice';

import {
  TextModal,
  DisplayModal,
  Instruction,
  InstructionType,
  AudioModal,
  VideoModal,
} from './components';
import {AddedConstructionContainer} from './elements';

const getInstruction = (file, item) => {
  switch (file.fi_id_filetype) {
    case 1:
      return {
        duration: file.fi_filesize,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'image',
        uploaded: true,
      };
    case 2:
      return {
        duration: file.fi_duration,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'video',
        uploaded: true,
      };
    case 3:
      return {
        linkID: item.fl_id_filelink,
        text: file.fi_text_content,
        type: 'text',
        uploaded: true,
      };
    case 5:
      return {
        duration: file.fi_filesize,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'document',
        uploaded: true,
      };
    case 7:
      return {
        duration: file.fi_duration,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'audio',
        uploaded: true,
      };
    default:
      return null;
  }
};

export const AddedInstructions = ({
  // register,
  setValue,
  taskToUpdate,
  setInstructions,
  fileInput,
  instructions,
  onUpload,
}) => {
  const dispatch = useDispatch();

  const [textInstruction, setTextInstruction] = useState('');
  const [textEditID, setTextEditID] = useState(null);
  const [textModalVisibility, setTextModalVisibility] = useState(false);
  const [audioEditData, setAudioEditData] = useState(null);
  const [audioModalVisibility, setAudioModalVisibility] = useState(false);
  const [audioShowData, setAudioShowData] = useState(false);
  const [displayModalVisibility, setDisplayModalVisibility] = useState(false);
  const [displayShowData, setDisplayShowData] = useState(null);
  const [draggedInside, setDraggedInside] = useState(false);
  const [videoEditData, setVideoEditData] = useState(null);
  const [videoModalVisibility, setVideoModalVisibility] = useState(false);
  const [videoShowData, setVideoShowData] = useState(null);
  const {activityId, draftTask} = useSelector(activeClassSelector);

  const updateLocalInstruction = useCallback(
    (id, data) => {
      const file = data.fi_id_filetype ? data : data.file;

      setInstructions(previousValue =>
        previousValue.map(item =>
          item.linkID === id ? getInstruction(file, data) : item,
        ),
      );
    },
    [setInstructions],
  );

  const activeTask = useMemo(
    () =>
      taskToUpdate
        ? {
            ai_id_activityitem: taskToUpdate.id,
            linkedFiles: taskToUpdate.taskFiles,
          }
        : draftTask,
    [draftTask, taskToUpdate],
  );

  useEffect(() => {
    if (taskToUpdate) {
      setValue('task-description', taskToUpdate.description);
      setValue('task-name', taskToUpdate.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskToUpdate]);

  useEffect(() => {
    if (activeTask && Array.isArray(activeTask?.linkedFiles)) {
      setInstructions(previousValue =>
        previousValue.length && activeTask.linkedFiles.length
          ? previousValue
          : activeTask.linkedFiles.map(item => {
              const file = item.fi_id_filetype ? item : item.file;
              return getInstruction(file, item);
            }),
      );
    }
  }, [activeTask, setInstructions]);

  return (
    <AddedConstructionContainer id="tasks">
      <TextModal
        isEdit={textEditID}
        onClose={() => {
          setTextEditID(null);
          setTextModalVisibility(false);
          setTextInstruction('');
        }}
        onSubmit={() => {
          const id = Math.floor(Math.random() * 1000) + 1000;

          if (textInstruction.length) {
            if (textEditID)
              dispatch(
                updateInstruction(
                  textEditID,
                  {
                    textContent: textInstruction,
                  },
                  activeTask?.ai_id_activityitem,
                  updateLocalInstruction,
                  taskToUpdate?.id,
                ),
              );
            else {
              dispatch(
                addTextInstruction(
                  activityId,
                  activeTask?.ai_id_activityitem,
                  textInstruction,
                  linkID => {
                    setInstructions(previousValue =>
                      previousValue.map(item => {
                        if (item.id !== id) return item;

                        return {
                          ...item,
                          linkID,
                          uploaded: true,
                        };
                      }),
                    );
                  },
                  taskToUpdate?.id,
                ),
              );

              setInstructions(previousValue => [
                ...previousValue,
                {
                  id,
                  text: textInstruction,
                  type: 'text',
                  uploaded: false,
                },
              ]);
              setTextModalVisibility(false);
            }
          }

          setTextInstruction('');
          setTextModalVisibility(false);
        }}
        setTextInstruction={setTextInstruction}
        textInstruction={textInstruction}
        visibility={textModalVisibility}
      />

      {displayModalVisibility ? (
        <DisplayModal
          onClose={() => {
            setDisplayModalVisibility(false);
            setDisplayShowData(null);
          }}
          showData={displayShowData}
        />
      ) : null}
      {videoModalVisibility ? (
        <VideoModal
          editData={videoEditData}
          onClose={() => {
            setVideoEditData(null);
            setVideoModalVisibility(false);
            setVideoShowData(null);
          }}
          onSubmit={body => {
            const id = Math.floor(Math.random() * 1000) + 1000;

            dispatch(
              addFileInstruction(
                activityId,
                activeTask.ai_id_activityitem,
                body,
                linkID => {
                  setInstructions(previousValue =>
                    previousValue.map(item => {
                      if (item.id !== id) return item;

                      return {
                        ...item,
                        linkID,
                        uploaded: true,
                      };
                    }),
                  );
                },
                taskToUpdate?.id,
              ),
            );

            setInstructions(previousValue => [
              ...previousValue,
              {
                duration: body.get('duration'),
                id,
                media: body.get('file'),
                text: body.get('title'),
                type: 'video',
                uploaded: false,
              },
            ]);
          }}
          onUpdate={(fileID, body) => {
            dispatch(
              updateInstruction(
                fileID,
                body,
                activeTask.ai_id_activityitem,
                updateLocalInstruction,
                taskToUpdate?.id,
              ),
            );
            setVideoEditData(null);
          }}
          showData={videoShowData}
        />
      ) : null}
      {audioModalVisibility ? (
        <AudioModal
          editData={audioEditData}
          onClose={() => {
            setAudioEditData(null);
            setAudioModalVisibility(false);
            setAudioShowData(false);
          }}
          onSubmit={body => {
            const id = Math.floor(Math.random() * 1000) + 1000;

            dispatch(
              addFileInstruction(
                activityId,
                activeTask.ai_id_activityitem,
                body,
                linkID => {
                  setInstructions(previousValue =>
                    previousValue.map(item => {
                      if (item.id !== id) return item;

                      return {
                        ...item,
                        linkID,
                        uploaded: true,
                      };
                    }),
                  );
                },
                taskToUpdate?.id,
              ),
            );

            setInstructions(previousValue => [
              ...previousValue,
              {
                duration: body.get('duration'),
                id,
                media: body.get('file'),
                text: body.get('title'),
                type: 'audio',
                uploaded: false,
              },
            ]);
          }}
          onUpdate={(fileID, body) => {
            dispatch(
              updateInstruction(
                fileID,
                body,
                activeTask.ai_id_activityitem,
                updateLocalInstruction,
                taskToUpdate?.id,
              ),
            );
            setAudioEditData(null);
          }}
          showData={audioShowData}
        />
      ) : null}
      <div className="assessment-task-container">
        {instructions.length ? (
          <div className="added-instructions-section mb-3">
            <h3 className="mb-3">Added Instructions</h3>
            {instructions.map((instruction, index) => (
              <Instruction
                data={instruction}
                sno={index}
                key={instruction?.id}
                onDelete={() =>
                  dispatch(
                    removeInstruction(
                      activeTask.ai_id_activityitem,
                      instruction.linkID,
                      taskToUpdate?.id,
                      () => {
                        setInstructions(previousValue =>
                          previousValue.filter(
                            item => item.linkID !== instruction.linkID,
                          ),
                        );
                      },
                    ),
                  )
                }
                onEditClick={() => {
                  if (instruction?.type === 'audio') {
                    setAudioEditData(instruction);
                    setAudioModalVisibility(true);
                  } else if (instruction?.type === 'text') {
                    setTextEditID(instruction.linkID);
                    setTextInstruction(instruction.text);
                    setTextModalVisibility(true);
                  } else if (instruction?.type === 'video') {
                    setVideoEditData(instruction);
                    setVideoModalVisibility(true);
                  }
                }}
                onShowClick={() => {
                  if (instruction?.type === 'audio') {
                    setAudioShowData(instruction);
                    setAudioModalVisibility(true);
                  } else if (
                    instruction?.type === 'document' ||
                    instruction?.type === 'image'
                  ) {
                    setDisplayShowData(instruction);
                    setDisplayModalVisibility(true);
                  } else if (instruction?.type === 'video') {
                    setVideoShowData(instruction);
                    setVideoModalVisibility(true);
                  }
                }}
              />
            ))}
          </div>
        ) : null}
        <div
          className={`add-instructions-section mb-4  p-4 ${
            draggedInside ? 'dragged-inside' : ''
          }`}
          onDragLeave={() => setDraggedInside(false)}
          onDragOver={event => {
            event.stopPropagation();
            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';
            setDraggedInside(true);
          }}
          onDrop={event => {
            event.stopPropagation();
            event.preventDefault();
            setDraggedInside(false);
            let files = [];

            if (event.dataTransfer.items) {
              for (
                let index = 0;
                index < event.dataTransfer.items.length;
                index += 1
              ) {
                if (event.dataTransfer.items[index].kind === 'file') {
                  const file = event.dataTransfer.items[index].getAsFile();
                  files.push(file);
                }
              }
            } else {
              files = event.dataTransfer.files;
            }

            if (files.length) {
              onUpload(files);
            }
          }}
        >
          {!instructions.length ? (
            <h3 className="mb-3 add-instruction-title">Add Instructions</h3>
          ) : null}
          <div className="instruction-types">
            <InstructionType
              icon={TextInstructionIcon}
              onClick={() => setTextModalVisibility(true)}
              title="Text"
            />
            <InstructionType
              icon={VideoInstructionIcon}
              onClick={() => setVideoModalVisibility(true)}
              title="Video"
            />
            <InstructionType
              icon={VoiceInstructionIcon}
              onClick={() => setAudioModalVisibility(true)}
              title="Voice"
            />
            <InstructionType
              icon={FilesInstructionIcon}
              onClick={() => fileInput.current.click()}
              title="Files"
            />
          </div>
          <div className="instruction-section-seperator mb-3 mt-4" />
          <div className="instructions-tip">
            <img alt="Tip" src={InstructionsTipIcon} />
            <span>
              Drag and drop supported files (pdf, jpg, png, mp4) here to attach
              them.
            </span>
          </div>
        </div>
      </div>
    </AddedConstructionContainer>
  );
};
