/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import EvaluationIcon from '../../../../../../../../../assets/icons/evaluation-icon.svg';
import {EvaluationContainer} from './elements';

export const EvaluationSection = ({assessmentSubmission}) => {
  const evaluated = useMemo(
    () =>
      assessmentSubmission?.students.filter(data => data.aouis_status === 3),

    [assessmentSubmission],
  );
  const submitted = useMemo(
    () =>
      assessmentSubmission?.students.filter(data => data.aouis_status === 2),
    [assessmentSubmission],
  );
  return (
    <EvaluationContainer>
      <div className="header-section">
        <div className="image">
          <img src={EvaluationIcon} alt="general" className="evaluation-icon" />
        </div>
        <div>
          <p className="header">Evaluation</p>
        </div>
      </div>
      <div className="seperator" />
      <div className="content-section">
        <div className="first-div">
          <div className="first-content">
            <div className="title-div">
              <span>1</span>
              <p className="title">No of Submission Evaluated </p>
            </div>
            <p className="content">{evaluated?.length}</p>
          </div>
        </div>
        <div className="first-div">
          <div className="first-content">
            <div className="title-div">
              <span>2</span>
              <p className="title">Pending Submission to be evaluated </p>
            </div>
            <p className="content">{submitted?.length}</p>
          </div>
        </div>
      </div>
    </EvaluationContainer>
  );
};
