import styled from 'styled-components';

export const FileCardContainer = styled.div`
  display: flex;
  align-items: center;
  .file-name {
    margin-bottom: unset;
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({theme}) => theme.colors.text};
    margin-left: 0.75rem;
    margin-top: 0.5rem;
    word-break: break-all;
  }
  .file-type {
    margin-bottom: unset;
    font-weight: 500;
    font-size: 0.75rem;
    color: #aeaeae;
    margin-left: 0.75rem;
    text-transform: capitalize;
  }
  .file-blob {
    border-radius: 0.3125rem;
    display: block;
    height: 3rem;
    width: 3rem;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    /* width: 100%; */
    /* height: 100%; */

    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-position: center;
    }
    .play-video {
      width: 3rem;
      height: 3rem;
    }
  }
`;
