/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import InstructionsIcon from '../../../../../../../../../assets/icons/instructions.svg';
import InstructionsActiveIcon from '../../../../../../../../../assets/icons/instruction-active.svg';
import ArrowHideImg from '../../../../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../../../../assets/icons/tableshow.svg';

import {
  DisplayModal,
  Instruction,
} from '../../../../../Components/AddTask/Instruction';
import AudioModal from '../../../../../Components/AudioModal';
import VideoModal from '../../../../../Components/VideoModal';
import {MappedTask} from '../MappedTask';
import {AssessmentTaskContainer} from './elements';
import {authSelector} from '../../../../../../../../../redux/authSlice';

export const AssessmentTask = ({task}) => {
  const [instructions, setInstructions] = useState([]);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [audioModalVisibility, setAudioModalVisibility] = useState(false);
  const [audioShowData, setAudioShowData] = useState(null);
  const [displayModalVisibility, setDisplayModalVisibility] = useState(false);
  const [displayShowData, setDisplayShowData] = useState(null);
  const [videoModalVisibility, setVideoModalVisibility] = useState(false);
  const [videoShowData, setVideoShowData] = useState(null);
  const {orgPreferences} = useSelector(authSelector);

  // Check org preference for assessment map is enable
  const assessmentMapPreference = orgPreferences?.find(
    item =>
      item.pr_slug === 'show_assessment_map' && Number(item.pr_value) === 1,
  );

  const toggleInstructions = () => setInstructionsOpen(!instructionsOpen);

  useEffect(() => {
    const files = task?.linkedFiles;

    if (files && files?.length) {
      setInstructions(
        files.map(item => {
          const file = item.fi_id_filetype ? item : item.file;

          switch (file.fi_id_filetype) {
            case 1:
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'image',
              };
            case 2:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                thumbnail: file.fi_thumbnail,
                type: 'video',
              };
            case 3:
              return {
                id: file.fl_id_filelink,
                text: file.fi_text_content,
                type: 'text',
              };
            case 5: {
              const split = file.fi_location.split('.');
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'document',
                pdf: split[split.length - 1] === 'pdf',
              };
            }
            case 7:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'audio',
              };
            default:
              return null;
          }
        }),
      );
    }
  }, [task.linkedFiles]);

  return (
    <AssessmentTaskContainer>
      {displayModalVisibility ? (
        <DisplayModal
          onClose={() => {
            setDisplayModalVisibility(false);
            setDisplayShowData(null);
          }}
          showData={displayShowData}
        />
      ) : null}
      {videoModalVisibility ? (
        <VideoModal
          onClose={() => {
            setVideoModalVisibility(false);
            setVideoShowData(null);
          }}
          showData={videoShowData}
        />
      ) : null}
      {audioModalVisibility ? (
        <AudioModal
          onClose={() => {
            setAudioModalVisibility(false);
            setAudioShowData(null);
          }}
          showData={audioShowData}
        />
      ) : null}
      <div className="assessment-task-details">
        <div className="assessment-task-meta">
          <div className="badge-container">
            <h4 className="assessment-task-title">{task?.ai_activityitem}</h4>
            <span className="evaluation-type">{task?.et_eval_name}</span>
          </div>
          <p>{task?.ai_activityitem_description}</p>
        </div>
      </div>
      {assessmentMapPreference && task?.tpc_criteria_value ? (
        <MappedTask task={task} />
      ) : null}
      {instructions?.length ? (
        <div className="added-task-instructions">
          <div className="accordion" id="accordionExample">
            <div className="card">
              <div
                className={classNames('card-header', {
                  clicked: instructionsOpen,
                })}
                id="headingOne"
              >
                <h2 className="mb-0">
                  <button
                    className="btn btn-block d-flex justify-content-between align-items-center"
                    type="button"
                    onClick={toggleInstructions}
                  >
                    <div className="accordion-header">
                      <img
                        alt="instructions"
                        className="resource"
                        src={
                          instructionsOpen
                            ? InstructionsActiveIcon
                            : InstructionsIcon
                        }
                      />
                      {instructions.length} Instructions added
                    </div>
                    <div>
                      <img
                        alt="Show Works"
                        className="arrow"
                        src={instructionsOpen ? ArrowShowImg : ArrowHideImg}
                      />
                    </div>
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                className={classNames('collapse', {
                  show: instructionsOpen,
                })}
              >
                <div className="card-body">
                  {instructions.map((instruction, index) => (
                    <div className="instruction-container">
                      <div className="index-container">{index + 1}</div>
                      <div className="data-container">
                        <Instruction
                          data={instruction}
                          key={instruction?.text}
                          onShowClick={() => {
                            if (instruction?.type === 'audio') {
                              setAudioShowData(instruction);
                              setAudioModalVisibility(true);
                            } else if (
                              (instruction?.type === 'document' &&
                                instruction?.pdf) ||
                              instruction?.type === 'image'
                            ) {
                              setDisplayShowData(instruction);
                              setDisplayModalVisibility(true);
                            } else if (instruction?.type === 'video') {
                              setVideoShowData(instruction);
                              setVideoModalVisibility(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </AssessmentTaskContainer>
  );
};
