import styled from 'styled-components';
import {Color} from '../../../../../../../../../constants';

export const ResubmitAssessmentModalContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2000;

  .resubmit-assessment-modal-wrapper {
    align-items: center;
    background-color: #fff;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    padding: 4.56rem 1.5rem 1.5rem 1.5rem;
    width: 95%;
    /* @media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
      & {
        margin-top: 85% !important;
      }
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 400px) {
      & {
        margin-top: 50% !important;
        margin-bottom: 20% !important;
      }
    }

    @media (min-width: 960px) {
      & {
        max-width: 50%;
      }
    }
    @media (max-width: 360px) {
      & {
        margin-top: 170px;
      }
    } */
    @media (min-width: 60rem) {
      & {
        max-width: 50%;
      }
    }

    .graphics {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;

      img:not(:nth-child(2)) {
        height: 6rem;
        width: 6rem;
      }

      img:nth-child(2) {
        height: 1.06rem;
        margin: 0 2.25rem;
        width: 2.156rem;
      }
    }

    h2 {
      color: #3d4457;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.583334;
      margin-bottom: 0;
    }

    .subtitle {
      color: #aaa;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 1rem;
    }

    .resubmission-details {
      -ms-overflow-style: none;
      background-color: #fffbee;
      border: 0.0625rem solid #ffcf31;
      border-radius: 0.312rem;
      max-height: 30vh;
      overflow-y: auto;
      padding: 1.06rem 1rem 1rem 1rem;
      scrollbar-width: none;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      .resubmission-profile {
        align-items: center;
        display: flex;
        margin-bottom: 0.562rem;

        img {
          height: 2rem;
          margin-right: 0.5rem;
          width: 2rem;
        }

        .resubmission-profile-text {
          display: flex;
          flex-direction: column;

          .resubmission-profile-name {
            color: ${Color.PRIMARY_TEXT};
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.21;
            margin-bottom: 0.125rem;
          }

          .resubmission-profile-time {
            color: ${Color.LIGHT_TEXT};
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.21;
          }
        }
      }

      .resubmission-note {
        color: ${Color.PRIMARY_TEXT};
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0;
        padding-left: 2.5rem;
      }
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 1.5rem;
      width: 100%;

      button {
        :active,
        :focus {
          outline: none;
        }
      }

      .cancel-button {
        background-color: transparent;
        border: none;
        color: #eb5757;
        font-size: 0.875rem;
        font-weight: 500;
        margin-right: 1.5rem;
      }

      .send-button {
        background-color: #40a1ff;
        border: none;
        border-radius: 0.5rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem 1.5rem;
      }
    }
  }
  .feed-back {
    width: 100% !important;
  }
`;
