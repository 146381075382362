import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {acceptInvitation, findStudentByToken} from '../../api/classes';
import CustomBox from '../../components/shared/CustomBox';
import CustomButton from '../../components/shared/CustomButton';
import CustomNavbar from '../../components/shared/CustomNavbar';
import CustomTextInput from '../../components/shared/CustomTextInput';
import useNavbarScroll from '../../hooks/useNavbarScroll';
import StudentInvitationContainer from './StudentInvitationContainer';

const StudentInvitation = () => {
  const [data, setData] = useState({
    confirmPassword: {
      error: '',
      value: '',
    },
    password: {
      error: '',
      value: '',
    },
    username: {
      error: '',
      value: '',
    },
  });
  const [student, setStudent] = useState(null);
  const [token] = useState(window.location.href.split('?token=')[1]);
  const history = useHistory();
  const {handleScroll, navbarTop} = useNavbarScroll(16, 81);

  useEffect(() => {
    findStudentByToken(token).then(response =>
      setStudent(response.data.userProfile),
    );
  }, [token]);

  const handleChange = event =>
    setData(state => ({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
      },
    }));

  const handleSubmission = event => {
    event.preventDefault();
    acceptInvitation({
      confirmPassword: data.confirmPassword.value,
      password: data.password.value,
      token,
      username: data.username.value,
    })
      .then(() => history.replace('/invite-accepted'))
      .catch(({response}) => {
        if (response?.data) {
          setData(state => {
            const cleanState = {...state};

            Object.keys(cleanState).forEach(key => {
              cleanState[key].error = '';
            });

            return {
              ...cleanState,
              [response.data.field]: {
                error: response.data.error,
                value: '',
              },
            };
          });
        }
      });
  };

  if (!student) return null;

  return (
    <StudentInvitationContainer onScroll={handleScroll}>
      <CustomNavbar top={navbarTop} />
      <div className="set-credentials-container">
        <h2 className="set-credentials-title">
          Hello {student.up_name_first} {student.up_name_last},
        </h2>
        <p className="set-credentials-description">
          Please set a password to accept the invitation from{' '}
          {student.org.or_orgname} and to secure your JungleCat account.
        </p>
        <CustomBox padding="24px 24px 32px 24px">
          <form className="set-credentials-form" onSubmit={handleSubmission}>
            <CustomTextInput
              autoComplete="off"
              error={data.username.error}
              label="Username"
              marginBottom="24px"
              name="username"
              onChange={handleChange}
              placeholder="Enter your username"
              value={data.username.value}
            />
            <CustomTextInput
              autoComplete="new-password"
              error={data.password.error}
              label="Password"
              marginBottom="24px"
              name="password"
              onChange={handleChange}
              placeholder="Enter a password"
              type="password"
              value={data.password.value}
            />
            <CustomTextInput
              autoComplete="new-password"
              error={data.confirmPassword.error}
              label="Confirm Password"
              marginBottom="24px"
              name="confirmPassword"
              onChange={handleChange}
              placeholder="Confirm the password"
              type="password"
              value={data.confirmPassword.value}
            />
            <CustomButton
              className="set-credentials-submit-button"
              text="Get Started"
              type="submit"
            />
          </form>
        </CustomBox>
      </div>
    </StudentInvitationContainer>
  );
};

export default StudentInvitation;
