import styled from 'styled-components';
import {motion} from 'framer-motion';

export const ViewDocumentContainer = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 2000;

  .sidebar-head {
    display: flex;
    flex-direction: column;
  }
  .document-view {
    padding: 7.0625rem 2rem 7.8125rem 2rem;
    overflow-y: auto;
    height: 100%;
  }

  .sidebar {
    background-color: white;
    height: 100%;
    max-width: 41.375rem;
    overflow-y: auto;
    /* padding: 2.625rem 2.5rem; */
    width: 100%;

    .sidebar-title {
      color: ${({theme}) => theme.colors.text};
      font-weight: 500;
      font-size: 1.5rem;
    }
    .class-name {
      font-weight: 500;
      font-size: 1rem;
      color: #aaaaaa;
    }

    .close-sidebar-button {
      background-color: transparent;
      border: none;
      height: 2rem;
      width: 2rem;

      :active,
      :focus {
        outline: none;
      }

      img {
        height: 100%;
        object-fit: contain;
        vertical-align: unset;
        width: 100%;
      }
    }
  }

  .download-btn {
    display: flex;
    color: ${({theme}) => theme.colors.primary};
    border: 0.0625rem solid ${({theme}) => theme.colors.primary};
    border-radius: 0.5rem;
  }
  .download-file-div {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
    background-color: #ffffff;
  }
  .file-size-profile {
    border-top: 0.0625rem solid #dedede;
    padding: 1.5rem 0 3rem 0;
    display: flex;
    justify-content: space-between;
  }
  .file-content {
    display: flex;
    flex-direction: column;
    padding-top: 1.2rem;
    padding-bottom: 1.5rem;
    .file-name {
      font-weight: 500;
      font-size: 1.25rem;
      color: ${({theme}) => theme.colors.text};
    }
    .uploaded-date,
    .file-code {
      font-size: 1rem;
      color: #aaaaaa;
      margin-right: 0.4rem;
    }
    .file-data {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
    }
    .class-name {
      margin-right: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 69%;
    }
  }
  .file-show {
    padding: 1.8125rem 0 1rem 0;
    img,
    video {
      max-width: 28.625rem;
      max-height: 18.75rem;
      object-position: center;
    }
  }
  .profile-name {
    margin-right: 0.5rem;
    font-size: 0.875rem;
  }
  .file-name {
    font-size: 0.875rem;
  }
  .file-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  /* .profile-image {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  } */

  .profile-div {
    display: flex;
    align-items: center;
    .profile-creator {
      background-color: ${({theme}) => theme.colors.primary};
      color: white;
    }
    .circle {
      border: 0.125rem solid white;
      border-radius: 1.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      height: 2.5rem;
      margin-right: 0.65rem;
      width: 2.5rem;
      text-transform: uppercase;
    }
  }
  .file-size-div {
    display: flex;
    align-items: center;
  }
  .previewContainer {
    width: 100%;
    margin-top: 5rem;
    &.is-video {
      .file-data {
        height: 100%;
      }
      embed {
        width: 100%;
        height: 100%;
      }
    }
    &.is-image {
      width: 100%;
      .image-file {
        width: 100%;
        height: 100%;

        object-fit: contain;
      }
    }
    &.is-document {
      width: 100%;

      .file-data {
        display: flex;
        flex-direction: column;
        background: #eeeeee;
        border: 0.125rem solid ${({theme}) => theme.colors.border};
        box-sizing: border-box;
        border-radius: 0.25rem;
        height: auto;
        .file-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: #2196f3;
          box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12);
          border-radius: 0.25rem 0.25rem 0px 0px;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 1rem;
          color: #ffffff;
        }
        .view-file {
          background: transparent;
          outline: none;
          border: none;
        }
        .expand-icon {
          vertical-align: middle;
        }
        .object-file {
          width: 100%;
          height: 31.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .iframe-file {
          height: 100%;
          width: 25rem;
          overflow: hidden;
        }
        .preview-Icon {
          height: 100%;
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
    &.is-audio {
      .audios {
        display: flex;
        align-items: center;
        height: 100%;
      }
      audio {
        width: 100% !important;
      }
      .source-audio {
        width: 100% !important;
      }
    }
  }
  .download-button-text {
    margin-left: 0.4rem;
  }
`;
