/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import RecordRTC from 'recordrtc';
import ReactBootstrapModal from 'react-bootstrap/Modal';

import CloseIcon from '../../../../../../../../../../../../../../../assets/icons/instruction-modal-close.svg';
import PauseRecordingIcon from '../../../../../../../../../../../../../../../assets/icons/pause-recording.svg';
import RecordAudioIcon from '../../../../../../../../../../../../../../../assets/icons/record-audio.svg';
import ResumeRecordingIcon from '../../../../../../../../../../../../../../../assets/icons/resume-recording.svg';
import RetakeRecordedAudioIcon from '../../../../../../../../../../../../../../../assets/icons/retake-recorded-audio.svg';
import RetakeRecordingIcon from '../../../../../../../../../../../../../../../assets/icons/retake-audio.svg';
import StopRecordingIcon from '../../../../../../../../../../../../../../../assets/icons/stop-audio.svg';
import CustomBox from '../../../../../../../../../../../../../../../components/shared/CustomBox';
import CustomButton from '../../../../../../../../../../../../../../../components/shared/CustomButton';
import CustomTextInput from '../../../../../../../../../../../../../../../components/shared/CustomTextInput';
import {AudioModalContainer} from './elements';
import {calculateTimeDuration} from '../../../../../../../../../../../../../../../utils/functions';

const isEdge =
  navigator.userAgent.indexOf('Edge') !== -1 &&
  (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const AudioModal = ({
  editData,
  onClose,
  onSubmit,
  onUpdate,
  showData,
}) => {
  const recorder = useRef();
  const audioElement = useRef();
  const [audioTitle, setAudioTitle] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [isRecordingFinished, setIsRecordingFinished] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRetakeVisible, setIsRetakeVisible] = useState(null);
  const [recorderAudio, setAudio] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [stream, setStream] = useState(null);
  const [timer, setTimer] = useState(null);

  const updateCurrentTime = useCallback(
    () => setCurrentTime(audioElement?.current?.currentTime || 0),
    [],
  );

  const pauseRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.pauseRecording();
      setIsRecordingPaused(true);
    }
  }, [recorder]);

  const recordAudio = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: isEdge
          ? true
          : {
              echoCancellation: false,
            },
      })
      .then(microphone => {
        const options = {
          bufferSize: 16384,
          checkForInactiveTracks: true,
          numberOfAudioChannels: isEdge ? 1 : 2,
          type: 'audio',
        };

        if (isSafari || isEdge) {
          options.recorderType = RecordRTC.StereoAudioRecorder;
        }

        if (isSafari) {
          options.bufferSize = 4096;
          options.numberOfAudioChannels = 2;
          options.sampleRate = 44100;
        }

        recorder.current = RecordRTC(microphone, options);
        recorder.current.startRecording();
        setIsRecording(true);
      });
  }, []);

  const resumeRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.resumeRecording();
      setIsRecordingPaused(false);
    }
  }, [recorder]);

  const retakeRecordedAudio = useCallback(() => {
    audioElement.current.removeEventListener('timeupdate', updateCurrentTime);

    navigator.mediaDevices
      .getUserMedia({
        audio: isEdge
          ? true
          : {
              echoCancellation: false,
            },
      })
      .then(microphone => {
        setStream(microphone);
        setCurrentTime(0);
        setIsRecording(false);
        setIsRecordingFinished(false);
        setIsRecordingPaused(false);
        setIsRetakeVisible(false);
        setSeconds(0);
        setTimer(null);
        setAudio(null);
        audioElement.current.controls = false;
        audioElement.current.muted = false;
        audioElement.current.volume = 0;
        audioElement.current.srcObject = microphone;
      });
  }, [updateCurrentTime]);

  const retakeRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        recorder.current.clearRecordedData();
        setIsRecording(false);
        setIsRecordingPaused(false);
        setSeconds(0);
        setTimer(null);
      });
    }
  }, [recorder]);

  const stopRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        const blob = recorder.current.getBlob();
        audioElement.current.addEventListener('timeupdate', updateCurrentTime);
        audioElement.current.srcObject = null;
        audioElement.current.src = URL.createObjectURL(blob);
        audioElement.current.muted = false;
        audioElement.current.volume = 1;
        audioElement.current.controls = true;
        recorder.current.destroy();
        recorder.current = null;
        setAudio(blob);
        setIsRecording(false);
        setIsRecordingFinished(true);

        if (stream) stream.getTracks().forEach(track => track.stop());
      });
    }
  }, [recorder, stream, updateCurrentTime]);

  useEffect(() => {
    if (editData || showData) {
      const audioData = editData || showData;
      setAudioTitle(audioData.text);
      audioElement.current.addEventListener('timeupdate', updateCurrentTime);
      audioElement.current.srcObject = null;
      audioElement.current.src =
        typeof audioData.media === 'string'
          ? audioData.media
          : URL.createObjectURL(audioData.media);
      audioElement.current.muted = false;
      audioElement.current.volume = 1;
      audioElement.current.controls = true;
      setIsRecordingFinished(true);
      setSeconds(parseInt(audioData.duration, 10));

      if (showData)
        audioElement.current.addEventListener('timeupdate', updateCurrentTime);
    } else
      navigator.mediaDevices
        .getUserMedia({
          audio: isEdge
            ? true
            : {
                echoCancellation: false,
              },
        })
        .then(microphone => {
          setStream(microphone);
          audioElement.current.muted = false;
          audioElement.current.volume = 0;
          audioElement.current.srcObject = microphone;
        });
  }, [editData, showData, updateCurrentTime]);

  useEffect(() => {
    if (timer && (isRecordingFinished || isRecordingPaused)) {
      clearInterval(timer);
      setTimer(null);
    } else if (!timer && isRecording && !isRecordingPaused)
      setTimer(
        setInterval(() => setSeconds(previousValue => previousValue + 1), 1000),
      );

    return () => (timer ? clearInterval(timer) : null);
  }, [isRecording, isRecordingFinished, isRecordingPaused, timer]);

  useEffect(
    () => () => {
      if (stream) stream.getTracks().forEach(track => track.stop());
    },
    [stream],
  );

  const onCancelProcess = useCallback(() => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        // recorder.current.camera.stop();
        recorder.current.destroy();
        recorder.current = null;

        setIsRecording(false);
        setIsRecordingFinished(true);

        if (stream) stream.getTracks().forEach(track => track.stop());
      });
    }
  }, [recorder, stream]);

  return (
    <ReactBootstrapModal centered show contentClassName="task-audio-modal">
      <AudioModalContainer>
        <div className="audio-modal-custom-full">
          <CustomBox padding="24px 0" className="audio-modal-custom">
            <div
              className="audio-modal-header"
              style={
                showData
                  ? {
                      justifyContent: 'flex-end',
                    }
                  : {}
              }
            >
              {showData ? null : (
                <h2>{editData ? 'Edit' : 'Add'} Audio Instruction</h2>
              )}
              <button
                className="modal-close-button"
                onClick={() => {
                  onCancelProcess();
                  onClose();
                }}
                type="button"
              >
                <img alt="Close Modal" src={CloseIcon} />
              </button>
            </div>
            <div className="audio-modal-body">
              <div className="audio-container">
                {!editData && !showData && isRecordingFinished ? (
                  <div className="retake-audio-button-container">
                    <button
                      className={`retake-audio-button ${
                        isRetakeVisible ? 'is-toggled' : ''
                      }`}
                      onClick={() => setIsRetakeVisible(true)}
                      type="button"
                    >
                      <img alt="Retake" src={RetakeRecordedAudioIcon} />
                      <span>Rerecord Audio</span>
                    </button>
                    {isRetakeVisible ? (
                      <div className="retake-confirmation">
                        <div className="retake-confirmation-arrow" />
                        <p>Are you sure to rerecord the audio?</p>
                        <div className="retake-button-group">
                          <button
                            className="retake-cancel-button"
                            onClick={() => setIsRetakeVisible(false)}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className="retake-confirm-button"
                            onClick={() => retakeRecordedAudio()}
                            type="button"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="audio-time-duration">
                  <div>
                    {calculateTimeDuration(
                      isRecordingFinished ? currentTime : seconds,
                    )}
                    {isRecordingFinished ? (
                      <span>/ {calculateTimeDuration(seconds, true)}</span>
                    ) : null}
                  </div>
                  <span className="audio-quality">Standard Quality</span>
                </div>
                <audio autoPlay ref={audioElement} />
                {!isRecordingFinished ? (
                  <div className="audio-modal-controls">
                    {isRecording ? (
                      <>
                        <button
                          className="small-button"
                          key="retake-recording"
                          onClick={retakeRecording}
                          type="button"
                        >
                          <img
                            alt="Retake Recording"
                            src={RetakeRecordingIcon}
                          />
                        </button>
                        <button
                          className="stop-recording-button"
                          key="stop-recording"
                          onClick={stopRecording}
                          type="button"
                        >
                          <img alt="Stop Recording" src={StopRecordingIcon} />
                        </button>
                        <button
                          className="small-button"
                          key="control-playback"
                          onClick={
                            isRecordingPaused ? resumeRecording : pauseRecording
                          }
                          type="button"
                        >
                          <img
                            alt="Pause Recording"
                            src={
                              isRecordingPaused
                                ? ResumeRecordingIcon
                                : PauseRecordingIcon
                            }
                          />
                        </button>
                      </>
                    ) : (
                      <button
                        key="record-audio"
                        onClick={recordAudio}
                        type="button"
                      >
                        <img alt="Record Audio" src={RecordAudioIcon} />
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            {showData ? null : (
              <div className="audio-modal-footer">
                <div className="audio-title">
                  <CustomTextInput
                    label="Audio Title"
                    onChange={event => setAudioTitle(event.target.value)}
                    value={audioTitle}
                  />
                </div>
                <div className="audio-instruction-buttons">
                  <button
                    className="cancel-button"
                    onClick={() => {
                      onCancelProcess();
                      onClose();
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                  <CustomButton
                    isDisabled={
                      editData ? !audioTitle : !recorderAudio || !audioTitle
                    }
                    onClick={() => {
                      if (editData)
                        onUpdate(editData.linkID, {
                          textContent: audioTitle,
                        });
                      else {
                        const body = new FormData();
                        body.append('duration', seconds);
                        body.append(
                          'file',
                          new File([recorderAudio], `${audioTitle}.mp3`, {
                            type: 'audio/mp3',
                          }),
                        );
                        body.append('title', audioTitle);
                        onSubmit(body);
                      }

                      setAudioTitle('');
                      onClose();
                    }}
                    text={editData ? 'Update' : 'Add Instruction'}
                  />
                </div>
              </div>
            )}
          </CustomBox>
        </div>
      </AudioModalContainer>
    </ReactBootstrapModal>
  );
};
