import styled from 'styled-components';
import {Color} from '../../../../../../../../../constants';

export const AssessmentTaskContainer = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  border-top: 0.375rem solid;
  width: 100%;
  margin-top: 1rem;

  @media (max-width: 43.75rem) {
    .assessment-task-details {
      :last-child {
        margin-bottom: unset;
      }
    }
  }
  .position {
    background: #ffffff;
    border: 0.0625rem solid #dedede;
    box-sizing: border-box;
    border-radius: 0.25rem;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    color: #3d4457;
    padding: 0.25rem 0.625rem;
    margin-right: 0.75rem;
  }
  .added-task-instructions {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  .assessment-task-details {
    border-radius: 0.5rem;
    padding: 1.5rem;
    .assessment-task-meta {
      .badge-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.75rem;
      }
      .assessment-task-title {
        color: #3d4457;
        font-weight: 500;
        margin-bottom: unset;
        font-size: 1.125rem;
      }
      .evaluation-type {
        font-weight: normal;
        font-size: 0.75rem;
        border: 0.0625rem solid #dedede;
        box-sizing: border-box;
        border-radius: 0.25rem;
        color: #aeaeae;
        padding: 0.25rem 0.375rem;
      }

      p {
        color: #838383;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.71;
        margin-bottom: 0;
      }
      @media (max-width: 33rem) {
        .badge-container {
          flex-direction: column-reverse;
          margin-bottom: 0.5rem;
          align-items: flex-start;
          .evaluation-type {
            width: 3.5rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .accordion {
    width: 100%;
    .card {
      border-radius: 0.5rem;
      border: 0.0625rem solid #e6e7e8;
    }
    .card-header {
      background-color: unset;
      padding: unset;
    }
    .card-body {
      padding: 0rem 1rem 1rem 1rem;
    }
    .accordion-header {
      display: flex;
      align-items: center;
      color: #838383;
    }
    .btn {
      line-height: 1;
      font-weight: 500;
      font-size: 0.875rem;
      color: #0c181f;
      padding: 0.875rem 0.875rem;
      :focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
      }
      .arrow {
        width: 1.1rem;
        height: 1.1rem;
      }
      .resource {
        margin-right: 0.93rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .instruction-container {
    display: flex;
    border-bottom: 0.0625rem solid #dedede;
    padding: 1rem 0rem 1rem 0rem;
    :last-child {
      padding: 1rem 0rem 0 0rem;
      border-bottom: unset;
    }
    .data-container {
      width: 100%;
    }
    .index {
      font-weight: 400;
      font-size: 0.875rem;
      color: #6d7579;
    }
    .title-container {
      font-weight: 400;
      font-size: 1rem;
      color: #091018;
    }
    @media (max-width: 33rem) {
      .index-container {
        width: 1.5rem;
      }
      .data-container {
        width: 97.5%;
      }
    }
  }
  .added-instruction {
    align-items: flex-start;
    border-radius: 0.3125rem;
    display: flex;
    & > a,
    & > button {
      background-color: transparent;
      border: none;
      height: 2.5rem;
      position: relative;
      width: 2.5rem;

      :focus,
      :active {
        outline: none;
      }
    }

    .play-video-icon {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        height: 1rem;
        width: 1rem;
      }
    }

    .instruction-icon {
      border-radius: 0.3125rem;
      height: 2.5rem;
      object-fit: cover;
      width: 2.5rem;
    }

    .instruction-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 0.625rem;

      span {
        color: #aeaeae;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.21;
        margin-bottom: 0.5rem;
        text-transform: capitalize;
      }

      p {
        color: ${Color.PRIMARY_TEXT};
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.71;
        margin: -0.125rem 0 0 0;
        overflow-wrap: anywhere !important;

        button {
          background-color: transparent;
          border: none;
          color: #00abff;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.71;

          :focus,
          :active {
            outline: none;
          }
        }
      }
    }

    .instruction-options {
      background-color: transparent;
      border: none;

      :focus,
      :active {
        outline: none;
      }
    }
  }

  .display-modal-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }
    &.for-document {
      width: 62%;
      @media (max-width: 33rem) {
        & {
          width: 90%;
        }
      }
    }

    .display-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .modal-close-button {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .instruction-document {
      border-radius: 0.375rem;
      height: 62vh;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .instruction-image {
      align-items: center;
      background-color: #535659;
      border-radius: 0.375rem;
      display: flex;
      height: 62vh;
      justify-content: center;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .text-modal-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }

    .text-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      h5 {
        margin-bottom: unset;
      }
      .modal-close-button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .text-content {
      background-color: #535659;
      height: 40vh;
      overflow-y: auto;
      position: relative;
      width: 100%;
      background: #e6e7e8;
      border-radius: 0px 0px 0.5rem 0.5rem;
      padding: 3rem 9rem;
      p {
        font-weight: 500;
        font-size: 1rem;
        text-align: justify;
        color: #091018;
        line-height: 2;
      }
    }
  }
  .seperator {
    height: 0.0625rem;
    width: 100%;
    background-color: #e9e9e9;
  }
  .btn-container {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    .Yes-No {
      padding: 0.5rem 1.25rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #ffffff;
      box-shadow: unset;
    }
    .Grade {
      padding: 0.4rem 0.5rem;
      border-radius: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #ffffff;
      text-transform: uppercase;
      box-shadow: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .grade-label {
        background-color: #ffffff;
        font-weight: 500;
        font-size: 0.875rem;
        padding: 0.15rem 0.56rem;
        border-radius: 0.25rem;
      }
    }
  }
  @media (max-width: 28rem) {
    .btn-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .footer-resub-button {
      margin-top: 1rem;
    }
  }
  .submissions {
    padding: 0 1.5rem 0rem 1.5rem;
  }
  .submission-header {
    font-weight: 500;
    font-size: 1rem;
    color: #091018;
  }
  .submission-container {
    display: flex;
    padding: 1rem 0rem 0 0rem;

    :last-child {
      padding: 1rem 0rem 1rem 0rem;
      border-bottom: unset;
    }
    :first-child {
      padding: 1rem 0rem 0 0rem;
      border-bottom: unset;
    }
  }
  .footer-resub-button {
    background-color: transparent;
    padding: 0.65rem 1.5rem;
    color: #40a1ff;
    font-size: 0.875rem;
    font-weight: 500;
    color: #474747;
    border: 0.0625rem solid #c1ced4;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    :active,
    :focus {
      outline: none;
    }
    img {
      margin-right: 0.5rem;
    }
  }
`;
