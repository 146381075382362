/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import {Link, useHistory} from 'react-router-dom';

import ArrowIcon from '../../../../../../assets/icons/arrow_right_blue.svg';
import AssessmentIcon from '../../../../../../assets/icons/assessment-fill.svg';
import {AssessmentHeaderContainer} from './elements';
import {MoreOptions} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import TeacherIcon from '../../../../../../assets/icons/post-teacher.svg';

export const AssessmentHeader = ({
  dueDate,
  name,
  publicationDate,
  draftedDate,
  isStudent,
  linkToEdit,
  handleDeletion,
  description,
  isPublished,
  linkToView,
  classID,
  assessmentID,
  isArchived,
  actionHideStatus,
  profileName,
}) => {
  const history = useHistory();
  const formatteddueDate = moment(dueDate).format('D MMMM YYYY');
  const formattedPublicationDate =
    moment(publicationDate).format('D MMMM YYYY');
  const publicationDate1 = moment(publicationDate).format('D MMM YYYY');
  const formattedDraftedDate = moment(draftedDate).format('D MMMM YYYY');
  const draftedDate1 = moment(draftedDate).format('D MMM YYYY');
  const dueDate1 = moment(dueDate).format('D MMM YYYY');
  return (
    <AssessmentHeaderContainer>
      <div className="header-top">
        <img
          alt="Assessment Icon"
          className="header_t-icon"
          src={AssessmentIcon}
        />
        <div className="header_t-right">
          <div className="header_tr-text">
            <div className="header_trt-title_head">
              {' '}
              <h2 className="header_trt-title">{name}</h2>
              <div className="assessment-username">
                <div className="seperator" />
                <span>{profileName && profileName}</span>
                <img alt="Teacher" src={TeacherIcon} className="teacher-icon" />
              </div>
            </div>
            {/* <div className="post-username">
              <h2 className="header_trt-title">{name}</h2>
              <span>{profileName && profileName}</span>
              <img alt="Teacher" src={TeacherIcon} />
            </div> */}
            <div className="publish-draft-dropdown-div">
              {publicationDate && isPublished ? (
                <div className="publish-data">
                  <div
                    className={classNames('header_trt-publication-date', {
                      'is-archive': isArchived,
                    })}
                  >
                    Published •{' '}
                    <span className="header_trtp-value">
                      {formattedPublicationDate}
                    </span>
                  </div>
                  <div className="header_trt-publication-date-hide">
                    Published •{' '}
                    <span className="header_trtp-value">
                      {publicationDate1}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="draft-data">
                  <div className="header_trt-drafted-date">
                    Drafted
                    <span className="header_trtd-value">
                      {' '}
                      • {formattedDraftedDate}
                    </span>
                  </div>
                  <div className="header_trt-drafted-date-hide">
                    Drafted
                    <span className="header_trtd-value"> • {draftedDate1}</span>
                  </div>
                </div>
              )}

              {isStudent || isArchived || actionHideStatus === 0 ? null : (
                <MoreOptions id="more-option">
                  <DropdownItem
                    label="Edit"
                    onClick={() => history.push(linkToEdit)}
                    id="edit"
                  />
                  <DropdownItem
                    isDanger
                    label="Delete"
                    onClick={handleDeletion}
                    id="delete"
                  />
                </MoreOptions>
              )}
            </div>
          </div>
          {dueDate !== null ? (
            <p className="header_tr-due-date">
              Due Date •{' '}
              <span className="header_trd-value">{formatteddueDate}</span>
            </p>
          ) : null}
        </div>
      </div>
      <div className="header_trt-hide">
        <h2 className="header_trt-title">{name}</h2>
        {dueDate !== null ? (
          <p className="header_tr-due-date-hide">
            Due Date • <span className="header_trd-value">{dueDate1}</span>
          </p>
        ) : null}
      </div>
      <p className="header-description">{description}</p>
      <Link
        className="body-action"
        to={
          isPublished
            ? linkToView
            : `/stream/${classID}/assessment/edit/${assessmentID}`
        }
        id="view-assessment-id"
      >
        View Assessment{' '}
        <img alt="View" className="body_a-icon" src={ArrowIcon} />
      </Link>
    </AssessmentHeaderContainer>
  );
};
