import styled from 'styled-components';

export const UserCardContainer = styled.div`
  .top-div {
    height: 15rem;
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: unset;

    background-color: ${backgroundColor => backgroundColor};
    :hover {
      padding: 1.375rem;
      border: 0.125rem solid ${({borderColor}) => borderColor};
    }
  }
  button {
    border: none;
    background: transparent;
  }

  .user-top {
    display: flex;
    justify-content: space-between;
    .user-top-content-left-top {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.375rem;
      letter-spacing: 0.025rem;
      color: #3d4457;
    }
    .user-top-content-left-bottom {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #3d4457;
    }
  }
  .footer-delete-break {
    height: 0.0625rem;
    background-color: #e0e0e0;
    width: 100%;
  }
  .user-bottom {
    .user-bottom-content-left {
      display: flex;
      text-align: left;
      justify-content: space-between;
      .user-bottom-content-left-top {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.025rem;
        color: #3d4457;
      }
      .user-bottom-content-left-bottom {
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: #a5a5a5;
      }
    }
    .user-bottom-content-left-none {
      display: flex;
      justify-content: space-between;
      margin-top: 4.6875rem;
      .user-bottom-content-left-bottom-none {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #3d4457;
      }
    }
  }
  @media (max-width: 48rem) {
    .top-div {
      height: 13.75rem;
      padding: 1.5rem !important;
    }
  }
`;
