/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';

import {AddAssessmentHeaderContainer} from './elements';
import BackIcon from '../../../../../../assets/icons/arrow_left.svg';
import CheckFillIcon from '../../../../../../assets/icons/tas-check-fill.svg';
import {MoreOptions} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {DeleteModal} from '../../../../components';

import {deleteAssessment} from '../../../Assesments/Assessment/slices/assessmentSlice';

import {deleteAssessmentFromPosts} from '../../../../../../redux/streamSlice';
import {showToast} from '../../../../../../components/common/Toast';

const DELAY = 500;

export const AddAssessmentHeader = ({
  currentTab,
  setCurrentTab,
  assessmentName,
  isPublished,
  assessmentId,
  // tabSwitchOnClick,
  isArchive,
  isTabSwitch,
  isSaveClicked,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTab]);

  return (
    <AddAssessmentHeaderContainer>
      <div className="view-assessment-nav">
        <div className="header-top">
          <div className="left-head">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="go-back-btn"
              id="go-back-id"
            >
              <img
                alt="Back Button"
                className="header_t-back-icon"
                src={BackIcon}
              />
            </button>
            <div className="assessment-head">
              <div>{assessmentName}</div>
              <div className="search-div">
                <button
                  className={
                    currentTab === 'details' ? 'buttom link' : 'buttom'
                  }
                  type="button"
                  onClick={() => {
                    setCurrentTab('details');
                  }}
                  id="details-id"
                >
                  {isSaveClicked && currentTab === 'tasks' ? (
                    <img
                      src={CheckFillIcon}
                      alt="check"
                      className="check-fill-icon mr-2"
                    />
                  ) : (
                    <span
                      className={classNames('mr-2 count', {
                        'is-current': currentTab === 'details',
                      })}
                    >
                      1
                    </span>
                  )}
                  <p>Basic Details</p>
                </button>
                <button
                  disabled={!isSaveClicked}
                  className={currentTab === 'tasks' ? 'buttom link' : 'buttom'}
                  type="button"
                  onClick={() => {
                    if (isSaveClicked && isTabSwitch()) {
                      // tabSwitchOnClick();
                      setCurrentTab('tasks');
                    }
                  }}
                  id="task-id"
                >
                  <span
                    className={classNames('mr-2 count', {
                      'is-current': currentTab === 'tasks',
                    })}
                  >
                    2
                  </span>
                  <p>Task & Instruction</p>
                </button>
              </div>
            </div>
          </div>
          <DeleteModal
            // isButtonLoading={isAssessmentLoading}
            loadingButtonLabel="Deleting"
            onButtonClick={() => {
              dispatch(
                deleteAssessment(
                  deleteId,

                  aouiID => {
                    dispatch(deleteAssessmentFromPosts(aouiID));
                    showToast(
                      false,
                      `${deleteName} deleted successfully`,
                      true,
                    );
                  },
                ),
              );
              history.goBack();
            }}
            onHide={() => {
              setDeleteId(null);

              setTimeout(() => {
                setDeleteName('');
              }, DELAY);
            }}
            show={!!deleteId}
            title={`Delete ${deleteName}`}
          />
          {!isPublished ? (
            <MoreOptions
              className={classNames('delete-assessement', {
                'is-archive': isArchive,
              })}
              id="more-option"
            >
              <DropdownItem
                isDanger
                label="Delete"
                onClick={() => {
                  setDeleteId(assessmentId);
                  setDeleteName(assessmentName);
                }}
                id="delete-btn-id"
              />
            </MoreOptions>
          ) : null}
        </div>
      </div>
    </AddAssessmentHeaderContainer>
  );
};
