import React from 'react';
import {useRouteMatch, Route, Switch, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {streamSelector} from '../../redux/streamSlice';
import {
  Stream,
  Works,
  SearchOrAddAssessment,
  AddAssessment,
  Library,
  LibraryStudent,
} from '../Stream/pages';
import Students from '../Stream/pages/Students';
// import Units from './pages/Units';
import Navbar from '../../components/shared/Navbar';

// import Assessment from './pages/Assesments/Assessment';
import {AssessmentView} from './pages';
import AssessmentMap from '../Stream/pages/AssessmentMap/index';
import {TaskSubmission} from './pages/AssessmentView/pages';
import UnitDetails from '../Stream/pages/Units/UnitDetails';
import Sessions from '../Sessions';
import AssessmentEvaluationView from '../Stream/pages/Assesments/AssesmentEvaluationView/Components';
import {TaskEvaluation} from '../Stream/pages/TaskEvaluation';
import {SessionsCalendar} from '../Admin/pages';
import {AllNotification} from '../Notification';

const Student = () => {
  const match = useRouteMatch();
  const streamState = useSelector(streamSelector);

  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/all-notification`}
          exact
          component={AllNotification}
        />
        <Route exact path={`${match.path}/assessment`} render={() => null} />
        <Route
          exact
          path={`${match.path}/assessment/:id`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/task/:taskID`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/assessment-summary`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID/task/:taskID`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID/task/:taskID`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/create/:id`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/assessment/edit/:id`}
          render={() => null}
        />
        <Route
          exact
          path={`${match.path}/sessions/calendar`}
          render={() => <Navbar />}
        />
        <Route path={`${match.path}/sessions`} render={() => null} />
        <Route render={() => <Navbar />} />
      </Switch>
      <Switch>
        <Route path={`${match.path}/live`} render={() => <Stream />} />
        <Route exact path={`${match.path}/works`} render={() => <Works />} />
        <Route
          exact
          path={`${match.path}/students`}
          render={() => <Students students={streamState.classStudents} />}
        />
        <Route
          exact
          path={`${match.path}/assessment-map`}
          render={() => <AssessmentMap />}
        />
        <Route
          exact
          path={`${match.path}/library`}
          render={() => <Library />}
        />
        <Route
          exact
          path={`${match.path}/library-student`}
          render={() => <LibraryStudent />}
        />
        {/* <Route
          exact
          path={`${match.path}/units`}
          render={() => (
            <Units
              isLoadingUnits={streamState.isLoading}
              units={streamState.classUnits}
            />
          )}
        /> */}
        <Route
          exact
          path={`${match.path}/units/:unitID`}
          render={() => <UnitDetails />}
        />
        <Route
          exact
          path={`${match.path}/assessment`}
          render={() => <SearchOrAddAssessment />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id`}
          render={() => <AssessmentView />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/task/:taskID`}
          render={() => <TaskSubmission />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students`}
          render={() => <AssessmentView />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/assessment-summary`}
          render={() => <AssessmentView />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID`}
          render={() => <AssessmentView />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID/task/:taskID`}
          render={() => <AssessmentView />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:assessmentID/students/:studentID`}
          render={() => <AssessmentEvaluationView />}
        />
        <Route
          exact
          path={`${match.path}/assessment/:id/students/:studentID/task/:taskID`}
          render={() => <TaskEvaluation />}
        />
        {/* <Route
          exact
          path={`${match.path}/assessment/create/:id`}
          render={() => <Assessment students={streamState.classStudents} />}
        />
        <Route
          exact
          path={`${match.path}/assessment/edit/:id`}
          render={() => <Assessment students={streamState.classStudents} />}
        /> */}
        <Route
          exact
          path={`${match.path}/assessment/create/:id`}
          render={() => <AddAssessment students={streamState.classStudents} />}
        />
        <Route
          exact
          path={`${match.path}/assessment/edit/:id`}
          render={() => <AddAssessment students={streamState.classStudents} />}
        />
        <Route
          component={SessionsCalendar}
          exact
          path={`${match.path}/sessions/calendar`}
        />
        <Route path={`${match.path}/sessions`}>
          <Sessions />
        </Route>
        <Redirect to={`${match.path}/live`} />
      </Switch>
    </>
  );
};

export default Student;
