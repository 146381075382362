/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import GeneralIcon from '../../../../../../../../../../assets/icons/general-icon.svg';
import {GeneralContainer} from './elements';

export const GeneralSection = ({assessmentSummary}) => (
  <GeneralContainer>
    <div className="header-section">
      <div className="image">
        <img src={GeneralIcon} alt="general" className="general-icon" />
      </div>
      <div>
        <p className="header">General</p>
      </div>
    </div>
    <div className="seperator" />
    <div className="content-section">
      <div className="first-div">
        <div className="first-content">
          <div className="title-div">
            <span>1</span>
            <p className="title">Unit Code</p>
          </div>
          <p className="content">
            {
              assessmentSummary?.instance?.orgUnitInstance?.orgUnit.unit
                ?.tun_code
            }
          </p>
        </div>
        <div className="first-content">
          <div className="title-div">
            <span>2</span>
            <p className="title">Unit Title</p>
          </div>
          <p className="content">
            {
              assessmentSummary?.instance?.orgUnitInstance?.orgUnit.unit
                ?.tun_title
            }
          </p>
        </div>
      </div>

      <div className="second-div">
        <div className="second-content">
          <div className="title-div">
            <span>3</span>
            <p className="title">Assessment Title</p>
          </div>
          <p className="content">{assessmentSummary?.ac_activity_name}</p>
        </div>
        <div className="second-content">
          <div className="title-div">
            <span>4</span>
            <p className="title">Date</p>
          </div>
          <p className="content">
            {moment(assessmentSummary?.aoui_timestamp_creation).format(
              'Do MMM YYYY',
            )}
          </p>
        </div>
      </div>
      <div className="third-div">
        <div className="third-content">
          <div className="title-div">
            <span>5</span>
            <p className="title">Time</p>
          </div>
          <p className="content">
            {moment(assessmentSummary?.aoui_timestamp_creation).format(
              'h.mm A',
            )}
          </p>
        </div>
        <div className="third-content">
          <div className="title-div">
            <span>6</span>
            <p className="title">Location</p>
          </div>
          <p className="content">{assessmentSummary?.ac_location}</p>
        </div>
      </div>
      <div className="fourth-div">
        <div className="title-div">
          <span>7</span>
          <p className="title">Teacher Name</p>
        </div>
        <p className="content-last">{`${assessmentSummary?.up_name_first} ${assessmentSummary?.up_name_last}`}</p>
      </div>
    </div>
  </GeneralContainer>
);
