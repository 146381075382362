import styled from 'styled-components';

const IndustryEngagementsFormContainer = styled.div`
  .serial {
    width: 4.4%;
  }

  .first-name,
  .last-name,
  .position {
    width: 18.6%;
  }

  .organisation {
    width: 20.7%;
  }

  .date {
    width: calc(19.1% - 1.5rem);
  }

  .delete {
    background-color: transparent;
    border: none;
    display: flex;
    height: 1.5rem;
    width: 1.5rem;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .add-teacher-section {
    padding-top: 1.5rem;

    button {
      align-items: center;
      background-color: transparent;
      border: none;
      color: ${({theme}) => theme.colors.primaryAlt};
      display: flex;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 0.875rem;
      padding: 0.6875rem 1.25rem;

      img {
        height: 1.25rem;
        margin-right: 0.5rem;
        object-fit: contain;
        width: 1.25rem;
      }
    }
  }
`;

export default IndustryEngagementsFormContainer;
