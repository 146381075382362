/* eslint-disable react/prop-types */
import React, {useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {EmptyState, Table, BackLink} from '../../components/common';

import {ListingPageHeader, TableSpinner} from '../Admin/components-new';
import {NotificationContainer} from './elements';
import {
  fetchAllNotification,
  notificationSelector,
  markAsRead,
} from '../../redux/notificationSlice';
import Navbar from '../../components/shared/Navbar';
import {notificationRedirection} from '../../utils/functions';

export const AllNotification = () => {
  const userType = localStorage.getItem('jc-user-type');
  const dispatch = useDispatch();
  const {notification, isLoading} = useSelector(notificationSelector);
  useEffect(() => {
    dispatch(fetchAllNotification());
  }, [dispatch]);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Cell: ({row: {original}}) => {
          const timeAgo =
            original?.al_timestamp && moment(original?.al_timestamp).fromNow();
          return (
            <button
              className="notication-messages-data"
              type="button"
              onClick={() => {
                dispatch(markAsRead(original?.al_id_auditlog));
                notificationRedirection(
                  original?.al_id_auditlog_type,
                  history,
                  original,
                );
              }}
            >
              <div className="creator-profile">
                <div
                  className={classNames('unseen', {
                    'unseen-dot': original?.al_notification_seen === 0,
                  })}
                />
                <span className="align-items-center circle d-flex justify-content-center profile-creator">
                  {original?.userProfile?.up_name_first[0]}
                  {original?.userProfile?.up_name_last[0]}
                </span>
              </div>
              <div className="notication-messages">
                <div className="message-details">
                  <div
                    className={classNames('message', {
                      delete:
                        original?.al_id_auditlog_type === 29 ||
                        original?.al_id_auditlog_type === 30,
                    })}
                  >
                    <span className="message-title">
                      {original?.al_notification_title}
                    </span>
                    <br />
                    {original?.al_notification_text}
                  </div>
                  <div className="received-details">
                    <div>
                      {' '}
                      {`${original?.userProfile?.up_name_first}
                     ${original?.userProfile?.up_name_last}`}
                    </div>
                    <div className="time">• {timeAgo}</div>
                  </div>
                </div>
              </div>
            </button>
          );
        },
        accessor: 'al_notification_title',
        className: 'notification-details',
      },
    ],
    [dispatch, history],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(notification || [])?.map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [notification],
  );

  return (
    <>
      <Helmet>
        <title>Notification | JungleCat</title>
      </Helmet>
      <Navbar />
      <NotificationContainer
        className={classNames({'is-admin': Number(userType) === 4})}
      >
        <BackLink
          className="mb-3"
          onClick={() => {
            history.goBack();
          }}
        />
        <ListingPageHeader className="mb-4" title="All Notifications" />

        <div className="table-container">
          {isLoading ? <TableSpinner /> : null}
          {!isLoading && !notification?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Notifications Found"
            />
          ) : null}

          {!isLoading &&
          notification?.length &&
          Array.isArray(notification) &&
          !isLoading ? (
            <Table
              columns={columns}
              data={data}
              itemName="Notifications"
              maxWidth="45rem"
              isSortedBy
            />
          ) : null}
        </div>
      </NotificationContainer>
    </>
  );
};
