import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';
// import moment from 'moment';
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {AddAssessmentContainer, AssessmentFormContainer} from './elements';

import {
  AddAssessmentHeader,
  AddAssessmentFooter,
} from '../../../../../ActiveClasses/pages/AddAssessment/components';

import {AddAssessmentBody} from './components';

import {
  getAllActivityMethods,
  getAllEvaluationType,
  // fetchAllUnits,
  getPerformanceCriteria,
  fetchAssessmentCondition,
  fetchKnowledgeEvidence,
  fetchPerformanceEvidence,
  updateAssessmentDetails,
  getAssessmentDetails,
  resetAction,
  fetchDraftTask,
  onDeletingAssessmentTask,
  deleteAssessment,
  adminAssessmentsSelector,
} from '../../../../../../redux/adminAssessmentsSlice';
import {Spacer} from '../../../../../../components/common';
import {publishActivityItems} from '../../../../../../api/assessment';
import {showToast} from '../../../../../../components/common/Toast';

export const AddAssessmentAdmin = () => {
  const [currentTab, setCurrentTab] = useState('details');
  const [isLoading, setIsLoading] = useState(false);
  const [openAddTask, setOpenAddTask] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [addedTasks, setAddedTasks] = useState([]);
  const [removedTasks, setRemovedTasks] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    errors,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      assessTitle: '',
      description: '',
      unit: null,
      assessType: null,
    },
  });
  const history = useHistory();
  const {id} = useParams();
  const dispatch = useDispatch();
  const {createdAssessment, activityId, createdTasks, isDeleteLoading} =
    useSelector(adminAssessmentsSelector);

  const isUpdate =
    !createdAssessment?.ac_activity_description?.length ||
    !createdAssessment?.ac_activity_name?.length;

  const isPublished = createdAssessment?.aoui_status === 2;

  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const onSubmit = () => {
    // Publishing assessment
    if (createdAssessment) {
      setIsUpdating(true);

      if (addedTasks.length) {
        publishActivityItems(addedTasks);
      }

      if (removedTasks.length) {
        dispatch(onDeletingAssessmentTask(removedTasks));
      }
    }

    history.replace('/admin/assessments');
  };

  // fetch data for add assessment
  useEffect(() => {
    // dispatch(fetchAllUnits());
    dispatch(getAllActivityMethods());
    dispatch(getAllEvaluationType());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(
        getAssessmentDetails(id, null, () => {
          setIsLoading(false);
        }),
      );
    }
    // Reseting the state
    return () => {
      dispatch(resetAction());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (activityId) dispatch(fetchDraftTask(activityId));
  }, [activityId, dispatch]);

  useEffect(() => {
    if (createdAssessment?.tun_id_tgovunit) {
      dispatch(getPerformanceCriteria(createdAssessment?.tun_id_tgovunit));
      dispatch(fetchPerformanceEvidence(createdAssessment?.ac_id_orgunit));
      dispatch(fetchKnowledgeEvidence(createdAssessment?.ac_id_orgunit));
      dispatch(fetchAssessmentCondition(createdAssessment?.ac_id_orgunit));
    }
  }, [dispatch, createdAssessment]);

  // const totalStudentsCount = assessmentState.filteredAssessmentStudents.length;

  const formOnSubmit = () => {
    if (createdTasks?.length) {
      return handleSubmit(onSubmit);
    }
    return event => event.preventDefault();
  };

  const isTabSwitch = useCallback(() => {
    if (!getValues('assessTitle')) {
      return false;
    }
    if (!getValues('description')) {
      return false;
    }
    if (!getValues('unit')) {
      return false;
    }
    if (!getValues('assessType')) {
      return false;
    }

    return true;
  }, [getValues]);

  const tabSwitchOnClick = useCallback(() => {
    if (!getValues('assessTitle')) {
      setError('assessTitle', {
        message: 'Please Enter Title',
      });
    }
    if (!getValues('description')) {
      setError('description', {
        message: 'Please Enter Description',
      });
    }
    if (!getValues('unit')) {
      setError('description', {
        message: 'Please select unit',
      });
    }
    if (!getValues('assessType')) {
      setError('description', {
        message: 'Please select assessType',
      });
    }

    if (!Object.keys(errors).length) {
      clearErrors();
      setCurrentTab('tasks');
    }
  }, [clearErrors, errors, getValues, setError]);

  const setFieldsError = useCallback(
    (fieldName, errorMessage) => {
      if (!getValues(fieldName)) {
        setError(fieldName, {
          message: errorMessage,
        });
      } else {
        clearErrors(fieldName);
      }
    },
    [clearErrors, getValues, setError],
  );

  const onSaveBasicDetails = useCallback(
    // eslint-disable-next-line consistent-return
    () => {
      const data = {
        type: getValues('assessType')?.value,
        title: getValues('assessTitle'),
        description: getValues('description'),
        unitId: getValues('unit')?.value,
        resourceRequired: getValues('resourcerequired'),
        studentInstruction: getValues('student-instructions'),
        additionalInformation: getValues('additional-information'),
      };

      if (!isPublished) {
        tabSwitchOnClick();
        setIsSaveClicked(true);
      }
      if (isPublished) {
        if (!data.title) {
          return setFieldsError('assessTitle', 'Please Enter Title');
        }
        clearErrors('assessTitle');

        if (!data.description) {
          return setFieldsError('description', 'Please Enter Description');
        }
        clearErrors('description');

        if (!getValues('unit')) {
          return setFieldsError('unit', 'Please select unit');
        }
        clearErrors('unit');
        if (!getValues('assessType')) {
          return setFieldsError('assessType', 'Please select assessType');
        }
        clearErrors('assessType');
      }

      const {title, description} = data;
      if (
        title?.trim() !== '' &&
        description?.trim() !== '' &&
        data.unitId !== ''
      ) {
        dispatch(
          updateAssessmentDetails(activityId, data, () => {
            setCurrentTab('tasks');
          }),
        );
      }
    },

    [
      activityId,
      clearErrors,
      dispatch,
      getValues,
      isPublished,
      setFieldsError,
      tabSwitchOnClick,
    ],
  );

  const onDeleteAssessment = (deleteId, deleteName) => {
    dispatch(
      deleteAssessment(
        deleteId,

        () => {
          if (!isDeleteLoading) {
            showToast(false, `${deleteName} deleted successfully`, true);
            history.replace('/admin/assessments');
          }
        },
      ),
    );
  };

  const onBack = () => history.replace('/admin/assessments');

  useEffect(() => {
    if (isUpdating) {
      setIsUpdating(false);
      onSaveBasicDetails();
    }
  }, [isUpdating, onSaveBasicDetails]);
  // console.log(createdAssessment);
  return (
    <>
      <Helmet>
        <title>
          {createdAssessment?.ac_activity_name || 'Assessment'} | JungleCat
        </title>
      </Helmet>
      <AddAssessmentContainer>
        <AddAssessmentHeader
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          assessmentName={createdAssessment?.ac_activity_name}
          assessmentId={createdAssessment?.ac_id_activity}
          isPublished={isPublished}
          tabSwitchOnClick={onSaveBasicDetails}
          isTabSwitch={isTabSwitch}
          isSaveClicked={isSaveClicked}
          onDeleteAssessment={onDeleteAssessment}
          isDeleteLoading={isDeleteLoading}
          onBack={onBack}
        />
        <Spacer height="8rem" />
        <AssessmentFormContainer method="POST" onSubmit={formOnSubmit()}>
          <AddAssessmentBody
            currentTab={currentTab}
            isUpdating={isUpdating}
            isLoading={isLoading}
            register={register}
            getValues={getValues}
            setValue={setValue}
            closeAddTask={() => setOpenAddTask(false)}
            isPublished={isPublished}
            addTask={task =>
              setAddedTasks(previousValue => [...previousValue, task])
            }
            control={control}
            errors={errors}
            stopUpdating={() => setIsUpdating(false)}
            removeTask={task =>
              setRemovedTasks(previousValue => [...previousValue, task])
            }
            openAddTask={openAddTask}
            setOpenAddTask={setOpenAddTask}
            setError={setError}
            clearErrors={clearErrors}
            watch={watch}
          />
          <Spacer height="6rem" />
          <AddAssessmentFooter
            // totalStudentsCount={totalStudentsCount}
            // selectedStudents={selectedStudents}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onButtonClick={formOnSubmit}
            continueButtonOnClick={onSaveBasicDetails}
            isTabSwitch={isTabSwitch}
            isSaveClicked={isSaveClicked}
            isUpdate={isUpdate}
            createdTasks={createdTasks}
          />
        </AssessmentFormContainer>
      </AddAssessmentContainer>
    </>
  );
};
