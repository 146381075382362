import styled, {keyframes} from 'styled-components';
import CustomBox from '../../../../../../../components/shared/CustomBox';

const fadein = keyframes`
  @keyframes fadein {
    from {
      opacity: 0;
      height: 0%;
    }
    to {
      opacity: 1;
      height: 100%;
    }
  }
`;

export const SharePostContainer = styled(CustomBox)`
  width: 100%;
  .validation-error {
    color: red;
    display: inline-block;
  }
  .multi-select {
    width: 20rem;
  }
  .share-post-closed,
  .share-post-open {
    img {
      height: 2.25rem;
      width: 2.25rem;
    }
  }
  @media (max-width: 43.75rem) {
    .student-select-dropdown-div {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      width: 100%;
      .dropdown-toggler {
        img {
          margin-top: 0.312rem;
        }
      }
      .dropdown {
        width: 100%;
        right: unset;
        .students-search {
          width: 100%;
        }
      }
    }
    .attachment-container {
      input[type='file'] {
        width: 30%;
      }
    }
    @media (min-width: 46.87rem) {
      .attachment-container {
        input[type='file'] {
          width: 30%;
        }
      }
    }
  }

  .share-post-closed {
    align-items: center;
    display: flex;

    img {
      height: 2.5rem;
      width: 2.5rem;
    }
    span {
      font-weight: 400;
      line-height: 1.21;
      font-size: 1rem;
      margin-left: 1rem;
      color: #aaa;
    }
    span:hover {
      cursor: pointer;
    }
  }
  .share-post-open {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    .teacher-name {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.0625rem;
      color: #565656;
    }
    .class-det {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.2;
      color: #aaa;
    }
  }
  .attachment-container {
    align-items: center;
    margin-top: 1rem;

    .anchor-icon {
      height: 0.812rem;
      width: 0.75rem;
    }

    span {
      color: #00abff;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.21;
      margin-left: 0.437rem;
    }
    input[type='file'] {
      position: absolute;
      opacity: 0;
    }
  }
  .fade-in {
    animation-name: ${fadein};
    animation-duration: 0.7s;
  }

  .btn-secondary {
    margin-right: 1.5rem;
    padding: 0;

    :active,
    :focus,
    :hover {
      background-color: #fff !important;
      outline: none;
    }
  }

  .submit-button {
    align-items: center;
    display: flex;

    .button-spinner {
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
      height: 1.25rem;
      margin-right: 0.5rem;
      width: 1.25rem;
    }
  }
`;
