import styled from 'styled-components';

export const StudentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100vh;
  width: 100%;

  .student-container {
    display: flex;
    flex-direction: column;
    padding: 7rem 13rem 3rem 13rem;
    width: 100%;
  }

  @media (max-width: 70rem) {
    .student-container {
      display: flex;
      flex-direction: column;
      padding: 7rem 1rem 3rem 8rem;
      width: 100% !important;
    }
  }
  @media (max-width: 43.75rem) {
    .student-container {
      display: flex;
      flex-direction: column;
      padding: 12rem 1rem 3rem 7rem;
    }
  }
  @media (max-width: 37.5rem) {
    .student-container {
      display: flex;
      flex-direction: column;
      padding: 12rem 1rem 3rem 1rem;
    }
  }
  .checkbox {
    height: 0.875rem;
    width: 0.875rem;
    border: 0.0625rem solid #bab8b8 !important;
  }
  .list-div {
    background: #ffffff;
    border: 0.0625rem solid #e5e5e5;
    border-radius: 0.312rem;
  }
  button:focus {
    outline: none;
    box-shadow: none;
  }

  .student-profile-picture {
    height: 2rem;
    margin-right: 0.75rem;
    width: 2rem;
  }

  .invitation-status-0,
  .invitation-status-1,
  .invitation-status-2 {
    border-radius: 0.3125rem;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.21;
    padding: 0.5rem 1rem;
  }
  .invitation-status-0 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }
  .invitation-status-1 {
    background-color: #e4f4f1;
    color: #4ec977;
  }
  .invitation-status-2 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }

  @media (max-width: 45rem) {
    .invitation-status-0,
    .invitation-status-1,
    .invitation-status-2 {
      border-radius: 5px;
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.21;
      padding: 8px 16px;
    }
  }
`;
export const TableStyles = styled.div`
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  padding: 1.5rem;
  width: 100%;

  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;
    /* tr {
      td {
        :nth-child(4) {
          display: flex;
          justify-content: space-between;
        }
      }
    } */

    .serial {
      width: 5%;
    }

    .fullname {
      width: 20%;
    }
    .ref-id {
      width: 12%;
    }
    .view {
      width: 13%;
      line-height: 1.5;
      overflow-wrap: anywhere;
      padding: 1rem 0;
    }

    .status {
      width: 15%;
    }

    .view-profile {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 0.875rem;
      justify-content: space-between;
      .view-arrow {
        margin-left: 0.5rem;
      }
      padding: 1rem;
    }
    .invited {
      display: flex;
      align-items: center;
      span {
        color: #aaa;
      }
      .view-profile {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.875rem;
        justify-content: space-between;
        opacity: 0.5;

        .view-arrow {
          margin-left: 0.5rem;
        }
        padding: unset;
      }
    }
    @media (max-width: 75rem) {
      .view-profile-div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
