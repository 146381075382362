import axios from '..';

export const fetchCurriculumByOrgUnitInstanceId = unitID =>
  axios.get(`/api/class/${unitID}/curriculum`);

export const publishCurriculumModuleFileById = (
  unitID,
  orgClassCurriculumFileId,
) =>
  axios.patch(
    `/api/class/${unitID}/curriculum/viewFileToSharedClass/${orgClassCurriculumFileId}/publish`,
  );

export const publishAllCurriculumModuleFiles = (unitID, orgClassCurriculumId) =>
  axios.patch(
    `/api/class/${unitID}/curriculum/${orgClassCurriculumId}/publish-all`,
  );

export const fetchCurriculumFileByFileId = (unitID, curriculumFileId) =>
  axios.get(
    `/api/class/${unitID}/curriculum/viewFileToSharedClass/${curriculumFileId}`,
  );
