import styled, {css} from 'styled-components';

export const SelectContainer = styled.div`
  .error-message {
    bottom: -0.2rem;
    color: ${({theme}) => theme.colors.danger};
    font-size: 0.75rem;
    left: 0;
    line-height: 0.9075rem;
  }
  .is-truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({maxWidth}) =>
    maxWidth
      ? css`
          max-width: ${maxWidth};
        `
      : ''};
  ${({width}) =>
    width
      ? css`
          width: ${width} !important;
        `
      : ''};
  ${({flexBasis}) =>
    flexBasis
      ? css`
          flex-basis: ${flexBasis} !important;
        `
      : ''};

  .label {
    align-items: center;
    color: ${({theme}) => theme.colors.text};
    display: flex;
    font-size: 0.875rem;
    line-height: 1.05875rem;

    img {
      height: 1rem;
      margin-left: 0.5rem;
      object-fit: contain;
      width: 1rem;
    }
  }

  .select-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .selected-value {
    color: ${({theme}) => theme.colors.text};
  }

  .dropdown-indicator-icon {
    height: 1.25rem;
    width: 1.25rem;
  }

  .option-content {
    color: ${({theme}) => theme.colors.text};
  }

  .blue-tick-icon {
    height: 1rem;
    width: 1rem;
  }
  .no-option-div {
    outline: none;
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: unset !important;
    margin-top: unset !important;
    font-size: 0.875rem;
    font-weight: 500;
    img {
      vertical-align: middle;
    }
  }
`;
