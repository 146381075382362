/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React from 'react';
import RemoveFileIcon from '../../../../../../../../../../../../assets/icons/delete-logo.svg';

import {LinkedTaskContainer} from './elements';

export const LinkedTask = ({linkTask, onClick}) => {
  const TimeStamp = timeData => {
    const secs = Math.floor(timeData);
    const hours = Math.floor(secs / (60 * 60));
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.floor(divisorForSeconds);
    if (hours > 0)
      return `${hours}:${minutes > 9 ? minutes : `0${minutes}`}:${
        seconds > 9 ? seconds : `0${seconds}`
      }`;
    return `${minutes > 9 ? minutes : `0${minutes}`}:${
      seconds > 9 ? seconds : `0${seconds}`
    }`;
  };
  return (
    <LinkedTaskContainer>
      <div className="details">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            {linkTask?.fl_video_timecode !== null ? (
              <div className="badge-time">
                <p>{TimeStamp(Number(linkTask?.fl_video_timecode))}</p>
              </div>
            ) : (
              <div />
            )}
            <div className="title">
              {linkTask?.activityItem?.ai_activityitem}
            </div>
          </div>
          <div>
            <button type="button" onClick={onClick} className="remove">
              <img src={RemoveFileIcon} alt="delete" />
            </button>
          </div>
        </div>
        <div className="description">
          {linkTask?.activityItem?.ai_activityitem_description}
        </div>
      </div>
    </LinkedTaskContainer>
  );
};
