import styled from 'styled-components';

import {Color} from '../../constants';

const StudentInvitationContainer = styled.main`
  -ms-overflow-style: none;
  background-color: #f4f5f8;
  height: 100vh;
  overflow-y: auto;
  margin: 0 -15px;
  padding: calc(81px + 7%) 0 7% 0;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  .set-credentials-container {
    margin: 0 auto;
    width: 46%;

    .set-credentials-title {
      color: ${Color.PRIMARY_TEXT};
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .set-credentials-description {
      color: ${Color.LIGHT_TEXT};
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 24px;
    }

    .set-credentials-form {
      display: flex;
      flex-direction: column;

      .set-credentials-submit-button {
        align-self: flex-end;
      }
    }
  }
`;

export default StudentInvitationContainer;
