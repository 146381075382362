import styled from 'styled-components';

export const StudentDropdownItemContainer = styled.button`

    align-items: center;
    background-color: #fff;
    border: none;
    outline: none;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    &.is-last {
      padding-bottom: 0;
    }

    :focus,
    :active {
      outline: none;
    }

    .students-dropdown-details {
      align-items: center;
      display: flex;
     
      .all-students-image {
        height: 2rem !important;
        margin-right: 0.562rem !important;
        width: 2rem !important;
      }

      div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        .student-name {
          color: ${({theme}) => theme.colors.text}};
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.21;
        }

        .reference-id {
          color: #aaa;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.21;
          margin-bottom: 0;
          margin-top: 0.20rem !important;
        }
      }
    }

    .students-dropdown-checkbox {
        border: 0.0625rem solid #ccc;
        border-radius: 0.625rem;
        display: flex;
        height: 1.187rem;
        overflow: hidden;
        width: 1.187rem;

        &.is-checked {
          border: none;
          height: 1.25rem !important;
          width: 1.25rem !important;
        }

        .checkbox-icon {
          height: 100% !important;
          width: 100% !important;
        }
      }


  }`;
