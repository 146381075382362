import PropTypes from 'prop-types';

const StudentToast = ({firstname, lastname, avatar, action}) => (
  <div className="toast-body">
    <span className="student-avatar">
      {avatar ? (
        <img src={avatar} alt="profile" />
      ) : (
        `${firstname[0]} ${lastname[0]}`
      )}
    </span>
    <span>
      <strong>{`${firstname} ${lastname} `}</strong>
      {action}
    </span>
  </div>
);

StudentToast.defaultProps = {
  firstname: '',
  lastname: '',
  avatar: '',
  action: 'joined',
};

StudentToast.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  avatar: PropTypes.string,
  action: PropTypes.string,
};

export default StudentToast;
