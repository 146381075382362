import styled from 'styled-components';
import {AdminListingPageContainer} from '../../../../../../../elements-new';

export const AssessmentSummaryContainer = styled(AdminListingPageContainer)`
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #40a1ff;
    }
    .seperator {
      margin-top: 0.5rem;
      width: 4.5rem;
      height: 0.125rem;
      background-color: #40a1ff;
    }
    .button-container {
      display: flex;
    }
  }
  .action-btn {
    background: transparent;
    background-color: #ffffff;
    padding: 0.5rem;
    border: 0.0625rem solid #dedede;
    border-radius: 0.312rem;
    outline: none;
    img {
      object-fit: contain;
      vertical-align: middle;
    }
  }
  .back-button {
    display: flex;
    margin-bottom: 1.5rem;
    img {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.1275rem;
      vertical-align: middle;
    }

    span {
      font-size: 0.875rem;
      color: #8a8c94;
      margin-left: 0.6875rem;
    }
  }
  .overview-data {
    background-color: #ffffff;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .overview-left {
      .assessment-title {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        color: #3d4457;
      }
      .time {
        font-weight: normal;
        font-size: 0.875rem;
        color: #8a8c94;
      }
    }

    .overview-right {
      display: flex;
      flex-direction: row;

      .teacher-info {
        margin-right: 1rem;
        .teacher-name {
          font-weight: 500;
          font-size: 1rem;
          color: #3d4457;
          text-align: right;
        }
        .created-by {
          font-size: 0.875rem;
          line-height: 1.31rem;
          text-align: right;
          color: #8a8c94;
          margin-bottom: unset;
        }
      }
      .firstlast-teacher {
        background: #ecf6ff;
        border-radius: 0.75rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .teacher-logo {
          font-weight: 500;
          font-size: 1rem;
          color: #3c97f0;
        }
      }
    }
  }
  @media (max-width: 25rem) {
    .overview-data .overview-right {
      display: flex;
      flex-direction: column-reverse;
    }
    .overview-data .overview-right .teacher-info {
      margin-right: unset;
      .created-by {
        margin-top: 0.5rem;
        text-align: left;
      }
    }
  }
`;
