/* eslint-disable react/prop-types */

import moment from 'moment';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import documentThumbnail from '../../../../../../assets/icons/document-thumbnail.svg';
import profileIcon from '../../../../../../assets/icons/profile.svg';
import rem from '../../../../../../utils/rem';
import {fetchAssessmentTasksOfStudent} from '../../Assessment/slices/assessmentSlice';
import {
  StudentAssessmentEvaluationContainer,
  StudentAssessmentEvaluationTaskContainer,
} from '../Elements/StudentAssessmentEvaluationContainer';
import EvaluationStatus from './EvaluationStatus';

// const gradeColors = {
//   A: '#2ec973',
//   B: '#8bc94e',
//   C: '#f6d519',
//   D: '#f6aa63',
//   F: '#ff5a5f',
// };

// const gradeLightColors = {
//   A: '#5dde8b',
//   B: '#9be057',
//   C: '#fcde3e',
//   D: '#ffc085',
//   F: '#ff9c9f',
// };

const StudentAssessmentEvaluation = () => {
  const dispatch = useDispatch();
  const {assessmentTasks, assessmentTasksError, assessmentTasksLoading} =
    useSelector(state => state.assessment);
  const history = useHistory();
  const {assessmentID, studentID} = useParams();
  const {studentName, studentSubmissionTimestamp} = history.location.state;

  useEffect(() => {
    if (assessmentID) {
      dispatch(fetchAssessmentTasksOfStudent(assessmentID, studentID));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentID]);

  const isPageLoading = assessmentTasksLoading || assessmentTasksError;

  if (isPageLoading) return null;

  return (
    <>
      <Helmet>
        <title>{studentName} | JungleCat</title>
      </Helmet>
      <StudentAssessmentEvaluationContainer className="student-task-evaluation">
        <header>
          <div className="left">
            <div className="back-btn-hide">
              <button onClick={() => history.goBack()} type="button">
                Back to Students List
              </button>
            </div>
            <div className="profile-details">
              <img alt="Profile" src={profileIcon} />
              <div>
                {!isPageLoading ? (
                  <p
                    className="name"
                    style={
                      studentSubmissionTimestamp
                        ? {
                            marginBottom: '6px',
                          }
                        : {
                            marginBottom: '0',
                          }
                    }
                  >
                    {studentName}
                  </p>
                ) : (
                  <Skeleton
                    height={rem(23)}
                    style={{
                      display: 'block',
                      marginBottom: rem(6),
                    }}
                  />
                )}
                {studentSubmissionTimestamp ? (
                  <p className="submission-date">
                    {moment(studentSubmissionTimestamp).format('MMM D, H:mm A')}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="back-btn">
            <button onClick={() => history.goBack()} type="button">
              Back to Students List
            </button>
          </div>
        </header>
        {assessmentTasks?.map(task => {
          const submission = task.submissions.find(
            item => item.ais_id_userprofile_student === studentID,
          );

          return (
            <StudentAssessmentEvaluationTaskContainer
              key={task.ai_id_activityitem}
            >
              <div className="title">
                <div className="text">
                  <h4>{task.ai_activityitem}</h4>
                </div>
              </div>
              <div className="content">
                <p>{task.ai_activityitem_description}</p>
                <EvaluationStatus
                  status={submission?.etv_type_value}
                  criteria={task.performanceCriteria.tpc_criteria_value}
                />
                {/* ))} */}
                {task.linkedFiles?.length ? (
                  <div className="assessment-task-files">
                    <div className="file-list">
                      {task.linkedFiles?.map(file => (
                        <button
                          onClick={() =>
                            history.push(
                              `${history.location.pathname}/task/${task.ai_id_activityitem}`,
                            )
                          }
                          type="button"
                        >
                          {' '}
                          <img
                            alt={file.fi_filename}
                            key={file.fi_id_file}
                            src={
                              [3, 5].includes(file.fi_id_filetype)
                                ? documentThumbnail
                                : file?.fi_thumbnail
                            }
                            style={{
                              objectFit: 'cover',
                            }}
                          />
                        </button>
                      ))}
                    </div>
                    {/* {submission ? (
                      <div className="btn-div">
                        <button
                          className="submitted-button"
                          onClick={() =>
                            history.push(
                              `${history.location.pathname}/task/${task.ai_id_activityitem}`,
                            )
                          }
                          style={{
                            backgroundColor: gradeColors[submission.ais_grade],
                          }}
                          type="button"
                        >
                          <div>
                            <span className="marks">
                              {submission.ais_marks}% Scored
                            </span>
                          </div>
                          <div>
                            <span
                              className="grade"
                              style={{
                                backgroundColor:
                                  gradeLightColors[submission.ais_grade],
                              }}
                            >
                              {submission.ais_grade}
                            </span>
                          </div>
                        </button>
                      </div>
                    ) : ( */}
                    <div className="btn-div">
                      <button
                        className="evaluate-button"
                        onClick={() =>
                          history.push(
                            `${history.location.pathname}/task/${task.ai_id_activityitem}`,
                          )
                        }
                        type="button"
                      >
                        {submission ? 'View Task' : 'Evaluate Task'}
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                ) : null}
              </div>
            </StudentAssessmentEvaluationTaskContainer>
          );
        })}
      </StudentAssessmentEvaluationContainer>
    </>
  );
};

export default StudentAssessmentEvaluation;
