import {createSlice} from '@reduxjs/toolkit';

import * as organisationApi from '../api/admin/basicInfo';

const basicSlice = createSlice({
  initialState: {
    error: null,
    isLoading: false,
    isLogoLoading: false,
    basicInfo: null,
  },
  name: 'basicInfo',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLogoLoading: (state, action) => {
      state.isLogoLoading = action.payload;
    },
    setBasicInfo(state, action) {
      state.basicInfo = action.payload;
    },
  },
});

export const {setError, setIsLoading, setBasicInfo, setIsLogoLoading} =
  basicSlice.actions;

export const fetchBasicInfo = () => async dispatch => {
  try {
    const response = await organisationApi.fetchBasicInfo();
    dispatch(setIsLoading(false));
    dispatch(setBasicInfo(response.data.orgDetail));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateBasicInfo = (data, callback) => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const formData = new FormData();
    formData.append('orgname', data.organisationName);
    formData.append('address', data.address);
    formData.append('logo', data.upload);
    if (data.upload) {
      dispatch(setIsLogoLoading(true));
    }
    const response = await organisationApi.updateBasicInfo(formData);

    dispatch(setBasicInfo(response.data.basicInfo));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
    dispatch(setIsLogoLoading(false));
  }
};

export const deleteOrganisationLogo = () => async dispatch => {
  dispatch(setIsLogoLoading(true));
  try {
    const response = await organisationApi.deleteOrganisationLogo();
    dispatch(setBasicInfo(response.data.basicInfo));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLogoLoading(false));
  }
};

export const basicInfoSelector = state => state.basicInfo;

export default basicSlice.reducer;
