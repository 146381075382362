import axios from '../index';

export const fetchAllPortfolioFiles = (username, academicYearId) =>
  axios.get(
    `/api/class/portfolio-files/${username}/?academicYearId=${academicYearId}`,
  );

export const fetchEnrolledQualification = username =>
  axios.get(`/api/class/enrolled-qualification/${username}`);

export const fetchAcademicYears = username =>
  axios.get(`/api/class/academic_year/${username}`);
