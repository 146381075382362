/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import classNames from 'classnames';
import {Button} from 'react-bootstrap';
import InstructionsActiveIcon from '../../../../../../../../assets/icons/instruction-active.svg';
import ArrowHideImg from '../../../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../../../assets/icons/tableshow.svg';

import {
  DisplayModal,
  Instruction,
  AudioModal,
  VideoModal,
  TextModal,
} from '../../../../../../Components';

import {AssessmentTaskContainer} from './elements';

export const AssessmentTask = ({task, position, key, assessmentStatus}) => {
  const [classID, setClassID] = useState(null);

  const history = useHistory();
  const [instructions, setInstructions] = useState([]);
  const [submission, setSubmission] = useState([]);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [audioModalVisibility, setAudioModalVisibility] = useState(false);
  const [textModalVisibility, setTextModalVisibility] = useState(false);
  const [audioShowData, setAudioShowData] = useState(null);
  const [textShowData, setTextShowData] = useState(null);
  const [displayModalVisibility, setDisplayModalVisibility] = useState(false);
  const [displayShowData, setDisplayShowData] = useState(null);
  const [videoModalVisibility, setVideoModalVisibility] = useState(false);
  const [videoShowData, setVideoShowData] = useState(null);

  const {pathname} = history.location;

  useEffect(() => {
    if (/stream/.test(pathname)) {
      const idOfClass = pathname.split('/')[2];
      setClassID(idOfClass);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleInstructions = () => setInstructionsOpen(!instructionsOpen);

  useEffect(() => {
    const files = task?.linkedFiles;

    if (files && files?.length) {
      setInstructions(
        files.map(item => {
          const file = item.fi_id_filetype ? item : item.file;
          switch (file.fi_id_filetype) {
            case 1:
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'image',
                title: file.fi_filename,
                uploadDate: file.fi_uploadedate,
              };
            case 2:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                thumbnail: file.fi_thumbnail,
                type: 'video',
                title: file.fi_filename,
                uploadDate: file.fi_uploadedate,
              };
            case 3:
              return {
                id: file.fl_id_filelink,
                text: file.fi_text_content,
                type: 'text',
                title: file.fi_filename,
                uploadDate: file.fi_uploadedate,
              };
            case 5: {
              const split = file.fi_location.split('.');
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'document',
                title: file.fi_filename,
                pdf: split[split.length - 1] === 'pdf',
                uploadDate: file.fi_uploadedate,
              };
            }
            case 7:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                title: file.fi_filename,
                type: 'audio',
                uploadDate: file.fi_uploadedate,
              };
            default:
              return null;
          }
        }),
      );
    }
  }, [task.linkedFiles]);
  useEffect(() => {
    const files = task?.fileSubmissions;

    if (files && files?.length) {
      setSubmission(
        files.map(item => {
          const file = item.fi_id_filetype ? item : item.file;
          switch (file.fi_id_filetype) {
            case 1:
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'image',
                title: file.fi_filename,
                uploadDate: file.fi_uploadedate,
              };
            case 2:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                thumbnail: file.fi_thumbnail,
                type: 'video',
                title: file.fi_filename,
                uploadDate: file.fi_uploadedate,
              };
            case 3:
              return {
                id: file.fl_id_filelink,
                text: file.fi_text_content,
                type: 'text',
                title: file.fi_filename,
                uploadDate: file.fi_uploadedate,
              };
            case 5: {
              const split = file.fi_location.split('.');
              return {
                duration: file.fi_filesize,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                type: 'document',
                title: file.fi_filename,
                pdf: split[split.length - 1] === 'pdf',
                uploadDate: file.fi_uploadedate,
              };
            }
            case 7:
              return {
                duration: file.fi_duration,
                id: file.fl_id_filelink,
                media: file.fi_location,
                text: file.fi_text_content,
                title: file.fi_filename,
                type: 'audio',
                uploadDate: file.fi_uploadedate,
              };
            default:
              return null;
          }
        }),
      );
    }
  }, [task.fileSubmissions]);

  return (
    <AssessmentTaskContainer>
      {displayModalVisibility ? (
        <DisplayModal
          onClose={() => {
            setDisplayModalVisibility(false);
            setDisplayShowData(null);
          }}
          showData={displayShowData}
        />
      ) : null}
      {videoModalVisibility ? (
        <VideoModal
          onClose={() => {
            setVideoModalVisibility(false);
            setVideoShowData(null);
          }}
          showData={videoShowData}
        />
      ) : null}
      {audioModalVisibility ? (
        <AudioModal
          onClose={() => {
            setAudioModalVisibility(false);
            setAudioShowData(null);
          }}
          showData={audioShowData}
        />
      ) : null}
      {textModalVisibility ? (
        <TextModal
          onClose={() => {
            setTextModalVisibility(false);
            setTextShowData(null);
          }}
          showData={textShowData}
        />
      ) : null}
      <div className="assessment-task-details" key={key}>
        <div className="assessment-task-meta">
          <div className="badge-container">
            <h4 className="assessment-task-title">
              <span className="position">{position}</span>
              {task?.ai_activityitem}
            </h4>
          </div>
          <p>{task?.ai_activityitem_description}</p>
        </div>
      </div>

      {instructions?.length ? (
        <div className="added-task-instructions">
          <div className="accordion" id="accordionExample">
            <div className="card">
              <div
                className={classNames('card-header', {
                  clicked: instructionsOpen,
                })}
                id="headingOne"
              >
                <h2 className="mb-0">
                  <button
                    className="btn btn-block d-flex justify-content-between align-items-center"
                    type="button"
                    onClick={toggleInstructions}
                  >
                    <div className="accordion-header">
                      <img
                        alt="instructions"
                        className="resource"
                        src={InstructionsActiveIcon}
                      />
                      {instructions?.length} Instructions Attached
                    </div>
                    <div>
                      <img
                        alt="Show Works"
                        className="arrow"
                        src={instructionsOpen ? ArrowShowImg : ArrowHideImg}
                      />
                    </div>
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                className={classNames('collapse', {
                  show: instructionsOpen,
                })}
              >
                <div className="card-body">
                  {instructions.map((instruction, index) => (
                    <div className="instruction-container">
                      <div className="data-container">
                        <Instruction
                          data={instruction}
                          index={index + 1}
                          key={instruction?.text}
                          onShowClick={() => {
                            if (instruction?.type === 'audio') {
                              setAudioShowData(instruction);
                              setAudioModalVisibility(true);
                            } else if (
                              (instruction?.type === 'document' &&
                                instruction?.pdf) ||
                              instruction?.type === 'image'
                            ) {
                              setDisplayShowData(instruction);
                              setDisplayModalVisibility(true);
                            } else if (instruction?.type === 'video') {
                              setVideoShowData(instruction);
                              setVideoModalVisibility(true);
                            } else if (instruction?.type === 'text') {
                              setTextShowData(instruction);
                              setTextModalVisibility(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {submission.length ? (
        <div className="submissions">
          <div className="submission-header">Submissions</div>
          {submission.map((file, index) => (
            <div className="submission-container">
              <div className="data-container">
                <Instruction
                  data={file}
                  isSubmission
                  index={index + 1}
                  key={file?.text}
                  onShowClick={() => {
                    if (file?.type === 'audio') {
                      setAudioShowData(file);
                      setAudioModalVisibility(true);
                    } else if (
                      (file?.type === 'document' && file?.pdf) ||
                      file?.type === 'image'
                    ) {
                      setDisplayShowData(file);
                      setDisplayModalVisibility(true);
                    } else if (file?.type === 'video') {
                      setVideoShowData(file);
                      setVideoModalVisibility(true);
                    } else if (file?.type === 'text') {
                      setTextShowData(file);
                      setTextModalVisibility(true);
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {assessmentStatus !== 2 &&
      assessmentStatus !== 5 &&
      assessmentStatus !== 3 ? (
        <>
          <div className="seperator" />
          <div className="btn-container">
            <Button
              variant="outline-primary"
              className="ml-auto add-file-btn"
              onClick={() => {
                history.push(
                  `/stream/${classID}/student/assessment/${task?.ai_id_activity}/task/${task?.ai_id_activityitem}`,
                );
              }}
            >
              {submission.length ? 'Add More Files' : 'Add Files'}
            </Button>
          </div>
        </>
      ) : null}
    </AssessmentTaskContainer>
  );
};
