import styled from 'styled-components';

export const OrganisationCardContainer = styled.div`
  .organisationcard {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 0.25rem;
    padding: 0 1.5rem;
    margin-top: 1.5rem;
  }
  .organisationcard-top {
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    width: 100%;
    padding: 1.5rem 0;
    border-bottom: 1px solid #e6e7e8;
    img {
      object-fit: contain;
    }
  }

  .organisationcard-bottom {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;

    span {
      font-weight: 600;
      font-size: 1.125rem;
      color: #0c181f;
    }
    p {
      font-weight: 500;
      font-size: 0.875rem;
      color: #9ea3a5;
      margin-bottom: unset;
    }
  }
`;
