/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useState, useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import {Tab, Tabs} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import TabTitle from '../../../../../../../../components/shared/TabTitle';
import {MainContainer} from './elements';
import StudentBlackImg from '../../../../../../../../assets/icons/student-black.svg';
import StudentBlueImg from '../../../../../../../../assets/icons/student-blue.svg';
import TecaherBlackImg from '../../../../../../../../assets/icons/teacher-black.svg';
import TeacherBlueImg from '../../../../../../../../assets/icons/teacher-blue.svg';
import UnitBlackImg from '../../../../../../../../assets/icons/units-black.svg';
import UnitBlueImg from '../../../../../../../../assets/icons/units-blue.svg';
import TabsContainer from '../../../../../../../../components/shared/TabsContainer';
import {Units, Students, Teachers} from './components';
import {
  classesSelector,
  getClassById,
} from '../../../../../../../../redux/classesSlice';

export const ClassTables = () => {
  const {isSingleClassLoading, singleClass} = useSelector(classesSelector);
  const {orgClassId} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClassById(orgClassId));
  }, []);

  const orgUnitInstance = useMemo(
    () => singleClass?.orgUnitInstance,
    [singleClass],
  );
  const orgClassStudents = useMemo(
    () => singleClass?.orgStudents,
    [singleClass],
  );
  const classTeachers = useMemo(
    () => singleClass?.classTeachers,
    [singleClass],
  );

  const tabs = useMemo(
    () => [
      {
        activeIcon: UnitBlueImg,
        icon: UnitBlackImg,
        key: 'Units',
        itemCount: String(singleClass?.orgUnitInstance?.length).padStart(
          2,
          '0',
        ),
        title: 'Units',
        component: () => <Units orgUnitInstance={orgUnitInstance} />,
      },
      {
        activeIcon: StudentBlueImg,
        icon: StudentBlackImg,
        itemCount: String(singleClass?.orgStudents?.length).padStart(2, '0'),
        key: 'Students',
        title: 'Students',
        component: () => <Students orgClassStudents={orgClassStudents} />,
      },
      {
        activeIcon: TeacherBlueImg,
        icon: TecaherBlackImg,
        itemCount: String(singleClass?.classTeachers?.length).padStart(2, '0'),
        key: 'Teachers',
        title: 'Teachers',
        component: () => <Teachers classTeachers={classTeachers} />,
      },
    ],
    [singleClass],
  );
  const [key, setKey] = useState(tabs[0].key);
  return (
    <MainContainer>
      {isSingleClassLoading ? (
        <>
          <Skeleton className="mb-3" height="1.31rem" width="3.56rem" />
          <Skeleton height="6.06rem" width="100%" />
        </>
      ) : (
        <>
          <TabsContainer>
            <Tabs activeKey={key} onSelect={setKey}>
              {tabs.map(tab => (
                <Tab
                  eventKey={tab.key}
                  key={tab.key}
                  title={
                    <TabTitle
                      icon={key === tab.key ? tab.activeIcon : tab.icon}
                      isActive={key === tab.key}
                      itemCount={tab.itemCount}
                      title={tab.title}
                    />
                  }
                >
                  {tab.component()}
                </Tab>
              ))}
            </Tabs>
          </TabsContainer>
        </>
      )}
    </MainContainer>
  );
};
