import PropTypes from 'prop-types';
import moment from 'moment';
import {useMemo} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import SessionMenuIcon from '../../../../../../../assets/icons/session-menu.svg';
import {Color} from '../../../../../../../constants';

const SessionContainer = styled.div`
  .session-thumbnail {
    border-radius: 0.25rem;
    height: 2.5rem;
    object-fit: cover;
    width: 2.5rem;
  }

  .session-title {
    color: ${Color.PRIMARY_TEXT};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.0625rem;
  }

  .session-information {
    color: #8a8c94;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5;
  }

  .session-menu {
    background-color: transparent;
    border: none;
    height: 1rem;
    width: 1rem;

    :focus {
      outline: none;
    }

    img {
      height: 100%;
      object-fit: contain;
      vertical-align: unset;
      width: 100%;
    }
  }
`;

const Session = ({session}) => {
  // Cache the formatted date of the session.
  const sessionDate = useMemo(
    () => moment(session.date).format('D MMM.'),
    [session],
  );
  return (
    <SessionContainer className="align-items-center d-flex justify-content-between pb-3">
      <Link className="align-items-center d-flex" to="/">
        <img
          alt={session.title}
          className="mr-2 session-thumbnail"
          src={session.thumbnail}
        />
        <div className="d-flex flex-column">
          <h4 className="session-title">{session.title}</h4>
          <span className="session-information">
            {sessionDate} • {session.duration}
          </span>
        </div>
      </Link>
      <button className="d-flex session-menu" type="button">
        <img alt="Menu" src={SessionMenuIcon} />
      </button>
    </SessionContainer>
  );
};

Session.propTypes = {
  session: PropTypes.shape({
    date: PropTypes.instanceOf(Date).isRequired,
    duration: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default Session;
