import React from 'react';
import {Helmet} from 'react-helmet';
import './App.css';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import ActiveClasses from './pages/ActiveClasses';
import AwaitingAssessment from './pages/AwaitingAssesment';
import Stream from './pages/Stream';
import Student from './pages/Student';
import Admin from './pages/Admin';
import {AllNotification} from './pages/Notification';
import ForgotPasswordOld from './pages/ForgotPasswordOld';
import ResetPassword from './pages/PasswordReset';
// import RequestCode from './pages/RequestCode';
import Signup from './pages/SignupOld';
import ActivateUser from './pages/ActivateUser';
import ActivateFreeAccount from './pages/ActivateFreeAccount';
import StudentInvitation from './pages/StudentInvitation';
import AdminTeacherAcceptInvitation from './pages/Admin/Components/AdminTeacherInvitation';
import AdminAcceptInvitation from './pages/Admin/Components/AdminInvitation';
import TeacherAcceptInvitation from './pages/Admin/Components/TeacherInvitation';
import StudentAcceptInvitation from './pages/Admin/Components/StudentInvitation';
import SuccessTeacherAcceptInvitation from './pages/Admin/Components/TeacherInvitation/SuccessMessage';
import SuccessStudentAcceptInvitation from './pages/Admin/Components/StudentInvitation/SuccessMessage';
import StudentInvitationAccepted from './pages/StudentInvitation/StudentInvitationAccepted';
import {PrivateRoute, LoginRoute} from './PrivateRoute';
import {ForgotPassword, Login, Register, Join} from './pages/Auth/pages';
import {JoinSuccessMessage} from './pages/Auth/pages/Join/components';
import {Portfolio} from './pages/Portfolio';
import NotFound from './pages/NotFoundPage';
import {SuperAdminLogin} from './pages/Auth/pages/SuperAdminLogin';

function App() {
  return (
    <>
      <Helmet>
        <title>JungleCat</title>
      </Helmet>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
              const userType = localStorage.getItem('jc-user-type');
              if (userType === '4') {
                return <Redirect to="/admin" />;
              }
              return <Redirect to="/classes" />;
            }}
          />
          <Route exact path="/accept-invite" component={StudentInvitation} />
          <Route
            exact
            path="/admin-accept-invite"
            render={() => <AdminAcceptInvitation />}
          />
          <Route
            exact
            path="/adminTeacher-accept-invite"
            render={() => <AdminTeacherAcceptInvitation />}
          />
          <Route
            exact
            path="/teacher-accept-invite"
            render={() => <TeacherAcceptInvitation />}
          />
          <Route
            exact
            path="/student-accept-invite"
            render={() => <StudentAcceptInvitation />}
          />
          <Route
            exact
            path="/teacher-accept-invite-success"
            render={() => <SuccessTeacherAcceptInvitation />}
          />
          <Route
            exact
            path="/student-accept-invite-success"
            render={() => <SuccessStudentAcceptInvitation />}
          />
          <Route
            exact
            path="/invite-accepted"
            component={StudentInvitationAccepted}
          />
          <Route exact path="/PageNotFound" component={NotFound} />
          {/* <LoginRoute path="/request-code" component={RequestCode} /> */}
          <LoginRoute path="/activate-user" component={ActivateUser} />
          <LoginRoute path="/password-reset" component={ResetPassword} />
          <PrivateRoute path="/classes" component={ActiveClasses} isTeacher />

          <PrivateRoute path="/admin" component={Admin} isAdmin />
          <PrivateRoute
            path="/all-notification"
            exact
            component={AllNotification}
          />
          <PrivateRoute
            path="/portfolio/:username"
            exact
            component={Portfolio}
          />
          <PrivateRoute
            exact
            path="/awaiting_assessments"
            component={AwaitingAssessment}
          />
          <PrivateRoute
            path="/stream/:id/student"
            component={Student}
            isTeacher
          />
          <PrivateRoute path="/stream/:id" component={Stream} isTeacher />

          <LoginRoute component={ForgotPassword} path="/forgot-password" />
          <LoginRoute component={Login} path="/login" />

          <LoginRoute
            component={ForgotPasswordOld}
            path="/reset-password-confirmation"
          />
          <LoginRoute component={Register} path="/register" />
          <LoginRoute component={Signup} path="/register-success" />

          <LoginRoute component={Join} path="/join" />
          <LoginRoute component={JoinSuccessMessage} path="/join-success" />
          <LoginRoute
            path="/activate-free-account"
            component={ActivateFreeAccount}
          />
          <LoginRoute component={SuperAdminLogin} path="/super-login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
