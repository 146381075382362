/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import profileIcon from '../../../../assets/icons/profile.svg';

import {StudentContainer, TableStyles} from './elements';
import {Table} from '../../../../components/common/Table';
import {streamSelector} from '../../../../redux/streamSlice';
import {TableSpinner} from '../../../Admin/components-new';
import EmptyState from '../../../../components/shared/EmptyState';
import ViewClassImg from '../../../../assets/icons/viewclass.svg';
import {TableCellLink} from '../../../../components/common/Table/styles';
import InfoIcon from '../../../../assets/icons/info.svg';

const Students = ({students}) => {
  const {isListLoading} = useSelector(streamSelector);
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'ID',
        accessor: 'up_org_reference',
        className: 'ref-id',
      },
      {
        Header: 'Student',
        Cell: ({row: {original}}) => {
          const firstName = original?.up_name_first;
          const lastName = original?.up_name_last;
          const profileImg = original?.up_avatar;
          return (
            <div>
              <img
                alt={`${firstName} ${lastName}`}
                className="student-profile-picture rounded-circle"
                src={profileImg || profileIcon}
              />
              <span>
                {firstName} {lastName}
              </span>
            </div>
          );
        },
        accessor: 'up_name_first',
        className: 'fullname',
      },
      {
        Header: 'Status',
        Cell: ({row: {original}}) => {
          const invitationStatus = original?.up_invitation_status;
          return (
            <div className={`invitation-status-${invitationStatus}`}>
              {invitationStatus === 1 ? 'Active' : 'Invitation Sent'}
            </div>
          );
        },
        accessor: 'up_invitation_status',
        className: 'status',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="view-profile-div">
            {original?.up_invitation_status === 1 ? (
              <TableCellLink
                onClick={() => {
                  history.push(`/portfolio/${original?.us_profile_name}`);
                }}
                type="button"
                className="view-profile"
                id="view-profile"
              >
                <span>View Profile</span>
                <img src={ViewClassImg} alt="loader" className="view-arrow" />
              </TableCellLink>
            ) : (
              <TableCellLink
                // onClick={() => {
                //   history.push(`/portfolio/${original?.us_profile_name}`);
                // }}
                type="button"
                className="view-profile"
                id="no-profile"
              >
                <div
                  className="invited"
                  data-html
                  data-for="tooltip"
                  data-tip={`<div class="tooltip-values">Profile cannot be viewed : User not in active status </div>`}
                >
                  <span>No Profile</span>
                  <img
                    alt="Failed"
                    className="ml-2 status-icon"
                    src={InfoIcon}
                  />
                  <ReactTooltip
                    backgroundColor="#3d4457"
                    effect="solid"
                    place="left"
                    id="tooltip"
                  />
                </div>
              </TableCellLink>
            )}
          </div>
        ),
        accessor: 'up_profile_name',
        className: 'view',
      },
    ],
    [history],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...students.map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [students],
  );

  return (
    <>
      <Helmet>
        <title>Students | JungleCat</title>
      </Helmet>
      <div
        className="row position-relative"
        // style={{
        //   backgroundColor: '#f4f5f8',
        //   minHeight: 'calc(100vh - 80px)',
        //   paddingBottom: '24px',
        // }}
      >
        <StudentContainer>
          <div className="student-container">
            <div className="align-items-center d-flex justify-content-between mb-4">
              <h2>Students</h2>
            </div>
            {isListLoading ? <TableSpinner /> : null}
            {!isListLoading && Array.isArray(students) && !students?.length ? (
              <EmptyState
                body="It seems that there is no students has been added to this class"
                title="No students are added yet!"
              />
            ) : null}
            {!isListLoading && students?.length > 0 ? (
              <TableStyles>
                <Table
                  columns={columns}
                  data={data}
                  itemName="Students"
                  maxWidth="45rem"
                  isSortedBy
                  globalFilterId="search-students"
                />
              </TableStyles>
            ) : null}
          </div>
        </StudentContainer>
      </div>
    </>
  );
};

export default Students;

Students.propTypes = {
  students: PropTypes.arrayOf(PropTypes.shape()),
};

Students.defaultProps = {
  students: [],
};
