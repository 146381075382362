import moment from 'moment';
import PropTypes from 'prop-types';
import {useMemo} from 'react';
import SessionBarContainer, {
  SessionBarLessThanHourContainer,
} from '../elements/SessionBarContainer';

const SessionBar = ({from, title, to}) => {
  const fromTime = useMemo(
    () => moment(from, 'HH:mm').format('hh:mm A'),
    [from],
  );
  const toTime = useMemo(() => moment(to, 'HH:mm').format('hh:mm A'), [to]);

  const topOffset = useMemo(() => {
    const [hoursToStart, minutesToStart] = from.split(':').map(Number);
    return hoursToStart * 70 + minutesToStart + 33;
  }, [from]);

  const barHeight = useMemo(() => {
    const [hoursToStop, minutesToStop] = to.split(':').map(Number);
    return hoursToStop * 70 + minutesToStop + 33 - topOffset;
  }, [topOffset, to]);

  if (barHeight < 70) {
    return (
      <SessionBarLessThanHourContainer
        className="position-absolute d-flex"
        height={barHeight}
        top={topOffset}
      >
        <div className="d-flex align-items-center">
          <span className="time-interval mb-0">
            {fromTime} - {toTime}
          </span>
        </div>
        <div className="d-flex align-items-center">
          <h5
            className="session-title mb-0 ml-2 d-inline-block text-truncate"
            style={{maxWidth: '150px'}}
            title={title}
          >
            {title}
          </h5>
        </div>
      </SessionBarLessThanHourContainer>
    );
  }
  return (
    <SessionBarContainer
      className="position-absolute"
      height={barHeight}
      top={topOffset}
    >
      <span className="time-interval">
        {fromTime} - {toTime}
      </span>
      <h5 className="session-title">{title}</h5>
    </SessionBarContainer>
  );
};

SessionBar.propTypes = {
  from: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default SessionBar;
