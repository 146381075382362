import axios from '../..';

export const fetchAllClasses = (academicYearId, qualificationId) =>
  axios.get(
    `api/admin/classes?academicYearId=${academicYearId || ''}&qualificationId=${
      qualificationId || ''
    }`,
  );

export const fetchAllFilterAcademicYears = () =>
  axios.get('api/admin/classes/add-class/academic-year');

export const fetchAllFilterCourses = () =>
  axios.get('api/admin/classes/add-class/all-courses');

export const fetchUnitsCount = () => axios.get('api/admin/classes/units-count');

export const updateClass = (orgClassId, data) =>
  axios.patch(`api/admin/classes/edit-class/${orgClassId}`, data);

export const deleteClass = orgClassId =>
  axios.delete(`api/admin/classes/delete-class/${orgClassId}`);

export const fetchAllTeachers = () =>
  axios.get(`api/admin/units/add-unit/teachers`);

// View Class
export const fetchClassById = orgClassId =>
  axios.get(`api/admin/classes/view-class/${orgClassId}`);

export const revokeStudentFromClass = orgClassStudentId =>
  axios.delete(
    `api/admin/classes/view-class/student-revoke/${orgClassStudentId}`,
  );

export const fetchAllAssessments = orgUnitInstanceId =>
  axios.get(
    `api/admin/classes/view-class/assessments-list/${orgUnitInstanceId}`,
  );
export const fetchAssessmentSummary = activityId =>
  axios.get(
    `api/admin/classes/view-class/assessments-list/${activityId}/getSummaryDetails`,
  );
// manage Units
export const fetchAllUnitsTeachers = () =>
  axios.get('api/admin/units/add-unit/teachers');

export const fetchUnitsByType = (orgClassId, type) =>
  axios.post(`api/admin/classes/manage-unit/unit-type/${orgClassId}`, type);

export const manageUnits = (orgClassId, data) =>
  axios.patch(`api/admin/classes/view-class/${orgClassId}/manage-units`, data);

export const addClassStudentsToViewClass = (orgClassId, data) =>
  axios.post(`api/admin/classes/view-class/${orgClassId}/add-students`, data);

export const ViewClassStudentSpreadsheetValid = (orgClassId, data) =>
  axios.post(
    `api/admin/classes/view-class/${orgClassId}/add-students/studentSpreadsheetValidation`,
    data,
  );

// Add flow
export const addNewClass = data =>
  axios.post(`api/admin/classes/add-class/new-class`, data);

export const addUnitsClass = unitData =>
  axios.post(`api/admin/classes/add-class/new-class/add-units`, unitData);

export const fetchAllStudents = data =>
  axios.post(`api/admin/classes/add-class/new-class/org-student`, data);

export const addClassStudents = classData =>
  axios.post(
    `api/admin/classes/add-class/new-class/add-units/add-students`,
    classData,
  );

export const individualClassStudentSpreadsheetValid = data =>
  axios.post(
    `api/admin/classes/add-class/add-units/add-students/studentSpreadsheetValidation`,
    data,
  );
