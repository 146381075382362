import styled from 'styled-components';

export const EditFormContainer = styled.div`
  padding: 10rem 2rem 7.8125rem 2rem;
  overflow-y: auto;
  height: 100%;

  .add-document-fields {
    height: 100%;
  }
  .title-code {
    width: 100%;
    display: flex;
  }
  .code {
    width: 33%;
  }
  .document-title {
    width: 67%;
    margin-left: 1rem;
  }
  .category-label,
  label,
  .upload-title {
    color: #8a8c94 !important;
    font-size: 0.875rem;
    line-height: 1.05875rem;
    margin-top: 1.5rem;
  }
  .upload-cover-image {
    margin-top: 1rem;
  }
`;
