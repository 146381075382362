import styled from 'styled-components';

export const FilterFilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.1rem 0rem;
  align-items: center;
  width: 100%;
  .filter-head-left {
    width: 20%;
  }
  .filter-head-right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .class-search-responsive {
    display: none;
  }
  .class-search,
  .class-search-responsive {
    /* background-color: #f4f5f8; */
    /* margin-right: 1rem; */
    width: unset;
    input {
      /* background-color: #f4f5f8; */
    }
  }
  @media (max-width: 65rem) {
    .filter-fields {
      width: 80% !important;
    }
  }
  @media (max-width: 55rem) {
    & {
      flex-direction: column;
      align-items: flex-start;
    }

    .filter-head-left {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
  @media (max-width: 45rem) {
    .class-search {
      width: 40% !important;
    }
    .filter-head-right {
      display: flex;
      .filter-fields {
        width: 100% !important;
      }
      .catergory-filter {
        margin-right: unset !important;
      }
    }

    .filter-head-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .class-search {
      display: none !important;
    }
    .class-search-responsive {
      display: flex !important;
      width: 93%;
    }
  }
  @media (max-width: 28rem) {
    .filter-head-left {
      flex-direction: column;
      .files-count {
        margin-bottom: 1rem;
      }
      .class-search-responsive {
        width: 100%;
      }
    }
    .filter-head-right {
      .filter-fields {
        flex-direction: column !important;
      }
      .catergory-filter,
      .type-filter {
        width: 100%;
      }
      .catergory-filter {
        margin-top: 1rem;
      }
    }
    .class-search {
      display: none !important;
    }
    .class-search {
      width: 90% !important;
    }
  }

  .class-search {
    width: unset;
  }

  .files-count {
    font-weight: 500;
    font-size: 16px;
    width: 100%;
  }
  .filter-fields {
    display: flex;
    width: 50%;
  }
  .catergory-filter,
  .type-filter {
    width: 50%;
    margin-right: 1rem;
  }
`;
