import styled from 'styled-components';

export const AddSessionHeaderContainer = styled.div`
  .header-container {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 3rem 1.5rem 3rem;

    @media (max-width: 41.25rem) {
      & {
        width: 100%;
        padding: 1.5rem;
      }
    }
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .title {
      font-weight: 500;
      font-size: 1.5rem;
      color: #3d4457;
    }
  }
`;
