import styled from 'styled-components';

export const TeachersContainer = styled.div`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 4%;
  }
  .ref-id {
    width: 13%;
  }

  .first-name {
    width: 10%;
  }
  .last-name {
    width: 10%;
  }
  .email {
    width: 33%;
  }
  .units-count {
    width: 30%;
  }
  .table-container {
    border-radius: unset;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .table-container {
    table {
      tr {
        :hover {
          background-color: unset;
        }
      }
      td {
        :last-child {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .invited {
    color: #3d4457;
    margin-left: 0.5rem;
    border-radius: 0.312rem;
    overflow: hidden;
    padding: 0.4rem 1.25rem;
    height: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      background-color: #ebf5ff;
    }
    .heading {
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }
    li {
      margin-bottom: 0.5rem;
    }
  }
  .__react_component_tooltip {
    font-size: 14px;
    font-weight: normal;
    padding: 8px;
    color: #ffffff;
    border-radius: 5px;
    width: 13rem !important;
  }
`;
