import styled from 'styled-components';

import {Colors} from '../../../constants';

const SessionFormContainer = styled.div.attrs(() => ({
  className: 'col d-flex justify-content-center align-items-center',
}))`
  .title {
    color: ${Colors.Text};
    font-size: 32px;
    font-weight: 600;
    line-height: 1.2;
  }

  .subtitle {
    color: ${Colors.LightText};
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 32px;
  }

  .session-form {
    .label {
      color: ${Colors.Text};
      font-size: 14px;
      line-height: 1.3;
    }

    .input-container {
      border-color: #e0e0e0;
      border-radius: 8px;
      padding: 0 24px;
    }

    /* .text-input {
      height: 56px !important;
    } */
    .start-end-time {
      display: flex;
      justify-content: space-between;
    }
    .error-time {
      color: ${({theme}) => theme.colors.danger};
      font-size: 0.75rem;
    }

    .textarea {
      height: 128px !important;
      padding: 12px 16px 0 16px;
    }

    .textarea,
    .text-input {
      color: ${Colors.Text};
      font-size: 14px;
      height: unset;
      line-height: 1.3;

      ::placeholder {
        color: ${Colors.Placeholder};
      }
    }

    .date-picker-button {
      height: 20px;
      width: 20px;

      img {
        height: 100%;
        object-fit: contain;
        width: 100%;
        vertical-align: unset;
      }
    }

    .submit-button {
      height: 56px;
      width: 100%;
    }
  }
`;

export default SessionFormContainer;
