/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useRef, useState, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';

import ProfileImg from '../../../../../../assets/icons/profile.svg';
import ButtonSpinnerIcon from '../../../../../../assets/icons/button-spinner.svg';
import AttachmentImg from '../../../../../../assets/icons/attachment.svg';
import Textarea from '../../../../../../components/shared/Textarea';
import {getFileType} from '../../../../../../utils/fileTypes';

import {FilesCard} from '../../../../components';
import CustomButton from '../../../../../../components/shared/CustomButton';

import {
  streamSelector,
  createPost,
  updatePost,
  setIsFormModalOpen,
  setSelectedStudents,
  setStudentDropdownTitle,
} from '../../../../../../redux/streamSlice';

import {authSelector} from '../../../../../../redux/authSlice';
import {ClosedPostForm, StudentsDropdown} from './components';
import {SharePostContainer} from './elements';
import {showToast} from '../../../../../../components/common/Toast';
import UpdateIcon from '../../../../../../assets/icons/updateadmintoast.svg';

export const SharePost = ({
  cancelEditing,
  files,
  isEditing,
  onCancel,
  post,
  setIsModalOpen,
  isModalOpen,
}) => {
  const match = useRouteMatch();
  const {register, handleSubmit, setValue} = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  });
  const userType = useRef(Number(localStorage.getItem('jc-user-type'))).current;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDisabled, setPostDisabled] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [filesToRemove, setFilesToRemove] = useState([]);

  const dispatch = useDispatch();
  const {
    classStudents,
    selectedStudents,
    studentSelectDropDownTitle,
    isStreamLoading,
    isFormModalOpen,
    classDetails,
  } = useSelector(streamSelector);
  const auth = useSelector(authSelector);
  const students = useMemo(
    () =>
      classStudents?.map(student => ({
        id: student?.up_id_userprofile,
        referenceId: student?.up_org_reference,
        value: student
          ? `${student?.up_name_first} ${student?.up_name_last}`
          : null,
      })),
    [classStudents],
  );
  const formattedFiles = [];

  // Formatting files to match with fileType
  useEffect(() => {
    setSelectedFiles(formattedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!post) dispatch(setIsFormModalOpen(false));
  }, [dispatch, post]);

  useEffect(() => {
    if (post) {
      setValue('post_content', post.ac_activity_name);
    }
  }, [post, setValue]);

  useEffect(() => {
    if (files) {
      setUploadedFiles(
        files.map(file => {
          const fileName = file.fi_filename;
          const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);
          return {
            id: file.fi_id_file,
            name: file.fi_filename,
            size: file.fi_filesize,
            type: getFileType(fileExt),
            location: file.fi_location,
            thumbnail: file.fi_thumbnail,
            uploaded: true,
          };
        }),
      );
    }
  }, [files]);

  const classId = match.params?.id;

  // show/hide post box.
  const toggleShareBox = state => {
    if (cancelEditing) {
      cancelEditing();
    }
    if (setIsModalOpen) {
      setIsModalOpen(state);
    } else {
      dispatch(setIsFormModalOpen(state));
    }

    setSelectedFiles([]);
  };

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
    }

    if (post) {
      setTimeout(() => {
        toggleShareBox(false);
        setPostDisabled(true);
      }, 500);
    } else {
      toggleShareBox(false);
      setPostDisabled(true);
    }
  };

  // giving some property to selected files
  selectedFiles.forEach(file => {
    const fileName = file.name;
    const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);
    formattedFiles.push({
      name: file.name,
      size: file.size,
      type: getFileType(fileExt),
      location: null,
    });
  });

  const onStudentSelect = selectedItems => {
    dispatch(setSelectedStudents(selectedItems));
    let title = 'All Students';
    if (selectedItems.length !== classStudents?.length) {
      title = `${selectedItems.length} Students Selected`;
    }
    dispatch(setStudentDropdownTitle(title));
  };

  const onSubmit = data => {
    if (isDisabled || selectedStudents?.length === 0) {
      return;
    }
    setPostDisabled(true);
    const formData = new FormData();
    const studentsList =
      selectedStudents?.length === 0
        ? students?.map(stud => stud?.id)
        : selectedStudents;

    if (!post) {
      formData.append('post_content', data?.post_content);
      formData.append('unit_id', classId);
    }
    if (userType === 2) {
      formData.append('share_with', 'everyone');
    }

    studentsList?.forEach(stud => formData?.append('students', stud));
    selectedFiles?.forEach(file => formData?.append('files', file, file?.name));
    if (post) {
      const body = data?.post_content
        ? {
            content: data?.post_content,
          }
        : null;
      dispatch(
        updatePost(
          classId,
          post.ac_id_activity,
          body,
          filesToRemove?.length ? filesToRemove : null,
          selectedFiles?.length ? formData : null,
          post.aoui_id_orgunitinstance,
          post.aoui_id_activityorgunitinstance,
          studentsList?.length ? studentsList : null,
          userType,

          () => {
            showToast(UpdateIcon, 'Post updated successfully', false);
            onCancelClick();
          },
        ),
      );
    } else {
      dispatch(
        createPost(classId, formData, userType, () => {
          showToast(UpdateIcon, 'Post added successfully', false);
          setPostDisabled(false);
          setSelectedFiles([]);
        }),
      );
    }
  };

  const onFileChange = e => {
    setSelectedFiles(previousValue => [
      ...previousValue,
      ...Array.from(e.target.files),
    ]);

    if (post) {
      setUploadedFiles(previousValue => [
        ...previousValue,
        ...Array.from(e.target.files),
      ]);
      setPostDisabled(false);
    }
  };

  const onFileRemove = (deleteKey, fileID) => {
    if (uploadedFiles?.length) {
      setFilesToRemove(previousValue => [...previousValue, fileID]);
      setUploadedFiles(previousValue =>
        previousValue.filter(file => file.id !== fileID),
      );
      setPostDisabled(false);
    }
    // remove file from state.
    if (!fileID) {
      setSelectedFiles(
        selectedFiles.filter((file, index) => index !== deleteKey),
      );
    }
  };
  let buttonText;
  if (post) {
    buttonText = isStreamLoading ? 'Saving' : 'Save';
  } else {
    buttonText = isStreamLoading ? 'Posting' : 'Post';
  }
  if (classDetails?.orui_is_archived === 1) return null;
  if (!isEditing && (isModalOpen || isFormModalOpen)) {
    return (
      <SharePostContainer
        onDragOver={event => {
          event.stopPropagation();
          event.preventDefault();
          event.dataTransfer.dropEffect = 'copy';
        }}
        onDrop={event => {
          event.stopPropagation();
          event.preventDefault();
          let chosenFiles = [];

          if (event.dataTransfer.items) {
            for (
              let index = 0;
              index < event.dataTransfer.items.length;
              index += 1
            ) {
              if (event.dataTransfer.items[index].kind === 'file') {
                const file = event.dataTransfer.items[index].getAsFile();
                chosenFiles.push(file);
              }
            }
          } else {
            chosenFiles = event.dataTransfer.files;
          }

          if (chosenFiles.length) {
            onFileChange({
              target: {
                files: chosenFiles,
              },
            });
          }
        }}
        padding="1.5rem"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="share-post-open d-flex flex-wrap fade-in">
            <img
              src={
                auth.user?.userProfiles[0]?.up_avatar
                  ? auth.user?.userProfiles[0]?.up_avatar
                  : ProfileImg
              }
              className="rounded-circle"
              alt="profile-img"
            />

            <div className="d-flex flex-column ml-2">
              <span className="teacher-name">
                {auth.user.userProfiles[0].up_name_first}{' '}
                {auth.user.userProfiles[0].up_name_last}
              </span>
              <span className="class-det">
                {classDetails?.orgUnit?.unit?.tun_title} | {students?.length}{' '}
                Students
              </span>
            </div>

            {userType === 3 || userType === 2 ? (
              <div className="d-lg-flex d-sm-block ml-auto student-select-dropdown-div">
                <StudentsDropdown
                  onSelect={onStudentSelect}
                  postStudents={post?.students}
                  students={students}
                  title={studentSelectDropDownTitle}
                  onChanged={() => setPostDisabled(false)}
                />
              </div>
            ) : null}
          </div>

          <Textarea
            name="post_content"
            onChange={event => {
              if (
                !event.target.value ||
                event.target.value === post?.ac_activity_name
              ) {
                setPostDisabled(true);
              } else {
                setPostDisabled(false);
              }
            }}
            ref={register({required: true})}
            rows="5"
            className="form-control"
            autoFocus
            id="post-content"
          />

          {(uploadedFiles || selectedFiles) && (
            <FilesCard
              onRemoveItemClick={onFileRemove}
              files={uploadedFiles || selectedFiles}
              className="mt-3"
              onCardClick={() => 'No on click'}
              removable={!isStreamLoading}
            />
          )}

          <div className="attachment-container d-flex">
            {/* Input accept only image/video/docs */}
            <input
              multiple
              accept=".doc,.csv,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,video/*"
              type="file"
              name="files"
              onChange={onFileChange}
              id="attach-file"
            />
            <img className="anchor-icon" alt="attachment" src={AttachmentImg} />
            <span className="my-auto">Attach a file</span>
            <div className="ml-auto d-flex">
              <button
                onClick={onCancelClick}
                className="btn-secondary"
                type="button"
                id="cancel-btn"
              >
                Cancel
              </button>
              <CustomButton
                id="submit-button"
                className="submit-button"
                isDisabled={isDisabled || selectedStudents?.length === 0}
                text={
                  <>
                    {isStreamLoading ? (
                      <img
                        alt="Processing"
                        className="button-spinner"
                        src={ButtonSpinnerIcon}
                      />
                    ) : null}
                    {buttonText}
                  </>
                }
                type="submit"
              />
            </div>
          </div>
        </form>
      </SharePostContainer>
    );
  }

  return (
    <ClosedPostForm
      isEditing={isEditing}
      toggleShareBox={toggleShareBox}
      avatar={auth.user?.userProfiles[0]?.up_avatar}
    />
  );
};
