import PropTypes from 'prop-types';

import TimeIntervalContainer from '../elements/TimeIntervalContainer';

const TimeInterval = ({time}) => (
  <>
    <TimeIntervalContainer className="align-items-center d-flex">
      <span className="mr-2 time">{time === 24 ? 0 : time}.00</span>
      <span className="flex-grow-1 time-indicator" />
    </TimeIntervalContainer>
  </>
);

TimeInterval.propTypes = {
  time: PropTypes.number.isRequired,
};

export default TimeInterval;
