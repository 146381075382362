/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useEffect, useState, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {Typeahead} from 'react-bootstrap-typeahead';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import addIcon from '../../../../assets/icons/addIcon.svg';
import CustomIconButton from '../../../../components/shared/CustomIconButton';
import CustomBox from '../../../../components/shared/CustomBox';
import {
  CustomTable,
  tabularize,
} from '../../../../components/shared/CustomTable';
import SearchInput from '../../../../components/shared/Inputs/SearchInput';
import {
  UnitsContainer,
  DropdownBtn,
  CustomDatePicker,
} from '../../Elements/UnitsContainer';
import {
  getAllUnits,
  getDashboard,
  getAllUnitsClass,
  getAllUnitsDepartments,
  getAllUnitsTeachers,
  adminInfo,
  editUnit,
  deleteUnit,
  setUnitToastSuccess,
  setDeletetUnitToastSuccess,
} from '../../adminSlice';
import {Dropdown} from '../../../../components/shared/Dropdown';
import DropdownItems from '../../../../components/shared/DropdownItems/DropdownItems';
import DropdownWrapper from '../../../../components/shared/DropdownWrapper/DropdownWrapper';
import ThreeDotIcon from '../../../../assets/icons/threedot.svg';
import EmptyState from '../../../../components/shared/EmptyState';
import CustomModal from '../../../../components/shared/CustomModal';
import CustomButton from '../../../../components/shared/CustomButton';
import CustomTextInput from '../../../../components/shared/CustomTextInput';
import CustomCancel from '../../../../components/shared/Buttons/CustomButton';
// import CustomTextInputWithIcon from '../../../../components/shared/CustomTextInputWithIcon';
import closeModal from '../../../../assets/icons/close-modal.svg';
// import searchIcon from '../../../../assets/icons/search-icon.svg';
// import dropdownIcon from '../../../../assets/icons/unit-down-arrow.svg';
import calenderIcon from '../../../../assets/icons/calender-icon.svg';
import deleteModal from '../../../../assets/icons/delete-modal.svg';

// import profile from '../../../../assets/icons/profile.svg';
import profileImg from '../../../../assets/icons/profile.svg';
import ToastContainer from '../../../../components/shared/ToastMessage';
import toastSuccess from '../../../../assets/icons/toast-add-success.svg';
import loader from '../../../../assets/icons/orgLogoLoader.svg';
import locked from '../../../../assets/icons/lockedpage.svg';

const UnitModal = ({
  isModalVisible,
  setIsModalVisible,
  orgUnitInstanceId,
  className,
  subjectName,
  unitDepartmentName,
  // setUnitDepartmentName,
  unitTeacherName,
  setUnitTeacherName,
  unitStartDate,
  setUnitStartDate,
  unitEndDate,
  setUnitEndDate,
  showToastSuccess,
  unitName,
}) => {
  const {unitDepartments} = useSelector(adminInfo);
  const {unitTeachers, unitClass} = useSelector(adminInfo);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [searchTeacher, setSearchTeacher] = useState(
    `${unitTeacherName[0]?.up_name_first} ${unitTeacherName[0]?.up_name_last}`,
  );
  const history = useHistory();
  const [listColumns, setListColumns] = useState(null);
  const [listData, setListData] = useState([]);
  const [listMaps, setListMaps] = useState(null);
  const [addTeacher, setAddTeacher] = useState([
    {
      up_id_userprofile: unitTeacherName[0]?.up_id_userprofile,
      up_name_first: `${unitTeacherName[0]?.up_name_first}`,
      up_name_last: `${unitTeacherName[0]?.up_name_last}`,
      up_org_reference: unitTeacherName[0]?.up_org_reference,
    },
  ]);
  const [addDepartment, setAddDepartment] = useState([
    {
      id: unitDepartmentName?.od_id_orgdepartment,
      name: unitDepartmentName?.od_department_name,
    },
  ]);
  // console.log(unitClass);
  // const [unitClassName, setUnitClassName] = useState(className);
  const [unitSubjectName, setUnitSubjectName] = useState(
    subjectName?.un_unitname,
  );
  const [classData, setClassData] = useState(className);
  const [newClass, setNewClass] = useState();
  const [startDatePickerIsOpen, setStartDatePickerIsOpen] = useState(false);
  const [endDatePickerIsOpen, setEndDatePickerIsOpen] = useState(false);
  const classDropdown = useRef();
  if (Date.parse(unitEndDate) < Date.parse(unitStartDate)) {
    setUnitEndDate('');
  }
  const openStartDatePicker = () => {
    setStartDatePickerIsOpen(!startDatePickerIsOpen);
  };
  const openEndDatePicker = () => {
    setEndDatePickerIsOpen(!endDatePickerIsOpen);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUnitsDepartments());
    dispatch(getAllUnitsClass());
    return () => {
      dispatch(getAllUnitsClass());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllUnitsTeachers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setClassData(className);
  }, [className]);

  useEffect(() => {
    if (unitTeachers.length) {
      setListData(
        unitTeachers.filter(item =>
          item.up_name_first
            .toLowerCase()
            .includes(searchTeacher.toLowerCase()),
        ),
      );
    }
  }, [unitTeachers, searchTeacher]);
  return (
    <div>
      <div>
        <CustomModal isVisible={isModalVisible}>
          <div className="modal-container-top">
            <div className="close-btn">
              <button
                onClick={() => {
                  setIsModalVisible(false);
                }}
                type="button"
                className="closeModalResBtn"
              >
                <img src={closeModal} alt="close" />
              </button>
            </div>
            <CustomBox className="modal-container">
              <div className="edit-unit-modal" style={{padding: '24px'}}>
                <div className="edit-unit-modal-header mb-3">
                  <div>
                    <h2 style={{fontSize: '24px'}}>Edit Unit details</h2>
                    <p>Make changes to the user details</p>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setIsModalVisible(false);
                      }}
                      type="button"
                      className="closeIconBtn"
                    >
                      <img src={closeModal} alt="close" />
                    </button>
                  </div>
                </div>
                <div className="add-unit-header">
                  <p className="add-unit-header-title">
                    <span className="edit-unit-title">Unit Name</span>{' '}
                    <span className="edit-unit-name ml-2">
                      {/* {className?.oc_class} - {unitSubjectName} */}
                      {unitName}
                    </span>
                  </p>
                </div>
                <div className="add-unit-custom-box">
                  <div>
                    <div>
                      <form className="add-unit-form">
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="typehead-title">Class Name</p>
                            {/* <CustomTextInput
                            autoComplete="off"
                            label="Class Name"
                            marginBottom="8px"
                            placeholder="Enter Class name"
                            onChange={e => {
                              setUnitClassName(e.target.value);
                              setDisabled(true);
                            }}
                            value={unitClassName}
                          /> */}
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onInputChange={value => {
                                setNewClass(value);
                                if (
                                  !unitClass.find(item =>
                                    item.oc_class
                                      .toLowerCase()
                                      .includes(value.toLowerCase()),
                                  )
                                ) {
                                  classDropdown.current.hideMenu();
                                }
                              }}
                              onChange={value => {
                                setClassData(value);
                              }}
                              ref={classDropdown}
                              placeholder="Choose Class"
                              selected={classData}
                              options={unitClass.map(item => ({
                                id: item.oc_id_orgclass,
                                name: item.oc_class,
                              }))}
                              className="unit-class-name"
                            />
                          </div>
                          <div className="add-unit-col">
                            <CustomTextInput
                              autoComplete="off"
                              label="Subject"
                              height="38px"
                              marginBottom="8px"
                              placeholder="Enter Subject name"
                              onChange={e => setUnitSubjectName(e.target.value)}
                              value={unitSubjectName}
                            />
                          </div>
                        </div>
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="typehead-title ">Department</p>
                            {/* <CustomTextInputWithIcon
                            autoComplete="off"
                            icon={dropdownIcon}
                            placeholder="Enter Department name"
                            onChange={e => {
                              if (e.target.value.length > 0) {
                                setIsDepartment(true);
                              } else {
                                setIsDepartment(false);
                              }
                              setSearchDepartment(e.target.value);
                              setUnitDepartmentName(e.target.value);
                            }}
                            value={searchDepartment}
                          />
                          {isDepartment && deptData.length > 0 ? (
                            <div className="suggestion">
                              {deptColumns && deptData && deptMaps ? (
                                <CustomTableWithoutHeader
                                  {...tabularizeTable(
                                    deptColumns,
                                    deptData,
                                    deptMaps,
                                  )}
                                  className="search-table"
                                />
                              ) : null}
                            </div>
                          ) : null} */}
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onChange={value => {
                                setAddDepartment(value);
                              }}
                              placeholder="Choose Department"
                              selected={addDepartment}
                              options={unitDepartments.map(item => ({
                                id: item.od_id_orgdepartment,
                                name: item.od_department_name,
                              }))}
                              className="unit-class-name"
                            />
                          </div>
                          <div className="add-unit-col">
                            <p className="mb-2" style={{color: '#3d4457'}}>
                              Teacher
                            </p>
                            {/* <CustomTextInputWithIcon
                            autoComplete="off"
                            placeholder="Search for teacher"
                            icon={searchIcon}
                            onChange={e => {
                              if (e.target.value.length > 0) {
                                setIsSuggestion(true);
                              } else {
                                setIsSuggestion(false);
                              }
                              setSearchTeacher(e.target.value);
                              setUnitTeacherName(e.target.value);
                            }}
                            value={searchTeacher}
                          />
                          {isSuggestion && listData.length > 0 ? (
                            <div className="suggestion">
                              {listColumns && listData && listMaps ? (
                                <CustomTableWithoutHeader
                                  {...tabularizeTable(
                                    listColumns,
                                    listData,
                                    listMaps,
                                  )}
                                  className="search-table"
                                />
                              ) : null}
                            </div>
                          ) : null} */}
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey={option =>
                                `${option?.up_name_first} ${option.up_name_last}`
                              }
                              onChange={value => {
                                setAddTeacher(value);
                              }}
                              placeholder="Choose Teacher"
                              selected={addTeacher}
                              // options={unitTeacherName.map(item => ({
                              //   id: item.up_id_userprofile,
                              //   name: `${item.up_name_first} ${item.up_name_last} ${item.up_org_reference}`,
                              // }))}
                              filterBy={() => true}
                              options={unitTeachers}
                              renderMenuItemChildren={option => (
                                <div className="teacher-row">
                                  <div className="profile-img">
                                    <img src={profileImg} alt="src" />
                                  </div>
                                  <div className="profile-name">
                                    <p>
                                      {`${option?.up_name_first} ${option?.up_name_last}`}{' '}
                                    </p>
                                    <span className="ref-id">
                                      {`${option?.up_org_reference}`}
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div className="add-unit-row">
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              Start date
                            </p>
                            <CustomDatePicker className="d-flex">
                              <DatePicker
                                name="dueDate"
                                autoComplete="off"
                                dateFormat="do MMMM yyyy"
                                placeholderText="Choose start date"
                                selected={unitStartDate}
                                className="form-control border-0 w-100  date-picker"
                                popperPlacement="bottom"
                                onChange={date => {
                                  setUnitStartDate(date);
                                  setStartDatePickerIsOpen(false);
                                }}
                                onClickOutside={() =>
                                  setStartDatePickerIsOpen(false)
                                }
                                open={startDatePickerIsOpen}
                                onFocus={() => {
                                  openStartDatePicker();
                                }}
                              />
                              <button
                                className="openDatepicker"
                                type="button"
                                onClick={() => {
                                  openStartDatePicker();
                                }}
                              >
                                <img
                                  src={calenderIcon}
                                  className="mr-1"
                                  alt="date"
                                />
                              </button>
                            </CustomDatePicker>
                          </div>
                          <div className="add-unit-col">
                            <p className="mb-2 mt-2" style={{color: '#3d4457'}}>
                              End date
                            </p>
                            <CustomDatePicker className="d-flex">
                              <DatePicker
                                name="dueDate"
                                autoComplete="off"
                                dateFormat="do MMMM yyyy"
                                placeholderText="Choose end date"
                                selected={unitEndDate}
                                onChange={date => {
                                  setUnitEndDate(date);
                                  setEndDatePickerIsOpen(false);
                                }}
                                minDate={new Date(unitStartDate)}
                                onClickOutside={() =>
                                  setEndDatePickerIsOpen(false)
                                }
                                open={endDatePickerIsOpen}
                                className="form-control border-0 w-100  date-picker"
                                popperPlacement="bottom"
                                onFocus={() => {
                                  openEndDatePicker();
                                }}
                              />
                              <button
                                className="openDatepicker"
                                type="button"
                                onClick={() => {
                                  openEndDatePicker();
                                }}
                              >
                                <img
                                  src={calenderIcon}
                                  className="mr-1"
                                  alt="date"
                                />
                              </button>
                            </CustomDatePicker>
                          </div>
                        </div>
                      </form>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-seperator" />
              <div className="add-student-modal" style={{padding: '24px'}}>
                <div className="button-container">
                  <CustomCancel
                    className="cancel"
                    bgColor="#fff"
                    onClick={() => {
                      setIsModalVisible(false);
                    }}
                  >
                    <span className="cancel-button-text">Cancel</span>
                  </CustomCancel>
                  <CustomButton
                    className="save-changes"
                    text="Save Changes"
                    onClick={() => {
                      // const unitClass = {
                      //   id: className?.oc_id_orgclass,
                      //   classname: unitClassName,
                      // };
                      const unit = {
                        id: subjectName?.un_id_unit,
                        unitname: unitSubjectName,
                      };
                      const unitData = {
                        newclass: newClass,
                        classname: classData ? classData[0] : null,
                        subjectname: unit,
                        department: addDepartment[0],
                        teacher: addTeacher[0],
                        startdate: unitStartDate,
                        enddate: unitEndDate,
                      };
                      if (
                        unitClass &&
                        unitStartDate &&
                        unitName &&
                        unitEndDate &&
                        addDepartment &&
                        addTeacher
                      ) {
                        dispatch(
                          editUnit(unitData, orgUnitInstanceId, () => {
                            setIsModalVisible(false);
                            showToastSuccess(
                              `Unit Details updated successfully`,
                            );
                            setTimeout(() => showToastSuccess(''), 5000);
                          }),
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </CustomBox>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};
const MoreOption = ({
  orgUnitInstanceId,
  unit,
  ocClass,
  departmentName,
  teacherName,
  startDate,
  endDate,
  setDeleteModalVisible,
  setUnitName,
  setUnitInstanceId,
  showToastSuccess,
}) => {
  const [unitStartDate, setUnitStartDate] = useState(
    moment(startDate).toDate(),
  );
  const [unitEndDate, setUnitEndDate] = useState(moment(endDate).toDate());
  const [unitTeacherName, setUnitTeacherName] = useState(teacherName);
  const [unitDepartmentName, setUnitDepartmentName] = useState(departmentName);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  return (
    <div className="dropdowncontainer">
      <DropdownWrapper toggleMenu={setDropdownVisibility}>
        <DropdownBtn>
          <button
            className="btn"
            onClick={() => setDropdownVisibility(!dropdownVisibility)}
            type="submit"
          >
            <img alt="Options" src={ThreeDotIcon} className="threeDot" />
          </button>
        </DropdownBtn>
        {dropdownVisibility && (
          <Dropdown className="dropdown">
            <DropdownItems
              click={() => {
                setIsEditModalVisible(true);
                setDropdownVisibility(false);
              }}
              text="Edit"
            />
            <DropdownItems
              withoutSeperator
              isDanger
              click={() => {
                setDropdownVisibility(false);
                // eslint-disable-next-line no-alert
                setUnitInstanceId(orgUnitInstanceId);
                setUnitName(`${ocClass.oc_class} - ${unit.un_unitname}`);
                setDeleteModalVisible(true);
              }}
              text="Delete"
            />
          </Dropdown>
        )}
      </DropdownWrapper>
      <UnitModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        orgUnitInstanceId={orgUnitInstanceId}
        className={[{id: ocClass.oc_id_orgclass, name: ocClass.oc_class}]}
        subjectName={unit}
        unitDepartmentName={unitDepartmentName}
        setUnitDepartmentName={setUnitDepartmentName}
        unitTeacherName={unitTeacherName}
        setUnitTeacherName={setUnitTeacherName}
        unitStartDate={unitStartDate}
        setUnitStartDate={setUnitStartDate}
        unitEndDate={unitEndDate}
        setUnitEndDate={setUnitEndDate}
        showToastSuccess={showToastSuccess}
        unitName={`${ocClass?.oc_class} - ${unit?.un_unitname}`}
      />
    </div>
  );
};
const Units = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tableColumns, setTableColumns] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableMaps, setTableMaps] = useState(null);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [unitName, setUnitName] = useState('');
  const [unitInstanceId, setUnitInstanceId] = useState('');
  const dispatch = useDispatch();
  const {
    units,
    unitToastSuccess,
    unitDataLoading,
    dashboardData,
    deleteUnitToastSuccess,
  } = useSelector(adminInfo);
  const history = useHistory();
  const [isToastSuccess, showToastSuccess] = useState('');
  const [isToastDanger, showToastDanger] = useState('');

  useEffect(() => {
    dispatch(getAllUnits());
    const columns = [
      'orui_id_orgunitinstance',
      {
        heading: '#',
        width: '2%',
      },
      {
        heading: 'UNIT NAME',
        width: '10%',
      },
      {
        heading: 'DEPARTMENT',
        width: '17%',
      },
      {
        heading: 'TEACHER',
        width: '12%',
      },
      {
        heading: 'STUDENTS',
        width: '11%',
      },
      {
        heading: 'START DATE',
        width: '12%',
      },
      {
        heading: 'END DATE',
        width: '12%',
      },
      {
        heading: ' ',
        width: '9%',
      },
      {
        heading: '',
        width: '3%',
      },
    ];
    const maps = {
      '#': [organizationReference => organizationReference + 1],
      'UNIT NAME': [
        (unit, ocClass) => `${ocClass?.oc_class} - ${unit?.un_unitname}`,
        'unit',
        'class',
      ],
      DEPARTMENT: [
        departmentName => departmentName?.od_department_name,
        'department',
      ],
      TEACHER: [
        teacherName =>
          `${teacherName[0]?.up_name_first} ${teacherName[0]?.up_name_last}`,
        'teacher',
      ],
      STUDENTS: [
        studentCount =>
          `${studentCount?.length}` > 0
            ? `${studentCount?.length} students`
            : `${studentCount?.length} student`,
        'students',
      ],
      'START DATE': [
        unitStartDate => moment(unitStartDate).format('D MMMM, YYYY'),
        'orui_date_start',
      ],
      'END DATE': [
        unitEndDate => moment(unitEndDate).format('D MMMM, YYYY'),
        'orui_date_finish',
      ],

      ' ': [
        orgUnitInstanceId => (
          <button
            type="button"
            onClick={() => {
              history.push(`units/view-unit/${orgUnitInstanceId}`);
            }}
            className="view-unit"
          >
            View Unit
          </button>
        ),
        'orui_id_orgunitinstance',
      ],
      '': [
        (
          orgUnitInstanceId,
          unit,
          ocClass,
          departmentName,
          teacherName,
          startDate,
          endDate,
        ) => (
          <MoreOption
            orgUnitInstanceId={orgUnitInstanceId}
            unit={unit}
            ocClass={ocClass}
            departmentName={departmentName}
            teacherName={teacherName}
            startDate={startDate}
            endDate={endDate}
            setDeleteModalVisible={setDeleteModalVisible}
            setUnitName={setUnitName}
            setUnitInstanceId={setUnitInstanceId}
            showToastSuccess={showToastSuccess}
          />
        ),
        'orui_id_orgunitinstance',
        'unit',
        'class',
        'department',
        'teacher',
        'orui_date_start',
        'orui_date_finish',
      ],
    };
    setTableColumns(columns);
    setTableMaps(maps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getDashboard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (units) {
      setTableData(
        units.filter(profile =>
          ` ${profile?.class?.oc_class} ${profile?.unit?.un_unitname} ${profile?.class?.od_department_name} ${profile?.teacher?.up_name_first} ${profile?.teacher?.up_name_last}`
            .toLowerCase()
            .includes(searchTerm),
        ),
      );
    }
  }, [units, searchTerm]);

  return (
    <>
      <Helmet>
        <title>Units | JungleCat</title>
      </Helmet>

      <UnitsContainer>
        <div className="container-unit">
          <div className="unit-header d-flex justify-content-between mb-4">
            <span className="unit-header-title">All Units</span>
            <CustomIconButton
              className="addbtn"
              icon={addIcon}
              text="Add Unit"
              isDisabled={
                !dashboardData?.academicYearCount ||
                !dashboardData?.departmentCount
              }
              onClick={() => {
                if (
                  dashboardData?.academicYearCount > 0 &&
                  dashboardData?.departmentCount > 0
                ) {
                  history.push(`units/add-unit`);
                }
              }}
            >
              {' '}
            </CustomIconButton>
          </div>
          <CustomBox padding="23px 24px 0 22px">
            <div className="unit-header1">
              <div className="count-div">
                <div>
                  <p className="unit-count">{tableData?.length || '0'} Units</p>
                </div>
              </div>
              <div className="search-box">
                <SearchInput
                  className="unit-search"
                  onChange={event => setSearchTerm(event.target.value)}
                  placeholder="Search"
                  value={searchTerm}
                  width="300px"
                />
              </div>
            </div>
            {tableColumns && tableData && tableMaps && !unitDataLoading ? (
              <CustomTable
                {...tabularize(tableColumns, tableData, tableMaps)}
                className="unit-table"
              />
            ) : null}
            <div className="unit-table-header-line" />
            {!unitDataLoading &&
            tableData?.length === 0 &&
            dashboardData?.academicYearCount > 0 &&
            dashboardData?.departmentCount > 0 ? (
              <div>
                <EmptyState
                  body="try adjusting the search or filter for what you are looking for"
                  title="No Units Found"
                />
              </div>
            ) : null}
            {!unitDataLoading &&
            tableData?.length === 0 &&
            (dashboardData?.academicYearCount === 0 ||
              dashboardData?.departmentCount === 0) ? (
              <div className="lockedContainer">
                <div>
                  <img src={locked} alt="loader" className="lockedpage" />
                </div>
                {dashboardData?.departmentCount === 0 ? (
                  <div className="content-locked">
                    <div>
                      <p className="statement"> No Department Found</p>
                    </div>
                    <div>
                      <p className="info">
                        To add a Unit, you must first create a department and an
                        academic year
                      </p>
                    </div>
                    <div>
                      <CustomButton
                        className="save-changes"
                        text="Add Department"
                        onClick={() => {
                          history.replace('/admin/organisation/departments');
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="content-locked">
                    <div>
                      <p className="statement"> No Academic year Found</p>
                    </div>
                    <div>
                      <p className="info">
                        To add a Unit, you must first create a department and an
                        academic year
                      </p>
                    </div>
                    <div>
                      <CustomButton
                        className="save-changes"
                        text="Add Academicyear"
                        onClick={() => {
                          history.replace('/admin/organisation/academicyear');
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {unitDataLoading ? (
              <div className="loader-images">
                <img src={loader} alt="loader" className="button-spinner1" />
              </div>
            ) : null}
          </CustomBox>
        </div>
        <CustomModal isVisible={isDeleteModalVisible}>
          <div className="delete-modal-container-top">
            <div className="close-btn">
              <button
                onClick={() => {
                  setDeleteModalVisible(false);
                }}
                type="button"
                className="closeModalResBtn"
              >
                <img src={closeModal} alt="close" />
              </button>
            </div>
            <CustomBox className="modal-container">
              <div className="delete-department-modal">
                <div className="delete-department-modal-header">
                  <div className="modal-header-left">
                    <div>
                      <img src={deleteModal} alt="close" />
                    </div>
                    <div className="mx-2">
                      <span className="delete-department-modal-title">
                        Delete {unitName} ?
                      </span>
                      <p>Are you sure you want to delete this unit ?</p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setDeleteModalVisible(false);
                      }}
                      type="button"
                      className="closeModalBtn"
                    >
                      <img src={closeModal} alt="close" />
                    </button>
                  </div>
                </div>
                <div className="footer-delete-break" />
                <div
                  className="delete-department-buttons"
                  style={{padding: ' 24px'}}
                >
                  <CustomCancel
                    className="cancel"
                    bgColor="#fff"
                    onClick={() => {
                      setDeleteModalVisible(false);
                    }}
                  >
                    <span className="cancel-button-text">Cancel</span>
                  </CustomCancel>
                  <CustomButton
                    text="Delete"
                    isDanger
                    onClick={() => {
                      dispatch(
                        deleteUnit(unitInstanceId, () => {
                          setDeleteModalVisible(false);
                          showToastDanger(`${unitName} Has been deleted`);
                          setTimeout(() => showToastDanger(''), 5000);
                        }),
                      );
                    }}
                  />
                </div>
              </div>
            </CustomBox>
          </div>
        </CustomModal>
      </UnitsContainer>
      {unitToastSuccess || isToastSuccess ? (
        <ToastContainer
          icon={toastSuccess}
          text={unitToastSuccess || isToastSuccess}
          isSuccess
          onClose={() => {
            showToastSuccess('');
            dispatch(setUnitToastSuccess(''));
          }}
        />
      ) : null}
      {deleteUnitToastSuccess || isToastDanger ? (
        <ToastContainer
          text={deleteUnitToastSuccess || isToastDanger}
          isDanger
          onClose={() => {
            showToastDanger('');
            dispatch(setDeletetUnitToastSuccess(''));
          }}
        />
      ) : null}
    </>
  );
};

export default Units;
