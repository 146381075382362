import {createSlice} from '@reduxjs/toolkit';

import * as teacherLibraryApi from '../api/admin/teacherLibrary';

const initialFormError = {fileCode: ''};

const teacherLibrarySlice = createSlice({
  initialState: {
    error: null,
    teacherLibrary: null,
    publicLibraryFiles: null,
    orgUnitInstance: null,
    isTeacherLibraryLoading: false,
    isListLoading: false,
    isFileUpload: 0,
    category: [],
    formError: initialFormError,
    isValidationLoading: false,
    isCategoryLoading: false,
  },
  name: 'teacherLibrary',
  reducers: {
    setTeacherLibrary: (state, action) => {
      state.teacherLibrary = action.payload;
    },
    setTeacherPublicLibrary: (state, action) => {
      state.publicLibraryFiles = action.payload;
    },
    setOrgUnitInstance: (state, action) => {
      state.orgUnitInstance = action.payload;
    },
    setIsTeacherLibrarysLoading: (state, action) => {
      state.isTeacherLibraryLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    deleteTeacherLibraryById: (state, action) => {
      state.teacherLibrary = state.teacherLibrary.filter(
        file => file.fi_id_file !== action.payload,
      );
    },
    setIsFileUpload: (state, action) => {
      state.isFileUpload = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setIsValidationLoading: (state, action) => {
      state.isValidationLoading = action.payload;
    },

    setIsCategoryLoading: (state, action) => {
      state.isCategoryLoading = action.payload;
    },
    appendNewCategory: (state, action) => {
      state.category = [action.payload, ...state.category];
    },
  },
});

export const {
  setTeacherLibrary,
  setTeacherPublicLibrary,
  setOrgUnitInstance,
  setIsTeacherLibrarysLoading,
  setError,
  setIsListLoading,
  deleteTeacherLibraryById,
  setIsFileUpload,
  setCategory,
  setFormError,
  clearFormError,
  setIsValidationLoading,
  setIsCategoryLoading,
  appendNewCategory,
} = teacherLibrarySlice.actions;

export const fetchAllLibraryFiles = orgUnitInstanceId => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const [classLibraryResponse, publicLibraryResponse] = await Promise.all([
      teacherLibraryApi.fetchAllLibraryFiles(orgUnitInstanceId),
      teacherLibraryApi.fetchPublicLibraryFiles(orgUnitInstanceId),
    ]);
    dispatch(setTeacherLibrary(classLibraryResponse?.data?.libraryFiles || []));
    dispatch(
      setTeacherPublicLibrary(
        publicLibraryResponse?.data?.publicLibraryFiles || [],
      ),
    );
    dispatch(
      setOrgUnitInstance(classLibraryResponse?.data?.orgUnitInstance || []),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const addLibraryDocument =
  (orgUnitInstanceId, data, callBack) => async dispatch => {
    dispatch(setIsTeacherLibrarysLoading(true));
    try {
      await teacherLibraryApi.addLibraryDocument(orgUnitInstanceId, data);
      if (callBack) {
        callBack();
      }
      dispatch(fetchAllLibraryFiles(orgUnitInstanceId));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsTeacherLibrarysLoading(false));
    }
  };

export const deleteLibraryClassFile =
  (deleteId, orgUnitInstanceId, callback) => async dispatch => {
    dispatch(setIsTeacherLibrarysLoading(true));
    try {
      await teacherLibraryApi.deleteLibraryClassFile(
        deleteId,
        orgUnitInstanceId,
      );
      dispatch(deleteTeacherLibraryById(deleteId));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsTeacherLibrarysLoading(false));
    }
  };

export const updateLibraryDocument =
  (orgUnitInstanceId, fileId, data, callBack) => async dispatch => {
    dispatch(setIsTeacherLibrarysLoading(true));
    try {
      await teacherLibraryApi.updateLibraryDocument(
        orgUnitInstanceId,
        fileId,
        data,
      );
      if (callBack) {
        callBack();
      }
      dispatch(fetchAllLibraryFiles(orgUnitInstanceId));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsTeacherLibrarysLoading(false));
    }
  };

export const fetchAllCategory = orgUnitInstanceId => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const response = await teacherLibraryApi.fetchAllCategory(
      orgUnitInstanceId,
    );
    dispatch(setCategory(response.data.allCategory));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const individualFileCodeValid =
  (orgUnitInstanceId, data) => async dispatch => {
    dispatch(setIsValidationLoading(true));
    try {
      await teacherLibraryApi.individualFileCodeValid(orgUnitInstanceId, {
        fileCode: data,
      });
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
      dispatch(setFormError(error?.response?.data?.error));
    } finally {
      dispatch(setIsValidationLoading(false));
    }
  };

export const individualFileCodeValidationInEdit =
  (orgUnitInstanceId, data, fileId) => async dispatch => {
    dispatch(setIsValidationLoading(true));
    try {
      await teacherLibraryApi.individualFileCodeValidationInEdit(
        orgUnitInstanceId,
        {fileCode: data},
        fileId,
      );
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
      dispatch(setFormError(error?.response?.data?.error));
    } finally {
      dispatch(setIsValidationLoading(false));
    }
  };

export const addNewCategory = (unitId, data, callBack) => async dispatch => {
  dispatch(setIsCategoryLoading(true));
  try {
    const category = {newCategory: data};
    const resp = await teacherLibraryApi.addNewCategory(unitId, category);
    dispatch(appendNewCategory(resp.data.addCategory));
    if (callBack) {
      callBack(resp.data.addCategory);
    }
  } catch (error) {
    callBack('error');
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsCategoryLoading(false));
  }
};

export const teacherLibrarySelector = state => state.teacherLibrary;

export default teacherLibrarySlice.reducer;
