import styled from 'styled-components';

export const TeacherFormContainer = styled.section`
  .accept-checkbox-label {
    color: ${({theme}) => theme.colors.text};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;
  }
`;
