import styled from 'styled-components';

import {Modal} from '../../../../../../../../../components/common';

export const EditAdminTeacherModalContainer = styled(Modal)`
  .user-type-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    p {
      margin-bottom: unset;
    }
  }
  .user-type {
    font-weight: normal;
    font-size: 0.875rem;
    color: #a5a5a5;
  }
  .exclaim-icon {
    margin-left: 0.3125rem;
  }
  .user-type-instruction {
    font-weight: normal;
    font-size: 0.75rem;
    color: #a5a5a5;
    margin-left: 0.3125rem;
    margin-top: 0.125rem;
  }
`;
