/* eslint-disable react/prop-types */
import React, {useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
// import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  getAllQualification,
  fetchAllAssessmentAndUnitsByQualId,
  deleteAssessment,
  adminAssessmentsSelector,
} from '../../../../../../redux/adminAssessmentsSlice';
import {
  EmptyState,
  MoreOptions,
  Table,
  Modal,
  Select,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';

import {showToast} from '../../../../../../components/common/Toast';

import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {ListAssessmentsContainer} from './elements';

const DELAY = 500;

export const ListAssessments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {assessments, isListLoading, isDeleteLoading, qualifications, units} =
    useSelector(adminAssessmentsSelector);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [filteredQualificationId, setFilteredQualificationId] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Title',
        accessor: 'ac_activity_name',
        className: 'activity-name',
      },
      {
        Header: 'Type',
        accessor: 'am_activitymethod',
        className: 'activity-type',
      },
      {
        Header: 'Unit',
        Cell: ({row: {original}}) => (
          <div className="pr-2">
            {`${original.tun_code} - ${original.tun_title}`}
          </div>
        ),
        accessor: 'tun_title',
        className: 'unit-name',
      },
      {
        Header: 'Created by',
        Cell: ({row: {original}}) => (
          <div className="pr-2">{`${original.up_name_first || ''} ${
            original.up_name_last || ''
          }`}</div>
        ),
        accessor: 'up_name_first',
        className: 'createdby',
      },

      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit"
                id="edit-assessment-btn"
                onClick={() => {
                  history.replace(
                    `assessments/${original?.ac_id_activity}/update`,
                  );
                }}
              />

              <DropdownItem
                isDanger
                label="Delete"
                id="delete-assessment-btn"
                onClick={() => {
                  setDeleteId(original?.ac_id_activity);
                  setDeleteName(original?.ac_activity_name);
                }}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'ac_id_activity',
        className: 'more-option',
      },
    ],
    [history],
  );

  const formatOptionForselect = ({code, label}, {context}) =>
    context === 'menu' ? (
      <div className="select-option" type="button">
        <div className="label">{label}</div>
        <div className="code">{code}</div>
      </div>
    ) : (
      label
    );

  const qualificationOptions = useMemo(
    () => [
      {label: 'All Qualification', value: '', code: ''},
      ...(qualifications?.map(item => ({
        label: item.qualification.tqual_title,
        value: item.qualification.tqual_id_tgovqualification,
        code: item.qualification.tqual_code,
      })) || []),
    ],
    [qualifications],
  );

  const unitsOptions = useMemo(
    () => [
      {label: 'All Units', value: '', code: ''},
      ...(units?.map(item => ({
        label: item.unit.tun_title,
        value: item.unit.tun_title,
        code: item.unit.tun_code,
      })) || []),
    ],
    [units],
  );

  useEffect(() => {
    setFilteredQualificationId({
      label: qualificationOptions[0]?.label,
      value: qualificationOptions[0]?.value,
      code: qualificationOptions[0]?.code,
    });
  }, [qualificationOptions]);

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(assessments || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [assessments],
  );

  useEffect(() => {
    if (filteredQualificationId && !isListLoading) {
      dispatch(
        fetchAllAssessmentAndUnitsByQualId(filteredQualificationId.value),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filteredQualificationId]);

  useEffect(() => {
    dispatch(getAllQualification());
  }, [dispatch]);

  const filters = useMemo(
    () => [
      {
        column: 'tun_title',
        data: unitsOptions,
        formatOptionLabel: formatOptionForselect,
        itemName: 'Unit',
      },
    ],
    [unitsOptions],
  );

  return (
    <>
      <Helmet>
        <title>Assessments | JungleCat</title>
      </Helmet>

      <ListAssessmentsContainer>
        <ListingPageHeader
          buttonLabel="Add Assessment"
          className="mb-4"
          onButtonClick={() => {
            history.push('assessments/create');
          }}
          title="All Assessments"
          btnId="add-assessment-btn"
        />

        <div className="table-container">
          <div className="mb-4 d-flex justify-content-end">
            <Select
              width="300px"
              isLarge={false}
              label="Qualification"
              options={qualificationOptions}
              formatOptionLabel={formatOptionForselect}
              placeholder="Select Qualification"
              value={filteredQualificationId}
              onChange={e => {
                setFilteredQualificationId(e);
              }}
            />
          </div>
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading && !assessments?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Assessments Found"
            />
          ) : null}

          {!isListLoading &&
          assessments?.length &&
          Array.isArray(assessments) &&
          !isListLoading ? (
            <Table
              columns={columns}
              data={data}
              filters={filters}
              itemName="Assessments"
              maxWidth="75rem"
              isSortedBy
            />
          ) : null}
        </div>
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this assessments?"
          isDelete
          isButtonLoading={isDeleteLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isDeleteLoading) {
              dispatch(
                deleteAssessment(deleteId, () => {
                  showToast(false, `${deleteName} deleted successfully`, true);
                }),
              );
              setDeleteId(null);
              setDeleteName('');
            }
          }}
          onHide={() => {
            setDeleteId(null);

            setTimeout(() => {
              setDeleteName('');
            }, DELAY);
          }}
          show={!!deleteId}
          title={`Delete ${deleteName}`}
          width="41.25rem"
        />
      </ListAssessmentsContainer>
    </>
  );
};
