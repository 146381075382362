/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';

import DropdownWrapper from '../../../../../../../../../../components/shared/DropdownWrapper/DropdownWrapper';

import {Dropdown} from '../../../../../../../../../../components/shared/Dropdown';
import {DropdownBtn, DropdownContainer} from './elements';
import {
  CustomTableWithoutHeader,
  tabularizeTable,
} from '../../../../../../../../../../components/shared/CustomTableWithoutHeader';
import SearchInput from '../../../../../../../../../../components/shared/Inputs/SearchInput';

import AddBtnIcon from '../../../../../../../../../../assets/icons/addplus-btn.svg';
import ProfileImg from '../../../../../../../../../../assets/icons/profile.svg';
import PlusIcon from '../../../../../../../../../../assets/icons/add-plus.svg';
import RemoveIcon from '../../../../../../../../../../assets/icons/remove.svg';

export const AddTeacherDropdown = ({
  unitTeachers,
  onAddTeacher,
  onRemoveTeacher,
  isChecked,
  orgUnit,
  orgUnitId,
}) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [listColumns, setListColumns] = useState(null);
  const [listData, setListData] = useState([]);
  const [listMaps, setListMaps] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (unitTeachers?.length) {
      setListData(
        unitTeachers?.filter(profiles =>
          `${profiles?.up_name_first} ${profiles?.up_name_last}`
            .toLowerCase()
            .includes(search),
        ),
      );
    }
  }, [search, unitTeachers]);

  useEffect(() => {
    const addedTeacherId = orgUnit[orgUnitId]?.map(data => data.teacherId);
    if (unitTeachers?.length) {
      setListData(
        unitTeachers?.filter(
          profiles => !addedTeacherId?.includes(profiles?.up_id_userprofile),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnit]);
  useEffect(() => {
    const departmentColumns = [
      'up_id_userprofile',

      {
        heading: '',
        width: '100%',
      },
    ];

    const mapDepartment = {
      '': [
        (fname, lname, reference, profileId) => (
          <button
            className="teacher-row"
            type="button"
            onClick={() => {
              onAddTeacher(profileId, fname, lname);
              setDropdownVisibility(false);
            }}
            // id={`${fname}-${lname}`}
          >
            <div className="profile-img">
              <img src={ProfileImg} alt="src" />
            </div>
            <div className="profile-name">
              <p>{`${fname} ${lname}`} </p>
              <span className="ref-id">{`${reference}`}</span>
            </div>
          </button>
        ),
        'up_name_first',
        'up_name_last',
        'up_org_reference',
        'up_id_userprofile',
      ],
    };
    setListColumns(departmentColumns);
    setListMaps(mapDepartment);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownContainer>
      <DropdownWrapper toggleMenu={setDropdownVisibility}>
        <DropdownBtn>
          {!isChecked || orgUnit[orgUnitId]?.length === 0 ? (
            <button
              type="button"
              // className={`unit-count1 ${!isChecked ? 'disabled' : ''}`}
              className="class-count1"
              onClick={() => {
                setDropdownVisibility(!dropdownVisibility);
              }}
              disabled={!isChecked}
              id="add-teacher"
            >
              <img src={PlusIcon} alt="icon" style={{marginRight: '0.5rem'}} />
              Add Teacher
            </button>
          ) : (
            <div className="added-user-div">
              {orgUnit[orgUnitId].map(item => (
                <button
                  type="button"
                  className="added-user"
                  id="remove-teacher"
                  onClick={() => onRemoveTeacher(item?.teacherId)}
                >
                  <span>{`${item?.fname} ${item?.lname}`}</span>
                  <img
                    src={RemoveIcon}
                    alt="icon"
                    style={{marginRight: '0.5rem'}}
                    className="remove-user"
                  />
                </button>
              ))}
              <button
                type="button"
                className="add-new-btn"
                onClick={() => {
                  setDropdownVisibility(!dropdownVisibility);
                }}
                disabled={!isChecked}
                id="add-new-btn"
              >
                <img src={AddBtnIcon} alt="icon" className="add-btn" />
              </button>
            </div>
          )}
        </DropdownBtn>
        {dropdownVisibility && (
          <Dropdown className="menuDropdown">
            <div>
              <div className="search-box">
                <SearchInput
                  className="class-search"
                  onChange={event => setSearch(event.target.value)}
                  placeholder="Search teacher"
                  value={search}
                  id="search-teacher"
                />

                {listColumns && listData && listMaps ? (
                  <div className="teacher-table-container">
                    <CustomTableWithoutHeader
                      {...tabularizeTable(listColumns, listData, listMaps)}
                      className="teacher-table"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Dropdown>
        )}
      </DropdownWrapper>
    </DropdownContainer>
  );
};
