import styled from 'styled-components';

export const AssessmentViewHeaderContainer = styled.header`
  background-color: #fff;
  border-bottom: 0.0625rem solid #e9e9e9;
  z-index: 1040;
  @media (max-width: 70.25rem) {
    & {
      width: 100%;
    }
  }
  .button-arrow {
    background-color: #fff;
    border: none;
    /* margin-right: 1rem; */

    :active,
    :focus {
      outline: none;
    }

    .header_t-back-icon {
      height: 2rem;
    }
  }
  .basic-details-header {
    padding: 1rem 2rem 0.75rem 2rem;
    h3 {
      color: #3d4457;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    .basic-details-description {
      color: #84888c;
      font-size: 1rem;
      font-weight: 400;
      padding-bottom: 1rem;
      margin-bottom: unset;
    }
    .published-text {
      font-size: 0.875rem;
      color: #6d7579;
      margin: 0.75rem 0 0 0;
    }
    .published-time {
      font-size: 0.875rem;
      color: #39ca84;
    }
    .outline-container {
      width: 100%;
      display: flex;
      align-items: center;
      border: 0.0625rem solid #e6e7e8;
      box-sizing: border-box;
      border-radius: 0.5rem;

      .basic-details-container {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem;
        @media (max-width: 45rem) {
          & {
            display: flex;
            flex-direction: column;
            padding: 1rem;
          }
          .time-container {
            width: 100%;
            border: unset;
            justify-content: flex-start;
          }
          .duedate-container,
          .assigned-student-container,
          .location-container {
            width: 100%;
            border: unset;
            align-items: center;
            justify-content: flex-start;
            margin-top: 1rem;
          }
        }
      }
      .time-container,
      .duedate-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17%;
        border-right: 1px solid #e6e7e8;
      }
      .assigned-student-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e6e7e8;
        width: 30%;
      }
      .location-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36%;
      }
      .text-data {
        font-size: 0.875rem;
        color: #0c181f;
      }
    }
  }

  .basic-datail-footer {
    padding: 0 2rem 2rem 2rem;
    .accordion {
      width: 100%;
      .card {
        border-radius: 0.5rem;
        border: 0.0625rem solid #e6e7e8;
      }
      .card-header {
        background-color: unset;
        padding: unset;
      }
      .card-header-last {
        background-color: unset;
        padding: unset;
        border-bottom: unset;
      }
      .card-body {
        padding: 0 1rem;
      }
      .accordion-header {
        display: flex;
        align-items: center;
      }
      .data {
        padding: 0 0.875rem 0.875rem 0.875rem;
      }
      .data-last {
        padding: 0 0.875rem 0.875rem 0.875rem;
      }
      .instruction-content {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        color: #6d7579;
      }
      .btn {
        line-height: 1;
        font-weight: 500;
        font-size: 0.875rem;
        color: #0c181f;
        padding: 0.875rem 0.875rem;
        :focus {
          outline: 0;
          box-shadow: 0 0 0 0.2rem transparent;
        }
        .arrow {
          width: 1.1rem;
          height: 1.1rem;
        }
        .resource {
          margin-right: 0.93rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .instruction-student-btn {
      background-color: unset;
      padding: 1rem 0 1rem 0;
      outline: unset;
      border: unset;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .instruction-additional-btn {
      background-color: unset;
      outline: unset;
      border: unset;
      width: 100%;
      display: flex;
      padding: 1rem 0 1rem 0;
      justify-content: space-between;
      align-items: center;
    }
    .instruction-required-btn {
      background-color: unset;
      outline: unset;
      border: unset;
      padding: 1rem 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .student-instruction {
      display: flex;
      border-bottom: 0.0625rem solid #e6e7e8;
    }
    .required-resources {
      display: flex;
    }
    .additional-resources {
      display: flex;
      border-bottom: 0.0625rem solid #e6e7e8;
    }
    .title {
      font-weight: 400;
      font-size: 14px;
      color: #6d7579;
    }
    .btn-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 28rem) {
    .basic-details-header {
      padding: 1rem 1.5rem 0.75rem 1.5rem;
    }
    .basic-datail-footer {
      padding: 0 1.5rem 2rem 1.5rem;
    }
  }
  .text-modal-container {
    display: flex;
    flex-direction: column;
    width: 45.8%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }

    .text-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      border-bottom: 0.0625rem solid #e6e7e8;
      h4 {
        margin-bottom: unset;
      }
      .modal-close-button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .text-content {
      background-color: #535659;
      height: 25rem;
      overflow-y: auto;
      position: relative;
      width: 100%;
      background: #ffffff;
      border-radius: 0px 0px 0.5rem 0.5rem;
      padding: 1.5rem;
      p {
        font-weight: 400;
        font-size: 1rem;
        text-align: justify;
        line-height: 1.5;
        color: #6d7579;
        margin-bottom: unset;
      }
    }
  }
  .assessment-status {
    background: #f2f8ff;
    border: 0.0625rem solid #e6e7e8;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    p {
      margin-bottom: unset;
    }
    .heading {
      font-weight: 500;
      font-size: 1rem;
      color: #5ba1f2;
    }
    .subtext {
      font-weight: 400;
      font-size: 1rem;
      color: #aaaaaa;
    }
  }
  .work-resigned {
    background: #ffffff;
    border: 0.0625rem solid #e6e7e8;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    p {
      margin-bottom: unset;
    }
    .heading {
      font-weight: 500;
      font-size: 1rem;
      color: #5355f6;
    }
    .subtext {
      font-weight: 400;
      font-size: 1rem;
      color: #aaaaaa;
    }
  }
  .work-evaluation {
    background: #ffffff;
    border: 0.0625rem solid #e6e7e8;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    p {
      margin-bottom: unset;
    }
    .heading {
      font-weight: 500;
      font-size: 1rem;
      color: #5ba1f2;
    }
    .subtext {
      font-weight: 400;
      font-size: 1rem;
      color: #aaaaaa;
    }
  }
  @media (max-width: 45rem) {
    .work-evaluation,
    .work-resigned {
      display: flex;
      flex-direction: column;
    }
    .button-right {
      margin-top: 1rem;
    }
  }
  .footer-resub-button {
    background-color: transparent;
    padding: 0.65rem 1.5rem;
    color: #40a1ff;
    font-size: 0.875rem;
    font-weight: 500;
    color: #474747;
    border: 0.0625rem solid #c1ced4;
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    :active,
    :focus {
      outline: none;
    }
    img {
      margin-right: 0.5rem;
    }
  }
  .text-modal-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }

    .text-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      h5 {
        margin-bottom: unset;
      }
      .modal-close-button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .text-content {
      background-color: #535659;
      height: 30vh;
      overflow-y: scroll;
      position: relative;
      width: 100%;
      background: #e6e7e8;
      border-radius: 0px 0px 0.5rem 0.5rem;
      padding: 3rem 9rem;
      p {
        font-weight: 500;
        font-size: 1rem;
        text-align: justify;
        color: #091018;
        line-height: 2;
      }
    }
  }
`;
