/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showToast} from '../../../../../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../../../../../assets/icons/addtoastadmin.svg';
import {
  addStudentManual,
  spreadSheetStudentsCheck,
  addStudentSpread,
  studentsSelector,
} from '../../../../../../../../../../redux/studentSlice';
import {AddStudentFooterContainer} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import CustomButton from '../../../../../../../../../../components/shared/CustomButton';

export const AddStudentFooter = ({
  addStudents,
  setAddStudents,
  spreadSheet,
  setSpreadSheet,
  manual,
  steps,
  setSteps,
  setUpload,
  setIsValidating,
  nextShow,
  isValidating,
  emailPattern,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    alreadyExistRefId,
    alreadyExistEmail,
    validStudent,
    duplicateData,
    isAddStudentsLoading,
  } = useSelector(studentsSelector);
  return (
    <AddStudentFooterContainer>
      <div className="add-student-footer fixed-bottom">
        {manual ? (
          <div className="add-student-title">
            <div className="button-container">
              <CustomCancel
                className="cancel1"
                bgColor="#fff"
                id="manualCancel"
                onClick={() => {
                  setUpload(null);
                  setAddStudents([]);
                  history.replace('/admin/users/student');
                }}
              >
                <span className="cancel1-button-text">Cancel</span>
              </CustomCancel>
              <CustomButton
                isDisabled={!addStudents?.length || steps}
                className="save-changes"
                text="Add Students"
                id="addStudents"
                onClick={() => {
                  if (addStudents?.length > 0 && !isAddStudentsLoading) {
                    dispatch(
                      addStudentManual(addStudents, () => {
                        showToast(
                          AddToastSuccessImg,
                          `${addStudents.length} Users added successfully`,
                        );
                        history.replace('/admin/users/student');
                        setAddStudents([]);
                      }),
                    );
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="add-student-spread">
            {steps === 0 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    history.replace('/admin/users/student');
                  }}
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(0);
                    setSpreadSheet([]);
                    setUpload(null);
                    setAddStudents([]);
                  }}
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validStudent?.length &&
            (alreadyExistEmail.length ||
              alreadyExistRefId.length ||
              duplicateData.length ||
              emailPattern.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                  }}
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : (
              <div className="button-container" />
            )}
            {steps === 3 ? <div className="button-container" /> : null}

            {steps === 0 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(0);
                    setSpreadSheet([]);
                    setAddStudents([]);
                    setUpload(null);
                  }}
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    setSteps(state => state + 1);
                  }}
                />
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  isDisabled={isValidating}
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                  }}
                >
                  <span className="cancel1-button-text">Upload Again</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Add Students"
                  onClick={() => {
                    setIsValidating(true);
                    dispatch(
                      spreadSheetStudentsCheck(spreadSheet, () => {
                        setTimeout(() => {
                          setIsValidating(false);
                          setSteps(state => state + 1);
                        }, 3000);
                      }),
                    );
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !alreadyExistEmail?.length &&
            !emailPattern?.length &&
            !duplicateData?.length &&
            !alreadyExistRefId?.length ? (
              <div className="button-container">
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    showToast(
                      AddToastSuccessImg,
                      `${validStudent.length} Students added successfully`,
                    );
                    setSteps(state => state + 1);
                    history.replace('/admin/users/student');
                    setIsValidating(true);
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validStudent?.length &&
            (alreadyExistEmail?.length ||
              alreadyExistRefId?.length ||
              duplicateData?.length ||
              emailPattern?.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                  }}
                >
                  <span className="cancel1-button-text">
                    Discard and Reupload
                  </span>
                </CustomCancel>
                <CustomButton
                  isDisabled={
                    !spreadSheet?.length ||
                    nextShow ||
                    isValidating ||
                    !validStudent?.length
                  }
                  className="save-changes"
                  text="Add valid students"
                  onClick={() => {
                    if (validStudent.length > 0 && !isAddStudentsLoading) {
                      dispatch(
                        addStudentSpread(validStudent, () => {
                          showToast(
                            AddToastSuccessImg,
                            `${validStudent.length} Students added successfully`,
                          );
                          history.replace('/admin/users/student');
                          setSpreadSheet([]);
                        }),
                      );
                    }
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            !validStudent?.length &&
            (alreadyExistEmail?.length ||
              alreadyExistRefId?.length ||
              duplicateData?.length ||
              emailPattern.length) ? (
              <div className="button-container">
                <CustomButton
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    history.replace('/admin/users/student');
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </AddStudentFooterContainer>
  );
};
