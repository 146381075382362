import styled from 'styled-components';

export const AssessmentCardContainer = styled.div`
  .btn {
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
  .delete-modal {
    flex-direction: column;
  }
  .delete-stream-modal {
    width: 100% !important;
    /* justify-content: center; */
  }
  .close-btn-stream {
    display: none;
  }
  background: #fff;
  border-radius: 0.75rem;
  box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  margin: 1.5rem 0;
  margin-top: ${({marginTop}) => marginTop};

  @media (max-width: 43.75rem) {
    .delete-modal {
      width: 90% !important;
      .delete-stream-modal {
        width: 100% !important;
        .delete-modal-close {
          display: none;
        }
        .delete-modal-header {
          .delete-icon {
            height: 3.06rem;
            width: 3.06rem;
          }
        }
      }
      .close-btn-stream {
        margin-bottom: 0.375rem;
        display: flex;
        justify-content: flex-end;
        button {
          background-color: transparent;
          border: none;
          padding: 0;

          :active,
          :focus {
            outline: none;
          }

          img {
            height: 1.5rem;
            vertical-align: unset;
            width: 1.5rem;
          }
        }
      }
    }
  }
  .delete-modal-header {
    align-items: flex-start;
    display: flex;
    padding: 1.5rem 1.5rem 2.625rem 1.5rem;

    & > img {
      height: 3.687rem;
      margin: 0.187rem 0.5rem 0 0;
      width: 3.687rem;
    }

    .delete-modal-text {
      flex-grow: 1;

      h3 {
        color: ${({theme}) => theme.colors.text};
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.58;
        margin-bottom: 0.125rem;
      }

      p {
        color: #aaaaaa;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0;
      }
    }

    .delete-modal-close {
      background-color: transparent;
      border: none;
      padding: 0;

      :active,
      :focus {
        outline: none;
      }

      img {
        height: 1.5rem;
        vertical-align: unset;
        width: 1.5rem;
      }
    }
  }

  .delete-modal-footer {
    border-top: 0.0625rem solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;

    .cancel-button {
      background-color: #fff !important;
      border: 0.0625rem solid #c1ced4;
      color: ${({theme}) => theme.colors.text};
      font-size: 0.875rem;
      height: 2.5rem;
      margin-right: 1rem;
    }

    .delete-button {
      background-color: #d64727 !important;
      font-size: 0.875rem;
      height: 2.5rem;
    }
  }
`;
