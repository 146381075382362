import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import {Color} from '../../../../../constants';

const SessionSuccessCard = styled(Card).attrs(() => ({
  className: 'py-4 px-5 d-flex align-items-center justify-content-center',
}))`
  border-radius: 0.75rem;
  .success-title {
    color: ${Color.PRIMARY_TEXT};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.8125rem;
  }
  .success-description {
    color: ${Color.SECONDARY_TEXT};
    font-size: 0.875rem;
    font-weight: 400;
  }
`;
export default SessionSuccessCard;
