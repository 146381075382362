import axios from '../index';

export const commentOnActivity = (activityId, comment) =>
  axios.post(`api/class/${activityId}/comment`, {
    comment,
  });

export const getComments = (activityId, offset) =>
  axios.get(`api/class/${activityId}/getComments?limit=10&offset=${offset}`);

export const deleteAssessment = aouiID =>
  axios.delete(`api/class/${aouiID}/assessment/delete`);

export const deleteAssessmentComment = commentId =>
  axios.delete(`api/class/${commentId}/comment`);

export const fetchAllAssessment = (classId, offset) =>
  axios.get(
    `api/class/${classId}/assessments/getAllAssessments?page=${offset}`,
  );

export const fetchAssessmentForStudent = (classId, offset) =>
  axios.get(
    `/api/class/${classId}/fetchAllAssessmentForStudent?page=${offset}&limit=10`,
  );
