import styled from 'styled-components';

const StudentItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;

  .student-avatar {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(64, 161, 255);
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    margin: 0 0.5rem 0 1rem;
    img {
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
    }
  }

  .student-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .student-name {
      color: ${({theme}) => theme.colors.text};
      font-size: 0.875rem;
      line-height: 0.875rem;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    .student-username {
      color: ${({theme}) => theme.colors.lightText};
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }

  .student-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.625rem;
    object-fit: contain;

    :last-child {
      margin-right: 1.5rem;
    }
  }

  .item-icon {
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    margin-right: 0.625rem;
    background-color: transparent;

    :focus,
    :active {
      outline: none;
    }

    :last-child {
      margin-right: 1rem;
    }

    .student-icon {
      width: 100%;
      height: 100%;
      margin-right: 0;
    }
  }
`;

export default StudentItemContainer;
