/* eslint-disable react/prop-types */
import React from 'react';
import {TaskSubmissionFooterContainer} from './elements';
import FileUploadToastIcon from '../../../../../../../../../../assets/icons/class-success.svg';
import {showToast} from '../../../../../../../../../../components/common/Toast';
import {
  removeFileFromTask,
  setSelectedFile,
  setNewLinkedFileToTask,
} from '../../../../../../../../../../redux/studentWebSlice';

export const TaskSubmissionFooter = ({
  steps,
  setSteps,
  upload,
  setUpload,
  isTaskLinked,
  history,
  taskId,
  assessmentID,
  classID,
  reused,
  fileImport,
  fileName,
  setStepsForReuse,
  stepsForReuse,
  selectedReusedFile,
  setSelectedReusedFile,
  dispatch,
  linkedFileId,
  setIsTaskLinked,
  setAddToPortFolio,
}) => (
  <TaskSubmissionFooterContainer className="fixed-bottom">
    <div className="footer-buttons">
      {fileImport && steps === 0 ? (
        <>
          <button
            className="footer-resub-button"
            onClick={() => {
              history.replace(
                `/stream/${classID}/student/assessment/${assessmentID}`,
              );
            }}
            type="button"
          >
            Cancel
          </button>
          <button
            className="footer-button"
            onClick={() => {
              setSteps(1);
            }}
            style={
              !upload || !fileName.length
                ? {
                    backgroundColor: '#80d5ff',
                    cursor: 'not-allowed',
                  }
                : {}
            }
            type="button"
            disabled={!upload || !fileName.length}
          >
            Next
          </button>
        </>
      ) : null}
      {fileImport && steps === 1 ? (
        <>
          <button
            className="footer-resub-button"
            onClick={() => {
              if (!isTaskLinked) {
                setSteps(0);
                setUpload(null);
              } else {
                dispatch(
                  removeFileFromTask(
                    assessmentID,
                    taskId,
                    linkedFileId.fileId,
                    linkedFileId.fileLinkId,
                    () => {
                      setSteps(0);
                      setUpload(null);
                      setAddToPortFolio(false);
                      setIsTaskLinked(false);
                    },
                  ),
                );
              }
            }}
            type="button"
          >
            Cancel
          </button>
          <button
            className="footer-button"
            onClick={() => {
              if (isTaskLinked) {
                showToast(
                  FileUploadToastIcon,
                  'File linked successfully ',
                  false,
                );
                history.replace(
                  `/stream/${classID}/student/assessment/${assessmentID}`,
                );
              }
            }}
            style={
              !isTaskLinked
                ? {
                    backgroundColor: '#80d5ff',
                    cursor: 'not-allowed',
                  }
                : {}
            }
            type="button"
          >
            Next
          </button>
        </>
      ) : null}
      {reused && stepsForReuse === 0 ? (
        <>
          <button
            className="footer-resub-button"
            onClick={() => {
              history.replace(
                `/stream/${classID}/student/assessment/${assessmentID}`,
              );
            }}
            type="button"
          >
            Cancel
          </button>
          <button
            className="footer-button"
            onClick={() => {
              setStepsForReuse(1);
            }}
            style={
              !selectedReusedFile
                ? {
                    backgroundColor: '#80d5ff',
                    cursor: 'not-allowed',
                  }
                : {}
            }
            type="button"
          >
            Next
          </button>
        </>
      ) : null}
      {reused && stepsForReuse === 1 ? (
        <>
          <button
            className="footer-resub-button"
            onClick={() => {
              if (!isTaskLinked) {
                setStepsForReuse(0);
                setSelectedReusedFile(null);
                dispatch(setSelectedFile(null));
              } else {
                history.replace(
                  `/stream/${classID}/student/assessment/${assessmentID}`,
                );
              }
            }}
            type="button"
          >
            Cancel
          </button>
          <button
            className="footer-button"
            onClick={() => {
              if (isTaskLinked) {
                showToast(
                  FileUploadToastIcon,
                  `${selectedReusedFile?.fileType?.ft_type} linked successfully `,
                  false,
                );
                dispatch(setNewLinkedFileToTask(null));
                history.replace(
                  `/stream/${classID}/student/assessment/${assessmentID}`,
                );
              }
            }}
            style={
              !isTaskLinked
                ? {
                    backgroundColor: '#80d5ff',
                    cursor: 'not-allowed',
                  }
                : {}
            }
            type="button"
          >
            Next
          </button>
        </>
      ) : null}
    </div>
  </TaskSubmissionFooterContainer>
);
