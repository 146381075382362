/* eslint-disable react/prop-types */
import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import MainInput from '../../../../../components/shared/Inputs/MainInput';
import AssessmentStudentCont from '../Elements/AssessmentStudentCont';
import CheckBox from '../../../../../components/shared/CheckBox';
import {
  addAssessmentStudents,
  removeAssessmentStudents,
  onFilteredAssessmentStudents,
  setSelectedStudents,
} from '../Assessment/slices/assessmentSlice';
import ProfileImg from '../../../../../assets/icons/profile.svg';
import allStudentsImg from '../../../../../assets/icons/all-students.svg';
import arrowDown from '../../../../../assets/icons/arrow_down.svg';

const AssessmentStudentsSection = ({hasError, clearError}) => {
  const dispatch = useDispatch();
  const {
    assessmentStudents,
    activityOrgunitInstanceId,
    filteredAssessmentStudents,
    selectedStudents,
  } = useSelector(state => state.assessment);
  const {pathname} = useHistory().location;
  const unitId = useMemo(() => pathname.split('/')[2], [pathname]);
  const [open, setOpen] = useState(false);
  const [studentsToAdd, setStudentsToAdd] = useState([]);
  const [studentsToRemove, setStudentsToRemove] = useState([]);
  const [studentsError, setStudentsError] = useState(null);
  const debouncedAddAndRemoveStudents = useCallback(
    (toAdd, toRemove) => {
      dispatch(
        addAssessmentStudents(activityOrgunitInstanceId, unitId, {
          students: toAdd,
        }),
      );
      dispatch(
        removeAssessmentStudents(
          {
            students: toRemove,
          },
          unitId,
          activityOrgunitInstanceId,
        ),
      );
      setStudentsToAdd([]);
      setStudentsToRemove([]);
    },
    [activityOrgunitInstanceId, dispatch, unitId],
  );

  const handleBlur = useCallback(
    event => {
      event.target.placeholder =
        selectedStudents.length === assessmentStudents.length
          ? 'All Students'
          : `${selectedStudents.length} Students`;
      event.target.classList.remove('is-focused');
    },
    [assessmentStudents.length, selectedStudents.length],
  );

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (assessmentStudents?.length) {
      dispatch(
        setSelectedStudents(
          assessmentStudents
            .filter(student => student.activityOrgUnitInstanceStudent.length)
            .map(student => student.up_id_userprofile),
        ),
      );
    }
  }, [assessmentStudents, dispatch]);

  useEffect(() => {
    /**
     * close modal if clicked on outside of element
     */
    const handleClickOutside = event => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !event.target.classList.contains('arrow-down') &&
        !event.target.classList.contains('select-students-input')
      ) {
        debouncedAddAndRemoveStudents(studentsToAdd, studentsToRemove);
        setOpen(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    debouncedAddAndRemoveStudents,
    studentsToAdd,
    studentsToRemove,
    wrapperRef,
  ]);

  /**
   * Adds a student to the list of students to be added to the assessment and
   * debounces the API call to add them.
   *
   * @param {{orgUnitInstanceStudentId: string, userProfileStudentId: string}} student
   * @param {number} activityOrgUnitInstanceStudentId
   */
  const handleAddStudent = (student, activityOrgUnitInstanceStudentId) => {
    dispatch(
      setSelectedStudents([...selectedStudents, student.userProfileStudentId]),
    );

    // The student is appended to the list of students to be added.
    const toAdd = activityOrgUnitInstanceStudentId
      ? studentsToAdd
      : [...studentsToAdd, student];

    // The student is filtered out from the list of students to be removed.
    const toRemove = studentsToRemove.filter(
      item => item !== activityOrgUnitInstanceStudentId,
    );

    setStudentsToRemove(toRemove);
    setStudentsToAdd(toAdd);
  };

  /**
   * Removes a student from the list of students to be removed from the
   * assessment and debounces the API call the remove them.
   *
   * @param {number} student
   * @param {string} userProfileId
   */
  const handleRemoveStudent = (student, userProfileId) => {
    const filterStudent = selectedStudents.filter(id => id !== userProfileId);
    dispatch(setSelectedStudents(filterStudent));

    // The student is filtered out from the list of students to be added.
    const toAdd = studentsToAdd.filter(
      item => item.userProfileStudentId !== userProfileId,
    );

    // The student is appended to the list of students to be added.
    const toRemove = student
      ? [...studentsToRemove, student]
      : studentsToRemove;
    setStudentsToAdd(toAdd);
    setStudentsToRemove(toRemove);
  };

  /**
   * Debounces the API call to add or remove all students from the assessment.
   *
   * @param {function} event
   * @returns {void}
   */
  const handleAddOrRemoveAllStudents = event => {
    if (event.target.checked) {
      /**
       * The students to be added must not have been queued for addition, must
       * not have been added to the database, and must not have been queued for
       * removal.
       */
      let toAdd = assessmentStudents.filter(student => {
        const activityOrgUnitInstanceStudentId =
          student.activityOrgUnitInstanceStudent[0]
            ?.aouis_id_activityorgunitinstancestudent;

        return (
          (!selectedStudents.includes(student.up_id_userprofile) ||
            !activityOrgUnitInstanceStudentId) &&
          !studentsToRemove.includes(activityOrgUnitInstanceStudentId)
        );
      });

      dispatch(
        setSelectedStudents(
          assessmentStudents.map(student => student.up_id_userprofile),
        ),
      );

      toAdd = toAdd.map(student => ({
        orgUnitInstanceStudentId: student.oruis_id_orgunitinstancestudent,
        userProfileStudentId: student.up_id_userprofile,
      }));

      setStudentsToRemove([]);
      setStudentsToAdd(toAdd);
    } else {
      /**
       * The students to be removed must not have been queued for removal, and
       * must have been added to the database.
       */
      let toRemove = assessmentStudents.filter(
        student =>
          selectedStudents.includes(student.up_id_userprofile) ||
          student.activityOrgUnitInstanceStudent[0]
            ?.aouis_id_activityorgunitinstancestudent,
      );

      dispatch(setSelectedStudents([]));

      toRemove = toRemove
        .map(
          student =>
            student.activityOrgUnitInstanceStudent[0]
              ?.aouis_id_activityorgunitinstancestudent,
        )
        .filter(
          activityOrgUnitInstanceStudentId => activityOrgUnitInstanceStudentId,
        );

      setStudentsToAdd([]);
      setStudentsToRemove(toRemove);
    }
  };

  useEffect(() => {
    if (studentsError !== 0) clearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsError]);

  return (
    <>
      {assessmentStudents.length !== 0 ? (
        <>
          <div className="d-flex">
            <MainInput
              className="w-100 select-students-input"
              type="text"
              id="assess-title"
              name="assess-title"
              placeholder={
                selectedStudents.length === assessmentStudents.length
                  ? 'All Students'
                  : `${selectedStudents.length} Students`
              }
              autoComplete="off"
              onBlur={handleBlur}
              onClick={() => setOpen(true)}
              onChange={e =>
                dispatch(onFilteredAssessmentStudents(e.target.value))
              }
              onFocus={event => {
                event.target.classList.add('is-focused');
                event.target.placeholder = 'Search';
              }}
            />
            <div
              className="d-flex select-students-arrow"
              role="button"
              tabIndex={0}
              onKeyDown={null}
              id="student-dropdown-id"
              onClick={() => setOpen(state => !state)}
            >
              <img className="arrow-down" src={arrowDown} alt="arrow-down" />
            </div>
          </div>
          {hasError || studentsError === 0 ? (
            <span className="validation-error">Select Atleast One Student</span>
          ) : null}
          {open && (
            <div className="assessment-students-dropdown" ref={wrapperRef}>
              <AssessmentStudentCont>
                <img src={allStudentsImg} alt="user-profile-pic" />
                <div>
                  <div className="student-name">All Students</div>
                  <div className="student-id">
                    {filteredAssessmentStudents.length} Students
                  </div>
                </div>
                <div className="ml-auto pr-2">
                  <CheckBox
                    id="all-check"
                    checked={
                      selectedStudents.length === assessmentStudents.length
                    }
                    onChange={event => {
                      handleAddOrRemoveAllStudents(event);

                      setStudentsError(
                        event.target.checked ? assessmentStudents.length : 0,
                      );
                    }}
                  />
                </div>
              </AssessmentStudentCont>
              {filteredAssessmentStudents.map(student => (
                <AssessmentStudentCont key={student.up_id_userprofile}>
                  <img src={ProfileImg} alt="user-profile-pic" />
                  <div>
                    <div className="student-name">{`${student.up_name_first} ${student.up_name_last}`}</div>
                    <div className="student-id">{`${student.up_org_reference}`}</div>
                  </div>
                  <div className="ml-auto pr-2">
                    <CheckBox
                      id={student.up_id_user}
                      checked={selectedStudents.includes(
                        student.up_id_userprofile,
                      )}
                      onChange={event => {
                        if (event.target.checked) {
                          handleAddStudent(
                            {
                              orgUnitInstanceStudentId:
                                student.oruis_id_orgunitinstancestudent,
                              userProfileStudentId: student.up_id_userprofile,
                            },
                            student.activityOrgUnitInstanceStudent[0]
                              ?.aouis_id_activityorgunitinstancestudent,
                          );
                        } else {
                          handleRemoveStudent(
                            student.activityOrgUnitInstanceStudent[0]
                              ?.aouis_id_activityorgunitinstancestudent,
                            student.up_id_userprofile,
                          );
                        }
                        const selectedCount = assessmentStudents.filter(
                          item => {
                            if (
                              item.up_id_userprofile ===
                              student.up_id_userprofile
                            ) {
                              if (event.target.checked) {
                                return true;
                              }
                              return false;
                            }

                            return selectedStudents.includes(
                              item.up_id_userprofile,
                            );
                          },
                        ).length;

                        setStudentsError(selectedCount);
                      }}
                    />
                  </div>
                </AssessmentStudentCont>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="validation-error mb-3">
          This unit has no students found
        </div>
      )}
    </>
  );
};

export default AssessmentStudentsSection;
