import styled from 'styled-components';

export const AddSessionFooterContainer = styled.div`
  .footer-container {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 3rem 1.5rem 3rem;

    @media (max-width: 41.25rem) {
      & {
        width: 100%;
        padding: 1.5rem;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .button-container {
      display: flex;
      flex-direction: row;
    }
    .button {
      padding: 0.875rem 1.25rem;

      &.cancel-button {
        background-color: white;
        border: 0.0625rem solid ${({theme}) => theme.colors.darkBorder};

        :active,
        :focus {
          border-color: ${({theme}) => theme.colors.primary};
        }

        .label {
          color: ${({theme}) => theme.colors.text};
        }
      }
    }
  }

  @media (max-width: 41.25rem) {
    .footer {
      display: flex;
      flex-direction: column;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
