/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import UserIcon from '../../../../../../../../../../assets/icons/user-profile-icon.svg';
import {StudentsContainer} from './elements';
import StudentsProfile from './components';

export const StudentsSection = ({students, studentsSubmission}) => {
  const totalStudents = useMemo(
    () => students[0]?.totalStudentsInUnit,
    [students],
  );
  const taskStudents = useMemo(() => students[0]?.students, [students]);
  const submittedStudents = useMemo(
    () => students[0]?.students?.filter(data => data.aouis_status === 2),
    [students],
  );
  const pendingStudents = useMemo(
    () => students[0]?.students?.filter(data => data.aouis_status === 1),
    [students],
  );

  return (
    <StudentsContainer>
      <div className="header-section">
        <div className="image">
          <img src={UserIcon} alt="general" className="user-icon" />
        </div>
        <div>
          <p className="header">Students</p>
        </div>
      </div>
      <div className="seperator" />
      <div className="content-section">
        <div className="first-div">
          <div className="first-content">
            <div className="title-div">
              <span>1</span>
              <p className="title">Total No of Students in Unit</p>
            </div>
            <StudentsProfile
              students={totalStudents || []}
              totalNumberOfStudents={totalStudents?.length}
            />
          </div>
        </div>
        <div className="between-div">
          <div className="first-content">
            <div className="title-div">
              <span>2</span>
              <p className="title">Assigned Students</p>
            </div>
            <StudentsProfile
              students={taskStudents || []}
              totalNumberOfStudents={taskStudents?.length}
            />
          </div>
        </div>
        <div className="between-div">
          <div className="first-content">
            <div className="title-div">
              <span>3</span>
              <p className="title">No of Students submitted</p>
            </div>
            <StudentsProfile
              students={submittedStudents || []}
              totalNumberOfStudents={submittedStudents?.length}
            />
          </div>
        </div>
        <div className="between-div">
          <div className="first-content">
            <div className="title-div">
              <span>4</span>
              <p className="title">
                No of Students who completed all tasks against perfomance
                criteria
              </p>
            </div>
            <StudentsProfile
              students={studentsSubmission || []}
              totalNumberOfStudents={studentsSubmission?.length}
            />
          </div>
        </div>
        <div className="last-div">
          <div className="first-content">
            <div className="title-div">
              <span>5</span>
              <p className="title">Students Pending to submit</p>
            </div>
            <StudentsProfile
              students={pendingStudents || []}
              totalNumberOfStudents={pendingStudents?.length}
            />
          </div>
        </div>
      </div>
    </StudentsContainer>
  );
};
