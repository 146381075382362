import styled from 'styled-components';

const AssessmentStudentCont = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1.125rem;
  img {
    border-radius: 1rem;
    height: 2rem;
    margin-right: 0.625rem;
  }
  .student-name {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #3d4457;
  }
  .student-id {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    color: #aaa;
  }
  :first-child {
    margin-bottom: 1.5rem;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export default AssessmentStudentCont;
