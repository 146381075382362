import {createSlice} from '@reduxjs/toolkit';
import getUser from '../../api/users';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    value: 0,
    email: '',
    isLoading: false,
  },
  reducers: {
    increment: state => {
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
      state.isLoading = false;
    },
    loadingStatus: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  increment,
  decrement,
  incrementByAmount,
  updateEmail,
  loadingStatus,
} = homeSlice.actions;

export const fetchUser = () => dispatch => {
  dispatch(loadingStatus(true));
  getUser()
    .then(response => {
      const {data} = response;
      if (data.status) {
        const {user} = data;
        dispatch(updateEmail(user.us_email));
      }
    })
    .catch(() => {
      dispatch(loadingStatus(false));
    });
};

export const userInfo = state => state.home;

export default homeSlice.reducer;
