import styled from 'styled-components';

const FooterWrapper = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;

  button {
    border: none;
    height: 2.625rem;
    border-radius: 0.5rem;
    padding: 0.875rem 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
  }

  button:first-child {
    background-color: transparent;
    width: 7rem;
  }

  button:last-child {
    color: white;
    background-color: ${({theme}) => theme.colors.primary};
    width: 5.5rem;
  }
`;

export default FooterWrapper;
