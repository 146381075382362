/* eslint-disable */
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {useMemo, useState} from 'react';
import moment from 'moment';
import {ModalContainer} from './elements';
import {ReactComponent as CloseBtn} from '../../../../../../../../assets/icons/close-modal.svg';
import {Table} from '../../../../../../../../components/common';
import {TableSpinner} from '../../../../../../components-new';
import TextModal from '../TextModal';

const VerificationHistoryModal = ({
  show,
  onHide,
  title,
  taskLogs,
  isLoading,
}) => {
  let taskLogStatus;
  function renderStatus(status) {
    if (status === 1) {
      taskLogStatus = 'Pending';
    } else if (status === 2) {
      taskLogStatus = 'Assigned';
    } else if (status === 3) {
      taskLogStatus = 'Approved';
    } else if (status === 4) {
      taskLogStatus = 'Rejected';
    } else if (status === 5) {
      taskLogStatus = 'Completed';
    }
    return taskLogStatus;
  }
  const [textContent, setTextContent] = useState('');
  const [isTpdModalOpen, setIsTpdModalOpen] = useState(false);

  const columns = useMemo(
    () => [
      {Header: '#', accessor: (_row, i) => i + 1},
      {
        Header: 'Assignee',
        Cell: ({row: {original}}) => (
          <div>
            {`${original?.up_name_first} 
            ${original?.up_name_last}`}
          </div>
        ),
        accessor: 'user',
        className: 'assignee-name',
      },
      {
        Header: 'Status',

        Cell: ({row: {original}}) => {
          const invitationStatus = original?.tl_id_taskstatus;
          return (
            <div className="status-container">
              <div className={`status-${invitationStatus}`}>
                {renderStatus(original?.tl_id_taskstatus)}
              </div>
            </div>
          );
        },
        accessor: 'status',
        className: 'status',
      },
      {
        Header: 'Comments',

        Cell: ({value}) => (
          <div style={{whiteSpace: 'pre-line'}}>
            {value.slice(0, 100)}
            {value.length > 100 ? (
              <>
                ...
                <button
                  onClick={() => {
                    setTextContent(value);
                    setIsTpdModalOpen(true);
                  }}
                  type="button"
                >
                  See more
                </button>
              </>
            ) : (
              ''
            )}
          </div>
        ),
        accessor: 'tl_comments',
        className: 'comments',
      },
      {
        Header: 'Verified Time',

        Cell: ({value}) =>
          moment(new Date(value)).format('HH:MM A, D MMM YYYY'),
        accessor: 'tl_created_at',
        className: 'verified-time',
      },
    ],
    [],
  );
  const data = useMemo(
    () => [
      ...(taskLogs || []).map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [taskLogs],
  );

  return (
    <>
      <TextModal
        content={textContent}
        isVisible={isTpdModalOpen}
        onClose={() => setIsTpdModalOpen(false)}
        title="COMMENTS"
      />
      <Modal centered show={show} onHide={onHide}>
        <ModalContainer>
          <header>
            <div>
              <h1>{title}</h1>
              <p>Detailed verification status preview of TAS Document</p>
            </div>
            <CloseBtn onClick={onHide} />
          </header>
          {/* {error && 'Something went wrong, please try again later'} */}
          {(() => {
            if (isLoading) return <TableSpinner />;
            if (data.length === 0) return <p>No changes to show.</p>;
            return <Table columns={columns} data={data} isWithoutHeader />;
          })()}
        </ModalContainer>
      </Modal>
    </>
  );
};

VerificationHistoryModal.defaultProps = {
  show: false,
  onHide: () => {},
  title: 'Verfication History',
  tasid: '',
};

VerificationHistoryModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  tasid: PropTypes.string,
};

export default VerificationHistoryModal;
