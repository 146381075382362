import React from 'react';
import PropTypes from 'prop-types';
import {SectionContainer} from '../elements/ViewClassHeaderContainer';

export const Section = ({Icon, header, data}) => (
  <SectionContainer>
    <div className="view-list">
      <div className="view-img mt-1">
        <img src={Icon} alt="close" className="view-list-img" />
      </div>
      <div className="mx-2">
        <span className="overview-bottom-header">{header}</span>
        <p>{data}</p>
      </div>
    </div>
  </SectionContainer>
);

Section.propTypes = {
  Icon: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};
