/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import {AddAssessmentFooterContainer} from './elements';
import {Button} from '../../../../../../components/common';

import ArrowIcon from '../../../../../../assets/icons/nextarrow.svg';

export const AddAssessmentFooter = ({
  currentTab,
  onButtonClick,
  continueButtonOnClick,
  isTabSwitch,
  isUpdate,
  createdTasks,
}) => (
  <AddAssessmentFooterContainer>
    <div className="footer-container fixed-bottom">
      <div className="selected-students" />
      <div className="manage-footer">
        <div className="button-container">
          {currentTab === 'details' ? (
            <Button
              className={classNames('button', {
                // 'is-archive': isArchive,
              })}
              isFullWidth={false}
              label={isUpdate ? 'Save & Continue' : 'Update & Continue'}
              onClick={continueButtonOnClick}
              icon={ArrowIcon}
              iconPostion="right"
              type="button"
              key="continue"
              isDisabled={!isTabSwitch()}
            />
          ) : (
            <Button
              className={classNames('button', {
                // 'is-archive': isArchive,
              })}
              isFullWidth={false}
              label="Save and Close"
              onClick={onButtonClick}
              type="submit"
              isDisabled={!createdTasks?.length}
              iconPostion="right"
              key="publish"
            />
          )}
        </div>
      </div>
    </div>
  </AddAssessmentFooterContainer>
);
