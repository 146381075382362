/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch} from 'react-redux';
import {Controller} from 'react-hook-form';
import {Menu, MenuItem} from 'react-bootstrap-typeahead';

import {UploadCoverImage} from '../UploadCoverImage';
import {UploadSection} from '../UploadSection';
import {
  Select,
  TextInput,
  TypeAhead,
} from '../../../../../../../../components/common';
import {
  clearFormError,
  individualFileCodeValid,
  addNewCategory,
} from '../../../../../../../../redux/adminLibrarySlice';

import {AddFormContainer} from './elements';

export const AddForm = ({
  uploadDocument,
  setUploadDocument,
  uploadCoverImage,
  setUploadCoverImage,
  onDrag,
  setOnDrag,
  register,
  setValue,
  categoryDropdown,
  errors,
  categoryAsOptions,
  control,
  fileStatusOptions,
  classesAsOptions,
  formError,
  clearErrors,
  setFieldError,
  watch,
}) => {
  const dispatch = useDispatch();
  return (
    <AddFormContainer>
      <form>
        <div className="add-document-fields">
          <div className="mt-4">
            <UploadSection
              title="Add File"
              upload={uploadDocument}
              setUpload={setUploadDocument}
              onDrag={onDrag}
              setOnDrag={setOnDrag}
              accept=".doc,.csv,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf,application/msword,.jpg,.jpeg,.png,.mov,.mp4"
            />
          </div>
          <div className="upload-cover-image">
            <UploadCoverImage
              title="Cover Image"
              upload={uploadCoverImage}
              setUpload={setUploadCoverImage}
              accept=".jpg,.jpeg,.png"
            />
          </div>
          <div>
            <Controller
              control={control}
              name="fileStatus"
              render={fields => (
                <Select
                  {...fields}
                  className="mt-2"
                  isLarge={false}
                  label="File Type"
                  hasError={!!errors.fileStatus}
                  errorMessage={errors.fileStatus?.message}
                  placeholder="Select the File Type"
                  options={fileStatusOptions}
                  id="file-type"
                />
              )}
            />
          </div>
          {watch('fileStatus')?.value === 0 ? (
            <Controller
              control={control}
              name="className"
              render={fields => (
                <Select
                  {...fields}
                  isLarge={false}
                  label="Choose Class"
                  hasError={!!errors.className}
                  errorMessage={errors.className?.message}
                  placeholder="Select the Class"
                  options={classesAsOptions}
                  id="class-name"
                />
              )}
            />
          ) : null}
          <div className="title-code">
            <div className="code">
              <Controller
                control={control}
                name="fileCode"
                render={fields => (
                  <TextInput
                    {...fields}
                    errorMessage={
                      errors.fileCode?.message || formError.fileCode
                    }
                    hasError={!!errors.fileCode || !!formError?.fileCode}
                    label="Code"
                    placeholder="Enter Code"
                    onBlur={e => {
                      fields.onBlur(e);
                      dispatch(individualFileCodeValid(e.target.value));
                    }}
                    onChange={e => {
                      fields.onChange(e);
                      dispatch(clearFormError());
                    }}
                    id="file-code"
                  />
                )}
              />
            </div>
            <div className="document-title">
              <Controller
                control={control}
                name="documentTitle"
                render={fields => (
                  <TextInput
                    {...fields}
                    errorMessage={errors.documentTitle?.message}
                    hasError={!!errors.documentTitle}
                    label="File Title"
                    placeholder="Enter the File Title"
                    id="file-title"
                  />
                )}
              />
            </div>
          </div>
          <TypeAhead
            errorMessage={
              !watch('fileCategory') ? errors.fileCategory?.message : null
            }
            hasError={!!errors.fileCategory}
            label="Category"
            placeholder="Select category"
            id="file-category-id"
            labelKey="name"
            onInputChange={value => {
              if (value) {
                clearErrors('fileCategory');
              } else {
                setFieldError('fileCategory', 'Select the Category');
              }
            }}
            ref={categoryDropdown}
            options={categoryAsOptions}
            onChange={e => {
              if (e) {
                clearErrors('fileCategory');
                if (
                  categoryAsOptions.find(
                    item =>
                      item?.name?.toLowerCase() === e[0]?.name?.toLowerCase(),
                  )
                ) {
                  setValue('fileCategory', e[0]);
                }
              }
            }}
            renderMenu={(results, menuProps, e) => {
              if (!results?.length)
                return (
                  <Menu {...menuProps} className="add-menu">
                    <MenuItem className="add-menu-item">
                      <button
                        type="button"
                        className="add-category-btn"
                        onClick={() => {
                          const newCategory = e.text;
                          dispatch(
                            addNewCategory(newCategory, data => {
                              categoryDropdown.current.hideMenu();
                              setValue('fileCategory', {
                                name: data?.fc_name,
                                id: data?.fc_id_filecategory,
                              });
                            }),
                          );
                        }}
                      >
                        {`Add ${e.text} as a new Category`}
                      </button>
                    </MenuItem>
                  </Menu>
                );

              return (
                <Menu {...menuProps} className="add-menu-list">
                  {results.map((result, index) => (
                    <MenuItem option={result} position={index}>
                      {result.name}
                    </MenuItem>
                  ))}
                  {!categoryAsOptions.find(
                    item => item?.name?.toLowerCase() === e.text.toLowerCase(),
                  ) ? (
                    <MenuItem className="add-menu-item-list">
                      <button
                        type="button"
                        className="add-category-btn"
                        onClick={() => {
                          const newCategory = e.text;
                          dispatch(
                            addNewCategory(newCategory, data => {
                              categoryDropdown.current.hideMenu();
                              setValue('fileCategory', {
                                name: data?.fc_name,
                                id: data?.fc_id_filecategory,
                              });
                            }),
                          );
                        }}
                      >
                        {`Add ${e.text} as a new Category`}
                      </button>
                    </MenuItem>
                  ) : null}
                </Menu>
              );
            }}
            dropUp
          />
          <input type="hidden" {...register('fileCategory')} />
        </div>
      </form>
    </AddFormContainer>
  );
};
