/* eslint-disable react/prop-types */

import {useMemo, useState} from 'react';
import axios from '../../../../../../../../../../api';

import TextModal from '../TextModal';

const ColumnTableValue = ({text, title, unitId}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const textWithoutLineBreaks = useMemo(
    () => text.replaceAll('<br />', ' '),
    [text],
  );

  return (
    <>
      <div className="column-table-value" style={{whiteSpace: 'pre-line'}}>
        {textWithoutLineBreaks.slice(0, 56)}
        {textWithoutLineBreaks.length > 56 ? (
          <>
            ...
            <button
              onClick={() => {
                setIsModalOpen(true);
                if (unitId) {
                  axios
                    .post(`/api/admin/tas/assessment-map/${unitId}`)
                    .then(({data}) => {
                      setModalData(
                        data.assessmentMap?.assessmentConditions || [],
                      );
                    });
                }
              }}
              type="button"
            >
              See more
            </button>
          </>
        ) : (
          ''
        )}
      </div>
      <TextModal
        content={modalData}
        isVisible={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={title}
      />
    </>
  );
};

export default ColumnTableValue;
