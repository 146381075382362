/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {FooterContainer} from './elements';
import {Button} from '../../../../../../../../../../components/common';
import {showToast} from '../../../../../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../../../../../assets/icons/addtoastadmin.svg';
import {ProgressLine} from '../../../../components';
import {addUnitsToClass} from '../../../../../../../../../../redux/classesSlice';

// const DELAY = 500;

export const Footer = ({
  progress,
  isAddClassLoading,
  setProgress,
  setAddClassStep,
  orgUnit,
  // newClassUnitsCount,
  currentClass,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onButtonCancel = () => {
    history.replace(`/admin/classes`);
    showToast(
      AddToastSuccessImg,
      `${currentClass?.classData?.classname} added successfully`,
    );
  };
  const onButtonClick = useCallback(() => {
    if (Object.keys(orgUnit).length && !isAddClassLoading) {
      dispatch(
        addUnitsToClass(currentClass, orgUnit, () => {
          setAddClassStep(2);
          setProgress(2);
        }),
      );
    }
  }, [
    orgUnit,
    isAddClassLoading,
    dispatch,
    currentClass,
    setAddClassStep,
    setProgress,
  ]);

  return (
    <FooterContainer>
      <div className="footer-container fixed-bottom">
        <div className="footer">
          <div>
            <ProgressLine progress={progress} />
          </div>
          <div className="button-container">
            <Button
              className="button cancel-button mr-3"
              isFullWidth={false}
              onClick={onButtonCancel}
              label="Cancel"
              id="cancel"
            />
            <Button
              className="button"
              isFullWidth={false}
              isLoading={isAddClassLoading}
              onClick={onButtonClick}
              label="Add Units"
              isDisabled={!Object.keys(orgUnit).length}
              id="add-units"
            />
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};
