/* eslint-disable react/prop-types */
import React from 'react';
import YellowTickImg from '../../../../../../assets/icons/yellowdonemark.svg';
import {NoUserAddedContainer} from './elements';

export const NoUserAdded = ({title, subtitle}) => (
  <NoUserAddedContainer>
    <img src={YellowTickImg} alt="green tick" />
    <h2 className="mt-3 header">{title}</h2>
    <p className="state">{subtitle}</p>
  </NoUserAddedContainer>
);
