import styled from 'styled-components';

export const TextModalContainer = styled.div`
  display: flex;
  .text-modal-custom-full {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 50rem) {
    .text-modal-container {
      width: 100% !important;
    }
    .text-modal-custom-full {
      width: 50% !important;
    }
  }
  @media (max-width: 43.75rem) {
    .text-modal-container {
      width: 100% !important;
    }
    .text-modal-custom-full {
      width: 90% !important;
      margin-top: 7%;
      display: flex;
      flex-direction: column;

      .modal-close-button {
        display: none;
      }
    }
  }

  .text-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .text-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .text-modal-title {
        align-items: center;
        display: flex;

        img {
          height: 1.5rem;
          margin-right: 0.5rem;
          width: 1.5rem;
        }

        h2 {
          color: ${({theme}) => theme.colors.text};
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 1.21;
          margin-bottom: 0;
        }
      }

      .modal-close-button {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .text-instruction-input {
      border: 0.0625rem solid #dedede;
      border-radius: 0.3125rem;
      color: ${({theme}) => theme.colors.text};
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
      padding: 0.687rem 0 0 1rem;
      resize: none;
      transition: border-color 0.1s, box-shadow 0.1s;
      width: 100%;

      :-moz-placeholder {
        color: '#aaa';
      }

      :-ms-input-placeholder {
        color: '#aaa';
      }

      ::-moz-placeholder {
        color: '#aaa';
      }

      ::-webkit-input-placeholder {
        color: '#aaa';
      }

      :active,
      :focus {
        border-color: ${({theme}) => theme.colors.primary};
        box-shadow: 0 0 0 0.125rem ${({theme}) => theme.colors.lightPrimary};
        outline: none;
      }
    }

    .modal-buttons {
      display: flex;
      align-self: flex-end;

      .cancel-button {
        background-color: transparent;
        border: none;
        color: ${({theme}) => theme.colors.text};
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.62;
        margin-right: 2.5rem;
        transition: color 0.1s;

        :focus,
        :active {
          outline: none;
        }
      }
    }
  }
`;
