/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import {useRef, useEffect, useMemo, useContext} from 'react';
import {SessionPeerVideoWrapper} from './elements';
import {ReactComponent as StudentMutedIcon} from '../../../../../../../../assets/icons/student-muted.svg';
import {ReactComponent as StudentUnMutedIcon} from '../../../../../../../../assets/icons/student-unmuted.svg';
import {ReactComponent as SpeakingIcon} from '../../../../../../../../assets/icons/speaking.svg';
import {ReactComponent as HostIdentification} from '../../../../../../../../assets/icons/host-identification.svg';
import {LiveSessionContext} from '../../../../contexts/LiveSessionContext';

const SessionPeerVideo = ({audioTrack, videoTrack, uid, speakingStates}) => {
  const ref = useRef();

  const {hostDetails, sessionParticipants, userId} =
    useContext(LiveSessionContext);

  const participantDetails = useMemo(() => {
    if (sessionParticipants) {
      return sessionParticipants.find(
        participant => participant?.up_id_userprofile === uid,
      );
    }
    return null;
  }, [sessionParticipants, uid]);

  const isHost = useMemo(() => hostDetails.id === uid, [hostDetails, uid]);

  useEffect(() => {
    if (!ref.current) return null;
    if (videoTrack) videoTrack?.play(ref.current);
    return () => {
      if (videoTrack) {
        videoTrack?.stop();
      }
    };
  }, [ref, videoTrack]);

  useEffect(() => {
    if (participantDetails && userId !== participantDetails.up_id_userprofile) {
      if (audioTrack) audioTrack?.play();
    }
    return () => audioTrack?.stop();
  }, [audioTrack, participantDetails, userId]);

  return (
    <SessionPeerVideoWrapper ref={ref}>
      <div className="peer-info">
        {participantDetails ? (
          <span className={isHost ? 'host' : undefined}>
            {isHost && <HostIdentification />}
            {`${participantDetails.up_name_first} ${participantDetails.up_name_last}`}
          </span>
        ) : null}
        <span>
          {(() => {
            if (!audioTrack || audioTrack.muted) {
              return <StudentMutedIcon />;
            }
            if (speakingStates[uid]) {
              return <SpeakingIcon />;
            }
            return <StudentUnMutedIcon />;
          })()}
        </span>
      </div>
    </SessionPeerVideoWrapper>
  );
};

SessionPeerVideo.defaultProps = {
  audioTrack: null,
  videoTrack: null,
  uid: '',
  speakingStates: {},
};

SessionPeerVideo.propTypes = {
  audioTrack: PropTypes.objectOf(PropTypes.any),
  videoTrack: PropTypes.objectOf(PropTypes.any),
  uid: PropTypes.string,
  speakingStates: PropTypes.objectOf(PropTypes.any),
};

export default SessionPeerVideo;
