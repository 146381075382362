import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const AllQualificationsContainer = styled(AdminListingPageContainer)`
  .index {
    width: 4%;
  }

  .qualification-code {
    width: 15%;
  }

  .qualification-name {
    width: 37%;
  }

  .qualification-releases {
    width: 25%;
  }

  .unit-count {
    width: 15%;
  }

  .more-options {
    width: 4%;
  }
`;
