/* eslint-disable react/prop-types */
import React from 'react';

export const InstructionType = ({icon, title, ...properties}) => (
  <button
    className="instruction-type mr-3"
    type="button"
    {...properties}
    id="instruction-type-id"
  >
    <img alt="Instruction Type" src={icon} />
    <span>{title}</span>
  </button>
);
