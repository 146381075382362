import axios from '../..';

export const deleteQualification = qualificationId =>
  axios.delete(`api/admin/deleteQualifications/${qualificationId}`);

export const fetchAllQualifications = () =>
  axios.get('api/admin/qualifications');

export const updateQualification = (qualificationId, data) =>
  axios.patch(`api/admin/editQualifications/${qualificationId}`, data);

export const individualQualificationValid = data =>
  axios.post(
    `api/admin/addQualifications/individualQualificationValidation`,
    data,
  );
export const addOrgQualification = data =>
  axios.post(`api/admin/addQualifications`, data);

export const addQualificationsSpread = data =>
  axios.post(`api/admin/addQualificationsSpread`, data);

export const spreadSheetQualificationsValid = data =>
  axios.post(
    `api/admin/qualification/qualificationSpreadSheetValidation`,
    data,
  );

export const unitSpreadsheetValidation = (data, qualificationId) =>
  axios.post(
    `api/admin/addQualifications/unitSpreadSheetValidation/${qualificationId}`,
    data,
  );

export const individualUnitValid = (data, qualificationId) =>
  axios.post(
    `api/admin/addQualifications/individualUnitValidation/${qualificationId}`,
    data,
  );
export const addUnitsUnderQualificationManual = (data, qualificationId) =>
  axios.post(
    `api/admin/addQualifications/addUnitsManual/${qualificationId}`,
    data,
  );

export const addUnitsUnderQualificationSpread = (data, qualificationId) =>
  axios.post(
    `api/admin/addQualifications/addUnitsSpreadSheet/${qualificationId}`,
    data,
  );
