import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const ListAssessmentsContainer = styled(AdminListingPageContainer)`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 4%;
  }

  .activity-name {
    width: 31%;
  }

  .unit-name {
    width: 31%;
  }

  .activity-type {
    width: 13%;
  }
  .createdby {
    width: 18%;
  }

  .more-option {
    width: 3%;
  }

  .code {
    font-size: 0.875rem;
    color: #8a8c94;
    margin-top: 0.5rem;
  }
  table {
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
`;
