import styled from 'styled-components';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;

  .message {
    margin-bottom: 0.5rem;
    align-self: flex-start;
    display: flex;

    &.is-previous-sender {
      margin-left: 2.625rem;
    }

    &.is-user {
      align-self: flex-end;

      .message-text {
        background-color: ${({theme}) => theme.colors.primary};
        color: white;
        max-width: 250px;
        overflow-wrap: break-word;
      }
    }

    .sender-avatar {
      background-color: ${({theme}) => theme.colors.primaryAlt};
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      margin-right: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 500;
      img {
        border-radius: 50%;
        width: 2.25rem;
        height: 2.25rem;
      }
    }

    .message-text {
      background-color: ${({theme}) => theme.colors.background};
      color: ${({theme}) => theme.colors.lightText};
      font-size: 0.875rem;
      line-height: 1.3125rem;
      border-radius: 1rem;
      padding: 0.75rem 1.25rem;
      max-width: 250px;
      overflow-wrap: break-word;
    }
  }

  .message-time {
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: #c9c9c9;
    margin: 0 0 1.5rem 2.625rem;
    align-self: flex-start;

    &.is-user {
      align-self: flex-end;
      margin-left: 0;
    }
  }
`;

export default MessageContainer;
