/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React from 'react';

import DocIcon from '../../../../../../../../assets/images/filetypes/doc.svg';
import PdfIcon from '../../../../../../../../assets/images/filetypes/pdf.svg';
import VideoIcon from '../../../../../../../../assets/images/filetypes/video.svg';
import CsvIcon from '../../../../../../../../assets/images/filetypes/csv.svg';
import ImgIcon from '../../../../../../../../assets/images/filetypes/img.svg';
import AudioIcon from '../../../../../../../../assets/icons/added-voice-instruction.svg';
import {FileCardContainer} from './elements';

const fileTypeToImgMap = {
  'application/pdf': PdfIcon,
  'application/msword': DocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    DocIcon,
  'text/csv': CsvIcon,
  // Images
  'image/jpeg': ImgIcon,
  'image/png': ImgIcon,
  'image/jpg': ImgIcon,
  'image/svg': ImgIcon,
  'image/svg+xml': ImgIcon,
  'image/gif': ImgIcon,
  'image/webp': ImgIcon,
  'image/tiff': ImgIcon,
  'image/psd': ImgIcon,
  'image/raw': ImgIcon,
  'image/bmp': ImgIcon,
  'image/heif': ImgIcon,
  'image/indd': ImgIcon,
  'image/jpeg 2000': ImgIcon,
  // Video
  'video/webm': VideoIcon,
  'video/mp4': VideoIcon,
  'video/mp2': VideoIcon,
  'video/mpeg': VideoIcon,
  'video/mpe': VideoIcon,
  'video/mpv': VideoIcon,
  'video/ogg': VideoIcon,
  'video/3gp': VideoIcon,
  'video/m4p': VideoIcon,
  'video/m4v': VideoIcon,
  'video/avi': VideoIcon,
  'video/wmv': VideoIcon,
  'video/mov': VideoIcon,
  'video/qt': VideoIcon,
  'video/flv': VideoIcon,
  'video/swf': VideoIcon,
  unknown: DocIcon,
};

export const FileCard = ({file, isEdit}) => {
  let fileURL;
  if (file) {
    fileURL =
      file?.type?.includes('image') ||
      file?.type?.includes('video') ||
      file?.type?.includes('Image') ||
      file?.type?.includes('Video')
        ? file?.location
        : fileTypeToImgMap[file?.type] || DocIcon;
    if (file?.type?.includes('Voice') || file?.type?.includes('audio')) {
      fileURL = AudioIcon;
    }
    if (!fileURL && isEdit) {
      fileURL = URL.createObjectURL(file);
    }
    if (!fileURL) {
      fileURL = URL.createObjectURL(file);
    }
  }
  return (
    <FileCardContainer>
      {file?.type?.includes('video') || file?.type?.includes('Video') ? (
        <div className="file-blob">
          <video src={fileURL} />
        </div>
      ) : (
        <img className="file-blob" src={fileURL} alt="card-img" />
      )}
      <p className="file-name">{`${file?.name?.substr(0, 30)}${
        file?.name?.length > 30
          ? ` ..${file?.name?.substring(file?.name?.lastIndexOf('.') + 1)}`
          : ''
      }`}</p>
    </FileCardContainer>
  );
};
