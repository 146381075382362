import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
  sessionsSelector,
  fetchCourses,
  fetchTeachersByUnitId,
  fetchClassesByCourseId,
  fetchUnitsByClassId,
} from '../../../../../../../../redux/sessionsSlice';
import {
  Select,
  TextInput,
  DateInput,
} from '../../../../../../../../components/common';

import {sessionSchema} from '../../../../../../../../utils/validation';
import {EditSessionContainer} from './elements';
import {TextArea} from '../../../../../../../../components/common/TextArea';

export const EditSessionModal = ({editDetails, onHide, onSubmit}) => {
  const dispatch = useDispatch();
  const {control, errors, handleSubmit, watch, setValue} = useForm({
    defaultValues: {
      sessionName: '',
      course: null,
      class: null,
      unit: null,
      teacher: null,
      date: '',
      startTime: '',
      endTime: '',
      description: '',
      // recurrence: null,
      // location: '',
    },
    resolver: yupResolver(sessionSchema),
  });
  const {
    courseOptions: courses,
    classOptions: classes,
    unitOptions: units,
    teacherOptions: teachers,
    isLoading,
  } = useSelector(sessionsSelector);

  const courseOptions = useMemo(
    () =>
      (courses || [])?.map(item => ({
        label: item.ocr_course_name,
        value: item.ocr_id_orgcourse,
        qualificationId: item.ocr_id_orgqual,
      })),
    [courses],
  );

  const classOptions = useMemo(
    () =>
      (classes || [])?.map(item => ({
        label: item.oc_class,
        value: item.oc_id_orgclass,
      })),
    [classes],
  );

  const unitOptions = useMemo(
    () =>
      (units || [])?.map(item => ({
        label: item.orgUnit.unit.tun_title,
        value: item.orui_id_orgunitinstance,
      })),
    [units],
  );

  const teacherOptions = useMemo(
    () =>
      (teachers || [])?.map(item => ({
        label: `${item.userProfile.up_name_first} ${item.userProfile.up_name_last}`,
        value: item.userProfile.up_id_userprofile,
      })),
    [teachers],
  );

  useEffect(() => {
    dispatch(fetchCourses());
  }, [dispatch]);

  useEffect(() => {
    if (editDetails) {
      setValue('class', {
        label: editDetails.class?.name,
        value: editDetails.class?.id,
      });
      setValue('course', {
        label: editDetails.course?.name,
        value: editDetails.course?.id,
      });

      setValue('sessionName', editDetails.sessionName);
      setValue('description', editDetails?.sessionDescription);
      // setValue('location', editDetails.location);

      setValue('unit', {
        label: editDetails.unit?.name,
        value: editDetails.unit?.id,
      });

      setValue('teacher', {
        label: editDetails.teacher?.name,
        value: editDetails.teacher?.id,
      });

      setValue('date', editDetails.date);

      // setValue('recurrence', {
      //   label: editDetails.recurrence?.name,
      //   value: editDetails.recurrence?.id,
      // });
      setValue('startTime', editDetails.startTime);
      setValue('endTime', editDetails.endTime);
    }
  }, [editDetails, setValue]);

  const course = watch('course');

  useEffect(() => {
    if (course) {
      dispatch(fetchClassesByCourseId(course.value));
    }
  }, [course, dispatch]);

  const classItem = watch('class');

  useEffect(() => {
    if (classItem) {
      dispatch(fetchUnitsByClassId(classItem.value));
    }
  }, [classItem, dispatch]);

  const unit = watch('unit');

  useEffect(() => {
    if (unit) {
      dispatch(fetchTeachersByUnitId(unit.value));
    }
  }, [unit, dispatch]);

  return (
    <EditSessionContainer
      buttonLabel="Save Changes"
      description="Make changes to the session details"
      isButtonLoading={isLoading}
      loadingButtonLabel="Saving"
      onButtonClick={handleSubmit(onSubmit)}
      onHide={onHide}
      show={!!editDetails}
      title="Edit session details"
      width="55.5rem"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container">
          <div className="mb-3">
            <Controller
              control={control}
              name="sessionName"
              render={fields => (
                <TextInput
                  {...fields}
                  autoComplete="off"
                  errorMessage={errors.sessionName?.message}
                  hasError={!!errors.sessionName}
                  label="Session Name"
                  placeholder="Enter session name"
                />
              )}
            />
          </div>
          <div className="row-container">
            <div className="field-left field-left1">
              <Controller
                control={control}
                name="course"
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.course}
                    errorMessage={errors.course?.message}
                    isLarge={false}
                    isDisabled
                    label="Course"
                    options={courseOptions}
                    placeholder="Choose a course"
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="class"
                isDisabled
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.class}
                    errorMessage={errors.class?.message}
                    isLarge={false}
                    isDisabled
                    label="Class"
                    options={classOptions}
                    placeholder="Choose a class"
                  />
                )}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="field-left field-left1">
              <Controller
                control={control}
                name="unit"
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.unit}
                    errorMessage={errors.unit?.message}
                    isLarge={false}
                    isDisabled
                    label="Unit"
                    options={unitOptions}
                    placeholder="Choose a unit"
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="teacher"
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.teacher}
                    errorMessage={errors.teacher?.message}
                    isLarge={false}
                    label="Teacher"
                    options={teacherOptions}
                    placeholder="Choose a teacher"
                  />
                )}
              />
            </div>
          </div>
          <div className="row-container">
            <div className="field-left">
              <Controller
                control={control}
                name="date"
                render={fields => (
                  <DateInput
                    {...fields}
                    hasError={!!errors.date}
                    errorMessage={errors.date?.message}
                    label="Date"
                    placeholder="Choose a date"
                    dateFormat="d MMMM, yyyy"
                    // minDate={minDate}
                    // maxDate={maxDate}
                  />
                )}
              />
            </div>
            <div className="field-right">
              <div className="row-container">
                <div className="field-left field-left1">
                  <Controller
                    control={control}
                    name="startTime"
                    render={fields => (
                      <TextInput
                        {...fields}
                        autoComplete="off"
                        errorMessage={
                          errors.startTime?.message
                          // || formError?.startTime
                        }
                        hasError={
                          !!errors.startTime
                          // || !!formError.startTime
                        }
                        label="Start Time"
                        placeholder="Choose a start time"
                        type="time"
                      />
                    )}
                  />
                </div>
                <div className="field-right">
                  <Controller
                    control={control}
                    name="endTime"
                    render={fields => (
                      <TextInput
                        {...fields}
                        autoComplete="off"
                        errorMessage={
                          errors.endTime?.message
                          // || formError?.endTime
                        }
                        hasError={
                          !!errors.endTime
                          // || !!formError.endTime
                        }
                        label="End Time"
                        placeholder="Choose a end time"
                        type="time"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row-container">
            <div className="field-left field-left1">
              <Controller
                control={control}
                name="recurrence"
                render={fields => (
                  <Select
                    {...fields}
                    hasError={!!errors.recurrence}
                    errorMessage={errors.recurrence?.message}
                    isLarge={false}
                    label="Recurrence"
                    isDisabled
                    // options={coursesOptions}
                    placeholder="Choose a recurrence"
                  />
                )}
              />
            </div>
            <div className="field-right">
              <Controller
                control={control}
                name="location"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoComplete="off"
                    errorMessage={
                      errors.location?.message
                      // || formError?.location
                    }
                    hasError={
                      !!errors.location
                      // || !!formError.location
                    }
                    label="Location"
                    placeholder="Enter a location"
                  />
                )}
              />
            </div>
          </div> */}
          <div className="mb-3">
            <Controller
              control={control}
              name="description"
              render={fields => (
                <TextArea
                  {...fields}
                  autoComplete="off"
                  errorMessage={errors.description?.message}
                  hasError={!!errors.description}
                  label="Description"
                  placeholder="Enter session description"
                  id="description-id"
                  rows="5"
                />
              )}
            />
          </div>
        </div>
      </form>
    </EditSessionContainer>
  );
};

EditSessionModal.defaultProps = {
  editDetails: null,
};

EditSessionModal.propTypes = {
  editDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,

    class: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,

    course: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,

    sessionName: PropTypes.string.isRequired,
    sessionDescription: PropTypes.string.isRequired,
    // location: PropTypes.string.isRequired,

    unit: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    // recurrence: PropTypes.shape({
    //   id: PropTypes.string.isRequired,
    //   name: PropTypes.string.isRequired,
    // }).isRequired,
    teacher: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    date: PropTypes.instanceOf(Date),
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
  }),
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
