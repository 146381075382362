import styled from 'styled-components';

export const ViewCurriculumFooterContainer = styled.div`
  .fixed-bottom {
    z-index: 1000;
  }
  .footer-container {
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 2rem 1.5rem 7rem;

    @media (max-width: 41.25rem) {
      & {
        width: 100%;
        padding: 1.5rem;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button-container {
      display: flex;
      flex-direction: row;
    }
    .button {
      padding: 0.875rem 1.25rem;

      &.cancel-button {
        background-color: white;
        border: 0.0625rem solid ${({theme}) => theme.colors.darkBorder};

        :active,
        :focus {
          border-color: ${({theme}) => theme.colors.primary};
        }

        .label {
          color: ${({theme}) => theme.colors.text};
        }
      }
    }
  }

  @media (max-width: 41.25rem) {
    .footer {
      display: flex;
      flex-direction: column;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
    }
  }
  .page {
    font-weight: 400;
    font-size: 0.875rem;
    color: #8a8c94;
    margin-bottom: unset;
  }
  .previous-button,
  .next-button {
    align-items: center;
    background-color: transparent;
    border: 0.0625rem solid #c9c9c9;
    display: flex;
    padding: 0.8125rem 1.5rem;

    &.is-disabled {
      opacity: 0.7;

      :active,
      :focus {
        border-color: #c9c9c9;
        box-shadow: none;
      }
    }

    :active,
    :focus {
      border-color: #40a1ff;
      box-shadow: 0 0 0 0.125rem #cfe7ff;
      outline: none;
      z-index: 10;
    }

    img {
      vertical-align: unset;
    }

    span {
      color: #3d4457;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1;
    }
  }

  .previous-button {
    border-radius: 0.5rem 0 0 0.5rem;
    margin-right: -0.0625rem;

    img {
      margin-right: 0.75rem;
    }
  }

  .next-button {
    border-radius: 0 0.5rem 0.5rem 0;

    img {
      margin-left: 0.75rem;
      transform: rotate(180deg);
    }
  }
`;
