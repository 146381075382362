/* eslint-disable react/prop-types */
import React from 'react';

import {ProgressContainer} from './elements';

export const ProgressLine = ({progress}) => (
  <ProgressContainer>
    <div className="progress-div">
      <p>{progress}/4 Completed</p>
      <div className="progress-bar">
        <div
          className={
            progress === 0
              ? 'progress-loader'
              : `progress-loader progress-loader-${progress}`
          }
        />
      </div>
    </div>
  </ProgressContainer>
);
