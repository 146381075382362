/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import CurrentIndicatorIcon from '../../../../../../assets/icons/current-indicator.svg';
import DocumentThumbnailIcon from '../../../../../../assets/icons/document-thumbnail.svg';
import AudioIcon from '../../../../../../assets/icons/added-voice-instruction.svg';
import {Select} from '../../../../../../components/common';
import CustomBox from '../../../../../../components/shared/CustomBox';
import VideoPlayer from '../../../../../../components/shared/VideoPlayer';
import {
  evaluateActivityItem,
  fetchAssessmentTasksOfStudent,
  fetchTasksLinkedToFiles,
  updateAssessmentTaskSubmission,
} from '../../../Assesments/Assessment/slices/assessmentSlice';
import {TaskFileContainer} from './elements';
import {DecisionButton} from './components';
import CustomButton from '../../../../../../components/shared/CustomButton';
import {formatTime} from '../../../../../../utils/functions/formatTime';

export const TaskFiles = ({
  currentFile,
  isFirstPlayback,
  onSelectFile,
  selectedLinkedTaskId,
  setIsFirstPlayback,
  setSelectedLinkedTaskId,
  isGraded,
  setOnSelectLinkedTask,
}) => {
  const isFileSetOnMarkerClick = useRef(false);
  const videoPlayer = useRef();
  const [comments, setComments] = useState('');
  const [evaluationColor, setEvaluationColor] = useState(null);
  const [evaluationId, setEvaluationId] = useState(null);
  const [evaluationLabel, setEvaluationLabel] = useState(null);
  const [evaluationValue, setEvaluationValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [task, setTask] = useState(null);
  const [submission, setSubmission] = useState(null);
  const [linkedTasksForVideo, setLinkedTasksForVideo] = useState(null);
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const {assessmentTasks, assessmentTasksError, assessmentTasksLoading} =
    useSelector(state => state.assessment);
  const dispatch = useDispatch();
  const {assessmentID, classID, studentID} = useParams();

  useEffect(() => {
    setOnSelectLinkedTask(() => () => {
      setComments('');
      setEvaluationColor(null);
      setEvaluationId(null);
      setEvaluationLabel(null);
      setEvaluationValue(null);
      setSelectedValue(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assessmentID && !assessmentTasksLoading && !assessmentTasks) {
      dispatch(fetchAssessmentTasksOfStudent(assessmentID, studentID));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentID, assessmentTasks, assessmentTasksLoading, studentID]);

  useEffect(() => {
    if (assessmentTasks && !selectedLinkedTaskId) {
      const taskItem = assessmentTasks?.length && assessmentTasks[0];
      setSelectedLinkedTaskId(taskItem?.ai_id_activityitem);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentTasks, selectedLinkedTaskId, studentID]);

  useEffect(() => {
    if (selectedLinkedTaskId) {
      const taskItem = assessmentTasks?.find(
        item => item.ai_id_activityitem === selectedLinkedTaskId,
      );
      const submissionItem = taskItem?.submissions.find(
        item => item.ais_id_userprofile_student === studentID,
      );

      setTask(taskItem);

      if (!isFileSetOnMarkerClick.current) {
        onSelectFile(taskItem?.linkedFiles[0]);
      } else {
        isFileSetOnMarkerClick.current = false;
      }

      setSubmission(submissionItem);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentTasks, studentID, selectedLinkedTaskId]);

  useEffect(() => {
    setComments(submission?.ais_notes || '');
    setEvaluationId(submission?.ais_id_eval_type_value || null);
  }, [submission]);

  useEffect(() => {
    let linkedTasks =
      currentFile?.fi_id_filetype === 2
        ? assessmentTasks?.filter(item =>
            item.linkedFiles.some(
              file => file.fi_id_file === currentFile.fi_id_file,
            ),
          )
        : null;
    linkedTasks = linkedTasks?.map(item => {
      const file = item.linkedFiles.find(
        fileLink => fileLink.fi_id_file === currentFile.fi_id_file,
      );
      const timecode = file.fl_video_timecode;
      const seconds = Math.floor(timecode);
      const minutes = Math.floor(seconds / 60);

      return {
        key: file.fl_id_filelink,
        id: `${item.ai_id_activityitem} ${file.fl_id_filelink}`,
        minutes,
        seconds,
        timecode,
        title: item.ai_activityitem,
      };
    });
    setLinkedTasksForVideo(linkedTasks || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentTasks, currentFile]);

  const FileElement = useMemo(() => {
    if (currentFile?.fi_id_filetype === 1)
      return (
        <img
          alt="File"
          className="file"
          key={currentFile?.fi_id_file}
          src={currentFile?.fi_location}
          style={{
            objectFit: 'contain',
          }}
        />
      );

    if (currentFile?.fi_id_filetype === 2) {
      return linkedTasksForVideo ? (
        <VideoPlayer
          key={`${selectedLinkedTaskId}/${currentFile?.fi_id_file}`}
          isFirstPlayback={isFirstPlayback}
          markers={linkedTasksForVideo}
          onMarkerClick={(taskId, fileLinkId) => {
            isFileSetOnMarkerClick.current = true;
            setSelectedLinkedTaskId(Number(taskId));
            const taskItem = assessmentTasks?.find(
              item => String(item.ai_id_activityitem) === String(taskId),
            );
            const file = taskItem?.linkedFiles?.find(
              fileItem =>
                String(fileItem.fl_id_filelink) === String(fileLinkId),
            );
            onSelectFile(file);
          }}
          setIsFirstPlayback={setIsFirstPlayback}
          src={currentFile?.fi_mux_playback_url}
          startingTime={currentFile.fl_video_timecode}
          videoPlayer={videoPlayer}
        />
      ) : null;
    }

    return <embed className="file" src={currentFile?.fi_location} />;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assessmentTasks,
    currentFile,
    isFirstPlayback,
    linkedTasksForVideo,
    selectedLinkedTaskId,
  ]);

  const defaultGrade = useMemo(() => {
    const submissiondetails = task?.submissions?.filter(
      submitData => submitData.ais_id_userprofile_student === studentID,
    );
    if (submissiondetails?.length) {
      const [defaultValue] = submissiondetails;
      return {
        color: defaultValue.etv_type_color,
        label: `${defaultValue.etv_type_value} # ${defaultValue.etv_type_label}`,
        value: defaultValue.etv_id_eval_type_value,
      };
    }

    return null;
  }, [studentID, task?.submissions]);

  useEffect(() => {
    if (defaultGrade) setSelectedValue(defaultGrade);
  }, [defaultGrade]);

  const gradeOptions = useMemo(
    () =>
      task?.evaluationType?.values?.map(value => ({
        color: value.etv_type_color,
        label: `${value.etv_type_value} # ${value.etv_type_label}`,
        value: value.etv_id_eval_type_value,
      })),
    [task?.evaluationType?.values],
  );

  if (assessmentTasksLoading || assessmentTasksError || !currentFile)
    return null;

  const isCommentsChanged = submission?.ais_notes !== comments;
  const isEvaluationTypeChanged =
    submission?.ais_id_eval_type_value !== evaluationId;
  const isSubmitDisabled =
    (!isCommentsChanged && !isEvaluationTypeChanged) ||
    !comments.length ||
    !evaluationId;

  const evaluateTask = event => {
    event.preventDefault();

    if (isSubmitDisabled || isSubmittingFeedback) {
      return;
    }

    if (!comments || !evaluationId) {
      return;
    }

    setIsSubmittingFeedback(true);
    dispatch(
      evaluateActivityItem(
        assessmentID,
        classID,
        selectedLinkedTaskId,
        studentID,
        {
          ais_notes: comments,
          ais_id_eval_type_value: evaluationId,
        },
        () => {
          const taskItem = assessmentTasks?.length && assessmentTasks[0];
          const fileIds = taskItem?.linkedFiles.map(file => file.fi_id_file);
          dispatch(fetchTasksLinkedToFiles(fileIds, assessmentID, studentID));
          dispatch(
            updateAssessmentTaskSubmission({
              comments,
              evaluationColor,
              evaluationId,
              evaluationLabel,
              evaluationValue,
              studentId: studentID,
              taskId: selectedLinkedTaskId,
            }),
          );
          setIsSubmittingFeedback(false);
        },
      ),
    );
  };

  const renderIcon = file => {
    if (file.fi_id_filetype === 2) {
      return file.fi_thumbnail;
    }
    if ([3, 5].includes(file.fi_id_filetype)) {
      return DocumentThumbnailIcon;
    }
    if (file.fi_id_filetype === 7) {
      return AudioIcon;
    }
    return file.fi_location;
  };

  return (
    <TaskFileContainer>
      <CustomBox padding="1.75rem 2rem 1.5rem 2rem">
        <div className="assessment-task-files">
          {task?.linkedFiles?.map(file => (
            <button
              key={file.fi_id_file}
              onClick={() => onSelectFile(file)}
              style={{
                backgroundColor:
                  currentFile.fi_id_file === file.fi_id_file
                    ? '#40a1ff'
                    : '#fff',
                border:
                  currentFile.fi_id_file === file.fi_id_file
                    ? '2px solid #40a1ff'
                    : 'none',
              }}
              className="task-files"
              type="button"
            >
              {currentFile.fi_id_file === file.fi_id_file ? (
                <img
                  alt="Current"
                  className="current-indicator"
                  src={CurrentIndicatorIcon}
                />
              ) : null}

              <img
                alt={file.fi_filename}
                className="thumbnail"
                src={renderIcon(file)}
                style={{
                  objectFit: 'cover',
                }}
              />
            </button>
          ))}
        </div>
        <div className="position-relative right">
          {currentFile?.fl_video_timecode && (
            <div className="position-absolute px-2 py-1 timecode">
              {formatTime(currentFile.fl_video_timecode)}
            </div>
          )}
          {FileElement}
          {task?.ai_id_evaltype === 1 && (
            <div className="decision-div">
              <DecisionButton
                text="No"
                onClick={() => {
                  if (isGraded) return;
                  setEvaluationColor(null);
                  setEvaluationId(2);
                  setEvaluationLabel(null);
                  setEvaluationValue('No');
                }}
                isSelected={evaluationId === 2}
                isDanger
              />
              <div className="mx-2" />
              <DecisionButton
                text="Yes"
                onClick={() => {
                  if (isGraded) return;
                  setEvaluationColor(null);
                  setEvaluationId(1);
                  setEvaluationLabel(null);
                  setEvaluationValue('Yes');
                }}
                isSelected={evaluationId === 1}
              />
            </div>
          )}
          {task?.ai_id_evaltype === 2 && (
            <div className="decision-div">
              <Select
                defaultValue={defaultGrade}
                formatOptionLabel={({color, label}) => {
                  const [gradeValue, gradeLabel] = label.split(' # ');
                  return (
                    <div className="align-items-center d-flex">
                      <span
                        className="align-items-center d-flex grade-value justify-content-center mr-2"
                        style={{backgroundColor: color}}
                      >
                        {gradeValue}
                      </span>
                      <div className="grade-label">{gradeLabel}</div>
                    </div>
                  );
                }}
                label="Choose Grade"
                isDisabled={isGraded}
                isLarge={false}
                isSearchable={false}
                onChange={event => {
                  if (isGraded) return;
                  const [gradeValue, gradeLabel] = event.label.split(' # ');
                  setEvaluationColor(event.color);
                  setEvaluationId(event.value);
                  setEvaluationLabel(gradeLabel);
                  setEvaluationValue(gradeValue);
                  setSelectedValue(event);
                }}
                options={gradeOptions}
                value={selectedValue}
                // width="50%"
              />
            </div>
          )}
          <form className="d-flex flex-column" onSubmit={evaluateTask}>
            <label className="comments mb-4" htmlFor="comments">
              <span>Feedback</span>
              <textarea
                id="comments"
                disabled={isGraded}
                onChange={event => setComments(event.target.value)}
                placeholder="Add your feedback here"
                rows={5}
                value={comments}
              />
            </label>
            <CustomButton
              className="align-self-end"
              isDisabled={isSubmitDisabled || isSubmittingFeedback}
              isLoading={isSubmittingFeedback}
              text="Submit Feedback"
              type="submit"
            />
          </form>
        </div>
      </CustomBox>
    </TaskFileContainer>
  );
};
