/* eslint-disable react/prop-types */

import React from 'react';
import styled from 'styled-components';

import {Colors} from '../../../../../../constants';

const StudentAssessmentEvaluationContainer = styled.div`
  background-color: ${({evaluationStatusColor}) => evaluationStatusColor};
  border-radius: 0.25rem;

  .criteria-text {
    color: ${Colors.Text};
    font-size: 0.875rem;
    line-height: 1.37rem;
    text-align: left;
  }

  .criteria-id {
    font-weight: 600;
  }
`;

const Status = {
  No: 'No',
  Yes: 'Yes',
};

const EvaluationStatus = ({status, criteria, criteriaId}) => {
  let evaluationStatusColor = '#f0f8ff';

  if (status === Status.Yes) {
    evaluationStatusColor = 'rgba(57, 202, 132, 0.12)';
  } else if (status === Status.No) {
    evaluationStatusColor = 'rgba(255, 126, 126, 0.12)';
  }

  return (
    <StudentAssessmentEvaluationContainer
      className="d-flex p-3 w-100"
      evaluationStatusColor={evaluationStatusColor}
    >
      <span className="criteria-id criteria-text mr-2">{criteriaId}</span>
      <span className="criteria-text">{criteria}</span>
    </StudentAssessmentEvaluationContainer>
  );
};

export default EvaluationStatus;
