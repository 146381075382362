import React from 'react';
import {useRouteMatch, Route, Switch} from 'react-router-dom';
import Navbar from '../../components/shared/Navbar';
import {AddAssessment, ActiveClassesPage, AddAssessmentByUnit} from './pages';

const ActiveClasses = () => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${match.path}`} exact render={() => null} />
        <Route
          path={`${match.path}/assessment/create`}
          exact
          render={() => null}
        />
        <Route
          path={`${match.path}/assessment/:id/update`}
          exact
          render={() => null}
        />
        <Route render={() => <Navbar />} />
      </Switch>
      <Switch>
        <Route
          path={`${match.path}`}
          exact
          render={() => <ActiveClassesPage />}
        />
        <Route
          path={`${match.path}/assessment/create`}
          exact
          render={() => <AddAssessmentByUnit />}
        />
        <Route
          path={`${match.path}/assessment/:id/update`}
          exact
          render={() => <AddAssessment />}
        />
      </Switch>
    </>
  );
};

export default ActiveClasses;
