import styled from 'styled-components';

export const DecisionButtonContainer = styled.div`
  .decision-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  width: 100%;
  border-radius: 0.312rem;
  background-color: ${({evaluationStatusColor}) => evaluationStatusColor};
  color: #3d4457;
  font-size: 0.875rem;
  span {
    margin-left: 0.75rem;
  }
  .btn {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 0.312rem;
    width: 100%;
    div {
      padding: 0.75rem 0.75rem;
      font-weight: bold;
      font-size: 1rem;
    }
    img {
      width: 2rem;
      height: 2rem;
      margin: 0.5rem;
    }
    &.yes {
      border: 0.0625rem solid #39ca84;
      color: #39ca84;
    }
    &.no {
      border: 0.0625rem solid #ff7e7e;
      color: #ff7e7e;
    }
    &.yes-active {
      background: #39ca84;
      color: #ffffff;
    }
    &.no-active {
      background: #ff7e7e;
      color: #ffffff;
    }
  }
`;
