/* eslint-disable react/prop-types */
import moment from 'moment';
import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Helmet} from 'react-helmet';
import {showToast} from '../../../../../../components/common/Toast';

import {AllAcademicYearsContainer} from './elements';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {
  Modal,
  Table,
  EmptyState,
  MoreOptions,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {
  getAllAcademicYears,
  academicYearsSelector,
  deleteAcademicYear,
  addAcademicYear,
  updateAcademicYear,
} from '../../../../../../redux/academicYearsSlice';
import {EditAcademicYearModal} from './components/EditAcademicYearModal';
import ToastSuccessIcon from '../../../../../../assets/icons/add-academic.svg';

const DELAY = 500;

export const AcademicYear = () => {
  const dispatch = useDispatch();

  const {isListLoading, isAcademicYearsLoading, academicYears} = useSelector(
    academicYearsSelector,
  );

  const [deleteId, setDeleteId] = useState();
  const [editDetails, setEditDetails] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  useEffect(() => {
    dispatch(getAllAcademicYears());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'START DATE',
        Cell: ({row: {original}}) => {
          const startAcademicYear = original?.oay_from_date;
          return moment(startAcademicYear).format('D MMM, YYYY');
        },
        accessor: 'oay_from_date',
        className: 'start-date',
      },
      {
        Header: 'END DATE',
        Cell: ({row: {original}}) => {
          const endAcademicYear = original?.oay_to_date;
          return moment(endAcademicYear).format('D MMM, YYYY');
        },
        accessor: 'oay_to_date',
        className: 'end-date',
      },

      {
        Header: '',
        Cell: ({value: academicYearId, row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit"
                onClick={() => {
                  setEditDetails({
                    id: academicYearId,
                    startDate: original?.oay_from_date,
                    endDate: original?.oay_to_date,
                  });
                }}
              />
              <DropdownItem
                isDanger
                label="Delete"
                isDisabled={original?.classes?.length}
                onClick={() => {
                  if (!original?.classes?.length) {
                    setDeleteId(academicYearId);
                  }
                }}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'oay_id_orgacadyear',
        className: 'more-option',
      },
    ],
    [setEditDetails],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(academicYears || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [academicYears],
  );

  const onUpdateSubmit = useCallback(
    details => {
      if (!isAcademicYearsLoading && editDetails?.id) {
        dispatch(
          updateAcademicYear(editDetails.id, details, () => {
            clearEditDetails();
            showToast(ToastSuccessIcon, `Academic year updated successfully`);
          }),
        );
      }
    },
    [clearEditDetails, dispatch, editDetails?.id, isAcademicYearsLoading],
  );

  const onAddSubmit = useCallback(
    details => {
      if (!isAcademicYearsLoading) {
        dispatch(
          addAcademicYear(details, () => {
            setIsAddModalOpen(false);
            showToast(ToastSuccessIcon, `Academic year added successfully`);
          }),
        );
      }
    },
    [dispatch, isAcademicYearsLoading],
  );

  return (
    <>
      <Helmet>
        <title>Academic Years | JungleCat</title>
      </Helmet>

      <AllAcademicYearsContainer>
        <ListingPageHeader
          buttonLabel="Add Academic year"
          className="mb-4 academicyear-header"
          onButtonClick={() => {
            setIsAddModalOpen(true);
          }}
          title="Academic Years"
        />
        <div className="table-container">
          {isListLoading ? <TableSpinner /> : null}
          {!isListLoading &&
          Array.isArray(academicYears) &&
          !academicYears?.length ? (
            <EmptyState
              description="It appears that no Academic Years have been added to the organisation."
              title="There are no Academic Years yet!"
            />
          ) : null}
          {!isListLoading && academicYears?.length ? (
            <Table
              columns={columns}
              data={data}
              isSortedBy
              itemName="Academic years"
              maxWidth="55.5rem"
            />
          ) : null}
        </div>

        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this academic year?"
          isDelete
          isButtonLoading={isAcademicYearsLoading}
          loadingButtonLabel="Deleting..."
          onButtonClick={() => {
            if (!isAcademicYearsLoading) {
              dispatch(
                deleteAcademicYear(deleteId, () => {
                  setDeleteId(null);
                  showToast(false, `Academic year has been deleted.`, true);
                  setTimeout(() => {}, DELAY);
                }),
              );
            }
          }}
          onHide={() => {
            setDeleteId(null);
            setTimeout(() => {}, DELAY);
          }}
          show={!!deleteId}
          title="Delete Academic year"
          width="41.25rem"
        />
        <EditAcademicYearModal
          editDetails={editDetails}
          onHide={clearEditDetails}
          isOpen={!!editDetails}
          onSubmit={onUpdateSubmit}
          buttonLabel="Save Changes"
          description="Make changes to the academic year details"
          isButtonLoading={isAcademicYearsLoading}
          loadingButtonLabel="Saving..."
          title="Edit Academic Year"
        />
        <EditAcademicYearModal
          isOpen={isAddModalOpen}
          onHide={() => {
            setIsAddModalOpen(false);
          }}
          onSubmit={onAddSubmit}
          buttonLabel="Add Academic Year"
          description="Add academic year to organisation"
          isButtonLoading={isAcademicYearsLoading}
          loadingButtonLabel="Adding..."
          title="Add Academic Year"
        />
      </AllAcademicYearsContainer>
    </>
  );
};
