import moment from 'moment';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';

import CloseIcon from '../../../../assets/icons/close-modal.svg';
import {fetchClassDetails} from '../../../../redux/streamSlice';
import SessionsChartContainer from '../../elements/SessionsChartContainer';
import SessionBar from './components/SessionBar';
import TimeInterval from './components/TimeInterval';

const TIME_INTERVALS = Array.from(new Array(25).keys());

const SessionsChart = ({date, sessions}) => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const {classDetails} = useSelector(state => state?.streamNew || {});

  useEffect(() => {
    dispatch(fetchClassDetails(id));
  }, [dispatch, id]);

  return (
    <SessionsChartContainer className="col d-flex flex-column justify-content-center position-relative">
      <button
        className="close-button position-absolute"
        type="button"
        onClick={() => history.goBack()}
        id="close-btn"
      >
        <img alt="Go Back" src={CloseIcon} />
      </button>
      <div className="col-8 offset-2 scheduled-sessions">
        <h3 className="title">Scheduled Sessions</h3>
        <p className="mb-4 subtitle">
          Scheduled sessions for{' '}
          <span className="unit-name">
            {classDetails?.orgUnit?.unit?.tun_title}
          </span>
        </p>
        <div className="sessions-chart">
          <div className="align-items-center chart-header d-flex pb-4 px-4 justify-content-between">
            <h4 className="selected-date mb-0">
              {moment(date).format('D MMMM YYYY')}
            </h4>
            <Link className="calendar-link" to="calendar" id="go-to-calendar">
              Go To Calendar
            </Link>
          </div>
          <div className="chart-body p-4 position-relative">
            {TIME_INTERVALS.map(item => (
              <TimeInterval key={String(item)} time={item} />
            ))}
            {sessions.map((session, index) => (
              <SessionBar
                from={session.from}
                // eslint-disable-next-line react/no-array-index-key
                key={String(index)}
                title={session.name}
                to={session.to}
              />
            ))}
          </div>
        </div>
      </div>
    </SessionsChartContainer>
  );
};

SessionsChart.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SessionsChart;
