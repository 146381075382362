/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {PostHeader, PostBody} from './components';
import {Hr} from '../../../../components/shared/Hr';
import CustomBox from '../../../../components/shared/CustomBox';
import {PostCardCont} from './elements';
import {Modal} from '../../../../components/common';
import {streamSelector, deletePost} from '../../../../redux/streamSlice';
import {showToast} from '../../../../components/common/Toast';

export const PostCard = ({
  intersectedElement,
  isIntersectedElement,
  post,
  onEdit,
  isArchived,
  // className,
}) => {
  const userType = useRef(localStorage.getItem('userId')).current;
  const {stream, isStreamLoading} = useSelector(streamSelector);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const {
    up_name_first,
    up_name_last,
    up_avatar,
    us_profile_name,
    ac_activity_name,
    ac_id_activity,
    ac_timestamp_creation,
    unit,
    files,
    students,
    commentCount,
    comments,
  } = post;

  return (
    <CustomBox
      margin="1.5rem 0"
      reference={isIntersectedElement ? intersectedElement : null}
      // className={className}
      // id={post?.aoui_id_activityorgunitinstance}
    >
      <PostCardCont>
        <PostHeader
          userName={us_profile_name}
          profileName={`${up_name_first} ${up_name_last}`}
          profileImg={up_avatar}
          unitDetail={unit ? unit.un_unitname : ''}
          time={ac_timestamp_creation}
          students={students}
          onclickDelete={() =>
            setDeleteId(post?.aoui_id_activityorgunitinstance)
          }
          onEdit={onEdit}
          isAuthor={post.up_id_user === userType}
          isTeacher={post.up_id_typeuserprofile === 3}
          isArchived={isArchived}
        />
        <Hr className="divider" />
        <PostBody
          profileName={`${up_name_first} ${up_name_last}`}
          time={ac_timestamp_creation}
          activity={ac_activity_name}
          activityId={ac_id_activity}
          commentCount={commentCount}
          comments={comments}
          files={files}
          isArchived={isArchived}
        />
        <Modal
          buttonLabel="Delete"
          description="Are you sure you want to delete this post?"
          isDelete
          isButtonLoading={isStreamLoading}
          loadingButtonLabel="Deleting"
          onButtonClick={() => {
            if (!isStreamLoading) {
              dispatch(
                deletePost(post.aoui_id_activityorgunitinstance, stream, () => {
                  showToast(false, 'Post deleted successfully', true);
                }),
              );
            }
          }}
          onHide={() => {
            setDeleteId(null);
          }}
          show={!!deleteId}
          title="Delete Post"
          width="41.25rem"
        />
      </PostCardCont>
    </CustomBox>
  );
};

PostCard.propTypes = {
  post: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
