/* eslint-disable react/prop-types */
import moment from 'moment';
import {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {useLocalStorage} from 'react-use';
import {useSelector} from 'react-redux';
import SessionBarContainer from '../elements/SessionBarContainer';
import CalendarAttendanceIcon from '../../../../../../../../../assets/icons/calendar-attendance.svg';
import PresentIcon from '../../../../../../../../../assets/icons/attendance-present.svg';
import AbsentIcon from '../../../../../../../../../assets/icons/attendance-absent.svg';

const SessionBar = ({
  id,
  from,
  title,
  to,
  unitName,
  attendance,
  liveSessionPreference,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const fromTime = useMemo(
    () => moment(from, 'HH:mm:ss').format('h.mm a'),
    [from],
  );
  const toTime = useMemo(() => moment(to, 'HH:mm:ss').format('h.mm a'), [to]);
  const [userType] = useLocalStorage('jc-user-type', null, {raw: true});
  const {user} = useSelector(state => state.auth);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const userProfileId = useMemo(() => {
    const userProfile = user?.userProfiles?.find(
      item => item.up_id_typeuserprofile === 2,
    );
    return userProfile?.up_id_userprofile;
  }, [user]);

  const topOffset = useMemo(() => {
    const [hoursToStart, minutesToStart] = from.split(':').map(Number);
    // one hour section size 142
    // ex: 1PM to 2PM height 142
    return hoursToStart * 142 + (142 / 60) * minutesToStart;
  }, [from]);

  const barHeight = useMemo(() => {
    const [hoursToStop, minutesToStop] = to.split(':').map(Number);
    // one hour section size 142
    // ex: 1PM to 2PM height 142
    return hoursToStop * 142 + (142 / 60) * minutesToStop - topOffset;
  }, [topOffset, to]);

  const isStudent = useMemo(() => +userType === 2, [userType]);

  const isPresent = useMemo(() => {
    if (isStudent) {
      const presentStudent = attendance.presentStudents.find(
        item => item.oruia_id_userprofile_student === userProfileId,
      );
      const absentStudent = attendance.absentStudents.find(
        item => item.oruia_id_userprofile_student === userProfileId,
      );

      if (presentStudent) return true;
      if (absentStudent) return false;
    }

    return undefined;
  }, [
    attendance?.absentStudents,
    attendance?.presentStudents,
    isStudent,
    userProfileId,
  ]);

  const isAttendanceMarked =
    attendance?.presentStudents?.length || attendance?.absentStudents?.length;

  let primaryColor;
  let altColor;
  let attendanceIcon;
  if (isPresent === true) {
    primaryColor = '#4ec977';
    altColor = '#edfaf1';
    attendanceIcon = PresentIcon;
  } else if (isPresent === false) {
    primaryColor = '#ff6969';
    altColor = '#ffe1e1';
    attendanceIcon = AbsentIcon;
  }

  if (barHeight < 75) {
    return (
      <SessionBarContainer
        className={`position-absolute less-half-hour${
          isHovering ? ' less-half-hour-hover' : ''
        }`}
        height={barHeight - 1}
        top={topOffset + 1}
        isStudent={isStudent}
        primaryColor={isStudent && primaryColor}
        altColor={isStudent && altColor}
        data-for={id}
        data-tip
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {!isStudent ? (
          <>
            {liveSessionPreference &&
              (isAttendanceMarked ? (
                <Link className="tooltip-link" to={`${id}/attendance`} id={id}>
                  <img alt="attendance" src={CalendarAttendanceIcon} />
                  <span>
                    {attendance?.presentStudents?.length || 0} /{' '}
                    {attendance?.students?.length || 0}
                  </span>
                </Link>
              ) : (
                <Link
                  className="tooltip-button"
                  to={`${id}/attendance`}
                  id={id}
                >
                  <img alt="attendance" src={CalendarAttendanceIcon} />
                </Link>
              ))}{' '}
          </>
        ) : (
          attendanceIcon && (
            <div className="attendance-icon">
              <img src={attendanceIcon} alt="Attendance Icon" />
            </div>
          )
        )}
        <div className="left-bar" />
        <span className="time-interval">
          {fromTime} - {toTime}
        </span>
        <h5 className="session-title">{title}</h5>
        <p className="session-class">{unitName}</p>
      </SessionBarContainer>
    );
  }

  return (
    <SessionBarContainer
      className="position-absolute one-hour"
      height={barHeight - 1}
      top={topOffset + 1}
      isStudent={isStudent}
      primaryColor={isStudent && primaryColor}
      altColor={isStudent && altColor}
      data-for={id}
      data-tip
    >
      <div className="mt-2">
        {!isStudent ? (
          <>
            {liveSessionPreference &&
              (isAttendanceMarked ? (
                <Link
                  className="tooltip-link mt-2"
                  to={`${id}/attendance`}
                  id={id}
                >
                  <img alt="attendance" src={CalendarAttendanceIcon} />
                  <span>
                    {attendance?.presentStudents?.length || 0} /{' '}
                    {attendance?.students?.length || 0}
                  </span>
                </Link>
              ) : (
                <Link
                  className="tooltip-button mt-2"
                  to={`${id}/attendance`}
                  id={id}
                >
                  <img alt="attendance" src={CalendarAttendanceIcon} />
                </Link>
              ))}{' '}
          </>
        ) : (
          attendanceIcon && (
            <div className="attendance-icon">
              <img src={attendanceIcon} alt="Attendance Icon" />
            </div>
          )
        )}
        <div className="left-bar" />
        <span className="time-interval">
          {fromTime} - {toTime}
        </span>
        <h5 className="session-title">{title}</h5>
        <p className="session-class">{unitName}</p>
      </div>
    </SessionBarContainer>
  );
};

export default SessionBar;
