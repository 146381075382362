import styled from 'styled-components';

export const MainContainer = styled.div`
  .nav-tabs {
    margin-bottom: 0 !important;

    .nav-link {
      margin-top: 1.5rem;
      margin-right: 1.5rem;
      padding: 1rem !important;
      &.active {
        background-color: white !important;
      }
      .tab-name {
        border: unset !important;
        margin-right: unset !important;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  .nav {
    display: flex;
    flex-wrap: unset;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    overflow-x: scroll !important;
  }
  .nav::-webkit-scrollbar {
    display: none;
  }
  .nav {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
