import styled from 'styled-components';

export const AddStudentFooterContainer = styled.div`
  bottom: 0;
  height: 3.875rem;
  margin-top: 6.25rem;
  .add-student-footer {
    padding: 1.5rem 3rem 1.5rem 3rem;
    width: 100%;
    background-color: #ffffff;
    .add-student-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .add-student-name {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.37rem;
      }
    }
    .add-student-spread {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-student-name {
        color: #3d4457;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.375rem;
      }
      @media (max-width: 55rem) {
        & {
          display: flex;
          flex-direction: column;
        }
        .progress-section {
          display: flex;
          align-self: flex-start;
          margin-bottom: 0.75rem;
        }
        .add-student-title {
          display: flex;
          align-self: flex-end;
        }
        .button-container {
          display: flex;
          align-self: flex-end;
        }
      }
    }

    .button-container-back {
      border: 0.0625rem solid black;
    }
    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .save-changes {
        margin-right: 0.25rem;
      }
      .cancel {
        margin-right: 1rem;
        border: 0.0625rem solid transparent;
        border-radius: 0.5rem;
        align-items: flex-end;
        .cancel-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
        }
        @media (max-width: 31.25rem) {
          & {
            display: none;
          }
        }
      }
      .cancel1 {
        margin-right: 1rem;
        border: 0.0625rem solid #dedede;
        border-radius: 0.5rem;
        align-items: flex-end;
        .cancel1-button-text {
          color: #3d4457;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
    }
  }
  .skip-this-step {
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0.3125rem;
    padding: 0.5rem 0.75rem;
    color: #80d5ff;
    font-weight: 500;
    font-size: 0.875rem;
    :hover {
      background-color: #f5faff;
    }
    a {
      text-decoration: none;
      color: #40a1ff;
    }
  }
`;
