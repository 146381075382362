import styled from 'styled-components';

import {Colors} from '../../../../../../constants';
import rem from '../../../../../../utils/rem';

const UnitDetailsContainer = styled.section`
  background-color: ${Colors.Background};
  margin: 0 -15px;
  min-height: 100vh;
  padding: ${rem(112)} ${rem(48)} ${rem(48)} 7rem;

  .tab-units {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 0rem 0rem 0rem 0rem;
    border-bottom: 0.0625rem solid #dedede;
    .bottom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      font-weight: 500;
      font-size: 1rem;
      outline: none;
      color: #0c181f;
      &.link {
        border-bottom: 0.125rem solid #40a1ff !important;
        color: #40a1ff;
      }
      p {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }
`;

export default UnitDetailsContainer;
