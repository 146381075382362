/* eslint-disable react/prop-types */

import classNames from 'classnames';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import axios from '../../../../../../../../api';
import AddIcon from '../../../../../../../../assets/icons/tas-table-add.svg';
import DeleteIcon from '../../../../../../../../assets/icons/tas-table-delete.svg';
// import InfoIcon from '../../../../../../../../assets/icons/text-input-info.svg';
import {
  Select,
  Table,
  TextInput,
} from '../../../../../../../../components/common';
import ErrorMessage from '../ErrorMessage';
import CoreAndElectiveComponentsFormContainer from './elements/CoreAndElectiveComponentsFormContainer';

const CoreAndElectiveComponentsForm = ({
  control,
  errors,
  onUpdate,
  setUnits,
  units,
  watch,
  tasStatus,
  showError,
  isDataCompleted,
}) => {
  const {orgClassId} = useParams();
  const [electiveUnits, setElectiveUnits] = useState([]);
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [stageTypes, setStageTypes] = useState([]);
  const stageNameValue = watch('stageName');
  const stageCountValue = watch('totalStageCount');

  useEffect(() => {
    if (orgClassId) {
      axios
        .get(`/api/admin/tas/${orgClassId}/units`)
        .then(({data: {orgQualUnits, tasStageTypes, tasUnits}}) => {
          setStageTypes(tasStageTypes);

          setUnits(
            tasUnits.map((unit, index) => ({...unit, serial: index + 1})),
          );

          setElectiveUnits(
            orgQualUnits.map(unit => ({
              label: `${unit.tun_code} ${unit.tun_title}`,
              value: {
                tun_code: unit.tun_code,
                tun_id_tgovunit: unit.tun_id_tgovunit,
                tun_title: unit.tun_title,
              },
            })),
          );
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgClassId]);

  const updateUnitApi = useCallback((unitId, data, unitName) => {
    axios.patch(`/api/admin/tas/units/${unitId}`, {data, unitName});
  }, []);

  const updateUnitValue = useCallback(
    (unitId, fieldName, isSelect) => event => {
      setUnits(previousValue =>
        previousValue.map(unit => {
          if (unit.otun_id_unit === unitId) {
            return {
              ...unit,
              [fieldName]: isSelect ? event.value : event.target.value,
            };
          }
          return unit;
        }),
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const stageNameOptions = useMemo(() => {
    if (stageTypes.length) {
      return stageTypes.map(type => ({
        label: type.tsty_name,
        value: type.tsty_name,
      }));
    }
    return [];
  }, [stageTypes]);

  const stageCountOptions = useMemo(() => {
    const options = [];
    if (stageNameValue?.value) {
      const stageType = stageTypes.find(
        type => type.tsty_name === stageNameValue?.value,
      );

      if (stageType) {
        for (let count = 1; count <= stageType.tsty_max_count; count += 1) {
          options.push({label: count, value: count});
        }
      }
    }
    return options;
  }, [stageNameValue?.value, stageTypes]);

  const stageOptions = useMemo(() => {
    const options = [];

    if (stageNameValue?.value && stageCountValue?.value) {
      for (let count = 1; count <= stageCountValue?.value; count += 1) {
        const value = `${stageNameValue?.value} ${count}`;
        options.push({label: value, value});
      }
    }

    return options;
  }, [stageNameValue?.value, stageCountValue?.value]);

  const caeuTableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
        sticky: 'left',
      },
      {
        Cell: ({value}) => (
          <div className="column-table-value" style={{marginRight: '1.5rem'}}>
            {value}
          </div>
        ),
        Header: 'Unit Code',
        accessor: 'otun_unit_code',
        className: 'unit-code',
        sticky: 'left',
      },
      {
        Cell: ({value}) => (
          <div className="column-table-value" style={{marginRight: '1.5rem'}}>
            {value}
          </div>
        ),
        Header: 'Unit Name',
        accessor: 'otun_unit_name',
        className: 'unit-name',
        sticky: 'left',
      },
      {
        Cell: ({value}) => (
          <div
            className={classNames('unit-type', {
              'is-core': value.toLowerCase() === 'core',
            })}
          >
            {value}
          </div>
        ),
        Header: 'COMPETENCY UNIT CATEGORY',
        accessor: 'otun_unit_type',
        className: 'type',
      },
      {
        Cell: ({row: {original}, value}) => (
          <Select
            className="table-input"
            isLarge={false}
            isLightBorder
            onChange={event => {
              updateUnitValue(
                original.otun_id_unit,
                'otun_unit_stage',
                true,
              )(event);

              if (event?.value) {
                updateUnitApi(original.otun_id_orgtasunit, {
                  column: 'otun_unit_stage',
                  value: event?.value,
                  unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                  columnName: stageNameValue?.value,
                });
              }
            }}
            options={stageOptions}
            placeholder="Select"
            value={{label: value, value}}
            isDisabled={tasStatus}
          />
        ),
        Header: stageNameValue?.value || '',
        accessor: 'otun_unit_stage',
        className: 'semester',
      },
      {
        Cell: ({row: {original}, value}) => {
          if (original.otun_unit_type === 'Core') {
            return false;
          }
          return (
            <TextInput
              className="table-input"
              onBlur={event => {
                updateUnitApi(original.otun_id_orgtasunit, {
                  column: 'otun_elective_category',
                  value: event.target?.value,
                  unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
                });
              }}
              onChange={updateUnitValue(
                original.otun_id_unit,
                'otun_elective_category',
              )}
              isDisabled={tasStatus}
              value={value}
            />
          );
        },
        Header: 'Elective Category',
        accessor: 'otun_elective_category',
        className: 'cat',
      },
      {
        Cell: ({value}) => {
          if (value.length) {
            return value.map(item => (
              <div>
                {item.otup_code} - {item.otup_name}
              </div>
            ));
          }
          return '-';
        },
        // <TextInput
        //   className="table-input"
        //   onBlur={event => {
        //     updateUnitApi(original.otun_id_orgtasunit, {
        //       column: 'otun_prerequisite',
        //       value: event.target?.value,
        //     });
        //   }}
        //   onChange={updateUnitValue(
        //     original.otun_id_orgtasunit,
        //     'otun_prerequisite',
        //   )}
        //   defaultValue={value}
        //   isDisabled={tasStatus}
        // />
        Header: 'Prerequisite',
        accessor: 'prerequisitesUnit',
        className: 'prerequisite',
      },
      {
        Cell: ({value}) => (
          <TextInput
            className="table-input"
            // onBlur={event => {
            //   updateUnitApi(original.otun_id_orgtasunit, {
            //     column: 'otun_nominal_hours',
            //     value: event.target?.value,
            //   });
            // }}
            // onChange={updateUnitValue(
            //   original.otun_id_unit,
            //   'otun_nominal_hours',
            // )}
            value={value}
            isDisabled
          />
        ),
        Header: 'Nominal Hours',
        accessor: 'otun_nominal_hours',
        className: 'nominal-hours',
      },
      // {
      //   Cell: ({value}) => (
      //     <TextInput className="table-input" value={value} isDisabled />
      //   ),
      //   Header: 'Minimum Payable Hours',
      //   accessor: 'otun_minimum_payable_hours',
      //   className: 'minimum-payable-hours',
      // },
      // {
      //   Cell: ({value}) => (
      //     <TextInput className="table-input" value={value} isDisabled />
      //   ),
      //   Header: 'Maximum Payable Hours',
      //   accessor: 'otun_maximum_payable_hours',
      //   className: 'maximum-payable-hours',
      // },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateUnitApi(original.otun_id_orgtasunit, {
                column: 'otun_f2f_hours',
                value: event.target?.value,
                unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
              });
            }}
            isDisabled={tasStatus}
            onChange={updateUnitValue(original.otun_id_unit, 'otun_f2f_hours')}
            value={value}
          />
        ),
        Header: 'Face to Face Hours',
        accessor: 'otun_f2f_hours',
        className: 'face-hours',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            isDisabled={tasStatus}
            onBlur={event => {
              updateUnitApi(original.otun_id_orgtasunit, {
                column: 'otun_nonf2f_hours',
                value: event.target?.value,
                unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
              });
            }}
            onChange={updateUnitValue(
              original.otun_id_unit,
              'otun_nonf2f_hours',
            )}
            value={value}
          />
        ),
        Header: 'Non Face to Face Hours',
        accessor: 'otun_nonf2f_hours',
        className: 'non-face-hours',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateUnitApi(original.otun_id_orgtasunit, {
                column: 'otun_assessment_hours',
                value: event.target?.value,
                unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
              });
            }}
            onChange={updateUnitValue(
              original.otun_id_unit,
              'otun_assessment_hours',
            )}
            value={value}
            isDisabled={tasStatus}
          />
        ),
        Header: 'Assessment Hours',
        accessor: 'otun_assessment_hours',
        className: 'assessment-hours',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateUnitApi(original.otun_id_orgtasunit, {
                column: 'otun_delivery_hours',
                value: event.target?.value,
                unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
              });
            }}
            onChange={updateUnitValue(
              original.otun_id_unit,
              'otun_delivery_hours',
            )}
            value={value}
            isDisabled={tasStatus}
          />
        ),
        Header: 'Delivery Hours',
        accessor: 'otun_delivery_hours',
        className: 'delivery-hours',
      },
      {
        Cell: ({row: {original}, value}) => (
          <TextInput
            className="table-input"
            onBlur={event => {
              updateUnitApi(original.otun_id_orgtasunit, {
                column: 'otun_lesson_count',
                value: event.target?.value,
                unitName: `${original?.otun_unit_code} - ${original?.otun_unit_name}`,
              });
            }}
            onChange={updateUnitValue(
              original.otun_id_unit,
              'otun_lesson_count',
            )}
            value={value}
            isDisabled={tasStatus}
          />
        ),
        Header: 'Lessons',
        accessor: 'otun_lesson_count',
        className: 'lessons',
      },
      {
        Cell: ({row: {original}, value}) => {
          const isCore = original?.otun_unit_type?.toLowerCase() === 'core';
          if (tasStatus) {
            return null;
          }
          return (
            <button
              className="delete"
              onClick={() => {
                if (isCore) return;

                axios.delete(`/api/admin/tas/units/${value}`).then(() => {
                  setUnits(previousValue =>
                    previousValue.filter(
                      item => item.otun_id_orgtasunit !== value,
                    ),
                  );

                  setElectiveUnits(previousValue => [
                    ...previousValue,
                    {
                      label: `${original.otun_unit_code} ${original.otun_unit_name}`,
                      value: {
                        tun_code: original.otun_unit_code,
                        tun_id_tgovunit: original.otun_id_unit,
                        tun_title: original.otun_unit_name,
                      },
                    },
                  ]);
                });
              }}
              style={isCore ? {cursor: 'default', opacity: 0} : null}
              type="button"
            >
              <img alt="Delete" src={DeleteIcon} />
            </button>
          );
        },
        accessor: 'otun_id_orgtasunit',
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stageNameValue?.value, stageOptions, tasStatus],
  );

  const totalValues = useMemo(() => {
    let values = {};

    if (units.length) {
      units.forEach(unit => {
        values = {
          assessmentHours:
            (values.assessmentHours || 0) +
            Number(unit.otun_assessment_hours || 0),
          deliveryHours:
            (values.deliveryHours || 0) + Number(unit.otun_delivery_hours || 0),
          faceHours: (values.faceHours || 0) + Number(unit.otun_f2f_hours || 0),
          nonFaceHours:
            (values.nonFaceHours || 0) + Number(unit.otun_nonf2f_hours || 0),
        };
      });
    }

    return values;
  }, [units]);

  return (
    <CoreAndElectiveComponentsFormContainer
      className="section"
      id="core-and-elective-components"
    >
      <h3>Core and Elective Components</h3>
      <p>
        If delivering a full qualification, identify core and elective
        components in accordance with the structure defined in the training
        package or course.
        <br />
        Define which elective units or modules are being offered so you can
        properly plan for all delivery variables.
        <br />
        Identify any entry requirements, as well as pre-requisite and
        co-requisite units, and the sequencing of delivery and assessment.
      </p>
      <form>
        <div className="align-items-end tas-form-row">
          <Controller
            className="form-control"
            control={control}
            name="stageName"
            render={fields => (
              <Select
                {...fields}
                className="flex-grow-0 tas-form-column"
                hasError={!!errors.stageName}
                hasInfo={false}
                isLarge={false}
                isLightBorder
                label="Stage Name"
                onChange={event => {
                  fields?.onChange?.(event);
                  onUpdate();
                }}
                isDisabled={tasStatus}
                options={stageNameOptions}
                placeholder="Select"
                flexBasis="27%"
              />
            )}
          />
          <Controller
            className="form-control"
            control={control}
            name="totalStageCount"
            render={fields => (
              <Select
                {...fields}
                className="flex-grow-0 tas-form-column"
                hasError={!!errors.totalStageCount}
                hasInfo={false}
                isLarge={false}
                isLightBorder
                isDisabled={tasStatus}
                label="No of Stages"
                onChange={event => {
                  fields?.onChange?.(event);
                  onUpdate();
                }}
                options={stageCountOptions}
                placeholder="Select"
                flexBasis="27%"
              />
            )}
          />
        </div>

        <div className="tas-form-row">
          <div className="is-full-width tas-form-column">
            {showError &&
            isDataCompleted?.includes('core-and-elective-components') ? (
              <ErrorMessage
                errorMessage="All core and elective unit details needed to be filled"
                className="error mb-2"
              />
            ) : null}
            <div className="column-table-title">
              Core and Elective Units
              {/* <img alt="Info" src={InfoIcon} /> */}
            </div>
            <div className="tas-table-extended">
              <Table
                columns={caeuTableColumns}
                data={units}
                isWithoutHeader
                isWithoutPagination
                showAllRows
              />
              {!tasStatus ? (
                <div
                  className="add-unit-section"
                  style={isAddMenuOpen ? {padding: '0.9375rem 2.125rem'} : null}
                >
                  {isAddMenuOpen ? (
                    <Select
                      hasInfo
                      isLarge={false}
                      isLightBorder
                      menuPlacement="top"
                      onBlur={() => setIsAddMenuOpen(false)}
                      onChange={event => {
                        setIsLoading(true);
                        axios
                          .post(
                            `/api/admin/tas/${orgClassId}/units`,
                            event?.value,
                          )
                          .then(({data: {tasUnits}}) => {
                            setUnits(
                              tasUnits.map((unit, index) => ({
                                ...unit,
                                serial: index + 1,
                              })),
                            );

                            setElectiveUnits(previousValue =>
                              previousValue.filter(
                                item =>
                                  item.value.tun_id_tgovunit !==
                                  event?.value?.tun_id_tgovunit,
                              ),
                            );
                            setIsLoading(false);
                          })
                          .catch(() => {
                            setIsLoading(false);
                          });

                        setIsAddMenuOpen(false);
                      }}
                      options={electiveUnits}
                      placeholder="Select"
                      width="24rem"
                    />
                  ) : (
                    <>
                      {!isLoading ? (
                        <button
                          onClick={() => setIsAddMenuOpen(true)}
                          type="button"
                          disabled={tasStatus}
                        >
                          <img alt="Add" src={AddIcon} /> Add Elective
                        </button>
                      ) : (
                        <span className="text-secondary">
                          Adding elective unit...
                        </span>
                      )}
                    </>
                  )}
                </div>
              ) : null}
              <div className="total-section">
                <span className="section-label">Total Hours:</span>
                <span className="section-value">{totalValues.faceHours}</span>
                <span className="section-value">
                  {totalValues.nonFaceHours}
                </span>
                <span className="section-value">
                  {totalValues.assessmentHours}
                </span>
                <span className="section-value">
                  {totalValues.deliveryHours}
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </CoreAndElectiveComponentsFormContainer>
  );
};

export default CoreAndElectiveComponentsForm;
