/* eslint-disable react/prop-types */
import React, {useState, useMemo, useCallback, useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';

import {FormSection, AddedInstructions} from './components';
import {
  fetchElementsByUnit,
  streamSelector,
} from '../../../../../../streamSlice';
import {
  removeAllInstructions,
  updateTask,
  addFileInstruction,
} from '../../../../../Assesments/Assessment/slices/assessmentSlice';
import ToastSuccess from '../../../../../../../../assets/icons/updateadmintoast.svg';
import {showToast} from '../../../../../../../../components/common/Toast';

const getInstruction = (file, item) => {
  switch (file.fi_id_filetype) {
    case 1:
      return {
        duration: file.fi_filesize,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'image',
        uploaded: true,
      };
    case 2:
      return {
        duration: file.fi_duration,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'video',
        uploaded: true,
      };
    case 3:
      return {
        linkID: item.fl_id_filelink,
        text: file.fi_text_content,
        type: 'text',
        uploaded: true,
      };
    case 5:
      return {
        duration: file.fi_filesize,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'document',
        uploaded: true,
      };
    case 7:
      return {
        duration: file.fi_duration,
        linkID: item.fl_id_filelink,
        media: file.fi_location,
        text: file.fi_text_content,
        type: 'audio',
        uploaded: true,
      };
    default:
      return null;
  }
};

export const TaskAndInstruction = ({
  register,
  getValues,
  setValue,
  closeAddTask,
  isPublished,
  addTask,
  taskToUpdate,
  control,
  errors,
  setError,
  clearErrors,
  isArchive,
}) => {
  const fileInput = useRef();
  const {
    location: {pathname},
  } = useHistory();
  const dispatch = useDispatch();
  const {elements} = useSelector(streamSelector);
  const [instructions, setInstructions] = useState([]);
  const [elementId, setElementId] = useState(null);
  const [performanceCriteriaId, setPerformanceCriteriaId] = useState(null);
  const [performanceEvidenceId, setPerformanceEvidenceId] = useState(null);
  const [knowledgeEvidenceId, setKnowledgeEvidenceId] = useState(null);
  const [assessmentConditionId, setAssessmentConditionId] = useState(null);

  // Group the elements for the select field.
  const elementGroup = useMemo(
    () =>
      elements?.map(element => ({
        label: `${element.te_element_id} ${element.te_element_value}`,
        options: element.performanceCriteria.map(criteria => ({
          label: `${criteria.tpc_criteria_id} ${criteria.tpc_criteria_value}`,
          value: criteria.tpc_id_tcriteria,
        })),
      })),
    [elements],
  );

  const assessmentState = useSelector(state => state.assessment);
  const {draftTask, activityId} = assessmentState;

  const updateLocalInstruction = useCallback((id, data) => {
    const file = data.fi_id_filetype ? data : data.file;

    setInstructions(previousValue =>
      previousValue.map(item =>
        item.linkID === id ? getInstruction(file, data) : item,
      ),
    );
  }, []);

  const activeTask = useMemo(
    () =>
      taskToUpdate
        ? {
            ai_id_activityitem: taskToUpdate.id,
            linkedFiles: taskToUpdate.taskFiles,
          }
        : draftTask,
    [draftTask, taskToUpdate],
  );

  useEffect(() => {
    if (/stream/.test(pathname)) {
      const orgUnitInstanceId = pathname.split('/')[2];
      dispatch(fetchElementsByUnit(orgUnitInstanceId));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (taskToUpdate) {
      setValue('taskDescription', taskToUpdate.description);
      setValue('taskName', taskToUpdate.name);
      setValue('evaluationtype', {
        value: taskToUpdate?.evaluationType,
        label: taskToUpdate?.evaluationName,
      });
      setElementId(taskToUpdate.elementId);
      setPerformanceCriteriaId(taskToUpdate?.performanceCriteriaId);
      setPerformanceEvidenceId(taskToUpdate?.performanceEvidenceId);
      setKnowledgeEvidenceId(taskToUpdate?.knowledgeEvidenceId);
      setAssessmentConditionId(taskToUpdate?.assessmentConditionId);
    }
  }, [setValue, taskToUpdate]);

  const onUpload = useCallback(
    files => {
      Array.from(files).forEach(file => {
        const id = Math.floor(Math.random() * 1000) + 1000;
        const body = new FormData();
        body.append('file', file);
        body.append('title', file.name);

        const uploadFile = fileType => {
          dispatch(
            addFileInstruction(
              activityId,
              activeTask.ai_id_activityitem,
              body,
              linkID => {
                setInstructions(previousValue =>
                  previousValue.map(item => {
                    if (item.id !== id) return item;

                    return {
                      ...item,
                      linkID,
                      uploaded: true,
                    };
                  }),
                );
              },
              taskToUpdate?.id,
            ),
          );

          setInstructions(previousValue => [
            ...previousValue,
            {
              duration: body.get('duration'),
              id,
              media: URL.createObjectURL(body.get('file')),
              text: body.get('title'),
              type: fileType,
              uploaded: false,
            },
          ]);
        };

        if (file.type.includes('image')) {
          body.append('duration', file.size);
          uploadFile('image');
        } else if (file.type.includes('video')) {
          const videoElement = document.createElement('video');
          videoElement.preload = 'metadata';

          videoElement.onloadedmetadata = () => {
            URL.revokeObjectURL(videoElement.src);
            body.append('duration', videoElement.duration);
            uploadFile('video');
          };

          videoElement.src = URL.createObjectURL(file);
        } else {
          body.append('duration', file.size);
          uploadFile('document');
        }
      });
    },
    [activityId, dispatch, activeTask?.ai_id_activityitem, taskToUpdate?.id],
  );

  useEffect(() => {
    if (activeTask && Array.isArray(activeTask?.linkedFiles)) {
      setInstructions(previousValue =>
        previousValue.length && activeTask.linkedFiles.length
          ? previousValue
          : activeTask.linkedFiles.map(item => {
              const file = item.fi_id_filetype ? item : item.file;
              return getInstruction(file, item);
            }),
      );
    }
  }, [activeTask]);

  const onAddPress = useCallback(() => {
    const descField = getValues('taskDescription');
    const taskName = getValues('taskName');
    const evaluationTypeField = getValues('evaluationtype');

    if (!descField?.length) {
      setError('taskDescription', {
        message: 'Please Enter Task Description',
      });
    }
    if (!taskName?.length) {
      setError('taskName', {
        message: 'Please Enter Task Name',
      });
    }
    if (!evaluationTypeField) {
      setError('evaluationtype', {
        message: 'Please Select Evaluation Type',
      });
    }

    if (
      !descField ||
      !taskName ||
      !evaluationTypeField ||
      instructions.some(item => !item.uploaded)
    )
      return;

    if (clearErrors) clearErrors();

    dispatch(
      updateTask(
        activeTask?.ai_id_activityitem,
        {
          description: descField,
          title: taskName,
          evaluationType: evaluationTypeField,
          performanceCriteriaId: performanceCriteriaId?.value,
          performanceEvidenceId: performanceEvidenceId?.value,
          knowledgeEvidenceId: knowledgeEvidenceId?.value,
          assessmentConditionId: assessmentConditionId?.value,
        },
        isPublished,
        Boolean(taskToUpdate),
        addTask
          ? () => {
              addTask(activeTask.ai_id_activityitem);
              showToast(
                ToastSuccess,
                'Task Has been added successfully',
                false,
              );
            }
          : () => {
              showToast(
                ToastSuccess,
                'Task Has been updated successfully',
                false,
              );
            },
      ),
    );

    setValue('taskName', '');
    setValue('taskDescription', '');
    setValue('evaluationtype', {});
    setElementId(null);
    setPerformanceCriteriaId(null);
    setPerformanceEvidenceId(null);
    setKnowledgeEvidenceId(null);
    setAssessmentConditionId(null);
    closeAddTask();
  }, [
    activeTask,
    addTask,
    assessmentConditionId,
    clearErrors,
    closeAddTask,
    dispatch,
    getValues,
    instructions,
    isPublished,
    knowledgeEvidenceId,
    performanceCriteriaId,
    performanceEvidenceId,
    setError,
    setValue,
    taskToUpdate,
  ]);

  return (
    <>
      <FormSection
        register={register}
        elementGroup={elementGroup}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        elementId={elementId}
        performanceCriteriaId={performanceCriteriaId}
        performanceEvidenceId={performanceEvidenceId}
        knowledgeEvidenceId={knowledgeEvidenceId}
        assessmentConditionId={assessmentConditionId}
        setPerformanceCriteriaId={setPerformanceCriteriaId}
        setPerformanceEvidenceId={setPerformanceEvidenceId}
        setKnowledgeEvidenceId={setKnowledgeEvidenceId}
        setAssessmentConditionId={setAssessmentConditionId}
      />
      <AddedInstructions
        setInstructions={setInstructions}
        activityId={activityId}
        instructions={instructions}
        setValue={setValue}
        taskToUpdate={taskToUpdate}
        fileInput={fileInput}
        updateLocalInstruction={updateLocalInstruction}
        isArchive={isArchive}
      />
      <div
        className="d-flex align-items-center pt-4 px-4"
        style={{borderTop: '0.0625rem solid #f2f2f4'}}
      >
        <input
          // accept=".doc,.csv,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,video/*,application/pdf"
          className="task-attach-file"
          onChange={event => onUpload(event.target.files)}
          multiple
          name="files"
          ref={fileInput}
          type="file"
          id="task-attach"
        />
        <div
          className="ml-auto mb-0 cancel-task-btn"
          role="button"
          id="cancel-id"
          tabIndex={0}
          onKeyDown={null}
          onClick={() => {
            if (!taskToUpdate) {
              dispatch(removeAllInstructions(activeTask.ai_id_activityitem));
            }

            closeAddTask();
          }}
        >
          Cancel
        </div>
        <Button
          variant="outline-primary"
          className="save-task-btn"
          onClick={onAddPress}
          id="update-add-id"
        >
          {taskToUpdate ? 'Update' : 'Save'}
        </Button>
      </div>
    </>
  );
};
