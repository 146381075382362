/* eslint-disable react/prop-types */

import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import InfoIcon from '../../../../../../assets/icons/info.svg';
import {ReleasesCellContainer} from './elements';

export const ReleasesCell = ({prefix, releases}) => {
  const currentRelease = (releases || []).slice(-1)[0] || {};
  const previousReleases = releases?.slice(0, -1);
  const dataTip = `
    <div class="d-flex flex-column">
      <span style="font-weight: 500;">Previous Releases:</span>
      ${previousReleases?.map(
        item =>
          `<span>&nbsp;&nbsp;•&nbsp;Release ${
            item[`${prefix}_release_number`]
          } (${moment(item[`${prefix}_release_date`]).format(
            'D MMM YYYY',
          )})</span>`,
      )}
    </div>
  `;

  return (
    <ReleasesCellContainer className="align-items-center d-flex">
      <span className="release-number">
        Release {currentRelease[`${prefix}_release_number`]}{' '}
        <span className="release-date">
          (
          {moment(currentRelease[`${prefix}_release_date`]).format(
            'D MMM YYYY',
          )}
          )
        </span>
      </span>
      {previousReleases?.length ? (
        <>
          <div
            className="align-items-center d-flex info-container justify-content-center ml-1"
            data-html
            data-tip={dataTip}
            type="button"
          >
            <img alt="Info" className="info-icon" src={InfoIcon} />
          </div>
          <ReactTooltip
            backgroundColor="#3d4457"
            effect="solid"
            place="right"
          />
        </>
      ) : null}
    </ReleasesCellContainer>
  );
};
