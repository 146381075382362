/* eslint-disable react/prop-types */
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';

import {portfolioSelector} from '../../../../../../redux/portfolioSlice';
import {TableSpinner} from '../../../../../Admin/components-new';
import {EmptyState, Table} from '../../../../../../components/common';
import {ClassAttendanceTableConatiner} from './elements';

export const ClassAttendanceTable = () => {
  const {unitAttendance, isLoading} = useSelector(portfolioSelector);
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'index',
        className: 'serial',
      },
      {
        Header: 'CLASS NAME',
        Cell: ({row: {original}}) => (
          <div className="class-unit-name-div">
            <p className="class-unit-name" title={original?.class?.oc_class}>
              {`${original?.class?.oc_class} - ${original?.orgUnit?.unit?.tun_title}`}
            </p>
          </div>
        ),
        accessor: 'classname',
        className: 'class-name',
      },
      {
        Header: 'UNIT CODE',
        Cell: ({row: {original}}) => (
          <div className="class-unit-name-div">
            <p className="class-unit-name" title={original?.class?.oc_class}>
              {`${original?.orgUnit?.unit?.tun_code}`}
            </p>
          </div>
        ),
        accessor: 'unitcode',
        className: 'unit-code',
      },
      {
        Header: 'TOTAL SESSIONS',
        Cell: ({row: {original}}) => {
          const totalSession = original?.sessions?.length;
          return (
            <div className="class-unit-name-div">
              <p
                className="class-unit-name"
                title={original?.orgUnitInstance?.class?.oc_class}
              >
                {totalSession}
              </p>
            </div>
          );
        },
        accessor: 'sessions',
        className: 'total-session',
      },
      {
        Header: 'PRESENT',
        Cell: ({row: {original}}) => {
          const presentSession = original?.sessions?.filter(
            item => item?.oruia_id_typeattendance === 2,
          );
          return (
            <div className="present-session-div">
              <p
                className="present-session"
                title={original?.orgUnitInstance?.class?.oc_class}
              >
                {presentSession?.length < 10
                  ? `0${presentSession?.length}`
                  : presentSession?.length}
              </p>
            </div>
          );
        },
        accessor: 'presentsession',
        className: 'present',
      },
      {
        Header: 'ABSENT',
        Cell: ({row: {original}}) => {
          const absentSession = original?.sessions?.filter(
            item => item?.oruia_id_typeattendance === 3,
          );
          return (
            <div className="absent-session-div">
              <p
                className="absent-session"
                title={original?.orgUnitInstance?.class?.oc_class}
              >
                {absentSession?.length < 10
                  ? `0${absentSession?.length}`
                  : absentSession?.length}
              </p>
            </div>
          );
        },
        accessor: 'absentsession',
        className: 'absent',
      },
    ],
    [],
  );
  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(unitAttendance || []).map((item, index) => ({
        ...item?.orgUnitInstance,
        classname: `${item?.orgUnitInstance?.class?.oc_class} - ${item?.orgUnitInstance?.orgUnit?.unit?.tun_title}`,
        index: index + 1,
      })),
    ],
    [unitAttendance],
  );

  return (
    <>
      <Helmet>
        <title>Attendance | JungleCat</title>
      </Helmet>
      <ClassAttendanceTableConatiner>
        <div className="table-container">
          {isLoading ? <TableSpinner /> : null}
          {!unitAttendance && !unitAttendance?.length ? (
            <EmptyState
              description="try adjusting the search or filter for what you are looking for"
              title="No Notifications Found"
            />
          ) : null}

          {!isLoading &&
          unitAttendance?.length &&
          Array.isArray(unitAttendance) &&
          !isLoading ? (
            <Table
              columns={columns}
              data={data}
              itemName="Active Classes"
              maxWidth="70rem"
              isSortedBy
            />
          ) : null}
        </div>
      </ClassAttendanceTableConatiner>
    </>
  );
};
