/* eslint-disable react/prop-types */
import React, {useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {DropdownItem} from '../../../../../../../../../../components/common/MoreOptions/components';
import {
  classesSelector,
  revokeStudentFromClass,
} from '../../../../../../../../../../redux/classesSlice';
import {
  Table,
  Modal,
  EmptyState,
  MoreOptions,
} from '../../../../../../../../../../components/common';
import PlusIcon from '../../../../../../../../../../assets/icons/refactor/plus-white.svg';

import {showToast} from '../../../../../../../../../../components/common/Toast';

import {TableSpinner} from '../../../../../../../../components-new';

import {StudentsContainer} from './elements';

const DELAY = 500;

export const Students = ({orgClassStudents}) => {
  const [deleteName, setDeleteName] = useState('');
  const [deleteId, setDeleteId] = useState(null);

  const {orgClassId} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {isSingleClassLoading, isDeleteLoading} = useSelector(classesSelector);

  const onButtonClick = () => {
    history.push(`/admin/classes/view-class/${orgClassId}/add-students`);
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Id',
        accessor: 'userProfile.up_org_reference',
        className: 'ref-id',
      },
      {
        Header: 'First Name',
        accessor: 'userProfile.up_name_first',
        className: 'first-name',
      },
      {
        Header: 'Last Name',
        accessor: 'userProfile.up_name_last',
        className: 'last-name',
      },
      {
        Header: 'Email',
        accessor: 'userProfile.up_email',
        className: 'email',
      },
      {
        Header: '',
        Cell: ({row: {original}, value}) => (
          <MoreOptions>
            <DropdownItem
              isDanger
              label="Revoke Student"
              onClick={() => {
                setDeleteId(value);
                setDeleteName(
                  `${original?.userProfile?.up_name_first} ${original?.userProfile?.up_name_last}`,
                );
              }}
            />
          </MoreOptions>
        ),
        accessor: 'ocs_id_orgclass_student',
        className: 'more-option',
      },
    ],
    [],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(orgClassStudents || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [orgClassStudents],
  );

  return (
    <StudentsContainer>
      <div className="table-container">
        {isSingleClassLoading ? <TableSpinner /> : null}
        {!isSingleClassLoading &&
        Array.isArray(orgClassStudents) &&
        !orgClassStudents?.length ? (
          <EmptyState
            description="It seems that there is no Classes has been added to the organisation"
            title="No Students are added yet!"
            buttonLabel="Add Student"
            buttonIcon={PlusIcon}
            onButtonClick={onButtonClick}
          />
        ) : null}

        {!isSingleClassLoading && orgClassStudents?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName="Students"
            maxWidth="75rem"
            isSortedBy
            buttonLabel="Add Student"
            buttonIcon={PlusIcon}
            onButtonClick={onButtonClick}
          />
        ) : null}
      </div>
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to Revoke the user from this class?"
        isDelete
        isButtonLoading={isDeleteLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          dispatch(
            revokeStudentFromClass(deleteId, () => {
              setDeleteId(null);
              showToast(
                false,
                `${deleteName} Has been revoked from class.`,
                true,
              );
              setTimeout(() => {
                setDeleteName('');
              }, DELAY);
            }),
          );
        }}
        onHide={() => {
          setDeleteId(null);
          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
    </StudentsContainer>
  );
};
