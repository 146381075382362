/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DashBoardContainer} from '../../Elements/DashBoardContainer';
import addArrow from '../../../../assets/icons/Arrow.svg';
import userCheckMark from '../../../../assets/icons/user-checkmark.svg';
import workBook from '../../../../assets/icons/work_logo.svg';
import userTeacher from '../../../../assets/icons/user_teachers.svg';
import userStudent from '../../../../assets/icons/user-students.svg';
import viewDetails from '../../../../assets/icons/basicinfo-view-details.svg';
import academicLogo from '../../../../assets/icons/academic-logo.svg';
import departmentLogo from '../../../../assets/icons/department-logo.svg';
import workLogo from '../../../../assets/icons/dashboard-work-logo.svg';
import arrowLogo from '../../../../assets/icons/org-arrow.svg';
import unitMark from '../../../../assets/icons/units-mark.svg';
import workMark from '../../../../assets/icons/org-work-mark.svg';
import addCircle from '../../../../assets/icons/add-circle.svg';
import basicInfoEmpty from '../../../../assets/icons/cart-is-empty.svg';
import addIcon from '../../../../assets/icons/addIcon.svg';
import lockUnit from '../../../../assets/icons/lock-unit.svg';
import CustomIconButton from '../../../../components/shared/CustomIconButton';
import loader from '../../../../assets/icons/orgLogoLoader.svg';
import {
  fetchDashboard,
  dashboardSelector,
} from '../../../../redux/dashboardSlice';

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashboard());
  }, []);
  const {dashboard, isLoading} = useSelector(dashboardSelector);

  return (
    <>
      <Helmet>
        <title>Dashboard | JungleCat</title>
      </Helmet>
      <DashBoardContainer>
        <div className="container-dashboard">
          <div className="dashboard-head">
            <span className="dashboard-header-title">Dashboard</span>
            {dashboard?.academicYear ? (
              <div className="dashboard-header-bottom">
                <span className="academic-year-title">Academic Year </span>
                <span className="academic-year">
                  {moment(dashboard?.academicYear?.oay_from_date).format(
                    'MMM YYYY',
                  )}{' '}
                  -{' '}
                  {moment(dashboard?.academicYear?.oay_to_date).format(
                    'MMM YYYY',
                  )}
                </span>
              </div>
            ) : null}
          </div>
          <div className="row left">
            {isLoading ? (
              <div className="loader-images offset-6">
                <img src={loader} alt="loader" className="button-spinner" />
              </div>
            ) : null}
            <div className="col-md-8">
              <div className="col-md-12">
                {!isLoading ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="dashboard-content-head">Users</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 user-admin">
                          <button
                            className="admin-top-div col-md-12"
                            type="button"
                            onClick={() => {
                              history.push('admin/users/admin');
                            }}
                            id="useradmin-id"
                          >
                            <div className="user-top mb-5">
                              <div className="mx-2">
                                <span className="user-top-content-left-top">
                                  {dashboard?.adminCount > 9
                                    ? `${dashboard?.adminCount}`
                                    : `0${dashboard?.adminCount}`}
                                </span>
                                <p className="user-top-content-left-bottom">
                                  Admins
                                </p>
                              </div>
                              <div>
                                <img src={userCheckMark} alt="close" />
                              </div>
                            </div>
                            <div className="footer-delete-break" />
                            <div className="user-bottom mt-3">
                              <div className="user-bottom-content-left">
                                <div className="mt-2">
                                  <span className="user-bottom-content-left-top">
                                    {dashboard?.pendingAdminCount > 9
                                      ? `${dashboard?.pendingAdminCount}`
                                      : `0${dashboard?.pendingAdminCount}`}
                                  </span>
                                  <p className="user-bottom-content-left-bottom">
                                    Invitation Pending
                                  </p>
                                </div>
                                <div className="mt-2 user-bottom-content-right">
                                  <button type="button" id="adminpage-id">
                                    <img src={addArrow} alt="close" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div className="col-md-4 user-teachers">
                          <button
                            className="teacher-top-div col-md-12"
                            type="button"
                            onClick={() => {
                              history.push('admin/users/admin');
                            }}
                            id="userteacher-id"
                          >
                            <div className="user-top mb-5">
                              <div className="mx-2">
                                <span className="user-top-content-left-top">
                                  {dashboard?.teacherCount > 9
                                    ? `${dashboard?.teacherCount}`
                                    : `0${dashboard?.teacherCount}`}
                                </span>
                                <p className="user-top-content-left-bottom">
                                  Teachers
                                </p>
                              </div>
                              <div>
                                <img src={userTeacher} alt="close" />
                              </div>
                            </div>

                            {dashboard?.teacherCount > 0 ? (
                              <div>
                                <div className="footer-delete-break" />
                                <div className="user-bottom mt-3">
                                  <div className="user-bottom-content-left">
                                    <div className="mt-2">
                                      <span className="user-bottom-content-left-top">
                                        {dashboard?.pendingTeacherCount > 9
                                          ? `${dashboard?.pendingTeacherCount}`
                                          : `0${dashboard?.pendingTeacherCount}`}
                                      </span>
                                      <p className="user-bottom-content-left-bottom">
                                        Invitation Pending
                                      </p>
                                    </div>
                                    <div className="mt-2 user-bottom-content-right">
                                      <button type="button" id="teacherpage-id">
                                        <img src={addArrow} alt="close" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="user-bottom">
                                  <div className="user-bottom-content-left-none">
                                    <div className="mt-2">
                                      <p className="user-bottom-content-left-bottom-none">
                                        Teachers
                                      </p>
                                    </div>
                                    <div className="user-bottom-content-right">
                                      <button type="button">
                                        <img src={addCircle} alt="close" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </button>
                        </div>
                        <div className="col-md-4 user-students">
                          <button
                            className="student-top-div col-md-12"
                            type="button"
                            onClick={() => {
                              history.push('admin/users/student');
                            }}
                            id="userstudent-id"
                          >
                            <div className="user-top mb-5">
                              <div className="mx-2">
                                <span className="user-top-content-left-top">
                                  {dashboard?.studentCount > 9
                                    ? `${dashboard?.studentCount}`
                                    : `0${dashboard?.studentCount}`}
                                </span>
                                <p className="user-top-content-left-bottom">
                                  Students
                                </p>
                              </div>
                              <div>
                                <img src={userStudent} alt="close" />
                              </div>
                            </div>
                            {dashboard?.studentCount > 0 ? (
                              <div>
                                <div className="footer-delete-break" />
                                <div className="user-bottom mt-3">
                                  <div className="user-bottom-content-left">
                                    <div className="mt-2">
                                      <span className="user-bottom-content-left-top">
                                        {dashboard?.pendingStudentCount > 9
                                          ? `${dashboard?.pendingStudentCount}`
                                          : `0${dashboard?.pendingStudentCount}`}
                                      </span>
                                      <p className="user-bottom-content-left-bottom">
                                        Invitation Pending
                                      </p>
                                    </div>
                                    <div className="mt-2 user-bottom-content-right">
                                      <button type="button" id="studentpage-id">
                                        <img src={addArrow} alt="close" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="user-bottom">
                                  <div className="user-bottom-content-left-none">
                                    <div className="mt-2">
                                      <p className="user-bottom-content-left-bottom-none">
                                        Students
                                      </p>
                                    </div>
                                    <div className="user-bottom-content-right">
                                      <button type="button">
                                        <img src={addCircle} alt="close" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!isLoading ? (
                  <div className="row dashboard-content-bottom">
                    <div className="col-md-6 units">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="dashboard-content-head">Units</p>
                        </div>
                      </div>
                      {dashboard?.departmentCount > 0 &&
                      dashboard?.academicYearCount > 0 ? (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="units-works">
                              <div className="units-works-top">
                                <div className="units-works-logo">
                                  <img src={workBook} alt="close" />
                                </div>
                              </div>
                              <div className="units-works-div">
                                <div className="units-work-bottom-content-left">
                                  <span className="units-works-bottom-top">
                                    {dashboard?.unitsCount > 9
                                      ? `${dashboard?.unitsCount}`
                                      : `0${dashboard?.unitsCount}`}
                                  </span>
                                  <p className="units-works-bottom-bottom">
                                    Units in {dashboard?.classCount} Classes
                                  </p>
                                </div>
                                <div className="units-work-bottom-content-right">
                                  <img src={unitMark} alt="close" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="units-works-lock">
                              <div className="overlay">
                                <div className="text-lock">
                                  <div>
                                    <img
                                      src={lockUnit}
                                      alt="close"
                                      width="40px"
                                      height="40px"
                                    />
                                  </div>

                                  <div>
                                    <p className="text-content">
                                      To add a Unit, you must first create a
                                      department and an academic year.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="units-works-lock-div">
                              <div className="units-works-top">
                                <div className="units-works-logo">
                                  <img src={workBook} alt="close" />
                                </div>
                                {dashboard?.departmentCount > 0 &&
                                dashboard?.unitsCount === 0 ? (
                                  <div>
                                    <CustomIconButton
                                      bgColor="#fff"
                                      icon={addIcon}
                                      text="Add Now"
                                      onClick={() => {
                                        history.push(
                                          'admin/qualifications/units',
                                        );
                                      }}
                                      id="add-now2-id"
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    {/* <CustomButton
                                      className="view-all-button"
                                      bgColor="#fff"
                                      onClick={() => {
                                        history.push('admin/units');
                                      }}
                                    >
                                      <span className="viewall-button-text">
                                        View All
                                      </span>
                                    </CustomButton> */}
                                    <CustomIconButton
                                      bgColor="#fff"
                                      icon={addIcon}
                                      text="Add Now"
                                      onClick={() => {
                                        history.push(
                                          'admin/qualifications/units',
                                        );
                                      }}
                                      id="add-now3-id"
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="units-works-div">
                                <div className="units-work-bottom-content-left">
                                  <span className="units-works-bottom-top">
                                    {dashboard?.unitsCount > 9
                                      ? `${dashboard?.unitsCount}`
                                      : `0${dashboard?.unitsCount}`}
                                  </span>
                                  <p className="units-works-bottom-bottom">
                                    {/* Units in {dashboard?.classCount} Classes */}
                                    Units
                                  </p>
                                </div>
                                <div className="units-work-bottom-content-right">
                                  <img src={unitMark} alt="close" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 units">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="dashboard-content-head">Works</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="units-works">
                            <div className="units-works-top">
                              <div className="units-works-logo">
                                <img src={workLogo} alt="close" />
                              </div>
                              {/* {dashboard?.unitsCount === 0 ? (
                                dashboard.departmentCount === 0 ? null : (
                                  <div>
                                    <CustomIconButton
                                      bgColor="#fff"
                                      icon={addIcon}
                                      text="Add Now"
                                      onClick={() => {
                                        history.push('admin/units');
                                      }}
                                    />
                                  </div>
                                )
                              ) : (
                                <div>
                                  <CustomButton
                                    className="view-all-button"
                                    bgColor="#fff"
                                    onClick={() => {
                                      history.push('admin/units');
                                    }}
                                  >
                                    <span className="viewall-button-text">
                                      View All
                                    </span>
                                  </CustomButton>
                                </div>
                              )} */}
                            </div>
                            <div className="units-works-div">
                              <div className="units-work-bottom-content-left">
                                <span className="units-works-bottom-top">
                                  {dashboard?.workCount > 9
                                    ? `${dashboard?.workCount}`
                                    : `0${dashboard?.workCount}`}
                                </span>
                                <p className="units-works-bottom-bottom">
                                  Works in {`${dashboard?.unitsCount} `}
                                  Units
                                </p>
                              </div>
                              <div className="units-work-bottom-content-right">
                                <img
                                  src={workMark}
                                  alt="close"
                                  className="work-mark-logo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {!isLoading ? (
              <div className="col-md-4 basic-info">
                <div className="col-md-12">
                  <div className="dashboard-right">
                    <div className="dashboard-right-content">
                      <div className="basic-info-title-div">
                        <span className="basic-info-title">BasicInfo</span>
                      </div>
                      {dashboard?.basicInfoDetail ? (
                        <div>
                          <div className="basic-info-logo">
                            {dashboard?.basicInfoDetail?.or_logo_url ? (
                              <img
                                src={dashboard?.basicInfoDetail?.or_logo_url}
                                alt="Logo"
                                className="basic-info-logo-img"
                              />
                            ) : (
                              <div className="basic-info-logo-empty">
                                <img
                                  src={basicInfoEmpty}
                                  alt="Logo"
                                  className="basic-info-logo-empty"
                                />
                              </div>
                            )}
                          </div>
                          <div className="basic-info-name-div">
                            <span className="basic-info-name">
                              {dashboard?.basicInfoDetail?.or_orgname}
                            </span>
                          </div>
                          <div className="basic-info-address-div">
                            <span className="basic-info-address">
                              {dashboard?.basicInfoDetail?.or_address}
                            </span>
                          </div>

                          <div className="basic-info-details-div">
                            <button
                              type="button"
                              className="basic-info-details"
                              onClick={() =>
                                history.push('admin/organisation/basic-info')
                              }
                              id="basic-info-page-id"
                            >
                              <div>
                                {' '}
                                <img src={viewDetails} alt="Logo" />
                              </div>
                              <div>
                                {' '}
                                <span className="basic-info-view-details">
                                  View Details
                                </span>
                              </div>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="basic-info-logo-empty">
                            <img
                              src={basicInfoEmpty}
                              alt="Logo"
                              className="basic-info-logo-empty"
                            />
                          </div>

                          <div className="basic-info-details-div-empty">
                            <CustomIconButton
                              className="addbtn"
                              icon={addIcon}
                              text="Add Basic Info"
                              onClick={() => {
                                history.push('admin/organisation/basic-info');
                              }}
                              id="add-basic-info-id"
                            >
                              {' '}
                            </CustomIconButton>
                          </div>
                        </div>
                      )}
                      <div className="organisation-div">
                        <div className="organisation1">
                          {' '}
                          <div className="organisation-left">
                            <div className="units-works-logo">
                              <img
                                src={departmentLogo}
                                alt="close"
                                className="org-logo"
                              />
                            </div>
                            <div className="mx-3">
                              <span className="org-dept-academic-count">
                                {' '}
                                {dashboard?.departmentCount > 9
                                  ? `${dashboard?.departmentCount}`
                                  : `0${dashboard?.departmentCount}`}
                              </span>
                              <br />

                              <span className="org-top-content-left-bottom">
                                Departments
                              </span>
                            </div>
                          </div>
                          {dashboard?.departmentCount > 0 ? (
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  history.push(
                                    'admin/organisation/departments',
                                  );
                                }}
                                id="departmentpage-id"
                              >
                                <img src={arrowLogo} alt="close" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  history.push(
                                    'admin/organisation/departments',
                                  );
                                }}
                                id="add-departmentpage-id"
                              >
                                <img src={addCircle} alt="close" />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="organisation2">
                          {' '}
                          <div className="organisation-left">
                            <div className="units-works-logo mt-1">
                              <img
                                src={academicLogo}
                                alt="close"
                                className="org-logo"
                              />
                            </div>
                            <div className="mx-3">
                              <span className="org-dept-academic-count">
                                {dashboard?.academicYearCount > 9
                                  ? `${dashboard?.academicYearCount}`
                                  : `0${dashboard?.academicYearCount}`}
                              </span>
                              <br />

                              <span className="org-top-content-left-bottom">
                                Academic Years
                              </span>
                            </div>
                          </div>
                          {dashboard?.academicYearCount > 0 ? (
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  history.push(
                                    'admin/organisation/academicyear',
                                  );
                                }}
                                id="academicpage-id"
                              >
                                <img src={arrowLogo} alt="close" />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  history.push(
                                    'admin/organisation/academicyear',
                                  );
                                }}
                                id="addacademicpage-id"
                              >
                                <img src={addCircle} alt="close" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </DashBoardContainer>
    </>
  );
};
export default Dashboard;
