/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {Button} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';

import SearchInput from '../../../../../../../../components/shared/Inputs/SearchInput';
import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';

import {
  publishAllCurriculumModuleFiles,
  getCurriculumByOrgUnitInstanceId,
  teacherCurriculumSelector,
} from '../../../../../../../../redux/teacherCurriculumSlice';
import UpdateIcon from '../../../../../../../../assets/icons/updateadmintoast.svg';

import {ViewCurriculumHeaderContainer} from './elements';

export const ViewCurriculumHeader = ({
  setSearchTerm,
  searchTerm,
  orgUnitInstanceId,
  orgClassCurriculumId,
  onBack,
  moduleDataLength,
  notPublishedFiles,
  academicYear,
}) => {
  const dispatch = useDispatch();

  const {courseCurriculum, isLoading} = useSelector(teacherCurriculumSelector);

  const onSubmitFiles = useCallback(() => {
    if (!isLoading) {
      dispatch(
        publishAllCurriculumModuleFiles(
          orgUnitInstanceId,
          orgClassCurriculumId,
          () => {
            showToast(UpdateIcon, `All modules Published successfully `, false);
            dispatch(getCurriculumByOrgUnitInstanceId(orgUnitInstanceId));
          },
        ),
      );
    }
  }, [dispatch, isLoading, orgClassCurriculumId, orgUnitInstanceId]);

  return (
    <ViewCurriculumHeaderContainer>
      <div className="header-container" id="header-container">
        <div className="header-top">
          <div className="left-content">
            <div className="back-container">
              <div>
                <button
                  className="backbutton"
                  onClick={onBack}
                  type="button"
                  id="back-btn-id"
                >
                  <img src={ArrowLeftImg} alt="arrow" />
                </button>
              </div>
              <div>
                <h2 className="title">{courseCurriculum?.ocr_course_name}</h2>
                <p className="year">{academicYear}</p>
              </div>
            </div>
          </div>
          <div className="right-content">
            <SearchInput
              className="class-search"
              onChange={event => setSearchTerm(event.target.value)}
              placeholder="Search files or modules"
              value={searchTerm}
              width="300px"
              id="search-file-id"
            />
            {moduleDataLength ? (
              <Button
                className="button add-button"
                onClick={() => {
                  if (notPublishedFiles) {
                    onSubmitFiles();
                  }
                }}
                isFullWidth={false}
                label="Publish All"
                isDisabled={!notPublishedFiles}
                id="publish-all-id"
              />
            ) : null}
          </div>
        </div>
      </div>
    </ViewCurriculumHeaderContainer>
  );
};
