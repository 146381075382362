import PropTypes from 'prop-types';
import {useMemo} from 'react';
import styled from 'styled-components';

const SessionBarContainer = styled.div`
  background-color: #54abff;
  border-radius: 0.1875rem;
  bottom: 0.875rem;
  height: 0.875rem;
  left: ${({left}) => `${left}px`};
  width: ${({width}) => `${width}px`};
`;

const SessionBar = ({from, to}) => {
  // The offset of the bar from the left.
  const leftOffset = useMemo(() => {
    const [hoursToStart, minutesToStart] = from.split(':').map(Number);
    return hoursToStart * 60 + minutesToStart + 20;
  }, [from]);

  // The width of the bar.
  const barWidth = useMemo(() => {
    const [hoursToStop, minutesToStop] = to.split(':').map(Number);
    return hoursToStop * 60 + minutesToStop + 20 - leftOffset;
  }, [leftOffset, to]);

  return (
    <SessionBarContainer
      className="position-absolute"
      left={leftOffset}
      width={barWidth}
    />
  );
};

SessionBar.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default SessionBar;
