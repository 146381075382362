import styled from 'styled-components';

export const TaskEvaluationContainer = styled.main`
  height: calc(100vh - 9.5rem);
  overflow-y: auto;
  padding-left: 3rem;
  padding-right: 3rem;

  .padding {
    height: 2rem;
    width: 100%;
  }

  header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 1.75rem 0;
    width: 100%;

    .back-btn {
      display: block;
      & > button {
        background-color: transparent;
        border: none;
        border-bottom: 0.0625rem solid #40a1ff;
        color: #40a1ff;
        font-size: 0.875rem;

        :active,
        :focus {
          outline: none;
        }
      }
    }
    .left {
      align-items: center;
      display: flex;
      .profile-details {
        display: flex;

        span {
          line-height: 1;
        }
      }

      .back-btn-hide {
        display: none;
      }

      img {
        height: 3rem;
        margin-right: 0.75rem;
      }

      .name {
        color: #3d4457;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.15;
      }

      .submission-date {
        color: #aaa;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.15;
        margin-bottom: 0;

        .primary-text {
          color: ${({theme}) => theme.colors.primary};
          font-weight: 500;
        }
      }
    }

    & > button {
      background-color: transparent;
      border: none;
      border-bottom: 0.0625rem solid #40a1ff;
      color: #40a1ff;
      font-size: 0.875rem;

      :active,
      :focus {
        outline: none;
      }
    }
  }
  .task-evaluation {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 69.75rem) {
      & {
        max-width: 70%;
        margin: 9% 5% 30% 4.8% !important;
        display: flex;
        flex-direction: column;
      }
    }
    @media (min-height: 32rem) {
      height: calc(100vh - 12.62rem);
    }

    @media (max-width: 60.5rem) {
      & {
        width: 90%;
      }
    }
    @media (max-width: 43.75rem) {
      & {
        max-width: 90%;
        margin: 9% 5% 30% 4.8% !important;
        display: flex;
        flex-direction: column;
      }
      .left {
        border-right: unset !important;
        border-bottom: 0.0625rem solid #dedede !important;
        width: 100% !important;
      }
      .rightContent {
        width: 100% !important;
      }
      .right {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem !important;
        form .right-row label {
          margin-right: 0.937rem !important;
        }
        form .right-row {
          display: flex;
          justify-content: space-between;

          .mark-input-response {
            width: 85%;

            word-wrap: wrap auto;
            .mark-input {
              input {
                width: 90%;
              }
            }
          }
          .label {
            width: 15% !important;
          }
        }
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      width: calc(50% - 0.75rem);

      .file {
        border-radius: 0.5rem;
        height: calc(100% - 3.375rem);
        width: 100%;

        :active,
        :focus {
          outline: none;
        }
      }

      .video-container {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        overflow: hidden;

        & > .video-js {
          height: 100%;
          width: 100%;

          .vjs-control-bar {
            background-color: #646464;
          }

          video {
            height: 100%;
            width: 100%;
          }
        }
      }

      .timecodes {
        background-color: #525252;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        padding: 1.25rem 1rem 0.875rem 1.25rem;

        .timecode {
          display: flex;

          :not(:last-child) {
            margin-bottom: 1.25rem;
          }

          .time {
            align-items: center;
            background-color: #40a1ff;
            border: none;
            border-radius: 0.156rem;
            color: #fff;
            display: flex;
            font-size: 0.75rem;
            font-weight: 500;
            height: 1.625rem;
            justify-content: center;
            margin-right: 0.875rem;
            width: 4.625rem;

            :active,
            :focus {
              outline: none;
            }
          }

          .task-name {
            color: #fff;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
      }
    }

    .rightContent {
      width: calc(50% - 0.75rem);
    }
  }
`;
