/* eslint-disable react/prop-types */
import React, {useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {Controller} from 'react-hook-form';

import {
  Select,
  TextInput,
  DateInput,
} from '../../../../../../../../components/common';
import {TextArea} from '../../../../../../../../components/common/TextArea';
import {TextEditor} from '../../../../../../../../components/common/TextEditor';
import {BasicDetailContainer} from './elements';
import AssessmentStudentsSection from '../../../../../Assesments/Components/AssessmentStudentsSection';
import {
  getListAssessmentStudents,
  getAllActivityMethods,
} from '../../../../../Assesments/Assessment/slices/assessmentSlice';
import CustomNumberInput from '../../../../../../../../components/shared/CustomNumberInput';

export const BasicDetails = ({
  register,
  setValue,
  control,
  getValues,
  errors,
  isPublished,
  clearErrors,
  setSelectStudentError,
  selectStudentError,
  maximumDurationHoursError,
  maximumDurationMinutesError,
  setMaximumDurationHoursError,
  setMaximumDurationMinutesError,
  maximumDurationHours,
  setMaximumDurationHours,
  maximumDurationMinutes,
  setMaximumDurationMinutes,
  setAssessType,
  assessType,
  unitId,
  setIsUpdatingStudents,
  isUpdatingStudents,
}) => {
  const assessment = useSelector(state => state.assessment);
  const {createdAssessment, activityOrgunitInstanceId, assessmentType} =
    assessment;
  const dispatch = useDispatch();

  const setAdditionalInformations = additionalInformationValue => {
    setValue('additional-information', additionalInformationValue);
  };
  const setStudentInstructions = studentInstructionValue => {
    setValue('student-instructions', studentInstructionValue);
  };

  useEffect(() => {
    dispatch(getAllActivityMethods());
  }, [dispatch]);

  useEffect(() => {
    if (createdAssessment?.ac_activity_name && unitId) {
      // Setting default value for assessments basic details input
      setValue('assessTitle', createdAssessment.ac_activity_name);
      setValue(
        'description',
        createdAssessment.ac_activity_description !== null
          ? createdAssessment.ac_activity_description
          : '',
      );
      setValue(
        'maximum-hours',
        createdAssessment.ac_maximum_duration_hours !== null
          ? createdAssessment.ac_maximum_duration_hours
          : 0,
      );

      setValue(
        'maximum-minutes',
        createdAssessment.ac_maximum_duration_minutes !== null
          ? createdAssessment.ac_maximum_duration_minutes
          : 0,
      );

      setValue(
        'resourcerequired',
        createdAssessment.ac_resource_required !== null
          ? createdAssessment.ac_resource_required
          : getValues('resourcerequired'),
      );
      setValue(
        'location',
        createdAssessment.ac_location !== null
          ? createdAssessment.ac_location
          : getValues('location'),
      );
      setValue(
        'student-instructions',
        createdAssessment.ac_student_instructions !== ''
          ? createdAssessment.ac_student_instructions
          : getValues('student-instructions'),
      );
      setValue(
        'additional-information',
        createdAssessment.ac_additional_information !== null
          ? createdAssessment.ac_additional_information
          : getValues('additional-information'),
      );

      // getting students list by aoui_id_orgunitinstance
      dispatch(getListAssessmentStudents(unitId, activityOrgunitInstanceId));
      if (createdAssessment?.ac_due_date !== null) {
        setValue('dueDate', moment(createdAssessment?.ac_due_date).toDate());
      }
      if (createdAssessment?.ac_maximum_duration_hours !== null) {
        setMaximumDurationHours(createdAssessment?.ac_maximum_duration_hours);
      }
      if (createdAssessment?.ac_maximum_duration_minutes !== null) {
        setMaximumDurationMinutes(
          createdAssessment?.ac_maximum_duration_minutes,
        );
      }

      const hours = createdAssessment?.ac_maximum_duration_hours;
      const minutes = createdAssessment?.ac_maximum_duration_minutes;
      setMaximumDurationHours(hours || hours === 0 ? hours : 0);
      setMaximumDurationMinutes(minutes || minutes === 0 ? minutes : 0);
      setAssessType({
        value: createdAssessment?.ac_id_activitymethod,
        label:
          createdAssessment?.ac_id_activitymethod === 1
            ? 'Practical'
            : 'Written Assessment',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activityOrgunitInstanceId,
    createdAssessment,
    dispatch,
    getValues,
    isPublished,
    setValue,
    unitId,
  ]);

  const assessmentTypeOptions = useMemo(
    () =>
      assessmentType?.map(
        item =>
          ({
            value: item.am_id_activitymethod,
            label: item.am_activitymethod,
          } || []),
      ),
    [assessmentType],
  );
  return (
    <BasicDetailContainer>
      <div className="basic-details">
        <div className="basic-left">
          <Select
            className="mb-4"
            isLarge={false}
            label="Assessment Type"
            placeholder="Select the Assessment Type"
            value={assessType}
            options={assessmentTypeOptions}
            onChange={value => {
              setAssessType(value);
            }}
            id="assessment-type"
          />

          <Controller
            control={control}
            name="assessTitle"
            render={({onChange, ...fields}) => (
              <TextInput
                {...fields}
                className="mb-4"
                type="text"
                id="assessTitle"
                label="Assessment Title"
                errorMessage={errors?.assessTitle?.message}
                hasError={errors?.assessTitle?.message}
                placeholder="Enter the Title"
                onChange={e => {
                  onChange(e);
                  if (e.target.value) {
                    clearErrors('assessTitle');
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="description"
            render={({onChange, ...fields}) => (
              <TextArea
                {...fields}
                className="mr-4 mt-4 mb-4"
                label="Description"
                rows="5"
                type="text"
                id="description"
                errorMessage={errors?.description?.message}
                hasError={errors?.description?.message}
                placeholder="Enter description"
                height="159px"
                onChange={e => {
                  onChange(e);
                  if (e.target.value) {
                    clearErrors('description');
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="resourcerequired"
            render={({onChange, ...fields}) => (
              <TextArea
                {...fields}
                className="mr-4 mt-4 mb-4"
                label="Resources Required"
                rows="5"
                type="text"
                id="resourceRequired"
                placeholder="Enter the required resources"
                height="159px"
                onChange={e => {
                  onChange(e);
                }}
              />
            )}
          />

          <div className="date-hrs">
            <div className="date-input">
              <Controller
                control={control}
                name="dueDate"
                render={({onChange, ...fields}) => (
                  <DateInput
                    {...fields}
                    errorMessage={errors?.dueDate?.message}
                    hasError={errors?.dueDate?.message}
                    className="due-date-div"
                    label="Due Date"
                    placeholder="Select Due Date"
                    dateFormat="yyyy-MM-dd"
                    onChange={e => {
                      onChange(e);
                      clearErrors('dueDate');
                    }}
                    id="due-date"
                  />
                )}
              />
            </div>

            <div className="max-min-duration-inputs">
              <CustomNumberInput
                containerClassName="maximum-duration-input"
                label="Maximum Duration"
                onChange={event => {
                  setMaximumDurationHoursError(false);
                  if (+event.target.value < 0) {
                    return;
                  }
                  setMaximumDurationHours(event.target.value);
                  if (event) {
                    setMaximumDurationHoursError(false);
                    setMaximumDurationMinutesError(false);
                  }
                }}
                placeholder="Hrs"
                value={maximumDurationHours}
                type="number"
                onControl={setMaximumDurationHours}
                min={0}
                error={maximumDurationHoursError}
                id="hrs-id"
              />
              <CustomNumberInput
                containerClassName="maximum-duration-input"
                onChange={event => {
                  setMaximumDurationMinutesError(false);
                  if (+event.target.value > 59 || +event.target.value < 0) {
                    setMaximumDurationMinutes('59');
                  } else {
                    setMaximumDurationMinutes(event.target.value);
                  }
                  if (event) {
                    setMaximumDurationMinutesError(false);
                    setMaximumDurationHoursError(false);
                  }
                }}
                placeholder="Min"
                value={maximumDurationMinutes}
                type="number"
                onControl={setMaximumDurationMinutes}
                min={0}
                max={59}
                error={maximumDurationMinutesError}
                id="mins-id"
              />
            </div>
          </div>

          <Controller
            control={control}
            name="location"
            render={({onChange, ...fields}) => (
              <TextInput
                {...fields}
                className="mb-4"
                type="text"
                id="location"
                label="Location"
                placeholder="Enter the location"
                errorMessage={errors?.location?.message}
                hasError={errors?.location?.message}
                onChange={e => {
                  onChange(e);
                  if (e.target.value) {
                    clearErrors('location');
                  }
                }}
              />
            )}
          />

          <div className="assessment-basic-details">
            <h6 className="assess-label">Assign for</h6>
            <AssessmentStudentsSection
              isPublished={isPublished}
              isUpdating={isUpdatingStudents}
              stopUpdating={() => setIsUpdatingStudents(false)}
              register={register}
              errors={errors}
              hasError={selectStudentError}
              clearError={() => setSelectStudentError(false)}
            />
          </div>
        </div>
        <div className="basic-right">
          <Controller
            control={control}
            name="student-instructions"
            render={({onChange, ...fields}) => (
              <TextEditor
                {...fields}
                label="Student Instructions"
                onChange={e => {
                  onChange(e);
                  setStudentInstructions(e);
                }}
                id="student-instructions-id"
              />
            )}
          />
          <Controller
            control={control}
            name="additional-information"
            render={({onChange, ...fields}) => (
              <TextEditor
                {...fields}
                label="Additional Information"
                className="mt-4"
                onChange={e => {
                  onChange(e);
                  setAdditionalInformations(e);
                }}
                id="additional-inform-id"
              />
            )}
          />
        </div>
      </div>
    </BasicDetailContainer>
  );
};
