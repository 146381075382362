/* eslint-disable react/prop-types */
import React, {useState, useCallback, useEffect, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {AssessmentViewFooterContainer} from './elements';
import {SubmissionModel} from './component';
import {
  submitAssessement,
  resubmissionAssessementRequest,
} from '../../../../../../redux/studentWebSlice';
import {TextInput} from '../../../../../../components/common';
import {assessmentSubmissionSchema} from '../../../../../../utils/validation';
import FileUploadToastIcon from '../../../../../../assets/icons/class-success.svg';
import {showToast} from '../../../../../../components/common/Toast';
import {TextArea} from '../../../../../../components/common/TextArea';

export const AssessmentViewFooter = ({
  tasks,
  assessmentID,
  classID,
  assessment,
  isLoadingForAction,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [resubmissionModal, setResubmissionModal] = useState(false);
  const filesCount = useRef(null);

  const {
    control,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm({
    defaultValues: {
      feedback: '',
      minutes: '',
      hours: '',
    },
    resolver: yupResolver(assessmentSubmissionSchema),
  });

  const submittedTasksCount = tasks?.filter(
    item => item?.fileSubmissions?.length,
  ).length;

  useEffect(() => {
    tasks?.map(item => {
      if (item?.fileSubmissions?.length) {
        filesCount.current += item?.fileSubmissions?.length;
      }
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const dispatch = useDispatch();
  const history = useHistory();

  const assessmentSubmission = useCallback(
    data => {
      const submissionData = {
        minutes: data.minutes,
        hours: data.hours,
      };
      dispatch(
        submitAssessement(submissionData, assessmentID, () => {
          showToast(
            FileUploadToastIcon,
            'Assessment Submitted successfully',
            false,
          );
          history.replace(`/stream/${classID}/live`);
        }),
      );
    },
    [assessmentID, classID, dispatch, history],
  );

  const assessmentResubmission = useCallback(() => {
    const submissionData = {
      feedback: getValues('feedback'),
    };

    dispatch(
      resubmissionAssessementRequest(submissionData, assessmentID, () => {
        showToast(FileUploadToastIcon, 'Resubmission Request Submitted', false);
        history.replace(`/stream/${classID}/live`);
      }),
    );
  }, [assessmentID, classID, dispatch, getValues, history]);

  return (
    <AssessmentViewFooterContainer className="fixed-bottom">
      {assessment?.aouis_status === 1 || assessment?.aouis_status === 4 ? (
        <p className="footer-text">
          {submittedTasksCount}/{tasks?.length} Submitted
        </p>
      ) : (
        <div />
      )}
      <div className="footer-buttons">
        {assessment?.aouis_status === 2 ||
        assessment?.aouis_status === 5 ||
        assessment?.aouis_status === 6 ? (
          <button
            className="footer-resub-button"
            onClick={() => {
              setResubmissionModal(true);
            }}
            type="button"
          >
            Request Resubmission
          </button>
        ) : (
          <button
            className="footer-button"
            onClick={() => {
              if (submittedTasksCount === tasks?.length) {
                setModalShow(true);
              }
            }}
            style={
              submittedTasksCount !== tasks?.length
                ? {
                    backgroundColor: '#80d5ff',
                    cursor: 'not-allowed',
                  }
                : {}
            }
            type="button"
          >
            Submit Assessment
          </button>
        )}
      </div>
      <SubmissionModel
        show={modalShow}
        title="Confirm work submission"
        width="23.12rem"
        buttonLabel="Submit Assessment"
        isButtonLoading={isLoadingForAction}
        onButtonClick={handleSubmit(assessmentSubmission)}
        onHide={() => {
          setModalShow(false);
        }}
      >
        <div className="summary mt-2">
          <div className="d-flex justify-content-between">
            <p>Tasks Attended </p>
            <span className="counts">
              {submittedTasksCount}/{tasks?.length}
            </span>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p>Files Linked/submitted </p>
            <span className="counts">{filesCount?.current}</span>
          </div>
        </div>
        <div className="get-details">
          <p>Time taken to submit the work</p>
          <div className="d-flex justify-content-between align-item-center mt-2">
            <div className="field-middle">
              <Controller
                control={control}
                name="hours"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoComplete="off"
                    hasError={!!errors.hours}
                    placeholder="Hours"
                    type="number"
                    id="hours"
                  />
                )}
              />
            </div>
            <div className="field-middle">
              <Controller
                control={control}
                name="minutes"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoComplete="off"
                    hasError={!!errors.minutes}
                    placeholder="Minutes"
                    type="number"
                    id="minutes"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </SubmissionModel>
      <SubmissionModel
        show={resubmissionModal}
        title="Request for resubmission ?"
        width="23.12rem"
        buttonLabel="Submit Request"
        isButtonLoading={isLoadingForAction}
        onButtonClick={assessmentResubmission}
        onHide={() => {
          setResubmissionModal(false);
        }}
      >
        <div className="Subtitle">
          <p>
            Request to start the asssessment from the beginning please state
            your reason for the request
          </p>
        </div>
        <div className="feed-back">
          <div className="mt-2">
            <Controller
              control={control}
              name="feedback"
              render={fields => (
                <TextArea
                  {...fields}
                  className="mr-4 mt-4"
                  placeholder="State your reason for resubmission"
                  rows="6"
                />
              )}
            />
          </div>
        </div>
      </SubmissionModel>
    </AssessmentViewFooterContainer>
  );
};
