import styled from 'styled-components';

const TasFormContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 -15px;

  main {
    display: flex;
    height: calc(100vh - 5.375rem);
  }
`;

export default TasFormContainer;
