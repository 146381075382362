import React from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import CardNextIcon from '../../../../../../assets/icons/card-next.svg';
import {ActiveClassCardContainer} from './elements';

export const ActiveClassCard = ({classItem, className, isArchived}) => {
  const location = useHistory();
  const goToClassStream = id => {
    location.push({
      pathname: `/stream/${id}`,
      state: {
        isArchived,
      },
    });
  };

  if (!classItem?.orgUnit) return null;

  return (
    <ActiveClassCardContainer
      className={className}
      onClick={() => goToClassStream(classItem?.orui_id_orgunitinstance)}
    >
      <div className="card-top">
        <div className="card-column-left">
          <p className="class-name" title={classItem?.class?.oc_class}>
            {classItem?.class?.oc_class}
          </p>
          <p className="unit-name" title={classItem?.orgUnit?.unit?.tun_title}>
            {classItem?.orgUnit?.unit?.tun_title}
          </p>
        </div>
        <div className="card-column-right">
          <button type="button" className="arrow-btn">
            <img alt="Next" src={CardNextIcon} />
          </button>
        </div>
      </div>
      <div className="seperator" />
      <div className="card-bottom">
        <div className="card-column">
          <p className="column-count">{classItem.students?.length}</p>
          <span className="column-type">Students</span>
        </div>
        <div className="card-column">
          <p className="column-count">{classItem?.assessmentCount}</p>
          <span className="column-type">Works</span>
        </div>
      </div>
    </ActiveClassCardContainer>
  );
};
ActiveClassCard.propTypes = {
  classItem: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  isArchived: PropTypes.string.isRequired,
};
