import styled from 'styled-components';

export const AddedTaskSectionContainer = styled.div`
  @media (max-width: 43.75rem) {
    .task-data-container {
      .dropdown-wrapper {
        display: block;
        .task-btn {
          button :hover {
            background-color: #f7f7f7 !important;
          }
        }
      }
    }
  }
  .task-data-container {
    background: #ffffff;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem;
    padding: 1.5rem 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .tasktype-image-div {
    width: 3rem;
    height: 3rem;
    background: #ebf5ff;
    border-radius: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .task-name {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3125rem;
    color: #525252;
  }
  .task-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 157%;
    color: #9e9d9d;
  }
  .task-type {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #b3b2b3;
  }
  .view-task {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #40a1ff;
    padding-right: 0.625rem;
  }
  .deleteIcon {
    cursor: pointer;
    position: absolute;
    right: 0.625rem;
  }

  .added-task-instructions {
    .added-instructions-meta {
      align-items: center;
      display: flex;
      justify-content: space-between;

      div {
        align-items: center;
        display: flex;

        h3 {
          color: ${({theme}) => theme.colors.text};
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.57;
          margin: 0 0.25rem;
        }
      }

      button {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }

        &.is-open img {
          transform: rotate(180deg);
        }
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    .added-instruction {
      align-items: flex-start;
      border: 0.0625rem solid #dedede;
      border-radius: 0.3125rem;
      display: flex;
      padding: 1rem;

      :not(:last-child) {
        margin-bottom: 1rem;
      }

      & > a,
      & > button {
        background-color: transparent;
        border: none;
        height: 2.5rem;
        position: relative;
        width: 2.5rem;

        :focus,
        :active {
          outline: none;
        }
      }

      .play-video-icon {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
          height: 1rem;
          width: 1rem;
        }
      }

      .instruction-icon {
        border-radius: 0.3125rem;
        height: 2.5rem;
        object-fit: cover;
        width: 2.5rem;
      }

      .instruction-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 0.625rem;
        width: 70%;

        span {
          color: #aeaeae;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1.21;
          margin-bottom: 0.5rem;
        }

        p {
          color: ${({theme}) => theme.colors.text};
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.71;
          margin: -0.125rem 0 0 0;
          word-break: break-word;
          width: 100%;

          button {
            background-color: transparent;
            border: none;
            color: #00abff;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.71;

            :focus,
            :active {
              outline: none;
            }
          }
        }
      }

      .instruction-options {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }
      }
    }
  }
  .task-top-content {
    display: flex;
    justify-content: space-between;
  }
  .task-added-div {
    width: 100%;
  }
  .evaluation-type-badge {
    border: 0.0625rem solid #dedede;
    box-sizing: border-box;
    border-radius: 0.25rem;
    font-weight: normal;
    font-size: 0.75rem;
    padding: 0.1rem 0.225rem 0 0.225rem;
    height: 1.5rem;
    color: #aeaeae;
  }
`;
