import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {DashBoardContainer} from './elements';

import UserCheckMarkIcon from '../../../../../../assets/icons/user-checkmark.svg';
import UserTeacherIcon from '../../../../../../assets/icons/user_teachers.svg';
import UserStudentIcon from '../../../../../../assets/icons/user-students.svg';

import LoaderIcon from '../../../../../../assets/icons/orgLogoLoader.svg';
import {
  fetchDashboard,
  dashboardSelector,
} from '../../../../../../redux/dashboardSlice';
import {UserCard} from './components';

export const DashboardNew = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDashboard());
  }, [dispatch]);

  const {dashboard, isLoading} = useSelector(dashboardSelector);
  return (
    <>
      <Helmet>
        <title>Dashboard | JungleCat</title>
      </Helmet>
      <DashBoardContainer>
        <div className="container-dashboard">
          <div className="dashboard-head">
            <span className="dashboard-header-title">Dashboard</span>
            {dashboard?.academicYear ? (
              <div className="dashboard-header-bottom">
                <span className="academic-year-title">Academic Year </span>
                <span className="academic-year">
                  {moment(dashboard?.academicYear?.oay_from_date).format(
                    'MMM YYYY',
                  )}{' '}
                  -{' '}
                  {moment(dashboard?.academicYear?.oay_to_date).format(
                    'MMM YYYY',
                  )}
                </span>
              </div>
            ) : null}
          </div>
          <div className="row left">
            <div className="col-md-8">
              <div className="col-md-12">
                {isLoading ? (
                  <div className="loader-images offset-7">
                    <img
                      src={LoaderIcon}
                      alt="loader"
                      className="button-spinner"
                    />
                  </div>
                ) : null}
                {!isLoading ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="dashboard-content-head">Users</p>
                        </div>
                      </div>

                      <div className="row">
                        <UserCard
                          count={dashboard?.adminCount}
                          backgroundColor="#f4f2ff"
                          borderColor="#e5e0ff"
                          pendingCount={dashboard?.pendingAdminCount}
                          userIcon={UserCheckMarkIcon}
                          userType="Admin"
                          linkTo="users/admin"
                        />
                        <UserCard
                          count={dashboard?.teacherCount}
                          backgroundColor="#fff2ec"
                          borderColor="#fcddcf"
                          pendingCount={dashboard?.pendingTeacherCount}
                          userIcon={UserTeacherIcon}
                          userType="Teachers"
                          linkTo="users/admin"
                        />
                        <UserCard
                          count={dashboard?.studentCount}
                          backgroundColor="#ecfbff"
                          borderColor="#bcebf7"
                          pendingCount={dashboard?.pendingStudentCount}
                          userIcon={UserStudentIcon}
                          userType="Students"
                          linkTo="users/student"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </DashBoardContainer>
    </>
  );
};
