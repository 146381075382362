/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useCallback, useState} from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import ButtonSpinnerIcon from '../../../../../../../../../../assets/icons/refactor/button-spinner.svg';
import {convertFileSize} from '../../../../../../../../../../utils/functions';
import CheckImg from '../../../../../../../../../../assets/icons/checked.svg';
import TickWhiteImg from '../../../../../../../../../../assets/icons/tick-new.svg';
import {FilePreviewContainer} from './elements';
import {
  fileLinkedToTask,
  studentWebSelector,
} from '../../../../../../../../../../redux/studentWebSlice';
import ImageIcon from '../../../../../../../../../../assets/images/filetypes/img.svg';
import DocIcon from '../../../../../../../../../../assets/images/filetypes/doc.svg';
import CsvIcon from '../../../../../../../../../../assets/images/filetypes/csv.svg';
import FileIcon from '../../../../../../../../../../assets/icons/document-instruction.svg';
import imageAudio from '../../../../../../../../../../assets/images/filetypes/audio-modal-image.png';

export const FilePreview = ({
  file,
  addToPortFolio,
  setAddToPortFolio,
  setIsTaskLinked,
  isTaskLinked,
  assessmentID,
  taskId,
  fileName,
}) => {
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [timeOfVideo, setTimeOfVideo] = useState(null);
  const documentRef = useRef(null);
  const {isAddingFiles} = useSelector(studentWebSelector);
  const fileType = file?.type.split('/')[0];

  const onStop = () => {
    if (videoRef?.current) {
      const secs = Math.floor(videoRef?.current?.currentTime);
      const hours = Math.floor(secs / (60 * 60));
      const divisorForMinutes = secs % (60 * 60);
      const minutes = Math.floor(divisorForMinutes / 60);
      const divisorForSeconds = divisorForMinutes % 60;
      const seconds = Math.floor(divisorForSeconds);
      if (hours > 0)
        setTimeOfVideo(
          `${hours}:${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`,
        );
      setTimeOfVideo(`${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`);
    }
  };

  useEffect(() => {
    if (imageRef.current && fileType === 'image') {
      imageRef.current.src = URL.createObjectURL(file);
    }
    if (videoRef.current && fileType === 'video') {
      videoRef.current.src = URL.createObjectURL(file);
    }
    if (documentRef.current && fileType === 'application') {
      documentRef.current.src = URL.createObjectURL(file);
    }
    if (audioRef.current && fileType === 'audio') {
      audioRef.current.src = URL.createObjectURL(file);
    }
  }, [imageRef, file, fileType, documentRef]);

  const renderPreview = useCallback(() => {
    const fileExt = file?.name?.substring(file?.name?.lastIndexOf('.') + 1);
    const renderIcon = ext => {
      if (ext === 'svg') {
        return ImageIcon;
      }
      if (ext === 'csv') {
        return CsvIcon;
      }
      if (ext === 'doc' || ext === 'docx') {
        return DocIcon;
      }
      return FileIcon;
    };
    if (fileType === 'image') {
      return <img ref={imageRef} alt="cloud" className="image-file" />;
    }
    if (fileType === 'video') {
      return (
        <div className="videos">
          <video
            controls
            ref={videoRef}
            width="100%"
            height="100%"
            onPause={onStop}
          >
            <track default kind="captions" srcLang="en" ref={videoRef} />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      );
    }
    if (fileType === 'audio') {
      return (
        <div className="audios " ref={audioRef} width="100%">
          <img
            src={imageAudio}
            alt="audio icon"
            style={{
              height: '200px',
              width: '200px',
              marginTop: '80px',
            }}
          />
          <audio controls>
            <source ref={audioRef} type="audio/mpeg" className="source-audio" />
          </audio>
        </div>
      );
    }
    if (fileExt === 'pdf') {
      return (
        <div className="file-data">
          <div style={{height: '500px'}}>
            <embed
              ref={documentRef}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      );
    }

    return <img src={renderIcon(fileExt)} alt="" className="preview-Icon" />;
  }, [file?.name, fileType]);

  return (
    <FilePreviewContainer>
      <div
        className="container-preview"
        style={fileType === 'image' ? {backgroundColor: '#535659'} : {}}
      >
        {renderPreview()}
      </div>

      <div className="preview-bottom">
        <div>
          <p className="file-name">{fileName}</p>
          <p className="file-size">{convertFileSize(file?.size)}</p>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="portfolio-btn"
            type="button"
            onClick={() => {
              setAddToPortFolio(state => !state);
            }}
          >
            <div className="left-div">
              <button type="button" className="checkbox">
                {addToPortFolio ? (
                  <img
                    src={CheckImg}
                    alt="checked"
                    width="19px"
                    height="19px"
                  />
                ) : (
                  ''
                )}
              </button>
              <div className="left-div-content">
                <span className="select-teacher-type">Add to portfolio</span>
              </div>
            </div>
          </button>
          <button
            className="footer-button"
            onClick={() => {
              if (!isTaskLinked && !isAddingFiles) {
                const formData = new FormData();
                formData.append('uploadDocument', file, file?.name);
                formData.append('fileName', fileName);
                formData.append('portfolio', addToPortFolio);
                if (videoRef?.current) {
                  formData.append('videoTime', videoRef?.current?.currentTime);
                }
                dispatch(
                  fileLinkedToTask(formData, assessmentID, taskId, () => {
                    setIsTaskLinked(true);
                  }),
                );
              }
            }}
            style={
              isTaskLinked
                ? {
                    backgroundColor: '#4EC977',
                  }
                : {}
            }
            type="button"
          >
            {isTaskLinked ? <img src={TickWhiteImg} alt="tick" /> : null}

            {!isTaskLinked ? 'Link Task ' : 'Task Linked '}
            {timeOfVideo || null}
            {!isTaskLinked && isAddingFiles ? (
              <img
                alt="Loading"
                className={classNames('spinner')}
                src={ButtonSpinnerIcon}
              />
            ) : null}
          </button>
        </div>
      </div>
    </FilePreviewContainer>
  );
};
