import React, {useEffect, useState, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useLocalStorage} from 'react-use';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import {Select} from '../../../../components/common';
import useNavbarScroll from '../../../../hooks/useNavbarScroll';
import {ActiveClassesAndAsssesssmentContainer} from './elements';
import Navbar from '../../../../components/shared/Navbar';
import {ActiveClassCard, MyAssessments, ProfileCard} from './components';
import {activeClassSelector} from '../../../../redux/activeClassSlice';
import {setStream} from '../../../../redux/streamSlice';
import EmptyState from '../../../../components/shared/EmptyState';
import {authSelector} from '../../../../redux/authSlice';

export const ActiveClassesPage = () => {
  const [userType] = useLocalStorage('jc-user-type', '', {
    raw: true,
  });
  const classState = useSelector(activeClassSelector);
  const dispatch = useDispatch();
  const [activeClassTab, setActiveClassTab] = useState(true);
  const [archiveClassTab, setArchiveClassTab] = useState(false);
  const [academicYear, setAcademicYear] = useState(null);
  const [myClassesTab, setMyClassesTab] = useState(true);
  const [myAssessmentTab, setMyAssessmentTab] = useState(false);
  const authState = useSelector(authSelector);

  const {handleScroll, navbarTop} = useNavbarScroll(16, 81);

  useEffect(() => {
    dispatch(setStream(null));
  }, [dispatch]);

  const activeClassGroup = useMemo(
    () =>
      classState?.activeClasses?.filter(
        item =>
          item?.class?.oc_is_archived === 0 &&
          (item?.class?.oc_id_orgacadyear === academicYear?.value ||
            academicYear?.value === ''),
      ) || [],
    [classState, academicYear],
  );
  const archiveClassGroup = useMemo(
    () =>
      classState?.activeClasses?.filter(
        item =>
          item?.class?.oc_is_archived === 1 &&
          (item?.class?.oc_id_orgacadyear === academicYear?.value ||
            academicYear?.value === ''),
      ) || [],
    [classState, academicYear],
  );

  const academicYearsOptions = useMemo(
    () => [
      {label: 'All Academic Years', value: ''},
      ...(classState?.academicYears || [])?.map(item => ({
        label: `${moment(new Date(item.oay_from_date)).format(
          'MMM YYYY',
        )} - ${moment(new Date(item.oay_to_date)).format('MMM YYYY')}`,
        value: item.oay_id_orgacadyear,
      })),
    ],
    [classState],
  );
  const onSelectChange = value => {
    setAcademicYear(value);
  };
  useEffect(() => {
    setAcademicYear({
      label: academicYearsOptions[0]?.label,
      value: academicYearsOptions[0]?.value,
    });
  }, [academicYearsOptions]);

  // Check org preference for video class page is enable
  const liveSessionPreference = authState?.orgPreferences?.find(
    item =>
      item.pr_slug === 'enable_video_session' && Number(item.pr_value) === 1,
  );

  return (
    <>
      <Helmet>
        <title>My Active Classes | JungleCat</title>
      </Helmet>
      <Navbar
        style={{
          top: navbarTop,
        }}
      />
      <ActiveClassesAndAsssesssmentContainer
        onScroll={handleScroll}
        className="active-class-container"
      >
        <div className="row class-card-container">
          {Number(userType) === 3 ? (
            <div className="col-3 profile-container">
              {authState?.isLoading ? (
                <div>
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                </div>
              ) : (
                <ProfileCard liveSessionPreference={liveSessionPreference} />
              )}
            </div>
          ) : null}
          <div className={Number(userType) === 3 ? 'col-9' : 'col-12'}>
            {Number(userType) === 3 ? (
              <div className="search-div">
                <button
                  className={classNames('bottom', {
                    link: myClassesTab,
                  })}
                  type="button"
                  onClick={() => {
                    setMyClassesTab(true);
                    setMyAssessmentTab(false);
                    // setSearchTerm('');
                  }}
                >
                  <p>My Active Classes</p>
                </button>
                <button
                  className={classNames('bottom', {
                    link: myAssessmentTab,
                  })}
                  type="button"
                  onClick={() => {
                    setMyClassesTab(false);
                    setMyAssessmentTab(true);
                    // setSearchTerm('');
                  }}
                >
                  <p>My Assessments</p>
                </button>
              </div>
            ) : null}
            {myClassesTab ? (
              <div className="my-classes-container">
                <div className="header-section">
                  <h2 className="title">
                    {activeClassTab ? 'My Classes' : 'Archived Classes'}
                  </h2>
                  <div className="right-side">
                    <div className="button-container">
                      <button
                        type="button"
                        className={classNames('active-class', {
                          clicked: activeClassTab,
                        })}
                        onClick={() => {
                          setActiveClassTab(true);
                          setArchiveClassTab(false);
                        }}
                      >
                        Active
                      </button>
                      <button
                        type="button"
                        className={classNames('archive-class', {
                          clicked: archiveClassTab,
                        })}
                        onClick={() => {
                          setActiveClassTab(false);
                          setArchiveClassTab(true);
                        }}
                      >
                        Archived
                      </button>
                    </div>
                    <div className="academic-year">
                      <Select
                        height="11rem"
                        width="14rem"
                        isLarge={false}
                        options={academicYearsOptions}
                        value={academicYear}
                        placeholder="Select the Academic Year"
                        onChange={e => {
                          onSelectChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="class-list">
                  {classState?.isLoading ? (
                    <div>
                      <div className="mb-3">
                        <Skeleton height={223} width="100%" />
                      </div>
                      <div className="mb-3">
                        <Skeleton height={223} width="100%" />
                      </div>
                      <div className="mb-3">
                        <Skeleton height={223} width="100%" />
                      </div>
                    </div>
                  ) : null}
                  {!classState?.isLoading && activeClassTab ? (
                    <div className="row container-active">
                      {activeClassGroup?.map(item => (
                        <div
                          className={
                            Number(userType) === 3
                              ? 'col-sm col-md-6 col-lg-4 col-xl-4'
                              : 'col-sm col-md-6 col-lg-3 col-xl-3'
                          }
                        >
                          <ActiveClassCard
                            classItem={item}
                            key={item?.orui_id_orgunitinstance}
                            isArchived={item?.class?.oc_is_archived}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {!classState?.isLoading && archiveClassTab ? (
                    <div className="row container-active">
                      {archiveClassGroup.map(item => (
                        <div
                          className={
                            Number(userType) === 3
                              ? 'col-sm col-md-6 col-lg-4 col-xl-4'
                              : 'col-sm col-md-6 col-lg-3 col-xl-3'
                          }
                        >
                          <ActiveClassCard
                            classItem={item}
                            key={item.orui_id_orgunitinstance}
                            isArchived={item?.class?.oc_is_archived}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                {!classState?.isLoading &&
                activeClassTab &&
                !activeClassGroup?.length ? (
                  <EmptyState
                    body="It seems no active class added to this organisation."
                    title="No Active class found"
                  />
                ) : null}
                {!classState?.isLoading &&
                archiveClassTab &&
                !archiveClassGroup?.length ? (
                  <EmptyState
                    body="It seems no archive class added to this organisation."
                    title="No Archive class found"
                  />
                ) : null}
              </div>
            ) : null}
            {myAssessmentTab ? (
              <div className="my-assessments-container">
                <MyAssessments />
              </div>
            ) : null}
          </div>
        </div>
      </ActiveClassesAndAsssesssmentContainer>
    </>
  );
};
