import classNames from 'classnames';
import PropTypes from 'prop-types';

import SidebarCloseIcon from '../../../../../../assets/icons/sidebar-close.svg';
import {SidebarHeaderContainer} from './elements';

const SidebarHeader = ({
  active,
  attendeesCount,
  onStudentListOpen,
  onChatOpen,
  onClose,
}) => (
  <SidebarHeaderContainer>
    <div className="header-tabs">
      <button
        type="button"
        className={classNames('header-tab', {
          'is-active': active === 'attendees',
        })}
        onClick={onStudentListOpen}
      >
        Attendees <span className="header-tab-badge">{attendeesCount}</span>
      </button>
      <button
        type="button"
        className={classNames('header-tab', {
          'is-active': active === 'chat',
        })}
        onClick={onChatOpen}
      >
        Chat
      </button>
    </div>
    <button type="button" className="sidebar-close" onClick={onClose}>
      <img src={SidebarCloseIcon} alt="Close" />
    </button>
  </SidebarHeaderContainer>
);

SidebarHeader.defaultProps = {
  onStudentListOpen: () => {},
  onChatOpen: () => {},
};

SidebarHeader.propTypes = {
  active: PropTypes.string.isRequired,
  attendeesCount: PropTypes.number.isRequired,
  onStudentListOpen: PropTypes.func,
  onChatOpen: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default SidebarHeader;
