import classNames from 'classnames';
import PropTypes from 'prop-types';
import {forwardRef} from 'react';

import CheckIcon from '../../../assets/icons/refactor/check.svg';
import {CheckboxContainer} from './elements';

export const Checkbox = forwardRef(
  ({hasError, label, onChange, value: isChecked}, ref) => (
    <CheckboxContainer type="button">
      <label className="align-items-center d-flex mb-0" htmlFor={label}>
        <div
          className={classNames('icon-container', 'mr-2', {
            'has-error': hasError,
            'is-checked': isChecked,
          })}
        >
          <img alt="Checked" className="icon" src={CheckIcon} />
        </div>
        <span className="label">{label}</span>
      </label>
      <input
        checked={isChecked}
        className="d-none"
        id={label}
        onChange={event => {
          onChange(event.target.checked);
        }}
        ref={ref}
        type="checkbox"
      />
    </CheckboxContainer>
  ),
);

Checkbox.defaultProps = {
  hasError: false,
  label: ' ',
};

Checkbox.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};
