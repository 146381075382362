import styled from 'styled-components';

export const UserTypeSelectContainer = styled.div`
  .user-type-div {
    margin-top: 1.5rem;
    .usertype-context {
      display: flex;
      flex-direction: column;
      .user-type {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #3d4457;
        margin-bottom: 0.25rem;
      }
      .select-user-type {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.3125rem;
        color: #8a8c94;
      }
    }
    .admin-type-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      outline: none;
      width: 100%;
      background: #ffffff;
      border: 0.0625rem solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 0.5rem;
      margin-top: 1rem;
      .left-div {
        display: flex;
        flex-direction: row;

        .checkbox {
          width: 1.31rem;
          height: 1.31rem;
          outline: unset;
          padding: 0;
          background-color: unset;
          border-radius: 0.25rem;
          border: 0.0625rem solid #e0e0e0;
          margin: 2.18rem 1rem;
          img {
            vertical-align: unset;
          }
        }
        .left-div-content {
          display: flex;
          flex-direction: column;
          margin: 1.25rem 0rem 1.25rem 0rem;
          text-align: left;
          .admin-type {
            font-weight: 500;
            font-size: 0.875rem;
            color: #3d4457;
          }
          .select-admin-type {
            margin-top: 0.25rem;
            font-weight: normal;
            font-size: 0.875rem;
            color: #8a8c94;
          }
        }
      }
      .right-div {
        .right-div-img {
          margin-right: 1rem;
        }
      }
    }
    .active-div {
      background: #ecf6ff !important;
      border: 0.0625rem solid #40a1ff !important;
    }
    .teacher-type-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      outline: none;
      width: 100%;
      background: #ffffff;
      border: 0.0625rem solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 0.5rem;
      margin-top: 1rem;

      .left-div {
        display: flex;
        flex-direction: row;

        .checkbox {
          width: 1.31rem;
          height: 1.31rem;
          outline: unset;
          padding: 0;
          background-color: unset;
          border-radius: 0.25rem;
          border: 0.0625rem solid #e0e0e0;
          margin: 2.18rem 1rem;
          img {
            vertical-align: unset;
          }
        }
        .left-div-content {
          display: flex;
          flex-direction: column;
          margin: 1.25rem 0 1.25rem 0;
          text-align: left;
          .teacher-type {
            font-weight: 500;
            font-size: 0.875rem;
            color: #3d4457;
          }
          .select-teacher-type {
            margin-top: 0.25rem;
            font-weight: normal;
            font-size: 0.875rem;
            color: #8a8c94;
          }
        }
      }
      .right-div {
        .right-div-img {
          margin-right: 1rem;
        }
      }
    }
  }
`;
