/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';
import classNames from 'classnames';
import moment from 'moment';

import {Link} from 'react-router-dom';

import ArrowIcon from '../../../../../../../../assets/icons/arrow_right_blue.svg';

import {AssessmentStatusContainer} from './elements';

export const AssessmentStatus = ({
  assessment,
  linkToView,
  assessmentStatus,
}) => {
  const renderStatus = useCallback(() => {
    const dateOfCreation = moment(assessment?.aoui_timestamp_updation).format(
      'DD MMM yy',
    );
    if (assessmentStatus === 1) {
      return (
        <div className="outline-left">
          <div>
            <p>{`${
              assessment?.tasks?.length > 9
                ? assessment?.tasks?.length
                : `0${assessment?.tasks?.length}`
            }`}</p>
            <span>Tasks</span>
          </div>
          <div className="time">
            <p>{`${assessment?.ac_maximum_duration_hours}.${assessment?.ac_maximum_duration_minutes}hrs`}</p>
            <span>Time</span>
          </div>
        </div>
      );
    }
    if (assessmentStatus === 2) {
      return (
        <div
          className={classNames('outline-left', {
            status: assessmentStatus === 2,
          })}
        >
          <div>
            <p
              className={classNames('status-text', {
                submitted: assessmentStatus === 2,
              })}
            >
              Assessment submitted
            </p>
          </div>
          <div className="time">
            <div
              className={classNames('date', {
                submitted: assessmentStatus === 2,
              })}
            >
              {dateOfCreation}
            </div>
            <div className="hrs">
              {' '}
              •{' '}
              <span>{`${assessment?.aouis_hours}.${assessment?.aouis_minutes} Hrs`}</span>
            </div>
          </div>
        </div>
      );
    }
    if (assessmentStatus === 3) {
      return (
        <div className="outline-left">
          <div className="assessment-evaluate">Assessment Evaluated</div>
        </div>
      );
    }
    if (assessmentStatus === 4) {
      return (
        <div
          className={classNames('outline-left', {
            status: assessmentStatus === 4,
          })}
        >
          <div>
            <p
              className={classNames('status-text', {
                reassigned: assessmentStatus === 4,
              })}
            >
              Work Reassigned
            </p>
          </div>
          <div className="time">
            <div
              className={classNames('date', {
                reassigned: assessmentStatus === 4,
              })}
            >
              {dateOfCreation}
            </div>
          </div>
        </div>
      );
    }
    if (assessmentStatus === 5) {
      return (
        <div
          className={classNames('outline-left', {
            status: assessmentStatus === 5,
          })}
        >
          <div>
            <p
              className={classNames('status-text', {
                'resubmission-requested': assessmentStatus === 5,
              })}
            >
              Resubmission Requested
            </p>
          </div>
          <div className="time">
            <div
              className={classNames('date', {
                'resubmission-requested': assessmentStatus === 5,
              })}
            >
              {dateOfCreation}
            </div>
          </div>
        </div>
      );
    }

    if (assessmentStatus === 6) {
      return (
        <div
          className={classNames('outline-left', {
            status: assessmentStatus === 6,
          })}
        >
          <div>
            <p
              className={classNames('status-text', {
                'request-declined': assessmentStatus === 6,
              })}
            >
              Resubmission Requested Declined
            </p>
          </div>
          <div className="time">
            <div
              className={classNames('date', {
                'request-declined': assessmentStatus === 6,
              })}
            >
              {dateOfCreation}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [assessment, assessmentStatus]);
  return (
    <AssessmentStatusContainer>
      <div className="header-last">
        <div className="outline-container">
          {renderStatus()}
          <div>
            <Link className="view-assessment" to={linkToView} id="view-id">
              {assessmentStatus === 3 ? 'View Result' : 'View Assessment'}
              <img alt="View" className="body_a-icon" src={ArrowIcon} />
            </Link>
          </div>
        </div>
      </div>
    </AssessmentStatusContainer>
  );
};
