import {createSlice} from '@reduxjs/toolkit';

import * as deparmentsApi from '../api/admin/departments';

const deparmentsSlice = createSlice({
  initialState: {
    error: null,
    departments: null,
    isDepartmentsLoading: false,
    isAddDepartmentLoading: false,
    isListLoading: true,
    isLoading: false,
    departmentsList: [],
  },
  name: 'departments',
  reducers: {
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setIsDepartmentsLoading: (state, action) => {
      state.isDepartmentsLoading = action.payload;
    },
    setIsAddDepartmentLoading: (state, action) => {
      state.isAddDepartmentLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    removeDepartmentById: (state, action) => {
      state.departments = state.departments.filter(
        department => department.od_id_orgdepartment !== action.payload,
      );
    },
    appendDepartments: (state, action) => {
      action.payload.forEach(dept => state.departments.push(dept));
    },
    appendNewDepartment: (state, action) => {
      state.departmentsList = [action.payload, ...state.departmentsList];
    },
    setDepartmentsList: (state, action) => {
      state.departmentsList = action.payload;
    },
  },
});

export const {
  setDepartments,
  setIsDepartmentsLoading,
  setIsAddDepartmentLoading,
  setError,
  setDepartmentsList,
  setIsLoading,
  setIsListLoading,
  appendDepartments,
  appendNewDepartment,
  removeDepartmentById,
} = deparmentsSlice.actions;

export const getAllDepartments = () => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const [departmentsResponse, departmentsListResponse] = await Promise.all([
      deparmentsApi.fetchAllDepartments(),
      deparmentsApi.fetchAllDepartmentsList(),
    ]);
    dispatch(setDepartments(departmentsResponse.data?.orgDepartments || []));
    dispatch(
      setDepartmentsList(departmentsListResponse.data.departments || []),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};
export const addDepartment = (data, callback) => async dispatch => {
  dispatch(setIsAddDepartmentLoading(true));
  try {
    const resp = await deparmentsApi.addDepartments(data);

    dispatch(appendDepartments(resp.data.orgDepartments));
    if (callback) {
      callback(resp.data.departmentsCount);
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAddDepartmentLoading(false));
  }
};

export const checkDepartmentExists = (data, callback) => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const newdept = {deptName: data};
    const resp = await deparmentsApi.checkDepartmentExists(newdept);

    if (callback) {
      callback(resp);
    }
  } catch (error) {
    callback('error');
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteDepartment = (departmentId, callback) => async dispatch => {
  dispatch(setIsDepartmentsLoading(true));

  try {
    const resp = await deparmentsApi.deleteDepartment(departmentId);
    dispatch(setIsDepartmentsLoading(false));
    dispatch(removeDepartmentById(resp.data.orgDepartments));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsDepartmentsLoading(false));
  }
};

export const departmentsSelector = state => state.departments;

export default deparmentsSlice.reducer;
