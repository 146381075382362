import axios from '../..';

export const fetchAllLibraryFiles = unitID =>
  axios.get(`/api/class/${unitID}/library-student`);

export const fetchPublicLibraryFiles = unitID =>
  axios.get(`/api/class/${unitID}/public-library`);

export const fetchAllCategory = unitId =>
  axios.get(`/api/class/${unitId}/library/get-category`);
