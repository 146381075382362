/* eslint-disable react/prop-types */
import React from 'react';
import GreenTickImg from '../../../../../../../../assets/icons/donemark.svg';
import {SuccessMessageContainer} from './elements';

export const SuccessMessage = ({title, subtitle}) => (
  <SuccessMessageContainer>
    <img src={GreenTickImg} alt="green tick" />
    <h2 className="mt-3 header">{title}</h2>
    <p className="state">{subtitle}</p>
  </SuccessMessageContainer>
);
