import React from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import AddArrowImg from '../../../../../../../../assets/icons/Arrow.svg';
import AddCircleImg from '../../../../../../../../assets/icons/add-circle.svg';
import {UserCardContainer} from './elements';

export const UserCard = ({
  count,
  backgroundColor,
  borderColor,
  pendingCount,
  userIcon,
  userType,
  linkTo,
}) => {
  const history = useHistory();
  return (
    <UserCardContainer
      className="col-md-4  user"
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <button
        className="col-md-12 top-div"
        type="button"
        onClick={() => {
          history.push(linkTo);
        }}
        id="user-id"
      >
        <div className="user-top mb-5">
          <div className="mx-2">
            <span className="user-top-content-left-top">
              {count > 9 ? `${count}` : `0${count}`}
            </span>
            <p className="user-top-content-left-bottom">{userType}</p>
          </div>
          <div>
            <img src={userIcon} alt="close" />
          </div>
        </div>

        {count > 0 ? (
          <div>
            <div className="footer-delete-break" />
            <div className="user-bottom mt-3">
              <div className="user-bottom-content-left">
                <div className="mt-2">
                  <span className="user-bottom-content-left-top">
                    {pendingCount > 9 ? `${pendingCount}` : `0${pendingCount}`}
                  </span>
                  <p className="user-bottom-content-left-bottom">
                    Invitation Pending
                  </p>
                </div>
                <div className="mt-2 user-bottom-content-right">
                  <button type="button" id="teacherpage-id">
                    <img src={AddArrowImg} alt="close" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="user-bottom">
              <div className="user-bottom-content-left-none">
                <div className="mt-2">
                  <p className="user-bottom-content-left-bottom-none">
                    {userType}
                  </p>
                </div>
                <div className="user-bottom-content-right">
                  <button type="button">
                    <img src={AddCircleImg} alt="close" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </button>
    </UserCardContainer>
  );
};

UserCard.propTypes = {
  count: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  pendingCount: PropTypes.number.isRequired,
  userIcon: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};
