import styled from 'styled-components';

export const ReleasesCellContainer = styled.div`
  .release-number {
    color: ${({theme}) => theme.colors.text};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.3125rem;

    .release-date {
      color: ${({theme}) => theme.colors.lightText};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem;
    }
  }

  .info-container {
    border-radius: 0.25rem;
    height: 2rem;
    transition: background-color 0.2s;
    width: 2rem;

    :hover {
      background-color: ${({theme}) => theme.colors.lighterPrimary};
    }

    .info-icon {
      height: 1.5rem;
      object-fit: contain;
      width: 1.5rem;
    }
  }
`;
