import styled from 'styled-components';

export const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 23rem;
  .header {
    font-size: 1.5rem;
    text-align: center;
  }
  .state {
    color: #aaaaaa;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    text-align: center;
  }
`;
