/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {showToast} from '../../../../../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../../../../../assets/icons/addtoastadmin.svg';
import {
  addAdminManual,
  spreadSheetAdminsCheck,
  addAdminSpread,
  adminTeachersSelector,
} from '../../../../../../../../../../redux/adminTeacherSlice';
import {AddAdminFooterContainer} from './elements';
import CustomCancel from '../../../../../../../../../../components/shared/Buttons/CustomButton';
import CustomButton from '../../../../../../../../../../components/shared/CustomButton';

export const AddAdminFooter = ({
  addAdmins,
  setAddAdmins,
  spreadSheet,
  setSpreadSheet,
  manual,
  steps,
  setSteps,
  setUpload,
  setIsTeacherSpreadsheet,
  setIsAdminSpreadsheet,
  isAdminSpreadsheet,
  isTeacherSpreadsheet,
  setIsValidating,
  nextShow,
  isValidating,
  emailPattern,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    alreadyExistRefId,
    alreadyExistEmail,
    duplicateData,
    validAdmin,
    isAddAdminTeacherLoading,
  } = useSelector(adminTeachersSelector);
  return (
    <AddAdminFooterContainer>
      <div className="add-admin-footer fixed-bottom">
        {manual ? (
          <div className="add-admin-title">
            <div className="button-container">
              <CustomCancel
                className="cancel1"
                bgColor="#fff"
                id="manualCancel"
                onClick={() => {
                  setUpload(null);
                  setAddAdmins([]);
                  setIsAdminSpreadsheet(false);
                  setIsTeacherSpreadsheet(false);
                  history.goBack();
                }}
              >
                <span className="cancel1-button-text">Cancel</span>
              </CustomCancel>
              <CustomButton
                isDisabled={!addAdmins?.length}
                className="save-changes"
                text="Add Users"
                id="addUsers"
                onClick={() => {
                  if (addAdmins?.length > 0 && !isAddAdminTeacherLoading) {
                    dispatch(
                      addAdminManual(addAdmins, () => {
                        showToast(
                          AddToastSuccessImg,
                          `${addAdmins.length} Users added successfully`,
                        );
                        history.replace('/admin/users/admin');
                        setAddAdmins([]);
                      }),
                    );
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="add-admin-spread">
            {steps === 0 ? <div className="button-container" /> : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(0);
                    setSpreadSheet([]);
                    setAddAdmins([]);
                    setUpload(null);

                    setIsAdminSpreadsheet(false);
                    setIsTeacherSpreadsheet(false);
                  }}
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : null}
            {steps === 2 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(1);
                    setSpreadSheet([]);
                    setUpload(null);
                    setAddAdmins([]);
                  }}
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : null}
            {steps === 3 &&
            !isValidating &&
            validAdmin?.length &&
            (alreadyExistEmail.length ||
              duplicateData.length ||
              alreadyExistRefId.length ||
              emailPattern.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                    setIsAdminSpreadsheet(false);
                    setIsTeacherSpreadsheet(false);
                  }}
                >
                  <span className="cancel-button-text">Back</span>
                </CustomCancel>
              </div>
            ) : (
              <div className="button-container" />
            )}
            {steps === 4 ? <div className="button-container" /> : null}
            {steps === 0 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    history.goBack();
                    setUpload(null);
                    setSpreadSheet([]);
                    setAddAdmins([]);
                    setIsAdminSpreadsheet(false);
                    setIsTeacherSpreadsheet(false);
                  }}
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!(!!isAdminSpreadsheet || !!isTeacherSpreadsheet)}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    setSteps(state => state + 1);
                  }}
                />
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSpreadSheet([]);
                    setAddAdmins([]);
                    setIsAdminSpreadsheet(false);
                    setIsTeacherSpreadsheet(false);
                    setSteps(state => state - 1);
                  }}
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    setSteps(state => state + 1);
                  }}
                />
              </div>
            ) : null}
            {steps === 2 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  isDisabled={isValidating}
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                    setIsAdminSpreadsheet(false);
                    setIsTeacherSpreadsheet(false);
                  }}
                >
                  <span className="cancel1-button-text">Upload Again</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Add Users"
                  onClick={() => {
                    setIsValidating(true);
                    dispatch(
                      spreadSheetAdminsCheck(
                        spreadSheet,
                        isAdminSpreadsheet,
                        isTeacherSpreadsheet,
                        () => {
                          setTimeout(() => {
                            setIsValidating(false);
                            setSteps(state => state + 1);
                          }, 3000);
                        },
                        showToast,
                      ),
                    );
                  }}
                />
              </div>
            ) : null}
            {steps === 3 &&
            !alreadyExistEmail?.length &&
            !emailPattern?.length &&
            !duplicateData?.length &&
            !alreadyExistRefId?.length ? (
              <div className="button-container">
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    showToast(
                      AddToastSuccessImg,
                      `${validAdmin.length} Users added successfully`,
                    );
                    setSteps(state => state + 1);
                    history.replace('/admin/users/admin');
                    setIsValidating(true);
                  }}
                />
              </div>
            ) : null}
            {steps === 3 &&
            !isValidating &&
            validAdmin?.length &&
            (alreadyExistEmail?.length ||
              duplicateData?.length ||
              alreadyExistRefId?.length ||
              emailPattern?.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                    setIsAdminSpreadsheet(false);
                    setIsTeacherSpreadsheet(false);
                  }}
                >
                  <span className="cancel1-button-text">
                    Discard and Reupload
                  </span>
                </CustomCancel>
                <CustomButton
                  isDisabled={
                    !spreadSheet?.length ||
                    nextShow ||
                    isValidating ||
                    !validAdmin?.length
                  }
                  className="save-changes"
                  text="Add valid Users"
                  onClick={() => {
                    if (validAdmin.length > 0 && !isAddAdminTeacherLoading) {
                      dispatch(
                        addAdminSpread(
                          validAdmin,
                          isAdminSpreadsheet,
                          isTeacherSpreadsheet,
                          () => {
                            showToast(
                              AddToastSuccessImg,
                              `${validAdmin.length} Users added successfully`,
                            );
                            history.replace('/admin/users/admin');
                            setSpreadSheet([]);
                          },
                        ),
                      );
                    }
                  }}
                />
              </div>
            ) : null}
            {steps === 3 &&
            !isValidating &&
            !validAdmin?.length &&
            (alreadyExistEmail?.length ||
              alreadyExistRefId?.length ||
              duplicateData?.length ||
              emailPattern.length) ? (
              <div className="button-container">
                <CustomButton
                  className="save-changesss"
                  text="Done"
                  onClick={() => {
                    history.replace('/admin/users/admin');
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </AddAdminFooterContainer>
  );
};
