import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const ViewCurriculumContainer = styled(AdminListingPageContainer)`
  padding: 5rem 0 4rem 5rem !important;
  @media (max-width: 55.5rem) {
    & {
      padding: 5rem 0 4.0625rem 5rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 5rem 0 4.0625rem 0rem !important;
    }
  }
`;
