// ModalContainer.js
import styled from 'styled-components';

const ModalContainer = styled.div`
  padding: 1.5rem;
  width: 90vw;
  height: auto;

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h1 {
      font-weight: 400;
      font-size: 1.5rem;
    }
    p {
      color: #aaaaaa;
    }

    svg {
      cursor: pointer;
    }
  }
  table {
    margin: 1.5rem 0;
    td:nth-child(1) {
      width: 3.125rem;
    }
    td:nth-child(4) {
      max-width: 12.5rem;
      button {
        background: transparent;
        color: ${({theme}) => theme.colors.primary};
        border: none;
      }
    }
    .serial {
      width: 4%;
    }
    .uniy-name {
      width: 19%;
    }
    .column-name {
      width: 20%;
    }

    .new-value {
      width: 35%;
    }

    .updated-by {
      width: 10%;
    }
    .updated-time {
      width: 12%;
    }
  }
`;

export default ModalContainer;
