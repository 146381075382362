import instance from '..';

export const createSession = data => instance.post('/api/session', data);

export const getSession = id => instance.get(`/api/session/${id}`);

export const hasHostJoined = roomId =>
  instance.get(`/api/session/hashostjoined/${roomId}`);

export const getSessions = params =>
  instance.get(`/api/session`, {
    params,
  });
