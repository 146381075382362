/* eslint-disable react/prop-types */
import React from 'react';

import {StudentsProfileContainer} from './elements';

export const StudentsProfile = ({students, totalNumberOfStudents}) => (
  <StudentsProfileContainer>
    <div className="d-flex students">
      {/* Show the first 7 students. */}
      {students?.length > 0 ? (
        students?.slice(0, 7).map(student => {
          const profile = student.profile || student;
          if (profile?.up_avatar) {
            return (
              <img
                src={profile?.up_avatar}
                className="rounded-circle img-student"
                alt="profile-img"
                title={`${profile?.up_name_first} ${profile?.up_name_last}`}
              />
            );
          }
          return (
            <div className="profile-teacher">
              {/* <img src={profileIcon} alt="icon" /> */}
              <span
                className="align-items-center circle d-flex justify-content-center"
                title={`${profile?.up_name_first} ${profile?.up_name_last}`}
              >
                {profile?.up_name_first[0]}
                {profile?.up_name_last[0]}
              </span>
            </div>
          );
        })
      ) : (
        <span className="no-students">No students profile</span>
      )}
      {/* Show the count of the remaining students. */}
      {students.length > 7 && (
        <span className="align-items-center circle d-flex justify-content-center remaining">
          +{students.length - 7}
        </span>
      )}
    </div>
    <div className="align-items-center d-flex justify-content-between mb-2">
      <span className="ratio">{`${totalNumberOfStudents} ${
        totalNumberOfStudents > 1 ? 'Students' : 'Student'
      }`}</span>
    </div>
  </StudentsProfileContainer>
);

export default StudentsProfile;
