import styled from 'styled-components';

export const MyAssessmentsContainer = styled.div`
  @media (max-width: 75rem) {
    .icon {
      transform: rotate(90deg);
    }
    .dropdowncontainer {
      right: -4rem;
    }
  }
  .serial {
    width: 4%;
  }
  .assessment-title {
    width: 35%;
    padding-right: 1rem !important;
  }

  .type {
    width: 15%;
  }
  .last-updated {
    width: 15%;
  }
  .date {
    font-weight: 400;
    font-size: 0.875rem;
    color: #aaaaaa;
    margin-bottom: 0;
    font-style: italic;
  }
  .column-text {
    font-weight: 400;
    font-size: 0.875rem;
    color: #3d4457;
    margin-bottom: 0;
  }
  .created-by {
    width: 20%;
  }

  .more-option {
    width: 3%;
  }

  .table-container {
    border-radius: 0.75rem;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.04) 0.0625rem 0.0625rem 0.25rem;
    padding: 1.5rem;
  }
  table {
    margin: 1rem 0px 1.5rem;
    width: 100%;
    tr {
      width: 100%;
      :hover {
        background-color: unset;
      }
    }
  }
`;
