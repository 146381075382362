/* eslint-disable react/prop-types */

import classNames from 'classnames';
import moment from 'moment';
import {useEffect, useMemo, useState, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
// import {useDispatch} from 'react-redux';

import axios from '../../../../../../api';
import AddTasIcon from '../../../../../../assets/icons/add-tas.svg';
import EditTasIcon from '../../../../../../assets/icons/edit-tas.svg';
import TableSpinnerIcon from '../../../../../../assets/icons/refactor/table-spinner.svg';
import {
  EmptyState,
  Table,
  MoreOptions,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {TableCellLink} from '../../../../../../components/common/Table/styles';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {TasListContainer} from './elements';
import UpdateClassToastIcon from '../../../../../../assets/icons/class-success.svg';
import {TaskStatus, DuplicateTasModal} from './components';
import {showToast} from '../../../../../../components/common/Toast';

const TasList = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const [classes, setClasses] = useState([]);
  const [duplicateDetails, setDuplicateDetails] = useState(null);
  const [isListLoading, setIsListLoading] = useState(true);
  const [classDropdownData, setClassDropdownData] = useState([]);
  const [classError, setClassError] = useState(null);
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const clearEditDetails = useCallback(() => {
    setDuplicateDetails(null);
  }, []);

  const data = useMemo(
    () => [
      ...(classes || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [classes],
  );

  const onSubmit = useCallback(
    duplicateData => {
      const {className} = duplicateData;
      if (className) {
        setIsDuplicateLoading(true);
        axios
          .post(`/api/admin/tas/${duplicateDetails?.tasId}/duplicate-tas`, {
            classId: className?.value,
            existingClassId: duplicateDetails?.id,
          })
          .then(() => {
            setIsDuplicateLoading(false);
            history.replace(`/admin/${className?.value}/tas-form`);
            showToast(
              UpdateClassToastIcon,
              `Tas details duplicated successfully`,
            );
          })
          .catch(() => {
            setClassError('Unable to duplicate this TAS.');
          });
      }
    },
    [duplicateDetails, history],
  );

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'Class',
        accessor: 'oc_class',
        className: 'course-name',
      },
      {
        Cell: ({value}) => <TaskStatus statusId={value} />,
        Header: 'Status',
        accessor: 'otas_id_taskstatus',
        className: 'status',
      },
      {
        Cell: ({row: {original}}) => {
          let assignee = '';
          if (original?.userprofile) {
            assignee = `${original?.userprofile?.up_name_first} ${original?.userprofile?.up_name_last}`;
          }
          return assignee;
        },
        Header: 'Assignee',
        accessor: 'userprofile.up_id_userprofile',
        className: 'assignee-name',
      },
      {
        Cell: ({row: {original}}) =>
          original?.up_name_first &&
          `${original?.up_name_first} ${original?.up_name_last}`,
        Header: 'Created By',
        accessor: 'up_id_userprofile',
        className: 'user-name',
      },
      {
        Cell: ({value}) =>
          value && moment(new Date(value)).format('D MMM YYYY'),
        Header: 'Last Update At',
        accessor: 'otas_updatedat',
        className: 'last-update-at',
      },
      {
        Cell: ({row: {original}, value}) => {
          const [isLoading, setLoading] = useState(false);
          let taskLogStatus;
          function renderButtonByStatus(taskStatus) {
            if (!taskStatus) {
              if (isLoading) {
                taskLogStatus = (
                  <>
                    <span>Creating&nbsp;&nbsp;</span>
                    <img
                      alt="Loading"
                      className="spinner"
                      src={TableSpinnerIcon}
                    />
                  </>
                );
              } else {
                taskLogStatus = (
                  <>
                    <img alt="Add TAS Icon" className="mr-2" src={AddTasIcon} />
                    <span>Add TAS</span>
                  </>
                );
              }
            } else if (
              taskStatus === 1 ||
              taskStatus === 2 ||
              taskStatus === 3 ||
              taskStatus === 4
            ) {
              taskLogStatus = (
                <>
                  <img alt="Edit TAS Icon" className="mr-2" src={EditTasIcon} />
                  <span>Edit TAS</span>
                </>
              );
            } else {
              taskLogStatus = (
                <>
                  <span>View TAS</span>
                </>
              );
            }
            return taskLogStatus;
          }
          return (
            <TableCellLink
              className={classNames('align-items-center', 'd-flex', {
                'is-button': original?.ts_id_taskstatus,
              })}
              onClick={async () => {
                if (!original?.tast_id_tasstatus) {
                  try {
                    setLoading(true);
                    await axios.post('/api/admin/create-tas', {
                      orgClassId: original?.oc_id_orgclass,
                      orgCourseId: original?.oc_id_orgcourse,
                      orgQualificationId: original?.oc_id_orgqualification,
                    });
                  } finally {
                    setLoading(false);
                  }
                }
                history.push({
                  pathname: `${value}/tas-form`,
                });
              }}
              type="button"
              id="tas-action-btn"
            >
              {renderButtonByStatus(original?.ts_id_taskstatus)}
              {/* {!original?.tast_id_tasstatus && (
                <>
                  {isLoading ? (
                    <>
                      <span>Creating&nbsp;&nbsp;</span>
                      <img
                        alt="Loading"
                        className="spinner"
                        src={TableSpinnerIcon}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        alt="Add TAS Icon"
                        className="mr-2"
                        src={AddTasIcon}
                      />
                      <span>Add TAS</span>
                    </>
                  )}
                </>
              )} */}
              {/* {original?.ts_id_taskstatus === 1 && (
                <>
                  <img alt="Edit TAS Icon" className="mr-2" src={EditTasIcon} />
                  <span>Edit TAS</span>
                </>
              )}
              {original?.ts_id_taskstatus === 5 && 'View TAS'} */}
            </TableCellLink>
          );
        },
        accessor: 'oc_id_orgclass',
        className: 'actions',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            {original?.tast_id_tasstatus === 1 ||
            original?.tast_id_tasstatus === 2 ? (
              <MoreOptions>
                <DropdownItem
                  label="Duplicate TAS"
                  id="duplicate-tas"
                  onClick={() => {
                    setIsDuplicateLoading(false);
                    axios
                      .get(
                        `/api/admin/classes-tas/${original?.oc_id_orgqualification}`,
                      )
                      .then(response => {
                        setClassDropdownData(response?.data?.classes);
                      });

                    setDuplicateDetails({
                      id: original?.oc_id_orgclass,
                      className: original?.oc_class,
                      tasId: original?.otas_id_orgtas,
                    });
                  }}
                />
              </MoreOptions>
            ) : null}
          </div>
        ),
        accessor: 'tas',
        className: 'more-option',
      },
    ],

    [history],
  );

  useEffect(() => {
    setIsListLoading(true);
    axios.get('/api/admin/classes-tas').then(response => {
      setClasses(response?.data?.classes);
      setIsListLoading(false);
    });
  }, []);

  return (
    <TasListContainer>
      <Helmet>
        <title>TAS | JungleCat</title>
      </Helmet>
      <ListingPageHeader
        className="mb-4"
        title="Training and Assessment Strategy"
      />
      <div className="table-container">
        {isListLoading ? <TableSpinner /> : null}
        {!isListLoading && !classes?.length ? (
          <EmptyState
            description="Try adjusting the search or filter to find what you are looking for."
            title="No Classes Found"
          />
        ) : null}
        {!isListLoading && data?.length && Array.isArray(data) ? (
          <Table
            columns={columns}
            data={data}
            itemName="Classes"
            maxWidth="75rem"
            isSortedBy
            globalFilterId="search-classes"
          />
        ) : null}
      </div>
      <DuplicateTasModal
        duplicateDetails={duplicateDetails}
        onHide={clearEditDetails}
        onSubmit={onSubmit}
        classDropdownData={classDropdownData}
        classError={classError}
        isDuplicateLoading={isDuplicateLoading}
      />
    </TasListContainer>
  );
};

export default TasList;
