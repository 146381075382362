/* eslint-disable react/prop-types */
import React from 'react';

import {useHistory} from 'react-router-dom';
import {FooterContainer} from './elements';
import {Button} from '../../../../../../../../../../components/common';
import {ProgressLine} from '../../../../components';

// const DELAY = 500;

export const Footer = ({progress, isAddClassLoading, onSubmit}) => {
  const history = useHistory();

  const onButtonCancel = () => {
    history.replace(`/admin/classes`);
  };

  return (
    <FooterContainer>
      <div className="footer-container fixed-bottom">
        <div className="footer">
          <div>
            <ProgressLine progress={progress} />
          </div>
          <div className="button-container">
            <Button
              className="button cancel-button mr-3"
              isFullWidth={false}
              onClick={onButtonCancel}
              label="Cancel"
              id="cancel"
            />
            <Button
              className="button"
              isFullWidth={false}
              isLoading={isAddClassLoading}
              label="Add Class"
              onClick={onSubmit}
              id="add-class"
            />
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};
