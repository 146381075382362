import styled from 'styled-components';

import {AdminListingPageContainer} from '../../../../../elements-new';

export const AllUnitsContainer = styled(AdminListingPageContainer)`
  .index {
    width: 4%;
  }

  .unit-code {
    width: 10%;
  }

  .unit-name {
    width: 32%;
  }

  .unit-releases {
    width: 25%;
  }

  .unit-type {
    width: 14%;
  }

  .more-options {
    width: 15%;
  }
  .code {
    font-size: 0.875rem;
    color: #8a8c94;
    margin-top: 0.5rem;
  }
`;
