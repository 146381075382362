/* eslint-disable react/prop-types */
import React from 'react';
import {UploadCoverImageContainer} from './elements';
import RemoveFileIcon from '../../../../../../../../assets/icons/close.svg';

import {FileCard} from '../FileCard';

export const UploadCoverImage = ({
  setUpload,
  upload,
  title,
  accept,
  setIsEdit,
  isEdit,
}) => {
  const onFileChange = e => {
    setUpload(e.target.files[0]);
  };

  return (
    <UploadCoverImageContainer>
      {upload ? (
        <div>
          <div className="upload-title">{title} </div>
          <div className="org-image-full">
            <div className="org-image-after">
              <FileCard file={upload} isEdit={isEdit} />
            </div>
            <div className="delete-logo">
              <button
                type="button"
                onClick={() => {
                  setIsEdit(true);
                  setUpload(null);
                }}
                id="remove"
              >
                <img src={RemoveFileIcon} alt="logo" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="add-cover-image">
          <div className="org-text">
            Add
            <input
              type="file"
              onChange={e => {
                setIsEdit(true);
                onFileChange(e);
              }}
              accept={accept}
              id="add-upload-document"
            />
            <span className="box__dragndrop"> {title}</span>
          </div>
        </div>
      )}
    </UploadCoverImageContainer>
  );
};
