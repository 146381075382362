import axios from '..';

export const fetchOrganisationByRtoCode = rtoCode =>
  axios.get(`api/tgov/organisation/${rtoCode}`);

export const joinOrganisationAdminByRtoCode = rtoCode =>
  axios.get(`auth/join-admin/find-organisation/${rtoCode}`);

export const logIn = data => axios.post('/auth/login', data);

export const register = data => axios.post('auth/register/org-admin', data);

export const joinFreeAdmin = data =>
  axios.post('auth/join-free/org-admin', data);

export const resetPassword = data => axios.post('/auth/forgot-password', data);

export const fetchProfile = () => axios.get('/profile/get');

export const fetchOrganisation = () => axios.get('/profile/organisation');

// super admin login as user
export const superAdminLogIn = data => axios.post('/auth/super-login', data);
