import styled from 'styled-components';

export const AssessmentSummaryContainer = styled.div`
  margin: 2rem auto;
  width: 80%;
  margin-top: 11rem;
  @media (max-width: 60rem) {
    & {
      width: 95%;
    }
  }
  @media (max-width: 50rem) {
    & {
      width: 95%;
      margin-top: 12rem;
    }
  }
  @media (max-width: 43.75rem) {
    & {
      margin-top: 15rem;
      width: 92%;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      .header {
        font-size: 1.25rem !important;
      }
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header {
      font-size: 1.5rem;
      color: #3d4457;
    }
    .button-container {
      display: flex;
    }
  }
  .action-btn {
    background: transparent;
    background-color: #ffffff;
    padding: 0.5rem;
    border: 0.0625rem solid #dedede;
    border-radius: 0.312rem;
    outline: none;
    img {
      object-fit: contain;
      vertical-align: middle;
    }
  }
`;
