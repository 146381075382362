import axios from '../..';

export const fetchAllSessions = oruiId =>
  axios.get(`api/admin/sessions/all-sessions${oruiId ? `?id=${oruiId}` : ''}`);

export const fetchSessionsByFilters = data =>
  axios.post('api/admin/sessions/sessions-filters', data);

export const updateSession = (sessionId, data) =>
  axios.patch(`api/admin/sessions/update-session/${sessionId}`, data);

export const deleteSession = sessionId =>
  axios.delete(`api/admin/sessions/delete-session/${sessionId}`);

// add flow
export const createSession = data => axios.post('api/admin/sessions', data);

export const fetchCourses = () =>
  axios.get('api/admin/classes/add-class/all-courses');

export const fetchClassesByCourseId = courseId =>
  axios.get(`api/admin/courses/${courseId}/classes`);

export const fetchUnitsByClassId = classId =>
  axios.get(`api/admin/classes/${classId}/units`);

export const fetchTeachersByUnitId = unitId =>
  axios.get(`api/admin/units/${unitId}/teachers`);

// attendance flow
export const fetchStudentsForSessionAttendance = sessionId =>
  axios.get(`api/admin/sessions/${sessionId}/attendance`);

export const attendanceSubmissionForSession = (sessionId, data) =>
  axios.post(`api/admin/sessions/${sessionId}/attendance-submission`, data);
