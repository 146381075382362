import {createSlice} from '@reduxjs/toolkit';
import {
  verifyOrgAdmin as verifyOrgAdminApi,
  verifyFreeAccountApi,
  getImportStatus,
} from '../../api/authOld/verifyOrgAdmin';
import {
  logInAfterVerification,
  setOrgPreferences,
  setUser,
  setUserOrganisation,
} from '../../redux/authSlice';
import {loginAfterInvitation} from '../AuthOld/authSlice';

export const activateUserSlice = createSlice({
  name: 'activateUser',
  initialState: {
    errorMessage: '',
  },
  reducers: {
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {setErrorMessage, setSubmitting} = activateUserSlice.actions;

export const verifyOrgAdmin = (data, history) => async dispatch => {
  try {
    const resp = await verifyOrgAdminApi(data);

    const timer = setInterval(async () => {
      const {
        data: {hasImported},
      } = await getImportStatus(resp.data.organisationId);

      if (hasImported) {
        dispatch(logInAfterVerification(resp.data.user, resp.data.token));
        dispatch(
          loginAfterInvitation(
            resp.data.user,
            resp.data.token,
            history,
            setUser,
            setUserOrganisation,
            setOrgPreferences,
          ),
        );
        clearInterval(timer);
      }
    }, 5000);

    dispatch(setErrorMessage(''));
  } catch (err) {
    dispatch(setErrorMessage('Failed verifying user.'));
  }
};

export const verifyFreeAdmin = (data, history) => async dispatch => {
  try {
    const resp = await verifyFreeAccountApi(data);

    dispatch(logInAfterVerification(resp.data.user, resp.data.token));
    dispatch(
      loginAfterInvitation(
        resp.data.user,
        resp.data.token,
        history,
        setUser,
        setUserOrganisation,
        setOrgPreferences,
      ),
    );

    dispatch(setErrorMessage(''));
  } catch (err) {
    dispatch(setErrorMessage('Failed verifying user.'));
  }
};

// state access fn's
export const activateUserSelector = state => state.activateUser;

export default activateUserSlice.reducer;
