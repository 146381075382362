/* eslint-disable react/prop-types */

import {Link} from 'react-router-dom';
import {AssessmentConditionContainer} from './elements';
// import HistoryIcon from '../../../../../../../../../../../../assets/icons/history-icon.svg';
// import AssessmentMapHistory from '../../../../../../../../../../../../components/common/AssessmentMapHistory';

export const AssessmentCondition = ({assessmentCondition, assessments}) => (
  // const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  // const [historyItem, setHistoryItem] = useState({
  //   itemId: null,
  //   assessmentType: null,
  // });

  // const activeMap = useMemo(() => {
  //   if (historyItem.itemId) {
  //     const item = assessmentCondition.find(
  //       ac => ac.tac_id_tgovassessmentcondition === historyItem.itemId,
  //     );
  //     const tasks = item.tasks.filter(
  //       data => data.ac_id_activitymethod === historyItem.assessmentType,
  //     );

  //     return {
  //       tasks,
  //       title: item.tac_condition,
  //       orgUnitInstanceId: item.orgUnitInstanceId,
  //     };
  //   }

  //   return null;
  // }, [assessmentCondition, historyItem]);

  // const onShowHistory = (itemId, assessmentType) => {
  //   setHistoryItem({
  //     itemId,
  //     assessmentType,
  //   });
  //   setIsHistoryVisible(true);
  // };
  // console.log('assessmentCondition :', assessmentCondition);
  <AssessmentConditionContainer>
    <div style={{width: '200vw'}}>
      <div className="title-container">
        <div className="serial">#</div>
        <div className="title">Assessment Conditions</div>
        {assessments.map(item => (
          <div className="observable-task">
            <div className="d-flex flex-column">
              <div className="observable-task">{item.ac_activity_name}</div>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  color: '#3d4457',
                }}
                className="mb-0 mt-1"
              >
                {item.am_activitymethod}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="content">
        {assessmentCondition?.map(item => {
          const evidenceItem = JSON.parse(
            !item?.tac_assesment_condition_items
              ? false
              : item?.tac_assesment_condition_items,
          );

          return (
            <>
              {item.tac_is_descriptor === 0 ? (
                <div className="assessment-condition-content">
                  <div className="top">
                    <div className="serial">
                      {' '}
                      <span className="serial-number-hash">#</span>
                      &bull;
                    </div>
                    <div className="title">
                      <span className="column-header">
                        Assessment Conditions
                      </span>
                      {item?.tac_condition}
                      {evidenceItem ? (
                        <ul className="ul-list">
                          {evidenceItem?.map(data => (
                            <li>{data}</li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                    {item.selectedAssessments.map(assessment => (
                      <div className="observable-task sub-item-task">
                        <span className="column-header">Observable Task</span>
                        {assessment?.assessmentTasks?.length ? (
                          <div className="d-flex flex-column">
                            {assessment.assessmentTasks.map(task => (
                              <div className="d-flex">
                                <Link className="sub-item-linked-task" to="/">
                                  {task?.ai_activityitem_title}
                                </Link>
                                {/* <button
                                  className="history-button"
                                  onClick={() => {
                                    onShowHistory(
                                      item.tac_id_tgovassessmentcondition,
                                      1,
                                    );
                                  }}
                                  type="button"
                                >
                                  <img alt="History" src={HistoryIcon} />
                                </button> */}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <span className="sub-item-empty-task">
                            No task linked
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <h6 className="pt-1 mt-2">{item?.tac_condition}</h6>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
    {/* <AssessmentMapHistory
        onHideHistory={() => {
          setIsHistoryVisible(false);
        }}
        isVisible={isHistoryVisible}
        tasks={activeMap?.tasks}
        title={activeMap?.title}
        orgUnitInstanceId={activeMap?.orgUnitInstanceId}
      /> */}
  </AssessmentConditionContainer>
);
