/* eslint-disable react/prop-types */

import React from 'react';
import classNames from 'classnames';
import NoIcon from '../../../../../../../../assets/icons/no.svg';
import YesIcon from '../../../../../../../../assets/icons/yes.svg';
import YesWhiteIcon from '../../../../../../../../assets/icons/yes_white.svg';
import NoWhiteIcon from '../../../../../../../../assets/icons/no_white.svg';
import {DecisionButtonContainer} from './elements';

export const DecisionButton = ({text, onClick, isSelected, isDanger}) => {
  let icon = isSelected ? YesWhiteIcon : YesIcon;
  if (isDanger) {
    icon = isSelected ? NoWhiteIcon : NoIcon;
  }
  return (
    <DecisionButtonContainer>
      <div className="decision-btn">
        <button
          type="button"
          className={classNames(
            'btn',
            {
              no: isDanger,
              yes: !isDanger,
            },
            {
              'no-active': isDanger && isSelected,
              'yes-active': !isDanger && isSelected,
            },
          )}
          onClick={onClick}
        >
          <div>{text}</div>
          <img src={icon} alt="" />
        </button>
      </div>
    </DecisionButtonContainer>
  );
};
