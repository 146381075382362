import React, {useState} from 'react';
import {Helmet} from 'react-helmet';

import {AddClassContainer} from './elements';
import {AddClassDetails, AddUnitsToClass, AddStudentsToClass} from './pages';

export const AddClass = () => {
  const [addClassStep, setAddClassStep] = useState(0);
  const [progress, setProgress] = useState(0);

  return (
    <>
      <Helmet>
        <title>Add Class | JungleCat</title>
      </Helmet>

      <AddClassContainer>
        {addClassStep === 0 ? (
          <AddClassDetails
            setAddClassStep={setAddClassStep}
            setProgress={setProgress}
            progress={progress}
          />
        ) : null}
        {addClassStep === 1 ? (
          <AddUnitsToClass
            setAddClassStep={setAddClassStep}
            setProgress={setProgress}
            progress={progress}
          />
        ) : null}
        {addClassStep === 2 ? (
          <AddStudentsToClass setProgress={setProgress} progress={progress} />
        ) : null}
      </AddClassContainer>
    </>
  );
};
