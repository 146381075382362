import styled from 'styled-components';

const TasFormSidebar = styled.aside`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-left: 3rem;
  width: 22%;

  button {
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 0.5rem;
    display: flex;
    flex-shrink: 0;
    height: 3rem;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 0.75rem 0 1rem;
    position: relative;
    text-align: left;

    :not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    &.is-active {
      background-color: white;

      ::before {
        background: linear-gradient(155.49deg, #419fff 9.5%, #0779ff 100.49%);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 0.3125rem;
      }

      .section-label {
        color: #1973da;
      }

      .section-status {
        background-color: white;
        color: #95c6ff;
      }
    }

    .section-label {
      color: #0c181f;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.125rem;
    }

    img {
      height: 1.5rem;
      object-fit: contain;
      width: 1.5rem;
    }

    .section-status {
      background-color: #e6e7e8;
      border-radius: 0.5rem;
      color: #9ea3a5;
      font-size: 0.625rem;
      font-weight: 500;
      line-height: 0.75rem;
      padding: 0.125rem 0;
      text-align: center;
      width: 1.875rem;
    }
  }
`;

export default TasFormSidebar;
