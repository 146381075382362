/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React from 'react';

import {UploadContainer} from './elements';
import RemoveFileIcon from '../../../../../../../../assets/icons/close.svg';
import FilesImg from '../../../../../../../../assets/icons/addFile.svg';
import {FileCard} from '../FileCard';

export const UploadSection = ({
  setUpload,
  upload,
  onDrag,
  setOnDrag,
  title,
  accept,
}) => {
  const onFileChange = e => {
    setUpload(e.target.files[0]);
  };

  return (
    <UploadContainer>
      <div className="upload-title">{title}</div>
      {upload ? (
        <div className="org-image-full">
          <div className="org-image-after">
            <FileCard file={upload} />
          </div>
          <div className="delete-logo">
            <button
              type="button"
              onClick={() => {
                setUpload(null);
              }}
              id="seat-upload"
            >
              <img src={RemoveFileIcon} alt="logo" />
            </button>
          </div>
        </div>
      ) : (
        <div
          onDragEnter={() => {
            setOnDrag(true);
          }}
          onDragLeave={() => {
            setOnDrag(false);
          }}
        >
          <div className={`org-image ${onDrag ? 'logo-drag' : ''}`}>
            <div className="org-logo">
              <img src={FilesImg} className="logo" alt="logo" />
            </div>
            <div className="org-text">
              Drop your file here or{' '}
              <input
                type="file"
                onChange={e => {
                  onFileChange(e);
                }}
                accept={accept}
                id="file-drop"
              />
              <span className="box__dragndrop">Browse</span>
            </div>
          </div>
        </div>
      )}
    </UploadContainer>
  );
};
