/* eslint-disable react/prop-types */
import {yupResolver} from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import {useCallback, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
// import {useLocalStorage} from 'react-use';

import {
  BackLink,
  Button,
  Checkbox,
  Link,
  Select,
  Spacer,
  TextInput,
} from '../../../../../../components/common';
import {
  authSelector,
  register as registerTeacher,
  setError,
} from '../../../../../../redux/authSlice';
import {COUNTRIES} from '../../../../../../utils/dummy';
import {useErrorMessage} from '../../../../../../utils/hooks';
import {registerTeacherSchema} from '../../../../../../utils/validation';
import {ErrorAlert, Heading, AuthSwitchLink} from '../../../../components';
import {TeacherFormContainer} from './elements';

const FIELDS_IN_ORDER = [
  'name',
  'email',
  'password',
  'confirmPassword',
  'accept',
  'country',
];

export const TeacherForm = ({onNavigateBack, accountType}) => {
  const dispatch = useDispatch();

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      accept: false,
      confirmPassword: '',
      country: null,
      email: '',
      name: '',
      password: '',
    },
    resolver: yupResolver(registerTeacherSchema),
  });
  const history = useHistory();
  const {error: serverError, isLoading} = useSelector(authSelector);

  const onSubmit = useCallback(
    data => {
      const details = {
        ...data,
        country: data.country?.value,
        organisationName: `${data.name.trim()}'s JungleCat`,
        orgType: accountType,
      };
      dispatch(
        registerTeacher(details, () => {
          history.push(`/register-success?email=${data.email}`);
        }),
      );
    },
    [accountType, dispatch, history],
  );

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {errorMessage, hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  const CountriesOptions = useMemo(
    () => [
      ...(COUNTRIES || []).map(item => ({
        label: item.name,
        value: item.name,
      })),
    ],
    [],
  );

  return (
    <TeacherFormContainer className="align-items-center col-lg-6 col-md-12 d-flex flex-column justify-content-center main-section position-relative px-4">
      <ErrorAlert isVisible={!!errorMessage} message={errorMessage} />
      <main className="d-flex flex-column main-content w-100">
        <Spacer height="3rem" />
        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <BackLink
            className="mb-3"
            onClick={() => {
              // clearLocalStorageValues();
              onNavigateBack();
            }}
          />
          <Heading
            marginBottom="2rem"
            subtitle="Enter your details to create an account."
            title="Junglecat"
          />
          <form
            autoComplete="off"
            className="align-items-center d-flex flex-column w-100"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="name"
              render={fields => (
                <TextInput
                  {...fields}
                  autoFocus
                  className="mb-4"
                  hasError={hasServerError || !!errors.name}
                  isLarge
                  label="Full Name"
                  placeholder="Enter your full name"
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.email}
                  isLarge
                  autoComplete="off"
                  label="Email"
                  placeholder="Enter your email address"
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  autoComplete="off"
                  hasError={hasServerError || !!errors.password}
                  isLarge
                  label="Password"
                  placeholder="Enter a password"
                  type="password"
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.confirmPassword}
                  isLarge
                  label="Confirm Password"
                  placeholder="Confirm the password"
                  type="password"
                />
              )}
            />
            <Controller
              control={control}
              name="country"
              render={fields => (
                <Select
                  {...fields}
                  className="mb-4"
                  hasError={!!errors.country}
                  label="Select Your Country"
                  options={CountriesOptions}
                  placeholder="Select country"
                />
              )}
            />
            <div className="align-items-start d-flex mb-4 w-100">
              <Controller
                control={control}
                name="accept"
                render={fields => (
                  <Checkbox hasError={!!errors.accept} {...fields} />
                )}
              />
              <p className="accept-checkbox-label mb-0">
                I accept the <Link to="/">Privacy Policy</Link> and{' '}
                <Link to="/">Terms of Service</Link> of JungleCat
              </p>
            </div>

            <Button
              isLarge
              isLoading={isLoading}
              label="Register"
              type="submit"
            />
          </form>
          <AuthSwitchLink
            linkHref="/login"
            linkText="Login"
            onLinkClick={() => {
              clearServerError();
              onNavigateBack();
            }}
            text="Already have an account?"
          />
        </div>
        <Spacer height="4rem" />
      </main>
    </TeacherFormContainer>
  );
};

TeacherForm.propTypes = {
  onNavigateBack: PropTypes.func.isRequired,
};
