import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomIconButton = styled.button.attrs(props => ({
  className: `rounded btn btn-${props.variant} d-flex align-items-center`,
}))`
  border: ${props =>
    props.active ? '1px solid #4EC977' : '1px solid #eeeeee'};
  background-color: ${props => props.active && '#EBF7EF'};
  padding: 0.75rem;
  img {
    transform: ${props =>
      props.rotate === 'true' ? 'rotate(90deg);' : 'none'};
  }
`;

const IconButton = ({
  alt,
  active,
  src,
  variant,
  className,
  rotate,
  ...props
}) => (
  <CustomIconButton
    variant={variant}
    rotate={rotate.toString()}
    className={className}
    active={active}
    {...props}
  >
    <img alt={alt} src={src} />
  </CustomIconButton>
);
IconButton.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  variant: PropTypes.string,
  rotate: PropTypes.bool,
  active: PropTypes.bool,
};
IconButton.defaultProps = {
  className: '',
  rotate: false,
  variant: 'light',
  active: false,
};

export default IconButton;
