import styled from 'styled-components';
import {Color} from '../../../../../../../../../../../constants';

export const ReuseFileContainer = styled.div`
  padding: 1.5rem 0 1.5rem 0;

  table {
    margin: 1rem 0;
    tr {
      td:first-child {
        width: 100%;
        margin-right: unset;
      }
      td:last-child {
        display: none;
      }
      td {
        padding: unset;
      }
    }

    thead {
      display: none;
    }
    tbody {
      border-top: 0.0625rem solid #e0e0e0;
    }
    tr {
      :hover {
        background-color: unset;
      }
    }
  }

  @media (max-width: 45rem) {
    table {
      tr {
        display: flex;
        align-items: center;
      }
      td {
        background: unset !important;
        text-align: left;
        :first-child {
          border-bottom: unset;
          margin-right: 1rem;
        }
      }
    }
  }

  .instruction-container {
    display: flex;
    border-bottom: 0.0625rem solid #dedede;
    padding: 1rem 0rem 1rem 0rem;
    :last-child {
      padding: 1rem 0rem 0 0rem;
      border-bottom: unset;
    }
    .data-container {
      width: 100%;
    }
    .index {
      font-weight: 400;
      font-size: 0.875rem;
      color: #6d7579;
    }
    .title-container {
      font-weight: 400;
      font-size: 1rem;
      color: #0c181f;
    }
    @media (max-width: 33rem) {
      .index-container {
        width: 1.5rem;
      }
      .data-container {
        width: 97.5%;
      }
    }
  }
  .uploaded {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-top: 0.25rem;
    color: #6d7579;
  }

  .added-instruction {
    align-items: flex-start;
    border-radius: 0.3125rem;
    display: flex;
    & > a,
    & > button {
      background-color: transparent;
      border: none;
      height: 2.5rem;
      position: relative;
      width: 2.5rem;

      :focus,
      :active {
        outline: none;
      }
    }

    .play-video-icon {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      img {
        height: 1rem;
        width: 1rem;
      }
    }

    .instruction-icon {
      border-radius: 0.3125rem;
      height: 2.5rem;
      object-fit: cover;
      width: 2.5rem;
    }

    .instruction-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 0.625rem;

      span {
        color: #aeaeae;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.21;
      }

      p {
        color: ${Color.PRIMARY_TEXT};
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.71;
        margin: -0.125rem 0 0 0;

        button {
          background-color: transparent;
          border: none;
          color: #00abff;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.71;

          :focus,
          :active {
            outline: none;
          }
        }
      }
    }

    .instruction-options {
      background-color: transparent;
      border: none;

      :focus,
      :active {
        outline: none;
      }
    }
  }

  .display-modal-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }
    &.for-document {
      width: 62%;
      @media (max-width: 33rem) {
        & {
          width: 90%;
        }
      }
    }

    .display-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .modal-close-button {
        background-color: transparent;
        border: none;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .instruction-document {
      border-radius: 0.375rem;
      height: 62vh;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .instruction-image {
      align-items: center;
      background-color: #535659;
      border-radius: 0.375rem;
      display: flex;
      height: 62vh;
      justify-content: center;
      object-fit: cover;
      overflow: hidden;
      position: relative;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .text-modal-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    @media (max-width: 33rem) {
      & {
        width: 90%;
      }
    }

    .text-modal-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1.5rem;
      h5 {
        margin-bottom: unset;
      }
      .modal-close-button {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;

        :focus,
        :active {
          outline: none;
        }

        img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }

    .text-content {
      background-color: #535659;
      height: 40vh;
      overflow-y: scroll;
      position: relative;
      width: 100%;
      background: #e6e7e8;
      border-radius: 0px 0px 0.5rem 0.5rem;
      padding: 3rem 9rem;
      p {
        font-weight: 500;
        font-size: 1rem;
        text-align: justify;
        color: #091018;
        line-height: 2;
      }
    }
  }
  .seperator {
    height: 0.0625rem;
    width: 100%;
    background-color: #e9e9e9;
  }
  .btn-container {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    .add-file-btn {
      padding: 0.5rem 1.5rem;
      background: #ffffff;
      border: 0.0625rem solid #40a1ff;
      box-sizing: border-box;
      border-radius: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      color: #40a1ff;
      box-shadow: unset;
    }
  }
  .file-btn {
    border: none;
    background-color: unset;
    outline: unset;
    align-items: unset;
    text-align: unset;
    width: 100%;
    padding: 1rem 0.25rem;
  }
`;
