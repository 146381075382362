/* eslint-disable react/prop-types */
import moment from 'moment';
import {useState} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import ScheduledSessionsChartContainer from './elements/ScheduledSessionsChartContainer';
import SessionBar from './components/SessionBar';
import TimeInterval from './components/TimeInterval';
import CalendarPreviousIcon from '../../../../../../../../assets/icons/calendar-previous.svg';
import CurrentTime from './components/CurrentTime';
import {streamSelector} from '../../../../../../../../redux/streamSlice';

const TIME_INTERVALS = Array.from(new Array(25).keys());

const ScheduledSessionsChart = ({
  date,
  setDate,
  sessions = [],
  liveSessionPreference,
}) => {
  const [dateToday] = useState(new Date());
  const streamState = useSelector(streamSelector);
  return (
    <ScheduledSessionsChartContainer
      className={classNames('d-flex flex-column justify-content-center', {
        'is-archive': streamState?.classDetails?.orui_is_archived,
      })}
    >
      <div className="scheduled-sessions-chart">
        <div className="align-items-center chart-header d-flex justify-content-between">
          <h4 className="selected-date mb-0">
            <span className="week">{moment(date).format('ddd,')}</span>{' '}
            <span>{moment(date).format('D MMM YYYY')}</span>
          </h4>
          <div className="navigation-buttons">
            <button
              className="navigation-button"
              type="button"
              onClick={() => {
                setDate(value => {
                  const newValue = new Date(value);
                  newValue.setDate(newValue.getDate() - 1);
                  return newValue;
                });
              }}
              id="previous-date-id"
            >
              <img alt="Previous Date" src={CalendarPreviousIcon} />
            </button>
            <button
              className="navigation-button"
              type="button"
              onClick={() => {
                setDate(value => {
                  const newValue = new Date(value);
                  newValue.setDate(newValue.getDate() + 1);
                  return newValue;
                });
              }}
              id="next-date-id"
            >
              <img alt="Next Date" src={CalendarPreviousIcon} />
            </button>
          </div>
        </div>
        <div className="chart-body position-relative">
          {TIME_INTERVALS.map((item, index) => (
            <TimeInterval
              key={String(item)}
              time={item}
              isLast={index + 1 === TIME_INTERVALS.length}
            />
          ))}
          {sessions.map(session => (
            <SessionBar
              id={session.oruil_id_orgunitinstancelesson}
              from={session.oruil_timestart}
              key={session.oruil_id_orgunitinstancelesson}
              title={session.oruil_name}
              to={session.oruil_timefinish}
              unitName={`${session.orgUnitInstance.class.oc_class} • ${session.orgUnitInstance.orgUnit.unit.tun_title}`}
              attendance={{
                students: session.students,
                presentStudents: session.presentStudents,
                absentStudents: session.absentStudents,
              }}
              liveSessionPreference={liveSessionPreference}
            />
          ))}
          {dateToday.toDateString() === date.toDateString() ? (
            <CurrentTime />
          ) : null}
        </div>
      </div>
    </ScheduledSessionsChartContainer>
  );
};

export {ScheduledSessionsChart};
