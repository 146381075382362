/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import {AddedTaskSectionContainer} from './elements';
import {TaskAndInstruction} from '../../../TaskAndInstruction';
import {AddTaskSection} from '../AddTaskSection';

// THIS IS FOR ADDING TASKS IN THE ASSESSMENT SECTION

export const AddedTaskSection = ({
  createdTasks,
  isLoadingAssessment,
  isPublished,
  removeTask,
  control,
  getValues,
  setValue,
  register,
  isArchive,
  clearErrors,
  setError,
  setOpenAddTask,
}) => {
  const [idOftaskToUpdate, setIdOfTaskToUpdate] = useState();

  return (
    <AddedTaskSectionContainer>
      {createdTasks.map(task =>
        task.id === idOftaskToUpdate ? (
          <div className="detail-task-container mb-3">
            <TaskAndInstruction
              closeAddTask={() => {
                setIdOfTaskToUpdate();
              }}
              getValues={getValues}
              isPublished={!task.isPublished}
              key={task.id}
              register={register}
              setValue={setValue}
              taskToUpdate={task}
              control={control}
              isArchive={isArchive}
              clearErrors={clearErrors}
              setError={setError}
            />
          </div>
        ) : (
          <AddTaskSection
            id={task.id}
            name={task.name}
            desc={task.description}
            key={task.id}
            type={task.type}
            evaluationType={task.evaluationName}
            files={task.taskFiles}
            isPublished={isPublished}
            removeTask={removeTask}
            setIdOfTaskToUpdate={setIdOfTaskToUpdate}
            performanceCriteriaName={task.performanceCriteriaName}
            performanceEvidenceName={task.performanceEvidenceName}
            knowledgeEvidenceName={task.knowledgeEvidenceName}
            assessmentConditionName={task.assessmentConditionName}
            isArchive={isArchive}
            setOpenAddTask={setOpenAddTask}
          />
        ),
      )}
      {isLoadingAssessment ? (
        <AddTaskSection isLoading={isLoadingAssessment} isArchive={isArchive} />
      ) : null}
    </AddedTaskSectionContainer>
  );
};
