import axios from '../..';

export const fetchAllLibraryFiles = unitID =>
  axios.get(`/api/class/${unitID}/library`);

export const fetchPublicLibraryFiles = unitID =>
  axios.get(`/api/class/${unitID}/public-library`);

export const addLibraryDocument = (unitId, formData) =>
  axios.post(`/api/class/${unitId}/library/add-document`, formData);

export const deleteLibraryClassFile = (fileId, unitId) =>
  axios.post(`/api/class/${unitId}/library/delete-document/${fileId}`);

export const updateLibraryDocument = (unitId, fileId, data) =>
  axios.post(`/api/class/${unitId}/library/update-document/${fileId}`, data);

export const fetchAllCategory = unitId =>
  axios.get(`/api/class/${unitId}/library/get-category`);

export const addNewCategory = (unitId, data) =>
  axios.post(`/api/class/${unitId}/library/add-category`, data);

export const individualFileCodeValid = (unitId, data) =>
  axios.post(
    `/api/class/${unitId}/library/add-document/file-code-validation`,
    data,
  );

export const individualFileCodeValidationInEdit = (unitId, data, fileId) =>
  axios.post(
    `/api/class/${unitId}/library/edit-document/file-code-validation/${fileId}`,
    data,
  );
