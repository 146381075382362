/* eslint-disable react/prop-types */
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {NonRtoHeader, RtoHeader} from './component';

const HeaderContainer = styled.div`
  background: linear-gradient(150deg, #419fff 10%, #0779ff 100%);
  padding: 1rem 1rem 1.5rem 1rem;
  width: 100%;

  .title {
    color: white;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .description {
    color: #ecf6ff;
    font-size: 0.875rem;
    line-height: 1.5;
  }
`;

const Header = ({liveSessionPreference}) => {
  const [classId, setClassId] = useState();
  const history = useHistory();

  useEffect(() => {
    const {pathname} = history.location;
    if (/stream/.test(pathname)) {
      const idOfClass = pathname.split('/')[2];
      setClassId(idOfClass);
    }
  }, [history.location]);

  return (
    <HeaderContainer>
      {liveSessionPreference ? (
        <NonRtoHeader classId={classId} history={history} />
      ) : (
        <RtoHeader classId={classId} history={history} />
      )}
    </HeaderContainer>
  );
};

export default Header;
