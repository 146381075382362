/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {showToast} from '../../../../../../../../components/common/Toast';
import AddToastSuccessImg from '../../../../../../../../assets/icons/addtoastadmin.svg';
import {
  spreadSheetStudentCheckToViewClass,
  addStudentsToViewClass,
  classesSelector,
} from '../../../../../../../../redux/classesSlice';

import {AddStudentFooterContainer} from './elements';
import CustomCancel from '../../../../../../../../components/shared/Buttons/CustomButton';
import CustomButton from '../../../../../../../../components/shared/CustomButton';

export const AddStudentFooter = ({
  addStudents,
  setAddStudents,
  spreadSheet,
  setSpreadSheet,
  manual,
  steps,
  setSteps,
  setUpload,
  setIsValidating,
  nextShow,
  isValidating,
  studentEmailPattern,
  alreadyExistStudentEmail,
  alreadyExistStudentRefId,
  otherOrgEmailErrorData,
  otherOrgReferIdErrorData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {orgClassId} = useParams();
  const {validClassStudent, isClassesLoading, duplicateData} =
    useSelector(classesSelector);
  return (
    <AddStudentFooterContainer>
      <div className="add-student-footer fixed-bottom">
        {manual ? (
          <div className="add-student-spread">
            <div className="progress-section" />
            <div className="add-student-title">
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setAddStudents([]);

                    history.replace(`/admin/classes/view-class/${orgClassId}`);
                  }}
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!addStudents?.length}
                  className="save-changes"
                  text="Add Students"
                  onClick={() => {
                    if (addStudents?.length > 0 && !isClassesLoading) {
                      dispatch(
                        addStudentsToViewClass(orgClassId, addStudents, () => {
                          showToast(
                            AddToastSuccessImg,
                            `${addStudents?.length} Students added successfully`,
                          );

                          history.replace(
                            `/admin/classes/view-class/${orgClassId}`,
                          );

                          setAddStudents([]);
                        }),
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="add-student-spread">
            {steps === 0 ? <div className="progress-section" /> : null}
            {steps === 1 ? (
              <CustomCancel
                className="cancel"
                bgColor="#fff"
                onClick={() => {
                  setSteps(0);
                  setSpreadSheet([]);
                  setUpload(null);
                  setAddStudents([]);
                }}
              >
                <span className="cancel-button-text">Back</span>
              </CustomCancel>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validClassStudent?.length &&
            (alreadyExistStudentRefId?.length ||
              duplicateData?.length ||
              alreadyExistStudentEmail?.length ||
              otherOrgEmailErrorData?.length ||
              otherOrgReferIdErrorData?.length) &&
            studentEmailPattern?.length ? (
              <div className="button-container" />
            ) : (
              <div className="button-container" />
            )}
            {steps === 3 ? <div className="button-container" /> : null}

            {steps === 0 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setSteps(0);
                    setSpreadSheet([]);
                    setAddStudents([]);
                    setUpload(null);

                    history.replace(`/admin/classes/view-class/${orgClassId}`);
                  }}
                >
                  <span className="cancel1-button-text">Cancel</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow}
                  className="save-changes"
                  text="Next"
                  onClick={() => {
                    setSteps(state => state + 1);
                  }}
                />
              </div>
            ) : null}
            {steps === 1 ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  isDisabled={isValidating}
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                  }}
                >
                  <span className="cancel1-button-text">Upload Again</span>
                </CustomCancel>
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Add Students"
                  onClick={() => {
                    setIsValidating(true);
                    dispatch(
                      spreadSheetStudentCheckToViewClass(
                        orgClassId,
                        spreadSheet,
                        () => {
                          setTimeout(() => {
                            setIsValidating(false);
                            setSteps(state => state + 1);
                          }, 3000);
                        },
                      ),
                    );
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !alreadyExistStudentRefId?.length &&
            !alreadyExistStudentEmail?.length &&
            !otherOrgEmailErrorData?.length &&
            !otherOrgReferIdErrorData?.length &&
            !duplicateData?.length &&
            !studentEmailPattern?.length &&
            validClassStudent?.length === spreadSheet?.length ? (
              <div className="button-container">
                <CustomButton
                  isDisabled={!spreadSheet.length || nextShow || isValidating}
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    showToast(
                      AddToastSuccessImg,
                      ` ${validClassStudent?.length} Students added successfully`,
                    );

                    setSteps(state => state + 1);
                    history.replace(`/admin/classes/view-class/${orgClassId}`);
                    setIsValidating(true);
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            validClassStudent?.length &&
            (alreadyExistStudentRefId?.length ||
              alreadyExistStudentEmail?.length ||
              otherOrgEmailErrorData?.length ||
              otherOrgReferIdErrorData?.length ||
              duplicateData?.length ||
              studentEmailPattern?.length) ? (
              <div className="button-container">
                <CustomCancel
                  className="cancel1"
                  bgColor="#fff"
                  onClick={() => {
                    setUpload(null);
                    setSteps(0);
                    setSpreadSheet([]);
                  }}
                >
                  <span className="cancel1-button-text">
                    Discard and Reupload
                  </span>
                </CustomCancel>
                <CustomButton
                  isDisabled={
                    !spreadSheet?.length ||
                    nextShow ||
                    isValidating ||
                    !validClassStudent?.length
                  }
                  className="save-changes"
                  text="Add valid students"
                  onClick={() => {
                    if (validClassStudent.length > 0 && !isClassesLoading) {
                      dispatch(
                        addStudentsToViewClass(
                          orgClassId,
                          validClassStudent,
                          () => {
                            showToast(
                              AddToastSuccessImg,
                              `${validClassStudent?.length} Students added successfully`,
                            );
                            history.replace(
                              `/admin/classes/view-class/${orgClassId}`,
                            );
                            setSpreadSheet([]);
                          },
                        ),
                      );
                    }
                  }}
                />
              </div>
            ) : null}
            {steps === 2 &&
            !isValidating &&
            !validClassStudent?.length &&
            (alreadyExistStudentRefId?.length ||
              alreadyExistStudentEmail?.length ||
              otherOrgEmailErrorData?.length ||
              otherOrgReferIdErrorData?.length ||
              duplicateData?.length ||
              studentEmailPattern?.length) ? (
              <div className="button-container">
                <CustomButton
                  className="save-changes"
                  text="Done"
                  onClick={() => {
                    history.replace(`/admin/classes/view-class/${orgClassId}`);
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </AddStudentFooterContainer>
  );
};
