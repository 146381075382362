/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import SessionPeerVideoScreen from '../SessionPeerVideoScreen';
import VideoGrid from '../VideoGrid';
import {ScreenSharingLayoutWrapper} from './elements';

const ScreenSharingLayout = ({
  isSharingScreen,
  localScreenVideoTrack,
  remoteScreenVideoTrack,
  remoteScreenAudioTrack,
  localAudioTrack,
  localVideoTrack,
  remoteUsers,
  speakingStates,
}) => (
  <ScreenSharingLayoutWrapper>
    {isSharingScreen ? (
      <SessionPeerVideoScreen videoTrack={localScreenVideoTrack} />
    ) : (
      <SessionPeerVideoScreen
        audioTrack={remoteScreenAudioTrack}
        videoTrack={remoteScreenVideoTrack}
      />
    )}
    <VideoGrid
      localAudioTrack={localAudioTrack}
      localVideoTrack={localVideoTrack}
      remoteUsers={remoteUsers}
      speakingStates={speakingStates}
    />
  </ScreenSharingLayoutWrapper>
);

ScreenSharingLayout.defaultProps = {
  isSharingScreen: false,
  localScreenVideoTrack: null,
  remoteScreenVideoTrack: null,
  remoteScreenAudioTrack: null,
  localAudioTrack: null,
  localVideoTrack: null,
  remoteUsers: [],
  speakingStates: {},
};

ScreenSharingLayout.propTypes = {
  isSharingScreen: PropTypes.bool,
  localScreenVideoTrack: PropTypes.objectOf(PropTypes.any),
  remoteScreenVideoTrack: PropTypes.objectOf(PropTypes.any),
  remoteScreenAudioTrack: PropTypes.objectOf(PropTypes.any),
  localAudioTrack: PropTypes.objectOf(PropTypes.any),
  localVideoTrack: PropTypes.objectOf(PropTypes.any),
  remoteUsers: PropTypes.arrayOf(PropTypes.object),
  speakingStates: PropTypes.objectOf(PropTypes.any),
};

export default ScreenSharingLayout;
