import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import MainInput from '../../../../../../components/shared/Inputs/MainInput';
import CustomButton from '../../../../../../components/shared/Buttons/CustomButton';
import {Nav} from '../../../../../../components/shared/Navbar/Elements/Navbar';
import DropdownWrapper from '../../../../../../components/shared/DropdownWrapper/DropdownWrapper';
import EllipsisImg from '../../../../../../assets/icons/ellipsis.svg';
import ArrowLeftImg from '../../../../../../assets/icons/arrow_left.svg';

import {
  setAssessmentTitle,
  setSearchField,
  fetchAllUnits,
  adminAssessmentsSelector,
  getAllQualification,
} from '../../../../../../redux/adminAssessmentsSlice';
import useNavbarScroll from '../../../../../../hooks/useNavbarScroll';
import {AddAssessmentByUnitContainer} from './elements';
import {PublishAssessments} from './components';
import {Select} from '../../../../../../components/common';

export const AddAssessmentByQualUnit = () => {
  const input = useRef();

  const [menuOpen, toggleMenu] = useState(false);
  const [unitId, setUnitId] = useState(null);
  const [qualId, setQualId] = useState(null);
  const dispatch = useDispatch();

  const {qualifications, searchTerm, units, assessmentLoading} = useSelector(
    adminAssessmentsSelector,
  );

  const formatOptionForselect = ({code, label}, {context}) =>
    context === 'menu' ? (
      <div className="select-option" type="button">
        <div className="label">{label}</div>
        <div className="code">{code}</div>
      </div>
    ) : (
      label
    );

  const qualificationOptions = useMemo(
    () =>
      qualifications?.map(item => ({
        label: item.qualification.tqual_title,
        value: item.qualification.tqual_id_tgovqualification,
        code: item.qualification.tqual_code,
      })) || [],
    [qualifications],
  );

  const orgUnitsOptions = useMemo(
    () =>
      units?.map(item => ({
        value: item.ou_id_orgunit,
        label: item.unit.tun_title,
        tgovId: item.unit.tun_id_tgovunit,
        code: item.unit.tun_code,
      })) || [],

    [units],
  );

  const history = useHistory();
  const {handleScroll, navbarTop} = useNavbarScroll(16, 81);

  useEffect(() => {
    if (input) input.current.focus();
  }, []);

  useEffect(() => {
    dispatch(setSearchField(''));
  }, [dispatch]);

  const onSubmitPress = useCallback(() => {
    if (searchTerm.trim() !== '' && unitId && qualId && !assessmentLoading) {
      dispatch(setAssessmentTitle(unitId?.value, history));
    }
  }, [assessmentLoading, dispatch, history, qualId, searchTerm, unitId]);

  useEffect(() => {
    if (qualId) {
      setUnitId(null);
      dispatch(fetchAllUnits(qualId?.value));
    }
  }, [dispatch, qualId]);

  useEffect(() => {
    dispatch(getAllQualification());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Add an Assessment | JungleCat</title>
      </Helmet>
      <AddAssessmentByUnitContainer className="row position-relative assessment-search-body">
        <Nav
          className="navbar navbar-expand-sm p-0 fixed-top row px-lg-4 ml-0 w-100"
          style={{
            top: navbarTop,
          }}
        >
          <button
            className="btn"
            onClick={() => history.goBack()}
            style={{
              padding: 0,
            }}
            type="button"
          >
            <img src={ArrowLeftImg} alt="arrow" />
          </button>
          <p className="assessment-heading mb-0 mr-auto">
            Create an Assessment
          </p>
          <DropdownWrapper toggleMenu={toggleMenu}>
            <button
              type="submit"
              className="btn"
              onClick={() => toggleMenu(!menuOpen)}
            >
              <img src={EllipsisImg} alt="ellipsis" />
            </button>
          </DropdownWrapper>
        </Nav>
        <main className="main-body" onScroll={handleScroll}>
          <div className="col-lg-6 col-12 mx-auto assessment-search-content">
            <PublishAssessments
              isButtonShow={false}
              assessmentLoading={assessmentLoading}
            />
            <div className="assessment-search-inputDiv">
              <Select
                className="mb-4"
                // hasError={!!errors.unit}
                // errorMessage={errors.unit?.message}
                formatOptionLabel={formatOptionForselect}
                isLarge={false}
                label="Qualification"
                options={qualificationOptions}
                placeholder="Choose a qualification"
                onChange={e => {
                  setQualId(e);
                }}
                id="unit-name"
              />
              <Select
                className="mb-4"
                // hasError={!!errors.unit}
                // errorMessage={errors.unit?.message}
                isLarge={false}
                label="Unit"
                formatOptionLabel={formatOptionForselect}
                options={orgUnitsOptions}
                isDisabled={!orgUnitsOptions.length}
                placeholder="Choose a unit"
                value={unitId}
                onChange={e => {
                  setUnitId(e);
                }}
                id="unit-name"
              />
              <MainInput
                type="text"
                id="search-add"
                label=""
                name="search-add"
                placeholder="Enter assessment title"
                autoComplete="off"
                onChange={e => dispatch(setSearchField(e.target.value))}
                value={searchTerm}
                register={input}
                style={
                  searchTerm !== ''
                    ? {
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',
                      }
                    : {}
                }
              />

              {searchTerm !== '' && (
                <CustomButton
                  type="button"
                  width="100%"
                  padding="0.625rem 1.125rem"
                  bgColor="#40A1FF"
                  onClick={onSubmitPress}
                  className="add-assessment-button"
                >
                  Add `{searchTerm}` as a New Assessment
                </CustomButton>
              )}
            </div>
          </div>
        </main>
      </AddAssessmentByUnitContainer>
    </>
  );
};
