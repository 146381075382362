import styled from 'styled-components';

import {Colors} from '../../../../../../../../../../../constants';

export const AssessmentMapContainer = styled.div`
  background-color: ${Colors.Background};
  min-height: calc(100vh - 5rem);
  padding: 2rem;
  overflow-y: auto;

  @media (max-width: 43.75rem) {
    & {
      padding: 12rem 1rem 3rem 7rem;
    }
  }
  @media (max-width: 37.5rem) {
    & {
      padding: 12rem 1rem 3rem 1rem;
    }
  }

  .page-title {
    color: ${Colors.Text};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.81rem;
  }

  .unit-name {
    color: ${Colors.LighterText};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    margin-bottom: 2rem;
  }

  .unit-name-skeleton {
    margin-bottom: 2rem;
  }
  .search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    padding: 0rem 0rem 0rem 0rem;
    border-bottom: 0.0625rem solid #dedede;
    .bottom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      font-weight: 500;
      font-size: 1rem;
      outline: none;
      color: #aaaaaa;
      &.link {
        border-bottom: 0.125rem solid #40a1ff !important;
        color: #3d4457;
      }
    }
  }
  .search-div::-webkit-scrollbar {
    display: none;
  }
`;
