import styled from 'styled-components';

const SidebarHeaderContainer = styled.header`
  padding: 0 1rem 0 1.5rem;
  border-bottom: 0.0625rem solid ${({theme}) => theme.colors.border};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-tabs {
    display: flex;

    .header-tab {
      padding: 1.5rem 0;
      border: none;
      border-bottom: 0.125rem solid transparent;
      margin: 0 2rem -0.0625rem 0;
      background-color: transparent;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${({theme}) => theme.colors.placeholder};

      .header-tab-badge {
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        margin-left: 0.25rem;
        background-color: #f3f3f3;
        font-size: 0.625rem;
        line-height: 0.75rem;
        color: #c9c9c9;
      }

      &.is-active {
        border-bottom-color: ${({theme}) => theme.colors.primary};
        color: ${({theme}) => theme.colors.text};

        .header-tab-badge {
          background-color: ${({theme}) => theme.colors.lighterPrimary};
          color: ${({theme}) => theme.colors.primary};
        }
      }
    }
  }

  .sidebar-close {
    width: 1rem;
    height: 1rem;
    border: none;
    margin-top: 1rem;
    background-color: transparent;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export default SidebarHeaderContainer;
