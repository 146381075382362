import styled from 'styled-components';

export const DisplayModalConatiner = styled.div`
  .display-modal-container {
    display: flex;
    flex-direction: column;
    .white-box {
      width: 100%;
    }
  }

  &.for-document {
    width: 100%;
    height: 630px;
    padding: 1.5rem;

    iframe {
      height: 92%;
      width: 600px;
    }
    @media (max-width: 720px) {
      iframe {
        height: 92%;
        width: 100%;
      }
    }
  }

  .instruction-document {
    border-radius: 0.375rem;
    height: 62vh;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .instruction-image {
    align-items: center;
    background-color: #000;
    border-radius: 0.375rem;
    display: flex;
    max-height: 62vh;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .modal-close-button {
    background-color: transparent;
    border: none;
    display: flex;
    align-self: flex-end;
    width: 100%;

    :focus,
    :active {
      outline: none;
    }

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  .display-modal-header {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 1rem;
    width: 100%;
    align-self: flex-end;

    .modal-close-button {
      background-color: transparent;
      border: none;

      :focus,
      :active {
        outline: none;
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
`;
