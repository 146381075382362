import moment from 'moment';
import {useSelector} from 'react-redux';
import {useCallback, useEffect, useState} from 'react';
import {CurrentTimeContainer} from './elements';
import {streamSelector} from '../../../../../../../../../../redux/streamSlice';

const CurrentTime = () => {
  const [topOffset, setTopOffset] = useState(0);
  const streamState = useSelector(streamSelector);
  // Set the top offset of the indicator based on the current time.
  const setTopOffsetUsingCurrentTime = useCallback(() => {
    const currentTime = moment().format('HH:mm');
    const [hoursNow, minutesNow] = currentTime.split(':').map(Number);
    setTopOffset(hoursNow * 142 + (142 / 60) * minutesNow - 14);
  }, []);

  // Update the position of the indicator every minute.
  useEffect(() => {
    setTopOffsetUsingCurrentTime();

    const intervalId = setInterval(() => {
      setTopOffsetUsingCurrentTime();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [setTopOffsetUsingCurrentTime]);

  if (!topOffset) return null;
  if (streamState?.classDetails?.orui_is_archived) return null;
  return (
    <CurrentTimeContainer
      className="align-items-center d-flex position-absolute"
      top={topOffset}
    >
      <span className="circle" />
      <span className="line" />
    </CurrentTimeContainer>
  );
};

export default CurrentTime;
