/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Color} from '../../../constants';

export const FilePreviewContainer = styled.div`
  ${({width}) => (width ? `width: ${width};` : '')}

  @media (max-width:${({width}) => width || ''}) {
    width: 100%;
  }

  .header {
    padding: 1.5rem 1.5rem 0 1.5rem;

    .trash-icon {
      height: 3.6875rem;
      width: 3.6875rem;
    }

    .title {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      color: #091018;
      display: -webkit-box !important;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.8125rem;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .description {
      color: ${({theme}) => theme.colors.lightText};
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
    }

    .close-modal-button {
      background-color: transparent;
      border: none;
      height: 1.5rem;
      width: 1.5rem;

      :active,
      :focus {
        outline: none;
      }

      .close-modal-icon {
        height: 100%;
        width: 100%;
      }
    }
  }
  .file-name {
    font-weight: 500;
    font-size: 1.125rem;
    color: #091018;
    margin-bottom: 0.5rem;
    overflow-wrap: anywhere;
  }
  .button-download {
    background-color: transparent;
    border-color: transparent;
    outline: unset;
    padding: unset;
  }
  .spliter {
    height: 1.5rem;
    background-color: #dedede;
    width: 0.0625rem;
    margin: 0 1rem;
  }
  .footer {
    .file-heading {
      font-weight: 500;
      font-size: 1.5rem;
      color: #3d4457;
      margin-bottom: 0.5rem;
    }
    .date {
      color: #8a8c94;
      font-weight: 400;
      font-size: 12px;
      margin-bottom: unset;
    }
  }

  .file-size {
    font-weight: 500;
    font-size: 0.75rem;
    color: #aeaeae;
    margin-bottom: unset;
  }
  .portfolio-btn {
    background-color: unset;
    outline: unset;
    border: unset;
  }
  .main-div {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  .container-preview {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      margin: unset;
    }
  }

  .iframe {
    height: 100%;
    width: 100%;
  }
  .audios {
    height: 400px;
    background-color: #d9ecff;
    display: flex;
    align-items: center;
    flex-direction: column;
    audio {
      width: 100%;
      bottom: 0;
      height: 100%;
    }
  }
  .videos {
    height: 500px;
    width: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
