/* eslint-disable react/prop-types */
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

// import {Select} from '../../../../../../../../../../../../components/common';
import CustomGroupSelectInput from '../../../../../../../../../../../../components/shared/CustomGroupSelectInput';
import {activeClassSelector} from '../../../../../../../../../../../../redux/activeClassSlice';
import {MappedTaskContainer} from './elements';

// const foundationSkills = [
//   {
//     id: 1,
//     name: (
//       <div className="">
//         <div>1 Reading skills to:</div>
//         <ul className="ml-4 mt-3">
//           <li>
//             locate information in food preparation lists and standard recipes to
//           </li>
//           <li>
//             locate and read date codes and rotation labels on food products
//           </li>
//         </ul>
//       </div>
//     ),
//   },
//   {
//     id: 1,
//     name: (
//       <div className="">
//         <div>2 Numeracy skills to: </div>
//         <ul className="ml-4 mt-3">
//           <li>Numeracy skills to: calculate the number of portions</li>
//           <li>Determine cooking times and temperatures.</li>
//         </ul>
//       </div>
//     ),
//   },
//   {
//     id: 1,
//     name: (
//       <div className="">
//         <div> 3 Problem-solving skills to:</div>
//         <ul className="ml-4 mt-3">
//           <li>
//             Evaluate quality of poultry and finished dishes and make adjustments
//             to ensure a quality product
//           </li>
//           <li>
//             Adjust taste and appearance of food products according to identified
//             deficiencies.
//           </li>
//         </ul>
//       </div>
//     ),
//   },
//   {
//     id: 1,
//     name: (
//       <div className="">
//         <div> 4 Self-management skills to:</div>
//         <ul className="ml-4 mt-3">
//           <li>
//             Efficiently sequence the stages of food preparation and production.
//           </li>
//         </ul>
//       </div>
//     ),
//   },
// ];

export const MappedTask = ({
  elementId,
  performanceCriteriaId,
  performanceEvidenceId,
  knowledgeEvidenceId,
  assessmentConditionId,
  setPerformanceCriteriaId,
  setPerformanceEvidenceId,
  setKnowledgeEvidenceId,
  setAssessmentConditionId,
}) => {
  const {
    performanceCriteria,
    performanceEvidences,
    knowledgeEvidences,
    assessmentConditions,
  } = useSelector(activeClassSelector);

  const convertName = fullName => {
    const arr = fullName.split(' ');

    arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1).toLowerCase();

    const formattedName = arr.join(' ');

    return formattedName;
  };

  const performanceCriteriaGroup = useMemo(
    () =>
      performanceCriteria?.map(element => ({
        elementId: element.tel_id_tgovelement,
        label: `${element.tel_element_id} ${element.tel_element_value}`,
        options: element.performanceCriteria.map(criteria => ({
          label: `${criteria.tpc_criteria_id} ${criteria.tpc_criteria_value}`,
          value: criteria.tpc_id_tgovperformancecriteria,
        })),
      })) || [],
    [performanceCriteria],
  );

  const knowledgeEvidenceGroup = useMemo(
    () =>
      knowledgeEvidences?.map(item => ({
        label: item.label,
        options: item.options.map(evidence => ({
          label: convertName(evidence.tke_evidence_value),
          value: evidence.tke_id_tgovknowledgeevidence,
        })),
      })) || [],
    [knowledgeEvidences],
  );

  const performanceEvidenceGroup = useMemo(
    () =>
      performanceEvidences?.map(item => ({
        label: item.label,
        options: item.options.map(evidence => ({
          label: convertName(evidence.tpe_evidence_value),
          value: evidence.tpe_id_tgovperformanceevidence,
        })),
      })),
    [performanceEvidences],
  );

  const assessmentConditionGroup = useMemo(
    () =>
      assessmentConditions?.map(item => ({
        label: item.label,
        options: item.options.map(condition => ({
          label: convertName(condition.tac_condition),
          value: condition.tac_id_tgovassessmentcondition,
        })),
      })),
    [assessmentConditions],
  );

  useEffect(() => {
    if (
      performanceCriteriaGroup?.length &&
      typeof performanceCriteriaId === 'number'
    ) {
      const element = performanceCriteriaGroup?.find(
        item => item.elementId === elementId,
      );
      const value = element?.options?.find(
        item => item.value === performanceCriteriaId,
      );
      setPerformanceCriteriaId(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, performanceCriteriaId, performanceCriteriaGroup]);

  useEffect(() => {
    if (
      performanceEvidenceGroup?.length &&
      typeof performanceEvidenceId === 'number'
    ) {
      const element = performanceEvidenceGroup?.find(data =>
        data.options.find(item => item.value === performanceEvidenceId),
      );
      const value = element?.options?.find(
        item => item.value === performanceEvidenceId,
      );
      setPerformanceEvidenceId(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceEvidenceGroup, performanceEvidenceId]);

  useEffect(() => {
    if (
      knowledgeEvidenceGroup?.length &&
      typeof knowledgeEvidenceId === 'number'
    ) {
      const element = knowledgeEvidenceGroup?.find(data =>
        data.options.find(item => item.value === knowledgeEvidenceId),
      );
      const value = element?.options?.find(
        item => item.value === knowledgeEvidenceId,
      );
      setKnowledgeEvidenceId(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledgeEvidenceGroup, knowledgeEvidenceId]);

  useEffect(() => {
    if (
      assessmentConditionGroup?.length &&
      typeof assessmentConditionId === 'number'
    ) {
      const element = assessmentConditionGroup?.find(data =>
        data.options.find(item => item.value === assessmentConditionId),
      );

      const value = element?.options?.find(
        item => item.value === assessmentConditionId,
      );

      setAssessmentConditionId(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentConditionGroup, assessmentConditionId]);

  return (
    <MappedTaskContainer>
      <div className="title">Map Task</div>
      <div className="map-column">
        <div className="left-field">
          <CustomGroupSelectInput
            label="Performance Criteria"
            name="performance-criteria"
            isLarge={false}
            placeholder="Select Performance Criteria"
            groupedOptions={performanceCriteriaGroup}
            className="mb-4"
            onChange={setPerformanceCriteriaId}
            value={performanceCriteriaId}
          />
        </div>
        <div className="right-field">
          <CustomGroupSelectInput
            label="Knowledge Evidence"
            name="knowledge-criteria"
            isLarge={false}
            placeholder="Select Knowledge Evidence"
            groupedOptions={knowledgeEvidenceGroup}
            className="mb-4"
            onChange={setKnowledgeEvidenceId}
            value={knowledgeEvidenceId}
          />
          {/* <Select
            className="mb-4"
            isLarge={false}
            label="Knowledge Evidence"
            placeholder="Select Knowledge Evidence"
            options={knowledgeEvidenceGroup}
            onChange={setKnowledgeEvidenceId}
            value={knowledgeEvidenceId}
          /> */}
        </div>
      </div>
      <div className="map-column">
        <div className="left-field">
          <CustomGroupSelectInput
            label="Performance Evidence"
            name="performance-criteria"
            isLarge={false}
            placeholder="Select Performance Evidence"
            groupedOptions={performanceEvidenceGroup}
            className="mb-4"
            onChange={setPerformanceEvidenceId}
            value={performanceEvidenceId}
          />
          {/* <Select
            className="mb-4"
            isLarge={false}
            label="Performance Evidence"
            placeholder="Select Performance Evidence"
            options={performanceEvidenceGroup}
            onChange={setPerformanceEvidenceId}
            value={performanceEvidenceId}
          /> */}
        </div>
        <div className="right-field">
          <CustomGroupSelectInput
            label="Assesment Conditions"
            name="assessment-conditions"
            isLarge={false}
            placeholder="Select Assesment Conditions"
            groupedOptions={assessmentConditionGroup}
            className="mb-4"
            onChange={setAssessmentConditionId}
            value={assessmentConditionId}
          />
          {/* <Select
            className="mb-4"
            isLarge={false}
            label="Assesment Conditions"
            placeholder="Select Assesment Conditions"
            options={assessmentConditionGroup}
            onChange={setAssessmentConditionId}
            value={assessmentConditionId}
          /> */}
        </div>
      </div>
    </MappedTaskContainer>
  );
};
