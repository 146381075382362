import styled from 'styled-components';

export const InvitationStatusContainer = styled.div`
  display: flex;
  flex-direction: row;

  .invitation-status-0,
  .invitation-status-1,
  .invitation-status-2 {
    border-radius: 0.3125rem;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.21;
    padding: 0.5rem 1rem;
  }
  .invitation-status-0 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }
  .invitation-status-1 {
    background-color: #e4f4f1;
    color: #4ec977;
  }
  .invitation-status-2 {
    background-color: #f4f4f4;
    color: #8c8c8c;
  }

  @media (max-width: 75rem) {
    & {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .invitation-status-0,
    .invitation-status-1,
    .invitation-status-2 {
      border-radius: 5px;
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.21;
      padding: 8px 16px;
    }
  }
`;
