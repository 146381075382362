/* eslint-disable react/prop-types */

import ReactBootstrapModal from 'react-bootstrap/Modal';

import CloseModalIcon from '../../../../../../../../../../assets/icons/refactor/close-modal.svg';
import {ModalContainer} from './elements';

export const ViewAssessmentMapModal = ({
  children,
  className,
  onHide,
  show,
  title,
  width,
}) => (
  <ReactBootstrapModal centered onHide={onHide} show={show}>
    <ModalContainer width={width} className={className}>
      <header className="align-items-start d-flex header justify-content-between">
        <div className="align-items-center d-flex details">
          <div className="d-flex flex-column">
            <h3 className="mb-1 title" title={title}>
              {title}
            </h3>
          </div>
        </div>
        <button
          className="close-modal-button flex-shrink-0"
          onClick={onHide}
          type="button"
        >
          <img
            alt="Close Modal"
            className="close-modal-icon"
            src={CloseModalIcon}
          />
        </button>
      </header>
      {children}
    </ModalContainer>
  </ReactBootstrapModal>
);
