/* eslint-disable react/prop-types */

import React, {useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';

import DueDateIcon from '../../../../../../../../../assets/icons/calender-new.svg';
import LocationIcon from '../../../../../../../../../assets/icons/location-new.svg';
import TimeIcon from '../../../../../../../../../assets/icons/sand-bottle-logo.svg';
import StudentIcon from '../../../../../../../../../assets/icons/student-logo-new.svg';
import ArrowHideImg from '../../../../../../../../../assets/icons/tablehide.svg';
import ArrowShowImg from '../../../../../../../../../assets/icons/tableshow.svg';
import ResourcesActiveIcon from '../../../../../../../../../assets/icons/resource.svg';
import ResourcesIcon from '../../../../../../../../../assets/icons/resources-disabled.svg';

import {BasicDetailsContainer} from './elements';

export const BasicDetails = ({assessment}) => {
  const assignedStudentsCount = assessment.instance?.students?.length;
  const totalStudentsCount = assessment.instance?.totalStudents?.length;
  const [isResourcesExpanded, setIsResourcesExpanded] = useState(false);

  const toggleResources = () => setIsResourcesExpanded(!isResourcesExpanded);

  return (
    <BasicDetailsContainer className="basic-detail-content">
      <div className="basic-details-header">
        <h3>Basic Details</h3>

        <p className="basic-details-description">
          {assessment.ac_activity_description}

          <p className="published-text">
            Published on :{' '}
            <span className="published-time">
              {moment(assessment?.aoui_timestamp_published).format(
                'D MMM YYYY',
              )}
            </span>
          </p>
        </p>

        <div className="outline-container">
          <div className="basic-details-container">
            <div className="time-container">
              <img alt="timeicon" src={TimeIcon} />
              <span className="text-data">
                {assessment?.ac_maximum_duration_hours !== 0
                  ? `${assessment?.ac_maximum_duration_hours} h`
                  : '0 h'}{' '}
                {assessment?.ac_maximum_duration_minutes !== 0
                  ? `${assessment?.ac_maximum_duration_minutes} mns`
                  : '0 mns'}{' '}
              </span>
            </div>
            <div className="duedate-container">
              <img
                alt="duedate-img"
                src={DueDateIcon}
                className="duedate-img"
              />{' '}
              &nbsp;
              <span className="text-data">
                {moment(assessment.ac_due_date).format('D MMM YYYY')}
              </span>
            </div>
            <div className="assigned-student-container">
              <img alt="Selected Students" src={StudentIcon} />
              <span className="text-data">
                &nbsp; Assigned for{' '}
                {assignedStudentsCount === totalStudentsCount
                  ? 'all'
                  : assignedStudentsCount}{' '}
                {assignedStudentsCount === 1 ? 'student' : 'students'}
              </span>
            </div>
            <div className="location-container">
              <img alt="location-icon" src={LocationIcon} />
              <span className="text-data">{assessment?.ac_location}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="seperator" />
      <div className="basic-datail-footer">
        {' '}
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div
              className={classNames('card-header', {
                clicked: isResourcesExpanded,
              })}
              id="headingOne"
            >
              <h2 className="mb-0">
                <button
                  className="btn btn-block d-flex justify-content-between align-items-center"
                  type="button"
                  onClick={toggleResources}
                >
                  <div className="accordion-header">
                    <img
                      alt="instructions"
                      className="resource"
                      src={
                        isResourcesExpanded
                          ? ResourcesActiveIcon
                          : ResourcesIcon
                      }
                    />
                    Instructions and Resources
                  </div>
                  <div>
                    <img
                      alt="Show Works"
                      className="arrow"
                      src={isResourcesExpanded ? ArrowShowImg : ArrowHideImg}
                    />
                  </div>
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className={classNames('collapse', {
                show: isResourcesExpanded,
              })}
            >
              <div className="card-body">
                <div className="student-instruction">
                  <div className="instruction-header">Student Instructions</div>
                  <div className="instruction-content">
                    {assessment?.ac_student_instructions?.replace(
                      /(<([^>]+)>)/gi,
                      '',
                    ) || 'Nil'}
                  </div>
                </div>
                <div className="required-resources">
                  <div className="instruction-header">Resources Required</div>
                  <div className="instruction-content">
                    {assessment?.ac_resource_required || 'Nil'}
                  </div>
                </div>
                <div className="additional-resources">
                  <div className="instruction-header">
                    Additional Instruction
                  </div>
                  <div className="instruction-content">
                    {assessment?.ac_additional_information?.replace(
                      /(<([^>]+)>)/gi,
                      '',
                    ) || 'Nil'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicDetailsContainer>
  );
};
