import {createSlice} from '@reduxjs/toolkit';

import * as taskApi from '../api/admin/task';

const taskSlice = createSlice({
  initialState: {
    error: null,
    taskStatus: null,
    user: null,
    task: [],
    isListLoading: false,
  },
  name: 'task',
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    setTaskStatus: (state, action) => {
      state.taskStatus = action.payload;
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {setError, setIsListLoading, setTaskStatus, setTask, setUser} =
  taskSlice.actions;

export const fetchAllTaskStatus = () => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await taskApi.fetchAllStatus();
    dispatch(setTaskStatus(response?.data?.allTaskStatusData));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const fetchTaskWithAllTaskLogs = orgTasId => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await taskApi.fetchAllLogs(orgTasId);
    dispatch(setTask(response?.data?.allTaskLog));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const fetchAllUser = () => async dispatch => {
  dispatch(setIsListLoading(true));
  try {
    const response = await taskApi.fetchAllUser();
    dispatch(setUser(response?.data?.orgAllAdmin));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const taskSelector = state => state.task;

export default taskSlice.reducer;
