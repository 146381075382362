/* eslint-disable react/prop-types */

import {useEffect, useMemo, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
  EmptyState,
  MoreOptions,
  Table,
  InvitationStatus,
  Modal,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {
  fetchAllStudents,
  studentsSelector,
  deleteStudent,
  resendStudentInvitation,
} from '../../../../../../redux/studentSlice';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {EditStudentModal} from './components/EditStudentModal';
import {StudentContainer} from './elements';
import {showToast} from '../../../../../../components/common/Toast';
import ViewClassImg from '../../../../../../assets/icons/viewclass.svg';
import {TableCellLink} from '../../../../../../components/common/Table/styles';
import InfoIcon from '../../../../../../assets/icons/info.svg';

const DELAY = 500;

export const Student = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {students, isListLoading, isStudentLoading} =
    useSelector(studentsSelector);
  const [editDetails, setEditDetails] = useState(null);
  const {state} = useLocation();
  const searchEmailId = state?.emailId;

  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');

  const [isSearch, setIsSearch] = useState('');
  useEffect(() => {
    if (searchEmailId) {
      setIsSearch(searchEmailId);
    }
  }, [searchEmailId]);

  useEffect(() => {
    dispatch(fetchAllStudents());
  }, [dispatch]);

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'serial',
        className: 'serial',
      },
      {
        Header: 'ID',
        accessor: 'up_org_reference',
        className: 'ref-id',
      },
      {
        Header: 'First Name',
        accessor: 'up_name_first',
        className: 'first-name',
      },
      {
        Header: 'Last Name',
        accessor: 'up_name_last',
        className: 'last-name',
      },
      {
        Header: 'Email',
        accessor: 'up_email',
        className: 'email',
      },
      {
        Header: 'Status',
        Cell: ({row: {original}, value}) => (
          <InvitationStatus
            value={value}
            time={original?.up_timestamp_creation}
          />
        ),
        accessor: 'up_invitation_status',
        className: 'status',
      },
      {
        Header: '',
        Cell: ({row: {original}}) => (
          <div className="view-profile-div">
            {original?.up_invitation_status === 1 ? (
              <TableCellLink
                onClick={() => {
                  history.push(`/portfolio/${original?.us_profile_name}`);
                }}
                type="button"
                className="view-profile"
              >
                <span>View Profile</span>
                <img src={ViewClassImg} alt="loader" className="view-arrow" />
              </TableCellLink>
            ) : (
              <TableCellLink
                // onClick={() => {
                //   history.push(`/portfolio/${original?.us_profile_name}`);
                // }}
                type="button"
                className="view-profile"
              >
                <div
                  className="invited"
                  data-html
                  data-for="tooltip"
                  data-tip={`<div class="tooltip-values">Profile cannot be viewed : User not in active status </div>`}
                >
                  <span>No Profile</span>
                  <img
                    alt="Failed"
                    className="ml-2 status-icon"
                    src={InfoIcon}
                  />
                  <ReactTooltip
                    backgroundColor="#3d4457"
                    effect="solid"
                    place="left"
                    id="tooltip"
                  />
                </div>
              </TableCellLink>
            )}
          </div>
        ),
        accessor: 'up_profile_name',
        className: 'view',
      },
      {
        Header: '',
        Cell: ({row: {original}, value}) => (
          <div className="align-items-center d-flex justify-content-center w-100">
            <MoreOptions>
              <DropdownItem
                label="Edit"
                onClick={() => {
                  setEditDetails({
                    studentId: value,
                    firstName: original?.up_name_first,
                    lastName: original?.up_name_last,
                    email: original?.up_email,
                    referId: original?.up_org_reference,
                  });
                }}
              />
              {original?.up_invitation_status === 1 ? null : (
                <DropdownItem
                  label="Resend Invitation"
                  onClick={() => {
                    dispatch(
                      resendStudentInvitation(value, {
                        firstName: original?.up_name_first,
                        lastName: original?.up_name_last,
                        email: original?.up_email,
                        referId: original?.up_org_reference,
                      }),
                    );
                  }}
                />
              )}
              <DropdownItem
                isDanger
                label="Delete"
                isDisabled={original?.units?.length}
                toolTipHeading="Student Cannot be deleted"
                toolTipSubText="Student is assigned in one or more class"
                onClick={() => {
                  setDeleteId(value);
                  setDeleteName(
                    `${original?.up_name_first} ${original?.up_name_last}`,
                  );
                }}
              />
            </MoreOptions>
          </div>
        ),
        accessor: 'up_id_userprofile',
        className: 'more-option',
      },
    ],
    [dispatch, history],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(students || []).map((item, index) => ({
        ...item,
        serial: index + 1,
      })),
    ],
    [students],
  );

  return (
    <StudentContainer>
      <ListingPageHeader
        buttonLabel="Add Student"
        className="mb-4"
        btnId="addStudentBtn"
        onButtonClick={() => {
          history.push('/admin/users/student/addStudent');
        }}
        title="All Students"
      />
      <div className="table-container">
        {isListLoading ? <TableSpinner /> : null}
        {!isListLoading && Array.isArray(students) && !students?.length ? (
          <EmptyState
            body="It seems that there is no students has been added to the organisation"
            title="No students are added yet!"
          />
        ) : null}
        {!isListLoading && students?.length ? (
          <Table
            columns={columns}
            data={data}
            itemName={students?.length === 1 ? 'Student' : 'Students'}
            isSortedBy
            maxWidth="75rem"
            autoSearch={isSearch}
          />
        ) : null}
      </div>

      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this user ?"
        isDelete
        isButtonLoading={isStudentLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          if (!isStudentLoading) {
            dispatch(
              deleteStudent(deleteId, () => {
                setDeleteId(null);
                showToast(
                  false,
                  `${deleteName} has been deleted successfully`,
                  true,
                );

                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }),
            );
          }
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />

      <EditStudentModal editDetails={editDetails} onHide={clearEditDetails} />
    </StudentContainer>
  );
};
