import styled from 'styled-components';

import {Modal} from '../../../../../../../../../components/common';

const DuplicateTasModalContainer = styled(Modal)`
  .divider {
    height: 0.03125rem;
    background: #e0e0e0;
  }
  .form-main-container {
    margin-top: 1.25rem;
  }
  .css-t385hn-MenuList {
    max-height: 15.75rem !important;
  }
  .error {
    color: ${({theme}) => theme.colors.danger};
    font-size: 0.875rem;
    left: 0;
  }
`;

export default DuplicateTasModalContainer;
