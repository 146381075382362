import styled from 'styled-components';

export const LinkedTaskContainer = styled.div`
  border: 0.0625rem solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-top: 1rem;
  .details {
    padding: 1rem;
  }
  .title {
    font-weight: 500;
    font-size: 1rem;
    color: #0c181f;
  }
  .description {
    font-weight: 400;
    font-size: 0.875rem;
    color: #6d7579;
    margin-top: 0.5rem;
  }
  .remove {
    background-color: unset;
    border: unset;
    outline: unset;
    img {
      margin-right: unset;
    }
  }
  .badge-time {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.25rem;
    p {
      background: #e0eeff;
      border-radius: 4px;
      color: #5ba1f2;
      font-weight: 500;
      font-size: 12px;
      padding: 0.12rem 0.5rem;
      margin-bottom: unset;
    }
  }
`;
