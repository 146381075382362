/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FilePreviewContainer from './elements';
import closeModal from '../../../../../../assets/icons/close-modal.svg';

import ImageIcon from '../../../../../../assets/images/filetypes/img.svg';
import DocIcon from '../../../../../../assets/images/filetypes/doc.svg';
import CsvIcon from '../../../../../../assets/images/filetypes/csv.svg';
import FileIcon from '../../../../../../assets/icons/document-instruction.svg';

// This for previewing files based on type of files
export const FilePreview = ({
  onClose,
  previewFileData,
  fileUrl,
  title,
  unitAndClassName,
  fileType,
}) => {
  const fileExt = previewFileData?.fi_filename_original?.substring(
    previewFileData?.fi_filename_original?.lastIndexOf('.') + 1,
  );

  const renderPreview = useCallback(() => {
    const renderIcon = ext => {
      if (ext === 'svg') {
        return ImageIcon;
      }
      if (ext === 'csv') {
        return CsvIcon;
      }
      if (ext === 'doc' || ext === 'docx') {
        return DocIcon;
      }
      return FileIcon;
    };
    if (fileType?.includes('Image')) {
      return <img src={fileUrl} alt="cloud" className="modal-content-body" />;
    }
    if (fileType?.includes('Video')) {
      return (
        <div className="videos">
          <video controls src={fileUrl} height="100%" width="100%">
            <track default kind="captions" srcLang="en" src={fileUrl} />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      );
    }
    if (fileType?.includes('Voice')) {
      return (
        <div className="audios" src={previewFileData?.fi_location} width="100%">
          <audio controls>
            <source
              src={previewFileData?.fi_location}
              type="audio/mpeg"
              className="source-audio"
            />
          </audio>
        </div>
      );
    }

    return (
      <div style={{padding: '1.5rem 0 0 0'}} className="files">
        {fileExt === 'pdf' ? (
          <div style={{height: '100%'}}>
            <embed
              src={`${previewFileData?.fi_location}?view=Fit`}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          </div>
        ) : (
          <div className="file-thumbnail">
            <img
              src={previewFileData?.fi_thumbnail || renderIcon(fileExt)}
              alt=""
              className="preview-Icon modal-content-body"
            />
          </div>
        )}
      </div>
    );
  }, [
    fileExt,
    fileType,
    fileUrl,
    previewFileData?.fi_location,
    previewFileData?.fi_thumbnail,
  ]);
  return (
    <>
      <FilePreviewContainer>
        <div className="preview-header">
          <div className="attachment-file-info">
            {/* <img src={ProfileImg} alt="profile-img" className="mr-2" /> */}
            <div className="attachment-file-details">
              <div className="title">
                <span>{title}</span>
              </div>
              <div className="unit-class-name">
                <span>{unitAndClassName}</span>
              </div>
            </div>
          </div>
          <div className="button-container">
            {/* {fileExt !== 'pdf' && fileType.includes('Document') ? (
              <>
                <a
                  href={fileUrl}
                  className="closeModalBtn"
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={CloudDownloadIcon} alt="close" className="cross" />
                </a>

                <div className="button-seperator" />
              </>
            ) : null} */}
            <button onClick={onClose} type="button" className="closeModalBtn">
              <img src={closeModal} alt="close" className="cross" />
            </button>
          </div>
        </div>
        {fileType?.includes('Video') || fileType?.includes('Image') ? (
          <div className="seperator" />
        ) : null}
        <div
          className={classNames('previewContainer', {
            files: !fileType?.includes('Video') && !fileType?.includes('Image'),
          })}
        >
          {renderPreview()}
        </div>
      </FilePreviewContainer>
    </>
  );
};

FilePreview.defaultProps = {
  fileType: '',
  fileUrl: '',
};

FilePreview.propTypes = {
  fileType: PropTypes.string,
  fileUrl: PropTypes.string,
};
