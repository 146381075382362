import styled from 'styled-components';
import {AdminListingPageContainer} from '../../../../../elements-new';

export const UnitsContainer = styled(AdminListingPageContainer)`
  padding: 2rem 3rem 3rem 3rem;

  @media (max-width: 55.5rem) {
    & {
      padding: 2rem 1.5rem 3rem 1.5rem;
    }
  }
  .check-box {
    width: 5%;
  }
  .unit-code {
    width: 10%;
  }

  .unit-name {
    width: 35%;
  }

  .essential {
    width: 15%;
  }
  .teachers {
    width: 35%;
  }

  .core,
  .elective {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0.312rem;
  }
  .core {
    color: #54abff;
    background: #ecf6ff;
  }
  .elective {
    background: #defae8;
    color: #4ec977;
  }
  .teacher,
  .more {
    font-weight: normal;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    color: #3d4457;
    text-align: center;
    border-radius: 0.25rem;
    background: #ecf6ff;
  }
  .teacher {
    margin-right: 0.5rem;
  }
  .type-container {
    display: flex;
    flex-direction: row;
  }
  .teachers-container {
    display: flex;
    flex-direction: row;
  }
  .table-container {
    padding-top: 0.9375rem;
  }
  table {
    margin-top: 0 !important;
    tr {
      :hover {
        background-color: unset;
      }
    }
  }
  .checkbox {
    width: 1.31rem;
    height: 1.31rem;
    outline: unset;
    padding: 0;
    background-color: unset;
    border-radius: 0.25rem;
    border: 1px solid #e0e0e0;
    img {
      vertical-align: unset;
    }
  }
  .search-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0.81rem 0px 0px 0px;
    .buttom {
      margin-right: 2.5rem;
      border: none;
      background-color: unset;
      outline: none;
      color: #bcbcbc;
      &.link {
        border-bottom: 1px solid black;
        color: #3d4457;
      }
    }
  }
  @media (max-width: 75rem) {
    .type-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .teachers-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
    .table-container {
      table {
        td {
          :last-child,
          :first-child {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .class-name {
    font-weight: 500;
    font-size: 1.25rem;
    color: #3d4457;
  }
  .qualification-name {
    font-weight: normal;
    font-size: 0.875rem;
    color: #8a8c94;
  }
  .unit-list {
    font-weight: 500;
    font-size: 0.875rem;
    color: #3d4457;
    text-transform: capitalize;
  }
`;
