import styled from 'styled-components';

export const FoundationSkillsContainer = styled.div`
  .title-container {
    background-color: #ffffff;
    display: flex;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #3d4457;
    padding: 1.5rem;
    margin-bottom: 0.125rem;
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    @media (max-width: 31.25rem) {
      & {
        display: none;
      }
    }
  }
  .serial {
    width: 2%;
    font-size: 0.875rem;
    color: #3d4457;
  }
  .title {
    width: 48%;
  }
  .observable-task {
    width: 25%;
  }
  .written-task {
    width: 25%;
  }
  .column-header {
    display: none;
  }
  .serial-number-hash {
    display: none;
  }
  .content {
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 0.0625rem 0.0625rem 0.25rem rgba(0, 0, 0, 0.04);
    @media (max-width: 31.25rem) {
      & {
        border-radius: 0.75rem;
      }
    }
    .title {
      font-size: 0.875rem;
    }
    .observable-task {
      width: 25%;
      font-weight: normal;
      font-size: 0.875rem;
      color: #aaaaaa;
      text-transform: initial;
    }
    .written-task {
      width: 25%;
      font-weight: normal;
      font-size: 0.875rem;
      color: #aaaaaa;
      text-transform: initial;
    }
  }
  .top {
    display: flex;
    @media (max-width: 31.25rem) {
      & {
        flex-direction: column;
      }
      .column-header {
        display: block;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #aaaaaa;
        margin-bottom: 0.5rem;
      }
      .serial-number-hash {
        display: block;
        margin-right: 0.3125rem;
        font-size: 0.875rem;
        color: #aaaaaa;
      }
      .serial {
        padding: 1.25rem 0;
        background-color: #f4f5f8;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 0.0625rem solid #c9c9c9;
        border-bottom: 0.0625rem solid #c9c9c9;
      }
      .title {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        border-bottom: 0.0625rem solid #c9c9c9;
      }
      .observable-task {
        width: 100%;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        border-bottom: 0.0625rem solid #c9c9c9;
      }
      .written-task {
        width: 100%;
        padding: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .foundation-skills-content {
    border-bottom: 0.0625rem solid #dedede;
    @media (max-width: 31.25rem) {
      & {
        border: unset;
        padding-bottom: unset;
      }
      ul {
        margin-bottom: unset;
      }
    }
    :last-child {
      border-bottom: unset;
      padding-bottom: unset;
    }
    :first-child {
      padding-top: unset;
    }
    padding-bottom: 1.125rem;
    padding-top: 1rem;
    text-transform: capitalize;
  }
  .ul-list {
    padding-left: 3.5rem;
    font-size: 0.875rem;
    li {
      line-height: 1.5rem;
    }

    padding-top: 0.5rem;
  }
`;

export default FoundationSkillsContainer;
