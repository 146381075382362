import styled, {css} from 'styled-components';

export const PortFolioFilesCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width:calc(100% - 1.875rem);
  position: absolute;
  left:0.9375rem;
  top:0;
  height:100%;

  :not(:last-child){
    padding-right: 0.5rem;
  }
  .card-content-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;

    padding: 0.5rem 0.5rem 0 0.5rem;
    }
  .card-detail-top {
      width: 100% !important;
      display: flex;
      flex-direction:column;
      margin-bottom:1rem;
      .card-left {
        width: 92%;
        font-weight: 500;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color:#1D1D1D;
      }
      .class-name-div{
        width:100%;
      }
      .class-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.75rem;
        color: #81838E;
        max-width:90%;
        margin-top:0.375rem;
      }
      
      }
  .card-content {
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;
    width: 98%;
    text-align: left;
    padding: 0 0.5rem 0.6rem 0.5rem;
    }
  
    .card-detail-bottom {
      font-weight: normal;
      font-size: 0.75rem;
      margin-top: 0.125rem;
      color: #9EA3A5;
      text-transform:capitalize;
      display: flex;
    }
  }
`;

export const FileContainer = styled.button`
  outline: none;

  border: unset;
  margin-right: 0.5rem;
  position: relative;
  background: white;
  box-sizing: border-box;
  border-radius: 0.4375rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({height}) =>
    height
      ? css`
          height: ${height};
        `
      : '8.75rem'};
  .file-content {
    width: 100%;
    outline: none;
    height: 100%;
    border: none;
    .file-container {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .files {
      object-fit: contain;
    }
  }
  .file-blob {
    border-radius: 0.3125rem;
    display: block;
    height: 4.62rem;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;

    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover !important;
    }
    .play-video {
      width: 3rem;
      height: 3rem;
    }
  }

  .badge {
    position: absolute;
    background: #ffffff;
    right: 0.625rem;
    top: 0.625rem;
    text-align: center;
    height: 1.375rem;
    display: flex;
    align-items: center;
    border-radius: 0.2rem;
    span {
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      color: #3d464c;
      padding: 0.25rem 0.5rem;
    }
  }
  .date {
    font-weight: 400;
    font-size: 0.75rem;
    color: #81838e;
  }
`;
