import styled from 'styled-components';

const TimeIntervalContainer = styled.div`
  position: relative;
  height: ${({isLast}) => (isLast ? 'auto' : '8.875rem')};

  .time {
    color: #9ea3a5;
    font-size: 0.75rem;
    line-height: 1.1875rem;
    text-align: right;
    width: 2.28rem !important;
    margin-right: 1.1875rem;
    font-weight: 600;
  }

  .time-indicator {
    border-bottom: 1px solid #e6e7e8;
  }

  .half-time-indicator {
    border-bottom: 1px dashed #e6e7e8;
    position: absolute;
    top: 4.4375rem;
    right: 0;
    width: calc(100% - 1.9375rem);
    display: ${({isLast}) => (isLast ? 'none' : null)};
  }
`;

export default TimeIntervalContainer;
