import styled from 'styled-components';

export const ProgressContainer = styled.div`
  .progress-div {
    p {
      font-size: 0.875rem;
      color: #3d4457;
      margin-right: 0.625rem;
      margin-bottom: 1rem;
    }
  }
  .progress-bar {
    width: 19.87rem;
    height: 0.5rem;
    background-color: #dedede;
    border-radius: 0.5rem;
    .progress-loader {
      width: 2%;
      height: 100%;
      background-color: #40a1ff;
      &.progress-loader-1 {
        width: 25%;
      }
      &.progress-loader-2 {
        width: 50%;
      }
      &.progress-loader-3 {
        width: 75%;
      }
      &.progress-loader-4 {
        width: 100%;
      }
    }
  }
  @media (max-width: 41.25rem) {
    .progress-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.75rem !important;
      p {
        font-size: 0.875rem;
        color: #3d4457;
        margin-right: 0.625rem;
        margin-bottom: unset !important;
      }
      .progress-bar {
        width: 15.93rem;
      }
    }
  }
`;
