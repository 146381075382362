import styled from 'styled-components';

export const FormContainer = styled.div`
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .save-changes {
      border: 0.0625rem solid #40a1ff;
      border-radius: 0.5rem;
      img {
        vertical-align: middle;
      }
      .add-button-text {
        color: #40a1ff;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        margin-top: 0.1rem;
      }
    }
    .cancel {
      margin-right: 1rem;
      border: 0.0625rem solid transparent;
      border-radius: 0.5rem;
      align-items: flex-end;
      .cancel-button-text {
        color: #3d4457;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }
  .form-fields {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 1.5rem;
    .fields,
    .field-last {
      width: 100%;
    }
    .fields {
      margin-right: 1.5rem;
    }
  }
  .user-type-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    p {
      margin-bottom: unset;
    }
  }
  .user-type {
    font-weight: normal;
    font-size: 0.875rem;
    color: #a5a5a5;
  }
  .exclaim-icon {
    margin-left: 0.3125rem;
  }
  .user-type-instruction {
    font-weight: normal;
    font-size: 0.75rem;
    color: #a5a5a5;
    margin-left: 0.3125rem;
    margin-top: 0.125rem;
  }
  @media (max-width: 55.5rem) {
    .form-fields {
      display: flex;
      flex-direction: column;
      padding-bottom: unset;
      .fields,
      .field-last {
        margin-bottom: 1.5rem;
      }
    }
  }
`;

export const ManualTableStyles = styled.div`
  background-color: #fff;
  box-shadow: unset;
  padding: 1.5rem 0;
  width: 100%;
  border-bottom: 0.0625rem solid #e0e0e0;

  table {
    margin: 1rem 0 1.5rem 0;
    width: 100%;

    .serial {
      width: 5%;
    }

    .ref-id-manual {
      width: 10%;
    }

    .first-name-manual {
      width: 21%;
    }
    .last-name-manual {
      width: 21%;
    }

    .email-manual {
      width: 40%;
    }
    .close-row {
      width: 8%;
    }

    .closeIconBtn {
      display: block;
      border: none;
      background: transparent;
      outline: none;
    }
  }
`;
