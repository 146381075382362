import {useHistory} from 'react-router-dom';

import {Button} from '../../../../../../../../components/common';
import {AddSessionFooterContainer} from './elements';

// eslint-disable-next-line react/prop-types
export const AddSessionFooter = ({isLoading, onSubmit}) => {
  const history = useHistory();

  const onCancel = () => {
    history.replace(`/admin/sessions`);
  };

  return (
    <AddSessionFooterContainer>
      <div className="footer-container fixed-bottom">
        <div className="footer">
          <div className="button-container">
            <Button
              className="button cancel-button mr-3"
              isFullWidth={false}
              onClick={onCancel}
              label="Cancel"
              id="cancel-btn-id"
            />
            <Button
              className="button"
              isFullWidth={false}
              isLoading={isLoading}
              label="Save"
              onClick={onSubmit}
              id="save-btn-id"
            />
          </div>
        </div>
      </div>
    </AddSessionFooterContainer>
  );
};
