/* eslint-disable react/prop-types */

import React, {useEffect, useState} from 'react';

import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {
  getAssessmentDetails,
  clearCreatedAssessment,
  studentWebSelector,
} from '../../../../redux/studentWebSlice';

import {AssessmentViewContainer} from './elements';

import {
  AssessmentDetails,
  AssessmentEvaluation,
  AssessmentViewFooter,
  AssessmentViewHeader,
} from './components';

export const AssessmentView = () => {
  const dispatch = useDispatch();

  const {createdAssessment, isLoadingForAction} =
    useSelector(studentWebSelector);

  const history = useHistory();
  const {id: assessmentID} = useParams();

  const [classID, setClassID] = useState(null);

  const {pathname} = history.location;

  useEffect(() => {
    if (/stream/.test(pathname)) {
      const idOfClass = pathname.split('/')[2];
      setClassID(idOfClass);
      dispatch(getAssessmentDetails(assessmentID));
    }

    return () => dispatch(clearCreatedAssessment());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AssessmentViewContainer>
      {createdAssessment?.instance ? (
        <AssessmentViewHeader
          assessment={createdAssessment}
          classID={classID}
        />
      ) : null}
      {createdAssessment?.aouis_status !== 3 ? (
        <div>
          <Helmet>
            <title>Assessment Details | JungleCat</title>
          </Helmet>
          <AssessmentDetails
            assessment={createdAssessment}
            assessmentStatus={createdAssessment?.aouis_status}
          />
        </div>
      ) : null}
      {createdAssessment?.aouis_status === 3 ? (
        <div>
          <Helmet>
            <title>Assessment Details | JungleCat</title>
          </Helmet>
          <AssessmentEvaluation
            assessment={createdAssessment}
            assessmentStatus={createdAssessment?.aouis_status}
          />
        </div>
      ) : null}

      {createdAssessment?.aouis_status !== 3 ? (
        <AssessmentViewFooter
          assessmentID={assessmentID}
          classID={classID}
          tasks={createdAssessment?.tasks}
          assessment={createdAssessment}
          isLoadingForAction={isLoadingForAction}
        />
      ) : null}
    </AssessmentViewContainer>
  );
};
