/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React, {useCallback, useRef} from 'react';
// import moment from 'moment';

import {FilePreviewContainer} from './elements';

import ImageIcon from '../../../../../../../../../../../../../../assets/images/filetypes/img.svg';
import DocIcon from '../../../../../../../../../../../../../../assets/images/filetypes/doc.svg';
import CsvIcon from '../../../../../../../../../../../../../../assets/images/filetypes/csv.svg';
import FileIcon from '../../../../../../../../../../../../../../assets/icons/document-instruction.svg';
import imageAudio from '../../../../../../../../../../../../../../assets/images/filetypes/audio-modal-image.png';

export const FilePreview = ({curriculumFile}) => {
  const videoRef = useRef(null);

  const renderPreview = useCallback(() => {
    const fileExt = curriculumFile?.fi_filename?.substring(
      curriculumFile?.fi_filename?.lastIndexOf('.') + 1,
    );
    const renderIcon = ext => {
      if (ext === 'svg') {
        return ImageIcon;
      }
      if (ext === 'csv') {
        return CsvIcon;
      }
      if (ext === 'doc' || ext === 'docx') {
        return DocIcon;
      }
      return FileIcon;
    };
    if (curriculumFile?.ft_type?.includes('Image')) {
      return (
        <img
          src={curriculumFile?.fi_location}
          alt="cloud"
          className="image-file"
        />
      );
    }
    if (curriculumFile?.ft_type?.includes('Video')) {
      return (
        <div className="videos">
          <video
            controls
            src={curriculumFile?.fi_location}
            width="100%"
            height="100%"
            ref={videoRef}
          >
            <track
              default
              kind="captions"
              srcLang="en"
              src={curriculumFile?.fi_location}
            />
            Sorry, your browser does not support embedded videos.
          </video>
        </div>
      );
    }
    if (curriculumFile?.ft_type?.includes('Voice')) {
      return (
        <div className="audios" src={curriculumFile?.fi_location} width="100%">
          <img
            src={imageAudio}
            alt="audio icon"
            style={{
              height: '200px',
              width: '200px',
              marginTop: '80px',
            }}
          />
          <audio controls>
            <source
              src={curriculumFile?.fi_location}
              type="audio/mpeg"
              className="source-audio"
            />
          </audio>
        </div>
      );
    }

    if (curriculumFile?.ft_type?.includes('Document')) {
      return (
        <div className="file-data">
          {fileExt === 'pdf' ? (
            <div style={{height: '500px', width: '100%'}}>
              <embed
                src={curriculumFile?.fi_location}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <img
              src={curriculumFile?.fi_thumbnail || renderIcon(fileExt)}
              alt=""
              className="preview-Icon"
            />
          )}
        </div>
      );
    }
    return FileIcon;
  }, [
    curriculumFile?.fi_filename,
    curriculumFile?.ft_type,
    curriculumFile?.fi_location,
    curriculumFile?.fi_thumbnail,
  ]);

  return (
    <FilePreviewContainer>
      <div
        className="container-preview"
        style={
          curriculumFile?.ft_type?.includes('Image')
            ? {
                height: '550px',
                backgroundColor: '#535659',
                display: 'flex',
                alignItems: 'center',
              }
            : {}
        }
      >
        {renderPreview()}
      </div>
    </FilePreviewContainer>
  );
};
