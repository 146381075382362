import styled from 'styled-components';

export const UploadContainer = styled.div`
  .upload-title {
    font-size: 0.875rem;
    font-weight: normal;
    margin-bottom: 1rem;
  }
  .org-image-full {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    align-items: center;
    border: 0.0625rem solid #dedede;
    padding: 0.75rem 0.625rem;
    justify-content: space-between;
    border-radius: 0.3125rem;
    .org-image-after {
      display: flex;
      flex-direction: row;
    }
    .delete-logo {
      margin-right: 0.625rem;
      button {
        border: none;
        background-color: transparent;
      }
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .button {
    background-color: unset;
    border: unset;
    outline: unset;
    padding: unset;
    display: flex;
    align-items: center;
  }
  .org-image-added {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2.5rem 2.5rem;
    background-color: #ffffff;
    border: 0.0625rem dashed #dedede;
    box-sizing: border-box;
    border-radius: 0.3125rem;
    .org-text {
      padding-top: 1.25rem;
      text-align: center;
      position: relative;
      overflow: hidden;
      font-weight: 500;
      font-size: 0.875rem;
      color: #3d4457;
      text-transform: uppercase;
      width: 10.75rem;
      overflow-wrap: break-word;
    }

    .org-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.875rem;
      background: #f3f3f3;
      border-radius: 0.5rem;
      width: 13rem;
    }
    .org-logo-div {
      display: flex;
      flex-direction: column;
      .delete-logo {
        align-self: flex-end;
        position: absolute;
        background-color: #c9c9c9;
        padding: 0.125rem;
        border-radius: 0.125rem;
        margin: -10px -10px 0 0;
      }
    }
  }
  .org-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 1.5rem;
    background: #ffffff;
    border: 0.0625rem dashed #dedede;
    box-sizing: border-box;
    border-radius: 0.3125rem;

    &.logo-drag {
      background: #f7fbff;
      border-color: #40a1ff;
    }
    .org-text {
      padding: 0.75rem 0 3rem 0;
      text-align: center;
      position: relative;
      overflow: hidden;
    }

    .org-logo {
      padding: 3rem 0 0 0;
    }
    .org-seperator {
      margin-top: 2.25rem;
      width: 18.75rem;
      height: 0.0625rem;
      background-color: #eaeaea;
      margin-bottom: 0.625rem;
      @media (max-width: 31.25rem) {
        & {
          width: 100%;
        }
      }
    }

    .org-text input {
      position: absolute;
      color: black;
      top: 0;
      right: 0;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      font-size: 18.75rem;
      height: 12.5rem;
    }
    span {
      color: #40a1ff;
    }
  }
`;
