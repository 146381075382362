/* eslint-disable react/prop-types */
import React from 'react';
import {useSelector} from 'react-redux';
import {unitsSelector} from '../../../../../../../../../../../redux/unitsSlice';
import {AddedTaskSection} from './components';

export const AddedTask = ({
  isPublished,
  removeTask,
  control,
  getValues,
  setValue,
  register,
  isArchive,
  clearErrors,
  setError,
  setOpenAddTask,
}) => {
  const {createdTasks, assessmentTasksLoading} = useSelector(unitsSelector);

  return (
    <>
      <AddedTaskSection
        createdTasks={createdTasks}
        isLoadingAssessment={assessmentTasksLoading}
        isPublished={isPublished}
        removeTask={removeTask}
        control={control}
        getValues={getValues}
        setValue={setValue}
        register={register}
        isArchive={isArchive}
        clearErrors={clearErrors}
        setError={setError}
        setOpenAddTask={setOpenAddTask}
      />
    </>
  );
};
