/* eslint-disable react/prop-types */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, Route, Switch, useHistory, useParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import backIcon from '../../../../../assets/icons/arrow_left.svg';
import {
  clearCreatedAssessment,
  getAssessmentDetails,
  getAssessmentSummary,
  evaluateActivity,
  deleteAssessment,
} from '../Assessment/slices/assessmentSlice';
import {deleteAssessmentFromPosts} from '../../../streamSlice';
import {
  AssessmentViewContainer,
  AssessmentViewHeaderContainer,
} from './elements';
// import AssessmentDetails from './AssessmentDetails';
// import StudentsDetails from './StudentsDetails';
// import {
//   ReassignModal,
//   ResubmitAssessmentModal,
//   RevokeStudentModal,
//   SendReminderModal,
// } from './StudentDetailsModals';

import {TaskEvaluation} from '../../TaskEvaluation';
// import DropdownWrapper from '../../../../../components/shared/DropdownWrapper/DropdownWrapper';
import closeIcon from '../../../../../assets/icons/cross-square.svg';
// import optionsIcon from '../../../../../assets/icons/ellipsis.svg';
import rem from '../../../../../utils/rem';

import {
  AssessmentDetails,
  StudentsDetails,
  AssessmentSummary,
  AssessmentViewFooter,
} from './components';

import {
  ReassignModal,
  ResubmitAssessmentModal,
  RevokeStudentModal,
  SendReminderModal,
} from './components/StudentsDetailsModals';
import {MoreOptions} from '../../../../../components/common';
import {DropdownItem} from '../../../../../components/common/MoreOptions/components';
import {DeleteModal} from '../../../components';
import {showToast} from '../../../../../components/common/Toast';
// import {authSelector} from '../../../../../redux/authSlice';

const DELAY = 500;
const AssessmentView = () => {
  const dispatch = useDispatch();

  const {
    createdAssessment,
    assessmentSummary,
    assessmentTasks,
    assessmentTasksLoading,
    assessmentTasksError,
  } = useSelector(state => state.assessment);

  const {
    control,
    // handleSubmit,
    // getValues,
    // formState: {errors},
  } = useForm();
  //   {
  //   defaultValues: {
  //     feedback: '',
  //     minutes: '',
  //     hours: '',
  //   },
  //   resolver: yupResolver(assessmentSubmissionSchema),
  // }

  const history = useHistory();
  const {id: assessmentID, studentID, taskID} = useParams();

  const userType = useRef(localStorage.getItem('jc-user-type')).current;
  const [classID, setClassID] = useState(null);
  const [comments, setComments] = useState('');
  const [currentResubmissionNote, setCurrentResubmissionNote] = useState('');
  const [currentResubmissionTime, setCurrentResubmissionTime] = useState('');
  const [currentStudentName, setCurrentStudentName] = useState('');
  // const [headerHeight, setHeaderHeight] = useState(0);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [reassignModalIsVisible, setReassignModalIsVisible] = useState(false);
  const [reassignCallback, setReassignCallback] = useState(null);
  const [remindModalIsVisible, setRemindModalIsVisible] = useState(false);
  const [remindCallback, setRemindCallback] = useState(null);
  const [revokeModalIsVisible, setRevokeModalIsVisible] = useState(false);
  const [revokeCallback, setRevokeCallback] = useState(null);
  const [resubmitModalIsVisible, setResubmitModalIsVisible] = useState(false);
  const [resubmitCallback, setResubmitCallback] = useState(null);
  const [task, setTask] = useState(null);
  const [feedBack, setFeedBack] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState(null);
  // const authState = useSelector(authSelector);
  // const currentUserProfileId =
  //   authState?.user?.userProfiles?.length > 1
  //     ? authState?.user?.userProfiles[1]?.up_id_userprofile
  //     : authState?.user?.userProfiles[0]?.up_id_userprofile;

  const {pathname} = history.location;
  const isTaskEvaluationPage =
    /stream\/[\d\w-]+\/assessment\/\d+\/students\/[\d\w-]+\/task\/\d+/.test(
      pathname,
    );
  const classId = pathname.split('/')[2];
  useEffect(() => {
    setTask(
      assessmentTasks?.find(
        item => item.ai_id_activityitem.toString() === taskID,
      ),
    );
  }, [assessmentTasks, taskID]);
  useEffect(() => {
    if (/stream/.test(pathname)) {
      const idOfClass = pathname.split('/')[2];
      setClassID(idOfClass);
      dispatch(getAssessmentDetails(assessmentID, idOfClass));
    }

    return () => dispatch(clearCreatedAssessment());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getAssessmentSummary(assessmentID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendReminder = (callback, name) => {
    setRemindModalIsVisible(true);
    setRemindCallback(() => callback);
    setCurrentStudentName(name);
  };

  const onSendReminderClose = () => {
    setRemindModalIsVisible(false);
    setRemindCallback(null);
    setCurrentStudentName('');
  };

  const onReassign = (callback, name) => {
    setReassignModalIsVisible(true);
    setReassignCallback(() => callback);
    setCurrentStudentName(name);
  };

  const onReassignClose = () => {
    setReassignModalIsVisible(false);
    setReassignCallback(null);
    setCurrentStudentName('');
  };

  const onRevokeStudent = (callback, name) => {
    setRevokeModalIsVisible(true);
    setRevokeCallback(() => callback);
    setCurrentStudentName(name);
  };

  const onRevokeClose = () => {
    setRevokeModalIsVisible(false);
    setRevokeCallback(null);
    setCurrentStudentName('');
  };

  const onResubmitAssessment = useCallback((callback, name, note, time) => {
    setResubmitModalIsVisible(true);
    setResubmitCallback(() => callback);
    setCurrentStudentName(name);
    setCurrentResubmissionNote(note);
    setCurrentResubmissionTime(time);
  }, []);

  const onResubmitClose = () => {
    setResubmitModalIsVisible(false);
    setResubmitCallback(null);
    setCurrentStudentName('');
    setCurrentResubmissionNote('');
    setCurrentResubmissionTime('');
  };

  const evaluateAssessment = event => {
    event.preventDefault();
    dispatch(
      evaluateActivity(
        assessmentID,
        createdAssessment.aoui_id_activityorgunitinstance,
        classID,
        studentID,
        {
          aouis_notes: comments,
        },
      ),
    );
    setComments('');
    setModalIsShown(false);
    history.goBack();
  };

  if (isTaskEvaluationPage && !task) {
    return null;
  }
  return (
    <AssessmentViewContainer
      fixedHeight={/(stream\/[\d\w-]+\/assessment\/\d+\/students\/[\d\w-]+)$/.test(
        pathname,
      )}
      flexEnd={/(stream\/[\d\w-]+\/assessment\/\d+\/students\/[\d\w-]+)$/.test(
        pathname,
      )}
    >
      <ReassignModal
        onClose={onReassignClose}
        reassignCallback={reassignCallback}
        studentName={currentStudentName}
        isVisible={
          reassignModalIsVisible && reassignCallback && currentStudentName
        }
      />

      {remindModalIsVisible && remindCallback && currentStudentName ? (
        <SendReminderModal
          onClose={onSendReminderClose}
          remindCallback={remindCallback}
          studentName={currentStudentName}
          isVisible={
            remindModalIsVisible && remindCallback && currentStudentName
          }
        />
      ) : null}
      {revokeModalIsVisible && revokeCallback && currentStudentName ? (
        <RevokeStudentModal
          onClose={onRevokeClose}
          revokeCallback={revokeCallback}
          studentName={currentStudentName}
          isVisible={
            revokeModalIsVisible && revokeCallback && currentStudentName
          }
        />
      ) : null}
      {resubmitModalIsVisible && resubmitCallback && currentStudentName ? (
        <ResubmitAssessmentModal
          onClose={onResubmitClose}
          resubmissionNote={currentResubmissionNote}
          resubmissionTime={currentResubmissionTime}
          resubmitCallback={resubmitCallback}
          studentName={currentStudentName}
          isVisible={
            resubmitModalIsVisible && resubmitCallback && currentStudentName
          }
          control={control}
          setFeedBack={setFeedBack}
          feedBack={feedBack}
        />
      ) : null}
      {modalIsShown ? (
        <div className="evaluate-assessment-modal-wrapper">
          <div className="evaluate-assessment-modal">
            <div className="assessment-modal-div">
              <h5>Evaluate Assessment</h5>
              <form onSubmit={evaluateAssessment}>
                <label className="comments" htmlFor="comments">
                  <span>Comments</span>
                  <textarea
                    id="comments"
                    onChange={event => setComments(event.target.value)}
                    placeholder="Add your comments here"
                    rows={5}
                    value={comments}
                  />
                </label>
                <button type="submit">Submit Evaluation</button>
              </form>
              <button
                className="cancel-modal"
                onClick={() => setModalIsShown(false)}
                type="button"
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <AssessmentViewHeaderContainer
        isTaskEvaluationPage={isTaskEvaluationPage}
        className="fixed-top"
      >
        <div className="view-assessment-nav">
          <div className="header-top">
            {!isTaskEvaluationPage ? (
              <div className="view-assess-top">
                <div>
                  <button onClick={() => history.goBack()} type="button">
                    <img
                      alt="Back Button"
                      className="header_t-back-icon"
                      src={backIcon}
                    />
                  </button>
                </div>
                <div className="header_t-text-view">
                  <h1>
                    {isTaskEvaluationPage
                      ? task.ai_activityitem
                      : createdAssessment?.ac_activity_name}
                  </h1>
                  <h2>
                    {isTaskEvaluationPage
                      ? task.ai_activityitem_description
                      : createdAssessment?.instance?.orgUnitInstance?.orgUnit
                          ?.unit?.un_unitname}
                  </h2>
                </div>
              </div>
            ) : null}
            {!isTaskEvaluationPage ? (
              <div className="header_t-text d-flex flex-column">
                {createdAssessment?.instance ? (
                  <h1>
                    {isTaskEvaluationPage
                      ? task.ai_activityitem
                      : createdAssessment?.ac_activity_name}
                  </h1>
                ) : (
                  <Skeleton
                    height={rem(28.8)}
                    style={{
                      marginBottom: rem(6),
                    }}
                    width="20%"
                  />
                )}
                {createdAssessment?.instance ? (
                  <h2>
                    {isTaskEvaluationPage
                      ? task.ai_activityitem_description
                      : createdAssessment?.instance?.orgUnitInstance?.orgUnit
                          ?.unit?.un_unitname}
                  </h2>
                ) : (
                  <Skeleton height={rem(19.9)} width="30%" />
                )}
              </div>
            ) : (
              <div className="header_t-text-task">
                <h1>
                  {isTaskEvaluationPage
                    ? task.ai_activityitem
                    : createdAssessment?.ac_activity_name}
                </h1>
                <h2>
                  {isTaskEvaluationPage
                    ? task.ai_activityitem_description
                    : createdAssessment?.instance?.orgUnitInstance?.orgUnit
                        ?.unit?.un_unitname}
                </h2>
              </div>
            )}
            {isTaskEvaluationPage ? (
              <button
                className="btn"
                onClick={() => history.goBack()}
                type="button"
              >
                <img alt="Options" src={closeIcon} className="closeIcon-task" />
              </button>
            ) : (
              <MoreOptions
                className={classNames('more-option', {
                  'is-archived':
                    createdAssessment?.instance?.orgUnitInstance
                      ?.orui_is_archived || userType === '2',
                })}
              >
                <DropdownItem
                  label="Edit"
                  onClick={() =>
                    history.push(
                      `/stream/${classId}/assessment/edit/${assessmentID}`,
                    )
                  }
                />
                <DropdownItem
                  isDanger
                  label="Delete"
                  onClick={() => {
                    setDeleteName(createdAssessment?.ac_activity_name);
                    setDeleteId(
                      createdAssessment.aoui_id_activityorgunitinstance,
                    );
                  }}
                />
              </MoreOptions>
            )}
            <DeleteModal
              // isButtonLoading={isAssessmentLoading}
              onButtonClick={() => {
                dispatch(
                  deleteAssessment(
                    deleteId,

                    aouiID => {
                      dispatch(deleteAssessmentFromPosts(aouiID));
                      showToast(
                        false,
                        `${deleteName} deleted successfully`,
                        true,
                      );
                    },
                  ),
                );
                history.goBack();
              }}
              onHide={() => {
                setDeleteId(null);

                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }}
              show={!!deleteId}
              title={`Delete ${deleteName}`}
            />
          </div>
          {!isTaskEvaluationPage ? (
            <div className="header-bottom">
              <NavLink
                activeClassName="active"
                exact
                replace
                to={`/stream/${classID}/assessment/${createdAssessment?.ac_id_activity}`}
              >
                Assessment Details
              </NavLink>
              {userType === '2' ? null : (
                <NavLink
                  activeClassName="active"
                  exact
                  isActive={() =>
                    /stream\/[\d\w-]+\/assessment\/\d+\/students/.test(pathname)
                  }
                  replace
                  to={`/stream/${classID}/assessment/${createdAssessment?.ac_id_activity}/students`}
                >
                  Students Details
                </NavLink>
              )}
              {userType === '2' ? null : (
                <NavLink
                  activeClassName="active"
                  exact
                  replace
                  to={`/stream/${classID}/assessment/${createdAssessment?.ac_id_activity}/assessment-summary`}
                >
                  Assessment Summary
                </NavLink>
              )}
            </div>
          ) : null}
        </div>
      </AssessmentViewHeaderContainer>
      {createdAssessment?.instance ? (
        <Switch>
          <Route exact path="/stream/:classID/assessment/:assessmentID">
            <Helmet>
              <title>Assessment Details | JungleCat</title>
            </Helmet>
            <AssessmentDetails assessment={createdAssessment} />
          </Route>
          <Route
            exact
            path="/stream/:classID/assessment/:assessmentID/students"
          >
            <Helmet>
              <title>Students Details | JungleCat</title>
            </Helmet>
            <StudentsDetails
              assessment={createdAssessment}
              onReassign={onReassign}
              onRevokeStudent={onRevokeStudent}
              onResubmitAssessment={onResubmitAssessment}
              onSendReminder={onSendReminder}
              isArchived={
                !createdAssessment?.instance?.orgUnitInstance?.orui_is_archived
              }
            />
          </Route>
          <Route
            exact
            path="/stream/:classID/assessment/:assessmentID/assessment-summary"
          >
            <Helmet>
              <title>Assessment Summary | JungleCat</title>
            </Helmet>
            <AssessmentSummary
              assessmentSummary={assessmentSummary}
              isArchived={
                !createdAssessment?.instance?.orgUnitInstance?.orui_is_archived
              }
            />
          </Route>
          <Route
            exact
            path="/stream/:classID/assessment/:assessmentID/students/:studentID"
          >
            <Helmet>
              <title>
                {task?.ai_activityitem || 'Task Evaluation'} | JungleCat
              </title>
            </Helmet>
            <TaskEvaluation assessment={createdAssessment} />
          </Route>
          <Route
            exact
            path="/stream/:classID/assessment/:assessmentID/students/:studentID/task/:taskID"
          >
            <Helmet>
              <title>
                {task?.ai_activityitem || 'Task Evaluation'} | JungleCat
              </title>
            </Helmet>
            <TaskEvaluation assessment={createdAssessment} />
          </Route>
        </Switch>
      ) : null}
      {/(stream\/[\d\w-]+\/assessment\/\d+\/students\/[\d\w-]+)$/.test(
        pathname,
      ) && !(assessmentTasksLoading || assessmentTasksError) ? (
        <AssessmentViewFooter
          assessmentID={assessmentID}
          classID={classID}
          onEvaluate={() => setModalIsShown(true)}
          onReassign={onReassign}
          student={createdAssessment?.instance?.students.find(
            student => student.aouis_id_userprofile_student === studentID,
          )}
          tasks={createdAssessment?.tasks}
        />
      ) : null}
    </AssessmentViewContainer>
  );
};

export default AssessmentView;
