/* eslint-disable react/prop-types */
import React, {useCallback, useState, useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {Button, TextInput} from '../../../../../../../../components/common';
import {showToast} from '../../../../../../../../components/common/Toast';
import {curriculumSchema} from '../../../../../../../../utils/validation';

import RemoveFileIcon from '../../../../../../../../assets/icons/delete-logo.svg';
import SearchInput from '../../../../../../../../components/shared/Inputs/SearchInput';
import ArrowLeftImg from '../../../../../../../../assets/icons/arrow_left.svg';
import PlusIcon from '../../../../../../../../assets/icons/refactor/plus-white.svg';
import {
  addCurriculumByCourseId,
  findModuleNameAlreadyExisted,
  getCurriculumByCourseId,
  clearFormError,
  setError as setServerError,
  curriculumSelector,
} from '../../../../../../../../redux/curriculumSlice';
import UpdateIcon from '../../../../../../../../assets/icons/updateadmintoast.svg';
import {useErrorMessage} from '../../../../../../../../utils/hooks';
import {AddModulesModal, UploadSection} from './component';
import {FileCard} from './component/UploadSection/components';
import {ViewCurriculumHeaderContainer} from './elements';

const FIELDS_IN_ORDER = ['moduleName'];

export const ViewCurriculumHeader = ({
  setSearchTerm,
  searchTerm,
  courseId,
  onBack,
}) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [step, setStep] = useState(0);

  const [upload, setUpload] = useState(null);
  const [moduleName, setModuleName] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [onDrag, setOnDrag] = useState(false);
  const {
    error: serverError,
    formError,
    courseCurriculum,
    isAddCurriculumLoading,
  } = useSelector(curriculumSelector);

  const onCancel = useCallback(() => {
    if (!isAddCurriculumLoading) {
      setModalShow(false);
      setStep(0);
      setUploadFiles([]);
      setFileNames([]);
      setUpload(null);
    }
  }, [isAddCurriculumLoading]);

  const clearServerError = useCallback(() => {
    dispatch(setServerError(null));
    dispatch(clearFormError());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: {
      moduleName: '',
      filename: '',
    },
    resolver: yupResolver(curriculumSchema),
  });

  useErrorMessage(errors, serverError, clearServerError, FIELDS_IN_ORDER);

  const onSubmit = useCallback(
    data => {
      dispatch(
        findModuleNameAlreadyExisted(courseId, data.moduleName, () => {
          setModuleName(data.moduleName);
          setStep(1);
        }),
      );
    },
    [courseId, dispatch],
  );

  const uploadFilesGroup = useMemo(
    () =>
      uploadFiles?.map((item, posi) => (
        <div className="org-image-full col-md-6" key={item.name}>
          <div className="org-image-after">
            <FileCard file={item} index={posi} fileNames={fileNames} />
          </div>
          <div className="delete-logo">
            <button
              type="button"
              onClick={() => {
                setUploadFiles(state =>
                  state?.filter((file, index) => index !== posi),
                );
                setFileNames(state =>
                  state?.filter((file, index) => index !== posi),
                );
              }}
            >
              <img src={RemoveFileIcon} alt="logo" />
            </button>
          </div>
        </div>
      )),
    [fileNames, uploadFiles],
  );

  const onSubmitFiles = useCallback(() => {
    if (!isAddCurriculumLoading && uploadFiles.length) {
      const formData = new FormData();
      fileNames?.forEach(name => formData?.append('fileNames', name));
      uploadFiles?.forEach(file => formData?.append('files', file, file?.name));
      formData.append('moduleName', moduleName);
      dispatch(
        addCurriculumByCourseId(courseId, formData, () => {
          setModalShow(false);
          setStep(0);
          showToast(UpdateIcon, 'Curriculum Module added successfully', false);
          dispatch(getCurriculumByCourseId(courseId));
          setUploadFiles([]);
          setFileNames([]);
        }),
      );
    }
  }, [
    courseId,
    dispatch,
    fileNames,
    isAddCurriculumLoading,
    moduleName,
    uploadFiles,
  ]);

  return (
    <ViewCurriculumHeaderContainer>
      <div className="header-container" id="header-container">
        <div className="header-top">
          <div className="left-content">
            <div className="back-container">
              <div>
                <button
                  className="backbutton"
                  onClick={onBack}
                  type="button"
                  id="backbutton-id"
                >
                  <img src={ArrowLeftImg} alt="arrow" />
                </button>
              </div>
              <div>
                <h2 className="title">{courseCurriculum?.ocr_course_name}</h2>
                {/* <p className="year">2021 - 2022</p> */}
              </div>
            </div>
          </div>
          <div className="right-content">
            <SearchInput
              className="class-search"
              onChange={event => setSearchTerm(event.target.value)}
              placeholder="Search files or modules"
              value={searchTerm}
              width="300px"
              id="class-search-id"
            />
            <Button
              className="button add-button"
              onClick={() => {
                setModalShow(true);
              }}
              isFullWidth={false}
              label="Add"
              icon={PlusIcon}
              iconPostion="right"
              id="add-id"
            />
          </div>
        </div>
      </div>
      <AddModulesModal
        show={modalShow}
        uploadFiles={uploadFiles}
        title="Add Curriculum"
        width="53.5rem"
        description={`Add modules on ${courseCurriculum?.ocr_course_name}`}
        isButtonLoading={isAddCurriculumLoading}
        onButtonClick={handleSubmit(onSubmit)}
        onHide={onCancel}
        step={step}
        onSubmitFiles={onSubmitFiles}
      >
        {step === 0 ? (
          <div className="modulename">
            <div className="d-flex justify-content-between align-item-center">
              <div className="field-middle">
                <Controller
                  control={control}
                  name="moduleName"
                  render={fields => (
                    <TextInput
                      {...fields}
                      errorMessage={
                        errors.moduleName?.message || formError.moduleName
                      }
                      autoFocus
                      hasError={!!errors.moduleName || !!formError.moduleName}
                      label="Module Name"
                      placeholder="Enter Module Name"
                      id="module-name"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ) : null}
        {step === 1 ? (
          <div className="filesection">
            <div className="fileupload">
              <UploadSection
                setUpload={setUpload}
                upload={upload}
                onDrag={onDrag}
                setOnDrag={setOnDrag}
                setFileName={setFileName}
                fileName={fileName}
                accept=".doc,.csv,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf,application/msword,.jpg,.jpeg,.png,.mov,.mp4,.mp3"
              />
            </div>
            <div className="d-flex justify-content-between fields">
              <div className="field-middle d-flex justify-content-between align-items-center">
                <Controller
                  control={control}
                  name="filename"
                  render={fields => (
                    <TextInput
                      {...fields}
                      label="File Name"
                      autoComplete="off"
                      type="text"
                      maxLength="50"
                      id="fileName"
                      value={fileName}
                      onChange={e => {
                        fields.onChange(e);
                        setFileName(e?.target?.value);
                      }}
                    />
                  )}
                />
                <Button
                  className="button add-files mt-4 ml-4"
                  onClick={() => {
                    if (upload !== null && fileName?.length) {
                      setUploadFiles(state => [...state, upload]);
                      setFileNames(state => [...state, fileName]);
                      setUpload(null);
                      setFileName('');
                    }
                  }}
                  id="add-plus"
                  isFullWidth={false}
                  icon={PlusIcon}
                />
              </div>
            </div>
            {uploadFilesGroup.length ? (
              <div className="file-list row">{uploadFilesGroup}</div>
            ) : null}
          </div>
        ) : null}
      </AddModulesModal>
    </ViewCurriculumHeaderContainer>
  );
};
