import styled from 'styled-components';

const HeaderContainer = styled.div`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  width: 41.25rem;
  margin-bottom: 1rem;
  @media (max-width: 45.75rem) {
    & {
      width: 95%;
    }
  }
`;

export default HeaderContainer;
