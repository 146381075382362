/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';

import CustomButton from '../../../../../../../../components/shared/Buttons/CustomButton';
import {PublishAssessmentsContainer} from './PublishAssessmentContainer';
import MenuCloseImg from '../../../../../../../../assets/icons/menu-close.svg';
import SyncingIcon from '../../../../../../../../assets/icons/syncing.svg';
import SyncingDoneIcon from '../../../../../../../../assets/icons/syncing-done.svg';
import SyncingErrorIcon from '../../../../../../../../assets/icons/syncing-error.svg';

export const PublishAssessments = ({
  isButtonShow,
  isLoading,
  assessmentLoading,
}) => (
  <>
    <PublishAssessmentsContainer>
      <div className="text-center w-100">
        <div className="assessment-head mb-1">Add an Assessment</div>
        <div className="assessment-subhead mb-3">
          You can add an assessment by select qualification and unit and give
          title for new assessment
          {isButtonShow && assessmentLoading ? (
            <img src={SyncingIcon} className="ml-2" alt="syncing-icon" />
          ) : isButtonShow ? (
            <img
              src={SyncingDoneIcon}
              className="ml-2"
              alt="syncing-done-icon"
            />
          ) : (
            isButtonShow && (
              <img
                src={SyncingErrorIcon}
                alt="syncing-error-icon"
                className="ml-2"
              />
            )
          )}
        </div>
      </div>
      {isButtonShow && !isLoading && (
        <div className="publish-buttons">
          <CustomButton
            bgColor="#00ABFF"
            opacity={0.3}
            className="mr-3"
            type="submit"
          >
            Publish
          </CustomButton>
          <img
            style={{cursor: 'pointer'}}
            src={MenuCloseImg}
            alt="menu-close"
          />
        </div>
      )}
    </PublishAssessmentsContainer>
  </>
);
