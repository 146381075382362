/* eslint-disable react/prop-types */

import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import ChevronRightIcon from '../../../../../../assets/icons/chevron-right.svg';
import {
  BackLink,
  EmptyState,
  Modal,
  MoreOptions,
  Table,
} from '../../../../../../components/common';
import {DropdownItem} from '../../../../../../components/common/MoreOptions/components';
import {TableCellLink} from '../../../../../../components/common/Table/styles';
import {showToast} from '../../../../../../components/common/Toast';
import {
  deleteUnit,
  fetchAllFilterQualifications,
  fetchAllUnits,
  unitsSelector,
} from '../../../../../../redux/unitsSlice';
import {ListingPageHeader, TableSpinner} from '../../../../components-new';
import {UnitType} from '../../../../elements-new';
import {ReleasesCell} from '../../components';
import {EditUnitModal} from './components';
import {AllUnitsContainer} from './elements';
import {authSelector} from '../../../../../../redux/authSlice';

const DELAY = 500;

export const AllUnits = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {qualificationId} = useParams();
  const {filterQualifications, isListLoading, isUnitLoading, units} =
    useSelector(unitsSelector);
  const {orgPreferences} = useSelector(authSelector);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [editDetails, setEditDetails] = useState(null);
  const [filteredQualificationId, setFilteredQualificationId] = useState('');
  // const {userOrganisation} = useSelector(state => state.auth);
  // Check org preference for assessment map is enable
  const assessmentMapPreference = orgPreferences?.find(
    item =>
      item.pr_slug === 'show_assessment_map' && Number(item.pr_value) === 1,
  );

  const clearEditDetails = useCallback(() => {
    setEditDetails(null);
  }, []);

  useEffect(() => {
    dispatch(fetchAllFilterQualifications());
  }, [dispatch]);

  // Check org preference for create custom unit is enable
  const unitPreference = orgPreferences?.find(
    item => item.pr_slug === 'create_unit' && Number(item.pr_value) === 1,
  );

  const defaultQualificationName = useMemo(
    () =>
      filterQualifications?.find(
        item =>
          item.qualification.tqual_id_tgovqualification ===
          Number(qualificationId),
      )?.qualification.tqual_title || '',
    [filterQualifications, qualificationId],
  );

  const filteredQualificationName = useMemo(
    () =>
      filterQualifications?.find(
        item =>
          item.qualification.tqual_id_tgovqualification ===
          Number(filteredQualificationId),
      )?.qualification.tqual_title || '',
    [filterQualifications, filteredQualificationId],
  );

  const qualificationOptions = useMemo(
    () =>
      filterQualifications?.map(item => ({
        label: item.qualification.tqual_title,
        value: item.qualification.tqual_id_tgovqualification,
        code: item.qualification.tqual_code,
      })) || [],
    [filterQualifications],
  );

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'index',
        className: 'index',
      },
      {
        Header: 'Unit Code',
        accessor: 'unit.tun_code',
        className: 'unit-code',
      },
      {
        Header: 'Unit Name',
        accessor: 'unit.tun_title',
        className: 'unit-name',
      },
      // {
      //   Header: 'Qualification Name',
      //   accessor:
      //     'orgQualificationUnit.orgQualification.qualification.tqual_title',
      //   className: 'qualification-name',
      // },
      {
        Header: 'Releases',
        Cell: ({value}) => <ReleasesCell prefix="tunr" releases={value} />,
        accessor: 'unit.unitReleases',
        className: 'unit-releases',
      },
      {
        Header: 'Type',
        Cell: ({value}) => (
          <UnitType>{value === 1 ? 'core' : 'elective'}</UnitType>
        ),
        accessor: 'oqu_is_essential',
        className: 'unit-type',
      },
      {
        Header: '',
        Cell: ({row: {original}, value}) => {
          const {tqual_id_tgovqualification: tgovQualId} = original;

          return (
            <div className="align-items-center d-flex justify-content-end w-100">
              {assessmentMapPreference ? (
                <TableCellLink
                  className="align-items-center d-flex mr-2"
                  onClick={() => {
                    history.push(
                      `/admin/qualifications/${tgovQualId}/units/${original?.unit.tun_id_tgovunit}`,
                    );
                  }}
                  type="button"
                >
                  <span className="mr-2">View Unit</span>
                  <img alt="View Unit" src={ChevronRightIcon} />
                </TableCellLink>
              ) : null}
              {!original?.unit?.tun_is_auto_imported ? (
                <MoreOptions>
                  <DropdownItem
                    label="Edit Unit Details"
                    onClick={() => {
                      setEditDetails({
                        code: original?.unit?.tun_code,
                        id: value,
                        name: original?.unit?.tun_title,
                        type:
                          original?.oqu_is_essential === 1
                            ? 'core'
                            : 'elective',
                      });
                    }}
                  />
                  <DropdownItem
                    isDanger
                    label="Delete"
                    isDisabled={original?.classes?.length}
                    toolTipHeading="Unit Cannot be deleted"
                    toolTipSubText="Unit is assigned in one or more class"
                    onClick={() => {
                      setDeleteId(value);
                      setDeleteName(original?.unit?.tun_title);
                    }}
                  />
                </MoreOptions>
              ) : null}
            </div>
          );
        },
        accessor: 'unit.tun_id_tgovunit',
        className: 'more-options',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assessmentMapPreference, history],
  );

  const data = useMemo(
    () => [
      ...(units || []).map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [units],
  );

  const formatOptionForQualification = ({code, label}, {context}) =>
    context === 'menu' ? (
      <div className="select-option" type="button">
        <div className="label">{label}</div>
        <div className="code">{code}</div>
      </div>
    ) : (
      label
    );

  const filters = useMemo(
    () => [
      {
        column:
          'orgQualificationUnit.orgQualification.qualification.tqual_title',
        data: qualificationOptions,
        formatOptionLabel: formatOptionForQualification,
        defaultValue: {
          label: defaultQualificationName,
          value: Number(qualificationId),
        },
        isFilteredManually: true,
        itemName: 'Qualifications',
        onChange: setFilteredQualificationId,
      },
    ],
    [defaultQualificationName, qualificationId, qualificationOptions],
  );

  const hiddenColumns = useMemo(
    () => ['orgQualificationUnit.orgQualification.qualification.tqual_title'],
    [],
  );

  useEffect(() => {
    setFilteredQualificationId(qualificationId || '');
  }, [qualificationId]);

  useEffect(() => {
    if (filteredQualificationId) {
      dispatch(fetchAllUnits(filteredQualificationId));
      history.replace(`/admin/qualifications/${filteredQualificationId}/units`);
    }
  }, [dispatch, filteredQualificationId, history]);

  return (
    <AllUnitsContainer>
      <BackLink
        className="mb-3"
        onClick={() => {
          history.replace('/admin/qualifications');
        }}
      />
      <ListingPageHeader
        buttonLabel="Add Unit"
        className="mb-4"
        buttonShow={!!unitPreference}
        onButtonClick={() => {
          if (qualificationId) {
            history.push({
              pathname: `/admin/qualifications/${qualificationId}/units/add-units`,
              state: {
                qualificationName: location.state?.qualificationName,
              },
            });
          } else {
            history.push('/admin/qualifications/units/add-units');
          }
        }}
        title={filteredQualificationName ? 'Units in' : 'All Units'}
        titleSuffix={filteredQualificationName}
      />
      <div className="table-container">
        {isListLoading ? <TableSpinner /> : null}
        {!isListLoading && Array.isArray(units) && !units?.length ? (
          <EmptyState
            description="It appears that no units have been added to the qualification."
            title="There are no units yet!"
          />
        ) : null}
        {!isListLoading && units?.length ? (
          <Table
            columns={columns}
            data={data}
            filters={filters}
            hiddenColumns={hiddenColumns}
            itemName="Units"
            isSortedBy
            maxWidth="70rem"
          />
        ) : null}
      </div>
      <Modal
        buttonLabel="Delete"
        description="Are you sure you want to delete this unit?"
        isDelete
        isButtonLoading={isUnitLoading}
        loadingButtonLabel="Deleting"
        onButtonClick={() => {
          if (!isUnitLoading) {
            dispatch(
              deleteUnit(qualificationId, deleteId, () => {
                setDeleteId(null);
                showToast(false, `${deleteName} has been deleted.`, true);

                setTimeout(() => {
                  setDeleteName('');
                }, DELAY);
              }),
            );
          }
        }}
        onHide={() => {
          setDeleteId(null);

          setTimeout(() => {
            setDeleteName('');
          }, DELAY);
        }}
        show={!!deleteId}
        title={`Delete ${deleteName}`}
        width="41.25rem"
      />
      <EditUnitModal editDetails={editDetails} onHide={clearEditDetails} />
    </AllUnitsContainer>
  );
};
