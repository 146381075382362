// import {useContext} from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import InstantSession from './components/InstantSession';
import LiveSession from './components/LiveSession';
import {ConfirmContextProvider} from './components/LiveSession/contexts/ConfirmContext';
import {
  // LiveSessionContext,
  LiveSessionContextProvider,
} from './components/LiveSession/contexts/LiveSessionContext';
import ScheduleSession from './components/ScheduleSession';

// const TestComponent = () => {
//   const context = useContext(LiveSessionContext);
//   console.log(context);
//   console.log(
//     'if this component rerenders more than one time, help me god ...',
//   );
//   return <div>testing</div>;
// };

const Sessions = () => {
  const {path} = useRouteMatch();
  return (
    <Switch>
      <Redirect exact from={`${path}/`} to="live" />
      <Route exact path={`${path}/create`}>
        <InstantSession />
      </Route>
      <Route exact path={`${path}/schedule`}>
        <ScheduleSession />
      </Route>
      <Route path={`${path}/:id`}>
        <LiveSessionContextProvider>
          <ConfirmContextProvider>
            <LiveSession />
            {/* <TestComponent /> */}
          </ConfirmContextProvider>
        </LiveSessionContextProvider>
      </Route>
    </Switch>
  );
};

export default Sessions;
