/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import TaskIcon from '../../../../../../../../../assets/icons/task-icon.svg';

import {TasksContainer} from '../../../../../Elements/AssessmentSummaryContainer';

export const TasksSection = ({assesmentTasks}) => {
  const TasksWithPerformanceCerteria = useMemo(
    () =>
      assesmentTasks?.filter(
        data => Object.keys(data?.performanceCriteria || {}).length,
      ),
    [assesmentTasks],
  );
  return (
    <TasksContainer>
      <div className="header-section">
        <div className="image">
          <img src={TaskIcon} alt="general" className="task-icon" />
        </div>
        <div>
          <p className="header">Tasks</p>
        </div>
      </div>
      <div className="seperator" />
      <div className="content-section">
        <div className="first-div">
          <div className="first-content">
            <div className="title-div">
              <span>1</span>
              <p className="title">All Tasks</p>
            </div>
            {assesmentTasks?.map((item, index) => (
              <div>
                <p className="content">{`${index + 1}. ${
                  item?.ai_activityitem
                }`}</p>
                <p className="sub-content">
                  {`${item?.ai_activityitem_description}`}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="second-div">
          <div className="second-content">
            <div className="title-div">
              <span>2</span>
              <p className="title">Tasks against Perfomace Criteria</p>
            </div>
            {TasksWithPerformanceCerteria?.length ? (
              TasksWithPerformanceCerteria?.map((item, index) => (
                <div>
                  <p className="content">{`${index + 1}. ${
                    item?.ai_activityitem
                  }`}</p>
                  <p className="sub-content">
                    {`${item?.ai_activityitem_description}`}
                  </p>
                </div>
              ))
            ) : (
              <p style={{marginLeft: '2rem'}}>Nil</p>
            )}
          </div>
        </div>
      </div>
    </TasksContainer>
  );
};
