import {createSlice} from '@reduxjs/toolkit';

import * as classesApi from '../api/admin/classes';
import * as unitsApi from '../api/admin/units';
import * as deparmentsApi from '../api/admin/departments';

const initialFormError = {className: ''};

const classesSlice = createSlice({
  initialState: {
    error: null,
    classes: null,
    singleClass: null,
    assessments: null,
    unitDetails: null,
    isClassesLoading: false,
    isSingleClassLoading: false,
    isListLoading: true,
    filterQualifications: null,
    filterCourses: null,
    filterAcademicYears: null,
    filterDepartments: null,
    filterTeachers: null,
    unitsCount: 0,
    isDeleteLoading: false,
    isAssessmentsLoading: false,
    assessmentSummary: null,
    unitTeachers: [],
    qualificationUnits: [],
    manageUnits: [],
    isAddClassLoading: false,
    currentClass: [],
    formError: initialFormError,
    newClassUnitsCount: 0,
    studentEmailPattern: [],
    alreadyExistStudentEmail: [],
    alreadyExistStudentRefId: [],
    otherOrganisationStudentEmail: [],
    otherOrganisationStudentReferId: [],
    duplicateData: [],
    validClassStudent: [],
    classStudents: null,
  },
  name: 'classes',
  reducers: {
    setClasses: (state, action) => {
      state.classes = action.payload;
    },
    setIsClassesLoading: (state, action) => {
      state.isClassesLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsListLoading: (state, action) => {
      state.isListLoading = action.payload;
    },
    setFilterQualifications: (state, action) => {
      state.filterQualifications = action.payload;
    },
    setFilterCourses: (state, action) => {
      state.filterCourses = action.payload;
    },
    setFilterAcademicYears: (state, action) => {
      state.filterAcademicYears = action.payload;
    },
    setFilterDepartments: (state, action) => {
      state.filterDepartments = action.payload;
    },
    setFilterTeachers: (state, action) => {
      state.filterTeachers = action.payload;
    },
    setUnitsCount: (state, action) => {
      state.unitsCount = action.payload;
    },
    editClass(state, action) {
      const classIndex = state.classes.findIndex(
        item => item.oc_id_orgclass === action.payload.oc_id_orgclass,
      );
      state.classes[classIndex] = action.payload;
    },
    removeClassById(state, action) {
      state.classes = state.classes.filter(
        item => item.oc_id_orgclass !== action.payload,
      );
    },
    // View class
    setSingleClass: (state, action) => {
      state.singleClass = action.payload;
    },
    setIsSingleClassLoading: (state, action) => {
      state.isSingleClassLoading = action.payload;
    },
    setIsDeleteLoading: (state, action) => {
      state.isDeleteLoading = action.payload;
    },
    removeClassStudentById(state, action) {
      state.singleClass.orgStudents = state.singleClass.orgStudents.filter(
        item => item.ocs_id_orgclass_student !== action.payload,
      );
    },
    setAssessments: (state, action) => {
      state.assessments = action.payload;
    },
    setUnitDetails: (state, action) => {
      state.unitDetails = action.payload;
    },
    setAssessmentSummary: (state, action) => {
      state.assessmentSummary = action.payload;
    },
    setIsAssessmentsLoading: (state, action) => {
      state.isAssessmentsLoading = action.payload;
    },
    // manage Units
    setUnitTeachers: (state, action) => {
      state.unitTeachers = action.payload;
    },
    viewQualificationUnits: (state, action) => {
      state.qualificationUnits = action.payload;
    },
    viewManageUnits: (state, action) => {
      state.manageUnits = action.payload;
    },
    // add student to class
    setAlreadyExistStudentEmail: (state, action) => {
      state.alreadyExistStudentEmail = action.payload;
    },
    setAlreadyExistStudentRef: (state, action) => {
      state.alreadyExistStudentRefId = action.payload;
    },
    setOtherOrganisationStudentsEmail: (state, action) => {
      state.otherOrganisationStudentEmail = action.payload;
    },
    setOtherOrganisationStudentsReferId: (state, action) => {
      state.otherOrganisationStudentReferId = action.payload;
    },
    setDuplicateData: (state, action) => {
      state.duplicateData = action.payload;
    },
    // add class
    setIsAddClassLoading: (state, action) => {
      state.isAddClassLoading = action.payload;
    },
    setCurrentNewClass: (state, action) => {
      state.currentClass = action.payload;
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setNewClassUnitsCount(state, action) {
      state.newClassUnitsCount = action.payload;
    },
    setStudentEmailPattern: (state, action) => {
      state.studentEmailPattern = action.payload;
    },
    setValidClassStudent: (state, action) => {
      state.validClassStudent = action.payload;
    },
    setClassStudents: (state, action) => {
      state.classStudents = action.payload;
    },
  },
});

export const {
  setClasses,
  setIsClassesLoading,
  setError,
  setIsListLoading,
  setFilterQualifications,
  setFilterCourses,
  setFilterAcademicYears,
  setFilterDepartments,
  setFilterTeachers,
  setUnitsCount,
  editClass,
  removeClassById,
  setSingleClass,
  setIsSingleClassLoading,
  removeClassStudentById,
  setIsDeleteLoading,
  setAssessments,
  setUnitDetails,
  setAssessmentSummary,
  setIsAssessmentsLoading,
  setUnitTeachers,
  viewQualificationUnits,
  viewManageUnits,
  setIsAddClassLoading,
  setCurrentNewClass,
  setFormError,
  clearFormError,
  setNewClassUnitsCount,
  setStudentEmailPattern,
  setAlreadyExistStudentEmail,
  setDuplicateData,
  setAlreadyExistStudentRef,
  setOtherOrganisationStudentsReferId,
  setOtherOrganisationStudentsEmail,
  setValidClassStudent,
  setClassStudents,
} = classesSlice.actions;

export const getAllClasses =
  (academicYearId, qualificationId) => async dispatch => {
    dispatch(setIsListLoading(true));
    try {
      const [
        filterQualificationsResponse,
        filterAcademicYearsResponse,
        unitsCountResponse,
        classResponse,
        filterAllTeachersResponse,
        filterAllCoursesResponse,
        filterAllDepartmentsResponse,
      ] = await Promise.all([
        unitsApi.fetchAllFilterQualifications(),
        classesApi.fetchAllFilterAcademicYears(),
        classesApi.fetchUnitsCount(),
        classesApi.fetchAllClasses(academicYearId, qualificationId),
        classesApi.fetchAllTeachers(),
        classesApi.fetchAllFilterCourses(),
        deparmentsApi.fetchAllDepartments(),
      ]);
      dispatch(setFilterTeachers(filterAllTeachersResponse.data.allTeachers));
      dispatch(
        setFilterDepartments(filterAllDepartmentsResponse.data.orgDepartments),
      );
      dispatch(setClasses(classResponse.data.allClasses || []));
      // qualifications for filter
      dispatch(
        setFilterQualifications(
          filterQualificationsResponse.data.allQualificationsList || [],
        ),
      );
      // academic years for filter
      dispatch(
        setFilterAcademicYears(
          filterAcademicYearsResponse.data.classAllAcademicYear || [],
        ),
      );
      // courses
      dispatch(
        setFilterCourses(filterAllCoursesResponse.data.allCourses || []),
      );
      // units count
      dispatch(setUnitsCount(unitsCountResponse.data.allUnitsCount));
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsListLoading(false));
    }
  };

export const deleteClass = (classId, callback) => async dispatch => {
  dispatch(setIsClassesLoading(true));

  try {
    await classesApi.deleteClass(classId);
    dispatch(removeClassById(classId));

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsClassesLoading(false));
  }
};
export const updateClass = (classId, data, callback) => async dispatch => {
  dispatch(setIsClassesLoading(true));

  try {
    const response = await classesApi.updateClass(classId, {
      departmentId: data.department.value,
      classTeacherId: data.classTeacher.value,
      className: data.className,
      academicYearId: data.academicYear.value,
      startDate: data.startDate,
      endDate: data.endDate,
    });

    if (response.data?.editClass) {
      dispatch(editClass(response.data?.editClass));

      if (callback) {
        callback();
      }
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsClassesLoading(false));
  }
};

// View class

export const getClassById = orgClassId => async dispatch => {
  dispatch(setIsSingleClassLoading(true));

  try {
    const resp = await classesApi.fetchClassById(orgClassId);

    dispatch(setSingleClass(resp.data?.singleClassData));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsSingleClassLoading(false));
  }
};
export const revokeStudentFromClass =
  (orgClassStudentId, callback) => async dispatch => {
    dispatch(setIsDeleteLoading(true));
    try {
      await classesApi.revokeStudentFromClass(orgClassStudentId);
      dispatch(removeClassStudentById(orgClassStudentId));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsDeleteLoading(false));
    }
  };
export const getAllAssessments = orgUnitInstanceId => async dispatch => {
  dispatch(setIsAssessmentsLoading(true));
  try {
    const resp = await classesApi.fetchAllAssessments(orgUnitInstanceId);
    dispatch(setAssessments(resp.data?.assessments || []));
    dispatch(setUnitDetails(resp.data?.unitDetails || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAssessmentsLoading(false));
  }
};
export const getAssessmentSummaryData = activityId => async dispatch => {
  dispatch(setIsAssessmentsLoading(true));
  try {
    const resp = await classesApi.fetchAssessmentSummary(activityId);
    dispatch(setAssessmentSummary(resp.data?.assessmentSummary || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsAssessmentsLoading(false));
  }
};
export const updateViewClass = (classId, data, callback) => async dispatch => {
  dispatch(setIsSingleClassLoading(true));

  try {
    const response = await classesApi.updateClass(classId, {
      departmentId: data.department.value,
      classTeacherId: data.classTeacher.value,
      className: data.className,
      academicYearId: data.academicYear.value,
      startDate: data.startDate,
      endDate: data.endDate,
    });

    if (response?.data) {
      dispatch(getClassById(classId));

      if (callback) {
        callback();
      }
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsSingleClassLoading(false));
  }
};
// manage Units

export const getUnitsByType = orgClassId => async dispatch => {
  dispatch(setIsClassesLoading(true));
  try {
    const resp = await classesApi.fetchUnitsByType(orgClassId, {type: 'ALL'});

    if (resp) {
      dispatch(setSingleClass(resp.data?.singleClassData));
      dispatch(viewManageUnits(resp.data?.allQualificationUnits || []));
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsClassesLoading(false));
  }
};
export const getAllUnitsTeachers = () => async dispatch => {
  dispatch(setIsSingleClassLoading(true));
  try {
    const resp = await classesApi.fetchAllUnitsTeachers();
    dispatch(setUnitTeachers(resp.data?.allTeachers || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsSingleClassLoading(false));
  }
};
export const manageUnits =
  (orgClassId, data, addUnits, removeUnits, callback) =>
  async (dispatch, getState) => {
    dispatch(setIsSingleClassLoading(true));
    const {singleClass} = getState()?.classes;
    try {
      const unitData = {
        orgUnits: data,
        removeUnits,
        addUnits,
      };
      const response = await classesApi.manageUnits(orgClassId, {
        orgClass: singleClass,
        orgUnits: unitData,
      });

      if (response?.data) {
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsSingleClassLoading(false));
    }
  };
export const addStudentsToViewClass =
  (orgClassId, data, callback) => async (dispatch, getState) => {
    const {singleClass} = getState()?.classes;
    dispatch(setIsClassesLoading(true));

    try {
      const classData = {
        currentclass: {
          oc_id_orgclass: singleClass.oc_id_orgclass,
          oc_date_start: singleClass.oc_date_start,
          oc_date_end: singleClass.oc_date_end,
        },
        students: data,
      };
      await classesApi.addClassStudentsToViewClass(orgClassId, classData);

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsClassesLoading(false));
    }
  };

export const spreadSheetStudentCheckToViewClass =
  (orgClassId, data, callback) => async dispatch => {
    dispatch(setIsClassesLoading(true));

    const resp = await classesApi.ViewClassStudentSpreadsheetValid(
      orgClassId,
      data,
    );

    dispatch(setAlreadyExistStudentEmail(resp.data.alreadyEmail));
    dispatch(setAlreadyExistStudentRef(resp.data.alreadyRef));
    dispatch(setStudentEmailPattern(resp.data.invalidEmails));
    dispatch(setDuplicateData(resp.data.duplicateData));
    dispatch(
      setOtherOrganisationStudentsReferId(
        resp.data.otherOrganisationStudentByReferId,
      ),
    );
    dispatch(
      setOtherOrganisationStudentsEmail(
        resp.data.otherOrganisationStudentByEmail,
      ),
    );

    dispatch(setValidClassStudent(resp.data.validUsers));
    dispatch(setIsClassesLoading(false));
    if (callback) {
      if (data.length === resp.data.validUsers.length) {
        dispatch(
          addStudentsToViewClass(orgClassId, resp.data.validUsers, callback),
        );
      } else {
        callback();
      }
    }
  };

// Add class
export const getDataForAddClass = () => async dispatch => {
  dispatch(setIsSingleClassLoading(true));
  try {
    const [
      filterQualificationsResponse,
      filterAcademicYearsResponse,
      filterAllTeachersResponse,
      filterAllCoursesResponse,
      filterAllDepartmentsResponse,
    ] = await Promise.all([
      unitsApi.fetchAllFilterQualifications(),
      classesApi.fetchAllFilterAcademicYears(),
      classesApi.fetchAllTeachers(),
      classesApi.fetchAllFilterCourses(),
      deparmentsApi.fetchAllDepartments(),
    ]);
    dispatch(setFilterTeachers(filterAllTeachersResponse.data.allTeachers));
    dispatch(
      setFilterDepartments(filterAllDepartmentsResponse.data.orgDepartments),
    );
    // qualifications for filter
    dispatch(
      setFilterQualifications(
        filterQualificationsResponse.data.allQualificationsList || [],
      ),
    );
    dispatch(setFilterCourses(filterAllCoursesResponse.data.allCourses || []));
    // academic years for filter
    dispatch(
      setFilterAcademicYears(
        filterAcademicYearsResponse.data.classAllAcademicYear || [],
      ),
    );
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsSingleClassLoading(false));
  }
};
export const addClass = (data, callback) => async dispatch => {
  dispatch(setIsAddClassLoading(true));
  try {
    const resp = await classesApi.addNewClass(data);
    dispatch(setCurrentNewClass(resp.data));

    dispatch(viewQualificationUnits(resp.data.allQualificationUnits[0] || []));
    if (resp) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
    dispatch(setFormError(error?.response?.data?.error));
  } finally {
    dispatch(setIsAddClassLoading(false));
  }
};

export const addUnitsToClass =
  (currentClass, orgUnit, callback) => async dispatch => {
    dispatch(setIsAddClassLoading(true));
    try {
      const unitData = {currentclass: currentClass?.orgClass, orgunit: orgUnit};

      await classesApi.addUnitsClass(unitData);
      const units = Object.keys(orgUnit).length;
      dispatch(setNewClassUnitsCount(units));
      dispatch(setCurrentNewClass(currentClass));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddClassLoading(false));
    }
  };
export const getAllOrgStudents = () => async dispatch => {
  dispatch(setIsClassesLoading(true));

  try {
    const resp = await classesApi.fetchAllStudents();
    dispatch(setClassStudents(resp.data.orgAllStudent));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsClassesLoading(false));
  }
};

export const addStudentsUnderNewClass =
  (data, callback) => async (dispatch, getState) => {
    const {currentClass} = getState()?.classes;
    dispatch(setIsAddClassLoading(true));

    try {
      const classData = {currentclass: currentClass?.orgClass, students: data};
      await classesApi.addClassStudents(classData);

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddClassLoading(false));
    }
  };

export const spreadSheetClassStudentCheck =
  (data, callback) => async dispatch => {
    dispatch(setIsClassesLoading(true));

    const resp = await classesApi.individualClassStudentSpreadsheetValid(data);

    dispatch(setStudentEmailPattern(resp.data.invalidEmails));
    dispatch(setDuplicateData(resp.data.duplicateData));
    dispatch(setValidClassStudent(resp.data.validUsers));
    dispatch(
      setOtherOrganisationStudentsReferId(
        resp.data.otherOrganisationStudentByReferId,
      ),
    );
    dispatch(
      setOtherOrganisationStudentsEmail(
        resp.data.otherOrganisationStudentByEmail,
      ),
    );
    dispatch(setIsClassesLoading(false));
    if (callback) {
      if (
        data.length === resp.data.validUsers.length &&
        !resp.data.duplicateData.length
      ) {
        dispatch(addStudentsUnderNewClass(resp.data.validUsers, callback));
      } else {
        callback();
      }
    }
  };

export const classesSelector = state => state.classes;

export default classesSlice.reducer;
