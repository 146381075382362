// import React, {useState} from 'react';
import {Helmet} from 'react-helmet';

import {AddCourseContainer} from './elements';
import {BasicDetails} from './components';

export const AddCourse = () => (
  <>
    <Helmet>
      <title>Add Course | JungleCat</title>
    </Helmet>

    <AddCourseContainer>
      <BasicDetails />
    </AddCourseContainer>
  </>
);
