/* eslint-disable react/prop-types */
import React from 'react';
import {Controller} from 'react-hook-form';
import ReactBootstrapModal from 'react-bootstrap/Modal';
import moment from 'moment';
import ArrowIcon from '../../../../../../../../assets/icons/act-to.svg';
import ProfileIcon from '../../../../../../../../assets/icons/profile.svg';
import ResubmitIcon from '../../../../../../../../assets/icons/resubmit-big.svg';
import {TextArea} from '../../../../../../../../components/common/TextArea';

import {ResubmitAssessmentModalContainer} from './elements';

export const ResubmitAssessmentModal = ({
  onClose,
  resubmissionNote,
  resubmissionTime,
  resubmitCallback,
  studentName,
  isVisible,
  control,
  setFeedBack,
  feedBack,
}) => (
  <ReactBootstrapModal centered onHide={() => {}} show={isVisible}>
    <ResubmitAssessmentModalContainer>
      <div className="resubmit-assessment-modal-wrapper">
        <div className="graphics">
          <img alt="Send Reminder" src={ResubmitIcon} />
          <img alt="To" src={ArrowIcon} />
          <img alt={studentName} src={ProfileIcon} />
        </div>
        <h2>{studentName} has requested for resubmission</h2>
        <p className="subtitle">
          Please respond to this request inorder to inform the student.
        </p>
        <div className="resubmission-details">
          <div className="resubmission-profile">
            <img alt="Resubmitted Student" src={ProfileIcon} />
            <div className="resubmission-profile-text">
              <span className="resubmission-profile-name">{studentName}</span>
              <span className="resubmission-profile-time">
                {moment(resubmissionTime).fromNow()}
              </span>
            </div>
          </div>
          <p className="resubmission-note">{resubmissionNote}</p>
        </div>
        <div className="feed-back">
          <div className="mt-2">
            <Controller
              control={control}
              name="feedback"
              render={fields => (
                <TextArea
                  {...fields}
                  label="Enter your feedback"
                  className="mr-4 mt-4"
                  placeholder="give feedback to the student"
                  rows="6"
                  onChange={e => {
                    fields.onChange(e);
                    setFeedBack(e.target.value);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="buttons">
          <button
            className="cancel-button"
            onClick={() => {
              resubmitCallback(6, feedBack);
              onClose();
            }}
            type="button"
          >
            Decline
          </button>
          <button
            className="send-button"
            onClick={() => {
              resubmitCallback(1, feedBack);
              onClose();
            }}
            type="button"
          >
            Accept Request
          </button>
        </div>
      </div>
    </ResubmitAssessmentModalContainer>
  </ReactBootstrapModal>
);
