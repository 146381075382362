import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash.debounce';
import {loginAfterInvitation, authSelector} from '../../../AuthOld/authSlice';
import {
  setUser,
  setUserOrganisation,
  setOrgPreferences,
} from '../../../../redux/authSlice';
import {
  acceptInvitationForAdminTeacher,
  findAdminTeacherByToken,
  findUserName,
} from '../../../../api/admin';
import CustomBox from '../../../../components/shared/CustomBox';
import CustomTextInput from '../../../../components/shared/CustomTextInput';
import CustomButton from '../../../../components/shared/Buttons/CustomButton';
import jcLogo from '../../../../assets/icons/jclogo.svg';
import buttonLoader from '../../../../assets/icons/button-spinner.svg';
// import useNavbarScroll from '../../../../hooks/useNavbarScroll';
import AdminTeacherInvitationContainer from './AdminTeacherContainer';
import ErrorMessage from './404ErrorPage';
// import Navbar from '../../Elements/Navbar';

const AdminTeacherInvitation = () => {
  const authState = useSelector(authSelector);
  const [data, setData] = useState({
    confirmPassword: {
      error: '',
      value: '',
    },
    password: {
      error: '',
      value: '',
    },
    username: {
      error: '',
      value: '',
    },
  });
  const [adminTeacher, setAdminTeacher] = useState(null);
  const [verifyUserName, setVerifyUserName] = useState(false);
  const [successUsername, setSuccessUsername] = useState(false);
  const [org, setOrg] = useState(null);
  const [token] = useState(window.location.href.split('?token=')[1]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  // const {handleScroll, navbarTop} = useNavbarScroll(16, 81);
  const toggleShowPassword = () => {
    setShowPassword(state => !state);
  };
  const toggleShowPassword1 = () => {
    setShowPassword1(state => !state);
  };

  useEffect(() => {
    findAdminTeacherByToken(token)
      .then(response => {
        setError(false);
        setAdminTeacher(response.data.newUserProfile);
        setOrg(response.data.OrgProfile);
      })
      .catch(() => {
        setError(true);
      });
  }, [token]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUserName = useCallback(
    debounce(username => {
      findUserName({username})
        .then(() => {
          if (username === '') {
            setSuccessUsername(false);
          } else {
            setSuccessUsername(true);
          }
          setVerifyUserName(false);
        })
        .catch(({response}) => {
          setVerifyUserName(false);
          setSuccessUsername(false);
          if (response?.data) {
            setData(state => {
              const cleanState = {...state};

              Object.keys(cleanState).forEach(key => {
                cleanState[key].error = '';
              });

              return {
                ...cleanState,
                [response.data.field]: {
                  error: response.data.error,
                },
              };
            });
          }
        });
    }, 1000),
    [],
  );
  const handleUserNameChange = event => {
    if (successUsername) {
      setSuccessUsername(false);
    }

    setVerifyUserName(true);
    setData(state => ({
      ...state,
      [event.target.name]: {
        error: '',
        value: event.target.value,
      },
    }));
    checkUserName(event.target.value);
  };

  const handleChange = event =>
    setData(state => ({
      ...state,
      [event.target.name]: {
        error: '',
        value: event.target.value,
      },
    }));

  const handleSubmission = event => {
    event.preventDefault();
    acceptInvitationForAdminTeacher({
      confirmPassword: data.confirmPassword.value,
      password: data.password.value,
      token,
      username: data.username.value,
    })
      .then(response => {
        dispatch(
          loginAfterInvitation(
            response.data.user,
            response.data.token,
            history,
            setUser,
            setUserOrganisation,
            setOrgPreferences,
          ),
        );
      })
      .catch(({response}) => {
        setVerifyUserName(false);
        setSuccessUsername(false);
        if (response?.data) {
          setData(state => {
            const cleanState = {...state};

            Object.keys(cleanState).forEach(key => {
              cleanState[key].error = '';
            });

            return {
              ...cleanState,
              [response.data.field]: {
                error: response.data.error,
              },
            };
          });
        }
      });
  };

  if ((!adminTeacher || !org || !token) && error) return <ErrorMessage />;

  return (
    <AdminTeacherInvitationContainer className="d-flex flex-column justify-content-center align-items-center">
      <div className="set-credentials-container">
        <img src={jcLogo} alt="logo" className="logo" />
        <CustomBox padding="32px 32px">
          <div className="header">
            <h2 className="set-credentials-title">Hi there</h2>
            <p className="set-credentials-description">
              Set a user name and password to get started with junglecat
            </p>
          </div>
          <form className="set-credentials-form" onSubmit={handleSubmission}>
            <CustomTextInput
              autoComplete="off"
              error={data.username.error}
              label="Enter a username"
              marginBottom="24px"
              borderRadius="8px"
              ErrorRight
              padding="19px 24px 19px 24px"
              name="username"
              inputErrorLogo={data.username.error}
              inputSuccessLogo={successUsername}
              inputVerifyLoader={verifyUserName}
              onChange={handleUserNameChange}
              placeholder="Enter a username"
              value={data.username.value}
            />
            <CustomTextInput
              autoComplete="new-password"
              error={data.password.error}
              label="Password"
              marginBottom="24px"
              borderRadius="8px"
              ErrorRight
              padding="19px 24px 19px 24px"
              name="password"
              onChange={handleChange}
              placeholder="Enter password"
              type={showPassword ? 'text' : 'password'}
              value={data.password.value}
              hide={showPassword}
              isPassword
              onClick={toggleShowPassword}
            />
            <CustomTextInput
              autoComplete="new-password"
              error={data.confirmPassword.error}
              label="Confirm Password"
              borderRadius="8px"
              ErrorRight
              padding="19px 24px 19px 24px"
              name="confirmPassword"
              onChange={handleChange}
              placeholder="Enter password"
              type={showPassword1 ? 'text' : 'password'}
              value={data.confirmPassword.value}
              hide={showPassword1}
              isPassword
              onClick={toggleShowPassword1}
            />
            <div className="button-container">
              <CustomButton
                width="100%"
                type="submit"
                bgColor="#40A1FF"
                borderRadius="8px"
                padding="16px 24px"
                isDisabled={
                  !data.username.value ||
                  !data.password.value ||
                  !data.confirmPassword.value ||
                  data.username.error ||
                  data.password.error ||
                  data.confirmPassword.error
                }
              >
                <>
                  {authState.isLoading ? (
                    <img
                      alt="Processing"
                      className="button-spinner"
                      src={buttonLoader}
                    />
                  ) : (
                    'Get Started'
                  )}
                </>
              </CustomButton>
            </div>
          </form>
        </CustomBox>
      </div>
    </AdminTeacherInvitationContainer>
  );
};

export default AdminTeacherInvitation;
