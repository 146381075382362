import {yupResolver} from '@hookform/resolvers/yup';

import {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {useDebounce, useLocalStorage} from 'react-use';

import {
  Button,
  Checkbox,
  Spacer,
  TextInput,
  TextInputWithData,
} from '../../../../../../components/common';
import {
  authSelector,
  joinOrganisationAdminByRtoCode,
  joinFreeAdmin,
  setError,
  setIsLoading,
  setJoinOrganisation,
} from '../../../../../../redux/authSlice';

import {useErrorMessage} from '../../../../../../utils/hooks';
import {JoinSchema} from '../../../../../../utils/validation';
import {AuthSwitchLink, ErrorAlert, Heading} from '../../../../components';

const FIELDS_IN_ORDER = [
  'rtoCode',
  'name',
  'email',
  'password',
  'accept',
  'organisationName',
];

export const JoinForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [organisationName, setOrganisationName] = useState('');

  const [OrgCode, setOrgCode] = useLocalStorage('OrgCode', null, {
    raw: true,
  });
  const [OrgId, setOrgId] = useLocalStorage('OrgId', null, {
    raw: true,
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      accept: false,
      rtoCode: '',
      OrgCode,
      OrgId,
      email: '',
      password: '',
      organisationName,
    },
    resolver: yupResolver(JoinSchema),
  });

  const {
    error: serverError,
    isLoading,
    isJoinLoading,
    joinOrganisation,
  } = useSelector(authSelector);
  const rtoCode = watch('rtoCode');

  const onSubmit = useCallback(
    data => {
      setOrgCode(data.OrgCode);
      setOrgId(data.OrgId);
      const details = {
        ...data,
      };

      dispatch(
        joinFreeAdmin(details, () => {
          dispatch(setJoinOrganisation(null));
          history.push(`/join-success?email=${data.email}`);
        }),
      );
    },
    [dispatch, history, setOrgCode, setOrgId],
  );

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {errorMessage, hasServerError, setHasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  useDebounce(
    async () => {
      dispatch(joinOrganisationAdminByRtoCode(rtoCode, setHasServerError));
    },
    1000,
    [dispatch, rtoCode],
  );

  useEffect(() => {
    if (!rtoCode.length) {
      dispatch(setJoinOrganisation(null));
    }
  }, [dispatch, rtoCode]);

  useEffect(() => {
    if (rtoCode.length) {
      dispatch(setIsLoading(true));
    }
    if (!joinOrganisation) {
      setValue('organisationName', '');
    }
    setOrganisationName('');
  }, [dispatch, joinOrganisation, rtoCode, setValue]);

  useEffect(() => {
    if (joinOrganisation) {
      setOrganisationName(joinOrganisation?.or_orgname || '');
      setValue('OrgCode', joinOrganisation?.or_rto_code || '');
      setValue('organisationName', joinOrganisation?.or_orgname || '');
      setValue('OrgId', joinOrganisation?.or_id_org || '');
    }
  }, [joinOrganisation, setValue]);

  return (
    <section className="align-items-center col-lg-6 col-md-12 d-flex flex-column justify-content-center main-section position-relative px-4">
      <ErrorAlert isVisible={!!errorMessage} message={errorMessage} />
      <main className="d-flex flex-column main-content w-100">
        <Spacer height="4rem" />
        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <Heading marginBottom="0rem" title="Welcome," />
          <Heading
            marginBottom="2rem"
            title="Create a Free Account"
            subtitle="Enter your basic details to create an account"
          />
          <form
            className="align-items-center d-flex flex-column w-100"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="rtoCode"
              render={fields => (
                <TextInputWithData
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.rtoCode}
                  hasFailed={hasServerError}
                  hasSucceeded={!!joinOrganisation}
                  isLarge
                  isLoading={isLoading}
                  label="RTO Code"
                  placeholder="Enter RTO code"
                  showData={organisationName}
                />
              )}
            />

            <Controller
              control={control}
              name="name"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={!!errors.name}
                  isLarge
                  label="Your Name"
                  placeholder="Enter your name"
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={hasServerError || !!errors.email}
                  isLarge
                  label="Email"
                  placeholder="Enter your email address"
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              render={fields => (
                <TextInput
                  {...fields}
                  className="mb-4"
                  hasError={!!errors.password}
                  isLarge
                  autoComplete="off"
                  label="Password"
                  placeholder="Enter password"
                  type="password"
                />
              )}
            />
            <div className="align-items-start d-flex mb-4 w-100">
              <Controller
                control={control}
                name="accept"
                render={fields => (
                  <Checkbox hasError={!!errors.accept} {...fields} />
                )}
              />
              <p className="accept-checkbox-label mb-0">
                I accept the <Link to="/">Privacy Policy</Link> and{' '}
                <Link to="/">Terms of Service</Link> of JungleCat
              </p>
            </div>
            <input {...register('OrgCode')} type="hidden" />
            <input {...register('OrgId')} type="hidden" />
            <input {...register('organisationName')} type="hidden" />
            <Button
              isLarge
              label="Join Junglecat"
              type="submit"
              isLoading={isJoinLoading}
            />
          </form>
          <AuthSwitchLink
            linkHref="/login"
            linkText="Login"
            onLinkClick={() => {
              clearServerError();
              dispatch(setJoinOrganisation(null));
            }}
            text="Already have an account?"
          />
        </div>
        <Spacer height="4rem" />
      </main>
    </section>
  );
};
