/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
// import {Color} from '../../../constants';

export const FilePreviewContainer = styled.div`
  .file-name {
    font-weight: 500;
    font-size: 1.125rem;
    color: #091018;
    margin-bottom: 0.5rem;
    overflow-wrap: anywhere;
  }

  .file-size {
    font-weight: 500;
    font-size: 0.75rem;
    color: #aeaeae;
    margin-bottom: unset;
  }
  .portfolio-btn {
    background-color: unset;
    outline: unset;
    border: unset;
  }
  .container-preview {
    height: 100%;
    width: 100%;
  }
  .container-preview {
    width: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      margin: unset;
    }
  }

  .iframe {
    height: 100%;
    width: 100%;
  }
  .audios {
    height: 400px;
    background-color: #d9ecff;
    display: flex;
    align-items: center;
    flex-direction: column;
    audio {
      width: 100%;
      bottom: 0;
      height: 100%;
      ::-webkit-media-controls-enclosure {
        background-color: #5ba1f2;
        border-radius: unset;
      }
      ::-webkit-media-controls-current-time-display,
      ::-webkit-media-controls-time-remaining-display {
        text-shadow: unset;
      }
    }
  }
  .videos {
    height: 500px;
    width: 100%;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
