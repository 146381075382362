/* eslint-disable react/prop-types */
import React, {useMemo, useState} from 'react';

// import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {
  EmptyState,
  Table,
} from '../../../../../../../../../../components/common';
import {
  DisplayModal,
  AudioModal,
  VideoModal,
  TextModal,
} from '../../../../../../../../Components';

import {Instruction} from './components';

import {TableSpinner} from '../../../../../../../../../Admin/components-new';
import {ReuseFileContainer} from './elements';
import SelectedTickImg from '../../../../../../../../../../assets/icons/selected-file-tick.svg';

import {
  studentWebSelector,
  setSelectedFile,
} from '../../../../../../../../../../redux/studentWebSlice';

export const ReuseAddedFiles = ({
  setSelectedReusedFile,
  selectedReusedFile,
}) => {
  const fileTypes = ['Video', 'Voice', 'Document', 'Image'];

  const [audioModalVisibility, setAudioModalVisibility] = useState(false);
  const [textModalVisibility, setTextModalVisibility] = useState(false);
  const [audioShowData, setAudioShowData] = useState(null);
  const [textShowData, setTextShowData] = useState(null);
  const [displayModalVisibility, setDisplayModalVisibility] = useState(false);
  const [displayShowData, setDisplayShowData] = useState(null);
  const [videoModalVisibility, setVideoModalVisibility] = useState(false);
  const [videoShowData, setVideoShowData] = useState(null);
  const dispatch = useDispatch();
  const {addedFiles, isLoading} = useSelector(studentWebSelector);

  // const history = useHistory();
  function findFileType(file) {
    switch (file?.fi_id_filetype) {
      case 1:
        return {
          duration: file.fi_filesize,
          id: file.fl_id_filelink,
          media: file.fi_location,
          text: file.fi_text_content || file.fi_filename,
          type: 'image',
          title: file.fi_filename,
          uploadDate: file.fi_uploadedate,
        };
      case 2:
        return {
          duration: file.fi_duration,
          id: file.fl_id_filelink,
          media: file.fi_location,
          text: file.fi_text_content || file.fi_filename,
          thumbnail: file.fi_thumbnail,
          type: 'video',
          title: file.fi_filename,
          uploadDate: file.fi_uploadedate,
        };
      case 3:
        return {
          id: file.fl_id_filelink,
          text: file.fi_text_content || file.fi_filename,
          type: 'text',
          title: file.fi_filename,
          uploadDate: file.fi_uploadedate,
        };
      case 5: {
        const split = file.fi_location.split('.');
        return {
          duration: file.fi_filesize,
          id: file.fl_id_filelink,
          media: file.fi_location,
          text: file.fi_text_content || file.fi_filename,
          type: 'document',
          title: file.fi_filename,
          pdf: split[split.length - 1] === 'pdf',
          uploadDate: file.fi_uploadedate,
        };
      }
      case 7:
        return {
          duration: file.fi_duration,
          id: file.fl_id_filelink,
          media: file.fi_location,
          text: file.fi_text_content || file.fi_filename,
          title: file.fi_filename,
          type: 'audio',
          uploadDate: file.fi_uploadedate,
        };
      default:
        return null;
    }
  }

  const fileTypeOptions = useMemo(
    () => [
      {label: 'All Files', value: ''},
      ...(fileTypes || [])?.map(item => ({
        label: item,
        value: item,
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const columns = useMemo(
    () => [
      {
        Cell: ({row: {original}}) => {
          const fileData = findFileType(original);
          return (
            <button
              type="button"
              onClick={() => {
                if (original.fi_id_file === selectedReusedFile?.fi_id_file) {
                  setSelectedReusedFile(null);
                  dispatch(setSelectedFile(null));
                } else {
                  setSelectedReusedFile(original);
                  dispatch(setSelectedFile(original));
                }
              }}
              className="file-btn"
              style={
                original.fi_id_file === selectedReusedFile?.fi_id_file
                  ? {
                      backgroundColor: '#ECF6FF',
                    }
                  : {}
              }
            >
              <div className="d-flex align-tems-center justify-content-between">
                <Instruction
                  data={fileData}
                  key={fileData.fi_id_file}
                  onShowClick={() => {
                    setSelectedReusedFile(original);
                    if (fileData?.type === 'audio') {
                      setAudioShowData(fileData);
                      setAudioModalVisibility(true);
                    } else if (
                      (fileData?.type === 'document' && fileData?.pdf) ||
                      fileData?.type === 'image'
                    ) {
                      setDisplayShowData(fileData);
                      setDisplayModalVisibility(true);
                    } else if (fileData?.type === 'video') {
                      setVideoShowData(fileData);
                      setVideoModalVisibility(true);
                    } else if (fileData?.type === 'text') {
                      setTextShowData(fileData);
                      setTextModalVisibility(true);
                    }
                  }}
                />
                {original.fi_id_file === selectedReusedFile?.fi_id_file ? (
                  <img src={SelectedTickImg} alt="tick" />
                ) : null}
              </div>
            </button>
          );
        },
        accessor: 'fi_filename',
        className: 'creator',
      },
      {
        Header: 'File Type',
        accessor: 'fileType.ft_type',
        className: 'file-type',
      },
    ],
    [dispatch, selectedReusedFile, setSelectedReusedFile],
  );

  // Add a serial number to each row and memoize the data.
  const data = useMemo(
    () => [
      ...(addedFiles || [])?.map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    ],
    [addedFiles],
  );

  return (
    <ReuseFileContainer>
      {displayModalVisibility ? (
        <DisplayModal
          onClose={() => {
            setDisplayModalVisibility(false);
            setDisplayShowData(null);
          }}
          showData={displayShowData}
        />
      ) : null}
      {videoModalVisibility ? (
        <VideoModal
          onClose={() => {
            setVideoModalVisibility(false);
            setVideoShowData(null);
          }}
          showData={videoShowData}
        />
      ) : null}
      {audioModalVisibility ? (
        <AudioModal
          onClose={() => {
            setAudioModalVisibility(false);
            setAudioShowData(null);
          }}
          showData={audioShowData}
        />
      ) : null}
      {textModalVisibility ? (
        <TextModal
          onClose={() => {
            setTextModalVisibility(false);
            setTextShowData(null);
          }}
          showData={textShowData}
        />
      ) : null}
      <div className="table-container">
        {isLoading ? <TableSpinner /> : null}
        {!isLoading && !addedFiles?.length ? (
          <EmptyState
            description="try adjusting the search or filter for what you are looking for"
            title="No Files Found"
          />
        ) : null}

        {!isLoading &&
        addedFiles?.length &&
        Array.isArray(addedFiles) &&
        !isLoading ? (
          <Table
            columns={columns}
            data={data}
            itemName="files"
            maxWidth="45rem"
            isSortedBy
            filters={[
              {
                column: 'fileType.ft_type',
                data: fileTypeOptions,
                itemName: 'file types',
              },
            ]}
          />
        ) : null}
      </div>
    </ReuseFileContainer>
  );
};
