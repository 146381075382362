import styled from 'styled-components';

export const AddSessionContainer = styled.div`
  min-height: 100vh;
  background-color: #f4f5f8;
  margin: 0rem -0.9375rem;
  padding: 0 0.9375rem;
`;

export const AddSessionFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .container-class {
    padding: 1.5rem 0;
    flex-shrink: 0;
    width: 55.5rem;
    margin: 7rem 0rem 9rem 0;

    @media (max-width: 55.5rem) {
      & {
        width: 98%;
      }
    }
  }

  .classheading {
    display: flex;
    flex-direction: column;

    .main-text {
      color: #3d4457;
      font-weight: 500;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .sub-text {
      font-size: 0.875rem;
      color: #8a8c94;
    }
  }

  .row-container-last {
    width: 50%;
    display: flex;
    flex-direction: row;

    .field-left,
    .field-right {
      width: 100%;
      margin-right: 1rem;
    }

    @media (max-width: 50rem) {
      & {
        width: 100%;
      }

      .field-left {
        margin-right: 1rem;
      }

      .field-right {
        margin-right: unset;
      }
    }

    @media (max-width: 33rem) {
      & {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .field-left {
        margin-right: unset;
      }

      .field-right {
        margin-top: 1rem;
      }
    }
  }
  .row-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .field-left,
    .field-right {
      width: 100%;
    }

    .field-left {
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
    }

    @media (max-width: 50rem) {
      & {
        display: flex;
        flex-direction: column;
      }

      .field-left {
        margin-bottom: 1rem;
      }

      .field-left1 {
        margin-right: unset;
      }

      .field-right {
        margin-bottom: 1rem;
      }
    }
  }
`;
