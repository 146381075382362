import {useEffect, useMemo, useState} from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import {
  MyPortfolioAndAttendanceConatiner,
  PortfolioContainer,
} from './elements';
import {EmptyState, Select} from '../../components/common';
import {TableSpinner} from '../Admin/components-new';
import {
  fetchAllPortfolioFiles,
  fetchAcademicYears,
  portfolioSelector,
  resetReduxValue,
} from '../../redux/portfolioSlice';
import {PortFolioFilesCard, ProfileCard, MyAttendance} from './components';
import {streamSelector} from '../../redux/streamSlice';
import Navbar from '../../components/shared/Navbar';

export const Portfolio = () => {
  const dispatch = useDispatch();
  const userType = localStorage.getItem('jc-user-type');
  const [portfolioTab, setPortfolioTab] = useState(true);
  const [attendanceTab, setAttendanceTab] = useState(false);
  const {username} = useParams();
  const {
    portfolioFiles,
    academicYears,
    isListLoading,
    enrolledQualification,
    unitAttendance,
    user,
    isLoading,
    userOrganisation,
  } = useSelector(portfolioSelector);
  // console.log(user);
  const [academicYear, setAcademicYear] = useState(null);
  const streamState = useSelector(streamSelector);

  const portfolioFilesGroup = useMemo(
    () =>
      portfolioFiles?.map(item => (
        <div className="card-class col-sm-6 col-lg-4 col-md-4 col-xl-4 col-xs-6 col-12 mb-4">
          <PortFolioFilesCard
            data={item}
            height="100%"
            unitAndClassName={`${item?.orgUnitInstance?.class?.oc_class} - ${item?.orgUnitInstance?.orgUnit?.unit?.tun_title}`}
            fileName={item?.fi_filename}
            uploadedDate={item?.fi_uploadedate}
            fileLocation={item?.fi_location}
            fileType={item?.fileType?.ft_type}
            thumbnail={
              item?.fi_cover_image ? item?.fi_cover_image : item?.fi_thumbnail
            }
            fileTitle={item?.fi_text_content}
            fileCode={item?.fi_code}
            fileCategory={item?.fileCategory?.fc_name}
            key={item?.fi_id_file}
            isArchive={streamState?.classDetails?.orui_is_archived}
            fileItem={item}
          />
        </div>
      )),
    [portfolioFiles, streamState],
  );

  useEffect(
    () => () => {
      dispatch(resetReduxValue());
    },
    [dispatch],
  );

  const academicYearsOptions = useMemo(
    () => [
      ...(academicYears || [])?.map(item => ({
        label: item,
        value: item,
      })),
    ],
    [academicYears],
  );
  // const years = useMemo(() => {
  //   const result = [];
  //   for (let year = 1990; year <= 2030; year += 1) {
  //     result.push({label: year, value: year});
  //   }
  //   return result;
  // }, []);

  useEffect(() => {
    dispatch(fetchAcademicYears(username));
  }, [dispatch, username]);

  const onSelectChange = academicYearId => {
    setAcademicYear(academicYearId);
    dispatch(fetchAllPortfolioFiles(username, academicYearId?.value));
  };
  useEffect(() => {
    setAcademicYear(
      academicYearsOptions.length
        ? {
            label: academicYearsOptions[0]?.label,
            value: academicYearsOptions[0]?.value,
          }
        : {
            label: 'No Academic Year',
            value: academicYearsOptions[0]?.value,
          },
    );
  }, [academicYearsOptions]);

  useEffect(() => {
    if (academicYears && !isLoading) {
      dispatch(fetchAllPortfolioFiles(username, academicYears[0]));
    }
  }, [dispatch, username, academicYears, isLoading]);

  return (
    <>
      <Helmet>
        <title>My Portfolio | JungleCat</title>
      </Helmet>
      <Navbar />
      <PortfolioContainer
        className={classNames({'is-admin': Number(userType) === 4})}
      >
        <div className="row portfolio-container">
          <div className="col-3 profile-container">
            {isListLoading ? (
              <div>
                <div className="mb-3">
                  <Skeleton height={223} width="100%" />
                </div>
                <div className="mb-3">
                  <Skeleton height={223} width="100%" />
                </div>
              </div>
            ) : (
              <ProfileCard
                enrolledQualification={enrolledQualification}
                user={user}
                userOrganisation={userOrganisation}
              />
            )}
          </div>
          <MyPortfolioAndAttendanceConatiner className="col-9 my-portfolio">
            <>
              <div>
                <div className="search-div">
                  <button
                    className={classNames('bottom', {
                      link: portfolioTab,
                    })}
                    type="button"
                    onClick={() => {
                      setPortfolioTab(true);
                      setAttendanceTab(false);
                      // setSearchTerm('');
                    }}
                  >
                    <p>Portfolio</p>
                  </button>
                  <button
                    className={classNames('bottom', {
                      link: attendanceTab,
                    })}
                    type="button"
                    onClick={() => {
                      setPortfolioTab(false);
                      setAttendanceTab(true);
                      // setSearchTerm('');
                    }}
                  >
                    <p>Attendance</p>
                  </button>
                </div>

                {isListLoading ? <TableSpinner /> : null}

                {portfolioTab && !isListLoading ? (
                  <>
                    <div className="portfolio-attendance-header">
                      <span className="flex-shrink-0 mb-0 title">
                        Portfolio
                      </span>
                      <div className="academic-year-search">
                        <Select
                          height="11rem"
                          width="9.625rem"
                          isLarge={false}
                          options={academicYearsOptions}
                          value={academicYear}
                          onChange={e => {
                            onSelectChange(e);
                          }}
                          className="academic-year"
                        />
                      </div>
                    </div>

                    {!isListLoading &&
                    Array.isArray(portfolioFilesGroup) &&
                    !portfolioFilesGroup?.length ? (
                      <EmptyState
                        description="It seems that there is no Files has been added to this Class"
                        title="No Files Found!"
                      />
                    ) : null}
                    <div className="row mt-4">{portfolioFilesGroup}</div>
                  </>
                ) : null}

                {attendanceTab && !isListLoading ? (
                  <>
                    <div className="portfolio-attendance-header">
                      <span className="flex-shrink-0 mb-0 title">
                        My Attendance
                      </span>
                      <div className="academic-year-search">
                        <Select
                          height="11rem"
                          width="9.625rem"
                          isLarge={false}
                          options={academicYearsOptions}
                          value={academicYear}
                          onChange={e => {
                            onSelectChange(e);
                          }}
                          className="academic-year"
                        />
                      </div>
                    </div>

                    {!isListLoading && !unitAttendance?.length ? (
                      <EmptyState
                        description="It seems no active class added to this academic year."
                        title="No Class Found!"
                      />
                    ) : (
                      <div className="container-active mt-4">
                        <MyAttendance />
                      </div>
                    )}

                    {/* <div className="row mt-4 ">hai</div> */}
                  </>
                ) : null}
              </div>
            </>
          </MyPortfolioAndAttendanceConatiner>
        </div>
      </PortfolioContainer>
    </>
  );
};
