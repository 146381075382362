import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {WelcomeWrapper} from './elements';
import {
  dashboardSelector,
  fetchDashboard,
} from '../../../../../../redux/dashboardSlice';
import {ReactComponent as JCLogo} from '../../../../../../assets/icons/jc_logo.svg';
import {ReactComponent as Cap} from '../../../../../../assets/icons/Navbar/cap.svg';
import {ReactComponent as Profile} from '../../../../../../assets/icons/profile.svg';
import {ReactComponent as HalfEllipse} from '../../../../../../assets/images/half-ellipse.svg';

const Welcome = ({classname, unitname, userAvatar, teacherAvatar}) => {
  const {dashboard} = useSelector(dashboardSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboard());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WelcomeWrapper>
      <header>
        <div className="logoAndClass">
          {dashboard?.basicInfoDetail?.or_logo_url ? (
            <img
              src={dashboard?.basicInfoDetail?.or_logo_url}
              alt="logo"
              className="orgLogo"
            />
          ) : null}
          <span className="divider" />
          <JCLogo className="jc-logo" />
          <Cap className="cap-image" />
          <div className="class-unit">
            <p>{classname}</p>
            <p>{unitname}</p>
          </div>
        </div>
        {userAvatar ? (
          <img
            src={userAvatar}
            className="rounded-circle"
            width="40px"
            height="40px"
            alt="profile"
          />
        ) : (
          <Profile className="rounded-circle" />
        )}
      </header>

      <main>
        <section>
          <div className="half-ellipse">
            <HalfEllipse />
            {teacherAvatar ? (
              <img src={teacherAvatar} alt="profile" />
            ) : (
              <Profile className="rounded-circle" />
            )}
            {/* <span className="time-badge">00:00</span> */}
          </div>
          <span className="welcome-msg">
            please wait, the teacher will join the call shortly
          </span>
        </section>
        {/* <span className="test-msg">Test sound and camera</span> */}
      </main>
    </WelcomeWrapper>
  );
};

Welcome.defaultProps = {
  classname: null,
  unitname: null,
  userAvatar: null,
  teacherAvatar: null,
};

Welcome.propTypes = {
  classname: PropTypes.string,
  unitname: PropTypes.string,
  userAvatar: PropTypes.string,
  teacherAvatar: PropTypes.string,
};

export default Welcome;
