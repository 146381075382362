/* eslint-disable react/prop-types */

import classNames from 'classnames';
import React from 'react';
import {Link} from 'react-router-dom';

import ArrowIcon from '../../../../../../assets/icons/arrow_right_blue.svg';
import {AssessmentBodyContainer} from './elements';

export const AssessmentBody = ({
  assessmentID,
  assignedCount,
  classID,
  gradedCount,
  isPublished,
  linkToView,
  submittedCount,
  isArchived,
}) => (
  <AssessmentBodyContainer className={classNames({'is-archived': isArchived})}>
    <div className="body-details">
      <div className="body_d-assigned">
        <p>{assignedCount}</p>
        <h3>Assigned</h3>
      </div>
      <div className="body_d-submitted">
        <p>{submittedCount}</p>
        <h3>Submitted</h3>
      </div>
      <div className="body_d-graded">
        <p>{gradedCount}</p>
        <h3>Graded</h3>
      </div>
    </div>
    <Link
      className="body-action"
      to={{
        pathname: isPublished
          ? linkToView
          : `/stream/${classID}/assessment/edit/${assessmentID}`,
        state: {isArchived},
      }}
      id="view-assessment-id"
    >
      View Assessment <img alt="View" className="body_a-icon" src={ArrowIcon} />
    </Link>
  </AssessmentBodyContainer>
);
