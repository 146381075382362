import {createSlice} from '@reduxjs/toolkit';

import * as studentWebApi from '../api/StudentWeb';

// const DELAY = 500;

const studentWebSlice = createSlice({
  initialState: {
    error: null,
    isLoading: false,
    createdAssessment: {},
    task: {},
    addedFiles: [],
    unlinkTaskFromFile: [],
    linkedFileId: null,
    isAddingFiles: false,
    selectedFile: null,
    newLinkedFile: null,
    isDeleteFiles: false,
    isLoadingForAction: false,
  },
  name: 'studentWeb',
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsAddingFiles(state, action) {
      state.isAddingFiles = action.payload;
    },
    setIsLoadingForAction(state, action) {
      state.isLoadingForAction = action.payload;
    },
    clearCreatedAssessment: state => {
      state.createdAssessment = {};
    },
    fetchCreatedAssessment: (state, action) => {
      state.createdAssessment = action.payload;
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },

    setAddedFiles: (state, action) => {
      state.addedFiles = action.payload;
    },
    setUnlinkTaskFromFile: (state, action) => {
      const file = state.addedFiles.find(
        item => item.fi_id_file === action.payload.fileId,
      );
      file.fileLinks = file.fileLinks.filter(
        item => item.fl_id_filelink !== action.payload.fileLinkId,
      );
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setSelectedFileUnlinkFromTask: (state, action) => {
      state.selectedFile.fileLinks = state.selectedFile.fileLinks.filter(
        item => item.fl_id_filelink !== action.payload,
      );
    },
    setSelectedFileAppendToTask: (state, action) => {
      state.selectedFile.fileLinks = [
        action.payload,
        ...state.selectedFile.fileLinks,
      ];
    },
    setNewLinkedFileToTask: (state, action) => {
      state.newLinkedFile = action.payload;
    },
    setLinkedFileIdToTask: (state, action) => {
      state.linkedFileId = action.payload;
    },
    setIsDeleteFile: (state, action) => {
      state.isDeleteFiles = action.payload;
    },
  },
});

export const {
  setError,
  setIsLoading,
  fetchCreatedAssessment,
  clearCreatedAssessment,
  setTask,
  setAddedFiles,
  setIsAddingFiles,
  setIsDeleteFile,
  setSelectedFile,
  setSelectedFileAppendToTask,
  setSelectedFileUnlinkFromTask,
  setIsLoadingForAction,
  setUnlinkTaskFromFile,
  setNewLinkedFileToTask,
  setLinkedFileIdToTask,
} = studentWebSlice.actions;

export const getAssessmentDetails = activityId => async dispatch => {
  dispatch(setIsLoading(true));

  try {
    const resp = await studentWebApi.getAssessmentDatas(activityId);

    dispatch(fetchCreatedAssessment(resp.data.assessments));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const getTaskDetailsById =
  (acvitityId, taskId, callback) => async dispatch => {
    dispatch(setIsLoading(true));

    try {
      const resp = await studentWebApi.getTaskData(acvitityId, taskId);
      dispatch(setTask(resp.data.Task));

      if (resp?.data) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const reuseAlreadyExistFiles = () => async dispatch => {
  dispatch(setIsLoading(true));
  try {
    const resp = await studentWebApi.reuseAlreadyExistFiles();
    dispatch(setAddedFiles(resp.data.addedFiles));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};
export const fileLinkedToTask =
  (formData, acvitityId, taskId, callback) => async dispatch => {
    dispatch(setIsAddingFiles(true));
    try {
      const resp = await studentWebApi.fileLinkedToTask(
        acvitityId,
        taskId,
        formData,
      );
      if (resp) {
        dispatch(setLinkedFileIdToTask(resp.data.fileAdded));
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddingFiles(false));
    }
  };

export const reusedFileLinkedToTask =
  (data, addToPortFolio, acvitityId, taskId, callback) => async dispatch => {
    dispatch(setIsAddingFiles(true));
    try {
      const linkedFile = {
        fileId: data?.previewFileData?.fi_id_file,
        addToPortFolio,
      };
      if (data?.currentTime !== null) {
        linkedFile.currentTime = data?.currentTime;
      }

      const resp = await studentWebApi.reusedFileLinkedToTask(
        acvitityId,
        taskId,
        linkedFile,
      );
      dispatch(setSelectedFileAppendToTask(resp.data.fileLink));
      dispatch(setNewLinkedFileToTask(resp.data.fileLink));

      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsAddingFiles(false));
    }
  };

export const removeFileFromTask =
  (acvitityId, taskId, fileId, fileLinkId, callback) => async dispatch => {
    dispatch(setIsDeleteFile(true));
    try {
      const resp = await studentWebApi.removeAddedTaskFile(
        acvitityId,
        taskId,
        fileId,
        fileLinkId,
      );

      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsDeleteFile(false));
    }
  };
export const unlinkFileFromTask =
  (deleteId, fileData, acvitityId, taskId, callback) => async dispatch => {
    dispatch(setIsDeleteFile(true));
    try {
      const resp = await studentWebApi.unlinkFileFromTask(
        acvitityId,
        taskId,
        deleteId,
      );
      dispatch(
        setUnlinkTaskFromFile({
          fileId: fileData?.fl_id_file,
          fileLinkId: deleteId,
        }),
      );
      dispatch(setSelectedFileUnlinkFromTask(deleteId));
      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsDeleteFile(false));
    }
  };

export const submitAssessement =
  (data, acvitityId, callback) => async dispatch => {
    dispatch(setIsLoadingForAction(true));
    try {
      const resp = await studentWebApi.submitAssessement(acvitityId, data);
      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoadingForAction(false));
    }
  };
export const resubmissionAssessementRequest =
  (data, acvitityId, callback) => async dispatch => {
    dispatch(setIsLoadingForAction(true));
    try {
      const resp = await studentWebApi.resubmissionAssessementRequest(
        acvitityId,
        data,
      );
      if (resp) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      dispatch(setIsLoadingForAction(false));
    }
  };

export const studentWebSelector = state => state.studentWeb;

export default studentWebSlice.reducer;
