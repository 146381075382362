/* eslint-disable react/prop-types */

import React, {useCallback} from 'react';

import {useDispatch} from 'react-redux';

import FilterCloseIcon from '../../../../../../../../assets/icons/filter-cancel.svg';
import {fetchSessionsByFilters} from '../../../../../../../../redux/sessionsSlice';

import {FilterListContainer} from './elements';

export const FilterList = ({
  onClose,
  courseId,
  setClassId,
  classId,
  setCourseId,
  setUnitId,
  unitId,
  setTeacherId,
  teacherId,
  setIsFilterApplied,
}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    filterData => {
      dispatch(
        fetchSessionsByFilters(filterData, () => {
          onClose();
        }),
      );
    },
    [dispatch, onClose],
  );

  return (
    <FilterListContainer>
      {courseId ? (
        <div className="filter-tag">
          {courseId?.label}
          <button
            type="button"
            onClick={() => {
              setCourseId(null);
              const filterData = {};
              onSubmit(filterData);
              setIsFilterApplied(false);
            }}
            id="filter-course-id"
          >
            <img src={FilterCloseIcon} alt="close" />
          </button>
        </div>
      ) : null}
      {classId ? (
        <div className="filter-tag">
          {classId?.label}
          <button
            type="button"
            onClick={() => {
              setClassId(null);
              const filterData = {
                course: courseId?.value,
                // location: data?.location?.value,
              };
              onSubmit(filterData);
            }}
            id="filter-class-id"
          >
            <img src={FilterCloseIcon} alt="close" />
          </button>
        </div>
      ) : null}
      {unitId ? (
        <div className="filter-tag">
          {unitId?.label}
          <button
            type="button"
            onClick={() => {
              setUnitId(null);
              const filterData = {
                course: courseId?.value,
                class: classId?.value,
              };
              onSubmit(filterData);
            }}
            id="filter-unit-id"
          >
            <img src={FilterCloseIcon} alt="close" />
          </button>
        </div>
      ) : null}
      {teacherId ? (
        <div className="filter-tag">
          {teacherId?.label}
          <button
            type="button"
            onClick={() => {
              setTeacherId(null);
              const filterData = {
                course: courseId?.value,
                class: classId?.value,
                unit: unitId?.value,
              };
              onSubmit(filterData);
            }}
            id="filter-teacher-id"
          >
            <img src={FilterCloseIcon} alt="close" />
          </button>
        </div>
      ) : null}
      {/* <button onClick={onSubmit} type="button">
        {courseId?.label}
      </button> */}
    </FilterListContainer>
  );
};
