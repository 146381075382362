/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import RecordRTC from 'recordrtc';

import closeIcon from '../../../../assets/icons/instruction-modal-close.svg';
import pauseRecordingIcon from '../../../../assets/icons/pause-recording.svg';
import recordVideoIcon from '../../../../assets/icons/record-video.svg';
import resumeRecordingIcon from '../../../../assets/icons/resume-recording.svg';
import retakeRecordedVideoIcon from '../../../../assets/icons/retake-recorded-video.svg';
import retakeRecordingIcon from '../../../../assets/icons/retake-recording.svg';
import stopRecordingIcon from '../../../../assets/icons/stop-recording.svg';
import CustomBox from '../../../../components/shared/CustomBox';
import CustomButton from '../../../../components/shared/CustomButton';
import CustomModal from '../../../../components/shared/CustomModal';
import CustomTextInput from '../../../../components/shared/CustomTextInput';
import {VideoModalContainer} from './elements';
import closeModal from '../../../../assets/icons/close-modal.svg';

function calculateTimeDuration(_seconds) {
  const hours = Math.floor(_seconds / 3600);
  let minutes = Math.floor((_seconds - hours * 3600) / 60);
  let seconds = Math.floor(_seconds - hours * 3600 - minutes * 60);

  if (minutes < 10) minutes = `0${minutes}`;

  if (seconds < 10) seconds = `0${seconds}`;

  return `${minutes}:${seconds}`;
}

export const VideoModal = ({
  editData,
  onClose,
  onSubmit,
  onUpdate,
  showData,
}) => {
  const recorder = useRef();
  const videoElement = useRef();
  const [isRecordingFinished, setIsRecordingFinished] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRetakeVisible, setIsRetakeVisible] = useState(null);
  const [recordedVideo, setVideo] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [stream, setStream] = useState(null);
  const [timer, setTimer] = useState(null);
  const [videoTitle, setVideoTitle] = useState('');

  const pauseRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.pauseRecording();
      setIsRecordingPaused(true);
    }
  }, [recorder]);

  const recordVideo = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then(camera => {
        recorder.current = RecordRTC(camera, {
          type: 'video',
        });
        recorder.current.startRecording();
        recorder.current.camera = camera;
        setIsRecording(true);
      });
  }, []);

  const resumeRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.resumeRecording();
      setIsRecordingPaused(false);
    }
  }, [recorder]);

  const retakeRecordedVideo = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then(camera => {
        setStream(camera);
        setIsRecording(false);
        setIsRecordingFinished(false);
        setIsRecordingPaused(false);
        setIsRetakeVisible(false);
        setSeconds(0);
        setTimer(null);
        setVideo(null);
        videoElement.current.controls = false;
        videoElement.current.muted = false;
        videoElement.current.volume = 0;
        videoElement.current.srcObject = camera;
      });
  }, []);

  const retakeRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        recorder.current.camera.stop();
        recorder.current.clearRecordedData();
        setIsRecording(false);
        setIsRecordingPaused(false);
        setSeconds(0);
        setTimer(null);
      });
    }
  }, [recorder]);

  const stopRecording = useCallback(() => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        const blob = recorder.current.getBlob();
        videoElement.current.srcObject = null;
        videoElement.current.src = URL.createObjectURL(blob);
        videoElement.current.muted = false;
        videoElement.current.volume = 1;
        videoElement.current.controls = true;
        recorder.current.camera.stop();
        recorder.current.destroy();
        recorder.current = null;
        setVideo(blob);
        setIsRecording(false);
        setIsRecordingFinished(true);

        if (stream) stream.getTracks().forEach(track => track.stop());
      });
    }
  }, [recorder, stream]);

  useEffect(() => {
    if (editData || showData) {
      const videoData = editData || showData;
      setVideoTitle(videoData.text);
      videoElement.current.srcObject = null;
      videoElement.current.src =
        typeof videoData.media === 'string'
          ? videoData.media
          : URL.createObjectURL(videoData.media);
      videoElement.current.muted = false;
      videoElement.current.volume = 1;
      videoElement.current.controls = true;
      setIsRecordingFinished(true);
    } else
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .then(camera => {
          setStream(camera);
          videoElement.current.muted = false;
          videoElement.current.volume = 0;
          videoElement.current.srcObject = camera;
        });
  }, [editData, showData]);

  useEffect(() => {
    if (timer && (isRecordingFinished || isRecordingPaused)) {
      clearInterval(timer);
      setTimer(null);
    } else if (!timer && isRecording && !isRecordingPaused)
      setTimer(
        setInterval(() => setSeconds(previousValue => previousValue + 1), 1000),
      );

    return () => (timer ? clearInterval(timer) : null);
  }, [isRecording, isRecordingFinished, isRecordingPaused, timer]);

  useEffect(
    () => () => {
      if (stream) stream.getTracks().forEach(track => track.stop());
    },
    [stream],
  );

  return (
    <CustomModal isVisible>
      <VideoModalContainer className="video-modal">
        <div className="video-modal-custom-full">
          <div className="close-btn-video">
            <button
              onClick={onClose}
              type="button"
              className="video-modal-close"
            >
              <img alt="Close" src={closeModal} />
            </button>
          </div>
          <CustomBox padding="1.5rem 0" className="video-modal-custom">
            <div
              className="video-modal-header"
              style={
                showData
                  ? {
                      justifyContent: 'space-between',
                    }
                  : {}
              }
            >
              {showData ? (
                <h5>{showData?.text}</h5>
              ) : (
                <h2>{editData ? 'Edit' : 'Add'} Video Instruction</h2>
              )}
              <button
                className="modal-close-button"
                onClick={onClose}
                type="button"
              >
                <img alt="Close Modal" src={closeIcon} />
              </button>
            </div>
            <div className="video-modal-body">
              <div className="video-container">
                {isRecording ? (
                  <div className="video-record-time">
                    <div />
                    <span>{calculateTimeDuration(seconds)}</span>
                  </div>
                ) : null}
                {!editData && !showData && isRecordingFinished ? (
                  <div className="retake-video-button-container">
                    <button
                      className={`retake-video-button ${
                        isRetakeVisible ? 'is-toggled' : ''
                      }`}
                      onClick={() => setIsRetakeVisible(true)}
                      type="button"
                    >
                      <img alt="Retake" src={retakeRecordedVideoIcon} />
                      <span>Retake Video</span>
                    </button>
                    {isRetakeVisible ? (
                      <div className="retake-confirmation">
                        <div className="retake-confirmation-arrow" />
                        <p>Are you sure to retake the video?</p>
                        <div className="retake-button-group">
                          <button
                            className="retake-cancel-button"
                            onClick={() => setIsRetakeVisible(false)}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className="retake-confirm-button"
                            onClick={() => retakeRecordedVideo()}
                            type="button"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <video autoPlay ref={videoElement} />
                {!isRecordingFinished ? (
                  <div className="video-modal-controls">
                    {isRecording ? (
                      <>
                        <button
                          className="small-button"
                          key="retake-recording"
                          onClick={retakeRecording}
                          type="button"
                        >
                          <img
                            alt="Retake Recording"
                            src={retakeRecordingIcon}
                          />
                        </button>
                        <button
                          className="stop-recording-button"
                          key="stop-recording"
                          onClick={stopRecording}
                          type="button"
                        >
                          <img alt="Stop Recording" src={stopRecordingIcon} />
                        </button>
                        <button
                          className="small-button"
                          key="control-playback"
                          onClick={
                            isRecordingPaused ? resumeRecording : pauseRecording
                          }
                          type="button"
                        >
                          <img
                            alt="Pause Recording"
                            src={
                              isRecordingPaused
                                ? resumeRecordingIcon
                                : pauseRecordingIcon
                            }
                          />
                        </button>
                      </>
                    ) : (
                      <button
                        key="record-video"
                        onClick={recordVideo}
                        type="button"
                      >
                        <img alt="Record Video" src={recordVideoIcon} />
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            {showData ? null : (
              <div className="video-modal-footer">
                <div className="video-title">
                  <CustomTextInput
                    label="Video Title"
                    onChange={event => setVideoTitle(event.target.value)}
                    value={videoTitle}
                  />
                </div>
                <div className="video-instruction-buttons">
                  <button
                    className="cancel-button"
                    onClick={onClose}
                    type="button"
                  >
                    Cancel
                  </button>
                  <CustomButton
                    className="add-video-instruction"
                    isDisabled={
                      editData ? !videoTitle : !recordedVideo || !videoTitle
                    }
                    onClick={() => {
                      if (editData)
                        onUpdate(editData.linkID, {
                          textContent: videoTitle,
                        });
                      else {
                        const body = new FormData();
                        body.append('duration', seconds);
                        body.append(
                          'file',
                          new File([recordedVideo], `${videoTitle}.webm`, {
                            type: 'video/webm',
                          }),
                        );
                        body.append('title', videoTitle);
                        onSubmit(body);
                      }

                      setVideoTitle('');
                      onClose();
                    }}
                    text={editData ? 'Update' : 'Add Instruction'}
                  />
                </div>
              </div>
            )}
          </CustomBox>
        </div>
      </VideoModalContainer>
    </CustomModal>
  );
};
