/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import {VideoContainerWrapper} from './elements';
import {DefaultLayout, ScreenSharingLayout} from './components';

const VideoContainer = ({
  remoteHost,
  layoutMode,
  remoteUsers,
  localVideoTrack,
  localAudioTrack,
  isSharingScreen,
  localScreenVideoTrack,
  remoteScreenAudioTrack,
  remoteScreenVideoTrack,
  speakingStates,
}) => (
  <VideoContainerWrapper>
    {(() => {
      if (layoutMode === 0) {
        return (
          <DefaultLayout
            remoteHost={remoteHost}
            localVideoTrack={localVideoTrack}
            localAudioTrack={localAudioTrack}
            remoteUsers={remoteUsers}
            speakingStates={speakingStates}
          />
        );
      }
      if (layoutMode === 1) {
        return (
          <ScreenSharingLayout
            localVideoTrack={localVideoTrack}
            localAudioTrack={localAudioTrack}
            isSharingScreen={isSharingScreen}
            localScreenVideoTrack={localScreenVideoTrack}
            remoteScreenVideoTrack={remoteScreenVideoTrack}
            remoteScreenAudioTrack={remoteScreenAudioTrack}
            remoteUsers={remoteUsers}
            remoteHost={remoteHost}
            speakingStates={speakingStates}
          />
        );
      }
    })()}
  </VideoContainerWrapper>
);

VideoContainer.defaultProps = {
  remoteHost: null,
  layoutMode: 0,
  remoteUsers: [],
  localVideoTrack: null,
  localAudioTrack: null,
  isSharingScreen: false,
  localScreenVideoTrack: null,
  remoteScreenAudioTrack: null,
  remoteScreenVideoTrack: null,
  speakingStates: {},
};

VideoContainer.propTypes = {
  remoteHost: PropTypes.objectOf(PropTypes.any),
  layoutMode: PropTypes.number,
  remoteUsers: PropTypes.arrayOf(PropTypes.object),
  localVideoTrack: PropTypes.objectOf(PropTypes.any),
  localAudioTrack: PropTypes.objectOf(PropTypes.any),
  isSharingScreen: PropTypes.bool,
  localScreenVideoTrack: PropTypes.objectOf(PropTypes.any),
  remoteScreenAudioTrack: PropTypes.objectOf(PropTypes.any),
  remoteScreenVideoTrack: PropTypes.objectOf(PropTypes.any),
  speakingStates: PropTypes.objectOf(PropTypes.any),
};

export default VideoContainer;
