import {createSlice} from '@reduxjs/toolkit';

import * as qualificationsApi from '../api/admin/qualifications';

const DELAY = 500;
const initialFormError = {qualificationCode: '', qualificationName: ''};
const initialUnitFormError = {unitCode: ''};

const qualificationsSlice = createSlice({
  initialState: {
    error: null,
    isListLoading: true,
    isQualificationLoading: false,
    qualifications: null,
    formError: initialFormError,
    isAddQualificationLoading: false,
    currentQualification: null,
    unitFormError: initialUnitFormError,
    alreadyExistQname: [],
    alreadyExistQcode: [],
    validQualification: [],
    duplicateData: [],
    duplicateUnitData: [],
    alreadyExistUcode: [],
    validUnitsToQualification: [],
  },
  name: 'qualifications',
  reducers: {
    editQualification: (state, action) => {
      const qualificationIndex = state.qualifications.findIndex(
        qualification =>
          qualification.oq_id_orgqual === action.payload.oq_id_orgqual,
      );
      state.qualifications[qualificationIndex] = action.payload;
    },
    removeQualificationById(state, action) {
      state.qualifications = state.qualifications.filter(
        qualification =>
          qualification.qualification.tqual_id_tgovqualification !==
          action.payload,
      );
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIsListLoading(state, action) {
      state.isListLoading = action.payload;
    },
    setIsQualificationLoading(state, action) {
      state.isQualificationLoading = action.payload;
    },
    setQualifications(state, action) {
      state.qualifications = action.payload;
    },
    setIsAddQualificationLoading(state, action) {
      state.isAddQualificationLoading = action.payload;
    },
    setFormError(state, action) {
      state.formError[action.payload.field] = action.payload.message;
    },
    clearFormError(state) {
      state.formError = initialFormError;
    },
    setCurrentNewQualification: (state, action) => {
      state.currentQualification = action.payload;
    },
    setAlreadyExistQcode: (state, action) => {
      state.alreadyExistQcode = action.payload;
    },
    setDuplicateData: (state, action) => {
      state.duplicateData = action.payload;
    },
    setDuplicateUnitData: (state, action) => {
      state.duplicateUnitData = action.payload;
    },
    setAlreadyExistQname: (state, action) => {
      state.alreadyExistQname = action.payload;
    },
    setValidQualification: (state, action) => {
      state.validQualification = action.payload;
    },
    setAlreadyExistUcode: (state, action) => {
      state.alreadyExistUcode = action.payload;
    },
    setAlreadyExistUname: (state, action) => {
      state.alreadyExistUname = action.payload;
    },
    setValidUnitsToQualification: (state, action) => {
      state.validUnitsToQualification = action.payload;
    },
    setUnitFormError(state, action) {
      state.unitFormError[action.payload.field] = action.payload.message;
    },
    clearUnitFormError(state) {
      state.unitFormError = initialUnitFormError;
    },
  },
});

export const {
  editQualification,
  removeQualificationById,
  setError,
  setIsListLoading,
  setIsQualificationLoading,
  setQualifications,
  setIsAddQualificationLoading,
  setFormError,
  clearFormError,
  setCurrentNewQualification,
  setAlreadyExistQcode,
  setAlreadyExistQname,
  setValidQualification,
  setAlreadyExistUcode,
  setDuplicateData,
  setDuplicateUnitData,
  setAlreadyExistUname,
  setValidUnitsToQualification,
  setUnitFormError,
  clearUnitFormError,
} = qualificationsSlice.actions;

export const deleteQualification =
  (qualificationId, callback) => async dispatch => {
    dispatch(setIsQualificationLoading(true));

    try {
      await qualificationsApi.deleteQualification(qualificationId);
      dispatch(removeQualificationById(qualificationId));

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      setTimeout(() => {
        dispatch(setIsQualificationLoading(false));
      }, DELAY);
    }
  };

export const fetchAllQualifications = () => async dispatch => {
  dispatch(setIsListLoading(true));

  try {
    const response = await qualificationsApi.fetchAllQualifications();
    dispatch(setQualifications(response.data?.allQualifications || []));
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    dispatch(setIsListLoading(false));
  }
};

export const updateQualification =
  (qualificationId, data, callback) => async dispatch => {
    dispatch(setIsQualificationLoading(true));

    try {
      const response = await qualificationsApi.updateQualification(
        qualificationId,
        {
          quCode: data.qualificationCode,
          quName: data.qualificationName,
        },
      );

      if (response.data?.orgQualification) {
        dispatch(editQualification(response.data?.orgQualification));

        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      setTimeout(() => {
        dispatch(setIsQualificationLoading(false));
      }, DELAY);
    }
  };
// Add Qualification Flow
export const individualQualificationCheck =
  (data, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.individualQualificationValid(data);
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );

      if (error?.response?.data?.error?.field) {
        dispatch(setFormError(error?.response?.data?.error));
      }
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const addNewOrgQualification = (data, callback) => async dispatch => {
  dispatch(setIsAddQualificationLoading(true));
  try {
    const resp = await qualificationsApi.addOrgQualification(data);
    dispatch(setCurrentNewQualification(resp.data.oneQualifications));
    dispatch(setIsAddQualificationLoading(false));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    setTimeout(() => {
      dispatch(setIsAddQualificationLoading(false));
    }, DELAY);
  }
};
export const addQualificationSpread = (data, callback) => async dispatch => {
  dispatch(setIsAddQualificationLoading(true));
  try {
    await qualificationsApi.addQualificationsSpread(data);

    dispatch(setIsAddQualificationLoading(false));
    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(
      setError(error?.response?.data?.message || 'Something went wrong.'),
    );
  } finally {
    setTimeout(() => {
      dispatch(setIsAddQualificationLoading(false));
    }, DELAY);
  }
};
export const spreadSheetQualificationsCheck =
  (data, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    const resp = await qualificationsApi.spreadSheetQualificationsValid(data);
    dispatch(setAlreadyExistQname(resp.data.alreadyQualificationName));
    dispatch(setAlreadyExistQcode(resp.data.alreadyQualificationCode));
    dispatch(setDuplicateData(resp.data.duplicateData));
    dispatch(setValidQualification(resp.data.validQualifications));
    dispatch(setIsAddQualificationLoading(false));

    if (callback) {
      if (
        resp.data.validQualifications.length === data.length &&
        !resp.data.duplicateData.length
      ) {
        dispatch(
          addQualificationSpread(resp.data.validQualifications, callback),
        );
      } else {
        callback();
      }
    }
  };
export const individualUnitCheck =
  (data, qualificationId, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.individualUnitValid(data, qualificationId);
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
      if (error?.response?.data?.error?.field) {
        dispatch(setUnitFormError(error?.response?.data?.error));
      }
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const addUnitsToQualificationManual =
  (data, qualificationId, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.addUnitsUnderQualificationManual(
        data,
        qualificationId,
      );

      dispatch(setIsAddQualificationLoading(false));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const addUnitsToQualificationSpread =
  (data, qualificationId, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.addUnitsUnderQualificationSpread(
        data,
        qualificationId,
      );
      callback();
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const spreadSheetUnitCheckToQualification =
  (data, qualificationId, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));

    const resp = await qualificationsApi.unitSpreadsheetValidation(
      data,
      qualificationId,
    );
    dispatch(setAlreadyExistUcode(resp.data.alreadyUnitCode));
    dispatch(setDuplicateUnitData(resp.data.duplicateUnitData));
    dispatch(setValidUnitsToQualification(resp.data.validUnits));
    if (callback) {
      if (
        resp.data.validUnits.length === data.length &&
        !resp.data.duplicateUnitData.length
      ) {
        dispatch(
          addUnitsToQualificationSpread(
            resp.data.validUnits,
            qualificationId,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };
export const individualUnitCheckByQualId =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.individualUnitValid(
        data,
        qualificationData.qualificationId,
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
      if (error?.response?.data?.error?.field) {
        dispatch(setUnitFormError(error?.response?.data?.error));
      }
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const addUnitsByQualificationIdManual =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.addUnitsUnderQualificationManual(
        data,
        qualificationData.qualificationId,
      );

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const addUnitsByQualIdSpread =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    try {
      await qualificationsApi.addUnitsUnderQualificationSpread(
        data,
        qualificationData.qualificationId,
      );

      if (callback) {
        callback(data);
      }
    } catch (error) {
      dispatch(
        setError(error?.response?.data?.message || 'Something went wrong.'),
      );
    } finally {
      setTimeout(() => {
        dispatch(setIsAddQualificationLoading(false));
      }, DELAY);
    }
  };
export const spreadSheetUnitCheckByQualificationId =
  (data, qualificationData, callback) => async dispatch => {
    dispatch(setIsAddQualificationLoading(true));
    const resp = await qualificationsApi.unitSpreadsheetValidation(
      data,
      qualificationData.qualificationId,
    );
    dispatch(setAlreadyExistUcode(resp.data.alreadyUnitCode));
    dispatch(setDuplicateUnitData(resp.data.duplicateUnitData));
    dispatch(setValidUnitsToQualification(resp.data.validUnits));
    dispatch(setIsAddQualificationLoading(false));
    if (callback) {
      if (
        resp.data.validUnits.length === data.length &&
        !resp.data.duplicateUnitData.length
      ) {
        dispatch(
          addUnitsByQualIdSpread(
            resp.data.validUnits,
            qualificationData,
            callback,
          ),
        );
      } else {
        callback();
      }
    }
  };
export const qualificationsSelector = state => state.qualifications;

export default qualificationsSlice.reducer;
