/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch} from 'react-redux';
import {Controller} from 'react-hook-form';
import {Menu, MenuItem} from 'react-bootstrap-typeahead';

import {UploadCoverImage} from '../UploadCoverImage';
import {UploadSection} from '../UploadSection';
import {TextInput, TypeAhead} from '../../../../../../../../components/common';
import {
  clearFormError,
  individualFileCodeValidationInEdit,
  addNewCategory,
} from '../../../../../../../../redux/teacherLibrarySlice';

import {EditFormContainer} from './elements';

export const EditForm = ({
  uploadDocument,
  uploadCoverImage,
  setUploadCoverImage,
  register,
  setValue,
  categoryDropdown,
  errors,
  categoryAsOptions,
  control,
  formError,
  watch,
  setSelectCategory,
  selectCategory,
  setIsEditCoverImage,
  isEditCoverImage,

  editDocument,
  orgUnitInstanceId,
}) => {
  const dispatch = useDispatch();
  return (
    <EditFormContainer>
      <form>
        <div className="add-document-fields">
          <div className="mt-4">
            <UploadSection
              title="Add Document"
              upload={uploadDocument}
              accept=".doc,.csv,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf,application/msword,.jpg,.jpeg,.png,.mov,.mp4"
            />
          </div>

          <div className="mt-4">
            <UploadCoverImage
              title="Cover Image"
              upload={uploadCoverImage}
              setUpload={setUploadCoverImage}
              accept=".jpg,.jpeg,.png"
              setIsEdit={setIsEditCoverImage}
              isEdit={isEditCoverImage}
            />
          </div>

          <div className="title-code">
            <div className="code">
              <Controller
                control={control}
                name="fileCode"
                render={fields => (
                  <TextInput
                    {...fields}
                    errorMessage={
                      errors.fileCode?.message || formError.fileCode
                    }
                    hasError={!!errors.fileCode || !!formError?.fileCode}
                    className="mt-4"
                    label="Code"
                    placeholder="Enter Code"
                    onBlur={e => {
                      fields.onBlur(e);
                      const fileId = editDocument?.fi_id_file;
                      dispatch(
                        individualFileCodeValidationInEdit(
                          orgUnitInstanceId,
                          e.target.value,
                          fileId,
                        ),
                      );
                    }}
                    onChange={e => {
                      fields.onChange(e);
                      dispatch(clearFormError());
                    }}
                    id="code-id"
                  />
                )}
              />
            </div>
            <div className="document-title">
              <Controller
                control={control}
                name="documentTitle"
                render={fields => (
                  <TextInput
                    {...fields}
                    errorMessage={errors.documentTitle?.message}
                    className="mt-4"
                    hasError={!!errors.documentTitle}
                    label="File Title"
                    placeholder="Enter the File Title"
                    id="file-title"
                  />
                )}
              />
            </div>
          </div>

          <div className="mt-4">
            <TypeAhead
              errorMessage={
                !watch('fileCategory') ? errors.fileCategory?.message : null
              }
              hasError={!!errors.fileCategory}
              label="Category"
              placeholder="Select category"
              id="file-category-id"
              labelKey="name"
              onInputChange={value => {
                setSelectCategory(null);
                if (
                  !categoryAsOptions.find(
                    item => item?.name?.toLowerCase() === value.toLowerCase(),
                  )
                ) {
                  setValue('fileCategory', value);
                }
              }}
              ref={categoryDropdown}
              selected={selectCategory}
              value={selectCategory}
              options={categoryAsOptions}
              onChange={e => {
                if (e) {
                  if (
                    categoryAsOptions.find(
                      item =>
                        item?.name?.toLowerCase() === e[0]?.name?.toLowerCase(),
                    )
                  ) {
                    setSelectCategory(e);
                    setValue('fileCategory', e[0]);
                  }
                }
              }}
              onBlur={e => {
                if (e.target.value === '') {
                  setSelectCategory([
                    {
                      name: editDocument?.fileCategory?.fc_name,
                      id: editDocument?.fileCategory?.fc_id_filecategory,
                    },
                  ]);
                }
              }}
              renderMenu={(results, menuProps, e) => {
                setSelectCategory(null);
                if (!results?.length)
                  return (
                    <Menu {...menuProps} className="add-menu">
                      <MenuItem className="add-menu-item">
                        <button
                          type="button"
                          className="add-category-btn"
                          onClick={() => {
                            const newCategory = e.text;
                            dispatch(
                              addNewCategory(
                                orgUnitInstanceId,
                                newCategory,
                                data => {
                                  categoryDropdown.current.hideMenu();
                                  setValue('fileCategory', {
                                    name: data?.fc_name,
                                    id: data?.fc_id_filecategory,
                                  });
                                },
                              ),
                            );
                          }}
                        >
                          {`Add ${e.text} Category`}
                        </button>
                      </MenuItem>
                    </Menu>
                  );

                return (
                  <Menu {...menuProps} className="add-menu-list">
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index}>
                        {result.name}
                      </MenuItem>
                    ))}
                    {!categoryAsOptions.find(
                      item =>
                        item?.name?.toLowerCase() === e.text.toLowerCase(),
                    ) ? (
                      <MenuItem className="add-menu-item-list">
                        <button
                          type="button"
                          className="add-category-btn"
                          onClick={() => {
                            const newCategory = e.text;
                            dispatch(
                              addNewCategory(
                                orgUnitInstanceId,
                                newCategory,
                                data => {
                                  categoryDropdown.current.hideMenu();
                                  setValue('fileCategory', {
                                    name: data?.fc_name,
                                    id: data?.fc_id_filecategory,
                                  });
                                },
                              ),
                            );
                          }}
                        >
                          {`Add ${e.text} as a new Category`}
                        </button>
                      </MenuItem>
                    ) : null}
                  </Menu>
                );
              }}
              dropUp
            />
          </div>
          <input type="hidden" {...register('fileCategory')} />
        </div>
      </form>
    </EditFormContainer>
  );
};
