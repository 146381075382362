import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {useRouteMatch, useLocation} from 'react-router-dom';
import {useLocalStorage} from 'react-use';
import ReactBootstrapModal from 'react-bootstrap/Modal';

import {SharePost} from './components/SharePost';
import StyledStreamLive, {StreamContainer} from '../../elements/StreamLive';

import PaginationSpinnerIcon from '../../../../assets/icons/pagination-spinner.svg';
import {fetchAllStream, streamSelector} from '../../../../redux/streamSlice';
import {AssessmentCard, PostCard} from '../../components';
import SessionSidebar from './components/SessionSidebar';
import StudentSessionSidebar from './components/StudentSessionSideBar';

export const Stream = () => {
  const [userType] = useLocalStorage('jc-user-type', '', {
    raw: true,
  });
  const intersectedElementObserver = useRef();
  const {
    stream,
    isPaginatingLoading,
    streamCount,
    classDetails,
    isListLoading,
  } = useSelector(streamSelector);
  const match = useRouteMatch();
  const classId = match.params?.id;
  const {state} = useLocation();
  const searchActivityOrgUnitInstanceId = state?.activityId?.toString();

  const dispatch = useDispatch();
  const [editingPostId, setEditingPostId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const intersectedElement = useCallback(
    node => {
      if (intersectedElementObserver.current) {
        intersectedElementObserver.current.disconnect();
      }

      intersectedElementObserver.current = new window.IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            if (
              !isPaginatingLoading &&
              streamCount > stream.length &&
              userType
            ) {
              if (searchActivityOrgUnitInstanceId?.length) {
                dispatch(
                  fetchAllStream(
                    classId,
                    Number(userType),
                    stream?.length,
                    searchActivityOrgUnitInstanceId,
                  ),
                );
              }
              dispatch(
                fetchAllStream(classId, Number(userType), stream?.length),
              );
            }
          }
        },
      );

      if (node) {
        intersectedElementObserver.current.observe(node);
      }
    },

    [
      classId,
      dispatch,
      isPaginatingLoading,
      searchActivityOrgUnitInstanceId,
      stream?.length,
      streamCount,
      userType,
    ],
  );

  useEffect(() => {
    if (searchActivityOrgUnitInstanceId?.length) {
      dispatch(
        fetchAllStream(
          classId,
          Number(userType),
          null,
          searchActivityOrgUnitInstanceId,
        ),
      );
    } else {
      dispatch(fetchAllStream(classId, Number(userType)));
    }
  }, [classId, dispatch, searchActivityOrgUnitInstanceId, userType]);

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const letters = {};
  // useEffect(() => {
  //   const [...classes] = document.getElementsByClassName('stream-card');
  //   classes?.map(item => {
  //     const letter = item.id || '';
  //     if (letter && !(letter in letters)) {
  //       letters[letter] = item;
  //       return letters[letter];
  //     }
  //     return null;
  //   });
  // }, [letters, stream]);

  // // onclick function for current element of letter and scroll property

  // useEffect(() => {
  //   if (searchActivityOrgUnitInstanceId?.length) {
  //     const letter = searchActivityOrgUnitInstanceId;

  //     if (letters[letter]) {
  //       const headerOffset =
  //         document.getElementById('navbar-container')?.offsetHeight + 80;

  //       const elementPosition = letters[letter].getBoundingClientRect().top;
  //       const offsetPosition =
  //         elementPosition + window.pageYOffset - headerOffset;

  //       window.scrollTo({
  //         top: offsetPosition,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // }, [letters, searchActivityOrgUnitInstanceId]);

  return (
    <>
      <Helmet>
        <title>Stream | JungleCat</title>
      </Helmet>
      <StreamContainer className="row stream-content">
        <div
          className={classNames('stream-container', {
            'is-archive': classDetails?.orui_is_archived,
          })}
        >
          <div
            className={
              Number(userType) === 3 || Number(userType) === 2
                ? 'stream-bottom-content'
                : 'stream-student-content'
            }
          >
            <StyledStreamLive>
              <SharePost
                cancelEditing={() => setEditingPostId(null)}
                isEditing={!!editingPostId}
              />
              {isListLoading ? (
                <div className="mt-3">
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                  <div className="mb-3">
                    <Skeleton height={223} width="100%" />
                  </div>
                </div>
              ) : null}
              {!isListLoading &&
                stream &&
                stream?.map((post, index) => {
                  const isIntersectedElement = index === stream.length - 2;

                  if (Number(post.ac_id_activitytype) === 1) {
                    const gradedCount = (
                      post.students || post.activityOrgUnitInstance[0].students
                    ).filter(student => student.aouis_status === 3).length;
                    const submittedCount = (
                      post.students || post.activityOrgUnitInstance[0].students
                    ).filter(student => student.aouis_status === 2).length;
                    return (
                      <AssessmentCard
                        assessment={post}
                        classID={classId}
                        gradedCount={gradedCount}
                        intersectedElement={intersectedElement}
                        isIntersectedElement={isIntersectedElement}
                        isStream
                        key={post.aoui_id_activityorgunitinstance}
                        submittedCount={submittedCount}
                        isArchived={classDetails?.orui_is_archived}
                        // className="stream-card"
                      />
                    );
                  }
                  return (
                    <>
                      <ReactBootstrapModal
                        centered
                        onHide={() => {}}
                        show={
                          editingPostId === post.aoui_id_activityorgunitinstance
                        }
                        contentClassName="edit-share-post-modal"
                      >
                        <SharePost
                          files={post.files}
                          onCancel={() => {
                            setEditingPostId(null);
                          }}
                          post={post}
                          setIsModalOpen={setIsEditModalOpen}
                          isModalOpen={isEditModalOpen}
                        />
                      </ReactBootstrapModal>
                      {editingPostId !==
                      post.aoui_id_activityorgunitinstance ? (
                        <PostCard
                          intersectedElement={intersectedElement}
                          isIntersectedElement={isIntersectedElement}
                          post={post}
                          key={post.aoui_id_activityorgunitinstance}
                          onEdit={() => {
                            setEditingPostId(
                              post.aoui_id_activityorgunitinstance,
                            );
                            setIsEditModalOpen(true);
                          }}
                          isArchived={classDetails?.orui_is_archived}
                          // className="stream-card"
                        />
                      ) : null}
                    </>
                  );
                })}
            </StyledStreamLive>
            {isPaginatingLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '1.5rem',
                }}
              >
                <img
                  alt="Paginating"
                  src={PaginationSpinnerIcon}
                  style={{
                    animation: 'rotating 1s linear infinite',
                    height: '2rem',
                    width: '2rem',
                  }}
                />
              </div>
            ) : null}
          </div>
          {Number(userType) === 3 && classDetails?.orui_is_archived !== 1 ? (
            <div className="stream-sidebar">
              <SessionSidebar />
            </div>
          ) : null}
          {Number(userType) === 2 && classDetails?.orui_is_archived !== 1 ? (
            <div className="stream-student-sidebar">
              <StudentSessionSidebar />
            </div>
          ) : null}
        </div>
      </StreamContainer>
    </>
  );
};
