/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

import {AddStudentContainer, FullContainer} from './elements';
import {
  UploadSection,
  ReuseAddedFiles,
  TaskSubmissionFooter,
  TaskSubmissionHeader,
  FilePreview,
  FilePreviewReuse,
} from './components';

import CustomBox from '../../../../../../../../components/shared/CustomBox';

import {Spacer} from '../../../../../../../../components/common';

export const FileSubmission = ({task, assessmentID, taskId, linkedFileId}) => {
  const history = useHistory();

  const [classID, setClassID] = useState(null);

  const {pathname} = history.location;

  useEffect(() => {
    if (/stream/.test(pathname)) {
      const idOfClass = pathname.split('/')[2];
      setClassID(idOfClass);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [fileImport, setImport] = useState(true);
  const [reused, setReused] = useState(false);
  const [upload, setUpload] = useState(null);
  const [onDrag, setOnDrag] = useState(false);
  const [addToPortFolio, setAddToPortFolio] = useState(false);
  const [isTaskLinked, setIsTaskLinked] = useState(false);

  const [selectedReusedFile, setSelectedReusedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const dispatch = useDispatch();

  const [steps, setSteps] = useState(0);
  const [stepsForReuse, setStepsForReuse] = useState(0);

  return (
    <>
      <FullContainer>
        <TaskSubmissionHeader task={task} />
        {/* <Spacer height="11rem" /> */}
        <AddStudentContainer>
          <div className="container-student">
            <div className="studentheading d-flex justify-content-between  mb-4">
              <h2 style={{fontSize: '1.5rem'}}>Submit files to the task</h2>
              <p className="sub-text">
                Upload files or Reuse the previously submitted files on this
                assessment
              </p>
            </div>
            <CustomBox padding="0.0625rem 1.25rem" className="custombox">
              <div className="search-div">
                <button
                  className={fileImport ? 'buttom link' : 'buttom'}
                  type="button"
                  onClick={() => {
                    if (!isTaskLinked) {
                      setImport(true);
                      setReused(false);
                      setSteps(0);
                    }
                  }}
                >
                  <p>Upload File</p>
                </button>
                <button
                  className={reused ? 'buttom link' : 'buttom'}
                  type="button"
                  onClick={() => {
                    if (!isTaskLinked) {
                      setImport(false);
                      setReused(true);
                      setStepsForReuse(0);
                    }
                  }}
                >
                  <p>Reuse Added Files</p>
                </button>
                <div className="seperator-search mt-2" />
              </div>

              {upload && steps === 1 && fileImport ? (
                <div style={{marginTop: '1.5rem'}}>
                  <FilePreview
                    file={upload}
                    fileName={fileName}
                    addToPortFolio={addToPortFolio}
                    setAddToPortFolio={setAddToPortFolio}
                    setIsTaskLinked={setIsTaskLinked}
                    isTaskLinked={isTaskLinked}
                    assessmentID={assessmentID}
                    taskId={taskId}
                  />
                </div>
              ) : null}

              {selectedReusedFile && stepsForReuse === 1 && reused ? (
                <div style={{marginTop: '1.5rem'}}>
                  <FilePreviewReuse
                    previewFileData={selectedReusedFile}
                    fileName={fileName}
                    addToPortFolio={addToPortFolio}
                    setAddToPortFolio={setAddToPortFolio}
                    setIsTaskLinked={setIsTaskLinked}
                    isTaskLinked={isTaskLinked}
                    assessmentID={assessmentID}
                    taskId={taskId}
                  />
                </div>
              ) : null}

              {reused && stepsForReuse === 0 ? (
                <ReuseAddedFiles
                  setSelectedReusedFile={setSelectedReusedFile}
                  selectedReusedFile={selectedReusedFile}
                />
              ) : (
                <div
                  className="inputfields"
                  style={
                    fileImport
                      ? steps === 1
                        ? {padding: '0rem 0rem 1.25rem 0rem'}
                        : null
                      : stepsForReuse === 1
                      ? {padding: '0rem 0rem 1.25rem 0rem'}
                      : null
                  }
                >
                  {steps === 0 && fileImport ? (
                    <UploadSection
                      steps={steps}
                      setUpload={setUpload}
                      upload={upload}
                      onDrag={onDrag}
                      setOnDrag={setOnDrag}
                      setFileName={setFileName}
                      accept=".doc,.csv,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf,application/msword,.jpg,.jpeg,.png,.mov,.mp4,.mp3"
                    />
                  ) : null}
                </div>
              )}
            </CustomBox>
          </div>
        </AddStudentContainer>
        <Spacer height="7rem" />
        <TaskSubmissionFooter
          steps={steps}
          setSteps={setSteps}
          upload={upload}
          fileName={fileName}
          setUpload={setUpload}
          isTaskLinked={isTaskLinked}
          assessmentID={assessmentID}
          taskId={taskId}
          history={history}
          classID={classID}
          reused={reused}
          fileImport={fileImport}
          setStepsForReuse={setStepsForReuse}
          stepsForReuse={stepsForReuse}
          selectedReusedFile={selectedReusedFile}
          setSelectedReusedFile={setSelectedReusedFile}
          dispatch={dispatch}
          setIsTaskLinked={setIsTaskLinked}
          setAddToPortFolio={setAddToPortFolio}
          linkedFileId={linkedFileId}
        />
      </FullContainer>
    </>
  );
};
