export const COUNTRIES = [
  {
    name: 'AFGHANISTAN',
    code: 'AFG',
  },
  {
    name: 'ALBANIA',
    code: 'ALB',
  },
  {
    name: 'ALGERIA',
    code: 'DZA',
  },
  {
    name: 'AMERICAN SAMOA',
    code: 'ASM',
  },
  {
    name: 'ANDORRA',
    code: 'AND',
  },
  {
    name: 'ANGOLA',
    code: 'AGO',
  },
  {
    name: 'ANGUILLA',
    code: 'AIA',
  },
  {
    name: 'ANTARCTICA',
    code: 'ATA',
  },
  {
    name: 'ANTIGUA AND BARBUDA',
    code: 'ATG',
  },
  {
    name: 'ARGENTINA',
    code: 'ARG',
  },
  {
    name: 'ARMENIA',
    code: 'ARM',
  },
  {
    name: 'ARUBA',
    code: 'ABW',
  },
  {
    name: 'AUSTRALIA',
    code: 'AUS',
  },
  {
    name: 'AUSTRIA',
    code: 'AUT',
  },
  {
    name: 'AZERBAIJAN',
    code: 'AZE',
  },
  {
    name: 'BAHAMAS',
    code: 'BHS',
  },
  {
    name: 'BAHRAIN',
    code: 'BHR',
  },
  {
    name: 'BANGLADESH',
    code: 'BGD',
  },
  {
    name: 'BARBADOS',
    code: 'BRB',
  },
  {
    name: 'BELARUS',
    code: 'BLR',
  },
  {
    name: 'BELGIUM',
    code: 'BEL',
  },
  {
    name: 'BELIZE',
    code: 'BLZ',
  },
  {
    name: 'BENIN',
    code: 'BEN',
  },
  {
    name: 'BERMUDA',
    code: 'BMU',
  },
  {
    name: 'BHUTAN',
    code: 'BTN',
  },
  {
    name: 'BOLIVIA',
    code: 'BOL',
  },
  {
    name: 'BONAIRE, SAINT EUSTATIUS AND SABA',
    code: 'BES',
  },
  {
    name: 'BOSNIA & HERZEGOVINA',
    code: 'BIH',
  },
  {
    name: 'BOTSWANA',
    code: 'BWA',
  },
  {
    name: 'BOUVET ISLAND',
    code: 'BVT',
  },
  {
    name: 'BRAZIL',
    code: 'BRA',
  },
  {
    name: 'BRIT -PROTECTED PERS',
    code: 'GBP',
  },
  {
    name: 'BRITISH OVERSEAS TERRITORIES CITIZEN',
    code: 'GBD',
  },
  {
    name: 'BRIT INDIAN OCN TERR',
    code: 'IOT',
  },
  {
    name: 'BRITISH -CITIZEN',
    code: 'GBR',
  },
  {
    name: 'BRITISH -O/SEAS CITZ',
    code: 'GBO',
  },
  {
    name: 'BRITISH -SUBJECT',
    code: 'GBS',
  },
  {
    name: 'BRITISH NATIONAL OVERSEAS',
    code: 'GBN',
  },
  {
    name: 'BRUNEI DARUSSALAM',
    code: 'BRN',
  },
  {
    name: 'BULGARIA',
    code: 'BGR',
  },
  {
    name: 'BURKINA FASO',
    code: 'BFA',
  },
  {
    name: 'BURKINA FASO',
    code: 'HVO',
  },
  {
    name: 'BURMA',
    code: 'BUR',
  },
  {
    name: 'BURUNDI',
    code: 'BDI',
  },
  {
    name: 'BYELORUSSIA',
    code: 'BYS',
  },
  {
    name: 'CAMBODIA',
    code: 'KHM',
  },
  {
    name: 'CAMEROON',
    code: 'CMR',
  },
  {
    name: 'CANADA',
    code: 'CAN',
  },
  {
    name: 'CABO VERDE',
    code: 'CPV',
  },
  {
    name: 'CAYMAN ISLANDS',
    code: 'CYM',
  },
  {
    name: 'CENTRAL AFRICAN REP',
    code: 'CAF',
  },
  {
    name: 'CHAD',
    code: 'TCD',
  },
  {
    name: 'CHILE',
    code: 'CHL',
  },
  {
    name: 'CHINA',
    code: 'CHN',
  },
  {
    name: 'COCOS (KEELING) ISL.',
    code: 'CCK',
  },
  {
    name: 'COLOMBIA',
    code: 'COL',
  },
  {
    name: 'COMOROS',
    code: 'COM',
  },
  {
    name: 'CONGO',
    code: 'COG',
  },
  {
    name: 'COOK ISLANDS',
    code: 'COK',
  },
  {
    name: 'COSTA RICA',
    code: 'CRI',
  },
  {
    name: "COTE D'IVOIRE",
    code: 'CIV',
  },
  {
    name: 'CROATIA',
    code: 'HRV',
  },
  {
    name: 'CUBA',
    code: 'CUB',
  },
  {
    name: 'CURACAO',
    code: 'CUW',
  },
  {
    name: 'CYPRUS',
    code: 'CYP',
  },
  {
    name: 'CZECH REPUBLIC',
    code: 'CZE',
  },
  {
    name: 'CZECHOSLOVAKIA',
    code: 'CSK',
  },
  {
    name: 'DEM REP OF THE CONGO',
    code: 'COD',
  },
  {
    name: 'DENMARK',
    code: 'DNK',
  },
  {
    name: 'DJIBOUTI',
    code: 'DJI',
  },
  {
    name: 'DOMINICA',
    code: 'DMA',
  },
  {
    name: 'DOMINICAN REPUBLIC',
    code: 'DOM',
  },
  {
    name: 'EAST GERMANY',
    code: 'DDR',
  },
  {
    name: 'EAST TIMOR',
    code: 'TMP',
  },
  {
    name: 'ECUADOR',
    code: 'ECU',
  },
  {
    name: 'EGYPT',
    code: 'EGY',
  },
  {
    name: 'EL SALVADOR',
    code: 'SLV',
  },
  {
    name: 'EQUATORIAL GUINEA',
    code: 'GNQ',
  },
  {
    name: 'ERITREA',
    code: 'ERI',
  },
  {
    name: 'ESTONIA',
    code: 'EST',
  },
  {
    name: 'ETA TEST',
    code: 'TST',
  },
  {
    name: 'ETHIOPIA',
    code: 'ETH',
  },
  {
    name: 'FALKLAND ISLANDS',
    code: 'FLK',
  },
  {
    name: 'FAROE ISLANDS',
    code: 'FRO',
  },
  {
    name: 'FIJI',
    code: 'FJI',
  },
  {
    name: 'FINLAND',
    code: 'FIN',
  },
  {
    name: 'FRANCE',
    code: 'FRA',
  },
  {
    name: 'FRANCE, METROPOLITAN',
    code: 'FXX',
  },
  {
    name: 'FRENCH GUIANA',
    code: 'GUF',
  },
  {
    name: 'FRENCH POLYNESIA',
    code: 'PYF',
  },
  {
    name: 'FRENCH SOUTHERN TERR',
    code: 'ATF',
  },
  {
    name: 'FYR MACEDONIA',
    code: 'MKD',
  },
  {
    name: 'GABON',
    code: 'GAB',
  },
  {
    name: 'GAMBIA',
    code: 'GMB',
  },
  {
    name: 'GEORGIA',
    code: 'GEO',
  },
  {
    name: 'GEORGIA/SANDWICH ISL',
    code: 'SGS',
  },
  {
    name: 'GERMANY',
    code: 'DEU',
  },
  {
    name: 'GHANA',
    code: 'GHA',
  },
  {
    name: 'GIBRALTAR',
    code: 'GIB',
  },
  {
    name: 'GREECE',
    code: 'GRC',
  },
  {
    name: 'GREENLAND',
    code: 'GRL',
  },
  {
    name: 'GRENADA',
    code: 'GRD',
  },
  {
    name: 'GUADELOUPE',
    code: 'GLP',
  },
  {
    name: 'GUAM',
    code: 'GUM',
  },
  {
    name: 'GUATEMALA',
    code: 'GTM',
  },
  {
    name: 'GUINEA',
    code: 'GIN',
  },
  {
    name: 'GUINEA-BISSAU',
    code: 'GNB',
  },
  {
    name: 'GUYANA',
    code: 'GUY',
  },
  {
    name: 'HAITI',
    code: 'HTI',
  },
  {
    name: 'HEARD & MCDONALD ISL',
    code: 'HMD',
  },
  {
    name: 'HONDURAS',
    code: 'HND',
  },
  {
    name: 'HONG KONG SAR',
    code: 'HKG',
  },
  {
    name: 'HUNGARY',
    code: 'HUN',
  },
  {
    name: 'ICELAND',
    code: 'ISL',
  },
  {
    name: 'INDIA',
    code: 'IND',
  },
  {
    name: 'INDONESIA',
    code: 'IDN',
  },
  {
    name: 'IRAN',
    code: 'IRN',
  },
  {
    name: 'IRAQ',
    code: 'IRQ',
  },
  {
    name: 'IRELAND',
    code: 'IRL',
  },
  {
    name: 'ISRAEL',
    code: 'ISR',
  },
  {
    name: 'ITALY',
    code: 'ITA',
  },
  {
    name: 'JAMAICA',
    code: 'JAM',
  },
  {
    name: 'JAPAN',
    code: 'JPN',
  },
  {
    name: 'JORDAN',
    code: 'JOR',
  },
  {
    name: 'KAZAKHSTAN',
    code: 'KAZ',
  },
  {
    name: 'KENYA',
    code: 'KEN',
  },
  {
    name: 'KIRIBATI',
    code: 'KIR',
  },
  {
    name: 'KOREA, NORTH',
    code: 'PRK',
  },
  {
    name: 'KOREA, SOUTH',
    code: 'KOR',
  },
  {
    name: 'KOSOVO',
    code: 'RKS',
  },
  {
    name: 'KUWAIT',
    code: 'KWT',
  },
  {
    name: 'KYRGYZSTAN',
    code: 'KGZ',
  },
  {
    name: 'LAO PEOPLES DEM REP',
    code: 'LAO',
  },
  {
    name: 'LATVIA',
    code: 'LVA',
  },
  {
    name: 'LEBANON',
    code: 'LBN',
  },
  {
    name: 'LESOTHO',
    code: 'LSO',
  },
  {
    name: 'LIBERIA',
    code: 'LBR',
  },
  {
    name: 'LIBYA',
    code: 'LBY',
  },
  {
    name: 'LIECHTENSTEIN',
    code: 'LIE',
  },
  {
    name: 'LITHUANIA',
    code: 'LTU',
  },
  {
    name: 'LUXEMBOURG',
    code: 'LUX',
  },
  {
    name: 'MACAU SAR',
    code: 'MAC',
  },
  {
    name: 'MADAGASCAR',
    code: 'MDG',
  },
  {
    name: 'MALAWI',
    code: 'MWI',
  },
  {
    name: 'MALAYSIA',
    code: 'MYS',
  },
  {
    name: 'MALDIVES',
    code: 'MDV',
  },
  {
    name: 'MALI',
    code: 'MLI',
  },
  {
    name: 'MALTA',
    code: 'MLT',
  },
  {
    name: 'MARSHALL ISLANDS',
    code: 'MHL',
  },
  {
    name: 'MARTINIQUE',
    code: 'MTQ',
  },
  {
    name: 'MAURITANIA',
    code: 'MRT',
  },
  {
    name: 'MAURITIUS',
    code: 'MUS',
  },
  {
    name: 'MAYOTTE',
    code: 'MYT',
  },
  {
    name: 'MEXICO',
    code: 'MEX',
  },
  {
    name: 'MICRONESIA',
    code: 'FSM',
  },
  {
    name: 'MOLDOVA, REPUBLIC OF',
    code: 'MDA',
  },
  {
    name: 'MONACO',
    code: 'MCO',
  },
  {
    name: 'MONGOLIA',
    code: 'MNG',
  },
  {
    name: 'MONTENEGRO',
    code: 'MNE',
  },
  {
    name: 'MONTSERRAT',
    code: 'MSR',
  },
  {
    name: 'MOROCCO',
    code: 'MAR',
  },
  {
    name: 'MOZAMBIQUE',
    code: 'MOZ',
  },
  {
    name: 'MYANMAR',
    code: 'MMR',
  },
  {
    name: 'NAMIBIA',
    code: 'NAM',
  },
  {
    name: 'NAURU',
    code: 'NRU',
  },
  {
    name: 'NEPAL',
    code: 'NPL',
  },
  {
    name: 'NETHERLANDS',
    code: 'NLD',
  },
  {
    name: 'NETHERLANDS ANTILLES',
    code: 'ANT',
  },
  {
    name: 'NEUTRAL ZONE',
    code: 'NTZ',
  },
  {
    name: 'NEW CALEDONIA',
    code: 'NCL',
  },
  {
    name: 'NEW ZEALAND',
    code: 'NZL',
  },
  {
    name: 'NICARAGUA',
    code: 'NIC',
  },
  {
    name: 'NIGER',
    code: 'NER',
  },
  {
    name: 'NIGERIA',
    code: 'NGA',
  },
  {
    name: 'NIUE',
    code: 'NIU',
  },
  {
    name: 'NORTHERN MARIANA ISL',
    code: 'MNP',
  },
  {
    name: 'NORWAY',
    code: 'NOR',
  },
  {
    name: 'OMAN',
    code: 'OMN',
  },
  {
    name: 'PAKISTAN',
    code: 'PAK',
  },
  {
    name: 'PALAU',
    code: 'PLW',
  },
  {
    name: 'PALESTINIAN AUTHRTY',
    code: 'PSE',
  },
  {
    name: 'PANAMA',
    code: 'PAN',
  },
  {
    name: 'PAPUA NEW GUINEA',
    code: 'PNG',
  },
  {
    name: 'PARAGUAY',
    code: 'PRY',
  },
  {
    name: 'PERU',
    code: 'PER',
  },
  {
    name: 'PHILIPPINES',
    code: 'PHL',
  },
  {
    name: 'PITCAIRN',
    code: 'PCN',
  },
  {
    name: 'POLAND',
    code: 'POL',
  },
  {
    name: 'PORTUGAL',
    code: 'PRT',
  },
  {
    name: 'PUERTO RICO',
    code: 'PRI',
  },
  {
    name: 'QATAR',
    code: 'QAT',
  },
  {
    name: 'REFUGEE AS PER ART 1',
    code: 'XXB',
  },
  {
    name: 'REFUGEE OTHER',
    code: 'XXC',
  },
  {
    name: 'REUNION',
    code: 'REU',
  },
  {
    name: 'ROMANIA',
    code: 'ROU',
  },
  {
    name: 'ROMANIA PRE 1/2/2002',
    code: 'ROM',
  },
  {
    name: 'RUSSIAN FEDERATION',
    code: 'RUS',
  },
  {
    name: 'RWANDA',
    code: 'RWA',
  },
  {
    name: 'SAINT LUCIA',
    code: 'LCA',
  },
  {
    name: 'SAMOA',
    code: 'WSM',
  },
  {
    name: 'SAN MARINO',
    code: 'SMR',
  },
  {
    name: 'SAO TOME & PRINCIPE',
    code: 'STP',
  },
  {
    name: 'SAUDI ARABIA',
    code: 'SAU',
  },
  {
    name: 'SENEGAL',
    code: 'SEN',
  },
  {
    name: 'SERBIA',
    code: 'SRB',
  },
  {
    name: 'SERBIA AND MONTENEGRO',
    code: 'SCG',
  },
  {
    name: 'SEYCHELLES',
    code: 'SYC',
  },
  {
    name: 'SIERRA LEONE',
    code: 'SLE',
  },
  {
    name: 'SINGAPORE',
    code: 'SGP',
  },
  {
    name: 'SINT MAARTEN (DUTCH PART)',
    code: 'SXM',
  },
  {
    name: 'SLOVAK REPUBLIC',
    code: 'SVK',
  },
  {
    name: 'SLOVENIA',
    code: 'SVN',
  },
  {
    name: 'SOLOMON ISLANDS',
    code: 'SLB',
  },
  {
    name: 'SOMALIA',
    code: 'SOM',
  },
  {
    name: 'SOUTH AFRICA',
    code: 'ZAF',
  },
  {
    name: 'SOUTH SUDAN',
    code: 'SSD',
  },
  {
    name: 'SOVIET UNION',
    code: 'SUN',
  },
  {
    name: 'SPAIN',
    code: 'ESP',
  },
  {
    name: 'SRI LANKA',
    code: 'LKA',
  },
  {
    name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    code: 'SHN',
  },
  {
    name: 'ST KITTS & NEVIS',
    code: 'KNA',
  },
  {
    name: 'ST PIERRE & MIQUELON',
    code: 'SPM',
  },
  {
    name: 'ST VINCENT/GRENADINE',
    code: 'VCT',
  },
  {
    name: 'STATELESS PERSON',
    code: 'XXA',
  },
  {
    name: 'SUDAN',
    code: 'SDN',
  },
  {
    name: 'SURINAME',
    code: 'SUR',
  },
  {
    name: 'SVALBARD & JAN MAYEN',
    code: 'SJM',
  },
  {
    name: 'SWAZILAND',
    code: 'SWZ',
  },
  {
    name: 'SWEDEN',
    code: 'SWE',
  },
  {
    name: 'SWITZERLAND',
    code: 'CHE',
  },
  {
    name: 'SYRIA',
    code: 'SYR',
  },
  {
    name: 'TAIWAN',
    code: 'TWN',
  },
  {
    name: 'TAJIKISTAN',
    code: 'TJK',
  },
  {
    name: 'TANZANIA',
    code: 'TZA',
  },
  {
    name: 'THAILAND',
    code: 'THA',
  },
  {
    name: 'TIMOR-LESTE',
    code: 'TLS',
  },
  {
    name: 'TOGO',
    code: 'TGO',
  },
  {
    name: 'TOKELAU',
    code: 'TKL',
  },
  {
    name: 'TONGA',
    code: 'TON',
  },
  {
    name: 'TRINIDAD AND TOBAGO',
    code: 'TTO',
  },
  {
    name: 'TUNISIA',
    code: 'TUN',
  },
  {
    name: 'TURKEY',
    code: 'TUR',
  },
  {
    name: 'TURKMENISTAN',
    code: 'TKM',
  },
  {
    name: 'TURKS AND CAICOS ISL',
    code: 'TCA',
  },
  {
    name: 'TUVALU',
    code: 'TUV',
  },
  {
    name: 'U.S MINOR ISLANDS',
    code: 'UMI',
  },
  {
    name: 'UGANDA',
    code: 'UGA',
  },
  {
    name: 'UKRAINE',
    code: 'UKR',
  },
  {
    name: 'UNITED ARAB EMIRATES',
    code: 'ARE',
  },
  {
    name: 'UNITED NATIONS AGNCY',
    code: 'UNA',
  },
  {
    name: 'UNITED NATIONS ORG',
    code: 'UNO',
  },
  {
    name: 'UNITED STATES',
    code: 'USA',
  },
  {
    name: 'UNMIK TRAVEL DOC',
    code: 'UNK',
  },
  {
    name: 'UNSPCFED NATIONALITY',
    code: 'XXX',
  },
  {
    name: 'URUGUAY',
    code: 'URY',
  },
  {
    name: 'UZBEKISTAN',
    code: 'UZB',
  },
  {
    name: 'VANUATU',
    code: 'VUT',
  },
  {
    name: 'VATICAN CITY STATE',
    code: 'VAT',
  },
  {
    name: 'VENEZUELA',
    code: 'VEN',
  },
  {
    name: 'VIETNAM',
    code: 'VNM',
  },
  {
    name: 'VIRGIN ISLANDS (BRIT',
    code: 'VGB',
  },
  {
    name: 'VIRGIN ISLANDS (U.S)',
    code: 'VIR',
  },
  {
    name: 'WALLIS & FUTUNA ISL',
    code: 'WLF',
  },
  {
    name: 'WESTERN SAHARA',
    code: 'ESH',
  },
  {
    name: 'YEMEN',
    code: 'YEM',
  },
  {
    name: 'YEMEN',
    code: 'YMD',
  },
  {
    name: 'YUGOSLAVIA',
    code: 'YUG',
  },
  {
    name: 'ZAIRE',
    code: 'ZAR',
  },
  {
    name: 'ZAMBIA',
    code: 'ZMB',
  },
  {
    name: 'ZIMBABWE',
    code: 'ZWE',
  },
];
