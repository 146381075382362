import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {
  Button,
  Checkbox,
  Link,
  Spacer,
  TextInput,
} from '../../../../components/common';
import {authSelector, logIn, setError} from '../../../../redux/authSlice';
import {useErrorMessage} from '../../../../utils/hooks';
import {loginSchema} from '../../../../utils/validation';
import {
  AuthSwitchLink,
  ErrorAlert,
  Heading,
  HeroSection,
} from '../../components';
import {AuthContainer} from '../../elements';

const FIELDS_IN_ORDER = ['email', 'password'];

export const Login = () => {
  const dispatch = useDispatch();
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      remember: true,
    },
    resolver: yupResolver(loginSchema),
  });
  const history = useHistory();
  const {error: serverError, isLoading} = useSelector(authSelector);

  const onSubmit = useCallback(
    data => {
      dispatch(
        logIn(data, userProfile => {
          const isAdmin = userProfile.up_id_typeuserprofile === 4;
          history.push(isAdmin ? '/admin' : '/classes');
        }),
      );
    },
    [dispatch, history],
  );

  const clearServerError = useCallback(() => {
    dispatch(setError(null));
  }, [dispatch]);

  const {errorMessage, hasServerError} = useErrorMessage(
    errors,
    serverError,
    clearServerError,
    FIELDS_IN_ORDER,
  );

  return (
    <AuthContainer className="no-gutters row">
      <Helmet>
        <title>Login | JungleCat</title>
      </Helmet>
      <HeroSection />
      <section className="align-items-center col-lg-6 col-md-12 d-flex flex-column justify-content-center main-section position-relative px-4">
        <ErrorAlert isVisible={!!errorMessage} message={errorMessage} />
        <main className="d-flex flex-column main-content w-100">
          <Spacer height="4rem" />
          <div className="d-flex flex-column justify-content-center flex-grow-1">
            <Heading
              subtitle="Enter your credentials to access your account."
              title="Welcome Back"
            />
            <form
              className="align-items-center d-flex flex-column w-100"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="email"
                render={fields => (
                  <TextInput
                    {...fields}
                    autoFocus
                    className="mb-4"
                    hasError={hasServerError || !!errors.email}
                    isLarge
                    label="Email"
                    placeholder="Enter your email address"
                    id="email"
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                render={fields => (
                  <TextInput
                    {...fields}
                    className="mb-4"
                    hasError={hasServerError || !!errors.password}
                    isLarge
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    id="password"
                  />
                )}
              />
              <div className="align-items-center d-flex justify-content-between mb-4 w-100">
                <Controller
                  control={control}
                  name="remember"
                  render={fields => (
                    <Checkbox {...fields} label="Remember me" />
                  )}
                />
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              <Button
                isLarge
                isLoading={isLoading}
                label="Log In"
                type="submit"
                id="submit"
              />
            </form>
            <AuthSwitchLink
              linkHref="/register"
              linkText="Join JungleCat"
              onLinkClick={clearServerError}
              text="Don't have an account?"
            />
          </div>
          <Spacer height="4rem" />
        </main>
      </section>
    </AuthContainer>
  );
};
